import concat from 'lodash/concat'
import slice from 'lodash/slice'

function rotate (arr, n) {
  return concat(slice(arr, n), slice(arr, 0, n))
}

function isFirstSmaller (first, second) {
  return first < second
}

function indexOfSmallest (arr) {
  let smallest = null
  let index = null
  arr.forEach((item, idx) => {
    if (smallest === null || isFirstSmaller(item, smallest)) {
      smallest = item
      index = idx
    }
  })
  return index
}

export function sanitizeRegionShape (points) {
  const smallest = indexOfSmallest(points)
  return rotate(points, smallest)
}
