import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import LocalisationList from "admin/localisation/LocalisationList";
import LocalisationEditPage from "admin/localisation/LocalisationEditPage";

class LocalisationAdminPages extends Component {
  render() {
    const { match, strings } = this.props;
    return (
      <Switch>
        <Route
          path={`${match.url}/edit/:id`}
          render={(props) => (
            <LocalisationEditPage {...props} strings={strings} />
          )}
        />
        <Route
          exact
          path={match.url}
          render={(props) => (
            <LocalisationList
              {...props}
              includeTitles="true"
              strings={strings}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(connect(null)(LocalisationAdminPages));
