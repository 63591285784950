// #NOTE -
/* 
Look into viewports and view port control as a replacement of rthe current leaflet-react implementation.
See 
* https://codepen.io/vgrem/pen/KKVbyJe
* https://stackoverflow.com/questions/48264529/how-to-get-map-object-in-react-leaflet-on-zoom-change-event
* https://stackoverflow.com/questions/60381912/setstate-in-onviewportchanged-results-in-crash-when-clicking-marker-with-off-map
*/

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Map, ImageOverlay } from "react-leaflet";
import { withMineLevel, MineLevelContext } from "ApplicationContexts";
import { getAreaById } from "components/WebWorker/reducer";
import { reactChildrenToFlatArray } from "utils/propsChildren";
import { getFolderFileNamesListById } from "components/Settings/reducer";
import { imageCheck } from "utils/imageCheck";
import _isEmpty from "lodash/isEmpty";
import { withComponentStateCache } from "react-component-state-cache";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

// --------------------------------------
// Marker not appearing for Simple Example
// Fix - https://github.com/PaulLeCam/react-leaflet/issues/453
//
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import "leaflet.smoothwheelzoom";

// 'react-leaflet-markercluster' styles
import "react-leaflet-markercluster/dist/styles.min.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function SimpleCRSImageMap({ ...props }) {
  const {
    mineLevel,
    mapViewState: rxMapViewState,
    mapViewType,
    style,
    displayOptions,
  } = props;

  //console.log("newMapViewState - displayOptions", displayOptions);

  // #WIP - corrupted???? how???
  let mapViewState = JSON?.parse(JSON?.stringify(rxMapViewState) || "{}"); // #WIP - deal with rxMapViewState being undefined in a better way thank this!!

  // defines whether to show default zoom controls or easyButton pan/zoom/lock button controls
  const isPanZoomButton =
    displayOptions?.includes("showPanZoomButton") || false;
  const isShowRestoreMapViewStateButton =
    displayOptions?.includes("showRestoreMapViewStateButton") || false;
  const isShowSearchControl =
    displayOptions?.includes("showSearchControl") || false; // default to displaying button active

  // setup map objects to state
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  const [isMapView, setIsMapView] = useState(null);

  // #TESTING - defined the leaflet map reference
  //let leafletMapRef;

  useEffect(() => {
    if (false) {
      console.log("MapViewState useEffect ------------------------------");
      console.log("MapViewState useEffect map xxxxxxxxx", map);
      console.log("MapViewState useEffect mapRef xxxxxxxxx", mapRef);
      console.log(
        "MapViewState useEffect mapRef.current xxxxxxxxx",
        mapRef?.current
      );
      console.log(
        "MapViewState useEffect mapRef.current isPanZoomButton",
        isPanZoomButton,
        "isShowRestoreMapViewStateButton",
        isShowRestoreMapViewStateButton,
        "isShowSearchControl",
        isShowSearchControl
      );

      console.log(
        "MapViewState useEffect ",
        " rxMapViewState - ",
        JSON?.stringify?.(rxMapViewState),
        " mineLevel - ",
        mineLevel,
        " mapViewType - ",
        mapViewType
      );

      console.log("MapViewState useEffect ------------------------------");
    }

    //if (!map) return;
    if (mapRef && mapRef.current) {
      const map = mapRef.current.leafletElement;

      setMap(map); //hook to set map
      // sets to const -> 'mapRef.current.leafletElement'

      // #WIP - fix this to setup the minimum zoom etc.
      //
      // const minZoomCalced =
      //   Math.floor(Math.log2(map.getSize().x / bounds.getEast())) - 1;
      // if (map.getMinZoom() !== minZoomCalced) {
      //   map.setMinZoom(minZoomCalced);
      //   map.setZoom(minZoomCalced);
      // }

      //mapRef.current.leafletElement.fitBounds(bounds);
      //map.setView({ lat: 50, lng: 50 }, 0);
      //map.fitBounds(bounds);
      //map.setView({ lat: 50, lng: 50 }, 3);

      // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
      // sets the startup centre and zoom of the map
      // sets initial view on component mount
      if (true) {
        if (
          mapViewState?.center !== undefined &&
          mapViewState?.zoom !== undefined
        ) {
          map.setView(
            { lat: mapViewState?.center[1], lng: mapViewState?.center[0] },
            zoom
          );
        } else {
          map.fitBounds(bounds);
        }
      }

      //
      // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

      // conditionally enable map movement/pan for leaflet map
      if (isPanZoomButton) {
        //----------------------------------------------------
        //
        // MAP MOVEMENT - DRAG ZOOM ETC
        //
        // see - https://gis.stackexchange.com/questions/54454/disable-leaflet-interaction-temporary
        //----------------------------------------------------

        const disableMapMovement = (map) => {
          map.dragging.disable();
          map.touchZoom.disable();
          map.doubleClickZoom.disable();
          //map.scrollWheelZoom.disable();
          map.smoothWheelZoom.disable();
          map.boxZoom.disable();
          map.keyboard.disable();
          if (map.tap) map.tap.disable();
        };

        const enableMapMovement = (map) => {
          map.dragging.enable();
          map.touchZoom.enable();
          map.doubleClickZoom.enable();
          //map.scrollWheelZoom.enable();
          map.smoothWheelZoom.enable();
          map.boxZoom.enable();
          map.keyboard.enable();
          if (map.tap) map.tap.enable();
        };

        // on startup disable map movement by user
        disableMapMovement(map);

        //----------------------------------------------------
        //
        // MAP VIEW MOVE - CONTROLS - function
        //
        //
        // At least of one of these is called by the map lock
        // so needs to be defined here beforehand
        //
        //----------------------------------------------------
        const moveMapViewButtonClick = (control, map) => {
          // show the zoom controls
          moveMapViewButton.disable();
          zoomOutButton.enable();
          zoomInButton.enable();
          cancelMapViewButton.enable();

          // disable the view buttons
          saveMapViewStateButton.disable();
          restoreMapViewStateButton.disable();

          // disable the search control
          searchButton.disable();

          // enable map movement
          enableMapMovement(map);

          setIsMapView(true);

          // show lock button state
          mapMoveLockButton.state("mapMoveLock-unlock");
          mapMoveLockButton.button.style.backgroundColor = "orange";
        };

        const cancelMapViewButtonClick = (control, map) => {
          // show the zoom controls
          moveMapViewButton.enable();
          zoomOutButton.disable();
          zoomInButton.disable();
          cancelMapViewButton.disable();

          // enable the view buttons
          saveMapViewStateButton.enable();
          isShowRestoreMapViewStateButton
            ? restoreMapViewStateButton.enable()
            : restoreMapViewStateButton.disable();

          // enable the search control
          searchButton.enable();

          // disable map movement
          disableMapMovement(map);

          setIsMapView(false);

          // show unlock button state
          mapMoveLockButton.state("mapMoveLock-lock");
          mapMoveLockButton.button.style.backgroundColor = "white";
        };

        //----------------------------------------------------
        //
        // MAP MARKER ZOOM  - CONTROLS - MAP LOCK INDICATOR
        //
        //
        // Indicates if map is locked
        //
        //----------------------------------------------------

        const mapMoveLockIcon = `<i class="fas fa-lock " ></i>`;
        const mapMoveUnLockIcon = `<i class="fas fa-unlock " ></i>`;

        const mapMoveLockButton = L.easyButton({
          states: [
            {
              stateName: "mapMoveLock-lock",
              icon: mapMoveLockIcon,
              title:
                "Map movement is locked. Click the Pan control to modify the map display.",
              onClick: moveMapViewButtonClick,
            },
            {
              stateName: "mapMoveLock-unlock",
              icon: mapMoveUnLockIcon,
              title: "Map movement is unlocked. Map drag and zoom is enabled.",
            },
          ],
        });

        //----------------------------------------------------
        //
        // MAP MARKER ZOOM  - CONTROLS
        //
        // Custom zoom controls
        //----------------------------------------------------

        // listeners for disabling buttons
        map.on("zoomend", (e) => {
          const map = e.target,
            max = map.getMaxZoom(),
            min = map.getMinZoom(),
            current = map.getZoom();
          const isMapViewCurent = isMapView?.current;
          if (current < max) {
            if (isMapViewCurent) {
              zoomInButton.enable();
            }
          }
          if (current >= max) {
            if (isMapViewCurent) {
              zoomInButton.disable();
            }
          }
          if (current > min) {
            if (isMapViewCurent) {
              zoomOutButton.enable();
            }
          }
          if (current <= min) {
            if (isMapViewCurent) {
              zoomOutButton.disable();
            }
          }
        });

        const moveMapViewZoomIn = `<i class="fas fa-plus "></i>`;
        const moveMapViewZoomOut = `<i class="fas fa-minus "></i>`;

        const zoomInButton = L.easyButton({
          states: [
            {
              stateName: "zoom-in-move-map-view",
              icon: moveMapViewZoomIn,
              title: "Zoom In",
              onClick: (control, map) => {
                map.setZoom(map.getZoom() + 1);
              },
            },
          ],
        });

        const zoomOutButton = L.easyButton({
          states: [
            {
              stateName: "zoom-out-move-map-view",
              icon: moveMapViewZoomOut,
              title: "Zoom Out",
              onClick: (control, map) => {
                map.setZoom(map.getZoom() - 1);
              },
            },
          ],
        });

        // const zoomBar = L.easyBar([zoomInButton, zoomOutButton]);
        // zoomBar.addTo(map, {
        //   position: "topleft",
        // });

        //----------------------------------------------------
        //
        // MAP VIEW MOVE - CONTROLS
        //
        //----------------------------------------------------

        const moveMapViewShow = `<i class="fas fa-arrows-up-down-left-right "></i>`;
        const moveMapViewHide = `<i class="fas fa-arrows-up-down-left-right " style="opacity:0.2"></i>`;

        const moveMapViewButton = L.easyButton({
          states: [
            {
              stateName: "show-move-map-view",
              icon: moveMapViewShow,
              title: "Pan and Zoom",
              onClick: moveMapViewButtonClick,
            },
            {
              stateName: "hide-move-map-view",
              icon: moveMapViewHide,
              title: "Disable move view",
            },
          ],
        });

        const cancelMapViewButton = L.easyButton({
          states: [
            {
              stateName: "cancel-move-map-view",
              icon: "fa fa-times",
              title: "Disable Pan and Zoom",
              onClick: cancelMapViewButtonClick,
            },
          ],
        });

        let moveMapViewBar = L.easyBar(
          [
            mapMoveLockButton,
            moveMapViewButton,
            zoomInButton,
            zoomOutButton,
            cancelMapViewButton,
          ],
          { position: "topleft" }
        );
        moveMapViewBar.addTo(map);

        // startup hide - zoom buttons
        moveMapViewButton.enable();
        zoomOutButton.disable();
        zoomInButton.disable();
        cancelMapViewButton.disable();

        //----------------------------------------------------
        //
        // MAP MARKER RESET VIEW - CONTROLS
        //
        //----------------------------------------------------
        // #NOTE - with current leaflet need to do action to setView
        // https://stackoverflow.com/questions/65322670/change-center-position-of-react-leaflet-map

        const mapViewRestoreShow = `<i class="fa fa-eye "></i>`;

        const restoreMapViewStateButton = L.easyButton({
          states: [
            {
              stateName: "map-view-restore",
              icon: mapViewRestoreShow,
              title: "Restore saved view",
              onClick: (e) => {
                const center = mapViewState?.center;
                const zoom = mapViewState?.zoom;
                if (Array.isArray(center) && !isNaN(zoom)) {
                  map.setView({ lat: center[1], lng: center[0] }, zoom, {
                    animation: false,
                  });
                }
              },
            },
          ],
        });

        const saveMapViewStateButton = L.easyButton({
          states: [
            {
              stateName: "map-view-save",
              icon: "fas fa-save",
              title: "Save view",
              onClick: (e) => {
                // get the current map center and zoom
                const coords = map.getCenter();
                const center = Object?.values?.(coords)?.reverse?.();
                const zoom = map.getZoom();

                // need this to get the current mapViewState 'options'
                let newMapViewState = props.componentstate.get(
                  mapViewType,
                  mineLevel.id
                );

                //console.log("newMapViewState 1 - options", newMapViewState);

                // spread to preserve any existing `options:`
                newMapViewState = {
                  ...newMapViewState,
                  id: mineLevel.id,
                  center: center,
                  zoom: zoom,
                };

                // set the new system saved mapViewState

                const settings = {
                  section: mapViewType,
                  key: mineLevel.id,
                  data: newMapViewState,
                };

                props.componentstate.set(
                  settings.section,
                  settings.key,
                  settings.data
                );

                props.saveUserSettingsComponentState({ settings });

                //console.log("newMapViewState 2 - options", newMapViewState);

                // set system saved mapViewState with current center and zoom
                // props.componentstate.set(
                //   mapViewType,
                //   mineLevel.id,
                //   newMapViewState
                // );
              },
            },
          ],
        });

        let restoreMapViewStateBar = L.easyBar(
          [restoreMapViewStateButton, saveMapViewStateButton],
          {
            position: "topleft",
            id: "horizontaleasybar",
          }
        );

        restoreMapViewStateBar.addTo(map);

        // disable the restore button on startup until check restore properties exist
        isShowRestoreMapViewStateButton
          ? restoreMapViewStateButton.enable()
          : restoreMapViewStateButton.disable();

        //----------------------------------------------------
        //
        // MAP MARKER REFRESH - CONTROLS
        //
        //----------------------------------------------------

        const refreshMarkerPositionsButton = L.easyButton({
          states: [
            {
              stateName: "reset-marker-positions",
              icon: "fa fa-redo",
              title: "Show all",
              onClick: (e) => {
                map.fitBounds(bounds);
              },
            },
          ],
        });

        let refreshMapBar = L.easyBar([refreshMarkerPositionsButton], {
          position: "topleft",
        });
        refreshMapBar.addTo(map);

        //----------------------------------------------------
        //
        // MAP MARKER SEARCH - CONTROLS
        //
        //----------------------------------------------------

        const mapSearchShow = `<i class="fas fa-search "></i>`;
        const mapSearchHide = `<i class="fas fa-search " style="opacity:0.2"></i>`;

        const searchButtonClick = (control, map) => {
          // get current tagMapViewState
          let newMapViewState = props.componentstate.get(
            mapViewType,
            mineLevel.id
          );

          let newOptions = newMapViewState?.options || [];

          // this detects the toggle of the searchButton, but the actual display of the search control
          // is done in e.g. TagTrackingMap with the inclusion of the component <SearchControl .../>
          //
          if (newOptions.includes("showSearchControl")) {
            newOptions = newOptions.filter(
              (setting) => setting !== "showSearchControl"
            );
          } else {
            newOptions.push("showSearchControl");
          }

          // spread to preserve any existing settings
          newMapViewState = {
            ...newMapViewState,
            id: mineLevel.id,
            options: newOptions,
          };

          //console.log("newMapViewState - options", newMapViewState);

          // set system saved mapViewState
          props.componentstate.set(mapViewType, mineLevel.id, newMapViewState);
        };

        const searchButton = L.easyButton({
          states: [
            {
              stateName: "mapSearch-show",
              icon: mapSearchShow,
              title: "Show search",
              onClick: searchButtonClick,
            },
            {
              stateName: "mapSearch-hide",
              icon: mapSearchHide,
              title: "Hide search",
              onClick: searchButtonClick,
            },
          ],
        });

        let searchBar = L.easyBar([searchButton]);
        searchBar.addTo(map);

        isShowSearchControl
          ? searchButton.state("mapSearch-hide")
          : searchButton.state("mapSearch-show");
      }
    }
    //console.log("xxxx map mounted");
  }, []);
  // #NOTE - Do not link props or control bar will re-render with each prop change.
  // The original coding intention is that the component is completely re-drawn so useEffect() is only
  // draw once per render.

  const { image_info, url } = mineLevel;
  const { width = 10000, height = 8000, backgroundColor } = image_info;

  let newStyle = style;

  if (backgroundColor) {
    newStyle = {
      ...style,
      backgroundColor: `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`,
    };
  }

  if (_isEmpty(width) === undefined || _isEmpty(height) === undefined) {
    return (
      <div>
        `Error With Image Dimensions: width ${width}, height ${height}`
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////
  // Conversion from (x, y) raster image coordinates to equivalent of latLng
  // Taken from Leaflet tutorial "Non-geographical maps"
  // http://leafletjs.com/examples/crs-simple/crs-simple.html
  ////////////////////////////////////////////////////////////////////////////
  var yx = L.latLng;
  var xy = function (x, y) {
    if (L.Util.isArray(x)) {
      // When doing xy([x, y]);
      return yx(x[1], x[0]);
    }
    return yx(y, x); // When doing xy(x, y);
  };

  // #WIP - add these to config options
  var minZoom = -5;
  var maxZoom = 5;

  const img = [
    width, // original width of image `level.jpg` - undercut 800 conversion example => x / ~longitude (0 is left, width,  13234 is right)
    height, // original height of image => y / ~ reverse of latitude (0 is top, height, 9356 is bottom)
  ];

  L.CRS.MySimple = L.extend({}, L.CRS.Simple, {
    //                      coefficients: a      b    c     d
    transformation: new L.Transformation(1 / 64, 0, 1 / 64, 0),
  });

  // center calculation based on original pixel dimensions
  // _or_ from mapView prop if this has been set
  //
  let center = [img[0] / 2, img[1] / 2];
  let zoom = 2;

  // sets the startup centre and zoom of the map
  if (true) {
    if (mapViewState?.center !== undefined) {
      const newCenter = mapViewState?.center; // Object.values(mapViewState?.center); // ?.reverse();
      if (center !== newCenter) {
        center = newCenter;
      }
    }
    if (mapViewState?.zoom !== undefined) {
      const newZoom = mapViewState?.zoom;
      if (zoom !== newZoom && !isNaN(newZoom)) {
        zoom = newZoom;
      }
    }
  }

  var bounds = L.latLngBounds([xy(0, 0), xy(img)]);

  // get map drag last center from target and set system Map settings state
  const handleMoveZoomEnd = (e) => {};

  // #WIP - testing layer add event
  const handleOnlayeradd = (e) => {
    //console.log("MapViewState handleOnlayeradd ", e);
    //mapRef.current.leafletElement.fitBounds(bounds);
    //mapRef.current.leafletElement.setView({ lat: 50, lng: 50 }, 0);
  };

  // support for map leaflet.contextmenu - #DEBUG message
  const showCoordinates = (e) => {
    console.log("MapViewState showCoordinates  showCoordinates", e.latlng);
    //alert(e.latlng);
  };

  const centerMap = (e) => {
    mapRef.current.leafletElement.panTo(e.latlng);
  };

  const fitMap = (e, bounds) => {
    mapRef.current.leafletElement.fitBounds(bounds);
  };

  const zoomIn = (e) => {
    mapRef.current.leafletElement.zoomIn();
  };

  const zoomOut = (e) => {
    mapRef.current.leafletElement.zoomOut();
  };

  //
  //
  //   var imageBounds = bounds;
  //
  // const handleMapClickPopup = (e) => {
  //   var popLocation = e.latlng;
  //   var popup = L.popup()
  //     .setLatLng(popLocation)
  //     .setContent("<p>Select the Pan and Zoom tool to move the map</p>")
  //     .openOn(map);
  // };

  // check for touch screen
  // if (L.Browser.touch) {
  //   console.log("L.Browser.touch", L.Browser.touch);
  // }

  // see - http://jsfiddle.net/xfdxe2ea/1/
  // see - https://stackoverflow.com/questions/33569779/lealefjs-prevent-dragging-marker-out-of-imagelayer-bounds
  // see also - https://stackoverflow.com/questions/29736912/leaflet-prevent-marker-to-be-dragged-outside-the-map-container

  // const handleMapClick = (e) => {
  //   var newPosition = e.latlng;

  //   console.log(`handleMapClick newPosition`, newPosition);
  //   console.log(`handleMapClick imageBounds`, imageBounds);

  //   if (imageBounds.contains(newPosition)) {
  //     var newMarker = L.marker(newPosition, { draggable: true }).addTo(
  //       leafletMapRef
  //     );
  //     var lastValidPosition;
  //     newMarker.on(
  //       "drag",
  //       function (event) {
  //         var latLng = newMarker.getLatLng();
  //         if (imageBounds.contains(latLng)) {
  //           lastValidPosition = latLng;
  //         } else {
  //           if (lastValidPosition) {
  //             newMarker.setLatLng(lastValidPosition);
  //           }
  //         }
  //       },
  //       newMarker
  //     );
  //   }
  // };

  //console.log("MapViewState  <Map center", center);

  // Reset the center when load the Map on component mount.
  // If the user moves away from the map the mapView preserves the
  // center and zoom in 'componentstate'.
  // When the map is viewed again the 'mapView' is retrieved during componentDidMount
  // and passed to the component state. The component state takes a cycle to update and
  // when this is changed it tries to re-render the <Map ../>.
  // However center and zoom are immutable and can not be changed after draw.
  // There is a solution to apply .setView with react-leaflet v3
  // see - https://stackoverflow.com/questions/65894789/react-leaflet-map-center-not-changing
  // see - https://stackoverflow.com/questions/65322670/change-center-position-of-react-leaflet-map
  // see - https://stackoverflow.com/questions/65171337/how-to-get-map-properties-and-handle-events-in-leaflet-v3-with-react-redux
  // ....
  //   class MapComponent extends Component {
  //     constructor(props) {
  //     super(props);
  //     this.mapRef = React.createRef();
  // }
  // render() {
  // return (
  //    <div>
  //        <MapContainer whenCreated={ mapInstance => { this.mapRef.current =mapInstance }}>
  //        </MapContainer>
  //        <AnotherComponent map = {this.mapRef}/>
  //    </div>}
  // }

  // class AnotherApp extends React.Component {
  //      //access the map with:
  //      this.props.map
  // }
  // ....
  //
  // However, we are using react-leaflet v2 here (mainly because we use leaflet-draw and andrewDodd's components
  // needs to be re-done).
  //
  // This 'hack' solution
  // See - https://stackoverflow.com/questions/65763931/react-leaflet-update-map-center
  // i.e.
  // "....Only addition to your code is the key prop, which takes the stringified center position.
  // So when the position changes, another instance of the MapContainer component is created
  //  (a fresh copy ;) ), having the proper center value. ..."
  //

  const mapKey = `${mapViewState?.id}-${JSON.stringify(
    mapViewState?.center
  )}-[${mapViewState?.zoom}]`;

  return (
    <Map
      style={newStyle}
      key={mapKey} // #HACK -  if center changes change the key to force a re-render ???
      ref={mapRef}
      crs={L.CRS.Simple} // MySimple // not dynamic
      bounds={bounds} // sets initial viewport only
      // #NOTE - do not set center and zoom here as it clobbers setting in useEffect
      //center={center}
      //zoom={zoom}
      maxBounds={bounds.pad(0.5)}
      minZoom={minZoom}
      maxZoom={maxZoom}
      // #NOTE - in setView(point, zoom), zoom will only go to the resolution designated by zoomSnap.
      // see - https://stackoverflow.com/questions/61233935/leaflet-setview-zoom-level-to-non-integer-decimal-value-in-r
      zoomSnap={0.01}
      onZoomEnd={handleMoveZoomEnd}
      onMoveEnd={handleMoveZoomEnd}
      zoomControl={!isPanZoomButton} // disable double click zoom
      doubleClickZoom={false}
      //onMouseDown={() => console.log("hhh onMouseDown!")}
      //onDragStart={() => console.log("hhh onDragging!")}
      //onClick={handleMapClickPopup}
      //onClick={handleMapClick}
      //onlayeradd={handleOnlayeradd}
      //
      attributionControl={false} // not dynamic
      //
      touchExtend={false}
      //
      contextmenu={true} // context menu plugin `npm i leaflet-contextmenu`
      contextmenuWidth={140}
      contextmenuItems={[
        // {
        //   text: "Show coordinates",
        //   callback: showCoordinates,
        // },
        {
          text: "Center map here",
          callback: centerMap,
        },
        {
          text: "Show all",
          callback: (e) => fitMap(e, bounds),
        },
        // {
        //   text: "Reset View",
        //   callback: resetView,
        // },
        "-",
        {
          text: "Zoom in",
          icon: process.env.PUBLIC_URL + "/images/zoom-in.png",
          callback: zoomIn,
        },
        {
          text: "Zoom out",
          icon: process.env.PUBLIC_URL + "/images/zoom-out.png",
          callback: zoomOut,
        },
      ]}
      // scroll wheel zoom smoothing
      scrollWheelZoom={false} // disable original zoom function
      smoothWheelZoom={true} // enable smooth zoom
      smoothSensitivity={1.5} // zoom speed. default is 1
    >
      <ImageOverlay url={url} bounds={bounds} pane={"tilePane"} />
      {props.children}
    </Map>
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */

  // console.log("areEqual prevProps", prevProps);
  // console.log("areEqual nextProps", nextProps);
  // console.log("areEqual nextProps === prevProps", nextProps === prevProps);
  // console.log(
  //   "areEqual nextProps.imageInfo === prevProps.imageInfo",
  //   JSON.stringify(nextProps.imageInfo) !== JSON.stringify(prevProps.imageInfo)
  // );

  //console.log("simplify nextProps", nextProps);
  //console.log("simplify(nextProps.children)", simplify(nextProps.children));

  // console.log(
  //   "simplify reactChildrenToFlatArray(nextProps.children)",
  //   reactChildrenToFlatArray(nextProps.children)
  // );

  return (
    reactChildrenToFlatArray(prevProps.children) ===
    reactChildrenToFlatArray(nextProps.children)
  );
}

// Note: About React.memo, Maps & Redux-form
// Redux-Form updates form props via Redux (as the name implies), this effectively updates the maps
// props too and causes a re-render. Re-rendering while dragging markers causes a 'snap' back to the last
// prop/state saved. This significantly impedes usabililty.
//
// https://stackoverflow.com/questions/59280319/react-memo-rerendering-with-the-same-props
//
// The solution below is to use React.memo wrapped around the map functional component
// to stop re-rendering of the component unless the image, or the marker pointers, have changed.
// Markers are passed as children.
//
// This requires a deep, flattened, comparison of the children passed to the component.
// If these are not flattened an object comparison will just suggest they're always
// being on each re-render making the comparison pointless.
//
// #REVIEW - move functions above to support utils?

// #WIP - disabled 8Feb21 as stopped NamedAreaSimple from re-rendering

export const MineLevelMap = React.memo(_MineLevelMap, areEqual);

export function _MineLevelMap({ ...props }) {
  const { error } = props.mineLevel;

  if (error) {
    return <div>{error}</div>;
  }

  //console.log("MineLevelMap re-render", new Date().getTime());

  return <SimpleCRSImageMap {...props}>{props.children}</SimpleCRSImageMap>;
}

const MineLevelMapWithContext = withMineLevel(MineLevelMap);

export default MineLevelMapWithContext;

function MineLevelMapViaContext({ mineLevel, ...props }) {
  // mapSettings
  return (
    <MineLevelContext.Provider
      value={{ mineLevel: mineLevel }} // mapSettings: mapSettings
    >
      <MineLevelMapWithContext {...props} />
    </MineLevelContext.Provider>
  );
}

const mapStateToProps = (state, props) => {
  let mineLevel = getAreaById(state, props.mineLevelId); //"DMLZ_Extraction"; // getMineLevelById(state, props.mineLevelId);
  if (mineLevel === undefined) {
    mineLevel = { error: "Unable to find area" };
  }

  // check if file exists
  const folderFilesList = getFolderFileNamesListById(state, "areas");

  mineLevel.url = imageCheck(
    folderFilesList,
    mineLevel?.image_filename,
    mineLevel?.image_Info
  );

  return {
    mineLevel,
  };
};

export const MineLevelMapById = withComponentStateCache(
  connect(mapStateToProps, { saveUserSettingsComponentState })(
    MineLevelMapViaContext
  )
);
