// see - src/components/RegionPreview/reducer.js
// see main reducer at src/reducers.js
//

import Immutable from "seamless-immutable";
import * as is from "is_js";

// isBrowerOfLimitedFunctionality
// flag limits features in UI which are not available to some browsers i.e. IE11
//
let initialState = Immutable({
  isBrowerOfLimitedFunctionality: false,
});

export default function reduce(state = initialState, action = {}) {
  // copy existing state
  const newState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case "CHECK_BROWSER":
      is.ie()
        ? (newState.isBrowerOfLimitedFunctionality = true)
        : (newState.isBrowerOfLimitedFunctionality = false);
      return newState;
    case "RESET":
      console.log("RESET! - CheckBrowser");
      return initialState; //Always return the initial state

    default:
      return state;
  }
}
