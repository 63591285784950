//#middleware

import { getUserSettings, getEmail } from "auth/reducer";

export const updateUserSettings = (store) => (next) => (action) => {
  let newAction = action;

  switch (action.type) {
    case "SAVE_USER_SETTINGS_COMPONENT_STATE":
      const state = store.getState();

      //console.log("xxx middleware action.payload", action.payload);

      const { settings } = action.payload;

      const userId = getEmail(state);
      let userSettings = { ...getUserSettings(state) };

      let newComponentState = [];
      if (userSettings?.componentState) {
        newComponentState = [...userSettings.componentState];
      }

      // remove any existing componentState record with same 'key' and 'section'
      newComponentState = newComponentState?.filter?.(
        (compState) =>
          compState.key !== settings.key &&
          compState.section !== settings.section
      );
      newComponentState.push(settings);

      userSettings.componentState = newComponentState;

      //console.log("xxx middleware userId", userId);
      //console.log("xxx middleware userSettings", userSettings);
      //console.log("xxx middleware newComponentState", newComponentState);

      store.dispatch({
        type: "CHANGE_USER_SETTINGS_COMPONENT_STATE",
        payload: {
          id: userId,
          settings: userSettings,
        },
      });

      break;
    default:
      break;
  }
  return next(newAction);
};
