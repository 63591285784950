export function login({ email, password }) {
  return { type: "LOGIN_REQUEST", payload: { email, password } };
}

export function logout() {
  return { type: "LOGOUT_REQUEST" };
}

export function checkAuth() {
  return { type: "CHECK_AUTH" };
}

export function reset() {
  return { type: "RESET" };
}
