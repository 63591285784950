import Immutable from "seamless-immutable";

const initialState = Immutable([]);

export default function reduce(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case "SHOW_AREA_PREVIEW":
      return Immutable(payload);
    case "CLEAR_AREA_PREVIEW":
    case "STOP_AREA_PREVIEW":
      return initialState;
    case "ADD_AREA_PREVIEW":
      // console.log(`xxxx  ADD_AREA_PREVIEW payload`, payload);
      return Immutable(state.asMutable().concat([payload]));
    case "ADD_ALL_AREA_PREVIEW":
      //console.log(`xxxx ADD_ALL_AREA_PREVIEW payload`, payload);
      let newState = [...state];
      payload.forEach((element) => {
        // console.log(`xxxx element`, element);
        // console.log(`xxxx newState`, newState);
        if (!newState.find((preview) => preview?.spec === element?.spec)) {
          newState.push(element);
        }
      });
      // console.log(`xxxx newState`, newState);
      return Immutable(newState);
    case "REMOVE_AREA_PREVIEW":
      //console.log(`xxxx REMOVE_AREA_PREVIEW payload`, payload);
      return Immutable(
        state.asMutable().filter((preview) => preview?.spec !== payload?.spec)
      );
    default:
      return state;
  }
}

export const getRegionPreviewList = (state) => {
  return state.regionPreview;
};

export const getRegionPreviewListByAreaId = (state, id) => {
  return state.regionPreview?.filter?.((preview) => preview.id === id);
};
