import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Dimmer, Loader } from "semantic-ui-react";

import { v4 as uuid } from "uuid";

import _isEmpty from "lodash/isEmpty";

import {
  getNamedAreaParentStatusesByAreaId,
  getAreaStatusesById,
} from "components/WebWorker/reducer";
import EditNamedAreaGroupForm from "admin/named-area-group/NamedAreaGroupEditForm";
import SubmissionModal from "admin/SubmissionModal";
import NamedAreaGroupPostSaveOptionsModal from "admin/named-area-group/NamedAreaGroupPostSaveOptionsModal";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import {
  updateNamedAreaGroup,
  updateNamedAreaButtonsByParentIdMqtt,
} from "NamedAreaGroups/actions";

import {
  SubmissionError,
  //
  // getFormValues,
  // getFormInitialValues,
  // getFormSyncErrors,
  // getFormMeta,
  // getFormAsyncErrors,
  // getFormSyncWarnings,
  // getFormSubmitErrors,
  // getFormError,
  // getFormNames,
  // isDirty,
  // isPristine,
  // isValid,
  // isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  //  hasSubmitFailed,
} from "redux-form";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { messageToken } from "utils/messageToken";
import { mqttPublish } from "components/WebWorker/actions";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { isMqttNotApiPost } from "components/ConfigJs";

import { getAllNamedAreaStatuses } from "components/WebWorker/reducer";
import { sendNamedAreaButtonUpdateByParent } from "components/WebWorker/sendNamedAreaButtonUpdateByParent";
import {
  saveNewNamedArea, //deleteNamedArea
} from "NamedAreas/actions";

class EditNamedAreaGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      initialValues: {},
      submitted: false,
      saveSuccess: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    //console.log("this.getInitialValues()", this.getInitialValues());
    this.setState({ initialValues: this.getInitialValues() });
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  getInitialValues = () => {
    const { areaId, namedAreaParents, namedAreaGroup } = this.props;
    //console.log(`xxx namedAreaParents`, namedAreaParents);
    const namedAreas = namedAreaParents.filter(
      (na) =>
        na.type.toLowerCase() === "polygon" &&
        na.sub_type?.toLowerCase() === "region"
    );

    //console.log(`getInitialValues`, namedAreas);

    let listOfNamedAreas = [];
    namedAreas.map((namedArea) => {
      listOfNamedAreas.push({
        id: uuid(),
        label: `${namedArea.namedArea}`,
        namedArea: {
          id: namedArea.id,
          priority: 1,
          label: `${namedArea.namedArea}`,
        },
      });
    });

    const initialValues = {
      mineLevelId: areaId,
      listOfNamedAreas,
      data: namedAreaGroup,
    };

    return initialValues;
  };

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );

    const { goto, submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      goto("/");
    }
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  setItemsPriority = (items) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and replace object by id

    // keep a record of the buttons/priority to be defined for named areas
    let namedAreaParentsChanges = {};
    // e.g.
    // Indicates the button (i.e. event) settings for particular priorities. Other priorities can be empty objects {}.
    // namedAreaParentsChanges = {
    //   "DMLZ_Extraction:AREA_A": {
    //     5: {
    //       priority: 0,
    //       id: "",
    //       named_area: "",
    //       clickable: true,
    //       icon: "icon-earthquake0",
    //       title: "Seismic 0",
    //       alt: "Level 0",
    //       color: "green",
    //       group: 0,
    //       type: "SEISMIC0_EVENT",
    //       hint: "Level 0",
    //       default: true,
    //       active_state: "on",
    //       active_color: "green",
    //       state: "on",
    //       on_time: 0,
    //       off_time: 0,
    //       train: 0,
    //       active: true,
    //     },
    //     7: {
    //       priority: 0,
    //       id: "",
    //       named_area: "",
    //       ...........
    //       train: 0,
    //       active: true,
    //     },
    //   },
    // };

    let newItems = JSON.parse(JSON.stringify(items));
    //console.log("setItemsPriority newItems", newItems);

    let priorityBase = 1;

    for (let indexGroup = newItems.length - 1; indexGroup >= 0; indexGroup--) {
      const group = newItems[indexGroup];
      //console.log("setItemsPriority group", group);
      const { subItems: levels } = group;

      if (levels !== undefined) {
        for (
          let indexLevel = levels.length - 1;
          indexLevel >= 0;
          indexLevel--
        ) {
          const level = levels[indexLevel];
          //console.log("setItemsPriority level", level);
          const { subItems: namedAreas } = level;
          if (namedAreas !== undefined) {
            for (
              let indexNamedArea = namedAreas.length - 1;
              indexNamedArea >= 0;
              indexNamedArea--
            ) {
              const namedArea = namedAreas[indexNamedArea];
              newItems[indexGroup].subItems[indexLevel].subItems[
                indexNamedArea
              ].namedArea.button.priority = priorityBase;

              const temp =
                namedAreaParentsChanges[namedArea.namedArea.id] || {};
              temp[priorityBase] = namedArea.namedArea.button;
              namedAreaParentsChanges[namedArea.namedArea.id] = temp;

              // console.log(
              //   "save setItemsPriority namedArea ... update priority",
              //   priorityBase,
              //   namedAreaParentsChanges,
              //   namedArea.namedArea.id
              // );
              priorityBase++;
            }
          }
        }
      }
    }

    return { newItems, namedAreaParentsChanges };
  };

  submitMyForm = (values) => {
    //console.log(`submitMyForm values`, values);

    const { mineLevelId, data } = values;

    const { namedAreaParents, namedAreaStatuses } = this.props;

    const { newItems, namedAreaParentsChanges } = this.setItemsPriority(data);

    // console.log(
    //   "EditNamedAreaGroupForm  onSubmit namedAreaParents",
    //   namedAreaParents
    // );

    let buttonChanges = [];

    // #WIP - add a button to all namedAreas
    namedAreaParents.forEach((parent) => {
      const { id, type } = parent;

      // console.log("id", id);
      // console.log("namedAreaParentsChanges", namedAreaParentsChanges);

      if (type.toLowerCase() === "polygon") {
        const buttons = namedAreaParentsChanges[id];
        if (buttons !== undefined) {
          // console.log("buttons", buttons);

          const setButtonsByPriority = Object.keys(buttons).sort();
          // console.log("setButtonsByPriority", setButtonsByPriority);

          const maxPriority = Math.max(...setButtonsByPriority);
          // console.log("maxPriority", maxPriority);

          let newButtons = [];
          for (let index = 0; index < maxPriority + 1; index++) {
            if (setButtonsByPriority.includes(index.toString())) {
              newButtons[index] = buttons[index];
            } else {
              // #WIP #TODO - move button template elsewhere

              newButtons[index] = {
                id: uuid(),
                named_area: id,
                clickable: false,
                icon: "icon-earthquake0",
                priority: index,
                title: "Seismic 0",
                alt: "Level 0",
                color: "green",
                group: 0,
                type: "SEISMIC0_EVENT",
                hint: "Level 0",
                default: true,
                active_state: "on",
                active_color: "green",
                state: "on",
                on_time: 0,
                off_time: 0,
                train: 0,
                active: false,
                marker: "RoundMarker",
              };
            }
          }

          buttonChanges.push({ parentId: id, buttons: newButtons });

          //console.log("newButtons", newButtons);

          //this.props.updateNamedAreaButtonsByParentId()
          // const { id, type, settings } = changeSetting;
          // const namedAreaPayload = {
          //   topic: `named_area/${namedAreaMsg.id}/change`,
          //   qos: 0,
          //   message: namedAreaMsg,
          //   retained: false,
          // };
          // this.props.updateNamedAreas({ newValues, resolve, reject})

          //console.log("save namedAreaParents", parent);
        }
      }
      //console.log("buttonChanges", buttonChanges);
    });

    // #NOTE - create a function
    // see - src/admin/named-area-simple/NamedAreaSimpleList.js
    // get current area info
    const {
      id,
      image_filename,
      image_info,
      slug,
      coordinatesUtm,
      ref_coord,
      floor,
      ceiling,
      default_color,
      default_state,
    } = this.props.area;

    let bounds = [];

    // protect against no coordinates set
    if (!_isEmpty(coordinatesUtm)) {
      if (coordinatesUtm[0].length === 4) {
        bounds = JSON.parse(JSON.stringify(coordinatesUtm[0])); // #NOTE - area is a single polygon (not multi)
        const firstPoint = bounds[0];
        bounds.push(firstPoint);
      }
    }

    // create new Area with updated namedAreaGroup
    const newArea = {
      id,
      image_filename,
      image_info: JSON.stringify(image_info),
      slug,
      bounds: bounds,
      ref_coord,
      floor,
      ceiling,
      default_color: default_color.toUpperCase(),
      default_state: default_state.toUpperCase(),
      named_area_group: JSON.stringify(newItems),
    };

    const newValues = { id: id, data: newArea };
    //console.log("newValues", newValues);

    let promiseArray = [];

    // with changes to button states, resend the button structure for the namedAreas
    //
    buttonChanges.forEach((change) => {
      const { parentId, buttons } = change;

      // if MQTT msg for 'named_area/id/change', then prepare and publish a mqtt message
      if (isMqttNotApiPost()) {
        this.props.updateNamedAreaButtonsByParentIdMqtt({
          parentId,
          buttons,
        });
        // else if API POST then send post a /change_named_area message
      } else {
        const publishChanges = sendNamedAreaButtonUpdateByParent(
          namedAreaStatuses,
          parentId,
          buttons
        );
        publishChanges.forEach((change) => {
          // this is destructured b/c reusing a message intended to be published 'message' via mqtt
          const { message } = change;

          promiseArray.push(
            new Promise((resolve, reject) =>
              this.props.saveNewNamedArea({
                values: message,
                resolve,
                reject,
              })
            )
          );
        });
      }
    });

    promiseArray.push(
      new Promise((resolve, reject) =>
        this.props.updateNamedAreaGroup({
          values: newValues,
          resolve,
          reject,
        })
      )
    );

    return Promise.all(promiseArray)
      .then((results) => {
        console.log(".... change named areas.", results);
      })
      .then(() => {
        console.log("CLEAR ALL SYSTEM EVENTS - system/event/clear");
        // after namedAreaGroup has changed, clear the existing events.

        //
        // #WIP
        // - this needs to change to clear only defined AREAS
        // - change to API which can be resolved
        // -
        this.props.mqttPublish({
          topic: `system/event/clear`,
          qos: 0,
          message: {
            clear_events: true,
            token: messageToken(),
          },
          retained: false,
        });

        this.setState({ saveSuccess: true });
      })
      .then(() => {
        if (false) {
          // at this stage, pop a dlg informing the user that they need to reset the control screen polygons
          const message = {
            header: {
              icon: "warning",
              title: "Review Lighting Controls",
            },
            content:
              "You have saved changes to the Lighting Layout. Saving changes to the Control Layout will clear all current light states for all Areas! ---#WIP--THIS SHOULD POPUP A MODAL INSTEAD!",
          };
          this.setState({
            errorMessage:
              "Re-enable all lights on polygons after saving changes to the Control layout.",
            modalMessage: message,
          });
          //this.onResetError();
          //this.props.goto("/");
        }
      })
      .catch((error) => {
        console.log(`onSubmit error`, error);
        this.onSetError("failed");
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  render() {
    const { error, submitting, areaId, strings, userSettings } = this.props;
    const { modalMessage, errorMessage, initialValues } = this.state;

    //console.log("xxx EditNamedAreaGroup - strings", strings);

    const openModal = errorMessage !== "";

    if (error) {
      return (
        <>
          <DataLoadingMessage id={areaId} strings={strings} />
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    return (
      <>
        <NamedAreaGroupPostSaveOptionsModal
          open={this.state.saveSuccess}
          onResetError={() => this.onResetError()}
          id={areaId}
        />
        <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          modalMessage={modalMessage}
          errorMessage={errorMessage}
        />
        <Dimmer active={submitting} page>
          <Loader>Saving changes</Loader>
        </Dimmer>
        <NavigationPromptModal
          formName={"editNamedAreaGroupForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <EditNamedAreaGroupForm
          initialValues={initialValues}
          onSubmit={(values) => this.submitMyForm(values)}
          strings={strings}
          userSettings={userSettings}
          // #NOTE - disabled as this is fired as soon as submit,
          // *not* after successful completion of the Promise!!
          // onSubmitSuccess={(result, dispatch) => {
          //   //console.log(`onSubmitSuccess ${result}`);
          //   // wait for state change before redirecting page
          //   this.setState(
          //     {
          //       submitted: true,
          //     },
          //     () => {
          //       // at this stage, pop a dlg informing the user that they need to reset the control screen polygons
          //       const message = {
          //         header: {
          //           icon: "warning",
          //           title: "Review Lighting Controls",
          //         },
          //         content:
          //           "You have saved changes to the Lighting Layout. Saving changes to the Control Layout will clear all current light states for all Areas! ",
          //       };
          //       this.setState({
          //         errorMessage:
          //           "Re-enable all lights on polygons after saving changes to the Control layout.",
          //         modalMessage: message,
          //       });
          //       //this.onResetError();
          //       //this.props.goto("/");
          //     }
          //   );
          // }}
          onSubmitFail={(errors) => {
            console.log(`onSubmitFail ${errors}`);
          }}
          onCancel={() => {
            this.props.goto("/admin/named-area-group");
          }}
        />
      </>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mqttPublish: (msg) => dispatch(mqttPublish(msg)),
    goto: (path) => dispatch(push(path)),
    updateNamedAreaGroup: (data) => dispatch(updateNamedAreaGroup(data)),
    updateNamedAreaButtonsByParentIdMqtt: (data) =>
      dispatch(updateNamedAreaButtonsByParentIdMqtt(data)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)),
  };
};

//export default connect(null, { updateMineLevel })(EditMineLevel);

function mapStateToProps(state, props) {
  const match = props.match;
  const id = match.params.id;
  // const nameArray = id.split(":");
  // const mineLevelId = nameArray[0];

  // #REVIEW - this code is used in a few places
  // let name;
  // if (nameArray[1] === undefined) {
  //   // for edit form use namedArea name (i.e. not `Level Wide (${nameArray[0]})`)
  //   name = nameArray[0];
  // } else {
  //   name = nameArray[1];
  // }

  const namedAreaParents = getNamedAreaParentStatusesByAreaId(state, id);
  const area = getAreaStatusesById(state, id);
  const namedAreaGroup = area?.namedAreaGroup || [];

  // console.log("NamedAreaGroupEditPage namedAreaGroup", namedAreaGroup);
  // console.log("NamedAreaGroupEditPage namedAreaParents", namedAreaParents);

  return {
    areaId: id,
    namedAreaParents,
    namedAreaGroup,
    area,
    namedAreaStatuses: getAllNamedAreaStatuses(state),
    submitting: isSubmitting("editNamedAreaGroupForm")(state),
    submitSucceeded: hasSubmitSucceeded("editNamedAreaGroupForm")(state),

    // error: namedAreaGroup === undefined ? "Named Area Group not found" : null,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNamedAreaGroup);
