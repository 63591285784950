import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import FireflyList from "admin/firefly/FireflyList";
import FireflyDetail from "admin/firefly/FireflyDetail";
import { fetchFireflys } from "UPSPanelControllers/actions";
import { isDeleteFireflyNotCommissioned } from "components/ConfigJs";

class FireflyAdminPages extends Component {
  componentDidMount() {
    // const { fetchFireflys } = this.props;
    // // #FETCHS
    // fetchFireflys(); // #FETCHS
  }

  render() {
    const { match, strings } = this.props;

    return (
      <Switch>
        {/* <Route path={`${match.url}/:id`} component={FireflyDetail} /> */}
        <Route
          path={`${match.url}/:id`}
          render={(props) => (
            <FireflyDetail
              {...props}
              isDeleteFireflyNotCommissioned={isDeleteFireflyNotCommissioned()}
              strings={strings}
            />
          )}
        />
        {/* <Route exact path={match.url} component={FireflyList} /> */}
        <Route
          path={match.url}
          render={(props) => (
            <FireflyList
              {...props}
              isDeleteFireflyNotCommissioned={isDeleteFireflyNotCommissioned()}
              strings={strings}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(connect(null, { fetchFireflys })(FireflyAdminPages));
