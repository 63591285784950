import React from "react";
import DeviceName from "components/DeviceName";
import FireflyLink from "admin/firefly/FireflyLink";
import FireflyLightChangeStatus from "../admin/firefly/FireflyLightChangeStatus";

import { connect } from "react-redux";
//import { getFireflyById } from "UPSPanelControllers/reducer";
import { getFireflyById } from "components/WebWorker/reducer";
import { isUserSuper, isUserAdmin } from "auth/reducer";

import { Icon } from "semantic-ui-react";

const Component = ({ includeLink, ...props }) => {
  if (includeLink) {
    return (
      <div>
        <div style={{ float: "left" }}>
          <FireflyLink id={props.id}>
            <DeviceName {...props} />
          </FireflyLink>
        </div>
        {/* <div style={{ float: "right" }}>
          <FireflyLink id={props.id}>
            <Icon name="cog"></Icon>
          </FireflyLink>
        </div> */}
        <div style={{ clear: "both", paddingTop: "6px" }}>
          {/* <small>
            <FireflyLightChangeStatus id={props.id} />
          </small> */}
        </div>
      </div>
    );
  }
  return <DeviceName {...props} />;
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const obj = getFireflyById(state, id);

  if (obj) {
    const statusTimeReceived = obj.lastStatusReport
      ? obj.lastStatusReport.timeReceived
      : "";
    const statusColor = obj.lastStatusReport
      ? obj.lastStatusReport.light.color
      : "";
    const cmdTimeSent = obj.lastCommand ? obj.lastCommand.updated : "";
    const cmdColor = obj.lastCommand ? obj.lastCommand.settings.color : "";

    return {
      id,
      name: obj.name,
      includeLink: isUserSuper(state) || isUserAdmin(state),
      statusTimeReceived,
      statusColor,
      cmdTimeSent,
      cmdColor,
    };
  }
  return {
    id,
    error: "no FireFly for id",
  };
};

export default connect(mapStateToProps)(Component);
