import React from "react";
import { connect } from "react-redux";

import { getControllerById } from "components/WebWorker/reducer";

import {
  formatDate,
  formatDateRelativeNow,
  parseDate,
} from "utils/format-date";

function Component({ statusReport }) {
  let updatedStatusReport = { ...statusReport };

  if (statusReport) {
    const { timestamp } = statusReport;

    let timeRxd;
    if (timestamp !== undefined) {
      timeRxd = parseDate(timestamp);
      timeRxd =
        formatDate(timeRxd) +
        " (" +
        formatDateRelativeNow(timeRxd, new Date()) +
        ")";
    } else {
      timeRxd = "No time information";
    }

    updatedStatusReport = { ...statusReport, timestamp: timeRxd };
  } else {
    updatedStatusReport = null;
  }

  return <pre>{JSON.stringify(updatedStatusReport, null, 2)}</pre>;
}

export default connect((state, { id }) => {
  const controller = getControllerById(state, id);
  return { statusReport: controller };
})(Component);
