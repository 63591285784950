import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";
import GenericButton from "admin/GenericButton";

import { fetchNamedAreas } from "NamedAreas/actions";
//import { getAllNamedAreas } from "NamedAreas/reducer";

import {
  getAllNamedAreaStatuses,
  getAllAreaStatuses,
} from "components/WebWorker/reducer";
import { deleteNamedArea } from "NamedAreas/actions";
import { updateNamedAreaGroup } from "NamedAreaGroups/actions";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class ListNamedAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        namedArea: "",
        area: "",
        id: "",
        subType: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
    };
  }

  componentDidMount() {
    //const { data, fetchNamedAreas } = this.props;
    // if (_isEmpty(data)) {
    //   // #FETCHS
    //   fetchNamedAreas(); // #FETCHS
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  // #NOTE - create a function
  // src/admin/named-area-group/NamedAreaGroupTableItems.js
  deleteItemByNamedAreaParentId = (items, id) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and return !== id

    let newItems = [];

    let i = 0;
    // groups
    items.forEach((item) => {
      // levels - subItems
      let newSubItem = [];
      let j = 0;
      item.subItems.forEach((item) => {
        // named areas - subSubItems
        let newSubSubItem = [];
        let k = 0;
        item.subItems.forEach((item) => {
          if (item.namedArea.id !== id) {
            newSubSubItem[k] = item;
            k = k + 1;
          }
        });
        newSubItem[j] = item;
        newSubItem[j].subItems = newSubSubItem;
        j = j + 1;
      });
      newItems[i] = item;
      newItems[i].subItems = newSubItem;
      i = i + 1;
    });

    return newItems;
  };

  // #NOTE - create a function
  // see - src/admin/named-area-group/NamedAreaGroupEditPage.js

  newNamedAreaGroup = (allAreas, allNamedAreas, namedAreaId) => {
    const namedArea = allNamedAreas.find(
      (namedArea) => namedArea.id === namedAreaId
    );
    const { area: areaId, parent: parentId } = namedArea;

    if (_isEmpty(areaId)) {
      return null;
    }

    const area = allAreas.find((area) => area.id === areaId);

    if (_isEmpty(area)) {
      return null;
    }

    // get current area info
    const {
      id,
      image_filename,
      image_info,
      slug,
      coordinatesUtm,
      ref_coord,
      floor,
      ceiling,
      default_color,
      default_state,
      namedAreaGroup,
    } = area;

    // update namedAreaGroup - delete current namedArea if exists
    if (_isEmpty(namedAreaGroup)) {
      return null;
    }

    const cloneItems = !_isEmpty(namedAreaGroup)
      ? JSON.parse(JSON.stringify(namedAreaGroup))
      : [];

    const newItems = this.deleteItemByNamedAreaParentId(cloneItems, parentId);

    let bounds = JSON.parse(JSON.stringify(coordinatesUtm[0])); // #NOTE - area is a single polygon (not multi)
    const firstPoint = bounds[0];
    bounds.push(firstPoint);

    // create new Area with updated namedAreaGroup
    const newArea = {
      id,
      image_filename,
      image_info: JSON.stringify(image_info),
      slug,
      bounds: bounds,
      ref_coord,
      floor,
      ceiling,
      default_color: default_color.toUpperCase(),
      default_state: default_state.toUpperCase(),
      named_area_group: JSON.stringify(newItems),
    };

    const newValues = { id: id, data: newArea };

    return newValues;
  };

  requestAction = (action) => {
    const { selections } = this.state;
    const {
      allNamedAreas,
      allAreas,
      deleteNamedArea,
      updateNamedAreaGroup,
      push,
    } = this.props;

    let namedAreas = [];
    for (var key in selections) {
      const selection = key;
      allNamedAreas.forEach((namedArea, idx) => {
        const { id, parent } = namedArea;
        // selections are parents, so match whole parent. eg."DMLZ_Extraction:1234" in "DMLZ_Extraction:1234:1604620816915"
        if (parent === selection) {
          namedAreas.push(id);
        }
      });
    }

    let promiseArray = [];

    namedAreas.forEach((namedArea) => {
      promiseArray.push(
        new Promise((resolve, reject) =>
          deleteNamedArea({ values: { id: namedArea }, resolve, reject })
        )
      );

      const newNamedAreaGroup = this.newNamedAreaGroup(
        allAreas,
        allNamedAreas,
        namedArea
      );

      // in loop because could be named area from different areas
      promiseArray.push(
        new Promise((resolve, reject) =>
          updateNamedAreaGroup({
            values: newNamedAreaGroup,
            resolve,
            reject,
          })
        )
      );
    });

    return Promise.all(promiseArray)
      .then((results) => {
        console.log(".... deleted named areas.", results);
      })
      .then(
        () => {
          push("/admin/named-area-advanced");
        },
        (msg) => {
          console.log("action failed", msg); // TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error } = this.props;
    const { column, data, direction } = this.state;

    // APP_TERMINOLOGY
    const strNamedArea = "Polygon";
    const strNamedAreas = "Polygons";

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "nalist10" },
              { value: 20, text: "20", key: "nalist20" },
              { value: 40, text: "40", key: "nalist40" },
              { value: 60, text: "60", key: "nalist60" },
              { value: data.length, text: "all", key: "nalistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const namedAreaFromDataById = (id) =>
      allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let enableButton;
    let disableButton;
    let deleteButton;
    let namedAreaSelectedList;
    if (selectedCount > 0) {
      let namedAreaNames = [];
      //console.log("data", data);

      // #REVIEW - WIP
      for (var key in selections) {
        if (namedAreaFromDataById(key) !== undefined) {
          let namedAreaName = namedAreaFromDataById(key).namedArea;

          // namedAreaName = namedAreaName.substring(
          //   0,
          //   namedAreaName.indexOf(" (")
          // ); // strip off the trailing ip
          namedAreaNames.push(namedAreaName);
        }
      }
      namedAreaNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      namedAreaNames = namedAreaNames.join(", "); // separate by commas

      enableButton = (
        <GenericButton
          label="Enable"
          color="blue"
          onClick={() => this.requestAction("enable")}
          size="large"
        />
      );
      disableButton = (
        <GenericButton
          label="Disable"
          color="orange"
          onClick={() => this.requestAction("disable")}
          size="large"
        />
      );
      deleteButton = (
        <DeleteButton
          onClick={() => this.requestAction("delete")}
          size="large"
        />
      );

      namedAreaSelectedList = (
        <Segment textAlign="left">
          <strong>{`${strNamedAreas} selected ...`} </strong>
          {namedAreaNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={5} textAlign={"left"}>
                <Header as="h1">{`Advanced Parent ${strNamedAreas}`}</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              <Link to="named-area-advanced/new">
                <Button color="blue" content="Add" size="large" />
              </Link>
              {!areAnySelected && (
                <span>Select items in the table below to apply an action.</span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {selectedCount} selected
                </span>
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    Clear
                  </Button>
                  <Button.Or />
                  {deleteButton}
                  {/* <Button.Or />
                  {enableButton}
                  <Button.Or />
                  {disableButton} */}
                </Button.Group>
              </div>
              <div>
                <span>{viewablesPage.length} items displayed.</span>
              </div>
            </Segment>
            {namedAreaSelectedList}
          </Segment.Group>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="trash" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="namedArea"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="id"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="subType"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="area"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "namedArea" ? direction : null}
                  onClick={this.handleSort("namedArea")}
                >
                  {strNamedArea}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "id" ? direction : null}
                  onClick={this.handleSort("id")}
                >
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "subType" ? direction : null}
                  onClick={this.handleSort("subType")}
                >
                  Type
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "area" ? direction : null}
                  onClick={this.handleSort("area")}
                >
                  Area
                </Table.HeaderCell>
                <Table.HeaderCell width={9}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(viewablesPage, ({ id, namedAreaLink, area, subType }) => (
                <Table.Row key={`row-id-${id}`}>
                  <Table.Cell>
                    <Checkbox
                      checked={this.isItemSelected(id)}
                      onChange={() => this.handleSelect(id)}
                    />
                  </Table.Cell>
                  <Table.Cell>{namedAreaLink}</Table.Cell>
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell>{subType}</Table.Cell>
                  <Table.Cell>{area || "-"}</Table.Cell>
                  <Table.Cell />
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  // list only unique parents
  const uniqueParents = elements
    .map((item) => item.parent)
    .filter((value, index, self) => self.indexOf(value) === index);

  let filteredParents = [];
  uniqueParents.forEach(function (uniqueParent, idx) {
    const namedAreaParentInfo = elements.find(
      (item) => item.parent === uniqueParent
    );

    const {
      area,
      active,
      sub_type,
      parent_name: parentName,
    } = namedAreaParentInfo;

    const id = uniqueParent;
    const nameArray = uniqueParent.split(":");

    // #NOTE
    // This is historical code when LEVEL_WIDE was listed as a named areas for
    // config. ALL_AREAS, LEVEL_WIDE are excluded from the list for now.
    // See comments `exclude ALL_AREAS, LEVEL_WIDE`

    let name;
    if (parentName.length <= 0) {
      if (nameArray.length < 2) {
        // ALL_AREAS, LEVEL_WIDE named area
        name = `Level Wide (${nameArray[0]})`;
        // exclude ALL_AREAS, LEVEL_WIDE
        // filteredParents[idx] = { ....}
      } else {
        name = nameArray[1];
        // compose new data set
      }
    } else {
      name = parentName;
    }

    filteredParents[idx] = {
      id: id,
      active: true, // active,
      namedArea: name,
      namedAreaLink: (
        <Link to={`/admin/named-area-advanced/edit/${id}`}>{name} </Link>
      ),
      subType: sub_type,
      area: area,
    };
  });

  return filteredParents;
};

function mapStateToProps(state) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  //const { isLoading, error } = state.namedAreas;
  const isLoading = false;
  const error = false;

  // exclude ALL_AREAS, LEVEL_WIDE
  const allNamedAreas = getAllNamedAreaStatuses(state).filter(
    (na) => na.type !== "LEVEL_WIDE" && na.type !== "ALL_AREAS"
  );

  const allAreas = getAllAreaStatuses(state);

  const data = _prepData(allNamedAreas);

  return {
    isLoading,
    error,
    data,
    allNamedAreas,
    allAreas,
  };
}

export default connect(mapStateToProps, {
  deleteNamedArea,
  updateNamedAreaGroup,
  push,
  fetchNamedAreas,
})(ListNamedAreas);
