import React, { PureComponent } from "react";
import L from "leaflet";
import {
  Circle,
  Rectangle,
  Polygon,
  Popup,
  // ,
  // Polyline,
  // Marker
} from "react-leaflet";
import getSemanticColor from "colorpalette";

import FireflyName from "containers/FireflyName";

//import TextPath from "react-leaflet-textpath";

const JustId = ({ fireflyId }) => {
  return <FireflyName id={fireflyId} />;
};

const RoundMarker = (props) => <Circle {...props} />;

const SquareMarker = ({ center, radius, ...props }) => {
  const bounds = L.latLng(center).toBounds(radius * 2);
  return <Rectangle bounds={bounds} {...props} />;
};

const BowtieMarker = ({ center, radius, ...props }) => {
  const bounds = L.latLng(center).toBounds(radius * 2);
  const positions = [
    bounds.getNorthWest(),
    bounds.getCenter(),
    bounds.getNorthEast(),
    bounds.getSouthEast(),
    bounds.getCenter(),
    bounds.getSouthWest(),
  ];
  return <Polygon positions={positions} {...props} />;
};

const TriangleMarker = ({ center, radius, ...props }) => {
  const bounds = L.latLng(center).toBounds(radius * 2);
  const c = bounds.getCenter();
  const positions = [
    { ...c, lat: bounds.getNorth() },
    bounds.getSouthEast(),
    bounds.getSouthWest(),
  ];
  return <Polygon positions={positions} {...props} />;
};

const StarMarker = ({ center, radius, ...props }) => {
  const boundsOuter = L.latLng(center).toBounds(radius * 2);
  const boundsInner = L.latLng(center).toBounds(radius * 1);
  const c = boundsOuter.getCenter();
  const positions = [
    { ...c, lng: boundsInner.getEast() },
    boundsOuter.getSouthEast(),
    { ...c, lat: boundsInner.getSouth() },
    boundsOuter.getSouthWest(),
    { ...c, lng: boundsInner.getWest() },
    boundsOuter.getNorthWest(),
    { ...c, lat: boundsInner.getNorth() },
    boundsOuter.getNorthEast(),
    { ...c, lng: boundsInner.getEast() },
  ];

  return <Polygon positions={positions} {...props} />;
};

// https://github.com/makinacorpus/Leaflet.TextPath
// https://leafletjs.com/reference-1.6.0.html#polyline
// npm install leaflet-textpath --save
// example source - https://github.com/makinacorpus/Leaflet.TextPath/blob/master/index.html
// test draws a line from center at 45deg (NE) for 5*radius

// const BowtieMarker_WIP = ({ center, radius, ...props }) => {
//   const bounds = L.latLng(center).toBounds(radius * 2);
//   const positions = [bounds.getCenter(), bounds.getNorthEast()];

//   const danger = L.polyline(
//     { positions },
//     {
//       weight: 10,
//       color: "orange",
//       opacity: 0.8
//     }
//   ).addTo(map);
//   danger.setText("\u25BA", {
//     repeat: true,
//     offset: 6,
//     attributes: { fill: "red" }
//   });

//   return danger;
// return <Polyline positions={positions} color="orange" setText={`help!`} />;
// return <Polyline positions={positions} weight="15" color="#8EE9FF" />;
//};

// react version
// https://www.npmjs.com/package/react-leaflet-textpath
// npm install react-leaflet-textpath --save
// https://github.com/clementallen/react-leaflet-textpath#readme
// const BowtieMarker = ({ center, radius, ...props }) => {
//   const bounds = L.latLng(center).toBounds(radius * 2);
//   const positions = [bounds.getCenter(), bounds.getNorthEast()];

//   return (
//     <TextPath positions={positions} text="Polyline text" center offset={10} />
//   );
// };

// marker using icon
// https://leafletjs.com/reference-1.6.0.html#icon
// https://github.com/PaulLeCam/react-leaflet/issues/355
//https://icons8.com/icons/set/map-marker

// const BowtieMarker_icon = ({ center, radius, ...props }) => {
//   const image = {
//     iconUrl: require("./MapMarkerIcons/marker-50.png"),
//     // shadowUrl: require("../public/marker-shadow.png"),
//     iconSize: [38, 95], // size of the icon
//     shadowSize: [50, 64], // size of the shadow
//     iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
//     shadowAnchor: [4, 62], // the same for the shadow
//     popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
//   };
//   return <Marker position={L.latLng(center)} icon={image} />;
// };

// ******************************

const HourGlassMarker = ({ center, radius, ...props }) => {
  const bounds = L.latLng(center).toBounds(radius * 2);
  const positions = [
    bounds.getNorthWest(),
    bounds.getNorthEast(),
    bounds.getCenter(),
    bounds.getSouthEast(),
    bounds.getSouthWest(),
    bounds.getCenter(),
  ];
  return <Polygon positions={positions} {...props} />;
};

const TravelwayMarker = ({ center, radius, ...props }) => {
  const bounds = L.latLng(center).toBounds(radius * 2);
  const c = bounds.getCenter();
  const positions = [
    bounds.getNorthWest(),
    bounds.getNorthEast(),
    { ...c, lng: bounds.getEast() },
    { ...c, lat: bounds.getSouth() },
    { ...c, lng: bounds.getWest() },
    bounds.getNorthWest(),
  ];
  return <Polygon positions={positions} {...props} />;
};

const DiamondMarker = ({ center, radius, ...props }) => {
  const bounds = L.latLng(center).toBounds(radius * 2 * 1.414);
  const c = bounds.getCenter();
  const positions = [
    { ...c, lng: bounds.getWest() },
    { ...c, lat: bounds.getNorth() },
    { ...c, lng: bounds.getEast() },
    { ...c, lat: bounds.getSouth() },
  ];
  return <Polygon positions={positions} {...props} />;
};

class FireflyMarker extends PureComponent {
  render() {
    const {
      lat,
      lng,
      statusSettings,
      deviceSettings,
      topologySettings,
      commandSettings,
      PopupContent = JustId,
      id,
      error,
    } = this.props;

    let dashArray = null;
    let fillColor = "grey";
    let lineColor = "grey";
    let Marker = DiamondMarker;

    //console.log("FireflyMarkers statusSettings ", statusSettings);
    //console.log("FireflyMarkers commandSettings ", commandSettings);
    //console.log("FireflyMarkers deviceSettings ", deviceSettings);
    //console.log("FireflyMarkers topologySettings", topologySettings);

    // definitions
    const isInCommission =
      topologySettings && topologySettings.mode === "commission" ? true : false;
    const isInTransition =
      commandSettings &&
      statusSettings &&
      commandSettings.color !== statusSettings.color
        ? true
        : false;
    const isStrobing =
      (commandSettings.on_time && commandSettings.off_time) ||
      commandSettings.led_state === "STROBE"
        ? true
        : false;

    if (commandSettings) {
      lineColor = getSemanticColor(commandSettings.color);

      //console.log("isInTransition", isInTransition, "id", id);

      switch (isStrobing) {
        case true:
          isInCommission
            ? (Marker = StarMarker)
            : isInTransition
            ? (Marker = TriangleMarker)
            : (Marker = SquareMarker); // normal state of strobbing firefly markers

          break;
        case false:
          isInCommission
            ? (Marker = StarMarker)
            : isInTransition
            ? (Marker = TriangleMarker)
            : (Marker = RoundMarker); // normal state of firefly markers
          break;
        default:
          break;
      }

      // #REVIEW - keep for ref for moment
      //
      // if (
      //   (commandSettings.on_time && commandSettings.off_time) ||
      //   commandSettings.led_state === "STROBE"
      // ) {
      //   Marker = SquareMarker;
      // } else {
      //   if (isInCommission) {
      //     Marker = StarMarker; // different transition marker
      //   } else if (isInTransition) {
      //     Marker = TriangleMarker; // standard marker
      //   } else {
      //     Marker = RoundMarker; // standard marker
      //   }
      // }
    }

    var status_check = false;

    if (deviceSettings) {
      switch (deviceSettings.status) {
        case "OK":
          if (!error) {
            if (deviceSettings.travelway === true) {
              Marker = TravelwayMarker;
              fillColor = getSemanticColor(statusSettings.color);
              status_check = true;
              lineColor = "purple";
            } else if (statusSettings) {
              fillColor = getSemanticColor(statusSettings.color);
              status_check = true;
            }
          }
          break;

        case "INACTIVE_FIREFLY":
          lineColor = "black";
          fillColor = "fuchsia";
          Marker = BowtieMarker;
          status_check = true;
          break;

        case "UPS_TIMEOUT":
          lineColor = "fuchsia";
          fillColor = "black";
          Marker = BowtieMarker;
          status_check = true;
          break;

        default:
          lineColor = "fuchsia";
          fillColor = "sienna";
          Marker = HourGlassMarker;
          status_check = true;
          break;
      }
    }

    // This is a timeout check that is set in func calcTimeAndStatus() UPSPanelControllers/reducer.js
    if (error && status_check === false) {
      if (error === "MISSING_STATUS") {
        status_check = true;
        lineColor = "black";
        fillColor = "fuchsia";
        Marker = BowtieMarker;
      } else if (error === "OLD_STATUS") {
        status_check = true;
        lineColor = "fuchsia";
        fillColor = "black";
        Marker = BowtieMarker;
      }
    }

    if (statusSettings && status_check === false) {
      fillColor = getSemanticColor(statusSettings.color);
    }

    if (fillColor === "white" && lineColor === "white") {
      lineColor = "darkgrey";
      dashArray = "10";
    }

    return (
      <Marker
        center={{ lat, lng }}
        color={lineColor}
        fillColor={fillColor}
        fillOpacity={1.0}
        radius={5}
        weight={3}
        dashArray={dashArray}
        pane={"shadowPane"}
      >
        <Popup maxWidth={"100%"}>
          <PopupContent fireflyId={id} />
        </Popup>
      </Marker>
    );
  }
}
export default FireflyMarker;
