// HB_15Mar22 - #OLD_CODE review file pending review and removal.
//
// This old code is still used by src/components/StatusChecks/index.js
//

import Immutable from "seamless-immutable";
import isEqual from "lodash/isEqual";
import { minutesAgo, parseDate } from "utils/format-date";

let initialState = Immutable({
  upsById: {},
  travelwayByLocation: {},
  fireflysById: {},
  isCommissioning: false,
  commissioningError: null,
  discoveryRequests: {},
  upsCommandRequests: {},
  advancedConfigRequests: {},
});

/*
function foundNewUPS (statusReport) {
  return Immutable({
    id: statusReport.id,
    created: new Date(),
    lastStatusReport: statusReport,
    position: {}
  })
}

function foundNewFirefly (statusReport) {
  return Immutable({
    id: statusReport.id,
    created: new Date(),
    lastStatusReport: statusReport,
    position: {},
    topology: statusReport.topology
  })
}
*/

function makeHasChanged(item, fields) {
  return function (a, b) {
    for (let i = 0; i < fields.length; i++) {
      const k = fields[i];
      if (a[item][k] !== b[item][k]) {
        return true;
      }
    }
    return false;
  };
}

export const hasTopologyChanged = makeHasChanged("topology", [
  "upsId",
  "position",
  "portNumber",
]);
export const hasPositionChanged = makeHasChanged("position", [
  "mineLevelId",
  "lat",
  "lng",
]);

function calcTimeAndStatus(payload) {
  if (payload.lastStatusReport) {
    payload = Immutable.set(
      payload,
      "lastStatusReceived",
      parseDate(payload.lastStatusReport.timeReceived)
    );
  }

  // console.log("FF status " + payload.device.status )

  if (payload.lastStatusReceived) {
    if (minutesAgo(payload.lastStatusReceived) > 14) {
      /*14*/
      //console.log("Timeout error Error " + payload.lastStatusReceived )

      payload = Immutable.set(payload, "statusError", "OLD_STATUS");
    } else {
      payload = Immutable.set(payload, "statusError", "");
    }
  } else {
    payload = Immutable.set(payload, "statusError", "MISSING_STATUS");
  }

  return payload;
}

function isNotCommissioned(ff) {
  return !ff || !ff.position;
}

function handleFFUpdate(state, payload) {
  payload = calcTimeAndStatus(Immutable(payload));

  const existing = state.fireflysById[payload.id];
  // If existing does exist, but has no position, we won't be rendering
  // it or controlling it so update it verbatim?
  if (isNotCommissioned(existing)) {
    return state.setIn(["fireflysById", payload.id], payload);
  }

  if (existing.topology && existing.position) {
    const topo = hasTopologyChanged(existing, payload);
    const position = hasPositionChanged(existing, payload);
    const name = existing.name !== payload.name;
    if (topo || position || name) {
      return state.setIn(["fireflysById", payload.id], payload);
    }
  }

  let newState = state;
  if (!isEqual(existing.lastCommand, payload.lastCommand)) {
    newState = newState.setIn(
      ["fireflysById", payload.id, "lastCommand"],
      payload.lastCommand
    );
  }

  // likely just the status report updated
  newState = newState.setIn(
    ["fireflysById", payload.id, "lastStatusReport"],
    payload.lastStatusReport
  );
  return newState;
}

function updateAllFFStatus(state) {
  let fireflysById = state.fireflysById;

  Object.keys(fireflysById).forEach((id) => {
    fireflysById = fireflysById.setIn(
      [id],
      calcTimeAndStatus(fireflysById[id])
    );
  });
  return state.setIn(["fireflysById"], fireflysById);
}

export default function reduce(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case "UPS_STATUS_UPDATE":
      return state.setIn(["upsById", payload.id], {
        ...payload,
      });

    case "TRAVELWAY_STATUS_UPDATE":
      return state.setIn(["travelwayByLocation", payload.location], {
        ...payload,
      });
    case "UPS_DELETED":
      if (!state.upsById[payload.id]) {
        return state;
      }
      return state.setIn(["upsById"], state.upsById.without(payload.id));

    case "FIREFLY_STATUS_UPDATE":
      return handleFFUpdate(state, payload);
    case "FIREFLY_DELETED":
      if (!state.fireflysById[payload.id]) {
        return state;
      }
      return state.setIn(
        ["fireflysById"],
        state.fireflysById.without(payload.id)
      );
    case "STATUS_CHECK_TICK":
      return updateAllFFStatus(state);

    case "COMMISSION_RECORDS_REQUEST":
      return state.merge([
        { isCommissioning: true },
        { commissioningError: null },
      ]);
    case "COMMISSION_RECORDS_SUCCESS":
      let newState = state
        .merge([{ isCommissioning: false }, { commissioningError: null }])
        .asMutable({ deep: true });
      const { mineLevelId, upsPositions, fireflyPositions } = payload;
      //console.log("Updating newState", newState);
      upsPositions.forEach((ups) => {
        const { id, point } = ups;
        newState.upsById[id].position = { mineLevelId, ...point };
      });

      fireflyPositions.forEach((ff) => {
        const { id, point, topology } = ff;
        newState.fireflysById[id].position = { mineLevelId, ...point };
        newState.fireflysById[id].topology = topology;
      });

      return Immutable(newState);
    case "COMMISSION_RECORDS_FAILED":
      return state.merge([
        { isCommissioning: false },
        { commissioningError: action.payload },
      ]);

    case "UPS_RUN_DISCOVERY":
      return state.setIn(["discoveryRequests", payload.id], true);
    case "UPS_DISCOVER_SUBMITTED":
      return state.setIn(["discoveryRequests", payload.id], null);
    case "UPS_DISCOVER_ERROR":
      return state.setIn(["discoveryRequests", payload.id], payload.message);

    case "UPS_SEND_COMMAND":
      return state.setIn(
        ["upsCommandRequests", payload.id],
        payload.cmdPayload
      );
    case "UPS_COMMAND_SUBMITTED":
      return state.setIn(["upsCommandRequests", payload.id], null);
    case "UPS_COMMAND_ERROR":
      return state.setIn(["upsCommandRequests", payload.id], {
        error: payload.message,
      });

    default:
      return state;
  }
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}

export const getAllUPSs = (state) => {
  const byId = state.upsPanelControllers.upsById;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getAllTravelways = (state) => {
  const byLocation = state.upsPanelControllers.travelwayByLocation;
  const keys = Object.keys(byLocation).sort();
  return stableOrder(keys, byLocation);
};

export const getUncommissionedUPSs = (state) => {
  return getAllUPSs(state);
};

export const getTravelwayByLocation = (state, location) =>
  state.upsPanelControllers.travelwayByLocation[location];

export const getUPSById = (state, id) => state.upsPanelControllers.upsById[id];

export const getFireflyById = (state, id) =>
  state.upsPanelControllers.fireflysById[id];

export const getAllFireflys = (state) => {
  const byId = state.upsPanelControllers.fireflysById;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getFirefliesForUPS = (state, upsId) => {
  return getAllFireflys(state).filter((ff) => ff.topology.upsId === upsId);
};

export const getTopology = (ff, preferFromLastStatus = false) => {
  if (preferFromLastStatus && ff.lastStatusReport) {
    return ff.lastStatusReport.topology;
  }
  return ff.topology;
};

export const getFirefliesForUPSId = (
  state,
  upsId,
  preferTopologyFromStatus = true
) => {
  return getAllFireflys(state).filter(
    (ff) => getTopology(ff, preferTopologyFromStatus).upsId === upsId
  );
};

export const getFireflyIdsForMineLevelId = (state, mineLevelId) => {
  return getAllFireflys(state)
    .filter((ff) => ff.getIn(["position", "mineLevelId"]) === mineLevelId)
    .map((ff) => ff.id);
};

export const getFireflysForMineLevelId = (state, mineLevelId) => {
  return getAllFireflys(state).filter(
    (ff) => ff.getIn(["position", "mineLevelId"]) === mineLevelId
  );
};

export const getUPSIdsForMineLevelId = (state, mineLevelId) => {
  return getAllUPSs(state)
    .filter((ups) => ups.getIn(["position", "mineLevelId"]) === mineLevelId)
    .map((ups) => ups.id);
};

export const getUPSDiscoveryRequest = (state, id) => {
  const request = state.upsPanelControllers.discoveryRequests[id];
  if (request === true) {
    return { active: true, error: null };
  }

  return { active: false, error: request };
};

export const getUPSCommandRequest = (state, id, payload) => {
  const request = state.upsPanelControllers.upsCommandRequests[id];
  if (!request) {
    return { active: false, error: null };
  }

  if (isEqual(payload, request)) {
    return { active: true, error: null };
  }

  return { active: false, error: request.error };
};

export const getUPSIpAddress = (state, id) =>
  state.upsPanelControllers.getIn([
    "upsById",
    id,
    "lastStatusReport",
    "topology",
    "ip",
  ]);
