import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Form,
  Container,
  Button,
  Menu,
  Popup,
  Icon,
  Segment,
  Header,
  Dropdown,
  Message,
  Grid,
  Tab,
  Label,
  Divider,
} from "semantic-ui-react";

import { LayersControl } from "react-leaflet";
import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroupGeoJson";

import _isEmpty from "lodash/isEmpty";

import {
  getFireflyById,
  getFireflyCoordinatesById,
  getControllerById,
  getControllerCoordinatesById,
} from "components/WebWorker/reducer";
import { formatRelative, parseISO, isPast } from "date-fns";
import isEqual from "lodash/isEqual";

import UPSRawStatusReport from "../../ups/UPSRawStatusReport";

import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import {
  Field,
  FieldArray,
  reduxForm,
  SubmissionError,
  change as unboundChange,
} from "redux-form";

import {
  getControllerPortsById,
  getFireflyByControllerId,
  getFireflyCoordinatesByUpsId,
  getAreaStatusesById,
  getControllerEmergencyEventSettingsById,
  getControllerTriggerEventSettingsById,
} from "components/WebWorker/reducer";

import { makeTemporaryCoordsFactory } from "MineLevels/reducer";
import { fetchUPSs, fetchFireflys } from "UPSPanelControllers/actions";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

// import {
//   getUPSById,
//   getFirefliesForUPSId,
//   getTopology,
// } from "UPSPanelControllers/reducer";

import {
  saveUPS,
  deleteUPS,
  savePositions,
  saveEmergencyEventSettings,
  saveTriggerEventSettings,
} from "UPSPanelControllers/actions";
import { saveFirefly, deleteFirefly } from "UPSPanelControllers/actions";

import ChooseMineLevelField from "components/ChooseMineLevelField";

import { MineLevelMapById } from "components/Map/MineLevelMap";

import UPSName from "containers/UPSName";
import FireflyName from "containers/FireflyName";
import PortSummary from "admin/bulk-positioning/UPSBulkEditPage/PortSummaryField";

import UPSMoveableMarker from "admin/bulk-positioning/UPSBulkEditPage/UPSMoveableMarker";
import UPSPortStringMapLocationEditor from "./UPSPortStringMapLocationEditor";
import sortBy from "lodash/sortBy";
import { renderField, renderCheckbox } from "admin/form-field";
import { getPortColor } from "admin/bulk-positioning/UPSBulkEditPage/port-colors";
import selector from "admin/bulk-positioning/UPSBulkEditPage/form-value-selector";

import SubmissionModal from "admin/SubmissionModal";
import NavigationPromptModal from "admin/NavigationPromptModal";
import { StatusEnum } from "utils/StatusEnum";

class UPSBulkPositioningForm extends Component {
  // Reinstate if we really need to start worrrying about the topology
  // status having changed
  // componentDidUpdate () {
  // console.log('saving', this.props)
  // }

  // #REVIEW -
  //  Render issue - with each heartbeat the STATE updates which causes localPts to be updated and so resets the map state
  //   This stops re-rendering the map unnecessarily

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      ctrlKey: false,
      submitted: false,
      errorMessage: "",
    };
  }

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  shouldComponentUpdate(nextProps, nextState) {
    // only re-render if the ups object has changed.
    // This stops re-rendering the map unnecessarily

    //console.log(`nextProps`, nextProps);
    return (
      !isEqual(this.props.ups, nextProps.ups) ||
      !isEqual(this.props.fireflies, nextProps.fireflies) ||
      !isEqual(this.props.ports, nextProps.ports) ||
      !isEqual(
        this.props.isEmergencyEventSettings,
        nextProps.isEmergencyEventSettings
      ) ||
      !isEqual(
        this.props.isTriggerEventSettings,
        nextProps.isTriggerEventSettings
      ) ||
      !isEqual(this.state.collapse, nextState.collapse) ||
      !isEqual(
        this.state.isIgnoreEmergencyTriggerEvents,
        nextState.isIgnoreEmergencyTriggerEvents
      ) ||
      // !isEqual(this.props.submitting, this.props.submitting) || //#WTF??????
      // !isEqual(this.props.pristine, this.props.pristine) ||
      //
      !isEqual(this.state.ctrlKey, nextState.ctrlKey) ||
      !isEqual(this.state.errorMessage, nextState.errorMessage)
    );
  }

  ctrlKeyDown = (e) => {
    if (e.keyCode === 17) {
      this.setState({ ctrlKey: true });
    }
  };

  ctrlKeyUp = (e) => {
    if (e.keyCode === 17) {
      this.setState({ ctrlKey: false });
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.ctrlKeyDown, false);
    document.addEventListener("keyup", this.ctrlKeyUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.ctrlKeyDown, false);
    document.removeEventListener("keyup", this.ctrlKeyUp, false);
  }

  submitMyForm = (formdata) => {
    const {
      saveFirefly,
      saveUPS,
      initialValues,
      transformPixelsToUtm,
      saveEmergencyEventSettings,
      saveTriggerEventSettings,
    } = this.props;

    //console.log("xxx formdata", formdata);
    //console.log("initialValues", initialValues);

    const {
      ups,
      fireflies,
      ports,
      isEmergencyEventSettings,
      isTriggerEventSettings,
    } = formdata;

    const { mineLevelId, lat, lng } = ups.position;
    const { name: network_name } = ups;

    const changePositions = [];
    Object.keys(fireflies).forEach((id) => {
      const ff = fireflies[id];
      const { position, topology } = ff;

      const ffInitialValue = initialValues.fireflies[id];
      const { position: positionInitialValue, topology: topologyInitialValue } =
        ffInitialValue;

      if (!isEqual(position, positionInitialValue)) {
        const objLatLng = transformPixelsToUtm.transform({
          lat: position.lat,
          lng: position.lng,
        });

        // transform position to UTM
        const newPosition = {
          mineLevelId: position.mineLevelId,
          lat: objLatLng.lat,
          lng: objLatLng.lng,
        };

        changePositions.push({
          id: id,
          type: "firefly",
          position: newPosition,
          topology,
        });
      }
    });

    const upsPosition = { id: ups.id, position: { mineLevelId, lat, lng } };
    const { id: upsIdInitial, position: positionInitial } = initialValues.ups;
    const upsPositionsInitialValue = {
      id: upsIdInitial,
      position: {
        mineLevelId: positionInitial.mineLevelId,
        lat: positionInitial.lat,
        lng: positionInitial.lng,
      },
    };

    if (!isEqual(upsPosition, upsPositionsInitialValue)) {
      const objLatLng = transformPixelsToUtm.transform({
        lat: upsPosition.position.lat,
        lng: upsPosition.position.lng,
      });

      // transform position to UTM
      const newPosition = {
        mineLevelId: upsPosition.position.mineLevelId,
        lat: objLatLng.lat,
        lng: objLatLng.lng,
      };

      changePositions.push({
        id: upsPosition.id,
        type: "controller",
        position: newPosition,
        name: network_name,
      });
    }

    // update settings
    let changeSettings = [];

    const makeEventSettings = (enable, ports, type) => {
      let portSettings = [];
      ports.forEach((port, idx) => {
        const { emergencyEventSettings, triggerEventSettings } = port;

        if (type === "emergency") {
          portSettings[idx] = emergencyEventSettings;
        } else if (type === "trigger") {
          portSettings[idx] = triggerEventSettings;
        }
      });

      return {
        enable: enable,
        ports: portSettings,
      };
    };

    const emergencyEventSettings = makeEventSettings(
      isEmergencyEventSettings,
      ports,
      "emergency"
    );
    const initialValuesEmergencyEventSettings = makeEventSettings(
      initialValues.isEmergencyEventSettings,
      initialValues.ports,
      "emergency"
    );

    // console.log(
    //   "SAVE emergencyEventSettings, initialValuesEmergencyEventSettings",
    //   emergencyEventSettings,
    //   initialValuesEmergencyEventSettings
    // );

    // don't send if object is unchanged
    if (!isEqual(emergencyEventSettings, initialValuesEmergencyEventSettings)) {
      changeSettings.push({
        id: ups.id,
        type: "emergencyEventSettings",
        settings: emergencyEventSettings,
      });
    }

    const triggerEventSettings = makeEventSettings(
      isTriggerEventSettings,
      ports,
      "trigger"
    );
    const initialValuesTriggerEventSettings = makeEventSettings(
      initialValues.isTriggerEventSettings,
      initialValues.ports,
      "trigger"
    );

    // console.log(
    //   "SAVE triggerEventSettings, initialValuesTriggerEventSettings",
    //   triggerEventSettings,
    //   initialValuesTriggerEventSettings
    // );

    // don't send if it is not enabled
    if (!isEqual(triggerEventSettings, initialValuesTriggerEventSettings)) {
      changeSettings.push({
        id: ups.id,
        type: "triggerEventSettings",
        settings: triggerEventSettings,
      });
    }

    let promiseArray = [];

    // update settings
    changeSettings.forEach((changeSetting) => {
      const { id, type, settings } = changeSetting;
      switch (type) {
        case "emergencyEventSettings":
          promiseArray.push(
            new Promise((resolve, reject) => {
              saveEmergencyEventSettings({
                values: { id, settings },
                resolve,
                reject,
              });
            })
          );

          break;
        case "triggerEventSettings":
          promiseArray.push(
            new Promise((resolve, reject) => {
              saveTriggerEventSettings({
                values: { id, settings },
                resolve,
                reject,
              });
            })
          );
          break;
        default:
          break;
      }
    });

    // update positions
    changePositions.forEach((changePosition) => {
      const {
        id,
        type,
        position: { mineLevelId, lat, lng },
        topology,
        name,
        mdt,
      } = changePosition;

      switch (type) {
        case "firefly":
          promiseArray.push(
            new Promise((resolve, reject) => {
              saveFirefly({
                values: {
                  id,
                  position: { mineLevelId, lat, lng },
                  topology,
                  name,
                  mdt,
                },
                resolve,
                reject,
              });
            })
          );
          break;
        case "controller":
          promiseArray.push(
            new Promise((resolve, reject) => {
              saveUPS({
                values: {
                  id,
                  position: { mineLevelId, lat, lng },
                  name,
                },
                resolve,
                reject,
              });
            })
          );
          break;
        default:
          break;
      }
    });

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("UPDATING CONTROLLER/FIREFLIES", results);
      })
      .then(
        () => {
          // wait for state change before redirecting page
          this.setState(
            {
              submitted: true,
            },
            () => {
              const { fetchUPSs, fetchFireflys } = this.props;
              // #FETCHS
              fetchUPSs(); // #FETCHS
              fetchFireflys(); // #FETCHS

              this.props.goto("/admin/controller/");
            }
          );
        },
        (msg) => {
          console.log("action failed", msg); // TODO probs should show this?
        }
      );
  };

  requestDelete = (values) => {
    const { deleteUPS, id, change } = this.props;

    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete");

    return new Promise((resolve, reject) => {
      deleteUPS({ values: { id: id }, resolve, reject });
    }).then(
      () => {
        // wait for state change before redirecting page
        this.setState(
          {
            submitted: true,
          },
          () => {
            const { fetchUPSs, fetchFireflys } = this.props;
            // #FETCHS
            fetchUPSs(); // #FETCHS
            fetchFireflys(); // #FETCHS

            this.props.goto("/admin/controller/");
          }
        );
      },
      (errorMsg) => {
        //console.log(`deleteUPS errorMsg`, errorMsg);
        // pop error dlg
        const message = {
          header: {
            icon: "warning",
            title: "Delete Failed",
          },
          content: "Error when attempting to delete data.",
        };
        this.setState({
          errorMessage: `${errorMsg}`,
          modalMessage: message,
        });
      }
    );
  };

  cancelForm = (e) => {
    e.preventDefault();
    //  this.props.goto("/admin/bulk-positioning/");  // <---- reminder
    this.props.goto("/admin/controller/");
  };

  onResetError = () => {
    const { goto, submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      goto("/");
    }
  };

  render() {
    const {
      handleSubmit,
      change,
      pristine,
      submitting,
      error,
      reset,
      ups,
      areaError,
      ports,
      //
      isIgnoreEmergencyTriggerEvents,
      //
      isDeleteButton,
      strings, // localisation
    } = this.props;

    const {
      submitted,
      collapse: isCollapse,
      ctrlKey,
      modalMessage,
      errorMessage,
    } = this.state;

    const openModal = errorMessage !== "";

    const panes = [
      {
        menuItem: strings?.["Position"],
        render: () => (
          <Tab.Pane>
            <MapPositioningDisplay mineLevelId={ups.mineLevelId}>
              <UPSMoveableMarker change={change} name={"ups.position"}>
                {ups.id}
                {/* <UPSName id={ups.id} /> */}
              </UPSMoveableMarker>
              <ForAllPorts
                PortComponent={UPSPortString}
                change={change}
                ctrlKey={ctrlKey}
              />
              <LayersControl position={"topright"}>
                <LayersControl.Overlay
                  key={"firefliesOverlay"}
                  name={"FireFlys"}
                >
                  <CurrentMineLevelFirefliesFeatureGroup />
                </LayersControl.Overlay>
              </LayersControl>
            </MapPositioningDisplay>
          </Tab.Pane>
        ),
      },
      {
        menuItem: strings?.["Latest Report"],
        render: () => (
          <Tab.Pane>
            <UPSRawStatusReport id={ups.id} />
          </Tab.Pane>
        ),
      },
    ];

    const isPorts = !_isEmpty(ports);

    return (
      <>
        <NavigationPromptModal
          formName={"upsBulkPositioningForm"}
          submitted={submitted}
          onSubmit={(formValues) => handleSubmit(this.submitMyForm(formValues))}
        />
        <SubmissionModal
          open={openModal}
          // #REVIEW - no action when dismiss dlg
          onResetError={() => this.onResetError()}
          modalMessage={modalMessage}
          errorMessage={errorMessage}
        />
        <Container fluid>
          {error && <Message error header="Error submitting" content={error} />}

          <Form
            onSubmit={handleSubmit(this.submitMyForm)}
            error={Boolean(error)}
          >
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column stretched width={isCollapse ? 1 : 6}>
                  {isCollapse && (
                    <Segment>
                      <Grid>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Popup
                              content={strings?.["Expand Left Side"]}
                              trigger={
                                <Button
                                  floated={"left"}
                                  icon
                                  onClick={this.expandCollapse}
                                  size={"mini"}
                                  color={"teal"}
                                >
                                  <Icon name={"angle right"} size={"large"} />
                                </Button>
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  )}
                  {!isCollapse && (
                    <>
                      <Segment>
                        <Grid>
                          <Grid.Row>
                            <div style={{ paddingLeft: "14px" }}>
                              <Menu icon="labeled">
                                <Menu.Item
                                  href={`http://${ups.ip}`}
                                  target="_blank"
                                  disabled={_isEmpty(ups.ip)}
                                >
                                  <Icon name="external alternate" />
                                  {strings?.["Controller Config"]}
                                </Menu.Item>
                              </Menu>
                            </div>
                            <Grid.Column floated={"right"}>
                              <Popup
                                content={strings?.["Collapse Left Side"]}
                                trigger={
                                  <Button
                                    floated={"right"}
                                    icon
                                    onClick={this.expandCollapse}
                                    size={"mini"}
                                    color={"teal"}
                                  >
                                    <Icon name={"angle left"} size={"large"} />
                                  </Button>
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Divider hidden />
                        <Form.Group>
                          <Field
                            name="ups.name"
                            label={strings?.["Name"]}
                            placeholder={strings?.["Name"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                          <Field
                            name="ups.id"
                            label={strings?.["Controller ID"]}
                            placeholder={strings?.["Controller ID"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                          <Field
                            name="ups.ip"
                            label={strings?.["Controller Address"]}
                            placeholder={strings?.["unknown"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                        </Form.Group>

                        {/* <Grid>
                        <Grid.Row columns={3}>
                          <Grid.Column width={8} />
                          <Grid.Column width={4} textAlign="left">
                            <Label
                              key="masterSlave"
                              size="big"
                              color={ups.master ? "blue" : "red"}
                            >
                              {ups.master ? "MASTER" : "SLAVE"}
                            </Label>
                          </Grid.Column>
                          <Grid.Column width={4} textAlign="right">
                            <Menu.Item
                              href={`http://${ups.ip}`}
                              position="right"
                              target="_blank"
                            >
                              <Icon name="external alternate" size="big" />
                            </Menu.Item>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid> */}
                        <Form.Group widths="equal">
                          <Field
                            name="ups.master"
                            label={strings?.["Master"]}
                            placeholder={strings?.["Controller Mode"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                          <Field
                            name="ups.location"
                            label={strings?.["Controller Location"]}
                            placeholder={strings?.["Controller Location"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />

                          <Field
                            name="ups.mineLevelId"
                            label={strings?.["Area"]}
                            placeholder={strings?.["Area"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                        </Form.Group>
                        <Field
                          name="ups.lastStatusReport"
                          label={strings?.["Latest Report"]}
                          placeholder={strings?.["Latest Report"]}
                          component={renderField}
                          className="disabled-form-field"
                          disabled
                        />
                      </Segment>
                      <Segment>
                        {!areaError.error && (
                          <>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column>
                                  <Header as="h2">{strings?.["Ports"]}</Header>
                                </Grid.Column>
                                {isPorts && (
                                  <Grid.Column width={4} floated={"right"}>
                                    <Form.Group inline>
                                      {/* #NOTE - enable this section to allow control over Emergency events */}
                                      {false &&
                                        !isIgnoreEmergencyTriggerEvents && (
                                          <Field
                                            name={`isEmergencyEventSettings`}
                                            label={
                                              strings?.[
                                                "Enable Emergency Events"
                                              ]
                                            }
                                            component={renderCheckbox}
                                            disabled={
                                              isIgnoreEmergencyTriggerEvents
                                            }
                                          />
                                        )}

                                      <Field
                                        name={`isTriggerEventSettings`}
                                        label={
                                          strings?.["Enable Trigger Events"]
                                        }
                                        component={renderCheckbox}
                                        disabled={
                                          isIgnoreEmergencyTriggerEvents
                                        }
                                      />
                                    </Form.Group>
                                  </Grid.Column>
                                )}
                                {!isPorts && (
                                  <>
                                    <Message
                                      icon
                                      color="orange"
                                      style={{ margin: "10px" }}
                                    >
                                      <Icon name="warning" />
                                      <Message.Content>
                                        <Message.Header>
                                          {
                                            strings?.[
                                              "Controller Port Message Header"
                                            ]
                                          }
                                        </Message.Header>
                                        {
                                          strings?.[
                                            "Controller Port Message Content"
                                          ]
                                        }
                                      </Message.Content>
                                    </Message>
                                  </>
                                )}
                              </Grid.Row>
                            </Grid>
                            <ForAllPorts
                              PortComponent={PortSummary}
                              onSubmit={handleSubmit(this.submitMyForm)}
                            />
                          </>
                        )}
                        {areaError.error && (
                          <>
                            <Message icon color="orange">
                              <Icon name="warning" />
                              <Message.Content>
                                <Message.Header>
                                  {strings?.["Controller Area Message Header"]}
                                </Message.Header>
                                {strings?.[
                                  `Controller Area Message Content`
                                ]?.replace("areaError_id", areaError.id)}
                              </Message.Content>
                            </Message>
                          </>
                        )}
                      </Segment>
                    </>
                  )}
                </Grid.Column>

                <Grid.Column width={isCollapse ? 15 : 10}>
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column>
                        {isDeleteButton && (
                          <Grid.Row>
                            <DeleteButton
                              onClick={this.requestDelete}
                              id={ups.id}
                              strings={strings}
                            />
                          </Grid.Row>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Button.Group floated="right">
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={reset}
                            >
                              {strings?.["Reset"]}
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              {strings?.["Cancel"]}
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Segment>

                  <Tab
                    panes={panes}
                    menu={{
                      borderless: true,
                      attached: false,
                      tabular: false,
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row columns={1}>
              <Grid.Column style={{ marginBottom: "20px" }}>
                <Segment>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Button.Group floated="right">
                        <SaveButton
                          submitting={submitting}
                          pristine={pristine}
                        />
                        <Button.Or />
                        <Button
                          type="button"
                          disabled={pristine || submitting}
                          onClick={reset}
                        >
                          Reset
                        </Button>
                        <Button.Or />
                        <Button
                          style={{ textAlign: "right" }}
                          onClick={(e) => this.cancelForm(e)}
                        >
                          Cancel
                        </Button>
                      </Button.Group>
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row> */}
            </Grid>

            {/* ********************************************************************************* */}

            {/* <Grid divided>
            <Grid.Column width={8}>
              <Field
                name="ups.name"
                label="Name"
                placeholder="No custom name"
                component={renderField}
                disabled
              />
              <Field
                name="ups.created"
                label="Commissioned"
                placeholder="Not yet commissioned"
                component={renderField}
                disabled
              />

              <Field
                name="ups.position.mineLevelId"
                component={ChooseMineLevelField}
                label="Mine level"
              />
              <Form.Group widths="equal">
                <Field
                  name="ups.position.lat"
                  component={renderField}
                  label="Latitude"
                  disabled
                />
                <Field
                  name="ups.position.lng"
                  component={renderField}
                  label="Longitude"
                  disabled
                />
              </Form.Group>
              <h2>Ports</h2>
              <ForAllPorts PortComponent={PortSummary} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column textAlign={"left"} width={8}>
                    <h2>Positioning</h2>
                  </Grid.Column>
                  <Grid.Column textAlign={"right"} width={8}>
                    <Form.Group style={{ float: "right" }}>
                      <SaveButton submitting={submitting} pristine={pristine} />
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <MapPositioningDisplay mineLevelId={ups.position.mineLevelId}>
                <UPSMoveableMarker change={change} name={"ups.position"}>
                  <UPSName id={ups.id} />
                </UPSMoveableMarker>
                <ForAllPorts PortComponent={UPSPortString} change={change} />
              </MapPositioningDisplay>
            </Grid.Column>
          </Grid>
 */}

            {/***********************************/}
          </Form>
          <DebugPagePropsMessages that={this} />
        </Container>
      </>
    );
  }
}

const ListPorts = ({ fields, PortComponent, change, ctrlKey }) => {
  return (
    <div>
      {fields.map((port, index) => {
        return (
          <PortComponent
            key={index}
            name={port}
            change={change}
            ctrlKey={ctrlKey}
          />
        );
      })}
    </div>
  );
};

const ForAllPorts = ({ PortComponent, change, ctrlKey }) => {
  return (
    <FieldArray
      name="ports"
      component={ListPorts}
      props={{ PortComponent, change, ctrlKey }}
    />
  );
};

function MapPositioningDisplay({ mineLevelId, children }) {
  if (!mineLevelId) {
    return <div>No mine level selected</div>;
  }
  return (
    <MineLevelMapById
      mineLevelId={mineLevelId}
      style={{
        height: "60vh",
        width: "100%",
        position: "relative",
        zIndex: 0,
        backgroundColor: `rgba(255,255,255,1)`,
      }}
    >
      {children}
    </MineLevelMapById>
  );
}

class UPSPortString extends Component {
  onRepositioned = (newPositions) => {
    const { dispatch } = this.props;
    const updates = newPositions.map((ff) =>
      unboundChange(
        "upsBulkPositioningForm",
        `fireflies.${ff.id}.position`,
        ff.position
      )
    );
    dispatch(updates); // relies on redux-batch!!!
  };
  popup = (ffId) => <FireflyName id={ffId} />;
  render() {
    const { portNumber, upsPosition, positionMode, fireflies, ctrlKey } =
      this.props;

    // forces to "asString" if the control key is held down
    const isAsIndividuals = ctrlKey ? false : positionMode === "asIndividuals";

    return (
      <UPSPortStringMapLocationEditor
        portNumber={portNumber}
        baseColor={getPortColor(portNumber)}
        popup={this.popup}
        fireflies={fireflies}
        asIndividuals={
          isAsIndividuals //positionMode === "asIndividuals"
        }
        upsPosition={upsPosition}
        onRepositioned={this.onRepositioned}
      />
    );
  }
}

UPSPortString = connect((state, { name }) => {
  const ups = selector(state, "ups");
  const port = selector(state, name);
  const fireflies = selector(state, "fireflies");

  return {
    portNumber: port.portNumber,
    positionMode: port.positionMode,
    upsPosition: ups.position,
    fireflies: port.fireflies.map((id) => ({ id, ...fireflies[id] })),
  };
})(UPSPortString);

// function SaveButton({ submitting, pristine, ...props }) {
//   const disabled = submitting || pristine;
//   let positive = true;
//   let text = "Save";
//   if (submitting) {
//     text = "Submitting";
//   } else if (pristine) {
//     positive = false;
//     text = "Nothing changed";
//   }

//   return (
//     <Form.Button disabled={disabled} positive={positive}>
//       {text}
//     </Form.Button>
//   );
// }

UPSBulkPositioningForm = reduxForm({
  form: "upsBulkPositioningForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
})(UPSBulkPositioningForm);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    saveUPS: (data) => dispatch(saveUPS(data)), // #WIP
    savePositions: (data) => dispatch(savePositions(data)), // #WIP
    deleteUPS: (data) => dispatch(deleteUPS(data)), // #WIP
    //
    saveFirefly: (data) => dispatch(saveFirefly(data)), // #WIP
    //
    saveEmergencyEventSettings: (data) =>
      dispatch(saveEmergencyEventSettings(data)),
    saveTriggerEventSettings: (data) =>
      dispatch(saveTriggerEventSettings(data)),
    fetchUPSs: () => dispatch(fetchUPSs()),
    fetchFireflys: () => dispatch(fetchFireflys()),
  };
};

const mapStateToProps = (state, props) => {
  //const stateUPS = getUPSById(state, props.id);

  const id = props.id;

  const stateUPS = getControllerById(state, props.id);

  //console.log(`xxx stateUPS`, stateUPS);

  const { firefly_count: fireflyCount, topology, timestamp } = stateUPS;
  const {
    area: mineLevelId,
    location,
    network_name,
    master,
    ip,
    ignore_server,
    deviceStatus,
  } = topology;

  // #NOTE
  // `ignore_server` denotes whether emergency and trigger events are
  // managed by the server config or the controller.

  let isIgnoreEmergencyTriggerEvents = false;
  if (ignore_server !== undefined) {
    isIgnoreEmergencyTriggerEvents = ignore_server == true;
  }

  const area = getAreaStatusesById(state, mineLevelId);

  const transformPixelsToUtm = area?.transformPixelsToUtm;

  const controllerCoord = getControllerCoordinatesById(state, id);

  // plot functions hereon use [lng, lat] so swap coordinates
  const lat =
    controllerCoord !== undefined && controllerCoord !== null
      ? controllerCoord[1] >= 0
        ? controllerCoord[1]
        : 0
      : 0;
  const lng =
    controllerCoord !== undefined && controllerCoord !== null
      ? controllerCoord[0] >= 0
        ? controllerCoord[0]
        : 0
      : 0;

  // *********************************************
  // const { id, name, created, position } = stateUPS;
  // const { mineLevelId, lat, lng } = position || {};

  const upsLatLng = { lat, lng };

  //console.log("mineLevelId, upsLatLng", mineLevelId, upsLatLng);

  const temporaryCoordsFactory = makeTemporaryCoordsFactory(state);
  const tempCoordsProvider = temporaryCoordsFactory.ForMineLevelId(
    mineLevelId,
    upsLatLng
  );

  //console.log("tempCoordsProvider", tempCoordsProvider);

  // const ups = {
  //   id,
  //   name,
  //   created,
  //   position: { mineLevelId, lat, lng },
  // };

  // *********************************************

  const lastStatusReport =
    timestamp !== undefined
      ? formatRelative(parseISO(timestamp), new Date(), {
          includeSeconds: true,
        })
      : "-";

  const ups = {
    id,
    name: network_name,
    created: "2020-07-28T03:44:32.341087779Z",
    ip,
    master,
    location,
    mineLevelId,
    lastStatusReport,
    position: { mineLevelId, lat, lng },
  };

  // don't show delete if controller is not commissioned

  const stateFireflies = getFireflyByControllerId(state, id);

  //console.log(`xxx stateFireflies`, stateFireflies);

  // initialise byPortByOrdinal to display 4 ports irrespective of whether the
  // ports have FFs connected.
  //
  let byPortByOrdinal = { 1: {}, 2: {}, 3: {}, 4: {} };
  let fireflies = {};

  stateFireflies.forEach((ff) => {
    const topo = ff.topology;
    const { port_number: portNumber, position } = topo;
    const portByOrdinal = byPortByOrdinal[portNumber] || {};
    const lastStatus = ff.timestamp;

    let deviceStatus = [];

    // Always bias to choosing the FF with the latest status report for a
    // given ordinal position (i.e. if a unit has been replaced, always put
    // the "new" unit at this position)
    if (
      !portByOrdinal[position] //|| lastStatus > fireflies[portByOrdinal[position]].lastStatus
    ) {
      portByOrdinal[position] = ff.id;
      // store this ff topology info for later port-oriented processing
      byPortByOrdinal[portNumber] = portByOrdinal;
    }

    const latLng = tempCoordsProvider.firefly(portNumber, position);
    const ffCoord = getFireflyCoordinatesById(state, ff.id);

    // [0,0] if deleted or new
    const isSomeZero =
      ffCoord === undefined ? true : ffCoord.some((item) => item === 0);

    if (isSomeZero) deviceStatus.push(StatusEnum.NOT_COMMISSIONED);

    const ffPosition = {
      // draw functions hereon use [lng, lat] so swap coordinates
      lat: !isSomeZero ? ffCoord[1] : latLng.lat,
      lng: !isSomeZero ? ffCoord[0] : latLng.lng,
      mineLevelId: ff.topology.area,
    };

    let originalCommissioning = null;
    if (true) {
      //    if (ff.created) {
      originalCommissioning = {
        topology: ff.topology,
        position: ffPosition,
      };
    }

    fireflies[ff.id] = {
      id: ff.id,
      topology: { ...topo, deviceStatus },
      position: ffPosition, //latLng,
      lastStatus,
      originalCommissioning,
    };
  });

  const emergencyEventSettings = getControllerEmergencyEventSettingsById(
    state,
    id
  );
  const isEmergencyEventSettings =
    emergencyEventSettings?.enable && !isIgnoreEmergencyTriggerEvents;

  const triggerEventSettings = getControllerTriggerEventSettingsById(state, id);
  const isTriggerEventSettings =
    triggerEventSettings?.enable && !isIgnoreEmergencyTriggerEvents;

  const defaultPortSettings = (id) => {
    return {
      id: id,
      enable: false,
      brightness: 0,
      color: "green",
      led_state: "on",
      on_time: 10,
      off_time: 10,
      train: 1,
    };
  };

  const ports = Object.keys(byPortByOrdinal)
    .sort()
    .map((portNumber) => {
      const portByOrdinal = byPortByOrdinal[portNumber];

      return {
        portNumber,
        positionMode: "asIndividuals", //"asString",
        emergencyEventSettings:
          emergencyEventSettings?.ports?.find(
            (port) => port?.id === Number.parseInt(portNumber, 10)
          ) || defaultPortSettings(Number.parseInt(portNumber, 10)),
        triggerEventSettings:
          triggerEventSettings?.ports?.find(
            (port) => port?.id === Number.parseInt(portNumber, 10)
          ) || defaultPortSettings(Number.parseInt(portNumber, 10)),
        fireflies: sortBy(Object.keys(portByOrdinal), (i) =>
          Number.parseInt(i, 10)
        )?.map((ordinal) => portByOrdinal[ordinal]),
      };
    });

  return {
    areaError:
      area === undefined
        ? { error: true, id: mineLevelId }
        : { error: false, id: mineLevelId },
    ups: selector(state, "ups") || { position: {} },
    ports: selector(state, "ports"),
    fireflies: selector(state, "fireflies"),
    isEmergencyEventSettings: selector(state, "isEmergencyEventSettings"),
    isTriggerEventSettings: selector(state, "isTriggerEventSettings"),
    isIgnoreEmergencyTriggerEvents,
    initialValues: {
      _action: "", // used to control dlg display
      ups,
      ports,
      fireflies,
      isEmergencyEventSettings,
      isTriggerEventSettings,
    },
    transformPixelsToUtm,
    //
    isDeleteButton:
      mineLevelId !== undefined &&
      !deviceStatus?.includes(StatusEnum.NOT_COMMISSIONED),

    onChange: (values, dispatch, props, previousValues) => {
      //console.log("upsBulkPositioningForm onChange values", values);
      //      console.log("UPSBulkPositioningForm bulkPos onChange props", props);
      // console.log(
      //   "ggg UPSBulkPositioningForm bulkPos onChange isEmergencyEventSettings",
      //   JSON.stringify(values.isEmergencyEventSettings)
      // );
      // console.log(
      //   "ggg UPSBulkPositioningForm bulkPos onChange isTriggerEventSettings",
      //   JSON.stringify(values.isTriggerEventSettings)
      // );
      // values.ports.forEach((port, idx) => {
      //   console.log(
      //     "UPSBulkPositioningForm bulkPos onChange ports emergencyEventSettings",
      //     idx,
      //     JSON.stringify(port.emergencyEventSettings)
      //   );
      //   console.log(
      //     "UPSBulkPositioningForm bulkPos onChange ports triggerEventSettings",
      //     idx,
      //     JSON.stringify(port.triggerEventSettings)
      //   );
      // });
      // console.log(
      //   "UPSBulkPositioningForm bulkPos onChange initialValues",
      //   props.initialValues
      // );
      // #REVIEW
      // #NOTE - this code is unnecessary as in v3 the mine level
      // i.e. area, can not be changed for a UPS in the web UI. Only in controller setup itself.
      //
      // Leaving code here for reference.......
      //
      // const newUps = values.ups;
      // const prevUps = previousValues.ups;
      // const mineLevelChanged =
      //   newUps.position.mineLevelId !== prevUps.position.mineLevelId;
      // if (mineLevelChanged) {
      //   let {
      //     initialValues: { ups, fireflies },
      //   } = props;
      //   let { mineLevelId, lat, lng } = ups.position || {};
      //   const newProvider = temporaryCoordsFactory.ForMineLevelId(
      //     newUps.position.mineLevelId
      //   );
      //   const upsOnNewLevel = newUps.position.mineLevelId !== mineLevelId;
      //   const newPositions = [];
      //   function addUpdate(fieldName, coords) {
      //     const { lat, lng } = coords;
      //     const formname = "upsBulkPositioningForm";
      //     newPositions.push(
      //       unboundChange(formname, `${fieldName}.position.lat`, lat)
      //     );
      //     newPositions.push(
      //       unboundChange(formname, `${fieldName}.position.lng`, lng)
      //     );
      //   }
      //   addUpdate("ups", upsOnNewLevel ? newProvider.ups() : { lat, lng });
      //   Object.keys(values.fireflies).forEach((id) => {
      //     const ff = values.fireflies[id];
      //     addUpdate(
      //       `fireflies.${id}`,
      //       upsOnNewLevel
      //         ? newProvider.firefly(
      //             ff.topology.portNumber,
      //             ff.topology.position
      //           )
      //         : fireflies[id].position
      //     );
      //   });
      //   console.log("mine level changed!, sending new positions", newPositions);
      //   dispatch(newPositions);
      // }
    },
  };
};

// UPSBulkPositioningForm = connect(mapStateToProps, { savePositions, push })(
//   UPSBulkPositioningForm
// );

UPSBulkPositioningForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UPSBulkPositioningForm);

export default UPSBulkPositioningForm;
