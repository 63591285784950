import React, { Component } from "react";
import { connect } from "react-redux";

import {
  showAreaPreview,
  stopAreaPreview,
} from "components/RegionPreview/actions";

import { makeGetNamedAreasForLevel } from "NamedAreas/reducer";

import { getNamedAreaEventsByAreaId } from "components/WebWorker/reducer";

import {
  getNamedAreaParentStatusesByAreaId,
  getNamedAreaStatusesById,
  getAreaStatusesById,
} from "components/WebWorker/reducer";
import { getNamedAreaDisplaySettings } from "components/Settings/reducer";
import PrecannedOperations from "pages/ControlRoomPage/PrecannedOperations";
import "react-virtualized/styles.css";
import { Column, Table as VTable, AutoSizer } from "react-virtualized";
import {
  Grid,
  Segment,
  Message,
  Header,
  Button,
  Icon,
  Popup,
  Checkbox,
  Container,
} from "semantic-ui-react";
import { SingleOperation } from "pages/ControlRoomPage/PreCannedOperationsCustomSOP";
import {
  isConfigJs,
  isShowEventButtons,
  controlScreenSOPdata,
} from "components/ConfigJs";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import { isBoomgateCrane, isShowLightControlSim } from "components/ConfigJs";
import { updateRemoteButtonClick } from "OperationalChanges/actions";
//import { isKthBitSet } from "utils/bit-operations";
import _isEqual from "lodash/isEqual";

import { getObjectDiff } from "utils/getObjectDiff";
import { Slider } from "react-semantic-ui-range";
import Heartbeat from "react-heartbeat";

import { getUserSessionIp, messageToken } from "utils/messageToken";
import { getUserSettings } from "auth/reducer";

// based on `MineLevelPrecannedOperationsList`
class MineLevelCustomSOPList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewables: this._prepViewables(this.props),
      sirenState: [],
      sirenIconClickTimeout: [],
      //sirenIconClickTimeout: [],
      //
      isShowLightControlSim: false,
      lightControlSimData: [],
      isLightControlSim: false,
      lightControlSimRate: 5000,
    };
  }

  getSimData = (namedAreaGroup) => {
    //console.log(`sim namedAreaGroup`, namedAreaGroup);

    let simData = [];
    if (namedAreaGroup !== null) {
      //groups
      namedAreaGroup.map((group) => {
        // levels
        group.subItems.map((level) => {
          // buttons
          level.subItems.map((na) => {
            simData.push(
              `${na?.namedArea?.button?.named_area}:${na?.namedArea?.button?.priority}`
            );
          });
        });
      });
    }
    return simData;
  };

  componentDidMount() {
    if (isShowLightControlSim()) {
      this.setState({ isShowLightControlSim: true });
      let simData = [];
      if (this.props.namedAreaGroup) {
        simData = this?.getSimData([...this.props.namedAreaGroup]) || [];
      }
      this.setState({ isShowLightControlSim: simData });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isShowLightControlSim() !== this.state.isShowLightControlSim) {
      this.setState({ isShowLightControlSim: isShowLightControlSim() });
    }

    if (!_isEqual(this.props.namedAreaGroup, prevProps.namedAreaGroup)) {
      let simData = [];
      if (this.props.namedAreaGroup) {
        simData = this?.getSimData([...this.props.namedAreaGroup]) || [];
      }

      this.setState({ isShowLightControlSim: simData });
    }
  }

  _prepViewables = (props) => {
    const { mineLevelId, namedAreasParents, levelWideNamedArea } = props;

    //    console.log("levelWideNamedArea", levelWideNamedArea);
    return [
      // #REVIEW - disable `fixed` level wide display in preference to real named area events
      // {
      //   key: "level",
      //   area: {
      //     id: levelWideNamedArea.area, // mineLevelId,
      //     spec: levelWideNamedArea.id, //mineLevelId,
      //     name: "LEVEL WIDE",
      //     //type: "MINE_LEVEL",
      //     groupsArray: levelWideNamedArea.button,
      //   },
      // },
      ...namedAreasParents.map((namedArea) => ({
        key: `named-area-${namedArea.id}`,
        area: {
          id: namedArea.area,
          spec: namedArea.id,
          name:
            namedArea.type !== "LEVEL_WIDE"
              ? namedArea.namedArea
              : "LEVEL WIDE",
          type: namedArea.type,
          groupsArray: namedArea.button,
        },
      })),
    ];
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   return !_isEqual(JSON.stringify(this.props), JSON.stringify(nextProps));
  // }

  //#DEBUG_SOLUTION - reviewing render states

  // shouldComponentUpdate(nextProps, nextState) {
  //   const thisState = JSON.parse(JSON.stringify(this.state));
  //   const newState = JSON.parse(JSON.stringify(nextState));

  //   const thisProps = JSON.parse(JSON.stringify(this.props));
  //   const newProps = JSON.parse(JSON.stringify(nextProps));

  //   const diffProps = getObjectDiff(thisProps, newProps);
  //   const diffState = getObjectDiff(thisState, newState);

  //   const checkProps = !_isEqual(thisProps, newProps);
  //   const checkState = !_isEqual(thisState, newState);

  //   const check = checkProps || checkState;

  //   if (true && check) {
  //     console.log(`xxx check diff`, check, diffProps, diffState);
  //     console.log(`xxx check thisProps`, thisProps);
  //     console.log(`xxx check newProps`, newProps);
  //     console.log(`xxx check thisState`, thisState);
  //     console.log(`xxx check newState`, newState);
  //   }

  //   return check;
  // }

  componentWillReceiveProps(nextProps) {
    if (
      !_isEqual(this.props, nextProps) &&
      nextProps.mineLevelId === this.props.mineLevelId
    ) {
      // console.log(`xx8xx this.props`, this.props);
      // console.log(`xx8xx nextProps`, nextProps);

      this.setState({ viewables: this._prepViewables(nextProps) });
    }
  }

  _getDatum(list, index) {
    return list[index];
  }

  handleTimeoutSirenIconClick = (id) => {
    let newSirenIconClickTimeout = [...this.state.sirenIconClickTimeout];

    // after timeout remote the id from the state
    newSirenIconClickTimeout = newSirenIconClickTimeout.filter(
      (item) => item !== id
    );
    this.setState({ sirenIconClickTimeout: newSirenIconClickTimeout });
  };

  timerSirenIconClick = (id) => {
    let newSirenIconClickTimeout = [...this.state.sirenIconClickTimeout];

    //if id does not already exist add it to the state
    if (!newSirenIconClickTimeout?.some((ids) => ids?.includes(id))) {
      newSirenIconClickTimeout.push(id);
    }
    this.setState({ sirenIconClickTimeout: newSirenIconClickTimeout });
    setTimeout(this.handleTimeoutSirenIconClick, 2000, id);
  };

  handleSirenIconClick = (data) => {
    const {
      id,
      namedAreaParentsPriorityByLevel,
      uniqueParentsNamedAreaEventsSirenActive,
      isSirenIconActive,
    } = data?.buttonInfo;

    // if 'isSirenIconActive' then on -> off toggle
    // toggleIdState - id, and indicates whether toggling 'on' (true) or 'off' (false)
    // e.g. "['9a27a19d-d6ac-4f8b-a9cb-ce4da4934741:true']"
    const toggleIdState = `${id}:${!isSirenIconActive}`;

    // set timeout for icon to be active for 2*1000 milli-seconds
    this.timerSirenIconClick(toggleIdState);
    // for user feedback we want the sirenIcon to stay on until updated when the click action event is applied to the polygons.
    // i.e. click to change polygon state, wait for response, in meantime simulate the state.
    // If the click action is not applied the icon will eventually time out.
    // timerSirenIconClick() - adds the clicked event id to the state 'sirenIconClickTimeout' and sets the timeout
    // after the timeout, handleTimeout() removed the completed event id from the state 'sirenIconClickTimeout'

    // Note:
    // this.state.sirenState - shows which levels have siren flag active
    // namedAreaParentsPriorityByLevel - all named areas on a level
    // uniqueParentsNamedAreaEventsSirenActive - named areas which have active siren

    // convert {id:priority} to array strings to make comparison easier
    const stringAllOnLevel = namedAreaParentsPriorityByLevel?.map(
      (item) => `${item.id}:${item.priority}`
    );

    const stringActiveSiren = uniqueParentsNamedAreaEventsSirenActive?.map(
      (item) => `${item.id}:${item.priority}`
    );

    // handleSirenIconClick is toggle action
    const isSirenActive = this.state.sirenState?.includes(id);

    let clickList = [];

    if (isSirenActive) {
      // toggle off so 'click' on all the buttons which are active
      // i.e. uniqueParentsNamedAreaEventsSirenActive

      clickList = stringAllOnLevel.filter((item) =>
        stringActiveSiren.includes(item)
      );
    } else {
      // toggle on so 'click' on all the buttons which are inactive
      // i.e. in namedAreaParentsPriorityByLevel but not uniqueParentsNamedAreaEventsSirenActive

      clickList = stringAllOnLevel.filter(
        (item) => !stringActiveSiren.includes(item)
      );
    }

    //
    // Clicking siren button, passes the array with [{"id:property"},...] to
    // remoteButtonClick reducer state.
    // This updates the 'SingleOperation' (see - src/pages/ControlRoomPage/PreCannedOperationsCustomSOP.js)
    // and fires the handleSirenIconClick() for the React.createRef() defined.
    // After firing the click the button is removed from the remoteButtonClick array state.
    //
    //

    // debug siren icon handler
    if (false) {
      console.log(`handleSirenIconClick id`, id);
      console.log(`handleSirenIconClick stringAllOnLevel`, stringAllOnLevel);
      console.log(`handleSirenIconClick stringActiveSiren`, stringActiveSiren);
      console.log(
        `handleSirenIconClick this.state.sirenState`,
        this.state.sirenState
      );
      console.log(`handleSirenIconClick isSirenActive`, isSirenActive);
      console.log(`handleSirenIconClick clickList`, clickList);
      console.log(
        `handleSirenIconClick -----------------------------------------------------`
      );
    }

    this.props.updateRemoteButtonClick(clickList);
  };

  handleTimeoutLightControlSim = (timerProps) => {
    const sampleRandomButton = _.sample(this.state.lightControlSimData);
    this.props.updateRemoteButtonClick([sampleRandomButton]);
  };

  onChangeLightControlSimCheckbox = (evt, data) => {
    let checked = data.checked;

    this.setState({
      isLightControlSim: checked,
    });
  };

  render() {
    const {
      showAreaPreview,
      stopAreaPreview,
      displaySettings,
      styleInfo,
      namedAreasParents,
      mineLevelId,
      namedAreaGroup,
      strings,
      userSettings,
    } = this.props;

    // ===========isLightControlSim==================

    const { isLightControlSim, lightControlSimRate } = this.state;

    const settingsLightControlSim = {
      start: (10 * 1000) / 1000,
      min: 1,
      max: 60,
      step: 1,
      onChange: (value) => {
        this.setState({
          lightControlSimRate: value * 1000,
        });
      },
    };

    const simpleClickSimulator = () => {
      return (
        <>
          <div
            style={{
              paddingLeft: "1em",
            }}
          >
            <Grid>
              <Grid.Row>
                <Segment>
                  <Header as="h3">Light Control Simulator</Header>
                  <Popup
                    content={
                      "Light Control simulator 'clicks' on random buttons at the rate set with the slider."
                    }
                    trigger={
                      <Checkbox
                        label={"Enable Lighting Control button click simulator"}
                        toggle
                        //defaultChecked
                        checked={isLightControlSim}
                        onClick={(evt, data) =>
                          this.onChangeLightControlSimCheckbox(evt, data)
                        }
                      />
                    }
                  />
                  <Header as="h4" style={{ paddingLeft: "5px" }}>
                    {`Button click rate ${
                      this.state.lightControlSimRate / 1000
                    } seconds`}
                  </Header>
                  <Slider
                    discrete
                    color="blue"
                    inverted={false}
                    settings={settingsLightControlSim}
                  />
                </Segment>
              </Grid.Row>
            </Grid>
            {isLightControlSim && (
              <Heartbeat
                heartbeatFunction={this.handleTimeoutLightControlSim}
                heartbeatInterval={lightControlSimRate}
              />
            )}
          </div>
        </>
      );
    };

    const getStringUniqueParentsNamedAreaEvents_Source_EventActive = (
      namedAreasEvents_Source_Active
    ) => {
      // get the unique named area parents objects where the _SOURCE_ is active.
      // e.g. source could be the siren, external trigger event, scheduled event job
      //
      // e.g.  [{id: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad:1633993784425', area: 'IOT_Office', parent: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad', priority: 12, active: true, …}, ...]
      // after .map()
      // e.g. [{id: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad', priority: 12},...]
      // Note - we are comparing parents here
      const uniqueParentnamedAreasEvents_Source_ActiveArray = new Set();
      // _Source_
      const uniqueParentsNamedAreaEvents_Source_Active =
        namedAreasEvents_Source_Active
          .filter((event) => {
            if (
              uniqueParentnamedAreasEvents_Source_ActiveArray.has(event.parent)
            ) {
              return false;
            }
            uniqueParentnamedAreasEvents_Source_ActiveArray.add(event.parent);
            return true;
          })
          .map((result) => {
            return {
              id: result?.parent,
              priority: result?.priority,
            };
          });

      const stringUniqueParentsNamedAreaEvents_Source_Active =
        uniqueParentsNamedAreaEvents_Source_Active.map((item) => {
          return `${item.id}:${item.priority}`;
        });

      return {
        stringUniqueParentsNamedAreaEvents_Source_Active,
        uniqueParentsNamedAreaEvents_Source_Active,
      };
    };

    // =============================

    const { viewables, isShowLightControlSim } = this.state;

    // railway_application
    const boomgateCrane = isBoomgateCrane() || false;

    const rowGetter = ({ index }) => this._getDatum(viewables, index);

    // #NOTE #REVIEW #TODO - make common function
    //

    const { view } = displaySettings;
    const { fontSize, paddingBottom } = styleInfo;

    // default row height
    let rowHeight = 50;
    switch (view) {
      case "compressed":
        rowHeight = 30;
        break;
      case "normal":
        rowHeight = 50;
        break;
      case "expanded":
        rowHeight = 80;

        break;
      default:
        rowHeight = 50;
        break;
    }

    // #NOTE - style does not work on Message.header so pass different classes
    // to set the font size.
    // Not pretty, but it works.
    //
    // default header font size
    let headerFontSize = "10"; // i.e. 1.0em
    switch (fontSize) {
      case 0.6:
        headerFontSize = "08";
        break;
      case 1:
        headerFontSize = "12";
        break;
      case 1.6:
        headerFontSize = "20";
        break;
      default:
        headerFontSize = "10";
        break;
    }

    const showEventButtons = isShowEventButtons() === true;

    let sopRows = [];
    if (namedAreaGroup !== undefined) {
      sopRows = namedAreaGroup;
      // #WIP - FIX THIS! re-loads SOP list continuously
      // console.log(
      //   "loading namedAreaGroup ....",
      //   JSON.stringify(namedAreaGroup)
      // );
    } else if (isConfigJs() && controlScreenSOPdata()) {
      sopRows = controlScreenSOPdata();
      if (showEventButtons) {
        console.log("SOP sopRows", sopRows);
      }
      if (!_isEmpty(sopRows)) {
        sopRows = sopRows.find((item) => item.area === mineLevelId)?.data;
      }
    }

    const sopRowStyle = {
      fontSize: `${fontSize} rem`,
      marginRight: "3px",
      paddingBottom: `0.1em`,
    };

    let content = [];

    // APP_TERMINOLOGY
    const strNamedArea = "Polygon";
    const strNamedAreas = "Polygons";

    const inactiveSirenPopupLabel =
      "Click to activate all polygons on this level, and the sirens on Controllers connected to FireFlys in these polygons. ";
    const activeSirenPopupLabel =
      "Click to deactivate all polygons (and controller sirens) on this level";

    if (!_isEmpty(sopRows)) {
      if (!_isEmpty(namedAreasParents)) {
        sopRows.forEach((row, index) => {
          // Review Access Privileges for the user
          //
          // console.log(`xxx row`, row);
          const userAccessThisGroup = userSettings?.namedAreaGroup?.find(
            (nag) => nag.id === row.id
          )?.access || ["r", "w", "x"];

          // console.log("xxx userAccessThisGroup", userAccessThisGroup);

          let disabled = true; // "x"
          let hide = true; // "r"

          if (userAccessThisGroup.includes("r")) hide = false;
          if (userAccessThisGroup.includes("x")) disabled = false;

          const { label, subItems } = row;
          !hide &&
            content.push(
              <Message>
                <Message.Header
                  className={`messageRowHeaderFontSize-${headerFontSize}`}
                >
                  {label}
                </Message.Header>
                <Message.Content>
                  <Grid>
                    {_isEmpty(subItems) ? (
                      <Grid.Row>
                        <Grid.Column>
                          {
                            strings?.[
                              "There are no levels defined for this group."
                            ]
                          }
                        </Grid.Column>
                      </Grid.Row>
                    ) : (
                      subItems.map(
                        ({ id, label, settings, subItems }, index) => {
                          //console.log(`xxx label, subItems`, label, subItems);

                          // #NOTE - sets the width of the buttons to be same for each level.
                          // Uses the length of the custom label (settings.title) if it exists,
                          // else uses default label (i.e. named area parent name)

                          let maxLabelLength = Math.max(
                            ...subItems.map((items) => {
                              return items?.settings?.title?.length > 0
                                ? items?.settings?.title?.length
                                : items?.label?.length;
                            })
                          ); // minimum label lengths

                          // get the named area parents and their priority for the level
                          // e.g. [{id: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad', priority: 12}, ...]

                          // This is needed ........................
                          const namedAreaParentsPriorityByLevel = subItems
                            ?.filter((subItem) => subItem.parent === id)
                            ?.map((subItem) => {
                              return {
                                id: subItem?.namedArea?.id,
                                priority: subItem?.namedArea?.button?.priority,
                              };
                            });

                          // #NOTE - this returns namedAreaParentsPriorityByLevel as a string array.
                          // Not used ATM but may be useful later
                          const namedAreaParentsPriorityByLevelString = subItems
                            ?.filter((subItem) => subItem.parent === id)
                            ?.map(
                              (subItem) =>
                                `${subItem?.namedArea?.id}:${subItem?.namedArea?.button?.priority}`
                            );

                          // ****************************************************************************************************
                          //
                          // Show siren icon active?
                          // siren is active if there is a namedAreaParent with relay_event_active = 1 in the level
                          //
                          // ****************************************************************************************************

                          // #NOTE #WIP #TODO
                          // CHECK THIS 1ST BIT IS SET, RATHER THAN FOR "1" BELOW
                          // \/\/\/\/\/\/\/\/
                          //

                          // check if there are any namedAreaEvents active with relay_event_active = 1
                          const namedAreasEventsSirenActive =
                            this.props.namedAreaEvents.filter(
                              (event) =>
                                event.active && event?.relay_event_active === 1
                            );

                          const {
                            stringUniqueParentsNamedAreaEvents_Source_Active:
                              stringUniqueParentsNamedAreaEventsSirenActive,
                            uniqueParentsNamedAreaEvents_Source_Active:
                              uniqueParentsNamedAreaEventsSirenActive,
                          } = getStringUniqueParentsNamedAreaEvents_Source_EventActive(
                            namedAreasEventsSirenActive
                          );

                          // #NOTE _ MAKE THIS INTO A FUNCTION!!!!!!!!!!!

                          // get the unique named area parents objects where siren is active
                          // e.g.  [{id: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad:1633993784425', area: 'IOT_Office', parent: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad', priority: 12, active: true, …}, ...]
                          // after .map()
                          // e.g. [{id: 'IOT_Office:a0836b4b-7d06-45b5-9c62-e4bb182f04ad', priority: 12},...]
                          // Note - we are comparing parents here
                          // const uniqueParentnamedAreasEventsSirenActiveArray =
                          //   new Set();
                          // const uniqueParentsNamedAreaEventsSirenActive =
                          //   namedAreasEventsSirenActive
                          //     .filter((event) => {
                          //       if (
                          //         uniqueParentnamedAreasEventsSirenActiveArray.has(
                          //           event.parent
                          //         )
                          //       ) {
                          //         return false;
                          //       }
                          //       uniqueParentnamedAreasEventsSirenActiveArray.add(
                          //         event.parent
                          //       );
                          //       return true;
                          //     })
                          //     .map((result) => {
                          //       return {
                          //         id: result?.parent,
                          //         priority: result?.priority,
                          //       };
                          //     });

                          // const stringUniqueParentsNamedAreaEventsSirenActive =
                          //   uniqueParentsNamedAreaEventsSirenActive.map((item) => {
                          //     return `${item.id}:${item.priority}`;
                          //   });

                          // So in summary:
                          // per level .....
                          //
                          // this.state.sirenState - shows which levels have siren flag active
                          //
                          // namedAreaParentsPriorityByLevel - all named areas on a level
                          // uniqueParentsNamedAreaEventsSirenActive - named areas which have active siren
                          //

                          // test - are namedAreas in 'namedAreaParentsPriorityByLevel' active events in 'uniqueParentsNamedAreaEventsSirenActive'
                          //const isSirenActive = this.state.sirenState?.includes(id);

                          // isActiveSiren - indicates if this button has an active siren event
                          let isSirenActive = false;

                          isSirenActive = namedAreaParentsPriorityByLevel
                            ?.map((item) => {
                              return uniqueParentsNamedAreaEventsSirenActive
                                .map((it) => {
                                  return (
                                    it.id === item.id &&
                                    it.priority === item.priority
                                  );
                                })
                                .includes(true);
                            })
                            .includes(true);

                          // update local state siren buttons
                          // e.g. ["<level_id>",... ]
                          let newSirenState = [...this.state.sirenState];
                          if (newSirenState?.includes(id) && !isSirenActive) {
                            newSirenState = newSirenState.filter(
                              (siren) => siren !== id
                            );
                            this.setState({ sirenState: newSirenState });
                          } else if (
                            isSirenActive &&
                            !newSirenState?.includes(id)
                          ) {
                            newSirenState.push(id);
                            this.setState({ sirenState: newSirenState });
                          }

                          // isShowSiren is a flag from the config for the level indicating whether to allow display of
                          // the siren icon - from config for level
                          const isShowSiren = settings?.siren_state == "on";

                          // check toggleIdState
                          // toggle button -> ON e.g. "['9a27a19d-d6ac-4f8b-a9cb-ce4da4934741:true']"
                          // toggle button -> OFF e.g. "['9a27a19d-d6ac-4f8b-a9cb-ce4da4934741:false']"

                          let isSirenIconActive = false;

                          const toggleIdStateON =
                            this.state.sirenIconClickTimeout?.includes(
                              `${id}:true`
                            );

                          const toggleIdStateOFF =
                            this.state.sirenIconClickTimeout?.includes(
                              `${id}:false`
                            );

                          // Whether or not to show the siren icon active (i.e. flag isSirenIconActive),
                          // depends on
                          // #1 - if there is an active siren event in the row (isShowSiren)
                          // #2 - if the icon button has been click to change the state. (toggleIdStateON or toggleIdStateOFF)
                          //
                          // If clicked, then force a timeout to an OFF state or an ON state.
                          //
                          // if the siren icon is active
                          if (isSirenActive) {
                            // and there is no entry in `sirenIconClickTimeout` to change state of the icon
                            if (!toggleIdStateOFF && !toggleIdStateON) {
                              // icon is active
                              isSirenIconActive = true;
                            } // but if the icon has been toggled OFF
                            else if (toggleIdStateOFF && !toggleIdStateON) {
                              // set the icon as inactive
                              isSirenIconActive = false;
                            }
                            // !isSirenActive
                          } else {
                            if (!toggleIdStateOFF && !toggleIdStateON) {
                              isSirenIconActive = false;
                            } else if (!toggleIdStateOFF && toggleIdStateON) {
                              isSirenIconActive = true;
                            }
                          }

                          // ****************************************************************************************************
                          //
                          // Show external_trigger_event icon active?
                          // external_trigger_event is active if there is a namedAreaParent with origin = "external_trigger_event" in the level
                          //
                          // ****************************************************************************************************

                          // check if there are any namedAreaEvents active with relay_event_active = 1
                          const namedAreasEventsExternalTriggerEventActive =
                            this.props.namedAreaEvents.filter(
                              (event) =>
                                event.active &&
                                ["ext_trigger_event"]?.includes(event?.origin)
                            );

                          const {
                            stringUniqueParentsNamedAreaEvents_Source_Active:
                              stringUniqueParentsNamedAreaEventsExternalTriggerEventActive,
                            uniqueParentsNamedAreaEvents_Source_Active:
                              uniqueParentsNamedAreaEventsExternalTriggerEventActive, // Not used for external trigger events
                          } = getStringUniqueParentsNamedAreaEvents_Source_EventActive(
                            namedAreasEventsExternalTriggerEventActive
                          );

                          // ****************************************************************************************************
                          //
                          // Show scheduled_event icon active?
                          // external_trigger_event is active if there is a namedAreaParent with origin = "external_trigger_event" in the level
                          //
                          // ****************************************************************************************************

                          // check if there are any namedAreaEvents active with relay_event_active = 1
                          const namedAreasEventsScheduledEventActive =
                            this.props.namedAreaEvents.filter(
                              (event) =>
                                event.active &&
                                ["scheduled_event"]?.includes(event?.origin)
                            );

                          const {
                            stringUniqueParentsNamedAreaEvents_Source_Active:
                              stringUniqueParentsNamedAreaEventsScheduledEventActive,
                            uniqueParentsNamedAreaEvents_Source_Active:
                              uniqueParentsNamedAreaEventsScheduledEventActive, // Not used for scheduled events
                          } = getStringUniqueParentsNamedAreaEvents_Source_EventActive(
                            namedAreasEventsScheduledEventActive
                          );

                          // *************************************************************************************

                          return (
                            <Grid.Row
                              style={
                                index === 0
                                  ? { ...sopRowStyle, ...{ paddingTop: `2em` } } // more space on top row
                                  : {
                                      ...sopRowStyle,
                                      ...{ paddingTop: `0.1em` },
                                    }
                              }
                            >
                              <Grid.Column width={!boomgateCrane ? 4 : 6}>
                                <Grid>
                                  <Grid.Row>
                                    <Grid.Column width={"2"}>
                                      {/* see - src/pages/ControlRoomPage/PreCannedOperationsCustomSOP.js "<MediaQuery minWidth={isDesktopOrLaptop}>" */}
                                      {isShowSiren && (
                                        <Popup
                                          content={
                                            isSirenIconActive
                                              ? activeSirenPopupLabel
                                              : inactiveSirenPopupLabel
                                          }
                                          trigger={
                                            <Button
                                              icon={"bullhorn"}
                                              size={"mini"}
                                              basic={!isSirenIconActive}
                                              color={
                                                isSirenIconActive
                                                  ? "purple"
                                                  : null
                                              }
                                              buttonInfo={{
                                                id,
                                                namedAreaParentsPriorityByLevel,
                                                uniqueParentsNamedAreaEventsSirenActive,
                                                isSirenIconActive,
                                              }}
                                              onClick={(e, data) =>
                                                this.handleSirenIconClick(data)
                                              }
                                              disabled={disabled}
                                            />
                                          }
                                        />
                                        // <Popup
                                        //   content="Polygons on this level activate the siren on controllers connected to the FireFlies in each region"
                                        //   trigger={<Icon name={"bullhorn"} />}
                                        // />
                                      )}
                                    </Grid.Column>
                                    <Grid.Column width={"13"}>
                                      <div
                                        style={{
                                          paddingLeft: "10px",
                                          paddingRight: "0.5rem",
                                          fontSize: `${1 * fontSize}rem`,
                                          fontWeight: "bold",
                                          paddingTop: `0.1em`,
                                          paddingBottom: `${paddingBottom}em`,
                                        }}
                                        as="h3"
                                      >
                                        {label}
                                      </div>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column
                                width={!boomgateCrane ? 12 : 7}
                                // #NOTE #BUTTON_STYLE - styling to display button wrapped on level
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {_isEmpty(subItems) ? (
                                  <Grid.Row>
                                    <Grid.Column>
                                      There are no {strNamedAreas} defined for
                                      this level.
                                    </Grid.Column>
                                  </Grid.Row>
                                ) : (
                                  subItems.map(
                                    ({ label, settings, namedArea }) => {
                                      let operation = [];
                                      // #WIP - testing setup button in namedAreaGroups
                                      //
                                      // ...current process working process
                                      if (false) {
                                        operation = namedAreasParents.find(
                                          (na) => na.id === namedArea.id
                                        )?.button; // #REVIEW #TODO ##FIXTHIS - buttons returns [[]] as elsewhere buttons.map() works. Why is this necessary?
                                      }

                                      // if mqtt messages are not available on startup, or slow to load, then
                                      // `operation` may not be defined
                                      if (operation === undefined) {
                                        return null;
                                      } else {
                                        // console.log(
                                        //   "operation kkk namedAreasParents",
                                        //   namedAreasParents
                                        // );
                                        // console.log(
                                        //   "operation kkk namedArea",
                                        //   namedArea
                                        // );
                                        // console.log(
                                        //   "operation kkk operation",
                                        //   JSON.stringify(operation)
                                        // );

                                        // compose content to pass
                                        // this is same as in PrecannedOperations.js
                                        //

                                        //#WIP  - hack out a groupsArray
                                        let newGroupsArray = [];
                                        const priority =
                                          namedArea?.button?.priority;
                                        const button = namedArea?.button;
                                        if (priority !== undefined) {
                                          for (
                                            let index = 0;
                                            index <= priority;
                                            index++
                                          ) {
                                            newGroupsArray[index] = {
                                              ...button,
                                            };
                                            newGroupsArray[index].priority =
                                              index;
                                          }
                                        }

                                        // console.log("newGroupsArray", newGroupsArray);
                                        // console.log(
                                        //   "newGroupsArray old operation",
                                        //   operation
                                        // );

                                        // use settings.title if it is defined
                                        const buttonLabel = settings?.title
                                          ? settings.title
                                          : label;

                                        if (
                                          buttonLabel?.length > maxLabelLength
                                        )
                                          maxLabelLength = buttonLabel.length;

                                        const area = {
                                          groupsArray: [newGroupsArray], // operation, // [newGroupsArray], // #WIP - ????????????////
                                          name: buttonLabel,
                                          spec: namedArea.id,
                                          type: "Polygon",
                                          id: mineLevelId,
                                          namedArea: label,
                                        };

                                        let option = {};

                                        // #WIP - testing setup button in namedAreaGroups
                                        //
                                        // ...current process working process
                                        if (false) {
                                          option =
                                            operation[0][namedArea.priority]; // // #REVIEW #TODO ##FIXTHIS - buttons returns [[]] as elsewhere buttons.map() works. Why is this necessary?
                                        } else {
                                          option = { ...namedArea?.button }; // #WIP - ????????????////
                                        }

                                        // 'option' comes from the button definition that is created when the namedAreaGroup is defined.
                                        // The button definition does not know the event state of relay_active.
                                        // So from event checks set the option.relay_active property.

                                        // #WIP #TODO - this needs to be expanded to other relay parameters
                                        // pass bits for relay registers
                                        // isSirenActive - level only
                                        if (
                                          stringUniqueParentsNamedAreaEventsSirenActive.includes(
                                            `${namedArea.id}:${option.priority}`
                                          )
                                        ) {
                                          option.relay_active = 1; // bit 1 = siren
                                        } else {
                                          option.relay_active = 0;
                                        }

                                        // see - `sendNamedAreaEventParent`

                                        // #DOGS_BREAKFAST!!!!!!!!!!!!!!!!
                                        // The origin and operator is unknown to the button so this is an attempt to match
                                        // the reocrds. This should be reviewed in detail.

                                        //
                                        // #WIP #TODO ---
                                        // Find the matching event and get the current origin and operator to update the record.
                                        // Similar to the siren icon.
                                        // Generalise??????????
                                        //

                                        if (
                                          stringUniqueParentsNamedAreaEventsExternalTriggerEventActive.includes(
                                            `${namedArea.id}:${option.priority}`
                                          )
                                        ) {
                                          // Similarly, the button definition does not know the current Origin and Operator.
                                          // Overwrite the default settings for active user information.
                                          //
                                          option.origin = "ext_trigger_event";
                                          option.operator = "ext_trigger_event";
                                        } else if (
                                          stringUniqueParentsNamedAreaEventsScheduledEventActive.includes(
                                            `${namedArea.id}:${option.priority}`
                                          )
                                        ) {
                                          // Similarly, the button definition does not know the current Origin and Operator.
                                          // Overwrite the default settings for active user information.
                                          //
                                          option.origin = "scheduled_event";
                                          option.operator = "scheduled_event";
                                        } else {
                                          // Similarly, the button definition does not know the current Origin and Operator.
                                          // Overwrite the default settings for active user information.
                                          //
                                          option.origin = "CLIENT"; // #TODO #REVIEW -
                                          option.operator =
                                            this.props.userSessionIp; // #TODO #REVIEW - from the user session information
                                        }

                                        // title of the button
                                        const title = buttonLabel;

                                        if (option?.type === undefined)
                                          return null; // #WIP - ????????????////

                                        // ****************
                                        return (
                                          <SingleOperation
                                            buttonRef={`${namedArea.id}:${option.priority}`}
                                            key={`${area.name}-${option.type}-${option.priority}`}
                                            buttonInfo={{
                                              enable: option.active,
                                              group: option.group,
                                              index: option.priority, //namedArea.priority, // #WIP - ????????????////
                                            }}
                                            area={area}
                                            operation={option}
                                            title={title}
                                            displaySettings={displaySettings}
                                            maxLabelLength={maxLabelLength}
                                            disabled={disabled}
                                            userSettings={userSettings}
                                          />
                                        );
                                      }
                                    }
                                  )
                                )}
                              </Grid.Column>
                            </Grid.Row>
                          );
                        }
                      )
                    )}
                  </Grid>
                </Message.Content>
              </Message>
            );
        });
      }
    } else {
      content.push(
        <Message
          header={this.props.strings?.["No Lighting Control Layout"]}
          content={this.props.strings?.["NO_LIGHTING_CONTROL_LAYOUT_MESSAGE"]}
        />
      );
    }
    return (
      <>
        {content}
        {isShowLightControlSim && simpleClickSimulator()}
        {showEventButtons && (
          <AutoSizer>
            {({ width, height }) => {
              return (
                <VTable
                  ref="Table"
                  disableHeader
                  rowCount={viewables.length}
                  rowHeight={rowHeight}
                  rowGetter={rowGetter}
                  width={width}
                  height={height - rowHeight}
                  onRowMouseOver={({ rowData: { area } }) => {
                    showAreaPreview && showAreaPreview(area);
                  }}
                  onRowMouseOut={({ rowData: { area } }) => {
                    stopAreaPreview && stopAreaPreview(area);
                  }}
                >
                  <Column
                    width={1}
                    flexGrow={1}
                    label="Name"
                    dataKey="area.name"
                    style={{ fontSize: `${fontSize}rem` }}
                    cellRenderer={({ rowData }) => {
                      return rowData.area.name;
                    }}
                  />
                  <Column
                    width={1}
                    flexGrow={2}
                    label="Actions"
                    dataKey="area"
                    cellRenderer={({ rowData }) => {
                      return (
                        <PrecannedOperations
                          area={rowData.area}
                          displayAllOnMineLevel={true}
                        />
                      );
                    }}
                  />
                </VTable>
              );
            }}
          </AutoSizer>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { mineLevelId } = props;

  const area = getAreaStatusesById(state, mineLevelId) || {};
  const { namedAreaGroup } = area;

  //console.log("namedAreaGroup", namedAreaGroup);

  const namedAreasParents = getNamedAreaParentStatusesByAreaId(
    state,
    mineLevelId
  );

  const levelWideNamedArea = getNamedAreaStatusesById(state, mineLevelId);

  // id -> id
  // mineLevelId -> area
  // areaName -> namedArea

  const displaySettings = getNamedAreaDisplaySettings(state);

  // support for sirens
  const namedAreaEvents = getNamedAreaEventsByAreaId(state, mineLevelId);

  const userSessionIp = getUserSessionIp();
  const userSettings = getUserSettings(state);

  return {
    namedAreasParents,
    levelWideNamedArea,
    //namedAreas: tempData_min, // makeGetNamedAreasForLevel(state, props), // tempData // #REVIEW - delete, old implementation left in for testing
    displaySettings,
    namedAreaGroup,
    namedAreaEvents,
    userSessionIp,
    userSettings,
  };
};

export default connect(mapStateToProps, {
  showAreaPreview,
  stopAreaPreview,
  updateRemoteButtonClick,
})(MineLevelCustomSOPList);
