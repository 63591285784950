import React from 'react'
// import { Container, Header, Image } from 'semantic-ui-react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

const PageNotImplemented = ({
  description = 'no description provided',
  location
}) => (
  <div>
    <Header as='h3'>Page not yet implemented</Header>
    <p>This page is not yet implemented. Description: {description}</p>
    <p>
      Route match for <code>{location.pathname}</code>
    </p>
  </div>
)

export default PageNotImplemented
