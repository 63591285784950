import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Container,
  Button,
  Grid,
  Pagination,
  Dropdown,
  Segment,
  Form,
  Table,
  Input,
  FormField,
} from "semantic-ui-react";

import { renderField } from "admin/form-field";

import { TrailingContent } from "components/TableTrailingContent";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging
import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

const validate = (values) => {
  const { mineLevelId, areaName, regions } = values;

  const errors = {};

  if (!mineLevelId) {
    errors.mineLevelId = "Required";
  }
  if (!areaName) {
    errors.areaName = "Required";
  }

  if (_isEmpty(regions)) {
    errors.regions = "Required";
  }

  return errors;
};

const normalise = (value, type) => {
  if (type === "noSpaces") {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

const required = (value) => (value ? undefined : "Required");

class LocalisationEditForm extends Component {
  constructor(props) {
    super(props);
    const downloadData = [];
    this.state = {
      downloadData,
      // initialise data list sort columns
      column: null,
      data: props?.initialValues?.data || [],
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search

        reference: "",
        localisation: "",
        english: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    // #TODO - DO THIS EVERYWHERE!!!!!!!!!!!! - MAKE A FUNCTION!
    // make sure only current filter settings are updated
    const newFilterInput = this.props.componentstate.get(
      "filterInput",
      `localisationFormList-${this.props.id}`
    );

    let filterInput = { ...this.state.filterInput };

    for (const key in filterInput) {
      if (Object.hasOwnProperty.call(filterInput, key)) {
        filterInput[key] = newFilterInput?.[key] || "";
      }
    }

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: `localisationFormList-${this.props.id}`,
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props?.initialValues?.data) !==
        JSON.stringify(prevProps.initialValues?.data) // if original props data changes
    ) {
      let newData = [...this.props?.initialValues?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch || undefined ? true : false; // unlocalised terms return as 'undefined'
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      if (direction === "descending") {
        newData = newData.reverse();
      }

      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  resetForm = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // https://github.com/Asymmetrik/ngx-leaflet/issues/88
    // unmount error - "TypeError: Cannot read property 'off' of undefined"

    // abort page changes
    //this.props.reset();
    this.props.onCancel();
  };

  submitMyForm = (values) => {
    //console.log(`LocalisationEditForm submitted: values`, values);

    this.props.onSubmit(values);
  };

  requestDelete = (id) => {
    const { change } = this.props;

    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete");
    //this.props.onDelete({ id, tagUserType });
  };

  render() {
    const { handleSubmit, pristine, submitting, strings } = this.props;

    const { isLoading, error } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "localFormlist10" },
              { value: 20, text: "20", key: "localFormlist20" },
              { value: 40, text: "40", key: "localFormlist40" },
              { value: 60, text: "60", key: "localFormlist60" },
              { value: data.length, text: "all", key: "localFormlistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <>
        <Form
          onSubmit={handleSubmit(this.submitMyForm)}
          style={{ marginTop: "14px" }}
        >
          <Segment>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column></Grid.Column>
                <Grid.Column>
                  <Grid.Row>
                    <Button.Group floated="right">
                      <SaveButton
                        submitting={submitting}
                        pristine={pristine}
                        strings={strings}
                        size="large"
                      />
                      <Button.Or />
                      <Button
                        type="button"
                        disabled={pristine || submitting}
                        onClick={(e) => this.resetForm(e)}
                        size="large"
                      >
                        Reset
                      </Button>
                      <Button.Or />
                      <Button
                        style={{ textAlign: "right" }}
                        onClick={(e) => this.cancelForm(e)}
                        size="large"
                      >
                        Cancel
                      </Button>
                    </Button.Group>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.reference ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="reference"
                    onChange={this.handleFilterChange}
                    value={filterInput?.reference}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.english ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="english"
                    onChange={this.handleFilterChange}
                    value={filterInput?.english}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.localisation ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="localisation"
                    onChange={this.handleFilterChange}
                    value={filterInput?.localisation}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "reference" ? direction : null}
                  onClick={this.handleSort("reference")}
                >
                  Reference
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={5}
                  sorted={column === "english" ? direction : null}
                  onClick={this.handleSort("english")}
                >
                  en-US
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "localisation" ? direction : null}
                  onClick={this.handleSort("localisation")}
                >
                  Localisation
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(viewablesPage, ({ reference, english, localisation }) => (
                <Table.Row>
                  <Table.Cell>{reference}</Table.Cell>
                  <Table.Cell>{english}</Table.Cell>
                  <Table.Cell>
                    <Field
                      name={`fieldData["${reference}"]`}
                      type="text"
                      component={renderField}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
        </Form>
        <DebugPagePropsMessages that={this} />
      </>
    );
  }
}

LocalisationEditForm = reduxForm({
  form: "localisationEditForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(LocalisationEditForm);

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues("localisationEditForm")(state), // #REVIEW - only used for debugging,
    // onChange: (values, dispatch, props, previousValues) => {
    //   console.log("LocalisationEditForm onChange values", values);
    // },
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, { saveUserSettingsComponentState })(
    LocalisationEditForm
  )
);
