// see - src/components/RegionPreview/reducer.js
// see main reducer at src/reducers.js
//

import Immutable from "seamless-immutable";

import _isEmpty from "lodash/isEmpty";

let initialState = Immutable({
  localMapState: {},
  movingMap: false, // state of current map. If moving (zoom, drag etc.) don't update data
  draggingMarker: false,
});

export default function reduce(state = initialState, action = {}) {
  // copy existing state
  const newState = { ...state };

  const { type, payload } = action;
  switch (type) {
    case "UPDATE_LOCAL_MAP":
      newState.localMapState = payload;
      return newState;
    case "CLEAR_LOCAL_MAP":
      return initialState;
    case "UPDATE_MOVING_MAP":
      // state of current map. If moving (zoom, drag etc.) don't update data.
      // Also used to track activity on polygon edits.
      newState.movingMap = payload;
      return newState;

    case "UPDATE_DRAGGING_MARKER":
      // Used to track markers being dragged/edited
      newState.draggingMarker = payload;
      return newState;

    case "RESET":
      console.log("RESET! - Map");
      return initialState; //Always return the initial state

    default:
      return state;
  }
}

export const GetLocalMap = (state) => state.map.localMapState;

export const isMapMoving = (state) => state.map.movingMap;

export const isDraggingMarker = (state) => state.map.draggingMarker;

// Selectors

// subset (reduced) of properties for each named area
// used to directly compare named areas objects excluding geometry etc.
export const GetLocalMapNamedAreasReducedProperties = (state) => {
  const namedAreas = state.map.localMapState?.namedAreas?.features;

  let reducedProperties = [];

  if (namedAreas !== undefined && !_isEmpty(namedAreas)) {
    namedAreas.forEach(({ properties }, index, arr) => {
      const {
        id,
        name,
        parent,
        origin,
        area,
        priority,
        button,
        coordinatesUtm,
      } = properties;

      const reducedProperty = {
        id: id,
        name: name,
        parent: parent,
        origin: origin,
        area: area,
        priority: priority,
        button: button,
        coordinatesUtm: coordinatesUtm,
      };

      reducedProperties.push(reducedProperty);
    });
  }

  return reducedProperties;
};
