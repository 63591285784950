import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router";

import { push } from "react-router-redux";
import {
  Form,
  Container,
  Button,
  Popup,
  Icon,
  Segment,
  Header,
  Message,
  Grid,
  Tab,
  Divider,
  Image,
  Table,
} from "semantic-ui-react";

import _ from "lodash";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging
import { LayersControl } from "react-leaflet";
import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroupGeoJson";
import { renderField, renderTextArea, renderSelect } from "admin/form-field";
import { SaveButton } from "admin/SaveButton";
import isEqual from "lodash/isEqual";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { tagTrackingUserDetailsCardPersonnel } from "admin/tag-tracking/TagTrackingUserDetailsCardPersonnel";
import { tagTrackingUserDetailsCardVehicle } from "admin/tag-tracking/TagTrackingUserDetailsCardVehicle";
import { getTagPersonnel, getTagVehicles } from "components/Tags/reducer";

const required = (value) => (value ? undefined : "Required");
const tagTypeOptions = [
  {
    key: "personnel",
    text: "Personnel",
    value: "personnel",
  },
  {
    key: "vehicle",
    text: "Vehicle",
    value: "vehicle",
  },
];

// function MapPositioningDisplay({ mineLevelId, children }) {
//   if (!mineLevelId) {
//     return <div>No mine level selected</div>;
//   }
//   return (
//     <MineLevelMapById
//       mineLevelId={mineLevelId}
//       style={{
//         height: "60vh",
//         width: "100%",
//         position: "relative",
//         zIndex: 0,
//         // #REVIEW #WIP #TODO - get background from mineLevel
//         //backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
//         //backgroundColor: `rgba(255,255,255,1)`,
//       }}
//     >
//       {children}
//     </MineLevelMapById>
//   );
// }

class TagTrackingDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      errorMessage: "",
      //
      isAssigned: true,
    };
  }

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  componentDidMount() {
    this.setState({ isAssigned: this.props.initialValues?.tagUserId > 0 });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tagUserId !== this.props.formValues.tagUserId &&
      this.props.initialValues?.tagUserId > 0
    ) {
      this.setState({ assigned: this.props.formValues.tagUserId });
    }
  }

  componentWillUnmount() {
    //console.log(` componentWillUnmount`);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only re-render if the firefly object has changed.
    // This stops re-rendering the map unnecessarily

    return (
      !isEqual(this.props.fireflyId, nextProps.fireflyId) ||
      !isEqual(this.props.fireflyNote, nextProps.fireflyNote) ||
      !isEqual(this.props.position, nextProps.position) ||
      !isEqual(this.state.collapse, nextState.collapse) ||
      !isEqual(this.state.errorMessage, nextState.errorMessage) ||
      !isEqual(this.state.tagType, nextState.tagType) ||
      !isEqual(this.props.formValues, nextProps.formValues) ||
      !isEqual(this.props.pristine, nextProps.pristine)
    );
  }

  submitMyForm = (values) => {
    // const { saveTagTrackingSettings, fetchTags } = this.props;
    // return new Promise((resolve, reject) => {
    //   saveTagTrackingSettings({ values: values, resolve, reject });
    // }).then(() => {
    //   // wait for state change before redirecting page
    //   this.setState(
    //     {
    //       submitted: true,
    //     },
    //     () => {
    //       fetchTags(); // #FETCHS
    //       this.props.push(`/status/tag/`);
    //     }
    //   );
    // });

    this.props.onSubmit(values);
  };

  cancelForm = (e) => {
    e.preventDefault();

    this.props.push("/status/tag/");
  };

  requestUnassign = (id) => {
    const { change, tagUserType } = this.props;

    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete"); // 'delete' is an accepted string to bypass the check
    this.props.onUnassign({ id, tagUserType });
  };

  onResetError = () => {
    const { push, submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      push("/");
    }
  };

  onChangeSelectUserType = (evt, data) => {
    //console.log(`onChangeSelectUserType`, data);

    this.props.change("tagUserType", data);
  };

  onChangeSelectUser = (evt, data) => {
    //console.log(`onChangeSelectUser`, data);

    // key = 0 is default "No .... defined" entry
    if (data > 0) {
      this.props.change("tagUserId", data);
      this.props.change("_action", "assign");
    }
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      reset,
      //
      id,
      tagUserId,
      tagUserType,
      formValues,
      tagPersonnelSelectOptions,
      tagVehicleSelectOptions,
      strings,
    } = this.props;

    const { collapse: isCollapse, isAssigned } = this.state;

    const imageFilenameUnknown = "tag-unknown.png";
    const imageFilenameUser = "avatar-construction.png";
    const imageFilenameVehicle = "avatar-dump-truck.png";

    const urlImageUnknown =
      process.env.PUBLIC_URL + "/images/" + imageFilenameUnknown;
    const urlImagePersonnel =
      process.env.PUBLIC_URL + "/images/" + imageFilenameUser;
    const urlImageVehicle =
      process.env.PUBLIC_URL + "/images/" + imageFilenameVehicle;

    // const panes = [
    //   {
    //     menuItem: "Position",
    //     render: () => (
    //       <Tab.Pane>
    //         <MapPositioningDisplay mineLevelId={mineLevelId}>
    //           <TagMoveableMarker change={change} name={"position"}>
    //             {fireflyId}
    //           </TagMoveableMarker>
    //           <LayersControl position={"topright"}>
    //             <LayersControl.Overlay
    //               key={"firefliesOverlay"}
    //               name={"FireFlys"}
    //             >
    //               <CurrentMineLevelFirefliesFeatureGroup />
    //             </LayersControl.Overlay>
    //           </LayersControl>
    //         </MapPositioningDisplay>
    //       </Tab.Pane>
    //     ),
    //   },
    //   {
    //     menuItem: "Latest Report",
    //     render: () => (
    //       <Tab.Pane>{/* <FireflyRawStatusReport id={fireflyId} /> */}</Tab.Pane>
    //     ),
    //   },
    // ];

    //console.log(`xxxxxxxxxxxx formValues`, formValues);
    //console.log(`xxxxxxxxxxxx id`, id);

    const tagUserOptions = (tagUserType) => {
      switch (tagUserType) {
        case "personnel":
          return tagPersonnelSelectOptions;
        case "vehicle":
          return tagVehicleSelectOptions;
        default:
          break;
      }
    };

    const tagUserImage = (tagUserType) => {
      switch (tagUserType) {
        case "personnel":
          return urlImagePersonnel;
        case "vehicle":
          return urlImageVehicle;
        default:
          return urlImageUnknown;
      }
    };

    const tagId = formValues?.tagId; // #NOTE - need to pass the integer Id for the tag for unassign (not the mac)

    return (
      <>
        <Container>
          {error && <div className="ui error message">{error}</div>}
          <Form onSubmit={handleSubmit(this.submitMyForm)}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column stretched width={isCollapse ? 1 : 6}>
                  {isCollapse && (
                    <Segment>
                      <Grid>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Popup
                              content={strings?.["Expand Left Side"]}
                              trigger={
                                <Button
                                  floated={"left"}
                                  icon
                                  onClick={this.expandCollapse}
                                  size={"mini"}
                                  color={"teal"}
                                >
                                  <Icon name={"angle right"} size={"large"} />
                                </Button>
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  )}
                  {!isCollapse && (
                    <>
                      <Segment>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Header as="h2">{"Tag"}</Header>
                            </Grid.Column>
                            <Grid.Column floated={"right"}>
                              <Popup
                                content={"Collapse Left Side"}
                                trigger={
                                  <Button
                                    floated={"right"}
                                    icon
                                    onClick={this.expandCollapse}
                                    size={"mini"}
                                    color={"teal"}
                                  >
                                    <Icon name={"angle left"} size={"large"} />
                                  </Button>
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Divider hidden />
                        <Field
                          name="mac"
                          label="Tag ID"
                          placeholder="Tag ID"
                          component={renderField}
                          className="disabled-form-field"
                          disabled
                        />
                        {/* <Form.Group widths="equal">
                          <Field
                            name="type"
                            label="Type"
                            placeholder="Polywoggle-Doodad"
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />

                          <Field
                            name="rssi"
                            label="RSSI"
                            placeholder="-99"
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                        </Form.Group> */}

                        {!isAssigned && (
                          <>
                            <Message icon color="orange">
                              <Icon name="warning" />
                              <Message.Content>
                                <Message.Header>
                                  Tag is not assigned
                                </Message.Header>
                                {`The tag user/vehicle information is not available. Please assign the tag to a user.`}
                              </Message.Content>
                            </Message>
                            <Segment>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <Image
                                      src={tagUserImage(tagUserType)}
                                      size="small"
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={10}>
                                    <Header as="h3">
                                      Assign the tag to a user
                                    </Header>
                                    <Field
                                      name={`tagUserType`}
                                      label="Select the user type"
                                      component={renderSelect}
                                      options={tagTypeOptions}
                                      onChange={(evt, data) =>
                                        this.onChangeSelectUserType(evt, data)
                                      }
                                      validate={[required]}
                                    />
                                    {tagTypeOptions
                                      ?.map((option) => option.key)
                                      ?.includes(tagUserType) && (
                                      <Field
                                        style={{ paddingTop: "5px" }}
                                        name={`tagUserId`}
                                        label={`Select the ${tagUserType}`}
                                        component={renderSelect}
                                        options={tagUserOptions(tagUserType)}
                                        onChange={(evt, data) =>
                                          this.onChangeSelectUser(evt, data)
                                        }
                                        validate={[required]}
                                      />
                                    )}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          </>
                        )}
                        {isAssigned &&
                          tagUserType === "personnel" &&
                          tagTrackingUserDetailsCardPersonnel(
                            urlImagePersonnel,
                            tagUserId,
                            true
                          )}

                        {isAssigned &&
                          tagUserType === "vehicle" &&
                          tagTrackingUserDetailsCardVehicle(
                            urlImageVehicle,
                            tagUserId,
                            true
                          )}
                        {/* <Field
                          name="lastStatusReport"
                          label="Latest Report"
                          placeholder="Latest Report"
                          component={renderField}
                          className="disabled-form-field"
                          disabled
                        /> */}
                      </Segment>

                      {false && (
                        <Segment>
                          <Field
                            name="tagNote"
                            label="Note"
                            placeholder="...enter notes. This text displays on the marker popup."
                            component={renderTextArea}
                          />
                        </Segment>
                      )}
                    </>
                  )}

                  {/* <Segment>
                  <Form.Group inline>
                    <SaveButton submitting={submitting} pristine={pristine} />
                    <DeleteButton onClick={this.requestDelete} id={fireflyId} />
                    <Form.Button onClick={reset}>Reset</Form.Button>
                  </Form.Group>
                </Segment> */}
                </Grid.Column>

                <Grid.Column width={isCollapse ? 15 : 10}>
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column>
                        <Grid.Row>
                          {isAssigned && (
                            <Popup
                              content="Click to clear the user assigned to this tag"
                              trigger={
                                <Button
                                  type="button"
                                  onClick={() => this.requestUnassign(tagId)}
                                  id={id}
                                  color="orange"
                                >
                                  {strings?.["Unassign"]}
                                </Button>
                              }
                            />
                          )}
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Button.Group floated="right">
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={reset}
                            >
                              {strings?.["Reset"]}
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              {strings?.["Cancel"]}
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Segment>

                  {/* <Tab
                    panes={panes}
                    menu={{
                      borderless: true,
                      attached: false,
                      tabular: false,
                    }}
                  /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <DebugPagePropsMessages that={this} />
        </Container>
      </>
    );
  }
}

const selector = formValueSelector("tagTrackingDetailsForm");

TagTrackingDetailsForm = reduxForm({
  form: "tagTrackingDetailsForm",
  enableReinitialize: false,
  keepDirtyOnReinitialize: false,
  touchOnChange: true,
})(TagTrackingDetailsForm);

const mapStateToProps = (state, props) => {
  const tagPersonnel = getTagPersonnel(state);

  let tagPersonnelSelectOptions = tagPersonnel?.map((item) => {
    const { id, firstname, lastname } = item;
    return { key: id, text: `${firstname} ${lastname}`, value: id };
  });

  if (!tagPersonnelSelectOptions.length)
    tagPersonnelSelectOptions = [
      { key: 0, text: `No personnel defined.`, value: 0 },
    ];

  const tagVehicles = getTagVehicles(state);
  let tagVehicleSelectOptions = tagVehicles?.map((item) => {
    const { id, name, type } = item;
    return { key: id, text: `${name} - ${type}`, value: id };
  });

  if (!tagVehicleSelectOptions.length)
    tagVehicleSelectOptions = [
      { key: 0, text: `No vehicles defined.`, value: 0 },
    ];

  return {
    tagUserType: selector(state, "tagUserType"),
    tagPersonnelSelectOptions: tagPersonnelSelectOptions,
    tagVehicleSelectOptions: tagVehicleSelectOptions,

    onChange: (values, dispatch, props, previousValues) => {
      //console.log("xxxxxxxxxxx onChange values", values);
      //console.log("xxxxxxxxxxx onChange props", props);
    },
    formValues: getFormValues("tagTrackingDetailsForm")(state), // #REVIEW - only used for debugging,
  };
};

TagTrackingDetailsForm = withRouter(
  connect(mapStateToProps, { push })(TagTrackingDetailsForm)
);

export default TagTrackingDetailsForm;
