export function fetchAllUsers() {
  return { type: "ALL_USERS_FETCH_REQUESTED" };
}

export const getAllUsers = (data) => {
  return { type: "GET_ALL_USERS", payload: data };
};

export function saveUser(payload) {
  return { type: "SAVE_USER", payload };
}

export const deleteUser = (id) => {
  return { type: "DELETE_USER", payload: id };
};

export const changeRole = (payload) => {
  return { type: "CHANGE_USER_ROLE", payload };
};

export const changePassword = (payload) => {
  return { type: "CHANGE_USER_PASSWORD", payload };
};

export const changeName = (payload) => {
  return { type: "CHANGE_USER_NAME", payload };
};

export const changeLanguage = (payload) => {
  return { type: "CHANGE_USER_LANGUAGE", payload };
};

export const changeSettings = (payload) => {
  return { type: "CHANGE_USER_SETTINGS", payload };
};

export const saveUserSettingsComponentState = (payload) => {
  return { type: "SAVE_USER_SETTINGS_COMPONENT_STATE", payload };
};
