import React, { Component } from "react";

import { Table } from "semantic-ui-react";

export default class BasicTable extends Component {
  render() {
    const {
      HeaderComponent,
      RowComponent,
      items,
      isLoading,
      error,
      getItemKey = (item, idx) => idx,
      dispatch,
    } = this.props;

    var trailingContent = null;
    if (isLoading) {
      trailingContent = <RowComponent wholeRowMessage={"Loading..."} />;
    } else if (error) {
      trailingContent = <RowComponent wholeRowMessage={error} error />;
    } else if (items.length === 0) {
      trailingContent = <RowComponent wholeRowMessage={"Empty list"} />;
    }
    return (
      <Table sortable celled fixed>
        <Table.Header>
          <HeaderComponent />
        </Table.Header>

        <Table.Body>
          {items.map((item, idx) => {
            return (
              <RowComponent
                item={item}
                key={`row-id-${getItemKey(item, idx)}`}
              />
            );
          })}
          {trailingContent}
        </Table.Body>
      </Table>
    );
  }
}
