import Immutable from 'seamless-immutable'
import { createSelector } from 'reselect'

export function blankSeismic () {
  const state = undefined
  const action = {}
  return Immutable({
    levelDefault: levelDefault(state, action),
    regionSettings: regionsReducer(state, action),
    isSaving: false,
    savingError: ''
  })
}

function regionsReducer (state = Immutable({ byId: {}, priority: [] }), action) {
  const { type, payload } = action

  switch (type) {
    case 'ADD_NEW_REGION':
      return state
        .setIn(['byId', payload.id], payload)
        .set('priority', state.priority.concat([payload.id]))
    case 'CHANGE_REGION_SHAPE':
      return state.setIn(['byId', payload.id, 'points'], payload.points)

    case 'DELETE_REGION':
      return state
        .set('byId', state.byId.without(payload.id))
        .set(
          'priority',
          Immutable(
            state.priority.asMutable().filter(elem => elem !== payload.id)
          )
        )
    case 'CHANGE_REGION_LIGHT_SETTING':
      return state.setIn(
        ['byId', payload.id, 'lightSettings'],
        payload.lightSettings
      )
    case 'RAISE_REGION_PRIORITY': {
      const index = state.priority.indexOf(payload.id)
      if (index < 1) {
        // not in list or at front of list
        return state
      }
      return state.set(
        'priority',
        Immutable(swapIndexes(state.priority.asMutable(), index, index - 1))
      )
    }
    case 'LOWER_REGION_PRIORITY': {
      const index = state.priority.indexOf(payload.id)
      if (index === -1 || index === state.priority.length - 1) {
        // not in list or at back of list
        return state
      }
      return state.set(
        'priority',
        Immutable(swapIndexes(state.priority.asMutable(), index, index + 1))
      )
    }
    default:
      return state
  }
}

function swapIndexes (list, index1, index2) {
  const newList = [...list]
  const temp = newList[index1]
  newList[index1] = newList[index2]
  newList[index2] = temp
  return newList
}
function levelDefault (state = { color: 'off' }, action) {
  if (action.type === 'CHANGE_DEFAULT_LIGHT_SETTING') {
    return action.lightSettings
  }
  return state
}

export default function reduce (state = blankSeismic(), action = {}) {
  switch (action.type) {
    case 'RESET_SEISMIC_OVERLAY_EDITOR':
      return blankSeismic()

    case 'FORCE_WHOLE_SETTINGS':
      return Immutable({
        levelDefault: action.payload.levelDefault,
        regionSettings: action.payload.regionSettings
      })

    case 'SAVE_NEW_REGION_REQUEST':
      return state.setIn(['isSaving'], true).setIn(['savingError'], '')
    case 'SAVE_NEW_REGION_SUCCESS':
      return state.setIn(['isSaving'], false).setIn(['savingError'], '')
    case 'SAVE_NEW_REGION_FAILED':
      return state
        .setIn(['isSaving'], false)
        .setIn(['savingError'], action.payload)

    default:
      return state.merge([
        {
          levelDefault: levelDefault(state.levelDefault, action)
        },
        {
          regionSettings: regionsReducer(state.regionSettings, action)
        }
      ])
  }
}

export const getEditingDefaultSettings = state =>
  state.editorSeismicLayer.levelDefault

const editorRegions = state => state.editorSeismicLayer.regionSettings

export const getEditingRegionsInOrder = createSelector(
  editorRegions,
  regionSettings => {
    return regionSettings.priority.map(id => {
      return regionSettings.byId[id]
    })
  }
)
