import React, { PureComponent } from "react";
import { push } from "react-router-redux";

import { Container, Header, Grid } from "semantic-ui-react";

import FlashMessagesList from "FlashMessages";

import { connect } from "react-redux";

import TagTrackingDetailsForm from "admin/tag-tracking/TagTrackingDetailsForm";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import { getTagById } from "components/Tags/reducer";

import NavigationPromptModal from "admin/NavigationPromptModal";

import {
  assignUser,
  assignVehicle,
  unassignUser,
  unassignVehicle,
  fetchTags,
  fetchAllTagUsers,
  fetchAllTagVehicles,
} from "components/Tags/actions";

class TagTrackingEditPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
    console.log(`TagTrackingEditPage - componentDidMount`);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  assignVehicle = (values) => {
    return new Promise((resolve, reject) => {
      this.props.assignVehicle({
        values,
        resolve,
        reject,
      });
    });
  };

  unassignVehicle = (values) => {
    return new Promise((resolve, reject) => {
      this.props.unassignVehicle({
        values,
        resolve,
        reject,
      });
    });
  };

  assignPersonnel = (values) => {
    return new Promise((resolve, reject) => {
      this.props.assignUser({
        values,
        resolve,
        reject,
      });
    });
  };

  unassignPersonnel = (values) => {
    return new Promise((resolve, reject) => {
      this.props.unassignUser({
        values,
        resolve,
        reject,
      });
    });
  };

  submitMyForm = (values) => {
    //console.log(`TagTrackingDetails values`, values);

    const { _action, tagId, tagUserType, id, tagUserId } = values;

    let promiseArray = [];

    switch (_action) {
      case "assign":
        switch (tagUserType) {
          case "personnel":
            promiseArray.push(
              this.assignPersonnel({ id: tagId, user_id: tagUserId })
            );
            break;
          case "vehicle":
            promiseArray.push(
              this.assignVehicle({ id: tagId, vehicle_id: tagUserId })
            );
            break;
          default:
            break;
        }

        break;

      default:
        break;
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("UPDATING TAG DETAILS", results);
      })
      .then(
        () => {
          // wait for state change before redirecting page
          this.setState(
            {
              submitted: true,
            },
            () => {
              this.props.fetchTags(); // #FETCHS
              this.props.fetchAllTagUsers();
              this.props.fetchAllTagVehicles();
              this.props.push(`/status/tag/`);
            }
          );
        },
        (msg) => {
          console.log("action failed", msg); // TODO probs should show this?
        }
      );
  };

  unassignId = ({ id, tagUserType }) => {
    //console.log(`unassignId id`, id);

    let promiseArray = [];

    switch (tagUserType) {
      case "personnel":
        promiseArray.push(this.unassignPersonnel({ id }));
        break;
      case "vehicle":
        promiseArray.push(this.unassignVehicle({ id }));
        break;
      default:
        break;
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("SUCCESS TagTrackingEditPage unassignTag", results);
      })
      .then(() => {
        this.props.fetchTags();
        this.props.push(`/status/tag`);
      })
      .catch((error) => {
        console.log(`ERROR TagTrackingEditPage unassignTag error`, error);
        //this.onSetError("failed");
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        //throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  render() {
    const { id, mac, error, push, tagData, strings } = this.props;
    const { submitted } = this.state;

    if (error) {
      return (
        <>
          <DataLoadingMessage id={id} strings={strings} />
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    // in development page reload will initially have no tagData
    // so return null until componentDidUpdate ...
    if (!tagData) return null;

    const {
      calculated_rssi,
      coord,
      last_updated,
      locations,
      tagId,
      tagUserId,
      tagUserType,
    } = tagData;

    let tagUserData = {};

    switch (tagUserType) {
      case "personnel":
        tagUserData = tagData?.userInfo;
        break;
      case "vehicle":
        tagUserData = tagData?.vehicleInfo;
        break;
      default:
        break;
    }

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"tagTrackingDetailsForm"}
          submitted={submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">Tag Details</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <TagTrackingDetailsForm
            id={id}
            initialValues={{
              _action: "", // used to control dlg display
              id,
              mac,
              tagId,
              tagUserId,
              tagUserType,
              tagUserData,
            }}
            onSubmit={(values) =>
              //this.submitMyForm(this._preProcessValues(values))
              this.submitMyForm(values)
            }
            onCancel={() => {
              push(`/status/tag`);
            }}
            onUnassign={(values) => this.unassignId(values)}
            strings={strings}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const match = props.match;
  const id = match.params.id;
  // derive mac from id. Format is `id~mac`
  const mac = id?.split?.("~")?.[1] || "";

  return {
    id,
    mac,
    error: "", //firefly ? undefined : "No Firefly for id",
    tagData: getTagById(state, id),
  };
};

export default connect(mapStateToProps, {
  push,
  TurnOnOffPokeTheWorker,
  assignUser,
  assignVehicle,
  unassignUser,
  unassignVehicle,
  fetchTags,
  fetchAllTagUsers,
  fetchAllTagVehicles,
})(TagTrackingEditPage);
