import { StatusEnum } from "utils/StatusEnum";
import { isDemoMode } from "components/ConfigJs";
import { toWebColor } from "utils/toWebColor";

export const convertFetchFireflysToMqtt = ({ ff_list: Fireflys }) => {
  //

  // Example object
  //
  // Altered: 0
  // Fault: 0
  // Resend: 0
  // Timestamp_State_Change: "2021-06-23T12:12:14+10:00"
  // Timestamp_Status: "2021-06-29T09:40:07.651515578+10:00"
  // area: "DMLZ_Extraction"
  // color: "GREEN"
  // controller: "74:e1:82:91:60:c5"
  // event: "DMLZ_Extraction_BASE"
  // id: "DMLZ_Extraction:P7:1"
  // location: "P7"
  // mac: "b4:e6:2d:99:04:7d"
  // off_time: 0
  // on_time: 0
  // position: 1
  // priority: 0
  // reason: "OK"
  // reported_color: "GREEN"
  // reported_state: "ON"
  // state: "ON"
  // sw_version: "3.00"
  // train: 0
  // utm: {X: 736493.1971396587, Y: 9548688.519158214}
  // utm_zone: 53
  // utm_zone_letter: 77
  // z: 0

  let fireflyCoordinates = {};
  let fireflyFeatures = {};
  let fireflyStatuses = {};

  // default startup state on fetch
  let defaultMarker = "TriangleMarker";
  let defaultStatusNum = [StatusEnum.API_FETCH, StatusEnum.NO_STATUS_REPORT];

  //_#DEMO_MODE
  const demoMode = isDemoMode() === true;
  if (demoMode) {
    defaultMarker = "RoundMarker";
    defaultStatusNum = [StatusEnum.OK];
  }

  const timeNow = new Date().getTime() / 1000;

  Fireflys.forEach((firefly) => {
    const {
      Altered,
      Fault,
      Resend,
      Timestamp_State_Change, // last time the state change on the FF
      Timestamp_Status, // last update of the FF status
      area,
      color,
      controller: ups_id,
      event,
      id,
      location,
      mac,
      off_time,
      on_time,
      brightness = "70", // set default as object may not be passed
      position,
      priority,
      reason,
      reported_color,
      reported_state,
      state,
      sw_version,
      train,
      utm: { X: easting, Y: northing },
      utm_zone: utm_zone_number,
      utm_zone_letter, // rx as ASCII int
      z,
      port: port_number,
      controller_name: ups_name,
      note: fireflyNote,
      //
      // missing from the API message. Set defaults...
      //
      status = "OK",
      tag_detect = false,
      ignoreServer = false,
    } = firefly;

    const stringUtmZoneLetter = String.fromCharCode(utm_zone_letter);
    const coordinates = [easting, northing];

    const lowerCaseColor = toWebColor(color.toLowerCase());

    const colorPlanStatus = {
      plan: lowerCaseColor,
      status: lowerCaseColor,
    };

    const lightStatePlanStatus = {
      plan: {
        led_state: state?.toLowerCase(),
        off_time: off_time,
        on_time: on_time,
        train: train,
        brightness: brightness,
      },
      status: {
        led_state: state?.toLowerCase(),
        off_time: off_time,
        on_time: on_time,
        train: train,
        brightness: brightness,
      },
    };

    const fireflyFeature = {
      id,
      type: "Feature",
      geometry: { type: "Point", coordinates: coordinates },
      properties: {
        area,
        color: colorPlanStatus,
        controllerMode: "operation",
        deviceStatus: defaultStatusNum,

        id,
        light: {
          // #WIP - initially set the plan to reported_color
          // This needs to be reviewed wrt current set event
          //
          color: colorPlanStatus,
          lightState: lightStatePlanStatus,
          led_state: reported_state?.toLowerCase(),
          // #WIP - initially set the plan to reported_color
          // This needs to be reviewed wrt current set event as above
          //
          marker: defaultMarker,
          off_time,
          on_time,
          position,
          train,
        },
        location,
        mac,
        position,
        timestamp: demoMode
          ? Timestamp_State_Change
          : Timestamp_Status !== undefined
          ? Timestamp_Status
          : timeNow,
        ups_id: ups_id,
        northing,
        easting,
        utm_zone_letter: stringUtmZoneLetter,
        utm_zone_number,
        z,
        fireflyNote: fireflyNote,
      },
    };
    fireflyFeatures[id] = fireflyFeature;

    // #NOTE -
    // #WIP - need to get controllerStatuses to determine mode and name
    // get controller mode e.g. commission, operation etc.
    // Can't pass via redux as function does not render any content
    let controllerStatuses = {};
    let controllerMode = "";

    if (false) {
      if (controllerStatuses[ups_id] !== undefined) {
        const {
          topology: { mode },
        } = controllerStatuses[ups_id];
        if (mode !== undefined) {
          controllerMode = mode;
        }
      }
    }

    fireflyStatuses[id] = {
      id,
      //
      utm: {
        northing,
        easting,
        utm_zone_letter: stringUtmZoneLetter,
        utm_zone_number,
        z,
      },
      //
      light: {
        // #WIP - initially set the plan to reported_color
        // This needs to be reviewed wrt current set event
        //
        color: colorPlanStatus,
        lightState: lightStatePlanStatus,
        led_state: reported_state?.toLowerCase(),
        // #WIP - initially set the plan to reported_color
        // This needs to be reviewed wrt current set event as above
        //
        marker: "TriangleMarker",
        off_time,
        on_time,
        position,
        train,
      },
      topology: {
        area,
        position,
        location,
        port_number,
        mac,
        ups_id,
        ups_name,
        controllerMode,
        deviceStatus: defaultStatusNum,
        ignoreServer,
      },
      device: { mac, status, sw_ver: sw_version, tag_detect },
      fireflyNote: fireflyNote,
      timestamp: undefined,
    };
  });

  fireflyCoordinates = {
    type: "FeatureCollection",
    features: Object.values(fireflyFeatures),
  };

  return { fireflyCoordinates, fireflyStatuses };
};
