import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withMineLevel } from "ApplicationContexts";

import L from "leaflet";
import { FeatureGroup, GeoJSON } from "react-leaflet";

import { makeIcon } from "components/Map/MakeIcon";

import {
  getFireflyIdsForMineLevelId,
  getFireflyCoordinatesByAreaId,
} from "components/WebWorker/reducer";

//import Firefly from "containers/FireflyMapMarker";

import hash from "object-hash";
import _isEmpty from "lodash/isEmpty";

// #TODO #REVEW - write a geoJson parse checker
function tryParseJSON(jsonObj) {
  try {
    var o = JSON.parse(JSON.stringify(jsonObj));
    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {}

  return false;
}

class CurrentMineLevelFirefliesFeatureGroup extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { fireflyIds: current } = this.props;
    const { fireflyIds: next } = nextProps;

    //    console.log("current", current);
    //   console.log("next", next);

    if (_isEmpty(current) || _isEmpty(next)) return false;

    if (current?.length === next?.length) {
      const lookup = current.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});

      const len = next?.length;
      for (var i = 0; i < len; i++) {
        if (!lookup[next[i]]) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  fireflyStyle() {
    return {
      // radius: 6,
      fillColor: "green",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    };
  }

  render() {
    const { fireflies, PopupContent } = this.props;

    const onEachFeature = (feature, layer) => {
      let popupContent = ``;
      if (feature.properties && feature.properties.id) {
        popupContent += feature.properties.id;
      }

      layer.bindPopup(popupContent);
    };

    const pointToLayer = (feature, latlng) => {
      //return L.circleMarker(latlng, null); // Change marker to circle
      return L.marker(latlng, {
        icon: makeIcon("RoundMarker", { stroke: "purple", fill: "purple" }, -4), // default to purple as this is not a suppported active color
      });
    };

    const valid = !_isEmpty(fireflies) && tryParseJSON(fireflies);

    return (
      valid && (
        <GeoJSON
          ref="geoJsonMarkers"
          key={hash(fireflies)}
          data={fireflies}
          //          style={{ color: "red" }}
          //style={fireflyStyle}
          style={() => ({
            color: "#4a83ec",
            weight: 0.5,
            fillColor: "#1a1d62",
            fillOpacity: 1,
          })}
          onEachFeature={onEachFeature}
          pointToLayer={pointToLayer}
        />
      )
    );
    // <FeatureGroup>
    //   {fireflies.map((id) => {
    //     return (
    //       <Firefly
    //         key={`firefly-${id}`}
    //         id={id}
    //         PopupContent={PopupContent}
    //       />
    //     );
    //   })}
    // </FeatureGroup>
  }
}

export function mapStateToProps(state, props) {
  const { mineLevel } = props;

  const fireflies = getFireflyCoordinatesByAreaId(state, mineLevel.id);
  const fireflyIds = getFireflyIdsForMineLevelId(state, mineLevel.id);

  return {
    fireflies,
    fireflyIds,
  };
}

export default withMineLevel(
  connect(mapStateToProps)(CurrentMineLevelFirefliesFeatureGroup)
);
