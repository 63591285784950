import { delay } from "redux-saga";
import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import * as ApiC from "api/apiController";
import * as ApiFF from "api/apiFirefly";
import * as ApiNA from "api/apiNamedArea";
import { SubmissionError } from "redux-form";
import { convertFetchControllersToMqtt } from "UPSPanelControllers/convertFetchControllersToMqtt";
import { convertFetchFireflysToMqtt } from "UPSPanelControllers/convertFetchFireflysToMqtt";
import { isMqttNotApiPost } from "components/ConfigJs";

function makeSaveFunction(apiCall) {
  return function* (action) {
    const { values, resolve, reject } = action.payload;
    const { id } = values;

    //console.log(`saveUPS makeSaveFunction`, values);
    if (!id) {
      reject(
        new SubmissionError({
          _error: "Must provide id",
        })
      );
      return;
    }
    try {
      yield apiCall(values);
      resolve(id);
    } catch (error) {
      console.log(`makeSaveFunction Error `, error);
      //const formError = new SubmissionError({ _error: error });
      reject(error);
    }
  };
}

function* deleteUPS(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    //console.log("@@ saga deleteUPS id", id);
    yield call(isMqttNotApiPost() ? ApiC.deleteUPS : ApiC.deleteUpsPost, id);
    yield put({
      type: "UPS_DELETE_SUCCESS",
      payload: { id },
    });
    resolve(id);
  } catch (err) {
    yield put({ type: "UPS_DELETE_FAILED", message: err });
    reject(err);
  }
}

function* deleteFirefly(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  //console.log("@@ sage deleteFirefly", id);

  try {
    yield call(
      isMqttNotApiPost() ? ApiFF.deleteFirefly : ApiFF.deleteFireflyPost,
      id
    );

    yield put({
      type: "FIREFLY_DELETE_SUCCESS",
      payload: { id },
    });
    resolve(id);
  } catch (err) {
    //console.log(`FIREFLY_DELETE_FAILED `, err);
    yield put({ type: "FIREFLY_DELETE_FAILED", message: err });

    reject(err);
  }
}

// #NOTE - no longer used for direct control of lights
// Left in place for historic reference ATM
function* saveFireflyLed(action) {
  const { values } = action.payload;

  try {
    yield call(delay, 1000);
    yield call(ApiFF.saveFireflyLed, values);
    yield put({ type: "SAVE_FIREFLY_LED_SUBMITTED", payload: { values } });
  } catch (e) {
    yield put({
      type: "SAVE_FIREFLY_LED_ERROR",
      message: e.message,
    });
  }
}

function* fetchUPSs(action) {
  console.log(`UPS_FETCH_REQUESTED`);
  try {
    const data = yield call(ApiC.fetchControllers, null);

    //console.log(`UPS_FETCH_SUCCEEDED data `, data);

    // fetchControllers/get_all_controllers contains data for many states:
    // * controllerCoordinates -> MQTT_CONTROLLER_COORDINATES_UPDATE
    // * controllerStatuses -> MQTT_CONTROLLER_STATUSES_UPDATE
    // * emergencyEventSettings -> MQTT_EMERGENCY_EVENT_SETTINGS_UPDATE
    // * triggerEventSettings -> MQTT_TRIGGER_EVENT_SETTINGS_UPDATE

    const {
      controllerCoordinates,
      controllerStatuses,
      emergencyEventSettings,
      triggerEventSettings,
    } = convertFetchControllersToMqtt(data);

    console.log(
      `UPS_FETCH_SUCCEEDED controllerCoordinates`,
      controllerCoordinates
    );

    yield put({
      type: "MQTT_CONTROLLER_COORDINATES_UPDATE",
      payload: controllerCoordinates,
    });

    console.log(`UPS_FETCH_SUCCEEDED controllerStatuses`, controllerStatuses);

    yield put({
      type: "MQTT_CONTROLLER_STATUSES_UPDATE",
      payload: controllerStatuses,
    });

    console.log(
      `UPS_FETCH_SUCCEEDED emergencyEventSettings`,
      emergencyEventSettings
    );

    yield put({
      type: "MQTT_EMERGENCY_EVENT_SETTINGS_UPDATE",
      payload: emergencyEventSettings,
    });

    console.log(
      `UPS_FETCH_SUCCEEDED triggerEventSettings`,
      triggerEventSettings
    );

    yield put({
      type: "MQTT_TRIGGER_EVENT_SETTINGS_UPDATE",
      payload: triggerEventSettings,
    });

    // used to trigger update to worker with fetch complete
    yield put({
      type: "UPS_FETCH_SUCCEEDED",
      payload: {},
    });
  } catch (e) {
    yield put({ type: "UPS_FETCH_FAILED", message: e.message });
  }
}

function* fetchFireflys(action) {
  console.log(`FIREFLY_FETCH_REQUESTED`);
  try {
    const data = yield call(ApiFF.fetchFireflys, null);

    //console.log(`FIREFLY_FETCH_SUCCEEDED data `, data);

    // fetchFireflys/ get_all_fireflys contains data for many states:
    // * fireflyCoordinates -> MQTT_FIREFLY_COORDINATES_UPDATE
    // * fireflyStatuses -> MQTT_FIREFLY_STATUSES_UPDATE

    const { fireflyCoordinates, fireflyStatuses } =
      convertFetchFireflysToMqtt(data);

    console.log(
      `FIREFLY_FETCH_SUCCEEDED fireflyCoordinates`,
      fireflyCoordinates
    );

    yield put({
      type: "MQTT_FIREFLY_COORDINATES_UPDATE",
      payload: fireflyCoordinates,
    });

    console.log(`FIREFLY_FETCH_SUCCEEDED fireflyStatuses`, fireflyStatuses);

    yield put({
      type: "MQTT_FIREFLY_STATUSES_UPDATE",
      payload: fireflyStatuses,
    });

    // used to trigger update to worker with fetch complete
    yield put({
      type: "FIREFLY_FETCH_SUCCEEDED",
      payload: {},
    });
  } catch (e) {
    yield put({ type: "FIREFLY_FETCH_FAILED", message: e.message });
  }
}

function* fetchAreaLightingplan({ payload }) {
  console.log(`FIREFLY_AREA_LIGHTINGPLAN_FETCH_REQUESTED - payload`, payload);
  try {
    const response = yield ApiFF.getAreaLightingPlan(payload);

    const { data } = response;
    yield put({
      type: "FIREFLY_AREA_LIGHTINGPLAN_FETCH_SUCCEEDED",
      payload: data,
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "FIREFLY_AREA_LIGHTINGPLAN_FETCH_FAILED",
          payload: { error: "fetchAreaLightingplan error", extra: data },
        });
      } else {
        yield put({
          type: "FIREFLY_AREA_LIGHTINGPLAN_FETCH_UNKNOWN",
          payload: { error: "fetchAreaLightingplan error", extra: data },
        });
      }
    }
  }
}

function* upsSaga() {
  const isMqtt = isMqttNotApiPost() || false;

  yield takeLatest("UPS_FETCH_REQUESTED", fetchUPSs);
  yield takeLatest("FIREFLY_FETCH_REQUESTED", fetchFireflys);

  // yield takeEvery("COMMISSION_RECORDS_REQUEST", handleCommissionRequest);

  yield takeEvery(
    "SAVE_UPS",
    makeSaveFunction(isMqtt ? ApiC.saveUPS : ApiC.saveUpsPost)
  );

  yield takeEvery(
    "SAVE_FIREFLY",
    makeSaveFunction(isMqtt ? ApiFF.saveFirefly : ApiFF.saveFireflyPost)
  );

  // #NOTE - no longer used for direct control of lights
  // Left in place for historic reference ATM
  yield takeEvery("SAVE_FIREFLY_LED", saveFireflyLed);

  yield takeEvery(
    "SAVE_EMERGENCY_EVENT_SETTINGS",
    makeSaveFunction(ApiNA.saveEmergencyEventSettings)
  );
  yield takeEvery(
    "SAVE_TRIGGER_EVENT_SETTINGS",
    makeSaveFunction(ApiNA.saveTriggerEventSettings)
  );

  yield takeEvery("DELETE_UPS", deleteUPS);
  yield takeEvery("DELETE_FIREFLY", deleteFirefly);

  // yield takeEvery("UPS_RUN_DISCOVERY", runUPSDiscovery);
  // yield takeEvery("UPS_SEND_ADVANCED_CONFIG", sendUPSAdvancedConfig);
  // yield takeEvery("UPS_SEND_COMMAND", sendUPSCommand);
  // yield takeEvery("TRAVELWAY_SEND_COMMAND", sendTravelwayCommand);

  yield takeLatest(
    "FIREFLY_AREA_LIGHTINGPLAN_FETCH_REQUESTED",
    fetchAreaLightingplan
  );
}

export default upsSaga;
