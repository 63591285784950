import cuid from "cuid";

export function addInfoFlashMessage(header = "", message = "") {
  return addFlashMessage(header, message, { info: true });
}
export function addWarningFlashMessage(header, message) {
  return addFlashMessage(header, message, { warning: true });
}

export function addWarningFlashMessageIdColor(id, color, header, message) {
  return addFlashMessageIdColor(id, color, header, message, { warning: true });
}

export function addErrorFlashMessage(header, message) {
  return addFlashMessage(header, message, { error: true });
}

export function addFlashMessage(header, content, other) {
  return {
    type: "ADD_FLASH_MESSAGE",
    payload: { id: cuid(), header, content, ...other },
  };
}

export function addFlashMessageIdColor(id, color, header, content, other) {
  return {
    type: "ADD_FLASH_MESSAGE",
    payload: { id, color, header, content, ...other },
  };
}

export function clearFlashMessages() {
  return { type: "CLEAR_FLASH_MESSAGES" };
}

export function removeFlashMessage(id) {
  return { type: "REMOVE_FLASH_MESSAGE", payload: { id } };
}

export function SetMessageBanner(messageState) {
  return { type: "SET_MESSAGE_BANNER", payload: messageState };
}
