import { connect } from "react-redux";
import { withComponentStateCache } from "react-component-state-cache";

import { fetchMineLevels } from "MineLevels/actions";
import { fetchUPSs } from "UPSPanelControllers/actions";
import { fetchFireflys } from "UPSPanelControllers/actions";
import {
  fetchNamedAreas,
  fetchNamedAreaEvents,
  fetchNamedAreaExtTriggerEventInfo,
  fetchNamedAreaTriggers,
} from "NamedAreas/actions";
import { fetchLocalisation } from "components/Localisation/actions";
import { fetchFolderFilesListByFolder } from "components/Settings/actions";
import {
  fetchTags,
  fetchAllTagUsers,
  fetchAllTagVehicles,
} from "components/Tags/actions";
import { isTagTracking } from "components/ConfigJs";

import { checkAuth } from "auth/actions";
import { fetchAllUsers } from "components/UserAdmin/actions";

const _FetchAllData = ({ ...props }) => {
  const {
    checkAuth,
    fetchAllUsers,
    fetchMineLevels,
    fetchUPSs,
    fetchFireflys,
    fetchNamedAreas,
    fetchNamedAreaEvents,
    fetchNamedAreaTriggers,
    fetchNamedAreaExtTriggerEventInfo,
    fetchFolderFilesListByFolder,
    fetchLocalisation,
    componentstate,
    fetchTags,
    fetchAllTagUsers,
    fetchAllTagVehicles,
  } = props;

  const checkRefresh = componentstate.get("FETCH_ALL_DATA", 1);

  // console.log(`XXXXXXXXXXXXXXXX FetchAllData get checkRefresh`, checkRefresh);

  if (checkRefresh === null) {
    // console.log(
    //   `XXXXXXXXXXXXXXXX FetchAllData get checkRefresh == null`,
    //   checkRefresh === null
    // );

    console.log(`_FETCH FetchAllData set FETCH_ALL_DATA, fetching data ....`);

    // #NOTE -
    // #WIP - at some stage may need to implement a process to wait
    // for the fetch of folder info to complete before the areas,
    // since area display depends on knowledge of folders
    //

    // #FETCH - get these first
    checkAuth(); // gets current login
    fetchAllUsers(); // gets all users for user admin
    //
    fetchLocalisation();
    fetchFolderFilesListByFolder("areas");
    fetchFolderFilesListByFolder("documents");
    fetchFolderFilesListByFolder("backups");

    //#FETCHS
    fetchMineLevels(); //#FETCHS
    fetchUPSs(); //#FETCHS
    fetchFireflys(); //#FETCHS
    fetchNamedAreas(); //#FETCHS
    fetchNamedAreaEvents(); // #FETCHS
    fetchNamedAreaTriggers(); // #FETCHS
    fetchNamedAreaExtTriggerEventInfo();

    if (isTagTracking()) {
      fetchTags();
      fetchAllTagUsers();
      fetchAllTagVehicles();
    } // #FETCHS

    componentstate.set("FETCH_ALL_DATA", 1, "FETCHED");

    console.log(`_FETCH FetchAllData cleared FETCH_ALL_DATA`);
  }

  return null;
};

export const FetchAllData = withComponentStateCache(
  connect(null, {
    fetchMineLevels,
    fetchUPSs,
    fetchFireflys,
    fetchNamedAreas,
    fetchNamedAreaEvents,
    fetchNamedAreaTriggers,
    fetchNamedAreaExtTriggerEventInfo,
    //
    checkAuth,
    fetchAllUsers,
    fetchLocalisation,
    fetchFolderFilesListByFolder,
    fetchTags,
    fetchAllTagUsers,
    fetchAllTagVehicles,
  })(_FetchAllData)
);
