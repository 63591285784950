import { connect } from "react-redux";
import FireflyMarker from "components/FireflyMarker";
import { getFireflyById } from "UPSPanelControllers/reducer";

const ConnectedFirefly = connect((state, props) => {
  const ff = getFireflyById(state, props.id);

  let error = ff.statusError;
  return {
    lat: ff.position.lat,
    lng: ff.position.lng,
    statusSettings: ff.getIn(["lastStatusReport", "light"]),
    deviceSettings: ff.getIn(["lastStatusReport", "device"]),

    // get topology block from firefly message
    topologySettings: ff.getIn(["lastStatusReport", "topology"]),

    commandSettings: ff.getIn(["lastCommand", "settings"]),
    error
  };
})(FireflyMarker);

export default ConnectedFirefly;
