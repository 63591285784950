import Immutable from "seamless-immutable";
import { getMineLevelById } from "MineLevels/reducer";
// test
import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash/isEqual";

const initialState = Immutable({
  byId: {},
  order: [],
  isLoading: false,
  error: null,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case "NAMED_AREAS_FETCH_REQUESTED":
      return state.merge({ isLoading: true });
    case "NAMED_AREAS_FETCH_SUCCEEDED":
      return state.merge([
        Immutable(action.payload),
        { isLoading: false, error: null },
      ]);
    case "NAMED_AREAS_FETCH_FAILED":
      return state.merge({ isLoading: false, error: action.payload });
    default:
      return state;
  }
}

export const getAllNamedAreas = (state) => {
  return Immutable(
    state.namedAreas.order.map((id) => {
      const obj = state.namedAreas.getIn(["byId", id]);
      const mineLevel = getMineLevelById(state, obj.mineLevelId);
      const mineLevelName = mineLevel ? mineLevel.name : "No mine level??";
      return obj.set("mineLevelName", mineLevelName);
    })
  );
};

export const getNamedAreaById = (state, id) => {
  return getAllNamedAreas(state).find((area) => area.id === id);
};

// create a "selector creator" that uses lodash.isEqual instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const makeGetNamedAreasForLevel = createDeepEqualSelector(
  (state, props) => props.mineLevelId,
  (state) => state.namedAreas.order.map((id) => state.namedAreas.byId[id]),
  (mineLevelId, namedAreas) => {
    return namedAreas
      .filter((area) => area.mineLevelId === mineLevelId)
      .asMutable();
  }
);
