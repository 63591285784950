import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Segment,
  Icon,
  Button,
  Checkbox,
  Popup,
  Grid,
} from "semantic-ui-react";

import { getNamedAreaEventsByAreaId } from "components/WebWorker/reducer";
import { saveNewNamedArea, deleteNamedArea } from "NamedAreas/actions";
import {
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
} from "OperationalChanges/actions";

import { allowedLightColors as _allowedLightColors } from "components/ConfigJs";

import { getEquivalentSemanticColorName } from "colorpalette";
import { toFireflyColor } from "utils/toFireflyColor";
import { ForceSingleLightManageNamedArea } from "components/Map/ForceSingleLightManageNamedArea";

let SingleColorOperation = ({ lightSetting, isEnabled, onClick, disabled }) => {
  //console.log(`xxxxxxxxxxxxxx lightSetting`, lightSetting);

  let semanticColorName = getEquivalentSemanticColorName(lightSetting);
  semanticColorName = lightSetting === "white" ? "grey" : semanticColorName;

  const popMsgForceButton = !isEnabled
    ? `Select to force the light color to ${lightSetting}`
    : `Select to clear the forced light color`;
  return (
    <Popup
      content={popMsgForceButton}
      trigger={
        <Button
          icon
          color={semanticColorName}
          onClick={onClick}
          basic={!isEnabled}
          disabled={disabled}
        >
          <Icon name={"lightbulb"} />
        </Button>
      }
    />
  );
};

class ForceSingleLight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isForcedActive: false,
      checkedStrobe: false,
      checkedOff: false,
      buttonColor: "", /// default to not selected
      allowedLightColors: ["red", "amber", "white", "green", "blue"], // default options
      //
    };
  }

  componentDidMount() {
    const { currentForcedColor, isCurrentForcedActive, isStrobe, isOff } =
      this.props;

    const allowedLightColors = _allowedLightColors();

    if (allowedLightColors) {
      const newAllowedLightColors = allowedLightColors.map(
        (color) => color.key
      );

      this.setState({
        allowedLightColors: newAllowedLightColors,
      });
    }
    this.setState({ buttonColor: currentForcedColor });
    this.setState({ checkedStrobe: isStrobe });
    this.setState({ checkedOff: isOff });
    this.setState({ isForcedActive: isCurrentForcedActive });
  }

  onChangeCheckboxStrobe = (e) => {
    const {
      //properties
      fireflyId,
      easting,
      northing,
      area,
      color,
      namedAreaEvents,
      // functions
      deleteNamedArea,
      saveNewNamedArea,
      activate,
      //
    } = this.props;

    // toggle from current state
    const isNewForcedStrobe = !this.state.checkedStrobe;
    const buttonColor = this.state.buttonColor;

    ForceSingleLightManageNamedArea({
      action: "lightState",
      fireflyId: fireflyId,
      area: area,
      easting: easting,
      northing: northing,
      color: color,
      setFireflyColor: buttonColor,
      setFireflyState: isNewForcedStrobe ? "strobe" : "on",

      namedAreaEvents: namedAreaEvents,
      deleteNamedArea: deleteNamedArea,
      saveNewNamedArea: saveNewNamedArea,
      activate: activate,
    });

    this.setState({ checkedStrobe: isNewForcedStrobe });

    this.props.onChange({ isDirty: true });
    //
  };

  onChangeCheckboxOff = (e) => {
    const {
      //properties
      fireflyId,
      easting,
      northing,
      area,
      color,
      namedAreaEvents,
      // functions
      deleteNamedArea,
      saveNewNamedArea,
      activate,
      //
    } = this.props;

    // toggle from current state
    const isNewForcedOff = !this.state.checkedOff;
    const buttonColor = this.state.buttonColor;

    if (isNewForcedOff) {
      ForceSingleLightManageNamedArea({
        action: "lightState",
        fireflyId: fireflyId,
        area: area,
        easting: easting,
        northing: northing,
        color: color,
        setFireflyColor: buttonColor,
        setFireflyState: isNewForcedOff ? "off" : "on",

        namedAreaEvents: namedAreaEvents,
        deleteNamedArea: deleteNamedArea,
        saveNewNamedArea: saveNewNamedArea,
        activate: activate,
      });
    } else {
      ForceSingleLightManageNamedArea({
        action: "clear",
        fireflyId: fireflyId,
        area: area,
        easting: easting,
        northing: northing,
        color: color,
        setFireflyColor: buttonColor,
        setFireflyState: isNewForcedOff ? "off" : "on",

        namedAreaEvents: namedAreaEvents,
        deleteNamedArea: deleteNamedArea,
        saveNewNamedArea: saveNewNamedArea,
        activate: activate,
      });
    }

    this.setState({ buttonColor: "" });
    this.setState({ checkedStrobe: false });
    this.setState({ checkedOff: isNewForcedOff });

    this.props.onChange({ isDirty: true });
  };

  onToggleColor = (evt, data) => {
    const {
      //properties
      fireflyId,
      easting,
      northing,
      area,
      color,
      namedAreaEvents,
      // functions
      deleteNamedArea,
      saveNewNamedArea,
      activate,
      //
      isCurrentForcedActive,
    } = this.props;

    const isCurrentForcedStrobe = this.state.checkedStrobe;

    const buttonColor = toFireflyColor(data.color);

    // console.log(
    //   "onToggleColor fireflyId,easting,northing,area,color, namedAreaEvents, data, isCurrentForcedActive, isCurrentForcedStrobe",
    //   fireflyId,
    //   easting,
    //   northing,
    //   area,
    //   color,
    //   namedAreaEvents,
    //   data,
    //   isCurrentForcedActive,
    //   isCurrentForcedStrobe
    // );

    // toggle OFF
    // forced color is active and color is current state -> toggle off
    if (isCurrentForcedActive && color.status === buttonColor) {
      ForceSingleLightManageNamedArea({
        action: "clear",
        fireflyId: fireflyId,
        area: area,
        easting: easting,
        northing: northing,
        color: color,
        setFireflyColor: buttonColor,
        setFireflyState: isCurrentForcedStrobe ? "strobe" : "on",

        namedAreaEvents: namedAreaEvents,
        deleteNamedArea: deleteNamedArea,
        saveNewNamedArea: saveNewNamedArea,
        activate: activate,
      });

      this.setState({ buttonColor: "" });
      this.setState({ checkedStrobe: false });
    }
    // toggle ON
    // forced color is active and color is different -> toggle on
    else {
      ForceSingleLightManageNamedArea({
        action: "lightColor",
        fireflyId: fireflyId,
        area: area,
        easting: easting,
        northing: northing,
        color: color,
        setFireflyColor: buttonColor,
        setFireflyState: isCurrentForcedStrobe ? "strobe" : "on",

        namedAreaEvents: namedAreaEvents,
        deleteNamedArea: deleteNamedArea,
        saveNewNamedArea: saveNewNamedArea,
        activate: activate,
      });

      this.setState({ buttonColor: buttonColor });
    }

    this.props.onChange({ isDirty: true });
  };

  render() {
    const {
      // local prop
      fireflyId,
      // redux prop
      currentForcedColor,
      isOff,
    } = this.props;

    const { allowedLightColors, buttonColor, checkedStrobe, checkedOff } =
      this.state;

    // console.log("hhh currentForcedColor", currentForcedColor);
    // console.log("hhh allowedLightColors", allowedLightColors);
    // console.log("hhh checkedStrobe", checkedStrobe);
    // console.log("hhh checkedOff", checkedOff);

    const popupMsgStrobe = isOff
      ? "Light is off. Turn the light on before activating strobe"
      : buttonColor !== ""
      ? "Enable/disable strobing of the forced light color"
      : "Force a light color before activating strobe";

    return (
      <>
        <Grid>
          <Grid.Row>
            <div style={{ display: "inline" }}>
              <Popup
                content={"Force the light on/off"}
                trigger={
                  <label
                    className={"leaflet-container font"}
                    style={{ background: "none", paddingRight: "10px" }}
                    for={`${fireflyId}+isCheckBoxOff`}
                  >
                    <input
                      style={{ marginLeft: "10px", marginRight: "5px" }}
                      type="checkbox"
                      checked={checkedOff}
                      onChange={this.onChangeCheckboxOff}
                    />
                    Off
                  </label>
                }
              />
              <Button.Group>
                {allowedLightColors.map((color, idx) => (
                  <SingleColorOperation
                    key={`${fireflyId}-${color}`}
                    lightSetting={color}
                    isEnabled={color === currentForcedColor.toLowerCase()}
                    onClick={(evt, data) => this.onToggleColor(evt, data)}
                    disabled={isOff}
                  />
                ))}
              </Button.Group>
              <Popup
                content={popupMsgStrobe}
                trigger={
                  <label
                    className={"leaflet-container font"}
                    style={{ background: "none" }}
                  >
                    <input
                      style={{ marginLeft: "10px", marginRight: "5px" }}
                      type="checkbox"
                      checked={checkedStrobe}
                      onChange={this.onChangeCheckboxStrobe}
                      disabled={buttonColor === "" || isOff}
                    />
                    Strobe
                  </label>
                }
              />
            </div>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const namedAreaEvents = getNamedAreaEventsByAreaId(state, props.area);

  return {
    namedAreaEvents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)),
  deleteNamedArea: (id) => {
    dispatch(deleteNamedArea(id));
  },
  //
  // see - src/pages/ControlRoomPage/PrecannedOperations.js
  activate: (operation) => dispatch(activateNamedAreaEventSingle(operation)),
  cancel: (operation) => dispatch(cancelNamedAreaEvent(operation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForceSingleLight);
