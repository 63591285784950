import { channelBufferPublishMsg } from "apiSaga";
import { myApi, dummyResponse } from "api/apiAxios";
import { messageToken, userSessionIp } from "utils/messageToken";

export function* fetchNamedAreas() {
  const response = yield myApi.get("/api/get_all_named_areas");
  // console.log(
  //   `API_TEST_apiNamedArea fetchNamedAreas get_all_named_areas`,
  //   response
  // );
  return response.data;
}

export function* fetchNamedAreaTriggers() {
  const response = yield myApi.get("/api/get_all_triggers");
  // console.log(
  //   `API_TEST_apiNamedArea fetchNamedAreaTriggers get_all_triggers`,
  //   response
  // );
  return response.data;
}

export function* fetchNamedAreaEvents() {
  const response = yield myApi.get("/api/get_all_events");
  // console.log(
  //   `API_TEST_apiNamedArea fetchNamedAreaEvents get_all_events`,
  //   response
  // );
  return response.data;
}

export function* fetchNamedAreaExtTriggerEventInfo() {
  // See - iot-firefly-server web/route.go  note
  // NOTE - for some WTF reason if I name these "get_all_..." the router does not find the handlefunc().
  // again "WTF?!" ... no anyway, they are no consistent with above "get_all..."
  const response = yield myApi.get("/api/get_ext_trigger_events");
  // console.log(
  //   `API_TEST_apiNamedArea fetchNamedAreaExtTriggerEventInfo get_all_ext_trigger_events`,
  //   response
  // );
  return response.data;
}

export function* saveNewNamedArea(values) {
  const { id } = values;

  // prepare message token
  const token = messageToken(userSessionIp);

  //console.log("saveNewNamedArea token", token);
  //console.log("saveNewNamedArea id", id);

  // const saveNewNamedAreaMessage = {
  //   id: id,
  //   area: area,
  //   name: name,
  //   shape: shape,
  //   type: "Polygon",
  //   subType: "region", // tagzone, forced, region
  //   priority: 1,
  //   parent: parent,
  //   // #WIP #TODO - set a central location for dummy buttons - used in a few places
  //   button: [
  //     {
  //       priority: 0,
  //       type: "SEISMIC0_EVENT",
  //       clickable: true,
  //       icon: "icon-earthquake0",
  //       color: "green",
  //       hint: "Level 0",
  //       title: "Seismic 0",
  //       alt: "Level 0",
  //       id: id,
  //       named_area: parent,
  //       group: 0,
  //       default: true,
  //       active: true,
  //       column: 0,
  //       state: "on",
  //       active_color: "green",
  //       active_state: "on",
  //       on_time: 0,
  //       off_time: 0,
  //       train: 0,
  //       marker: "RoundMarker"
  //     },
  //   ],
  //   default_color: "GREEN",
  //   default_state: "ON",
  //   style:
  //     '{"stroke":true,"color":"hsl(137.508,50%,75%)","weight":1,"opacity":1,"fill":true,"fillColor":"hsl(137.508,50%,75%)","fillOpacity":0.5,"clickable":true}',
  //   token: token,
  // };

  const payloadNewSaveMsg = {
    topic: `named_area/${id}/change`,
    qos: 0,
    message: { ...values, token },
    retained: false,
  };

  console.log("payloadNewSaveMsg", payloadNewSaveMsg);

  const saveNewNamedArea = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadNewSaveMsg, "MQTT_PUBLISH");

      // #WIP - ..................
      //
      // checkToken(token)
      //   .catch((error) => {
      //     console.log(`checkToken error: ${error}`);
      //     throw error; // throwing this or another error jumps to the next catch
      //   })
      //   .then((result) => console.log("found the Ack at index: " + result)) // found matching ack
      //   .then(() => removeAckToken(token)) // delete it
      //   // .then((result) => resolve(result))
      //   .then(() => resolve("ACK new OK"))
      //   .catch((error) => {
      //     console.log(`checkToken final error: ${error}`);
      //     reject(error);
      //   });

      // #TODO - pass token to resolve
      // resolve(`${payload .....
      resolve({ topic: `${payloadNewSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveNewNamedArea();
    console.log("saveNewNamedArea response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    //console.log("saveNewNamedArea response", response);
    return response;
  }
}

export function* saveNewNamedAreaPost(values) {
  const { id } = values;

  // prepare message token
  const token = messageToken(userSessionIp);

  const payloadNewSaveMsg = {
    topic: `named_area/${id}/change`,
    ...values,
    token,
  };

  // console.log(
  //   "API_TEST_apiNamedArea payloadNewSaveMsg post",
  //   payloadNewSaveMsg
  // );

  const response = yield myApi.post(
    `/api/change_named_area`,
    payloadNewSaveMsg
  );

  // console.log(
  //   `API_TEST_apiNamedArea saveNewNamedAreaPost change_named_area`,
  //   response
  // );

  return { topic: `${payloadNewSaveMsg.topic}`, token: token };
}

export function* saveNewNamedAreaEventPost(values) {
  const { topic, message } = values;
  const { token } = message;

  const payloadNewSaveMsg = {
    topic,
    ...message,
  };

  // console.log(
  //   "API_TEST_apiNamedArea saveNewNamedAreaEventPost post",
  //   payloadNewSaveMsg
  // );

  const response = yield myApi.post(`/api/change_event`, payloadNewSaveMsg);
  // console.log(
  //   `API_TEST_apiNamedArea saveNewNamedAreaEventPost change_event`,
  //   response
  // );

  return { topic: `${payloadNewSaveMsg.topic}`, token: token };
}

export function* saveNewEmergencyEventPost(values) {
  const { topic, message } = values;
  const { token } = message;

  const payloadNewSaveMsg = {
    topic,
    ...message,
  };

  console.log(
    "API_TEST_apiNamedArea payloadNewSaveMsg post",
    payloadNewSaveMsg
  );

  const response = yield myApi.post(
    `/api/change_emergency_event`,
    payloadNewSaveMsg
  );
  // console.log(
  //   `API_TEST_apiNamedArea saveNewEmergencyEventPost change_emergency_event`,
  //   response
  // );

  return { topic: `${payloadNewSaveMsg.topic}`, token: token };
}

// **********************************************
// scheduled events - event jobs
//
// saveNewScheduledEventPost
export function* saveNewScheduledEventPost(values) {
  const { id } = values;

  // prepare message token
  const token = messageToken(userSessionIp);

  const payloadNewSaveMsg = {
    topic: `scheduled_event/${id}/change`,
    ...values,
    token,
  };

  console.log(
    "API_TEST_apiNamedArea saveNewScheduledEventPost post",
    payloadNewSaveMsg
  );

  const response = yield myApi.post(
    `/api/change_scheduled_event_job`,
    payloadNewSaveMsg
  );

  console.log(
    `API_TEST_apiNamedArea saveNewScheduledEventPost change_event_job_cron`,
    response
  );

  return { topic: `${payloadNewSaveMsg.topic}`, token: token };
}

export function* getScheduledEventJobs() {
  const response = yield myApi.get("/api/get_all_scheduled_event_jobs");
  // console.log(`API_TEST_apiNamedArea get_all_scheduled_event_jobs`, response);
  return response.data;
}

export function* deleteAllScheduledEventJobs() {
  console.log(`API_TEST_apiNamedArea deleteAllScheduledEventJobs`);

  const response = yield myApi.get("/api/delete_scheduled_event_jobs");

  console.log(`API_TEST_apiNamedArea deleteAllScheduledEventJobs`, response);
  return response.data;
}

export function* deleteScheduledEventJob(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  console.log("deleteScheduledEventJob token", token);
  console.log("deleteScheduledEventJob id", id);

  const payloadDeleteMsg = {
    id: id,
    topic: `scheduled_job/${id}/delete`,
    delete: true,
    token: token,
  };

  //console.log("payloadDeleteMsg", payloadDeleteMsg);

  const response = yield myApi.post(
    "/api/delete_scheduled_event_job",
    payloadDeleteMsg
  );
  // console.log(
  //   `API_TEST_apiNamedArea deleteScheduledEventJob delete_scheduled_event_job`,
  //   response
  // );
  return { topic: `${payloadDeleteMsg.topic}`, token: token };
}

export function* fetchNamedAreaScheduledEventInfo() {
  // See - iot-firefly-server web/route.go  note
  // NOTE - for some WTF reason if I name these "get_all_..." the router does not find the handlefunc().
  // again "WTF?!" ... no anyway, they are no consistent with above "get_all..."
  const response = yield myApi.get("/api/get_scheduled_events");
  // console.log(
  //   `API_TEST_apiNamedArea fetchNamedAreaScheduledEventInfo get_all_scheduled_events`,
  //   response
  // );
  return response.data;
}

export function* deleteNamedArea(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  // console.log("deleteNamedArea token", token);
  // console.log("deleteNamedArea id", id);

  const payloadDeleteMsg = {
    topic: `named_area/${id}/delete`,
    qos: 0,
    message: { id: id, delete: true, token: token },
    retained: false,
  };

  // console.log("payloadDeleteMsg", payloadDeleteMsg);

  const deleteNamedArea = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadDeleteMsg, "MQTT_PUBLISH");

      // checkToken(token)
      //   .catch((error) => {
      //     console.log(`checkToken error: ${error}`);
      //     throw error; // throwing this or another error jumps to the next catch
      //   })
      //   .then((result) => console.log("found the Ack at index: " + result)) // found matching ack
      //   .then(() => removeAckToken(token)) // delete it
      //   // .then((result) => resolve(result))
      //   .then(() => resolve("ACK new OK"))
      //   .catch((error) => {
      //     console.log(`checkToken final error: ${error}`);
      //     reject(error);
      //   });

      //resolve(`${payloadDeleteMsg.topic}`);
      resolve({ topic: `${payloadDeleteMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield deleteNamedArea();
    console.log("deleteNamedArea response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false); //protectedAPI.post(`/minelevel`, form);
    //console.log("deleteNamedArea response", response);
    return response;
  }
}

export function* deleteNamedAreaPost(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  //console.log("deleteNamedAreaPost token", token);
  //console.log("deleteNamedAreaPost id", id);

  const payloadDeleteMsg = {
    id: id,
    topic: `named_area/${id}/delete`,
    delete: true,
    token: token,
  };

  //console.log("payloadDeleteMsg", payloadDeleteMsg);

  const response = yield myApi.post("/api/delete_named_area", payloadDeleteMsg);
  // console.log(
  //   `API_TEST_apiNamedArea deleteNamedAreaPost delete_named_area`,
  //   response
  // );
  return { topic: `${payloadDeleteMsg.topic}`, token: token };
}

export function* saveEmergencyEventSettings({ id, settings }) {
  // prepare message token
  const token = messageToken(userSessionIp);

  // console.log("saveEmergencyEventSettings token", token);
  // console.log("saveEmergencyEventSettings portSettings", id, settings);

  const saveMessage = {
    id,
    ...settings,
    token: token,
  };

  //  console.log("saveMessage", saveMessage);

  const payloadSaveMsg = {
    topic: `emergency/${id}/change`,
    qos: 0,
    message: saveMessage,
    retained: false,
  };

  console.log("saveEmergencyEventSettings payloadSaveMsg", payloadSaveMsg);

  const saveEmergencyEventSettings = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadSaveMsg, "MQTT_PUBLISH");
      //resolve("saveEmergencyEventSettings Saved");
      // resolve(`${payload .....
      resolve({ topic: `${payloadSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveEmergencyEventSettings();
    //console.log("saveEmergencyEventSettings response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    // console.log("saveEmergencyEventSettings response", response);
    return response;
  }
}

export function* saveTriggerEventSettings({ id, settings }) {
  // prepare message token
  const token = messageToken(userSessionIp);

  // console.log("saveTriggerEventSettings token", token);
  // console.log("saveTriggerEventSettings id, position, name", id, settings);

  const saveMessage = {
    id,
    ...settings,
    token: token,
  };

  // console.log("saveMessage", saveMessage);

  const payloadSaveMsg = {
    topic: `trigger/${id}/change`,
    qos: 0,
    message: saveMessage,
    retained: false,
  };

  //  console.log("payloadSaveMsg", payloadSaveMsg);

  const saveTriggerEventSettings = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadSaveMsg, "MQTT_PUBLISH");
      //resolve("saveTriggerEventSettings Saved");
      // resolve(`${payload .....
      resolve({ topic: `${payloadSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveTriggerEventSettings();
    console.log("saveTriggerEventSettings response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    //    console.log("saveTriggerEventSettings response", response);
    return response;
  }
}
