import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router";

import { push } from "react-router-redux";
import {
  Form,
  Container,
  Button,
  Menu,
  Popup,
  Icon,
  Segment,
  Header,
  Dropdown,
  Message,
  Grid,
  Tab,
  Divider,
} from "semantic-ui-react";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging
import { isDirty, isSubmitting } from "redux-form";

import { LayersControl } from "react-leaflet";

import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroupGeoJson";
//import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroup";

import { getMineLevelCentre } from "MineLevels/reducer";

//import { getFireflyById } from 'UPSPanelControllers/reducer'
import {
  getFireflyById,
  getControllerById,
  getFireflyCoordinatesById,
} from "components/WebWorker/reducer";
import { formatRelative, parseISO } from "date-fns";

import { saveFirefly, deleteFirefly } from "UPSPanelControllers/actions";

import ChooseMineLevelField from "components/ChooseMineLevelField";
import TopologyField from "components/TopologyField";

import MapWithImage from "components/Map/MapWithImage";
//import { MineLevelMapById } from "components/MineLevelMap";
import { MineLevelMapById } from "components/Map/MineLevelMap";

import MapMarker from "components/ColorableMapMarker";

import FireflyMoveableMarker from "admin/firefly/FireflyMoveableMarker";

import FireflyName from "containers/FireflyName";

import { renderField, renderTextArea } from "admin/form-field";
import { SaveButton, AlwaysSaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import selector from "admin/firefly/form-value-selector";

import isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import { transform } from "components/Map/referencePoints";

import FireflyRawStatusReport from "admin/firefly/FireflyRawStatusReport";
import { getAreaStatusesById } from "components/WebWorker/reducer";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import SubmissionModal from "admin/SubmissionModal";
import NavigationPromptModal from "admin/NavigationPromptModal";

import { fetchUPSs, fetchFireflys } from "UPSPanelControllers/actions";

function MapPositioningDisplay({ mineLevelId, children }) {
  if (!mineLevelId) {
    return <div>No mine level selected</div>;
  }
  return (
    <MineLevelMapById
      mineLevelId={mineLevelId}
      style={{
        height: "60vh",
        width: "100%",
        position: "relative",
        zIndex: 0,
        // #REVIEW #WIP #TODO - get background from mineLevel
        //backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
        //backgroundColor: `rgba(255,255,255,1)`,
      }}
    >
      {children}
    </MineLevelMapById>
  );
}

// class EditLocationField extends Component {
//   render() {
//     const { fireflyId, mineLevelId, lat, lng, onMarkerMoved } = this.props; // mineLevelId,

//     //console.log("FireflyDetailsForm EditLocationField this.props", this.props);

//     if (!mineLevelId) {
//       return <div>Must select mine area</div>;
//     }
//     return (
//       <Form.Field>
//         <MineLevelMapById
//           mineLevelId={mineLevelId}
//           style={{
//             height: "60vh",
//             width: "100%",
//             position: "relative",
//             zIndex: 0,
//             // #REVIEW #WIP #TODO - get background from mineLevel
//             //backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
//             backgroundColor: `rgba(255,255,255,1)`,
//           }}
//         >
//           <MapMarker
//             lat={lat}
//             lng={lng}
//             draggable
//             onDragend={onMarkerMoved}
//             color={"blue"}
//             size={20}
//             circleText={"F"}
//           >
//             {fireflyId}
//             {/* <FireflyName id={fireflyId} /> */}
//           </MapMarker>

//           {/* HB_1Oct20 - LayersControl was commented out as this was causing map updates
// which were grabbing the marker from the mouse pointer.
// Review the operation and need for marker overlays.
// Consider putting in a detailed shouldComponentUpdate (see above) */}

//           <LayersControl position={"topright"}>
//             <LayersControl.Overlay key={"firefliesOverlay"} name={"Fireflies"}>
//               <CurrentMineLevelFirefliesFeatureGroup />
//             </LayersControl.Overlay>
//           </LayersControl>
//         </MineLevelMapById>
//       </Form.Field>
//     );
//   }
// }

// EditLocationField = connect((state) => ({ ...selector(state, "position") }))(
//   EditLocationField
// );

// class UpdateTopologyButton extends Component {
//   revert = (e) => {
//     e.preventDefault();
//     const { change, commissionedTopology } = this.props;
//     change("topology", commissionedTopology);
//   };
//   update = (e) => {
//     e.preventDefault();
//     const { change, reportedTopology } = this.props;
//     change("topology", reportedTopology);
//   };
//   doNothing = (e) => {
//     e.preventDefault();
//   };
//   render() {
//     const { setting, commissionedTopology, reportedTopology } = this.props;
//     let text = "ERROR";
//     let disabled = true;
//     let color = "red";
//     let onClick = this.doNothing;
//     if (isEqual(reportedTopology, commissionedTopology)) {
//       text = "Unchanged";
//       disabled = true;
//       color = "grey";
//       onClick = this.doNothing;
//     } else if (isEqual(setting, commissionedTopology)) {
//       text = "Update";
//       disabled = false;
//       color = "green";
//       onClick = this.update;
//     } else if (isEqual(setting, reportedTopology)) {
//       text = "Revert";
//       disabled = false;
//       color = "orange";
//       onClick = this.revert;
//     }

//     return (
//       <Form.Field>
//         <Form.Button disabled={disabled} color={color} onClick={onClick}>
//           {text}
//         </Form.Button>
//       </Form.Field>
//     );
//   }
// }
// UpdateTopologyButton = connect((state) => ({
//   setting: selector(state, "topology"),
//   commissionedTopology: selector(state, "commissionedTopology"),
//   reportedTopology: selector(state, "reportedTopology"),
// }))(UpdateTopologyButton);

const options = [
  { key: "1234567891", text: "1234567891", value: "1234567891" },
  { key: "1234567892", text: "1234567892", value: "1234567892" },
  { key: "1234567893", text: "1234567893", value: "1234567893" },
  { key: "1234567894", text: "1234567894", value: "1234567894" },
];

const renderCheckbox = (field) => (
  <Form.Checkbox
    checked={!!field.input.value}
    name={field.input.name}
    label={field.label}
    onChange={(e, { checked }) => field.input.onChange(checked)}
  />
);

const renderSelect = (field) => (
  <Form.Select
    selection
    clearable
    allowAdditions
    className="label"
    defaultValue="hide"
    //
    label={field.label}
    name={field.input.name}
    onBlur={(e, { value }) => {
      //console.log("BLUR", value);
      field.input.onChange(value);
    }}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    //onAddItem={this.handleAddition} // catch addition of items
    value={field.input.value}
  />
);

class FireflyDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options,
      collapse: false,
      errorMessage: "",
    };
  }

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  componentDidMount() {
    //console.log(`jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj componentDidMount`);
  }

  componentWillUnmount() {
    //console.log(`jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj componentWillUnmount`);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only re-render if the firefly object has changed.
    // This stops re-rendering the map unnecessarily

    return (
      !isEqual(this.props.fireflyId, nextProps.fireflyId) ||
      !isEqual(this.props.fireflyNote, nextProps.fireflyNote) ||
      !isEqual(this.props.position, nextProps.position) ||
      !isEqual(this.state.collapse, nextState.collapse) ||
      !isEqual(this.state.errorMessage, nextState.errorMessage)
      // ||
      // !isEqual(this.props.submitting, this.props.submitting) ||
      // !isEqual(this.props.pristine, this.props.pristine)
    );
  }

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });

  submitMyForm = (values) => {
    const { saveFirefly, transformPixelsToUtm, reset, fireflyId } = this.props;

    //console.log("submitMyForm values", values);
    const objLatLng = transformPixelsToUtm.transform({
      lat: values.position.lat,
      lng: values.position.lng,
    });

    // transform position to UTM
    const newPosition = {
      position: {
        mineLevelId: values.position.mineLevelId,
        lat: objLatLng.lat,
        lng: objLatLng.lng,
      },
    };
    const newValues = { ...values, ...newPosition };

    //console.log("saveFirefly newValues", newValues);

    return new Promise((resolve, reject) => {
      saveFirefly({ values: newValues, resolve, reject });
    }).then(() => {
      // wait for state change before redirecting page
      this.setState(
        {
          submitted: true,
        },
        () => {
          const { fetchFireflys } = this.props;
          fetchFireflys(); // #FETCHS

          // #WIP - stay on the same page when we can fetch() the update
          this.props.goto(`/admin/firefly`);
        }
      );
    });
  };

  requestDelete = (values) => {
    const { deleteFirefly, fireflyId, push, change } = this.props;

    //console.log(`deleteFirefly requestDelete`, this.props);

    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete");

    return new Promise((resolve, reject) => {
      deleteFirefly({ values: { id: fireflyId }, resolve, reject });
    }).then(
      () => {
        const { fetchFireflys } = this.props;
        fetchFireflys(); // #FETCHS

        this.props.goto("/admin/firefly/");
      },
      (errorMsg) => {
        //console.log(`deleteFirefly errorMsg`, errorMsg);
        // pop error dlg
        const message = {
          header: {
            icon: "warning",
            title: "Delete Failed",
          },
          content: "Error when attempting to delete data.",
        };
        this.setState({
          errorMessage: `${errorMsg}`,
          modalMessage: message,
        });
      }
    );
  };

  cancelForm = (e) => {
    e.preventDefault();

    this.props.goto("/admin/firefly/");
  };

  onDragEnded = ({ target }) => {
    const ll = target.getLatLng();
    this.props.change("position.lat", ll.lat);
    this.props.change("position.lng", ll.lng);
  };

  clickControllerIdIcon = (e) => {
    e.preventDefault();
    this.props.goto(`/admin/controller/${this.props.controllerId}`);
  };

  onResetError = () => {
    const { goto, submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      goto("/");
    }
  };

  render() {
    const {
      handleSubmit,
      change,
      pristine,
      submitting,
      error,
      fireflyId,
      reset,
      controllerIp,
      //controllerId,
      mineLevelId,
      // lat,
      // lng,
      // onMarkerMoved,
      //formValues,
      //
      isDeleteButton,
      strings,
    } = this.props;

    const {
      collapse: isCollapse,
      submitted,
      modalMessage,
      errorMessage,
    } = this.state;

    const openModal = errorMessage !== "";

    //console.log(`xxxxxxxxxxx formValues`, formValues);

    const panes = [
      {
        menuItem: "Position",
        render: () => (
          <Tab.Pane>
            <MapPositioningDisplay mineLevelId={mineLevelId}>
              <FireflyMoveableMarker change={change} name={"position"}>
                {fireflyId}
                {/* <FireflyName id={ups.id} /> */}
              </FireflyMoveableMarker>
              <LayersControl position={"topright"}>
                <LayersControl.Overlay
                  key={"firefliesOverlay"}
                  name={"FireFlys"}
                >
                  <CurrentMineLevelFirefliesFeatureGroup />
                </LayersControl.Overlay>
              </LayersControl>
            </MapPositioningDisplay>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Latest Report",
        render: () => (
          <Tab.Pane>
            <FireflyRawStatusReport id={fireflyId} />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <>
        <NavigationPromptModal
          formName={"fireflyDetailsForm"}
          submitted={submitted}
          onSubmit={(formValues) => handleSubmit(this.submitMyForm(formValues))}
        />
        <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          modalMessage={modalMessage}
          errorMessage={errorMessage}
        />
        <Container>
          {error && <div className="ui error message">{error}</div>}
          <Form onSubmit={handleSubmit(this.submitMyForm)}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column stretched width={isCollapse ? 1 : 6}>
                  {isCollapse && (
                    <Segment>
                      <Grid>
                        <Grid.Row columns={1}>
                          <Grid.Column>
                            <Popup
                              content={strings?.["Expand Left Side"]}
                              trigger={
                                <Button
                                  floated={"left"}
                                  icon
                                  onClick={this.expandCollapse}
                                  size={"mini"}
                                  color={"teal"}
                                >
                                  <Icon name={"angle right"} size={"large"} />
                                </Button>
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  )}
                  {!isCollapse && (
                    <>
                      <Segment>
                        <Grid>
                          <Grid.Row>
                            <div style={{ paddingLeft: "14px" }}>
                              <Menu icon="labeled">
                                <Menu.Item
                                  name="Controller Site"
                                  href={`http://${controllerIp}`}
                                  target="_blank"
                                  disabled={_isEmpty(controllerIp)}
                                >
                                  <Icon name="external alternate" />
                                  Controller Config {controllerIp}
                                </Menu.Item>
                                <Menu.Item
                                  name="Controller Details"
                                  onClick={(e) => this.clickControllerIdIcon(e)}
                                >
                                  <Icon name="hdd" />
                                  Controller Details
                                </Menu.Item>
                              </Menu>
                            </div>
                            <Grid.Column floated={"right"}>
                              <Popup
                                content={strings?.["Collapse Left Side"]}
                                trigger={
                                  <Button
                                    floated={"right"}
                                    icon
                                    onClick={this.expandCollapse}
                                    size={"mini"}
                                    color={"teal"}
                                  >
                                    <Icon name={"angle left"} size={"large"} />
                                  </Button>
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Divider hidden />
                        <Field
                          name="id"
                          label={strings?.["FireFly ID"]}
                          placeholder={strings?.["FireFly Id"]}
                          component={renderField}
                          className="disabled-form-field"
                          disabled
                        />
                        <Field
                          name="position.mineLevelId"
                          label={strings?.["Area"]}
                          placeholder={strings?.["Area"]}
                          component={renderField}
                          className="disabled-form-field"
                          disabled
                        />
                        <Form.Group widths="equal">
                          <Field
                            name="fireflyLocation"
                            label={strings?.["Location"]}
                            placeholder={strings?.["FireFly Location"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                          <Field
                            name="fireflyPosition"
                            label={strings?.["Position"]}
                            placeholder={strings?.["FireFly Position"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                          <Field
                            name="fireflyMac"
                            label={strings?.["MAC Address"]}
                            placeholder={strings?.["FireFly MAC Address"]}
                            component={renderField}
                            className="disabled-form-field"
                            disabled
                          />
                        </Form.Group>
                        <Segment>
                          <Form.Group>
                            <Field
                              name="controllerName"
                              label={strings?.["Controller"]}
                              placeholder={strings?.["Controller Name"]}
                              component={renderField}
                              className="disabled-form-field"
                              disabled
                            />
                            <Field
                              name="controllerId"
                              label={strings?.["Controller MAC"]}
                              placeholder={strings?.["Controller MAC"]}
                              component={renderField}
                              className="disabled-form-field"
                              disabled
                            />
                            <Field
                              name="controllerIp"
                              label={strings?.["Controller Config Address"]}
                              placeholder={strings?.["unknown"]}
                              component={renderField}
                              className="disabled-form-field"
                              disabled
                            />

                            {/* <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                            }}
                            className={"biggerIcon"}
                          >
                            <Menu.Item
                              href={`http://${controllerIp}`}
                              position="right"
                              target="_blank"
                            >
                              <Icon name="external alternate" size="big" />
                            </Menu.Item>
                          </div>
                        </div> */}
                          </Form.Group>
                        </Segment>
                        <Field
                          name="lastStatusReport"
                          label={strings?.["Latest Report"]}
                          placeholder={strings?.["Latest Report"]}
                          component={renderField}
                          className="disabled-form-field"
                          disabled
                        />
                      </Segment>
                      <Segment>
                        <Field
                          name="fireflyNote"
                          label={strings?.["Note"]}
                          placeholder={strings?.["FireFly_Note_Placeholder"]}
                          component={renderTextArea}
                        />
                      </Segment>
                      {/* #NOTE - commented out as this is unlikely to ever be used. Was in first definition from Greg */}
                      {/* {false && (
                        <Segment>
                          <Header>BLE Setup</Header>

                          <Form.Group widths="equal" inline>
                            <Field
                              component={renderSelect}
                              label="BLE Slave ID"
                              name="idBLETag"
                              options={this.state.options}
                              placeholder="Select a BLE Slave ID"
                            />
                            <Field
                              component={renderCheckbox}
                              label="BLE Tag detect"
                              name="isBLETagDetect"
                            />
                          </Form.Group>
                        </Segment>
                      )} */}
                    </>
                  )}

                  {/* <Segment>
                  <Form.Group inline>
                    <SaveButton submitting={submitting} pristine={pristine} />
                    <DeleteButton onClick={this.requestDelete} id={fireflyId} />
                    <Form.Button onClick={reset}>Reset</Form.Button>
                  </Form.Group>
                </Segment> */}
                </Grid.Column>

                <Grid.Column width={isCollapse ? 15 : 10}>
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column>
                        {isDeleteButton && (
                          <Grid.Row>
                            <DeleteButton
                              onClick={this.requestDelete}
                              id={fireflyId}
                              strings={strings}
                            />
                          </Grid.Row>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Button.Group floated="right">
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={reset}
                            >
                              {strings?.["Reset"]}
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              {strings?.["Cancel"]}
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Segment>

                  <Tab
                    panes={panes}
                    menu={{
                      borderless: true,
                      attached: false,
                      tabular: false,
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <DebugPagePropsMessages that={this} />
        </Container>
      </>
    );
  }
}

// see - import selector from "./form-value-selector";
//const selector = formValueSelector("fireflyDetailsForm");

FireflyDetailsForm = reduxForm({
  form: "fireflyDetailsForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
})(FireflyDetailsForm);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    saveFirefly: (data) => dispatch(saveFirefly(data)),
    deleteFirefly: (data) => dispatch(deleteFirefly(data)),
    //
    fetchFireflys: () => dispatch(fetchFireflys()),
  };
};

const mapStateToProps = (state, props) => {
  const { isDeleteFireflyNotCommissioned } = props;

  const firefly = getFireflyById(state, props.id);

  //console.log(`xxxx firefly`, firefly);

  const id = props.id;

  // extract out the actual "immutable" parts
  const { utm, topology, timestamp, fireflyNote } = firefly;

  const {
    area: mineLevelId,
    position: fireflyPosition,
    location: fireflyLocation,
    mac: fireflyMac,
    ups_name,
    ups_id,
  } = topology;

  const area = getAreaStatusesById(state, mineLevelId);
  const transformPixelsToUtm = area?.transformPixelsToUtm;
  const transformUtmToPixels = area?.transformUtmToPixels;

  const controller = getControllerById(state, ups_id);

  //console.log(`controller`, controller);

  const controllerIp = controller?.topology?.ip;
  const fireflyCoord = getFireflyCoordinatesById(state, id);
  const lat = fireflyCoord !== undefined ? fireflyCoord[1] : 0;
  const lng = fireflyCoord !== undefined ? fireflyCoord[0] : 0;

  const mdt = [];

  const lastStatusReport =
    timestamp !== undefined
      ? formatRelative(parseISO(timestamp), new Date(), {
          includeSeconds: true,
        })
      : "-";

  const isBLETagDetect = "false";
  const idBLETag = "12345678";

  let isDeleteButton =
    area !== undefined && isDeleteFireflyNotCommissioned
      ? true
      : !_isEmpty(utm);

  return {
    // values,
    // submitSucceeded,
    fireflyId: selector(state, "id"),
    controllerIp: selector(state, "controllerIp"),
    controllerId: selector(state, "controllerId"),
    position: selector(state, "position"),
    fireflyNote: selector(state, "fireflyNote"),
    initialValues: {
      _action: "", // used to control dlg display
      id,
      lastStatusReport,
      fireflyLocation,
      fireflyPosition,
      fireflyMac,
      controllerName: ups_name,
      controllerId: ups_id,
      controllerIp,
      position: { mineLevelId, lat, lng },
      commissionedTopology: topology,
      //reportedTopology,
      isBLETagDetect,
      idBLETag,
      fireflyNote,
    },
    transformPixelsToUtm,
    transformUtmToPixels,
    mineLevelId,
    //
    isDeleteButton,

    //
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("onChange values", values);
      //console.log("onChange props", props);

      const mdtChanged = values.mdt !== previousValues.mdt;

      if (mdtChanged) {
        let {
          change,
          initialValues: { mdt: mdt },
        } = props;

        dispatch(change("mdt", values.mdt));
      }

      const mineLevelChanged =
        values.position.mineLevelId !== previousValues.position.mineLevelId;
      if (mineLevelChanged) {
        let {
          change,
          initialValues: {
            position: { mineLevelId, lat, lng },
          },
        } = props;

        if (values.position.mineLevelId !== mineLevelId) {
          // const centre = getMineLevelCentre(state, values.position.mineLevelId);
          // lat = centre.lat;
          // lng = centre.lng;
          lat = 0;
          lng = 0;
        }
        dispatch(change("position.lat", lat));
        dispatch(change("position.lng", lng));
      }
    },
    formValues: getFormValues("fireflyDetailsForm")(state), // #REVIEW - only used for debugging,
  };
};

FireflyDetailsForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FireflyDetailsForm)
);

export default FireflyDetailsForm;
