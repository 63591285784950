import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";

//import { getAllMineLevels } from 'MineLevels/reducer'
import { getAllAreaStatuses } from "components/WebWorker/reducer";

const ChooseMineLevel = ({
  options,
  currentValue = null,
  onChange,
  disabled = false,
}) => {
  return (
    <Dropdown
      selection
      options={options}
      value={currentValue}
      onChange={onChange}
      placeholder={"Choose an area"}
      disabled={disabled}
    />
  );
};

function mapStateToProps(state) {
  return {
    options: getAllAreaStatuses(state).map((ml) => ({
      value: ml.id,
      text: ml.name,
    })),
  };
}

export default connect(mapStateToProps)(ChooseMineLevel);
