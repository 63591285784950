import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import _isEmpty from "lodash/isEmpty";
import hash from "object-hash";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
  getUserSettings,
} from "auth/reducer";

import { checkAuth } from "auth/actions";

import { Container, Grid, Card } from "semantic-ui-react";

import { getSettingsDataLoading } from "components/Settings/reducer";

import GeneralHomePage from "pages/GeneralHomePage";
import ControlRoomPage from "pages/ControlRoomPage";

//import UPSAdminPages from "admin/ups/UPSAdminPages";
import FireflyAdminPages from "admin/firefly/FireflyAdminPages";
import BulkPositioningAdminPages from "admin/bulk-positioning/BulkPositioningAdminPages";
import NamedAreaGroupAdminPages from "admin/named-area-group/NamedAreaGroupAdminPages";
import NamedAreaAdminPages from "admin/named-area/NamedAreaAdminPages";
import NamedAreaSimpleAdminPages from "admin/named-area-simple/NamedAreaSimpleAdminPages";
import MineLevelAdminPage from "admin/mine-level/MineLevelAdminPages";
import TagTrackingAdminPages from "admin/tag-tracking/TagTrackingAdminPages";
import TagTrackingUserAdminPages from "admin/tag-tracking/TagTrackingUserAdminPages";
import EventAdminPages from "admin/event/EventAdminPages";

import LogPage from "admin/logs/Logs";
import FailPage from "admin/logs/FailLog";

import FaultPage from "pages/Faults/FaultPage";

import LoginPage from "containers/LoginPage";
import Header from "components/Header";
//import SubHeader from "components/HeaderSubHeader";

import Footer from "components/Footer";
import NoMatch from "components/NoMatch";

import LevelSpecificPage from "pages/LevelSpecificPage";

import CheckBrowser from "components/CheckBrowser";
import { checkBrowser } from "components/CheckBrowser/actions";
import {
  CheckConfigJs,
  checkForBrowserType,
  isTagTracking as _isTagTracking,
  isLightingControl as _isLightingControl,
  configHomepage as _configHomepage,
  configAppTitle as _configAppTitle,
  isDemoMode as _isDemoMode,
  appResponsive,
  isAreaImageToState,
  isShowBackupRestore as _isShowBackupRestore,
  isShowExternalTriggerEvent as _isShowExternalTriggerEvent,
  isShowScheduledEvent as _isShowScheduledEvent,
  languageOptions,
} from "components/ConfigJs";

import Logger from "components/Logger";
//import TagTrackingList from "admin/tag-tracking/TagTrackingList";
import TagTrackingByZoneList from "admin/tag-tracking/TagTrackingByZoneList";
import TagTrackingMap from "admin/tag-tracking/TagTrackingMap";

// user pages
import UserPages from "components/User/UserPages";
import UserAdminPages from "components/UserAdmin/UserAdminPages";

// help pages
import HelpFilesList from "components/Help/HelpFilesList";
import HelpFilesListAreas from "components/Help/HelpFilesListAreas";

// tools pages
import ToolsMqtt from "components/Tools/ToolsMqtt";
import ToolsDatabase from "components/Tools/ToolsDatabase";
import ToolsTerminal from "components/Tools/ToolsTerminal";
import ToolsSystem from "components/Tools/ToolsSystem";
import ToolsBackupRestore from "components/Tools/ToolsBackupRestore";
import ToolsMqttList from "components/Tools/ToolsMqttList";

// localisation
//import LocalisationList from "admin/localisation/LocalisationList";

//

// Test pages
import TestMqttControl from "pages/Test/TestMQTT/index";
import TestMqttGeoJson from "pages/Test/TestMqttGeoJson";
import TestMarkerIcons from "pages/Test/TestMarkerIcons";

// #REVIEW - these need to be combined in proper MQTT management of subscribe and publish
//import Worker from "pages/Test/TestMQTT/testMqttWorker";
import WebWorker from "components/WebWorker";
import StatusChecks from "components/StatusChecks";

import { FetchAllData } from "components/FetchData";

import Settings from "components/Settings";

// Area Images changed
import AreaImages from "components/AreaImages/AreaImageImg";
import { getIsAreaImagesChanged } from "components/WebWorker/reducer";

import { fetchLocalisation } from "components/Localisation/actions";

//import useWindowDimensions from "utils/useWindowDimensions";
import { useMediaQuery } from "react-responsive";

import { fetchFolderFilesListByFolder } from "components/Settings/actions";

// localisation
import { getUserData } from "auth/reducer";
import { getUserById } from "components/UserAdmin/reducer";
import { getLocalisationByComponentId } from "components/Localisation/reducer";
import { strings } from "components/App/localisation";
import { defaultStrings } from "components/App/localisation";
import LocalisationAdminPages from "admin/localisation/LocalisationAdminPages";

let AuthorizedApp = ({
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
  userSettings,
  //
  componentKey,
  //language, // localisation
  strings,
}) => {
  // get application view settings

  // console.log("xxx AuthorizedApp - componentKey", componentKey);
  // console.log("xxx AuthorizedApp - language", language);
  // console.log("xxx AuthorizedApp - strings", strings);
  //console.log("xxx AuthorizedApp - userSettings", userSettings);

  // console.log(
  //   "xxx AuthorizedApp - strings getLanguage",
  //   strings?.getLanguage?.()
  // );
  // console.log(
  //   "xxx AuthorizedApp - strings getInterfaceLanguage ",
  //   strings?.getInterfaceLanguage?.()
  // );

  // what home page do we show?
  const isTagTracking =
    userSettings?.application?.includes("tagTracking") || _isTagTracking();
  const isLightingControl =
    userSettings?.application?.includes("smartLighting") ||
    _isLightingControl();

  // #WIP - for now if smartLighting is not configed, default to tag tracking zones
  // #TODO - setup a config option to allow selector for which page is startup
  //
  let configHomepage = _configHomepage();
  if (isTagTracking && !isLightingControl) configHomepage = "tagTrackingZone";

  const isNotGuest = isUserSuper || isUserAdmin || isUserUser;
  const isShowBackupRestore =
    userSettings?.feature?.includes("showBackupRestore") ||
    _isShowBackupRestore();
  const isShowExternalTriggerEvent =
    userSettings?.feature?.includes("showExternalTriggerEvent") ||
    _isShowExternalTriggerEvent();
  const isShowScheduledEvent =
    userSettings?.feature?.includes("showScheduledEvent") ||
    _isShowScheduledEvent();

  // set the default lighting control page
  // if user is notGuest then show lighting control...
  const lightingControlPage = isNotGuest
    ? // unless lighting control is not enabled in which case...
      isLightingControl
      ? ControlRoomPage
      : // show the guest view
        GeneralHomePage
    : GeneralHomePage; // guest view

  //console.log("xxx isNotGuest", isNotGuest);
  //console.log("xxx isLightingControl", isLightingControl);

  let HomePage = lightingControlPage; // default to 'guest view'

  switch (configHomepage) {
    case "lightingControl":
      // defer to default
      break;
    case "tagTrackingMap":
      if (isTagTracking) {
        HomePage = TagTrackingMap;
      }
      break;
    case "tagTrackingZone":
      if (isTagTracking) {
        HomePage = TagTrackingByZoneList;
      }
      break;

    default:
      break;
  }

  const allowGuest = isUserGuest;
  const allowOperator = isUserSuper || isUserAdmin || isUserUser;
  const allowAdmin = isUserSuper || isUserAdmin;
  const allowSuper = isUserSuper;

  //const { height, width } = useWindowDimensions();
  const { mobile, tablet, laptop, desktop } = appResponsive();

  // #NOTE - This has was WIP and has been disabled.
  // Any variable which updates here will cause the app to re-render.
  // This shouldn't be a problem _EXCEPT_ many pages, like
  // polygon edit, firefly edit, controller edit, indeed anything with a
  // map render under react-leaflet, will **fail** during the re-render.
  //
  // This happens because the component unmounts and remounts. Since the edit pages
  // are passed parameters from the edit page they can not reinitialise the forms on
  // componentDidMount.
  //
  // To fix this requires updating these pages to be able to re-load with ComponentDidMount
  // then re-implement the responsive sizing.
  //
  // This has been disabled for now and should be revisited when there time allows.
  //

  // const isDesktopOrLaptop = useMediaQuery({
  //   query: `(min-width: ${desktop}px)`,
  // });

  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: `(max-width: ${mobile}px)`,
  // });

  // const isMobile = useMediaQuery({
  //   query: `(min-width: ${mobile}px)`,
  // });

  // const isTablet = useMediaQuery({
  //   query: `(min-width: ${tablet}px)`,
  // });

  // const isLaptop = useMediaQuery({
  //   query: `(min-width: ${laptop}px)`,
  // });

  // const isDesktop = useMediaQuery({
  //   query: `(min-width: ${desktop}px)`,
  // });

  // // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
  // let alarmButtonSize = "large";
  // if (isMobile) alarmButtonSize = "mini";
  // if (isTablet) alarmButtonSize = "tiny";
  // if (isLaptop) alarmButtonSize = "small";
  // if (isDesktop) alarmButtonSize = "large";

  //const alarmButtonSize = "small";

  // console.log(
  //   `SCREENSIZE: isMobile,isTablet,isLaptop,isDesktop, `,
  //   isMobile,
  //   isTablet,
  //   isLaptop,
  //   isDesktop
  // );

  // #NOTE - this is a patch while the code above is disabled
  const isMobile = useMediaQuery({
    query: `(max-width: ${mobile}px)`,
  });

  const showMinimal = isMobile; // !isDesktop && !isLaptop && !isTablet;
  const showSubHeader = false; // (isMobile || isTablet) && !isDesktop && !showMinimal;
  //const windowDimensions = { height: height, width: width };

  return (
    <>
      <Header
        className={"header"}
        //subHeader={showSubHeader}
        showMinimal={showMinimal}
        //alarmButtonSize={alarmButtonSize}
        //winDims={windowDimensions}
        key={componentKey} // used to force a re-render if the language is changed
        //language={language}
        strings={strings}
        userSettings={userSettings}
      />
      {/* {showSubHeader && (
        <SubHeader
          className={"header"}
          subHeader={!showSubHeader}
          showMinimal={showMinimal}
          alarmButtonSize={alarmButtonSize}
          //winDims={windowDimensions}
        />
      )} */}
      <Container className={"main"}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/lighting" component={lightingControlPage} />
          {allowOperator && (
            <Route path="/level/:nameSlug" component={LevelSpecificPage} />
          )}
          {allowOperator && (
            <Route
              path="/event-log"
              render={(props) => (
                <EventAdminPages
                  {...props}
                  strings={strings}
                  userSettings={userSettings}
                />
              )}
            />
          )}
          {allowOperator && isShowExternalTriggerEvent && (
            <Route
              path="/external-trigger-event"
              render={(props) => (
                <EventAdminPages
                  {...props}
                  strings={strings}
                  userSettings={userSettings}
                />
              )}
            />
          )}

          {allowOperator && (
            <Route
              path="/status"
              component={() => (
                <Switch>
                  <Route
                    path="/status/faults/firefly"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle={strings?.["FireFly Faults"]}
                        faultType="firefly"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/status/faults/battery"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle={strings?.["Battery Faults"]}
                        faultType="battery"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/status/faults/network"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle={strings?.["Network Faults"]}
                        faultType="network"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/status/controller"
                    //component={BulkPositioningAdminPages}
                    render={(props) => (
                      <BulkPositioningAdminPages
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/status/firefly"
                    //component={FireflyAdminPages}
                    render={(props) => (
                      <FireflyAdminPages
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/status/faults/controller"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle={strings?.["Controller Status"]}
                        faultType="controller"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  {isTagTracking && (
                    <>
                      <Route
                        path="/status/tag"
                        //component={TagTrackingAdminPages}
                        render={(props) => (
                          <TagTrackingAdminPages
                            {...props}
                            strings={strings}
                            userSettings={userSettings}
                          />
                        )}
                      />
                      {/* <Route
                        path="/admin/tag"
                        render={(props) => (
                          <TagTrackingList {...props} includeTitles="true" />
                        )}
                      /> */}
                      <Route
                        path="/status/tag-map"
                        render={(props) => (
                          <TagTrackingMap
                            {...props}
                            includeTitles="true"
                            //strings={strings} // #NOTE - this is a possible startup page so strings are imported.
                            userSettings={userSettings}
                          />
                        )}
                      />
                      <Route
                        path="/status/tagzone"
                        render={(props) => (
                          <TagTrackingByZoneList
                            {...props}
                            includeTitles="true"
                            // strings={strings} // #NOTE - this is a possible startup page so strings are imported.
                            userSettings={userSettings}
                          />
                        )}
                      />
                      <Route
                        path="/status/taguser/personnel"
                        render={(props) => (
                          <TagTrackingUserAdminPages
                            {...props}
                            tagUserType="personnel"
                            strings={strings}
                            userSettings={userSettings}
                          />
                        )}
                      />
                      <Route
                        path="/status/taguser/vehicle"
                        render={(props) => (
                          <TagTrackingUserAdminPages
                            {...props}
                            tagUserType="vehicle"
                            strings={strings}
                            userSettings={userSettings}
                          />
                        )}
                      />
                    </>
                  )}
                </Switch>
              )}
            />
          )}
          {allowAdmin && (
            <Route
              path="/admin"
              component={() => (
                <Switch>
                  <Route
                    path="/admin/named-area-group"
                    //component={NamedAreaGroupAdminPages}
                    render={(props) => (
                      <NamedAreaGroupAdminPages
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/named-area"
                    //component={NamedAreaSimpleAdminPages}
                    render={(props) => (
                      <NamedAreaSimpleAdminPages
                        {...props}
                        namedAreaSubType="region"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/named-area/region"
                    //component={NamedAreaSimpleAdminPages}
                    render={(props) => (
                      <NamedAreaSimpleAdminPages
                        {...props}
                        namedAreaSubType="region"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/named-area-advanced"
                    component={NamedAreaAdminPages}
                  />
                  <Route
                    path="/admin/named-area-advanced-all"
                    component={NamedAreaAdminPages}
                  />
                  <Route
                    path="/admin/named-area/forced"
                    //component={NamedAreaSimpleAdminPages}
                    render={(props) => (
                      <NamedAreaSimpleAdminPages
                        {...props}
                        namedAreaSubType="forced"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/area"
                    //component={MineLevelAdminPage}
                    render={(props) => (
                      <MineLevelAdminPage
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/controller"
                    //component={BulkPositioningAdminPages}
                    render={(props) => (
                      <BulkPositioningAdminPages
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  {/* <Route path="/admin/ups" component={UPSAdminPages} /> */}
                  <Route
                    path="/admin/firefly"
                    //component={FireflyAdminPages}
                    render={(props) => (
                      <FireflyAdminPages
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/logs"
                    //component={LogPage}
                    render={(props) => (
                      <LogPage
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/fail" //component={FailPage}
                    render={(props) => (
                      <FailPage
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  {/* <Route
                    path="/admin/bulk-positioning"
                    //component={BulkPositioningAdminPages}
                    render={(props) => (
                      <BulkPositioningAdminPages
                        {...props}
                        strings={strings}
                      />
                    )}
                  /> */}
                  <Route
                    path="/admin/faults/firefly"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle={strings["FireFly Faults"]}
                        faultType="firefly"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/faults/battery"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle="Battery Faults"
                        faultType="battery"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/faults/network"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle="Network Faults"
                        faultType="network"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/faults/controller"
                    // component={FaultPage}
                    render={(props) => (
                      <FaultPage
                        {...props}
                        pageTitle="Controller Status"
                        faultType="controller"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/admin/faults/logs"
                    render={(props) => (
                      <Logger
                        {...props}
                        includeTitles="true"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  {isTagTracking && (
                    <>
                      <Route
                        path="/admin/named-area/tagzone"
                        //component={NamedAreaSimpleAdminPages}
                        render={(props) => (
                          <NamedAreaSimpleAdminPages
                            {...props}
                            namedAreaSubType="tagzone"
                            strings={strings}
                            userSettings={userSettings}
                          />
                        )}
                      />
                      {/* <Route
                        path="/admin/tag"
                        component={TagTrackingAdminPages}
                      /> */}
                    </>
                  )}
                  {/* {isShowExternalTriggerEvent && (
                    <Route
                      path="/admin/external-trigger-event"
                      render={(props) => (
                        <EventAdminPages {...props} strings={strings} />
                      )}
                    />
                  )} */}
                  {isShowScheduledEvent && (
                    <Route
                      path="/admin/scheduled-job"
                      render={(props) => (
                        <EventAdminPages
                          {...props}
                          strings={strings}
                          userSettings={userSettings}
                        />
                      )}
                    />
                  )}
                  {isShowScheduledEvent && (
                    <Route
                      path="/admin/scheduled-event"
                      render={(props) => (
                        <EventAdminPages
                          {...props}
                          strings={strings}
                          userSettings={userSettings}
                        />
                      )}
                    />
                  )}

                  {/* <Route
                    path="/admin/event-log"
                    render={(props) => (
                      <EventAdminPages {...props} strings={strings} />
                    )}
                  /> */}
                  <Route
                    //component={NoMatch}
                    render={(props) => (
                      <NoMatch
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                </Switch>
              )}
            />
          )}
          {allowOperator && (
            <Route
              path="/admin"
              component={() => (
                <Switch>
                  {isTagTracking && (
                    <>
                      {/* <Route
                        path="/admin/named-area/tagzone"
                        //component={NamedAreaSimpleAdminPages}
                        render={(props) => (
                          <NamedAreaSimpleAdminPages
                            {...props}
                            namedAreaSubType="tagzone"
                          />
                        )}
                      /> */}
                      <Route
                        path="/status/tag"
                        component={TagTrackingAdminPages}
                      />
                    </>
                  )}
                  <Route component={NoMatch} />
                </Switch>
              )}
            />
          )}
          {/* Test menu */}
          {allowSuper && (
            <Route
              path="/test"
              component={() => (
                <Switch>
                  <Route
                    path="/test/test-mqtt-control"
                    render={(props) => <TestMqttControl {...props} />}
                  />
                  <Route
                    path="/test/test-mqtt-image-overlay-geojson-utm-table"
                    render={(props) => <TestMqttGeoJson {...props} />}
                  />
                  <Route
                    path="/test/test-marker-icons"
                    render={(props) => <TestMarkerIcons {...props} />}
                  />
                  <Route component={NoMatch} />
                </Switch>
              )}
            />
          )}
          <Route
            path="/user"
            component={() => (
              <Switch>
                <Route
                  path="/user/profile"
                  render={(props) => (
                    <UserPages
                      {...props}
                      strings={strings}
                      userSettings={userSettings}
                    />
                  )}
                />
                <Route
                  path="/user/admin"
                  render={(props) => (
                    <UserAdminPages
                      {...props}
                      strings={strings}
                      userSettings={userSettings}
                    />
                  )}
                />
                <Route component={NoMatch} />
              </Switch>
            )}
          />
          <Route
            path="/help"
            component={() => (
              <Switch>
                <Route
                  path="/help/files"
                  render={(props) => (
                    <HelpFilesList
                      {...props}
                      strings={strings}
                      userSettings={userSettings}
                    />
                  )}
                />
                <Route
                  path="/help/area-images"
                  render={(props) => (
                    <HelpFilesListAreas
                      {...props}
                      strings={strings}
                      userSettings={userSettings}
                    />
                  )}
                />
                <Route component={NoMatch} />
              </Switch>
            )}
          />
          {allowSuper && (
            <Route
              path="/tools"
              component={() => (
                <Switch>
                  <Route
                    path="/tools/mqtt"
                    render={(props) => (
                      <ToolsMqtt
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/tools/database"
                    render={(props) => (
                      <ToolsDatabase
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/tools/terminal"
                    render={(props) => (
                      <ToolsTerminal
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/tools/system"
                    render={(props) => (
                      <ToolsSystem
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  {isShowBackupRestore && (
                    <Route
                      path="/tools/backup-restore"
                      render={(props) => (
                        <ToolsBackupRestore
                          {...props}
                          includeTitles="true"
                          strings={strings}
                          userSettings={userSettings}
                        />
                      )}
                    />
                  )}
                  <Route
                    path="/tools/mqtt-messages"
                    render={(props) => (
                      <ToolsMqttList
                        {...props}
                        includeTitles="true"
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    path="/tools/localisation"
                    render={(props) => (
                      <LocalisationAdminPages
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  <Route
                    //component={NoMatch}
                    render={(props) => (
                      <NoMatch
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                </Switch>
              )}
            />
          )}
          {allowAdmin && (
            <Route
              path="/tools"
              component={() => (
                <Switch>
                  <Route
                    path="/tools/system"
                    render={(props) => (
                      <ToolsSystem
                        {...props}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    )}
                  />
                  {isShowBackupRestore && (
                    <Route
                      path="/tools/backup-restore"
                      render={(props) => (
                        <ToolsBackupRestore
                          {...props}
                          includeTitles="true"
                          strings={strings}
                          userSettings={userSettings}
                        />
                      )}
                    />
                  )}
                  <Route component={NoMatch} />
                </Switch>
              )}
            />
          )}
          <Route component={NoMatch} />
        </Switch>
      </Container>
    </>
  );
};

AuthorizedApp = connect((state) => {
  return {
    isUserSuper: isUserSuper(state),
    isUserAdmin: isUserAdmin(state),
    isUserUser: isUserUser(state),
    isUserGuest: isUserGuest(state),
  };
})(AuthorizedApp);

AuthorizedApp = withRouter(AuthorizedApp);

function UnauthorizedApp({ componentKey, strings }) {
  // console.log("xxx UnauthorizedApp - componentKey", componentKey);
  // console.log("xxx UnauthorizedApp - strings", strings);
  // console.log(
  //   "xxx UnauthorizedApp - strings getInterfaceLanguage ",
  //   strings?.getInterfaceLanguage?.()
  // );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 600px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 601px)",
  });

  let columWidth = 9;
  if (isDesktopOrLaptop) {
    columWidth = 9;
  }
  if (isTabletOrMobileDevice) {
    columWidth = 15;
  }

  return (
    <>
      <Header plain key={componentKey} strings={strings} />
      <Grid
        columns="one"
        container
        centered
        className={"main"}
        style={{ marginTop: "20px" }}
      >
        <Grid.Column width={columWidth}>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route
              render={() => {
                return <Redirect to="/" />;
              }}
            />
          </Switch>
        </Grid.Column>
      </Grid>
    </>
  );
}

function CheckingAuth() {
  return (
    <div>
      <Header plain />
      <Grid container centered>
        <Grid.Column width="nine">
          <Card fluid>
            <Card.Content>
              <div>Checking existing login ...</div>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </div>
  );
}

// see - https://stackoverflow.com/questions/25606730/get-current-locale-of-chrome
function getClientLocale() {
  if (typeof Intl !== "undefined") {
    try {
      return Intl.NumberFormat().resolvedOptions().locale;
    } catch (err) {
      console.error("Cannot get locale from Intl");
    }
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentKey: "",
      language: "en-US", // localisation
      //
      clientLocale: {},
      clientLanguage: [],
    };
  }

  componentDidMount() {
    const clientLocale = getClientLocale();
    this.setState({ clientLocale: clientLocale });

    let clientLanguage;
    if (window.navigator.languages) {
      clientLanguage = window.navigator.languages[0];
    } else {
      clientLanguage =
        window.navigator.userLanguage || window.navigator.language;
    }
    this.setState({ clientLocale: clientLanguage });

    console.log("LOCALISATION clientLocale set to ", clientLocale);
    console.log("LOCALISATION clientLanguage set to ", clientLanguage);
    console.log("LOCALISATION localisedTerms ", this.props.localisedTerms);
    console.log("LOCALISATION languageOptions ", languageOptions);

    let newLocalisedTerms = {};

    if (!_isEmpty(this.props.localisedTerms))
      newLocalisedTerms = { ...this.props.localisedTerms };

    // update localised terms rx from the db via fetch with terms defined locally,
    // b/c the database may not be complete.
    languageOptions.forEach((lang) => {
      // JIC there is no db entry
      const dbTerms = newLocalisedTerms?.[lang.key] || {
        "FireFly Lighting System": "FireFly Lighting System",
      };
      newLocalisedTerms[lang.key] = {
        ...defaultStrings,
        ...dbTerms,
      };
    });

    console.log("LOCALISATION newLocalisedTerms ", newLocalisedTerms);

    // console.log("xxx app - newLocalisedTerms", newLocalisedTerms);
    // console.log(
    //   "xxx app - localised this.props.thisUserLang",
    //   this.props.thisUserLang
    // );

    strings.setContent(newLocalisedTerms);
    strings.setLanguage(this.props.thisUserLang);
    // #NOTE - this is a convoluted but ... after the language is set in State then,
    // also set the componentKey. The componentKey changes will force a re-render of components
    // using this key - i.e. Authorized app and Header. This forces update of the localised terms.
    //
    // This is necessary because it takes awhile after login to fetch the localisation terms.
    //
    this.setState({ language: this.props.thisUserLang }, () => {
      this.setState({
        componentKey: hash(
          `${this.props.thisUserLang}-${Object.keys(newLocalisedTerms)}`
        ),
      });
    });
  }

  componentWillMount() {
    //this.props.fetchLocalisation(); // issue here as can not do server call if not authorised - #FIX?
    this.props.checkAuth();
  }

  componentDidUpdate(prevProps, prevState) {
    // If the localised terms have changed then update the strings.
    if (
      JSON.stringify(this.props.localisedTerms) !==
      JSON.stringify(prevProps.localisedTerms)
    ) {
      if (!_isEmpty(this.props.localisedTerms)) {
        // update localised terms rx from the db via fetch with terms defined locally,
        // b/c the database may not be complete.
        let newLocalisedTerms = { ...this.props.localisedTerms };
        newLocalisedTerms["en-US"] = {
          ...defaultStrings,
          ...newLocalisedTerms?.["en-US"],
        };

        // console.log(
        //   "xxx app - componentDidUpdate setContent localisedTerms ",
        //   this.props.localisedTerms
        // );
        // console.log(
        //   "xxx app - componentDidUpdate setLanguage getLanguage thisUserLang ",
        //   this.props.thisUserLang
        // );
        strings.setContent(newLocalisedTerms);
        strings.setLanguage(this.props.thisUserLang);
        // #NOTE - *must* setLanguage after doing another setContent
        //
        // #NOTE - this is a convoluted but ... after the language is set in State then,
        // also set the componentKey. The componentKey changes will force a re-render of components
        // using this key - i.e. Authorized app and Header. This forces update of the localised terms.
        //
        // This is necessary because it takes awhile after login to fetch the localisation terms.
        //
        this.setState({ language: this.props.thisUserLang }, () => {
          this.setState({
            componentKey: hash(
              `${this.props.thisUserLang}-${Object.keys(newLocalisedTerms)}`
            ),
          });
        });

        // console.log(
        //   "xxx app - componentDidUpdate localisedTerms ",
        //   this.props.localisedTerms
        // );
      }
    }

    // re-render components if the language has changed e.g. in the Users Profile settings.
    if (this.props.thisUserLang !== prevProps.thisUserLang) {
      strings.setLanguage(this.props.thisUserLang);
      // #NOTE - this is a convoluted but ... after the language is set in State then,
      // also set the componentKey. The componentKey changes will force a re-render of components
      // using this key - i.e. Authorized app and Header. This forces update of the localised terms.
      //
      // This is necessary because it takes awhile after login to fetch the localisation terms.
      //
      this.setState({ language: this.props.thisUserLang }, () =>
        this.setState({
          componentKey: hash(
            `${this.props.thisUserLang}-${Object.keys(
              this.props.localisedTerms
            )}`
          ),
        })
      );

      // console.log(
      //   "xxx app header - componentDidUpdate language set to ",
      //   this.props.thisUserLang
      // );
    }
  }

  render() {
    const {
      isAuthenticated,
      isCheckingAuth,
      hasCheckedOnce,
      // !isSettingsDataLoading - if no longer loading (i.e. loaded) then proceed with loading other....
      //isSettingsDataLoading, // #WIP - may be necessary to check loading of server data. Here JIC.
      //isAreaImagesChanged, // #WIP - to update the area image when Area message changes (see - < AreaImages />)
      userSettings,
    } = this.props;

    const { language, componentKey } = this.state;

    const configAppTitle = _configAppTitle();
    const isDemoMode =
      userSettings?.feature?.includes("showDemoMode") || _isDemoMode();

    var content;

    // APP_TERMINOLOGY
    let strTitle =
      strings?.[`${configAppTitle}`] || strings?.["FireFly Lighting System"];

    if (isDemoMode) {
      strTitle = `${strTitle} [DEMO]`;
    }

    // console.log("xxx app - render ----------------------");
    // console.log("xxx app - render - language", language);
    // console.log("xxx app - render - strings", strings);
    // console.log("xxx app - render - localisedTerms", localisedTerms);
    // console.log("xxx app - render - componentKey", componentKey);

    if (isAuthenticated) {
      // #NOTE -
      // #WIP - may not be necessary.
      // see - src/components/FetchData/index.js
      //
      //this.props.fetchLocalisation();

      content = (
        <AuthorizedApp
          language={language}
          key={componentKey} // used to force a re-render if the display language is changed
          componentKey={componentKey}
          strings={strings}
          userSettings={userSettings}
        />
      ); // hash forced re-render if language changes
    } else if (isCheckingAuth || !hasCheckedOnce) {
      content = (
        <CheckingAuth
          key={componentKey} // used to force a re-render if the display language is changed
          strings={strings}
          userSettings={userSettings}
        />
      );
    } else {
      content = (
        <UnauthorizedApp
          language={language}
          key={componentKey} // used to force a re-render if the display language is changed
          componentKey={componentKey}
          strings={strings}
          userSettings={userSettings}
        />
      );
    }

    return (
      <>
        {/* Loads configuration settings from cookies and server */}
        {isAuthenticated && <Settings userSettings={userSettings} />}
        {isAuthenticated && <WebWorker />}
        {isAuthenticated && <FetchAllData />}
        {/* routine checks on system status */}
        {isAuthenticated && <StatusChecks />}
        {/* loads config.js file, before checkForBrowseType!  */}
        <CheckConfigJs />
        {checkForBrowserType() && <CheckBrowser />}
        {isAreaImageToState() && isAuthenticated && <AreaImages />}
        <Helmet titleTemplate="%s - IoT Automation" defaultTitle={strTitle}>
          <meta name="description" content={`IoT Automation ${strTitle}`} />
        </Helmet>
        <div className={"article"}>
          {content}
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  // localisation
  // get language from most recent fetch to avoid need to log out/in
  const thisUser = getUserData(state);

  const thisUserId = thisUser?.email; // undefined if not logged in
  const thisUserLang = getUserById(state, thisUserId)?.language || "en-US";
  const localisedTerms = getLocalisationByComponentId(state, "App");

  return {
    isBrowerOfLimitedFunctionality:
      state.checkBrowser.isBrowerOfLimitedFunctionality,
    isAuthenticated: isAuthenticated(state),
    isCheckingAuth: state.auth.isCheckingAuth,
    hasCheckedOnce: state.auth.hasCheckedOnce,
    isSettingsDataLoading: getSettingsDataLoading(state),
    //
    isAreaImagesChanged: getIsAreaImagesChanged(state),
    //
    thisUserLang, // localisation
    userSettings: thisUser?.settings || {},
    localisedTerms,
  };
}

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => {
    dispatch(checkAuth());
  },
  checkBrowser: () => {
    dispatch(checkBrowser());
  },
  fetchLocalisation: () => {
    dispatch(fetchLocalisation());
  },
  fetchFolderFilesListByFolder: (folder) => {
    dispatch(fetchFolderFilesListByFolder(folder));
  },
  // ,addErrorFlashMessage: (error, extra) => {
  //   dispatch(addErrorFlashMessage(error, extra));
  // }
});

// export default withRouter(connect(mapStateToProps, { checkAuth, checkBrowser })(App));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
