import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Form,
  Container,
  Button,
  Icon,
  Segment,
  Header,
  Message,
  Grid,
  Checkbox,
} from "semantic-ui-react";

import { Slider } from "react-semantic-ui-range";
import "semantic-ui-css/semantic.min.css";

import { formatRelative, parseISO, isPast } from "date-fns";

import { Field, reduxForm, formValueSelector } from "redux-form";

import {
  renderInput,
  renderSelect,
  renderField,
  renderRadio,
  renderCheckbox,
} from "admin/form-field";

import { SaveButton } from "admin/SaveButton";

import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging

import { isUserSuper, isUserAdmin } from "auth/reducer";
import { getAllUsers } from "components/UserAdmin/reducer";
import { fetchAllUsers } from "components/UserAdmin/actions";

import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";
import hash from "object-hash";

import {
  allowedLanguages as _allowedLanguages,
  allowedRoles as _allowedRoles,
  allowedApplications as _allowedApplications,
  allowedFeatures as _allowedFeatures,
} from "components/ConfigJs";

import { DEFAULT_SETTINGS } from "utils/DEFAULTS";

const required = (value) => (value ? undefined : "Required");
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const uniqueEmail = (value, allValues, props, name) =>
  props.allUserEmails.includes(value)
    ? "This email has already been used"
    : undefined;

const validate = (values) => {
  const required = [
    "username",
    "email",
    "role",
    "language",
    "password",
    "passwordConfirm",
  ];

  const errors = {};

  required.forEach((r) => {
    if (!get(values, r)) {
      set(errors, r, "Required");
    }
  });

  if (get(values, "password") !== get(values, "passwordConfirm")) {
    set(errors, "passwordConfirm", "Does not match");
  }

  //console.log("@@ validate errors", errors);

  return errors;
};

class UserCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 6,
      hidden: true,
    };
  }

  submitMyForm = (values) => {
    this.props.onSubmit(values);
  };

  cancelForm = (e) => {
    e.preventDefault();
    this.props.goto("/user/admin");
  };

  toggleShow = (state) => {
    this.setState({ hidden: !this.state.hidden });
  };

  onChangeApplication = (e, data) => {
    const { stateValueApplication, change } = this.props;
    const { optionKey } = data;

    let newApplication = [...stateValueApplication];

    // toggle - remove if exists
    if (newApplication.includes(optionKey)) {
      newApplication = newApplication.filter((app) => app !== optionKey);
    } else {
      newApplication.push(optionKey);
    }

    change("settings.application", newApplication);
  };

  applicationRadiosContent = (
    options,
    stateValueApplication,
    isAdmin,
    isSuper
  ) => {
    let content = [];

    if (options.length === 0) {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={"warning"} style={{ marginRight: "10px" }} />
          {this.props.strings?.["No applications enabled"]}
        </Form.Field>
      );
    }

    options.map((option) => {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={option.icon} />
          <Checkbox
            optionKey={option.key}
            label={option.text}
            checked={stateValueApplication?.includes(option.value)}
            onChange={this.onChangeApplication}
            Checkbox
            disabled={!isAdmin && !isSuper}
          />
        </Form.Field>
      );
    });

    return (
      <Grid style={{ padding: "10px" }}>
        <Form>
          <Grid.Row>
            <Grid.Column>
              <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                {this.props.strings?.["Applications"]}
              </div>
            </Grid.Column>
          </Grid.Row>
          {content}
        </Form>
      </Grid>
    );
  };

  onChangeFeature = (e, data) => {
    const { stateValueFeature, change } = this.props;
    const { optionKey } = data;

    let newFeature = [...stateValueFeature];

    // toggle - remove if exists
    if (newFeature.includes(optionKey)) {
      newFeature = newFeature.filter((app) => app !== optionKey);
    } else {
      newFeature.push(optionKey);
    }

    change("settings.feature", newFeature);
  };

  featureRadiosContent = (options, stateValueFeature, isAdmin, isSuper) => {
    let content = [];

    if (options.length === 0) {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={"warning"} style={{ marginRight: "10px" }} />
          {this.props.strings?.["No features enabled"]}
        </Form.Field>
      );
    }

    options.map((option) => {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={option.icon} />
          <Checkbox
            optionKey={option.key}
            label={option.text}
            checked={stateValueFeature?.includes(option.value)}
            onChange={this.onChangeFeature}
            Checkbox
            disabled={!isAdmin && !isSuper}
          />
        </Form.Field>
      );
    });

    return (
      <Grid style={{ padding: "10px" }}>
        <Form>
          <Grid.Row>
            <Grid.Column>
              <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                {this.props.strings?.["Features"]}
              </div>
            </Grid.Column>
          </Grid.Row>
          {content}
        </Form>
      </Grid>
    );
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
      error,
      //reduxFormValues,
      allUserEmails,
      isSuper,
      isAdmin,
      initialValues,
      strings,
      stateValueApplication,
      stateValueFeature,
    } = this.props;

    // APP_TERMINOLOGY

    const {
      settings: {
        display: { columnWidth },
      },
    } = initialValues;

    const settings = {
      start: columnWidth,
      min: 0,
      max: 16,
      step: 2,
      onChange: (value) => {
        //console.log("value", value);
        this.props.change("settings.columnWidth", value);

        this.setState({
          columnWidth: value,
        });
      },
    };

    const allowedLanguages = _allowedLanguages();
    const allowedRoles = _allowedRoles();
    const allowedApplications = _allowedApplications();
    const allowedFeatures = _allowedFeatures();

    return (
      <Container>
        {error && <Message error header="Error submitting" content={error} />}
        <Form onSubmit={handleSubmit(this.submitMyForm)} error={Boolean(error)}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column stretched>
                <Segment>
                  <Header as="h3">{strings?.["User Details"]}</Header>
                  <Field
                    name="username"
                    label={strings?.["Name"]}
                    placeholder="Enter the user name"
                    component={renderInput}
                    validate={[required]}
                  />
                  <Field
                    name="email"
                    label={strings?.["Email Address"]}
                    placeholder='e.g. "name@company.com"'
                    component={renderInput}
                    allUserEmails={allUserEmails}
                    validate={[required, email, uniqueEmail]}
                  />
                  <Field
                    name="role"
                    label={strings?.["Role"]}
                    placeholder={strings?.["User's Role"]}
                    component={renderSelect}
                    options={
                      isSuper
                        ? allowedRoles
                        : allowedRoles.filter(
                            (option) => option.value !== "super"
                          )
                    }
                    validate={[required]}
                    //className={!isSuper ? "disabled-form-field" : null}
                    disabled={!isAdmin && !isSuper}
                  />
                  <Field
                    style={{ paddingTop: "6px" }}
                    name={"language"}
                    label={strings?.["Language"]}
                    placeholder={strings?.["Language"]}
                    component={renderSelect}
                    options={allowedLanguages}
                    validate={[required]}
                  />
                </Segment>
                <Segment>
                  <Field
                    name={"password"}
                    label={strings?.["Password"]}
                    placeholder={strings?.["Enter a new user password"]}
                    component={renderInput}
                    validate={[required]}
                    type={this.state.hidden ? "password" : "text"}
                    icon={
                      <Icon
                        name={this.state.hidden ? "eye" : "eye slash"}
                        link
                        onClick={this.toggleShow}
                      />
                    }
                  />
                  <Field
                    name={"passwordConfirm"}
                    label={strings?.["Confirm Password"]}
                    placeholder="Reenter your password to confirm"
                    component={renderInput}
                    validate={[required]}
                    type={this.state.hidden ? "password" : "text"}
                    icon={
                      <Icon
                        name={this.state.hidden ? "eye" : "eye slash"}
                        link
                        onClick={this.toggleShow}
                      />
                    }
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Grid columns={2}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={12}>
                      <Grid.Row>
                        <Button.Group floated="right">
                          <SaveButton
                            submitting={submitting}
                            pristine={pristine}
                            strings={strings}
                          />
                          <Button.Or />
                          <Button
                            type="button"
                            disabled={pristine || submitting}
                            onClick={reset}
                          >
                            {strings?.["Reset"]}
                          </Button>
                          <Button.Or />
                          <Button
                            style={{ textAlign: "right" }}
                            onClick={(e) => this.cancelForm(e)}
                          >
                            {strings?.["Cancel"]}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Segment>
                  <Header a="h3">{strings?.["Settings"]}</Header>
                  <Segment>
                    <Header a="h3">{strings?.["Display"]}</Header>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Field
                            name="settings.display.sopInterface"
                            label={strings?.["Show Lighting Control Layout"]}
                            component={renderCheckbox}
                          />
                          <Field
                            name="settings.display.legend"
                            label="Show map legend"
                            component={renderCheckbox}
                          />
                          <Field
                            name="settings.display.hint"
                            label={strings?.["Show hints on Polygon buttons"]}
                            component={renderCheckbox}
                          />
                          <Grid>
                            <Grid.Row
                              columns={2}
                              style={{ paddingBottom: "0px" }}
                            >
                              <Grid.Column width={3}>
                                <label>{strings?.["Control Size"]}</label>
                              </Grid.Column>
                              <Grid.Column>
                                <Form.Group inline>
                                  <Field
                                    component={renderRadio}
                                    label={strings?.["Compressed"]}
                                    name="settings.display.view"
                                    radioValue={"compressed"}
                                  />
                                  <Field
                                    component={renderRadio}
                                    label={strings?.["Normal"]}
                                    name="settings.display.view"
                                    radioValue={"normal"}
                                  />
                                  <Field
                                    component={renderRadio}
                                    label={strings?.["Expanded"]}
                                    name="settings.display.view"
                                    radioValue={"expanded"}
                                  />
                                </Form.Group>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2} style={{ paddingTop: "0px" }}>
                              <Grid.Column width={3}>
                                <label>{strings?.["Column Width"]}</label>
                              </Grid.Column>
                              <Grid.Column width={10}>
                                <Slider
                                  key={hash(settings)}
                                  discrete
                                  color="red"
                                  inverted={false}
                                  settings={settings}
                                />
                                <Field
                                  name="settings.display.columnWidth"
                                  type="hidden"
                                  component="input"
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                  <Segment>
                    <Header a="h3">{strings?.["Options"]}</Header>
                    {this.applicationRadiosContent(
                      allowedApplications,
                      stateValueApplication,
                      isAdmin,
                      isSuper
                    )}
                    {this.featureRadiosContent(
                      allowedFeatures,
                      stateValueFeature,
                      isAdmin,
                      isSuper
                    )}
                  </Segment>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
}

const selector = formValueSelector("userCreateForm");

UserCreateForm = reduxForm({
  form: "userCreateForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(UserCreateForm);

const mapDispatchToProps = (dispatch, props) => {
  return {
    goto: (path) => dispatch(push(path)),
    fetchAllUsers: () => dispatch(fetchAllUsers()),
  };
};

const mapStateToProps = (state, props) => {
  const allUsers = getAllUsers(state);
  const allUserEmails = allUsers.map((user) => user.email);

  //console.log("allUsers", allUsers);

  const newSettings = { ...DEFAULT_SETTINGS };

  return {
    isSuper: isUserSuper(state), // `super` can change user status
    isAdmin: isUserAdmin(state),
    allUserEmails: allUserEmails,
    initialValues: {
      settings: newSettings,
    },
    columnWidth: selector(state, "settings.display.columnWidth"),
    stateValueApplication: selector(state, "settings.application") || [],
    stateValueFeature: selector(state, "settings.feature") || [],
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("onChange values", values);
    },
    reduxFormValues: getFormValues("userCreateForm")(state), // #REVIEW - only used for debugging,
  };
};

UserCreateForm = connect(mapStateToProps, mapDispatchToProps)(UserCreateForm);

export default UserCreateForm;
