import React, { Component } from "react";
import { push } from "react-router-redux";
import { Container, Header, Grid, Message } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import { connect } from "react-redux";
import TagTrackingUserForm from "admin/tag-tracking/TagTrackingUserForm";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { getTagPersonnel, getTagVehicles } from "components/Tags/reducer";
import { SubmissionError } from "redux-form";
import {
  addUpdateUser,
  addUpdateVehicle,
  deleteTagUserById,
  deleteTagVehicleById,
  fetchAllTagUsers,
  fetchAllTagVehicles,
} from "components/Tags/actions";

class TagTrackingUserEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }
  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  saveAddUpdateUser = (values) => {
    return new Promise((resolve, reject) => {
      this.props.addUpdateUser({
        values,
        resolve,
        reject,
      });
    });
  };

  saveAddUpdateVehicle = (values) => {
    return new Promise((resolve, reject) => {
      this.props.addUpdateVehicle({
        values,
        resolve,
        reject,
      });
    });
  };

  deleteTagUserById = (values) => {
    return new Promise((resolve, reject) => {
      this.props.deleteTagUserById({
        values,
        resolve,
        reject,
      });
    });
  };

  deleteTagVehicleById = (values) => {
    return new Promise((resolve, reject) => {
      this.props.deleteTagVehicleById({
        values,
        resolve,
        reject,
      });
    });
  };

  submitMyForm = (values) => {
    //console.log(`submitMyForm values`, values);

    const { _action, tagUserData, tagUserType } = values;
    const { id } = tagUserData;

    let promiseArray = [];

    switch (_action) {
      case "edit":
        switch (tagUserType) {
          case "personnel":
            const { firstname, lastname } = tagUserData;
            promiseArray.push(
              this.saveAddUpdateUser({ id, firstname, lastname })
            );
            break;
          case "vehicle":
            const { name, type } = tagUserData;
            promiseArray.push(this.saveAddUpdateVehicle({ id, name, type }));
            break;

          default:
            break;
        }

        break;
      default:
        break;
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("SUCCESS TagTrackingUserEditPage Submit", results);
      })
      .then(() => {
        this.props.fetchAllTagUsers();
        this.props.fetchAllTagVehicles();
        this.props.push(`/status/taguser/${tagUserType}`);
      })
      .catch((error) => {
        console.log(`ERROR addUpdateUser error`, error);
        //this.onSetError("failed");
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        //throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  deleteId = ({ id, tagUserType }) => {
    console.log(`deleteId id`, id);

    let promiseArray = [];

    switch (tagUserType) {
      case "personnel":
        promiseArray.push(this.deleteTagUserById({ id }));
        break;
      case "vehicle":
        promiseArray.push(this.deleteTagVehicleById({ id }));
        break;

      default:
        break;
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("SUCCESS TagTrackingUserEditPage deleteTag", results);
      })
      .then(() => {
        this.props.fetchAllTagUsers();
        this.props.fetchAllTagVehicles();
        this.props.push(`/status/taguser/${tagUserType}`);
      })
      .catch((error) => {
        console.log(
          `ERROR TagTrackingUserEditPage deleteTagUserById error`,
          error
        );
        //this.onSetError("failed");
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        //throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  render() {
    const { id, error, tagUserType, tagUsers, tagVehicles, push, strings } =
      this.props;

    // APP_TERMINOLOGY
    let strTagUser = "User";
    let strTagUsers = "Users";

    switch (tagUserType) {
      case "personnel":
        strTagUser = "Personnel";
        strTagUsers = "Personnel";
        break;
      case "vehicle":
        strTagUser = "Vehicle";
        strTagUsers = "Vehicles";
        break;
      case null:
      default:
        break;
    }

    // #WIP - ATM don't allow reload, or direct link to a firefly page.....
    // Instead redirect to the controller list page and wait for firefly to list (with next message).

    if (error) {
      push(`/status/taguser/${tagUserType}`);
      return null;
      //   return (
      //   <>
      //     <DataLoadingMessage id={id} strings={strings}/>
      //     <DebugPagePropsMessages that={this} />
      //   </>
      // );
    }

    let tagUserData;
    switch (tagUserType) {
      case "personnel":
        tagUserData = tagUsers?.find((user) => user.id == id) || {}; // id is string or integer

        // temporary until nickname is setup (note - cloned above to be mutable)
        tagUserData.nick = `${
          tagUserData?.firstname
        }${tagUserData?.lastname?.charAt(0)}`;
        break;
      case "vehicle":
        tagUserData = tagVehicles?.find((vehicle) => vehicle.id == id) || {}; // id is string or integer
        console.log(`tagUserData`, tagUserData);
        break;
      default:
        break;
    }

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">Tag User Details</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <TagTrackingUserForm
            id={id}
            tagUserType={tagUserType}
            initialValues={{
              _action: "edit", // used to control dlg display
              tagUserData,
              tagUserType,
            }}
            header={`Edit ${strTagUser}`}
            formType={"edit"}
            onSubmit={(values) => this.submitMyForm(values)}
            onCancel={() => {
              push(`/status/taguser/${tagUserType}`);
            }}
            onDelete={(values) => this.deleteId(values)}
            strings={strings}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const match = props.match;
  const id = match.params.id;
  return {
    id,
    error: "",
    tagUsers: getTagPersonnel(state),
    tagVehicles: getTagVehicles(state),
  };
};

export default connect(mapStateToProps, {
  push,
  TurnOnOffPokeTheWorker,
  addUpdateUser,
  addUpdateVehicle,
  deleteTagUserById,
  deleteTagVehicleById,
  fetchAllTagUsers,
  fetchAllTagVehicles,
})(TagTrackingUserEditPage);
