import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

function TestMenu({ level }) {
  return (
    <Dropdown item simple text={"Test"} className="link item">
      <Dropdown.Menu>
        {/* #NOTE - these are disable. were used for testing */}
        {false && (
          <>
            <Dropdown.Item as={Link} to={`/test/test-mqtt-control`}>
              Mqtt msgs
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`/test/test-mqtt-image-overlay-geojson-utm-table`}
            >
              Named Areas & Markers
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item as={Link} to={`/test/test-marker-icons`}>
          Test Marker Icons
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

TestMenu.defaultProps = {};

export default TestMenu;
