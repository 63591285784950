// see - https://odoe.net/blog/custom-leaflet-control
//     - https://github.com/odoe/leaflet-control-sample

import L from "leaflet";
import _ from "lodash";
import "leaflet/dist/leaflet.css";
//import "components/Map/bootstrap.css"; // use for testing old styling if necessary

var yx = L.latLng;
var xy = function (x, y) {
  if (L.Util.isArray(x)) {
    // When doing xy([x, y]);
    return yx(x[1], x[0]);
  }
  return yx(y, x); // When doing xy(x, y);
};

function sortId(a, b) {
  var _a = a.feature.properties.id;
  var _b = b.feature.properties.id;
  if (_a < _b) {
    return -1;
  }
  if (_a > _b) {
    return 1;
  }
  return 0;
}

// L.Control.Search = L.Control.extend({
const SearchControl = L.Control.extend({
  options: {
    // topright, topleft, bottomleft, bottomright
    position: "topleft",
    placeholder: "Search...",
  },
  initialize: function (options /*{ data: {...}  }*/) {
    // constructor
    L.Util.setOptions(this, options);
  },
  onAdd: function (map) {
    // happens after added to map
    var container = L.DomUtil.create("div", "search-container");
    this.form = L.DomUtil.create("form", "form", container);
    var group = L.DomUtil.create("div", "form-group", this.form);
    this.input = L.DomUtil.create("input", "form-control input-sm", group);
    this.input.type = "text";
    this.input.placeholder = this.options.placeholder;
    this.results = L.DomUtil.create("div", "list-group", group);
    L.DomEvent.addListener(
      this.input,
      "keyup",
      _.debounce(this.keyup, 300),
      this
    );
    L.DomEvent.addListener(this.form, "submit", this.submit, this);
    L.DomEvent.disableClickPropagation(container);
    return container;
  },
  onRemove: function (map) {
    // when removed
    L.DomEvent.removeListener(this.input, "keyup", this.keyup, this);
    L.DomEvent.removeListener(this.form, "submit", this.submit, this);
  },
  keyup: function (e) {
    if (e.keyCode === 38 || e.keyCode === 40) {
      // do nothing
    } else {
      this.results.innerHTML = "";
      if (this.input.value.length > 2) {
        var value = this.input.value;
        var results = _.take(
          _.filter(this.options.data, function (x) {
            return (
              x.feature.properties.id
                .toUpperCase()
                .indexOf(value.toUpperCase()) > -1
            );
          }).sort(sortId),
          10
        );
        _.map(results, (x) => {
          var a = L.DomUtil.create("a", "list-group-item");
          a.href = "";
          a.setAttribute("data-result-name", x.feature.properties.id);
          a.innerHTML = x.feature.properties.id;
          this.results.appendChild(a);
          L.DomEvent.addListener(a, "click", this.itemSelected, this);
          return a;
        });
      }
    }
  },
  itemSelected: function (e) {
    L.DomEvent.preventDefault(e);
    var elem = e.target;
    var value = elem.innerHTML;
    this.input.value = elem.getAttribute("data-result-name");
    var feature = _.find(
      this.options.data,
      function (x) {
        return x.feature.properties.id === value;
      },
      this
    );
    if (feature) {
      // console.log(
      //   "search CLICKED ON feature",
      //   feature,
      //   feature?.getLatLng?.(),
      //   this._map.getMaxZoom(),
      //   feature?.feature?.geometry?.coordinates
      // );
      // check if leaflet layer data used
      if (feature?.getLatLng?.()) {
        this._map.setView(feature?.getLatLng(), this._map.getMaxZoom());
      }
      // check if geoJson data used
      else if (feature?.feature?.geometry?.coordinates) {
        let coords = feature?.feature?.geometry?.coordinates;
        if (L.Util.isArray(coords)) {
          this._map.setView(
            { lat: coords[1], lng: coords[0] },
            2 // this._map.getMaxZoom()
          );
        }
      }
    }
    this.results.innerHTML = "";
  },
  submit: function (e) {
    L.DomEvent.preventDefault(e);
  },
});

export default SearchControl;

// L.control.search = function (id, options) {
//   return new L.Control.Search(id, options);
// };
