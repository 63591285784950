/*
1Dec21 - stopped using this as the data update would re-drawn a layer with each hash().
17Mar22 - being used again now....seems to be OK..? review comment and clarify?!

Issue refs:
- https://github.com/PaulLeCam/react-leaflet/issues/389
- 

See also:
- https://github.com/PaulLeCam/react-leaflet/issues/697
- https://github.com/PaulLeCam/react-leaflet/issues/332
- https://gist.github.com/Shrugsy/5e898173c965e7642db8927636bedf7a


For markercluster see:
- https://stackoverflow.com/questions/59306768/marker-clustering-leaflet-markercluster-with-react-leaflet-2-0/59322023
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { renderToString } from "react-dom/server";

import { withMineLevel } from "ApplicationContexts";

import L from "leaflet";
import { GeoJSON } from "react-leaflet";

import { makeIcon } from "components/Map/MakeIcon";

import {
  getTagTrackingCoordinatesByAreaId,
  getTagIdsByAreaId,
} from "components/Tags/reducer";

import {
  getNamedAreaInfos,
  getNamedAreaInfosBySubTypeId,
} from "components/WebWorker/reducer";

import { transformGeoJsonUtmToPixels } from "components/Map/util-geoJsonToPixels";

import hash from "object-hash";
import _isEmpty from "lodash/isEmpty";

import TagTrackingZonesMarkerPopupModal from "admin/tag-tracking/TagTrackingZonesMarkerPopupModal";
import { NamedAreaPolygonPopup } from "components/Map/NamedAreaPolygonPopup";
import { features } from "process";

//
// #NOTE - 17Mar22 - temporarily disabled the modal dlg for polygon (tag zone) click for tags
// and instead made it the same as std. map view - i.e. click through to the polygon edit page.
//
const ENABLE_MODAL_DLG_POPUP = false;
//

// #TODO #REVEW - write a geoJson parse checker
function tryParseJSON(jsonObj) {
  try {
    var o = JSON.parse(JSON.stringify(jsonObj));
    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {}
  return false;
}

class CurrentTagTrackingZonesFeatureGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenTagTrackingZonesMarkerPopup: false,
      // modal for details of the marker
      isOpenTagTrackingZonesMarkerPopupModal: {
        active: false,
        id: "",
        area: "",
      },
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if the popup is open stop rendering
    if (nextState?.isOpenTagTrackingZonesMarkerPopup === true) {
      return false;
    }
    return true;
  }

  resetModal = () => {
    this.setState({
      isOpenTagTrackingZonesMarkerPopupModal: {
        active: false,
        id: "",
        label: "",
        area: "",
      },
    });
  };

  render() {
    const { area, geoJSONNamedAreas } = this.props;

    const onEachFeature = (feature, layer) => {
      let popupContent = ``;
      if (feature.properties && feature.properties.id) {
        const parentId = feature.properties.parent;
        const parentName = feature.properties.parent_name;
        if (ENABLE_MODAL_DLG_POPUP) {
          popupContent = `<button data-key=${parentId}:${parentName} class="link button-edit">${parentName}</button>`;
          layer.bindPopup(popupContent);
        } else {
          const { properties } = feature;
          const { id, parent, parent_name, sub_type } = properties;
          const namedAreaPolygonPopup = renderToString(
            <NamedAreaPolygonPopup
              id={id}
              data={{
                parent,
                parentName: parent_name,
                sub_type,
              }}
            />
          );
          layer.bindPopup(namedAreaPolygonPopup);
        }
      }

      const querySelectorId = ENABLE_MODAL_DLG_POPUP
        ? ".button-edit"
        : ".popupNamedAreaPolygon";

      layer.on("popupclose", (a) => {
        this.setState({
          isOpenTagTrackingZonesMarkerPopup: false,
        });
      });
      layer.on("popupopen", (a) => {
        this.setState({
          isOpenTagTrackingZonesMarkerPopup: true,
        });
        const popUp = a.target.getPopup();
        popUp
          .getElement()

          .querySelector(querySelectorId)
          .addEventListener("click", (e) => {
            // console.log(
            //   "clicked edit on marker: ",
            //   e,
            //   "target: ",
            //   e.target,
            //   "data-key ",
            //   e.target.dataset.key
            // );

            if (ENABLE_MODAL_DLG_POPUP) {
              const id = e.target.dataset.key;
              if (!_isEmpty(id)) {
                const newModal = {
                  active: true,
                  id: `${id.split(":")?.[0]}:${id.split(":")?.[1]}`, // #NOTE: 0 - area, 1 - parentId,
                  label: id.split(":")?.[2], // 2 - additional label
                  area: area,
                };
                this.setState(
                  {
                    isOpenTagTrackingZonesMarkerPopupModal: newModal,
                  },
                  () => console.log(`CLICK ON TAG ZONE ID: `, newModal)
                );
                this.setState({
                  isOpenTagTrackingZonesMarkerPopup: false,
                });
              }
            } else {
              const id = e.target.getAttribute("dataid");
              if (!_isEmpty(id)) {
                this.props.goto(`/admin/named-area/${id}`);
              } else {
                this.props.goto(`/admin/named-area/`);
              }
              this.setState({
                isOpenTagTrackingZonesMarkerPopup: false,
              });
            }
          });
      });
    };

    // styling for polygons
    const geojsonPolygonStyle = (feature) => {
      const { properties } = feature;

      //      console.log("geoJSONNamedAreasUtm properties", properties);

      const {
        style: { fillColor = "rgb(0, 0, 0)", fillOpacity = 0.5 },
      } = properties;

      // console.log(
      //   "geoJSONNamedAreasUtm properties",
      //   properties,
      //   fillColor,
      //   fillOpacity
      // );

      return {
        fillColor: fillColor,
        color: fillColor,
        weight: 1,
        opacity: 1,
        fillOpacity: fillOpacity,
      };
    };

    const filterTags = (feature, layer) => {
      return feature.properties.area === area;
    };

    const valid = true; //  !_isEmpty(tags) && tryParseJSON(tags);

    const isOpenSettingsModal =
      this.state.isOpenTagTrackingZonesMarkerPopupModal.active === true;

    return (
      valid && (
        <>
          {isOpenSettingsModal && (
            <TagTrackingZonesMarkerPopupModal
              open={isOpenSettingsModal}
              initialValues={this.state.isOpenTagTrackingZonesMarkerPopupModal}
              handleSettingsSave={(values) => this.handleSettingsSave(values)}
              resetModal={() => this.resetModal(false)}
            />
          )}
          <GeoJSON
            ref="geoJsonTagZones"
            key={hash(geoJSONNamedAreas)}
            data={geoJSONNamedAreas}
            onEachFeature={onEachFeature}
            style={geojsonPolygonStyle}
            coordsToLatLng={(coords) => {
              // swaps the coordinate references around so when polygons are created the pixel refer are [x,y] , not [y,x]
              //                    latitude , longitude, altitude
              return new L.LatLng(coords[1], coords[0], coords[2]); // <--------- Normal behavior
            }}
            filter={filterTags}
          />
        </>
      )
    );
  }
}

export function mapStateToProps(state, props) {
  const { mineLevel } = props;

  // Named Area polygons
  let geoJSONNamedAreasUtm = getNamedAreaInfosBySubTypeId(state, "tagzone");

  // **********************************
  // similar to code in src/components/Map/MineLevelMapLeaflet.js
  // #NOTE - make common

  let geoJSONNamedAreas;

  if (
    typeof geoJSONNamedAreasUtm.features !== "undefined" &&
    geoJSONNamedAreasUtm.features.length
  ) {
    // deep clone - to stop geometry being converted on raw *utm data
    geoJSONNamedAreas = JSON.parse(JSON.stringify(geoJSONNamedAreasUtm));

    // transform the coordinates
    const mineLevelKey = mineLevel.id;
    let mineLevelObj = {};
    mineLevelObj[mineLevelKey] = { ...mineLevel };

    geoJSONNamedAreas = transformGeoJsonUtmToPixels(
      geoJSONNamedAreas,
      mineLevelObj,
      1,
      false
    );
  } else {
    // add an empty geoJson featurecollection
    geoJSONNamedAreas = {
      type: "FeatureCollection",
      features: [],
    };
  }

  //console.log(`wwww geoJSONNamedAreas`, geoJSONNamedAreas);

  return {
    area: mineLevel.id,
    geoJSONNamedAreas,
  };
}

const mapDispatchToProps = (dispatch) => ({
  goto: (path) => dispatch(push(path)),
});

export default withMineLevel(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CurrentTagTrackingZonesFeatureGroup)
);
