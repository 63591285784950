import React from "react";
import { connect } from "react-redux";

import { Segment, Message, List } from "semantic-ui-react";

import { getMqttDataLoadingResponseRequestMessageQueue } from "components/WebWorker/reducer";

const DataLoadingMessage = (props) => {
  const { id, dataLoadingMessageQueue, strings } = props;

  return (
    <Message>
      <Message.Header>
        {strings?.["Data Loading Message Header"]}
      </Message.Header>
      <Message.Content>
        <br />
        <p>{`${strings?.["Data Loading Message Content"]} ${id}`}</p>
        <br />
        <strong>{strings?.["Data Loading Receiving"]} </strong>
        {dataLoadingMessageQueue && (
          <Segment>
            <List>
              {dataLoadingMessageQueue.map((item) => {
                return <List.Item>{`${item}...`}</List.Item>;
              })}
            </List>
          </Segment>
        )}
      </Message.Content>
    </Message>
  );
};

const mapStateToProps = (state, props) => {
  const dataLoadingMessageQueue =
    getMqttDataLoadingResponseRequestMessageQueue(state);
  return {
    dataLoadingMessageQueue,
  };
};

export default connect(mapStateToProps, null)(DataLoadingMessage);
