import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import ListNamedAreas from "admin/named-area/NamedAreaList";
import ListAllNamedAreas from "admin/named-area/NamedAreaListAll";
import ListAllNamedAreaEvents from "admin/named-area/NamedAreaListAllEvents";
import CreateNamedArea from "admin/named-area/NamedAreaCreatePage";
import EditNamedArea from "admin/named-area/NamedAreaEditPage";

import { fetchNamedAreas } from "NamedAreas/actions";

import NoMatch from "components/NoMatch";

class NamedAreaPage extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route path={`${match.url}/new`} component={CreateNamedArea} />
        <Route path={`${match.url}/edit/:id`} component={EditNamedArea} />
        <Route exact path={match.url} component={ListNamedAreas} />
        <Route exact path={`${match.url}/all`} component={ListAllNamedAreas} />
        <Route
          exact
          path={`${match.url}/all-events`}
          component={ListAllNamedAreaEvents}
        />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default withRouter(connect(null, { fetchNamedAreas })(NamedAreaPage));
