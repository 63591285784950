// #MINELEVELMAPLEAFLET

import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-contextmenu";
import "leaflet.smoothwheelzoom";

import "leaflet-groupedlayercontrol";
import "leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min.css";
import "components/Map/MapIcons.css";

import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton";

//import "components/leaflet-search-control/leaflet-search-control";
import SearchControl from "../leaflet-search-control/leaflet-search-control";

import {
  transformGeoJsonUtmToPixels,
  transformGeoJsonPixelsToUtm,
} from "components/Map/util-geoJsonToPixels";

import {
  getAreaById,
  getMapState,
  getFireflyCoordinatesByAreaId,
  getControllerCoordinatesByAreaId,
  getNamedAreaInfosByRegionPreviewByAreaId,
  getIsDirty,
  getFaults,
  getFireflyIdsUpdateListByArea,
  getFireflyIdsDeleteListByArea,
} from "components/WebWorker/reducer";

import {
  namedAreaClearDeleteSelections,
  namedAreasSetIsDirty,
  mqttPublish,
  fireflyIdsUpdateListDeleteId,
} from "components/WebWorker/actions";

import { GetLocalMap } from "components/Map/reducer";
import { UpdateLocalMap } from "components/Map/actions";

import { sortAlphaNum } from "utils/sortAlphaNum";
import { round } from "utils/number-utils.js";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { omitDeep } from "utils/omitDeep";

import { formatRelative, parseISO } from "date-fns";

import { saveFirefly } from "UPSPanelControllers/actions";
import { saveUPS } from "UPSPanelControllers/actions";

// import plugin's css (if present)
// note, that this is only one of possible ways to load css
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";

import { makeIcon } from "components/Map/MakeIcon";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import { renderToString } from "react-dom/server";

//import { FireflyMarkerPopup } from "components/Map/FireflyMarkerPopup";
//import { ControllerMarkerPopup } from "components/Map/ControllerMarkerPopup";
import { NamedAreaPolygonPopup } from "components/Map/NamedAreaPolygonPopup";

import { StatusEnum } from "utils/StatusEnum";

import { withComponentStateCache } from "react-component-state-cache";

import { getFolderFileNamesListById } from "components/Settings/reducer";

import { getRegionPreviewListByAreaId } from "components/RegionPreview/reducer";

import {
  getTimedOutDevices,
  getNamedAreaEventsByAreaId,
} from "components/WebWorker/reducer";

// >>>>> flash message support
import {
  isConfigJs,
  faultMessageBannerDisplay,
  faultMessageBannerText,
  isDemoMode,
  getMapDisplayOptions,
  markerAcceptableEventLabels,
  isTagTracking,
  isDataCollectionDuringMarkerPopupOpen,
} from "components/ConfigJs";

import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
  clearFlashMessages,
  SetMessageBanner,
} from "FlashMessages/actions";

import cuid from "cuid";
// <<<<< flash message support

import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { SetMapMoving, SetMapMarkerDragging } from "components/Map/actions";
import { saveNewNamedArea, deleteNamedArea } from "NamedAreas/actions";

import {
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
  waitEventTimeOut,
} from "OperationalChanges/actions";

import { isKthBitSet } from "utils/bit-operations";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import {
  addAreaPreview,
  removeAreaPreview,
} from "components/RegionPreview/actions";

import { getMapImageById } from "components/Settings/reducer";

import FireflyMarkerPopupModal from "components/Map/FireflyMarkerPopupModal";
import ControllerMarkerPopupModal from "components/Map/ControllerMarkerPopupModal";

import { ForceSingleLightManageNamedArea } from "components/Map/ForceSingleLightManageNamedArea";
import { markerDefinition } from "components/Map/FireflyMarkerDefinition";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

//import { info } from "console";

// -----------------------------------------------
// DEFAULT AND DEBUG
//
// -----------------------------------------------

// #DEBUG
// DISABLE code sections under development and prototyped
const DEBUG_ENABLED_FALSE = false;
const DEBUG_ENABLED_TRUE = true;
const DEBUG_SHOW_COUNT_OF_MARKERS_EACH_LAYER = false; // #WIP - was working a long time ago but broken now?!

//

// #REVIEW - Need this? This is setup to be able to draw linestrings etc,
// but are there too many layers as-is?
// This adds a group for each location. i.e. The 'location' is the port, e.g. "Panel 1"
// This allows the turning on/off of individual locations.
// #REVIEW
//
// * move this.locationGroupLayers etc. to state rather than forced via "this."
// use setState to update once groups are defined

const mapDisplayOptions = getMapDisplayOptions();
const { controllerLocations, controllerCables, tagTrackingFFsMarkers } =
  mapDisplayOptions;

// Disabled for now to reduce load on leaflet
const DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER = controllerLocations;
const DEFAULT_SHOW_FIREFLY_POLYLINE_GROUP_LAYER =
  controllerCables && DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER;
// << needs location group above to also be enabled

//
// -----------------------------------------------

//

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refPts: {},
      thislocalMapState: {},
      map: { area: "defaultArea" },
      heartBeat: true,
      isMapLoading: true,
      isMapLoaded: false,
      mapSrc: "",
      //
      layerControlState: {},
      // marker popup state
      isPopupOpen: false,
      // map view state
      mapViewState: { id: undefined, center: undefined, zoom: undefined },
      savedMapViewState: {},

      // #NOTE -
      // * set when componentDidMount is run.
      // Used to determine if map regionPreview should be updated

      isMapComponentDidMount: false,
      //
      isPokeTheWorkerLocal: true, // local record of whether to collect data, stops updates immediately instead of waiting for redux response
      //
      markerPositionChanges: {},
      forceMapRefreshFireflys: false, // used to force map refresh if markers moved
      forceMapRefreshControllers: false, // used to force map refresh if markers moved
      isMoving: false,
      isMarkerDragging: false,
      //
      // tags
      isTagTracking: false,
      showTags: ["unknown", "personnel", "vehicle"],
      //
      // tracks which forced markers have been clicked
      forcedClickList: [],
      //
      // tracks if map move in use
      isMapView: false,
      // tracks if edit tools in use
      isMoveMarkerPositions: false,
      // tracks activeLayers so can toggle on/off
      activeLayersList: [],
      // modal for details of the marker
      isOpenFireflyMarkerPopupModal: { active: false, id: "", area: "" },
      isOpenControllerMarkerPopupModal: { active: false, id: "", area: "" },
      //
      isDataCollectionDuringMarkerPopupOpen:
        isDataCollectionDuringMarkerPopupOpen(),
      ctrlKey: false,
      fireflySelection: [],
      isEnableSearchBox: true, // #WIP - pass from configJs
      isShowSearchBox: false,
      searchBoxContent: ["List..."],
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  // #WIP - #TODO - MAKE COMMON
  // see - src/pages/ControlRoomPage/MineLevelOpsControl.js
  // message banner support
  //
  //
  faultMessageBannerId = (faultType) => {
    // check if a message of this type exists

    // get the id for the flash message
    const messageBanners = this.props.messageBanners; // from redux state

    console.log(`messageBanners`, messageBanners);
    return messageBanners[faultType].id;
  };

  clearFaultMessageBanner = (faultType) => {
    // get the id for the flash message
    const bannerId = this.faultMessageBannerId(faultType);

    console.log(`message bannerId`, bannerId);
    if (bannerId) {
      this.props.removeFlashMessage(bannerId);
      //#REVIEW - make this a function..........
      // delete this banner message from state
      let tmpObj = {};
      tmpObj.faultType = faultType;
      tmpObj.display = false;
      tmpObj.id = "";

      this.props.SetMessageBanner(tmpObj);
    }
  };

  areaImageNotFoundMessage = (areaImageNotFound, imageFilename) => {
    const messageBanners = this.props.messageBanners;

    if (messageBanners === undefined) return;

    // console.log(`messageBanners`, messageBanners);
    // console.log(`message imageFilename`, imageFilename);
    // console.log(`message areaImageNotFound`, areaImageNotFound);

    // #NOTE - old comment, but still applies. Need to centralise function.
    //#REVIEW - force to one type for now - this needs to be merged with AlarmButtons and other messaging

    const bannerDisplayed = messageBanners["areaImageNotFound"]?.display;
    let bannerInfo = messageBanners["areaImageNotFound"]?.info || [];
    const isBannerDisplayedForThisImageFilename =
      bannerInfo?.includes(imageFilename);

    if (isConfigJs() && faultMessageBannerDisplay()) {
      const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

      // if this fault type allows message banner display (in config)
      // and there hasn't been a message banner displayed previously

      if (isMessageBannerForFaultType["areaImageNotFound"]) {
        if (areaImageNotFound) {
          // set
          if (bannerDisplayed) {
            // banner already exists
            if (!isBannerDisplayedForThisImageFilename) {
              // update banner
              // clear current banner
              this.clearFaultMessageBanner("areaImageNotFound");
              // set a new banner
              this.setFaultMessageBanner([...bannerInfo, imageFilename]);
            }
          } else {
            // no current banner
            // set a new banner
            this.setFaultMessageBanner([...bannerInfo, imageFilename]);
          }
        } else {
          // clear banner
          if (bannerDisplayed) {
            if (isBannerDisplayedForThisImageFilename) {
              // clear just this banner
              // clear current banner
              this.clearFaultMessageBanner("areaImageNotFound");
              // set a new banner
              this.setFaultMessageBanner(
                bannerInfo.filter((file) => file !== "B")
              );
            }
            //
          }
        }
      }
    }
  };

  setFaultMessageBanner = (bannerInfo) => {
    console.log(`message bannerInfo`, bannerInfo);
    // set a new banner
    // create a unique ID for the banner
    const id = cuid();
    // display a new banner
    this.displayErrors(id, "areaImageNotFound", bannerInfo);

    // record the update state of banner including the id (needed to delete this particular banner)
    let tmpObj = {};
    tmpObj.faultType = "areaImageNotFound"; // #REVIEW - force to one status check type for now
    tmpObj.display = true;
    tmpObj.id = id;
    // multiple files : same
    tmpObj.info = bannerInfo;

    this.props.SetMessageBanner(tmpObj);
  };

  // get the text for the fault message banner from config.js or set default
  getFaultMessageBannerText = (faultType) => {
    let header;
    let message;
    let color;

    if (isConfigJs() && faultMessageBannerText()) {
      const messageBannerText = faultMessageBannerText(); // from config.js file
      header = messageBannerText[faultType].header;
      message = messageBannerText[faultType].message;
      color = messageBannerText[faultType].color;
    } else {
      // default messages if config.js object is stuffed
      switch (faultType) {
        case "areaImageNotFound":
          header = "Area Image Can Not Be Found";
          message =
            "An area image can not be found on the server, or the server connection has failed. Check the server operation then log out/in.";
          color = "red";
          break;
        default:
          break;
      }
    }

    return { header, message, color };
  };

  // loads header and message to display on control screen page
  // depending on significance of the error
  // All cases go to the Browser Logger page
  //
  displayErrors(id, faultType, info) {
    const { header, message, color } =
      this.getFaultMessageBannerText(faultType);

    // set color from highest fault_level for the faultType
    //    const color = this.setColorMessageBanner(faultType);

    this.props.addWarningFlashMessageIdColor(
      id,
      color,
      header,
      message + " " + info.join(", ") || "Unknown problem"
    );
    //console.log("message displayErrors - " + header + "-" + message);
  }

  // styling for polygons
  geojsonPolygonStyle = (feature) => {
    return {
      fillColor: feature.properties?.style || "grey",
      color: feature.properties?.style || "grey",
      weight: 2,
      opacity: 0.5,
      fillOpacity: 0.2,
    };
  };

  // support for map leaflet.contextmenu
  showCoordinates = (e) => {
    alert(e.latlng);
  };

  centerMap = (e) => {
    this.map.panTo(e.latlng);
  };

  fitMap = (e, bounds) => {
    this.map.fitBounds(bounds);
  };

  zoomIn = (e) => {
    this.map.zoomIn();
  };

  zoomOut = (e) => {
    this.map.zoomOut();
  };

  // draw
  drawTheMap = (
    mapId,
    mineLevel,
    geoJSONMarkersData,
    geoJSONNamedAreas,
    geoJSONControllers
  ) => {
    const {
      id: mineLevelId,
      image_filename,
      image_info: { width = 10000, height = 8000 },
    } = mineLevel;

    //console.log("mapViewState", mapViewState);
    const { center: setCenter, zoom: setZoom } = this.state.mapViewState;

    let url = process.env.PUBLIC_URL + "/areas/" + image_filename; // "extraction.png";

    let isMapLoading = true;
    // console.log("loaded isMapLoading @ entry to drawTheMap ", isMapLoading);
    this.setState({ isMapLoading: true });
    this.setState({ mapSrc: url });

    // if (this.state.isMapLoaded) {
    //   console.log(`loaded isMapLoaded TRUE `, url);
    // }

    // // setup a dummy image and load the map image in the background
    // // create a replacement image using known dimensions
    // let canvas = document.createElement("canvas");
    // // set desired size of transparent image
    // canvas.width = width;
    // canvas.height = height;

    // // extract as new image (data-uri)
    // const url = canvas.toDataURL();

    ////////////////////////////////////////////////////////////////////////////
    // Conversion from (x, y) raster image coordinates to equivalent of latLng
    // Taken from Leaflet tutorial "Non-geographical maps"
    // http://leafletjs.com/examples/crs-simple/crs-simple.html
    ////////////////////////////////////////////////////////////////////////////
    var yx = L.latLng;
    var xy = function (x, y) {
      if (L.Util.isArray(x)) {
        // When doing xy([x, y]);
        return yx(x[1], x[0]);
      }
      return yx(y, x); // When doing xy(x, y);
    };

    const img = [
      width, // original width of image `level.jpg` - undercut 800 conversion example => x / ~longitude (0 is left, width,  13234 is right)
      height, // original height of image => y / ~ reverse of latitude (0 is top, height, 9356 is bottom)
    ];

    // if a center has been passed to component use it, else recalc
    let center = [width / 2, height / 2]; // center calculation based on original pixel dimensions
    if (setCenter) center = Object.values(setCenter); // .reverse();

    let zoom = -4;
    if (setZoom) zoom = setZoom;

    // console.log(
    //   "mapViewState center setCenter setZoom",
    //   mineLevelId,
    //   center,
    //   setCenter,
    //   setCenter ? Object.values(setCenter).reverse() : "????",
    //   zoombounds
    // );

    var bounds = L.latLngBounds([xy(0, 0), xy(img)]);

    let base = L.tileLayer(url, {
      bounds: bounds,
      noWrap: true,
    });

    // #REVEW / #WIP - put up 'loading' banners
    // events to show loading progress
    // https://stackoverflow.com/questions/27378572/leaflet-fire-event-when-tile-layer-loading-begins-and-ends

    //base.on("loading", this.setState({ isMapLoading: true }));
    //base.on("load", this.setState({ isMapLoading: false }));

    // #REVIEW - not used
    base.on("tileload", function () {
      console.log("base loaded");
    });

    base.on("tileerror", (error, tile) => {
      console.log(`Error ${error} loading tile ${tile}`);
    });

    this.map = null;

    // define the map
    this.map = L.map(mapId, {
      crs: L.CRS.Simple,

      //maxBounds: bounds.pad(0.5), // http://leafletjs.com/reference-1.0.3.html#map-maxbounds
      // center: center,
      // zoom: zoom,

      // #WIP - add these to config options
      minZoom: -5,
      maxZoom: 5,
      // in setView(point, zoom), zoom will only go to the resolution designated by
      // zoomSnap.
      // see - https://stackoverflow.com/questions/61233935/leaflet-setview-zoom-level-to-non-integer-decimal-value-in-r
      zoomSnap: 0.01,

      // removes the zoom controls. These are display via easyButtons
      zoomControl: false,

      // disable double click zoom
      doubleClickZoom: false,

      attributionControl: false,
      contextmenu: true, // context menu plugin `npm i leaflet-contextmenu`
      contextmenuWidth: 140,
      contextmenuItems: [
        // {
        //   text: "Show coordinates",
        //   callback: this.showCoordinates,
        // },
        {
          text: "Center map here",
          callback: this.centerMap,
        },
        {
          text: "Show all",
          callback: (e) => this.fitMap(e, bounds),
        },
        // #REVIEW - this is disabled in favour of the control which enables zoom-in/zoom-out
        //
        "-",
        {
          text: "Zoom in",
          icon: process.env.PUBLIC_URL + "/images/zoom-in.png",
          callback: this.zoomIn,
        },
        {
          text: "Zoom out",
          icon: process.env.PUBLIC_URL + "/images/zoom-out.png",
          callback: this.zoomOut,
        },
      ],
      // scroll wheel zoom smoothing
      scrollWheelZoom: false, // disable original zoom function
      smoothWheelZoom: false, // enable smooth zoom // see - 'disableMapMovement'
      smoothSensitivity: 1.5, // zoom speed. default is 1
    });

    // ------------------------------------------------------
    // set single image background

    let overlayUrl;

    // check file exists on server
    if (this.props.folderFilesList?.includes(image_filename)) {
      overlayUrl = url;

      // does not apply to default.png
      if (!image_filename.includes("default.png")) {
        this.areaImageNotFoundMessage(false, image_filename);
        // console.log(
        //   `removing a message banner!!!! - areaImageNotFoundMessage`,
        //   mineLevelId,
        //   image_filename
        // );
      }
    } else {
      // setup a dummy image and load the map image in the background
      // create a replacement image using known dimensions
      let canvas = document.createElement("canvas");
      // set desired size of transparent image
      canvas.width = width;
      canvas.height = height;
      // extract as new image (data-uri)
      overlayUrl = canvas.toDataURL();

      // #NOTE #WIP #TODO
      // SET A MESSAGE IF THE MAP FILE IS NOT AVAILABLE

      // doe not apply to default.png
      if (!image_filename.includes("default.png")) {
        this.areaImageNotFoundMessage(true, image_filename);
        // console.log(
        //   `displaying a message banner!!!! - areaImageNotFoundMessage`,
        //   mineLevelId,
        //   image_filename
        // );
      }
    }

    // ************************************************************************************
    // ************************************************************************************

    // --------------------------------
    // #WIP #TESTING
    // see - https://gis.stackexchange.com/questions/183872/leaflet-custom-info-control-update-with-extra-parameters
    //
    // Simple custom control to display a list of text
    // -
    // - displays after map move so that data is updated
    // - lists all FireFly in the current bounds (view)
    // - text list has click eventlistener
    //
    // - use as basis/testing to accumulate control+ select on FireFly 'SHOW_CONTOL_SELECT'
    //
    // --------------------------------

    const SHOW_CONTROL_SELECT = true;
    const SHOW_CUSTOM_CONTROL_EVENT = SHOW_CONTROL_SELECT
      ? "popupclose"
      : "move";

    var MyCustomControl = L.Control.extend({
      options: {
        // Default control position
        position: "bottomright",
      },
      onAdd: function (map) {
        // Create a container with classname and return it
        this._div = L.DomUtil.create("div", "my-custom-control");

        L.DomEvent.on(this._div, "click", this._click);
        L.DomEvent.disableClickPropagation(this._div);
        return this._div;
      },
      _click: (e) => {
        // console.log(
        //   "clicked myCustomControl: ",
        //   e,
        //   "target: ",
        //   e.target,
        //   "data-key = ",
        //   e.target.dataset.key,
        //   "this.layerlistFireflies ",
        //   this.layerlistFireflies
        // );

        const id = e.target.dataset.key;
        if (!_isEmpty(id)) {
          const marker = this?.layerlistFireflies?.[id];
          this.map.setView(marker?.getLatLng(), this.map.getMaxZoom());
        }
      },
      setContent: function (content) {
        // Set the innerHTML of the container
        this.getContainer().innerHTML = content;
      },
    });

    // Assign to a variable so you can use it later and add it to your map
    var myCustomControl = new MyCustomControl().addTo(this.map);

    if (SHOW_CONTROL_SELECT) {
      // display the control+select FireFly lists
      myCustomControl.setContent(this.state.fireflySelection);
    }
    // display the FireFly in the current view
    else {
      myCustomControl.setContent(this.state.searchBoxContent);
    }

    // update list after map moves
    this.map.on(SHOW_CUSTOM_CONTROL_EVENT, () => {
      if (SHOW_CONTROL_SELECT) {
        // display the control+select FireFly lists
        myCustomControl.setContent(this.state.fireflySelection.join("<br>\n"));
      }
      // display the FireFly in the current view
      else {
        // construct an empty list to fill with onscreen markers
        const inBounds = [];
        // get the map bounds - the top-left and bottom-right locations
        const bounds = this.map.getBounds();

        // for each marker, consider whether it is currently visible by comparing
        // with the current map bounds
        if (this.geoJSONGroupLayer) {
          this.geoJSONGroupLayer.eachLayer((layer) => {
            if (bounds.contains(layer.getLatLng())) {
              //inBounds.push(layer?.feature?.properties?.id);
              const id = layer?.feature?.properties?.id;
              inBounds.push(
                `<button
                data-key=${id}
                class="link myCustomControl-link"
              >
                ${id}
              </button>`
              );
            }
          });
        }

        // display a list of markers.
        myCustomControl.setContent(inBounds.join("<br>\n"));
      }
    });

    if (false) {
      // --------------------------------
      // #WIP #TESTING
      // see - https://gis.stackexchange.com/questions/183872/leaflet-custom-info-control-update-with-extra-parameters
      //
      // Simple custom control to display a list of text
      // -
      // - displays after map move so that data is updated
      // - text list has click eventlistener
      //
      // See - src/components/Map/leaflet-search-control.js
      // -----------------------------------------------

      // load empty panel to place control
      let items = [];
      var searchControl = L.control
        .search({
          data: items,
        })
        .addTo(this.map);

      // data is not initially available so
      // remove and redraw the control after the map has moved
      // see - https://gis.stackexchange.com/questions/142773/how-to-update-data-in-leaflet-control
      //

      this.map.on("move", () => {
        // remove previous control
        searchControl.remove();

        // reload control with fresh data set
        // data is drawn from geoJson layers
        let items = [];
        if (this.geoJSONGroupLayer) {
          this.geoJSONGroupLayer.eachLayer((layer) => {
            items.push(layer);
          });
        }
        // pass data items as geoJson
        searchControl = L.control
          .search({
            data: items,
          })
          .addTo(this.map);
      });
    }

    // ************************************************************************************
    // ************************************************************************************
    // ************************************************************************************

    let isMapImageFromState = false;
    // if the image is available from the reducer Settings state load this instead
    if (!_isEmpty(this.props.mapImageFromState)) {
      overlayUrl =
        "data:image/png;base64," + this.props.mapImageFromState.imageData;
      isMapImageFromState = true;
    }

    const image = L.imageOverlay(overlayUrl, bounds);
    image.addTo(this.map);

    // image load events
    image.on("load", (event) => {
      // console.log(
      //   "LOADED NEW BACKGROUND IMAGE ",
      //   url,
      //   "** isMapLoading FALSE ** <<<<<"
      // );
      //console.log("LOADED NEW BACKGROUND IMAGE event", event);

      // check if image is loaded from the url or via the state image
      if (event?.target?._url == url || isMapImageFromState) {
        isMapLoading = false;
        // console.log("loaded isMapLoading", isMapLoading);
        this.setState({ isMapLoading: false });
      }
    });

    image.on("error", (error, tile) => {
      console.log(`Error ${error} loading imageOverlay ${tile}`);
    });

    // console.log(
    //   `xxxxx newMapViewState - setCenter, setZoom, center, zoom, bounds`,
    //   setCenter,
    //   "-",
    //   setZoom,
    //   "-",
    //   center,
    //   "-",
    //   zoom,
    //   "-",
    //   bounds
    // );

    // #NOTE - this defines if the map loads with the previously saved view settings
    if (true) {
      if (!_isEmpty(setCenter) || !_isEmpty(setZoom)) {
        // if mapViewState has been set
        this.map.setView(xy(center), zoom);
      } else {
        // new page load so fit to bounds
        this.map.fitBounds(bounds);
      }
    } else {
      // new page load so fit to bounds
      this.map.fitBounds(bounds);
    }

    // record the current zoom level
    this.previousZoom = this.map.getZoom();

    // record the current map
    this.previousCenter = this.map.getCenter();

    // ****************************************************
    //
    // Setup and draw the Fireflies
    //
    // ****************************************************

    //
    this.layerlistFireflies = {};

    //
    this.testExternalStr = "<strong>Point: </strong>"; // test passing variable outside into ....

    // add popup information
    const onEachFeatureFirefly = (feature, layer) => {
      const { properties } = feature;
      const { id, area } = properties;
      if (id && area) {
        this.layerlistFireflies[id] = layer;

        //#TODO - make this the same as how 'NamedAreaPolygonPopup' is implemented.
        // Make Controller, NamedArea and FireFly popups all same process

        const popupInfo = `<div><strong>FireFly: </strong><button data-key=${id} class="link button-edit">${id}</button></div>`;
        layer.bindPopup(popupInfo);
        layer.on("popupclose", (event) => {
          this.setState({ isPopupOpen: false });

          // if the map view is moving then do not re-enable data collection on close
          // unless config says 'isDataCollectionDuringMarkerPopupOpen'

          if (!this.state.isMarkerDragging || !this.state.isMapView) {
            if (!this.state.isDataCollectionDuringMarkerPopupOpen) {
              this.enableDataCollect();
            }
          }
        });

        layer.on("popupopen", (a) => {
          // track of marker popup is opened
          this.setState({ isPopupOpen: true });

          // if the map view is already moving don't bother disabling data collection again
          // unless config says 'isDataCollectionDuringMarkerPopupOpen'

          if (!this.state.isMarkerDragging && !this.state.isMapView) {
            if (!this.state.isDataCollectionDuringMarkerPopupOpen) {
              this.disableDataCollect();
            }
          }

          var popUp = a.target.getPopup();
          popUp
            .getElement()
            .querySelector(`.button-edit`)
            .addEventListener("click", (e) => {
              // console.log(
              //   "clicked edit on marker: ",
              //   e,
              //   "target: ",
              //   e.target,
              //   "data-key ",
              //   e.target.dataset.key
              // );

              const id = e.target.dataset.key;
              if (!_isEmpty(id)) {
                const newModal = {
                  active: true,
                  id: id,
                  area: area,
                };
                // if the map marker is being moved disable the modal dlg display
                // as this is confusing to the user
                if (!this.state.isMarkerDragging) {
                  this.setState(
                    {
                      isOpenFireflyMarkerPopupModal: newModal,
                    },
                    () =>
                      console.log(
                        `CLICK ON FF ID: `,
                        newModal,
                        properties?.light?.event_id
                      )
                  );
                }
              }
            });
        });

        // #WIP - testing control + click event to add to list
        layer.on("popupopen", (e) => {
          const marker = e.target;
          const id = marker?.feature?.id;

          let newFireFlySelection = this.state.fireflySelection;
          // if the control key is held down
          if (this.state.ctrlKey) {
            // and the FF id is not already in the selection list
            if (!newFireFlySelection.includes(id)) {
              // add it
              newFireFlySelection.push(id);
              this.setState({ fireflySelection: newFireFlySelection });
            } else {
              newFireFlySelection = newFireFlySelection.filter(
                (list) => list !== id
              );
              this.setState({ fireflySelection: newFireFlySelection });
            }
          }
          // if the control key is not down
          else {
            // and if the FF select list has FFs ... clear the list
            if (newFireFlySelection.length) {
              this.setState({ fireflySelection: [] });
            }
          }

          //
          console.log(
            "CLICKED ON MARKER marker",
            marker?.feature?.id,
            " -- ctrlKey : ",
            this.state.ctrlKey,
            " -- ctrlKey : ",
            this.state.fireflySelection
          );
        });
      }

      //----------------------------------------------------
      //
      // MAP MARKER MOVE FIREFLY POSITIONS - DRAG EVENTS
      //
      //----------------------------------------------------

      layer.on("dragstart", (e) => {
        //
      });

      // markerPositionChanges

      layer.on("dragend", (e) => {
        const marker = e.target;
        const position = marker.getLatLng();
        const markerProperties = marker.feature.properties;
        const { id, forced } = markerProperties;

        console.log("xxx dragend Firefly position", id, position);
        console.log(
          "xxx dragend Firefly fire off FF update!!!! markerProperties",
          marker,
          markerProperties
        );
        // console.log(
        //   "xxx dragend Firefly fire off FF update!!!! forced",
        //   forced ? "FORCED!!!!!!!!!!!!!!!!" : "Not forced",
        //   id
        // );

        if (forced) {
          // if Markers has a FORCED- event_id, clear it
          ForceSingleLightManageNamedArea({
            action: "clear",
            fireflyId: id,
            area: area,
            easting: null,
            northing: null,
            color: null,
            setFireflyColor: null,
            setFireflyState: null,

            namedAreaEvents: this.props.namedAreaEvents,
            deleteNamedArea: this.props.deleteNamedArea,
            saveNewNamedArea: this.props.saveNewNamedArea,
            activate: this.props.activate,
          });
        }

        let newMarkerPositionChanges = this.state.markerPositionChanges;
        newMarkerPositionChanges[id] = {
          id: id,
          type: "firefly",
          properties: markerProperties,
          position: position,
        };
        this.setState({ markerPositionChanges: newMarkerPositionChanges });
      });
    };

    // This is very important! Use a canvas otherwise the chart is too heavy for the browser when
    // the number of points is too high, as in this case where we have around 300K points to plot
    var myRenderer = L.canvas({
      padding: 0.5,
    });

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerFirefly = (feature, latlng) => {
      // ####SVG Marker###

      // #DEBUG
      // #KEEP THIS...
      const iconColor = { stroke: "grey", fill: "grey" };
      const markerColor = {
        stroke: feature.properties.color.plan,
        fill: feature.properties.color.status,
      };

      const { svgIcon, svgStyle } = markerDefinition(feature);
      const currentZoom = this.map.getZoom();

      return L.marker(latlng, {
        renderer: myRenderer,
        //icon: makeIcon("CrossMarker", iconColor, 1), // default to 'cross' on first write of coordinate message
        //icon: makeIcon("CrossMarker", markerColor, currentZoom), // default to 'cross' on first write of coordinate message
        icon: makeIcon(svgIcon, svgStyle, currentZoom),
        draggable: false,
      });
    };

    // TESTING_DRAG

    // set style of firefly markers
    const fireflyStyle = (feature) => {
      let setFillColor;
      if (feature.properties.color) {
        const color = feature.properties.color;
        switch (color.status) {
          case "green":
            setFillColor = "green";
            break;
          case "blue":
            setFillColor = "blue";
            break;
          case "amber":
            setFillColor = "orange";
            break;
          case "white":
            setFillColor = "white";
            break;
          case "red":
            setFillColor = "red";
            break;
          default:
            setFillColor = "green";
            break;
        }
      }

      return {
        radius: 6,
        fillColor: setFillColor,
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    };

    // determine unique properties array from geoJson
    //
    let uniqueProperties = geoJSONMarkersData.features.reduce(
      (acc, { properties }) => {
        Object.entries(properties).forEach(([key, val]) => {
          acc[key] = acc[key] || new Set();
          acc[key].add(val);
        });

        return acc;
      },
      {}
    );

    if (DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER) {
      let locations = [];
      this.locationGroupLayers = {};

      if (uniqueProperties["location"]) {
        locations = [...uniqueProperties["location"]].sort(sortAlphaNum);
        locations.forEach((value, idx) => {
          //console.log("locations[idx]", value);

          this[value] = L.featureGroup().addTo(this.map);
          // -> this makes firefly location group a separate layer
          // disabled for now so does not crowd the layer control
          this.locationGroupLayers[value] = this[value];
        });
      }
    }

    // #REVIEW  - Issue is that with growing array location groups are not created on startup.
    // Need to make these with each update of the state geoJson
    // constrain to area
    const filterArea = (feature, layer) => {
      return feature.properties.area === mineLevelId; //"DMLZ_Undercut"; //this.state.map.area; // "DMLZ_Extraction";
    };

    // add firefly markers to the map
    // Note - Only Controllers and Fireflies are add to map by default (.addTo(this.map))
    this.geoJSONGroupLayer = L.geoJSON(geoJSONMarkersData, {
      pointToLayer: pointToLayerFirefly,
      onEachFeature: onEachFeatureFirefly, // #NOTES - Essential as it defines this.layerlistFireflies
      style: fireflyStyle, //#DEBUG - DISABLED FOR RECTANGLE TESTING  ###DEFAULT_MARKER####
      //coordsToLatLng: coordsToLatLng, // <<--- #REVIEW I still don't underestand what this does
      filter: filterArea,
    }).addTo(this.map);

    // display the layer when the map is loaded
    // if (!isMapLoading) {
    //   this.geoJSONGroupLayer.addTo(this.map);
    // }

    // ********************************************************************************
    //
    // Setup and draw the controller markers
    //
    // ********************************************************************************

    // list of controller layers
    this.layerlistControllers = {};

    // add popup information
    const onEachFeatureController = (feature, layer) => {
      const { properties } = feature;
      const { id, area, network_name } = properties;

      const controllerLabel = `${network_name} (${id})`;

      if (id && area) {
        //#TODO - make this the same as how 'NamedAreaPolygonPopup' is implemented.
        // Make Controller, NamedArea and FireFly popups all same process

        const popupInfo = `<div><strong>Controller: </strong><button data-key=${id} class="link button-edit">${controllerLabel}</button></div>`;
        layer.bindPopup(popupInfo);
        layer.on("popupclose", (event) => {
          this.setState({ isPopupOpen: false });
          // if the map view is moving then do not re-enable data collection on close
          if (!this.state.isMarkerDragging || !this.state.isMapView) {
            if (!this.state.isDataCollectionDuringMarkerPopupOpen) {
              this.enableDataCollect();
            }
          }
        });
        layer.on("popupopen", (a) => {
          // track of marker popup is opened
          this.setState({ isPopupOpen: true });
          // if the map view is already moving don't bother disabling data collection again
          if (!this.state.isMarkerDragging && !this.state.isMapView) {
            if (!this.state.isDataCollectionDuringMarkerPopupOpen) {
              this.disableDataCollect();
            }
          }

          var popUp = a.target.getPopup();
          popUp
            .getElement()
            .querySelector(`.button-edit`)
            .addEventListener("click", (e) => {
              // console.log(
              //   "clicked edit on marker: ",
              //   e,
              //   "target: ",
              //   e.target,
              //   "data-key ",
              //   e.target.dataset.key
              // );

              const id = e.target.dataset.key;
              if (!_isEmpty(id)) {
                const newModal = {
                  active: true,
                  id: id,
                  area: area,
                };
                // if the map marker is being moved disable the modal dlg display
                // as this is confusing to the user
                if (!this.state.isMarkerDragging) {
                  this.setState(
                    {
                      isOpenControllerMarkerPopupModal: newModal,
                    },
                    () => console.log(`CLICK ON Controller ID: `, newModal)
                  );
                }
              }
            });
        });
      }
      if (feature.properties && feature.properties.id) {
        this.layerlistControllers[feature.properties.id] = layer;
      }

      // #REVIEW - can't do this as-is b/c location is an array of positions.
      // Is this a duplication from FF which has never worked?
      // if (feature.properties && feature.properties.location) {
      //   // adds a separate layer specifically for location
      //   this[`${feature.properties.location}`].addLayer(layer);
      // }

      //----------------------------------------------------
      //
      // MAP MARKER MOVE CONTROLLER POSITIONS - DRAG EVENTS
      //
      //----------------------------------------------------

      layer.on("dragstart", (e) => {
        //
      });

      // markerPositionChanges

      layer.on("dragend", (e) => {
        const marker = e.target;
        const position = marker.getLatLng();
        const markerProperties = marker.feature.properties;
        const { id } = markerProperties;

        let newMarkerPositionChanges = this.state.markerPositionChanges;
        newMarkerPositionChanges[id] = {
          id: id,
          type: "controller",
          properties: markerProperties,
          position: position,
        };
        this.setState({ markerPositionChanges: newMarkerPositionChanges });
      });
    };

    // This is very important! Use a canvas otherwise the chart is too heavy for the browser when
    // the number of points is too high, as in this case where we have around 300K points to plot
    // var myRenderer = L.canvas({
    //   padding: 0.5,
    // });

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerController = (feature, latlng) => {
      // #KEEP THIS...
      const iconColor = { stroke: "blue", fill: "blue" };
      const currentZoom = this.map.getZoom();

      return L.marker(latlng, {
        renderer: myRenderer,
        icon: makeIcon("SquareMarker", iconColor, currentZoom), // default to 'cross' on first write of coordinate message
        draggable: false,
      });
    };

    // set style of controller markers
    const controllerStyle = (feature) => {
      let setFillColor = "green";
      if (feature.properties.color) {
        const color = feature.properties.color;
        switch (color.status) {
          case "green":
            setFillColor = "green";
            break;
          case "blue":
            setFillColor = "blue";
            break;
          case "amber":
            setFillColor = "orange";
            break;
          case "white":
            setFillColor = "white";
            break;
          case "red":
            setFillColor = "red";
            break;
          default:
            setFillColor = "green";
            break;
        }
      }

      return {
        radius: 6,
        fillColor: setFillColor,
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    };

    // add controller markers to the map
    // Note - Only Controllers and Fireflies are add to map by default (.addTo(this.map))
    this.geoJSONControllerGroupLayer = L.geoJSON(geoJSONControllers, {
      pointToLayer: pointToLayerController,
      onEachFeature: onEachFeatureController,
      style: controllerStyle,
      //coordsToLatLng: coordsToLatLng, // <<--- #REVIEW I still don't underestand what this does
      filter: filterArea,
    }).addTo(this.map);

    // display the layer when the map is loaded
    // if (!isMapLoading) {
    //   this.geoJSONControllerGroupLayer.addTo(this.map);
    // }

    // ********************************************************************************
    //
    // Setup and draw the named area polygons
    //
    // ********************************************************************************

    // list of named area polygons
    this.layerlistNamedAreas = {};

    const onEachFeatureNamedAreas = (feature, layer) => {
      if (feature.properties && feature.properties.id) {
        this.layerlistNamedAreas[feature.properties.id] = layer;
      }

      const { properties } = feature;
      const { id, parent, parent_name, sub_type } = properties;
      const namedAreaPolygonPopup = renderToString(
        <NamedAreaPolygonPopup
          id={id}
          data={{
            parent,
            parentName: parent_name,
            sub_type,
          }}
        />
      );
      layer.bindPopup(namedAreaPolygonPopup);
      layer.on("popupclose", (event) => {
        this.setState({ isPopupOpen: false });
      });
      layer.on("popupopen", (event) => {
        this.setState({ isPopupOpen: true });

        var popUp = event.target.getPopup();

        // added eventListener to controller marker content
        // see - src/components/Map/NamedAreaPolygonPopup.js
        popUp
          .getElement()
          .querySelector(".popupNamedAreaPolygon")
          .addEventListener("click", (e) => {
            // console.log(
            //   "clicked edit on polygon: ",
            //   e,
            //   "target: ",
            //   e.target,
            //   "dataid ",
            //   e.target.getAttribute("dataid")
            // );

            const id = e.target.getAttribute("dataid");
            if (!_isEmpty(id)) {
              this.props.goto(`/admin/named-area/${id}`);
            } else {
              this.props.goto(`/admin/named-area/`);
            }
          });
      });
    };

    // add named area polygons to the map
    this.geoJSONNamedAreaGroupLayer = L.geoJSON(geoJSONNamedAreas, {
      style: this.geojsonPolygonStyle,
      onEachFeature: onEachFeatureNamedAreas,
      coordsToLatLng: function (coords) {
        // swaps the coordinate references around so when polygons are created the pixel refer are [x,y] , not [y,x]
        //                    latitude , longitude, altitude
        return new L.LatLng(coords[1], coords[0], coords[2]); // <--------- Normal behavior
        //return new L.LatLng(coords[0], coords[1], coords[2]);
      },
    }).addTo(this.map);
    // #Note - adding to the map before adding to layer will set [x] default .addTo(this.map);

    // update leaflet id to properties (used to delete.edit shapes in local state)
    this.geoJSONNamedAreaGroupLayer.eachLayer(function (layer) {
      layer.feature.properties._leaflet_id = layer._leaflet_id; //#TODO - change to L.Stamp()
    });

    //#TODO - change to L.Stamp()
    //     Also note that .getLayers() works for LayerGroup (and FeatureGroup and GeoJson), but not for L.Map.
    // Usage of "private" properties and methods like _layers or _leaflet_id or _latlng is discouraged.

    // add layers to the local maps state
    const geoJsonPixels = this.geoJSONNamedAreaGroupLayer.toGeoJSON();

    //const mineLevel = this.props.mineLevel;
    const mineLevelKey = mineLevel.id;
    let mineLevelObj = {};
    mineLevelObj[mineLevelKey] = { ...mineLevel };

    const geoJsonUtm = this.convertGeoJsonPixelsToUtm(
      geoJsonPixels,
      mineLevelObj
    );

    //console.log("qqq add layers to the local maps state geoJson", geoJsonUtm);
    this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
    this.setState({ thislocalMapState: geoJsonUtm });

    // push layer to back layer.bringToFront()
    this.geoJSONNamedAreaGroupLayer.bringToBack();

    // console.log(
    //   "xxx this.geoJSONNamedAreaGroupLayer",
    //   this.geoJSONNamedAreaGroupLayer
    // );

    // ********************************************************************************
    //
    // Setup the map layers into control groups and display controls
    //
    // ********************************************************************************

    // APP_TERMINOLOGY
    const strNamedAreas = "Polygons";

    //
    // Use the custom grouped layer control, not "L.control.layers"
    // https://github.com/ismyrnow/leaflet-groupedlayercontrol
    // see also -  https://stackoverflow.com/questions/34384905/leaflet-how-to-toggle-all-layers-either-off-or-on-at-once/34392545#34392545
    let groupedOverlays = {
      Markers: {
        FireFlys: this.geoJSONGroupLayer,
        Controllers: this.geoJSONControllerGroupLayer,
        Polygons: this.geoJSONNamedAreaGroupLayer,
      },
    };

    const options = {
      collapsed: true,
      //exclusiveGroups: ["Named Areas"],
      groupsCollapsable: true, // see - https://github.com/ismyrnow/leaflet-groupedlayercontrol/pull/46
      // Show a checkbox next to non-exclusive group labels for toggling all
      groupCheckboxes: true,
    };

    // Use the custom grouped layer control, not "L.control.layers"
    // https://github.com/ismyrnow/leaflet-groupedlayercontrol
    // see also -  https://stackoverflow.com/questions/34384905/leaflet-how-to-toggle-all-layers-either-off-or-on-at-once/34392545#34392545
    this.controller = L.control
      .groupedLayers(null, groupedOverlays, options)
      .addTo(this.map);

    //----------------------------------------------------
    //
    // MAP MOVEMENT - DRAG ZOOM ETC
    //
    // see - https://gis.stackexchange.com/questions/54454/disable-leaflet-interaction-temporary
    //----------------------------------------------------

    const disableMapMovement = (map) => {
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      //map.scrollWheelZoom.disable();
      map.smoothWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();
    };

    const enableMapMovement = (map) => {
      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      //map.scrollWheelZoom.enable();
      map.smoothWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
      if (map.tap) map.tap.enable();
    };

    // on startup disable map movement by user
    //console.log(`123simple disableMapMovement`);
    disableMapMovement(this.map);

    //----------------------------------------------------
    //
    // MAP VIEW MOVE - CONTROLS - function
    //
    //
    // At least of one of these is called by the map lock
    // so needs to be defined here beforehand
    //
    //----------------------------------------------------

    const moveMapViewButtonClick = (control, map) => {
      // show the zoom controls
      moveMapViewButton.disable();
      zoomOutButton.enable();
      zoomInButton.enable();
      cancelMapViewButton.enable();

      // disable the move button
      moveMarkerPositionsButton.disable();

      // disable the view buttons
      saveMapViewStateButton.disable();
      restoreMapViewStateButton.disable();

      // disable the search control
      searchButton.disable();

      // enable map movement
      enableMapMovement(this.map);

      // map has started moving so disable data collection
      // ...at this point isMarkerDragging should be false to stop data collection
      mapStartMoving();

      // #NOTE - toogle map layers AFTER mapStartMoving
      // ...sets isMarkerDragging to true
      toggleMapLayers(control, map, true);

      // change the markers to look 'editable'
      // fireflys
      changeMarkerStyle(
        this.geoJSONGroupLayer,
        this.layerlistFireflies,
        "dummyRoundMarker"
      );
      // controllers
      changeMarkerStyle(
        this.geoJSONControllerGroupLayer,
        this.layerlistControllers,
        "dummyRoundMarker"
      );

      // show unlock button state
      mapMoveLockButton.state("mapMoveLock-unlock");
      mapMoveLockButton.button.style.backgroundColor = "orange";

      this.setState({ isMapView: true });
    };

    const cancelMapViewButtonClick = (control, map) => {
      // show the zoom controls
      moveMapViewButton.enable();
      zoomOutButton.disable();
      zoomInButton.disable();
      cancelMapViewButton.disable();
      moveMarkerPositionsButton.enable();

      // enabled the view buttons
      saveMapViewStateButton.enable();
      setRestoreMapViewStateButton("enable");

      // enable the search button
      searchButton.enable();

      // #NOTE - toogle map layers BEFORE mapStopMoving
      // ...sets isMarkerDragging to false
      toggleMapLayers(control, map, false);

      // disable map movement
      disableMapMovement(this.map);

      // map has stopped moving so enable data collection
      // ...at this point isMarkerDragging should be false to stop data collection
      mapStopMoving();

      // // map has stopped moving so enable data collection
      // this.setState({ isPokeTheWorkerLocal: true });
      // const { isPokeTheWorker } = this.props;

      // console.log(
      //   `123simple disableMapMovement, isPokeTheWorker`,
      //   isPokeTheWorker
      // );

      // if (!isPokeTheWorker) {
      //   // turn on data collection
      //   this.props.TurnOnOffPokeTheWorker(true);
      // }

      // show lock button state
      mapMoveLockButton.state("mapMoveLock-lock");
      mapMoveLockButton.button.style.backgroundColor = "white";

      this.setState({ isMapView: false });

      // force a map refresh to restore marker positions & disable drag
      this.setState({ forceMapRefreshFireflys: true });
      // force a map refresh to restore marker positions & disable drag
      this.setState({ forceMapRefreshControllers: true });
    };

    //----------------------------------------------------
    //
    // MAP MARKER ZOOM  - CONTROLS - MAP LOCK INDICATOR
    //
    //
    // Indicates if map is locked
    //
    //----------------------------------------------------

    const mapMoveLockIcon = `<i class="fas fa-lock " ></i>`;
    const mapMoveUnLockIcon = `<i class="fas fa-unlock " ></i>`;

    const mapMoveLockButton = L.easyButton({
      states: [
        {
          stateName: "mapMoveLock-lock",
          icon: mapMoveLockIcon,
          title:
            "Map movement is locked. Click the Pan control to modify the map display.",
          onClick: moveMapViewButtonClick,
        },
        {
          stateName: "mapMoveLock-unlock",
          icon: mapMoveUnLockIcon,
          title: "Map movement is unlocked. Map drag and zoom is enabled.",
        },
      ],
    });

    //let mapMoveLockBar = L.easyBar([mapMoveLock], { position: "topleft" });
    //mapMoveLockBar.addTo(this.map);

    //----------------------------------------------------
    //
    // MAP MARKER ZOOM  - CONTROLS
    //
    //
    // Custom zoom controls
    //
    //----------------------------------------------------

    // listeners for disabling buttons
    this.map.on("zoomend", (e) => {
      const map = e.target,
        max = map.getMaxZoom(),
        min = map.getMinZoom(),
        current = map.getZoom(),
        isMapView = this.state.isMapView;

      if (current < max) {
        if (isMapView) {
          zoomInButton.enable();
        }
      }
      if (current >= max) {
        if (isMapView) {
          zoomInButton.disable();
        }
      }
      if (current > min) {
        if (isMapView) {
          zoomOutButton.enable();
        }
      }
      if (current <= min) {
        if (isMapView) {
          zoomOutButton.disable();
        }
      }
    });

    const moveMapViewZoomIn = `<i class="fas fa-plus "></i>`;
    const moveMapViewZoomOut = `<i class="fas fa-minus "></i>`;

    const zoomInButton = L.easyButton({
      states: [
        {
          stateName: "zoom-in-move-map-view",
          icon: moveMapViewZoomIn,
          title: "Zoom in",
          onClick: (control, map) => {
            map.setZoom(map.getZoom() + 1);
          },
        },
      ],
    });

    const zoomOutButton = L.easyButton({
      states: [
        {
          stateName: "zoom-out-move-map-view",
          icon: moveMapViewZoomOut,
          title: "Zoom out",
          onClick: (control, map) => {
            map.setZoom(map.getZoom() - 1);
          },
        },
      ],
    });
    //const zoomBar = L.easyBar([zoomInButton, zoomOutButton]);
    //zoomBar.addTo(this.map);

    //----------------------------------------------------
    //
    // MAP VIEW MOVE - CONTROLS
    //
    //----------------------------------------------------

    const moveMapViewShow = `<i class="fas fa-arrows-up-down-left-right fa-lg"></i>`;
    const moveMapViewHide = `<i class="fas fa-arrows-up-down-left-right fa-lg" style="opacity:0.2"></i>`;

    const disableActiveLayer = (layer, layerName, activeLayersList) => {
      let newActiveLayersList = [activeLayersList];
      if (this.map.hasLayer(layer)) {
        if (!newActiveLayersList.includes(layerName)) {
          newActiveLayersList.push(layerName);

          this.map.removeLayer(layer);
        }
      }

      return newActiveLayersList;
    };

    const enableActiveLayer = (layer, layerName, activeLayersList) => {
      let newActiveLayersList = activeLayersList;
      if (!this.map.hasLayer(layer)) {
        if (newActiveLayersList.includes(layerName)) {
          newActiveLayersList.filter((layer) => layer !== layerName);

          layer.addTo(this.map);
        }
      }

      return newActiveLayersList;
    };

    // remove all layers

    const disableAllActiveLayers = (activeLayersList) => {
      let newActiveLayerList = [...activeLayersList];

      // remove all layers
      newActiveLayerList = disableActiveLayer(
        this.geoJSONGroupLayer,
        "geoJSONGroupLayer",
        newActiveLayerList
      );

      // newActiveLayerList = disableActiveLayer(
      //   this.geoJSONGroupLayerFireflyTooltips,
      //   "geoJSONGroupLayerFireflyTooltips",
      //   newActiveLayerList
      // );
      // newActiveLayerList = disableActiveLayer(
      //   this.geoJSONGroupLayerFireflyForcedTooltips,
      //   "geoJSONGroupLayerFireflyForcedTooltips",
      //   newActiveLayerList
      // );
      newActiveLayerList = disableActiveLayer(
        this.geoJSONControllerGroupLayer,
        "geoJSONControllerGroupLayer",
        newActiveLayerList
      );
      // newActiveLayerList = disableActiveLayer(
      //   this.geoJSONGroupLayerTags,
      //   "geoJSONGroupLayerTags",
      //   newActiveLayerList
      // );
      // newActiveLayerList = disableActiveLayer(
      //   this.geoJSONTagTrackingFFsGroupLayer,
      //   "geoJSONTagTrackingFFsGroupLayer",
      //   newActiveLayerList
      // );
      // newActiveLayerList = disableActiveLayer(
      //   this.geoJSONTagTrackingFFsGroupLayerVehicle,
      //   "geoJSONTagTrackingFFsGroupLayerVehicle",
      //   newActiveLayerList
      // );

      return newActiveLayerList;
    };

    const enableAllActiveLayers = (activeLayersList) => {
      let newActiveLayerList = [...activeLayersList];

      // add all layers
      this.geoJSONGroupLayer.addTo(this.map);

      // add layers which were displayed
      newActiveLayerList = enableActiveLayer(
        this.geoJSONGroupLayer,
        "geoJSONGroupLayer",
        newActiveLayerList
      );

      newActiveLayerList = enableActiveLayer(
        this.geoJSONControllerGroupLayer,
        "geoJSONControllerGroupLayer",
        newActiveLayerList
      );

      return newActiveLayerList;
    };

    //

    const toggleMapLayers = (control, map, moveMap) => {
      const { isPokeTheWorker, TurnOnOffPokeTheWorker, SetMapMarkerDragging } =
        this.props;

      let newActiveLayerList = [...this.state.activeLayersList];

      switch (moveMap) {
        case true:
          // turn off data collection
          SetMapMarkerDragging(true);
          this.setState({ isMarkerDragging: true });

          break;
        case false:
          // restore/refresh the layer control
          SetMapMarkerDragging(false);
          this.setState({ isMarkerDragging: false });

          // force a map refresh to restore marker positions & disable drag
          this.setState({ forceMapRefreshFireflys: true });
          // force a map refresh to restore marker positions & disable drag
          this.setState({ forceMapRefreshControllers: true });

          break;

        default:
          break;
      }

      this.setState({ activeLayerList: newActiveLayerList });
    };

    const moveMapViewButton = L.easyButton({
      states: [
        {
          stateName: "show-move-map-view",
          icon: moveMapViewShow,
          title: "Pan and Zoom",
          onClick: moveMapViewButtonClick,
        },
        {
          stateName: "hide-move-map-view",
          icon: moveMapViewHide,
          title: "Disable move view",
        },
      ],
    });

    const cancelMapViewButton = L.easyButton({
      states: [
        {
          stateName: "cancel-move-map-view",
          icon: "fa fa-times",
          title: "Disable Pan and Zoom",
          onClick: cancelMapViewButtonClick,
        },
      ],
    });

    let moveMapViewBar = L.easyBar(
      [
        mapMoveLockButton,
        moveMapViewButton,
        zoomInButton,
        zoomOutButton,
        cancelMapViewButton,
      ],
      { position: "topleft" }
    );
    moveMapViewBar.addTo(this.map);

    // startup hide - zoom buttons
    moveMapViewButton.enable();
    zoomOutButton.disable();
    zoomInButton.disable();
    cancelMapViewButton.disable();

    //----------------------------------------------------
    //
    // MAP MARKER MOVE POSITIONS - CONTROLS
    //
    //----------------------------------------------------

    // Alternate methods to display icons:
    // <span class="star">&starf;</span>
    // '<i class="icon cog" />'
    //
    // see - https://github.com/CliffCloud/Leaflet.EasyButton/issues/82
    // see - http://danielmontague.com/projects/easyButton.js/v1/examples/#fancier-disable
    // see custom.css 'easyButton' section for styling

    const enableFeatureGroupDraggable = (featureGroup, action) => {
      featureGroup.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          //console.log(`123 layer`, action, layer);
          if (action) {
            // if (layer.feature.id === "GBC_Extraction:P26E:1") {
            //    console.log(`123 layer`, layer);
            // }
            layer.dragging.enable();
          } else if (!action) {
            layer.dragging.disable();
          }
        }
      });
    };

    const changeMarkerStyle = (featureGroup, layerlist, markerName) => {
      // #DEBUG - color_change
      // console.log(`123SIMPLE changeMarkerStyle featureGroup`, featureGroup);
      // console.log(`123SIMPLE this.layerlistFireflies`, this.layerlistFireflies);

      const currentZoom = this.map.getZoom();
      featureGroup.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          const id = layer?.feature?.properties?.id;
          const position = layer?.feature?.properties?.position || 0;
          const color =
            layer?.feature?.properties?.light?.color?.status || "grey";

          const newColor = { stroke: color, fill: color, text: position };

          //#DEBUG - color_change
          // console.log(
          //   `123SIMPLE layer?.feature?.properties?.`,
          //   color,
          //   newColor,
          //   layer?.feature?.properties
          // );
          switch (markerName) {
            case "typicalNumberedMapMarker":
              layer.setIcon(
                makeIcon(
                  "TypicalNumberedMapMarker",
                  newColor, // hack position into color object for numbered markers
                  currentZoom
                )
              );
              break;
            case "dummyRoundMarker":
              layer.setIcon(
                makeIcon(
                  "RoundMarker",
                  {
                    stroke: "purple",
                    fill: "purple",
                    text: "",
                  }, // hack position into color object for numbered markers
                  currentZoom
                )
              );
              break;
            default:
              break;
          }
        }
      });
    };

    const moveMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "move-marker-positions",
          icon: "fas fa-edit",
          title: "Edit FireFly or Controller positions",
          onClick: (control, map) => {
            // lock the move buttons
            mapMoveLockButton.disable();
            moveMarkerPositionsButton.disable();
            saveMarkerPositionsButton.enable();
            cancelMarkerPositionsButton.enable();

            // disable the view buttons
            saveMapViewStateButton.disable();
            setRestoreMapViewStateButton("disable");

            // disable the search button
            searchButton.disable();

            // disable move
            moveMapViewButton.disable();

            let newActiveLayerList = [...this.state.activeLayersList];
            // remove all the active layers
            newActiveLayerList = disableAllActiveLayers(newActiveLayerList);
            this.setState({ activeLayerList: newActiveLayerList });

            // make sure FFs and Controller layers are enabled
            this.geoJSONGroupLayer.addTo(this.map);
            this.geoJSONControllerGroupLayer.addTo(this.map);

            // enable dragging of markers
            enableFeatureGroupDraggable(this.geoJSONGroupLayer, true);
            enableFeatureGroupDraggable(this.geoJSONControllerGroupLayer, true);

            // change the markers to look 'editable'
            // fireflys
            changeMarkerStyle(
              this.geoJSONGroupLayer,
              this.layerlistFireflies,
              "typicalNumberedMapMarker"
            );
            // controllers
            changeMarkerStyle(
              this.geoJSONControllerGroupLayer,
              this.layerlistControllers,
              "typicalNumberedMapMarker"
            );

            // turn off data collection
            const {
              isPokeTheWorker,
              TurnOnOffPokeTheWorker,
              SetMapMarkerDragging,
            } = this.props;

            SetMapMarkerDragging(true);
            // console.log(`debug data on/off isMarkerDragging: true`);
            this.setState({ isMarkerDragging: true });

            if (isPokeTheWorker) {
              // console.log(
              //   `TurnOnOffPokeTheWorker moveMarkerPositionsButton -->`,
              //   false
              // );
              TurnOnOffPokeTheWorker(false);
            }
            this.setState({ isPokeTheWorkerLocal: false });
          },
        },
      ],
    });

    const saveMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "save-marker-positions",
          icon: "fas fa-save",
          title: "Save position changes",
          onClick: (e) => {
            const markerPositionChanges = this.state.markerPositionChanges;

            // console.log(
            //   ` markerPositionChanges markerPositionChanges`,
            //   markerPositionChanges
            // );

            if (!_isEmpty(markerPositionChanges)) {
              const {
                saveFirefly,
                saveUPS,
                transformPixelsToUtm,
                mineLevelId,
              } = this.props;

              let promiseArray = [];

              for (const change in markerPositionChanges) {
                const {
                  id,
                  type,
                  properties,
                  position: changedPosition,
                } = markerPositionChanges[change];

                const objLatLng = transformPixelsToUtm.transform({
                  lat: changedPosition.lat,
                  lng: changedPosition.lng,
                });

                // transform position to UTM
                const newPosition = {
                  mineLevelId: mineLevelId,
                  lat: objLatLng.lat,
                  lng: objLatLng.lng,
                };

                // console.log(
                //   ` markerPositionChanges id, type, properties, newPosition`,
                //   id,
                //   type,
                //   properties,
                //   changedPosition,
                //   newPosition
                // );

                switch (type) {
                  case "firefly":
                    const {
                      id: fireflyId,
                      position: fireflyPosition,
                      location: fireflyLocation,
                      mac: fireflyMac,
                      note: fireflyNote,
                    } = properties;

                    promiseArray.push(
                      new Promise((resolve, reject) => {
                        saveFirefly({
                          values: {
                            id: fireflyId,
                            position: newPosition,
                            fireflyLocation,
                            fireflyPosition,
                            fireflyMac,
                            fireflyNote,
                          },
                          resolve,
                          reject,
                        });
                      })
                    );
                    break;
                  case "controller":
                    const { id: upsId, network_name } = properties;

                    console.log("controller saveUPS properties ", properties);

                    promiseArray.push(
                      new Promise((resolve, reject) => {
                        saveUPS({
                          values: {
                            id: upsId,
                            position: newPosition,
                            name: network_name, // need to save name for DEMO_MODE updates as real message is not available
                          },
                          resolve,
                          reject,
                        });
                      })
                    );
                    break;
                  default:
                    break;
                }
              }

              return Promise.all(promiseArray)
                .then((results) => {
                  console.log("UPDATING CONTROLLER/FIREFLY POSITIONS", results);
                })
                .then(
                  () => {
                    let newActiveLayerList = [...this.state.activeLayersList];
                    // enable all the active layers
                    newActiveLayerList =
                      enableAllActiveLayers(newActiveLayerList);
                    this.setState({ activeLayerList: newActiveLayerList });

                    closeMarkerPositionButtons();
                  },
                  (msg) => {
                    console.log("action failed", msg); // TODO probs should show this?
                  }
                );
            }
            closeMarkerPositionButtons();
          },
        },
      ],
    });

    const closeMarkerPositionButtons = () => {
      // clear all position changes
      this.setState({ markerPositionChanges: {} });
      // disable marker drag
      enableFeatureGroupDraggable(this.geoJSONGroupLayer, false);
      enableFeatureGroupDraggable(this.geoJSONControllerGroupLayer, false);

      let newActiveLayerList = [...this.state.activeLayersList];
      // enable all the active layers
      newActiveLayerList = enableAllActiveLayers(newActiveLayerList);
      this.setState({ activeLayerList: newActiveLayerList });

      // enable the move buttons
      mapMoveLockButton.enable();
      moveMarkerPositionsButton.enable();
      saveMarkerPositionsButton.disable();
      cancelMarkerPositionsButton.disable();

      // enabled the view buttons
      saveMapViewStateButton.enable();
      setRestoreMapViewStateButton("enable");

      // enable the search button
      searchButton.enable();

      // enable move
      moveMapViewButton.enable();

      // turn on data collection
      this.setState({ isPokeTheWorkerLocal: true });
      const { isPokeTheWorker, TurnOnOffPokeTheWorker, SetMapMarkerDragging } =
        this.props;

      SetMapMarkerDragging(false);

      //console.log(`debug data on/off isMarkerDragging: false`);
      this.setState({ isMarkerDragging: false });

      if (!isPokeTheWorker) {
        // console.log(
        //   `TurnOnOffPokeTheWorker closeMarkerPositionButtons -->`,
        //   true
        // );
        TurnOnOffPokeTheWorker(true);
      }
      this.setState({ isPokeTheWorkerLocal: true });

      // force a map refresh to restore marker positions & disable drag
      this.setState({ forceMapRefreshFireflys: true });
      // force a map refresh to restore marker positions & disable drag
      this.setState({ forceMapRefreshControllers: true });
    };

    const cancelMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "cancel-marker-positions",
          icon: "fa fa-times",
          title: "Cancel position changes",
          onClick: (e) => {
            // clear all position changes
            this.setState({ markerPositionChanges: {} });
            // disable dragging of markers
            enableFeatureGroupDraggable(this.geoJSONGroupLayer, false);
            enableFeatureGroupDraggable(
              this.geoJSONControllerGroupLayer,
              false
            );

            let newActiveLayerList = [...this.state.activeLayersList];
            // enable all the active layers
            newActiveLayerList = enableAllActiveLayers(newActiveLayerList);
            this.setState({ activeLayerList: newActiveLayerList });

            // disable the move buttons
            moveMarkerPositionsButton.enable();
            saveMarkerPositionsButton.disable();
            cancelMarkerPositionsButton.disable();

            // enable the view buttons
            saveMapViewStateButton.enable();
            restoreMapViewStateButton.enable();

            // enable the search button
            searchButton.enable();

            // enable move
            moveMapViewButton.enable();

            // turn on data collection
            this.setState({ isPokeTheWorkerLocal: true });
            const {
              isPokeTheWorker,
              TurnOnOffPokeTheWorker,
              SetMapMarkerDragging,
            } = this.props;

            SetMapMarkerDragging(false);
            //console.log(`debug data on/off isMarkerDragging: false`);
            this.setState({ isMarkerDragging: false });

            if (!isPokeTheWorker) {
              // console.log(
              //   `TurnOnOffPokeTheWorker cancelMarkerPositionsButton -->`,
              //   true
              // );
              TurnOnOffPokeTheWorker(true);
            }
            this.setState({ isPokeTheWorkerLocal: true });

            // force a map refresh to restore marker positions & disable drag
            this.setState({ forceMapRefreshFireflys: true });
            // force a map refresh to restore marker positions & disable drag
            this.setState({ forceMapRefreshControllers: true });
          },
        },
      ],
    });

    let editBar = L.easyBar([
      moveMarkerPositionsButton,
      saveMarkerPositionsButton,
      cancelMarkerPositionsButton,
    ]);

    if (this.props.role.allowAdmin) {
      editBar.addTo(this.map);
    }

    // startup hide - save and cancel buttons
    moveMarkerPositionsButton.enable();
    saveMarkerPositionsButton.disable();
    cancelMarkerPositionsButton.disable();

    //----------------------------------------------------
    //
    // MAP MARKER RESET VIEW - CONTROLS
    //
    //----------------------------------------------------
    // #NOTE - with current leaflet need to do action to setView
    // https://stackoverflow.com/questions/65322670/change-center-position-of-react-leaflet-map

    const mapViewRestoreShow = `<i class="fa fa-eye "></i>`;
    const restoreMapViewStateButton = L.easyButton({
      states: [
        {
          stateName: "map-view-restore",
          icon: mapViewRestoreShow,
          title: "Restore saved view",
          onClick: (e) => {
            const savedMapViewState = this.state.savedMapViewState;
            const center = savedMapViewState?.center;
            const zoom = savedMapViewState?.zoom;
            if (Array.isArray(center) && !isNaN(zoom)) {
              this.map.setView({ lat: center[1], lng: center[0] }, zoom, {
                animation: false,
              });
            }
          },
        },
      ],
    });

    const saveMapViewStateButton = L.easyButton({
      states: [
        {
          stateName: "map-view-save",
          icon: "fas fa-save",
          title: "Save view",
          onClick: (e) => {
            // get the current map center and zoom
            const coords = this.map.getCenter();
            const center = Object?.values?.(coords)?.reverse?.();
            const zoom = this.map.getZoom();

            const newMapViewState = {
              id: mineLevel.id,
              center: center,
              zoom: zoom,
            };

            // set the new system saved mapViewState

            const settings = {
              section: "mapViewState",
              key: mineLevel?.id,
              data: { ...newMapViewState },
            };

            this.props.componentstate.set(
              settings.section,
              settings.key,
              settings.data
            );

            this.props.saveUserSettingsComponentState({ settings });

            // this.props.componentstate.set(
            //   "mapViewState",
            //   mineLevel.id,
            //   newMapViewState
            // );
          },
        },
      ],
    });

    let restoreMapViewStateBar = L.easyBar(
      [restoreMapViewStateButton, saveMapViewStateButton],
      {
        position: "topleft",
        id: "horizontaleasybar",
      }
    );

    restoreMapViewStateBar.addTo(this.map);
    // disable the restore button on startup until check restore properties exist
    restoreMapViewStateButton.disable();

    //----------------------------------------------------
    //
    // MAP MARKER REFRESH - CONTROLS
    //
    //----------------------------------------------------

    const refreshMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "reset-marker-positions",
          icon: "fa fa-redo",
          title: "Show all",
          onClick: (e) => {
            // force a map refresh to restore marker positions & disable drag
            this.setState({ forceMapRefreshFireflys: true });
            this.setState({ forceMapRefreshControllers: true });
            //console.log("clicked Refresh Map : bounds", bounds);
            this.map.fitBounds(bounds);
          },
        },
      ],
    });

    let refreshMapBar = L.easyBar([
      //restoreMapViewStateButton,
      refreshMarkerPositionsButton,
    ]);
    refreshMapBar.addTo(this.map);

    //----------------------------------------------------
    //
    // MAP MARKER SEARCH - CONTROLS
    //
    //----------------------------------------------------

    const mapSearchShow = `<i class="fas fa-search "></i>`;
    const mapSearchHide = `<i class="fas fa-search " style="opacity:0.2"></i>`;

    const searchButtonClick = (control, map) => {
      const isNewShowSearchBox = !this.state.isShowSearchBox;

      // toggle state
      this.setState({ isShowSearchBox: isNewShowSearchBox });

      // --------------------------------
      // #WIP #TESTING
      // see - https://gis.stackexchange.com/questions/183872/leaflet-custom-info-control-update-with-extra-parameters
      //
      // Simple custom control to display a list of text
      // -
      // - displays after map move so that data is updated
      // - text list has click eventlistener
      //
      // See - src/components/Map/leaflet-search-control.js
      // -----------------------------------------------

      if (isNewShowSearchBox) {
        searchButton.state("mapSearch-hide");
        // reload control with fresh data set
        // data is drawn from geoJson layers
        let items = [];
        if (this.geoJSONGroupLayer) {
          this.geoJSONGroupLayer.eachLayer((layer) => {
            items.push(layer);
          });
          //console.log("xxx items", items);
        }
        searchControl = new SearchControl({ data: items }).addTo(this.map);
      } else {
        searchButton.state("mapSearch-show");
        searchControl.remove();
      }
    };

    const searchButton = L.easyButton({
      states: [
        {
          stateName: "mapSearch-show",
          icon: mapSearchShow,
          title: "Search for FireFlys",
          onClick: searchButtonClick,
        },
        {
          stateName: "mapSearch-hide",
          icon: mapSearchHide,
          title: "Hide Search",
          onClick: searchButtonClick,
        },
      ],
    });

    let searchBar = L.easyBar([searchButton]);
    searchBar.addTo(this.map);

    //----------------------------------------------------
    //
    //
    //
    //----------------------------------------------------

    const setRestoreMapViewStateButton = (action) => {
      // action = enable / disable

      switch (action) {
        case "enable":
          // if the savedMapViewState has a center and zoom then
          if (
            this.state?.savedMapViewState?.center &&
            this.state?.savedMapViewState?.zoom
          ) {
            // enable the restore button
            restoreMapViewStateButton.enable();
          }
          break;
        case "disable":
          restoreMapViewStateButton.disable();
          break;

        default:
          break;
      }
    };

    //----------------------------------------------------
    //
    // MAP EVENTS
    //
    //----------------------------------------------------

    // map manipulation and moving events
    const mapStartMoving = () => {
      this.props.SetMapMoving(true);
      this.setState({ isMoving: true });

      // console.log(
      //   `debug data on/off mapStartMoving this.state.isMarkerDragging `,
      //   this.state.isMarkerDragging
      // );

      if (!this.state.isMarkerDragging) {
        this.setState({ isPokeTheWorkerLocal: false });

        const { isPokeTheWorker } = this.props;
        if (isPokeTheWorker) {
          //console.log(`TurnOnOffPokeTheWorker mapStartMoving -->`, false);
          // turn off data collection
          this.props.TurnOnOffPokeTheWorker(false);
        }
      }
    };

    const mapStopMoving = () => {
      this.props.SetMapMoving(false);
      this.setState({ isMoving: false });

      // console.log(
      //   `nextState. debug data on/off mapStopMoving this.state.isMarkerDragging `,
      //   this.state.isMarkerDragging
      // );

      if (!this.state.isMarkerDragging) {
        this.setState({ isPokeTheWorkerLocal: true });
        const { isPokeTheWorker } = this.props;
        if (!isPokeTheWorker) {
          //console.log(`TurnOnOffPokeTheWorker mapStopMoving -->`, true);
          // turn on data collection
          this.props.TurnOnOffPokeTheWorker(true);
        }
      }
    };

    // #WIP
    // if mousedown/movestart on map while pan tool is inactive advise user
    // that need to enable pan tool for mouse
    // this.map.on("mousedown movestart", function () {
    //   alert("mousedown");
    // });

    //disable_simple
    // if implement 'pan' tool then no longer need this to start/stop
    if (false) {
      // fires when map starts moving: dragging map, zooming etc.
      this.map.on("movestart zoomstart ", mapStartMoving); // mousedown?

      // fires when map is moving
      this.map.on("move", (e) => {
        //console.log(`COLLECTING DATA ... move  `);

        this.props.SetMapMoving(true);

        const { isPokeTheWorker } = this.props;
        // reinforce turning off data collection!
        // this should never happen....but....
        if (isPokeTheWorker) {
          // turn off data collection
          this.props.TurnOnOffPokeTheWorker(false);
        }
        this.setState({ isPokeTheWorkerLocal: false });
      });

      // fires when map stops moving: dragging map, zooming etc.
      this.map.on("moveend zoomend ", mapStopMoving); // mouseup?
    }
    // *****************************************************************************************
    // #REVIEW - setup ordering layers for all levels with toggle
    // setup layer control events
    // https://stackoverflow.com/questions/14103489/leaflet-layer-control-events
    this.map.on("overlayadd", onOverlayAdd);

    function onOverlayAdd(e) {
      // https://gis.stackexchange.com/questions/137061/how-to-change-layer-order-in-leaflet-js
      // push layer to back layer.bringToFront()

      if (e.name === "Polygon") {
        e.layer.bringToBack();
      }
      //console.log("onOverlayAdd e", e);
    }

    //----------------------------------------------------
    //
    // MAP EVENTS (more :-)
    //
    //----------------------------------------------------

    // This manages the size of the marker icons after the map has zoomed
    const zoomend = () => {
      const currentZoom = this.map.getZoom();
      console.log("zoomend currentZoom", currentZoom);

      // Tracking whether zooming in or out
      // only update icon if size has changed
      let diffZoom = this.previousZoom - currentZoom;

      // diffZoom > 0 - console.log("zoomend zoomed out");
      // iffZoom < 0 - console.log("zoomend zoomed in");
      if (diffZoom !== 0) {
        const firefliesIdInLayer = Object.keys(this.layerlistFireflies);
        firefliesIdInLayer.forEach((fireflyId, idx) => {
          const iconOptions =
            this.layerlistFireflies[fireflyId].getIcon().options;
          const { iconName, iconSize, iconColor } = iconOptions;

          // only update icon if size has changed

          this.layerlistFireflies[fireflyId].setIcon(
            makeIcon(iconName, iconColor, currentZoom)
          );
        });
      }

      this.previousZoom = currentZoom;
    };

    this.map.on("zoomend", zoomend);

    const moveend = () => {
      // do nothing
    };
    this.map.on("moveend", moveend);

    // add 'dummy' map click event to maintain the buttonStates
    this.map.on("click", (e) => {
      // e.latLng is undefined then the 'click' event has been fired by this.map.fire() elsewhere
      if (e.latLng === undefined) {
        const { isMarkerDragging, savedMapViewState } = this.state;

        // if map is stationary (no drag etc)
        if (!isMarkerDragging) {
          // if the savedMapViewState has a center and zoom then
          if (savedMapViewState?.center && savedMapViewState?.zoom) {
            // enable the restore button
            restoreMapViewStateButton.enable();
          }
        }
      }
    });

    // initialise the map view state on map load
    this.setState({ savedMapViewState: this.props.savedMapViewState }, () =>
      this.map.fire("click")
    );

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++
    //
    // Finally! map drawn!!!!!!!!
    //
    // ++++++++++++++++++++++++++++++++++++++++++++++
  };

  //----------------------------------------------------
  //
  //
  //----------------------------------------------------

  convertGeoJsonPixelsToUtm = (geoJsonPixels, mineLevel) => {
    // Output coordinates of shape in UTM
    // returns geoJSON

    // convert pixel coordinates in geoJson Pixels to utm
    // deep clone - to stop geometry being converted on raw *utm data
    geoJsonPixels = JSON.parse(JSON.stringify(geoJsonPixels));
    const geoJsonUtm = transformGeoJsonPixelsToUtm(geoJsonPixels, mineLevel);

    // #TODO - NOTE: 'drawnItemsToJSON' below strips out the properties!
    // - needs to be updated to preserve PROPERTIES
    // - do not use until fixed

    // preserve styling of polygon in geoJson
    //
    //geoJson = this.drawnItemsToJSON(this.editableLayers);
    return geoJsonUtm;
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { mineLevel, isPokeTheWorker } = nextProps;
    const {
      isPokeTheWorkerLocal,
      isOpenFireflyMarkerPopupModal,
      isOpenControllerMarkerPopupModal,
      isMarkerDragging,
    } = nextState;

    // console.log(`nextState. ........`);
    // console.log(`nextState.isPokeTheWorkerLocal`, isPokeTheWorkerLocal);
    // console.log(`nextState.isMarkerDragging`, isMarkerDragging);

    if (
      mineLevel === undefined ||
      // if not collecting data and the modal popup is triggered then re-render component
      (!isPokeTheWorkerLocal &&
        isOpenFireflyMarkerPopupModal.active === false &&
        isOpenControllerMarkerPopupModal.active === false)
    ) {
      return false;
    } else {
      return true;
    }
  }

  ctrlKeyDown = (e) => {
    if (e.keyCode === 17) {
      this.setState({ ctrlKey: true });
    }
  };

  ctrlKeyUp = (e) => {
    if (e.keyCode === 17) {
      this.setState({ ctrlKey: false });
    }
  };

  componentDidMount() {
    const { mineLevel, savedMapViewState } = this.props;

    this.setState({ isTagTracking: isTagTracking() });
    document.addEventListener("keydown", this.ctrlKeyDown, false);
    document.addEventListener("keyup", this.ctrlKeyUp, false);

    if (!_isEmpty(mineLevel)) {
      this.setState({ map: { area: mineLevel?.id } });

      this.setState({ isMapComponentDidMount: true });

      if (!_isEmpty(savedMapViewState)) {
        // wait for state change before mounting the map
        this.setState({ mapViewState: savedMapViewState }, () => {
          //console.log("newMapViewState updated");
          this.mapMount();
        });
      } else {
        this.mapMount();
      }
    } else {
      // #NOTE - this should never happen, but on rapid reload of the page
      // I experienced mineLevel undefined.

      console.log(`LOAD ERROR: AREA IS EMPTY `, mineLevel);
    }
  }

  componentWillUnmount() {
    const { mineLevel } = this.props;

    document.removeEventListener("keydown", this.ctrlKeyDown, false);
    document.removeEventListener("keyup", this.ctrlKeyUp, false);

    // clear out map
    if (this.map && this.map.remove) {
      this.map.off();
      this.map.remove();
    }
  }

  mapMount = () => {
    const { render } = this.props;

    const mapId = "map-" + render;

    const { mineLevel } = this.props;

    const {
      geoJSONMarkersDataUtm,
      geoJSONNamedAreasUtm,
      geoJSONControllersUtm,
    } = this.props;

    let geoJSONMarkersData;
    let geoJSONControllers;

    // ***************************************************************
    //
    // Update fireflies geoJson
    //
    // ***************************************************************

    if (
      typeof geoJSONMarkersDataUtm.features !== "undefined" &&
      geoJSONMarkersDataUtm.features.length
    ) {
      // #REVIEW - this now happens in WebWorker reducer for fireflies
      // deep clone - to stop geometry being converted on raw *utm data
      //geoJSONMarkersData = JSON.parse(JSON.stringify(geoJSONMarkersDataUtm));
      //geoJSONMarkersData = this.transformGeoJsonUtmToPixels(geoJSONMarkersData);

      geoJSONMarkersData = geoJSONMarkersDataUtm;
    } else {
      // add an empty geoJson featurecollection
      geoJSONMarkersData = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // Update Controllers geoJson
    //
    // ***************************************************************

    if (
      typeof geoJSONControllersUtm.features !== "undefined" &&
      geoJSONControllersUtm.features.length
    ) {
      geoJSONControllers = geoJSONControllersUtm;
    } else {
      // add an empty geoJson featurecollection
      geoJSONControllers = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // Update named area geoJson
    //
    // ***************************************************************

    let geoJSONNamedAreas;

    if (
      typeof geoJSONNamedAreasUtm.features !== "undefined" &&
      geoJSONNamedAreasUtm.features.length
    ) {
      // deep clone - to stop geometry being converted on raw *utm data
      geoJSONNamedAreas = JSON.parse(JSON.stringify(geoJSONNamedAreasUtm));

      const mineLevel = this.props.mineLevel;
      const mineLevelKey = mineLevel.id;
      let mineLevelObj = {};
      mineLevelObj[mineLevelKey] = { ...mineLevel };

      geoJSONNamedAreas = transformGeoJsonUtmToPixels(
        geoJSONNamedAreas,
        mineLevelObj,
        1,
        false
      );
    } else {
      // add an empty geoJson featurecollection
      geoJSONNamedAreas = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // draw the map
    //
    // ***************************************************************

    this.drawTheMap(
      mapId,
      mineLevel,
      geoJSONMarkersData,
      geoJSONNamedAreas,
      geoJSONControllers
    );
  };

  // disable data collection while map is moving
  mapStartMoving = () => {
    this.props.SetMapMoving(true);
    this.setState({ isMoving: true });

    // if isMarkerDragging is false do not turn off data collection
    if (!this.state.isMarkerDragging) {
      this.setState({ isPokeTheWorkerLocal: false });

      const { isPokeTheWorker } = this.props;
      if (isPokeTheWorker) {
        //console.log(`TurnOnOffPokeTheWorker mapStopMoving -->`, false);
        // turn off data collection
        this.props.TurnOnOffPokeTheWorker(false);
      }
    }
  };

  // enable data collection while map is moving
  mapStopMoving = () => {
    this.props.SetMapMoving(false);
    this.setState({ isMoving: false });

    // if isMarkerDragging is false do not turn off data collection
    if (!this.state.isMarkerDragging) {
      this.setState({ isPokeTheWorkerLocal: true });
      const { isPokeTheWorker } = this.props;
      if (!isPokeTheWorker) {
        //console.log(`TurnOnOffPokeTheWorker mapStopMoving -->`, true);
        // turn on data collection
        this.props.TurnOnOffPokeTheWorker(true);
      }
    }
  };

  disableDataCollect = () => {
    this.props.SetMapMoving(true);
    this.setState({ isMoving: true });

    this.setState({ isPokeTheWorkerLocal: false });
    const { isPokeTheWorker } = this.props;
    if (isPokeTheWorker) {
      //console.log(`TurnOnOffPokeTheWorker disableDataCollect -->`, false);
      // turn off data collection
      this.props.TurnOnOffPokeTheWorker(false);
    }
  };

  enableDataCollect = () => {
    this.props.SetMapMoving(false);
    this.setState({ isMoving: false });

    this.setState({ isPokeTheWorkerLocal: true });
    const { isPokeTheWorker } = this.props;
    if (!isPokeTheWorker) {
      //console.log(`TurnOnOffPokeTheWorker enableDataCollect -->`, true);
      // turn on data collection
      this.props.TurnOnOffPokeTheWorker(true);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // check if the saved map view state has changed
    if (
      JSON.stringify(this.props.savedMapViewState) !==
      JSON.stringify(prevProps.savedMapViewState)
    ) {
      //console.log("savedMapViewState componentDidUpdate this.state changed");
      this.setState({ savedMapViewState: this.props.savedMapViewState }, () =>
        this.map.fire("click")
      );
    }

    // check if marker is clicked to show popup, and the area is the same.
    // If so, do not re-draw the map layers, just return to re-render and pop the dlg
    // #Note - if the FF or Controller Popup is open, or the modal dlg is open,
    // then don't process any updates.
    if (
      this.state?.isPopupOpen === true ||
      this.state?.isOpenFireflyMarkerPopupModal.active === true
    ) {
      return;
    }

    // #NOTE - keep in mind they there could be multiple maps Areas loaded. Each as a state.

    // check if map is now visible
    if (prevProps.showMap !== this.props.showMap && this.props.showMap) {
      // reinitialise map
      // see - https://gis.stackexchange.com/questions/289975/toggling-leaflet-map-on-click
      this.map.invalidateSize();
    }

    const { mineLevel } = this.props;

    // if the area (mine level) has changed, remove the map and remount it all again
    if (
      prevProps.mineLevelId !== this.props.mineLevelId &&
      !_isEmpty(mineLevel)
    ) {
      if (this.map && this.map.remove) {
        this.map.off();
        this.map.remove();
      }

      // console.log("hhh mineLevel", mineLevel);

      this.setState({ map: { area: mineLevel.id } });
      // reset map load state pending reload of area background image

      // console.log("loaded isMapLoading", true);
      this.setState({ isMapLoading: true });

      // set saved map view state to local state when initialised
      const newMapViewState = this.props.savedMapViewState;

      if (!_isEmpty(newMapViewState)) {
        this.setState({ mapViewState: newMapViewState });
      }

      this.mapMount();
    }

    // delay update until background map image has loaded
    if (this.state.isMapLoading) {
      console.log(
        `WARNING.....Map is loading...updates are not rendering!!!!!!!!!`
      );
      return;
    }

    // ****************************************************************************************
    //
    // Update Firefly markers
    //
    // ****************************************************************************************
    //

    if (
      !_isEmpty(this.props.geoJSONMarkersDataUtm) && //#WIP - disabled to test rendering update for lightingplan changes
      this.props.geoJSONMarkersDataUtm.features && // features propertie exists but does not have to have any FFs
      // #NOTE - ATM this state is not used (i.e. not set). Leave in place for #REVIEW
      !this.state.isPopupOpen && // don't update if a marker popup is open, because redraw clobber eventListener is open dlg
      (this.props.fireflyIdsUpdateList.length > 0 ||
        this.state.forceMapRefreshFireflys)
    ) {
      // console.log(
      //   `123simple this.props.fireflyIdsUpdateList`,
      //   this.props.fireflyIdsUpdateList
      // );

      if (this.state.forceMapRefreshFireflys)
        console.log(`------------> FORCED REFRESH - FireFly markers`);

      // keep a copy of the previous state
      // #REVIEW #TODO - is this necesary since we have already compared at the reducer?
      let prevGeoJSONMarkersData = {};

      // deep clone - to stop geometry being converted on raw *utm data
      let geoJSONMarkersData = JSON.parse(
        JSON.stringify(this.props.geoJSONMarkersDataUtm)
      );

      //
      // update the fireflies.

      // make a local copy
      let newFireflyIdsUpdateList = [...this.props.fireflyIdsUpdateList];

      // check for deleted FFs
      if (
        !_isEmpty(this.props.fireflyIdsUpdateList) &&
        !_isEmpty(this.props.fireflyIdsDeleteList)
      ) {
        // check if there are any FFs for deletion and if so delete it from
        // the firefly layers - this.layerlistFireflies[id]
        if (this.props.fireflyIdsDeleteList.length > 0) {
          let newLayerListFireflies = {};
          let updatedFireflyIds = [];

          for (const [key, value] of Object.entries(this.layerlistFireflies)) {
            if (this.props.fireflyIdsDeleteList.includes(key)) {
              this.map.removeLayer(value);
              // if we delete the ID don't update it
              newFireflyIdsUpdateList = newFireflyIdsUpdateList.filter(
                (ff) => ff === key
              );

              // remove FF Id from fireflyIdsUpdateList after it's been processed
              //
              updatedFireflyIds.push(key);
            } else {
              newLayerListFireflies[key] = value;
            }
          }

          // console.log(
          //   `map xxx fireflyIdsUpdateList updatedFireflyIds`,
          //   updatedFireflyIds
          // );

          // remove FF Id from fireflyIdsUpdateList
          this.props.fireflyIdsUpdateListDeleteId(updatedFireflyIds);
          this.layerlistFireflies = newLayerListFireflies;
        }
      }

      // check - if there are firefly for update but the layer does not exist,
      // which can happen if fireflies have been deleted from the controller
      // or firefly page, then just remove these from the update list.

      // console.log(`map fireflyIdsUpdateList -----------------------------`);

      // console.log(
      //   `map fireflyIdsUpdateList this.props.fireflyIdsUpdateList`,
      //   this.props.fireflyIdsUpdateList
      // );

      // console.log(
      //   `map fireflyIdsUpdateList this.props.fireflyIdsDeleteList`,
      //   this.props.fireflyIdsDeleteList
      // );

      //

      let currentLayerlistFireflies = [];

      for (const [key, value] of Object.entries(this.layerlistFireflies)) {
        currentLayerlistFireflies.push(key);
        // console.log(`map fireflyIdsUpdateList layerlistFireflies key`, key);
      }

      // console.log(
      //   `map fireflyIdsUpdateList newFireflyIdsUpdateList`,
      //   newFireflyIdsUpdateList
      // );

      let newFireflyIdsUpdateListInCurrentLayers = [];
      let newFireflyIdsUpdateListNotInCurrentLayers = [];

      newFireflyIdsUpdateList.forEach((id) => {
        // if the current layer list of fireflies does not include the update id,
        // remove it from the update list as there is no point in updating a layer which
        // does not exist
        if (!currentLayerlistFireflies?.includes(id)) {
          // is not a current layer
          newFireflyIdsUpdateListNotInCurrentLayers.push(id);
        } else {
          newFireflyIdsUpdateListInCurrentLayers.push(id);
        }
      });

      if (newFireflyIdsUpdateListNotInCurrentLayers.length) {
        this.props.fireflyIdsUpdateListDeleteId(
          newFireflyIdsUpdateListNotInCurrentLayers
        );
      }
      // console.log(`map fireflyIdsUpdateList -----------------------------`);

      // now manage the FF updates - this.geoJSONMarkersData
      if (
        !_isEmpty(this.props.fireflyIdsUpdateList) ||
        this.state.forceMapRefreshFireflys
      ) {
        // console.log(
        //   `123simple updatedFireflyIds fireflyIdsUpdateList, newFireflyIdsUpdateList`,
        //   this.props.fireflyIdsUpdateList,
        //   newFireflyIdsUpdateList
        // );

        // update markers for geoJSON
        this.updateFireflyMarkersGeoJSON(
          this.map,
          geoJSONMarkersData,
          prevGeoJSONMarkersData,
          this.props.mineLevelId,
          newFireflyIdsUpdateListInCurrentLayers, //newFireflyIdsUpdateList,
          this.state.forceMapRefreshFireflys
        );
      }

      // if doing forced map refresh reset flag
      if (this.state.forceMapRefreshFireflys) {
        this.setState({ forceMapRefreshFireflys: false });
      }
    }

    // ****************************************************************************************
    //
    // Update controller markers
    //
    // ****************************************************************************************

    if (
      (!_isEmpty(this.props.geoJSONControllersUtm) &&
        !_isEmpty(this.props.geoJSONControllersUtm?.features) &&
        this.props.geoJSONControllersUtm !== prevProps.geoJSONControllersUtm &&
        // #WIP?????????????????????????
        // is this really why???????//
        !this.state.isPopupOpen) || // don't update if a marker popup is open, because redraw clobber eventListener is open dlg
      this.state.forceMapRefreshControllers
    ) {
      if (this.state.forceMapRefreshControllers)
        console.log(`------------> FORCED REFRESH - Controller Markers`);

      // keep a copy of the previous state
      let prevGeoJSONControllers = {};
      if (prevProps.geoJSONControllersUtm !== undefined) {
        prevGeoJSONControllers = JSON.parse(
          JSON.stringify(prevProps.geoJSONControllersUtm)
        );
      }

      // deep clone - to stop geometry being converted on raw *utm data
      let geoJSONControllers = JSON.parse(
        JSON.stringify(this.props.geoJSONControllersUtm)
      );

      //update markers for geoJSON
      this.updateControllerMarkersGeoJSON(
        this.map,
        geoJSONControllers,
        prevGeoJSONControllers,
        this.props.mineLevelId,
        this.state.forceMapRefreshControllers
      );

      // if doing forced map refresh reset flag
      if (this.state.forceMapRefreshControllers) {
        this.setState({ forceMapRefreshControllers: false });
      }
    }

    // ****************************************************************************************
    //
    // update named area polygons
    //
    // ****************************************************************************************

    // #NOTE - geoJSONNamedAreasUtm can exist but .features could be empty if no polygons are displayed
    if (
      !_isEmpty(this.props.geoJSONNamedAreasUtm) &&
      JSON.stringify(this.props.geoJSONNamedAreasUtm) !==
        JSON.stringify(prevProps.geoJSONNamedAreasUtm)
    ) {
      // console.log(
      //   "xxx updateNamedAreasGeoJSON this.props.geoJSONNamedAreasUtm",
      //   this.props.geoJSONNamedAreasUtm
      // );
      // console.log(
      //   "xxx updateNamedAreasGeoJSON prevProps.geoJSONNamedAreasUtm",
      //   prevProps.geoJSONNamedAreasUtm
      // );

      // deep clone - to stop geometry being converted on raw *utm data
      let geoJSONNamedAreas = JSON.parse(
        JSON.stringify(this.props.geoJSONNamedAreasUtm)
      );

      const mineLevel = this.props.mineLevel;
      const mineLevelKey = mineLevel.id;
      let mineLevelObj = {};
      mineLevelObj[mineLevelKey] = { ...mineLevel };

      // transform the coordinates to pixel image references
      geoJSONNamedAreas = transformGeoJsonUtmToPixels(
        geoJSONNamedAreas,
        mineLevelObj,
        1,
        false
      );

      this.updateNamedAreasGeoJSON(
        this.map,
        geoJSONNamedAreas,
        this.props.mineLevelId
      );

      // if doing forced map refresh reset flag
      if (this.state.forceMapRefreshControllers) {
        this.setState({ forceMapRefreshControllers: false });
      }
    }
  }

  // If the FireFlys are changed the visual changes (on the main map screen) are:
  // * POSITION  - updated with setLatLng()
  // * LIGHT light characteristics - e.g. color, state, event_id (used for tracking forced lights)
  // * ICON the icon drawn - update here with setIcon()
  // * FORCED - keep track of lights which have been forced

  updateFireflyMarkersGeoJSON(
    map,
    geoJSONMarkersData,
    prevGeoJSONMarkersData,
    mineLevelId,
    fireflyIdsUpdateList,
    forceUpdate
  ) {
    // tracks fireflies which have been updated. These should be deleted from the list.
    let updatedFireflyIds = [];
    // update....
    geoJSONMarkersData.features.map((feature, idx) => {
      const { properties } = feature;
      const { id } = properties;

      // if the layer exist for the id
      if (this.layerlistFireflies[id]) {
        // if it is has had a change or there is a forced update
        if (fireflyIdsUpdateList.includes(id) || forceUpdate) {
          // ...update it.

          // accumulate a list of updated fireflys as it is being processed
          updatedFireflyIds.push(id);

          //
          // update the POSITION
          const { geometry } = feature;
          const { light, timestamp } = properties;

          // coord geom is reversed
          const X = round(geometry.coordinates[0], 2);
          const Y = round(geometry.coordinates[1], 2);
          var newLatLng = new L.LatLng(Y, X);
          this.layerlistFireflies[id].setLatLng(newLatLng);

          // update the ICON
          const { svgIcon, svgStyle } = markerDefinition(feature);
          const currentZoom = this.map.getZoom();
          this.layerlistFireflies[id].setIcon(
            makeIcon(svgIcon, svgStyle, currentZoom)
          );

          // update the LIGHT information
          this.layerlistFireflies[id].feature.properties.light = light;

          // update the FORCED information from any recent updates to event_id
          if (light?.event_id?.includes("FORCED")) {
            this.layerlistFireflies[id].feature.properties.forced = true;
          }

          //
        }

        //??????????????????????
      } else if (feature.properties.area === mineLevelId) {
        //console.log("FF_UPDATE -> ADD TO LAYER LIST ...", id);
        // if it's not in the layer list, and is in the area i.e. `mineLevelId` add it..........
        //
        // Note - pointToLayer and onEachFeature are defined in ComponentDidMount() and
        // .addData() runs `onEachFeatureFirefly` which adds the feature to layerlistFireflies[]

        this.geoJSONGroupLayer.addData(geoJSONMarkersData.features[idx]);
      }
    });

    //console.log(`xx8xx updatedFireflyIds`, updatedFireflyIds);

    if (updatedFireflyIds.length) {
      // after processing the list send a delete processed items from the update list
      this.props.fireflyIdsUpdateListDeleteId(updatedFireflyIds);
    }
  }

  updateControllerMarkersGeoJSON(
    map,
    geoJSONControllers,
    prevJSONControllers,
    mineLevelId,
    forceUpdate
  ) {
    // map the geoJSONControllers and delete all the markers who have changed propertie
    // and accumulate these markers and
    // add them all at the end

    // convert previous marker data to keyed object
    let prevMarkerData = {};
    if (prevJSONControllers?.features !== undefined) {
      prevJSONControllers.features.map((feature, idx) => {
        const {
          properties: { id },
        } = feature;
        prevMarkerData[id] = feature;
      });
    }

    // find the fireflies which have changed in geojsonMarkerOptions object
    geoJSONControllers.features.map((feature, idx) => {
      const { properties } = feature;
      const {
        id,
        area,
        location,
        position,
        easting,
        northing,
        utm_zone_number,
        utm_zone_letter,
        z,
        color,
        timestamp,
        mode,
        master,
        //
        deviceStatus,
        user_relay,
      } = properties;

      let isChanged = !_isEqual(
        omitDeep(prevMarkerData[id], ["timestamp"]),
        omitDeep(feature, ["timestamp"])
      );

      // console.log(`MARKER_REDRAW isChanged`, isChanged);
      // if (isChanged) {
      //   console.log(`MARKER_REDRAW prevMarkerData[id]`, prevMarkerData[id]);
      //   console.log(`MARKER_REDRAW feature`, feature);
      // }

      const lastStatusReport =
        timestamp !== undefined
          ? formatRelative(parseISO(timestamp), new Date(), {
              includeSeconds: true,
            })
          : "-";
      const { geometry } = feature;

      // coord geom is reversed
      const X = round(geometry.coordinates[0], 2);
      const Y = round(geometry.coordinates[1], 2);

      // status checks
      let status = "OK"; // lets assume everything is OK. Ever the optimist eh?!

      if (deviceStatus.includes(StatusEnum.API_FETCH)) {
        status = "API_FETCH";
      }

      if (
        deviceStatus.includes(StatusEnum.NO_STATUS_REPORT) ||
        deviceStatus.includes(StatusEnum.TIMEOUT)
      ) {
        status = "INACTIVE_CONTROLLER";
      }

      const isSirenActive = isKthBitSet(user_relay, 1);
      if (isSirenActive) {
        status = "RELAY_SIREN";
      }

      // _#DEMO_MODE - force regular updates irrespective of real changes
      if (!isSirenActive && isDemoMode()) {
        status = "OK";
      }

      // console.log(`Controller MARKER_REDRAW id`, id);
      // console.log(
      //   `Controller MARKER_REDRAW this.layerlistControllers[id]`,
      //   this.layerlistControllers[id]
      // );
      // console.log(`Controller MARKER_REDRAW isChanged`, isChanged);
      // console.log(`Controller MARKER_REDRAW status !== "OK"`, status !== "OK");
      // console.log(`Controller MARKER_REDRAW forceUpdate`, forceUpdate);
      // console.log(`Controller MARKER_REDRAW -------------`);

      if (this.layerlistControllers[id]) {
        if (isChanged || status !== "OK" || forceUpdate) {
          //console.log(`xxx Controller MARKER_REDRAW forceUpdate`, forceUpdate);

          // controller color is set but whether there are faults and mode settings
          let upsFaultmsg = this.props.faults;

          //console.log("upsFaultmsg", upsFaultmsg);
          const isUpsNetworkFault = upsFaultmsg.some(
            (fault) =>
              fault.type === "network" &&
              fault.active &&
              fault.id === id &&
              fault.area === area
          );

          // #Note - this is probably overkill to detect battery ON for controller
          // but making sure!
          const isUpsBatteryOnFault = upsFaultmsg.some(
            (fault) =>
              fault.type === "battery" &&
              fault.active &&
              fault.subtype === "charge" &&
              fault.description.includes("ON") &&
              fault.id === id &&
              fault.area === area
          );

          // ####SVG Marker###
          // default
          let svgIcon = "SquareMarker";
          let svgStyle = { stroke: "grey", fill: "grey" };

          // CONTROLLER UPS MODE SETTINGS
          // * isInCommission
          // * isMaster
          const isInCommission = mode.toLowerCase() === "commission";
          const isMaster = master ? true : false;

          // isInCommission - change icon
          isInCommission
            ? (svgIcon = "StarMarker")
            : (svgIcon = "SquareMarker");

          // isMaster - change color
          switch (isMaster) {
            case true:
            default:
              svgStyle = { stroke: "blue", fill: "blue" };
              break;
            case false:
              svgStyle = { stroke: "brown", fill: "brown" };
              break;
          }

          // CONTROLLER UPS FAULT SETTINGS
          // * isUpsNetworkFault
          // * isUpsBatteryOnFault
          // override color if network fault &/or battery fault
          // ups icon normal = blue/blue
          // network fault = outline red, fill blue, SquareMarker
          // battery fault = outline red, fill orange, DiamondMarker

          if (isUpsNetworkFault) {
            if (isUpsBatteryOnFault) {
              svgIcon = "DiamondMarker";
              svgStyle = { stroke: "red", fill: "orange" };
            } else {
              svgIcon = "SquareMarker";
              svgStyle = { stroke: "blue", fill: "red" };
            }
          } else {
            if (isUpsBatteryOnFault) {
              svgIcon = "DiamondMarker";
              svgStyle = { stroke: "blue", fill: "orange" };
            }
          }

          // #NOTE
          // #WIP
          // Status switch is used for firefly. The checks above should be moved
          // into the case below.

          switch (status) {
            case "RELAY_SIREN":
              svgIcon = "RelaySirenMarker";
              break;
            case "API_FETCH":
              svgIcon = "TriangleMarker";
              break;

            case "INACTIVE_CONTROLLER": // aka UPS_TIMEOUT
              svgStyle = { stroke: "fuchsia", fill: "black" };
              svgIcon = "HourGlassMarker";
              break;
            default:
              break;
          }

          const currentZoom = this.map.getZoom();

          this.layerlistControllers[id].setIcon(
            makeIcon(svgIcon, svgStyle, currentZoom)
          );
        }
      } else if (properties.area === mineLevelId) {
        // if it's not in the layer list, and is in the area i.e. `mineLevelId` add it..........
        //
        // Note - pointToLayer and onEachFeature are defined in ComponentDidMount() and
        // .addData() runs `onEachFeatureController` which adds the feature to layerlistControllers[]
        this.geoJSONControllerGroupLayer.addData(
          geoJSONControllers.features[idx]
        );
      }
    });
  }

  updateNamedAreasGeoJSON(map, geoJSONPolygonData, mineLevelId) {
    // #TODO - !
    // map the geoJSONPolygonData and delete all the shapes who have changed propertie
    // and accumulate these shapes
    // add them all at the end

    // check if a named area has been removed
    // get list of IDs for current named area (i.e. layers)
    const layersIds = Object.keys(this.layerlistNamedAreas);

    // get the Ids for the incoming update of named area data
    let namedAreaIds = [];
    for (const namedArea in geoJSONPolygonData.features) {
      namedAreaIds.push(geoJSONPolygonData.features[namedArea].properties.id);
    }

    // check for a difference
    // filter each item and return it if it is *not* in the namedAreas
    const inLayerNotInNamedArea = layersIds.filter(
      (i) => !namedAreaIds.includes(i)
    );

    inLayerNotInNamedArea.forEach((layer, idx) => {
      // delete any layers which are in layersIds but *not* in namedAreaIds
      // remove the layer from layer groups
      this.geoJSONNamedAreaGroupLayer.removeLayer(
        this.layerlistNamedAreas[layer]
      );
      // to be really sure remove it from the map completely
      this.map.removeLayer(this.layerlistNamedAreas[layer]);
      // delete the layer from the list
      delete this.layerlistNamedAreas[layer];
    });

    if (true) {
      // find the named areas which have changed in geoJSONPolygonData object
      geoJSONPolygonData.features.map(({ properties }, idx) => {
        const {
          id,
          area,
          parent,
          type,
          name,
          parent_name,
          sub_type = "region",
          style,
        } = properties;

        //console.log("xxx >>> geoJSONPolygonData properties", properties);

        // if the shape is not in the layer list
        if (!this.layerlistNamedAreas[id]) {
          // add it
          // note pointToLayer and onEachFeature are defined in ComponentDidMount() <--- #REVIEW - is it? i.e. for namedArea?
          this.geoJSONNamedAreaGroupLayer.addData(
            geoJSONPolygonData.features[idx]
          );
        } else {
          // if the shape exists but has changed, update it

          // #WIP - should also review updates to shape coordinates

          // AFAIK the only way to update is to go through every layer until finding the match!
          // thankfully there are not many layers for namedAreas
          this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
            if (layer === this.layerlistNamedAreas[id]) {
              layer.feature.properties = properties;
            }
          });
        }
      });
    }
  }

  resetModalFirefly = () => {
    this.setState({
      isOpenFireflyMarkerPopupModal: { active: false, id: "", area: "" },
    });
  };

  resetModalController = () => {
    this.setState({
      isOpenControllerMarkerPopupModal: { active: false, id: "", area: "" },
    });
  };

  render() {
    const { render, showMap, mineLevel = {} } = this.props;
    const { isMapLoading } = this.state;

    const mapId = "map-" + render;

    const { legend: isShowLegend } = this.props.namedAreaDisplaySettings;

    const visibility =
      showMap !== true ? { display: "none" } : { display: "block" };

    const showLegend = isShowLegend
      ? undefined
      : { minHeight: "calc(95vh - 320px)" };

    const {
      image_info: { backgroundColor },
    } = mineLevel;

    const bgColor = backgroundColor
      ? `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`
      : "green";

    const isOpenFireflyMarkerPopupModal =
      this.state.isOpenFireflyMarkerPopupModal.active === true;
    const isOpenControllerMarkerPopupModal =
      this.state.isOpenControllerMarkerPopupModal.active === true;

    return (
      <>
        {isOpenFireflyMarkerPopupModal && (
          <FireflyMarkerPopupModal
            open={isOpenFireflyMarkerPopupModal}
            initialValues={this.state.isOpenFireflyMarkerPopupModal}
            handleSettingsSave={(values) => this.handleSettingsSave(values)}
            resetModal={() => this.resetModalFirefly(false)}
          />
        )}
        {isOpenControllerMarkerPopupModal && (
          <ControllerMarkerPopupModal
            open={isOpenControllerMarkerPopupModal}
            initialValues={this.state.isOpenControllerMarkerPopupModal}
            handleSettingsSave={(values) => this.handleSettingsSave(values)}
            resetModal={() => this.resetModalController(false)}
          />
        )}
        <div
          className={"leafletMapContainer"}
          style={{
            ...showLegend,
            ...visibility,
          }}
        >
          <div className={"leafletMapContainerDiv"}>
            {/* <div id="sidebar"></div> */}
            <div
              className={"leafletMap"}
              style={{ backgroundColor: bgColor }}
              id={mapId}
            />
            {/* <div id="custom-map-controls"></div> */}
          </div>
        </div>

        {/* testing loading image by having image in a separte part of the dom */}
        {/* <img
          style={{ display: "none" }}
          src={this.state.mapSrc}
          onLoad={() => {
            console.log(`loaded <img> image `, this.state.mapSrc);
            this.setState({ isMapLoaded: true });
          }}
        /> */}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  // track changes to the saved map view state
  const savedMapViewState = props.componentstate.get(
    "mapViewState",
    `${props?.mineLevelId}`
  ) || {
    id: `${props?.mineLevelId}`,
    options: [],
  };

  const mineLevel = getAreaById(state, props.mineLevelId);

  const transformPixelsToUtm = mineLevel?.transformPixelsToUtm;

  const markersData = getMapState(state);

  // Firefly coordinates
  let geoJSONMarkersDataUtm = getFireflyCoordinatesByAreaId(
    state,
    props.mineLevelId
  );

  // #DEBUG - setup empty layer
  //geoJSONMarkersDataUtm = [];

  // Firefly update changes
  const fireflyIdsUpdateList = getFireflyIdsUpdateListByArea(
    state,
    props.mineLevelId
  );

  // Firefly deletetions
  const fireflyIdsDeleteList = getFireflyIdsDeleteListByArea(
    state,
    props.mineLevelId
  );

  // Controller coordinates
  const geoJSONControllersUtm = getControllerCoordinatesByAreaId(
    state,
    props.mineLevelId
  );

  // returns a list of polygon regions to 'show' on the map
  const regionPreview =
    getRegionPreviewListByAreaId(state, props.mineLevelId) || []; // e.g. [{id: "test_area", name: "polygon", namedArea: "uglyBear", spec: "test_area:a2d9282e-4b41-446f-b100-922fefbceeb9", type: "Polygon"}]

  // Named Area polygons
  //
  // #NOTE - we are only displaying the polygons selected by the show/hide context menu for
  // the SOP button interface. These areas are returned in 'regionPreview'.
  //
  // returns the geoJson of the polygons (named area) based on the regionList
  let geoJSONNamedAreasUtm = getNamedAreaInfosByRegionPreviewByAreaId(
    state,
    regionPreview,
    props.mineLevelId
  );

  const localMapState = GetLocalMap(state);
  const isDirty = getIsDirty(state);

  const namedAreaDisplaySettings = getNamedAreaDisplaySettings(state);

  const faults = getFaults(state) || [];

  // check for image files
  const folderFilesList = getFolderFileNamesListById(state, "areas");

  // message banner support
  const { messageBanners } = state.alarmButton;

  // deviceTimestamps for checking timeout

  const timedOutDevices = getTimedOutDevices(state);

  const isPokeTheWorker = isOnOffPokeTheWorker(state);

  const namedAreaEvents = getNamedAreaEventsByAreaId(state, props.mineLevelId);

  // user roles

  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);

  // map image from state
  // this has previously been loaded and saved to the Settings reducer state
  const mapImageFromState = getMapImageById(state, props.mineLevelId);

  return {
    mineLevel,
    markersData,
    geoJSONMarkersDataUtm,
    localMapState,
    geoJSONNamedAreasUtm,
    isDirty,
    namedAreaDisplaySettings,
    geoJSONControllersUtm,
    //
    faults,
    regionPreview,
    //
    fireflyIdsUpdateList,
    fireflyIdsDeleteList,
    folderFilesList,
    //
    messageBanners,
    //
    timedOutDevices,
    //
    isPokeTheWorker,
    //
    namedAreaEvents,
    //
    transformPixelsToUtm,
    // user roles
    role: {
      allowGuest: userGuest,
      allowOperator: userSuper || userAdmin || userUser,
      allowAdmin: userSuper || userAdmin,
      allowSuper: userSuper,
    },
    //
    // map image from state
    mapImageFromState,
    //
    savedMapViewState,
  };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateLocalMap: (mapState) => {
    dispatch(UpdateLocalMap(mapState));
  },
  namedAreaClearDeleteSelections: (parentId) => {
    dispatch(namedAreaClearDeleteSelections(parentId));
  },
  namedAreasSetIsDirty: (id) => {
    dispatch(namedAreasSetIsDirty(id));
  },
  mqttPublish: (mqttMsg) => {
    dispatch(mqttPublish(mqttMsg));
  },
  goto: (path) => dispatch(push(path)),
  fireflyIdsUpdateListDeleteId: (id) =>
    dispatch(fireflyIdsUpdateListDeleteId(id)),
  // flash message support
  clearFlashMessages: () => {
    dispatch(clearFlashMessages());
  },
  removeFlashMessage: (id) => {
    dispatch(removeFlashMessage(id));
  },
  addWarningFlashMessageIdColor: (id, color, header, message) => {
    dispatch(addWarningFlashMessageIdColor(id, color, header, message));
  },
  SetMessageBanner: (messageState) => {
    dispatch(SetMessageBanner(messageState));
  },
  TurnOnOffPokeTheWorker: (action) => {
    dispatch(TurnOnOffPokeTheWorker(action));
  },
  SetMapMoving: (bool) => dispatch(SetMapMoving(bool)),
  SetMapMarkerDragging: (bool) => dispatch(SetMapMarkerDragging(bool)),

  //
  saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)),
  deleteNamedArea: (id) => {
    dispatch(deleteNamedArea(id));
  },
  //
  // see - src/pages/ControlRoomPage/PrecannedOperations.js
  activate: (operation) => dispatch(activateNamedAreaEventSingle(operation)),
  cancel: (operation) => dispatch(cancelNamedAreaEvent(operation)),
  waitEventTimeOut: (action) => dispatch(waitEventTimeOut(action)),
  saveFirefly: (data) => dispatch(saveFirefly(data)),
  saveUPS: (data) => dispatch(saveUPS(data)),
  //
  // region preview
  addAreaPreview: (area) => dispatch(addAreaPreview(area)),
  removeAreaPreview: (area) => dispatch(removeAreaPreview(area)),
  //
  saveUserSettingsComponentState: (data) =>
    dispatch(saveUserSettingsComponentState(data)),
});

export default withComponentStateCache(
  connect(mapStateToProps, mapDispatchToProps)(Map)
);
