export function cancelNamedAreaEvent(operation) {
  //console.log(`cancelNamedAreaEvent operation`, operation);
  return { type: "DEACTIVATE_NAMED_AREA_EVENT", payload: { operation } };
}

export function activateNamedAreaEvent(operation) {
  //console.log(`activateNamedAreaEvent processClick operation`, operation);
  return { type: "ACTIVATE_NAMED_AREA_EVENT", payload: { operation } };
}

export function activateNamedAreaEventSingle(operation) {
  //console.log(`activateNamedAreaEventSingle processClick operation`, operation);
  return { type: "ACTIVATE_NAMED_AREA_EVENT_SINGLE", payload: { operation } };
}

export function updateButtonGroupState(buttons) {
  //console.log("updateButtonGroupState buttons", buttons);
  return { type: "UPDATE_BUTTONS_GROUP_STATE", payload: { buttons } };
}

export function waitEventTimeOut(action) {
  return { type: "WAIT_EVENT_TIMEOUT", payload: { action } };
}

export function updateRemoteButtonClick(array) {
  return { type: "UPDATE_REMOTE_BUTTON_CLICK", payload: { array } };
}

export function removeRemoteButtonClick(id) {
  return { type: "REMOVE_REMOTE_BUTTON_CLICK", payload: { id } };
}

export function clearRemoteButtonClick() {
  return { type: "CLEAR_REMOTE_BUTTON_CLICK" };
}

// ******************
// OLD
// *****************

// NOT USED
export function fetchActiveOperationalChanges() {
  return { type: "FETCH_OPERATIONAL_CHANGES_REQUESTED" };
}

// NOT USED
export function cancelOperationalChange(id) {
  return { type: "DEACTIVATE_OPERATIONAL_CHANGE", payload: { id } };
}

// NOT USED
export function fetchPrecannedOperations() {
  return { type: "FETCH_PRECANNED_OPERATIONS_REQUESTED" };
}

// NOT USED
export function buildActivateChange(area, operation) {
  return () => {
    return {
      type: "ACTIVATE_OPERATIONAL_CHANGE",
      payload: { area, operation },
    };
  };
}

// NOT USED
export function toggleForcedColor(id, color) {
  console.log("toggleForcedColor", id, color);
  return {
    type: "TOGGLE_FORCED_COLOR",
    payload: { id, color },
  };
}
