import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Polygon } from 'react-leaflet'
import { EditControlFeatureGroup } from '@andrewdodd/react-leaflet-draw'
import cuid from 'cuid'

import { getEditingRegionsInOrder } from './reducer'
import * as actions from './actions'
import getSemanticColor from 'colorpalette'

import 'leaflet-draw/dist/leaflet.draw.css'

function makeId (region) {
  return JSON.stringify(region.points)
}

export class CreateRegionsMapEditor extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  }
  addRegion = evt => {
    const points = evt.layer.getLatLngs()[0]
    this.props.addRegion(points)
  }
  deleteRegion = e => {
    const { regionId } = e.props
    this.props.deleteRegion(regionId)
  }
  changeRegion = (e, layer) => {
    const points = layer.getLatLngs()[0]
    const { regionId } = e.props
    this.props.changeRegion(regionId, points)
  }

  render () {
    const renderId = new Date().getTime()
    const { onActivityStarted, onActivityStopped, opacity = 0.5 } = this.props
    return (
      <EditControlFeatureGroup
        onCreated={this.addRegion}
        onDeleted={this.deleteRegion}
        onEdited={this.changeRegion}
        onActivityStarted={onActivityStarted}
        onActivityStopped={onActivityStopped}
        controlProps={{
          draw: {
            rectangle: true,
            marker: false,
            circle: false,
            circlemarker: false,
            polygon: {
              allowIntersection: false,
              drawError: {
                color: '#E1E100',
                message: '<strong>Wups</strong> shapes cannot intersect'
              }
            },
            polyline: false
          },
          edit: {
            edit: {
              selectedPathOptions: {
                maintainColor: true,
                fillOpacity: 0.5
                // fillColor: nullm
              }
            },
            poly: {
              allowIntersection: false,
              drawError: {
                color: 'fuchsia',
                message: '<strong>Wups</strong> shapes cannot intersect'
              }
            }
          },
          position: 'topright'
        }}
      >
        {this.props.orderedRegions
          .asMutable()
          .reverse()
          .map((region, idx) => {
            const { points, lightSettings: { color } } = region
            const semColor = getSemanticColor(color)
            return (
              <Polygon
                positions={points}
                fillColor={semColor}
                fillOpacity={opacity}
                color={color === 'white' ? 'black' : semColor}
                regionId={region.id}
                key={`editing-region-${makeId(region)}-${idx}-${renderId}`}
              />
            )
          })}
      </EditControlFeatureGroup>
    )
  }
}

function mapStateToProps (state) {
  return {
    orderedRegions: getEditingRegionsInOrder(state)
  }
}

export function mapDispatchToProps (dispatch, ownProps) {
  return {
    addRegion: shape => {
      dispatch(actions.addNewRegion(cuid(), shape))
    },
    deleteRegion: regionId => {
      dispatch(actions.deleteRegion(regionId))
    },
    changeRegion: (regionId, newShape) => {
      dispatch(actions.changeRegionShape(regionId, newShape))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(
  CreateRegionsMapEditor
)
