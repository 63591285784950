export function mqttTest(mqttMsg) {
  return { type: "MQTT_TEST", payload: mqttMsg };
}

export function removeMqttMessage(id) {
  return { type: "REMOVE_MQTT_MESSAGE", payload: { id } };
}

export function clearMqttMessages() {
  return { type: "CLEAR_MQTT_MESSAGES" };
}

export function newNamedAreaMqtt(mqttMsg) {
  return { type: "NEW_NAMED_AREA_MQTT", payload: mqttMsg };
}

export function deleteNamedAreaMqtt(mqttMsg) {
  return { type: "DELETE_NAMED_AREA_MQTT", payload: mqttMsg };
}

export function infoNamedAreaMqtt(mqttMsg) {
  return { type: "INFO_NAMED_AREA_MQTT", payload: mqttMsg };
}

export function changeNamedAreaMqtt(mqttMsg) {
  return { type: "CHANGE_NAMED_AREA_MQTT", payload: mqttMsg };
}

export function membersNamedAreaMqtt(mqttMsg) {
  return { type: "MEMBER_NAMED_AREA_MQTTS", payload: mqttMsg };
}

export function eventNamedAreaMqtt(mqttMsg) {
  return { type: "EVENT_NAMED_AREA_MQTT", payload: mqttMsg };
}
