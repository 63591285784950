import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";

class FailPage extends Component {
  render() {
    const { strings } = this.props;
    return (
      <div>
        <h1>{strings?.["Fault Log"]}</h1>
        <object
          data="/fault.txt"
          frameBorder="0"
          height="500"
          width="100%"
        ></object>
      </div>
    );
  }
}

export default connect(null)(FailPage);
