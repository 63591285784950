import React from "react";
import { Button, Popup, Icon, Grid } from "semantic-ui-react";
import { round } from "utils/number-utils.js";
import _isEmpty from "lodash/isEmpty";

export const NamedAreaPolygonPopup = ({ id, data }) => {
  const { parent, parentName, sub_type } = data;

  // APP_TERMINOLOGY
  let strNamedArea = "Region";

  switch (sub_type) {
    case "tagzone":
      strNamedArea = "Tag Zone";
      break;
    case "forced":
      strNamedArea = "Forced FireFly";
      break;
    case null:
    default:
      break;
  }

  // #NOTE - the `dataid` passed to the Butotn is used to attach an eventListener to the rendered string.
  // This is how the onClick event is setup.
  // See - src/components/Map/MineLevelMapLeaflet.js
  // "... .addEventListener("click", (e) => { ..."

  //
  //
  // #NOTE - `dataid` is places on both button and icon, as it is not possible to guarantee only one click target.

  // console.log(
  //   "NamedAreaPolygonPopup parent, parentName, sub_type",
  //   parent,
  //   parentName,
  //   sub_type
  // );

  return (
    <Grid>
      <Grid.Row style={{ paddingTop: "1em", paddingBottom: "0px" }}>
        <Grid.Column>
          <Icon name="star" color={"purple"} />
          <strong>{strNamedArea}: </strong>
          <button
            type={"submit"}
            className={`${parent ? "link" : null} popupNamedAreaPolygon`}
          >
            <span dataid={`${sub_type}/edit/${parent}`}>
              {parentName || "unknown"}{" "}
            </span>
          </button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: "0.5em" }}></Grid.Row>
    </Grid>
  );
};
