import React, { Component } from "react";
import { connect } from "react-redux";
import {
  isDirty,
  isSubmitting,
  getFormValues,
  hasSubmitSucceeded,
  submit,
  isValid,
} from "redux-form";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import NavigationPrompt from "react-router-navigation-prompt";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class NavigationPromptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldPromptSaveShow = () => {
    //console.log(`NavigationPromptModal shouldPromptSaveShow props`, this.props);

    const {
      submitted,
      isFormDirty,
      isSubmitting,
      submitSucceeded,
      formValues,
    } = this.props;

    const _action = formValues?._action;

    // Don't show the '"are you sure"
    // if the form is in the process of saving it'll be 'isSubmitting',
    // or has just submitted 'submitSucceeded',
    if (
      isSubmitting ||
      submitSucceeded ||
      submitted == true ||
      _action === "delete"
    )
      return false;

    // if the form is not dirty
    if (!isFormDirty) return false;

    // default
    return true;
  };

  promptSaveCatchAndSave = (onConfirm, onCancel) => {
    const { formValues, formName, remoteSubmit, isValid } = this.props;

    // check if form is valid

    if (isValid) {
      // #NOTE - testing remote submit instead of directly submitting the form
      // remote submit the form
      //remoteSubmit(formName);

      // submit the form
      this.props.onSubmit(formValues);

      // OK the dlg
      onConfirm();
    } else {
      console.log(`FORM DATA IS NOT VALID`);
      onCancel();
    }
  };

  render() {
    return (
      <NavigationPrompt when={this.shouldPromptSaveShow}>
        {({ onConfirm, onCancel }) => (
          <Modal open={true}>
            <Header icon="save" content="Unsaved Changes" />
            <Modal.Content>
              <p>
                The page changes were not saved. Do you really want to leave?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button color={"orange"} onClick={onConfirm}>
                Yes, I want to leave
              </Button>
              <Button color={"blue"} onClick={onCancel}>
                No, take me back to review, and Save
              </Button>
              <Button
                color={"green"}
                onClick={() => this.promptSaveCatchAndSave(onConfirm, onCancel)}
              >
                Leave, but also Save the changes
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </NavigationPrompt>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { formName } = props;

  return {
    //
    isFormDirty: isDirty(formName)(state),
    isSubmitting: isSubmitting(formName)(state),
    formValues: getFormValues(formName)(state),
    submitSucceeded: hasSubmitSucceeded(formName)(state),
    isValid: isValid(formName)(state),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    remoteSubmit: (formName) => dispatch(submit(formName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationPromptModal);
