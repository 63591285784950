import React from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
// import customicons from './custom.svg' // this should be used after upgrading to CRA 2.0
import "./index.css";
import ReactSVG from "react-svg";
// SVG & CSS produced with this: https://icomoon.io/
// Following broadly the process here: https://medium.com/douglas-matoso-english/build-an-svg-icon-component-with-react-de9db211ebd6

export default function ({ name, ...props }) {
  // if (name.indexOf('custom-') === -1) {
  //   return (
  //     <Icon
  //       name={name}
  //       style={{ marginRight: 'auto', marginLeft: 'auto' }}
  //       {...props}
  //     />
  //   )
  // }
  // name = name.slice(7)
  let path = `icons/${name}`;

  if (!path.endsWith(".svg")) path += ".svg";

  // <use xlinkHref={`#icon-${name}`} />
  return (
    <div className="custom-icon">
      <ReactSVG
        src={path}
        svgstyle={{ fill: props.color }}
        wrapper="span"
      ></ReactSVG>
    </div>
    // <svg className={`custom-icon icon-${name}`}>
    //   <use xlinkHref={`icons/custom.svg#icon-${name}`} />
    // </svg>
  );
}
