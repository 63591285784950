import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GetLocalMap } from "components/Map/reducer";

import {
  Header,
  Table,
  Button,
  Checkbox,
  Grid,
  Container,
  Form,
  Radio,
} from "semantic-ui-react";

import Map from "pages/Test/TestMqttGeoJsonMap";
//import Map from "components/Map/mapBase.js";
//import MapMini from "./TestMqttGeoJsonMapMini";

// based on
// - https://embed.plnkr.co/Gvei5I0S9yEo6fCYXPuy/
// see -> <Map /> from "./TestMapTilesMap";

// --------------------------------------
// Marker not appearing for Simple Example
// Fix - https://github.com/PaulLeCam/react-leaflet/issues/453
//
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// --------------------------------------
// HACKfix: Use zIndex to stop leaflet map from being above the Semantic-UI dropdown menus!
const DEFAULT_MAP_STYLE = {
  height: "800px",
  width: "100%",
  position: "relative",
  zIndex: 0,
};

const TAG_MARKER_ICONS = [
  "TagUnknownMarker",
  "TagHardHat",
  "TagTruckPickupMarker",
  "TagAmbulanceMarker",
  "TagLightVehicleMarker",
  "TagLoaderMarker",
  "TagTruckMarker",
  "TagJumboMarker",
];

class TestMarkerIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckboxMarker: false,
      isCheckboxRefMarker: false,
      isCheckboxFireflies: false,
      isCheckboxPolyline: false,
      isAddPolygonClicked: false,
      //
      // tag icon demo - radio buttons
      iconSelection: "",
    };
  }

  isAddPolygonClicked = () => {
    this.setState({
      isAddPolygonClicked: !this.state.isAddPolygonClicked,
    });
  };

  onClickCheckbox = (name) => {
    console.log("onClickCheckbox", name);
    this.setState({
      [name]: !this.state[name],
    });

    // uiseful - https://hallister.github.io/semantic-react/#!/Checkbox

    // via REDUX map the state of the controls
    // within the Map component, catch updates and alter layers etc. based on
    // STATE messages.

    // _alt_
    // pass down the state to child component (as below)

    // see
    // https://gis.stackexchange.com/questions/186131/placing-controls-outside-map-container-with-leaflet
  };

  onClickRadioButton = (name) => {
    this.setState({ iconSelection: name });
  };

  render() {
    let {
      isCheckboxMarker,
      isCheckboxRefMarker,
      isCheckboxFireflies,
      isCheckboxPolyline,
      //
      isCheckboxTagPersonnel,
    } = this.state;

    const { localMapState } = this.props;
    // console.log(
    //   "localMapState namedAreas",
    //   JSON.stringify(localMapState.namedAreas)
    // );

    // #REVIEW - replace with object search
    // https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
    function getNested(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj);
    }

    const tempArray = getNested(localMapState, "namedAreas", "features") || [];
    // console.log("tempArray", tempArray);

    return (
      <>
        <div className={"genericGridHeader"}>
          <Container>
            <Grid columns={2}>
              <Grid.Row className={"genericTitleHeader"}>
                <Grid.Column width={4}>
                  <Header as="h1">Test Marker Icons</Header>
                </Grid.Column>
                <Grid.Column width={12}></Grid.Column>
              </Grid.Row>
              <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column width={3}>
                  <div id="custom-map-controls"></div>
                  {/* <button id="toggle">TOGGLE</button>
              <hr />
              <Button class="ui button" onClick={this.isAddPolygonClicked}>
                Add Polygon
              </Button> */}
                  {/* <Grid.Row>
                    <Checkbox
                      label="Display Markers"
                      name="check"
                      checked={isCheckboxMarker}
                      onClick={this.onClickCheckbox.bind(
                        this,
                        "isCheckboxMarker"
                      )}
                    />
                  </Grid.Row> */}
                  {/* <Grid.Row>
                    <Checkbox
                      label="Display Ref Points"
                      name="check"
                      checked={isCheckboxRefMarker}
                      onClick={this.onClickCheckbox.bind(
                        this,
                        "isCheckboxRefMarker"
                      )}isCheckboxRefMarker
                    />
                  </Grid.Row> */}
                  {/* <Grid.Row>
                    <Checkbox
                      label="Display FireFlys"
                      name="check"
                      checked={isCheckboxFireflies}
                      onClick={this.onClickCheckbox.bind(
                        this,
                        "isCheckboxFireflies"
                      )}
                    />
                  </Grid.Row> */}
                  {/* <Grid.Row>
                    <Checkbox
                      label="Display Cables"
                      name="polyline"
                      checked={isCheckboxPolyline}
                      onClick={this.onClickCheckbox.bind(
                        this,
                        "isCheckboxPolyline"
                      )}
                    />
                  </Grid.Row> */}
                  {/* <Grid.Row>
                    <Checkbox
                      label="Display Tag Personnel"
                      name="check"
                      checked={isCheckboxTagPersonnel}
                      onClick={this.onClickCheckbox.bind(
                        this,
                        "isCheckboxTagPersonnel"
                      )}
                    />
                  </Grid.Row> */}
                  <Grid.Row>
                    <Header as="h3">Select the icon type</Header>
                    <Form>
                      {/* <Form.Field>
                        Selected value: <b>{this.state.iconSelection}</b>
                      </Form.Field> */}
                      {TAG_MARKER_ICONS.map((option) => {
                        return (
                          <Form.Field>
                            <Radio
                              label={option}
                              name="radioGroup"
                              value={option}
                              checked={this.state.iconSelection === option}
                              onChange={(e, data) => {
                                this.onClickRadioButton(data.value);
                              }}
                            />
                          </Form.Field>
                        );
                      })}
                    </Form>
                  </Grid.Row>
                  <Grid.Row>
                    <br />
                    To do:
                    <br />* change marker size (stepper?)
                    <br />* option to select area background
                    <br />* modify dimensions of the marker ??
                    <br />* setup marker type as ConfigJs
                    <br />* add clustering to demo?
                    <br />* offset pointer? I wonder if this is necessary.
                    Position is not exact.
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Map
                    style={DEFAULT_MAP_STYLE}
                    render="single"
                    parentState={this.state}
                  />
                  {/* <MapMini
                style={DEFAULT_MAP_STYLE}
                render="single"
                parentState={this.state}
              /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const localMapState = GetLocalMap(state);

  return {
    localMapState,
  };
}

export default connect(mapStateToProps, null)(TestMarkerIcons);
