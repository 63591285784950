import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup'
import { Table } from 'semantic-ui-react'
import { formatDateRelativeNow } from 'utils/format-date'

import { withMineLevel } from 'ApplicationContexts'
import { connect } from 'react-redux'
import {
  getMineLevelHistory,
  getIsLoadingSeismicLayersForMineLevel
} from 'seismicOverlays/reducer'
import { fetchSeismicLayersForMineLevel } from 'seismicOverlays/actions'
import { startEditingWithSeismicAsStart } from 'pages/CreateSeismicOverlayPage/actions'

import BasicTable from 'components/BasicTable'

function HeaderRow () {
  return (
    <Table.Row>
      <Table.HeaderCell>Id</Table.HeaderCell>
      <Table.HeaderCell>Created</Table.HeaderCell>
      <Table.HeaderCell>Since</Table.HeaderCell>
      <Table.HeaderCell>Default Color</Table.HeaderCell>
      <Table.HeaderCell>Regions #</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  )
}

function BuildItemRow (
  activeLayer,
  previewingId,
  onPreview,
  onReinstate,
  onStartEditorWithSeismic
) {
  return function ({ item, error, wholeRowMessage, ...props }) {
    if (wholeRowMessage) {
      return (
        <Table.Row error={error}>
          <Table.Cell colSpan={6}>{wholeRowMessage}</Table.Cell>
        </Table.Row>
      )
    }
    return (
      <Table.Row positive={item === activeLayer}>
        <Table.Cell>{item.id}</Table.Cell>
        <Table.Cell>{item.createdAt.toISOString()}</Table.Cell>
        <Table.Cell>{formatDateRelativeNow(item.createdAt)}</Table.Cell>
        <Table.Cell>{item.levelDefault.color}</Table.Cell>
        <Table.Cell>{item.regionSettings.length}</Table.Cell>
        <Table.Cell>
          {onPreview && (
            <Popup
              content='Preview on map'
              trigger={
                <Button
                  icon='search'
                  onClick={() => onPreview(item.id)}
                  primary={item.id === previewingId}
                  disabled={item.id === previewingId}
                />
              }
            />
          )}
          {onReinstate && (
            <Popup
              content='Reinstate this'
              trigger={
                <Button icon='upload' onClick={() => onReinstate(item.id)} />
              }
            />
          )}
          {onStartEditorWithSeismic && (
            <Popup
              content='Edit new seismic from this'
              trigger={
                <Button
                  icon='edit'
                  onClick={() => onStartEditorWithSeismic(item.id)}
                />
              }
            />
          )}
        </Table.Cell>
      </Table.Row>
    )
  }
}

class SeismicLayerList extends Component {
  static propTypes = {
    layers: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    previewingId: PropTypes.any,
    onPreview: PropTypes.func,
    onStartEditorWithSeismic: PropTypes.func
  }

  render () {
    const {
      layers,
      isLoading,
      onPreview,
      onStartEditorWithSeismic,
      onReinstate,
      previewingId
    } = this.props

    return (
      <BasicTable
        HeaderComponent={HeaderRow}
        RowComponent={BuildItemRow(
          layers[0],
          previewingId,
          onPreview,
          onReinstate,
          onStartEditorWithSeismic
        )}
        items={layers}
        isLoading={isLoading}
        getItemKey={(item, idx) => item.id}
      />
    )
  }
}

class ConnectedSeismicLayerList extends React.Component {
  componentDidMount () {
    this.props.fetchSeismicLayers()
  }
  componentDidUpdate (prevProps) {
    if (prevProps.mineLevel.id !== this.props.mineLevel.id) {
      this.props.fetchSeismicLayers()
    }
  }

  render () {
    const { fetchMineLevels, ...props } = this.props
    return <SeismicLayerList {...props} />
  }
}

function mapStateToProps (state, props) {
  return {
    layers: getMineLevelHistory(state, props.mineLevel.id),
    isLoading: getIsLoadingSeismicLayersForMineLevel(state, props.mineLevel.id)
  }
}

function mapDispatchToProps (dispatch, props) {
  return {
    fetchSeismicLayers: () => {
      dispatch(fetchSeismicLayersForMineLevel(props.mineLevel.id))
    },
    onStartEditorWithSeismic: seismicLayerId => {
      dispatch(
        startEditingWithSeismicAsStart(props.mineLevel.id, seismicLayerId)
      )
    }
  }
}
export default withMineLevel(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedSeismicLayerList)
)
