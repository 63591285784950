import React from "react";
import { Button, Popup, Icon, Grid } from "semantic-ui-react";
import { round } from "utils/number-utils.js";
import _isEmpty from "lodash/isEmpty";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

export const ControllerMarkerPopup = ({ id, data }) => {
  const {
    id: ups_id,
    easting,
    northing,
    X,
    Y,
    z,
    utm_zone_number,
    utm_zone_letter,
    color,
    location,
    lastStatusReport,
    user_relay,
    role,
  } = data;

  const { allowAdmin } = role;

  // #NOTE - the `dataid` passed to the Butotn is used to attach an eventListener to the rendered string.
  // This is how the onClick event is setup.
  // See - src/components/Map/MineLevelMapLeaflet.js
  // "... .addEventListener("click", (e) => { ..."

  //
  //
  // #NOTE - `dataid` is places on both button and icon, as it is not possible to guarantee only one click target.

  // disable link for operator
  const buttonLink = (
    <button
      type={"submit"}
      className={`${ups_id ? "link" : null} popupControllerMarkerLink`}
    >
      <span dataid={ups_id}>{ups_id || "unknown"} </span>
    </button>
  );

  const noLink = <strong>{ups_id || "unknown"} </strong>;

  return (
    <div style={{ width: "250px" }}>
      <Grid>
        <Grid.Row
          key={`key-C-${ups_id}`}
          style={{ paddingTop: "1em", paddingBottom: "0px" }}
        >
          <Grid.Column width={6}>
            {/* <Icon name="hdd" color={"blue"} /> */}
            <strong>Controller</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {allowAdmin ? buttonLink : noLink}
          </Grid.Column>
        </Grid.Row>
        {location?.map((loc, idx) => {
          const locString = JSON.stringify(loc)?.replaceAll('"', "");
          return (
            <Grid.Row
              key={`key-C-${ups_id}-${locString}`}
              style={{ paddingTop: "0em", paddingBottom: "0px" }}
            >
              <Grid.Column width={6}>
                {idx > 0 ? null : <strong>Location</strong>}
              </Grid.Column>
              <Grid.Column width={9}>{locString}</Grid.Column>
            </Grid.Row>
          );
        })}
        <Grid.Row
          key={`key-C-${ups_id}-user-relay`}
          style={{ paddingTop: "0em", paddingBottom: "0px" }}
        >
          <Grid.Column width={6}>
            <strong>User Relay</strong>
          </Grid.Column>
          <Grid.Column width={9}>{user_relay || 0}</Grid.Column>
        </Grid.Row>
        <Grid.Row key={`key-C-${ups_id}-last`} style={{ paddingTop: "0.5em" }}>
          {/* <Grid.Column width={6}>
            <strong>Last Report</strong>
          </Grid.Column>
          <Grid.Column width={9}>{lastStatusReport}</Grid.Column> */}
        </Grid.Row>
      </Grid>
    </div>
  );
};
