import { createStore, applyMiddleware, compose } from 'redux'

import { reduxBatch } from '@manaflair/redux-batch'

/* eslint-disable no-underscore-dangle */
function isReduxDevtoolExtensionPresent () {
  return (
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  )
}

const composeFunction = isReduxDevtoolExtensionPresent()
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
    // Prevent recomputing reducers for `replaceReducer`
    shouldHotReload: false
  })
  : compose
/* eslint-enable */

export default function (rootReducer, initialState = {}, middlewares) {
  const reducer = rootReducer
  const preloadedState = initialState
  const enhancers = [reduxBatch, applyMiddleware(...middlewares), reduxBatch]
  const enhancer = composeFunction(...enhancers)
  const store = createStore(reducer, preloadedState, enhancer)
  return store
}
