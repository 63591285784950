// see - https://axios-http.com/docs/req_config

import axios from "axios"; // just so I don't have to shovel the XSRF-TOKEN stuff around

import { tagTrackingPort } from "components/ConfigJs";

// #TODO - this should not be used...remove it and all references to it....
export const protectedAPI = axios.create({
  baseURL: "/api",
  timeout: 60000, // Let the server do timeouts less than 1 min
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const tagServicePort = tagTrackingPort() || "8010";

const getBaseURL = () => {
  let base = window.location.origin;
  if (process.env.NODE_ENV === "production") {
    const port = window?.location?.port;
    console.log(`tagApi getBaseURL port `, port);
    if (port) {
      base = base.replace(`:${port}`, `:${tagServicePort}/api/v1/`);
    } else {
      base = `${base}:${tagServicePort}/api/v1/`;
    }
    console.log(`tagApi getBaseURL - `, base);
    return base;
  } else if (process.env.NODE_ENV === "development") {
    console.log(`tagApi getBaseURL - "/"`);
    return "/";
  }
};

export const tagApi = axios.create({
  baseURL: getBaseURL(), // see - src/setupProxy.js
  timeout: 6000, // 6 seconds
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// prevent server returning 200 & html when api route not valid (server issue)
// see - https://github.com/axios/axios/issues/889
tagApi.interceptors.response.use(
  (response) => {
    console.log(`tagApi interceptors response`, response);
    return response.headers["content-type"] ===
      "application/json; charset=UTF-8"
      ? response
      : Promise.reject(response);
  },
  (error) => Promise.reject(error)
);

if (false) {
  tagApi.interceptors.request.use((request) => {
    console.log("tagApi Starting Request", request);
    return request;
  });

  tagApi.interceptors.response.use((response) => {
    console.log("tagApi Response Request", response);
    return response;
  });
}

export const myApi = axios.create({
  baseURL: "/", // see - src/setupProxy.js
  timeout: 6000, // 6 seconds
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// #NOTE - this should be enabled for myApi (as it is for tagApi),
// but the api response headers do not always return a 'content-type'
// and if they do then the content-type is not always json.
// #WIP - need to update the api response from the server to be consistent.
//

// prevent server returning 200 & html when api route not valid (server issue)
// see - https://github.com/axios/axios/issues/889
// myApi.interceptors.response.use(
//   (response) => {
//     //console.log(`myApi interceptors response`, response);
//     return response.headers["content-type"] ===
//       "application/json; charset=UTF-8"
//       ? response
//       : Promise.reject(response);
//   },
//   (error) => Promise.reject(error)
// );

// DEBUG - interceptors
if (false) {
  myApi.interceptors.request.use((request) => {
    console.log("myApi Starting Request", request);
    return request;
  });

  myApi.interceptors.response.use((response) => {
    console.log("myApi Response:", response);
    return response;
  });
}

// e.g.
//   //dummy ending for testing...
//   // const response = yield dummyResponse(false); //protectedAPI.post(`/minelevel`, form);
//   // console.log("saveNewMineLevel response", response);
//   // return response;

export function dummyResponse(response) {
  return new Promise(function (resolve, reject) {
    if (response) {
      resolve("worked!");
    } else {
      reject("whoops!");
    }
  });
}

// see - https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers

// get requests optionally need a params key to properly set query parameters
//delete requests with a body need it to be set under a data key

// axios.get('/user?ID=12345')
//   .then(function (response) {
//     // Do something
//   })

// axios.post('any-url', payload).then(
//   // payload is the body of the request
//   // Do something
// )

// axios.delete('url', { data: payload }).then(
//   // Observe the data keyword this time. Very important
//   // payload is the request body
//   // Do something
// )
