import { StatusEnum } from "utils/StatusEnum";
import _isEmpty from "lodash/isEmpty";

export const convertFetchControllersToMqtt = (data) => {
  // Example object
  //
  // id: "74:e1:82:91:60:c5",
  // area: "DMLZ_Extraction",
  // name:"ups14"
  // location:["P5","P6","P7","P8"]
  // utm: { X: 736424.0460048476, Y: 9548788.906859454 },
  // utm_zone: 53,
  // utm_zone_letter: 55,
  // z: 0,
  // ip: "10.208.26.143",
  // master: false,
  // mode: "operation",
  // battery: true,
  // network: true,
  // timestamp: 1624847346,
  // sw_version: "",
  // type: "UPS",
  // emergency_source_active: false,
  // trigger: 0,
  // emergency_config: {...},
  // trigger_config: {...},
  // firefly_count: [
  //   { location: "P5", count: 0, operating: true },
  //   { location: "P6", count: 0, operating: true },
  //   { location: "P7", count: 2, operating: true },
  //   { location: "P8", count: 0, operating: true },
  // ],

  let controllerCoordinates = {};
  let controllerFeatures = {};
  let controllerStatuses = {};
  let emergencyEventSettings = {};
  let triggerEventSettings = {};

  data.Controllers.forEach((controller) => {
    const {
      id,
      area,
      name: network_name,
      utm: { X: easting, Y: northing },
      utm_zone_letter, // rx as ASCII int
      utm_zone: utm_zone_number,
      location: rxLocation,
      z,
      ip,
      master,
      mode,
      battery,
      network,
      timestamp: ts,
      sw_version: sw_ver,
      type,
      emergency_config,
      trigger_config,
      firefly_count,
      user_relay,
      //
    } = controller;

    // sometimes(why?) location is empty [""], if possible get the location information
    // from the firefly_count
    let location = rxLocation;
    if (rxLocation.length === 1 && rxLocation[0] === "") {
      location = firefly_count.map((loc) => {
        return loc.location;
      });
    }

    const timestamp = new Date(ts * 1000);

    const stringUtmZoneLetter = String.fromCharCode(utm_zone_letter);

    const controllerFeature = {
      id,
      type: "Feature",
      properties: {
        status: "api", // for info, file search for "used to track the status of the object on maps and in lists"
        id,
        network_name,
        area,
        master,
        user_relay: user_relay || 0,
        mode,
        northing,
        easting,
        utm_zone_letter: stringUtmZoneLetter,
        utm_zone_number,
        z,

        timestamp: timestamp,
        //
        deviceStatus: [StatusEnum.API_FETCH, StatusEnum.NO_STATUS_REPORT],
        location,
      },
      geometry: { type: "Point", coordinates: [easting, northing] },
    };

    controllerFeatures[id] = controllerFeature;

    const mac = id;

    const controllerStatus = {
      id,
      electrical: {},
      firefly_count,
      system: { sw_ver },
      timestamp: undefined,
      topology: { area, ip, location, mac, master, mode, network_name },
    };
    controllerStatuses[id] = controllerStatus;

    emergencyEventSettings[id] = emergency_config?.ports?.map?.((port) => {
      return {
        ...port,
        color: port?.color.toLowerCase(),
        led_state: port?.led_state.toLowerCase(),
      };
    });
    triggerEventSettings[id] = trigger_config?.ports?.map?.((port) => {
      return {
        ...port,
        color: port?.color?.toLowerCase(),
        led_state: port?.led_state?.toLowerCase(),
      };
    });
  });

  controllerCoordinates = {
    type: "FeatureCollection",
    features: Object.values(controllerFeatures),
  };

  return {
    controllerCoordinates,
    controllerStatuses,
    emergencyEventSettings,
    triggerEventSettings,
  };
};
