import React, { Component } from "react";
import "admin/event-scheduled/ScheduledEvent.css";

import { connect } from "react-redux";
import { push as goto } from "react-router-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Checkbox,
  Popup,
  Tab,
} from "semantic-ui-react";
import _isEqual from "lodash/isEqual";

import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";
import GenericButton from "admin/GenericButton";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { format } from "date-fns";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";

import {
  fetchScheduledEventJobs,
  deleteScheduledEventJob,
  saveNewScheduledEvent,
} from "NamedAreas/actions";
import hash from "object-hash";
import { toWebColor } from "utils/toWebColor";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";
import {
  getAllAreaStatuses,
  getScheduledEventJobInfo,
} from "components/WebWorker/reducer";
import { formatRelative, parseISO } from "date-fns";
// see - https://www.npmjs.com/package/cronstrue
import cronstrue from "cronstrue/i18n";
import { getUserData } from "auth/reducer";
import { v4 as uuid } from "uuid";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class ScheduledEventListAllJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        disable: "",
        name: "",
        active: "",
        cronExpression: "",
        timestamp: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},

      //
      periodSelection: "24hrs",
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    this.props.fetchScheduledEventJobs();

    // #TODO - DO THIS EVERYWHERE!!!!!!!!!!!! - MAKE A FUNCTION!
    // make sure only current filter settings are updated
    const newFilterInput = this.props.componentstate.get(
      "filterInput",
      "eventJobList"
    );

    let filterInput = { ...this.state.filterInput };

    for (const key in filterInput) {
      if (Object.hasOwnProperty.call(filterInput, key)) {
        filterInput[key] = newFilterInput?.[key] || "";
      }
    }

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "eventJobList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });

    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          //console.log(`xxx item[key]`, item[key]);
          //console.log(`xxx match[key]`, match[key]);

          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  requestAction = (action) => {
    const { selections } = this.state;
    const {
      deleteScheduledEventJob,
      saveNewScheduledEvent,
      onlyActiveJobs,
      strings,
    } = this.props;

    let promiseArray = [];

    if (action === "delete") {
      //console.log("xxx action delete selections", selections);

      for (var key in selections) {
        const selection = key;

        //console.log("xxx action delete key", key);

        promiseArray.push(
          new Promise((resolve, reject) =>
            deleteScheduledEventJob({
              values: { id: selection },
              resolve,
              reject,
            })
          )
        );
      }
    }

    if (action === "copy") {
      console.log("xxx copy selections", selections);

      for (var key in selections) {
        const selection = key;

        const jobTemplate =
          onlyActiveJobs?.find((job, idx) => job.eventJobId === selection) ||
          {};

        const values = JSON.parse(jobTemplate.info);

        // set new eventJobId for jobTemplate
        const id = uuid(); // see - src/admin/named-area-simple/NamedAreaSimpleCreatePage.js, also see for copy()
        const password = uuid(); // not supported ATM

        const {
          name,
          disable,
          cron,
          mineLevelId: area_id,
          note,
          duration,
          events,
        } = values;

        const newEvents = events.map((event) => {
          return { polygon_id: event.polygonId, user_relay: event.userRelay };
        });

        const cron_active_duration = `${duration.value}:${duration.unit}`;

        const copyString = `(${strings?.["copy"]})`;
        const newName = `${name} ${copyString}`;
        const info = JSON.stringify({
          ...values,
          name: newName,
        });

        const newValues = {
          id,
          name: newName,
          cron,
          area_id,
          disable,
          events: newEvents,
          note,
          password,
          cron_active_duration,
          info,
        };

        //console.log("xxx copy newValues", newValues);

        promiseArray.push(
          new Promise((resolve, reject) => {
            saveNewScheduledEvent({
              values: newValues,
              resolve,
              reject,
            });
          })
        );
      }
    }
    return Promise.all(promiseArray)
      .then((results) => {
        console.log(" .... processed Scheduled Jobs selections.", results);
      })
      .then(
        () => {
          this.props.fetchScheduledEventJobs();
          this.props.goto(`/admin/scheduled-job/`);
        },
        (errorMsg) => {
          console.log("action failed", errorMsg); // #TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, levels, strings } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // APP_TERMINOLOGY
    let strEventJob = strings?.["Scheduled Job"];
    let strEventJobs = strings?.["Scheduled Jobs"];

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "eventJoblist10" },
              { value: 20, text: "20", key: "eventJoblist20" },
              { value: 40, text: "40", key: "eventJoblist40" },
              { value: 60, text: "60", key: "eventJoblist60" },
              { value: data.length, text: "all", key: "eventJoblistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const eventJobFromDataById = (id) => allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let addButton;
    let updateButton;
    let enableButton;
    let disableButton;
    let deleteButton;
    let copyButton;
    let eventJobSelectedList;

    addButton = (
      <Link to={`/admin/scheduled-job/new`}>
        <Button color="blue" content={strings?.["Add"]} size="large" />
      </Link>
    );

    updateButton = (
      <Popup
        content={strings?.["SCHEDULED_JOBS_LIST_UPDATE_POP_MSG"]}
        trigger={
          <Button
            color="orange"
            onClick={() => this.props.fetchScheduledEventJobs()}
            size="large"
          >
            {strings?.["Update"]}
          </Button>
        }
      />
    );

    if (selectedCount > 0) {
      let eventJobNames = [];

      for (var key in selections) {
        if (eventJobFromDataById(key) !== undefined) {
          let eventJobName = eventJobFromDataById(key).name;
          eventJobNames.push(eventJobName);
        }
      }
      eventJobNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      eventJobNames = eventJobNames.join(", "); // separate by commas

      enableButton = (
        <GenericButton
          label={strings?.["Enable"]}
          color="blue"
          onClick={() => this.requestAction("enable")}
          size="large"
        />
      );
      disableButton = (
        <GenericButton
          label={strings?.["Disable"]}
          color="orange"
          onClick={() => this.requestAction("disable")}
          size="large"
        />
      );
      deleteButton = (
        <DeleteButton
          size="large"
          onClick={() => this.requestAction("delete")}
          strings={strings}
        />
      );

      copyButton = (
        <GenericButton
          label={strings?.["Copy"]}
          color="brown"
          onClick={() => this.requestAction("copy")}
          size="large"
        />
      );

      eventJobSelectedList = (
        <Segment textAlign="left">
          <strong>{`${strEventJob} selected ...`} </strong>
          {eventJobNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={5} textAlign={"left"}>
                <Header as="h1">{strings?.["Scheduled Jobs"]}</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <>
                  <Button.Group>
                    {addButton}
                    <Button.Or size="large" />
                    {updateButton}
                  </Button.Group>
                  <span>
                    {
                      strings?.[
                        "Select items in the table below to apply an action"
                      ]
                    }
                  </span>
                </>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or size="large" />
                  {copyButton}
                  {<Button.Or size="large" />}
                  {deleteButton}
                  {false && <Button.Or size="large" />}
                  {false && enableButton}
                  {false && <Button.Or />}
                  {false && disableButton}
                </Button.Group>
              </div>
              <span
                style={{
                  marginLeft: "20px",
                  visibility: areAnySelected ? "visible" : "hidden",
                }}
              >
                {viewablesPage.length} items displayed. {selectedCount} selected
              </span>
            </Segment>
            {eventJobSelectedList}
          </Segment.Group>
          {levels.length === 0 && (
            <Message
              content={strings?.["No Area data available. Waiting..."]}
            />
          )}
          {levels.length > 0 && (
            <Tab
              //style={{ paddingTop: "1rem" }}
              // className={"????"}
              activeIndex={this.state.activeIndex}
              onTabChange={this.handleTabChange}
              menu={{
                attached: true,
                className: "tabTitleWrapped",
                tabular: true,
                //style: { display: "flex", justifyContent: "center" },
              }}
              panes={levels?.map((level) => {
                // data filter to level
                //const levelData = data.filter(item => item.area === level.id) || {};
                return {
                  menuItem: level.name,
                  render: () => (
                    <Tab.Pane>
                      <Table sortable celled striped key={hash(viewablesPage)}>
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell style={headerCellStyle}>
                              <Icon style={{ opacity: "0.7" }} name="edit" />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                className={
                                  filterInput?.disable
                                    ? "filterInputHighlight"
                                    : null
                                }
                                size="mini"
                                fluid
                                icon="search"
                                placeholder="Filter..."
                                name="disable"
                                onChange={this.handleFilterChange}
                                value={filterInput?.disable}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                className={
                                  filterInput?.name
                                    ? "filterInputHighlight"
                                    : null
                                }
                                size="mini"
                                fluid
                                icon="search"
                                placeholder="Filter..."
                                name="name"
                                onChange={this.handleFilterChange}
                                value={filterInput?.name}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                className={
                                  filterInput?.cronExpression
                                    ? "filterInputHighlight"
                                    : null
                                }
                                size="mini"
                                fluid
                                icon="search"
                                placeholder="Filter..."
                                name="cronExpression"
                                onChange={this.handleFilterChange}
                                value={filterInput?.cronExpression}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle} />
                            <Table.Cell style={headerCellStyle} />
                            {/* <Table.Cell style={headerCellStyle}>
                              <Input
                                className={
                                  filterInput?.active
                                    ? "filterInputHighlight"
                                    : null
                                }
                                size="mini"
                                fluid
                                icon="search"
                                placeholder="Filter..."
                                name="active"
                                onChange={this.handleFilterChange}
                                value={filterInput?.active}
                              />
                            </Table.Cell> */}
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                className={
                                  filterInput?.note
                                    ? "filterInputHighlight"
                                    : null
                                }
                                size="mini"
                                fluid
                                icon="search"
                                placeholder="Filter..."
                                name="note"
                                onChange={this.handleFilterChange}
                                value={filterInput?.note}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle} />
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell>
                              <Checkbox
                                checked={this.areAllSelected(viewablesPage)}
                                indeterminate={this.areAllIndeterminate(
                                  viewablesPage
                                )}
                                onChange={() =>
                                  this.handleSelectAll(viewablesPage)
                                }
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              width={1}
                              sorted={column === "disable" ? direction : null}
                              onClick={this.handleSort("disable")}
                            >
                              {strings?.["Disabled"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              width={4}
                              sorted={column === "name" ? direction : null}
                              onClick={this.handleSort("name")}
                            >
                              {strings?.["Name"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              width={4}
                              sorted={
                                column === "cronExpression" ? direction : null
                              }
                              onClick={this.handleSort("cronExpression")}
                            >
                              {strings?.["Start"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>
                              {strings?.["State"]}
                            </Table.HeaderCell>

                            <Table.HeaderCell width={1}>
                              {strings?.["Duration"]}
                            </Table.HeaderCell>
                            {/* <Table.HeaderCell
                              width={1}
                              sorted={column === "active" ? direction : null}
                              onClick={this.handleSort("active")}
                            >
                              {strings?.["Active"]}
                            </Table.HeaderCell> */}
                            <Table.HeaderCell
                              width={3}
                              sorted={column === "note" ? direction : null}
                              onClick={this.handleSort("note")}
                            >
                              {strings?.["Note"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              width={2}
                              sorted={column === "timestamp" ? direction : null}
                              onClick={this.handleSort("timestamp")}
                            >
                              {strings?.["Created"]}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {_.map(
                            viewablesPage,
                            ({
                              id,
                              disable,
                              nameLink,
                              active,
                              cronExpression,
                              duration,
                              jobCreated,
                              note,
                              style,
                              jobState,
                              jobStateClass,
                            }) => (
                              <Table.Row key={`row-id-${id}`} style={style}>
                                <Table.Cell>
                                  <Checkbox
                                    checked={this.isItemSelected(id)}
                                    onChange={() => this.handleSelect(id)}
                                  />
                                </Table.Cell>
                                <Table.Cell style={style}>{disable}</Table.Cell>
                                <Table.Cell style={style}>
                                  {nameLink}
                                </Table.Cell>
                                <Table.Cell style={style}>
                                  {cronExpression}
                                </Table.Cell>
                                <Table.Cell
                                  style={style}
                                  className={jobStateClass}
                                >
                                  {jobState}
                                </Table.Cell>
                                <Table.Cell style={style}>
                                  {duration}
                                </Table.Cell>
                                {/* <Table.Cell>{active}</Table.Cell> */}
                                <Table.Cell style={style}>{note}</Table.Cell>
                                <Table.Cell style={style}>
                                  {jobCreated}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                          <TrailingContent
                            data={data}
                            isLoading={isLoading}
                            error={error}
                          />
                        </Table.Body>
                      </Table>
                      {pagination}
                    </Tab.Pane>
                  ),
                };
              })}
            />
          )}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = ({ elements, strings, userLanguage }) => {
  let filteredElements = [];

  elements.forEach(function (element, idx) {
    const {
      active,
      activeJob,
      area,
      cron,
      cronActiveDuration,
      cronId,
      disable,
      eventJobId,
      name,
      note,
      password,
      timestamp,
      userRelay,
    } = element;

    console.log("xxx _prepData element", element);

    let jobCreated = "-";
    if (timestamp !== undefined) {
      // format - previous used in tableformatters.js
      jobCreated = formatRelative(timestamp * 1000, new Date(), {
        includeSeconds: true,
      });
    }

    let style = null;

    // change row if active
    //if (!active) style = { backgroundColor: "rgb(219, 40, 40, 15%)" };

    // set style of disabled jobs
    if (disable === true) {
      style = {
        // backgroundColor: "rgb(239, 239, 239, 0.3)",  // turn red-ish?
        //color: "rgb(0, 0, 0, 0.3)", // make the test "grey"
      };
    }

    // see - https://github.com/bradymholt/cRonstrue/tree/master/src/i18n/locales
    // for a list of supported locales
    // userLanguage will be e.g. "es-419" so strip off the locale
    let cronstrueLocale = "en";
    const userLocale = userLanguage?.split("-")?.[0] || "en";
    if (userLocale) cronstrueLocale = userLocale;

    const cronExpression = cronstrue?.toString(cron, {
      locale: cronstrueLocale,
      use24HourTimeFormat: true,
    });
    const duration = cronActiveDuration?.split(":")?.join(" ")?.toString();

    // display state of job
    // - QUEUED
    // - RUNNING...
    //
    let jobState = strings?.["QUEUED"];
    let jobStateClass = null;
    if (activeJob && !disable) {
      jobState = `${strings?.["RUNNING"]}...`;
      jobStateClass = "flashing jobStateBg";
    }

    // compose new data set
    filteredElements[idx] = {
      id: eventJobId,
      active: active ? strings?.["ACTIVE"] : strings?.["INACTIVE"],
      name,
      nameLink: (
        <Link to={`/admin/scheduled-job/edit/${eventJobId}`}>{name} </Link>
      ),
      cronExpression,
      duration,
      area,
      note,
      jobCreated,
      style,
      disable: disable ? strings?.["DISABLED"] : strings?.["ENABLED"],
      jobState,
      jobStateClass,
      timestamp: `${timestamp}`,
    };
  });

  return filteredElements;
};

function mapStateToProps(state, props) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  //const { isLoading, error } = state.eventJobs;
  const isLoading = false;
  const error = false;

  const allAreas = getAllAreaStatuses(state);

  let levels = [];
  let onlyActiveJobs = [];

  // create level information
  allAreas.forEach((area) => {
    const { id } = area;
    levels.push({ id: id, name: id });
  });

  const scheduledEventJobInfo = getScheduledEventJobInfo(state);

  //console.log("xxx scheduledEventJobInfo", scheduledEventJobInfo);

  onlyActiveJobs = scheduledEventJobInfo.filter((job) => job.active !== false);

  // only return one of each Even Job Id
  const uniqueKeys = [...new Set(onlyActiveJobs.map((job) => job.eventJobId))];

  // 'getScheduledEventJobInfo'  returns a line item for every cron job so filter
  // this to show unique job #s.
  let firstJobInOnlyActiveJobs = [];
  uniqueKeys.map((key) => {
    const first = onlyActiveJobs?.filter(
      (data) => data.eventJobId === key
    )?.[0];
    firstJobInOnlyActiveJobs.push(first);
  });

  //console.log("xxx onlyActiveJobs", onlyActiveJobs);
  //console.log("xxx onlyActiveJobs unique", firstJobInOnlyActiveJobs);

  const data = _prepData({
    elements: firstJobInOnlyActiveJobs,
    strings: props.strings,
    userLanguage: getUserData(state)?.language || "en-US",
  });

  //console.log("xxx data", data);
  //console.log("xxx data levels", levels);

  return {
    isLoading,
    error,
    data,
    levels,
    onlyActiveJobs,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    TurnOnOffPokeTheWorker,
    goto,
    fetchScheduledEventJobs,
    saveUserSettingsComponentState,
    deleteScheduledEventJob,
    saveNewScheduledEvent,
  })(ScheduledEventListAllJobs)
);
