import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as Api from "api/apiNamedAreaGroup";
import { isMqttNotApiPost } from "components/ConfigJs";

function* handleUpdateNamedAreaGroup(action) {
  const { values, resolve, reject } = action.payload;
  const { id, data } = values;

  try {
    const response = isMqttNotApiPost()
      ? yield yield Api.updateNamedAreaGroup(id, data)
      : yield Api.updateNamedAreaGroupPost(id, data);

    yield put({
      type: "UPDATE_NAMED_AREA_GROUP_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({
      type: "UPDATE_NAMED_AREA_GROUP_FAILED",
      message: error.message,
    });
    reject(error.message);
  }
}

function* saga() {
  yield takeEvery("UPDATE_NAMED_AREA_GROUP", handleUpdateNamedAreaGroup);
}
export default saga;
