import { delay } from 'redux-saga'
import { put, call, fork } from 'redux-saga/effects'

const SECS_PER_TICK = 15

function * generateTicks () {
  try {
    while (true) {
      yield call(delay, 1000 * SECS_PER_TICK)
      yield put({ type: 'STATUS_CHECK_TICK' })
    }
  } catch (error) {
    console.log('err', error)
  }
}

function * statusCheckSaga () {
  yield fork(generateTicks)
}

export default statusCheckSaga
