export function fetchSeismicLayersForMineLevel (mineLevelId) {
  return {
    type: 'MINE_LEVEL_ALL_SEISMIC_FETCH_REQUESTED',
    payload: { mineLevelId }
  }
}

export function fetchCurrentSeismicLayerForMineLevel (mineLevelId) {
  return {
    type: 'MINE_LEVEL_CURRENT_SEISMIC_FETCH_REQUESTED',
    payload: { mineLevelId }
  }
}
