import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Popup,
  Button,
  Menu,
  Ref,
  Header,
  Segment,
  Icon as SemIcon,
  Checkbox,
} from "semantic-ui-react";

import { connect } from "react-redux";
import Icon from "components/Icon";

import isEmpty from "lodash/isEmpty";

import { isActiveEvent } from "OperationalChanges/reducer";

import {
  showAreaPreview,
  stopAreaPreview,
  addAreaPreview,
  removeAreaPreview,
  clearAreaPreview,
  addAllAreaPreview,
} from "components/RegionPreview/actions";

import { getRegionPreviewList } from "components/RegionPreview/reducer";

import {
  getNamedAreaEventButtonGroupState,
  getAllAreaStatuses,
  getAllNamedAreaStatuses,
} from "components/WebWorker/reducer";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import {
  cancelNamedAreaEvent,
  activateNamedAreaEvent,
  updateButtonGroupState,
  waitEventTimeOut,
  removeRemoteButtonClick,
} from "OperationalChanges/actions";

import { getRemoteButtonClick } from "OperationalChanges/reducer";

import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import {
  appResponsive,
  isUserRelay as _isUserRelay,
  isPolygonShowHide,
} from "components/ConfigJs";
import MediaQuery from "react-responsive";
import { updateRemoteButtonClick } from "OperationalChanges/actions";
import { isKthBitSet } from "utils/bit-operations";

const ICON_DEFINITIONS = {
  isSirenIcon: "bullhorn",
  isExtTriggerIcon: "chain",
  isScheduledIcon: "clock outline",
};

export class SingleOperation extends Component {
  constructor(props) {
    super(props);
    this.contextRef = React.createRef();
    this[`${props.buttonRef}`] = React.createRef();
    this.state = {
      open: false,
      isRemoteButtonClick: false,
      appResponsive: appResponsive(),
      isUserRelay: false,
      isPolygonShowHide: isPolygonShowHide(),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_isEqual(JSON.stringify(this.props), JSON.stringify(nextProps)) ||
      this.state.open !== nextState.open ||
      this.props.remoteButtonClick?.length > 0
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // check if button is in 'remoteButtonClick'.
    // ATM this indicated to trigger the siren.
    //
    const remoteButtonClick = this.props?.remoteButtonClick;
    const prevRemoteButtonClick = prevProps?.remoteButtonClick;

    const isRemoteButtonClick =
      remoteButtonClick?.includes(this.props?.buttonRef) || false;
    // with update, if button is in 'remoteButtonClick' array then fire the click handler.
    //

    if (remoteButtonClick !== prevRemoteButtonClick && isRemoteButtonClick) {
      // set the local state to inform the button of isRemoteButtonClick (Siren)
      this.setState({ isRemoteButtonClick: isRemoteButtonClick }, () => {
        // https://stackoverflow.com/questions/40091000/simulate-click-event-on-react-element

        this[`${this.props.buttonRef}`].current.handleClick();
        this.props.removeRemoteButtonClick(this.props.buttonRef);
      });
    }
  }

  _onClick = (e, data) => {
    const { operation, area, buttonInfo } = data;

    if (false) {
      // debug .................

      console.log(
        "SingleOperation _onClick operation",
        new Date().getTime(),
        JSON.stringify(operation)
      );
      console.log("SingleOperation _onClick operation", operation);

      console.log(
        "SingleOperation _onClick this.state.isRemoteButtonClick",
        this.state.isRemoteButtonClick
      );

      console.log("SingleOperation _onClick area", area);
      console.log("SingleOperation _onClick area.spec", area.spec);
      console.log(
        "SingleOperation _onClick area.groupsArray",
        area.groupsArray
      );

      console.log("SingleOperation _onClick operation.type", operation.type);
      console.log(
        "SingleOperation _onClick operation.priority",
        operation.priority
      );
      console.log("SingleOperation _onClick operation.group", operation.group);
      console.log(
        "SingleOperation _onClick area.groupsArray[operation.group]",
        area.groupsArray[operation.group]
      );
      console.log(
        "SingleOperation _onClick area.groupsArray[operation.group].length",
        area.groupsArray[operation.group].length
      );

      console.log("SingleOperation _onClick buttonInfo", buttonInfo);

      // debug .................
    }

    // Note:
    //
    // Two cases:
    // 1 - whole mine event
    // In this case we simulate the whole mine by triggering a level wide event
    // for all areas
    // 2 - separate named areas
    // Separate named areas includes level wide events.

    // 1 - for ALL_AREAS
    if (area.spec === "ALL_AREAS") {
      // console.log("ALL_AREAS click operation", operation);
      // console.log("ALL_AREAS click area", area);
      // console.log("ALL_AREAS click buttonInfo", buttonInfo);

      // Note - this process sends an operation per mine level for whole mine events
      // ... however should instead use `ups/mine_wide_emergency` message
      if (false) {
        // find all named areas for the mine,
        // there is a level wide named area named after
        // each area name. i.e. area id = named area id
        const allAreas = this.props.allAreas;
        if (allAreas !== undefined || !isEmpty(allAreas)) {
          const namedAreas = allAreas.map((area) => area.id);
          namedAreas.forEach((namedArea) => {
            // change the operation, area and buttonInfo to simulate a level wide named event
            //
            let newOperation = operation;
            newOperation.id = namedArea;
            newOperation.named_area = namedArea;

            let newArea = area;
            newArea.id = namedArea;
            newArea.name = namedArea;
            newArea.spec = namedArea;

            // ...this is unchanged (for now)
            let newButtonInfo = buttonInfo;

            this.processClick({
              operation: newOperation,
              area: newArea,
              buttonInfo: newButtonInfo,
            });
          });
        }
      }
      if (true) {
        // Send mine_wide_emergency for Mine Wide buttons
        // `ups/mine_wide_emergency`
        // Sets all the firefly's to the same color and state for all devices mine wide
        // {
        // "color":  "RED,GREEN,AMBER,WHITE,BLUE,ZONE"
        // "state": "The Led flash state ON,OFF,STROBE,FORWARD,BACKWARD",
        // "level": 1,2,3,4
        // "clear": true / false
        // }

        this.processClick({
          operation: operation,
          area: area,
          buttonInfo: buttonInfo,
        });
      }
    } else {
      // 2 - process a named event single operation

      this.processClick({
        operation: operation,
        area: area,
        buttonInfo: buttonInfo,
      });
    }

    //
  };

  processClick = ({ operation: rxOperation, area, buttonInfo }) => {
    // clone operation as need to update properties later based on state (see 'relay_active')
    let operation = { ...rxOperation };

    // #NOTE - here there are two possible states for the button
    //
    // 1 - button is shown as active in the UI
    // 2 - button is show as inactive in the UI
    //
    // If only 1 button is shown as ACTIVE in a row (i.e. the buttonGroup) then this has an active event associated with it.
    // If >1 button is shown as ACTIVE in a row, then the highest priority button will have the current active event.
    // Buttons in a row are in order of priority. Left is lowest, right is highest.
    //
    // Buttons are drawn in button groups.
    //
    // Consider a named area button group with three separate groups.
    // Three buttons in the 1st, one in 2nd, 2 in 3rd.
    //
    // Group  0        - 1 -  2
    //
    //        [0][1][2]-[0]-[0][1]
    //
    // Group:Button 0:2 may be selected, but if 1:0 or say 2:1 is selected then the event is associated with the most recent
    // button press (2:1) but buttons 0:2, 1:0 and 2:1 will all be shown as active in the UI (i.e. a solid icon).
    //
    // isActiveEvent - indicates if the event is active on that button
    // isActiveButton - indicates if the button is shown as active in the UI

    const namedAreaParent = area.spec;
    const namedAreaPriority = operation.priority;
    const selectedNamedAreaButtonGroups = area.groupsArray;

    // update buttons group state from the click (on a button!)
    const buttons = this.props.buttonGroupState;
    let newButtons = JSON.parse(JSON.stringify(buttons));

    // #DEBUG messages
    if (false) {
      console.log("processClick operation", operation);
      console.log("processClick area", area);
      console.log("processClick buttonInfo", buttonInfo);
      console.log(
        "processClick newButtons - updated named area event group state",
        newButtons
      );
      console.log("processClick namedAreaParent", namedAreaParent);
      console.log(
        "processClick newButtons[namedAreaParent]",
        newButtons[namedAreaParent]
      );
    }

    if (!newButtons.hasOwnProperty(namedAreaParent)) {
      // catch error if no button group state for button
      //
      // set default button group
      newButtons[namedAreaParent] = { 0: -1 };
    }

    const isActiveButton =
      newButtons[namedAreaParent][buttonInfo.group] === buttonInfo.index;

    const isActiveEvent = this.props.isActive;

    let prevRelayActive = operation.relay_active;

    const isRemoteButtonClick = this.state.isRemoteButtonClick;
    // console.log(
    //   "processClick isResendEvent prevRelayActive",
    //   `${prevRelayActive}`
    // );

    // see - 'relay-active'
    if (isRemoteButtonClick) {
      // #WIP - isRemoteClick needs to be expanded to passing relay_active object
      operation.relay_active = 1; // #NOTE = changes to bit operation
      // remote click is 'acknowledged' so clear flag
      this.setState({ isRemoteButtonClick: false });
    } else {
      // #WIP - isRemoteClick needs to be expanded to passing relay_active object
      operation.relay_active = 0; // #NOTE = changes to bit operation
    }

    if (false) {
      console.log(
        "processClick isResendEvent isRemoteButtonClick ",
        isRemoteButtonClick
      );

      console.log("processClick isResendEvent isActiveEvent", isActiveEvent);
      console.log("processClick isResendEvent isActiveButton", isActiveButton);
      console.log(
        "processClick isResendEvent prevRelayActive",
        prevRelayActive
      );
      console.log(
        "processClick isResendEvent operation.relay_active",
        operation.relay_active
      );
    }

    // check for remote control/resend event trigger
    let isResendEvent = false;
    if (
      isActiveEvent &&
      isActiveButton &&
      prevRelayActive !== operation.relay_active &&
      isRemoteButtonClick
    ) {
      isResendEvent = true;

      console.log(
        `isResendEvent - processClick resend event with operation.relay_active = '${
          operation.relay_active
        }' (don't toggle button from '${isActiveButton}' ->  '${!isActiveButton}'. resendEvent = ${isResendEvent} )`
      );
    }

    // Gets the next highest buttons
    // step through all the button event states and see if any others are active (from highest to lowest priority)
    // return the highest button (if any)
    //
    // e.g. nextPriorityEventOperation = getNextPriorityEventOperation(newButtons[namedAreaParent], selectedNamedAreaButtonGroups)
    const getNextPriorityEventOperation = (buttons, selectedButtonGroups) => {
      let nextPriorityEventOperation = {};
      let group = Object.keys(buttons).length;
      while (group--) {
        // go backward from highest to lowest priority
        const groupButtonSet = buttons[group];
        if (groupButtonSet !== -1) {
          nextPriorityEventOperation =
            selectedButtonGroups[group][groupButtonSet];
          break;
        }
      }
      return nextPriorityEventOperation;
    };

    // Sets redux event to pause collection of updated events from mqtt
    // This allows the button click to change locally for the wait period,
    // and for the event send to update the broker state and
    // confirm the action
    const waitEventTimeOut = (seconds) => {
      this.props.waitEventTimeOut("start");
      setTimeout(() => {
        this.props.waitEventTimeOut("stop");
      }, seconds); // WAIT_EVENT_TIMEOUT
    };

    // ********************************
    //
    // MAIN processing
    //
    // ********************************
    //
    // only if button group is defined (may be undefined on startup)
    //
    if (
      newButtons[namedAreaParent] !== undefined ||
      !isEmpty(newButtons[namedAreaParent])
    ) {
      // initialise button group records data is inconsistent
      if (
        Object.keys(newButtons[namedAreaParent])?.length !==
        selectedNamedAreaButtonGroups?.length
      ) {
        newButtons[namedAreaParent] = {};
        for (let i = 0; i < selectedNamedAreaButtonGroups.length; i++) {
          newButtons[namedAreaParent][i] = -1;
        }
      }

      if (isActiveButton) {
        //
        // Clicked button is active.
        // Could be active because it is an active event &/or is an active button selection
        //
        // if it's an activeEvent and activeButton, then toggle the event off, then check if there is another
        // event which should be sent by checking what the next priority event is.
        //
        // if it's *not* an activeEvent and activeButton, then update the button state, and *resend* current event.
        // Resending the current event sends the updated button group state to all clients.
        // Also (perhaps) reinforces the event _BUT_ perhaps sends unnecessary event traffic.
        //
        //
        // #REVIEW -
        // As per above point about unnecessary event traffic, could consider:
        // ? - send a separate mqtt message which contains the button group information
        // ? - send the button group information via the sever/db. This process is similar to what happens in v2
        //
        //
        //

        //console.log("processClick isResendEvent", isResendEvent);

        // isActiveButton and not a resendEvent then update the button group, otherwise everything stays the same
        if (!isResendEvent) {
          //console.log("processClick isResendEvent - updatebuttons");

          // isActiveButton so toggle it off. i.e. set button group as -1 denotes no button set
          newButtons[namedAreaParent][buttonInfo.group] = -1;

          //console.log("kkk updatebuttons newButtons", newButtons);
          this.props.updatebuttons(newButtons);
        }

        if (isActiveEvent) {
          if (isResendEvent) {
            // isActiveButton and isActiveEvent but operation has changed so don't cancel (i.e. toggle) the event, just resend it
            // Don't look for next highest event, just resend the current state with a new operation.
            // rather, just resend the event with updated operation

            // console.log(
            //   "processClick isResendEvent activate - operation",
            //   operation
            // );

            waitEventTimeOut(1000);
            this.props.activate(operation);
          } else {
            // isActiveButton and isActiveEvent so cancel the active event....

            // console.log(
            //   "processClick isResendEvent cancel - operation",
            //   operation
            // );

            waitEventTimeOut(1000);
            this.props.cancel(operation);

            // ok, we cancelled an event, so check if should there be
            // another event to activate?
            let nextPriorityEventOperation = getNextPriorityEventOperation(
              newButtons[namedAreaParent],
              selectedNamedAreaButtonGroups
            );

            // console.log(
            //   "processClick isResendEvent activate - operation",
            //   nextPriorityEventOperation
            // );

            // if there is a next highest event (i.e. an active button) activate it
            if (!isEmpty(nextPriorityEventOperation)) {
              waitEventTimeOut(1000);
              this.props.activate(nextPriorityEventOperation);
            }
          }
        } else {
          // !isActiveEvent
          //
          // This button has *no* active event, so find any current event and resend it.
          //
          // If there is a next highest event (i.e. an active button) *resend* (i.e. re-activate) it.
          //
          // #NOTE
          // * - didn't bother checking if the event is active since if the button is active, and it's a higher priority then
          // it should be activated anyway.
          //
          let nextPriorityEventOperation = getNextPriorityEventOperation(
            newButtons[namedAreaParent],
            selectedNamedAreaButtonGroups
          );

          // console.log(
          //   "processClick isResendEvent activate - operation",
          //   nextPriorityEventOperation
          // );

          if (!isEmpty(nextPriorityEventOperation)) {
            waitEventTimeOut(1000);
            this.props.activate(nextPriorityEventOperation);
          }
        }
      } else {
        // !isActiveButton
        //
        // not an active button so update the button state in the button group, making it active
        //
        newButtons[namedAreaParent][buttonInfo.group] = buttonInfo.index;
        //console.log("kkk updatebuttons newButtons", newButtons);
        this.props.updatebuttons(newButtons);

        // ok, so we've clicked the button, but is there already an existing event ongoing at a higher priority?
        // if so then we don't need to trigger this event.
        let nextPriorityEventOperation = getNextPriorityEventOperation(
          newButtons[namedAreaParent],
          selectedNamedAreaButtonGroups
        );
        // if the next highest event is this event operation then fire away!
        if (namedAreaPriority >= nextPriorityEventOperation.priority) {
          // console.log(
          //   "processClick isResendEvent activate - operation",
          //   nextPriorityEventOperation
          // );

          waitEventTimeOut(1000);
          this.props.activate(operation);
        } else {
          // resend the highest priority event.
          // This is primarily to send around the button group state changes for
          // other buttons but also to reinforce the event.

          // console.log(
          //   "processClick isResendEvent activate - operation",
          //   nextPriorityEventOperation
          // );

          waitEventTimeOut(1000);
          this.props.activate(nextPriorityEventOperation);
        }
      }
    }
  };

  render() {
    const {
      area,
      operation,
      isActive,
      key,
      buttonInfo,
      buttonGroupState,
      displaySettings,
      title,
      maxLabelLength,
      regionPreviewList,
      disabled,
      userSettings,
    } = this.props;

    const { appResponsive, isPolygonShowHide } = this.state;

    const isUserRelay =
      userSettings?.feature?.includes("showUserRelay") || _isUserRelay();

    //console.log("SingleOperation re-render ", new Date().getTime());

    const namedAreaParent = area.spec;
    let buttonActive = false;
    let buttonEnabled = true; // #WIP - should default to false

    // #DEBUG messages
    if (false) {
      console.log(
        "SingleOperation buttonGroupState",
        JSON.stringify(buttonGroupState)
      );
      console.log("SingleOperation area", area);
      console.log("SingleOperation operation", operation);
      console.log("SingleOperation buttonInfo", buttonInfo);
    }

    if (!isEmpty(buttonGroupState)) {
      if (
        buttonGroupState[namedAreaParent] !== undefined &&
        !isEmpty(buttonGroupState[namedAreaParent])
      ) {
        // DEBUG msgs
        if (false) {
          console.log("buttonActive namedAreaParent", namedAreaParent);

          console.log("buttonActive buttonGroupState", buttonGroupState);
          console.log(
            "buttonActive buttonGroupState[namedAreaParent]",
            buttonGroupState[namedAreaParent]
          );
          console.log("buttonActive buttonInfo.group", buttonInfo.group);
          console.log(
            "buttonActive buttonGroupState[namedAreaParent][buttonInfo.group]",
            buttonGroupState[namedAreaParent][buttonInfo.group]
          );
          console.log("buttonActive buttonInfo.index", buttonInfo.index);
          console.log("buttonActive ----------");
        }
        // button is active (i.e. enabled) in named area setup
        // assume active if set active or state is undefined
        buttonEnabled = buttonInfo.enable || buttonInfo.enable === undefined;

        // consider button active to send events if
        // 1 - the index is value
        // 2  - the button is enabled in setup of named area buttons

        buttonActive =
          buttonGroupState[namedAreaParent][buttonInfo.group] ===
            buttonInfo.index && buttonInfo.index !== -1;
        //&& buttonEnabled;

        // #DEBUG msgs
        if (false) {
          console.log(
            "buttonActive Single --------------------------------------------------------"
          );
          console.log(
            "buttonActive Single --------",
            JSON.stringify(buttonInfo),
            "---",
            JSON.stringify(buttonGroupState[namedAreaParent]),
            " ----------"
          );
          console.log(
            "buttonActive Single --------------------------------------------------------"
          );
          console.log("buttonActive Single buttonEnabled", buttonEnabled);
          console.log("buttonActive Single buttonActive", buttonActive);
          console.log("buttonActive Single buttonInfo", buttonInfo);
          console.log("buttonActive Single buttonInfo.index", buttonInfo.index);
          console.log("buttonActive Single namedAreaParent ", namedAreaParent);
          console.log("buttonActive Single buttonInfo.group", buttonInfo.group);
          console.log("buttonActive Single buttonGroupState", buttonGroupState);
          console.log(
            "buttonActive Single buttonGroupState[namedAreaParent]",
            buttonGroupState[namedAreaParent]
          );
          console.log(
            "buttonActive Single buttonGroupState[namedAreaParent][buttonInfo.group]",
            buttonGroupState[namedAreaParent][buttonInfo.group]
          );
        }
      }
    }

    const buttonColor = (color) => {
      switch (color) {
        case "red":
          return "red";
        case "orange":
        case "amber":
          return "orange";
        case "white":
          return "white";
        case "blue":
          return "blue";
        case "no_change":
          return "grey";
        case "off":
          return "black";
        default:
          // not "GREEN" as used by the FF controller but "green" used by the semantic ui CSS
          return color.toLowerCase(); // #REVIEW - this shouldn't be necessary as I thought I'd caught these previously but THIS MAKES SURE!
      }
    };

    const color = buttonColor(operation.color) || "red";

    //    console.log("color", operation, color);

    //    const iconColor = isActive && buttonActive ? "white" : color;

    const iconColor = buttonActive ? "white" : color;
    const iconName = operation.icon;
    const iconOpacity = buttonEnabled ? "1" : "0.2";
    const buttonStyle = { padding: "0.5em", opacity: iconOpacity };

    const { hint: isShowHint, view } = displaySettings;

    let size = "medium";
    switch (view) {
      case "compressed":
        size = "mini";
        break;
      case "normal":
        size = "medium";
        break;
      case "expanded":
        size = "huge";
        break;
      default:
        size = "medium";
        break;
    }

    // override size for top header icons
    if (area.type === "WHOLE_MINE") {
      size = "massive";
    }

    // if LEVEL_WIDE make one size bigger than selected
    if (area.type === "MINE_LEVEL") {
      const sizes = [
        "mini",
        "tiny",
        "small",
        "medium",
        "large",
        "big",
        "huge",
        "massive",
      ];
      size = sizes[sizes.indexOf(size) + 1];
    }

    const createContextFromEvent = (e) => {
      const left = e.clientX;
      const top = e.clientY;
      const right = left + 1;
      const bottom = top + 1;
      return {
        getBoundingClientRect: () => ({
          left,
          top,
          right,
          bottom,

          height: 0,
          width: 0,
        }),
      };
    };

    const handleRelayUserCheck = (e, data) => {
      const { label, checked, stringNamedAreaParentPriority } = data;

      //console.log(`handleRelayUserCheck label, checked`, label, checked);
      //console.log(`handleRelayUserCheck data`, data);

      this.props.updateRemoteButtonClick([stringNamedAreaParentPriority]);

      // if (checked) {
      //   setSelection([...selection, label]);
      // } else {
      //   setSelection(selection.filter((el) => el !== label));
      // }
    };

    const handleItemClick = (event, data) => {
      const { name: item, area } = data;
      const { id, name, namedArea, spec, type } = area;
      const {
        showAreaPreview,
        addAreaPreview,
        removeAreaPreview,
        clearAreaPreview,
        addAllAreaPreview,
        regionPreviewList,
        allAreas,
      } = this.props;

      // create array of all named areas polygons
      const currentArea = allAreas.find((area) => area.id === id);
      let allNamedAreaPolygons = [];

      if (!_isEmpty(currentArea?.namedAreaGroup)) {
        currentArea.namedAreaGroup.forEach((row, index) => {
          const { subItems } = row;
          subItems.map(({ label, subItems }, index) => {
            subItems.map(({ label, settings, namedArea }) => {
              const area = {
                spec: namedArea.id,
                type: "Polygon",
                id: id,
                namedArea: label,
              };
              allNamedAreaPolygons.push(area);
            });
          });
        });
      }

      const previewState = { id, name, namedArea, spec, type };

      // console.log(` handleItemClick data`, data);
      // console.log(` handleItemClick previewState`, previewState);
      // console.log(` handleItemClick regionPreviewList`, regionPreviewList);
      // console.log(
      //   ` handleItemClick allNamedAreaPolygons`,
      //   allNamedAreaPolygons
      // );

      const isPreview = regionPreviewList.find(
        (preview) => preview.spec === spec
      );

      switch (item) {
        case "show":
          showAreaPreview(previewState);
          break;
        case "add":
          if (!isPreview) {
            addAreaPreview(previewState);
          }
          break;
        case "remove":
          if (isPreview) {
            removeAreaPreview(previewState);
          }
          break;
        case "showAll":
          addAllAreaPreview(allNamedAreaPolygons);
          break;
        case "clear":
          clearAreaPreview();
          break;
        case "list":
          //console.log(` handleItemClick regionPreviewList`, regionPreviewList);
          break;
        default:
          break;
      }

      this.setState({ open: false });
    };

    const isPreview = regionPreviewList.find(
      (preview) => preview.spec === area.spec
    );

    const isAnyPreview = !_isEmpty(regionPreviewList);

    // APP_TERMINOLOGY
    const strNamedArea = "Polygon";
    const strNamedAreas = "Polygons";

    const { mobile, tablet, laptop, desktop } = appResponsive;
    const isDesktopOrLaptop = tablet; // (min-width: 1351px)
    const isTabletOrMobileDevice = tablet; // 1350 (max-width: 1350px)

    // Setup display of icons
    //
    // There are current three icons which append to buttons to indicate the active state of:
    // * siren - isSirenIcon
    // * external trigger - isExtTriggerIcon
    // * scheduled event -  isScheduledIcon

    let displayIcons = [];

    // isSirenIcon - determined from review of current event
    // siren is active if there is a namedAreaParent with relay_event_active = 1 in the level
    // See - src/pages/ControlRoomPage/MineLevelCustomSOPList.js
    // Search "// Show siren icon active?"

    const isSirenIcon = isKthBitSet(operation.relay_active, 1) || false;
    if (isSirenIcon) {
      displayIcons.push("isSirenIcon");
    }

    const isExtTriggerIcon =
      operation?.origin?.includes("ext_trigger_event") === true;

    if (isExtTriggerIcon) {
      displayIcons.push("isExtTriggerIcon");
    }

    const isScheduledEventIcon =
      operation?.origin?.includes("scheduled_event") === true;

    if (isScheduledEventIcon) {
      displayIcons.push("isScheduledIcon");
    }

    const isButtonIcons = displayIcons.length;

    // button length changes to accommodate additional icons
    const buttonWidth = isButtonIcons
      ? maxLabelLength + displayIcons.length * 3 + 3
      : maxLabelLength + 3; // add a 'bit' more to compensate for non-monospace fonts

    //console.log("xxx displayIcons, operation", displayIcons, operation);

    return (
      <>
        <MediaQuery maxWidth={isTabletOrMobileDevice}>
          <Button
            ref={this[`${this.props.buttonRef}`]}
            //disabled={!buttonEnabled}
            size={"massive"} // mobile view has bigggg buttons
            key={key}
            color={color}
            icon={isButtonIcons}
            operation={operation}
            area={area}
            buttonInfo={buttonInfo}
            onClick={this._onClick}
            basic={!buttonActive}
            style={{
              padding: "0.5em",
              marginBottom: "5px",
              marginRight: "8px",
              width: `${buttonWidth}ch`,
              height: "2em",

              // #NOTE #BUTTON_STYLE - styling to display button wrapped on level
              display: "flex",
            }}
            disabled={disabled}
          >
            {title}
            <div style={{ marginLeft: "auto" }}>
              {displayIcons.map((displayIcon) => (
                <SemIcon
                  name={ICON_DEFINITIONS[displayIcon]}
                  inverted={buttonActive}
                  color={iconColor}
                />
              ))}
            </div>
          </Button>
        </MediaQuery>
        <MediaQuery minWidth={isDesktopOrLaptop}>
          <>
            <Ref innerRef={this.contextRef}>
              <Button
                ref={this[`${this.props.buttonRef}`]}
                // context menu support
                onContextMenu={(e) => {
                  e.preventDefault();
                  this.contextRef.current = createContextFromEvent(e);
                  this.setState({ open: true });
                }}
                size={size}
                key={key}
                color={color}
                icon={isButtonIcons}
                operation={operation}
                area={area}
                buttonInfo={buttonInfo}
                onClick={this._onClick}
                basic={!buttonActive}
                style={{
                  padding: "0.5em",
                  marginBottom: "5px",
                  width: `${buttonWidth}ch`,
                  height: "2em",

                  // #NOTE #BUTTON_STYLE - styling to display button wrapped on level
                  display: "flex",
                }}
                disabled={disabled}
              >
                {title}
                <div style={{ marginLeft: "auto" }}>
                  {displayIcons.map((displayIcon) => (
                    <SemIcon
                      name={ICON_DEFINITIONS[displayIcon]}
                      inverted={buttonActive}
                      color={iconColor}
                    />
                  ))}
                </div>
              </Button>
            </Ref>
            <Popup
              basic
              context={this.contextRef}
              onClose={() => this.setState({ open: false })}
              open={this.state.open}
              position={"top left"}
            >
              {area.namedArea !== title && (
                <Header
                  style={{ paddingLeft: "0.5em" }}
                >{`${title} (${area.namedArea})`}</Header>
              )}
              {area.namedArea === title && (
                <Header style={{ paddingLeft: "0.5em" }}>{title}</Header>
              )}

              <Menu vertical>
                <Menu.Item>
                  <Menu.Header>Polygon</Menu.Header>

                  <Menu.Menu>
                    <Menu.Item
                      as={Link}
                      header
                      to={`/admin/named-area/region/edit/${area.spec}`}
                    >
                      <SemIcon name="edit" />
                      Edit {strNamedArea}
                    </Menu.Item>
                    {isPolygonShowHide && !isPreview && (
                      <Menu.Item
                        name="add"
                        area={area}
                        //active={activeItem === 'editorials'}
                        onClick={handleItemClick}
                      >
                        <SemIcon name="eye" />
                        Show {strNamedArea}
                      </Menu.Item>
                    )}
                    {isPolygonShowHide && isPreview && (
                      <Menu.Item
                        name="remove"
                        area={area}
                        //active={activeItem === 'editorials'}
                        onClick={handleItemClick}
                      >
                        <SemIcon name="eye slash" />
                        Hide {strNamedArea}
                      </Menu.Item>
                    )}
                    {isPolygonShowHide && (
                      <>
                        <Menu.Item
                          name="showAll"
                          area={area}
                          //active={activeItem === 'editorials'}
                          onClick={handleItemClick}
                        >
                          <SemIcon name="sun" />
                          Show All {strNamedAreas}
                        </Menu.Item>
                        <Menu.Item
                          name="clear"
                          area={area}
                          //active={activeItem === 'editorials'}
                          onClick={handleItemClick}
                          disabled={!isAnyPreview}
                        >
                          <SemIcon name="repeat" />
                          Hide All {strNamedAreas}
                        </Menu.Item>
                      </>
                    )}
                    {/* <Menu.Item
                  name="list"
                  area={area}
                  //active={activeItem === 'editorials'}
                  onClick={handleItemClick}
                >
                  <SemIcon name="list" />
                  List Regions to Console
                </Menu.Item> */}
                  </Menu.Menu>
                </Menu.Item>
                {isUserRelay && (
                  <Menu.Item>
                    <Menu.Header>User Relay</Menu.Header>
                    <Menu.Menu>
                      <Menu.Item>
                        <Checkbox
                          style={{ fontSize: "1em" }}
                          label={`Siren`}
                          name="siren"
                          area={area}
                          operation={operation}
                          stringNamedAreaParentPriority={`${operation.named_area}:${operation.priority}`}
                          checked={isKthBitSet(operation?.relay_active, 1)}
                          onChange={handleRelayUserCheck}
                        />
                      </Menu.Item>
                      {/* <Menu.Item>
                      <Checkbox
                        style={{ fontSize: "1em" }}
                        label="Boomgate"
                        name="boomgate"
                        //checked={true}
                        onChange={handleRelayUserCheck}
                      />
                    </Menu.Item>
                    <Menu.Item>
                      <Checkbox
                        style={{ fontSize: "1em" }}
                        label="Fan"
                        name="fan"
                        //checked={true}
                        onChange={handleRelayUserCheck}
                      />
                    </Menu.Item> */}
                    </Menu.Menu>
                  </Menu.Item>
                )}
              </Menu>
              {false && isShowHint && (
                <Segment>
                  External Trigger MQTT Message {operation.named_area}:
                  {operation.priority}
                </Segment>
              )}
            </Popup>
          </>
        </MediaQuery>
      </>
    );
  }
}

SingleOperation = connect(
  () => {
    return (state, props) => {
      const isActive = isActiveEvent(state, props);
      const buttonGroupState = getNamedAreaEventButtonGroupState(state);
      const allAreas = getAllAreaStatuses(state);
      // const namedAreaDisplaySettings = getNamedAreaDisplaySettings(state);

      const regionPreviewList = getRegionPreviewList(state);

      return {
        isActive,
        buttonGroupState,
        allAreas,
        //namedAreaDisplaySettings,
        regionPreviewList,
        remoteButtonClick: getRemoteButtonClick(state),
      };
    };
  },
  (dispatch, props) => {
    // const { area, operation } = props;
    // const activate = buildActivateChange(area, operation);
    return {
      activate: (operation) => dispatch(activateNamedAreaEvent(operation)),
      cancel: (operation) => dispatch(cancelNamedAreaEvent(operation)),
      updatebuttons: (buttons) => dispatch(updateButtonGroupState(buttons)),
      waitEventTimeOut: (action) => dispatch(waitEventTimeOut(action)),
      showAreaPreview: (area) => dispatch(showAreaPreview(area)),
      stopAreaPreview: (area) => dispatch(stopAreaPreview(area)),
      addAreaPreview: (area) => dispatch(addAreaPreview(area)),
      removeAreaPreview: (area) => dispatch(removeAreaPreview(area)),
      clearAreaPreview: () => dispatch(clearAreaPreview()),
      addAllAreaPreview: (allPolygonsArray) =>
        dispatch(addAllAreaPreview(allPolygonsArray)),
      removeRemoteButtonClick: (id) => dispatch(removeRemoteButtonClick(id)),
      updateRemoteButtonClick: (clickList) =>
        dispatch(updateRemoteButtonClick(clickList)),
    };
  }
)(SingleOperation);

// function areEqual(prevProps, nextProps) {
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */
//   console.log("nextProps", nextProps);
//   //console.log("areEqual", _isEqual(nextProps, prevProps));

//   const {
//     area: prevArea,
//     displayAllOnMineLevel: prevDisplayAllOnMineLevel,
//     displaySettings: prevDisplaySettings,
//   } = prevProps;
//   const {
//     area: nextArea,
//     displayAllOnMineLevel: nextDisplayAllOnMineLevel,
//     displaySettings: nextDisplaySettings,
//   } = nextProps;

//   //return _isEqual(nextProps, prevProps);

//   return true; //_isEqual(nextArea, prevArea);
// }

//export const PrecannedOperations = React.memo(_PrecannedOperations, areEqual);
function PrecannedOperations({
  area,
  //options, // #TODO - return BUTTON GROUP for all named areas .........
  floated,
  displayAllOnMineLevel = false,
  //size = "medium",
  displaySettings,
  userSettings,
  ...props
}) {
  const { view } = displaySettings;
  let size = "medium";
  switch (view) {
    case "compressed":
      size = "mini";
      break;
    case "normal":
      size = "medium";
      break;
    case "expanded":
      size = "huge";
      break;
    default:
      size = "medium";
      break;
  }
  // override size for top header icons
  if (area.id === "ALL_AREAS") {
    size = "massive";
  }

  // if LEVEL_WIDE make one size bigger than selected
  if (area.type === "LEVEL_WIDE") {
    const sizes = [
      "mini",
      "tiny",
      "small",
      "medium",
      "large",
      "big",
      "huge",
      "massive",
    ];
    size = sizes[sizes.indexOf(size) + 1];
  }

  return area.groupsArray.map((group) => {
    return createGroup({
      area,
      floated,
      size,
      group,
      displaySettings,
      userSettings,
    });
  });
}

const createGroup = ({
  area,
  floated,
  size,
  group,
  displaySettings,
  userSettings,
}) => {
  return (
    <Button.Group
      floated={floated}
      compact
      size={size}
      style={{ marginRight: "3px" }}
    >
      {group.map((option, idx) => {
        return (
          <SingleOperation
            key={`${area.name}-${option.type}-${idx}`}
            buttonInfo={{
              enable: option.active,
              group: option.group,
              index: idx,
            }}
            area={area}
            operation={option}
            displaySettings={displaySettings}
            userSettings={userSettings}
          />
        );
      })}
    </Button.Group>
  );
};

const mapStateToProps = (state, props) => {
  return {
    displaySettings: getNamedAreaDisplaySettings(state),
  };
};

export default connect(mapStateToProps)(PrecannedOperations);
