// change colors to web colors
export function toWebColor(color) {
  const lowerCaseColor = color?.toLowerCase();
  // catch the exceptions 1st
  switch (lowerCaseColor) {
    case "amber":
      return "orange";
    case "white":
      return "grey";
    // return everything else as-is
    case lowerCaseColor:
      return lowerCaseColor;
    default:
      return "grey";
  }
}
