import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Header, Grid } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

class LogPage extends Component {
  render() {
    const { strings } = this.props;

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["System Log"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <object
            data="/log.txt"
            frameborder="0"
            height="500"
            width="100%"
          ></object>
        </Container>
      </div>
    );
  }
}

export default connect(null)(LogPage);
