import React, { Component, useDebugValue } from "react";
import { connect } from "react-redux";

import {
  Form,
  Container,
  Label,
  Header,
  Grid,
  Message,
  Segment,
  Table,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging

import { push } from "react-router-redux";

import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import ChooseMineLevelField from "components/ChooseMineLevelField";

import { deleteNamedArea } from "NamedAreas/actions";
import { renderField } from "admin/form-field";
import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import { reset } from "testdouble";

import NamedAreaTable from "admin/named-area/NamedAreaTable";

import { GetLocalMap } from "components/Map/reducer";
import { ClearLocalMap } from "components/Map/actions";

import Map from "admin/named-area/NamedAreaMap";

import {
  getNamedAreaStatusesByAreaId,
  getAllNamedAreaStatuses,
} from "components/WebWorker/reducer";

// based on
// - https://embed.plnkr.co/Gvei5I0S9yEo6fCYXPuy/
// see -> <Map /> from "./TestMapTilesMap";

// --------------------------------------
// Marker not appearing for Simple Example
// Fix - https://github.com/PaulLeCam/react-leaflet/issues/453
//
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// --------------------------------------
// HACKfix: Use zIndex to stop leaflet map from being above the Semantic-UI dropdown menus!
const DEFAULT_MAP_STYLE = {
  height: "800px",
  width: "100%",
  position: "relative",
  zIndex: 0,
};

const validate = (values) => {
  const required = ["namedAreaIdValue", "mineLevelId"];

  //console.log(`xxxx validate values `, values);

  const errors = {};

  required.forEach((r) => {
    if (!get(values, r)) {
      set(errors, r, "Required");
    }
  });

  //console.log("@@ validate errors", errors);

  return errors;
};

const normalise = (value, type) => {
  if (type === "noSpaces") {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

const required = (value) => (value ? undefined : "Required");
const isNamedAreaNameOK = (value, allValues, props, name) => {
  const { namedAreaIdValue: id } = allValues;

  const { uniqueNamedAreaParentIds } = props;
  const valueNoSpace = value.replace(" ", "_");

  const notIncludingThisId = uniqueNamedAreaParentIds
    .filter((item) => !item.id.includes(id))
    .map((item) => item.parentName);

  //console.log(`notIncludingThisId `, notIncludingThisId);

  if (notIncludingThisId?.includes(valueNoSpace)) {
    return "This name has already been used";
  } else {
    return undefined;
  }
};

class EditNamedAreaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLoading: true,
      errorMessage: "",
      id: props.id,
    };
  }

  // #REVIEW -
  //  Render issue - with each heartbeat the STATE updates which causes localPts to be updated and so resets the map state

  //   This stops re-rendering the map unnecessarily
  // #WIP

  componentDidMount() {
    // clear the map from any previous edits
    this.props.ClearLocalMap();

    // #WIP - set temporarily
    this.setState({ contentLoading: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // update component when state changes, not when props change or
    // initial props will clobber form edits

    // console.log("shouldComponentUpdate this.props", this.props);
    // console.log("shouldComponentUpdate nextProps", nextProps);

    return !isEqual(this.props, nextProps); //!isEqual(this.state, nextState); //!isEqual(this.props, nextProps);
  }

  requestDelete = (id) => {
    const { handleSubmit, deleteNamedArea, push } = this.props;

    console.log("requestDelete", handleSubmit, deleteNamedArea, push);
    console.log("WIP.......");
  };

  // onSubmit = (e) => {
  //   e.preventDefault();
  //   this.props.handleSubmit(this); // calls prop.onSubmit()
  // };

  submitMyForm = (values) => {
    //console.log(`form submitted: values`, values);
    this.props.onSubmit(values);
  };

  resetForm = (e) => {
    e.preventDefault();
    this.props.ClearLocalMap();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // abort page changes
    this.props.onCancel();
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      //reset,
      mineLevelId,
      namedAreaIdValue,
      namedAreaParentName,
      strings,
    } = this.props;

    // APP_TERMINOLOGY
    const strNamedArea = "Polygon";
    const strNamedAreas = "Polygons";

    const { id } = this.state;

    if (id === undefined) {
      return (
        <>
          <DataLoadingMessage id={id} strings={strings} />
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    const refLocationsComponent = (mineLevelId) => {
      if (!mineLevelId) {
        return <div>Must select mine area</div>;
      }
      return (
        <div>
          <Map
            style={DEFAULT_MAP_STYLE}
            render="single"
            parentState={this.state}
            mineLevelId={mineLevelId}
            namedAreaId={id}
          />
        </div>
      );
    };

    const { contentLoading } = this.state;

    function getNested(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj);
    }
    const tempArray = []; // getNested(localMapState, "namedAreas", "features") || [];

    if (contentLoading) {
      return (
        <Dimmer active={contentLoading} page>
          <Loader>Loading data</Loader>
        </Dimmer>
      );
    } else {
      return (
        <div className="genericGridHeader">
          <Container>
            <Grid columns={2}>
              <Grid.Row className={"genericTitleHeader"}>
                <Grid.Column width={4} textAlign={"left"}>
                  <Header as="h1">{strNamedArea}</Header>
                </Grid.Column>
                <Grid.Column width={12} textAlign={"left"}>
                  <FlashMessagesList />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
            </Grid>
            {/* ***************Create Form ************************ */}
            {error && (
              <Message error header="Error submitting" content={error} />
            )}

            <Form
              onSubmit={handleSubmit(this.submitMyForm)}
              error={Boolean(error)}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2">{`Edit ${strNamedArea}`}</Header>

                      <Field
                        name="mineLevelId"
                        label="Area"
                        placeholder="Enter area name"
                        component={renderField}
                        disabled
                      />
                      <Field
                        name="namedAreaIdValue" // namedAreaId
                        label="ID"
                        placeholder={`UUID value of the ${strNamedArea}`}
                        component={renderField}
                        disabled={true}
                      />
                      <Field
                        name="namedAreaParentName"
                        label={`${strNamedArea} Name`}
                        placeholder={`Enter the name of the ${strNamedArea}`}
                        component={renderField}
                        validate={[required, isNamedAreaNameOK]}
                        normalize={(value) => normalise(value, "normalise")}
                      />
                      <Field
                        name="namedAreaSubType"
                        label={`${strNamedArea} Sub Type`}
                        placeholder={`Enter the subtype of the ${strNamedArea}`}
                        component={renderField}
                        //validate={[required, isNamedAreaNameOK]}
                        normalize={(value) => normalise(value, "normalise")}
                        disabled={true}
                      />
                    </Segment>
                    <Segment>
                      <Message icon color="orange">
                        <Icon name="warning" />
                        <Message.Content>
                          <Message.Header>
                            Use the map controls to add a new polygon
                          </Message.Header>
                          Click on the map's Polygon or Rectangle icon to draw a
                          new polygon shape. A shape must be drawn to be able to
                          save the form.
                        </Message.Content>
                      </Message>
                    </Segment>
                    <Segment>
                      <Header as="h2">{strNamedAreas}</Header>
                      <NamedAreaTable
                        mineLevelId={mineLevelId}
                        namedAreaIdValue={namedAreaIdValue}
                        namedAreaParentName={namedAreaParentName}
                      />
                    </Segment>
                    <Segment>
                      <Message icon color="blue">
                        <Icon name="info" />
                        <Message.Content>
                          <Message.Header>About 'Save Changes'</Message.Header>
                          Modify a polygon, or colors, to enable 'Save Changes'.
                          To 'Delete' named areas, check the box at the end of
                          each row to mark for deletion, then save the changes.
                          Mark all rows for deletion to delete the entire named
                          area.
                        </Message.Content>
                      </Message>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    {false && (
                      <Segment>
                        <Grid columns={2}>
                          <Grid.Column>
                            <Grid.Row>
                              <DeleteButton
                                onClick={this.requestDelete}
                                id={id}
                              />
                            </Grid.Row>
                          </Grid.Column>
                          <Grid.Column>
                            <Grid.Row>
                              <Button.Group floated="right">
                                <SaveButton
                                  submitting={submitting}
                                  pristine={pristine}
                                  strings={strings}
                                />
                                <Button.Or />
                                {/* <Button
                                type="button"
                                disabled={pristine || submitting}
                                onClick={(e) => this.resetForm(e)}
                              >
                                Reset
                              </Button> 
                              <Button.Or /> */}
                                <Button
                                  style={{ textAlign: "right" }}
                                  onClick={(e) => this.cancelForm(e)}
                                >
                                  Cancel
                                </Button>
                              </Button.Group>
                            </Grid.Row>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    )}
                    <Segment>
                      <Header as="h2">Map</Header>
                      {refLocationsComponent(mineLevelId)}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>

            <DebugPagePropsMessages that={this} />
          </Container>
        </div>
      );
    }
  }
}

const selector = formValueSelector("editNamedAreaForm");

EditNamedAreaForm = reduxForm({
  form: "editNamedAreaForm",
  // Note - to reinitialize values in componentDidMount
  // Make sure you set the values of enableReinitialize & keepDirtyOnReinitialize to true
  // See - https://stackoverflow.com/questions/44273099/redux-form-initialization-with-props-initialize
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(EditNamedAreaForm);

const mapStateToProps = (state, props) => {
  const { id, mineLevelId, initialValues } = props;
  const { namedAreaIdValue } = initialValues;

  // collect an array of existing named area parent names to validate against named area name changes
  const allNamedAreaStatuses = getNamedAreaStatusesByAreaId(state, mineLevelId); // getAllNamedAreaStatuses(state);
  const uniqueNamedAreaParentIds = allNamedAreaStatuses
    .map((item) => {
      return { id: item.parent, parentName: item.parent_name };
    }) // .parent
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.id === value.id && t.parentName === value.parentName
        )
    );
  //  .filter((value, index, self) => self.indexOf(value) === index);
  //.map((item) => item.split(":")[1]);

  //console.log(`uniqueNamedAreaParentIds`, uniqueNamedAreaParentIds);

  // # REVIEW #NOTE - duplicate props. mineLevelId is also in initialValues object. Fix this.

  const localMapState = GetLocalMap(state);

  //  Render issue - with each heartbeat the STATE updates which causes localPts to be updated and so resets the map state
  // See console.log below - note grab action is cancelled when the time changes.
  //console.log("ggg render issue ref1", ref1, new Date(Date.now()));
  // See - shouldComponentUpdate

  return {
    id,
    mineLevelId,
    localMapState,
    namedAreaIdValue,
    namedAreaParentName: selector(state, "namedAreaParentName"),
    initialValues,
    uniqueNamedAreaParentIds,
    //namedAreaIdValue: selector(state, "namedAreaId"),
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("reduxForm onChange validate values", values);

      if (values.mineLevelId !== previousValues.mineLevelId) {
        //console.log("onChange", values);
        const {
          change,
          initialValues: { namedAreaIdValue, mineLevelId, regions },
        } = props;
        // if (values.mineLevelId === mineLevelId) {
        //   dispatch(change("regions", regions));
        // } else {
        //   dispatch(change("regions", []));
        // }
      }
    },
    reduxFormValues: getFormValues("editNamedAreaForm")(state), // #REVIEW - only used for debugging,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ClearLocalMap: () => {
    dispatch(ClearLocalMap());
  },
});

EditNamedAreaForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNamedAreaForm);

export default EditNamedAreaForm;
