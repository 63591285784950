import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import ExternalTriggerEditPage from "admin/event-external-trigger/ExternalTriggerEditPage";

import ScheduledEventList from "admin/event-scheduled/ScheduledEventList";
import ScheduledEventCreatePage from "admin/event-scheduled/ScheduledEventCreatePage";
import ScheduledEventEditPage from "admin/event-scheduled/ScheduledEventEditPage";

import ScheduledEventListAllJobs from "admin/event-scheduled/ScheduledEventListAllJobs";

import EventLogListAllEvents from "admin/event-log/EventLogListAllEvents";

import ScheduledEventListEditPage from "admin/event-scheduled/ScheduledEventListEditPage";

class EventAdminPages extends Component {
  render() {
    const { match, strings } = this.props;

    //console.log("xxx match", match);

    return (
      <Switch>
        <Route
          exact
          path={"/external-trigger-event"}
          render={(props) => (
            <ExternalTriggerEditPage {...props} strings={strings} />
          )}
        />
        <Route
          exact
          path={"/admin/scheduled-job"}
          render={(props) => (
            <ScheduledEventListAllJobs {...props} strings={strings} />
          )}
        />
        <Route
          exact
          path={"/admin/scheduled-event"}
          render={(props) => (
            <ScheduledEventListEditPage {...props} strings={strings} />
          )}
        />

        <Route
          path={`/admin/scheduled-job/new`}
          render={(props) => (
            <ScheduledEventCreatePage {...props} strings={strings} />
          )}
        />
        <Route
          path={`/admin/scheduled-job/edit/:id`}
          render={(props) => (
            <ScheduledEventEditPage {...props} strings={strings} />
          )}
        />
        <Route
          exact
          path={"/event-log"}
          render={(props) => (
            <EventLogListAllEvents {...props} strings={strings} />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(connect(null)(EventAdminPages));
