import { v4 as uuid } from "uuid";
import { newNamedAreaTemplate } from "admin/named-area-simple/NewNamedAreaTemplate";

export const createFireflyNamedAreaId = (fireflyId) => {
  // 'id'
  // normally namedAreas have an 'id' based on the time they are created.
  // For Firefly named areas, the id is based on the area:location:position of the firefly
  // except with - delimiters instead of colon, after all '-' are stripped prior to making the id
  //
  // e.g. id: "DMLZ_Extraction:8429b438-2109-40b3-9084-54fb5f1feb59:DMLZ_Extraction-P17-1"

  let id = fireflyId.replace(/-/g, "");
  id = id.replace(/:/g, "-");

  return `FORCED-${id}`;
};

export const createNamedAreaAroundFirefly = (
  fireflyId,
  point,
  areaId,
  color,
  state,
  priority
) => {
  // console.log(
  //   `forcedLight: createNamedAreaAroundFirefly`,
  //   fireflyId,
  //   point,
  //   areaId,
  //   color,
  //   state
  // );

  // 'point' is an object {easting, northing}
  //
  const areaName = uuid();

  const id = createFireflyNamedAreaId(fireflyId);
  const threshold = 1;
  const { easting, northing } = point;

  const shape = [
    [easting - threshold, northing + threshold],
    [easting + threshold, northing + threshold],
    [easting + threshold, northing - threshold],
    [easting - threshold, northing - threshold],
    // repeat 1st point
    [easting - threshold, northing + threshold],
  ];

  const namedAreaId = `${areaId}:${areaName}:${id}`;
  const name = `${id}`;
  const parent = `${areaId}:${areaName}`;
  const parentName = `${id}`; // there is only one polygon

  return newNamedAreaTemplate(
    namedAreaId,
    name,
    parent,
    parentName,
    areaId,
    shape,
    color,
    { active_state: state },
    "forced", //namedAreaSubType
    priority
  );
};
