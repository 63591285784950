import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GetLocalMap } from "components/Map/reducer";

import { Header, Table, Button, Checkbox, Grid } from "semantic-ui-react";

import Map from "./TestMqttGeoJsonMap";
//import Map from "components/Map/mapBase.js";
//import MapMini from "./TestMqttGeoJsonMapMini";

import TestNamedAreaTable from "pages/Test/TestMQTT/TestMqttNamedAreasSetup";

// based on
// - https://embed.plnkr.co/Gvei5I0S9yEo6fCYXPuy/
// see -> <Map /> from "./TestMapTilesMap";

// --------------------------------------
// Marker not appearing for Simple Example
// Fix - https://github.com/PaulLeCam/react-leaflet/issues/453
//
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// --------------------------------------
// HACKfix: Use zIndex to stop leaflet map from being above the Semantic-UI dropdown menus!
const DEFAULT_MAP_STYLE = {
  height: "800px",
  width: "100%",
  position: "relative",
  zIndex: 0,
};

class TestMqttGeoJson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckboxMarker: true,
      isCheckboxRefMarker: true,
      isCheckboxFireflies: true,
      isCheckboxPolyline: false,
      isAddPolygonClicked: false,
    };
  }

  isAddPolygonClicked = () => {
    this.setState({
      isAddPolygonClicked: !this.state.isAddPolygonClicked,
    });
  };

  onClickCheckbox = (name) => {
    this.setState({
      [name]: !this.state[name],
    });

    // uiseful - https://hallister.github.io/semantic-react/#!/Checkbox

    // via REDUX map the state of the controls
    // within the Map component, catch updates and alter layers etc. based on
    // STATE messages.

    // _alt_
    // pass down the state to child component (as below)

    // see
    // https://gis.stackexchange.com/questions/186131/placing-controls-outside-map-container-with-leaflet
  };

  render() {
    let {
      isCheckboxMarker,
      isCheckboxRefMarker,
      isCheckboxFireflies,
      isCheckboxPolyline,
    } = this.state;

    const { localMapState } = this.props;
    // console.log(
    //   "localMapState namedAreas",
    //   JSON.stringify(localMapState.namedAreas)
    // );

    // #REVIEW - replace with object search
    // https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
    function getNested(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj);
    }

    const tempArray = getNested(localMapState, "namedAreas", "features") || [];
    // console.log("tempArray", tempArray);

    return (
      <>
        <Grid>
          <Header as="h2">Test Named Areas for Extraction Layer</Header>

          <Grid.Row>
            <Grid.Column width={6}>
              <div id="custom-map-controls"></div>
              {/* <button id="toggle">TOGGLE</button>
              <hr />
              <Button class="ui button" onClick={this.isAddPolygonClicked}>
                Add Polygon
              </Button> */}
              <Grid.Row>
                <Checkbox
                  label="Display Markers"
                  name="check"
                  checked={isCheckboxMarker}
                  onClick={this.onClickCheckbox.bind(this, "isCheckboxMarker")}
                />
              </Grid.Row>
              <Grid.Row>
                <Checkbox
                  label="Display Ref Points"
                  name="check"
                  checked={isCheckboxRefMarker}
                  onClick={this.onClickCheckbox.bind(
                    this,
                    "isCheckboxRefMarker"
                  )}
                />
              </Grid.Row>
              <Grid.Row>
                <Checkbox
                  label="Display Fireflies"
                  name="check"
                  checked={isCheckboxFireflies}
                  onClick={this.onClickCheckbox.bind(
                    this,
                    "isCheckboxFireflies"
                  )}
                />
              </Grid.Row>
              <Grid.Row>
                <Checkbox
                  label="Display Cables"
                  name="polyline"
                  checked={isCheckboxPolyline}
                  onClick={this.onClickCheckbox.bind(
                    this,
                    "isCheckboxPolyline"
                  )}
                />
              </Grid.Row>

              <Grid.Row>
                <TestNamedAreaTable />
              </Grid.Row>
              <Grid.Row>
                <ul>
                  {tempArray.map(
                    (
                      {
                        properties: { id, Firefly_List },
                        geometry: { coordinates, type },
                      },
                      idx
                    ) => (
                      <li style={{ overflowWrap: "break-word" }}>
                        <b>Area: </b>
                        {id}
                        <br />
                        <b>FireFly_List: </b> {Firefly_List}
                      </li>
                    )
                  )}
                </ul>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={10}>
              <Map
                style={DEFAULT_MAP_STYLE}
                render="single"
                parentState={this.state}
              />
              {/* <MapMini
                style={DEFAULT_MAP_STYLE}
                render="single"
                parentState={this.state}
              /> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const localMapState = GetLocalMap(state);

  return {
    localMapState,
  };
}

export default connect(mapStateToProps, null)(TestMqttGeoJson);
