import React, { Component } from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import { Message } from "semantic-ui-react";
import { LayersControl, Rectangle } from "react-leaflet";

import { connect } from "react-redux";

import MineLevelMap from "components/MineLevelMap";
import CurrentSeismicFeatureGroup from "containers/CurrentSeismicFeatureGroup";
import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroup";
import CreateRegionsMapEditor from "./CreateRegionsMapEditor";
import EditSeismicRegionSettings from "./EditSeismicRegionSettings";
import { saveAndApply, resetSeismicOverlayEditor } from "./actions";
import MineLevelPageHeader from "containers/MineLevelPageHeader";
import {
  getIsLoadingSeismicLayersForMineLevel,
  getCurrentSeismicLayer,
} from "seismicOverlays/reducer";

import { withMineLevel } from "ApplicationContexts";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import getSemanticColor from "colorpalette";

let SaveSettingsButton = ({ ...props }) => {
  let text = "Save and apply";
  if (props.isSaving) {
    text = "Busy saving";
  } else if (props.isEditing) {
    text = "Currently editing";
  }
  return (
    <Button
      onClick={() => {
        props.saveAndApply(props.mineLevel.id);
      }}
      disabled={props.isSaving || props.isEditing}
    >
      {text}
    </Button>
  );
};

SaveSettingsButton = withMineLevel(
  connect(
    (state) => {
      return { isSaving: state.editorSeismicLayer.isSaving };
    },
    { saveAndApply }
  )(SaveSettingsButton)
);

const SaveError = connect((state) => {
  return { message: state.editorSeismicLayer.savingError };
})(({ message }) => (message ? <Message warning content={message} /> : null));

class UISettings extends Component {
  render() {
    const { opacity, onOpacityChange, showDefault, onToggleShowDefault } =
      this.props;
    return (
      <div>
        <Header dividing>UI</Header>
        <Form>
          <Form.Field inline>
            <label>Show default layer</label>
            <Radio
              toggle
              checked={showDefault}
              onChange={onToggleShowDefault}
            />
          </Form.Field>
          <Form.Field inline>
            <label>Opacity</label>
            <InputRange
              maxValue={1.0}
              minValue={0.0}
              step={0.1}
              formatLabel={(value) => `${Math.trunc(value * 100)}%`}
              value={opacity}
              onChange={(value) => onOpacityChange(value)}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

class CreateSeismicOverlayPage extends Component {
  constructor(props) {
    super(props);
    this.state = { opacity: 0.5, showDefault: true, isEditing: false };
    this.onOpacityChange = this.onOpacityChange.bind(this);
    this.onToggleShowDefault = this.onToggleShowDefault.bind(this);
  }
  onStartedEditingShapes = (e) => {
    this.setState({ isEditing: true });
  };
  onStoppedEditingShapes = (e) => {
    this.setState({ isEditing: false });
  };

  onOpacityChange(opacity) {
    this.setState({ ...this.state, opacity });
  }

  onToggleShowDefault() {
    this.setState({ ...this.state, showDefault: !this.state.showDefault });
  }

  componentDidMount() {
    this.props.triggerReset();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.mineLevel.id !== this.props.mineLevel.id) {
      this.setState({ ...this.state, opacity: 0.5, showDefault: false });
      this.props.triggerReset();
    }
  }
  render() {
    const { mineLevel, currentSeismicControlName } = this.props;
    let defaultLevel = [];
    if (this.state.showDefault) {
      const semColor = getSemanticColor(this.props.defaultLayerColor);
      const lineColor = semColor;
      let fill = semColor;
      if (semColor === "white") {
        const Pattern = (
          <svg xmlns="http://www.w3.org/2000/svg" key="white-pattern">
            <defs>
              <linearGradient
                id="WhiteGradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
                spreadMethod="reflect"
              >
                <stop offset="5%" stopColor="white" stopOpacity="1" />
                <stop offset="50%" stopColor="wheat" stopOpacity="1" />
                <stop offset="95%" stopColor="white" stopOpacity="1" />
              </linearGradient>

              <pattern
                id="WhitePattern"
                x="0"
                y="0"
                width=".25"
                height=".25"
                spreadMethod="reflect"
              >
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#WhiteGradient)"
                />
              </pattern>
            </defs>
          </svg>
        );
        defaultLevel.push(Pattern);
        fill = "url(#WhitePattern)";
      }

      defaultLevel.push(
        <Rectangle
          key="default-area"
          bounds={mineLevel.image.bounds}
          color={lineColor}
          fillColor={fill}
          fillOpacity={this.state.opacity}
          pane={"tilePane"}
        />
      );
    }
    return (
      <div>
        <MineLevelPageHeader>Create seismic overlay</MineLevelPageHeader>
        <Grid divided>
          <Grid.Row>
            <Grid.Column width={5}>
              <EditSeismicRegionSettings
                onCopyExistingSettings={
                  this.props.useActiveSeismicAsStartingPoint
                }
              />
              <br />
              <UISettings
                onOpacityChange={this.onOpacityChange}
                opacity={this.state.opacity}
                showDefault={this.state.showDefault}
                onToggleShowDefault={this.onToggleShowDefault}
              />
              <br />
              <SaveSettingsButton isEditing={this.state.isEditing} />
              <SaveError />
            </Grid.Column>
            <Grid.Column width={11}>
              <Header as="h4">Preview</Header>
              <MineLevelMap key={`minelevelmap-${mineLevel.id}`}>
                <LayersControl position={"topright"}>
                  <LayersControl.Overlay
                    key={`currentSeismicOverlay-${currentSeismicControlName}`}
                    name={currentSeismicControlName}
                  >
                    <CurrentSeismicFeatureGroup />
                  </LayersControl.Overlay>
                  <LayersControl.Overlay
                    key={"firefliesOverlay"}
                    name={"FireFlys"}
                  >
                    <CurrentMineLevelFirefliesFeatureGroup />
                  </LayersControl.Overlay>
                </LayersControl>
                {defaultLevel}
                <CreateRegionsMapEditor
                  opacity={this.state.opacity}
                  onActivityStarted={this.onStartedEditingShapes}
                  onActivityStopped={this.onStoppedEditingShapes}
                />
              </MineLevelMap>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { mineLevel } = props;
  let currentSeismicControlName = "Active seismic";

  if (getIsLoadingSeismicLayersForMineLevel(state, mineLevel.id)) {
    currentSeismicControlName = "Loading active seismic...";
  } else if (!getCurrentSeismicLayer(state, mineLevel.id)) {
    currentSeismicControlName = "No active seismic";
  }
  return {
    currentSeismicControlName,
    defaultLayerColor: state.editorSeismicLayer.levelDefault.color,
  };
};

function mapDispatchToProps(dispatch, props) {
  return {
    triggerReset: () => dispatch(resetSeismicOverlayEditor()),
    useActiveSeismicAsStartingPoint: () =>
      dispatch({
        type: "SEISMIC_OVERLAY_EDITOR_USE_CURRENT_SETTINGS",
        payload: { mineLevelId: props.mineLevel.id },
      }),
  };
}
export default withMineLevel(
  connect(mapStateToProps, mapDispatchToProps)(CreateSeismicOverlayPage)
);
