import React from "react";

import { Header, Card, Divider, Message } from "semantic-ui-react";

import LoginForm from "components/LoginForm";
import FlashMessagesList from "FlashMessages";

import Logger from "components/Logger";

const HomePage = () => (
  <Card fluid>
    <Card.Content>
      <FlashMessagesList />
      <Message>
        <Message.Header>LOGIN</Message.Header>
      </Message>
      <LoginForm />
      {/* #REVIEW - disable logger from admin login - questionable of its usefulless */}
      {/* <Divider />
      <Logger includeTitles="false" /> */}
    </Card.Content>
  </Card>
);

export default HomePage;
