import { isCustomSOP } from "components/ConfigJs";

export const NAMED_AREA_DISPLAY_SETTINGS = getNamedAreaDisplaySettings();

function getNamedAreaDisplaySettings() {
  let hint = getCookie("hint");
  if (hint === undefined) {
    // default
    hint = false;
    setCookie("hint", hint, 500);
  } else {
    hint = hint?.toString() === "true";
  }

  let legend = getCookie("legend");
  if (legend === undefined) {
    // default
    legend = false;
    setCookie("legend", legend, 500);
  } else {
    legend = legend?.toString() === "true";
  }

  let view = getCookie("view");
  if (view === undefined) {
    // default
    view = "normal";
    setCookie("view", view, 500);
  }

  let columnWidth = getCookie("columnWidth");
  if (columnWidth === undefined) {
    columnWidth = 6;
    setCookie("columnWidth", columnWidth, 500);
  }

  let sopInterface = getCookie("sopInterface");
  if (sopInterface === undefined) {
    sopInterface = isCustomSOP()?.toString() === "true";
    //console.log("isCustomSOP(), sopInterface", isCustomSOP(), sopInterface);
    setCookie("sopInterface", sopInterface, 500);
  } else {
    // make absolutely sure!!!!!!!!!!!!!
    sopInterface = true;
  }

  return { hint, legend, view, columnWidth, sopInterface };
}

export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value?.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop()?.split(";")?.shift();
  }
  return undefined;
}

export function clearCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function setCookie(cookieName, cookieValue, hourToExpire) {
  //  console.log("setCookie", cookieName, "value ", cookieValue);
  let date = new Date();
  date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
  document.cookie =
    cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
}
