import {
  parseISO,
  format,
  formatRelative,
  differenceInMinutes,
} from "date-fns";

export function formatDate(d, fmtDefault = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") {
  return format(d, fmtDefault);
}

export function formatDateShort(d, fmtDefault = "yyyy-MM-dd HH:mm") {
  return format(d, fmtDefault);
}

export function formatDateRelativeNow(d, now = new Date()) {
  return formatRelative(d, now);
}

export function parseDate(d) {
  return parseISO(d);
}

export function minutesAgo(d) {
  return differenceInMinutes(new Date(), d);
}

const SECS_PER_MIN = 60;
const SECS_PER_HR = 60 * SECS_PER_MIN;
const SECS_PER_DAY = 24 * SECS_PER_HR;

function fmt(singular, value) {
  return value > 1 ? `${value} ${singular}s` : `${value} ${singular}`;
}

export function formatDuration(up) {
  const days = Math.floor(up / SECS_PER_DAY);
  up -= days * SECS_PER_DAY;
  const hours = Math.floor(up / SECS_PER_HR);
  up -= hours * SECS_PER_HR;
  const mins = Math.floor(up / SECS_PER_MIN);
  up -= mins * SECS_PER_MIN;
  const secs = up;

  let uptime = "n/a";
  if (days > 0) {
    uptime = [fmt("day", days), fmt("hr", hours), fmt("min", mins)].join(" ");
  } else if (hours > 0) {
    uptime = [fmt("hr", hours), fmt("min", hours), fmt("sec", secs)].join(" ");
  } else if (mins > 0) {
    uptime = [fmt("min", hours), fmt("sec", secs)].join(" ");
  } else {
    uptime = fmt("sec", secs);
  }

  return uptime;
}
