import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { combineReducers } from "redux";

import { ComponentStateCache } from "react-component-state-cache";

import {
  ConnectedRouter,
  routerReducer,
  routerMiddleware,
} from "react-router-redux";
import createHistory from "history/createBrowserHistory";

// import './index.css'
import "semantic-ui-offline/semantic.min.css";
import App from "components/App";

import reducers from "reducers";
import configureStore from "configureStore";
import registerServiceWorker from "./registerServiceWorker";
import createSagaMiddleware from "redux-saga";

import { AppContainer } from "react-hot-loader";

import mineLevelsSaga from "MineLevels/sagas";
import namedAreasSaga from "NamedAreas/sagas";
import namedAreaGroupsSaga from "NamedAreaGroups/sagas";
import seismicOverlaySaga from "seismicOverlays/sagas";
import authSaga from "auth/saga";
import upsSaga from "UPSPanelControllers/sagas";
import createSeismicSaga from "pages/CreateSeismicOverlayPage/saga";
import operationalChangesSaga from "OperationalChanges/sagas";
import liveUpdates from "LiveUpdates/saga";
import statusCheck from "StatusCheck/saga";
import apiSaga from "apiSaga";
import userAdminSagas from "components/UserAdmin/sagas";
import localisationSagas from "components/Localisation/sagas";
import eventLogSagas from "components/EventLog/sagas";
import settingsSagas from "components/Settings/sagas";
import tagsSagas from "components/Tags/sagas";
import toolsSagas from "components/Tools/sagas";

import websocket from "@samuelcastro/redux-websocket";

import CacheBuster from "CacheBuster";
import { ErrorBoundary } from "errorBoundary";

// https://github.com/LogRocket/redux-logger
//import logger from "redux-logger";

import { saveAckToken } from "components/WebWorker/ackTokenMiddleware";
import { updateNamedAreaGroup } from "NamedAreaGroups/middleware";
import { updateUserSettings } from "components/UserAdmin/middleware";
import { monitorSettings } from "components/Settings/middleware";

import { isConfigJs, isTagTracking } from "components/ConfigJs";

import svg4everybody from "svg4everybody";
svg4everybody();

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  routerMiddleware(history),
  sagaMiddleware,
  websocket,
  saveAckToken,
  updateNamedAreaGroup,
  updateUserSettings,
  monitorSettings,
  // ,
  // logger
]; // redux-logger

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = configureStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  {},
  middlewares
);

const render = (Component) => {
  ReactDOM.render(
    <ComponentStateCache>
      <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={history}>
          <AppContainer>
            <ErrorBoundary>
              <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                  if (loading) return null;
                  if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                  }
                  return <Component />;
                }}
              </CacheBuster>
            </ErrorBoundary>
          </AppContainer>
        </ConnectedRouter>
      </Provider>
    </ComponentStateCache>,
    document.getElementById("root")
  );
};
registerServiceWorker();

sagaMiddleware.run(mineLevelsSaga);
sagaMiddleware.run(namedAreasSaga);
sagaMiddleware.run(namedAreaGroupsSaga);
sagaMiddleware.run(seismicOverlaySaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(upsSaga);
sagaMiddleware.run(createSeismicSaga);
sagaMiddleware.run(operationalChangesSaga);

/// tag tracking websocket for liveUpdates
isTagTracking() && sagaMiddleware.run(liveUpdates);

sagaMiddleware.run(statusCheck);
sagaMiddleware.run(apiSaga);
sagaMiddleware.run(userAdminSagas);
sagaMiddleware.run(localisationSagas);
sagaMiddleware.run(eventLogSagas);
sagaMiddleware.run(settingsSagas);
sagaMiddleware.run(tagsSagas);
sagaMiddleware.run(toolsSagas);

render(App);

if (module.hot) {
  module.hot.accept("components/App", () => {
    render(App);
  });
}
