import Immutable from 'seamless-immutable'
import { createSelector } from 'reselect'

const initialState = Immutable({
  byId: {},
  fetchingAll: {},
  fetchingCurrent: {}
})

const reduce = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case 'MINE_LEVEL_ALL_SEISMIC_FETCH_REQUESTED':
      return state.setIn(['fetchingAll', payload.mineLevelId], true)
    case 'MINE_LEVEL_ALL_SEISMIC_FETCH_SUCCEEDED':
      return state
        .setIn(['byId'], state.byId.merge(action.payload.layersById))
        .setIn(['fetchingAll', payload.mineLevelId], false)
    case 'MINE_LEVEL_ALL_SEISMIC_FETCH_FAILED':
      return state.setIn(['fetchingAll', payload.mineLevelId], false)

    case 'MINE_LEVEL_CURRENT_SEISMIC_FETCH_REQUESTED':
      return state.setIn(['fetchingCurrent', payload.mineLevelId], true)
    case 'MINE_LEVEL_CURRENT_SEISMIC_FETCH_SUCCEEDED':
      return state
        .setIn(
          ['byId', action.payload.layer.id],
          Immutable(action.payload.layer)
        )
        .setIn(['fetchingCurrent', payload.mineLevelId], false)
    case 'MINE_LEVEL_CURRENT_SEISMIC_FETCH_FAILED':
      return state.setIn(['fetchingCurrent', payload.mineLevelId], false)
    default:
      return state
  }
}

export default reduce

export const getMineLevelHistory = (
  state,
  mineLevelId,
  includeCurrent = true
) => {
  const obj = state.seismicOverlays.byId
  const layers = Object.keys(obj).map(key => obj[key])

  const forMineLevel = layers.filter(layer => layer.mineLevelId === mineLevelId)

  return Immutable(
    forMineLevel.sort((a, b) => b.id - a.id).slice(includeCurrent ? 0 : 1)
  )
}

export const getCurrentSeismicLayer = createSelector(
  getMineLevelHistory,
  history => history[0]
)

export const getIsLoadingSeismicLayersForMineLevel = (state, id) => {
  return (
    state.seismicOverlays.getIn(['fetchingAll', id], false) ||
    state.seismicOverlays.getIn(['fetchingAll', id], false)
  )
}

export const getSeismicLayerById = (state, id) =>
  state.seismicOverlays.getIn(['byId', id])
