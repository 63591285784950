let currentAckToken = [];

export function setAckToken(ack) {
  currentAckToken = [...currentAckToken, ack];

  // housekeeping, cleanup old acks as these are no longer relevant
  currentAckToken = filterOldAckToken(currentAckToken);

  console.log(`CURRENT ACKS`, currentAckToken);
}

function filterOldAckToken(acks) {
  // parse the token to get timestamp string
  // get most recent ack timestamp and delete all acks older than 1min
  //

  // get timestamps same order of magn
  const compareTime = new Date().getTime();

  //console.log(`compareTime`, compareTime);
  const newAckToken = currentAckToken.filter(
    (ack) => ack.timeStamp > compareTime - 30 * 1000 // 30 seconds
  );

  //#TODO - #NOTE - set ACK delete in  config db

  return newAckToken;
}

export function getAckToken() {
  //console.log("isAckReceived getAckToken currentAckToken", currentAckToken);

  return currentAckToken;
}

export function clearAckToken() {
  currentAckToken = [];
  return currentAckToken;
}

export function removeAckToken(removeToken) {
  currentAckToken = currentAckToken.filter(
    ({ token }) => token !== removeToken
  );
  return currentAckToken;
}
