// see main reducer at src/reducers.js
//

import Immutable from "seamless-immutable";

// let initialState = Immutable({
//   // displayed message list
//   mqttMsg: [],
// });

const initialState = Immutable([]);

export default function reduce(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case "MQTT_TEST":
      console.log("zzz TestWorker Reducer MQTT_TEST Payload - ", payload);
      return state.concat([action.payload]);
    case "ADD_MQTT_MESSAGE":
      //console.log("ADD_MQTT_MESSAGE");
      return state.concat([action.payload]);
    case "REMOVE_MQTT_MESSAGE":
      console.log("zzz REMOVE_FLASH_MESSAGE");
      return Immutable(
        //        state.asMutable().filter(({ id }) => id !== action.payload.id)
        state.asMutable().filter(({ id }) => !id.includes(action.payload.id))
      );
    case "CLEAR_MQTT_MESSAGES":
      console.log("zzz CLEAR_MQTT_MESSAGES");
      return initialState;
    // case "MQTT_NAMED_AREA_NEW":
    //   console.log(
    //     "zzz TestWorker Reducer MQTT_NAMED_AREA_NEW Payload - ",
    //     payload
    //   );
    //   return newState; /// do nothing for now
    default:
      return state;
  }
}

export function getAllMqttMessages(state) {
  return state.mqttMessages;
}
