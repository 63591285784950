import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Segment,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";

import _ from "lodash";

import { fetchMineLevels } from "MineLevels/actions";
import { fetchNamedAreas } from "NamedAreas/actions";

import { getAllAreaStatuses } from "components/WebWorker/reducer";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

//import { isBoomgateCrane } from "components/ConfigJs";

class ListNamedAreaGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: props.data,
      direction: null,
      language: "en-US", // localisation
    };
  }

  componentDidMount() {
    //const { data, fetchMineLevels, fetchNamedAreas  } = this.props;
    // if (_isEmpty(data)) {
    //   // #FETCHS
    //   fetchMineLevels(); // #FETCHS
    //   fetchNamedAreas();
    // }
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props;

    if (data !== nextProps.data) {
      let newData = nextProps.data;

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction } = this.state;

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    // APP_TERMINOLOGY
    const strNamedAreaGroups = strings?.["Lighting Control Layouts"];
    const strArea = strings?.["Control Layout for Area"];

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strNamedAreaGroups}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          {false && (
            <Segment textAlign="left" style={segmentStyle}>
              <Link to="named-area-group/new">
                <Button color="blue" content="Add" />
              </Link>
            </Segment>
          )}
          <Table sortable celled fixed striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === "name" ? direction : null}
                  onClick={this.handleSort("name")}
                >
                  {strArea}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(data, ({ id, name }) => (
                <Table.Row key={`row-id-${id}`}>
                  <Table.Cell>
                    <Link to={`/admin/named-area-group/edit/${id}`}>
                      {name}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
                columnSpan={1}
              />
            </Table.Body>
          </Table>
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  let filteredElements = [];

  elements.forEach(function (element, idx) {
    const {
      id,
      name,
      image_filename,
      floor,
      ceiling,
      default_color,
      default_state,
    } = element;

    if (!id?.includes("defaultArea")) {
      // compose new data set
      filteredElements[idx] = {
        id: id,
        name: id.replaceAll("_", " "),
      };
    }
  });
  return filteredElements;
};

function mapStateToProps(state) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  //const { isLoading, error } = state.mineLevels;

  const isLoading = false;
  const error = false;

  const data = _prepData(getAllAreaStatuses(state));

  return {
    isLoading,
    error,
    data,
  };
}

export default connect(mapStateToProps, {
  fetchMineLevels,
  fetchNamedAreas,
})(ListNamedAreaGroups);
