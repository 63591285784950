export function updateNamedAreaGroup(payload) {
  return { type: "UPDATE_NAMED_AREA_GROUP", payload };
}

export function updateNamedAreaButtonsByParentIdMqtt(payload) {
  return {
    type: "UPDATE_BUTTONS_IN_NAMED_AREAS_BY_PARENT_MQTT",
    payload,
  };
}
