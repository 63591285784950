import React from "react";
import { connect } from "react-redux";

import { Container, Segment } from "semantic-ui-react";
import { getServerInfoVersion } from "components/Settings/reducer";
//import {getServerInfoVersion} from "../../components/Settings/reducer"

// build date
// from  https://stackoverflow.com/questions/53028778/how-to-show-build-datetime-on-my-react-web-app-using-create-react-app
// format - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
import preval from "preval.macro";
import { getServerTimestamp } from "components/WebWorker/reducer";
import { getUserData } from "auth/reducer";

const dateTimeStamp = preval`module.exports = new Date().toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });`;

const year = new Date().getFullYear();

const VERSION = "1.5.79";

// _#VERSION
// A BRIEF HISTORY:
//
// #TODO next up...
//
// 1.5.?? - ........
//        - Added reorder and show/hide areas for applications
//        - Backup/Restore - added broadcast
//        - Backup/Restore - save filename dlg
//        - Backup/Restore - manage backup files
//        - Added support for API /external_trigger_event (later)
//        - Added support for MQTT named_area/id/external_trigger_event_info (? perhaps just use API fetch)

//
// RELEASE HISTORY

// 1.5.79 - Shelved 'isShowScheduledEvent'
//        - added ordering to Area create, edit, list
//        - Added event logging (web-app and server).
//        - Removed config 'isShowEventReport'
//        - Added period selection to event log
//        - Added download CSV to event log
//        - Moved Event Log and External Trigger page to Operator role
//        - Fixed drag target on Lighting Control layout edit. Add popup info.
//        - #DEMO_MODE defined all light properties b/c in some slow systems these are not immediately available in Modal popup
//        - Added support for application and display settings via table.user.settings (WIP)
//        - Added Access rights to Lighting Control layout > Group edit settings for display control per User (WIP)
//        - Added pagination to Access rights Lighting Control layout > Group edit settings list
//        - Added userSettings config for 'features': demo, dev, ext trig event, sched event, user relay
//        - Fix Create User populates from DEFAULT_SETTINGS
//        - Fix User delete would delete thisUser
//        - Moved backupRestore to features.
//        - Changed checkServer() process to pop msg banner if fails
//        - Changed startup page for tag tracking
//        - Added Scheduled Events to namedAreaGroup setup (WIP)
//        - Fix to namedAreaGroup settings for User access control
//        - Fix to system/timestamp checks for Web worker
//        - #DEMO_MODE - added API fetchFireflys() polling to for data.
//        - Added Scheduled Jobs & Events List, Create page (WIP)
//        - Added Server Time to footer
//        - Added Publish Clear event message after Create/Edit Area
//        - Changed routing "mine-level" to "area"
//        - Localisation pulls available languages from src/components/ConfigJs/index.js > languageOptions
//
//
//
//
// 1.5.78 - Added config flags: isShowEventReport, allowedLightstates, allowedLightColors, allowedMapMarkers, allowedLanguages, allowedRoles
//        - Disabled ForceLight for Guest role
//        - Changed OFF marker color to hard black for more contrast to WHITE's grey
//        - Fixed ALLOWED_LIGHT_COLORS, ALLOWED_LIGHT_STATE, ALLOWED_MAP_MARKER in config applies globally
//        - Added warning that FORWARD, BACKWARD are 'per port settings'
//        - More localisation
//        - Added Sched Event support - list page, simple add page (WIP)
//        - Added Support for White
//        - Added Scheduled Event support - icons displaying for schedule event permissions, main nav options  (WIP)
//        - Add support for event.disable. Displays on advanced events and external trigger list pages (WIP)
//        - Added Clear button to Advanced All Events list
//        - Added save filter state Advanced All Events list
//        - Added support for API /external_trigger_event_info. Used with [UPDATE] button/fetch.
//        - More .. source report for external trigger events (WIP)
//        - Added source report for external trigger events (WIP)
//        - Added new config isShowExternalTriggerEvent
//        - Added permission bit register to namedAreaGroup to enable control as external trigger event
//        - Added chain link icon to namedAreaGroup to indicate linked for external trigger event
//        - Added chain icon to Lighting control if event is from origin 'external_trigger_event'
//        - Added support for Operator (in addition to existing Origin)
//        - Added Test section to demonstrate tag marker icons
//        - Fix to VSCode config (i.e. remove jsconfig.json leaving only tsconfig.json)
//        - Added Server time to System Tools (needs server 2.0.49)
//        - Changed startup messages on server status. Removed "disconnected".
//        - Localisation - save edits form updates (needs server 2.0.49)
//        - Set keepAliveInterval: 30 for testing
//        - Fix select [x] on Controller list deleted controller
//        - Localisation - english always terms (ie. Super) to tomato
//        - Fix to filtering (more)
//        - Patched default 'device' property for demo data
//        - Added localisation edit page
//        - Fix to filtering on list pages so updates render when prop data is not updating, also useful for demo_mode
//        - Fix to namedArea create name to allow same name for all subTypes
//        - Localisation - defaults strategy: db localisation > db english (1st language) > hard coded English default
//        - Added localisation start of localisation support
//        - Added size column to Backup file list
//        - Fix to pass network_name on controller update (reqd for Demo mode changes)
//        - Fix to add network_name to controller marker labels
//        - Backup/Restore - upload backup file
//        - Backup/Restore - detect backup service available
//        - Added check for active systemd services as basis of enabling features (WIP) (e.g. iot-tag-tracker, iot-firefly-server-backup-monitor)
//        - Fix to restore lights for all Areas (& eventually to all namedAreaGroups defined)
//        - Fix to remove multiple underlines in names
//        - Fixed level settings not updated when dragging namedAreas between Levels in namedAreaGroup
//        - Added Lighting layout changes preserve event order via modal for option to clear or restore.
//        - Fixed Show All area when > 1 area defined
//        - Tag Tracking - added support for tags detected in multiple areas concurrently (from location data)
//        - Fixed "undefinedv" in Controller list
//        - Added 'Restore saved view' and 'Save view' buttons on react-leaflet and leaflet maps
//        - Added map search react wrapper
//        - Added basis for leaflet map search
//        - Added basis for multi-select items on leaflet
// 1.5.77 - Fixed possible EventEmitter memory leak detected in setupProxy
//        - set backup/restore display via config 'isBackupRestore'
//        - fix to NoMatch page for sub sections of main menu
// 1.5.76 - added backup and restore feature
//        - fix when new area added but no definition for namedAreaGroup
// 1.5.75 - Changes to tag zones for consistency with region zones on map views
//        - added styling preferences to polygon config for Tag Zones
//        - set polygon color for tag zones on Tag Map
// 1.5.74 - Reintroduced polygon zones to map view
// 1.5.73 - added support for "processing request..." to FF marker modal forced
//        - cleaned up fireflymarker update & fixed update to forced light/state with marker refresh
//        - fix to marker colors when move marker positions
// 1.5.72 - Reintroduced forced lighting to FF marker modal dlg
//        - Moved allowed colors to settings 'allowedLightColors'
//        - Moved lighting priority levels to settings 'lightingEventPriority'
// 1.5.71 - Changes to Smart Lighting pan/move view to emphasise no data updates
//        - Fix to data enable after pan/move disabled
// 1.5.70 - Tags - Fix to tag zone create page field labels
//        - Rearranged config to support separate isTagTracking and isLightingControl applications
//        - Added Tools > MQTT List page for display of misc mqtt messages
// 1.5.69 - Added DOWNLOAD XLSX to Controllers & FFs list pages
//        - Tags - added config 'isLightingControl' to config to disable lighting control (WIP)
// 1.5.68 - Tags - fix to get tagTracking port 8010 from config 'tagTrackingPort'
//        - Tag_Daemon - fixed icon can display as strobe
//        - Changed config.js to iot-firefly-server-web-app.js to support new package nomenclature
//        - Tags - added Advanced report for firefly/tag for debugging tag detections (WIP)
//        - Tags - added Advanced report for tag_daemon/mac/report to report on controllers with tag_daemon setup (WIP)
// 1.5.67 - Tags - fix CHANGE message data update not working
//        - Tags - fix for tag data list when no areas defined
// 1.5.66 - Tags - Bug in initialChangedTagCoordinates INITIAL message rx
// 1.5.65 - Tags - Added react-marker-cluster, styled
//        - Tags - fixed type button filtering
//        - Tags - Changed styling on marker icons. No longer need to be rotated with clustering.
//        - Tags - Added support for INITIAL. Rewrote tag processing reducer functions.
//        - Tags - Fix to tag list tagEntry filtering
//        - Tags - Add config 'tagTrackingMap' to show tag map
//        - misc. cleanup of console messages
//        - updated react version to 17.0.1
// 1.5.64 - stop delete of new FFs or when no area (isDeleteFireflyNotCommissioned)
//        - stop delete of new Controller or when no area
//        - added conditional data collect during popup open (isDataCollectionDuringMarkerPopupOpen)
//        - fix to controller status update to update Area in controllerCoordinate
//        - enabled Sirens in context menu as config 'isUserRelay'
//        - enabled Show/Hide/All Regions  in context menu as config 'isPolygonShowHide'
//        - map lock click action acts as Pan tool click
//        - fix to movePosition controller icon display. Added separate forceUpdate for each layer.
//        - fix User Relay sort in Controllers list
//        - fix to controller marker popup modal rendering after updated controller status
// 1.5.63 - disabled Sirens in context menu (WIP - need to put config)
//        - disabled Show/Hide/All Regions in context menu (WIP with new layer)
//        - more on Firefly & Controller marker popup modal dlgs
//        - Tag - added zone change api post
//        - disabled 'WorkerMarker' when tag_daemon is active (WIP - untested)
//        - Tag - more support for display of status (e.g. color of markers set)
// 1.5.62 - More controller marker modal popup
// 1.5.61 - Added controller marker modal popup
//        - Tags - Area now derived from FF locations
// 1.5.60 - Fix to utm prop in fireflyStatuses
//        - Removed forced lighting from FF marker modal (WIP)
// 1.5.59 - Tags - added icon color for initial,lost
//        - Tags - added support for Areas and Zone changes
//        - Tags - fixed report time on TagsInZone
//        - Tags - added status to tag messages, and to tag list (debugging)
// 1.5.58 - Tags - Tag icon displays assigned user or vehicle info
// 1.5.57 - Tags - Updated zone information to display on tag zones and in tag list
//        - Updates to Firefly popup modal dlg (WIP)
// 1.5.56 - Added Pan control to Area map for testing using leaflet-react
//        - Added lock icon to Pan control on Lighting control simple for user feedback on pan state
// 1.5.55 - Change preload of images and saving images to state configurable (<AreaImage />)
//        - Changes to MineLevelMapLeafletSimple to disable data collect when popup & modal active
//        - Tags - fix to api/current coordinate initialisation
//        - Tags - fix to stop websocket if isTagTracking not enabled
// 1.5.54 - Added configJs config for minelevelmapleafletsimple vs minelevelmapleaflet for testing (isAreaMapSimple)
//        - removed all tag tracking layers/controls from minelevelmapleaflet
// 1.5.53 - Fix controller marker refresh on minelevelmapleafletsimple marker move
// 1.5.52 - Changed Area image load to separate startup process (WIP)
// 1.5.51 - Tags - added display of tag zones on tag map
//        - Tags - catch server return of non-Json when no api route
//        - Tags - cater for new system null data api
// 1.5.50 - Lots more on Tag tracking ..........
// 1.5.49 - Lots on Tag tracking ..........
// 1.5.48 - Changed Light control map layers to improve UI with larger # of FFs and during demo_mode
// 1.5.47 - Save loaded images to reducer state for reloading of Map
// 1.5.46 - Added addition fetches for controller data when in Demo mode as can not expect ups/+/status messages
//        - Changed bulk commission to show all ports even if FFs are 0
//        - Fix to /change_controller API to pass 'name' not 'location' (also fix to Server)
//        - Added 'Move Map View' functionality - disables all layers while moving map image to a new position
// 1.5.45 - Minor change to simData loading
//        - Minor changes to Controller Status vs Reports (faults) to make cleared to user
//        - Fireflies were not being deleted correctly. Added firefly delete list and used to properly manage deleted FFs
// 1.5.44 - Fixed Lighting control active OFF state on lights not working
//        - Made OFF state defn consistent with Area & namedAreaGroup
//        - Set OFF state to light grey if color defined
//        - Fixed OffMarker. Made s"en-US"maller so stroke not impacting edges. Made opaque.
//        - Fix to simData when namedAreaGroup not defined
//        - Added ErrorBoundary to display errors to the screen "Something went wrong"
//        - Added config to show/hide some FF marker popup properties
//        - Added forced 'F' to forced strobe squaremarker
//        - Added "Changed" acknowledgement of force click for firefly marker popup
// 1.5.43 - Removed erroneous import of ts file which caused build failure (since ~1.5.23 [commit: e39e493] "Tag tracking WIP")
//        - Removed unncessary fontawesome webfont files
//        - Fixed display of tagtracking options (controls) on map page reload
//        - Add simpleClickSimulator to Lighting Control for testing
// 1.5.42 - Added support for api/change_event, api/change_emergency_event
//        - Fixed onSubmitSuccess firing before save/change promise completed on some forms
//        - Removed timestamp comparison for updates on Controller marker
//        - Changed ALL_AREAS buttons to !toggleButton so can not toggle state.
//        - Removed "Last Report" from Controller and Firefly marker popup (reduces rendering)
//        - tagTracking - fixed erroneous display of empty tag layer
//        - tagTracking - omitDeep compare for geoJson changes
//        - tagTracking - added basis for CMS. WIP.
//        - deviceStatus - added reporting for FFs shifted in location (WIP)
//        - deviceStatus - added readable deviceStatus to FF marker popups
//        -
// 1.5.41 - Added deviceStatus to Controller list (highlights new FFs)
//        - Changed highlighting of *new devices as consistently yellow (particularly FFs)
// 1.5.40 - Changed display strings 'Firefly' -> 'FireFly', 'Fireflies' -> 'FireFlys'
//        - Cleaned up console.logs
//        - Fixed promiseArray result on bulk delete for namedAreaSimple when namedAreaGroup is empty
//        - Filtered out mqtt message with topicId === ""
//        - Fix Siren icon display when in demo_mode
// 1.5.39 - Added tag layer for Unknown tag types (useful for setup when tags are undefined)
//        - Added button to control visibility of tag tracking layers
//        - Added button to control visibiilty of tag tracking zones
//        - *WIP* - toggle of tagZone layers
//        - Changed to show tag id if unknown
// 1.5.38 - Added support for vehicle & personnel tag tracking layers
// 1.5.37 - Added textOverlay to ta"en-US"g icon for count of tags at FF
//        - Add link from tag tracking marker popup to details page
// 1.5.36 - Fixed update render on TagsInZones
//        - Added support named_area/id/members to update firefly_list
//        - Fix to remove tag icons when moving map markers
//        - Added web worker MQTT debug message console output in config.js
//        - Added POST equivalent to publish MQTT queue for namedAreaGroup updates
//        - Centralised tags.tagType creation to reducer
//        - Moved tag details view to /status/ rather than /admin/
//        - Improvements to tagTracking icons. Added light and heavy vehicle
// 1.5.35 - Improved data fetch after area: new, delete, change
//        - Moved 'Help' to under user account to make space in to nav
//        - Setup default sort of polygons to parent name
//        - Added support for namedAreaGroupPost
//        - Fixed listing only 'region' polygons for NamedAreaGroups
// 1.5.34 - Refactored RESTapi code
//        - Added session informati"en-US"on to System tools (no longer in app header)
//        - Fixed format of Guest role 'lights only' view.
//        - Fixed toggle display of Legend on 'lights only' / Guest view
//        - Moved 'Faults' to separate menu 'Status' available to Operator role.
//        - Moved 'Tags' to separate menu 'Tag Tracking' for tag tracking application config
//        - Removed links to admin pages if role is <= admin (lists, map popups etc)
//        - Removed marker move function from Lighting control if role is <= operator
// 1.5.33 - Added timeout delay on Siren icon click for improved usability
// 1.5.32 - Added context menu for Siren control. Change logic of Siren group control.
//        -
// 1.5.31 - Changes to forced lights list: removed 'copy' action
//        - Removed Mario as demo animated forced light icon, replaced with "F"
//        - Added RelaySirenMarker to display when bit 1 set in user_relay on Controller marker
// 1.5.30 - [Sirens]
//        - Fix to broken link from Lighting control button context menu
//        - Horn icon event remotely triggers all buttons in level
//        - Horn icon toggles state"en-US" of level - all on/off
// 1.5.29 - [Sirens] WIP
//        - Fix to namedArea copy (to support recent server changes)
//        - Tweak to namedAreaGroup dlg labels
//        - Added horn icon to SOP list [Sirens]
//        - Added Advanced list pages for nameAreas and Events
//        - Added support for relay_event_active (namedArea, events, mqtt) [Sirens]
//        - Added horn icon display of relay_event_active = 1 [Sirens]
// 1.5.28 - Fix to Controller Emergency settings select for 'off'
//        - Improvements on display of Emergency events (colors/states, markers etc.)
// 1.5.27 - Fix to coordinateUTM reference for Area update with namedAreaGroup change
//        - Fix to Controller marker update after move in demo mode
// 1.5.26 - Added "CAPS" on login form to show caps lock is down
// 1.5.25 - (tagTracking) Added styled buttons for tags and tagZones (WIP)
//        - (tagTracking) Reduced tagTracking coordinate geoJson to [firefly:manyTags]
//        - Striped all lists/tables
//        - (tagTracking) Split tagInZones into area tabs
//        - (tagTracking) Changed tag details to display user and/or vehicle information if available
// 1.5.24 - Fixed named area subtyp"en-US"e link from map polygons on lighting control page
//        - Fixed user and vehicle info on tag zone page
//        - Updated tag tracking details form info
//        - Add heartbeat for tag collection, this is disabled with {tagTracking: false} in config.js
//        - when FFs are moved with no change to lighting plan (i.e. within same polygon, or around _BASE) then no /lightingplan is sent.
//          A new lightingplan used to be sent with every coordinate change, but this changed with server changes to the priority processing.
//          To accommodate this there are now checks to update the object if it already exists.
//        - Added 'coordinate' mqqt messages to HIGH priority queue. When >1 new FF were placed, i.e. in Controller details page, the
//          server would respond with new /coordinate and /lightingplan messages. The lightingplan messages were being
//          processed before the coordinate message. The later coordinate message would overwrite the lightingplan info leaving
//          the lighting plan unknown i.e. 'grey'.
// 1.5.23 - Moved Marker device event labelling to config.js
// 1.5.22 - More work on Tags By Zone page
//        - Added map refresh button to reset map view (useful for debugging)
//        - Fix to display and removal of Forced (bugs introduced by 1.5.20)
//        - Delete Force event when marker moves position (in Lighting control only ATM - WIP)
//        - Added text overlays to markers to explain current lighting events (on RoundMarkers only ATM)
//
//        M - modbus
//        P - polygon
//        F - forced
//        S - sloughmeter
//        T - tag
//        C - crane
//
// 1.5.21 - Added Tags By Zone page (WIP)
// 1.5.20 - Added Zone information to Tags
//        - Split namedAreas into regions, tags, forced
// 1.5.19 - Added Force and Event ID concurrently in popup
// 1.5.18 - Added Event ID info to FF marker popups
// 1.5.17 - Removed responsive subHeader to fix re-render fail on some pages
//          (see - https://iotautomation.atlassian.net/browse/SWA-2)
// 1.5.16 - Updated numbered SVG marker for move positions
// 1.5.15 - Fixed RoundMarker not being round
//        - Added Control screen ma"en-US"rker move functionality: move, save, cancel
//        - Added demo animated icons: Mario, pulsating
//        - Added configurable displinfoay of Location and Cables to Control layer
//        - Fixed logic with fireflyIdsUpdateList (only unique Ids )
//        - Added Awesomefont support
// 1.5.14 - Added highlights to firefly list if 'not yet commissioned'
//        - Fixed missing props in firefly/change message
// 1.5.13 - Changed Firefly list from radio to select, added filter highlight
// 1.5.12 - Fixed/improved responsive sizing
// 1.5.11 - Fix/updated timestamp display for Controllers and Firefly
//        - Added Location to Controller popup
//        - Fixed api POST interface issues. Response should be topic/token to  be compat with MQTT
//        - Fixed mapViewState updates correctly retains map center/zoom. New load now fits bounds.
// 1.5.10 - Display controller Id when startup via lightingplan
//       - Added Last Report & Locations to Controller marker popup, added last report to FireflyMarkerPopup
//       -
// 1.5.9 - Add Demo mode
//       - Fixed named area polygon links from Control screen
//       - Add support for showing transition in led_state on map & in popup
//       - Fix map not rerender if popup open controller & polygon
// 1.5.8 - Reverted back to /get_broker usage (see 1.5.5)
//       - Add support for api/change_controller
//       - Add support for api/change_named_area (WIP)
//       - Requires server 2.0.19
// 1.5.7 - Added isPokeTheWorkerLocal to immediately stop updates
//       - Add support for api/delete_firefly
//       - Add support for api/delete_controller
//       - Add support for api/delete_named_area
//       - Add support for api/delete_area
//       - Requires server 2.0.18
// 1.5.6 - Fixed bug with startup and no Areas defined, reset defaultArea
//       - Fix Area does not delete from State
//       - Reverted /get_broker usage until reviewed (1.5.5)
//       - Requires server 2.0.17
// 1.5.5 - Added support for api/get_broker instead of network.js
// 1.5.4 - Added support for Firefly ‘Notes’ - these display in marker popups, and in detail and list pages
// 1.5.3 - Added Force light control to marker popup, added support for OFF icons.
// 1.5.2 - Added catch for union bug
//

const Footer = (props) => (
  <div className={"footer"}>
    <Segment
      inverted
      vertical
      //   style={{ margin: '0.6em 0em 0em'}}
      style={{ fontSize: "x-small", marginTop: "10px" }}
    >
      <Container textAlign="center">
        <p>
          {`Copyright ${year} - IoT Automation Global | UI: ${VERSION} Built ${dateTimeStamp} ${
            props.serverVersion === undefined
              ? ""
              : `| SERVER: ${props.serverVersion} | SERVER TIME: ${new Date(
                  props.serverTimestamp * 1000
                ).toLocaleString(props.userLanguage, {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: false,
                })}`
          }`}
        </p>
      </Container>
    </Segment>
  </div>
);

function mapStateToProps(state, props) {
  return {
    serverVersion: getServerInfoVersion(state),
    serverTimestamp: getServerTimestamp(state),
    userLanguage: getUserData(state)?.language || "en-US",
  };
}

export default connect(mapStateToProps, null)(Footer);
