import React from "react";
// import { Container, Header, Image } from 'semantic-ui-react'
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

const NoMatch = ({ location }) => (
  <div style={{ paddingTop: "20px" }}>
    <Header as="h3">Page not found</Header>
    <p>
      No match for <code>{location.pathname}</code>
    </p>
  </div>
);

export default NoMatch;
