import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";
import GenericButton from "admin/GenericButton";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { getAllUsers } from "components/UserAdmin/reducer";
import { fetchAllUsers, deleteUser } from "components/UserAdmin/actions";

import { getEmail, getUserRole } from "auth/reducer";

import {
  allowedLanguages as _allowedLanguages,
  allowedRoles as _allowedRoles,
} from "components/ConfigJs";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        userName: "",
        userEmail: "",
        userApplication: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
    };
  }

  componentDidMount() {
    this.props.fetchAllUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        // #NOTE - can't select thisUser
        if (this.props.thisUser !== item.id) {
          newSelections[item.id] = true;
        }
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  isThisUser = (id) => this.props.thisUser === id;

  requestAction = (action) => {
    const { selections } = this.state;
    const { allUsers, thisUser } = this.props;
    // console.log("requestAction selections", selections);
    // console.log("requestAction action ", action);
    // console.log("requestAction allUsers", allUsers);

    const allUsersExceptThisUser = allUsers.filter(
      (user) => user.email !== thisUser.email
    );
    const { deleteUser, goto } = this.props;
    let users = [];
    for (var key in selections) {
      const selection = key;
      allUsersExceptThisUser.forEach((user, idx) => {
        const { email } = user;
        if (email === selection) {
          users.push(email);
        }
      });
    }

    let promiseArray = [];

    users.forEach((user) => {
      promiseArray.push(
        new Promise((resolve, reject) => {
          deleteUser({ values: { id: user }, resolve, reject });
        })
      );
    });

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("DELETE USER: ", results);
      })
      .then(
        () => {
          goto("/user/admin");
        },
        (errorMsg) => {
          console.log("delete failed", errorMsg); // #TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "userlist10" },
              { value: 20, text: "20", key: "userlist20" },
              { value: 40, text: "40", key: "userlist40" },
              { value: 60, text: "60", key: "userlist60" },
              { value: data.length, text: "all", key: "userlistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const usersFromDataById = (id) => allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let addButton;
    let deleteButton;
    let usersSelectedList;

    addButton = (
      <Link to="/user/admin/new">
        <Button color="blue" content={strings?.["Add"]} size="large" />
      </Link>
    );

    if (selectedCount > 0) {
      let userEmailNames = [];
      //console.log("data", data);

      // #REVIEW - WIP
      for (var key in selections) {
        if (usersFromDataById(key) !== undefined) {
          let userEmailName = usersFromDataById(key).userEmail;

          // namedAreaName = namedAreaName.substring(
          //   0,
          //   namedAreaName.indexOf(" (")
          // ); // strip off the trailing ip
          userEmailNames.push(userEmailName);
        }
      }
      userEmailNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      userEmailNames = userEmailNames.join(", "); // separate by commas

      deleteButton = (
        <DeleteButton
          onClick={() => this.requestAction("delete")}
          strings={strings}
        />
      );

      usersSelectedList = (
        <Segment textAlign="left">
          <strong>Users selected ... </strong>
          {userEmailNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["Users"]}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <>
                  <Button.Group>{addButton}</Button.Group>

                  <span>
                    {
                      strings?.[
                        "Select items in the table below to apply an action"
                      ]
                    }
                  </span>
                </>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or size="large" />
                  {deleteButton}
                </Button.Group>
              </div>
              <span
                style={{
                  marginLeft: "20px",
                  visibility: areAnySelected ? "visible" : "hidden",
                }}
              >
                {viewablesPage.length} items displayed. {selectedCount} selected
              </span>
            </Segment>
            {usersSelectedList}
          </Segment.Group>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="trash" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="userName"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="userEmail"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="userApplication"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "userEmail" ? direction : null}
                  onClick={this.handleSort("userEmail")}
                >
                  {strings?.["Email"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "userName" ? direction : null}
                  onClick={this.handleSort("userName")}
                >
                  {strings?.["Name"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "userRole" ? direction : null}
                  onClick={this.handleSort("userRole")}
                >
                  {strings?.["Role"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "userLang" ? direction : null}
                  onClick={this.handleSort("userLang")}
                >
                  {strings?.["Language"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={6}
                  sorted={column === "userApplication" ? direction : null}
                  onClick={this.handleSort("userApplication")}
                >
                  {strings?.["Application"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({
                  id,
                  userEmailLink,
                  userEmail,
                  userName,
                  userRole,
                  userLang,
                  userApplication,
                }) => (
                  <Table.Row key={`row-id-${id}`}>
                    <Table.Cell>
                      <Checkbox
                        checked={this.isItemSelected(id)}
                        onChange={() => this.handleSelect(id)}
                        disabled={this.isThisUser(id)}
                      />
                    </Table.Cell>
                    <Table.Cell>{userEmailLink}</Table.Cell>
                    <Table.Cell>{userName} </Table.Cell>
                    <Table.Cell>{userRole}</Table.Cell>
                    <Table.Cell>{userLang}</Table.Cell>
                    <Table.Cell>{userApplication}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements, thisUserRole, allowedRoles, strings) => {
  let newElements = JSON.parse(JSON.stringify(elements));

  // super users are to be hidden
  if (thisUserRole !== "super")
    newElements = elements.filter((user) => user.role !== "super");

  let filteredElements = [];
  newElements.forEach(function (user, idx) {
    const { username, email, role, language, settings } = user;

    const { application } = settings;

    // localise the application list
    let newApplication = [];
    application.forEach((app) => {
      newApplication.push(strings?.[app]);
    });

    filteredElements[idx] = {
      id: email,
      userEmail: email,
      userEmailLink: <Link to={`/user/admin/edit/${email}`}>{email} </Link>,
      userName: username,
      userRole: allowedRoles.find((option) => option.key === role)?.text,
      userLang: language,
      userApplication: newApplication?.join(", "),
    };
  });

  return filteredElements;
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    deleteUser: (data) => dispatch(deleteUser(data)),
    goto: (path) => dispatch(push(path)),
    fetchAllUsers: () => dispatch(fetchAllUsers()),
  };
};

const mapStateToProps = (state, props) => {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  const isLoading = false;
  const error = false;

  const allUsers = getAllUsers(state);

  const thisUser = getEmail(state) || "?";
  const thisUserRole = getUserRole(state) || "";

  const allowedRoles = _allowedRoles();
  const data = _prepData(allUsers, thisUserRole, allowedRoles, props.strings);

  return {
    isLoading,
    error,
    data,
    allUsers,
    thisUser,
    thisUserRole,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
