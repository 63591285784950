import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Message, Popup } from "semantic-ui-react";

import { getAllMessages } from "FlashMessages/reducer";

import MediaQuery from "react-responsive";

import { removeFlashMessage } from "FlashMessages/actions";
import { strings } from "components/App/localisation";

class FlashMessagesList extends Component {
  static propTypes = {
    messages: PropTypes.array,
  };

  render() {
    let { messages } = this.props;

    // This is WIP ....
    // The idea is to truncate the messages to appear on one line only so the text does not wrap.

    // Need to keep a long version for the popup tooltip
    let addLongToMessages = [];
    messages.forEach(function (value, idx) {
      const contentLong = value.content;
      addLongToMessages[idx] = {
        ...value,
        contentLong: contentLong,
      }; // spread out the object and update 'content' to be truncated to same length as header with trailing ...
    });
    messages = addLongToMessages;

    // Make a version of messages which strips out the content line leaving only the header
    // this is used when multiple messages are displayed in the same line
    let miniMessages = [];
    messages.forEach(function (value, idx) {
      const miniMsg =
        value.content.substring(0, value.header.length * 1.5) + " ..."; // content line is a multiple of the header text plus ...
      miniMessages[idx] = { ...value, content: miniMsg }; // spread out the object and update 'content' to be truncated to same length as header with trailing ...
    });

    // show the mini message version if there is more than one message box
    if (messages.length - 1) {
      messages = miniMessages;
    }

    return (
      <>
        <MediaQuery maxWidth={1224}>
          {messages.map(({ id, contentLong, ...rest }) => {
            return (
              <div key={`div-${id}`}>
                <Popup
                  content={`${contentLong} ${strings?.["Click to dismiss message"]}`}
                  trigger={
                    <Message
                      key={`message-${id}`}
                      onClick={() => {
                        this.props.removeFlashMessage(id);
                      }}
                      {...rest}
                    />
                  }
                />
              </div>
            );
          })}
        </MediaQuery>
        <MediaQuery minWidth={1225}>
          <div style={{ display: "flex" }}>
            {messages.map(({ id, contentLong, ...rest }) => {
              return (
                <div
                  key={`div-${id}`}
                  style={{ flexGrow: 1, paddingRight: "5px" }}
                >
                  <Popup
                    content={`${contentLong} ${strings?.["Click to dismiss message"]}`}
                    trigger={
                      <Message
                        key={`message-${id}`}
                        {...rest}
                        onClick={() => {
                          this.props.removeFlashMessage(id);
                        }}
                      />
                    }
                  />
                </div>
              );
            })}
          </div>
        </MediaQuery>
      </>
    );
  }
}

function mapStateToProps(state) {
  return { messages: getAllMessages(state) };
}

export default connect(mapStateToProps, { removeFlashMessage })(
  FlashMessagesList
);
