import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";

import {
  Header,
  Grid,
  Table,
  Container,
  Input,
  Pagination,
  Dropdown,
  Icon,
  Segment,
  Button,
  Checkbox,
  Radio,
  Popup,
  Message,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";

import { fetchUPSs, fetchFireflys } from "UPSPanelControllers/actions";

//import { getAllFireflys } from "UPSPanelControllers/reducer";
import { getAllFireflyStatuses } from "components/WebWorker/reducer";

import { deleteFirefly } from "UPSPanelControllers/actions";

import { hasTopologyChanged } from "UPSPanelControllers/reducer";

import FireflyLink from "./FireflyLink";
import UPSLink from "../ups/UPSLink";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

import { formatRelative, parseISO } from "date-fns";
import { formatDateRelativeNow, minutesAgo } from "utils/format-date";

//import { timeConverter } from "utils/format-time";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { getAllConflicts } from "components/StatusChecks/reducer";

//import { sortAlphaNum } from "utils/sortAlphaNum";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { withComponentStateCache } from "react-component-state-cache";

import { StatusEnum } from "utils/StatusEnum";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import { getMqttMsg } from "components/WebWorker/reducer.js";

import { Download } from "components/Download/index.js";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

const displayOptions = [
  {
    key: "all",
    text: "Show All",
    value: "all",
  },
  {
    key: "ok",
    text: "OK",
    value: "ok",
  },
  {
    key: "commissioned",
    text: "New",
    value: "commissioned",
  },
  // {
  //   key: "conflict",
  //   text: "Position Conflict",
  //   value: "conflict",
  // },
  // {
  //   key: "moved",
  //   text: "Position Moved",
  //   value: "moved",
  // },
  {
    key: "shifted",
    text: "Position Shifted",
    value: "shifted",
  },
  {
    key: "timeout",
    text: "Timeout",
    value: "timeout",
  },
  {
    key: "old",
    text: "No Status Report",
    value: "old",
  },
];

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class FireflyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        firefly: { string: "", include: true },
        controller: { string: "", include: true },
        area: { string: "", include: true },
        location: { string: "", include: true },
        position: { string: "", include: true },
        note: { string: "", include: true },
        status: { string: "", include: true },
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox delete selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
      // default for radio button group
      statusGroup: "all",
    };
  }

  componentDidMount() {
    const { data, fetchUPSs, fetchFireflys, componentstate } = this.props;
    // if (_isEmpty(data)) {
    //   // #FETCHS
    //   fetchUPSs(); // #FETCHS
    //   fetchFireflys(); // #FETCHS
    // }

    const filterInput = componentstate.get("filterInput", "fireflyList");

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });

      // reset the radio button states
      //
      const filterInputStatusString = filterInput?.status?.string;

      switch (filterInputStatusString) {
        case "":
          this.setState({ statusGroup: "all" });
          break;
        case "ok":
          this.setState({ statusGroup: "ok" });
          break;
        case "Notcommissioned":
          this.setState({ statusGroup: "commissioned" });
          break;
        case "sameposition.Statusreportmissing":
          this.setState({ statusGroup: "conflict" });
          break;
        case "Reportedtopologychanged":
          this.setState({ statusGroup: "moved" });
          break;
        case "Shifted":
          this.setState({ statusGroup: "shifted" });
          break;
        case "Nostatusreport":
          this.setState({ statusGroup: "old" });
          break;
        case "Timeout":
          this.setState({ statusGroup: "timeout" });
          break;
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "fireflyList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          const checkItemString = item[key]?.toLowerCase()?.replace(/\s/g, ""); // strip white spaces from value to e.g. "UPS02" = "UPS 02"
          const checkMatchString = match[key]?.string?.toLowerCase();
          const checkIncludes =
            checkMatchString !== "" // remove item which don't match, but exclude "" empty case
              ? match[key].include && checkItemString.includes(checkMatchString) //'.include' = true for include, = false for exclude
              : true;

          checkMatch = checkMatch && checkIncludes;
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });

      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  //  handleFilterStatusChange = ({ checked, value }) => { // <<--- for radio buttons
  handleFilterStatusChange = (e, data) => {
    this.setState({ statusGroup: data.value });

    let search = "";

    switch (data.value) {
      case "ok":
        search = "ok";
        break;
      case "commissioned":
        search = "Not commissioned";
        break;
      case "conflict":
        search = "same position. Status report missing";
        break;
      case "moved":
        search = "Reported topology changed";
        break;
      case "shifted":
        search = "Shifted";
        break;
      case "old":
        search = "No status report";
        break;
      case "timeout":
        search = "Timeout";
        break;
      case "all":
      default:
        search = "";
        break;
    }
    this.setFilterState({ name: "status", value: search }, true);
  };

  handleFilterChange = (e) => {
    const target = e.target;
    this.setFilterState(target, true);
  };

  setFilterState = ({ name, value }, include) => {
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // strip white spaces from value to e.g. "UPS02" = "UPS 02"
    // update match value with most recent filter entry
    match[name].string = value.replace(/\s/g, "");
    match[name].include = include;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (e, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    console.log("xxx fireflylist handleSelect id", id);
    this.setState(
      (prevState) => {
        if (_has(prevState.selections, id)) {
          // { 1: true } -> {}
          return _derivedState(
            update(prevState, {
              selections: { $unset: [id] },
            })
          );
        }
        // {} -> { 1: true }
        return _derivedState(
          update(prevState, {
            selections: { [id]: { $set: true } },
          })
        );
      }
      //() => console.log("xxx this.state.selections", this.state.selections)
    );
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        if (
          item.statusValue !== StatusEnum.NOT_COMMISSIONED ||
          this.props.isDeleteFireflyNotCommissioned // &&  item.statusValue !== StatusEnum.OK
        ) {
          newSelections[item.id] = true;
        }
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  requestDelete = () => {
    const { selections } = this.state;
    const { deleteFirefly, push } = this.props;

    let promiseArray = [];

    for (var selection in selections) {
      //console.log("deleting Firefly ... ", selection);
      promiseArray.push(
        // eslint-disable-next-line no-loop-func
        new Promise((resolve, reject) => {
          deleteFirefly({ values: { id: selection }, resolve, reject });
        })
      );
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("DELETING FIREFLYS: ", results);
      })
      .then(
        () => {
          this.props.goto("/admin/firefly/");
        },
        (msg) => {
          console.log("delete failed", msg); // TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "fflist10" },
              { value: 20, text: "20", key: "fflist20" },
              { value: 40, text: "40", key: "fflist40" },
              { value: 60, text: "60", key: "fflist60" },
              { value: data.length, text: "all", key: "fflistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Total of {itemsCount} items.</span>{" "}
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const fireflyFromDataById = (id) => allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let deleteButton;
    let fireflyDeleteList;
    if (selectedCount > 0) {
      let fireflyNames = [];
      //console.log("data", data);
      for (var key in selections) {
        if (fireflyFromDataById(key) !== undefined) {
          let fireflyName = fireflyFromDataById(key).firefly; // returns "name (ip_address)"
          //fireflyName = fireflyName.substring(0, fireflyName.indexOf(" (")); // strip off the trailing ip
          fireflyNames.push(fireflyName);
        }
      }
      fireflyNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      fireflyNames = fireflyNames.join(", "); // separate by commas

      deleteButton = (
        <DeleteButton onClick={this.requestDelete} strings={strings} />
      );

      fireflyDeleteList = (
        <Segment textAlign="left">
          <strong>FireFlys selected ... </strong>
          {fireflyNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["FireFly Status"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <span>
                  {viewablesPage.length} items displayed. Select items in the
                  table below to apply an action.
                </span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or size="large" />
                  {deleteButton}
                </Button.Group>
              </div>
              <span
                style={{
                  marginLeft: "20px",
                  visibility: areAnySelected ? "visible" : "hidden",
                }}
              >
                {viewablesPage.length} items displayed. {selectedCount} selected
              </span>
              <div>
                <Download data={data} dataType={"firefly"} />
              </div>
            </Segment>
            {fireflyDeleteList}
          </Segment.Group>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="trash" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.firefly?.string
                        ? "filterInputHighlight"
                        : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="firefly"
                    onChange={this.handleFilterChange}
                    value={filterInput?.firefly?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.controller?.string
                        ? "filterInputHighlight"
                        : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="controller"
                    onChange={this.handleFilterChange}
                    value={filterInput?.controller?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.area?.string ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="area"
                    onChange={this.handleFilterChange}
                    value={filterInput?.area?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.location?.string
                        ? "filterInputHighlight"
                        : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="location"
                    onChange={this.handleFilterChange}
                    value={filterInput?.location?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.position?.string
                        ? "filterInputHighlight"
                        : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="position"
                    onChange={this.handleFilterChange}
                    value={filterInput?.position?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.note?.string ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="note"
                    onChange={this.handleFilterChange}
                    value={filterInput?.note?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Dropdown
                    fluid
                    selection
                    className={
                      this.state.statusGroup !== "all"
                        ? "dropdownSelectioHighlight"
                        : null
                    }
                    style={{ margin: "5px", width: "150px" }}
                    options={displayOptions}
                    value={this.state.statusGroup}
                    onChange={this.handleFilterStatusChange}
                    //onChange={(e, data) => this.handleFilterStatusChange(data)}
                  />

                  {/* <Grid doubling columns={3}>
                    <Grid.Column style={{ paddingBottom: "0rem" }}>
                      <Popup
                        content="Shows all Fireflies."
                        trigger={
                          <Radio
                            label="Show All"
                            name="statusGroup"
                            value="all"
                            checked={this.state.statusGroup === "all"}
                            onChange={(e, data) =>
                              this.handleFilterStatusChange(data)
                            }
                          />
                        }
                      />
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: "0rem" }}>
                      <Popup
                        content="Shows only Fireflies checked as OK."
                        trigger={
                          <Radio
                            label="Firefly OK"
                            name="statusGroup"
                            value="ok"
                            checked={this.state.statusGroup === "ok"}
                            onChange={(e, data) =>
                              this.handleFilterStatusChange(data)
                            }
                          />
                        }
                      />
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: "0rem" }}>
                      <Popup
                        content="Shows only new Fireflies yet to be commissioned."
                        trigger={
                          <Radio
                            label="New Firefly"
                            name="statusGroup"
                            value="commissioned"
                            checked={this.state.statusGroup === "commissioned"}
                            onChange={(e, data) =>
                              this.handleFilterStatusChange(data)
                            }
                          />
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Popup
                        content="Shows Fireflies which conflict with another Firefly. Delete these Fireflies."
                        trigger={
                          <Radio
                            label="Position Conflict"
                            name="statusGroup"
                            value="conflict"
                            checked={this.state.statusGroup === "conflict"}
                            onChange={(e, data) =>
                              this.handleFilterStatusChange(data)
                            }
                          />
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Popup
                        content="Shows Fireflies which have been moved."
                        trigger={
                          <Radio
                            label="Position Moved"
                            name="statusGroup"
                            value="moved"
                            checked={this.state.statusGroup === "moved"}
                            onChange={(e, data) =>
                              this.handleFilterStatusChange(data)
                            }
                          />
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Popup
                        content="Shows Fireflies which have no recent status report. These could be faulty, disconnected or removed."
                        trigger={
                          <Radio
                            label="No Status Report"
                            name="statusGroup"
                            value="old"
                            checked={this.state.statusGroup === "old"}
                            onChange={(e, da"]}ta) =>
                              this.handleFilterStatusChange(data)
                            }
                          />
                        }
                      />
                    </Grid.Column>
                  </Grid> */}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "firefly" ? direction : null}
                  onClick={this.handleSort("firefly")}
                >
                  {strings?.["FireFly"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "controller" ? direction : null}
                  onClick={this.handleSort("controller")}
                >
                  {strings?.["Controller"]}
                </Table.HeaderCell>
                {/* <Table.HeaderCell width={1}>Bulk Position</Table.HeaderCell> */}
                <Table.HeaderCell
                  width={2}
                  sorted={column === "area" ? direction : null}
                  onClick={this.handleSort("area")}
                >
                  {strings?.["Area"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "location" ? direction : null}
                  onClick={this.handleSort("location")}
                >
                  {strings?.["Location"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "position" ? direction : null}
                  onClick={this.handleSort("position")}
                >
                  {strings?.["Position"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "fireflyMac" ? direction : null}
                  onClick={this.handleSort("fireflyMac")}
                >
                  {strings?.["MAC"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "swVersion" ? direction : null}
                  onClick={this.handleSort("swVersion")}
                >
                  {strings?.["SW Version"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "lastStatusReport" ? direction : null}
                  onClick={this.handleSort("lastStatusReport")}
                >
                  {strings?.["Latest Report"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "note" ? direction : null}
                  onClick={this.handleSort("note")}
                >
                  {strings?.["Note"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "status" ? direction : null}
                  onClick={this.handleSort("status")}
                >
                  {strings?.["Status"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({
                  id,
                  firefly,
                  fireflyLink,
                  controller,
                  controllerLink,
                  // controllerBulkPositionLink,
                  area,
                  position,
                  location,
                  lastStatusReport,
                  status,
                  disableDelete,
                  swVersion,
                  fireflyMac,
                  note,
                  style,
                }) => (
                  <Table.Row key={`row-id-${id}`} style={style}>
                    <Table.Cell>
                      <Checkbox
                        checked={this.isItemSelected(id)}
                        onChange={() => this.handleSelect(id)}
                        disabled={disableDelete}
                      />
                    </Table.Cell>
                    <Table.Cell>{fireflyLink}</Table.Cell>
                    <Table.Cell>{controllerLink || "-"}</Table.Cell>
                    {/* <Table.Cell>{controllerBulkPositionLink || "-"}</Table.Cell> */}
                    <Table.Cell>{area || "-"}</Table.Cell>
                    <Table.Cell>{location || "-"}</Table.Cell>
                    <Table.Cell>{position || "-"}</Table.Cell>
                    <Table.Cell>{fireflyMac || "-"}</Table.Cell>
                    <Table.Cell>{swVersion || "-"}</Table.Cell>
                    <Table.Cell>{lastStatusReport}</Table.Cell>
                    <Table.Cell>{note || "-"}</Table.Cell>
                    <Table.Cell>{status || "-"}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

// const StatusEnum = {
//   OK: 0,
//   OLD_STATUS_REPORT: 1,
//   NO_STATUS_REPORT: 2,
//   DIFFERENT_TOPOLOGY: 3,
//   NOT_COMMISSIONED: 4,
//   SAME_POSITION: 5,
//   NEW: 6,
//   properties: {
//     1: { warning: true },
//     2: { error: true },
//     3: { error: true },
//     4: { error: true },
//     5: { error: true },
//     6: { error: true },
//   },
// };

const getStatus = (firefly, fireflies, conflicts, mqttMsg) => {
  const { id, timestamp, topology } = firefly;

  // #WIP #MOVED

  //console.log(`xxx mqttMsg`, mqttMsg);

  const faults = [
    `Firefly IOT_Office:T1:3 MAC was "c4:4f:33:23:6f:fd", has been changed to "c4:4f:33:23:ad:01"`,
    `Firefly IOT_Office:T1:2 MAC was "c4:4f:33:23:aa:35", has been changed to "c4:4f:33:23:6f:fd"`,
    `Firefly IOT_Office:T1:1 MAC was "24:0a:c4:50:c8:58" has been replaced to "c4:4f:33:23:aa:35"`,
  ];

  const shifted = [
    // {
    //   id: "IOT_Office:T1:3",
    //   area: "IOT_Office",
    //   location: "T1",
    //   position: 3,
    //   oldMac: "c4:4f:33:23:6f:fd",
    //   newMac: "c4:4f:33:23:ad:01",
    // },
    // {
    //   id: "IOT_Office:T1:2",
    //   area: "IOT_Office",
    //   location: "T1",
    //   position: 2,
    //   oldMac: "c4:4f:33:23:aa:35",
    //   newMac: "c4:4f:33:23:6f:fd",
    // },
    // {
    //   id: "IOT_Office:T1:1",
    //   area: "IOT_Office",
    //   location: "T1",
    //   position: 1,
    //   oldMac: "24:0a:c4:50:c8:58",
    //   newMac: "c4:4f:33:23:aa:35",
    // },
  ];

  // find unique Set of locations
  // for each location
  const shiftedInLocation = shifted.filter((item) => item.location === "T1");
  //console.log(`xxx movedInLocation`, movedInLocation);

  // if >1 entry for locations
  //
  let sortedShiftedInLocation = [];
  let shiftedPosition = [];
  if (shiftedInLocation.length > 1) {
    sortedShiftedInLocation = shiftedInLocation?.sort(
      (a, b) => a.position - b.position
    );

    // iterate to the last index
    sortedShiftedInLocation.forEach((val, idx, sortedShiftedInLocation) => {
      // test for valid object in last index

      if (!Object.is(sortedShiftedInLocation.length - 1, idx)) {
        const nextItem = sortedShiftedInLocation[idx + 1];
        // if consecutive positions e.g. 2 = 3 - 1
        if (val.position === nextItem?.position - 1) {
          if (val.newMac === nextItem?.oldMac) {
            shiftedPosition.push({ id: val.id, prevPosition: nextItem.id });
          }
        }
      }
    });
  }

  //console.log(`xxx sortedMovedInLocation`, sortedMovedInLocation);
  //console.log(`xxx movedPosition`, movedPosition);

  //... check for position moved (shifted)
  const isShiftedPosition = shiftedPosition.find((item) => item.id === id);
  if (isShiftedPosition) {
    return {
      value: StatusEnum.SHIFTED_POSITION,
      message: `Shifted. Was ${isShiftedPosition.prevPosition}`,
    };
  }

  // #WIP #TODO
  //
  // NEED TO REVIEW PROCESS FOR v3
  //

  // check for position conflicts
  let samePosition = [];
  //
  // conflicts.forEach((conflict, index, arr) => {
  //   const { fireflyIps } = conflict;
  //   if (fireflyIps !== undefined) {
  //     if (fireflyIps.includes(firefly.id)) {
  //       // get FFs conflicting at the samePosition (i.e. not this FF)
  //       const foundThese = fireflyIps.filter(function (x) {
  //         return x !== firefly.id;
  //       });
  //       // see if we already have foundThese
  //       foundThese.forEach((conflict, index, arr) => {
  //         if (!samePosition.includes(conflict)) {
  //           samePosition.push(conflict);
  //         }
  //       });
  //     }
  //   }
  // });

  // if (samePosition.length) {
  //   return {
  //     value: StatusEnum.SAME_POSITION,
  //     message: `Firefly (${[...samePosition]}) at the same position. ${
  //       lastStatusReport ? "Status report exists" : "Status report missing"
  //     }.`,
  //   };
  // }

  // ....check for a status report?
  if (!timestamp) {
    return {
      value: StatusEnum.NO_STATUS_REPORT,
      message: "No status report",
    };
  }

  // ... has it been commissioned?
  if (
    topology &&
    topology?.deviceStatus?.includes(StatusEnum.NOT_COMMISSIONED)
  ) {
    return {
      value: StatusEnum.NOT_COMMISSIONED,
      message: "New - Not commissioned",
    };
  }

  // ... has it moved?
  // if (hasTopologyChanged(firefly, lastStatusReport)) {
  //   return {
  //     value: StatusEnum.DIFFERENT_TOPOLOGY,
  //     message: "Reported topology changed",
  //   };
  // }

  // ... has it timed out?
  if (
    topology &&
    (topology?.deviceStatus?.includes(StatusEnum.INACTIVE) ||
      topology?.deviceStatus?.includes(StatusEnum.TIMEOUT))
  ) {
    return {
      value: StatusEnum.INACTIVE,
      message: "Timeout",
    };
  }

  // ... is status report recent? i.e. in past 15 minutes?
  const minsAgo = minutesAgo(parseISO(timestamp));
  if (minsAgo > 15) {
    return {
      value: StatusEnum.OLD_STATUS_REPORT,
      message: `Last status report was ${formatRelative(
        parseISO(timestamp),
        new Date(),
        {
          includeSeconds: true,
        }
      )}`,
    };
  }

  // ... OK!
  return { value: StatusEnum.OK, message: "Ok" };
};

const _prepData = (
  elements,
  conflicts,
  roles,
  mqttMsg,
  isDeleteFireflyNotCommissioned
) => {
  //console.log("testFFs data elements", elements);

  const { allowGuest, allowOperator, allowAdmin, allowSuper } = roles;
  let filteredElements = [];

  elements.forEach(function (element, idx) {
    const { id, topology, fireflyNote, timestamp } = element;
    const { area, position, location, ups_id, ups_name, mac } = topology;

    // if (id === "DMLZ_Extraction:P17:1") {
    //   console.log("P17:1 testFFs data element", element);
    // }

    let lastStatusReport = "-";
    if (timestamp !== undefined) {
      // format - previous used in tableformatters.js
      lastStatusReport = formatRelative(parseISO(timestamp), new Date(), {
        includeSeconds: true,
      });
    }

    let note = "-";
    if (fireflyNote !== undefined) {
      note = fireflyNote;
    }

    let swVersion = "-";
    // #NOTE - dead, or unresponsive FFs will not have a .device propertie
    //
    if (element?.device !== undefined) {
      const {
        device: { sw_ver },
      } = element;
      if (sw_ver !== undefined) {
        swVersion = sw_ver;
      }
    }

    //let upsName = "-";
    // #REVIEW
    // this is a hack to get the mineLevel name - should be in the UPS message?!
    //upsName = upsById[upsId].name || "-";

    // styling for rows for highlight or error states
    // report status of row
    const elementStatus = getStatus(element, elements, conflicts, mqttMsg);

    const { value, message } = elementStatus;
    let icon = "check";
    let color = "green";
    let style = null;
    if (value !== StatusEnum.OK) {
      icon = "warning sign";
      const props = StatusEnum.properties[value];
      const { warning = false, error = false } = props;
      color = (error && "red") || (warning && "yellow") || "blue";
      style = { backgroundColor: "rgb(219, 40, 40, 15%)" };
    }
    if (value === StatusEnum.NOT_COMMISSIONED) {
      style = { backgroundColor: "rgb(255, 255, 0, 15%)" };
    }

    const status = message;

    // disable delete for NOT_COMMISSIONED, and OK
    const disableDelete =
      value === StatusEnum.NOT_COMMISSIONED && !isDeleteFireflyNotCommissioned; // || value === StatusEnum.OK

    const fireflyLink = allowAdmin ? (
      <FireflyLink id={id}>{id}</FireflyLink>
    ) : (
      `${id || ""}`
    );

    const controllerLink = allowAdmin ? (
      <UPSLink id={ups_id}>{`${ups_id || ""} (${id})`}</UPSLink>
    ) : (
      `${ups_id || ""} (${id})`
    );

    // compose new data set
    filteredElements[idx] = {
      id: id,
      firefly: `${id}`,
      fireflyLink: fireflyLink,
      fireflyMac: mac ? `${mac}` : "-",
      controller: `${ups_name} (${ups_id})`,
      controllerLink: controllerLink,
      // controllerBulkPositionLink: (
      //   <Link to={`/admin/ups/${ups_id}`}>{ups_name}</Link>
      // ),
      area: area,
      position: `${position}`, // needs to be string for sort ordering (see localCompare)
      location: location,
      lastStatusReport: lastStatusReport,
      status: status,
      statusValue: value,
      disableDelete: disableDelete,
      swVersion: `${swVersion}`,
      note: note,
      style,
      // Raw data for download report
      ups_name,
      ups_id,
      timestamp,
    };
  });

  return filteredElements;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    deleteFirefly: (data) => dispatch(deleteFirefly(data)),
    fetchUPSs: () => dispatch(fetchUPSs()),
    fetchFireflys: () => dispatch(fetchFireflys()),
    saveUserSettingsComponentState: (data) =>
      dispatch(saveUserSettingsComponentState(data)),
  };
};

function mapStateToProps(state, props) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  // const { isLoading, error } = state.ups
  const isLoading = false;
  const error = false;

  // #REVIEW
  // this is a hack to get the UPS name - should be in the Firefly message?!
  //const { upsPanelControllers } = state;
  //const { upsById } = upsPanelControllers;

  const fireflyStatuses = getAllFireflyStatuses(state);
  const conflicts = getAllConflicts(state);

  const mqttMsg = getMqttMsg(state);

  // user roles
  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);
  const allowGuest = userGuest;

  const allowOperator = userSuper || userAdmin || userUser;
  const allowAdmin = userSuper || userAdmin;
  const allowSuper = userSuper;

  const { isDeleteFireflyNotCommissioned } = props;

  const data = _prepData(
    fireflyStatuses,
    conflicts,
    {
      allowGuest: allowGuest,
      allowOperator: allowOperator,
      allowAdmin: allowAdmin,
      allowSuper: allowSuper,
    },
    mqttMsg,
    isDeleteFireflyNotCommissioned
  );

  return {
    isLoading,
    error,
    data,
    conflicts,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, mapDispatchToProps)(FireflyList)
);
