import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

//import CreateMineLevelForm from "./MineLevelCreateForm";

import SubmissionModal from "admin/SubmissionModal";

import {
  SubmissionError,
  //
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
  getFormMeta,
  getFormAsyncErrors,
  getFormSyncWarnings,
  getFormSubmitErrors,
  getFormError,
  getFormNames,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from "redux-form";

import { Dimmer, Loader } from "semantic-ui-react";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class CreateNamedAreaGroups extends Component {
  state = {
    errorMessage: "",
  };

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    const { saveNewMineLevel } = this.props;

    return new Promise((resolve, reject) => {
      //console.log("values", values);
      saveNewMineLevel({ values, resolve, reject });
    }).catch((error) => {
      console.log(`onSubmit error`, error);
      this.onSetError("failed");
      // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
      throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
    });
  };

  render() {
    const { saveNewMineLevel, submitting, strings } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    // #NOTE-
    // #WIP - structure for a create named area group.
    // Incomplete.

    return (
      <>
        {/* <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          errorMessage={errorMessage}
        />
        <Dimmer active={submitting} page>
          <Loader>Saving changes</Loader>
        </Dimmer>
        <NavigationPromptModal
          formName={"createNamedAreaGroupForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <CreateMineLevelForm
          onSubmit={(values) => {
            return new Promise((resolve, reject) => {
              //console.log("values", values);
              saveNewMineLevel({ values, resolve, reject });
            }).catch((error) => {
              console.log(`onSubmit ${error}`);
              this.onSetError("failed");
              // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
              throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
            });
          }}
          onSubmitSuccess={(result, dispatch) => {
            console.log(`onSubmitSuccess ${result}`);
            // wait for state change before redirecting page
            this.setState(
              {
                submitted: true,
              },
              () => {
                this.onResetError();
                this.props.goto("/admin/named-area-group");
              }
            );
          }}
          onSubmitFail={(errors) => {
            console.log(`onSubmitFail ${errors}`);
          }}
          onCancel={() => {
            this.props.goto("/admin/named-area-group");
          }}
        /> */}
      </>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    //    saveNewMineLevel: (data) => dispatch(saveNewMineLevel(data)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

export default connect(
  (state) => ({
    //submitting: isSubmitting("createNewNamedAreaGroupForm")(state),
  }),
  mapDispatchToProps
)(CreateNamedAreaGroups);
