export const fetchEventLog = () => {
  return { type: "EVENT_LOG_FETCH_REQUESTED" };
};

export const fetchEventLogPeriod = (data) => {
  return { type: "EVENT_LOG_PERIOD_FETCH_REQUESTED", payload: data };
};

export const deleteEventLog = (data) => {
  console.log("EVENT_LOG_DELETE_REQUESTED", data);
  return { type: "EVENT_LOG_DELETE_REQUESTED", payload: data };
};
