import { myApi } from "api/apiAxios";

export function* login(email, password) {
  const response = yield myApi.post("/auth/login", { email, password });
  //console.log("auth login response", response);
  return response;
}

export function* logout() {
  const response = yield myApi.post("/auth/logout");
  //console.log("auth logout response", response);
  return response;
}

export function* getUser() {
  // console.log(
  //   "getUser process.env.REACT_APP_AXIOS_BASE_URL",
  //   process.env.REACT_APP_AXIOS_BASE_URL
  // );
  //  console.log("auth getUser");
  const response = yield myApi.get("/auth/user");
  return response;
}
