// import React from "react";

// const User = () => <div>Nothing here yet...coming soon...user profile</div>;

// export default User;

import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";

import UserEditPage from "components/User/UserEditPage";

class UserPages extends Component {
  render() {
    const { match, strings } = this.props;

    //console.log(`UserPages`, match);
    //console.log("xxx UserPages - strings ", strings);
    return (
      <Switch>
        <Route
          path={`${match.url}/:id`}
          //component={UserEditPage}
          render={(props) => <UserEditPage {...props} strings={strings} />}
        />
        <Route
          exact
          path={match.url}
          //component={UserEditPage}
          render={(props) => <UserEditPage {...props} strings={strings} />}
        />
      </Switch>
    );
  }
}

export default withRouter(UserPages);
