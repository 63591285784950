export function GeoJsonToMqttNamedAreaChange(values) {
  const {
    id,
    area,
    name,
    coordinates,
    type,
    priority,
    origin,
    parent,
    parent_name,
    button,
    default_color,
    default_state,
    style,
    token,
  } = values;

  // duplicate first coordinate in shape to close the ring
  // e.g. coordinates
  // [
  //   [
  //     [737076.1835723922, 9549038.410007201],
  //     [737092.1832776744, 9549024.94413115],
  //     [737129.8460999232, 9549065.317777187],
  //     [737110.3945287307, 9549077.532396676],
  //   ],
  // ],

  let shape;
  // NOTE - only supports a single polygon shape, not multi-, so get the first shape in coordinates array
  shape = coordinates[0];
  // check first point and last should be the same
  if (shape[0] !== shape[shape.length - 1]) {
    // or append first to end point to close the polygon ring
    shape = [...shape, shape[0]];
  }

  return {
    id: id,
    area: area,
    name: name,
    shape: shape,
    type: type,
    priority: priority,
    origin: origin,
    parent: parent,
    parent_name: parent_name,
    button: button,
    default_color: default_color,
    default_state: default_state,
    style,
    token: token,
  };
}
