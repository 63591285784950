export function round(val, places = 4) {
  switch (typeof val) {
    case "number":
      const rnd = Math.pow(10, places);
      return Math.round(val * rnd) / rnd;
    case "string":
      return round(Math.parseFloat(val), places);
    default:
      return val;
  }
}
