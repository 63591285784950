import React from "react";
import DeviceName from "components/DeviceName";

import { connect } from "react-redux";
import { getUPSById } from "UPSPanelControllers/reducer";
import UPSLink from "admin/ups/UPSLink";

const Component = ({ ...props }) => {
  return (
    <UPSLink id={props.id}>
      <DeviceName {...props} />
    </UPSLink>
  );
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const ups = getUPSById(state, id);
  if (ups) {
    return { id, name: ups.name };
  }
  return { id, error: "no UPS for id" };
};

export default connect(mapStateToProps)(Component);
