import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiTagTracking";
import { SubmissionError } from "redux-form";

const checkTagData = (data) => {
  if (data === undefined || data === null) {
    return [];
  } else {
    return data;
  }
};

function* fetchTags(action) {
  try {
    const fetch = yield call(Api.getTags);
    //console.log(`tagTracking TAGS_FETCH_SUCCEEDED fetch`, fetch);
    const data = checkTagData(fetch?.data?.list);
    //console.log(`tagTracking TAGS_FETCH_SUCCEEDED data`, data);
    yield put({
      type: "TAGS_FETCH_SUCCEEDED",
      payload: data,
    });
  } catch (error) {
    console.log(`TAGS_FETCH_FAILED ERROR`, error);

    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "TAGS_FETCH_FAILED",
          payload: { error: "Tags error", extra: data },
        });
      } else {
        yield put({
          type: "TAGS_FETCH_UNKNOWN",
          payload: { error: "Tags error", extra: data },
        });
      }
    }
  }
}

function* handleFetchAllTagUsers(action) {
  try {
    const fetch = yield call(Api.getAllTagUsers);
    const data = checkTagData(fetch?.data);
    console.log(`TAGS_FETCH_ALL_USERS_REQUESTED fetch`, fetch);
    yield put({
      type: "TAGS_FETCH_ALL_USERS_SUCCEEDED",
      payload: data,
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "TAGS_FETCH_ALL_USERS_FAILED",
          payload: { error: "Tags error", extra: data },
        });
      } else {
        yield put({
          type: "TAGS_FETCH_ALL_USERS_UNKNOWN",
          payload: { error: "Tags error", extra: data },
        });
      }
    }
  }
}

function* handleFetchAllTagVehicles(action) {
  try {
    const fetch = yield call(Api.getAllTagVehicles);
    const data = checkTagData(fetch?.data);
    console.log(`TAGS_FETCH_ALL_VEHICLES_REQUESTED fetch`, fetch);

    yield put({
      type: "TAGS_FETCH_ALL_VEHICLES_SUCCEEDED",
      payload: checkTagData(data),
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "TAGS_FETCH_ALL_VEHICLES_FAILED",
          payload: { error: "Tags error", extra: data },
        });
      } else {
        yield put({
          type: "TAGS_FETCH_ALL_VEHICLES_UNKNOWN",
          payload: { error: "Tags error", extra: data },
        });
      }
    }
  }
}

// #WIP - the functions below should be standardised like above
//

function* handleAssignUser(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = yield Api.assignUser(values);
    yield put({
      type: "TAGS_ASSIGN_USER_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_ASSIGN_USER_FAILED", message: error });
    reject(error);
  }
}

function* handleAssignVehicle(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = yield Api.assignVehicle(values);
    yield put({
      type: "TAGS_ASSIGN_VEHICLE_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_ASSIGN_VEHICLE_FAILED", message: error });
    reject(error);
  }
}

function* handleUnassignUser(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = yield Api.unassignUser(id);
    yield put({
      type: "TAGS_UNASSIGN_USER_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_UNASSIGN_USER_FAILED", message: error });
    reject(error);
  }
}

function* handleUnassignVehicle(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = yield Api.unassignVehicle(id);
    yield put({
      type: "TAGS_UNASSIGN_VEHICLE_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_UNASSIGN_VEHICLE_FAILED", message: error });
    reject(error);
  }
}

// { id, firstname, lastname }
function* handleAddUpdateUser(action) {
  const { values, resolve, reject } = action.payload;
  const { id, firstname, lastname } = values;

  try {
    const response = yield Api.addUpdateUser({ id, firstname, lastname });
    console.log(`TAGS_ADD_UPDATE_USER_SUCCEEDED`, response);
    yield put({
      type: "TAGS_ADD_UPDATE_USER_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_ADD_UPDATE_USER_FAILED", message: error });
    reject(error);
  }
}

// { id, firstname, lastname }
function* handleAddUpdateVehicle(action) {
  const { values, resolve, reject } = action.payload;
  const { id, name, type } = values;

  try {
    const response = yield Api.addUpdateVehicle({ id, name, type });
    console.log(`TAGS_ADD_UPDATE_VEHICLE_SUCCEEDED`, response);
    yield put({
      type: "TAGS_ADD_UPDATE_VEHICLE_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_ADD_UPDATE_VEHICLE_FAILED", message: error });
    reject(error);
  }
}

function* handleDeleteTagUserById(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = yield Api.deleteTagUserById(id);
    yield put({
      type: "TAGS_DELETE_USER_BY_ID_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_DELETE_USER_BY_ID_FAILED", message: error });
    reject(error);
  }
}

function* handleDeleteTagVehicleById(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = yield Api.deleteTagVehicleById(id);
    yield put({
      type: "TAGS_DELETE_VEHICLE_BY_ID_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_DELETE_VEHICLE_BY_ID_FAILED", message: error });
    reject(error);
  }
}

function* handleUpdateTagZone(action) {
  const { values, resolve, reject } = action.payload;

  console.log(`updateTagZone handleUpdateTagZone values`, values);

  const { id } = values;

  try {
    const response = yield Api.updateTagZone(id);

    console.log(`updateTagZone response`, response);
    yield put({
      type: "TAGS_UPDATE_TAG_ZONE_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "TAGS_UPDATE_TAG_ZONE_FAILED", message: error });
    reject(error);
  }
}

// function* handleUpdateTagZone(action) {
//   const { values } = action.payload;
//   const { id } = values;

//   console.log(`updateTagZone handleUpdateTagZone values`, values);

//   try {
//     const response = yield Api.updateTagZone(id);
//     console.log(
//       `tagTracking updateTagZone TAGS_UPDATE_TAG_ZONE_SUCCEEDED response`,
//       response
//     );
//     yield put({
//       type: "TAGS_UPDATE_TAG_ZONE_SUCCEEDED",
//       payload: { id },
//     });
//   } catch (error) {
//     console.log(`TAGS_UPDATE_TAG_ZONE_FAILED ERROR`, error);

//     const status = error?.status;
//     const data = error?.data;

//     if (status && data) {
//       if (status === 401) {
//         yield put({
//           type: "TAGS_UPDATE_TAG_ZONE_FAILED",
//           payload: { error: "Tags error", extra: data },
//         });
//       } else {
//         yield put({
//           type: "TAGS_UPDATE_TAG_ZONE_UNKNOWN",
//           payload: { error: "Tags error", extra: data },
//         });
//       }
//     }
//   }
// }

function* tagsSagas() {
  yield takeLatest("TAGS_FETCH_REQUESTED", fetchTags);
  yield takeEvery("TAGS_ASSIGN_USER", handleAssignUser);
  yield takeEvery("TAGS_ASSIGN_VEHICLE", handleAssignVehicle);
  yield takeEvery("TAGS_UNASSIGN_USER", handleUnassignUser);
  yield takeEvery("TAGS_UNASSIGN_VEHICLE", handleUnassignVehicle);
  //
  yield takeEvery("TAGS_ADD_UPDATE_USER", handleAddUpdateUser);
  yield takeEvery("TAGS_DELETE_USER_BY_ID", handleDeleteTagUserById);

  //
  yield takeEvery("TAGS_ADD_UPDATE_VEHICLE", handleAddUpdateVehicle);
  yield takeEvery("TAGS_DELETE_VEHICLE_BY_ID", handleDeleteTagVehicleById);

  //
  yield takeLatest("TAGS_FETCH_ALL_USERS", handleFetchAllTagUsers);
  yield takeLatest("TAGS_FETCH_ALL_VEHICLES", handleFetchAllTagVehicles);

  //
  // updateTagZone
  yield takeEvery("TAGS_UPDATE_TAG_ZONE", handleUpdateTagZone);
}

export default tagsSagas;
