import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

//import { getFireflyById } from 'UPSPanelControllers/reducer'
import { getFireflyById } from "components/WebWorker/reducer";

export function FireflyLink({ id, notFound, ...props }) {
  let to = `/admin/firefly/${id}`;
  if (notFound) {
    to = "#";
  }
  return <Link to={to}>{props.children}</Link>;
}

FireflyLink.propTypes = {
  id: PropTypes.string.isRequired,
  notFound: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const ups = getFireflyById(state, id);
  return { id, notFound: !ups };
};

export default connect(mapStateToProps)(FireflyLink);
