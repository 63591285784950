import React, { Component } from "react";
import { connect } from "react-redux";
import { formatDateRelativeNow } from "utils/format-date";

import { Table, Container, Button } from "semantic-ui-react";
import BasicTable from "components/BasicTable";
import { getActiveOperationalChanges } from "OperationalChanges/reducer";
import { cancelOperationalChange } from "OperationalChanges/actions";

function HeaderRow() {
  return (
    <Table.Row>
      <Table.HeaderCell>Id</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Started</Table.HeaderCell>
      <Table.HeaderCell>Created by</Table.HeaderCell>
      <Table.HeaderCell>Actions</Table.HeaderCell>
    </Table.Row>
  );
}

let ItemRow = ({
  item,
  error = false,
  wholeRowMessage,
  cancelOperationalChange,
  ...props
}) => {
  if (wholeRowMessage) {
    return (
      <Table.Row error={error}>
        <Table.Cell colSpan={5}>{wholeRowMessage}</Table.Cell>
      </Table.Row>
    );
  }

  const opsChange = item;
  return (
    <Table.Row>
      <Table.Cell>{opsChange.id}</Table.Cell>
      <Table.Cell>{opsChange.description}</Table.Cell>
      <Table.Cell>{formatDateRelativeNow(opsChange.createdAt)}</Table.Cell>
      <Table.Cell>{opsChange.createdBy}</Table.Cell>
      <Table.Cell>
        <Button
          icon="cancel"
          onClick={() => cancelOperationalChange(opsChange.id)}
        />
      </Table.Cell>
    </Table.Row>
  );
};
ItemRow = connect(null, { cancelOperationalChange })(ItemRow);
/*
class ActiveOperationalChangesList extends Component {
  render () {
    const { activeChanges } = this.props
    return (
      <Container>
        <h3>Active Changes</h3>
        <BasicTable
          HeaderComponent={HeaderRow}
          RowComponent={ItemRow}
          items={activeChanges}
          getItemKey={(item, idx) => item.id}
        />
      </Container>
    )
  }
}*/

class ActiveOperationalChangesList extends Component {
  render() {
    const { activeChanges } = this.props;
    return <Container></Container>;
  }
}

function mapStateToProps(state) {
  return {
    activeChanges: null, // set to null as not used //getActiveOperationalChanges(state)
  };
}

export default connect(mapStateToProps)(ActiveOperationalChangesList);
