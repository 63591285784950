import React, { Component, useDebugValue } from "react";
import { connect } from "react-redux";

import {
  Form,
  Container,
  Label,
  Header,
  Grid,
  Message,
  Segment,
  Table,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import { renderSelect, renderRadio } from "admin/form-field";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging
import { push } from "react-router-redux";

import L, { bounds } from "leaflet";

import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import get from "lodash/get";
import set from "lodash/set";

import ImageUpload from "admin/mine-level/ImageUpload";
import MapWithImage from "components/Map/MapWithImage";
import MapMarker from "components/ColorableMapMarker";
import getSemanticColor from "colorpalette";
import { localPts } from "components/Map/referencePoints";

import { deleteMineLevel } from "MineLevels/actions";
import { renderField } from "admin/form-field";
import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import FlashMessagesList from "FlashMessages";
import { reset } from "testdouble";

import { LeastSquaresApproximationFourPointsSixParametersTransformation } from "projection";

import {
  //initialiseArea, // #NOTE - attempt to make a common separate initial function. Abandoned.
  loadImage,
  loadImgToFile,
} from "./MineLevelInitialiseArea";

import { getAllAreaStatuses } from "components/WebWorker/reducer";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { TemplateDefaultArea } from "components/ConfigJs";
import { getFolderFileNamesListById } from "components/Settings/reducer";
import { imageCheck } from "utils/imageCheck";
import { allowedLightColors as _allowedLightColors } from "components/ConfigJs";

const validate = (values) => {
  const required = [
    "name",
    "active",
    "order",
    "defaultColor",
    "defaultState",
    "mapFile",
    "imageInfo.backgroundColor",
    "imageInfo",
    "ref1.easting",
    "ref1.northing",
    "ref1.zoneLetter",
    "ref1.zoneNum",
    "ref2.easting",
    "ref2.northing",
    "ref2.zoneLetter",
    "ref2.zoneNum",
    "ref3.easting",
    "ref3.northing",
    "ref3.zoneLetter",
    "ref3.zoneNum",
    "ref4.easting",
    "ref4.northing",
    "ref4.zoneLetter",
    "ref4.zoneNum",
  ];

  const errors = {};

  required.forEach((r) => {
    if (!get(values, r)) {
      set(errors, r, "Required");
    }
  });

  //console.log("@@ validate errors", errors);

  return errors;
};

const normalise = (value, type) => {
  switch (type) {
    case "name":
      return value.replace(" ", "_");
    case "easting":
      return (value > 0 && Number(value)) || "";
    case "northing":
      return (value > 0 && Number(value)) || "";
    case "99":
      return (value < 99 && value > 0 && Number(value)) || "";
    case "char":
      return (
        (value.match(/^[A-Za-z]+$/) &&
          value.toString().toUpperCase().slice(0, 1)) ||
        ""
      );
    default:
      return value;
  }
};

const required = (value) => (value ? undefined : "Required");
const isAreaNameOK = (value, allValues, props, name) => {
  const { allAreaIds } = props;

  const valueNoSpace = value.replace(" ", "_");
  if (allAreaIds.includes(valueNoSpace)) {
    return "Area name has already been used";
  } else {
    return undefined;
  }
};

// calculates the UTM region from the ref points and image
const calculateAreaRegion = (values) => {
  const {
    bounds,
    ref1,
    ref2,
    ref3,
    ref4,
    imageRef1,
    imageRef2,
    imageRef3,
    imageRef4,
  } = values;

  let utmRef = [];
  utmRef.push({ lat: ref1?.easting, lng: ref1?.northing });
  utmRef.push({ lat: ref2?.easting, lng: ref2?.northing });
  utmRef.push({ lat: ref3?.easting, lng: ref3?.northing });
  utmRef.push({ lat: ref4?.easting, lng: ref4?.northing });

  let pixelRef = [];
  pixelRef.push({ lat: imageRef1?.lat, lng: imageRef1?.lng });
  pixelRef.push({ lat: imageRef2?.lat, lng: imageRef2?.lng });
  pixelRef.push({ lat: imageRef3?.lat, lng: imageRef3?.lng });
  pixelRef.push({ lat: imageRef4?.lat, lng: imageRef4?.lng });

  // the geometric coordinate to map pixel transformation
  const Transform = (localPts, standardPts) => {
    const LeastSq4Pt =
      LeastSquaresApproximationFourPointsSixParametersTransformation.fromPoints(
        localPts,
        standardPts
      );
    return LeastSq4Pt;
  };

  // update region bounds utm points
  const transform = Transform(utmRef, pixelRef); // convert utmRef -> pixelRef

  const {
    _northEast: { lat: height, lng: width },
  } = bounds;

  // area rectangle has four points
  const southWest = transform.transform({
    lat: 0,
    lng: 0,
  });
  const northEast = transform.transform({
    lat: height,
    lng: width,
  });
  const northWest = { lng: southWest.lng, lat: northEast.lat };
  const southEast = { lng: northEast.lng, lat: southWest.lat };

  let boundsArray = [southWest, northWest, northEast, southEast, southWest];
  boundsArray = boundsArray.map((bound) => Object.values(bound).reverse());

  // debug.....................
  // console.log("lll bounds", bounds);
  // console.log("lll imgBounds", boundsArray);
  // console.log("lll southWest", Object.values(southWest).reverse());
  // console.log("lll northEast", Object.values(northEast).reverse());
  // console.log("lll utmRef", utmRef);
  // console.log("lll pixelRef", pixelRef);

  return boundsArray;
};

const initialiseValues = async (
  defaultColor,
  defaultState,
  imageInfo,
  defaultRefCoords,
  folderFilesList,
  active,
  order
) => {
  // console.log(
  //   "initialiseValues defaultColor",
  //   defaultColor,
  //   "defaultState",
  //   defaultState,
  //   "imageInfo",
  //   imageInfo,
  //   "defaultRefCoords",
  //   defaultRefCoords,
  //   "folderFilesList",
  //   folderFilesList
  // );

  const newFilename = "default.png";

  const url = imageCheck(folderFilesList, newFilename, imageInfo);

  let imageFile;

  return new Promise((resolve, reject) => {
    loadImgToFile(url, newFilename)
      .catch((err) => {
        throw err;
      })
      .then((result) => {
        //console.log("initialiseValues loadImgToFile result", result);
        imageFile = result;
        loadImage(url)
          .then((img) => {
            // console.log("loadImgToFile img", img);
            // console.log(
            //   `loadImgToFile img ${img.src} w: ${img.width} | h: ${img.height}`
            // );

            const imageSrc = img.src;
            const imageHeight = img.height;
            const imageWidth = img.width;

            //console.log("initialiseValues loadImgToFile imageFile", imageFile);

            // change the filename by resaving image
            const blob = imageFile.slice(0, imageFile.size, "image/png");
            const filename = imageFile.name;
            let newFilename = filename; // filename.replace("-", ""); // strip out all '-' as used to parse loaded files
            // newFilename = newFilename.replace(
            //   ".png",
            //   `-[${img.width},${img.height}].png`
            // );
            const newFile = new File([blob], newFilename, {
              type: "image/png",
            });

            const newImageInfo = {
              width: imageWidth,
              height: imageHeight,
              backgroundColor: imageInfo.backgroundColor,
            };

            // console.log("initialValues newFilename", newFilename);
            // console.log("initialValues newFile", newFile);
            // console.log("initialValues defaultRefCoords", defaultRefCoords);

            const bounds = L.latLngBounds([0, 0], [imageHeight, imageWidth]);
            const imageRef1 = bounds.getNorthWest();
            const imageRef2 = bounds.getNorthEast();
            const imageRef3 = bounds.getSouthEast();
            const imageRef4 = bounds.getSouthWest();

            const ref1 = defaultRefCoords.ref1;
            const ref2 = defaultRefCoords.ref2;
            const ref3 = defaultRefCoords.ref3;
            const ref4 = defaultRefCoords.ref4;

            const areaRegion = calculateAreaRegion({
              bounds,
              ref1,
              ref2,
              ref3,
              ref4,
              imageRef1,
              imageRef2,
              imageRef3,
              imageRef4,
            });

            const values = {
              defaultColor: defaultColor,
              defaultState: defaultState,
              mapFile: newFile,
              imageInfo: newImageInfo,
              mapUrl: imageSrc,
              bounds: bounds,
              areaRegion: areaRegion,
              // reference to bottom left corner
              // image ref 1 is top left
              imageRef1: imageRef1,
              imageRef2: imageRef2,
              imageRef3: imageRef3,
              imageRef4: imageRef4,
              //
              ref1: ref1,
              ref2: ref2,
              ref3: ref3,
              ref4: ref4,
              //
              active: active,
              order: order,
            };
            //this.setState({ contentLoading: false });
            //console.log("initialValues values .......", values);
            resolve(values);
          })
          .catch((err) => {
            console.error(`loadImage ${err}`);
            reject(err);
            //this.setState({ errorMessage: err });
            //this.setState({ contentLoading: false });
          });
      })
      // .then(
      //   loadImage(url)
      //     .then((img) => {
      //       console.log("loadImgToFile img", img);
      //       console.log(
      //         `loadImgToFile img ${img.src} w: ${img.width} | h: ${img.height}`
      //       );

      //       const imageSrc = img.src;
      //       const imageHeight = img.height;
      //       const imageWidth = img.width;

      //       console.log("initialiseValues loadImgToFile imageFile", imageFile);

      //       // change the filename by resaving image
      //       const blob = imageFile.slice(0, imageFile.size, "image/png");
      //       const filename = imageFile.name;
      //       let newFilename = filename.replace("-", ""); // strip out all '-' as used to parse loaded files
      //       newFilename = newFilename.replace(
      //         ".png",
      //         `-[${img.width},${img.height}].png`
      //       );
      //       const newFile = new File([blob], newFilename, {
      //         type: "image/png",
      //       });

      //       const newImageInfo = {
      //         width: imageWidth,
      //         height: imageHeight,
      //         backgroundColor: imageInfo.backgroundColor,
      //       };

      //       console.log("initialValues newFilename", newFilename);
      //       console.log("initialValues newFile", newFile);

      //       const bounds = L.latLngBounds([0, 0], [imageHeight, imageWidth]);
      //       const imageRef1 = bounds.getNorthWest();
      //       const imageRef2 = bounds.getNorthEast();
      //       const imageRef3 = bounds.getSouthEast();
      //       const imageRef4 = bounds.getSouthWest();

      //       const values = {
      //         defaultColor: defaultColor,
      //         defaultState: defaultState,
      //         mapFile: newFile,
      //         imageInfo: newImageInfo,
      //         mapUrl: imageSrc,
      //         bounds: bounds,
      //         // reference to bottom left corner
      //         // image ref 1 is top left
      //         imageRef1: imageRef1,
      //         imageRef2: imageRef2,
      //         imageRef3: imageRef3,
      //         imageRef4: imageRef4,
      //         ref1: localPts()[0],
      //         ref2: localPts()[1],
      //         ref3: localPts()[2],
      //         ref4: localPts()[3],
      //       };
      //       //this.setState({ contentLoading: false });
      //       console.log("initialValues values .......", values);
      //       resolve(values);
      //     })
      //     .catch((err) => {
      //       console.error(`loadImage ${err}`);
      //       reject(err);
      //       //this.setState({ errorMessage: err });
      //       //this.setState({ contentLoading: false });
      //     })
      // )
      .catch((err) => {
        console.log("getImageFile error", err);

        // #NOTE - this should never happen because we've aleady checks the file exists
        // and loaded a default blob if not available.

        // load default image
        const url = process.env.PUBLIC_URL + "/areas/default.png";
        //this.setState({ errorMessage: err });
        return loadImgToFile(url, newFilename);
      });

    // loadImage(url)
    //   .then((img) => {
    //     console.log(`loadImage ${img.src} w: ${img.width} | h: ${img.height}`);

    //     const height = img.height;
    //     const width = img.width;

    //     // change the filename by resaving image
    //     const blob = file.slice(0, file.size, "image/png");
    //     const filename = file.name;
    //     let newFilename = filename.replace("-", ""); // strip out all '-' as used to parse loaded files
    //     newFilename = newFilename.replace(
    //       ".png",
    //       `-[${img.width},${img.height}].png`
    //     );
    //     const newFile = new File([blob], newFilename, {
    //       type: "image/png",
    //     });

    //     console.log("onMapChanged newFilename", newFilename);
    //     console.log("onMapChanged newFile", newFile);

    //     const bounds = L.latLngBounds([0, 0], [height, width]);
    //     const imageRef1 = bounds.getNorthWest();
    //     const imageRef2 = bounds.getNorthEast();
    //     const imageRef3 = bounds.getSouthEast();
    //     const imageRef4 = bounds.getSouthWest();

    //     // update form props for new imageRefs
    //     this.props.change("mapFile", newFile);
    //     this.props.change("imageRef1", imageRef1);
    //     this.props.change("imageRef2", imageRef2);
    //     this.props.change("imageRef3", imageRef3);
    //     this.props.change("imageRef4", imageRef4);

    //     this.setState({
    //       mapFile: newFile,
    //       mapUrl: url,
    //       bounds: bounds,
    //       // reference to bottom left corner
    //       // image ref 1 is top left
    //       imageRef1: imageRef1,
    //       imageRef2: imageRef2,
    //       imageRef3: imageRef3,
    //       imageRef4: imageRef4,
    //     });
    //     console.log("onMapChanged bounds", bounds);

    //     this.setState({ contentLoading: false });
    //   })
    //   .catch((err) => console.error(`loadImage ${err}`));
  });
};

class CreateMineLevelForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLoading: true,
      errorMessage: "",
      id: props.id,
      name: "",
      defaultColor: "green",
      defaultState: "on",
      mapFile: "",
      imageInfo: {
        width: "",
        height: "",
        backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
      },
      mapUrl: "",
      bounds: [],
      imageRef1: {},
      imageRef2: {},
      imageRef3: {},
      imageRef4: {},
      ref1: {},
      ref2: {},
      ref3: {},
      ref4: {},
      areaRegion: [],
      defaultRefCoords: {},
      // color picker
      displayColorPicker: false,
      backgroundColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      //
      initialValues: {
        name: "",
        defaultColor: "green",
        defaultState: "on",
        mapFile: "",
        imageInfo: {
          width: "",
          height: "",
          backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
        },
        mapUrl: "",
        bounds: [],
        imageRef1: {},
        imageRef2: {},
        imageRef3: {},
        imageRef4: {},
        ref1: {},
        ref2: {},
        ref3: {},
        ref4: {},
        areaRegion: [],
      },
      //
      collapse: false,
    };
  }

  // #REVIEW -
  //  Render issue - with each heartbeat the STATE updates which causes localPts to be updated and so resets the map state

  //   This stops re-rendering the map unnecessarily
  // #WIP

  componentDidMount() {
    const { folderFilesList } = this.props;

    const initialValues = this.state.initialValues;
    const { defaultColor, defaultState, imageInfo, active, order } =
      initialValues;

    // set state for color picker
    if (imageInfo?.backgroundColor !== undefined) {
      this.setState({ backgroundColor: imageInfo.backgroundColor });
    }

    // get default reference coordinates for the form
    const templateDefaultArea = TemplateDefaultArea() || {};
    const templateDefaultAreaRefCoords = templateDefaultArea?.ref_coord;
    let defaultRefCoords;

    if (!_isEmpty(templateDefaultAreaRefCoords)) {
      const ref1 = {
        easting: templateDefaultAreaRefCoords[0].utm[0],
        northing: templateDefaultAreaRefCoords[0].utm[1],
        zoneLetter: templateDefaultAreaRefCoords[0].utm_zone_letter,
        zoneNum: templateDefaultAreaRefCoords[0].utm_zone_number,
      };
      const ref2 = {
        easting: templateDefaultAreaRefCoords[1].utm[0],
        northing: templateDefaultAreaRefCoords[1].utm[1],
        zoneLetter: templateDefaultAreaRefCoords[1].utm_zone_letter,
        zoneNum: templateDefaultAreaRefCoords[1].utm_zone_number,
      };
      const ref3 = {
        easting: templateDefaultAreaRefCoords[2].utm[0],
        northing: templateDefaultAreaRefCoords[2].utm[1],
        zoneLetter: templateDefaultAreaRefCoords[2].utm_zone_letter,
        zoneNum: templateDefaultAreaRefCoords[2].utm_zone_number,
      };
      const ref4 = {
        easting: templateDefaultAreaRefCoords[3].utm[0],
        northing: templateDefaultAreaRefCoords[3].utm[1],
        zoneLetter: templateDefaultAreaRefCoords[3].utm_zone_letter,
        zoneNum: templateDefaultAreaRefCoords[3].utm_zone_number,
      };

      defaultRefCoords = { ref1, ref2, ref3, ref4 };
    }

    this.setState({ defaultRefCoords: defaultRefCoords });

    initialiseValues(
      defaultColor,
      defaultState,
      imageInfo,
      defaultRefCoords,
      folderFilesList,
      active,
      order
    )
      .then((values) => {
        //console.log("componentDidMount initialiseValues .... ", values);
        this.setState({ ...values, initialValues: { ...values } });
        // initialize() is a prop provided by reduxForm, that can be used to fill up the form values.
        // Note - enableReinitialize: true,
        this.props.initialize(values);
        this.setState({ contentLoading: false });
      })
      .catch((err) => {
        //console.log("componentDidMount initialiseValues error", err);
        this.setState({ errorMessage: err });
        this.setState({ contentLoading: false });
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const check =
      !_isEqual(this.props.formValues, nextProps.formValues) ||
      !_isEqual(this.state, nextState);

    return check;
  }

  svgMarkerIcon = (text, color) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className="svg-icon-svg"
        style={{ width: "24px", height: "36px" }}
      >
        <path
          className="svg-icon-path"
          d="M 1 12 L 12 34 L 23 12 A 6 6 0 0 0 1 12 Z"
          strokeWidth="2"
          stroke={color} //"#DB2828"
          strokeOpacity="undefined"
          fill={color} //"#DB2828"
          fillOpacity="0.8"
        ></path>
        <circle
          className="svg-icon-circle"
          cx="12"
          cy="12"
          r="8.399999999999999"
          fill="rgb(255,255,255)"
          fillOpacity="1"
          stroke={color} //"#DB2828"
          strokeOpacity='1"'
          strokeWidth="2"
        ></circle>
        <text
          textAnchor="middle"
          x="12"
          y="16.2"
          style={{ fontSize: "12px" }}
          fill="rgba(0, 0, 0,1)"
        >
          {text}
        </text>
      </svg>
    );
  };

  onFieldChanged = () => {
    //console.log("lll FieldChanged!");

    // update area region calculation
    const {
      bounds,
      ref1,
      ref2,
      ref3,
      ref4,
      imageRef1,
      imageRef2,
      imageRef3,
      imageRef4,
    } = this.state;

    const areaRegion = calculateAreaRegion({
      bounds,
      ref1,
      ref2,
      ref3,
      ref4,
      imageRef1,
      imageRef2,
      imageRef3,
      imageRef4,
    });

    //console.log("lll areaRegion", areaRegion);
    this.props.change("areaRegion", areaRegion);
    this.setState({ areaRegion: areaRegion });
  };

  createRefTable = () => {
    const colObjRef = ["easting", "northing", "zoneLetter", "zoneNum"];
    const colLabel = ["easting", "northing", "zone letter", "zone number"];
    const colType = ["easting", "northing", "char", "99"];
    const colFieldType = ["number", "number", "text", "number"];

    const rows = 4;
    const cols = 4; // 1st column is marker icon

    let table = [];

    // Outer loop to create parent
    for (let i = 0; i < rows; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j < cols; j++) {
        children.push(
          <Table.Cell key={`row-id-${i}${j}`}>
            <Field
              name={`ref${i + 1}.${colObjRef[j]}`}
              placeholder={`Ref ${i + 1} ${colLabel[j]}`}
              component={renderField}
              transparent
              //onChange={(event, value) => console.log("Field value", value)}
              onBlur={() => this.onFieldChanged()}
              normalize={(value) => normalise(value, colType[j])}
              type={colFieldType[j]}
            />
          </Table.Cell>
        );
      }
      //Create the parent and add the children
      table.push(
        <Table.Row key={`row-id-${i}`}>
          <Table.Cell>
            <div>{this.svgMarkerIcon(i + 1, "#2185D0")}</div>
          </Table.Cell>
          {children}
        </Table.Row>
      );
    }
    return table;
  };

  onMapChanged = (file, url) => {
    if (!file) {
      return;
    }

    // console.log("file", file);
    // console.log("url", url);

    this.setState({ contentLoading: true });

    loadImage(url)
      .then((img) => {
        //console.log(`loadImage ${img.src} w: ${img.width} | h: ${img.height}`);

        const src = img.src;
        const height = img.height;
        const width = img.width;

        // console.log("onMapChanged file", file);
        // console.log("onMapChanged url", url);

        // change the filename by resaving image
        const blob = file.slice(0, file.size, "image/png");
        const filename = file.name;
        let newFilename = filename; // filename.replace("-", ""); // strip out all '-' as used to parse loaded files
        // newFilename = newFilename.replace(
        //   ".png",
        //   `-[${img.width},${img.height}].png`
        // );
        const newFile = new File([blob], newFilename, {
          type: "image/png",
        });

        const imageInfo = {
          width: width,
          height: height,
          backgroundColor: this.state.backgroundColor,
        };

        // console.log("onMapChanged newFilename", newFilename);
        // console.log("onMapChanged newFile", newFile);

        const bounds = L.latLngBounds([0, 0], [height, width]);
        const imageRef1 = bounds.getNorthWest();
        const imageRef2 = bounds.getNorthEast();
        const imageRef3 = bounds.getSouthEast();
        const imageRef4 = bounds.getSouthWest();

        // update form props for new imageRefs
        this.props.change("mapFile", newFile);
        this.props.change("imageInfo", imageInfo);
        this.props.change("mapUrl", src);

        this.props.change("imageRef1", imageRef1);
        this.props.change("imageRef2", imageRef2);
        this.props.change("imageRef3", imageRef3);
        this.props.change("imageRef4", imageRef4);

        this.setState({
          mapFile: newFile,
          imageInfo: imageInfo,
          mapUrl: src,
          bounds: bounds,
          // reference to bottom left corner
          // image ref 1 is top left
          imageRef1: imageRef1,
          imageRef2: imageRef2,
          imageRef3: imageRef3,
          imageRef4: imageRef4,
        });
        //console.log("onMapChanged bounds", bounds);

        this.setState({ contentLoading: false });
      })
      .catch((err) => console.error(`loadImage ${err}`));

    // this.setState({ contentLoading: true });

    // // console.log("onMapChanged file", file);
    // // console.log("onMapChanged url", url);

    // // change the filename by resaving image
    // const blob = file.slice(0, file.size, "image/png");
    // const filename = file.name;
    // const newFilename = filename.replace("-", ""); // strip out all '-' as used to parse loaded files
    // const newFile = new File([blob], `${newFilename}`, { type: "image/png" });

    // // console.log("onMapChanged newFilename", newFilename);
    // // console.log("onMapChanged newFile", newFile);

    // this.props.change("mapFile", newFile);
    // var that = this;
    // var img = new Image();

    // img.onload = function () {
    //   const { width, height } = this;
    //   const bounds = L.latLngBounds([0, 0], [height, width]);
    //   const imageRef1 = bounds.getNorthWest();
    //   const imageRef2 = bounds.getNorthEast();
    //   const imageRef3 = bounds.getSouthEast();
    //   const imageRef4 = bounds.getSouthWest();

    //   // update form props for new imageRefs
    //   that.props.change("imageRef1", imageRef1);
    //   that.props.change("imageRef2", imageRef2);
    //   that.props.change("imageRef3", imageRef3);
    //   that.props.change("imageRef4", imageRef4);

    //   that.setState({
    //     mapFile: newFile,
    //     mapUrl: url,
    //     bounds: bounds,
    //     // reference to bottom left corner
    //     // image ref 1 is top left
    //     imageRef1: imageRef1,
    //     imageRef2: imageRef2,
    //     imageRef3: imageRef3,
    //     imageRef4: imageRef4,
    //   });
    //   console.log("onMapChanged bounds", bounds);

    //   that.setState({ contentLoading: false });
    // };
    // img.src = url;
  };

  onDragEnded(id, newLocation) {
    if (this.state.bounds.contains(newLocation)) {
      this.props.change(id, newLocation);
      this.setState({ [id]: newLocation });

      // update area region calculation
      const {
        bounds,
        ref1,
        ref2,
        ref3,
        ref4,
        imageRef1,
        imageRef2,
        imageRef3,
        imageRef4,
      } = this.state;

      const areaRegion = calculateAreaRegion({
        bounds,
        ref1,
        ref2,
        ref3,
        ref4,
        imageRef1,
        imageRef2,
        imageRef3,
        imageRef4,
      });
      this.props.change("areaRegion", areaRegion);
      this.setState({ areaRegion: areaRegion });
    } else {
      this.setState({ [id]: { ...this.state[id] } });
    }
  }

  makeOnDragEnd(id) {
    return ({ target }) => {
      this.onDragEnded(id, target.getLatLng());
    };
  }

  // #NOTE - this function it not called. Can not delete within a create form.
  requestDelete = (id) => {
    return null;
  };

  submitMyForm = (values) => {
    //console.log(`form submitted: values`, values);
    this.props.onSubmit(values);
  };

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  resetForm = (e) => {
    e.preventDefault();

    // const width = 15001; // default image dimmensions
    // const height = 10001;
    // const bounds = L.latLngBounds([0, 0], [height, width]);
    // let image = new Image(width, height);
    // // image.src = "boo!";

    // this.setState({
    //   // default to empty image
    //   mapFile: "",
    //   mapUrl: image,
    //   bounds: bounds,
    //   imageRef1: bounds.getNorthWest(),
    //   imageRef2: bounds.getNorthEast(),
    //   imageRef3: bounds.getSouthEast(),
    //   imageRef4: bounds.getSouthWest(),
    // });

    // const initialValues = {
    //   name: "",
    //   ref1: localPts()[0],
    //   ref2: localPts()[1],
    //   ref3: localPts()[2],
    //   ref4: localPts()[3],
    // };

    const { initialValues, defaultRefCoords } = this.state;
    const { defaultColor, defaultState, imageInfo, active, order } =
      initialValues;
    const { folderFilesList } = this.props;

    initialiseValues(
      defaultColor,
      defaultState,
      imageInfo,
      defaultRefCoords,
      folderFilesList,
      active,
      order
    )
      .then((values) => {
        //console.log("initialValues", values);
        this.setState({ ...values });
        // initialize() is a prop provided by reduxForm, that can be used to fill up the form values.
        // Note - enableReinitialize: true,
        this.props.initialize(values);
        this.setState({ contentLoading: false });
        this.setState(values);
      })
      .catch((err) => {
        this.setState({ errorMessage: err });
        this.setState({ contentLoading: false });
      });

    //console.log("setInitialValues", setInitialValues);

    // initialize() is a prop provided by reduxForm, that can be used to fill up the form values.
    // Note - enableReinitialize: true,
    //this.props.initialize(setInitialValues);
    //this.props.reset();

    //this.setState(setInitialValues);
  };

  cancelForm = (e) => {
    e.preventDefault();

    // abort page changes
    this.props.onCancel();
  };

  handleColorClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleColorClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorChange = (color) => {
    this.setState({ backgroundColor: color.rgb });
    this.props.change("backgroundColor", color.rgb);

    let imageInfo = this.state.imageInfo;
    imageInfo.backgroundColor = color.rgb;
    this.props.change("imageInfo", imageInfo);
  };

  handleOrderChange = (e, data) => {
    this.props.change("order", data);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      //reset,
      strings,
      allAreaIds,
    } = this.props;

    const {
      //id,
      //mapFile,
      mapUrl,
      bounds,
      imageRef1,
      imageRef2,
      imageRef3,
      imageRef4,
    } = this.state;

    //console.log("MineLevelCreateForm mapUrl, bounds,", mapUrl, bounds);

    const stylesColorPicker = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
        },
        group: {
          alignItems: "center",
          display: "flex",
        },
        label: {
          fontWeight: "bold",
          paddingRight: "10px",
          fontSize: ".92857143em", //#NOTE #HACK - FFS why it is so hard to inherit font sizes??!
        },

        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    const refLocationsComponent = (
      <div>
        <MapWithImage
          style={{
            height: "60vh",
            width: "100%",
            position: "relative",
            zIndex: 0,
            backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
          }}
          imageInfo={{
            bounds,
            src: mapUrl,
          }}
        >
          {[
            ["ref1point", imageRef1, "imageRef1", "blue"],
            ["ref2point", imageRef2, "imageRef2", "blue"],
            ["ref3point", imageRef3, "imageRef3", "blue"],
            ["ref4point", imageRef4, "imageRef4", "blue"],
          ].map(([key, position, id, color], idx) => (
            <MapMarker
              key={key}
              lat={position.lat}
              lng={position.lng}
              draggable={true}
              onDragend={this.makeOnDragEnd(id)}
              color={getSemanticColor(color)}
              circleText={idx + 1}
            >
              <p>Reference {idx + 1}</p>
              <p>
                Pixel Coord: {parseInt(position.lat)},{parseInt(position.lng)}
              </p>
            </MapMarker>
          ))}
        </MapWithImage>
        {/* Information confusing to user
          <div>Ref 1 Pixel: {JSON.stringify(imageRef1)}</div>
          <div>Ref 2 Pixel: {JSON.stringify(imageRef2)}</div> */}

        {/* REVIEW - pads between image and save button - huge hack - fix the spacing */}
        <br />
      </div>
    );

    const allowedLightColors = _allowedLightColors();
    const orderOptions = [...allAreaIds, "new"]?.map((area, idx) => {
      return { key: idx + 1, value: idx + 1, text: idx + 1 };
    });

    const isCollapse = this.state.collapse;
    const { contentLoading } = this.state;

    if (contentLoading) {
      return (
        <Dimmer active={contentLoading} page>
          <Loader>Loading data</Loader>
        </Dimmer>
      );
    } else {
      return (
        <div className="genericGridHeader">
          <Container>
            <Grid columns={2}>
              <Grid.Row className={"genericTitleHeader"}>
                <Grid.Column width={4} textAlign={"left"}>
                  <Header as="h1">Area</Header>
                </Grid.Column>
                <Grid.Column width={12} textAlign={"left"}>
                  <FlashMessagesList />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
            </Grid>
            {/* ***************Create Form ************************ */}
            {error && (
              <Message error header="Error submitting" content={error} />
            )}

            <Form
              onSubmit={handleSubmit(this.submitMyForm)}
              error={Boolean(error)}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column stretched width={isCollapse ? 1 : 6}>
                    {isCollapse && (
                      <Segment>
                        <Grid>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Popup
                                content={strings?.["Expand Left Side"]}
                                trigger={
                                  <Button
                                    floated={"left"}
                                    icon
                                    onClick={this.expandCollapse}
                                    size={"mini"}
                                    color={"teal"}
                                  >
                                    <Icon name={"angle right"} size={"large"} />
                                  </Button>
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    )}
                    {!isCollapse && (
                      <>
                        <Segment>
                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                <Header as="h2">Add Area</Header>
                              </Grid.Column>
                              <Grid.Column>
                                <Popup
                                  content={strings?.["Collapse Left Side"]}
                                  trigger={
                                    <Button
                                      floated={"right"}
                                      icon
                                      onClick={this.expandCollapse}
                                      size={"mini"}
                                      color={"teal"}
                                    >
                                      <Icon
                                        name={"angle left"}
                                        size={"large"}
                                      />
                                    </Button>
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column>
                                <Field
                                  name="name"
                                  label="Area"
                                  placeholder="Enter the area name"
                                  component={renderField}
                                  validate={[required, isAreaNameOK]}
                                  normalize={(value) =>
                                    normalise(value, "name")
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={"equal"}>
                              {/* <Grid.Column>
                                <Field
                                  name="active"
                                  label="Active"
                                  placeholder="Active"
                                  component={renderField}
                                />
                              </Grid.Column> */}
                              <Grid.Column>
                                <Field
                                  name="order"
                                  label="Order"
                                  placeholder="order"
                                  component={renderSelect}
                                  options={orderOptions}
                                  onChange={this.handleOrderChange}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                        {error && (
                          <Message error header="Error" content={error} />
                        )}

                        <Segment>
                          <Header as="h3">{strings?.["Light Settings"]}</Header>

                          <Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                <Field
                                  name="defaultColor"
                                  label={strings?.["Default Color of Lights"]}
                                  component={renderSelect}
                                  options={allowedLightColors}
                                />
                                <Form.Group
                                  inline
                                  style={{ paddingTop: "5px" }}
                                >
                                  <label>
                                    {strings?.["Default State of Lights"]}
                                  </label>

                                  <Field
                                    component={renderRadio}
                                    label={strings?.["ON"]}
                                    name="defaultState"
                                    radioValue={"on"}
                                  />
                                  <Field
                                    component={renderRadio}
                                    label={strings?.["OFF"]}
                                    name="defaultState"
                                    radioValue={"off"}
                                  />
                                </Form.Group>
                              </Grid.Column>
                              <Grid.Column>
                                <Message icon color="blue">
                                  <Icon name="info" />
                                  <Message.Content>
                                    <Message.Header>
                                      {strings?.["Area Message Header"]}
                                    </Message.Header>
                                    {strings?.["Area Message Content"]}
                                  </Message.Content>
                                </Message>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>

                        <Segment>
                          <Grid>
                            <Grid.Row columns={"equal"}>
                              <Grid.Column>
                                <Field
                                  name="backgroundColor"
                                  type="hidden"
                                  component="input"
                                />
                                <div>
                                  <div style={stylesColorPicker.group}>
                                    <label style={stylesColorPicker.label}>
                                      {strings?.["Map Background Color"]}
                                    </label>
                                    <div
                                      style={stylesColorPicker.swatch}
                                      onClick={this.handleColorClick}
                                    >
                                      <div style={stylesColorPicker.color} />
                                    </div>
                                  </div>
                                  {this.state.displayColorPicker ? (
                                    <div style={stylesColorPicker.popover}>
                                      <div
                                        style={stylesColorPicker.cover}
                                        onClick={this.handleColorClose}
                                      />
                                      <SketchPicker
                                        color={this.state.backgroundColor}
                                        onChange={this.handleColorChange}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </Grid.Column>
                              <Grid.Column>
                                <Field
                                  name="mapFile"
                                  type="hidden"
                                  component="input"
                                />
                                <ImageUpload
                                  onFileChanged={this.onMapChanged}
                                  strings={strings}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                        <Segment>
                          <Header as="h3">
                            {strings?.["Map Reference Markers"]}
                          </Header>
                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell width={6}>
                                  {strings?.["Easting"]}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={6}>
                                  {strings?.["Northing"]}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                  {strings?.["Letter"]}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                  {strings?.["Number"]}
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.createRefTable()}</Table.Body>
                          </Table>
                        </Segment>
                      </>
                    )}
                  </Grid.Column>
                  <Grid.Column width={isCollapse ? 15 : 10}>
                    <Segment>
                      <Grid columns={2}>
                        <Grid.Column>
                          {/* <Grid.Row>
                            <DeleteButton
                              onClick={this.requestDelete}
                              id={id}
                            />
                          </Grid.Row> */}
                        </Grid.Column>
                        <Grid.Column>
                          <Grid.Row>
                            <Button.Group floated="right">
                              <SaveButton
                                submitting={submitting}
                                pristine={pristine}
                                strings={strings}
                              />
                              <Button.Or />
                              <Button
                                type="button"
                                disabled={pristine || submitting}
                                onClick={(e) => this.resetForm(e)}
                              >
                                {strings?.["Reset"]}
                              </Button>
                              <Button.Or />
                              <Button
                                style={{ textAlign: "right" }}
                                onClick={(e) => this.cancelForm(e)}
                              >
                                {strings?.["Cancel"]}
                              </Button>
                            </Button.Group>
                          </Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    <Segment>
                      <Header as="h2">{strings?.["Map"]}</Header>
                      {refLocationsComponent}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>

            <DebugPagePropsMessages that={this} />
          </Container>
        </div>
      );
    }
  }
}

const selector = formValueSelector("createNewMineLevelForm");

CreateMineLevelForm = reduxForm({
  form: "createNewMineLevelForm",
  // Note - to reinitialize values in componentDidMount
  // Make sure you set the values of enableReinitialize & keepDirtyOnReinitialize to true
  // See - https://stackoverflow.com/questions/44273099/redux-form-initialization-with-props-initialize
  //enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(CreateMineLevelForm);

const mapStateToProps = (state, props) => {
  //const id = props.id;

  // collect an array of existing area ids to validate against new area name
  const allAreaStatuses = getAllAreaStatuses(state);
  const allAreaIds = allAreaStatuses.map((item) => item.id);

  //  Render issue - with each heartbeat the STATE updates which causes localPts to be updated and so resets the map state
  // See console.log below - note grab action is cancelled when the time changes.
  //console.log("ggg render issue ref1", ref1, new Date(Date.now()));
  // See - shouldComponentUpdate

  return {
    mineLevelId: selector(state, "id"),
    allAreaIds,
    folderFilesList: getFolderFileNamesListById(state, "areas"),
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("reduxForm onChange values", values);
      //const areaRegion = calculateAreaRegion(values);
      //console.log("lll areaRegion", areaRegion);
    },
    reduxFormValues: getFormValues("createNewMineLevelForm")(state), // #REVIEW - only used for debugging,
  };
};

CreateMineLevelForm = connect(mapStateToProps, { deleteMineLevel, push })(
  CreateMineLevelForm
);

export default CreateMineLevelForm;
