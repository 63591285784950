import React from "react";
import { Table } from "semantic-ui-react";

export const TrailingContent = (props) => {
  const { data, isLoading, error, columnSpan } = props;

  // trailing content should span two columns unless otherwise defined.
  // i.e. some forms only have one column, or first columns are small

  const ItemRow = ({ item, error = false, wholeRowMessage, ...props }) => {
    if (wholeRowMessage) {
      return (
        <Table.Row error={error}>
          <Table.Cell colSpan={columnSpan ? columnSpan : 2}>
            {wholeRowMessage}
          </Table.Cell>
        </Table.Row>
      );
    }
  };

  if (isLoading) {
    return <ItemRow wholeRowMessage={"Loading..."} />;
  } else if (error) {
    return <ItemRow wholeRowMessage={error} error />;
  } else if (typeof data !== "undefined" && data.length === 0) {
    return <ItemRow wholeRowMessage={"Empty list. Waiting for data..."} />;
  } else {
    return null;
  }
};
