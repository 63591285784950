import React, { Component } from "react";
import { Modal, Header, Icon, Button } from "semantic-ui-react";

export default class EnableDisableButton extends Component {
  state = { open: false };

  clicked = (e) => {
    const { onClick, id } = this.props;
    onClick(id);
  };
  close = () => this.setState({ open: false });
  open = () => this.setState({ open: true });

  render() {
    const { open } = this.state;
    const { label, color, disabled = false, size = "large" } = this.props;
    return (
      <Modal
        open={open}
        onOpen={this.open}
        onClose={this.close}
        trigger={
          <Button
            color={color}
            onClick={(e) => {
              e.preventDefault(); // Stop any form validation
              this.open();
            }}
            disabled={disabled}
            size={size}
          >
            {label}
          </Button>
        }
        basic
        size="small"
      >
        <Header icon="question" content={label} />
        <Modal.Content>
          <p>Are you sure you want to {label.toLowerCase()}?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button inverted onClick={this.close}>
            <Icon name="close" /> No
          </Button>
          <Button color={color} inverted onClick={this.clicked}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
