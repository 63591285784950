import numeric from "numeric";
import flatten from "lodash/flatten";
import chunk from "lodash/chunk";

// 2D Conformal Transformation
//
// All of this stuff is just an implementation of the processes described in the
// Bentley 2D Conformal Transformation pdf

export class ConformalTransformation {
  constructor(k, theta, Tx, Ty) {
    this.k = k;
    this.theta = theta;
    this.Tx = Tx;
    this.Ty = Ty;
    const a = k * Math.cos(theta);
    const b = k * Math.sin(theta);
    this.T = [[a], [b], [Tx], [Ty]];
    this.transform = this.transform.bind(this);
  }

  static fromPoints(standardPoints, localPoints) {
    const [s1, s2] = standardPoints;
    const [l1, l2] = localPoints;

    const e1 = s1.lng;
    const n1 = s1.lat;
    const e2 = s2.lng;
    const n2 = s2.lat;
    const E = [[e1], [n1], [e2], [n2]];

    const x1 = l1.lng;
    const y1 = l1.lat;
    const x2 = l2.lng;
    const y2 = l2.lat;

    const X = [
      [x1, -y1, 1, 0],
      [y1, x1, 0, 1],
      [x2, -y2, 1, 0],
      [y2, x2, 0, 1]
    ];

    const invX = numeric.inv(X);
    let T = numeric.dot(invX, E);
    T = flatten(T);
    const [a, b, Tx, Ty] = T;
    const theta = Math.atan(b / a);
    const k = a / Math.cos(theta);

    return new ConformalTransformation(k, theta, Tx, Ty);
  }

  transform(pts) {
    const list = Array.isArray(pts) ? pts : [pts];
    const X = flatten(
      list.map(pt => {
        const [x, y] = arrayFromLatLng(pt);
        return [
          [x, -y, 1, 0],
          [y, x, 0, 1]
        ];
      })
    );

    let results = numeric.dot(X, this.T);
    results = chunk(flatten(results), 2);
    const asPoints = results.map(res => latlntFromArray(res));

    if (Array.isArray(pts)) {
      return asPoints;
    }
    return asPoints[0];
  }
}

const arrayFromLatLng = latLng => [latLng.lng, latLng.lat];
const latlntFromArray = array => ({ lng: array[0], lat: array[1] });

export class MineLevelMapImageProjection {
  constructor(mineFromImageTranform, imageFromMineTranform) {
    this.mineFromImageTranform = mineFromImageTranform;
    this.imageFromMineTranform = imageFromMineTranform;
  }

  mineFromImage(pts) {
    return this.mineFromImageTranform.transform(pts);
  }

  imageFromMine(pts) {
    return this.imageFromMineTranform.transform(pts);
  }

  static fromPoints(mineCoords, imageCoords) {
    return new MineLevelMapImageProjection(
      AffineWithoutRotationTransformation.fromPoints(mineCoords, imageCoords),
      AffineWithoutRotationTransformation.fromPoints(imageCoords, mineCoords)
    );
  }
}

export class AffineWithoutRotationTransformation {
  constructor(Sx, Sy, Tx, Ty) {
    this.Sx = Sx;
    this.Sy = Sy;
    this.Tx = Tx;
    this.Ty = Ty;
    this.T = [[Sx], [Sy], [Tx], [Ty]];
    this.transform = this.transform.bind(this);
  }

  static fromPoints(standardPoints, localPoints) {
    const [s1, s2] = standardPoints;
    const [l1, l2] = localPoints;

    const e1 = s1.lng;
    const n1 = s1.lat;
    const e2 = s2.lng;
    const n2 = s2.lat;
    const E = [[e1], [n1], [e2], [n2]];

    const x1 = l1.lng;
    const y1 = l1.lat;
    const x2 = l2.lng;
    const y2 = l2.lat;

    const X = [
      [x1, 0, 1, 0],
      [0, y1, 0, 1],
      [x2, 0, 1, 0],
      [0, y2, 0, 1]
    ];

    const invX = numeric.inv(X);
    let T = numeric.dot(invX, E);
    T = flatten(T);
    const [Sx, Sy, Tx, Ty] = T;

    return new AffineWithoutRotationTransformation(Sx, Sy, Tx, Ty);
  }

  transform(pts) {
    const list = Array.isArray(pts) ? pts : [pts];
    const X = flatten(
      list.map(pt => {
        const [x, y] = arrayFromLatLng(pt);
        return [
          [x, 0, 1, 0],
          [0, y, 0, 1]
        ];
      })
    );

    let results = numeric.dot(X, this.T);
    results = chunk(flatten(results), 2);
    const asPoints = results.map(res => latlntFromArray(res));

    if (Array.isArray(pts)) {
      return asPoints;
    }
    return asPoints[0];
  }
}

export class LeastSquaresApproximationFourPointsSixParametersTransformation {
  constructor(Sa, Sb, Tx, Sc, Sd, Ty) {
    this.Sa = Sa;
    this.Sb = Sb;
    this.Tx = Tx;
    this.Sc = Sc;
    this.Sd = Sd;
    this.Ty = Ty;
    this.T = [[Sa], [Sb], [Tx], [Sc], [Sd], [Ty]];
    this.transform = this.transform.bind(this);
  }

  static fromPoints(standardPoints, localPoints) {
    const [s1, s2, s3, s4] = standardPoints;
    const [l1, l2, l3, l4] = localPoints;

    const e1 = s1.lng;
    const n1 = s1.lat;
    const e2 = s2.lng;
    const n2 = s2.lat;
    const e3 = s3.lng;
    const n3 = s3.lat;
    const e4 = s4.lng;
    const n4 = s4.lat;
    const L = [[e1], [n1], [e2], [n2], [e3], [n3], [e4], [n4]];

    const x1 = l1.lng;
    const y1 = l1.lat;
    const x2 = l2.lng;
    const y2 = l2.lat;
    const x3 = l3.lng;
    const y3 = l3.lat;
    const x4 = l4.lng;
    const y4 = l4.lat;
    const A = [
      [x1, y1, 1, 0, 0, 0],
      [0, 0, 0, x1, y1, 1],
      [x2, y2, 1, 0, 0, 0],
      [0, 0, 0, x2, y2, 1],
      [x3, y3, 1, 0, 0, 0],
      [0, 0, 0, x3, y3, 1],
      [x4, y4, 1, 0, 0, 0],
      [0, 0, 0, x4, y4, 1]
    ];

    // A trans
    const Atrans = numeric.transpose(A);

    // A trans.A
    const AtransA = numeric.dot(Atrans, A);

    // inverse A trans . A
    const invAtransA = numeric.inv(AtransA);

    // Atrans.L
    const AtransL = numeric.dot(Atrans, L);

    // (inverse A trans . A).Atrans.L

    const invAtransAAtransL = numeric.dot(invAtransA, AtransL);

    let T = flatten(invAtransAAtransL);
    const [Sa, Sb, Tx, Sc, Sd, Ty] = T;

    return new LeastSquaresApproximationFourPointsSixParametersTransformation(
      Sa,
      Sb,
      Tx,
      Sc,
      Sd,
      Ty
    );
  }

  transform(pts) {
    const list = Array.isArray(pts) ? pts : [pts];
    const X = flatten(
      list.map(pt => {
        const [x, y] = arrayFromLatLng(pt);
        return [
          [x, y, 1, 0, 0, 0],
          [0, 0, 0, x, y, 1]
        ];
      })
    );

    let results = numeric.dot(X, this.T);
    results = chunk(flatten(results), 2);
    const asPoints = results.map(res => latlntFromArray(res));

    if (Array.isArray(pts)) {
      return asPoints;
    }
    return asPoints[0];
  }
}
