export const ALLOWED_LIGHT_COLORS = getConfigItem()

function getConfigItem () {
  try {
    const val = getCookie('available-colors')
    const serverValues = JSON.parse(atob(val))
    return serverValues
  } catch (e) {
    return ['off', 'white', 'red', 'amber', 'green']
  }
}

function getCookie (name) {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift()
  }
  return null
}

export function sanitizeLightSettings (lightSettings = 'off') {
  let { color = lightSettings } = lightSettings

  if (ALLOWED_LIGHT_COLORS.indexOf(color) === -1) {
    color = 'off'
  }

  return { color }
}
