import React, { Component } from "react";
import { push } from "react-router-redux";

import PropTypes from "prop-types";

import { Container, Header, Grid, Message, List } from "semantic-ui-react";

import { connect } from "react-redux";

import { getControllerById } from "components/WebWorker/reducer";

import UPSBulkPositioningForm from "admin/bulk-positioning/UPSBulkEditPage/UPSBulkPositioningForm";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class UPSDetail extends Component {
  static propTypes = {
    ups: PropTypes.object,
    error: PropTypes.string,
  };

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  render() {
    const { id, error, strings } = this.props;

    // #WIP - ATM don't allow reload, or direct link to a controller page.....
    // Instead redirect to the controller list page and wait for controller to list (with next message).

    if (error) {
      this.props.goto("/admin/controller/");
      return null;
      //   return (
      //   <>
      //     <DataLoadingMessage id={id} />
      //     <DebugPagePropsMessages that={this} />
      //   </>
      // );
    }

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">
                  {strings?.["Header_Controller_BulkPosition"]}
                </Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <UPSBulkPositioningForm id={id} strings={strings} />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    goto: (path) => dispatch(push(path)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

const mapStateToProps = (state, props) => {
  const match = props.match;
  const id = match.params.id;

  const ups = getControllerById(state, id);

  return {
    id,
    error: ups ? undefined : "No Controller for id",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UPSDetail);
