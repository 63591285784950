//#middleware
import { strings } from "components/App/localisation";
import { clearCookie } from "cookies";

export const monitorSettings = (store) => (next) => (action) => {
  let newAction = action;
  let flashMessages = {};
  let storeState = {};

  // Intercepts service check and pops flash message if service is not 'active'

  // Makes use of actions via at src/FlashMessages/actions
  // e.g. export function SetMessageBanner(messageState) {
  //     return { type: "SET_MESSAGE_BANNER", payload: messageState };
  //   }
  switch (action.type) {
    case "SERVER_CHECK_SERVICE_FETCH_SUCCEEDED":
      storeState = store.getState();
      // get existing message banners
      flashMessages = storeState.flashMessages;

      //console.log("xxx middleware action.payload", action.payload);
      //console.log("xxx middleware messageBanners", flashMessages);

      const { service, check } = action.payload;

      // See
      //  - export function addFlashMessageIdColor(id, color, header, content, other)
      // - src/FlashMessages/actions.js

      if (check !== "active") {
        const id = `SERVICE_CHECK_${service}`;

        if (!flashMessages?.some?.((msg) => msg.id === id)) {
          const color = "red";
          const header = `${strings?.["ERROR: Service Fault"]} "${service}" `;
          const content = `${strings?.["Service reports"]} "${check}"`;

          store.dispatch({
            type: "ADD_FLASH_MESSAGE",
            payload: { id, color, header, content },
          });
        }
      }
      break;
    case "MQTT_SYSTEM_PROCESS_MSG_UPDATE":
      storeState = store.getState();

      // get existing message banners
      flashMessages = store.getState().flashMessages;

      // get systemProcessMsgs

      const systemProcessMsg = storeState?.webWorker?.general?.systemProcessMsg;

      //console.log("xxx middleware action.payload", action.payload);
      //console.log("xxx middleware messageBanners", flashMessages);

      // process the system/process message queue

      systemProcessMsg.forEach((msg) => {
        //console.log("xxx middleware process msg", JSON.stringify(msg));

        const {
          id: processId,
          process,
          message,
          status,
          file,
          user,
          timestamp,
        } = msg;

        let id = `PROCESS_MSG_${process}`;
        let header = strings?.[message] || id;
        let content = strings?.[`${message}_CONTENT`] || `${id}_CONTENT`;
        let color = "red";
        let flashing = null;

        switch (process.toUpperCase()) {
          case "BACKUP":
            // REQUESTED -> SUCCEEDED or REQUESTED -> FAILED

            color = "orange";

            switch (status.toUpperCase()) {
              case "REQUESTED":
              case "SUCCEEDED":
              case "FAILED":
                break;
              default:
                break;
            }

            break;
          case "RESTORE":
            // REQUESTED -> LOGOUT_WARNING -> LOGOUT -> SUCCEEDED or REQUESTED -> FAILED

            color = "pink";
            flashing = "flashing";

            // set flag for every restore message to cater for login in mid-process
            store.dispatch({
              type: "SET_SYSTEM_RESTORE",
              payload: { restore: true },
            });

            // disable data collection if restore is underway....stops distraction of data processing
            store.dispatch({
              type: "SYSTEM_TURN_ON_OFF_POKE_THE_WORKER",
              payload: false,
            });

            switch (status.toUpperCase()) {
              case "LOGOUT_WARNING":
                //e.g. message: "PROCESS_RESTORE_LOGOUT_WARNING_MSG:1659504946"//
                const newMessageArray = message?.split(":") || ["UNKNOWN", 0];

                header = strings?.[newMessageArray[0]];
                const countdown = Number?.(newMessageArray?.[1]) - timestamp;
                let countDownMsg = `${countdown} sec. `;

                if (countdown === 0) {
                  countDownMsg = `...${strings?.["NOW"]} `;
                }

                content =
                  strings?.[`${newMessageArray[0]}_CONTENT`] +
                  countDownMsg +
                  `${strings?.["Requested by"]} ${user}`;

                // if exist, clear backup messages
                if (
                  flashMessages?.some?.(
                    (msg) => msg.id === "PROCESS_MSG_BACKUP"
                  )
                ) {
                  store.dispatch({
                    type: "REMOVE_FLASH_MESSAGE",
                    payload: { id: "PROCESS_MSG_BACKUP" },
                  });
                }

                break;
              case "REQUESTED":
              case "SUCCEEDED":
              case "FAILED":
                break;
              case "LOGOUT":
                // if exist, clear restore messages
                store.dispatch({
                  type: "REMOVE_FLASH_MESSAGE",
                  payload: { id: "PROCESS_MSG_RESTORE" },
                });

                // see - src/components/User/UserMenuItem.js > UserLogOut()

                // terminate webworker
                store.dispatch({
                  type: "MQTT_RESET",
                  payload: true,
                });

                // reset all states
                store.dispatch({
                  type: "RESET",
                });

                //request logout
                store.dispatch({
                  type: "LOGOUT_REQUEST",
                });

                // clear authentication cookie
                clearCookie("jwt-token");

                break;
              default:
                break;
            }

            break;

          default:
            break;
        }

        if (status?.toUpperCase() === "REQUESTED") {
          if (!flashMessages?.some?.((msg) => msg.id === id)) {
            store.dispatch({
              type: "ADD_FLASH_MESSAGE",
              payload: { id, color, header, content },
            });
          }
        }
        // for everything else
        else {
          //console.log("xxx 123 process status id", process, status, id);

          // remove all prior
          store.dispatch({
            type: "REMOVE_FLASH_MESSAGE",
            payload: { id },
          });
          // add new
          store.dispatch({
            type: "ADD_FLASH_MESSAGE",
            payload: { id, color, header, content, className: flashing },
          });
        }

        // clear message from state after processing
        store.dispatch({
          type: "MQTT_SYSTEM_PROCESS_MSG_CLEAR",
          payload: { id: processId },
        });
      });

      break;
    default:
      break;
  }
  return next(newAction);
};
