// #NOTE - this is very similar to
// src/components/WebWorker/processAreaInfoMessage.js

import { strToObj } from "utils/strToObj";
import _isString from "lodash/isString";
import { strToArray } from "utils/strToArray";

export const convertFetchAreasToMqtt = ({ areas }) => {
  //console.log(`API MINE_LEVELS_FETCH_SUCCEEDED areas`, areas);

  let areaInfos = {};
  let areaFeatures = {};
  let areaStatuses = {};

  // active: true
  // bounds: Array(5)
  //   0: (2) [735799.5638007121, 9547988.719091762]
  //   1: (2) [738899.0141732342, 9547988.719091762]
  //   2: (2) [738899.0141732342, 9550167.067123882]
  //   3: (2) [735799.5638007121, 9550167.067123882]
  //   4: (2) [735799.5638007121, 9547988.719091762]
  // length: 5
  // __proto__: Array(0)
  // ceiling: 0
  // default_color: "GREEN"
  // default_state: "ON"
  // floor: 0
  // id: "DMLZ_Extraction" // // following below are not yet defined in mqtt object
  // easting: easting,
  // northing: northing,
  // utm_zone_number: utm_zone_number,
  // utm_zone_letter: utm_zone_letter,
  // z: z,
  // image_filename: "test1-2021-June-28-14-19-08.png"
  // image_info: "{\"width\":2531,\"height\":1931,\"backgroundColor\":{\"r\":255,\"g\":255,\"b\":255,\"a\":1}}"
  // location: ""
  // named_area_group: "[{\"id\":\"55ca0a32-4f42-43f0-b4c9-d0722c1106aa\",\"label\":\"New group 1\",\"subItems\":[{\"id\":\"89758955-aaed-4486-b842-171f65b990f7\",\"parent\":\"55ca0a32-4f42-43f0-b4c9-d0722c1106aa\",\"label\":\"New level\",\"subItems\":[{\"id\":\"5bbd39d7-ee58-4252-adee-95a39f8f332a\",\"label\":\"Test\",\"namedArea\":{\"id\":\"DMLZ_Extraction:5715acba-902b-467b-9cc2-06d084ebe6dc\",\"priority\":1,\"label\":\"Test\",\"button\":{\"clickable\":true,\"icon\":\"icon-earthquake123\",\"priority\":1,\"title\":\"Seismic 1\",\"alt\":\"Level 1\",\"color\":\"red\",\"group\":0,\"type\":\"SEISMIC1_EVENT\",\"hint\":\"Level 0\",\"default\":true,\"active_state\":\"on\",\"active_color\":\"red\",\"state\":\"on\",\"on_time\":0,\"off_time\":0,\"train\":0,\"active\":true,\"marker\":\"RoundMarker\",\"id\":\"cee81bd8-820d-47c9-a34c-f774ceda51c2\",\"named_area\":\"DMLZ_Extraction:5715acba-902b-467b-9cc2-06d084ebe6dc\"}},\"parent\":\"89758955-aaed-4486-b842-171f65b990f7\"}],\"settings\":{\"clickable\":true,\"icon\":\"icon-earthquake123\",\"priority\":0,\"title\":\"Seismic 1\",\"alt\":\"Level 1\",\"color\":\"red\",\"group\":0,\"type\":\"SEISMIC1_EVENT\",\"hint\":\"Level 0\",\"default\":true,\"active_state\":\"on\",\"active_color\":\"red\",\"state\":\"on\",\"on_time\":0,\"off_time\":0,\"train\":0,\"active\":true,\"marker\":\"RoundMarker\"}}]}]"
  // ref_coord: Array(4)
  // 0:
  //    area: "DMLZ_Extraction:1"
  //    id: "DMLZ_Extraction:1"
  //    image_xy: (2) [1847.9768121112127, 111.99481761394281]
  //    index: 0
  //    utm: (2) [736100, 9550000]
  //    utm_zone_letter: "M"
  //    utm_zone_number: 57
  //    z: 0
  //    .....
  // __proto__: Object
  // __proto__: Array(0)
  // slug: "dmlz_extraction"
  // token: ""

  // ref_coord
  //
  // area: "DMLZ_Extraction:1"
  // id: "DMLZ_Extraction:1"
  // image_xy: Array(2)
  // 0: 1847.9768121112127
  // 1: 111.99481761394281
  // length: 2
  // __proto__: Array(0)
  // index: 0
  // utm: Array(2)
  // 0: 736100
  // 1: 9550000
  // length: 2namedAreaInfos
  // utm_zone_number: 57
  // z: 0

  areas.forEach((area) => {
    const {
      id,
      active,
      bounds = [
        [0, 0],
        [1, 0],
        [0, 1],
        [1, 1],
        [0, 0],
      ], // #NOTE - there must be bounds!!
      ceiling,
      default_color,
      default_state,
      floor,
      image_filename,
      image_info,
      location,
      named_area_group,
      ref_coord,
      slug,
      order,

      //
    } = area;

    console.log(`MINE_LEVELS_FETCH_SUCCEEDED area`, area);

    if (id !== "ALL_AREAS") {
      //console.log(`MINE_LEVELS_FETCH_SUCCEEDED area`, area);
      if (bounds !== null) {
        // pop off the last element of the array which is the close point added for psql
        bounds.pop();
      }

      const imageInfo =
        image_info !== undefined
          ? strToObj(image_info)
          : {
              width: 0,
              height: 0,
              backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
            };

      const name = id.replaceAll("_", " "); // strip underscores from id to make the name

      const namedAreaGroup =
        named_area_group !== undefined ? strToArray(named_area_group) : [];

      // #NOTE - left in place pending update to go server to add utm info
      // also see below.

      //const stringUtmZoneLetter = String.fromCharCode(utm_zone_letter);

      const coordinates = [bounds];
      const areaFeature = {
        id: id,
        type: "Feature",
        geometry: { type: "Polygon", coordinates: coordinates },
        properties: {
          status: "api", // for info, file search for "used to track the status of the object on maps and in lists"
          id: id,
          name: name,
          image_filename: image_filename,
          image_info: imageInfo,
          namedAreaGroup: namedAreaGroup,
          coordinatesUtm: coordinates,
          slug: slug,
          ref_coord: ref_coord,
          floor: floor,
          ceiling: ceiling,
          //status: status, // <--- #REVIEW/TODO - conflicts with status above. Change all local 'status' to 'source' if this is not used.

          default_color: _isString(default_color)
            ? default_color.toLowerCase()
            : "green", // force defaults if nothing sent  // ## REVIEW #TODO - get defaults from setup for application
          default_state: _isString(default_state)
            ? default_state.toLowerCase()
            : "on", // force defaults if nothing sent
          // // following below are not yet defined in mqtt object
          // easting: easting,
          // northing: northing,
          // utm_zone_number: utm_zone_number,
          // utm_zone_letter: utm_zone_letter,
          // z: z,
          order: order,
          active: active,
        },
      };
      areaFeatures[id] = areaFeature;
      areaStatuses[id] = areaFeature.properties;

      console.log(`MINE_LEVELS_FETCH_SUCCEEDED areaFeatures`, areaFeatures);
    }
  });

  areaInfos = {
    type: "FeatureCollection",
    features: Object.values(areaFeatures),
  };

  return {
    areaInfos,
    areaStatuses,
  };
};
