import { connect } from "react-redux";

import {
  SetNamedAreaDisplaySettings,
  SetPollRates,
  fetchMqttBroker,
  fetchCheckService,
} from "components/Settings/actions";

import { NAMED_AREA_DISPLAY_SETTINGS } from "cookies";
import { isConfigJs, pollRates, checkService } from "components/ConfigJs";
import { withComponentStateCache } from "react-component-state-cache";
import { UpdateNamedAreaDisplaySettings } from "components/Settings/actions";

function Settings({ ...props }) {
  // setup named area display settings
  props.SetNamedAreaDisplaySettings(NAMED_AREA_DISPLAY_SETTINGS);
  // get mqtt settings from the server
  props.fetchMqttBroker();

  const services = checkService() || [];

  //console.log("xxx services", services);
  //console.log("xxx userSettings", props?.userSettings);

  // application or features which require service checks:
  // - BackupRestore - iot-firefly-server-backup-monitor
  // - TagTracking - iot-tag-tracking

  // checkService for applications

  // checkService for features

  services.forEach((serv) => {
    // check if services required for features/applications are running OK

    const isApplication = props?.userSettings?.application?.includes(
      serv.application
    );
    const isFeature = props?.userSettings?.feature?.includes(serv.feature);

    if (isApplication || isFeature) {
      props.fetchCheckService({
        service: serv.service,
        check: "status",
      });
    }
  });

  // setup pollRates from config if available
  if (isConfigJs() && pollRates()) {
    const { disable, workerEssentials, worker, server, tagTracking } =
      pollRates();

    props.SetPollRates({
      disable: disable,
      worker: worker,
      workerEssentials,
      server: server,
      tagTracking: tagTracking,
    });
  }

  console.log("LOADED USER SETTINGS: ", JSON.stringify(props?.userSettings));

  // #NOTE - #USER_SETTINGS
  // reset componentStateCache from db settings records
  const componentState = props?.userSettings?.componentState || [];
  componentState.forEach((settings) => {
    props.componentstate.set(settings.section, settings.key, settings.data);
  });

  // #NOTE - #USER_SETTINGS
  const displaySettings = props?.userSettings?.display || [];
  props.UpdateNamedAreaDisplaySettings(displaySettings);

  return null;
}

export default withComponentStateCache(
  connect(null, {
    SetNamedAreaDisplaySettings,
    SetPollRates,
    fetchMqttBroker,
    fetchCheckService,
    UpdateNamedAreaDisplaySettings,
  })(Settings)
);
