import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Grid,
  Segment,
  Form,
  Popup,
  Dropdown,
  Checkbox,
  Table,
  Icon,
} from "semantic-ui-react";
import { renderSelect, renderRadio } from "admin/form-field";
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  getFormValues,
  isDirty,
} from "redux-form";

const monthOptions = (strings) => {
  return [
    {
      key: 0,
      text: "*",
      value: 0,
    },

    {
      key: 1,
      text: strings?.["Select Date"],
      value: 1,
    },
    {
      key: 2,
      text: strings?.["january"],
      value: 2,
    },
    {
      key: 3,
      text: strings?.["february"],
      value: 3,
    },
    {
      key: 4,
      text: strings?.["march"],
      value: 4,
    },
    {
      key: 5,
      text: strings?.["april"],
      value: 5,
    },
  ];
};

const dayOptions = (strings) => {
  return [
    {
      key: 0,
      text: "*",
      value: 0,
    },
    {
      key: 1,
      text: strings?.["monday"],
      value: 1,
    },
    {
      key: 2,
      text: strings?.["tuesday"],
      value: 2,
    },
    {
      key: 3,
      text: strings?.["wednesday"],
      value: 3,
    },
    {
      key: 4,
      text: strings?.["thursday"],
      value: 4,
    },
    {
      key: 5,
      text: strings?.["friday"],
      value: 5,
    },
    {
      key: 6,
      text: strings?.["saturday"],
      value: 6,
    },
    {
      key: 7,
      text: strings?.["sunday"],
      value: 7,
    },
  ];
};

class _CronWizard extends Component {
  constructor() {
    super();
    this.state = {
      //
    };
  }

  handleMonthChange = (e, data) => {
    const { value } = data;

    console.log("xxx data", data);

    console.log("xxx value", value);

    // custom action
    // if (value.length > 0 && value.includes(0)) {
    //   const index = value.indexOf(0);
    //   if (index > -1) {
    //     value.splice(index, 1);
    //   }
    // }
  };

  handleEveryCheck = (e, data) => {
    console.log("xxx check data", data);
  };

  handleTrashClick = (e, data) => {
    console.log("xxx check data", data);
  };

  rowContent = (id, strings) => {
    return (
      <>
        <Table.Cell>
          <Popup
            content={""}
            trigger={
              <Checkbox
                checked={true}
                checkProp={"@every month"}
                onClick={this.handleEveryCheck}
              />
            }
          />
          <Popup
            content={strings?.[""]}
            trigger={
              <Dropdown
                selection
                //multiple
                compact
                options={monthOptions(strings)}
                style={{ marginLeft: "5px", minWidth: "120px" }}
                defaultValue={0}
                onChange={this.handleMonthChange}
              />
            }
          />
        </Table.Cell>
        <Table.Cell>
          <Popup
            content={""}
            trigger={
              <Checkbox
                checked={true}
                checkProp={"@every day"}
                onClick={this.handleEveryCheck}
              />
            }
          />
          <Popup
            content={strings?.[""]}
            trigger={
              <Dropdown
                selection
                //multiple
                compact
                options={dayOptions(strings)}
                style={{ marginLeft: "5px", minWidth: "120px" }}
                defaultValue={0}
                onChange={this.handleMonthChange}
              />
            }
          />
        </Table.Cell>
        <Table.Cell>3</Table.Cell>
        <Table.Cell>4</Table.Cell>
        <Table.Cell>5</Table.Cell>
        <Table.Cell>6</Table.Cell>
        <Table.Cell>
          <Icon name="trash" id={id} onClick={this.handleTrashClick} />
        </Table.Cell>
      </>
    );
  };

  render() {
    const { strings } = this.props;

    return (
      <Segment>
        <Form>
          <Table compact celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Month</Table.HeaderCell>
                <Table.HeaderCell>Day</Table.HeaderCell>
                <Table.HeaderCell>Hour</Table.HeaderCell>
                <Table.HeaderCell>Minute</Table.HeaderCell>
                <Table.HeaderCell>Duration</Table.HeaderCell>
                <Table.HeaderCell>User Relay</Table.HeaderCell>
                <Table.HeaderCell>
                  <Icon name="trash" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {[0, 1, 2]?.map((row, idx) => {
                return (
                  <Table.Row key={`row-id-${idx}`}>
                    {this.rowContent(row, strings)}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Form>
      </Segment>
    );
  }
}

export const CronWizard = connect(null, null)(_CronWizard);
