import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import UPSBulkEditPage from "admin/bulk-positioning/UPSBulkEditPage";

import { fetchUPSs } from "UPSPanelControllers/actions";

// #REVIEW - redirect back to UPS list page if link not valid instead of *unsupported bulk positioning list*
//import BulkPositioningList from './BulkPositioningList'
import UPSList from "admin/ups/UPSList";

class BulkPositioningAdminPages extends Component {
  render() {
    const { match, strings } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/:id`}
          // component={UPSBulkEditPage}
          render={(props) => <UPSBulkEditPage {...props} strings={strings} />}
        />
        {/* <Route path={`${match.url}/ups/:id`} component={UPSBulkEditPage} /> */}
        {/* <Route exact path={match.url} component={BulkPositioningList} /> */}
        <Route
          exact
          path={match.url}
          //component={UPSList}
          render={(props) => <UPSList {...props} strings={strings} />}
        />
      </Switch>
    );
  }
}

export default withRouter(
  connect(null, { fetchUPSs })(BulkPositioningAdminPages)
);
