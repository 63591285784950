import { call, put, takeEvery } from 'redux-saga/effects'
import * as Api from 'api'
import { parseDate } from 'utils/format-date'

// worker Saga: will be fired on MINE_LEVELS_FETCH_REQUESTED actions
function * fetchSeismicLayersForMineLevel (action) {
  const { mineLevelId } = action.payload
  try {
    const layers = yield call(Api.fetchSeismicLayersForMineLevel, mineLevelId)
    yield put({
      type: 'MINE_LEVEL_ALL_SEISMIC_FETCH_SUCCEEDED',
      payload: {
        mineLevelId,
        layersById: layers.reduce((acc, cur) => {
          cur.createdAt = parseDate(cur.createdAt)
          acc[cur.id] = cur
          return acc
        }, {})
      }
    })
  } catch (error) {
    yield put({
      type: 'MINE_LEVEL_ALL_SEISMIC_FETCH_FAILED',
      payload: {
        message: error.message,
        mineLevelId
      }
    })
  }
}

function * fetchCurrentSeismicLayerForMineLevel (action) {
  const { mineLevelId } = action.payload
  try {
    const layer = yield call(
      Api.fetchCurrentSeismicLayerForMineLevel,
      mineLevelId
    )
    layer.createdAt = parseDate(layer.createdAt)
    yield put({
      type: 'MINE_LEVEL_CURRENT_SEISMIC_FETCH_SUCCEEDED',
      payload: {
        mineLevelId,
        layer
      }
    })
  } catch (error) {
    yield put({
      type: 'MINE_LEVEL_CURRENT_SEISMIC_FETCH_FAILED',
      payload: {
        message: error.message,
        mineLevelId
      }
    })
  }
}

function * seismicSettingsSaga () {
  yield takeEvery(
    'MINE_LEVEL_ALL_SEISMIC_FETCH_REQUESTED',
    fetchSeismicLayersForMineLevel
  )

  yield takeEvery(
    'MINE_LEVEL_CURRENT_SEISMIC_FETCH_REQUESTED',
    fetchCurrentSeismicLayerForMineLevel
  )
}

export default seismicSettingsSaga
