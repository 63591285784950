function strToObj(str) {
  var obj = {};
  if (str && typeof str === "string") {
    var objStr = str.match(/\{(.)+\}/g);
    eval("obj =" + objStr);
  }
  return obj;
}

export const convertFetchNamedAreaEventsToMqtt = (data) => {
  let namedAreaEvents = {};

  data.list.forEach((namedArea) => {
    const cloneNamedAreaEvents = { ...namedAreaEvents };
    const addEvents = makeNamedAreaEvent(namedArea, cloneNamedAreaEvents);

    namedAreaEvents = addEvents.namedAreaEvents;
  });

  return {
    namedAreaEvents,
  };
};

const makeNamedAreaEvent = (newMessage, oldNamedAreaEvents) => {
  let namedAreaEvents = { ...oldNamedAreaEvents };

  // #NOTE -
  // #WIP - review wrt to making a common funtion with webWorker
  //

  const isNamedAreaEvent = true; //topic.includes("named_area");
  const isEmergencyEvent = false; //topic.includes("emergency");
  const isTriggerEvent = false; // topic.includes("trigger");
  const isTagDaemonEvent = false; //topic.toLowerCase().includes("tag_daemon");

  {
    const {
      id,
      priority,
      active,
      active_color,
      active_state,
      timestamp,
      precanned,
      button_groups,
      type,
      sub_type,
      start,
      end,
      on_time,
      off_time,
      train,
      relay_event_active,
      origin,
      operator,
      note,
      password,
      disable,
    } = newMessage;

    // define parent named area in event
    // e.g.NAMED_AREA_FETCH_SUCCEEDED
    // level wide -> DMLZ_Extraction
    // named area -> DMLZ_Extraction:123:1600327852119

    const idArray = id.split(":");
    const area = idArray[0]; // "DMLZ_Extraction"

    let parent = idArray[0]; // "DMLZ_Extraction" i.e. level wide default
    if (idArray.length > 1) {
      parent = `${idArray[0]}:${idArray[1]}`; // "DMLZ_Extraction:123" i.e. named area
    }

    // NOTE: newMessage.button_groups e.g. {"0":2,"1":-1,"2":0}
    // passes the current button group status as a string within the event message.
    // JSON.parse does not convert it to an object becuase the Json is non-std.
    // Instead this handy function using eval() works OK.
    // It is not ideal to use eval() as it executes the js string but in this case seems
    // pretty safe.
    // function strToObj(str) {
    //   var obj = {};
    //   if (str && typeof str === "string") {
    //     var objStr = str.match(/\{(.)+\}/g);
    //     eval("obj =" + objStr);
    //   }
    //   return obj;
    // }

    // NOTE: emergency `ALL_AREA` events are sent via
    // emergency/ALL_AREAS/event, not via namedArea events.
    // **However** a default ALL_AREAS namedArea event message may be sent, so ignore it!!!

    if (
      isEmergencyEvent ||
      isTriggerEvent ||
      isNamedAreaEvent //&& id !== "ALL_AREAS"
      //&& !token.toLowerCase().includes("ignore")
    ) {
      // set key value by id
      namedAreaEvents[id] = {
        id,
        priority,
        active,
        active_color,
        active_state,
        timestamp,
        precanned,
        button_groups,
        type,
        sub_type,
        start,
        end,
        on_time,
        off_time,
        train,
        relay_event_active,
        origin,
        operator,
        note,
        password,
        disable,
      };

      // NOTE - about button_groups being `undefined`
      //
      // button_groups property of events contains the state of the button groups in the
      // current system. `button_groups` state is maintained by the clients and updates are
      // sent with the event messages (i.e. when a control screen button is clicked).
      //
      // However if a named area is changed (or new) the server will send out an event message
      // for the changed named area. Since the server has no knowledge of the button_group
      // status it sends a message which does not have the button_group property.
      //
      //
      // if button_groups is undefined (i.e. not in the mqtt object)

      if (
        button_groups === null ||
        button_groups === undefined ||
        button_groups === "{}" ||
        button_groups === ""
      ) {
        // then check if it *does* exist in the current object
        // if not, then create a default, but don't change the existing value
        if (namedAreaEvents[id].button_groups === undefined) {
          namedAreaEvents[id].button_groups = { 0: -1 };
          // Note
          // { 0: -1 } - sets 1 group, none selected
          // { 0: 0 } - sets 1 group, 1st button selected
        }
      } else {
        // otherwise, update the button_groups as usual
        namedAreaEvents[id].button_groups = strToObj(button_groups);
      }

      // #WIP #TODO
      // For now, the above code below checks whether the property exists or not and processes
      // appropriately. However this should be addressed by moving the `button_group` to
      // a database record and have the server return the current button_group state.
      // This is also important for startup. Since a re-start does not restore the button
      // states of the clients in this current implemention.

      //console.log("mqbutton_groups
      //   "qqq mqtt emergency/+/event OR named_area/+/event OR trigger/+/event rx ",
      //   id,
      //   JSON.stringify(newMessage),
      //   namedAreaEvents[id],
      //   active
      // );
    }
  }

  return { namedAreaEvents: { ...namedAreaEvents } };
};
