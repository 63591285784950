import React from "react";
import PropTypes from "prop-types";

function DeviceName({ id, name, error, ...props }) {
  if (error) {
    return (
      <span>
        {error} - {id}
      </span>
    );
  }
  if (name) {
    return (
      <span>
        {name} <small>({id})</small>
      </span>
    );
  }
  return <span>{id}</span>;
}

DeviceName.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  error: PropTypes.string
};

export default DeviceName;
