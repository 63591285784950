import React, { Component } from "react";
import { push } from "react-router-redux";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Popup,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import GenericButton from "admin/GenericButton";

import { DateTime } from "luxon";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { ClearLog } from "components/Logger/actions";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";

// tag specific operations
//

import {
  getAllTags,
  getAllTagCoordinates,
  getTagPersonnel,
  getTagVehicles,
} from "components/Tags/reducer";

import {
  fetchTags,
  unassignUser,
  unassignVehicle,
  fetchAllTagUsers,
  fetchAllTagVehicles,
} from "components/Tags/actions";

import { fetchAllUsers } from "components/UserAdmin/actions";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class TagTrackingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search        id: "",
        mac: "",
        firefly: "",
        rssi: "",
        name: "",
        vehicle: "",
        tagZone: "",
        area: "",
        tagUserType: "",
        status: "",
        tagEntry: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
    };
  }

  componentDidMount() {
    const filterInput = this.props.componentstate.get(
      "filterInput",
      "tagsList"
    );

    //console.log("filterInput componentDidMount", filterInput);

    if (!_.isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "tagsList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included

        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase().includes(match[key].toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        if (!item.disableUnassign) newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  fetchStuff = () => {
    this.props.fetchAllUsers();
    this.props.fetchTags();
    this.props.fetchAllTagUsers();
    this.props.fetchAllTagVehicles();
  };

  unassignVehicle = (values) => {
    return new Promise((resolve, reject) => {
      this.props.unassignVehicle({
        values,
        resolve,
        reject,
      });
    });
  };

  unassignPersonnel = (values) => {
    return new Promise((resolve, reject) => {
      this.props.unassignUser({
        values,
        resolve,
        reject,
      });
    });
  };

  requestAction = (action) => {
    const { selections } = this.state;

    const { data } = this.props;

    let promiseArray = [];

    if (action === "unassign") {
      for (var key in selections) {
        const selection = key; // mac address

        const tagSelection = data?.find((tag) => tag.id === selection);
        const { tagId, tagUserType } = tagSelection;

        console.log(`unassign`, tagSelection);

        switch (tagUserType) {
          case "personnel":
            promiseArray.push(this.unassignPersonnel({ id: tagId }));
            break;
          case "vehicle":
            promiseArray.push(this.unassignVehicle({ id: tagId }));
            break;

          default:
            break;
        }
      }
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log(" .... processed unassign tags", results);
      })
      .then(
        () => {
          this.props.fetchTags();
          this.props.fetchAllTagUsers();
          this.props.fetchAllTagVehicles();
          this.props.push(`/status/tag`);
        },
        (errorMsg) => {
          console.log("action failed", errorMsg); // #TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction, filterInput } = this.state;

    const includeTitles = this.props.includeTitles === "true";
    const title = includeTitles ? (
      <Header as="h1">{strings?.["Tags"]}</Header>
    ) : (
      <div></div>
    );

    // APP_TERMINOLOGY
    let strTag = strings?.["Tag"];
    let strTags = strings?.["Tags"];

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "taglist10" },
              { value: 20, text: "20", key: "taglist20" },
              { value: 40, text: "40", key: "taglist40" },
              { value: 60, text: "60", key: "taglist60" },
              { value: data.length, text: "all", key: "taglistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const tagUserFromDataById = (id) => allData.find((item) => item.id == id); // falsey

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let unassignButton;
    let tagSelectedList;
    if (selectedCount > 0) {
      let tagNames = [];

      // #REVIEW - WIP
      for (var key in selections) {
        if (tagUserFromDataById(key) !== undefined) {
          let tagName = tagUserFromDataById(key).id;
          tagNames.push(tagName);
        }
      }
      tagNames.sort(sortAlphaNum);
      tagNames = tagNames.join(", "); // separate by commas

      unassignButton = (
        <GenericButton
          label={strings?.["Unassign"]}
          color="orange"
          onClick={() => this.requestAction("unassign")}
        />
      );

      tagSelectedList = (
        <Segment textAlign="left">
          <strong>
            {strings.formatString(strings?.[`{list_items} selected...`], {
              list_items: strTags,
            })}
          </strong>
          {tagNames}
        </Segment>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{title}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment textAlign="left" style={segmentStyle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Popup
                content={strings.formatString(
                  strings?.["{list_count_item} count. Click to refresh data."],
                  { list_count_item: "Tag" }
                )}
                trigger={
                  <Button
                    size="large"
                    color="orange"
                    onClick={() => this.fetchStuff()}
                    // onClick={this.props.ClearLog}
                  >
                    {itemsCount}
                  </Button>
                }
              />
            </div>
          </Segment>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <span>
                  {
                    strings?.[
                      "Select items in the table below to apply an action"
                    ]
                  }
                </span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {selectedCount} selected
                </span>
                <Button basic onClick={this.handleClearAll}>
                  {strings?.["Clear"]}
                </Button>
                <Button.Group>{unassignButton}</Button.Group>
              </div>
              <div>
                <span>
                  {strings.formatString(
                    strings?.[`{list_length} items displayed`],
                    {
                      list_length: viewablesPage.length,
                    }
                  )}
                </span>
              </div>
            </Segment>
            {tagSelectedList}
          </Segment.Group>

          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="edit" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.tagEntry ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="tagEntry"
                    onChange={this.handleFilterChange}
                    value={filterInput?.tagEntry}
                  />
                </Table.Cell>
                {/* <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.name ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder="Filter..."
                    name="name"
                    onChange={this.handleFilterChange}
                    value={filterInput?.name}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.vehicle ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder="Filter..."
                    name="vehicle"
                    onChange={this.handleFilterChange}
                    value={filterInput?.vehicle}
                  />
                </Table.Cell> */}
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.tagUserType ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="tagUserType"
                    onChange={this.handleFilterChange}
                    value={filterInput?.tagUserType}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={filterInput?.id ? "filterInputHighlight" : null}
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="id"
                    onChange={this.handleFilterChange}
                    value={filterInput?.id}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.tagZone ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="tagZone"
                    onChange={this.handleFilterChange}
                    value={filterInput?.tagZone}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.firefly ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="firefly"
                    onChange={this.handleFilterChange}
                    value={filterInput?.firefly}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.rssi ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="rssi"
                    onChange={this.handleFilterChange}
                    value={filterInput?.rssi}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.area ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="area"
                    onChange={this.handleFilterChange}
                    value={filterInput?.area}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.area ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="status"
                    onChange={this.handleFilterChange}
                    value={filterInput?.status}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "tagEntry" ? direction : null}
                  onClick={this.handleSort("tagEntry")}
                >
                  {strings?.["Tag User"]}
                </Table.HeaderCell>
                {/* <Table.HeaderCell
                  sorted={column === "name" ? direction : null}
                  onClick={this.handleSort("name")}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "vehicle" ? direction : null}
                  onClick={this.handleSort("vehicle")}
                >
                  Vehicle
                </Table.HeaderCell> */}
                <Table.HeaderCell
                  width={1}
                  sorted={column === "tagUserType" ? direction : null}
                  onClick={this.handleSort("tagUserType")}
                >
                  {strings?.["Type"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "mac" ? direction : null}
                  onClick={this.handleSort("mac")}
                >
                  {strings?.["MAC"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "tagZone" ? direction : null}
                  onClick={this.handleSort("tagZone")}
                >
                  {strings?.["Zones"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "firefly" ? direction : null}
                  onClick={this.handleSort("firefly")}
                >
                  {strings?.["FireFly"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "rssi" ? direction : null}
                  onClick={this.handleSort("rssi")}
                >
                  {strings?.["RSSI"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "area" ? direction : null}
                  onClick={this.handleSort("area")}
                >
                  {strings?.["Areas"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "status" ? direction : null}
                  onClick={this.handleSort("status")}
                >
                  {strings?.["Status"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "lastStatusReport" ? direction : null}
                  onClick={this.handleSort("lastStatusReport")}
                >
                  {strings?.["Latest Report"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({
                  id,
                  mac,
                  firefly,
                  rssi,
                  name,
                  vehicle,
                  tagZone,
                  area,
                  tagLink,
                  tagUserType,
                  lastStatusReport,
                  disableUnassign,
                  status,
                }) => (
                  <Table.Row>
                    <Table.Cell>
                      <Checkbox
                        checked={this.isItemSelected(id)}
                        onChange={() => this.handleSelect(id)}
                        disabled={disableUnassign}
                      />
                    </Table.Cell>
                    <Table.Cell>{tagLink}</Table.Cell>
                    {/* <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{vehicle}</Table.Cell> */}
                    <Table.Cell>{tagUserType}</Table.Cell>
                    <Table.Cell>{mac}</Table.Cell>
                    <Table.Cell>{tagZone}</Table.Cell>
                    <Table.Cell>{firefly}</Table.Cell>
                    <Table.Cell>{rssi}</Table.Cell>
                    <Table.Cell>{area}</Table.Cell>
                    <Table.Cell>{status}</Table.Cell>
                    <Table.Cell>{lastStatusReport}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements, coords, tagUsers) => {
  let filteredElements = [];

  const { personnel, vehicles } = tagUsers;

  // console.log(
  //   `tagTracking elements, personnel, vehicles`,
  //   elements,
  //   personnel,
  //   vehicles
  // );

  // only need a subset of data
  elements.forEach(function (element, idx) {
    const {
      id,
      mac,
      calculated_rssi: rssi,
      tagId,
      tagUserId,
      tagUserType,
      last_updated: lastUpdated,
      locations,
      area = "?",
      zones: tagZones = ["?"],
      status,
    } = element;

    // #DEBUG - for debugging coordinate points
    // let coordInfo = ``;
    // const coord = coords?.find((coord) => coord.id === id);
    // if (coord) {
    //   //console.log(`uuu coords[id]`, coord);
    //   const {
    //     geometry: { coordinates },
    //     properties: { easting, northing },
    //   } = coord;
    //   coordInfo = `[${northing},${easting}]: [${coordinates.join(", ")}]`;
    // }

    // is it a user _OR_ a vehicle
    let tagEntry = "?";
    switch (tagUserType) {
      case "personnel":
        const tagInfoPersonnel = personnel?.find(
          (info) => info.id === tagUserId
        ) || { firstname: "", lastname: "" };
        const { firstname, lastname } = tagInfoPersonnel;
        tagEntry = `${firstname} ${lastname}`;
        break;
      case "vehicle":
        const tagInfoVehicle = vehicles?.find((info) => info.id === tagUserId);
        const { name, type } = tagInfoVehicle || { name: "", type: "" };
        tagEntry = `${name} - ${type}`;
        break;
      case "unknown":
        tagEntry = `?`;
        break;

      default:
        break;
    }

    // disable unassign if not yet assigned
    let disableUnassign = tagEntry === "?";

    // break down lastStatusReport as it may not be defined in object
    let lastStatusReport = "-";
    if (lastUpdated) {
      // see - https://moment.github.io/luxon/#/formatting?id=toformat
      lastStatusReport = DateTime.fromISO(lastUpdated).toFormat("FF");
    }

    // tagZones
    // e.g.  [{id: 'Area_Main:e12dbf83-d6ae-49d6-972c-5d3dc4d7876b:1639025597226', area: 'Area_Main', name: 'Left'}...]
    const tagZone = tagZones?.map((zone) => `${zone.name}`);

    // compose new data set
    filteredElements[idx] = {
      id: id,
      mac: mac,
      tagId: tagId,
      disableUnassign: disableUnassign,
      tagLink: <Link to={`/status/tag/${id}`}>{tagEntry} </Link>,
      tagEntry: tagEntry,
      firefly: locations.join(", "),
      rssi: rssi.toString(),
      name: tagEntry,
      vehicle: tagEntry,
      tagUserType: tagUserType === "unknown" ? "?" : tagUserType,
      tagZone: tagZone?.length ? tagZone.join(", ") : "?",
      area: area,
      lastStatusReport,
      status: status?.toUpperCase(),
    };
  });

  //console.log(`TagTrackingList filteredElements`, filteredElements);

  return filteredElements;
};

function mapStateToProps(state) {
  const tagData = getAllTags(state);
  const tagCoordinates = getAllTagCoordinates(state);
  const tagPersonnel = getTagPersonnel(state);
  const tagVehicles = getTagVehicles(state);

  // console.log(`TagTrackingList tagData`, tagData);
  // console.log(`TagTrackingList tagPersonnel`, tagPersonnel);
  // console.log(`TagTrackingList tagVehicles`, tagVehicles);

  const data = _prepData(tagData, tagCoordinates, {
    personnel: tagPersonnel,
    vehicles: tagVehicles,
  });

  return {
    data,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    push,
    ClearLog,
    fetchTags,
    fetchAllUsers,
    //
    fetchAllTagUsers,
    fetchAllTagVehicles,
    unassignUser,
    unassignVehicle,
    saveUserSettingsComponentState,
  })(TagTrackingList)
);
