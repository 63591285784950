import L from "leaflet";

// loads image, writes to file with new filename stripping timecode
export const loadImgToFile = async (url, newFilename) => {
  //console.log("loadImgToFile url", url);
  //console.log("loadImgToFile newFilename", newFilename);
  return await fetch(url)
    .then((response) => {
      //console.log("loadImgToFile response", response);
      if (response.ok) {
        //console.log("loadImgToFile response ok?", response.ok);
        //console.log("loadImgToFile response blob", response.clone().blob());
        // response.clone() b/c stream is locked on 2nd time through
        return response.clone().blob(); // response.blob(); //response.status; // response.json(); // #REVIEW - this returns  SyntaxError: Unexpected token � in JSON at position 0
      } else {
        //console.log("Fetch ", url, "failed. Response : ", response);
        throw response.status;
      }
    })
    //.then((r) => r.blob())
    .then(
      (blobFile) => new File([blobFile], newFilename, { type: "image/png" })
    )
    .catch((err) => {
      console.log("loadImgToFile err:", err); // most likely 404
      throw err;
    });
};

export async function loadImage(imageUrl) {
  //console.log("IMAGE LOADED: imageUrl", imageUrl);

  let img;
  const imageLoadPromise = new Promise((resolve) => {
    img = new Image();
    img.onload = resolve;
    img.src = imageUrl;
  });

  await imageLoadPromise;
  //console.log("IMAGE LOADED:", img);
  return img;
}

const checkImage = (path) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: "ok" });
    img.onerror = () => resolve({ path, status: "error" });

    img.src = path;
  });

export const initialiseArea = async (initialValues) => {
  const { id, image_filename, ref_coord } = initialValues;

  const url = process.env.PUBLIC_URL + "/areas/" + image_filename;
  let width, height;
  let image = new Image();

  // console.log("initialValues", initialValues);
  // console.log("image_filename", image_filename);
  // console.log("url", url);

  // get filename before date code
  const filenameArray = image_filename.split("-");
  const newFilename = `${filenameArray[0]}.png`;

  let imageFile;

  const getImageFile = async (url, newFilename) => {
    return await fetch(url)
      .then((response) => {
        //console.log("getImageFile response", response);
        if (response.ok) {
          return response.blob(); //status; // response.json(); // #REVIEW - this returns  SyntaxError: Unexpected token � in JSON at position 0
        } else {
          console.log(response.status);
          return Promise.reject(response.status);
        }
      })
      //.then((r) => r.blob())
      .then(
        (blobFile) => new File([blobFile], newFilename, { type: "image/png" })
      )
      .catch((err) => {
        console.log(err); // most likely 404
        throw err;
      });
  };

  getImageFile(url, newFilename)
    .catch((err) => {
      throw err;
    })
    .then((result) => {
      imageFile = result;
      //console.log("getImageFile", imageFile);
    })
    .catch((err) => {
      console.log("getImageFile error", err);
      const url = process.env.PUBLIC_URL + "/areas/default.png";
      const bounds = L.latLngBounds([0, 0], [height, width]);
      this.setState({ contentLoading: false });
      this.setState({ errorMessage: err });
    });

  image.onload = () => {
    // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
    // The natural size is the actual image size regardless of rendering.
    // The 'normal' width/height are for the **rendered** size.
    width = image.naturalWidth || image.width;
    height = image.naturalHeight || image.height;

    const bounds = L.latLngBounds([0, 0], [height, width]);

    const refObject = (idx) => {
      return {
        easting: ref_coord[idx].utm[0],
        northing: ref_coord[idx].utm[1],
        zoneLetter: ref_coord[idx].utm_zone_letter,
        zoneNum: ref_coord[idx].utm_zone_number,
        lat: ref_coord[idx].image_xy[0],
        lng: ref_coord[idx].image_xy[1],
      };
    };

    const ref1 = refObject(0);
    const ref2 = refObject(1);
    const ref3 = refObject(2);
    const ref4 = refObject(3);

    //      const imageRef1 = L.latLng({ lat: ref1.lat, lng: ref1.lng });
    const imageRef1 = L.latLng(ref1.lat, ref1.lng);
    const imageRef2 = L.latLng(ref2.lat, ref2.lng);
    const imageRef3 = L.latLng(ref3.lat, ref3.lng);
    const imageRef4 = L.latLng(ref4.lat, ref4.lng);

    const initialValues = {
      id: id,
      name: id,
      mapFile: imageFile,
      mapUrl: url,
      bounds: bounds,
      imageRef1: imageRef1,
      imageRef2: imageRef2,
      imageRef3: imageRef3,
      imageRef4: imageRef4,
      ref1: ref1,
      ref2: ref2,
      ref3: ref3,
      ref4: ref4,
    };

    return initialValues;
  };

  image.src = url; // triggers onload
};
