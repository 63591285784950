import React, { Component } from "react";
import { Button, Label, Icon } from "semantic-ui-react";

export default class ImageUpload extends Component {
  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.props.onFileChanged(file, reader.result);
    };
    reader.readAsDataURL(file);

    // Note - If you select the same file, the onChange event of input[type=file] will not fire.
    // This is commonly done with debug testing e.g. same extraction.png
    // This resets the input field so if you removed it you can re-add the same file.

    //e.target.value = "";
  }

  render() {
    const { strings } = this.props;

    return (
      // <Form.Field inline>
      //   <label for="mapfile">{label}</label>
      //   <input
      //     id="mapfile"
      //     placeholder={label}
      //     type="file"
      //     accept="image/png, image/jpeg"
      //     onChange={(e) => this._handleImageChange(e)}
      //   />
      // </Form.Field>
      <>
        <Button
          as="div"
          labelPosition="right"
          onClick={() => this.input.click()}
        >
          <Button icon title={strings?.["Add another file"]}>
            <Icon name="upload" />
          </Button>
          <Label as="a" basic pointing="left">
            {strings?.["Choose Map Image"]}
          </Label>
        </Button>

        <input
          ref={(element) => (this.input = element)}
          hidden
          onChange={(e) => this._handleImageChange(e)}
          type="file"
          accept="image/png, image/jpeg"
        />
      </>
    );
  }
}
