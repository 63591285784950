import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Header, Grid, List, Segment } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

class ToolsDatabase extends Component {
  render() {
    // const controllers = [
    //   {
    //     id: "74:e1:82:8c:ec:13",
    //     area: "DMLZ_Extraction",
    //     name: "UPS28",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737851.353281354 9548960.50196291)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:91:60:c5",
    //     area: "DMLZ_Extraction",
    //     name: "UPS 14",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737552.989255152 9548627.62692841)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:2f:75",
    //     area: "DMLZ_Extraction",
    //     name: "UPS 02",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737374.115666202 9548860.55998173)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:91:03:b9",
    //     area: "DMLZ_Extraction",
    //     name: "UPS 06",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737166.079852615 9548969.57274285)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "04:a3:16:fd:75:60",
    //     area: "DMLZ_Extraction",
    //     name: "UPS 01",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737306.416302142 9548900.69111753)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:32:db",
    //     area: "DMLZ_Extraction",
    //     name: "ups21",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737150.922473644 9548953.78035979)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:60:2c",
    //     area: "DMLZ_Extraction",
    //     name: "UPS27",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737858.05801772 9548961.22397027)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "7c:66:9d:54:03:af",
    //     area: "DMLZ_Extraction",
    //     name: "dffc-36",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737319.945387662 9549553.78895927)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:60:08",
    //     area: "DMLZ_Extraction",
    //     name: "dffc-31",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737663.304252667 9549216.3928454)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "40:bd:32:e5:93:43",
    //     area: "DMLZ_Extraction",
    //     name: "UPS 15",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737660.669322774 9548624.52916733)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "7c:66:9d:4d:d6:5d",
    //     area: "DMLZ_Extraction",
    //     name: "UPS22",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737068.487366615 9549043.51966745)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "7c:66:9d:46:03:ca",
    //     area: "DMLZ_Extraction",
    //     name: "dffc-35",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737317.318450921 9549546.17550865)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:d6:49",
    //     area: "DMLZ_Extraction",
    //     name: "UPS 13",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737473.441889625 9548718.83866327)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:8c:e4:60",
    //     area: "DMLZ_Extraction",
    //     name: "dffc-23",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(736996.372842553 9549092.98420171)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:6a:a3",
    //     area: "DMLZ_Extraction",
    //     name: "dffc-34",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737422.839158499 9549483.07701795)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "1c:ba:8c:e3:21:a3",
    //     area: "DMLZ_Extraction",
    //     name: "DFFC-10",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737455.060924783 9548714.00609566)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:55:46",
    //     area: "DMLZ_Extraction",
    //     name: "DFFC-33",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737518.751235129 9549363.56519596)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:8c:db:6f",
    //     area: "DMLZ_Extraction",
    //     name: "UPS32",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737567.712564145 9549289.74664204)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:2d:56",
    //     area: "DMLZ_Extraction",
    //     name: "UPS29",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737779.532027233 9549120.35130749)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:4f:a9",
    //     area: "DMLZ_Extraction",
    //     name: "dffc-24",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(736916.271537226 9549135.9592174)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:67:10",
    //     area: "DMLZ_Extraction",
    //     name: "ups30",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737658.644212408 9549203.68890362)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:8c:c3:cc",
    //     area: "DMLZ_Undercut",
    //     name: "dffc-43",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737718.737209277 9549168.60167435)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:8d:4b:86",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 12",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737278.208100737 9548925.32870088)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:3e:1b",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 05",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737535.638830251 9548655.26907502)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:4b:e3",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 17",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737380.273801567 9548851.66554107)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:8c:c6",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 16",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737158.299041944 9548982.98449685)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:8c:fc",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 07",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737538.323437115 9548656.5196655)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "0c:ae:7d:24:ef:d8",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 19",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737493.606319526 9548701.62078885)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "40:bd:32:e5:29:b3",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 11",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737379.825135911 9548879.04083912)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "40:bd:32:e6:3e:31",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 20",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737209.458973068 9548960.43161182)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "74:e1:82:8d:c8:3c",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 18",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737114.734055996 9549027.3425085)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:32:ab",
    //     area: "DMLZ_Undercut",
    //     name: "dffc-45",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737305.423321085 9549568.90914207)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c4:f3:12:81:67:2b",
    //     area: "DMLZ_Undercut",
    //     name: "ups37",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737212.453232057 9548957.7602412)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "f0:45:da:83:01:44",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 08",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737436.471721763 9548782.56314349)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "c8:df:84:d3:83:b2",
    //     area: "DMLZ_Undercut",
    //     name: "UPS 09",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737244.684285398 9548936.36730892)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "04:a3:16:fd:75:63",
    //     area: "DMLZ_Undercut",
    //     name: "UPS04",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737642.263054562 9548643.51803093)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "d0:ff:50:39:a3:10",
    //     area: "DMLZ_Undercut",
    //     name: "DFFC-44",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737406.481816212 9549499.84300068)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    //   {
    //     id: "7c:66:9d:48:cd:de",
    //     area: "DMLZ_Undercut",
    //     name: "dffc-3",
    //     location: "Panel1,Panel2,Panel3,Panel4",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     utm: "POINT(737533.877957206 9549362.61236947)",
    //     z: null,
    //     ip: null,
    //     master: true,
    //     mode: "operation",
    //     timestamp: null,
    //     battery: false,
    //     network: false,
    //     sw_ver: "UPS",
    //     type: "UPS",
    //     emergency_source_active: null,
    //     trigger_source: null,
    //     emergency_config: null,
    //     trigger_config: null,
    //   },
    // ];

    // const fireflies = [
    //   {
    //     id: "DMLZ_Extraction:DD19E:5",
    //     mac: "24:0a:c4:51:1e:a4",
    //     area: "DMLZ_Extraction",
    //     location: "DD19E",
    //     position: 5,
    //     utm: "POINT(737382.232827574 9549314.74257347)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:DD22W:11",
    //     mac: "b4:e6:2d:ad:9c:11",
    //     area: "DMLZ_Extraction",
    //     location: "DD22W",
    //     position: 11,
    //     utm: "POINT(737225.529520075 9548897.34609137)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:DD22W:13",
    //     mac: "b4:e6:2d:98:a7:e9",
    //     area: "DMLZ_Extraction",
    //     location: "DD22W",
    //     position: 13,
    //     utm: "POINT(737359.161195923 9549286.23095178)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:DD24E:17",
    //     mac: "c4:4f:33:23:7d:5d",
    //     area: "DMLZ_Extraction",
    //     location: "DD24E",
    //     position: 17,
    //     utm: "POINT(737628.380567744 9549192.96209264)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:DD27E:16",
    //     mac: "c4:4f:33:23:af:0d",
    //     area: "DMLZ_Extraction",
    //     location: "DD27E",
    //     position: 16,
    //     utm: "POINT(737394.936850181 9549137.05909116)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:1",
    //     mac: "c4:4f:33:23:ce:41",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 1,
    //     utm: "POINT(737844.999568699 9548951.97288121)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:11",
    //     mac: "c4:4f:33:23:60:5d",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 11,
    //     utm: "POINT(737870.17449439 9548868.2813015)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:13",
    //     mac: "24:0a:c4:50:d0:38",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 13,
    //     utm: "POINT(737863.875439816 9548852.15549792)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:15",
    //     mac: "c4:4f:33:23:7c:3d",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 15,
    //     utm: "POINT(737853.560396106 9548838.14442457)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:17",
    //     mac: "24:0a:c4:50:c8:50",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 17,
    //     utm: "POINT(737843.172301757 9548825.05811535)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:19",
    //     mac: "24:0a:c4:50:d0:00",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 19,
    //     utm: "POINT(737832.037306253 9548810.95706101)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:2",
    //     mac: "b4:e6:2d:ad:0b:3d",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 2,
    //     utm: "POINT(737868.81148203 9548859.85216343)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:21",
    //     mac: "24:0a:c4:50:d3:0c",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 21,
    //     utm: "POINT(737820.231940555 9548796.93097222)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:3",
    //     mac: "c4:4f:33:23:57:59",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 3,
    //     utm: "POINT(737850.84006177 9548935.64392156)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:4",
    //     mac: "b4:e6:2d:98:a8:25",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 4,
    //     utm: "POINT(737848.105724652 9548831.70329245)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:5",
    //     mac: "c4:4f:33:23:68:d9",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 5,
    //     utm: "POINT(737856.454084961 9548918.16862837)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:6",
    //     mac: "b4:e6:2d:98:f5:a5",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 6,
    //     utm: "POINT(737826.357600941 9548803.72110944)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:7",
    //     mac: "c4:4f:33:23:69:35",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 7,
    //     utm: "POINT(737861.619665877 9548900.10298882)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:EFD:9",
    //     mac: "4c:11:ae:b7:f5:74",
    //     area: "DMLZ_Extraction",
    //     location: "EFD",
    //     position: 9,
    //     utm: "POINT(737866.635686744 9548881.90260662)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:1",
    //     mac: "24:0a:c4:50:fc:50",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 1,
    //     utm: "POINT(737657.150899806 9549199.11195248)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:11",
    //     mac: "c4:4f:33:23:66:99",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 11,
    //     utm: "POINT(737732.069335217 9549141.75352502)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:13",
    //     mac: "c4:4f:33:1d:91:85",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 13,
    //     utm: "POINT(737747.352284061 9549130.48948462)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:15",
    //     mac: "c4:4f:33:23:60:a9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 15,
    //     utm: "POINT(737762.635229686 9549119.22543956)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:2",
    //     mac: "24:0a:c4:50:d3:78",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 2,
    //     utm: "POINT(737663.296025765 9549192.4416491)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:3",
    //     mac: "4c:11:ae:b7:fc:10",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 3,
    //     utm: "POINT(737670.937507885 9549186.80963998)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:4",
    //     mac: "4c:11:ae:b7:fc:d0",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 4,
    //     utm: "POINT(737678.57898909 9549181.17762969)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:5",
    //     mac: "c4:4f:33:23:d3:a5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 5,
    //     utm: "POINT(737686.22046949 9549175.54561823)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:7",
    //     mac: "24:0a:c4:50:d0:2c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 7,
    //     utm: "POINT(737701.503427988 9549164.28159183)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_E:9",
    //     mac: "24:0a:c4:50:cf:2c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_E",
    //     position: 9,
    //     utm: "POINT(737716.786383268 9549153.01756075)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:1",
    //     mac: "24:0a:c4:50:c7:08",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 1,
    //     utm: "POINT(737320.054717278 9549538.69790715)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:10",
    //     mac: "c4:4f:33:23:dc:3d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 10,
    //     utm: "POINT(737381.671900721 9549492.14493391)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:11",
    //     mac: "c4:4f:33:1e:14:c1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 11,
    //     utm: "POINT(737387.842414684 9549486.87501743)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:12",
    //     mac: "c4:4f:33:23:dc:e1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 12,
    //     utm: "POINT(737395.346427649 9549480.8629537)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:13",
    //     mac: "c4:4f:33:1e:c0:89",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 13,
    //     utm: "POINT(737401.439936462 9549476.18601661)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:14",
    //     mac: "c4:4f:33:23:68:b5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 14,
    //     utm: "POINT(737408.799499759 9549470.839644)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:15",
    //     mac: "c4:4f:33:23:9b:e9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 15,
    //     utm: "POINT(737417.421707123 9549463.71403991)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:16",
    //     mac: "c4:4f:33:23:55:b9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 16,
    //     utm: "POINT(737424.705329795 9549458.07194082)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:17",
    //     mac: "4c:11:ae:b7:fd:10",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 17,
    //     utm: "POINT(737431.544551795 9549453.17093751)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:18",
    //     mac: "24:0a:c4:51:0e:5c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 18,
    //     utm: "POINT(737437.637356952 9549447.97587932)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:19",
    //     mac: "4c:11:ae:b7:fc:d4",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 19,
    //     utm: "POINT(737444.624234503 9549442.7785448)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:2",
    //     mac: "4c:11:ae:b7:fb:84",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 2,
    //     utm: "POINT(737326.673750655 9549534.97903498)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:20",
    //     mac: "24:0a:c4:51:09:00",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 20,
    //     utm: "POINT(737452.952235226 9549437.13367231)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:21",
    //     mac: "24:0a:c4:50:f9:20",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 21,
    //     utm: "POINT(737461.872913039 9549430.45125054)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:22",
    //     mac: "c4:4f:33:23:ab:99",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 22,
    //     utm: "POINT(737467.375633201 9549425.55254214)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:23",
    //     mac: "c4:4f:33:23:8c:fd",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 23,
    //     utm: "POINT(737474.652416835 9549419.17161814)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:24",
    //     mac: "c4:4f:33:23:dc:25",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 24,
    //     utm: "POINT(737482.828787022 9549412.63930574)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:25",
    //     mac: "c4:4f:33:23:69:7d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 25,
    //     utm: "POINT(737489.9663382 9549407.73731337)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:26",
    //     mac: "4c:11:ae:b7:fb:f4",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 26,
    //     utm: "POINT(737497.399371582 9549401.79865636)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:27",
    //     mac: "c4:4f:33:1e:bf:f9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 27,
    //     utm: "POINT(737504.248938873 9549397.06501462)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:28",
    //     mac: "4c:11:ae:b7:f8:40",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 28,
    //     utm: "POINT(737509.311225636 9549394.97737186)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:29",
    //     mac: "c4:4f:33:1c:ed:e5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 29,
    //     utm: "POINT(737511.068194064 9549387.26328247)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:3",
    //     mac: "4c:11:ae:b7:fb:d0",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 3,
    //     utm: "POINT(737333.5100663 9549529.48659178)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:30",
    //     mac: "4c:11:ae:b7:fc:cc",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 30,
    //     utm: "POINT(737513.278376217 9549378.08211361)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:4",
    //     mac: "c4:4f:33:23:ae:65",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 4,
    //     utm: "POINT(737341.019362627 9549524.21346586)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:5",
    //     mac: "4c:11:ae:b7:fd:2c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 5,
    //     utm: "POINT(737346.142402811 9549518.87343689)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:6",
    //     mac: "c4:4f:33:23:dc:d1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 6,
    //     utm: "POINT(737353.427924888 9549513.74929198)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:7",
    //     mac: "24:0a:c4:50:db:94",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 7,
    //     utm: "POINT(737360.191225727 9549508.47840588)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:8",
    //     mac: "c4:4f:33:23:9a:f1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 8,
    //     utm: "POINT(737367.177389371 9549502.91106183)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_EAST:9",
    //     mac: "c4:4f:33:23:d3:85",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_EAST",
    //     position: 9,
    //     utm: "POINT(737374.535208552 9549497.1206579)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:1",
    //     mac: "c4:4f:33:23:ad:59",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 1,
    //     utm: "POINT(737360.868952218 9549510.25098583)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:10",
    //     mac: "b4:e6:2d:ac:82:f9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 10,
    //     utm: "POINT(737430.809048253 9549511.31947177)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:12",
    //     mac: "24:0a:c4:50:ce:50",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 12,
    //     utm: "POINT(737446.863363448 9549498.69689723)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:16",
    //     mac: "b4:e6:2d:ab:ca:2d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 16,
    //     utm: "POINT(737474.496339825 9549470.81013823)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:17",
    //     mac: "b4:e6:2d:ad:09:41",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 17,
    //     utm: "POINT(737478.043925339 9549460.44320787)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:19",
    //     mac: "24:0a:c4:50:ca:d0",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 19,
    //     utm: "POINT(737472.038816588 9549442.85263243)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:2",
    //     mac: "24:0a:c4:50:da:c4",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 2,
    //     utm: "POINT(737373.19328568 9549523.4626586)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:20",
    //     mac: "c4:4f:33:23:b0:2d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 20,
    //     utm: "POINT(737467.925134458 9549436.35290356)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:3",
    //     mac: "24:0a:c4:50:d0:4c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 3,
    //     utm: "POINT(737387.515438522 9549531.71043705)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:4",
    //     mac: "4c:11:ae:b7:f4:5c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 4,
    //     utm: "POINT(737406.271460183 9549526.77582525)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:5",
    //     mac: "24:0a:c4:50:e0:64",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 5,
    //     utm: "POINT(737422.630771369 9549516.81706694)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:6",
    //     mac: "b4:e6:2d:98:f2:fd",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 6,
    //     utm: "POINT(737397.640794112 9549530.20252635)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:7",
    //     mac: "4c:11:ae:b7:fa:ac",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 7,
    //     utm: "POINT(737454.889672379 9549492.02103263)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_to_NVD_Ramp:8",
    //     mac: "b4:e6:2d:99:14:8d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_to_NVD_Ramp",
    //     position: 8,
    //     utm: "POINT(737414.749624655 9549522.01767397)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:1",
    //     mac: "b4:e6:2d:ac:63:dd",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 1,
    //     utm: "POINT(737649.704047371 9549201.26575396)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:10",
    //     mac: "24:0a:c4:51:09:48",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 10,
    //     utm: "POINT(737588.174304884 9549254.0311215)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:11",
    //     mac: "24:0a:c4:50:e9:38",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 11,
    //     utm: "POINT(737581.484851297 9549259.2282139)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:12",
    //     mac: "c4:4f:33:23:cd:1d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 12,
    //     utm: "POINT(737574.948685504 9549266.05172717)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:13",
    //     mac: "c4:4f:33:23:9a:e5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 13,
    //     utm: "POINT(737567.738088018 9549271.32370929)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:14",
    //     mac: "c4:4f:33:1e:58:55",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 14,
    //     utm: "POINT(737560.082524306 9549277.33738107)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:15",
    //     mac: "24:0a:c4:50:e6:dc",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 15,
    //     utm: "POINT(737553.020288725 9549282.3870766)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:16",
    //     mac: "c4:4f:33:23:8d:59",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 16,
    //     utm: "POINT(737545.884334244 9549287.73298812)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:17",
    //     mac: "c4:4f:33:1e:b0:95",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 17,
    //     utm: "POINT(737539.419515912 9549293.37264012)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:18",
    //     mac: "c4:4f:33:23:ab:3d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 18,
    //     utm: "POINT(737533.696125871 9549297.7530228)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:19",
    //     mac: "c4:4f:33:1e:a6:e1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 19,
    //     utm: "POINT(737527.52527442 9549301.9125771)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:2",
    //     mac: "b4:e6:2d:ac:16:41",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 2,
    //     utm: "POINT(737641.82310669 9549206.53964868)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:20",
    //     mac: "c4:4f:33:1c:e7:99",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 20,
    //     utm: "POINT(737525.083128351 9549307.83823446)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:21",
    //     mac: "c4:4f:33:23:5f:5d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 21,
    //     utm: "POINT(737523.242917253 9549316.05508734)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:22",
    //     mac: "c4:4f:33:23:b0:e9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 22,
    //     utm: "POINT(737521.625176739 9549323.90178048)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:23",
    //     mac: "4c:11:ae:b7:f5:78",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 23,
    //     utm: "POINT(737520.155598878 9549331.45197677)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:24",
    //     mac: "24:0a:c4:50:d0:84",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 24,
    //     utm: "POINT(737518.164277118 9549338.85571386)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:25",
    //     mac: "c4:4f:33:23:ae:a9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 25,
    //     utm: "POINT(737516.767429664 9549345.73991956)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:26",
    //     mac: "c4:4f:33:23:cd:81",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 26,
    //     utm: "POINT(737514.850699869 9549353.05235367)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:3",
    //     mac: "4c:11:ae:b7:f2:f8",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 3,
    //     utm: "POINT(737634.985098481 9549211.88427933)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:4",
    //     mac: "c4:4f:33:23:67:99",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 4,
    //     utm: "POINT(737629.03926447 9549216.63528639)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:5",
    //     mac: "24:0a:c4:50:d2:40",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 5,
    //     utm: "POINT(737621.157791224 9549221.68645897)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:6",
    //     mac: "c4:4f:33:23:67:d5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 6,
    //     utm: "POINT(737614.169228825 9549226.44027587)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:7",
    //     mac: "c4:4f:33:23:7c:55",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 7,
    //     utm: "POINT(737607.782421987 9549233.41169597)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:8",
    //     mac: "4c:11:ae:b7:f7:78",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 8,
    //     utm: "POINT(737601.693521095 9549240.38224144)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_W:9",
    //     mac: "c4:4f:33:23:b0:ad",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_W",
    //     position: 9,
    //     utm: "POINT(737594.709298622 9549246.76370342)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:1",
    //     mac: "c4:4f:33:23:ce:d9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 1,
    //     utm: "POINT(737312.465103107 9549541.52180387)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:10",
    //     mac: "b4:e6:2d:ac:47:9d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 10,
    //     utm: "POINT(737246.703570659 9549571.59211217)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:11",
    //     mac: "c4:4f:33:23:ac:d1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 11,
    //     utm: "POINT(737238.075967969 9549576.20251598)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:12",
    //     mac: "b4:e6:2d:ac:19:7d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 12,
    //     utm: "POINT(737229.741577328 9549579.03688528)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:14",
    //     mac: "24:0a:c4:50:c9:14",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 14,
    //     utm: "POINT(737212.186088038 9549587.37070968)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:2",
    //     mac: "c4:4f:33:23:5f:51",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 2,
    //     utm: "POINT(737304.132814951 9549544.95529524)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:3",
    //     mac: "c4:4f:33:23:57:cd",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 3,
    //     utm: "POINT(737297.436405249 9549547.78209335)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:4",
    //     mac: "c4:4f:33:23:54:a1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 4,
    //     utm: "POINT(737291.188703262 9549551.05393343)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:5",
    //     mac: "c4:4f:33:1e:39:ed",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 5,
    //     utm: "POINT(737284.493303665 9549554.03131169)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:6",
    //     mac: "c4:4f:33:23:d3:8d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 6,
    //     utm: "POINT(737277.054291429 9549557.45448462)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:7",
    //     mac: "c4:4f:33:23:6a:85",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 7,
    //     utm: "POINT(737269.913591492 9549561.02475234)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:8",
    //     mac: "24:0a:c4:50:e7:68",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 8,
    //     utm: "POINT(737262.47500063 9549564.59564275)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD_ACC_WEST:9",
    //     mac: "c4:4f:33:23:69:69",
    //     area: "DMLZ_Extraction",
    //     location: "NFD_ACC_WEST",
    //     position: 9,
    //     utm: "POINT(737254.887016147 9549568.0192081)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:1",
    //     mac: "b4:e6:2d:98:f0:d1",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 1,
    //     utm: "POINT(737864.723234197 9548890.93222555)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:10",
    //     mac: "24:0a:c4:50:cf:ac",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 10,
    //     utm: "POINT(737816.106847818 9549038.13134143)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:11",
    //     mac: "b4:e6:2d:ac:c4:e5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 11,
    //     utm: "POINT(737808.945873 9549062.19531828)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:12",
    //     mac: "c4:4f:33:23:60:91",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 12,
    //     utm: "POINT(737811.382429112 9549054.05055308)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:13",
    //     mac: "b4:e6:2d:ad:8e:7d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 13,
    //     utm: "POINT(737795.405621808 9549095.82025311)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:14",
    //     mac: "c4:4f:33:23:61:05",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 14,
    //     utm: "POINT(737805.618828661 9549071.3042493)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:15",
    //     mac: "b4:e6:2d:98:a4:cd",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 15,
    //     utm: "POINT(737770.276701235 9549113.59341529)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:16",
    //     mac: "c4:4f:33:23:7c:fd",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 16,
    //     utm: "POINT(737800.152160778 9549088.18723554)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:17",
    //     mac: "b4:e6:2d:ac:5a:15",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 17,
    //     utm: "POINT(737739.710810098 9549136.1215054)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:18",
    //     mac: "24:0a:c4:50:d4:1c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 18,
    //     utm: "POINT(737787.377399246 9549101.68636889)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:19",
    //     mac: "b4:e6:2d:98:a5:c9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 19,
    //     utm: "POINT(737709.144905977 9549158.64957687)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:2",
    //     mac: "24:0a:c4:51:05:3c",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 2,
    //     utm: "POINT(737838.197092111 9548970.67281677)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:20",
    //     mac: "b4:e6:2d:ad:9a:e5",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 20,
    //     utm: "POINT(737693.861949195 9549169.91360561)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:08",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:3",
    //     mac: "b4:e6:2d:aa:fa:c9",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 3,
    //     utm: "POINT(737853.497730533 9548926.75867563)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:4",
    //     mac: "c4:4f:33:1c:f6:3d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 4,
    //     utm: "POINT(737832.949752843 9548986.00153769)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:5",
    //     mac: "b4:e6:2d:99:11:95",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 5,
    //     utm: "POINT(737841.966047991 9548959.56513782)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:6",
    //     mac: "c4:4f:33:23:ce:8d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 6,
    //     utm: "POINT(737826.743813186 9549004.95804416)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:7",
    //     mac: "b4:e6:2d:ac:56:9d",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 7,
    //     utm: "POINT(737830.291892228 9548994.81274739)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:8",
    //     mac: "c4:4f:33:23:cd:91",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 8,
    //     utm: "POINT(737821.130167026 9549022.58125846)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NFD:9",
    //     mac: "b4:e6:2d:ab:f7:59",
    //     area: "DMLZ_Extraction",
    //     location: "NFD",
    //     position: 9,
    //     utm: "POINT(737819.212576692 9549029.68886028)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:1",
    //     mac: "c4:4f:33:23:b2:c1",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 1,
    //     utm: "POINT(737267.963047779 9549555.70176734)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:2",
    //     mac: "c4:4f:33:1d:2d:79",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 2,
    //     utm: "POINT(737256.553854224 9549541.18334245)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:3",
    //     mac: "c4:4f:33:23:d0:b5",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 3,
    //     utm: "POINT(737245.144663994 9549526.664918)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:4",
    //     mac: "c4:4f:33:23:60:2d",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 4,
    //     utm: "POINT(737233.735476871 9549512.146494)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:5",
    //     mac: "c4:4f:33:23:63:b1",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 5,
    //     utm: "POINT(737222.326293074 9549497.62807044)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:6",
    //     mac: "24:0a:c4:50:d0:cc",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 6,
    //     utm: "POINT(737210.917112489 9549483.10964733)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:NU_35_4:7",
    //     mac: "c4:4f:33:23:5e:c9",
    //     area: "DMLZ_Extraction",
    //     location: "NU_35_4",
    //     position: 7,
    //     utm: "POINT(737199.507935012 9549468.59122466)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:1",
    //     mac: "4c:11:ae:b7:fc:8c",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 1,
    //     utm: "POINT(737662.335710436 9548629.63031513)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:11",
    //     mac: "c4:4f:33:23:67:a1",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 11,
    //     utm: "POINT(737579.051644087 9548623.85843066)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:13",
    //     mac: "24:0a:c4:50:c8:34",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 13,
    //     utm: "POINT(737560.29308216 9548627.75573703)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:2",
    //     mac: "24:0a:c4:50:ce:8c",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 2,
    //     utm: "POINT(737655.259375637 9548629.35261564)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:3",
    //     mac: "24:0a:c4:51:0e:60",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 3,
    //     utm: "POINT(737647.735971716 9548629.00260963)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:4",
    //     mac: "c4:4f:33:23:63:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 4,
    //     utm: "POINT(737639.093585962 9548627.98949523)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:5",
    //     mac: "c4:4f:33:23:af:b9",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 5,
    //     utm: "POINT(737631.198351778 9548627.86285256)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:7",
    //     mac: "c4:4f:33:23:7c:89",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 7,
    //     utm: "POINT(737615.99901221 9548625.83172568)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P_NU:9",
    //     mac: "4c:11:ae:b7:f5:18",
    //     area: "DMLZ_Extraction",
    //     location: "P_NU",
    //     position: 9,
    //     utm: "POINT(737596.034149211 9548624.2570434)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:1",
    //     mac: "b4:e6:2d:99:05:81",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 1,
    //     utm: "POINT(737476.950648547 9548712.61468361)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:10",
    //     mac: "b4:e6:2d:99:10:89",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 10,
    //     utm: "POINT(737588.148542129 9548851.70514315)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:11",
    //     mac: "b4:e6:2d:98:fd:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 11,
    //     utm: "POINT(737602.797001788 9548870.16224922)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:12",
    //     mac: "b4:e6:2d:98:f7:25",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 12,
    //     utm: "POINT(737614.605466873 9548885.51957248)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:13",
    //     mac: "b4:e6:2d:99:11:59",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 13,
    //     utm: "POINT(737626.560614624 9548899.98869854)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:2",
    //     mac: "b4:e6:2d:98:a8:75",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 2,
    //     utm: "POINT(737491.150156961 9548729.88920423)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:3",
    //     mac: "b4:e6:2d:ac:4d:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 3,
    //     utm: "POINT(737501.487731269 9548743.6844395)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:4",
    //     mac: "b4:e6:2d:99:04:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 4,
    //     utm: "POINT(737513.867287789 9548758.53463396)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:5",
    //     mac: "b4:e6:2d:ac:59:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 5,
    //     utm: "POINT(737525.224284981 9548772.26548464)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:6",
    //     mac: "b4:e6:2d:ac:4e:f5",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 6,
    //     utm: "POINT(737541.367828341 9548792.49421549)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:7",
    //     mac: "b4:e6:2d:ac:48:41",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 7,
    //     utm: "POINT(737553.323741132 9548807.25925796)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:8",
    //     mac: "b4:e6:2d:99:13:69",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 8,
    //     utm: "POINT(737564.234339875 9548821.28724043)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10:9",
    //     mac: "b4:e6:2d:ad:8f:01",
    //     area: "DMLZ_Extraction",
    //     location: "P10",
    //     position: 9,
    //     utm: "POINT(737576.191832508 9548836.64415747)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:1",
    //     mac: "b4:e6:2d:98:f2:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 1,
    //     utm: "POINT(737782.30308247 9549098.00063763)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:10",
    //     mac: "b4:e6:2d:99:04:a1",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 10,
    //     utm: "POINT(737366.471814587 9549516.59707753)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:2",
    //     mac: "b4:e6:2d:ad:9e:81",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 2,
    //     utm: "POINT(737773.033121559 9549084.11668973)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:3",
    //     mac: "b4:e6:2d:98:a2:45",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 3,
    //     utm: "POINT(737760.775417538 9549068.7602798)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:4",
    //     mac: "b4:e6:2d:98:90:4d",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 4,
    //     utm: "POINT(737749.419223801 9549055.62091791)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:5",
    //     mac: "b4:e6:2d:98:9a:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 5,
    //     utm: "POINT(737738.505791646 9549042.47972028)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:6",
    //     mac: "b4:e6:2d:98:f5:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 6,
    //     utm: "POINT(737726.25059332 9549025.34972922)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:7",
    //     mac: "b4:e6:2d:99:8f:75",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 7,
    //     utm: "POINT(737716.385413772 9549012.50150745)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:8",
    //     mac: "b4:e6:2d:aa:fa:d1",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 8,
    //     utm: "POINT(737703.225794553 9548995.6708559)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P10N:9",
    //     mac: "b4:e6:2d:98:fd:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P10N",
    //     position: 9,
    //     utm: "POINT(737690.226963171 9548981.35542716)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:1",
    //     mac: "b4:e6:2d:99:08:e9",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 1,
    //     utm: "POINT(737459.739208008 9548737.66784354)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:10",
    //     mac: "b4:e6:2d:98:f3:a1",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 10,
    //     utm: "POINT(737564.361435593 9548869.22862312)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:11",
    //     mac: "b4:e6:2d:99:05:89",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 11,
    //     utm: "POINT(737575.871579155 9548884.43877218)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:2",
    //     mac: "b4:e6:2d:aa:fb:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 2,
    //     utm: "POINT(737468.858713744 9548750.07187028)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:3",
    //     mac: "b4:e6:2d:98:a6:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 3,
    //     utm: "POINT(737479.4709812 9548763.65634332)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:4",
    //     mac: "b4:e6:2d:ac:4d:91",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 4,
    //     utm: "POINT(737490.228360001 9548776.64956029)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:5",
    //     mac: "b4:e6:2d:99:14:81",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 5,
    //     utm: "POINT(737503.531196684 9548793.33460794)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:6",
    //     mac: "b4:e6:2d:ad:8f:05",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 6,
    //     utm: "POINT(737515.487656733 9548808.09965089)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:7",
    //     mac: "b4:e6:2d:ac:57:79",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 7,
    //     utm: "POINT(737527.593866103 9548823.16023521)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:8",
    //     mac: "b4:e6:2d:ac:46:41",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 8,
    //     utm: "POINT(737539.850297458 9548838.66432807)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11:9",
    //     mac: "b4:e6:2d:8e:a8:31",
    //     area: "DMLZ_Extraction",
    //     location: "P11",
    //     position: 9,
    //     utm: "POINT(737552.105946192 9548853.87248968)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11N:1",
    //     mac: "b4:e6:2d:ac:48:0d",
    //     area: "DMLZ_Extraction",
    //     location: "P11N",
    //     position: 1,
    //     utm: "POINT(737759.113785739 9549116.11448844)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11N:3",
    //     mac: "b4:e6:2d:ad:9b:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P11N",
    //     position: 3,
    //     utm: "POINT(737738.184454593 9549089.53572932)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11N:4",
    //     mac: "b4:e6:2d:99:03:bd",
    //     area: "DMLZ_Extraction",
    //     location: "P11N",
    //     position: 4,
    //     utm: "POINT(737726.825127253 9549074.47310597)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11N:5",
    //     mac: "4c:11:ae:b7:fa:4c",
    //     area: "DMLZ_Extraction",
    //     location: "P11N",
    //     position: 5,
    //     utm: "POINT(737715.470364097 9549061.18609865)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11N:6",
    //     mac: "b4:e6:2d:aa:54:25",
    //     area: "DMLZ_Extraction",
    //     location: "P11N",
    //     position: 6,
    //     utm: "POINT(737701.121604208 9549042.8761153)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P11N:7",
    //     mac: "b4:e6:2d:99:13:75",
    //     area: "DMLZ_Extraction",
    //     location: "P11N",
    //     position: 7,
    //     utm: "POINT(737686.775061377 9549025.45394262)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12:1",
    //     mac: "b4:e6:2d:ac:f9:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P12",
    //     position: 1,
    //     utm: "POINT(737443.578353406 9548766.56371398)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12:2",
    //     mac: "b4:e6:2d:ad:9b:59",
    //     area: "DMLZ_Extraction",
    //     location: "P12",
    //     position: 2,
    //     utm: "POINT(737456.425928255 9548780.1421971)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12N:1",
    //     mac: "c4:4f:33:23:5f:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P12N",
    //     position: 1,
    //     utm: "POINT(737732.258890721 9549130.39088346)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12N:2",
    //     mac: "c4:4f:33:23:63:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P12N",
    //     position: 2,
    //     utm: "POINT(737723.371390508 9549120.64855947)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12N:3",
    //     mac: "24:0a:c4:50:d0:94",
    //     area: "DMLZ_Extraction",
    //     location: "P12N",
    //     position: 3,
    //     utm: "POINT(737712.786474227 9549107.35949603)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12N:4",
    //     mac: "c4:4f:33:23:7b:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P12N",
    //     position: 4,
    //     utm: "POINT(737701.394226976 9549089.92945437)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12N:5",
    //     mac: "c4:4f:33:1e:b8:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P12N",
    //     position: 5,
    //     utm: "POINT(737688.813270685 9549074.27820128)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P12N:6",
    //     mac: "4c:11:ae:b7:f3:98",
    //     area: "DMLZ_Extraction",
    //     location: "P12N",
    //     position: 6,
    //     utm: "POINT(737676.235028108 9549059.51475657)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:1",
    //     mac: "b4:e6:2d:98:f1:31",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 1,
    //     utm: "POINT(737429.578511678 9548795.16040332)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:10",
    //     mac: "b4:e6:2d:ac:49:21",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 10,
    //     utm: "POINT(737554.455514192 9548953.44895166)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:2",
    //     mac: "b4:e6:2d:98:a6:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 2,
    //     utm: "POINT(737441.685477248 9548810.66544484)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:4",
    //     mac: "b4:e6:2d:ad:96:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 4,
    //     utm: "POINT(737474.416859502 9548851.71122732)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:5",
    //     mac: "b4:e6:2d:98:a6:d1",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 5,
    //     utm: "POINT(737490.337643237 9548872.97655165)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:6",
    //     mac: "b4:e6:2d:ab:eb:81",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 6,
    //     utm: "POINT(737505.28693634 9548892.03531135)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:7",
    //     mac: "b4:e6:2d:99:10:75",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 7,
    //     utm: "POINT(737517.988179092 9548907.89130289)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:8",
    //     mac: "b4:e6:2d:99:a5:05",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 8,
    //     utm: "POINT(737529.34584443 9548921.69691803)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13:9",
    //     mac: "b4:e6:2d:ac:49:bd",
    //     area: "DMLZ_Extraction",
    //     location: "P13",
    //     position: 9,
    //     utm: "POINT(737543.394583117 9548939.19772454)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:1",
    //     mac: "c4:4f:33:23:65:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 1,
    //     utm: "POINT(737710.643858737 9549150.72093939)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:10",
    //     mac: "c4:4f:33:23:ce:05",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 10,
    //     utm: "POINT(737614.041491936 9549029.19042354)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:11",
    //     mac: "c4:4f:33:23:70:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 11,
    //     utm: "POINT(737615.284969553 9549029.63908617)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:12",
    //     mac: "24:0a:c4:50:ff:dc",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 12,
    //     utm: "POINT(737603.029380792 9549014.28291652)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:13",
    //     mac: "b4:e6:2d:98:f0:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 13,
    //     utm: "POINT(737589.578579796 9548997.59820889)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:14",
    //     mac: "c4:4f:33:23:7a:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 14,
    //     utm: "POINT(737578.070156004 9548983.12787164)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:15",
    //     mac: "c4:4f:33:23:67:61",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 15,
    //     utm: "POINT(737566.563700966 9548969.39770026)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:2",
    //     mac: "24:0a:c4:50:e4:44",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 2,
    //     utm: "POINT(737703.037175765 9549140.56742842)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:22",
    //     mac: "c4:4f:33:1d:df:39",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 22,
    //     utm: "POINT(737457.379431298 9548830.67649854)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:3",
    //     mac: "c4:4f:33:23:67:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 3,
    //     utm: "POINT(737692.423448207 9549127.31543376)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:4",
    //     mac: "24:0a:c4:50:cf:d8",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 4,
    //     utm: "POINT(737681.809723417 9549114.06343945)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:5",
    //     mac: "c4:4f:33:1e:b7:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 5,
    //     utm: "POINT(737671.196001389 9549100.8114455)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:6",
    //     mac: "24:0a:c4:50:cf:80",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 6,
    //     utm: "POINT(737660.582282123 9549087.5594519)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:7",
    //     mac: "24:0a:c4:50:e7:88",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 7,
    //     utm: "POINT(737649.968565623 9549074.30745866)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:8",
    //     mac: "c4:4f:33:23:b7:19",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 8,
    //     utm: "POINT(737640.995700527 9549063.30762127)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P13N:9",
    //     mac: "c4:4f:33:1d:26:d1",
    //     area: "DMLZ_Extraction",
    //     location: "P13N",
    //     position: 9,
    //     utm: "POINT(737628.741140924 9549047.80347326)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:1",
    //     mac: "24:0a:c4:50:c7:90",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 1,
    //     utm: "POINT(737411.549943632 9548821.69291789)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:2f:75",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:10",
    //     mac: "b4:e6:2d:99:12:31",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 10,
    //     utm: "POINT(737580.732185088 9549032.22875932)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:11",
    //     mac: "b4:e6:2d:ac:4c:51",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 11,
    //     utm: "POINT(737566.985824609 9549016.293141)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:12",
    //     mac: "b4:e6:2d:99:12:41",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 12,
    //     utm: "POINT(737553.830583063 9548998.58099749)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:13",
    //     mac: "b4:e6:2d:ac:0b:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 13,
    //     utm: "POINT(737541.579782446 9548985.00121892)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:2",
    //     mac: "c4:4f:33:23:b0:99",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 2,
    //     utm: "POINT(737423.773754297 9548837.09142812)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:2f:75",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:3",
    //     mac: "b4:e6:2d:ac:48:31",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 3,
    //     utm: "POINT(737435.997568759 9548852.48993883)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:2f:75",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:5",
    //     mac: "b4:e6:2d:ac:4e:e5",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 5,
    //     utm: "POINT(737642.315955932 9549111.9649083)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:6",
    //     mac: "b4:e6:2d:aa:fb:11",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 6,
    //     utm: "POINT(737629.757782432 9549095.3950596)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:7",
    //     mac: "b4:e6:2d:ac:4e:ed",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 7,
    //     utm: "POINT(737618.399444324 9549081.22820959)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:8",
    //     mac: "b4:e6:2d:ac:4e:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 8,
    //     utm: "POINT(737605.248910853 9549065.29116389)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14:9",
    //     mac: "b4:e6:2d:ac:46:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P14",
    //     position: 9,
    //     utm: "POINT(737592.694202678 9549049.35237255)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14N:1",
    //     mac: "24:0a:c4:50:d0:98",
    //     area: "DMLZ_Extraction",
    //     location: "P14N",
    //     position: 1,
    //     utm: "POINT(737687.310379662 9549170.83229478)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14N:2",
    //     mac: "c4:4f:33:23:b0:69",
    //     area: "DMLZ_Extraction",
    //     location: "P14N",
    //     position: 2,
    //     utm: "POINT(737678.061950812 9549157.74515666)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14N:3",
    //     mac: "c4:4f:33:1d:fe:61",
    //     area: "DMLZ_Extraction",
    //     location: "P14N",
    //     position: 3,
    //     utm: "POINT(737666.575405493 9549143.25869711)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P14N:4",
    //     mac: "c4:4f:33:23:67:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P14N",
    //     position: 4,
    //     utm: "POINT(737655.08886354 9549128.77223799)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15:1",
    //     mac: "b4:e6:2d:ac:48:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P15",
    //     position: 1,
    //     utm: "POINT(737399.870197393 9548854.72082997)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:2f:75",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15:2",
    //     mac: "b4:e6:2d:98:9a:65",
    //     area: "DMLZ_Extraction",
    //     location: "P15",
    //     position: 2,
    //     utm: "POINT(737411.902002181 9548869.96660292)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:2f:75",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:1",
    //     mac: "c4:4f:33:23:5f:f9",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 1,
    //     utm: "POINT(737662.176447044 9549186.06611611)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:10",
    //     mac: "c4:4f:33:1e:a7:11",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 10,
    //     utm: "POINT(737559.195706612 9549055.39464197)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:11",
    //     mac: "24:0a:c4:50:ce:80",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 11,
    //     utm: "POINT(737544.401436801 9549037.53136012)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:12",
    //     mac: "c4:4f:33:23:70:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 12,
    //     utm: "POINT(737532.592797337 9549022.17516028)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:13",
    //     mac: "b4:e6:2d:98:f1:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 13,
    //     utm: "POINT(737519.590189211 9549005.79536241)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:15",
    //     mac: "b4:e6:2d:98:f0:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 15,
    //     utm: "POINT(737495.075680969 9548974.32215234)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:2",
    //     mac: "24:0a:c4:50:cf:ec",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 2,
    //     utm: "POINT(737654.102714482 9549174.84188796)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:3",
    //     mac: "24:0a:c4:50:cf:b0",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 3,
    //     utm: "POINT(737643.494306205 9549162.73629171)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:4",
    //     mac: "c4:4f:33:23:60:31",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 4,
    //     utm: "POINT(737631.690696727 9549149.3015428)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:5",
    //     mac: "c4:4f:33:23:67:91",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 5,
    //     utm: "POINT(737618.461833939 9549132.02811589)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:6",
    //     mac: "c4:4f:33:23:65:85",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 6,
    //     utm: "POINT(737606.57857521 9549116.60023634)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:7",
    //     mac: "c4:4f:33:1d:25:e1",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 7,
    //     utm: "POINT(737594.176198056 9549102.05882456)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:8",
    //     mac: "c4:4f:33:1e:a4:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 8,
    //     utm: "POINT(737572.496363905 9549071.91876372)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P15N:9",
    //     mac: "b4:e6:2d:ad:9a:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P15N",
    //     position: 9,
    //     utm: "POINT(737507.48453109 9548991.30560471)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:1",
    //     mac: "4c:11:ae:b7:f6:14",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 1,
    //     utm: "POINT(737376.683777367 9548874.46216775)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:2f:75",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:11",
    //     mac: "c4:4f:33:1e:51:f9",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 11,
    //     utm: "POINT(737508.355084301 9549038.65251332)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:12",
    //     mac: "24:0a:c4:51:0f:20",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 12,
    //     utm: "POINT(737496.165843611 9549024.89997308)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:13",
    //     mac: "24:0a:c4:50:ff:94",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 13,
    //     utm: "POINT(737541.21152016 9549081.00744524)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:14",
    //     mac: "24:0a:c4:50:cd:d4",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 14,
    //     utm: "POINT(737531.672163588 9549068.61562194)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:2",
    //     mac: "24:0a:c4:50:db:70",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 2,
    //     utm: "POINT(737469.195622587 9548989.03936562)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:3",
    //     mac: "24:0a:c4:51:0e:6c",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 3,
    //     utm: "POINT(737399.24917899 9548901.33284749)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:4",
    //     mac: "4c:11:ae:b7:f6:08",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 4,
    //     utm: "POINT(737414.494118684 9548920.52719258)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:5",
    //     mac: "c4:4f:33:1d:c2:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 5,
    //     utm: "POINT(737430.036279971 9548939.72244799)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:6",
    //     mac: "c4:4f:33:23:9b:31",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 6,
    //     utm: "POINT(737444.980870234 9548957.73489663)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16:7",
    //     mac: "c4:4f:33:24:25:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P16",
    //     position: 7,
    //     utm: "POINT(737456.640514705 9548973.09564076)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:1",
    //     mac: "c4:4f:33:23:5f:01",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 1,
    //     utm: "POINT(737637.196329383 9549203.14836342)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:12",
    //     mac: "b4:e6:2d:99:11:51",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 12,
    //     utm: "POINT(737509.267200666 9549044.78498888)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:14",
    //     mac: "24:0a:c4:51:0e:14",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 14,
    //     utm: "POINT(737482.950200883 9549007.94292769)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:3",
    //     mac: "c4:4f:33:23:68:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 3,
    //     utm: "POINT(737619.411939931 9549181.29683111)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:4",
    //     mac: "c4:4f:33:1e:bf:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 4,
    //     utm: "POINT(737607.310668867 9549167.68876105)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:5",
    //     mac: "4c:11:ae:b7:f5:54",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 5,
    //     utm: "POINT(737596.544811865 9549152.32182183)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:6",
    //     mac: "c4:4f:33:23:7d:51",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 6,
    //     utm: "POINT(737583.985697754 9549135.22925921)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:7",
    //     mac: "c4:4f:33:23:cb:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 7,
    //     utm: "POINT(737572.03120689 9549121.05027679)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:8",
    //     mac: "c4:4f:33:23:71:15",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 8,
    //     utm: "POINT(737558.878744419 9549104.51388896)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P16N:9",
    //     mac: "4c:11:ae:b7:fa:c0",
    //     area: "DMLZ_Extraction",
    //     location: "P16N",
    //     position: 9,
    //     utm: "POINT(737546.023401954 9549087.67908364)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:1",
    //     mac: "c4:4f:33:23:ce:11",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 1,
    //     utm: "POINT(737352.149373783 9548890.80654894)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:10",
    //     mac: "24:0a:c4:50:e7:0c",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 10,
    //     utm: "POINT(737448.58220018 9549012.53288425)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:11",
    //     mac: "b4:e6:2d:aa:54:31",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 11,
    //     utm: "POINT(737459.462716669 9549026.15654427)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:12",
    //     mac: "b4:e6:2d:ab:ca:59",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 12,
    //     utm: "POINT(737470.343236174 9549039.78020466)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:13",
    //     mac: "b4:e6:2d:ab:ca:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 13,
    //     utm: "POINT(737481.223758592 9549053.40386543)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:14",
    //     mac: "24:0a:c4:50:dd:f4",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 14,
    //     utm: "POINT(737492.104283916 9549067.02752658)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:15",
    //     mac: "b4:e6:2d:ad:09:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 15,
    //     utm: "POINT(737502.984812041 9549080.65118812)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:16",
    //     mac: "24:0a:c4:50:e6:e8",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 16,
    //     utm: "POINT(737513.865343184 9549094.27485003)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:2",
    //     mac: "24:0a:c4:50:d1:dc",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 2,
    //     utm: "POINT(737364.554986729 9548906.6017653)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:3",
    //     mac: "c4:4f:33:1d:f5:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 3,
    //     utm: "POINT(737375.541387423 9548920.84752258)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:4",
    //     mac: "c4:4f:33:1e:be:81",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 4,
    //     utm: "POINT(737387.050514871 9548935.60963834)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:5",
    //     mac: "b4:e6:2d:a3:85:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 5,
    //     utm: "POINT(737394.179660948 9548944.41458983)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:6",
    //     mac: "b4:e6:2d:ab:ca:81",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 6,
    //     utm: "POINT(737405.060162995 9548958.03824796)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:7",
    //     mac: "b4:e6:2d:ad:08:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 7,
    //     utm: "POINT(737415.940667957 9548971.66190646)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:8",
    //     mac: "b4:e6:2d:ad:08:51",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 8,
    //     utm: "POINT(737426.821175714 9548985.28556535)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17:9",
    //     mac: "b4:e6:2d:ab:ca:71",
    //     area: "DMLZ_Extraction",
    //     location: "P17",
    //     position: 9,
    //     utm: "POINT(737437.701686491 9548998.90922461)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:1",
    //     mac: "b4:e6:2d:98:f1:61",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 1,
    //     utm: "POINT(737613.709762171 9549221.70673937)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:2",
    //     mac: "b4:e6:2d:ad:0a:29",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 2,
    //     utm: "POINT(737603.042220111 9549208.36238866)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:3",
    //     mac: "c4:4f:33:1d:b2:95",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 3,
    //     utm: "POINT(737592.374680842 9549195.01803832)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:4",
    //     mac: "24:0a:c4:50:e7:08",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 4,
    //     utm: "POINT(737581.70714437 9549181.67368834)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:5",
    //     mac: "24:0a:c4:51:0f:0c",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 5,
    //     utm: "POINT(737571.039610692 9549168.32933872)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:6",
    //     mac: "b4:e6:2d:ad:0b:89",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 6,
    //     utm: "POINT(737560.372079697 9549154.98498947)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:7",
    //     mac: "b4:e6:2d:ab:fd:05",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 7,
    //     utm: "POINT(737549.70455161 9549141.64064058)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:8",
    //     mac: "b4:e6:2d:a4:9b:a1",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 8,
    //     utm: "POINT(737539.037026314 9549128.29629205)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P17N:9",
    //     mac: "c4:4f:33:23:7c:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P17N",
    //     position: 9,
    //     utm: "POINT(737528.369503816 9549114.95194388)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:1",
    //     mac: "c4:4f:33:23:8d:85",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 1,
    //     utm: "POINT(737324.183160763 9548905.08299883)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:10",
    //     mac: "b4:e6:2d:ad:08:71",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 10,
    //     utm: "POINT(737416.458503443 9549020.7858298)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:11",
    //     mac: "b4:e6:2d:ad:09:a1",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 11,
    //     utm: "POINT(737426.71133216 9549033.6417016)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:12",
    //     mac: "b4:e6:2d:ad:0a:15",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 12,
    //     utm: "POINT(737436.964163574 9549046.49757373)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:13",
    //     mac: "b4:e6:2d:98:f7:3d",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 13,
    //     utm: "POINT(737447.216997574 9549059.3534462)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:14",
    //     mac: "b4:e6:2d:98:a8:45",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 14,
    //     utm: "POINT(737457.469834046 9549072.20931901)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:15",
    //     mac: "b4:e6:2d:ad:09:49",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 15,
    //     utm: "POINT(737467.722673212 9549085.06519216)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:16",
    //     mac: "24:0a:c4:50:cb:08",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 16,
    //     utm: "POINT(737479.622716667 9549101.17233581)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:2",
    //     mac: "24:0a:c4:51:06:c4",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 2,
    //     utm: "POINT(737334.643255556 9548917.48455741)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:3",
    //     mac: "c4:4f:33:23:ae:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 3,
    //     utm: "POINT(737344.688774362 9548930.79473668)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:4",
    //     mac: "4c:11:ae:b7:fb:90",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 4,
    //     utm: "POINT(737354.941585091 9548943.65060612)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:5",
    //     mac: "c4:4f:33:23:af:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 5,
    //     utm: "POINT(737366.030427829 9548957.20588923)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:6",
    //     mac: "c4:4f:33:23:57:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 6,
    //     utm: "POINT(737376.046477467 9548970.64454692)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:7",
    //     mac: "c4:4f:33:23:70:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 7,
    //     utm: "POINT(737384.866114953 9548982.31045179)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:8",
    //     mac: "c4:4f:33:23:ae:79",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 8,
    //     utm: "POINT(737395.952853653 9548995.07408723)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18:9",
    //     mac: "c4:4f:33:23:b0:bd",
    //     area: "DMLZ_Extraction",
    //     location: "P18",
    //     position: 9,
    //     utm: "POINT(737214.963811696 9548891.46364874)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:1",
    //     mac: "c4:4f:33:1e:a8:19",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 1,
    //     utm: "POINT(737592.163601966 9549241.73976869)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:2",
    //     mac: "24:0a:c4:50:ce:08",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 2,
    //     utm: "POINT(737580.985849195 9549227.58558087)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:3",
    //     mac: "24:0a:c4:50:e4:78",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 3,
    //     utm: "POINT(737569.808099616 9549213.43139348)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:4",
    //     mac: "b4:e6:2d:99:08:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 4,
    //     utm: "POINT(737558.63035301 9549199.2772065)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:5",
    //     mac: "b4:e6:2d:ab:ca:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 5,
    //     utm: "POINT(737547.452609594 9549185.12301995)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:6",
    //     mac: "c4:4f:33:23:ad:89",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 6,
    //     utm: "POINT(737536.274869148 9549170.96883381)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:7",
    //     mac: "c4:4f:33:23:71:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 7,
    //     utm: "POINT(737525.097131899 9549156.8146481)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P18N:8",
    //     mac: "c4:4f:33:23:7c:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P18N",
    //     position: 8,
    //     utm: "POINT(737513.919397625 9549142.6604628)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:1",
    //     mac: "c4:4f:33:1d:92:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 1,
    //     utm: "POINT(737299.500992481 9548922.16445425)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:10",
    //     mac: "24:0a:c4:50:fc:28",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 10,
    //     utm: "POINT(737406.737314993 9549056.68174741)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:11",
    //     mac: "c4:4f:33:1d:9b:69",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 11,
    //     utm: "POINT(737417.386750657 9549072.25122909)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:12",
    //     mac: "c4:4f:33:23:cf:0d",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 12,
    //     utm: "POINT(737429.354304606 9549087.33337813)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:13",
    //     mac: "c4:4f:33:1e:be:21",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 13,
    //     utm: "POINT(737441.321861976 9549102.41552765)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:14",
    //     mac: "c4:4f:33:23:5e:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 14,
    //     utm: "POINT(737453.28942287 9549117.49767764)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:15",
    //     mac: "c4:4f:33:23:cf:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 15,
    //     utm: "POINT(737465.256987405 9549132.5798281)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:2",
    //     mac: "4c:11:ae:b7:fc:08",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 2,
    //     utm: "POINT(737309.885713221 9548934.19794097)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:3",
    //     mac: "c4:4f:33:23:7c:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 3,
    //     utm: "POINT(737321.469150959 9548948.8902723)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:4",
    //     mac: "24:0a:c4:50:d0:1c",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 4,
    //     utm: "POINT(737331.261782074 9548962.33027142)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:5",
    //     mac: "4c:11:ae:b7:fd:64",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 5,
    //     utm: "POINT(737342.992417195 9548976.35668109)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:6",
    //     mac: "b4:e6:2d:ab:ca:11",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 6,
    //     utm: "POINT(737354.201314804 9548990.23659017)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:7",
    //     mac: "b4:e6:2d:aa:ed:ed",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 7,
    //     utm: "POINT(737374.673131109 9549014.445328)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:8",
    //     mac: "b4:e6:2d:ab:ca:45",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 8,
    //     utm: "POINT(737386.035545027 9549030.09773075)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19:9",
    //     mac: "24:0a:c4:50:ce:f4",
    //     area: "DMLZ_Extraction",
    //     location: "P19",
    //     position: 9,
    //     utm: "POINT(737395.378448056 9549042.4310599)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:1",
    //     mac: "4c:11:ae:b7:fa:04",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 1,
    //     utm: "POINT(737570.173678114 9549262.95748149)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:2",
    //     mac: "c4:4f:33:23:71:55",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 2,
    //     utm: "POINT(737560.906288227 9549250.99540132)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:3",
    //     mac: "c4:4f:33:23:64:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 3,
    //     utm: "POINT(737549.251645295 9549237.56053411)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:4",
    //     mac: "c4:4f:33:23:7c:69",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 4,
    //     utm: "POINT(737536.992952068 9549221.02393693)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:5",
    //     mac: "4c:11:ae:b7:fd:74",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 5,
    //     utm: "POINT(737523.294570094 9549204.58508973)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:6",
    //     mac: "c4:4f:33:23:65:b1",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 6,
    //     utm: "POINT(737512.929856107 9549190.75253361)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P19N:7",
    //     mac: "c4:4f:33:23:55:05",
    //     area: "DMLZ_Extraction",
    //     location: "P19N",
    //     position: 7,
    //     utm: "POINT(737499.855036227 9549175.39889642)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:1",
    //     mac: "b4:e6:2d:ac:49:8d",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 1,
    //     utm: "POINT(737266.44111817 9548925.65803148)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:10",
    //     mac: "b4:e6:2d:99:10:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 10,
    //     utm: "POINT(737366.652399221 9549056.83019569)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:11",
    //     mac: "b4:e6:2d:ac:51:71",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 11,
    //     utm: "POINT(737377.75259216 9549070.91198744)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:12",
    //     mac: "24:0a:c4:50:e7:f0",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 12,
    //     utm: "POINT(737388.852788146 9549084.99377961)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:13",
    //     mac: "c4:4f:33:23:63:89",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 13,
    //     utm: "POINT(737399.952987284 9549099.07557219)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:14",
    //     mac: "24:0a:c4:50:ce:ac",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 14,
    //     utm: "POINT(737411.053189352 9549113.15736519)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:15",
    //     mac: "24:0a:c4:50:f8:9c",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 15,
    //     utm: "POINT(737422.153394462 9549127.23915861)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:16",
    //     mac: "c4:4f:33:23:d4:69",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 16,
    //     utm: "POINT(737433.253602618 9549141.32095244)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:2",
    //     mac: "b4:e6:2d:ab:d7:b1",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 2,
    //     utm: "POINT(737277.355593058 9548940.86938507)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:3",
    //     mac: "b4:e6:2d:ad:0b:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 3,
    //     utm: "POINT(737290.06092049 9548957.40176816)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:4",
    //     mac: "b4:e6:2d:ab:ca:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 4,
    //     utm: "POINT(737302.914546786 9548973.57406619)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:5",
    //     mac: "b4:e6:2d:aa:54:e5",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 5,
    //     utm: "POINT(737313.599516645 9548986.49357305)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:6",
    //     mac: "b4:e6:2d:ad:08:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 6,
    //     utm: "POINT(737322.251657779 9549000.50303284)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:7",
    //     mac: "b4:e6:2d:aa:54:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 7,
    //     utm: "POINT(737333.351838545 9549014.58482293)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:8",
    //     mac: "24:0a:c4:51:13:d0",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 8,
    //     utm: "POINT(737344.452022468 9549028.66661344)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20:9",
    //     mac: "b4:e6:2d:ad:09:85",
    //     area: "DMLZ_Extraction",
    //     location: "P20",
    //     position: 9,
    //     utm: "POINT(737355.552209325 9549042.74840436)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:1",
    //     mac: "b4:e6:2d:aa:55:05",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 1,
    //     utm: "POINT(737546.691034452 9549282.99588077)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:10",
    //     mac: "24:0a:c4:50:db:6c",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 10,
    //     utm: "POINT(737460.664559248 9549173.0244705)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:11",
    //     mac: "c4:4f:33:23:ae:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 11,
    //     utm: "POINT(737451.10607335 9549160.80542651)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:2",
    //     mac: "c4:4f:33:23:54:8d",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 2,
    //     utm: "POINT(737537.132528089 9549270.7768339)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:3",
    //     mac: "b4:e6:2d:ad:8f:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 3,
    //     utm: "POINT(737527.574024097 9549258.55778736)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:4",
    //     mac: "b4:e6:2d:ab:ca:29",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 4,
    //     utm: "POINT(737518.015522361 9549246.33874114)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:5",
    //     mac: "4c:11:ae:b7:fb:98",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 5,
    //     utm: "POINT(737508.457022893 9549234.11969523)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:6",
    //     mac: "b4:e6:2d:ad:0a:09",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 6,
    //     utm: "POINT(737498.898525574 9549221.90064965)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:7",
    //     mac: "b4:e6:2d:ab:ca:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 7,
    //     utm: "POINT(737489.340030629 9549209.68160438)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:8",
    //     mac: "4c:11:ae:b7:fc:d8",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 8,
    //     utm: "POINT(737479.781537942 9549197.46255944)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P20N:9",
    //     mac: "b4:e6:2d:ab:cf:59",
    //     area: "DMLZ_Extraction",
    //     location: "P20N",
    //     position: 9,
    //     utm: "POINT(737470.223047518 9549185.24351481)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:db:6f",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21:1",
    //     mac: "24:0a:c4:50:f8:cc",
    //     area: "DMLZ_Extraction",
    //     location: "P21",
    //     position: 1,
    //     utm: "POINT(737241.914467966 9548944.36466836)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:1",
    //     mac: "24:0a:c4:50:cc:6c",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 1,
    //     utm: "POINT(737524.101188457 9549299.47872017)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:10",
    //     mac: "c4:4f:33:23:60:59",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 10,
    //     utm: "POINT(737407.853265593 9549153.30105161)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:12",
    //     mac: "c4:4f:33:23:b0:e1",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 12,
    //     utm: "POINT(737382.020438987 9549120.81713125)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:13",
    //     mac: "4c:11:ae:b7:f3:1c",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 13,
    //     utm: "POINT(737369.104031897 9549104.57517187)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:2",
    //     mac: "c4:4f:33:23:d1:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 2,
    //     utm: "POINT(737511.184736104 9549283.23675482)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:3",
    //     mac: "24:0a:c4:50:ce:d4",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 3,
    //     utm: "POINT(737498.268287963 9549266.99479001)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:4",
    //     mac: "24:0a:c4:50:c8:48",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 4,
    //     utm: "POINT(737485.351843818 9549250.75282575)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:5",
    //     mac: "c4:4f:33:23:5f:41",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 5,
    //     utm: "POINT(737472.435403886 9549234.51086204)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:6",
    //     mac: "4c:11:ae:b7:f5:0c",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 6,
    //     utm: "POINT(737459.518967951 9549218.26889887)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:7",
    //     mac: "24:0a:c4:50:e4:30",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 7,
    //     utm: "POINT(737446.602536233 9549202.02693624)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:8",
    //     mac: "c4:4f:33:23:ce:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 8,
    //     utm: "POINT(737433.686108617 9549185.78497415)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P21N:9",
    //     mac: "c4:4f:33:23:57:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P21N",
    //     position: 9,
    //     utm: "POINT(737420.769684997 9549169.54301261)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22:1",
    //     mac: "b4:e6:2d:ad:9b:95",
    //     area: "DMLZ_Extraction",
    //     location: "P22",
    //     position: 1,
    //     utm: "POINT(737209.916443878 9548955.84338561)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:1",
    //     mac: "24:0a:c4:50:cf:04",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 1,
    //     utm: "POINT(737512.722573988 9549336.94909944)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:2",
    //     mac: "c4:4f:33:23:69:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 2,
    //     utm: "POINT(737501.062626585 9549321.59360188)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:3",
    //     mac: "c4:4f:33:1e:21:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 3,
    //     utm: "POINT(737487.476410163 9549302.07197176)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:4",
    //     mac: "c4:4f:33:23:57:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 4,
    //     utm: "POINT(737473.960382453 9549284.85889848)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:5",
    //     mac: "c4:4f:33:1e:9e:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 5,
    //     utm: "POINT(737460.444359367 9549267.64582583)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:6",
    //     mac: "c4:4f:33:23:66:11",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 6,
    //     utm: "POINT(737446.928340686 9549250.4327538)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P22N:7",
    //     mac: "c4:4f:33:1d:c4:79",
    //     area: "DMLZ_Extraction",
    //     location: "P22N",
    //     position: 7,
    //     utm: "POINT(737433.412326523 9549233.2196824)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:1",
    //     mac: "b4:e6:2d:98:a6:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 1,
    //     utm: "POINT(737187.32784282 9548976.02697869)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:10",
    //     mac: "c4:4f:33:23:7c:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 10,
    //     utm: "POINT(737288.608301498 9549103.36798003)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:11",
    //     mac: "c4:4f:33:23:66:31",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 11,
    //     utm: "POINT(737299.861701346 9549117.51698224)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:12",
    //     mac: "4c:11:ae:b7:f4:ec",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 12,
    //     utm: "POINT(737311.115104417 9549131.66598485)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:13",
    //     mac: "c4:4f:33:23:b2:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 13,
    //     utm: "POINT(737322.368510501 9549145.81498787)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:14",
    //     mac: "c4:4f:33:23:af:35",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 14,
    //     utm: "POINT(737334.552436732 9549160.55702564)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:15",
    //     mac: "c4:4f:33:1e:95:f5",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 15,
    //     utm: "POINT(737221.709687101 9549583.49524913)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:2",
    //     mac: "b4:e6:2d:98:a4:f9",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 2,
    //     utm: "POINT(737198.581214607 9548990.1759772)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:3",
    //     mac: "b4:e6:2d:ad:09:95",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 3,
    //     utm: "POINT(737209.834589629 9549004.32497612)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:4",
    //     mac: "b4:e6:2d:ac:46:75",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 4,
    //     utm: "POINT(737221.087967656 9549018.47397545)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:5",
    //     mac: "b4:e6:2d:ad:16:e5",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 5,
    //     utm: "POINT(737232.341348796 9549032.62297519)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:6",
    //     mac: "b4:e6:2d:ad:42:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 6,
    //     utm: "POINT(737243.594733168 9549046.77197534)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:7",
    //     mac: "b4:e6:2d:ac:44:45",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 7,
    //     utm: "POINT(737254.848120547 9549060.9209759)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:8",
    //     mac: "b4:e6:2d:98:f7:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 8,
    //     utm: "POINT(737266.101511044 9549075.06997687)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23:9",
    //     mac: "b4:e6:2d:98:a8:71",
    //     area: "DMLZ_Extraction",
    //     location: "P23",
    //     position: 9,
    //     utm: "POINT(737277.35490477 9549089.21897825)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23N:1",
    //     mac: "c4:4f:33:23:af:45",
    //     area: "DMLZ_Extraction",
    //     location: "P23N",
    //     position: 1,
    //     utm: "POINT(737505.372404306 9549373.81218865)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23N:10",
    //     mac: "24:0a:c4:50:cb:50",
    //     area: "DMLZ_Extraction",
    //     location: "P23N",
    //     position: 10,
    //     utm: "POINT(737475.333055692 9549449.3386319)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23N:2",
    //     mac: "24:0a:c4:50:cc:58",
    //     area: "DMLZ_Extraction",
    //     location: "P23N",
    //     position: 2,
    //     utm: "POINT(737496.401290791 9549361.40495647)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23N:3",
    //     mac: "4c:11:ae:b7:f5:ac",
    //     area: "DMLZ_Extraction",
    //     location: "P23N",
    //     position: 3,
    //     utm: "POINT(737484.208226431 9549347.14354433)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23N:4",
    //     mac: "24:0a:c4:50:c7:48",
    //     area: "DMLZ_Extraction",
    //     location: "P23N",
    //     position: 4,
    //     utm: "POINT(737472.489484617 9549331.95274522)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P23N:9",
    //     mac: "4c:11:ae:b7:fa:50",
    //     area: "DMLZ_Extraction",
    //     location: "P23N",
    //     position: 9,
    //     utm: "POINT(737175.651668784 9548915.85799718)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24:1",
    //     mac: "b4:e6:2d:ad:8f:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P24",
    //     position: 1,
    //     utm: "POINT(737161.146759222 9548989.56152634)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:03:b9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:1",
    //     mac: "c4:4f:33:23:70:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 1,
    //     utm: "POINT(737488.758257799 9549400.19315846)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:10",
    //     mac: "24:0a:c4:50:c9:20",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 10,
    //     utm: "POINT(737383.835614168 9549267.56585092)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:11",
    //     mac: "c4:4f:33:23:b6:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 11,
    //     utm: "POINT(737462.468220069 9549485.04709231)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:46:03:ca",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:2",
    //     mac: "c4:4f:33:23:9b:51",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 2,
    //     utm: "POINT(737477.100172951 9549385.45678914)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:3",
    //     mac: "c4:4f:33:23:ae:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 3,
    //     utm: "POINT(737465.442091336 9549370.72042027)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:4",
    //     mac: "4c:11:ae:b7:f4:c0",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 4,
    //     utm: "POINT(737453.784013073 9549355.98405187)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:5",
    //     mac: "24:0a:c4:50:d2:04",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 5,
    //     utm: "POINT(737442.125938271 9549341.24768391)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:6",
    //     mac: "24:0a:c4:50:d0:7c",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 6,
    //     utm: "POINT(737430.467866707 9549326.51131641)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:7",
    //     mac: "c4:4f:33:23:f0:89",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 7,
    //     utm: "POINT(737418.809798599 9549311.77494936)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:8",
    //     mac: "4c:11:ae:b7:fd:80",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 8,
    //     utm: "POINT(737407.151733738 9549297.03858276)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P24N:9",
    //     mac: "c4:4f:33:23:d1:81",
    //     area: "DMLZ_Extraction",
    //     location: "P24N",
    //     position: 9,
    //     utm: "POINT(737395.493672333 9549282.30221661)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:55:46",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25:1",
    //     mac: "c4:4f:33:23:d1:99",
    //     area: "DMLZ_Extraction",
    //     location: "P25",
    //     position: 1,
    //     utm: "POINT(737135.856934851 9549002.0579235)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:1",
    //     mac: "c4:4f:33:23:65:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 1,
    //     utm: "POINT(737464.972906195 9549418.45647093)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:10",
    //     mac: "c4:4f:33:23:61:3d",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 10,
    //     utm: "POINT(737371.890329558 9549301.77833783)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:2",
    //     mac: "24:0a:c4:50:e6:9c",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 2,
    //     utm: "POINT(737454.630387156 9549405.49223254)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:3",
    //     mac: "24:0a:c4:50:cd:98",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 3,
    //     utm: "POINT(737444.287870746 9549392.5279945)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:4",
    //     mac: "c4:4f:33:23:b2:cd",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 4,
    //     utm: "POINT(737433.945356962 9549379.5637568)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:5",
    //     mac: "c4:4f:33:23:7c:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 5,
    //     utm: "POINT(737423.602845806 9549366.59951945)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:6",
    //     mac: "24:0a:c4:50:e3:f0",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 6,
    //     utm: "POINT(737413.260337278 9549353.63528243)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:7",
    //     mac: "24:0a:c4:50:d3:d4",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 7,
    //     utm: "POINT(737402.917831488 9549340.67104577)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P25N:8",
    //     mac: "c4:4f:33:23:60:99",
    //     area: "DMLZ_Extraction",
    //     location: "P25N",
    //     position: 8,
    //     utm: "POINT(737392.575328219 9549327.70680945)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:1",
    //     mac: "24:0a:c4:50:d1:30",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 1,
    //     utm: "POINT(737111.776050824 9549021.65357739)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:10",
    //     mac: "c4:4f:33:23:ae:f9",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 10,
    //     utm: "POINT(737214.514219688 9549151.55633732)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:11",
    //     mac: "c4:4f:33:23:ac:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 11,
    //     utm: "POINT(737225.929587881 9549165.98997947)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:12",
    //     mac: "4c:11:ae:b7:fd:20",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 12,
    //     utm: "POINT(737237.344959287 9549180.42362206)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:13",
    //     mac: "4c:11:ae:b7:f8:60",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 13,
    //     utm: "POINT(737248.760333904 9549194.85726508)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:14",
    //     mac: "24:0a:c4:50:db:78",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 14,
    //     utm: "POINT(737260.175711737 9549209.29090853)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:15",
    //     mac: "c4:4f:33:23:69:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 15,
    //     utm: "POINT(737271.591092784 9549223.72455241)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:16",
    //     mac: "c4:4f:33:23:ad:ed",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 16,
    //     utm: "POINT(737283.006477044 9549238.15819673)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:17",
    //     mac: "24:0a:c4:50:db:50",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 17,
    //     utm: "POINT(737294.421864403 9549252.59184148)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:2",
    //     mac: "24:0a:c4:51:0e:28",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 2,
    //     utm: "POINT(737123.191390086 9549036.08721566)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:3",
    //     mac: "c4:4f:33:23:c3:99",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 3,
    //     utm: "POINT(737134.606732452 9549050.52085435)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:4",
    //     mac: "c4:4f:33:23:b6:ed",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 4,
    //     utm: "POINT(737146.022078144 9549064.95449348)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:5",
    //     mac: "c4:4f:33:23:cd:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 5,
    //     utm: "POINT(737157.437427048 9549079.38813304)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:6",
    //     mac: "c4:4f:33:23:70:e9",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 6,
    //     utm: "POINT(737168.852779173 9549093.82177303)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:7",
    //     mac: "24:0a:c4:50:f8:f8",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 7,
    //     utm: "POINT(737180.26813451 9549108.25541346)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:8",
    //     mac: "4c:11:ae:b7:fc:1c",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 8,
    //     utm: "POINT(737191.683493058 9549122.68905431)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26:9",
    //     mac: "c4:4f:33:1e:ae:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P26",
    //     position: 9,
    //     utm: "POINT(737203.09885482 9549137.1226956)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:1",
    //     mac: "c4:4f:33:23:af:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 1,
    //     utm: "POINT(737441.901388105 9549439.08538802)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:10",
    //     mac: "24:0a:c4:50:cf:30",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 10,
    //     utm: "POINT(737342.840081846 9549311.95221459)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:2",
    //     mac: "c4:4f:33:23:d3:89",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 2,
    //     utm: "POINT(737430.89456434 9549424.95947814)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:3",
    //     mac: "4c:11:ae:b7:fb:d8",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 3,
    //     utm: "POINT(737419.887743465 9549410.83356869)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:4",
    //     mac: "c4:4f:33:23:9b:a1",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 4,
    //     utm: "POINT(737408.880925702 9549396.70765967)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:5",
    //     mac: "24:0a:c4:51:09:08",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 5,
    //     utm: "POINT(737397.874110831 9549382.58175108)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:6",
    //     mac: "c4:4f:33:23:c3:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 6,
    //     utm: "POINT(737386.867299073 9549368.45584293)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:7",
    //     mac: "c4:4f:33:1d:95:05",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 7,
    //     utm: "POINT(737375.860490207 9549354.3299352)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:8",
    //     mac: "c4:4f:33:23:5e:d1",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 8,
    //     utm: "POINT(737364.853684453 9549340.20402789)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P26N:9",
    //     mac: "c4:4f:33:23:66:75",
    //     area: "DMLZ_Extraction",
    //     location: "P26N",
    //     position: 9,
    //     utm: "POINT(737353.846881703 9549326.07812103)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27:1",
    //     mac: "24:0a:c4:51:13:f8",
    //     area: "DMLZ_Extraction",
    //     location: "P27",
    //     position: 1,
    //     utm: "POINT(737087.393335684 9549039.17846926)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27:2",
    //     mac: "c4:4f:33:23:5e:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P27",
    //     position: 2,
    //     utm: "POINT(737100.244567467 9549054.38528835)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27:3",
    //     mac: "c4:4f:33:1e:b8:35",
    //     area: "DMLZ_Extraction",
    //     location: "P27",
    //     position: 3,
    //     utm: "POINT(737113.095803222 9549069.59210785)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:1",
    //     mac: "c4:4f:33:23:b0:31",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 1,
    //     utm: "POINT(737418.226954237 9549457.05245282)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:10",
    //     mac: "c4:4f:33:1d:d1:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 10,
    //     utm: "POINT(737321.253627176 9549334.67027312)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:11",
    //     mac: "4c:11:ae:b7:fa:9c",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 11,
    //     utm: "POINT(737310.478827369 9549321.07225507)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:12",
    //     mac: "c4:4f:33:1d:14:ed",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 12,
    //     utm: "POINT(737299.70403042 9549307.47423741)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:2",
    //     mac: "c4:4f:33:23:8d:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 2,
    //     utm: "POINT(737407.452128695 9549443.45443132)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:3",
    //     mac: "c4:4f:33:23:d4:75",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 3,
    //     utm: "POINT(737396.677306013 9549429.8564102)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:4",
    //     mac: "c4:4f:33:23:55:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 4,
    //     utm: "POINT(737385.902486189 9549416.25838946)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:5",
    //     mac: "24:0a:c4:51:09:40",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 5,
    //     utm: "POINT(737375.127669221 9549402.66036911)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:6",
    //     mac: "24:0a:c4:50:e6:88",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 6,
    //     utm: "POINT(737364.352855115 9549389.06234914)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:7",
    //     mac: "c4:4f:33:23:af:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 7,
    //     utm: "POINT(737353.578043869 9549375.46432956)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:8",
    //     mac: "24:0a:c4:50:e4:e8",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 8,
    //     utm: "POINT(737342.803235485 9549361.86631036)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P27N:9",
    //     mac: "24:0a:c4:50:d2:74",
    //     area: "DMLZ_Extraction",
    //     location: "P27N",
    //     position: 9,
    //     utm: "POINT(737332.028429844 9549348.26829155)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28:1",
    //     mac: "c4:4f:33:23:cf:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P28",
    //     position: 1,
    //     utm: "POINT(737066.894364643 9549060.46997554)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28:2",
    //     mac: "24:0a:c4:50:c6:84",
    //     area: "DMLZ_Extraction",
    //     location: "P28",
    //     position: 2,
    //     utm: "POINT(737075.790400888 9549072.86898545)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28:3",
    //     mac: "c4:4f:33:1d:8f:0d",
    //     area: "DMLZ_Extraction",
    //     location: "P28",
    //     position: 3,
    //     utm: "POINT(737087.517047244 9549085.48712668)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:4d:d6:5d",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:1",
    //     mac: "c4:4f:33:24:24:89",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 1,
    //     utm: "POINT(737394.21794023 9549475.24235093)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:10",
    //     mac: "c4:4f:33:23:57:75",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 10,
    //     utm: "POINT(737295.715741033 9549350.80607014)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:11",
    //     mac: "c4:4f:33:1d:a6:b1",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 11,
    //     utm: "POINT(737284.771066929 9549336.9798187)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:12",
    //     mac: "c4:4f:33:23:5f:99",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 12,
    //     utm: "POINT(737273.826395886 9549323.15356767)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:2",
    //     mac: "4c:11:ae:b7:fa:b4",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 2,
    //     utm: "POINT(737383.273239678 9549461.41609592)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:3",
    //     mac: "c4:4f:33:23:69:4d",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 3,
    //     utm: "POINT(737372.32854196 9549447.5898413)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:4",
    //     mac: "c4:4f:33:23:58:bd",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 4,
    //     utm: "POINT(737361.383847309 9549433.76358708)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:5",
    //     mac: "c4:4f:33:1e:08:71",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 5,
    //     utm: "POINT(737350.439155493 9549419.93733326)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:6",
    //     mac: "24:0a:c4:50:ff:60",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 6,
    //     utm: "POINT(737339.494466745 9549406.11107984)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:7",
    //     mac: "24:0a:c4:50:cf:20",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 7,
    //     utm: "POINT(737328.549780834 9549392.28482682)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:8",
    //     mac: "24:0a:c4:50:e8:20",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 8,
    //     utm: "POINT(737317.605097987 9549378.45857419)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P28N:9",
    //     mac: "24:0a:c4:50:ff:54",
    //     area: "DMLZ_Extraction",
    //     location: "P28N",
    //     position: 9,
    //     utm: "POINT(737306.660417979 9549364.63232196)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:6a:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:1",
    //     mac: "b4:e6:2d:ac:b3:85",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 1,
    //     utm: "POINT(737369.9846928 9549493.13689735)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:10",
    //     mac: "b4:e6:2d:ac:23:85",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 10,
    //     utm: "POINT(737257.88793823 9549351.38388626)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:11",
    //     mac: "b4:e6:2d:98:f1:81",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 11,
    //     utm: "POINT(737245.481500405 9549335.28817854)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:2",
    //     mac: "b4:e6:2d:98:a6:85",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 2,
    //     utm: "POINT(737359.224238347 9549479.84820769)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:3",
    //     mac: "b4:e6:2d:98:f0:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 3,
    //     utm: "POINT(737346.51952658 9549463.63533886)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:4",
    //     mac: "b4:e6:2d:98:a4:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 4,
    //     utm: "POINT(737333.963246388 9549447.2140211)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:5",
    //     mac: "b4:e6:2d:ac:48:49",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 5,
    //     utm: "POINT(737323.20319393 9549434.07330756)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:6",
    //     mac: "b4:e6:2d:ad:44:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 6,
    //     utm: "POINT(737310.1981491 9549416.94338624)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:7",
    //     mac: "b4:e6:2d:aa:fb:59",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 7,
    //     utm: "POINT(737297.195854802 9549400.8492442)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:8",
    //     mac: "b4:e6:2d:ac:75:cd",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 8,
    //     utm: "POINT(737284.043819889 9549384.45956482)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29N:9",
    //     mac: "b4:e6:2d:ad:9a:b9",
    //     area: "DMLZ_Extraction",
    //     location: "P29N",
    //     position: 9,
    //     utm: "POINT(737271.337100091 9549367.47683288)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:1",
    //     mac: "c4:4f:33:1e:4c:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 1,
    //     utm: "POINT(737042.364022823 9549078.80533948)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:10",
    //     mac: "c4:4f:33:1d:a8:89",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 10,
    //     utm: "POINT(737147.360473461 9549211.4765909)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:11",
    //     mac: "c4:4f:33:1e:42:8d",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 11,
    //     utm: "POINT(737159.128899483 9549225.72442947)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:12",
    //     mac: "c4:4f:33:23:66:85",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 12,
    //     utm: "POINT(737170.805405639 9549240.41634091)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:13",
    //     mac: "c4:4f:33:23:65:49",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 13,
    //     utm: "POINT(737184.79976326 9549258.50410619)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:14",
    //     mac: "c4:4f:33:23:61:11",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 14,
    //     utm: "POINT(737195.864463609 9549274.16044773)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:15",
    //     mac: "c4:4f:33:1e:b3:55",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 15,
    //     utm: "POINT(737207.964757872 9549287.15288738)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:16",
    //     mac: "c4:4f:33:1e:b4:29",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 16,
    //     utm: "POINT(737219.026984895 9549301.92020778)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:17",
    //     mac: "c4:4f:33:23:d3:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 17,
    //     utm: "POINT(737232.030418279 9549318.45667714)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:2",
    //     mac: "c4:4f:33:23:60:4d",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 2,
    //     utm: "POINT(737054.04049542 9549093.49724649)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:3",
    //     mac: "24:0a:c4:50:e8:30",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 3,
    //     utm: "POINT(737065.716971368 9549108.18915394)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:4",
    //     mac: "c4:4f:33:23:cb:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 4,
    //     utm: "POINT(737077.393450566 9549122.88106183)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:5",
    //     mac: "c4:4f:33:23:68:69",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 5,
    //     utm: "POINT(737089.069933229 9549137.57297017)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:6",
    //     mac: "24:0a:c4:50:fc:88",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 6,
    //     utm: "POINT(737100.746419253 9549152.26487895)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:7",
    //     mac: "c4:4f:33:1e:c0:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 7,
    //     utm: "POINT(737112.422908519 9549166.95678817)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:8",
    //     mac: "c4:4f:33:1d:b5:99",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 8,
    //     utm: "POINT(737124.099401254 9549181.64869783)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P29S:9",
    //     mac: "c4:4f:33:23:68:d1",
    //     area: "DMLZ_Extraction",
    //     location: "P29S",
    //     position: 9,
    //     utm: "POINT(737135.775897345 9549196.34060794)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:1",
    //     mac: "4c:11:ae:b7:f4:f0",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 1,
    //     utm: "POINT(737345.898972998 9549510.52751348)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:10",
    //     mac: "24:0a:c4:50:d4:20",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 10,
    //     utm: "POINT(737235.444881403 9549368.82814027)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:11",
    //     mac: "c4:4f:33:23:af:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 11,
    //     utm: "POINT(737222.887204242 9549351.70348722)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:12",
    //     mac: "c4:4f:33:23:62:81",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 12,
    //     utm: "POINT(737209.73347299 9549336.2716484)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:13",
    //     mac: "c4:4f:33:23:af:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 13,
    //     utm: "POINT(737195.983649435 9549319.142834)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:14",
    //     mac: "4c:11:ae:b7:fd:44",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 14,
    //     utm: "POINT(737184.027825992 9549304.38231936)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:15",
    //     mac: "24:0a:c4:50:c7:f4",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 15,
    //     utm: "POINT(737170.276454501 9549286.66186384)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:16",
    //     mac: "c4:4f:33:23:63:91",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 16,
    //     utm: "POINT(737156.523521936 9549268.35065274)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:17",
    //     mac: "c4:4f:33:23:af:61",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 17,
    //     utm: "POINT(737143.37268242 9549252.40445897)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:2",
    //     mac: "4c:11:ae:b7:fb:d4",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 2,
    //     utm: "POINT(737335.586632228 9549497.66717741)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:3",
    //     mac: "24:0a:c4:51:0d:bc",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 3,
    //     utm: "POINT(737324.224851637 9549482.30851223)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:4",
    //     mac: "24:0a:c4:51:0e:e0",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 4,
    //     utm: "POINT(737311.673814794 9549467.39734225)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:5",
    //     mac: "4c:11:ae:b7:fc:28",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 5,
    //     utm: "POINT(737299.716291223 9549451.44877051)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:6",
    //     mac: "c4:4f:33:23:cd:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 6,
    //     utm: "POINT(737287.459326903 9549434.6132781)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:7",
    //     mac: "c4:4f:33:1d:81:35",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 7,
    //     utm: "POINT(737273.714776622 9549419.55731271)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:8",
    //     mac: "c4:4f:33:23:70:e5",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 8,
    //     utm: "POINT(737260.262126118 9549401.83680001)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30N:9",
    //     mac: "24:0a:c4:51:0e:94",
    //     area: "DMLZ_Extraction",
    //     location: "P30N",
    //     position: 9,
    //     utm: "POINT(737247.407051169 9549385.00228075)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P30S:1",
    //     mac: "c4:4f:33:23:ce:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P30S",
    //     position: 1,
    //     utm: "POINT(737020.524678415 9549100.57755379)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:1",
    //     mac: "b4:e6:2d:98:f1:45",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 1,
    //     utm: "POINT(737322.558120816 9549527.88630403)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:10",
    //     mac: "4c:11:ae:b7:f5:84",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 10,
    //     utm: "POINT(737210.464971602 9549387.76177275)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:11",
    //     mac: "c4:4f:33:23:69:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 11,
    //     utm: "POINT(737195.937542017 9549368.24334667)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:12",
    //     mac: "c4:4f:33:23:b0:75",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 12,
    //     utm: "POINT(737182.028572589 9549349.95223524)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:13",
    //     mac: "c4:4f:33:1d:7a:4d",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 13,
    //     utm: "POINT(737169.759129487 9549334.88959152)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:14",
    //     mac: "c4:4f:33:23:ce:25",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 14,
    //     utm: "POINT(737160.181136473 9549322.48311005)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:15",
    //     mac: "24:0a:c4:50:d0:08",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 15,
    //     utm: "POINT(737148.810000252 9549308.89686603)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:16",
    //     mac: "c4:4f:33:1e:a6:69",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 16,
    //     utm: "POINT(737137.52985719 9549295.91909928)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:17",
    //     mac: "24:0a:c4:50:ce:fc",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 17,
    //     utm: "POINT(737126.172052486 9549282.0401022)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:18",
    //     mac: "c4:4f:33:23:67:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 18,
    //     utm: "POINT(737113.021082348 9549265.79841078)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:2",
    //     mac: "c4:4f:33:23:68:95",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 2,
    //     utm: "POINT(737312.845098712 9549516.37087325)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:3",
    //     mac: "24:0a:c4:50:ff:78",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 3,
    //     utm: "POINT(737301.6362172 9549502.49173768)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:4",
    //     mac: "c4:4f:33:23:d4:15",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 4,
    //     utm: "POINT(737289.680180872 9549487.7263557)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:5",
    //     mac: "c4:4f:33:23:7c:51",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 5,
    //     utm: "POINT(737277.422578186 9549471.62453432)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:6",
    //     mac: "24:0a:c4:51:09:78",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 6,
    //     utm: "POINT(737265.169942704 9549457.44957935)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:7",
    //     mac: "c4:4f:33:1e:a4:51",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 7,
    //     utm: "POINT(737253.213484084 9549442.39565746)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:8",
    //     mac: "24:0a:c4:50:c6:d0",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 8,
    //     utm: "POINT(737240.807056106 9549426.30142093)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31N:9",
    //     mac: "24:0a:c4:50:c6:f0",
    //     area: "DMLZ_Extraction",
    //     location: "P31N",
    //     position: 9,
    //     utm: "POINT(737226.605254648 9549407.25107459)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P31S:1",
    //     mac: "c4:4f:33:23:ae:45",
    //     area: "DMLZ_Extraction",
    //     location: "P31S",
    //     position: 1,
    //     utm: "POINT(736991.948454931 9549109.56814059)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:1",
    //     mac: "b4:e6:2d:ac:88:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 1,
    //     utm: "POINT(737295.548251204 9549543.38233436)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:10",
    //     mac: "c4:4f:33:23:65:8d",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 10,
    //     utm: "POINT(737181.15127064 9549399.38122526)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:11",
    //     mac: "c4:4f:33:23:57:95",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 11,
    //     utm: "POINT(737168.14548115 9549381.95537136)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:12",
    //     mac: "c4:4f:33:23:61:89",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 12,
    //     utm: "POINT(737154.696345221 9549365.86241533)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:2",
    //     mac: "c4:4f:33:23:ce:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 2,
    //     utm: "POINT(737283.230665486 9549527.25182542)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:3",
    //     mac: "c4:4f:33:23:67:3d",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 3,
    //     utm: "POINT(737271.125663805 9549512.48699768)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:4",
    //     mac: "c4:4f:33:23:64:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 4,
    //     utm: "POINT(737259.019881339 9549497.42623676)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:5",
    //     mac: "c4:4f:33:23:b0:25",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 5,
    //     utm: "POINT(737247.51151192 9549482.95577163)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:6",
    //     mac: "c4:4f:33:23:64:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 6,
    //     utm: "POINT(737233.910644505 9549465.82741317)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:7",
    //     mac: "24:0a:c4:51:14:10",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 7,
    //     utm: "POINT(737220.458349768 9549448.55069592)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:8",
    //     mac: "c4:4f:33:23:72:c1",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 8,
    //     utm: "POINT(736993.44580297 9549112.67172742)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32N:9",
    //     mac: "24:0a:c4:50:ce:9c",
    //     area: "DMLZ_Extraction",
    //     location: "P32N",
    //     position: 9,
    //     utm: "POINT(737194.899903915 9549416.06527024)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:1",
    //     mac: "c4:4f:33:1e:bf:49",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 1,
    //     utm: "POINT(736963.681755662 9549123.25659118)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:10",
    //     mac: "c4:4f:33:23:ce:b1",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 10,
    //     utm: "POINT(737078.745539239 9549269.3368951)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:11",
    //     mac: "c4:4f:33:23:7c:95",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 11,
    //     utm: "POINT(737091.922265554 9549286.42200851)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:12",
    //     mac: "4c:11:ae:b7:f5:e4",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 12,
    //     utm: "POINT(737106.271012178 9549304.73300551)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:13",
    //     mac: "c4:4f:33:23:60:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 13,
    //     utm: "POINT(737117.927528047 9549318.90580263)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:15",
    //     mac: "c4:4f:33:23:cb:65",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 15,
    //     utm: "POINT(737040.390908304 9549220.64345551)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:2",
    //     mac: "c4:4f:33:23:cf:3d",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 2,
    //     utm: "POINT(736976.466604304 9549139.48773386)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:3",
    //     mac: "c4:4f:33:23:71:45",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 3,
    //     utm: "POINT(736989.251457095 9549155.71887709)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:4",
    //     mac: "24:0a:c4:51:0e:34",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 4,
    //     utm: "POINT(737002.036313814 9549171.95002087)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:5",
    //     mac: "c4:4f:33:23:70:8d",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 5,
    //     utm: "POINT(737014.821174568 9549188.1811652)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:6",
    //     mac: "4c:11:ae:b7:fd:90",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 6,
    //     utm: "POINT(737027.606039472 9549204.41231008)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:8",
    //     mac: "c4:4f:33:23:ce:51",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 8,
    //     utm: "POINT(737053.175781281 9549236.87460149)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P32S:9",
    //     mac: "4c:11:ae:b7:fd:3c",
    //     area: "DMLZ_Extraction",
    //     location: "P32S",
    //     position: 9,
    //     utm: "POINT(737065.960658186 9549253.10574802)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P33S:1",
    //     mac: "c4:4f:33:23:66:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P33S",
    //     position: 1,
    //     utm: "POINT(736938.103090818 9549139.30421456)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:1",
    //     mac: "c4:4f:33:23:b0:41",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 1,
    //     utm: "POINT(736914.316433605 9549157.11563484)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:10",
    //     mac: "24:0a:c4:50:d0:34",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 10,
    //     utm: "POINT(737001.730932691 9549316.06014216)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:11",
    //     mac: "24:0a:c4:50:ca:c8",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 11,
    //     utm: "POINT(737016.375928801 9549333.8207469)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:12",
    //     mac: "c4:4f:33:1d:ea:91",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 12,
    //     utm: "POINT(737030.426047618 9549351.84026822)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:13",
    //     mac: "c4:4f:33:1c:ef:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 13,
    //     utm: "POINT(737041.780714009 9549367.50460028)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:15",
    //     mac: "c4:4f:33:23:f0:99",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 15,
    //     utm: "POINT(737059.379635365 9549390.25902894)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:2",
    //     mac: "c4:4f:33:23:71:39",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 2,
    //     utm: "POINT(736904.80474993 9549193.33582051)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:3",
    //     mac: "c4:4f:33:23:ce:15",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 3,
    //     utm: "POINT(736916.986679712 9549209.06199254)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:4",
    //     mac: "c4:4f:33:23:7c:c9",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 4,
    //     utm: "POINT(736929.168613074 9549224.78816509)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:5",
    //     mac: "c4:4f:33:1e:c0:71",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 5,
    //     utm: "POINT(736941.350550234 9549240.51433818)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:6",
    //     mac: "c4:4f:33:23:cd:b1",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 6,
    //     utm: "POINT(736953.532491084 9549256.24051181)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:7",
    //     mac: "c4:4f:33:1e:ab:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 7,
    //     utm: "POINT(736965.714435621 9549271.96668597)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:8",
    //     mac: "c4:4f:33:1c:fc:05",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 8,
    //     utm: "POINT(736977.896383734 9549287.69286066)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P35S:9",
    //     mac: "c4:4f:33:1d:82:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P35S",
    //     position: 9,
    //     utm: "POINT(736990.078335645 9549303.41903589)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P36S:1",
    //     mac: "c4:4f:33:23:66:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P36S",
    //     position: 1,
    //     utm: "POINT(736867.641735072 9549194.38703217)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:4f:a9",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:1",
    //     mac: "b4:e6:2d:99:13:99",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 1,
    //     utm: "POINT(737648.803483946 9548638.17378631)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:10",
    //     mac: "b4:e6:2d:98:9a:21",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 10,
    //     utm: "POINT(737746.273434936 9548762.248137)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:11",
    //     mac: "b4:e6:2d:ab:e8:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 11,
    //     utm: "POINT(737757.103444039 9548776.03417798)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:12",
    //     mac: "b4:e6:2d:99:10:29",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 12,
    //     utm: "POINT(737767.933455931 9548789.82021937)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:2",
    //     mac: "b4:e6:2d:ac:46:59",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 2,
    //     utm: "POINT(737659.633466925 9548651.95982366)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:3",
    //     mac: "b4:e6:2d:99:15:55",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 3,
    //     utm: "POINT(737670.4634527 9548665.74586142)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:4",
    //     mac: "b4:e6:2d:ac:46:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 4,
    //     utm: "POINT(737681.293441488 9548679.53189958)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:5",
    //     mac: "b4:e6:2d:ad:9f:11",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 5,
    //     utm: "POINT(737692.123433176 9548693.31793814)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:6",
    //     mac: "b4:e6:2d:ac:46:39",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 6,
    //     utm: "POINT(737702.953427658 9548707.10397711)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:7",
    //     mac: "b4:e6:2d:aa:fa:91",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 7,
    //     utm: "POINT(737713.783425153 9548720.89001648)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:8",
    //     mac: "b4:e6:2d:99:13:45",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 8,
    //     utm: "POINT(737724.61342555 9548734.67605625)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4:9",
    //     mac: "b4:e6:2d:ad:07:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P4",
    //     position: 9,
    //     utm: "POINT(737735.443428734 9548748.46209642)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:1",
    //     mac: "b4:e6:2d:98:9a:49",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 1,
    //     utm: "POINT(737855.812833681 9548900.41482017)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:2",
    //     mac: "b4:e6:2d:99:8f:69",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 2,
    //     utm: "POINT(737844.902612536 9548886.5964581)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:3",
    //     mac: "b4:e6:2d:ac:4d:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 3,
    //     utm: "POINT(737833.99239433 9548872.77809644)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:4",
    //     mac: "b4:e6:2d:98:a3:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 4,
    //     utm: "POINT(737823.082178949 9548858.95973517)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:5",
    //     mac: "b4:e6:2d:ad:8d:fd",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 5,
    //     utm: "POINT(737812.171966623 9548845.14137431)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:6",
    //     mac: "b4:e6:2d:99:06:31",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 6,
    //     utm: "POINT(737801.26175723 9548831.32301385)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P4N:7",
    //     mac: "b4:e6:2d:ac:10:55",
    //     area: "DMLZ_Extraction",
    //     location: "P4N",
    //     position: 7,
    //     utm: "POINT(737790.351550668 9548817.50465378)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:60:2c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:1",
    //     mac: "b4:e6:2d:ad:8e:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 1,
    //     utm: "POINT(737607.60714985 9548635.25000945)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:10",
    //     mac: "b4:e6:2d:98:f4:81",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 10,
    //     utm: "POINT(737719.012265393 9548776.66013098)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:11",
    //     mac: "b4:e6:2d:aa:54:19",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 11,
    //     utm: "POINT(737731.390630538 9548792.37236931)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:12",
    //     mac: "b4:e6:2d:ac:4c:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 12,
    //     utm: "POINT(737743.768999359 9548808.08460815)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:2",
    //     mac: "b4:e6:2d:98:a3:45",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 2,
    //     utm: "POINT(737619.985480907 9548650.9622431)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:3",
    //     mac: "b4:e6:2d:ab:fd:0d",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 3,
    //     utm: "POINT(737632.363815641 9548666.67447727)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:4",
    //     mac: "b4:e6:2d:98:a5:91",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 4,
    //     utm: "POINT(737644.742154271 9548682.38671195)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:5",
    //     mac: "b4:e6:2d:98:f1:91",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 5,
    //     utm: "POINT(737657.120496695 9548698.09894716)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:6",
    //     mac: "b4:e6:2d:aa:54:d9",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 6,
    //     utm: "POINT(737669.498842793 9548713.81118288)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:7",
    //     mac: "b4:e6:2d:98:fd:25",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 7,
    //     utm: "POINT(737681.87719279 9548729.52341913)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:8",
    //     mac: "b4:e6:2d:ac:4d:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 8,
    //     utm: "POINT(737694.255546573 9548745.23565589)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5:9",
    //     mac: "b4:e6:2d:ac:05:41",
    //     area: "DMLZ_Extraction",
    //     location: "P5",
    //     position: 9,
    //     utm: "POINT(737706.633904034 9548760.94789318)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:1",
    //     mac: "24:0a:c4:50:e7:18",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 1,
    //     utm: "POINT(737841.437150259 9548928.71507542)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:2",
    //     mac: "b4:e6:2d:99:13:89",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 2,
    //     utm: "POINT(737831.875780752 9548918.23473773)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:3",
    //     mac: "b4:e6:2d:ac:03:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 3,
    //     utm: "POINT(737819.622558541 9548903.76641014)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:4",
    //     mac: "c4:4f:33:1d:d8:e5",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 4,
    //     utm: "POINT(737806.171377562 9548886.93377512)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:5",
    //     mac: "b4:e6:2d:ac:b2:79",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 5,
    //     utm: "POINT(737793.911878225 9548870.09797391)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:6",
    //     mac: "b4:e6:2d:98:e7:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 6,
    //     utm: "POINT(737779.56541309 9548852.67585414)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:7",
    //     mac: "24:0a:c4:50:d3:fc",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 7,
    //     utm: "POINT(737766.111074788 9548834.6594916)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P5N:8",
    //     mac: "c4:4f:33:23:54:ad",
    //     area: "DMLZ_Extraction",
    //     location: "P5N",
    //     position: 8,
    //     utm: "POINT(737752.961688034 9548819.30575344)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:1",
    //     mac: "b4:e6:2d:ac:46:65",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 1,
    //     utm: "POINT(737568.054820306 9548633.80152312)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:10",
    //     mac: "b4:e6:2d:98:fd:05",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 10,
    //     utm: "POINT(737681.817650558 9548777.4191859)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:11",
    //     mac: "b4:e6:2d:ab:c9:f9",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 11,
    //     utm: "POINT(737694.457984774 9548793.37670664)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:12",
    //     mac: "b4:e6:2d:aa:fa:85",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 12,
    //     utm: "POINT(737707.098322821 9548809.3342279)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:13",
    //     mac: "b4:e6:2d:aa:fa:b9",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 13,
    //     utm: "POINT(737719.738664918 9548825.2917497)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:14",
    //     mac: "b4:e6:2d:98:a3:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 14,
    //     utm: "POINT(737732.379010957 9548841.24927202)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:15",
    //     mac: "b4:e6:2d:ac:53:61",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 15,
    //     utm: "POINT(737745.019360824 9548857.20679488)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:2",
    //     mac: "b4:e6:2d:98:f0:e5",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 2,
    //     utm: "POINT(737580.695119048 9548649.75903909)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:3",
    //     mac: "b4:e6:2d:ac:87:15",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 3,
    //     utm: "POINT(737593.335421731 9548665.71655559)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:4",
    //     mac: "b4:e6:2d:ac:4c:85",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 4,
    //     utm: "POINT(737605.975728248 9548681.67407262)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:5",
    //     mac: "b4:e6:2d:ac:48:11",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 5,
    //     utm: "POINT(737618.616038815 9548697.63159017)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:6",
    //     mac: "b4:e6:2d:ad:96:0d",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 6,
    //     utm: "POINT(737631.256353324 9548713.58910826)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:7",
    //     mac: "b4:e6:2d:ad:8d:b9",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 7,
    //     utm: "POINT(737643.896671666 9548729.54662688)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:8",
    //     mac: "b4:e6:2d:ad:9e:65",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 8,
    //     utm: "POINT(737656.536994059 9548745.50414602)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6:9",
    //     mac: "b4:e6:2d:ac:05:01",
    //     area: "DMLZ_Extraction",
    //     location: "P6",
    //     position: 9,
    //     utm: "POINT(737669.177320393 9548761.4616657)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:1",
    //     mac: "b4:e6:2d:98:a4:99",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 1,
    //     utm: "POINT(737831.404775928 9548965.29002623)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:2",
    //     mac: "b4:e6:2d:ac:57:cd",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 2,
    //     utm: "POINT(737819.677054514 9548952.29997662)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:3",
    //     mac: "b4:e6:2d:ac:ad:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 3,
    //     utm: "POINT(737806.969231911 9548934.94746129)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:4",
    //     mac: "24:0a:c4:50:d0:9c",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 4,
    //     utm: "POINT(737795.836057962 9548921.58591393)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:5",
    //     mac: "b4:e6:2d:ac:4f:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 5,
    //     utm: "POINT(737785.074732456 9548908.00142704)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:6",
    //     mac: "c4:4f:33:23:57:69",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 6,
    //     utm: "POINT(737772.813672382 9548890.57374964)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P6N:7",
    //     mac: "c4:4f:33:23:b0:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P6N",
    //     position: 7,
    //     utm: "POINT(737760.259528605 9548874.92247788)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:1",
    //     mac: "b4:e6:2d:99:04:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 1,
    //     utm: "POINT(737542.69943543 9548649.7015065)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:10",
    //     mac: "b4:e6:2d:ad:8d:cd",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 10,
    //     utm: "POINT(737657.218373732 9548794.50942098)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:11",
    //     mac: "b4:e6:2d:ac:4e:e1",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 11,
    //     utm: "POINT(737669.942720131 9548810.59919196)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:12",
    //     mac: "b4:e6:2d:ab:fc:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 12,
    //     utm: "POINT(737682.667070635 9548826.68896347)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:13",
    //     mac: "b4:e6:2d:ac:4f:3d",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 13,
    //     utm: "POINT(737695.391425025 9548842.77873553)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:14",
    //     mac: "b4:e6:2d:98:fd:3d",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 14,
    //     utm: "POINT(737708.115783518 9548858.86850813)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:15",
    //     mac: "b4:e6:2d:98:f1:69",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 15,
    //     utm: "POINT(737720.840145898 9548874.95828127)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:2",
    //     mac: "b4:e6:2d:ac:49:95",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 2,
    //     utm: "POINT(737555.42374597 9548665.79127261)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:3",
    //     mac: "b4:e6:2d:ac:48:55",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 3,
    //     utm: "POINT(737568.148060397 9548681.88103927)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:4",
    //     mac: "b4:e6:2d:a4:6b:05",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 4,
    //     utm: "POINT(737580.872378932 9548697.97080647)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:5",
    //     mac: "b4:e6:2d:aa:54:95",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 5,
    //     utm: "POINT(737593.596701353 9548714.0605742)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:6",
    //     mac: "b4:e6:2d:98:a8:29",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 6,
    //     utm: "POINT(737606.321027879 9548730.15034248)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:7",
    //     mac: "b4:e6:2d:98:f1:1d",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 7,
    //     utm: "POINT(737619.045358293 9548746.24011129)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:8",
    //     mac: "b4:e6:2d:aa:54:d5",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 8,
    //     utm: "POINT(737631.769692814 9548762.32988065)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7:9",
    //     mac: "b4:e6:2d:ac:4d:95",
    //     area: "DMLZ_Extraction",
    //     location: "P7",
    //     position: 9,
    //     utm: "POINT(737644.494031222 9548778.41965054)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:1",
    //     mac: "b4:e6:2d:98:a7:a5",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 1,
    //     utm: "POINT(737819.877883941 9548999.79739861)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:2",
    //     mac: "4c:11:ae:b7:fa:b0",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 2,
    //     utm: "POINT(737809.416463733 9548986.95194194)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:3",
    //     mac: "24:0a:c4:50:e7:9c",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 3,
    //     utm: "POINT(737797.160829556 9548971.59579156)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:4",
    //     mac: "b4:e6:2d:98:f7:55",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 4,
    //     utm: "POINT(737785.498629116 9548955.35025423)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:5",
    //     mac: "c4:4f:33:23:a1:85",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 5,
    //     utm: "POINT(737773.840574624 9548941.17627008)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:6",
    //     mac: "c4:4f:33:23:ab:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 6,
    //     utm: "POINT(737761.290895 9548926.70872262)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P7N:7",
    //     mac: "b4:e6:2d:ad:8d:e9",
    //     area: "DMLZ_Extraction",
    //     location: "P7N",
    //     position: 7,
    //     utm: "POINT(737748.431597286 9548908.39481918)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:1",
    //     mac: "b4:e6:2d:99:13:59",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 1,
    //     utm: "POINT(737520.483829351 9548669.88420484)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:10",
    //     mac: "b4:e6:2d:ac:46:5d",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 10,
    //     utm: "POINT(737625.489889645 9548802.65157654)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:11",
    //     mac: "b4:e6:2d:98:a8:79",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 11,
    //     utm: "POINT(737637.157246487 9548817.403509)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:12",
    //     mac: "b4:e6:2d:ac:43:19",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 12,
    //     utm: "POINT(737648.824606693 9548832.15544191)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:13",
    //     mac: "b4:e6:2d:99:13:95",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 13,
    //     utm: "POINT(737660.491970255 9548846.90737527)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:14",
    //     mac: "b4:e6:2d:ac:8e:d1",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 14,
    //     utm: "POINT(737672.159337177 9548861.6593091)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:15",
    //     mac: "b4:e6:2d:99:10:65",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 15,
    //     utm: "POINT(737683.826707453 9548876.41124337)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:16",
    //     mac: "b4:e6:2d:98:a2:35",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 16,
    //     utm: "POINT(737695.494081093 9548891.1631781)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:17",
    //     mac: "b4:e6:2d:aa:54:41",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 17,
    //     utm: "POINT(737707.161457979 9548905.91511329)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:2",
    //     mac: "b4:e6:2d:ac:4c:55",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 2,
    //     utm: "POINT(737532.151155959 9548684.63613321)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:3",
    //     mac: "b4:e6:2d:ac:49:91",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 3,
    //     utm: "POINT(737543.818485922 9548699.38806204)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:4",
    //     mac: "b4:e6:2d:ac:49:9d",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 4,
    //     utm: "POINT(737555.485819247 9548714.13999132)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:5",
    //     mac: "b4:e6:2d:98:e7:91",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 5,
    //     utm: "POINT(737567.153155933 9548728.89192106)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:6",
    //     mac: "b4:e6:2d:99:13:85",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 6,
    //     utm: "POINT(737578.820495975 9548743.64385125)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:7",
    //     mac: "b4:e6:2d:ad:8f:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 7,
    //     utm: "POINT(737590.487839383 9548758.39578189)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:8",
    //     mac: "b4:e6:2d:ac:49:99",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 8,
    //     utm: "POINT(737602.155186036 9548773.14771299)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8:9",
    //     mac: "b4:e6:2d:98:a6:49",
    //     area: "DMLZ_Extraction",
    //     location: "P8",
    //     position: 9,
    //     utm: "POINT(737613.822536161 9548787.89964454)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:91:60:c5",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:1",
    //     mac: "b4:e6:2d:99:10:8d",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 1,
    //     utm: "POINT(737806.859291483 9549034.01280716)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:2",
    //     mac: "b4:e6:2d:ac:4c:79",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 2,
    //     utm: "POINT(737796.136048229 9549018.35664092)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:3",
    //     mac: "b4:e6:2d:98:a2:85",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 3,
    //     utm: "POINT(737783.773620903 9549002.85280304)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:4",
    //     mac: "b4:e6:2d:ac:49:f1",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 4,
    //     utm: "POINT(737771.411197448 9548987.34896565)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:5",
    //     mac: "b4:e6:2d:99:11:f5",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 5,
    //     utm: "POINT(737759.048777644 9548971.84512876)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:6",
    //     mac: "b4:e6:2d:ac:46:a9",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 6,
    //     utm: "POINT(737746.686361704 9548956.34129236)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:7",
    //     mac: "b4:e6:2d:98:f7:6d",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 7,
    //     utm: "POINT(737734.323949413 9548940.83745646)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P8N:8",
    //     mac: "b4:e6:2d:ad:95:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P8N",
    //     position: 8,
    //     utm: "POINT(737721.96154088 9548925.33362104)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:ec:13",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:1",
    //     mac: "b4:e6:2d:ac:0e:99",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 1,
    //     utm: "POINT(737496.998084973 9548688.73856103)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:10",
    //     mac: "b4:e6:2d:ad:9b:e9",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 10,
    //     utm: "POINT(737599.375516389 9548817.05072322)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:11",
    //     mac: "b4:e6:2d:99:10:7d",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 11,
    //     utm: "POINT(737611.334121924 9548832.70357138)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:12",
    //     mac: "b4:e6:2d:99:04:75",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 12,
    //     utm: "POINT(737622.993079672 9548847.76534611)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:13",
    //     mac: "b4:e6:2d:98:fd:59",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 13,
    //     utm: "POINT(737635.398022374 9548863.12107753)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:14",
    //     mac: "b4:e6:2d:98:a8:0d",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 14,
    //     utm: "POINT(737647.505677549 9548878.77353921)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:15",
    //     mac: "b4:e6:2d:98:f3:c5",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 15,
    //     utm: "POINT(737662.45373195 9548897.67375881)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:16",
    //     mac: "b4:e6:2d:98:f7:2d",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 16,
    //     utm: "POINT(737674.560215149 9548912.88232547)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:2",
    //     mac: "b4:e6:2d:ad:8e:dd",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 2,
    //     utm: "POINT(737509.850597577 9548704.53386491)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:3",
    //     mac: "b4:e6:2d:99:04:b5",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 3,
    //     utm: "POINT(737521.805085419 9548718.70735309)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9:4",
    //     mac: "24:0a:c4:50:ff:b0",
    //     area: "DMLZ_Extraction",
    //     location: "P9",
    //     position: 4,
    //     utm: "POINT(737533.424594372 9548733.03175829)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9N:1",
    //     mac: "b4:e6:2d:98:8f:e9",
    //     area: "DMLZ_Extraction",
    //     location: "P9N",
    //     position: 1,
    //     utm: "POINT(737795.328347425 9549065.41285793)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:P9N:2",
    //     mac: "b4:e6:2d:ac:57:ed",
    //     area: "DMLZ_Extraction",
    //     location: "P9N",
    //     position: 2,
    //     utm: "POINT(737784.267071114 9549050.94132504)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:2d:56",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_E_P7_P10:11",
    //     mac: "c4:4f:33:1d:9e:85",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_E_P7_P10",
    //     position: 11,
    //     utm: "POINT(737538.476258736 9548642.72666016)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_E_P7_P10:7",
    //     mac: "c4:4f:33:23:64:55",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_E_P7_P10",
    //     position: 7,
    //     utm: "POINT(737509.519636954 9548671.69961654)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_E_P7_P10:9",
    //     mac: "c4:4f:33:23:74:b5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_E_P7_P10",
    //     position: 9,
    //     utm: "POINT(737523.997950194 9548657.21314078)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:1",
    //     mac: "c4:4f:33:23:b1:0d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 1,
    //     utm: "POINT(737674.998578471 9548630.1137785)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:10",
    //     mac: "c4:4f:33:1e:b1:45",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 10,
    //     utm: "POINT(737733.094143964 9548686.48309042)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:11",
    //     mac: "c4:4f:33:23:b0:65",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 11,
    //     utm: "POINT(737739.965162816 9548693.56780358)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:12",
    //     mac: "c4:4f:33:23:64:1d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 12,
    //     utm: "POINT(737746.542203749 9548702.13285113)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:13",
    //     mac: "c4:4f:33:23:64:b5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 13,
    //     utm: "POINT(737758.951091973 9548719.11826724)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:14",
    //     mac: "c4:4f:33:23:65:15",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 14,
    //     utm: "POINT(737752.522617199 9548710.40374615)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:15",
    //     mac: "24:0a:c4:50:c6:ac",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 15,
    //     utm: "POINT(737766.123998831 9548727.6787341)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:16",
    //     mac: "c4:4f:33:23:b3:d5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 16,
    //     utm: "POINT(737773.447387131 9548736.83654088)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:17",
    //     mac: "24:0a:c4:50:e8:00",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 17,
    //     utm: "POINT(737780.771194806 9548746.13944804)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:18",
    //     mac: "c4:4f:33:23:b5:05",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 18,
    //     utm: "POINT(737787.795957111 9548754.99435855)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:19",
    //     mac: "c4:4f:33:23:64:39",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 19,
    //     utm: "POINT(737796.162424909 9548764.29938224)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:2",
    //     mac: "c4:4f:33:23:ca:5d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 2,
    //     utm: "POINT(737684.092048355 9548632.67898595)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:20",
    //     mac: "c4:4f:33:23:a0:69",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 20,
    //     utm: "POINT(737801.996258859 9548773.45861483)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:21",
    //     mac: "c4:4f:33:23:65:99",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 21,
    //     utm: "POINT(737809.469432326 9548782.90896717)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:3",
    //     mac: "c4:4f:33:23:b3:ad",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 3,
    //     utm: "POINT(737691.621380631 9548635.24767926)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:4",
    //     mac: "c4:4f:33:1e:7b:b9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 4,
    //     utm: "POINT(737697.967499064 9548641.00112656)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:5",
    //     mac: "24:0a:c4:50:ca:ac",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 5,
    //     utm: "POINT(737703.718562676 9548647.05206085)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:6",
    //     mac: "c4:4f:33:23:66:7d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 6,
    //     utm: "POINT(737709.39830395 9548654.28738632)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:7",
    //     mac: "c4:4f:33:23:b0:01",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 7,
    //     utm: "POINT(737715.676825891 9548662.63143429)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:8",
    //     mac: "c4:4f:33:23:d1:05",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 8,
    //     utm: "POINT(737722.32774569 9548670.97423174)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_NE_Fringe:9",
    //     mac: "c4:4f:33:23:d2:49",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_NE_Fringe",
    //     position: 9,
    //     utm: "POINT(737728.233094854 9548679.0234685)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:1",
    //     mac: "c4:4f:33:1e:bf:71",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 1,
    //     utm: "POINT(737265.008711044 9548919.14906954)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:10",
    //     mac: "4c:11:ae:b7:fb:0c",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 10,
    //     utm: "POINT(737196.423688091 9548951.44013799)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:11",
    //     mac: "24:0a:c4:50:f6:b0",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 11,
    //     utm: "POINT(737186.239801724 9548958.93954474)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:12",
    //     mac: "24:0a:c4:50:f8:d4",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 12,
    //     utm: "POINT(737178.432000739 9548963.69523676)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:13",
    //     mac: "24:0a:c4:51:0f:28",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 13,
    //     utm: "POINT(737170.773158472 9548968.45053279)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:14",
    //     mac: "24:0a:c4:50:e7:70",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 14,
    //     utm: "POINT(737164.082747068 9548973.27724333)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:15",
    //     mac: "c4:4f:33:23:69:b1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 15,
    //     utm: "POINT(737155.753586912 9548978.03431569)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:16",
    //     mac: "4c:11:ae:b7:fa:88",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 16,
    //     utm: "POINT(737148.542994148 9548983.30630885)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:17",
    //     mac: "c4:4f:33:23:ab:a1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 17,
    //     utm: "POINT(737141.181479328 9548987.83886087)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:18",
    //     mac: "c4:4f:33:23:ab:d9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 18,
    //     utm: "POINT(737134.937550971 9548992.5164164)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:19",
    //     mac: "c4:4f:33:23:8d:79",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 19,
    //     utm: "POINT(737129.218314839 9548998.45030914)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:2",
    //     mac: "c4:4f:33:1e:af:59",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 2,
    //     utm: "POINT(737257.122116168 9548922.27733007)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:20",
    //     mac: "c4:4f:33:23:ab:81",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 20,
    //     utm: "POINT(737123.346979639 9549003.20085968)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:21",
    //     mac: "c4:4f:33:1e:a8:2d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 21,
    //     utm: "POINT(737116.65676015 9549008.10154935)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:22",
    //     mac: "24:0a:c4:50:da:b4",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 22,
    //     utm: "POINT(737109.596692145 9549013.96501206)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:3",
    //     mac: "c4:4f:33:1e:ae:71",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 3,
    //     utm: "POINT(737248.343139882 9548925.92584626)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:4",
    //     mac: "c4:4f:33:23:c3:a1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 4,
    //     utm: "POINT(737240.157840668 9548928.75896132)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:5",
    //     mac: "c4:4f:33:1e:bb:9d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 5,
    //     utm: "POINT(737231.97371916 9548932.03597668)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:6",
    //     mac: "4c:11:ae:b7:fa:40",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 6,
    //     utm: "POINT(737223.865451123 9548935.83067825)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:7",
    //     mac: "c4:4f:33:23:a3:11",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 7,
    //     utm: "POINT(737215.980425318 9548939.55080251)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:8",
    //     mac: "4c:11:ae:b7:fb:c4",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 8,
    //     utm: "POINT(737207.875885984 9548944.75118949)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P20_P26:9",
    //     mac: "c4:4f:33:23:ae:35",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P20_P26",
    //     position: 9,
    //     utm: "POINT(737201.258583318 9548949.05982115)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:1",
    //     mac: "c4:4f:33:1e:a4:11",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 1,
    //     utm: "POINT(737106.477963303 9549017.52451721)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:10",
    //     mac: "c4:4f:33:23:58:b1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 10,
    //     utm: "POINT(737034.769057576 9549079.56531033)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:11",
    //     mac: "c4:4f:33:23:61:79",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 11,
    //     utm: "POINT(737025.999641092 9549086.83949147)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:12",
    //     mac: "4c:11:ae:b7:fd:18",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 12,
    //     utm: "POINT(737018.566562794 9549092.48218943)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:13",
    //     mac: "c4:4f:33:23:69:25",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 13,
    //     utm: "POINT(737011.352470068 9549096.42175891)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:14",
    //     mac: "c4:4f:33:23:67:71",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 14,
    //     utm: "POINT(737004.358831072 9549099.25168758)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:15",
    //     mac: "24:0a:c4:50:cf:5c",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 15,
    //     utm: "POINT(736992.677083363 9549103.64782866)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:16",
    //     mac: "c4:4f:33:1e:bd:ad",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 16,
    //     utm: "POINT(736988.063858952 9549105.36156217)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:17",
    //     mac: "c4:4f:33:23:71:a5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 17,
    //     utm: "POINT(736979.583370369 9549109.23120543)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:18",
    //     mac: "c4:4f:33:23:8d:b5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 18,
    //     utm: "POINT(736971.323772183 9549112.1384692)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:19",
    //     mac: "24:0a:c4:50:e7:84",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 19,
    //     utm: "POINT(736962.989302146 9549114.89796219)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:2",
    //     mac: "c4:4f:33:1c:fc:d1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 2,
    //     utm: "POINT(737098.972867963 9549024.05277005)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:20",
    //     mac: "4c:11:ae:b7:f9:ac",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 20,
    //     utm: "POINT(736955.405508637 9549119.87498824)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:21",
    //     mac: "c4:4f:33:1d:72:41",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 21,
    //     utm: "POINT(736948.566903189 9549124.99800755)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:22",
    //     mac: "4c:11:ae:b7:f6:10",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 22,
    //     utm: "POINT(736941.729276901 9549130.4909441)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:23",
    //     mac: "24:0a:c4:51:09:64",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 23,
    //     utm: "POINT(736934.890866073 9549135.6879457)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:24",
    //     mac: "4c:11:ae:b7:f6:54",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 24,
    //     utm: "POINT(736927.978366746 9549141.0331111)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:25",
    //     mac: "4c:11:ae:b7:f8:18",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 25,
    //     utm: "POINT(736919.951415159 9549147.41700509)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:26",
    //     mac: "24:0a:c4:50:c7:4c",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 26,
    //     utm: "POINT(736913.78233941 9549152.24231134)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:27",
    //     mac: "c4:4f:33:23:ab:79",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 27,
    //     utm: "POINT(736907.464695563 9549157.21597875)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:28",
    //     mac: "24:0a:c4:51:09:60",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 28,
    //     utm: "POINT(736900.47771373 9549162.56133881)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:29",
    //     mac: "c4:4f:33:23:ad:09",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 29,
    //     utm: "POINT(736893.416447465 9549167.98087903)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:3",
    //     mac: "c4:4f:33:23:55:d5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 3,
    //     utm: "POINT(737089.386091619 9549032.07071405)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:30",
    //     mac: "4c:11:ae:b7:f6:f4",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 30,
    //     utm: "POINT(736889.477645296 9549171.2466106)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:4",
    //     mac: "24:0a:c4:50:f8:e0",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 4,
    //     utm: "POINT(737078.015615006 9549041.49683758)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:5",
    //     mac: "c4:4f:33:23:a9:29",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 5,
    //     utm: "POINT(737067.537911498 9549050.55067229)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:6",
    //     mac: "c4:4f:33:23:57:51",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 6,
    //     utm: "POINT(737060.924715515 9549056.41294581)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:7",
    //     mac: "24:0a:c4:50:cb:f8",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 7,
    //     utm: "POINT(737054.31151861 9549062.27521876)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:8",
    //     mac: "24:0a:c4:50:db:3c",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 8,
    //     utm: "POINT(737047.103660306 9549068.5829719)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_P26_P32:9",
    //     mac: "24:0a:c4:50:e7:14",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_P26_P32",
    //     position: 9,
    //     utm: "POINT(737039.597686189 9549074.81753041)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:1",
    //     mac: "24:0a:c4:50:ce:f8",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 1,
    //     utm: "POINT(737480.618532508 9548690.98714319)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:10",
    //     mac: "c4:4f:33:23:5f:71",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 10,
    //     utm: "POINT(737429.143595557 9548715.55226103)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:11",
    //     mac: "c4:4f:33:23:ce:19",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 11,
    //     utm: "POINT(737425.733659943 9548721.62846295)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:12",
    //     mac: "c4:4f:33:23:60:b9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 12,
    //     utm: "POINT(737422.32293764 9548727.40925252)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:13",
    //     mac: "c4:4f:33:23:60:81",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 13,
    //     utm: "POINT(737418.911814697 9548733.0407064)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:14",
    //     mac: "c4:4f:33:23:60:a5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 14,
    //     utm: "POINT(737416.172381008 9548739.18894813)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:15",
    //     mac: "24:0a:c4:50:e8:18",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 15,
    //     utm: "POINT(737418.576955124 9548747.17263529)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:16",
    //     mac: "c4:4f:33:23:b6:c5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 16,
    //     utm: "POINT(737423.806581931 9548753.22692887)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:17",
    //     mac: "24:0a:c4:50:fc:48",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 17,
    //     utm: "POINT(737429.776738041 9548757.64919576)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:18",
    //     mac: "c4:4f:33:23:70:a5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 18,
    //     utm: "POINT(737436.337427932 9548760.07196708)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:2",
    //     mac: "24:0a:c4:51:08:f8",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 2,
    //     utm: "POINT(737474.79931873 9548687.31801032)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:3",
    //     mac: "4c:11:ae:b7:f8:14",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 3,
    //     utm: "POINT(737468.681737384 9548683.48742804)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:4",
    //     mac: "c4:4f:33:23:65:59",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 4,
    //     utm: "POINT(737461.675589824 9548681.58200788)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:5",
    //     mac: "c4:4f:33:23:60:fd",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 5,
    //     utm: "POINT(737453.636086021 9548683.22854268)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:6",
    //     mac: "c4:4f:33:23:71:dd",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 6,
    //     utm: "POINT(737447.099602511 9548689.90505514)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:7",
    //     mac: "c4:4f:33:23:70:31",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 7,
    //     utm: "POINT(737441.45922593 9548697.46744978)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:8",
    //     mac: "4c:11:ae:b7:f2:f0",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 8,
    //     utm: "POINT(737436.860376166 9548704.58297743)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp_Undercut_SVD:9",
    //     mac: "c4:4f:33:23:60:8d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp_Undercut_SVD",
    //     position: 9,
    //     utm: "POINT(737433.301087655 9548710.51134758)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:1",
    //     mac: "24:0a:c4:50:da:ec",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 1,
    //     utm: "POINT(737189.516288712 9548949.38952141)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:15",
    //     mac: "b4:e6:2d:98:a7:f9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 15,
    //     utm: "POINT(737232.41937479 9548905.55350354)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:17",
    //     mac: "b4:e6:2d:99:04:cd",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 17,
    //     utm: "POINT(737238.547965093 9548915.22478154)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:2",
    //     mac: "24:0a:c4:50:e7:a4",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 2,
    //     utm: "POINT(737182.625536878 9548943.03913759)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:3",
    //     mac: "b4:e6:2d:98:f7:29",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 3,
    //     utm: "POINT(737177.217569947 9548933.89495666)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:4",
    //     mac: "4c:11:ae:b7:fc:b8",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 4,
    //     utm: "POINT(737178.357145975 9548906.21145762)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:5",
    //     mac: "24:0a:c4:51:08:c8",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 5,
    //     utm: "POINT(737189.128003197 9548896.56333806)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:6",
    //     mac: "24:0a:c4:50:db:9c",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 6,
    //     utm: "POINT(737203.036035088 9548890.31177896)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:7",
    //     mac: "24:0a:c4:50:e4:88",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 7,
    //     utm: "POINT(737184.242154845 9548902.96982418)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:8",
    //     mac: "24:0a:c4:51:1e:e0",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 8,
    //     utm: "POINT(737228.687445201 9548902.08385975)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_Ramp:9",
    //     mac: "b4:e6:2d:99:8f:49",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_Ramp",
    //     position: 9,
    //     utm: "POINT(737194.78167896 9548893.92130606)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:10",
    //     mac: "4c:11:ae:b7:f5:20",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 10,
    //     utm: "POINT(737415.361986095 9548798.60094458)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:11",
    //     mac: "4c:11:ae:b7:f3:4c",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 11,
    //     utm: "POINT(737410.021868225 9548807.04989585)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:12",
    //     mac: "c4:4f:33:23:b6:d9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 12,
    //     utm: "POINT(737405.127406973 9548815.05128226)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:13",
    //     mac: "c4:4f:33:23:66:f9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 13,
    //     utm: "POINT(737400.97777318 9548823.0529717)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:3",
    //     mac: "c4:4f:33:1e:a4:f5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 3,
    //     utm: "POINT(737452.000007964 9548740.20355202)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:5",
    //     mac: "c4:4f:33:1e:af:d9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 5,
    //     utm: "POINT(737447.40350411 9548748.20623287)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:7",
    //     mac: "c4:4f:33:23:b0:d5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 7,
    //     utm: "POINT(737431.679476554 9548772.95788635)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_W_P11_P20:9",
    //     mac: "c4:4f:33:1e:a7:69",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_W_P11_P20",
    //     position: 9,
    //     utm: "POINT(737420.553926147 9548790.44786672)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:1",
    //     mac: "b4:e6:2d:ac:27:69",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 1,
    //     utm: "POINT(737495.041318902 9548686.18608745)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:10",
    //     mac: "b4:e6:2d:99:11:d1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 10,
    //     utm: "POINT(737516.758794161 9548664.45637926)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:11",
    //     mac: "b4:e6:2d:ad:96:09",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 11,
    //     utm: "POINT(737502.280478461 9548678.9428526)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:12",
    //     mac: "b4:e6:2d:98:9a:71",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 12,
    //     utm: "POINT(737487.802158173 9548693.42932108)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:13",
    //     mac: "b4:e6:2d:ac:05:29",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 13,
    //     utm: "POINT(737480.562996271 9548700.6725535)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:14",
    //     mac: "b4:e6:2d:ac:be:15",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 14,
    //     utm: "POINT(737466.084668934 9548715.15901469)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:15",
    //     mac: "b4:e6:2d:ac:4f:b9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 15,
    //     utm: "POINT(737456.968703298 9548732.12557233)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:16",
    //     mac: "b4:e6:2d:ac:e6:f1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 16,
    //     utm: "POINT(737442.657644635 9548756.06088629)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:17",
    //     mac: "b4:e6:2d:ac:b4:61",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 17,
    //     utm: "POINT(737437.613870313 9548763.91678744)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:18",
    //     mac: "b4:e6:2d:ad:9a:95",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 18,
    //     utm: "POINT(737426.488309028 9548781.40579034)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:2",
    //     mac: "b4:e6:2d:98:a2:7d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 2,
    //     utm: "POINT(737473.323833188 9548707.91578471)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:20",
    //     mac: "b4:e6:2d:98:fd:31",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 20,
    //     utm: "POINT(737411.371904878 9548810.59578394)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:21",
    //     mac: "b4:e6:2d:ac:4f:6d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 21,
    //     utm: "POINT(737402.177385856 9548826.00888675)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:22",
    //     mac: "b4:e6:2d:ac:fb:3d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 22,
    //     utm: "POINT(737392.23719676 9548841.27600177)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:23",
    //     mac: "b4:e6:2d:ad:95:d9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 23,
    //     utm: "POINT(737383.487427985 9548855.80011453)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:24",
    //     mac: "b4:e6:2d:ac:3b:25",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 24,
    //     utm: "POINT(737372.652296483 9548870.62570102)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:25",
    //     mac: "b4:e6:2d:98:a6:09",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 25,
    //     utm: "POINT(737358.379323661 9548880.57746567)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:26",
    //     mac: "b4:e6:2d:98:f3:e5",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 26,
    //     utm: "POINT(737342.308609667 9548887.13073291)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:27",
    //     mac: "b4:e6:2d:ac:24:45",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 27,
    //     utm: "POINT(737325.492630286 9548893.39003869)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:28",
    //     mac: "b4:e6:2d:ad:9a:a1",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 28,
    //     utm: "POINT(737310.465578111 9548900.08849619)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:29",
    //     mac: "b4:e6:2d:ad:8d:ed",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 29,
    //     utm: "POINT(737293.202873383 9548906.49694829)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:3",
    //     mac: "b4:e6:2d:98:f1:5d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 3,
    //     utm: "POINT(737624.044781711 9548626.55010181)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:30",
    //     mac: "b4:e6:2d:98:fd:15",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 30,
    //     utm: "POINT(737276.539309913 9548913.93958296)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:4",
    //     mac: "b4:e6:2d:ac:be:09",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 4,
    //     utm: "POINT(737605.718837995 9548625.11916085)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:5",
    //     mac: "b4:e6:2d:98:a3:a9",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 5,
    //     utm: "POINT(737587.097397552 9548624.57746168)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:6",
    //     mac: "b4:e6:2d:ac:64:9d",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 6,
    //     utm: "POINT(737568.926378881 9548625.3650339)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:7",
    //     mac: "b4:e6:2d:ac:85:91",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 7,
    //     utm: "POINT(737553.153435186 9548631.62475414)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:93:43",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:8",
    //     mac: "b4:e6:2d:ac:57:bd",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 8,
    //     utm: "POINT(737545.715411245 9548635.48341802)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SFD_West:9",
    //     mac: "b4:e6:2d:98:f1:ad",
    //     area: "DMLZ_Extraction",
    //     location: "SFD_West",
    //     position: 9,
    //     utm: "POINT(737531.237105052 9548649.96990107)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:1",
    //     mac: "c4:4f:33:23:d0:f1",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 1,
    //     utm: "POINT(737521.21278378 9549056.3743305)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:11",
    //     mac: "c4:4f:33:23:d3:cd",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 11,
    //     utm: "POINT(737588.164583294 9548801.95644895)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:12",
    //     mac: "c4:4f:33:23:61:81",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 12,
    //     utm: "POINT(737577.102291937 9548787.1945862)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:13",
    //     mac: "24:0a:c4:50:cc:c0",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 13,
    //     utm: "POINT(737532.872442026 9549071.73939704)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:15",
    //     mac: "c4:4f:33:1e:b9:d1",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 15,
    //     utm: "POINT(737130.705548261 9549334.70458965)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:e4:60",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:17",
    //     mac: "c4:4f:33:23:69:89",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 17,
    //     utm: "POINT(737566.936106649 9548773.31782025)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:18",
    //     mac: "24:0a:c4:50:e7:8c",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 18,
    //     utm: "POINT(737494.609805587 9549021.67342169)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:2",
    //     mac: "c4:4f:33:1e:b4:c9",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 2,
    //     utm: "POINT(737461.861746454 9548723.60441113)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "1c:ba:8c:e3:21:a3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:3",
    //     mac: "24:0a:c4:51:0e:a8",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 3,
    //     utm: "POINT(737207.454508286 9549431.86466985)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:54:03:af",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:4",
    //     mac: "24:0a:c4:50:ff:84",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 4,
    //     utm: "POINT(737544.817835094 9548746.16069361)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:5",
    //     mac: "c4:4f:33:23:6c:4d",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 5,
    //     utm: "POINT(737476.751283875 9548805.2436603)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:6",
    //     mac: "24:0a:c4:50:d2:0c",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 6,
    //     utm: "POINT(737466.587459559 9548792.24980371)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:SVD_FW1:8",
    //     mac: "c4:4f:33:23:5f:d1",
    //     area: "DMLZ_Extraction",
    //     location: "SVD_FW1",
    //     position: 8,
    //     utm: "POINT(737556.774636084 9548761.21599568)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:d6:49",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Extraction:UCL_SFD:2",
    //     mac: "c4:4f:33:23:d7:35",
    //     area: "DMLZ_Extraction",
    //     location: "UCL_SFD",
    //     position: 2,
    //     utm: "POINT(737235.339111852 9548910.65223104)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:db",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD-31:1",
    //     mac: "c4:4f:33:23:ab:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD-31",
    //     position: 1,
    //     utm: "POINT(737311.308733989 9549554.92131627)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD-31:2",
    //     mac: "c4:4f:33:23:61:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD-31",
    //     position: 2,
    //     utm: "POINT(737299.051106448 9549538.7527077)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD-31:3",
    //     mac: "c4:4f:33:1e:ab:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD-31",
    //     position: 3,
    //     utm: "POINT(737286.792483992 9549522.21398816)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD-31:4",
    //     mac: "c4:4f:33:23:af:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD-31",
    //     position: 4,
    //     utm: "POINT(737273.042515775 9549505.08346224)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD-31:5",
    //     mac: "c4:4f:33:23:60:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD-31",
    //     position: 5,
    //     utm: "POINT(737258.395790608 9549486.77428475)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD-32:1",
    //     mac: "24:0a:c4:50:ca:44",
    //     area: "DMLZ_Undercut",
    //     location: "DD-32",
    //     position: 1,
    //     utm: "POINT(737281.994984306 9549566.61784608)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:1",
    //     mac: "b4:e6:2d:98:f3:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 1,
    //     utm: "POINT(737492.448939011 9548714.49707967)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:10",
    //     mac: "b4:e6:2d:98:a2:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 10,
    //     utm: "POINT(737596.5088707 9548849.03699112)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:11",
    //     mac: "b4:e6:2d:ac:4f:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 11,
    //     utm: "POINT(737608.071102025 9548863.98587261)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:12",
    //     mac: "c4:4f:33:23:68:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 12,
    //     utm: "POINT(737619.633336566 9548878.93475459)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:13",
    //     mac: "c4:4f:33:23:61:99",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 13,
    //     utm: "POINT(737629.289818201 9548638.37235198)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:14",
    //     mac: "c4:4f:33:1e:bc:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 14,
    //     utm: "POINT(737654.498540845 9548651.32785758)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:15",
    //     mac: "c4:4f:33:23:72:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 15,
    //     utm: "POINT(737598.149806244 9548635.64560121)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:17",
    //     mac: "c4:4f:33:23:67:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 17,
    //     utm: "POINT(737683.199480717 9548689.13167071)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:2",
    //     mac: "b4:e6:2d:99:11:e1",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 2,
    //     utm: "POINT(737504.011140398 9548729.44595677)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:3",
    //     mac: "b4:e6:2d:ac:1d:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 3,
    //     utm: "POINT(737515.573345 9548744.39483436)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:4",
    //     mac: "b4:e6:2d:ad:96:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 4,
    //     utm: "POINT(737527.135553039 9548759.34371243)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:5",
    //     mac: "b4:e6:2d:aa:55:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 5,
    //     utm: "POINT(737538.697764297 9548774.29259099)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:6",
    //     mac: "b4:e6:2d:ad:8e:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 6,
    //     utm: "POINT(737550.25997899 9548789.24147004)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:7",
    //     mac: "b4:e6:2d:99:6e:0d",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 7,
    //     utm: "POINT(737561.822196903 9548804.19034958)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:8",
    //     mac: "b4:e6:2d:98:a3:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 8,
    //     utm: "POINT(737573.384418249 9548819.1392296)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10E:9",
    //     mac: "b4:e6:2d:ac:49:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD10E",
    //     position: 9,
    //     utm: "POINT(737584.946642808 9548834.08811012)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD10W:1",
    //     mac: "b4:e6:2d:98:a4:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD10W",
    //     position: 1,
    //     utm: "POINT(737477.473423686 9548740.87518303)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:1",
    //     mac: "b4:e6:2d:98:f2:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 1,
    //     utm: "POINT(737474.199785578 9548742.06762629)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:10",
    //     mac: "b4:e6:2d:ad:9a:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 10,
    //     utm: "POINT(737578.678660554 9548875.96015823)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:11",
    //     mac: "b4:e6:2d:99:8f:35",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 11,
    //     utm: "POINT(737590.287441237 9548890.8371086)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:12",
    //     mac: "b4:e6:2d:aa:fb:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 12,
    //     utm: "POINT(737601.896225154 9548905.71405945)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:13",
    //     mac: "b4:e6:2d:99:08:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 13,
    //     utm: "POINT(737613.505012407 9548920.59101076)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:2",
    //     mac: "b4:e6:2d:98:f1:49",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 2,
    //     utm: "POINT(737485.80853607 9548756.94457239)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:3",
    //     mac: "b4:e6:2d:98:f2:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 3,
    //     utm: "POINT(737497.417289903 9548771.82151896)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:4",
    //     mac: "b4:e6:2d:99:11:6d",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 4,
    //     utm: "POINT(737509.026047192 9548786.698466)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:5",
    //     mac: "b4:e6:2d:98:9b:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 5,
    //     utm: "POINT(737520.634807708 9548801.57541352)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:6",
    //     mac: "b4:e6:2d:ad:40:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 6,
    //     utm: "POINT(737532.243571572 9548816.45236151)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:7",
    //     mac: "b4:e6:2d:98:9a:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 7,
    //     utm: "POINT(737543.852338886 9548831.32930998)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:8",
    //     mac: "b4:e6:2d:98:f0:e1",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 8,
    //     utm: "POINT(737555.461109436 9548846.20625892)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11E:9",
    //     mac: "b4:e6:2d:98:a4:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD11E",
    //     position: 9,
    //     utm: "POINT(737567.069883321 9548861.08320834)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11W:1",
    //     mac: "b4:e6:2d:98:f1:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD11W",
    //     position: 1,
    //     utm: "POINT(737459.6768733 9548770.66404761)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11W:2",
    //     mac: "b4:e6:2d:ac:86:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD11W",
    //     position: 2,
    //     utm: "POINT(737471.779918187 9548784.68885733)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11W:3",
    //     mac: "b4:e6:2d:98:f5:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD11W",
    //     position: 3,
    //     utm: "POINT(737490.405410471 9548786.56295281)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11W:4",
    //     mac: "b4:e6:2d:ac:81:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD11W",
    //     position: 4,
    //     utm: "POINT(737502.510270314 9548801.32760045)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD11W:5",
    //     mac: "b4:e6:2d:99:a5:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD11W",
    //     position: 5,
    //     utm: "POINT(737514.317921959 9548816.3889771)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12E:1",
    //     mac: "b4:e6:2d:98:a5:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD12E",
    //     position: 1,
    //     utm: "POINT(737459.858312503 9548776.87822426)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12E:2",
    //     mac: "b4:e6:2d:98:4d:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD12E",
    //     position: 2,
    //     utm: "POINT(737470.900558548 9548789.87007717)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12E:3",
    //     mac: "b4:e6:2d:99:11:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD12E",
    //     position: 3,
    //     utm: "POINT(737482.844032623 9548799.9001751)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12E:4",
    //     mac: "b4:e6:2d:ad:9b:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD12E",
    //     position: 4,
    //     utm: "POINT(737494.951174908 9548815.40466097)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12E:5",
    //     mac: "b4:e6:2d:ac:16:6d",
    //     area: "DMLZ_Undercut",
    //     location: "DD12E",
    //     position: 5,
    //     utm: "POINT(737506.162122167 9548830.17169039)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12W:1",
    //     mac: "b4:e6:2d:ac:53:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD12W",
    //     position: 1,
    //     utm: "POINT(737442.914850934 9548797.19486584)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12W:2",
    //     mac: "b4:e6:2d:ac:48:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD12W",
    //     position: 2,
    //     utm: "POINT(737453.975412817 9548811.37042007)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD12W:3",
    //     mac: "b4:e6:2d:99:03:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD12W",
    //     position: 3,
    //     utm: "POINT(737460.089584164 9548813.86963484)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13E:1",
    //     mac: "b4:e6:2d:ac:db:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD13E",
    //     position: 1,
    //     utm: "POINT(737443.042650899 9548803.40918624)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13E:2",
    //     mac: "b4:e6:2d:ac:92:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD13E",
    //     position: 2,
    //     utm: "POINT(737454.693340493 9548816.54739719)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "f0:45:da:83:01:44",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:1",
    //     mac: "b4:e6:2d:98:a5:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 1,
    //     utm: "POINT(737718.722728348 9549163.86939567)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:10",
    //     mac: "b4:e6:2d:ac:7b:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 10,
    //     utm: "POINT(737788.138316203 9549107.75054901)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:11",
    //     mac: "c4:4f:33:1e:07:8d",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 11,
    //     utm: "POINT(737795.71875971 9549101.51609242)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:12",
    //     mac: "b4:e6:2d:98:a4:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 12,
    //     utm: "POINT(737801.961305119 9549096.3202576)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:13",
    //     mac: "c4:4f:33:23:68:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 13,
    //     utm: "POINT(737807.754619359 9549090.23786859)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:14",
    //     mac: "b4:e6:2d:ac:bd:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 14,
    //     utm: "POINT(737812.651808411 9549083.26998357)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:15",
    //     mac: "c4:4f:33:1d:02:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 15,
    //     utm: "POINT(737816.061370693 9549077.04687628)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:16",
    //     mac: "b4:e6:2d:99:8f:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 16,
    //     utm: "POINT(737819.464618611 9549068.45445897)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:17",
    //     mac: "4c:11:ae:b7:f3:14",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 17,
    //     utm: "POINT(737822.56562844 9549058.23694828)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:18",
    //     mac: "b4:e6:2d:98:94:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 18,
    //     utm: "POINT(737825.815987242 9549048.16622773)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:19",
    //     mac: "c4:4f:33:23:cd:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 19,
    //     utm: "POINT(737828.916995783 9549037.94793618)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:2",
    //     mac: "c4:4f:33:23:72:0d",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 2,
    //     utm: "POINT(737726.896170156 9549156.59452427)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:20",
    //     mac: "b4:e6:2d:ad:8f:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 20,
    //     utm: "POINT(737831.87221198 9549028.91573891)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:3",
    //     mac: "b4:e6:2d:98:f7:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 3,
    //     utm: "POINT(737734.029727102 9549150.36021881)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:4",
    //     mac: "c4:4f:33:23:5f:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 4,
    //     utm: "POINT(737740.719556783 9549145.31162555)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:5",
    //     mac: "b4:e6:2d:ac:4e:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 5,
    //     utm: "POINT(737747.408614352 9549139.96781289)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:6",
    //     mac: "c4:4f:33:1d:58:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 6,
    //     utm: "POINT(737755.881656844 9549133.2872115)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:7",
    //     mac: "b4:e6:2d:ac:74:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 7,
    //     utm: "POINT(737765.544368081 9549125.86210298)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:8",
    //     mac: "c4:4f:33:23:5f:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 8,
    //     utm: "POINT(737773.569736935 9549118.88681695)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13EN:9",
    //     mac: "c4:4f:33:23:d1:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD13EN",
    //     position: 9,
    //     utm: "POINT(737780.704173389 9549112.95137563)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:1",
    //     mac: "b4:e6:2d:98:a7:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 1,
    //     utm: "POINT(737425.563365528 9548825.65083469)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:2",
    //     mac: "b4:e6:2d:ac:48:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 2,
    //     utm: "POINT(737437.445704766 9548840.71202196)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:3",
    //     mac: "b4:e6:2d:ac:57:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 3,
    //     utm: "POINT(737449.912640651 9548852.36839108)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:4",
    //     mac: "c4:4f:33:23:9b:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 4,
    //     utm: "POINT(737469.140266799 9548856.01651861)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:5",
    //     mac: "b4:e6:2d:99:8f:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 5,
    //     utm: "POINT(737479.905022655 9548870.93271189)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:6",
    //     mac: "b4:e6:2d:ad:95:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 6,
    //     utm: "POINT(737492.010809323 9548885.99331389)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:7",
    //     mac: "b4:e6:2d:98:a5:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 7,
    //     utm: "POINT(737504.114929476 9548900.38806595)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:8",
    //     mac: "b4:e6:2d:ad:9c:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 8,
    //     utm: "POINT(737516.595771694 9548916.48345585)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13W:9",
    //     mac: "b4:e6:2d:98:fd:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD13W",
    //     position: 9,
    //     utm: "POINT(737527.510510777 9548931.76918391)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:1",
    //     mac: "c4:4f:33:23:62:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 1,
    //     utm: "POINT(737708.611467603 9549170.70218472)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:10",
    //     mac: "c4:4f:33:23:a9:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 10,
    //     utm: "POINT(737648.857667061 9549219.09803743)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:11",
    //     mac: "c4:4f:33:23:55:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 11,
    //     utm: "POINT(737642.317179136 9549224.29504184)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:12",
    //     mac: "c4:4f:33:23:54:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 12,
    //     utm: "POINT(737634.585080981 9549228.92458386)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:2",
    //     mac: "c4:4f:33:23:a9:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 2,
    //     utm: "POINT(737702.36851456 9549175.74945718)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:3",
    //     mac: "24:0a:c4:50:da:80",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 3,
    //     utm: "POINT(737696.720196156 9549180.35032974)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:4",
    //     mac: "c4:4f:33:1e:bd:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 4,
    //     utm: "POINT(737690.924907208 9549185.6926755)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:5",
    //     mac: "24:0a:c4:50:e6:e4",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 5,
    //     utm: "POINT(737685.129612322 9549191.03508602)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:6",
    //     mac: "24:0a:c4:50:e7:60",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 6,
    //     utm: "POINT(737679.483687959 9549196.52519856)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:7",
    //     mac: "c4:4f:33:23:6a:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 7,
    //     utm: "POINT(737672.348942876 9549202.31487818)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:8",
    //     mac: "24:0a:c4:50:e7:24",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 8,
    //     utm: "POINT(737665.063656911 9549207.51314643)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD13WN:9",
    //     mac: "c4:4f:33:23:9b:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD13WN",
    //     position: 9,
    //     utm: "POINT(737657.036711257 9549213.89759208)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:1",
    //     mac: "b4:e6:2d:99:11:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 1,
    //     utm: "POINT(737424.300701836 9548827.05988576)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:2",
    //     mac: "b4:e6:2d:98:90:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 2,
    //     utm: "POINT(737436.106216072 9548841.23346855)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:3",
    //     mac: "b4:e6:2d:98:a4:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 3,
    //     utm: "POINT(737449.631251282 9548857.84396152)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:4",
    //     mac: "b4:e6:2d:98:a2:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 4,
    //     utm: "POINT(737461.288599476 9548872.31388011)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:5",
    //     mac: "b4:e6:2d:ad:86:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 5,
    //     utm: "POINT(737472.945558056 9548886.63583426)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:6",
    //     mac: "b4:e6:2d:98:a4:a5",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 6,
    //     utm: "POINT(737485.348889345 9548901.5476816)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:7",
    //     mac: "b4:e6:2d:ad:18:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 7,
    //     utm: "POINT(737496.111790536 9548915.72404804)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:8",
    //     mac: "b4:e6:2d:98:fd:35",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 8,
    //     utm: "POINT(737506.873737075 9548929.60448303)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14E:9",
    //     mac: "b4:e6:2d:98:a5:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD14E",
    //     position: 9,
    //     utm: "POINT(737515.54093442 9548939.93924417)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD14W:1",
    //     mac: "b4:e6:2d:ac:cb:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD14W",
    //     position: 1,
    //     utm: "POINT(737408.062401852 9548854.69907284)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD15E:1",
    //     mac: "b4:e6:2d:ad:40:a5",
    //     area: "DMLZ_Undercut",
    //     location: "DD15E",
    //     position: 1,
    //     utm: "POINT(737410.291920713 9548852.76956551)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:4b:e3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD15W:1",
    //     mac: "b4:e6:2d:aa:54:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD15W",
    //     position: 1,
    //     utm: "POINT(737391.442222656 9548878.71405378)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD15W:2",
    //     mac: "b4:e6:2d:98:a8:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD15W",
    //     position: 2,
    //     utm: "POINT(737400.855022592 9548889.63870338)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD15W:3",
    //     mac: "b4:e6:2d:98:f3:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD15W",
    //     position: 3,
    //     utm: "POINT(737418.888803167 9548892.10627093)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD15W:4",
    //     mac: "b4:e6:2d:98:fd:0d",
    //     area: "DMLZ_Undercut",
    //     location: "DD15W",
    //     position: 4,
    //     utm: "POINT(737431.583439322 9548904.50188827)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD15W:5",
    //     mac: "b4:e6:2d:98:a4:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD15W",
    //     position: 5,
    //     utm: "POINT(737442.944275506 9548919.71244726)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16E:1",
    //     mac: "b4:e6:2d:ac:87:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD16E",
    //     position: 1,
    //     utm: "POINT(737392.758697647 9548882.40976229)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16E:2",
    //     mac: "b4:e6:2d:ac:b2:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD16E",
    //     position: 2,
    //     utm: "POINT(737403.976552006 9548896.43695481)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16E:3",
    //     mac: "b4:e6:2d:ad:3b:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD16E",
    //     position: 3,
    //     utm: "POINT(737415.194409452 9548910.46414772)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16E:4",
    //     mac: "b4:e6:2d:ad:23:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD16E",
    //     position: 4,
    //     utm: "POINT(737426.412270105 9548924.49134103)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16E:5",
    //     mac: "b4:e6:2d:ac:e4:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD16E",
    //     position: 5,
    //     utm: "POINT(737437.630133737 9548938.51853474)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16W:1",
    //     mac: "b4:e6:2d:ac:4f:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD16W",
    //     position: 1,
    //     utm: "POINT(737380.000098239 9548889.10221038)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16W:2",
    //     mac: "b4:e6:2d:ad:9b:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD16W",
    //     position: 2,
    //     utm: "POINT(737391.434347064 9548903.68371137)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16W:3",
    //     mac: "b4:e6:2d:98:a4:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD16W",
    //     position: 3,
    //     utm: "POINT(737402.868599128 9548918.26521281)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16W:4",
    //     mac: "b4:e6:2d:ad:1b:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD16W",
    //     position: 4,
    //     utm: "POINT(737414.302854425 9548932.8467147)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD16W:5",
    //     mac: "b4:e6:2d:ac:0d:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD16W",
    //     position: 5,
    //     utm: "POINT(737425.737113072 9548947.42821704)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17E:1",
    //     mac: "b4:e6:2d:ac:48:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD17E",
    //     position: 1,
    //     utm: "POINT(737368.402332945 9548896.97531937)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17E:2",
    //     mac: "b4:e6:2d:98:a3:99",
    //     area: "DMLZ_Undercut",
    //     location: "DD17E",
    //     position: 2,
    //     utm: "POINT(737380.212258887 9548912.77656498)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17E:3",
    //     mac: "b4:e6:2d:ac:4c:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD17E",
    //     position: 3,
    //     utm: "POINT(737392.022188339 9548928.57781117)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17E:4",
    //     mac: "b4:e6:2d:ac:76:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD17E",
    //     position: 4,
    //     utm: "POINT(737403.83212131 9548944.37905794)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17E:5",
    //     mac: "84:0d:8e:0b:d0:e8",
    //     area: "DMLZ_Undercut",
    //     location: "DD17E",
    //     position: 5,
    //     utm: "POINT(737415.64205779 9548960.18030528)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17E:6",
    //     mac: "b4:e6:2d:ac:b3:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD17E",
    //     position: 6,
    //     utm: "POINT(737427.451997778 9548975.98155321)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e5:29:b3",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17W:1",
    //     mac: "b4:e6:2d:ac:56:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD17W",
    //     position: 1,
    //     utm: "POINT(737355.142686268 9548908.10813892)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17W:2",
    //     mac: "b4:e6:2d:98:a8:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD17W",
    //     position: 2,
    //     utm: "POINT(737366.799631836 9548922.87401191)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17W:3",
    //     mac: "b4:e6:2d:99:08:99",
    //     area: "DMLZ_Undercut",
    //     location: "DD17W",
    //     position: 3,
    //     utm: "POINT(737378.45658087 9548937.63988537)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17W:4",
    //     mac: "c4:4f:33:23:67:35",
    //     area: "DMLZ_Undercut",
    //     location: "DD17W",
    //     position: 4,
    //     utm: "POINT(737389.550334802 9548950.7796071)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17W:5",
    //     mac: "c4:4f:33:1d:24:e9",
    //     area: "DMLZ_Undercut",
    //     location: "DD17W",
    //     position: 5,
    //     utm: "POINT(737400.315889196 9548965.54785407)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD17W:6",
    //     mac: "c4:4f:33:23:68:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD17W",
    //     position: 6,
    //     utm: "POINT(737411.072346124 9548978.24457119)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18E:1",
    //     mac: "b4:e6:2d:98:a6:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD18E",
    //     position: 1,
    //     utm: "POINT(737339.960094147 9548912.29155608)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18E:2",
    //     mac: "b4:e6:2d:ad:9b:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD18E",
    //     position: 2,
    //     utm: "POINT(737351.480766538 9548927.30440748)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18E:3",
    //     mac: "b4:e6:2d:ad:08:6d",
    //     area: "DMLZ_Undercut",
    //     location: "DD18E",
    //     position: 3,
    //     utm: "POINT(737363.001442242 9548942.31725939)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18E:4",
    //     mac: "b4:e6:2d:98:9b:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD18E",
    //     position: 4,
    //     utm: "POINT(737374.522121256 9548957.33011179)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18E:5",
    //     mac: "c4:4f:33:1e:a2:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD18E",
    //     position: 5,
    //     utm: "POINT(737389.169765581 9548976.23116718)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18W:1",
    //     mac: "b4:e6:2d:98:f4:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD18W",
    //     position: 1,
    //     utm: "POINT(737310.646382605 9548923.31901712)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18W:2",
    //     mac: "b4:e6:2d:ac:bd:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD18W",
    //     position: 2,
    //     utm: "POINT(737321.553320515 9548937.79094872)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD18W:3",
    //     mac: "b4:e6:2d:98:f3:91",
    //     area: "DMLZ_Undercut",
    //     location: "DD18W",
    //     position: 3,
    //     utm: "POINT(737337.503258238 9548943.22343391)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD19E:1",
    //     mac: "b4:e6:2d:98:f6:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD19E",
    //     position: 1,
    //     utm: "POINT(737316.957688218 9548933.06,816486)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD19E:2",
    //     mac: "b4:e6:2d:98:9a:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD19E",
    //     position: 2,
    //     utm: "POINT(737329.662523697 9548949.26162504)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD19E:3",
    //     mac: "b4:e6:2d:98:f3:6d",
    //     area: "DMLZ_Undercut",
    //     location: "DD19E",
    //     position: 3,
    //     utm: "POINT(737342.36736317 9548965.45508577)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD19E:4",
    //     mac: "b4:e6:2d:ac:46:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD19E",
    //     position: 4,
    //     utm: "POINT(737355.072206638 9548981.64854705)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:4b:86",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD19W:1",
    //     mac: "b4:e6:2d:98:9a:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD19W",
    //     position: 1,
    //     utm: "POINT(737298.028477726 9548928.82733133)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20E:1",
    //     mac: "b4:e6:2d:ac:8f:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD20E",
    //     position: 1,
    //     utm: "POINT(737293.312599815 9548947.92774423)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20E:2",
    //     mac: "b4:e6:2d:99:08:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD20E",
    //     position: 2,
    //     utm: "POINT(737305.001373213 9548963.01907864)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20E:3",
    //     mac: "b4:e6:2d:ac:8e:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD20E",
    //     position: 3,
    //     utm: "POINT(737316.690150126 9548978.11041355)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20E:4",
    //     mac: "b4:e6:2d:ac:bd:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD20E",
    //     position: 4,
    //     utm: "POINT(737328.378930317 9548993.20174895)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20E:5",
    //     mac: "b4:e6:2d:98:f4:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD20E",
    //     position: 5,
    //     utm: "POINT(737340.067714025 9549008.29308485)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20E:6",
    //     mac: "b4:e6:2d:ad:8f:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD20E",
    //     position: 6,
    //     utm: "POINT(737351.756501013 9549023.38442124)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:1",
    //     mac: "b4:e6:2d:98:90:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 1,
    //     utm: "POINT(737257.724418244 9548952.68318733)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:2",
    //     mac: "b4:e6:2d:ad:8f:5d",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 2,
    //     utm: "POINT(737266.536508309 9548962.05578328)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:3",
    //     mac: "b4:e6:2d:aa:fb:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 3,
    //     utm: "POINT(737285.311440197 9548964.07751404)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:4",
    //     mac: "84:0d:8e:0b:cf:ac",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 4,
    //     utm: "POINT(737296.818533824 9548978.25192641)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:5",
    //     mac: "b4:e6:2d:99:8f:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 5,
    //     utm: "POINT(737308.923361588 9548992.86865996)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:6",
    //     mac: "b4:e6:2d:ac:e8:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 6,
    //     utm: "POINT(737320.435968827 9549008.818683)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:7",
    //     mac: "b4:e6:2d:98:95:0d",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 7,
    //     utm: "POINT(737332.242959565 9549023.58418136)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD20W:8",
    //     mac: "b4:e6:2d:aa:fa:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD20W",
    //     position: 8,
    //     utm: "POINT(737343.900208709 9549038.05414326)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21E:1",
    //     mac: "b4:e6:2d:ad:9b:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD21E",
    //     position: 1,
    //     utm: "POINT(737255.853351514 9548949.35886849)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21E:2",
    //     mac: "b4:e6:2d:98:f1:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD21E",
    //     position: 2,
    //     utm: "POINT(737267.586623091 9548964.27251969)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21E:3",
    //     mac: "b4:e6:2d:98:f4:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD21E",
    //     position: 3,
    //     utm: "POINT(737277.449265036 9548976.37974146)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21E:4",
    //     mac: "b4:e6:2d:ad:8d:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD21E",
    //     position: 4,
    //     utm: "POINT(737289.258445941 9548991.88506971)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c8:df:84:d3:83:b2",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:1",
    //     mac: "b4:e6:2d:98:94:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 1,
    //     utm: "POINT(737238.504759978 9548951.47645757)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:2",
    //     mac: "b4:e6:2d:ac:8e:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 2,
    //     utm: "POINT(737250.064263392 9548966.48922292)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:3",
    //     mac: "b4:e6:2d:98:a5:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 3,
    //     utm: "POINT(737261.623770139 9548981.50198877)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:4",
    //     mac: "b4:e6:2d:99:13:8d",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 4,
    //     utm: "POINT(737273.183280213 9548996.51475511)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:5",
    //     mac: "b4:e6:2d:98:f5:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 5,
    //     utm: "POINT(737284.742793618 9549011.52752194)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:6",
    //     mac: "b4:e6:2d:ad:8d:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 6,
    //     utm: "POINT(737296.302310353 9549026.54028927)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD21W:7",
    //     mac: "b4:e6:2d:ac:72:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD21W",
    //     position: 7,
    //     utm: "POINT(737307.861830418 9549041.55305709)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:1",
    //     mac: "24:0a:c4:51:0e:f8",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 1,
    //     utm: "POINT(737225.71539997 9548958.90879985)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:2",
    //     mac: "b4:e6:2d:98:f2:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 2,
    //     utm: "POINT(737237.399370758 9548974.14318968)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:3",
    //     mac: "24:0a:c4:51:09:0c",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 3,
    //     utm: "POINT(737249.083344955 9548989.37758003)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:4",
    //     mac: "b4:e6:2d:98:a6:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 4,
    //     utm: "POINT(737260.767322557 9549004.61197089)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:5",
    //     mac: "b4:e6:2d:99:11:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 5,
    //     utm: "POINT(737272.451303567 9549019.84636227)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:6",
    //     mac: "b4:e6:2d:ac:0c:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 6,
    //     utm: "POINT(737284.135287983 9549035.08075416)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22E:7",
    //     mac: "b4:e6:2d:ac:be:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD22E",
    //     position: 7,
    //     utm: "POINT(737295.81927569 9549050.31514656)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:1",
    //     mac: "b4:e6:2d:ad:90:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 1,
    //     utm: "POINT(737213.371388007 9548966.9318309)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:10",
    //     mac: "b4:e6:2d:ac:46:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 10,
    //     utm: "POINT(737318.410235993 9549102.93199655)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:2",
    //     mac: "b4:e6:2d:ac:57:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 2,
    //     utm: "POINT(737225.042357606 9548982.04295842)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:3",
    //     mac: "b4:e6:2d:ad:9b:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 3,
    //     utm: "POINT(737236.713330483 9548997.15408644)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:4",
    //     mac: "b4:e6:2d:ab:cc:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 4,
    //     utm: "POINT(737248.384306865 9549012.26521496)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:5",
    //     mac: "b4:e6:2d:98:f4:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 5,
    //     utm: "POINT(737260.055286518 9549027.37634398)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:6",
    //     mac: "b4:e6:2d:ac:88:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 6,
    //     utm: "POINT(737271.726269682 9549042.4874735)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:7",
    //     mac: "b4:e6:2d:ac:ee:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 7,
    //     utm: "POINT(737283.397256118 9549057.59860351)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:8",
    //     mac: "b4:e6:2d:ac:57:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 8,
    //     utm: "POINT(737295.068246057 9549072.70973403)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD22W:9",
    //     mac: "b4:e6:2d:ad:8e:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD22W",
    //     position: 9,
    //     utm: "POINT(737306.739239275 9549087.82086504)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:1",
    //     mac: "b4:e6:2d:98:f5:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 1,
    //     utm: "POINT(737201.627001734 9548975.6931099)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:10",
    //     mac: "b4:e6:2d:98:a4:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 10,
    //     utm: "POINT(737305.999147849 9549111.5840909)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:11",
    //     mac: "b4:e6:2d:ad:9a:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 11,
    //     utm: "POINT(737317.596069802 9549126.68309131)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:12",
    //     mac: "b4:e6:2d:ac:56:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 12,
    //     utm: "POINT(737329.192994995 9549141.78209222)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:2",
    //     mac: "b4:e6:2d:98:a5:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 2,
    //     utm: "POINT(737213.223893503 9548990.79210577)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:3",
    //     mac: "b4:e6:2d:ac:6a:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 3,
    //     utm: "POINT(737224.820788517 9549005.89110216)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:4",
    //     mac: "b4:e6:2d:ac:be:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 4,
    //     utm: "POINT(737236.417686993 9549020.99009904)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:5",
    //     mac: "b4:e6:2d:ac:45:e9",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 5,
    //     utm: "POINT(737248.014588715 9549036.08909643)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:6",
    //     mac: "b4:e6:2d:ac:56:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 6,
    //     utm: "POINT(737259.611493794 9549051.18809431)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:7",
    //     mac: "b4:e6:2d:ac:7b:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 7,
    //     utm: "POINT(737271.208402334 9549066.28709271)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:8",
    //     mac: "b4:e6:2d:98:f4:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 8,
    //     utm: "POINT(737282.805314113 9549081.3860916)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23E:9",
    //     mac: "b4:e6:2d:ab:ca:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD23E",
    //     position: 9,
    //     utm: "POINT(737294.40222936 9549096.485091)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "40:bd:32:e6:3e:31",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:1",
    //     mac: "b4:e6:2d:ac:4c:91",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 1,
    //     utm: "POINT(737187.944965017 9548984.01562241)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:10",
    //     mac: "b4:e6:2d:98:f4:91",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 10,
    //     utm: "POINT(737292.44108375 9549118.74532392)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:11",
    //     mac: "b4:e6:2d:ad:9a:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 11,
    //     utm: "POINT(737304.051780394 9549133.71529318)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:12",
    //     mac: "b4:e6:2d:98:f4:e9",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 12,
    //     utm: "POINT(737315.662480274 9549148.68526292)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:13",
    //     mac: "b4:e6:2d:ad:9b:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 13,
    //     utm: "POINT(737327.273183616 9549163.65523316)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:2",
    //     mac: "b4:e6:2d:ad:8e:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 2,
    //     utm: "POINT(737199.555631401 9548998.9855873)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:3",
    //     mac: "b4:e6:2d:ac:4d:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 3,
    //     utm: "POINT(737211.166301246 9549013.95555268)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:4",
    //     mac: "b4:e6:2d:ac:4c:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 4,
    //     utm: "POINT(737222.776974447 9549028.92551855)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:5",
    //     mac: "b4:e6:2d:ad:8e:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 5,
    //     utm: "POINT(737234.387650884 9549043.8954849)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:6",
    //     mac: "b4:e6:2d:aa:55:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 6,
    //     utm: "POINT(737245.998330778 9549058.86545173)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:7",
    //     mac: "b4:e6:2d:ad:8e:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 7,
    //     utm: "POINT(737257.609014026 9549073.83541905)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:8",
    //     mac: "b4:e6:2d:99:09:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 8,
    //     utm: "POINT(737269.219700509 9549088.80538685)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD23W:9",
    //     mac: "b4:e6:2d:ac:a2:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD23W",
    //     position: 9,
    //     utm: "POINT(737280.830390451 9549103.77535514)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:1",
    //     mac: "b4:e6:2d:ac:95:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 1,
    //     utm: "POINT(737175.303048222 9548991.44756434)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:10",
    //     mac: "b4:e6:2d:98:a4:e1",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 10,
    //     utm: "POINT(737278.820941933 9549125.34336875)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:11",
    //     mac: "b4:e6:2d:98:f1:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 11,
    //     utm: "POINT(737290.322946555 9549140.22068276)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:12",
    //     mac: "b4:e6:2d:99:04:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 12,
    //     utm: "POINT(737301.824954464 9549155.09799726)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:13",
    //     mac: "b4:e6:2d:99:10:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 13,
    //     utm: "POINT(737313.326965669 9549169.97531223)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:14",
    //     mac: "b4:e6:2d:ad:9b:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 14,
    //     utm: "POINT(737324.828980156 9549184.85262769)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:2",
    //     mac: "b4:e6:2d:ac:be:0d",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 2,
    //     utm: "POINT(737186.805023331 9549006.32487402)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:3",
    //     mac: "b4:e6:2d:ad:9f:35",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 3,
    //     utm: "POINT(737198.307001626 9549021.20218417)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:4",
    //     mac: "b4:e6:2d:98:f4:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 4,
    //     utm: "POINT(737209.808983211 9549036.07949481)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:5",
    //     mac: "b4:e6:2d:98:a5:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 5,
    //     utm: "POINT(737221.310968085 9549050.95680593)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:6",
    //     mac: "b4:e6:2d:99:03:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 6,
    //     utm: "POINT(737232.81295625 9549065.83411753)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:7",
    //     mac: "b4:e6:2d:ac:1e:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 7,
    //     utm: "POINT(737244.314947706 9549080.71142961)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:8",
    //     mac: "b4:e6:2d:99:86:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 8,
    //     utm: "POINT(737255.816942456 9549095.58874217)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24E:9",
    //     mac: "b4:e6:2d:98:f0:e9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24E",
    //     position: 9,
    //     utm: "POINT(737267.318940605 9549110.46605522)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:1",
    //     mac: "b4:e6:2d:98:a5:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 1,
    //     utm: "POINT(737163.411977155 9549000.94906695)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:10",
    //     mac: "b4:e6:2d:99:11:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 10,
    //     utm: "POINT(737267.21989061 9549134.86626523)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:11",
    //     mac: "b4:e6:2d:98:f3:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 11,
    //     utm: "POINT(737278.754119746 9549149.74595633)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:12",
    //     mac: "b4:e6:2d:ad:9e:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 12,
    //     utm: "POINT(737290.288352189 9549164.6256479)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:13",
    //     mac: "b4:e6:2d:ac:a2:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 13,
    //     utm: "POINT(737301.822587934 9549179.50533996)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:14",
    //     mac: "b4:e6:2d:98:94:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 14,
    //     utm: "POINT(737313.356826984 9549194.3850325)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:15",
    //     mac: "b4:e6:2d:ac:c9:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 15,
    //     utm: "POINT(737324.891069343 9549209.26472552)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:16",
    //     mac: "b4:e6:2d:ad:8f:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 16,
    //     utm: "POINT(737336.425315005 9549224.14441902)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:2",
    //     mac: "b4:e6:2d:98:a7:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 2,
    //     utm: "POINT(737174.946176536 9549015.82875373)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:3",
    //     mac: "b4:e6:2d:98:f0:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 3,
    //     utm: "POINT(737186.480379223 9549030.70844099)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:4",
    //     mac: "b4:e6:2d:99:05:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 4,
    //     utm: "POINT(737198.014585217 9549045.58812873)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:5",
    //     mac: "b4:e6:2d:ad:9b:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 5,
    //     utm: "POINT(737209.548794515 9549060.46781695)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:6",
    //     mac: "b4:e6:2d:ac:15:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 6,
    //     utm: "POINT(737221.083007122 9549075.34750565)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:7",
    //     mac: "b4:e6:2d:ac:64:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 7,
    //     utm: "POINT(737232.617223035 9549090.22719482)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:8",
    //     mac: "b4:e6:2d:99:11:c5",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 8,
    //     utm: "POINT(737244.151442255 9549105.10688448)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD24W:9",
    //     mac: "b4:e6:2d:99:11:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD24W",
    //     position: 9,
    //     utm: "POINT(737255.685664777 9549119.98657462)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:1",
    //     mac: "b4:e6:2d:99:04:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 1,
    //     utm: "POINT(737151.073931899 9549010.89565759)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:10",
    //     mac: "b4:e6:2d:98:a8:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 10,
    //     utm: "POINT(737254.209038256 9549143.57169606)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:11",
    //     mac: "b4:e6:2d:99:11:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 11,
    //     utm: "POINT(737265.668510869 9549158.31348046)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:12",
    //     mac: "b4:e6:2d:ac:46:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 12,
    //     utm: "POINT(737277.127986737 9549173.05526532)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:13",
    //     mac: "b4:e6:2d:ac:57:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 13,
    //     utm: "POINT(737288.58746576 9549187.79705065)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:2",
    //     mac: "b4:e6:2d:ac:76:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 2,
    //     utm: "POINT(737162.533375169 9549025.63743777)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:3",
    //     mac: "b4:e6:2d:98:a6:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 3,
    //     utm: "POINT(737173.99282159 9549040.37921842)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:4",
    //     mac: "b4:e6:2d:98:f1:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 4,
    //     utm: "POINT(737185.452271379 9549055.12099954)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:5",
    //     mac: "b4:e6:2d:98:a8:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 5,
    //     utm: "POINT(737196.91172432 9549069.86278112)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:6",
    //     mac: "b4:e6:2d:98:a5:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 6,
    //     utm: "POINT(737208.37118063 9549084.60456318)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:7",
    //     mac: "b4:e6:2d:98:f1:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 7,
    //     utm: "POINT(737219.830640202 9549099.3463457)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:8",
    //     mac: "b4:e6:2d:98:e7:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 8,
    //     utm: "POINT(737231.290102922 9549114.08812868)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25E:9",
    //     mac: "b4:e6:2d:98:a7:99",
    //     area: "DMLZ_Undercut",
    //     location: "DD25E",
    //     position: 9,
    //     utm: "POINT(737242.749569018 9549128.82991214)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:c6",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:1",
    //     mac: "b4:e6:2d:99:15:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 1,
    //     utm: "POINT(737139.030894716 9549019.43576608)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:10",
    //     mac: "b4:e6:2d:ac:a5:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 10,
    //     utm: "POINT(737242.566610207 9549152.92741232)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:11",
    //     mac: "b4:e6:2d:98:f6:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 11,
    //     utm: "POINT(737254.070595058 9549167.75981984)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:12",
    //     mac: "b4:e6:2d:99:10:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 12,
    //     utm: "POINT(737265.574583196 9549182.59222783)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:13",
    //     mac: "b4:e6:2d:98:f7:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 13,
    //     utm: "POINT(737277.078574625 9549197.4246363)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:14",
    //     mac: "b4:e6:2d:ac:46:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 14,
    //     utm: "POINT(737288.582569228 9549212.25704525)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:15",
    //     mac: "b4:e6:2d:98:a3:d1",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 15,
    //     utm: "POINT(737300.086567228 9549227.08945468)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:16",
    //     mac: "b4:e6:2d:ad:9c:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 16,
    //     utm: "POINT(737311.590568518 9549241.92186458)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:17",
    //     mac: "b4:e6:2d:ac:49:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 17,
    //     utm: "POINT(737323.094573091 9549256.75427496)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:18",
    //     mac: "b4:e6:2d:98:f7:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 18,
    //     utm: "POINT(737334.598580843 9549271.58668581)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:19",
    //     mac: "b4:e6:2d:ac:59:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 19,
    //     utm: "POINT(737346.10259199 9549286.41909715)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:2",
    //     mac: "b4:e6:2d:98:a7:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 2,
    //     utm: "POINT(737150.534849974 9549034.26816932)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:20",
    //     mac: "b4:e6:2d:ac:46:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 20,
    //     utm: "POINT(737357.606606425 9549301.25150896)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:21",
    //     mac: "b4:e6:2d:ad:8e:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 21,
    //     utm: "POINT(737369.110624145 9549316.08392125)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:22",
    //     mac: "c4:4f:33:23:57:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 22,
    //     utm: "POINT(737380.614645152 9549330.91633402)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:23",
    //     mac: "b4:e6:2d:98:a3:8d",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 23,
    //     utm: "POINT(737392.118669332 9549345.74874726)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:24",
    //     mac: "b4:e6:2d:99:10:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 24,
    //     utm: "POINT(737403.622696909 9549360.58116099)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:25",
    //     mac: "b4:e6:2d:ac:4e:e9",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 25,
    //     utm: "POINT(737415.126727773 9549375.41357519)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:26",
    //     mac: "b4:e6:2d:aa:fa:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 26,
    //     utm: "POINT(737426.63076192 9549390.24598987)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:27",
    //     mac: "b4:e6:2d:ac:4d:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 27,
    //     utm: "POINT(737438.134799242 9549405.07840503)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:28",
    //     mac: "b4:e6:2d:ac:48:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 28,
    //     utm: "POINT(737449.638839963 9549419.91082067)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:29",
    //     mac: "b4:e6:2d:a5:11:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 29,
    //     utm: "POINT(737461.142883965 9549434.74323679)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:3",
    //     mac: "b4:e6:2d:ac:8a:c5",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 3,
    //     utm: "POINT(737162.03880852 9549049.10057303)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:4",
    //     mac: "b4:e6:2d:98:f7:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 4,
    //     utm: "POINT(737173.542770357 9549063.93297721)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:5",
    //     mac: "b4:e6:2d:ac:7e:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 5,
    //     utm: "POINT(737185.04673537 9549078.76538188)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:6",
    //     mac: "b4:e6:2d:ac:04:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 6,
    //     utm: "POINT(737196.550703785 9549093.59778701)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:7",
    //     mac: "b4:e6:2d:98:9a:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 7,
    //     utm: "POINT(737208.054675487 9549108.43019263)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:8",
    //     mac: "b4:e6:2d:99:14:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 8,
    //     utm: "POINT(737219.558650475 9549123.26259871)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD25W:9",
    //     mac: "b4:e6:2d:98:f1:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD25W",
    //     position: 9,
    //     utm: "POINT(737231.062628751 9549138.09500528)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:1",
    //     mac: "b4:e6:2d:98:a5:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 1,
    //     utm: "POINT(737137.658614183 9549021.54795184)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:10",
    //     mac: "b4:e6:2d:98:a7:91",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 10,
    //     utm: "POINT(737204.719098784 9549130.46463651)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:11",
    //     mac: "b4:e6:2d:99:04:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 11,
    //     utm: "POINT(737251.501642984 9549190.10812736)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:12",
    //     mac: "b4:e6:2d:98:f1:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 12,
    //     utm: "POINT(737216.974303327 9549145.67456914)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:13",
    //     mac: "b4:e6:2d:99:06:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 13,
    //     utm: "POINT(737262.524361159 9549203.88377993)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:14",
    //     mac: "b4:e6:2d:ac:84:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 14,
    //     utm: "POINT(737274.218616433 9549218.24271148)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:15",
    //     mac: "b4:e6:2d:99:8f:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 15,
    //     utm: "POINT(737285.727561599 9549232.93506674)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:16",
    //     mac: "c4:4f:33:23:58:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 16,
    //     utm: "POINT(737298.505592605 9549248.73382409)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:17",
    //     mac: "c4:4f:33:23:ce:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 17,
    //     utm: "POINT(737310.540017532 9549264.97846328)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:18",
    //     mac: "c4:4f:33:23:67:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 18,
    //     utm: "POINT(737320.481972312 9549278.56521834)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:19",
    //     mac: "24:0a:c4:51:06:98",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 19,
    //     utm: "POINT(737332.363717613 9549293.40456553)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:2",
    //     mac: "b4:e6:2d:ad:8e:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 2,
    //     utm: "POINT(737149.203875915 9549035.90724271)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:20",
    //     mac: "4c:11:ae:b7:f3:90",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 20,
    //     utm: "POINT(737344.321122804 9549308.68762148)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:21",
    //     mac: "24:0a:c4:51:0e:38",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 21,
    //     utm: "POINT(737355.940334034 9549322.82481891)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:22",
    //     mac: "c4:4f:33:1e:b3:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 22,
    //     utm: "POINT(737367.264184003 9549337.92460031)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:23",
    //     mac: "c4:4f:33:23:cd:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 23,
    //     utm: "POINT(737378.923291298 9549353.06,048775)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:24",
    //     mac: "c4:4f:33:23:67:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 24,
    //     utm: "POINT(737390.282129784 9549367.30936097)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:25",
    //     mac: "c4:4f:33:1e:ae:8d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 25,
    //     utm: "POINT(737401.269166407 9549381.78117669)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:26",
    //     mac: "c4:4f:33:23:70:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 26,
    //     utm: "POINT(737412.553924846 9549396.17822095)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:27",
    //     mac: "c4:4f:33:23:6b:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 27,
    //     utm: "POINT(737424.434912098 9549410.72165542)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:3",
    //     mac: "b4:e6:2d:98:f4:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 3,
    //     utm: "POINT(737161.011286401 9549050.82072206)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:4",
    //     mac: "b4:e6:2d:ac:6f:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 4,
    //     utm: "POINT(737164.701904971 9549066.27361878)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:6",
    //     mac: "b4:e6:2d:ad:93:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 6,
    //     utm: "POINT(737169.886855373 9549083.505955)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:7",
    //     mac: "b4:e6:2d:98:fc:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 7,
    //     utm: "POINT(737181.695229979 9549098.85500633)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26E:9",
    //     mac: "b4:e6:2d:ad:12:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26E",
    //     position: 9,
    //     utm: "POINT(737192.607093914 9549112.90078778)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:1",
    //     mac: "b4:e6:2d:ac:48:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 1,
    //     utm: "POINT(737118.980371178 9549041.75814751)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:10",
    //     mac: "b4:e6:2d:ac:49:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 10,
    //     utm: "POINT(737219.5655684 9549172.30701115)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:11",
    //     mac: "b4:e6:2d:ac:46:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 11,
    //     utm: "POINT(737230.741716991 9549186.81244275)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:12",
    //     mac: "b4:e6:2d:99:06:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 12,
    //     utm: "POINT(737241.917868583 9549201.31787481)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:13",
    //     mac: "b4:e6:2d:98:f3:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 13,
    //     utm: "POINT(737253.094023392 9549215.82330733)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:14",
    //     mac: "b4:e6:2d:98:f1:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 14,
    //     utm: "POINT(737264.270181314 9549230.32874031)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:15",
    //     mac: "c4:4f:33:23:cf:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 15,
    //     utm: "POINT(737275.446342346 9549244.83417376)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:16",
    //     mac: "b4:e6:2d:99:13:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 16,
    //     utm: "POINT(737286.622506484 9549259.33960766)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:17",
    //     mac: "c4:4f:33:23:66:d1",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 17,
    //     utm: "POINT(737297.798673734 9549273.84504204)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:18",
    //     mac: "c4:4f:33:24:24:d1",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 18,
    //     utm: "POINT(737308.97484409 9549288.35047687)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:19",
    //     mac: "c4:4f:33:23:d3:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 19,
    //     utm: "POINT(737320.15101756 9549302.85591216)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:2",
    //     mac: "b4:e6:2d:98:f4:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 2,
    //     utm: "POINT(737130.156491771 9549056.26357496)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:20",
    //     mac: "c4:4f:33:23:66:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 20,
    //     utm: "POINT(737331.327194022 9549317.36134792)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:21",
    //     mac: "4c:11:ae:b7:f5:f0",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 21,
    //     utm: "POINT(737342.503373711 9549331.86678414)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:22",
    //     mac: "c4:4f:33:23:64:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 22,
    //     utm: "POINT(737353.679556501 9549346.37222083)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:23",
    //     mac: "c4:4f:33:23:71:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 23,
    //     utm: "POINT(737364.855742408 9549360.87765797)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:24",
    //     mac: "4c:11:ae:b7:f4:f8",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 24,
    //     utm: "POINT(737376.031931417 9549375.38309558)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:25",
    //     mac: "24:0a:c4:50:cf:0c",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 25,
    //     utm: "POINT(737387.208123536 9549389.88853366)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:26",
    //     mac: "24:0a:c4:50:e0:78",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 26,
    //     utm: "POINT(737398.384318764 9549404.39397219)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:27",
    //     mac: "24:0a:c4:50:d2:9c",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 27,
    //     utm: "POINT(737409.855809364 9549417.86284442)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:3",
    //     mac: "b4:e6:2d:ac:48:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 3,
    //     utm: "POINT(737141.332615477 9549070.76900287)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:4",
    //     mac: "b4:e6:2d:ac:84:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 4,
    //     utm: "POINT(737152.508742291 9549085.27443124)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:5",
    //     mac: "b4:e6:2d:ac:8e:5d",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 5,
    //     utm: "POINT(737163.68487211 9549099.77986007)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:6",
    //     mac: "b4:e6:2d:ad:09:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 6,
    //     utm: "POINT(737174.861005143 9549114.28528937)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:7",
    //     mac: "b4:e6:2d:ac:4f:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 7,
    //     utm: "POINT(737186.037141295 9549128.79071912)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:8",
    //     mac: "b4:e6:2d:ac:cb:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 8,
    //     utm: "POINT(737197.213280551 9549143.29614933)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD26W:9",
    //     mac: "b4:e6:2d:99:13:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD26W",
    //     position: 9,
    //     utm: "POINT(737208.389422924 9549157.80158001)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8d:c8:3c",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:1",
    //     mac: "b4:e6:2d:ac:ae:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 1,
    //     utm: "POINT(737116.00279342 9549042.35791544)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:10",
    //     mac: "b4:e6:2d:ad:9c:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 10,
    //     utm: "POINT(737205.156173349 9549182.98739026)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:11",
    //     mac: "b4:e6:2d:98:f5:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 11,
    //     utm: "POINT(737216.817403565 9549198.93706906)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:12",
    //     mac: "b4:e6:2d:99:11:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 12,
    //     utm: "POINT(737226.978838134 9549211.04354545)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:13",
    //     mac: "b4:e6:2d:ad:96:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 13,
    //     utm: "POINT(737238.040311914 9549225.51513469)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:14",
    //     mac: "c4:4f:33:23:60:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 14,
    //     utm: "POINT(737249.40363209 9549241.46561273)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:17",
    //     mac: "24:0a:c4:50:d0:28",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 17,
    //     utm: "POINT(737282.286237442 9549283.40151997)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:18",
    //     mac: "b4:e6:2d:ac:5d:c9",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 18,
    //     utm: "POINT(737295.141524214 9549300.23586664)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:19",
    //     mac: "c4:4f:33:23:ab:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 19,
    //     utm: "POINT(737308.593439718 9549317.36457251)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:2",
    //     mac: "b4:e6:2d:ad:9a:a5",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 2,
    //     utm: "POINT(737131.247260889 9549061.4053949)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:20",
    //     mac: "24:0a:c4:50:e7:90",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 20,
    //     utm: "POINT(737321.449519778 9549334.49486253)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:21",
    //     mac: "24:0a:c4:51:0e:a4",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 21,
    //     utm: "POINT(737332.209960061 9549347.78352325)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:22",
    //     mac: "4c:11:ae:b7:fb:e0",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 22,
    //     utm: "POINT(737344.17071749 9549364.32431917)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:23",
    //     mac: "24:0a:c4:50:e8:d4",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 23,
    //     utm: "POINT(737355.527004388 9549377.61140492)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:24",
    //     mac: "4c:11:ae:b7:fd:28",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 24,
    //     utm: "POINT(737367.185923947 9549392.67331819)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:25",
    //     mac: "24:0a:c4:50:e6:d4",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 25,
    //     utm: "POINT(737380.339158312 9549409.5068995)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:26",
    //     mac: "c4:4f:33:23:9b:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 26,
    //     utm: "POINT(737395.288553913 9549429.59104019)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:3",
    //     mac: "b4:e6:2d:ac:e3:49",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 3,
    //     utm: "POINT(737139.790717651 9549081.28447308)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:4",
    //     mac: "b4:e6:2d:ab:fc:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 4,
    //     utm: "POINT(737139.984599652 9549098.22632919)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:5",
    //     mac: "b4:e6:2d:98:a5:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 5,
    //     utm: "POINT(737151.343968264 9549112.69710648)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:6",
    //     mac: "b4:e6:2d:99:15:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 6,
    //     utm: "POINT(737163.301532346 9549128.05411072)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:7",
    //     mac: "b4:e6:2d:ad:95:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 7,
    //     utm: "POINT(737173.764796077 9549141.63945941)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:8",
    //     mac: "b4:e6:2d:99:14:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 8,
    //     utm: "POINT(737182.435839363 9549153.45394154)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27E:9",
    //     mac: "b4:e6:2d:ac:56:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD27E",
    //     position: 9,
    //     utm: "POINT(737195.291094046 9549170.28825866)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27EN:1",
    //     mac: "b4:e6:2d:ac:83:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD27EN",
    //     position: 1,
    //     utm: "POINT(737436.925005438 9549458.33457794)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27EN:14",
    //     mac: "24:0a:c4:50:c7:1c",
    //     area: "DMLZ_Undercut",
    //     location: "DD27EN",
    //     position: 14,
    //     utm: "POINT(737271.524240721 9549269.52100001)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27EN:15",
    //     mac: "c4:4f:33:1e:b3:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD27EN",
    //     position: 15,
    //     utm: "POINT(737261.807712469 9549256.67349094)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27EN:2",
    //     mac: "c4:4f:33:23:ab:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD27EN",
    //     position: 2,
    //     utm: "POINT(737426.916404761 9549447.70734833)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27EN:3",
    //     mac: "c4:4f:33:23:b0:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD27EN",
    //     position: 3,
    //     utm: "POINT(737407.246190512 9549444.94810995)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:1",
    //     mac: "c4:4f:33:23:ac:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 1,
    //     utm: "POINT(737408.092361627 9549483.11453913)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:10",
    //     mac: "24:0a:c4:50:da:a8",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 10,
    //     utm: "POINT(737303.006724972 9549345.19730739)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:11",
    //     mac: "c4:4f:33:24:25:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 11,
    //     utm: "POINT(737288.361596511 9549327.48032681)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:12",
    //     mac: "4c:11:ae:b7:f3:34",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 12,
    //     utm: "POINT(737272.216674072 9549305.92069456)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:2",
    //     mac: "c4:4f:33:23:ad:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 2,
    //     utm: "POINT(737396.567402749 9549468.02340529)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:3",
    //     mac: "24:0a:c4:50:d0:b0",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 3,
    //     utm: "POINT(737385.042447183 9549452.93227195)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:4",
    //     mac: "c4:4f:33:23:ad:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 4,
    //     utm: "POINT(737373.517494932 9549437.84113912)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:5",
    //     mac: "c4:4f:33:24:25:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 5,
    //     utm: "POINT(737361.992545999 9549422.75000681)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:6",
    //     mac: "c4:4f:33:1e:bf:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 6,
    //     utm: "POINT(737350.467600381 9549407.658875)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:7",
    //     mac: "4c:11:ae:b7:fb:b0",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 7,
    //     utm: "POINT(737338.94265808 9549392.5677437)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:8",
    //     mac: "4c:11:ae:b7:fd:0c",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 8,
    //     utm: "POINT(737327.417719087 9549377.47661292)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD27N:9",
    //     mac: "c4:4f:33:1d:6e:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD27N",
    //     position: 9,
    //     utm: "POINT(737315.892783418 9549362.38548264)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:1",
    //     mac: "c4:4f:33:23:ae:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 1,
    //     utm: "POINT(737384.456121705 9549501.38186329)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:10",
    //     mac: "c4:4f:33:1e:14:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 10,
    //     utm: "POINT(737271.755733216 9549356.82196518)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:11",
    //     mac: "4c:11:ae:b7:fb:b4",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 11,
    //     utm: "POINT(737258.595468606 9549337.32497276)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:12",
    //     mac: "24:0a:c4:50:cf:cc",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 12,
    //     utm: "POINT(737239.470535009 9549315.47629963)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:2",
    //     mac: "c4:4f:33:1d:0a:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 2,
    //     utm: "POINT(737373.097216147 9549487.05265286)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:3",
    //     mac: "24:0a:c4:50:ff:9c",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 3,
    //     utm: "POINT(737361.440005232 9549472.5762019)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:4",
    //     mac: "4c:11:ae:b7:f5:34",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 4,
    //     utm: "POINT(737351.276375357 9549459.89345333)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:5",
    //     mac: "c4:4f:33:23:67:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 5,
    //     utm: "POINT(737341.105489456 9549444.2357017)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:6",
    //     mac: "c4:4f:33:23:54:bd",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 6,
    //     utm: "POINT(737327.957756808 9549429.47366103)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:7",
    //     mac: "c4:4f:33:1e:b1:41",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 7,
    //     utm: "POINT(737315.998570678 9549413.52471982)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:8",
    //     mac: "24:0a:c4:50:cb:c8",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 8,
    //     utm: "POINT(737300.453360267 9549393.44216014)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD28N:9",
    //     mac: "c4:4f:33:23:ad:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD28N",
    //     position: 9,
    //     utm: "POINT(737288.487904952 9549375.12574104)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:1",
    //     mac: "24:0a:c4:50:e8:d8",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 1,
    //     utm: "POINT(737360.731076333 9549519.20220685)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:10",
    //     mac: "4c:11:ae:b7:fc:b4",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 10,
    //     utm: "POINT(737249.612092855 9549376.26354977)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:11",
    //     mac: "24:0a:c4:50:e6:6c",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 11,
    //     utm: "POINT(737234.067292832 9549356.33000879)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:12",
    //     mac: "24:0a:c4:51:09:a4",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 12,
    //     utm: "POINT(737217.474696157 9549334.47591752)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:13",
    //     mac: "4c:11:ae:b7:fb:f8",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 13,
    //     utm: "POINT(737202.526150683 9549314.68812409)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:14",
    //     mac: "24:0a:c4:50:ff:d0",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 14,
    //     utm: "POINT(737187.573680892 9549293.41948642)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:15",
    //     mac: "4c:11:ae:b7:fc:9c",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 15,
    //     utm: "POINT(737172.62826927 9549274.81466265)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:16",
    //     mac: "24:0a:c4:51:0f:24",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 16,
    //     utm: "POINT(737157.976868661 9549254.73003011)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:17",
    //     mac: "c4:4f:33:1e:5f:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 17,
    //     utm: "POINT(737142.731203044 9549235.23859174)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:18",
    //     mac: "24:0a:c4:51:09:2c",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 18,
    //     utm: "POINT(737126.890487886 9549216.04428163)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:19",
    //     mac: "c4:4f:33:23:aa:e9",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 19,
    //     utm: "POINT(737111.045861656 9549195.37043265)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:2",
    //     mac: "c4:4f:33:23:ad:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 2,
    //     utm: "POINT(737350.804131178 9549506.50406714)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:20",
    //     mac: "c4:4f:33:23:a9:d9",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 20,
    //     utm: "POINT(737095.802571214 9549176.76681393)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:21",
    //     mac: "c4:4f:33:23:ab:35",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 21,
    //     utm: "POINT(737082.34208999 9549156.38285426)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:22",
    //     mac: "c4:4f:33:1d:bc:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 22,
    //     utm: "POINT(737067.40300109 9549140.14645356)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:23",
    //     mac: "c4:4f:33:1e:a4:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 23,
    //     utm: "POINT(737053.349832146 9549120.94808193)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:24",
    //     mac: "24:0a:c4:51:08:fc",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 24,
    //     utm: "POINT(737038.401338469 9549101.15917243)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:3",
    //     mac: "4c:11:ae:b7:f7:3c",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 3,
    //     utm: "POINT(737340.041713892 9549492.47579417)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:4",
    //     mac: "4c:11:ae:b7:f4:64",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 4,
    //     utm: "POINT(737329.132324318 9549479.18625986)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:5",
    //     mac: "24:0a:c4:51:0e:b0",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 5,
    //     utm: "POINT(737316.876780573 9549463.83133721)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:6",
    //     mac: "c4:4f:33:23:8e:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 6,
    //     utm: "POINT(737304.322136154 9549448.03271699)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:7",
    //     mac: "24:0a:c4:51:09:30",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 7,
    //     utm: "POINT(737291.61580012 9549431.19774165)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:8",
    //     mac: "c4:4f:33:1d:f5:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 8,
    //     utm: "POINT(737279.209345927 9549415.10162434)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD29N:9",
    //     mac: "c4:4f:33:23:6a:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD29N",
    //     position: 9,
    //     utm: "POINT(737264.858185126 9549395.90351454)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "d0:ff:50:39:a3:10",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:1",
    //     mac: "4c:11:ae:b7:f9:a0",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 1,
    //     utm: "POINT(737337.782986075 9549539.24720912)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:2",
    //     mac: "4c:11:ae:b7:fc:70",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 2,
    //     utm: "POINT(737326.424414206 9549525.36136617)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:3",
    //     mac: "c4:4f:33:23:ac:19",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 3,
    //     utm: "POINT(737314.768642399 9549511.48305381)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:4",
    //     mac: "c4:4f:33:1e:bc:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 4,
    //     utm: "POINT(737304.603285352 9549497.89706435)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:5",
    //     mac: "24:0a:c4:50:db:38",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 5,
    //     utm: "POINT(737292.045901752 9549481.06176567)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:6",
    //     mac: "c4:4f:33:23:a9:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 6,
    //     utm: "POINT(737279.493121846 9549465.99919983)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD30N:7",
    //     mac: "24:0a:c4:50:e4:34",
    //     area: "DMLZ_Undercut",
    //     location: "DD30N",
    //     position: 7,
    //     utm: "POINT(737267.83257061 9549450.34372843)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:32:ab",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:1",
    //     mac: "b4:e6:2d:ad:9a:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 1,
    //     utm: "POINT(737649.86427865 9548645.12611332)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:10",
    //     mac: "c4:4f:33:1e:a6:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 10,
    //     utm: "POINT(737696.951366393 9548706.99780271)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:11",
    //     mac: "c4:4f:33:23:54:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 11,
    //     utm: "POINT(737769.447787569 9548801.21079836)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:12",
    //     mac: "4c:11:ae:b7:fb:ac",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 12,
    //     utm: "POINT(737714.589958217 9548730.03470379)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:13",
    //     mac: "c4:4f:33:23:a3:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 13,
    //     utm: "POINT(737792.018616801 9548830.44797857)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:14",
    //     mac: "c4:4f:33:23:ac:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 14,
    //     utm: "POINT(737803.379700636 9548845.5098209)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:2",
    //     mac: "b4:e6:2d:98:9a:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 2,
    //     utm: "POINT(737659.281366778 9548657.38202132)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:3",
    //     mac: "b4:e6:2d:98:fd:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 3,
    //     utm: "POINT(737668.696886076 9548669.04645431)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:4",
    //     mac: "b4:e6:2d:ac:7d:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 4,
    //     utm: "POINT(737663.916024269 9548663.73238544)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:5",
    //     mac: "b4:e6:2d:ac:85:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 5,
    //     utm: "POINT(737688.730655738 9548696.51422403)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:6",
    //     mac: "b4:e6:2d:ac:81:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 6,
    //     utm: "POINT(737673.48208387 9548675.98835656)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:7",
    //     mac: "b4:e6:2d:ad:8f:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 7,
    //     utm: "POINT(737724.605266783 9548743.17827805)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:8",
    //     mac: "b4:e6:2d:98:9a:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 8,
    //     utm: "POINT(737735.365763504 9548756.46718886)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD4W:9",
    //     mac: "b4:e6:2d:98:f5:91",
    //     area: "DMLZ_Undercut",
    //     location: "DD4W",
    //     position: 9,
    //     utm: "POINT(737746.576685703 9548771.08687933)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:1",
    //     mac: "b4:e6:2d:ad:8f:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 1,
    //     utm: "POINT(737629.459247961 9548646.51144495)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:10",
    //     mac: "b4:e6:2d:99:13:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 10,
    //     utm: "POINT(737738.549970292 9548787.74848454)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:11",
    //     mac: "b4:e6:2d:ac:49:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 11,
    //     utm: "POINT(737750.671179987 9548803.44149164)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:12",
    //     mac: "b4:e6:2d:ac:05:0d",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 12,
    //     utm: "POINT(737761.752081513 9548820.09600174)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:13",
    //     mac: "b4:e6:2d:aa:fb:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 13,
    //     utm: "POINT(737771.467127634 9548832.35173358)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:2",
    //     mac: "b4:e6:2d:ac:7b:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 2,
    //     utm: "POINT(737641.580424622 9548662.20444719)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:3",
    //     mac: "b4:e6:2d:ad:9c:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 3,
    //     utm: "POINT(737653.701605052 9548677.89744997)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:4",
    //     mac: "b4:e6:2d:ac:65:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 4,
    //     utm: "POINT(737665.822789147 9548693.59045329)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:5",
    //     mac: "b4:e6:2d:ad:8f:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 5,
    //     utm: "POINT(737677.943976891 9548709.28345714)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:6",
    //     mac: "b4:e6:2d:99:12:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 6,
    //     utm: "POINT(737690.06516819 9548724.97646154)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:7",
    //     mac: "b4:e6:2d:ac:4d:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 7,
    //     utm: "POINT(737702.186363257 9548740.66946648)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:8",
    //     mac: "84:0d:8e:0b:cf:94",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 8,
    //     utm: "POINT(737714.307561978 9548756.36247196)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5E:9",
    //     mac: "b4:e6:2d:99:12:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD5E",
    //     position: 9,
    //     utm: "POINT(737726.428764249 9548772.05547798)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:1",
    //     mac: "b4:e6:2d:98:a2:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 1,
    //     utm: "POINT(737610.539475269 9548645.67395374)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:10",
    //     mac: "b4:e6:2d:ac:85:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 10,
    //     utm: "POINT(737717.23704155 9548783.72199047)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:11",
    //     mac: "b4:e6:2d:99:04:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 11,
    //     utm: "POINT(737729.09234421 9548799.0606638)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:12",
    //     mac: "b4:e6:2d:98:f5:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 12,
    //     utm: "POINT(737740.947650368 9548814.39933764)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:13",
    //     mac: "b4:e6:2d:ac:04:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 13,
    //     utm: "POINT(737752.80296003 9548829.738012)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:14",
    //     mac: "b4:e6:2d:ac:46:35",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 14,
    //     utm: "POINT(737764.65827318 9548845.07668687)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:15",
    //     mac: "b4:e6:2d:99:8f:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 15,
    //     utm: "POINT(737776.513589831 9548860.41536226)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:16",
    //     mac: "b4:e6:2d:99:13:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 16,
    //     utm: "POINT(737788.368909979 9548875.75403817)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:17",
    //     mac: "b4:e6:2d:ac:46:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 17,
    //     utm: "POINT(737800.224233509 9548891.0927146)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:2",
    //     mac: "b4:e6:2d:ac:10:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 2,
    //     utm: "POINT(737622.394746441 9548661.01262243)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:3",
    //     mac: "b4:e6:2d:98:a8:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 3,
    //     utm: "POINT(737634.250020996 9548676.35129163)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:4",
    //     mac: "b4:e6:2d:ab:e6:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 4,
    //     utm: "POINT(737646.105299166 9548691.68996135)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:5",
    //     mac: "b4:e6:2d:ad:9a:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 5,
    //     utm: "POINT(737657.960580836 9548707.02863158)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:6",
    //     mac: "b4:e6:2d:ac:04:c5",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 6,
    //     utm: "POINT(737669.815866004 9548722.36730233)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:7",
    //     mac: "b4:e6:2d:ac:4f:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 7,
    //     utm: "POINT(737681.671154674 9548737.70597359)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:8",
    //     mac: "b4:e6:2d:ac:4d:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 8,
    //     utm: "POINT(737693.526446839 9548753.04464537)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD5W:9",
    //     mac: "b4:e6:2d:ac:4f:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD5W",
    //     position: 9,
    //     utm: "POINT(737705.381742504 9548768.38331766)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:1",
    //     mac: "b4:e6:2d:aa:fb:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 1,
    //     utm: "POINT(737588.334572933 9548642.03380305)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:10",
    //     mac: "b4:e6:2d:ad:8e:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 10,
    //     utm: "POINT(737693.013126101 9548777.00761631)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:11",
    //     mac: "b4:e6:2d:ac:4f:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 11,
    //     utm: "POINT(737704.64409328 9548792.00470912)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:12",
    //     mac: "b4:e6:2d:98:f6:95",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 12,
    //     utm: "POINT(737716.275063824 9548807.00180242)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:13",
    //     mac: "b4:e6:2d:99:04:69",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 13,
    //     utm: "POINT(737727.90603773 9548821.99889621)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:14",
    //     mac: "b4:e6:2d:98:a7:b9",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 14,
    //     utm: "POINT(737739.537014886 9548836.99599049)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:15",
    //     mac: "b4:e6:2d:98:a2:49",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 15,
    //     utm: "POINT(737751.167995518 9548851.99308526)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:16",
    //     mac: "b4:e6:2d:ac:47:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 16,
    //     utm: "POINT(737762.798979508 9548866.99018052)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:17",
    //     mac: "b4:e6:2d:ac:0c:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 17,
    //     utm: "POINT(737774.429966864 9548881.98727627)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:2",
    //     mac: "b4:e6:2d:ad:4c:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 2,
    //     utm: "POINT(737599.965509844 9548657.03089146)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:3",
    //     mac: "b4:e6:2d:ad:9e:49",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 3,
    //     utm: "POINT(737611.596450002 9548672.02798036)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:4",
    //     mac: "b4:e6:2d:99:8f:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 4,
    //     utm: "POINT(737623.227393643 9548687.02506974)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:5",
    //     mac: "b4:e6:2d:ab:fc:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 5,
    //     utm: "POINT(737634.858340639 9548702.02215961)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:6",
    //     mac: "b4:e6:2d:ad:6c:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 6,
    //     utm: "POINT(737646.489291004 9548717.01924997)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:7",
    //     mac: "b4:e6:2d:98:f2:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 7,
    //     utm: "POINT(737658.120244737 9548732.01634083)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:8",
    //     mac: "b4:e6:2d:ad:2b:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 8,
    //     utm: "POINT(737669.751201823 9548747.01343216)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6E:9",
    //     mac: "b4:e6:2d:ab:fc:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD6E",
    //     position: 9,
    //     utm: "POINT(737681.38216228 9548762.01052399)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:1",
    //     mac: "b4:e6:2d:ac:46:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 1,
    //     utm: "POINT(737570.61017851 9548642.52483698)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:10",
    //     mac: "b4:e6:2d:98:f4:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 10,
    //     utm: "POINT(737676.079516748 9548779.14454158)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:11",
    //     mac: "b4:e6:2d:99:03:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 11,
    //     utm: "POINT(737687.798349136 9548794.32451129)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:12",
    //     mac: "b4:e6:2d:aa:54:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 12,
    //     utm: "POINT(737699.517185051 9548809.5044815)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:13",
    //     mac: "b4:e6:2d:ac:49:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 13,
    //     utm: "POINT(737711.236024277 9548824.68445222)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:14",
    //     mac: "b4:e6:2d:aa:54:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 14,
    //     utm: "POINT(737722.954867032 9548839.86442345)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:15",
    //     mac: "b4:e6:2d:ac:46:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 15,
    //     utm: "POINT(737734.673713093 9548855.04439518)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:16",
    //     mac: "b4:e6:2d:98:f6:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 16,
    //     utm: "POINT(737746.392562687 9548870.22436742)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:2",
    //     mac: "c4:4f:33:23:66:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 2,
    //     utm: "POINT(737580.999246612 9548656.03056541)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:3",
    //     mac: "b4:e6:2d:99:15:6d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 3,
    //     utm: "POINT(737594.04778536 9548672.8847678)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:4",
    //     mac: "b4:e6:2d:99:06:1d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 4,
    //     utm: "POINT(737605.766593808 9548688.06473396)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:5",
    //     mac: "b4:e6:2d:ad:9c:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 5,
    //     utm: "POINT(737617.485405783 9548703.24470064)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:6",
    //     mac: "b4:e6:2d:ac:8a:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 6,
    //     utm: "POINT(737629.204221071 9548718.42466782)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:7",
    //     mac: "b4:e6:2d:98:a8:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 7,
    //     utm: "POINT(737640.923039888 9548733.6046355)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:8",
    //     mac: "b4:e6:2d:98:f4:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 8,
    //     utm: "POINT(737652.641862014 9548748.78460369)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD6W:9",
    //     mac: "b4:e6:2d:98:a2:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD6W",
    //     position: 9,
    //     utm: "POINT(737664.360687673 9548763.96457238)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:1",
    //     mac: "b4:e6:2d:ac:47:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 1,
    //     utm: "POINT(737557.672448927 9548650.9934096)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:10",
    //     mac: "b4:e6:2d:99:8f:5d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 10,
    //     utm: "POINT(737663.098307448 9548787.0385586)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:11",
    //     mac: "b4:e6:2d:ac:6d:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 11,
    //     utm: "POINT(737674.812308776 9548802.15468875)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:12",
    //     mac: "b4:e6:2d:ac:50:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 12,
    //     utm: "POINT(737686.526313518 9548817.27081941)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:13",
    //     mac: "b4:e6:2d:ad:9b:cd",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 13,
    //     utm: "POINT(737698.24032167 9548832.38695056)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:14",
    //     mac: "b4:e6:2d:ad:9b:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 14,
    //     utm: "POINT(737709.954333233 9548847.50308221)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:15",
    //     mac: "b4:e6:2d:98:a2:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 15,
    //     utm: "POINT(737721.66834821 9548862.61921435)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:16",
    //     mac: "b4:e6:2d:98:f5:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 16,
    //     utm: "POINT(737733.382366598 9548877.735347)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:17",
    //     mac: "b4:e6:2d:ac:4c:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 17,
    //     utm: "POINT(737745.096388395 9548892.85148015)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:18",
    //     mac: "b4:e6:2d:ac:4d:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 18,
    //     utm: "POINT(737756.810413605 9548907.96761379)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:19",
    //     mac: "b4:e6:2d:aa:54:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 19,
    //     utm: "POINT(737768.524442224 9548923.08374794)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:2",
    //     mac: "b4:e6:2d:99:8f:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 2,
    //     utm: "POINT(737569.386419544 9548666.10953528)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:20",
    //     mac: "b4:e6:2d:ad:9b:29",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 20,
    //     utm: "POINT(737780.238474252 9548938.19988258)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:3",
    //     mac: "b4:e6:2d:aa:54:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 3,
    //     utm: "POINT(737581.100393571 9548681.22566146)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:4",
    //     mac: "b4:e6:2d:98:a3:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 4,
    //     utm: "POINT(737592.814371012 9548696.34178813)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:5",
    //     mac: "b4:e6:2d:aa:fa:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 5,
    //     utm: "POINT(737604.52835187 9548711.45791529)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:6",
    //     mac: "b4:e6:2d:98:a2:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 6,
    //     utm: "POINT(737616.24233625 9548726.57404296)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:7",
    //     mac: "b4:e6:2d:ac:9c:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 7,
    //     utm: "POINT(737627.956323928 9548741.69017113)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:8",
    //     mac: "b4:e6:2d:ab:fd:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 8,
    //     utm: "POINT(737639.67031502 9548756.80629979)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7E:9",
    //     mac: "b4:e6:2d:ac:46:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD7E",
    //     position: 9,
    //     utm: "POINT(737651.384309526 9548771.92242894)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:8c:fc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:1",
    //     mac: "b4:e6:2d:98:94:a1",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 1,
    //     utm: "POINT(737546.229509503 9548661.08565337)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:10",
    //     mac: "b4:e6:2d:98:a7:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 10,
    //     utm: "POINT(737651.383841775 9548796.80743992)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:11",
    //     mac: "b4:e6:2d:98:94:a5",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 11,
    //     utm: "POINT(737663.06,7673498 9548811.8876409)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:12",
    //     mac: "b4:e6:2d:98:9a:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 12,
    //     utm: "POINT(737674.751508503 9548826.96784238)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:13",
    //     mac: "b4:e6:2d:ac:48:4d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 13,
    //     utm: "POINT(737686.435347014 9548842.04804435)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:14",
    //     mac: "b4:e6:2d:99:14:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 14,
    //     utm: "POINT(737698.11918892 9548857.12824682)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:15",
    //     mac: "b4:e6:2d:98:f0:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 15,
    //     utm: "POINT(737709.803034105 9548872.20844979)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:16",
    //     mac: "b4:e6:2d:98:e7:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 16,
    //     utm: "POINT(737721.486882798 9548887.28865326)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:17",
    //     mac: "b4:e6:2d:ac:88:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 17,
    //     utm: "POINT(737733.170734772 9548902.36885722)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:18",
    //     mac: "b4:e6:2d:98:f5:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 18,
    //     utm: "POINT(737744.854590253 9548917.44906167)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:19",
    //     mac: "b4:e6:2d:ac:b9:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 19,
    //     utm: "POINT(737756.538449125 9548932.52926663)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:2",
    //     mac: "b4:e6:2d:aa:fa:b5",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 2,
    //     utm: "POINT(737557.913310668 9548676.16584989)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:20",
    //     mac: "c4:4f:33:23:d3:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 20,
    //     utm: "POINT(737767.599859682 9548946.65635499)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:21",
    //     mac: "c4:4f:33:23:71:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 21,
    //     utm: "POINT(737780.155184032 9548962.60253541)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:22",
    //     mac: "c4:4f:33:1e:b7:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 22,
    //     utm: "POINT(737790.913355233 9548975.00876823)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:23",
    //     mac: "c4:4f:33:1e:a3:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 23,
    //     utm: "POINT(737804.069042118 9548992.73784723)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:24",
    //     mac: "c4:4f:33:23:65:01",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 24,
    //     utm: "POINT(737812.743169256 9549005.58769473)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:25",
    //     mac: "b4:e6:2d:98:f7:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 25,
    //     utm: "POINT(737820.818086854 9549017.25521224)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:3",
    //     mac: "b4:e6:2d:ac:cf:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 3,
    //     utm: "POINT(737569.597115117 9548691.24604691)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:4",
    //     mac: "b4:e6:2d:ac:56:55",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 4,
    //     utm: "POINT(737581.280923073 9548706.32624443)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:5",
    //     mac: "b4:e6:2d:98:a5:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 5,
    //     utm: "POINT(737592.964734316 9548721.40644244)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:6",
    //     mac: "b4:e6:2d:aa:fb:5d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 6,
    //     utm: "POINT(737604.648549061 9548736.48664094)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:7",
    //     mac: "b4:e6:2d:ad:7f:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 7,
    //     utm: "POINT(737616.332367091 9548751.56683994)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:8",
    //     mac: "b4:e6:2d:ac:93:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 8,
    //     utm: "POINT(737628.016188629 9548766.64703944)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD7W:9",
    //     mac: "b4:e6:2d:ad:9b:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD7W",
    //     position: 9,
    //     utm: "POINT(737639.70001356 9548781.72723943)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:1",
    //     mac: "b4:e6:2d:ad:8f:31",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 1,
    //     utm: "POINT(737534.189233358 9548670.73557947)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:10",
    //     mac: "b4:e6:2d:ac:47:f5",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 10,
    //     utm: "POINT(737639.642567296 9548806.7339675)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:11",
    //     mac: "b4:e6:2d:99:14:d1",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 11,
    //     utm: "POINT(737651.359621418 9548821.84490199)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:12",
    //     mac: "b4:e6:2d:ac:93:09",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 12,
    //     utm: "POINT(737663.076679064 9548836.95583697)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:13",
    //     mac: "b4:e6:2d:98:f0:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 13,
    //     utm: "POINT(737674.793740008 9548852.06677246)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:14",
    //     mac: "b4:e6:2d:ad:96:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 14,
    //     utm: "POINT(737686.510804371 9548867.17770843)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:15",
    //     mac: "b4:e6:2d:aa:54:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 15,
    //     utm: "POINT(737698.227872251 9548882.28864491)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:16",
    //     mac: "b4:e6:2d:ac:48:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 16,
    //     utm: "POINT(737709.944943435 9548897.39958188)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:17",
    //     mac: "b4:e6:2d:98:a3:71",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 17,
    //     utm: "POINT(737721.662018139 9548912.51051936)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:18",
    //     mac: "c4:4f:33:23:b0:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 18,
    //     utm: "POINT(737733.37909615 9548927.62145733)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:19",
    //     mac: "b4:e6:2d:98:f5:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 19,
    //     utm: "POINT(737745.096177567 9548942.73239579)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:2",
    //     mac: "b4:e6:2d:ac:d3:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 2,
    //     utm: "POINT(737545.906256868 9548685.84650949)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:3",
    //     mac: "b4:e6:2d:99:11:8d",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 3,
    //     utm: "POINT(737557.623283683 9548700.95744001)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:4",
    //     mac: "b4:e6:2d:ac:4d:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 4,
    //     utm: "POINT(737569.340313911 9548716.06837101)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:5",
    //     mac: "b4:e6:2d:ac:be:11",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 5,
    //     utm: "POINT(737581.057347664 9548731.17930252)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:6",
    //     mac: "b4:e6:2d:ad:8e:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 6,
    //     utm: "POINT(737592.774384718 9548746.29023453)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:7",
    //     mac: "b4:e6:2d:ac:84:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 7,
    //     utm: "POINT(737604.491425297 9548761.40116702)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:8",
    //     mac: "b4:e6:2d:99:11:b1",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 8,
    //     utm: "POINT(737616.20846918 9548776.51210002)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8E:9",
    //     mac: "b4:e6:2d:98:f1:6d",
    //     area: "DMLZ_Undercut",
    //     location: "DD8E",
    //     position: 9,
    //     utm: "POINT(737627.925516477 9548791.62303351)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:1",
    //     mac: "24:0a:c4:50:d3:28",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 1,
    //     utm: "POINT(737523.341579793 9548680.08403163)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:10",
    //     mac: "b4:e6:2d:ac:7d:79",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 10,
    //     utm: "POINT(737628.321617933 9548816.87303412)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:11",
    //     mac: "b4:e6:2d:99:06:65",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 11,
    //     utm: "POINT(737639.919512688 9548831.8744381)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:12",
    //     mac: "b4:e6:2d:99:14:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 12,
    //     utm: "POINT(737651.517410901 9548846.87584257)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:13",
    //     mac: "24:0a:c4:50:ce:3c",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 13,
    //     utm: "POINT(737663.11531235 9548861.87724754)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:14",
    //     mac: "c4:4f:33:23:68:75",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 14,
    //     utm: "POINT(737674.713217259 9548876.87865299)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:15",
    //     mac: "c4:4f:33:1e:49:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 15,
    //     utm: "POINT(737687.767975759 9548894.64667377)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:16",
    //     mac: "24:0a:c4:50:d0:04",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 16,
    //     utm: "POINT(737697.909037003 9548906.88146539)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:17",
    //     mac: "c4:4f:33:23:af:21",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 17,
    //     utm: "POINT(737709.506951949 9548921.88287232)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:18",
    //     mac: "c4:4f:33:23:92:e5",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 18,
    //     utm: "POINT(737721.104870133 9548936.88427976)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:19",
    //     mac: "c4:4f:33:23:d0:c1",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 19,
    //     utm: "POINT(737732.702791768 9548951.88568768)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:2",
    //     mac: "b4:e6:2d:98:f2:05",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 2,
    //     utm: "POINT(737535.538579858 9548696.86182002)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:20",
    //     mac: "c4:4f:33:23:65:5d",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 20,
    //     utm: "POINT(737744.300716642 9548966.8870961)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:21",
    //     mac: "c4:4f:33:1e:a9:9d",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 21,
    //     utm: "POINT(737755.898644975 9548981.88850501)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:22",
    //     mac: "c4:4f:33:1e:54:ed",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 22,
    //     utm: "POINT(737767.49657654 9548996.88991442)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:23",
    //     mac: "c4:4f:33:23:cd:f9",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 23,
    //     utm: "POINT(737779.094511564 9549011.89132432)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:3",
    //     mac: "b4:e6:2d:ac:81:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 3,
    //     utm: "POINT(737547.136447939 9548711.86322006)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:4",
    //     mac: "b4:e6:2d:ac:bd:c5",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 4,
    //     utm: "POINT(737558.734319372 9548726.86462059)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:5",
    //     mac: "b4:e6:2d:98:f1:85",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 5,
    //     utm: "POINT(737570.332194039 9548741.86602162)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:6",
    //     mac: "b4:e6:2d:98:a8:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 6,
    //     utm: "POINT(737581.930072168 9548756.86742313)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:8",
    //     mac: "b4:e6:2d:ac:89:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 8,
    //     utm: "POINT(737605.125838357 9548786.87022764)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD8W:9",
    //     mac: "b4:e6:2d:98:f5:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD8W",
    //     position: 9,
    //     utm: "POINT(737616.723726413 9548801.87163064)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:1",
    //     mac: "b4:e6:2d:98:f5:39",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 1,
    //     utm: "POINT(737512.945039057 9548692.09943838)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:10",
    //     mac: "c4:4f:33:23:cd:dd",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 10,
    //     utm: "POINT(737617.38119457 9548827.37632821)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:11",
    //     mac: "c4:4f:33:1e:b4:fd",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 11,
    //     utm: "POINT(737628.9852287 9548842.40709623)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:12",
    //     mac: "4c:11:ae:b7:f4:e4",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 12,
    //     utm: "POINT(737640.589266069 9548857.43786474)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:13",
    //     mac: "c4:4f:33:23:69:45",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 13,
    //     utm: "POINT(737652.193306799 9548872.46863374)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:14",
    //     mac: "c4:4f:33:23:71:59",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 14,
    //     utm: "POINT(737663.797350982 9548887.49940325)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:15",
    //     mac: "c4:4f:33:23:65:89",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 15,
    //     utm: "POINT(737675.401398411 9548902.53017325)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:16",
    //     mac: "c4:4f:33:23:71:81",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 16,
    //     utm: "POINT(737687.00544919 9548917.56094374)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:17",
    //     mac: "c4:4f:33:1e:18:a5",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 17,
    //     utm: "POINT(737698.609503325 9548932.59171474)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:18",
    //     mac: "24:0a:c4:50:ce:c0",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 18,
    //     utm: "POINT(737710.213560918 9548947.62248622)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:19",
    //     mac: "c4:4f:33:23:66:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 19,
    //     utm: "POINT(737721.817621749 9548962.65325821)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:2",
    //     mac: "b4:e6:2d:98:f1:51",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 2,
    //     utm: "POINT(737524.549042899 9548707.13020193)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:20",
    //     mac: "c4:4f:33:23:70:7d",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 20,
    //     utm: "POINT(737733.421685935 9548977.68403069)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:3",
    //     mac: "b4:e6:2d:98:f2:25",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 3,
    //     utm: "POINT(737536.153050094 9548722.16096598)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:4",
    //     mac: "b4:e6:2d:98:a3:f1",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 4,
    //     utm: "POINT(737547.757060752 9548737.19173053)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:5",
    //     mac: "b4:e6:2d:ac:4d:61",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 5,
    //     utm: "POINT(737559.361074656 9548752.22249557)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:6",
    //     mac: "b4:e6:2d:98:f1:15",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 6,
    //     utm: "POINT(737570.965091911 9548767.25326111)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:7",
    //     mac: "b4:e6:2d:ad:8f:2d",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 7,
    //     utm: "POINT(737582.569112629 9548782.28402714)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:8",
    //     mac: "b4:e6:2d:ad:9e:3d",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 8,
    //     utm: "POINT(737594.173136587 9548797.31479367)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9E:9",
    //     mac: "c4:4f:33:23:af:a9",
    //     area: "DMLZ_Undercut",
    //     location: "DD9E",
    //     position: 9,
    //     utm: "POINT(737605.777163904 9548812.34556069)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:3e:1b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9W:1",
    //     mac: "b4:e6:2d:ad:9a:d5",
    //     area: "DMLZ_Undercut",
    //     location: "DD9W",
    //     position: 1,
    //     utm: "POINT(737500.007704036 9548700.27206358)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9W:2",
    //     mac: "b4:e6:2d:ac:8f:a5",
    //     area: "DMLZ_Undercut",
    //     location: "DD9W",
    //     position: 2,
    //     utm: "POINT(737511.965465843 9548715.67827891)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9W:3",
    //     mac: "b4:e6:2d:ad:9b:e1",
    //     area: "DMLZ_Undercut",
    //     location: "DD9W",
    //     position: 3,
    //     utm: "POINT(737523.923231318 9548731.08449475)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:DD9W:4",
    //     mac: "b4:e6:2d:ad:9a:ad",
    //     area: "DMLZ_Undercut",
    //     location: "DD9W",
    //     position: 4,
    //     utm: "POINT(737535.881000236 9548746.4907111)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "0c:ae:7d:24:ef:d8",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:1",
    //     mac: "c4:4f:33:23:af:75",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 1,
    //     utm: "POINT(737636.24323261 9549236.88847268)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:10",
    //     mac: "24:0a:c4:50:ce:28",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 10,
    //     utm: "POINT(737697.697321653 9549211.71767464)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:11",
    //     mac: "24:0a:c4:50:cf:e4",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 11,
    //     utm: "POINT(737704.532221883 9549205.19057325)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:12",
    //     mac: "24:0a:c4:50:d0:60",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 12,
    //     utm: "POINT(737710.179303756 9549200.14312351)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:13",
    //     mac: "c4:4f:33:23:7c:79",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 13,
    //     utm: "POINT(737713.88207659 9549192.14498027)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:14",
    //     mac: "c4:4f:33:23:5f:6d",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 14,
    //     utm: "POINT(737711.474726254 9549183.12396712)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:15",
    //     mac: "24:0a:c4:50:ff:a4",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 15,
    //     utm: "POINT(737706.987829176 9549176.32873215)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:2",
    //     mac: "c4:4f:33:23:7c:39",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 2,
    //     utm: "POINT(737641.470588973 9549242.05232893)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:3",
    //     mac: "24:0a:c4:50:c6:a4",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 3,
    //     utm: "POINT(737647.733206936 9549244.40319787)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:4",
    //     mac: "c4:4f:33:23:67:89",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 4,
    //     utm: "POINT(737655.477959117 9549243.93923995)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:5",
    //     mac: "4c:11:ae:b7:fd:98",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 5,
    //     utm: "POINT(737662.617440092 9549239.92543751)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:6",
    //     mac: "c4:4f:33:23:64:bd",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 6,
    //     utm: "POINT(737669.902730589 9549234.72749498)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:7",
    //     mac: "4c:11:ae:b7:fd:a4",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 7,
    //     utm: "POINT(737676.591003549 9549229.08716561)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:8",
    //     mac: "c4:4f:33:23:67:49",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 8,
    //     utm: "POINT(737683.577200683 9549223.44650039)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:E_Ramp_NVD_E_ACC_to_NFD:9",
    //     mac: "c4:4f:33:23:7c:31",
    //     area: "DMLZ_Undercut",
    //     location: "E_Ramp_NVD_E_ACC_to_NFD",
    //     position: 9,
    //     utm: "POINT(737690.859343826 9549217.06435591)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "74:e1:82:8c:c3:cc",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:10",
    //     mac: "c4:4f:33:23:a9:d5",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 10,
    //     utm: "POINT(737488.222489569 9549422.83385677)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:12",
    //     mac: "4c:11:ae:b7:fb:a8",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 12,
    //     utm: "POINT(737474.029409039 9549435.22834075)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:13",
    //     mac: "c4:4f:33:23:c3:cd",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 13,
    //     utm: "POINT(737578.103358369 9549275.76825156)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:14",
    //     mac: "c4:4f:33:1e:ae:4d",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 14,
    //     utm: "POINT(737587.026038265 9549270.11926034)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:15",
    //     mac: "c4:4f:33:23:af:2d",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 15,
    //     utm: "POINT(737594.756256698 9549264.1773663)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:16",
    //     mac: "c4:4f:33:23:6a:99",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 16,
    //     utm: "POINT(737602.782821994 9549257.6425478)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:17",
    //     mac: "c4:4f:33:1e:ba:9d",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 17,
    //     utm: "POINT(737611.104156745 9549249.92267235)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:18",
    //     mac: "c4:4f:33:1e:87:71",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 18,
    //     utm: "POINT(737422.969819265 9549476.12717728)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:19",
    //     mac: "24:0a:c4:51:09:84",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 19,
    //     utm: "POINT(737415.239188945 9549481.91893553)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:2",
    //     mac: "c4:4f:33:23:61:51",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 2,
    //     utm: "POINT(737526.751037963 9549346.96125917)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:21",
    //     mac: "24:0a:c4:51:09:50",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 21,
    //     utm: "POINT(737399.334201313 9549494.68724941)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:22",
    //     mac: "24:0a:c4:50:cf:40",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 22,
    //     utm: "POINT(737392.941423875 9549499.4372613)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:23",
    //     mac: "24:0a:c4:50:e7:98",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 23,
    //     utm: "POINT(737385.80624655 9549505.07972885)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:24",
    //     mac: "c4:4f:33:1e:b4:e9",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 24,
    //     utm: "POINT(737379.413117806 9549509.68561695)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:25",
    //     mac: "24:0a:c4:51:09:18",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 25,
    //     utm: "POINT(737373.171690808 9549515.32473562)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:26",
    //     mac: "c4:4f:33:1e:ac:79",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 26,
    //     utm: "POINT(737366.03572227 9549520.6700917)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:27",
    //     mac: "c4:4f:33:1e:ab:29",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 27,
    //     utm: "POINT(737359.04988997 9549526.45921734)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:28",
    //     mac: "c4:4f:33:23:d4:2d",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 28,
    //     utm: "POINT(737350.574027674 9549532.07318833)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:29",
    //     mac: "4c:11:ae:b7:fb:cc",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 29,
    //     utm: "POINT(737344.185242091 9549538.32023769)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:30",
    //     mac: "c4:4f:33:23:b0:15",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 30,
    //     utm: "POINT(737337.796455514 9549544.56728612)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:4",
    //     mac: "c4:4f:33:1e:bd:69",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 4,
    //     utm: "POINT(737530.282011544 9549330.36605679)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:6",
    //     mac: "4c:11:ae:b7:fc:84",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 6,
    //     utm: "POINT(737534.709088654 9549314.65616152)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_East:8",
    //     mac: "c4:4f:33:1e:b3:09",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_East",
    //     position: 8,
    //     utm: "POINT(737502.644939324 9549412.73368454)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:1",
    //     mac: "c4:4f:33:23:68:f9",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 1,
    //     utm: "POINT(737523.671682021 9549365.17909073)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:1",
    //     mac: "b4:e6:2d:ac:46:49",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 1,
    //     utm: "POINT(737525.881661165 9549356.14468822)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:10",
    //     mac: "24:0a:c4:50:e9:44",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 10,
    //     utm: "POINT(737556.997701979 9549293.86151505)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:11",
    //     mac: "c4:4f:33:23:cd:c5",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 11,
    //     utm: "POINT(737480.502600521 9549431.71314752)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:12",
    //     mac: "c4:4f:33:23:69:79",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 12,
    //     utm: "POINT(737571.412096436 9549280.5639453)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:13",
    //     mac: "24:0a:c4:50:e8:24",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 13,
    //     utm: "POINT(737464.152031821 9549444.77416138)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:14",
    //     mac: "b4:e6:2d:99:11:e9",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 14,
    //     utm: "POINT(737586.868171219 9549267.05814209)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:15",
    //     mac: "b4:e6:2d:99:11:dd",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 15,
    //     utm: "POINT(737595.938044657 9549260.67006269)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:16",
    //     mac: "84:0d:8e:0b:cf:6c",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 16,
    //     utm: "POINT(737603.816003666 9549254.28639268)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:17",
    //     mac: "b4:e6:2d:ac:bd:b1",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 17,
    //     utm: "POINT(737611.54500139 9549247.90307595)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:18",
    //     mac: "c4:4f:33:23:d4:d9",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 18,
    //     utm: "POINT(737620.170105377 9549242.40498467)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:19",
    //     mac: "c4:4f:33:23:ac:a1",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 19,
    //     utm: "POINT(737628.491427003 9549234.69220038)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:2",
    //     mac: "c4:4f:33:23:ce:d5",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 2,
    //     utm: "POINT(737521.457873423 9549372.87720077)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:20",
    //     mac: "c4:4f:33:24:24:a1",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 20,
    //     utm: "POINT(737406.324958582 9549490.67903849)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:3",
    //     mac: "c4:4f:33:1e:78:71",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 3,
    //     utm: "POINT(737519.396163137 9549381.75832833)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:5",
    //     mac: "24:0a:c4:50:e7:cc",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 5,
    //     utm: "POINT(737517.198724107 9549395.97467434)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:7",
    //     mac: "c4:4f:33:23:57:a9",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 7,
    //     utm: "POINT(737510.190366305 9549407.38702458)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:8",
    //     mac: "c4:4f:33:23:8c:75",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 8,
    //     utm: "POINT(737546.000009448 9549303.37011438)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:NVD_West:9",
    //     mac: "c4:4f:33:23:5f:4d",
    //     area: "DMLZ_Undercut",
    //     location: "NVD_West",
    //     position: 9,
    //     utm: "POINT(737494.474541041 9549420.43357347)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "7c:66:9d:48:cd:de",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:1",
    //     mac: "b4:e6:2d:98:f2:41",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 1,
    //     utm: "POINT(737638.825482303 9548639.19928899)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:10",
    //     mac: "b4:e6:2d:98:a8:35",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 10,
    //     utm: "POINT(737486.922822097 9548709.03695045)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:11",
    //     mac: "b4:e6:2d:99:15:79",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 11,
    //     utm: "POINT(737540.55222907 9548654.43821303)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:12",
    //     mac: "b4:e6:2d:ac:49:c1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 12,
    //     utm: "POINT(737464.971937896 9548744.89715155)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:13",
    //     mac: "b4:e6:2d:98:a6:a1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 13,
    //     utm: "POINT(737523.16412192 9548669.57625556)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:14",
    //     mac: "b4:e6:2d:99:12:01",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 14,
    //     utm: "POINT(737443.46407422 9548779.28281698)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:15",
    //     mac: "c4:4f:33:23:58:91",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 15,
    //     utm: "POINT(737506.222893646 9548684.71323667)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:16",
    //     mac: "b4:e6:2d:98:f7:1d",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 16,
    //     utm: "POINT(737422.409822858 9548816.18925226)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:17",
    //     mac: "b4:e6:2d:98:a2:75",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 17,
    //     utm: "POINT(737492.551807944 9548697.1845523)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:18",
    //     mac: "b4:e6:2d:ac:57:e5",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 18,
    //     utm: "POINT(737412.918949125 9548832.49095371)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:19",
    //     mac: "b4:e6:2d:ac:49:c5",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 19,
    //     utm: "POINT(737479.798719683 9548718.82040155)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:2",
    //     mac: "b4:e6:2d:98:a7:c9",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 2,
    //     utm: "POINT(737618.114339306 9548636.9976817)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:20",
    //     mac: "b4:e6:2d:ad:1c:dd",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 20,
    //     utm: "POINT(737475.947820473 9548727.11821173)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:21",
    //     mac: "b4:e6:2d:ac:9b:b5",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 21,
    //     utm: "POINT(737470.314896999 9548737.49094088)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:22",
    //     mac: "b4:e6:2d:99:a5:39",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 22,
    //     utm: "POINT(737464.08692159 9548748.16118736)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:23",
    //     mac: "b4:e6:2d:98:a5:01",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 23,
    //     utm: "POINT(737459.343818113 9548757.05187209)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:24",
    //     mac: "b4:e6:2d:ad:6e:69",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 24,
    //     utm: "POINT(737331.634762884 9548906.54289884)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:25",
    //     mac: "b4:e6:2d:ac:45:9d",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 25,
    //     utm: "POINT(737449.099760258 9548769.94624787)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:27",
    //     mac: "c4:4f:33:23:65:79",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 27,
    //     utm: "POINT(737438.125530493 9548788.32363824)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:28",
    //     mac: "24:0a:c4:50:fc:54",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 28,
    //     utm: "POINT(737432.487503179 9548796.77370867)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:3",
    //     mac: "b4:e6:2d:ad:9b:c1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 3,
    //     utm: "POINT(737700.946221706 9548653.58612955)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:4",
    //     mac: "b4:e6:2d:a4:67:59",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 4,
    //     utm: "POINT(737607.530996494 9548636.21200378)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:5",
    //     mac: "b4:e6:2d:ac:4c:a1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 5,
    //     utm: "POINT(737567.020214858 9548636.7635024)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:6",
    //     mac: "b4:e6:2d:ad:8e:e9",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 6,
    //     utm: "POINT(737549.472850059 9548648.05200551)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:7",
    //     mac: "c4:4f:33:23:ac:49",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 7,
    //     utm: "POINT(737577.591864345 9548635.10683246)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:8",
    //     mac: "b4:e6:2d:ac:8f:45",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 8,
    //     utm: "POINT(737515.139593002 9548676.84742938)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FE:9",
    //     mac: "b4:e6:2d:ac:46:e1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FE",
    //     position: 9,
    //     utm: "POINT(737558.095946813 9548641.8147514)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "04:a3:16:fd:75:63",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:1",
    //     mac: "24:0a:c4:50:d0:54",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 1,
    //     utm: "POINT(737202.900402892 9548965.03161025)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:10",
    //     mac: "c4:4f:33:23:5f:a5",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 10,
    //     utm: "POINT(737137.230883219 9549011.08619294)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:11",
    //     mac: "24:0a:c4:50:ff:68",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 11,
    //     utm: "POINT(737127.860673398 9549020.4243883)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:12",
    //     mac: "c4:4f:33:1e:a6:89",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 12,
    //     utm: "POINT(737121.61966887 9549026.95090692)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:13",
    //     mac: "c4:4f:33:23:67:65",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 13,
    //     utm: "POINT(737127.119453628 9549021.46681383)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:14",
    //     mac: "24:0a:c4:51:0e:88",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 14,
    //     utm: "POINT(737100.516915951 9549044.47287179)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:15",
    //     mac: "c4:4f:33:23:f0:e1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 15,
    //     utm: "POINT(737089.068222152 9549052.63913339)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:16",
    //     mac: "c4:4f:33:23:54:f1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 16,
    //     utm: "POINT(737077.923730485 9549063.02645183)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:16",
    //     mac: "c4:4f:33:24:24:b5",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 16,
    //     utm: "POINT(737096.640718217 9549048.32239451)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:17",
    //     mac: "c4:4f:33:1e:ae:e1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 17,
    //     utm: "POINT(737067.797113517 9549069.85415845)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:18",
    //     mac: "c4:4f:33:23:67:25",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 18,
    //     utm: "POINT(737063.268405333 9549073.79078857)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:19",
    //     mac: "c4:4f:33:23:69:41",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 19,
    //     utm: "POINT(737051.172247818 9549085.14210377)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:2",
    //     mac: "24:0a:c4:50:ce:14",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 2,
    //     utm: "POINT(737197.532819641 9548970.81309463)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:20",
    //     mac: "c4:4f:33:23:68:19",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 20,
    //     utm: "POINT(737041.364839296 9549094.63626204)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:21",
    //     mac: "c4:4f:33:23:b6:c1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 21,
    //     utm: "POINT(737033.035673628 9549099.09882478)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:22",
    //     mac: "24:0a:c4:50:ce:18",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 22,
    //     utm: "POINT(737021.429757824 9549105.34460792)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:23",
    //     mac: "c4:4f:33:1e:b5:b1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 23,
    //     utm: "POINT(737013.997494516 9549110.39612392)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:24",
    //     mac: "24:0a:c4:50:d3:6c",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 24,
    //     utm: "POINT(737004.7749529 9549115.45256326)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:25",
    //     mac: "c4:4f:33:23:60:09",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 25,
    //     utm: "POINT(736995.225220039 9549121.16058656)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:26",
    //     mac: "c4:4f:33:23:63:99",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 26,
    //     utm: "POINT(736986.241998104 9549127.85775765)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:27",
    //     mac: "c4:4f:33:23:9b:3d",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 27,
    //     utm: "POINT(736977.258774924 9549134.55492713)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:28",
    //     mac: "c4:4f:33:1c:f8:d1",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 28,
    //     utm: "POINT(736968.275550724 9549141.25209498)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:29",
    //     mac: "c4:4f:33:23:f0:85",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 29,
    //     utm: "POINT(736959.292325385 9549147.94926122)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:3",
    //     mac: "24:0a:c4:50:d0:b4",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 3,
    //     utm: "POINT(737192.855816002 9548973.82241332)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:30",
    //     mac: "24:0a:c4:50:ce:0c",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 30,
    //     utm: "POINT(736950.309098916 9549154.64642585)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:4",
    //     mac: "c4:4f:33:23:60:f5",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 4,
    //     utm: "POINT(737188.069289794 9548976.02465153)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:5",
    //     mac: "c4:4f:33:23:66:2d",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 5,
    //     utm: "POINT(737177.211509677 9548984.04094107)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:6",
    //     mac: "c4:4f:33:23:af:15",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 6,
    //     utm: "POINT(737176.022206202 9548984.34210392)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:7",
    //     mac: "24:0a:c4:51:0e:50",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 7,
    //     utm: "POINT(737165.040363877 9548993.25490725)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:8",
    //     mac: "24:0a:c4:50:cf:bc",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 8,
    //     utm: "POINT(737155.518691519 9548999.93485522)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW1:9",
    //     mac: "24:0a:c4:50:d0:88",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW1",
    //     position: 9,
    //     utm: "POINT(737146.748389802 9549004.84601923)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW2:1",
    //     mac: "c4:4f:33:23:ad:f9",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW2",
    //     position: 1,
    //     utm: "POINT(737222.126213529 9548954.03472182)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:SVD_FW2:2",
    //     mac: "b4:e6:2d:ab:fc:9d",
    //     area: "DMLZ_Undercut",
    //     location: "SVD_FW2",
    //     position: 2,
    //     utm: "POINT(737235.219806432 9548948.38025427)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    //   {
    //     id: "DMLZ_Undercut:UCL_SFD:1",
    //     mac: "4c:11:ae:b7:fa:60",
    //     area: "DMLZ_Undercut",
    //     location: "UCL_SFD",
    //     position: 1,
    //     utm: "POINT(737257.531404844 9548936.18414103)",
    //     utm_zone_number: 53,
    //     utm_zone_letter: 77,
    //     z: null,
    //     controller: "c4:f3:12:81:67:2b",
    //     color: "GREEN",
    //     state: "ON",
    //     reported_color: "GREEN",
    //     reported_state: "ON",
    //     on_time: null,
    //     off_time: null,
    //     train: null,
    //     timestamp_status: 1616716329,
    //     timestamp_state_change: 1618970385,
    //     sw_ver: 3.06,
    //     reason: null,
    //     priority: null,
    //   },
    // ];

    // function onlyUnique(value, index, self) {
    //   return self.indexOf(value) === index;
    // }

    // const controllerIds = controllers.map((item) => item.id);

    // console.log(`controllerIds`, controllerIds);

    // let controllerLocations = [];

    // controllerIds.forEach((element) => {
    //   const ffs = fireflies.filter((ff) => ff.controller === element);

    //   const locations = ffs.map((ff) => ff.location);

    //   const unique = locations.filter(onlyUnique);

    //   controllerLocations[element] = unique;
    // });

    // console.log(`controllerLocations`, controllerLocations);

    // controllerIds.forEach((id, index) =>
    //   console.log(`${id}, ${controllerLocations[id].join(",")}`)
    // );

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">Database Tools</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment>
            <List>
              <List.Item>Save Database</List.Item>
              <List.Item>Restore Database</List.Item>
              <List.Item>Oranges</List.Item>
              <List.Item>Clear Database</List.Item>
            </List>
          </Segment>
          {/* <Segment>{JSON.stringify(controllerLocations)}</Segment> */}
        </Container>
      </div>
    );
  }
}

//export default connect(null)(ToolsDatabase);
export default ToolsDatabase;
