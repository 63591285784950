import React, { Component } from "react";
import { push } from "react-router-redux";

import { Container, Header, Grid, Message } from "semantic-ui-react";

import { connect } from "react-redux";

import UserCreateForm from "components/UserAdmin/UserCreateForm";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import DataLoadingMessage from "components/DataLoading/dataLoading";

import { SubmissionError } from "redux-form";

import {
  saveUser,
  fetchAllUsers,
  deleteUser,
} from "components/UserAdmin/actions";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class UserCreatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  submitMyForm = (values) => {
    return new Promise((resolve, reject) => {
      //console.log("xxx UserCreate values", values);

      // set email as `id`
      const { email: id } = values;
      const newValues = { id, ...values };
      this.props.saveUser({ values: newValues, resolve, reject });
    })
      .then(() => {
        this.props.fetchAllUsers();
        //this.onResetError();
        this.props.goto("/user/admin");
      })
      .catch((error) => {
        console.log(`onSubmit error`, error);
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  render() {
    const { id, error, strings } = this.props;

    if (error) {
      return (
        //   placeholder
        null
      );
    }

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"userCreateForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["Create New User"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <UserCreateForm
            strings={strings}
            onSubmit={(values) => this.submitMyForm(values)}
            // #NOTE - disabled as this is fired as soon as submit,
            // *not* after successful completion of the Promise!!
            // onSubmitSuccess={(result, dispatch) => {
            //   //console.log(`onSubmitSuccess ${result}`);
            //   // wait for state change before redirecting page
            //   this.setState(
            //     {
            //       submitted: true,
            //     },
            //     () => {
            //       //this.onResetError();
            //       this.props.goto("/user/admin");
            //     }
            //   );
            // }}
            onSubmitFail={(errors) => {
              console.log(`onSubmitFail ${JSON.stringify(errors)}`);
            }}
            onCancel={() => {
              this.props.goto("/user/admin");
            }}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    saveUser: (data) => dispatch(saveUser(data)),
    goto: (path) => dispatch(push(path)),
    fetchAllUsers: () => dispatch(fetchAllUsers()),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

function mapStateToProps(state, props) {
  // placeholder
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreatePage);
