/*
1Dec21 - stopped using this as the data update would re-drawn a layer with each hash().

Issue refs:
- https://github.com/PaulLeCam/react-leaflet/issues/389
- 

See also:
- https://github.com/PaulLeCam/react-leaflet/issues/697
- https://github.com/PaulLeCam/react-leaflet/issues/332
- https://gist.github.com/Shrugsy/5e898173c965e7642db8927636bedf7a


For markercluster see:
- https://stackoverflow.com/questions/59306768/marker-clustering-leaflet-markercluster-with-react-leaflet-2-0/59322023

*/

/*
About 'react-leaflet-markercluster'

See - https://github.com/yuzhva/react-leaflet-markercluster/issues/69

This works for GeoJson but we need to use an older version of react-leaflet-markercluster (v2.0.0) which is compatible
with react-leaflet v2. We can't update react-leaflet to v3 until @andrewdodd EditFeatureLayer dependence is removed from the 
code.
*/

import React, { Component } from "react";
import { connect } from "react-redux";

import { withMineLevel } from "ApplicationContexts";

import L from "leaflet";

import MarkerClusterGroup from "react-leaflet-markercluster";
import { GeoJSON } from "react-leaflet";

import { makeIcon } from "components/Map/MakeIcon";

import {
  getTagTrackingCoordinatesByAreaId,
  getTagIdsByAreaId,
} from "components/Tags/reducer";

import hash from "object-hash";
import _isEmpty from "lodash/isEmpty";

import TagTrackingMarkerPopupModal from "admin/tag-tracking/TagTrackingMarkerPopupModal";

// #TODO #REVEW - write a geoJson parse checker
function tryParseJSON(jsonObj) {
  try {
    var o = JSON.parse(JSON.stringify(jsonObj));
    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {}
  return false;
}

// props
// - https://www.npmjs.com/package/react-leaflet-markercluster#api
// - https://github.com/Leaflet/Leaflet.markercluster#all-options
//
// Function for creating custom icon for cluster group
// https://github.com/Leaflet/Leaflet.markercluster#customising-the-clustered-markers
// NOTE: iconCreateFunction is running by leaflet, which does not support ES6 arrow func syntax
// eslint-disable-next-line
const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "marker-cluster-custom",
    iconSize: L.point(40, 40, true),
  });
};

const filterTags = (tags, activeTagType) => {
  let filterTags = {};
  filterTags.type = "FeatureCollection";
  if (tags?.features !== undefined) {
    filterTags.features = tags.features.filter((tag) =>
      activeTagType.includes(tag.properties.tagUserType)
    );
  } else {
    filterTags.features = [];
  }
  return filterTags;
};

class CurrentTagTrackingFeatureGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenTagTrackingMarkerPopup: false,
      // modal for details of the marker
      isOpenTagTrackingMarkerPopupModal: {
        active: false,
        id: "",
        area: "",
      },
      tags: {},
    };
  }

  componentDidMount() {
    const { tags, activeTagType } = this.props;

    // update tag data from active filter buttons
    const newTags = filterTags(tags, activeTagType);
    this.setState({ tags: newTags });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if the popup is open stop rendering
    if (nextState?.isOpenTagTrackingMarkerPopup === true) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { tags, activeTagType } = this.props;

    // #NOTE - only recalculate the tag display data if the tags have changed,
    // or the tag filtering has changed.
    // This is to reduce the # of recalculations.

    if (prevProps.tags !== tags || prevProps.activeTagType !== activeTagType) {
      // update tag data from active filter buttons
      const newTags = filterTags(tags, activeTagType);
      this.setState({ tags: newTags });
    }
  }

  resetModal = () => {
    this.setState({
      isOpenTagTrackingMarkerPopupModal: {
        active: false,
        id: "",
        area: "",
      },
    });
  };

  render() {
    const { area } = this.props;
    const { tags } = this.state;

    const onEachFeature = (feature, layer) => {
      let popupContent = ``;
      if (feature.properties && feature.properties.id) {
        const {
          properties: { id, status, tagUserType, userInfo, vehicleInfo, mac },
        } = feature;

        let tagEntry;
        switch (tagUserType) {
          case "personnel":
            const { firstname, lastname } = userInfo;
            tagEntry = `${firstname} ${lastname}`;
            break;
          case "vehicle":
            const { name, type } = vehicleInfo;
            tagEntry = `${name}`;
            break;
          case "unknown":
            tagEntry = `${mac}`;
            break;
          default:
            break;
        }

        //popupContent = `<button data-key=${id} class="link button-edit">${tagEntry}</button><p><strong>Status:</strong> ${status?.toUpperCase()} </p>`;
        popupContent = `<button data-key=${id} class="link button-edit">${tagEntry}</button>`;
      }

      layer.bindPopup(popupContent);
      layer.on("popupclose", (a) => {
        this.setState({
          isOpenTagTrackingMarkerPopup: false,
        });
      });
      layer.on("popupopen", (a) => {
        this.setState({
          isOpenTagTrackingMarkerPopup: true,
        });
        const popUp = a.target.getPopup();
        popUp
          .getElement()
          .querySelector(`.button-edit`)
          .addEventListener("click", (e) => {
            // console.log(
            //   "clicked edit on marker: ",
            //   e,
            //   "target: ",
            //   e.target,
            //   "data-key ",
            //   e.target.dataset.key
            // );

            const id = e.target.dataset.key;
            if (!_isEmpty(id)) {
              const newModal = {
                active: true,
                id: id,
                area: area,
              };
              this.setState(
                {
                  isOpenTagTrackingMarkerPopupModal: newModal,
                },
                () => console.log(`CLICK ON TAG ID: `, newModal)
              );
              this.setState({
                isOpenTagTrackingMarkerPopup: false,
              });
            }
          });
      });
    };

    const pointToLayer = (feature, latlng) => {
      const {
        properties: { tagUserType, status },
      } = feature;

      let tagIcon = "TagUnknownMarker";
      switch (tagUserType) {
        case "personnel":
          tagIcon = "TagHardHat";
          break;
        case "vehicle":
          tagIcon = "TagTruckPickupMarker";
          break;

        default:
          break;
      }

      let tagColor = "Peru";
      switch (status) {
        case "lost":
          tagColor = "grey";
          break;
        case "initial":
          tagColor = "green";
          break;

        default:
          break;
      }

      //return L.circleMarker(latlng, null); // Change marker to circle
      return L.marker(latlng, {
        icon: makeIcon(tagIcon, { stroke: tagColor, fill: tagColor }, 1), // default to purple as this is not a suppported active color
      });
    };

    const valid = !_isEmpty(tags) && tryParseJSON(tags);

    const isOpenSettingsModal =
      this.state.isOpenTagTrackingMarkerPopupModal.active === true;

    // #NOTE - comment in rendering.
    // Re-render *should* only happen if the tag has moved position or the tag filter has changed (see componentDidUpdate),
    // however in reality the coordinate data is update to such a high precision (with interpolation between FF coordinates) that the
    // tag data does update with every websocket data delivery.
    // So we have to rely on the server (websocket connection) being the arbitor of the need to update.
    //

    return (
      valid && (
        <>
          {isOpenSettingsModal && (
            <TagTrackingMarkerPopupModal
              open={isOpenSettingsModal}
              initialValues={this.state.isOpenTagTrackingMarkerPopupModal}
              handleSettingsSave={(values) => this.handleSettingsSave(values)}
              resetModal={() => this.resetModal(false)}
            />
          )}

          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            //spiderfyOnMaxZoom={false}
            //disableClusteringAtZoom={false}
            iconCreateFunction={createClusterCustomIcon}
          >
            <GeoJSON
              ref="geoJsonTagMarkers"
              key={hash(tags)}
              data={tags}
              onEachFeature={onEachFeature}
              pointToLayer={pointToLayer}
            />
          </MarkerClusterGroup>
        </>
      )
    );
  }
}

export function mapStateToProps(state, props) {
  const { mineLevel, activeTagType } = props;

  const tags = getTagTrackingCoordinatesByAreaId(state, mineLevel.id);
  const tagIds = getTagIdsByAreaId(state, mineLevel.id);

  // src/admin/tag-tracking/CurrentTagTrackingFeatureGroupGeoJson.js

  // console.log(
  //   `CurrentTagTrackingFeatureGroupGeoJson ----- ${mineLevel.id} -------------`
  // );
  // console.log("CurrentTagTrackingFeatureGroupGeoJson tagIds", tagIds);
  // console.log("CurrentTagTrackingFeatureGroupGeoJson tags", tags);

  // #DEBUG - debugging the plot points - leave in place
  if (false) {
    console.log(`tagTracking - TAG_UPDATE CHANGE coordInfo tags ---- `, tags);
    if (tags) {
      tags.features.forEach((coord) => {
        const {
          id,
          geometry: { coordinates },
          properties: { easting, northing, tagUserType },
        } = coord;
        const coordInfo = `[${northing},${easting}]: [${coordinates.join(
          ", "
        )}]`;

        //if (false && id === `1A:1B:1C:1D:1E:1F`) {
        console.log(
          `tagTracking - TAG_UPDATE CHANGE coordInfo tags 1A:1B:1C:1D:1E:1F`,
          id,
          coordInfo,
          activeTagType,
          tagUserType
        );
        //}
      });
    }
  }

  return {
    area: mineLevel.id,
    tags,
    tagIds,
  };
}

export default withMineLevel(
  connect(mapStateToProps)(CurrentTagTrackingFeatureGroup)
);
