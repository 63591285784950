// #middleware updateNamedAreaGroup

import {
  getAllAreaStatuses,
  getNamedAreaEventsByAreaId,
  getButtonPrioritysInNamedAreaGroupByAreaId,
} from "components/WebWorker/reducer";

// compare the updatedNamedAreaGroup with the current version and
// apply the active events to the same ...?

export const updateNamedAreaGroup = (store) => (next) => (action) => {
  let newAction = action;

  switch (action.type) {
    case "UPDATE_NAMED_AREA_GROUP":
      const state = store.getState();
      //const areaId = newAction.payload.values.data.id;

      const areas = getAllAreaStatuses(state);

      areas.forEach((area) => {
        const namedAreaEventsByAreaId = getNamedAreaEventsByAreaId(
          state,
          area.id
        );
        const namedAreaGroupButtons =
          getButtonPrioritysInNamedAreaGroupByAreaId(state, area.id);

        const activeAllNamedAreaEvents = namedAreaEventsByAreaId
          .filter((event) => event.active)
          .map((event) => {
            const { id, priority } = event;
            return { id, priority };
          });

        let namedAreaGroupButtonStates = [];
        namedAreaGroupButtons.forEach((button) => {
          const {
            id: buttonId,
            priority: buttonPriority,
            event: buttonEvent,
            level,
            group,
          } = button;

          const isActiveEvent = activeAllNamedAreaEvents.some(
            (event) =>
              event.id.includes(buttonId) && event.priority === buttonPriority
          );

          namedAreaGroupButtonStates.push({
            id: buttonId,
            priority: buttonPriority,
            active: isActiveEvent,
            buttonEvent: buttonEvent,
            level,
            group,
          });
        });

        store.dispatch({
          type: "UPDATE_NAMED_AREA_GROUP_BUTTON_STATES_PREVIOUS",
          payload: {
            id: area.id,
            data: namedAreaGroupButtonStates,
          },
        });
      });

      break;
    default:
      break;
  }
  return next(newAction);
};
