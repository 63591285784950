import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Map, ScaleControl, TileLayer } from "react-leaflet";

import { withMineLevel, MineLevelContext } from "ApplicationContexts";
import { getMineLevelById } from "MineLevels/reducer";
import { connect } from "react-redux";

const DEFAULT_MAP_STYLE = {
  height: "600px",
  width: "100%",
  position: "relative",
  zIndex: 0,
};
function SimpleCRSImageMap({ ...props }) {
  const {
    imageInfo,
    // HACKfix: Use zIndex to stop leaflet map from being above the Semantic-UI dropdown menus!
    style,
    center = [0, 0],
  } = props;
  const { minZoom, bounds, tilepath } = imageInfo;
  const maxBounds = L.latLngBounds(bounds).pad(0.4);

  console.log("Map - SimpleCRSImageMap: imageInfo ", imageInfo);
  console.log("Map - maxBounds", maxBounds);
  console.log("Map - bounds", bounds);

  var yx = L.latLng;
  var xy = function (x, y) {
    if (L.Util.isArray(x)) {
      // When doing xy([x, y]);
      return yx(x[1], x[0]);
    }
    return yx(y, x); // When doing xy(x, y);
  };
  console.log("xy(bounds)", xy(bounds));

  return (
    <Map
      style={{ ...DEFAULT_MAP_STYLE, ...style }}
      ref={(r) => {
        if (r) {
          const minZoom = r.leafletElement.getMinZoom();
          r.leafletElement.setMinZoom(0); // MUST set this prior to calculation
          const minZoomCalced = r.leafletElement.getBoundsZoom(bounds, false);
          if (minZoomCalced !== minZoom) {
            r.leafletElement.fitBounds(bounds);
          }
          r.leafletElement.setMinZoom(minZoomCalced);
          r.leafletElement.setMaxZoom(23);
        }
      }}
      center={center}
      bounds={bounds} // sets initial viewport only
      maxBounds={maxBounds}
      attributionControl={false} // not dynamic
      key={`mapMinZoom-${minZoom}`} // include non-dynamic props to force react re-render
    >
      <ScaleControl imperial={false} />
      {props.children}
      <TileLayer url={tilepath} maxZoom={23} tms />
    </Map>
  );
}

export function MineLevelMap({ ...props }) {
  const { error, image } = props.mineLevel;
  if (error) {
    return <div>{error}</div>;
  }

  //console.log("MineLevelMap: image ", JSON.stringify(image));

  return (
    <SimpleCRSImageMap imageInfo={image} {...props}>
      {props.children}
    </SimpleCRSImageMap>
  );
}

const MineLevelMapWithContext = withMineLevel(MineLevelMap);
export default MineLevelMapWithContext;

function MineLevelMapViaContext({ mineLevel, ...props }) {
  return (
    <MineLevelContext.Provider value={mineLevel}>
      <MineLevelMapWithContext {...props} />
    </MineLevelContext.Provider>
  );
}
export const MineLevelMapById = connect((state, props) => {
  let mineLevel = getMineLevelById(state, props.mineLevelId);
  if (mineLevel === undefined) {
    mineLevel = { error: "Unable to find area" };
  }
  return {
    mineLevel,
  };
})(MineLevelMapViaContext);
