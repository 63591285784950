import React from "react";

export const MineLevelContext = React.createContext();

// This function takes a component...
export function withMineLevel(Component) {
  // ...and returns another component...
  return function MineLevelComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <MineLevelContext.Consumer>
        {(value) => (
          <Component
            {...props}
            mineLevel={value.mineLevel}
            displaySettings={value.displaySettings} // #WIP - these are settings e.g. show SOP, column width. Need to move these to user settings db
            //mapSettings={value.mapSettings}
          />
        )}
      </MineLevelContext.Consumer>
    );
  };
}

export const UPSControllerContext = React.createContext();

// This function takes a component...
export function withUPS(Component) {
  // ...and returns another component...
  return function UPSComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <UPSControllerContext.Consumer>
        {(ups) => <Component {...props} ups={ups} />}
      </UPSControllerContext.Consumer>
    );
  };
}
