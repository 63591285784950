import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Form,
  Container,
  Grid,
  Segment,
  Button,
  Header,
  Icon,
  Popup,
  Dropdown,
  Table,
  Tab,
  Input,
  TabPane,
} from "semantic-ui-react";

import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

import {
  renderInput,
  renderSelect,
  renderField,
  renderRadio,
  renderCheckbox,
  renderTextArea,
} from "admin/form-field";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging

// see - https://www.npmjs.com/package/react-timezone-select
import TimezoneSelect from "react-timezone-select";

// see - https://www.npmjs.com/package/cronstrue
import cronstrue from "cronstrue/i18n";

// see - 7 segment fork - https://www.npmjs.com/package/@chuxingpay/cron-parser
import { parseExpression, fieldsToExpression } from "cron-parser";

import ChooseMineLevelField from "components/ChooseMineLevelField";
import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";
import {
  getAreaStatusesById,
  getNamedAreasInfoInNamedAreaGroupByAreaId,
} from "components/WebWorker/reducer";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import {
  optionsMonths,
  optionsDays,
  optionsDayOfWeek,
  optionsHours,
  optionsMinutes,
  optionsDuration,
  options60mins,
  options24hours,
  options12months,
} from "admin/event-scheduled/ScheduleEventFormOptions";
import { convertFieldsToCronExpression } from "admin/event-scheduled/ScheduledEventCronUtils";
import { DEFAULT_CRON_EXPRESSION } from "utils/DEFAULTS";
import { minValueEqual, maxValueEqual } from "utils/normalise";
import { isKthBitSet } from "utils/bit-operations";
import NumberInput from "semantic-ui-react-numberinput";

// range limits
const minValueEqual0 = minValueEqual(0);
const maxValueEqual15 = maxValueEqual(15);

const validate = (values) => {
  const { mineLevelId } = values;

  const errors = {};

  if (!mineLevelId) {
    errors.mineLevelId = "Required";
  }

  //console.log("xxx validate", errors);

  return errors;
};

const normalise = (value, type) => {
  if (type === "noSpaces") {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

const required = (value) => (value ? undefined : "Required");
const isScheduledEventNameOK = (value, allValues, props, name) => {
  const { id } = allValues;

  const { strings } = props;

  if (false) {
    return strings?.["This name has already been used"];
  } else {
    return undefined;
  }
};

// DEBUG
const DEBUG_HIDDEN = { display: "none" }; // null

class ScheduledEventForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      currentTimezone: "",
      selectedTimezone: "",
      //
      date: "",
      time: "",
      dateTime: "",
      datesRange: "",

      dateStart: "",
      dateStop: "",

      //
      disableDay: false,
      //
      cronValue: DEFAULT_CRON_EXPRESSION,
      //
      isAddEvent: true,
      events: [],
      //
      valueBitRegister: "0",
      valueDuration: "1",
    };
  }

  componentDidMount() {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({
      selectedTimezone: currentTimezone,
      currentTimezone,
    });
    //

    console.log(
      "xxx componentDidMount initialValues.events",
      this.props.initialValues.events
    );

    // initialise events - these are managed in local state
    this.setState({ events: this.props.initialValues.events });
  }

  resetForm = (e) => {
    e.preventDefault();

    this.props.reset();
    // initialise events - these are managed in local state
    this.setState({ events: this.props.initialValues.events });
  };

  cancelForm = (e) => {
    e.preventDefault();

    // https://github.com/Asymmetrik/ngx-leaflet/issues/88
    // unmount error - "TypeError: Cannot read property 'off' of undefined"

    // abort page changes
    //this.props.reset();
    this.props.onCancel();
  };

  submitMyForm = (values) => {
    const { reduxFormValues } = this.props;

    console.log("xxx submitMyForm values", values);

    const { events } = this.state;
    this.props.onSubmit({ ...reduxFormValues, events });
  };

  requestDelete = (id) => {
    const { change } = this.props;
    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete");

    this.props.onDelete(id);
  };

  setSelectedTimezone = (selectedTimezone) => {
    console.log("xxx setSelectedTimezone", selectedTimezone);

    this.setState({ selectedTimezone });
  };

  callback = (cronValue) => {
    console.log("xxx cronValue", cronValue);
  };

  handleSelectMonth = () => {};
  handleCheckEveryMonth = (e, data) => {
    if (data) {
      this.props.change("month.value", 1);
    } else {
      this.props.change("month.value", "*");
    }
  };
  handleSelectDay = () => {};
  handleCheckEveryDay = (e, data) => {
    if (data) {
      this.props.change("dayOfMonth.value", 1);
    } else {
      this.props.change("dayOfMonth.value", "*");
    }
  };
  handleSelectDayOfWeek = (e, data) => {
    if (data > 0) {
      this.setState({ disableDay: true }, () => {
        this.props.change("dayOfMonth.every", false);
        this.props.change("dayOfMonth.value", "*");
      });
    } else {
      this.setState({ disableDay: false });
    }
  };

  handleCheckEveryHour = (e, data) => {
    // if (data) {
    //   // checked
    //   this.props.change("hour.value", 1);
    // }
    // //unchecked
    // else {
    //   this.props.change("hour.value", 1);
    // }
  };

  handleCheckEveryMinute = (e, data) => {
    console.log("xxx handleCheckEveryMinute", data);

    if (data === true) {
      // checked
      this.props.change("minute.value", 2);

      this.props.change("duration.value", 1);
      this.setState({ valueDuration: "1" });
      //this.props.change("duration.unit", "minute");
    }
    // uncheck
    else {
      this.props.change("minute.value", 0);

      this.props.change("duration.value", 1);
      this.setState({ valueDuration: "1" });
    }
  };

  handleSelectGroup = (e, data) => {
    console.log("xxx handleSelectGroup data", data);

    this.setState({ isAddEvent: true });
    this.props.change("namedAreaGroupOption.level", "none");
    this.props.change("namedAreaGroupOption.polygon", "none");
  };

  handleSelectLevel = (e, data) => {
    console.log("xxx handleSelectLevel data", data);

    this.setState({ isAddEvent: true });
    //this.props.change("namedAreaGroupOption.polygon", "none");
  };

  handleSelectPolygon = (e, data) => {
    console.log("xxx handleSelectPolygon data", data);
    this.setState({ isAddEvent: false });
  };

  onClickAddEvent = (e, data) => {
    console.log("xxx onClickAddEvent data", data);

    const {
      reduxFormValues,
      reduxFormValues: {
        namedAreaGroupOption: { polygonId },
      },
      namedAreaGroupInfo,
    } = this.props;

    console.log("xxx onClickAddEvent reduxFormValues", reduxFormValues);
    console.log("xxx onClickAddEvent namedAreaGroupInfo", namedAreaGroupInfo);

    let newEvents = [...this.state.events];

    const eventInfo = newEvents.find((event) => event.polygonId === polygonId);
    if (!eventInfo) {
      const test = namedAreaGroupInfo?.filter((i) => i.polygonId !== polygonId);

      console.log("xxx onClickAddEvent test", polygonId, test);

      const nagInfo = namedAreaGroupInfo?.find(
        (i) => i.polygonId === polygonId
      );
      console.log("xxx onClickAddEvent nagInfo", nagInfo);

      if (nagInfo) {
        newEvents.push(nagInfo);
        this.setState({ events: newEvents }, () => {
          this.props.change("events", newEvents);
        });
      }
    }
  };

  // see - https://github.com/arfedulov/semantic-ui-calendar-react
  handleChange = (event, { name, value }) => {
    console.log("xxx name, value", name, value);

    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }

    switch (name) {
      case "dateStart":
        this.props.change("start.date", value);
        break;
      case "dateStop":
        this.props.change("stop.date", value);
        break;
      default:
        break;
    }
  };

  handleUserRelayChange = (e, data) => {
    console.log("xxx handleUserRelayChange ", data);

    const { value, polygonId } = data;

    let newEvents = [...this.state.events];

    // copy event & update it
    let currentEvent = {
      ...newEvents.find((event) => event.polygonId === polygonId),
    };
    currentEvent.userRelay = Number(value);

    // filter event
    newEvents = newEvents.filter((event) => event.polygonId !== polygonId);

    // add back the update event
    newEvents.push(currentEvent);
    this.setState({ events: newEvents });
  };

  handleTrashClick = (e, data) => {
    //console.log("xxx handleTrashClick data", data);

    let newEvents = [...this.state.events];
    newEvents = newEvents.filter((event) => event.polygonId !== data.id);

    this.setState({ events: newEvents }, () =>
      this.props.change("events", newEvents)
    );
  };

  handleChangeStepperBitRegister = (value) => {
    this.setState({ valueBitRegister: value });
  };

  handleChangeDuration = (value) => {
    this.setState({ valueDuration: value });
    this.props.change("duration.value", Number(value));
  };

  // if duration units change reset the value -> 0
  handleSelectDurationUnit = () => {
    this.setState({ valueDuration: "1" });
    this.props.change("duration.value", Number(1));
  };

  render() {
    const {
      header,
      handleSubmit,
      pristine,
      submitting,
      error,
      initialValues,
      strings,
      reduxFormValues,
      cronStartExpression,
      cronStopExpression,
      userLanguage,
      everyDay,
      everyHour,
      everyMinute,
      id,
    } = this.props;

    // APP_TERMINOLOGY
    let strScheduledEvent = strings?.["Scheduled Job"];
    let strScheduledEvents = strings?.["Scheduled Jobs"];

    const isNewScheduledEvent = id === "undefined";

    const { errorMessage } = this.state;

    //console.log("xxx this.props", this.props);
    // console.log("xxx initialValues", initialValues);
    // console.log("xxx initialValues", initialValues);
    console.log("xxx reduxFormValues", reduxFormValues);
    // console.log("xxx cronStartExpression", cronStartExpression);
    // console.log("xxx cronStopExpression", cronStopExpression);

    // see - https://github.com/bradymholt/cRonstrue/tree/master/src/i18n/locales
    // for a list of supported locales
    // userLanguage will be e.g. "es-419" so strip off the locale
    let cronstrueLocale = "en";
    const userLocale = userLanguage?.split("-")?.[0] || "en";
    if (userLocale) cronstrueLocale = userLocale;
    //console.log("xxx cronstrueLocale", cronstrueLocale);

    // #NOTE - restrict the minimum event period to 1 minutes ON-OFF.
    // i.e. minimum event period is 1min // #TODO - make this an option??????
    // This allows sufficient time for the ON event to activate and propogate around the system
    // in a slow network sitation before the OFF activation is sent.
    //
    // So cases:
    // - if every 2 minute is selected fix the duration option to 1min
    // - if every 3 min then duration options: 1,2
    // - if every 4 min then duration options: 1,2,3

    const durationInputMax = () => {
      let max = 1;
      const unit = this.props?.reduxFormValues?.duration?.unit;
      const valueMin = this.props?.reduxFormValues?.minute?.value;
      const everyMin = this.props?.reduxFormValues?.minute?.every;

      switch (unit) {
        case "hour":
          max = 23;
          break;
        case "minute":
          if (!everyMin) {
            max = 59;
          } else if (valueMin > 2) {
            max = valueMin - 1;
          }
          break;

        default:
          break;
      }
      return max;
    };

    let optionsFilteredDuration = [...optionsDuration];

    if (everyHour)
      optionsFilteredDuration = optionsFilteredDuration?.filter(
        (opt) => opt.value !== "hour"
      );

    let optionsFilteredDayOfMonth = [...optionsDays()];
    if (everyDay) {
      optionsFilteredDayOfMonth = optionsFilteredDayOfMonth?.filter(
        (opt) => opt.value !== "*"
      );
    }

    const eventsTableNoneContent = (
      <Table.Row>
        <Table.Cell>{strings?.["Add a Polygon"]}</Table.Cell>
        {/* <Table.Cell /> */}
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
    );

    const eventsTableContent = (event) => {
      const {
        polygonId,
        groupLabel,
        levelLabel,
        buttonLabel,
        buttonCustomLabel,
        userRelay,
      } = event;

      // #TODO - change to stepper!!!!!!!!!!1
      //https://github.com/pksilen/semantic-ui-react-numberinput

      const userRelayContent = (event) => {
        return (
          <>
            {/* <Input
              size="mini"
              fluid
              placeholder={strings?.["User Relay"]}
              name="userRelay"
              onChange={this.handleUserRelayChange}
              polygonId={event.polygonId}
              value={event?.userRelay}
              type="number"
              step={1}
              min={0}
              max={15}
              component={renderField}
              validate={[required, minValueEqual0, maxValueEqual15]}
              parse={(value) => (value !== "" ? Number(value) : "")}
            /> */}
            <NumberInput
              //buttonPlacement={"right"}
              minValue={0}
              maxValue={15}
              stepAmount={1}
              value={this.state.valueBitRegister}
              onChange={this.handleChangeStepperBitRegister}
            />
          </>
        );
      };

      return (
        <Table.Row>
          {/* <Table.Cell>{polygonId}</Table.Cell> */}
          <Table.Cell>{groupLabel}</Table.Cell>
          <Table.Cell>{levelLabel}</Table.Cell>
          <Table.Cell>{`${buttonLabel} (${buttonCustomLabel})`}</Table.Cell>
          <Table.Cell>{userRelayContent(event)}</Table.Cell>
          <Table.Cell>
            <Icon
              name={"trash"}
              id={polygonId}
              onClick={this.handleTrashClick}
            />
          </Table.Cell>
        </Table.Row>
      );
    };

    const nextCronTableContent = (startExp, stopExp, userLanguage) => {
      const start = parseExpression(startExp);
      const stop = parseExpression(stopExp);

      const rows = [1, 2, 3];
      const dateFormat = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };

      const content = [];

      rows.forEach((row) => {
        const startDate = new Date(start.next().toString()).toLocaleString(
          userLanguage,
          dateFormat
        );

        const stopDate = new Date(stop.next().toString()).toLocaleString(
          userLanguage,
          dateFormat
        );

        content.push(
          <Table.Row>
            <Table.Cell>{row}</Table.Cell>
            <Table.Cell>{startDate}</Table.Cell>
            <Table.Cell>{stopDate}</Table.Cell>
          </Table.Row>
        );
      });

      return content;
    };

    return (
      <Container>
        <Form onSubmit={handleSubmit(this.submitMyForm)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        {!isNewScheduledEvent && (
                          <Grid.Row>
                            <DeleteButton
                              onClick={this.requestDelete}
                              id={id}
                              strings={strings}
                            />
                            {/* <Button
                            type="button"
                            onClick={this.requestDelete}
                            id={id}
                            color="red"
                          >
                            Delete
                          </Button> */}
                          </Grid.Row>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Button.Group floated="right">
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              //disable={pristine || submitting}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={(e) => this.resetForm(e)}
                            >
                              {strings?.["Reset"]}
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              {strings?.["Cancel"]}
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{header}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column width={6}>
                        <Field
                          name="name"
                          label={strings?.formatString(strings?.["Name"])}
                          //placeholder={`Enter the name of the ${strScheduledEvent}`}
                          placeholder={strings?.formatString(
                            strings?.[
                              "Enter the name of the Scheduled Event Job"
                            ]
                          )}
                          component={renderField}
                          validate={[required, isScheduledEventNameOK]}
                          normalize={(value) => normalise(value, "normalise")}
                        />
                        <div hidden>
                          <Field
                            name="id"
                            label={strings?.["ID"]}
                            placeholder={`Unique ID value of the ${strScheduledEvent}`}
                            component={renderField}
                            disabled={true}
                          />
                        </div>
                        <Field
                          name="mineLevelId"
                          component={ChooseMineLevelField}
                          label={strings?.["Area"]}
                          disabled={!isNewScheduledEvent}
                        />
                        <Segment
                          style={{ backgroundColor: "rgb(248, 248, 249" }}
                        >
                          <Field
                            name="disable"
                            label={strings?.["Disable Job"]}
                            component={renderCheckbox}
                          />
                        </Segment>
                        {
                          // #NOTE - (for future development) - there is not absolute time entry (all times are relative to the server time) so no need to set a timezone for entry
                          false && (
                            <Segment>
                              <div className="select-wrapper">
                                <label
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {strings?.["timezone"]}
                                </label>
                                <div style={{ paddingTop: "4px" }}>
                                  <TimezoneSelect
                                    //className={"ui fluid selection dropdown"}
                                    value={this.state.selectedTimezone}
                                    onChange={this.setSelectedTimezone}
                                  />
                                </div>
                              </div>
                            </Segment>
                          )
                        }
                        {
                          // NOTE - (for future development) - for addition of absolute start/stop times.
                          false && (
                            <Segment>
                              <label>
                                <strong>{strings?.["Start"]}</strong>
                              </label>
                              <Grid style={{ paddingTop: "5px" }}>
                                <Grid.Row>
                                  <Grid.Column>
                                    <Field
                                      component={renderRadio}
                                      label={strings?.["Now"]}
                                      name="start.value"
                                      radioValue={0}
                                    />
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ marginRight: "10px" }}>
                                        <Field
                                          component={renderRadio}
                                          label={strings?.["Date"]}
                                          name="start.value"
                                          radioValue={1}
                                        />
                                      </div>

                                      <SemanticDatepicker
                                        //disabled={true}
                                        // see - https://www.npmjs.com/package/react-semantic-ui-datepickers
                                        locale={userLanguage} // #TODO - add localisation support #REVIEW - TEST!???
                                        clearable
                                        showToday={false}
                                        pointing={"top right"}
                                        format={"DD-MM-YYYY"}
                                        name={"dateStart"}
                                        onChange={this.handleChange}
                                        value={this.state.dateStart}
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          )
                        }
                        <Segment>
                          <label>
                            <strong>{strings?.["Month"]}</strong>
                          </label>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ marginRight: "10px" }}>
                                    <Field
                                      name="month.every"
                                      label={strings?.["every"]}
                                      component={renderCheckbox}
                                      onChange={this.handleCheckEveryMonth}
                                    />
                                  </div>
                                  <Field
                                    name={`month.value`}
                                    placeholder={
                                      strings?.["JOB_MONTH_SELECTOR_POP_MSG"]
                                    }
                                    //multiple={true}
                                    component={renderSelect}
                                    options={
                                      this.props.everyMonth
                                        ? options12months()
                                        : optionsMonths
                                    }
                                    onChange={this.handleSelectMonth}
                                    //validate={[required]}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                        <Segment>
                          <label>
                            <strong>{strings?.["Day"]}</strong>
                          </label>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ marginRight: "10px" }}>
                                    <Field
                                      name="dayOfMonth.every"
                                      label={strings?.["every"]}
                                      component={renderCheckbox}
                                      disabled={this.state.disableDay}
                                      onChange={this.handleCheckEveryDay}
                                    />
                                  </div>
                                  <Field
                                    name={`dayOfMonth.value`}
                                    placeholder={
                                      strings?.["JOB_DAY_SELECTOR_POP_MSG"]
                                    }
                                    //multiple={true}
                                    component={renderSelect}
                                    options={optionsFilteredDayOfMonth}
                                    onChange={this.handleSelectDay}
                                    //validate={[required]}
                                    disabled={this.state.disableDay}
                                  />
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                  <Field
                                    name={`dayOfWeek.value`}
                                    placeholder={
                                      strings?.[
                                        "JOB_DAYOFWEEK_SELECTOR_POP_MSG"
                                      ]
                                    }
                                    //multiple={true}
                                    component={renderSelect}
                                    options={optionsDayOfWeek}
                                    onChange={this.handleSelectDayOfWeek}
                                    //validate={[required]}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                        {false && (
                          <Segment>
                            <label>
                              <strong>{strings?.["DAY_WEEK"]}</strong>
                            </label>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column>
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ marginRight: "10px" }}>
                                      <Field
                                        name="dayOfWeek.every"
                                        label={strings?.["every"]}
                                        component={renderCheckbox}
                                      />
                                    </div>
                                    <Field
                                      name={`dayOfWeek.value`}
                                      placeholder={
                                        strings?.[
                                          "JOB_DAYOFWEEK_SELECTOR_POP_MSG"
                                        ]
                                      }
                                      //multiple={true}
                                      component={renderSelect}
                                      options={optionsDayOfWeek}
                                      onChange={this.handleSelectDayOfWeek}
                                      //validate={[required]}
                                    />
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        )}
                        <Segment>
                          <label>
                            <strong>{strings?.["Hour"]}</strong>
                          </label>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ marginRight: "10px" }}>
                                    <Field
                                      name="hour.every"
                                      label={strings?.["every"]}
                                      component={renderCheckbox}
                                      onChange={this.handleCheckEveryHour}
                                    />
                                  </div>
                                  <Field
                                    name={`hour.value`}
                                    //multiple={true}
                                    placeholder={strings?.["Hours"]}
                                    component={renderSelect}
                                    options={
                                      everyHour
                                        ? options24hours()
                                        : optionsHours()
                                    }
                                    onChange={this.handleSelectHour}
                                    //validate={[required]}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                        <Segment>
                          <label>
                            <strong>{strings?.["Minute"]}</strong>
                          </label>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ marginRight: "10px" }}>
                                    <Field
                                      name="minute.every"
                                      label={strings?.["every"]}
                                      component={renderCheckbox}
                                      onChange={this.handleCheckEveryMinute}
                                    />
                                  </div>
                                  <Field
                                    name={`minute.value`}
                                    //multiple={true}
                                    placeholder={strings?.["Minutes"]}
                                    component={renderSelect}
                                    options={
                                      everyMinute
                                        ? options60mins()
                                        : optionsMinutes()
                                    }
                                    onChange={this.handleSelectMinute}
                                    //validate={[required]}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                        {
                          // NOTE - (for future development) - for addition of absolute start/stop times.
                          // #TODO - if this is developed need to accommodate locale for month format etc
                          false && (
                            <Segment>
                              <label>
                                <strong>{strings?.["Stop"]}</strong>
                              </label>
                              <Grid style={{ paddingTop: "5px" }}>
                                <Grid.Row>
                                  <Grid.Column>
                                    <Field
                                      component={renderRadio}
                                      label={strings?.["Never"]}
                                      name="stop.value"
                                      radioValue={0}
                                    />
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ marginRight: "10px" }}>
                                        <Field
                                          component={renderRadio}
                                          label={strings?.["Date"]}
                                          name="stop.value"
                                          radioValue={1}
                                        />
                                      </div>
                                      <SemanticDatepicker
                                        //disabled={true}
                                        // see - https://www.npmjs.com/package/react-semantic-ui-datepickers
                                        locale={userLanguage} // #TODO - add localisation support - #REVIEW #TEST!!!
                                        clearable
                                        showToday={false}
                                        pointing={"top right"}
                                        format={"DD-MM-YYYY"} // #TODO - add month format support
                                        name={"dateStop"}
                                        onChange={this.handleChange}
                                        value={this.state.dateStop}
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          )
                        }
                        <Segment>
                          <label>
                            <strong>{strings?.["Duration"]}</strong>
                          </label>
                          <Grid style={{ paddingTop: "5px" }}>
                            <Grid.Row columns={"equal"}>
                              <Grid.Column width={6}>
                                <NumberInput
                                  //buttonPlacement={"right"}
                                  minValue={1}
                                  maxValue={durationInputMax()}
                                  stepAmount={1}
                                  value={this.state.valueDuration}
                                  onChange={this.handleChangeDuration}
                                />
                                {/* <Field
                                  style={{ minWidth: "10em" }}
                                  name={`duration.value`}
                                  //multiple={true}
                                  placeholder={"Duration"}
                                  component={renderSelect}
                                  options={
                                    this.props.durationUnit === "hour"
                                      ? options24hours()
                                      : options60mins()
                                  }
                                  onChange={this.handleSelectDuration}
                                  //validate={[required]}
                                /> */}
                              </Grid.Column>
                              <Grid.Column width={10}>
                                <Field
                                  name={`duration.unit`}
                                  //multiple={true}
                                  placeholder={"Duration Units"}
                                  component={renderSelect}
                                  options={optionsFilteredDuration}
                                  onChange={this.handleSelectDurationUnit}
                                  //validate={[required]}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <Grid.Row>
                          <Grid.Column>
                            <Segment>
                              <Grid
                                style={{
                                  backgroundColor: "rgb(248, 248, 249)",
                                }}
                              >
                                <Header style={{ paddingTop: "10px" }} as="h2">
                                  {strings?.["Schedule"]}
                                </Header>
                                <Grid.Row columns={"equal"}>
                                  <Grid.Column>
                                    <Header as="h3" style={{ color: "green" }}>
                                      <Icon name="thumbs up" color="green" />
                                      {strings?.["Activate"]}
                                    </Header>
                                    <Header as="h3">
                                      {cronstrue?.toString(
                                        cronStartExpression,
                                        {
                                          locale: cronstrueLocale,
                                          use24HourTimeFormat: true,
                                        }
                                      )}
                                    </Header>
                                    <div style={DEBUG_HIDDEN}>
                                      <Field
                                        name="cron.start"
                                        placeholder={"- - - - - - -"}
                                        component={renderField}
                                        validate={[
                                          required,
                                          isScheduledEventNameOK,
                                        ]}
                                        normalize={(value) =>
                                          normalise(value, "normalise")
                                        }
                                        disabled
                                      />
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Header as="h3" style={{ color: "red" }}>
                                      <Icon name="thumbs down" color="red" />
                                      {strings?.["Deactivate"]}
                                    </Header>
                                    <Header as="h3">
                                      {cronstrue?.toString(cronStopExpression, {
                                        locale: cronstrueLocale,
                                        use24HourTimeFormat: true,
                                      })}
                                    </Header>
                                    <div style={DEBUG_HIDDEN}>
                                      <Field
                                        name="cron.stop"
                                        placeholder={"- - - - - - -"}
                                        component={renderField}
                                        validate={[
                                          required,
                                          isScheduledEventNameOK,
                                        ]}
                                        normalize={(value) =>
                                          normalise(value, "normalise")
                                        }
                                        disabled
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column>
                                    <Table sortable striped celled>
                                      <Table.Header>
                                        <Table.HeaderCell>
                                          CRON
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {cronStartExpression}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {cronStopExpression}
                                        </Table.HeaderCell>
                                      </Table.Header>
                                      <Table.Header>
                                        <Table.HeaderCell width={1}>
                                          {strings?.["Next"]}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={7}>
                                          {strings?.["Activate"]}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={7}>
                                          {strings?.["Deactivate"]}
                                        </Table.HeaderCell>
                                      </Table.Header>
                                      <Table.Body>
                                        {nextCronTableContent(
                                          cronStartExpression,
                                          cronStopExpression,
                                          userLanguage
                                        )}
                                      </Table.Body>
                                    </Table>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                            {false && (
                              // #REVIEW #TODO - left here as an example of using the job schedule to do other things
                              <Segment>
                                <Header as="h3">
                                  {strings?.["System Message"]}
                                </Header>
                                <Tab
                                  //style={{ paddingTop: "1rem" }}
                                  // className={"????"}
                                  //activeIndex={this.state.activeIndex}
                                  //onTabChange={this.handleTabChange}
                                  menu={{
                                    attached: true,
                                    className: "tabTitleWrapped",
                                    tabular: true,
                                    //style: { display: "flex", justifyContent: "center" },
                                  }}
                                  panes={[
                                    { name: "Broadcast" },
                                    { name: "Email" },
                                    { name: "Text" },
                                  ]?.map((level) => {
                                    // data filter to level
                                    //const levelData = data.filter(item => item.area === level.id) || {};
                                    return {
                                      menuItem: level.name,
                                      render: () => (
                                        <Tab.Pane>
                                          {strings?.["FUTURE_FEATURE"]}
                                        </Tab.Pane>
                                      ),
                                    };
                                  })}
                                />
                              </Segment>
                            )}
                            <Segment>
                              <Header as="h3">
                                {strings?.["Lighting Event"]}
                              </Header>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column>
                                    <Field
                                      name="note"
                                      label={strings?.["Note"]}
                                      placeholder={
                                        strings?.[
                                          "Sched_Event_Note_Placeholder"
                                        ]
                                      }
                                      component={renderTextArea}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row
                                  style={{
                                    paddingBottom: "0px",
                                  }}
                                >
                                  <Grid.Column>
                                    <label>
                                      <strong>
                                        {strings?.["Choose Polygons"]}
                                      </strong>
                                    </label>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row
                                  style={{
                                    paddingTop: "5px",
                                  }}
                                >
                                  <Grid.Column>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ paddingRight: "10px" }}>
                                        <Field
                                          name={`namedAreaGroupOption.group`}
                                          //label={strings?.["Group"]}
                                          placeholder={
                                            strings?.["Select Group"]
                                          }
                                          //multiple={true}
                                          component={renderSelect}
                                          options={this.props.groupOptions}
                                          onChange={this.handleSelectGroup}
                                          //validate={[required]}
                                        />
                                      </div>
                                      <div>
                                        <Icon name="arrow right" />
                                      </div>
                                      <div style={{ paddingRight: "10px" }}>
                                        <Field
                                          name={`namedAreaGroupOption.level`}
                                          //label={strings?.["Level"]}
                                          placeholder={
                                            strings?.["Select Level"]
                                          }
                                          //multiple={true}
                                          component={renderSelect}
                                          options={this.props.levelOptions}
                                          onChange={this.handleSelectLevel}
                                          //validate={[required]}
                                        />
                                      </div>
                                      <div>
                                        <Icon name="arrow right" />
                                      </div>
                                      <div style={{ paddingRight: "10px" }}>
                                        <Field
                                          name={`namedAreaGroupOption.polygonId`}
                                          //label={strings?.["Polygon"]}
                                          placeholder={
                                            strings?.["Select Polygon"]
                                          }
                                          //multiple={true}
                                          component={renderSelect}
                                          options={this.props.polygonOptions}
                                          onChange={this.handleSelectPolygon}
                                          //validate={[required]}
                                        />
                                      </div>
                                      <div>
                                        <Icon name="arrow right" />
                                      </div>
                                      <Popup
                                        content={
                                          strings?.[
                                            "Click to add selected Polygon"
                                          ]
                                        }
                                        trigger={
                                          <Button
                                            type="button"
                                            size="large"
                                            color="blue"
                                            onClick={this.onClickAddEvent}
                                            disabled={this.state.isAddEvent}
                                          >
                                            {strings?.["Add"]}
                                          </Button>
                                        }
                                      />
                                    </div>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row
                                  style={{
                                    paddingTop: "5px",
                                  }}
                                >
                                  <Grid.Column>
                                    <Table sortable celled striped>
                                      <Table.Header>
                                        {/* <Table.HeaderCell>
                                          {strings?.["ID"]}
                                        </Table.HeaderCell> */}
                                        <Table.HeaderCell>
                                          {strings?.["Group"]}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {strings?.["Level"]}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          {strings?.["Polygon"]}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3}>
                                          {strings?.["User Relay"]}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={1}>
                                          <Icon name="trash" />
                                        </Table.HeaderCell>
                                      </Table.Header>
                                      <Table.Body>
                                        {this.state?.events?.length < 1
                                          ? eventsTableNoneContent
                                          : this.state?.events?.map?.((event) =>
                                              eventsTableContent(event)
                                            )}
                                      </Table.Body>
                                    </Table>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        <DebugPagePropsMessages that={this} />
      </Container>
    );
  }
}

const selector = formValueSelector("scheduledEventForm");

ScheduledEventForm = reduxForm({
  form: "scheduledEventForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(ScheduledEventForm);

const getGroupOptions = (nagInfo, areaId, strings) => {
  let options = [];

  if (areaId === undefined) {
    options.push({
      key: "none",
      text: strings?.["Choose an Area"],
      value: "none",
    });
    return options;
  }

  if (nagInfo.length < 1) {
    options.push({
      key: "none",
      text: strings?.["No Groups defined"],
      value: "none",
    });
    return options;
  }

  nagInfo.forEach((info, idx) => {
    const isKeyExists = options?.some(
      (option) => option.value === info.groupLabel
    );
    //console.log("xxx isKeyExists info", info);
    //console.log("xxx isKeyExists", isKeyExists);
    if (
      !isKeyExists
      // stop duplicate keys
    ) {
      options.push({ key: idx, text: info.groupLabel, value: info.groupLabel });
    }
  });
  return options;
};

const getLevelOptions = (nagInfo, groupOption, strings) => {
  let options = [];

  if (groupOption === "none") {
    options.push({
      key: "none",
      text: strings?.["Choose a Group"],
      value: "none",
    });
    return options;
  }

  if (nagInfo.length < 1) {
    options.push({
      key: "none",
      text: strings?.["No Levels defined"],
      value: "none",
    });
    return options;
  }

  nagInfo.forEach((info, idx) => {
    if (info.groupLabel === groupOption) {
      const isKeyExists = options?.some(
        (option) => option.value === info.levelLabel
      );

      if (
        // stop duplicate keys
        !isKeyExists
      ) {
        options.push({
          key: idx,
          text: info.levelLabel,
          value: info.levelLabel,
        });
      }
    }
  });
  return options;
};

const getPolygonOptions = (
  nagInfo,
  groupOption,
  levelOption,
  events,
  strings
) => {
  let options = [];

  if (levelOption === "none") {
    options.push({
      key: "none",
      text: strings?.["Choose a Level"],
      value: "none",
    });
    return options;
  }

  if (nagInfo.length < 1) {
    options.push({
      key: "none",
      text: strings?.["No Polygons defined"],
      value: "none",
    });
    return options;
  }

  nagInfo.forEach((info, idx) => {
    //console.log("xxx getPolygonOptions info", info);
    //console.log("xxx getPolygonOptions groupOption", groupOption);
    //console.log("xxx getPolygonOptions levelOption", levelOption);
    //console.log("xxx getPolygonOptions events", events);
    //console.log("xxx getPolygonOptions info.polygonId", info.polygonId);

    const isAlreadySelected = events?.some(
      (event) => event.polygonId === info.polygonId
    );

    //console.log("xxx getPolygonOptions isAlreadySelected", isAlreadySelected);
    //console.log("xxx getPolygonOptions ------------------");

    if (
      info.levelLabel === levelOption &&
      info.groupLabel === groupOption &&
      !isAlreadySelected
    ) {
      const text = `${info.buttonLabel} ${
        info.buttonCustomLabel !== "" ? `(${info.buttonCustomLabel})` : ""
      }`;
      options.push({
        key: idx,
        text,
        value: info.polygonId,
      });
    }
  });
  return options;
};

const mapStateToProps = (state, props) => {
  const mineLevelId = selector(state, "mineLevelId");
  const area = getAreaStatusesById(state, mineLevelId);

  // #NOTE #REVIEW - this is used to create the selector options for the polygon with [x] Scheduled Event checked (i.e. permission bit 2 = true in namedAreaGroup)
  // This `getNamedAreasInfoInNamedAreaGroupByAreaId` is very similar to the golang func() `Get_Named_Areas_Info_In_Named_Area_Group` since the later was
  // created to support the trigger event mqtt information messages 'ext_trigger/+/info'. This could be cleaned up to have one source of information.
  //
  const namedAreaGroupInfo = getNamedAreasInfoInNamedAreaGroupByAreaId(
    state,
    mineLevelId
  );

  // filter info based on settings.permission kthbit 2 is set for 'scheduled_event'
  const newNamedAreaGroupInfo = namedAreaGroupInfo.filter((info) =>
    isKthBitSet(info.permission, 2)
  );

  const groupOptions = getGroupOptions(
    newNamedAreaGroupInfo,
    mineLevelId,
    props.strings
  );

  const levelOptions = getLevelOptions(
    newNamedAreaGroupInfo,
    selector(state, "namedAreaGroupOption.group"),
    props.strings
  );

  const polygonOptions = getPolygonOptions(
    newNamedAreaGroupInfo,
    selector(state, "namedAreaGroupOption.group"),
    selector(state, "namedAreaGroupOption.level"),
    selector(state, "events"),
    props.strings
  );

  // console.log("xxx mineLevelId", mineLevelId);
  // console.log("xxx namedAreaGroupInfo", namedAreaGroupInfo);
  // console.log("xxx newNamedAreaGroupInfo", newNamedAreaGroupInfo);
  // console.log("xxx groupOptions", groupOptions);
  // console.log("xxx levelOptions", levelOptions);
  // console.log("xxx polygonOptions", polygonOptions);

  return {
    id: props?.id || "undefined",
    mineLevelId,
    area,
    cronStartExpression:
      selector(state, "cron.start") || DEFAULT_CRON_EXPRESSION,
    cronStopExpression: selector(state, "cron.stop") || DEFAULT_CRON_EXPRESSION,

    everyMonth: selector(state, "month.every"),
    everyDay: selector(state, "dayOfMonth.every"),
    everyHour: selector(state, "hour.every"),
    everyMinute: selector(state, "minute.every"),
    durationUnit: selector(state, "duration.unit"),
    // selector options
    groupOptions,
    levelOptions,
    polygonOptions,
    namedAreaGroupInfo,
    //
    events: selector(state, "events"),

    reduxFormValues: getFormValues("scheduledEventForm")(state),
    onChange: (values, dispatch, props, previousValues) => {
      const { change } = props;

      console.log("xxx onChange values", values);
      console.log("xxx onChange props.reduxFormValues", props.reduxFormValues);
      const newCron = convertFieldsToCronExpression(props.reduxFormValues);

      dispatch(change("cron.start", newCron.start));
      dispatch(change("cron.stop", newCron.stop));
    },
  };
};

export default connect(mapStateToProps, null)(ScheduledEventForm);
