// see - https://codesandbox.io/s/04lz4580pl?file=/src/index.js
// - https://stackoverflow.com/questions/62420839/reading-multiple-files-cotents-react-js
import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Popup, Icon } from "semantic-ui-react";

import {
  saveFolderFile,
  fetchFolderFilesListByFolder,
} from "components/Settings/actions";

class _Upload extends Component {
  constructor() {
    super();
    this.state = {
      isFileUploaded: false,
      show: false,
      //
      file: null,
      fileName: "",
      fileType: "",
      //
      //
      uploadButton: { disabled: false },
    };
  }

  fileInputRef = React.createRef();

  requestAction = (action) => {
    const { file } = this.state;

    const { saveFolderFile } = this.props;

    let promiseArray = [];

    if (action === "save") {
      //for (var key in selections) {
      //const selection = key; // mac address

      //console.log("saveFolderFile file ", file);

      promiseArray.push(
        new Promise((resolve, reject) =>
          saveFolderFile({
            values: {
              filename: "filenameblah", // #WIP #REVIEW - ATM we do not pass the filename and folder. These default
              folder: "folderblah",
              file: file,
            },
            resolve,
            reject,
          })
        )
      );
      //}
    }

    //console.log("saveFolderFile file promiseArray ", promiseArray);

    return Promise.all(promiseArray)
      .then((results) => {
        console.log(" .... processed request", results);
      })
      .then(
        () => {
          this.props.fetchFolderFilesListByFolder("backups"); // #FETCHS
          //this.props.push(`/tools/backup-restore`);
        },
        (errorMsg) => {
          console.log("action failed", errorMsg); // #TODO probs should show this?
        }
      );
  };

  fileChange = (e) => {
    this.setState({ file: e.target.files[0] }, () => {
      //console.log("fileupload File chosen --->", this.state.file);

      this.requestAction("save");

      // set a timer so the user does not pound the upload button,
      // as the upload takes a few seconds to be processed
      this.setState({
        uploadButton: { disabled: true },
      });
      setTimeout(() => {
        this.setState({
          uploadButton: { disabled: false },
        });
      }, 4000); // wait for a timeout before making button active
    });
  };

  render() {
    const {
      popup = "",
      label = "",
      fileType = ".zip",
      disabled = false,
    } = this.props;
    return (
      <>
        <Popup
          content={popup}
          trigger={
            <Button
              //icon={"upload"}
              //labelPosition={"left"}
              content={label}
              color={"teal"}
              size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              onClick={() => this.fileInputRef.current.click()}
              disabled={this.state.uploadButton.disabled || disabled}
              loading={this.state.uploadButton.disabled}
            ></Button>
          }
        />
        <input
          ref={this.fileInputRef}
          type="file"
          accept={fileType}
          hidden
          onChange={this.fileChange}
        />
      </>
    );
  }
}

export const Upload = connect(null, {
  saveFolderFile,
  fetchFolderFilesListByFolder,
})(_Upload);
