import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";

import {
  Header,
  Grid,
  Table,
  Container,
  Input,
  Pagination,
  Dropdown,
  Icon,
  Segment,
  Button,
  Checkbox,
  Radio,
  Popup,
  Message,
  Tab,
  Form,
} from "semantic-ui-react";

import { renderField } from "admin/form-field";

import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import GenericButton from "admin/GenericButton";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging
import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";
//import { StatusEnum } from "utils/StatusEnum";

import { Download } from "components/Download/index.js";

import { fetchNamedAreaExtTriggerEventInfo } from "NamedAreas/actions";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

const validate = (values) => {
  const { mineLevelId, areaName, regions } = values;

  const errors = {};

  if (!mineLevelId) {
    errors.mineLevelId = "Required";
  }
  if (!areaName) {
    errors.areaName = "Required";
  }

  if (_isEmpty(regions)) {
    errors.regions = "Required";
  }

  return errors;
};

const displayOptions = [
  {
    key: "all",
    text: "Show All",
    value: "all",
  },
  {
    key: "ok",
    text: "OK",
    value: "ok",
  },
  {
    key: "commissioned",
    text: "New",
    value: "commissioned",
  },
  // {
  //   key: "conflict",
  //   text: "Position Conflict",
  //   value: "conflict",
  // },
  // {
  //   key: "moved",
  //   text: "Position Moved",
  //   value: "moved",
  // },
  {
    key: "shifted",
    text: "Position Shifted",
    value: "shifted",
  },
  {
    key: "timeout",
    text: "Timeout",
    value: "timeout",
  },
  {
    key: "old",
    text: "No Status Report",
    value: "old",
  },
];

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class ExternalTriggerEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        id: { string: "", include: true },
        active: { string: "", include: true },
        area: { string: "", include: true },
        group: { string: "", include: true },
        level: { string: "", include: true },
        polygon: { string: "", include: true },
        source: { string: "", include: true },
        reason: { string: "", include: true },
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox delete selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
      // default for radio button group
      statusGroup: "all",
      //
      // tab info
      activeIndex: 0,
    };
  }

  componentDidMount() {
    const {
      data,
      //fetchUPSs,
      //fetchFireflys,
      componentstate,
    } = this.props;

    const filterInput = componentstate.get(
      "filterInput",
      "externalTriggerList"
    );

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });

      // reset the radio button states
      //
      const filterInputStatusString = filterInput?.status?.string;

      switch (filterInputStatusString) {
        case "":
          this.setState({ statusGroup: "all" });
          break;
        case "ok":
          this.setState({ statusGroup: "ok" });
          break;
        case "Notcommissioned":
          this.setState({ statusGroup: "commissioned" });
          break;
        case "sameposition.Statusreportmissing":
          this.setState({ statusGroup: "conflict" });
          break;
        case "Reportedtopologychanged":
          this.setState({ statusGroup: "moved" });
          break;
        case "Shifted":
          this.setState({ statusGroup: "shifted" });
          break;
        case "Nostatusreport":
          this.setState({ statusGroup: "old" });
          break;
        case "Timeout":
          this.setState({ statusGroup: "timeout" });
          break;
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "externalTriggerList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          const checkItemString = item[key]?.toLowerCase()?.replace(/\s/g, ""); // strip white spaces from value to e.g. "UPS02" = "UPS 02"
          const checkMatchString = match[key]?.string?.toLowerCase();
          const checkIncludes =
            checkMatchString !== "" // remove item which don't match, but exclude "" empty case
              ? match[key].include && checkItemString.includes(checkMatchString) //'.include' = true for include, = false for exclude
              : true;

          checkMatch = checkMatch && checkIncludes;
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });

      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  //  handleFilterStatusChange = ({ checked, value }) => { // <<--- for radio buttons
  handleFilterStatusChange = (e, data) => {
    this.setState({ statusGroup: data.value });

    let search = "";

    switch (data.value) {
      case "ok":
        search = "ok";
        break;
      case "commissioned":
        search = "Not commissioned";
        break;
      case "conflict":
        search = "same position. Status report missing";
        break;
      case "moved":
        search = "Reported topology changed";
        break;
      case "shifted":
        search = "Shifted";
        break;
      case "old":
        search = "No status report";
        break;
      case "timeout":
        search = "Timeout";
        break;
      case "all":
      default:
        search = "";
        break;
    }
    this.setFilterState({ name: "status", value: search }, true);
  };

  handleFilterChange = (e) => {
    const target = e.target;
    this.setFilterState(target, true);
  };

  setFilterState = ({ name, value }, include) => {
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // strip white spaces from value to e.g. "UPS02" = "UPS 02"
    // update match value with most recent filter entry
    match[name].string = value.replace(/\s/g, "");
    match[name].include = include;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (e, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState(
      (prevState) => {
        if (_has(prevState.selections, id)) {
          // { 1: true } -> {}
          return _derivedState(
            update(prevState, {
              selections: { $unset: [id] },
            })
          );
        }
        // {} -> { 1: true }
        return _derivedState(
          update(prevState, {
            selections: { [id]: { $set: true } },
          })
        );
      }
      //() => console.log("xxx this.state.selections", this.state.selections)
    );
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        if (
          true
          // #WIP - left in place, may be useful
          // item.statusValue !== StatusEnum.NOT_COMMISSIONED ||
          // this.props.isDeleteFireflyNotCommissioned // &&  item.statusValue !== StatusEnum.OK
        ) {
          newSelections[item.id] = true;
        }
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  resetForm = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // https://github.com/Asymmetrik/ngx-leaflet/issues/88
    // unmount error - "TypeError: Cannot read property 'off' of undefined"

    // abort page changes
    //this.props.reset();
    this.props.onCancel();
  };

  submitMyForm = (values) => {
    //console.log(`LocalisationEditForm submitted: values`, values);

    this.props.onSubmit(values);
  };

  requestAction = (action) => {
    const { selections } = this.state;
    const { push } = this.props;

    let promiseArray = [];

    // if (action === "update") {
    //   this.props.fetchNamedAreaExtTriggerEventInfo();
    // }

    if (action === "delete") {
    }

    if (action === "copy") {
    }
  };

  handleTabChange = (e, { activeIndex, panes }) => {
    // #WIP - debugging reset to latest tab

    const area = panes?.[activeIndex]?.menuItem;
    console.log("kkkkkkkkkk tab handleTabChange ", area, panes, activeIndex);
    this.setState({ activeIndex });
  };

  render() {
    // form props
    const { handleSubmit, pristine, submitting, initialValues } = this.props;

    const { isLoading, error, levels, strings } = this.props;
    const {
      column,
      data: rxData,
      direction,
      filterInput,
      activeIndex,
    } = this.state;

    // filter data based on the tabs selected i.e. activeIndex
    const selectedArea = levels?.[activeIndex]?.id;
    const data = rxData?.filter?.((item) => item.area === selectedArea);

    // APP_TERMINOLOGY
    let strExternalTrigger = strings?.["External Trigger"];
    let strExternalTriggers = strings?.["External Triggers"];

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "extTriglist10" },
              { value: 20, text: "20", key: "extTriglist20" },
              { value: 40, text: "40", key: "extTriglist40" },
              { value: 60, text: "60", key: "extTriglist60" },
              { value: data.length, text: "all", key: "extTriglistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Total of {itemsCount} items.</span>{" "}
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const externalTriggerFromDataById = (id) =>
      allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let updateButton;
    let enableButton;
    let disableButton;
    let deleteButton;
    let copyButton;
    let externalTriggerSelectedList;

    updateButton = (
      <Popup
        content={strings?.["EXT_TRIGGER_EVENT_LIST_UPDATE_POP_MSG"]}
        trigger={
          <Button
            color="orange"
            onClick={() => this.props.fetchNamedAreaExtTriggerEventInfo()}
            size="large"
          >
            {strings?.["Update"]}
          </Button>
        }
      />
    );

    if (selectedCount > 0) {
      updateButton = null; // disable update button if items selected

      let externalTriggerNames = [];

      for (var key in selections) {
        if (externalTriggerFromDataById(key) !== undefined) {
          let externalTriggerName = externalTriggerFromDataById(key)?.id; // #WIP - what should this be???

          externalTriggerNames.push(externalTriggerName);
        }
      }
      externalTriggerNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      externalTriggerNames = externalTriggerNames.join(", "); // separate by commas

      enableButton = (
        <GenericButton
          label="Enable"
          color="blue"
          onClick={() => this.requestAction("enable")}
          size="large"
        />
      );
      disableButton = (
        <GenericButton
          label="Disable"
          color="orange"
          onClick={() => this.requestAction("disable")}
          size="large"
        />
      );
      deleteButton = (
        <DeleteButton
          onClick={() => this.requestAction("delete")}
          strings={strings}
          size="large"
        />
      );

      copyButton = (
        <GenericButton
          label="Copy"
          color="brown"
          onClick={() => this.requestAction("copy")}
          size="large"
        />
      );

      externalTriggerSelectedList = (
        <Segment textAlign="left">
          <strong>{`${strExternalTriggers} selected ...`} </strong>
          {externalTriggerNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <>
        <Form
          onSubmit={handleSubmit(this.submitMyForm)}
          style={{ marginTop: "14px" }}
        >
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <>
                  {updateButton}

                  <span>
                    {
                      strings?.[
                        "Select items in the table below to apply an action"
                      ]
                    }
                  </span>
                </>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or size="large" />
                  {false && copyButton}
                  {false && <Button.Or size="large" />}
                  {false && deleteButton}
                  {false && <Button.Or />}
                  {enableButton}
                  <Button.Or />
                  {disableButton}
                </Button.Group>
              </div>
              <span
                style={{
                  marginLeft: "20px",
                  visibility: areAnySelected ? "visible" : "hidden",
                }}
              >
                {viewablesPage.length} items displayed. {selectedCount} selected
              </span>
              {false && (
                <Button.Group floated="right">
                  <SaveButton
                    submitting={submitting}
                    pristine={pristine}
                    strings={strings}
                    size="large"
                  />
                  <Button.Or />
                  <Button
                    type="button"
                    disabled={pristine || submitting}
                    onClick={(e) => this.resetForm(e)}
                    size="large"
                  >
                    Reset
                  </Button>
                  <Button.Or />
                  <Button
                    style={{ textAlign: "right" }}
                    onClick={(e) => this.cancelForm(e)}
                    size="large"
                  >
                    Cancel
                  </Button>
                </Button.Group>
              )}
              {/* <div>
                <Download data={data} dataType={"firefly"} />
              </div> */}
            </Segment>
            {externalTriggerSelectedList}
          </Segment.Group>
          {levels.length === 0 && (
            <Message
              content={strings?.["No Area data available. Waiting..."]}
            />
          )}
          {levels.length > 0 && (
            <Tab
              //style={{ paddingTop: "1rem" }}
              // className={"????"}
              activeIndex={this.state.activeIndex}
              onTabChange={this.handleTabChange}
              menu={{
                attached: true,
                className: "tabTitleWrapped",
                tabular: true,
                //style: { display: "flex", justifyContent: "center" },
              }}
              panes={levels?.map((level) => {
                // data filter to level
                //const levelData = data.filter(item => item.area === level.id) || {};
                return {
                  menuItem: level.name,
                  render: () => (
                    <Tab.Pane>
                      <Table sortable celled striped>
                        <Table.Header>
                          <Table.Row>
                            {/* <Table.Cell style={headerCellStyle}>
                              <Icon style={{ opacity: "0.7" }} name="trash" />
                            </Table.Cell> */}
                            {/* <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.disable?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="disable"
                                onChange={this.handleFilterChange}
                                value={filterInput?.disable?.string}
                              />
                            </Table.Cell> */}
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.id?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="id"
                                onChange={this.handleFilterChange}
                                value={filterInput?.id?.string}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.active?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="active"
                                onChange={this.handleFilterChange}
                                value={filterInput?.active?.string}
                              />
                            </Table.Cell>
                            {/* <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.area?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="area"
                                onChange={this.handleFilterChange}
                                value={filterInput?.area?.string}
                              />
                            </Table.Cell> */}
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.group?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="group"
                                onChange={this.handleFilterChange}
                                value={filterInput?.group?.string}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.level?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="level"
                                onChange={this.handleFilterChange}
                                value={filterInput?.level?.string}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.polygon?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="polygon"
                                onChange={this.handleFilterChange}
                                value={filterInput?.polygon?.string}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.source?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="source"
                                onChange={this.handleFilterChange}
                                value={filterInput?.source?.string}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.reason?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="reason"
                                onChange={this.handleFilterChange}
                                value={filterInput?.reason?.string}
                              />
                            </Table.Cell>
                            <Table.Cell style={headerCellStyle}>
                              <Input
                                size="mini"
                                fluid
                                className={
                                  filterInput?.lastStatusReport?.string
                                    ? "filterInputHighlight"
                                    : null
                                }
                                icon="search"
                                placeholder={strings?.["Filter..."]}
                                name="lastStatusReport"
                                onChange={this.handleFilterChange}
                                value={filterInput?.lastStatusReport?.string}
                              />
                            </Table.Cell>
                            {/* <Table.Cell style={headerCellStyle}>
                  <Dropdown
                    fluid
                    selection
                    className={
                      this.state.statusGroup !== "all"
                        ? "dropdownSelectioHighlight"
                        : null
                    }
                    style={{ margin: "5px", width: "150px" }}
                    options={displayOptions}
                    value={this.state.statusGroup}
                    onChange={this.handleFilterStatusChange}
                    //onChange={(e, data) => this.handleFilterStatusChange(data)}
                  />
                </Table.Cell> */}
                          </Table.Row>
                          <Table.Row>
                            {/* <Table.HeaderCell>
                              <Checkbox
                                checked={this.areAllSelected(viewablesPage)}
                                indeterminate={this.areAllIndeterminate(
                                  viewablesPage
                                )}
                                onChange={() =>
                                  this.handleSelectAll(viewablesPage)
                                }
                              />
                            </Table.HeaderCell> */}
                            {/* <Table.HeaderCell
                              width={1}
                              sorted={column === "disable" ? direction : null}
                              onClick={this.handleSort("disable")}
                            >
                              {strings?.["Disabled"]}
                            </Table.HeaderCell> */}
                            <Table.HeaderCell
                              sorted={column === "id" ? direction : null}
                              onClick={this.handleSort("id")}
                            >
                              {strings?.["External Trigger ID"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={column === "active" ? direction : null}
                              onClick={this.handleSort("active")}
                            >
                              {strings?.["Active"]}
                            </Table.HeaderCell>
                            {/* <Table.HeaderCell
                              sorted={column === "area" ? direction : null}
                              onClick={this.handleSort("area")}
                            >
                              {strings?.["Area"]}
                            </Table.HeaderCell> */}
                            <Table.HeaderCell
                              sorted={column === "group" ? direction : null}
                              onClick={this.handleSort("group")}
                            >
                              {strings?.["Group"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={column === "level" ? direction : null}
                              onClick={this.handleSort("level")}
                            >
                              {strings?.["Level"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={column === "polygon" ? direction : null}
                              onClick={this.handleSort("polygon")}
                            >
                              {strings?.["Polygon"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={column === "source" ? direction : null}
                              onClick={this.handleSort("source")}
                            >
                              {strings?.["Event Source"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={column === "reason" ? direction : null}
                              onClick={this.handleSort("reason")}
                            >
                              {strings?.["Note"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              sorted={
                                column === "lastStatusReport" ? direction : null
                              }
                              onClick={this.handleSort("lastStatusReport")}
                            >
                              {strings?.["Latest Report"]}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {_.map(
                            viewablesPage,
                            ({
                              id,
                              active,
                              area,
                              group,
                              level,
                              polygon,
                              source,
                              reason,
                              lastStatusReport,
                              style,
                              disable,
                            }) => (
                              <Table.Row key={`row-id-${id}`} style={style}>
                                {/* <Table.Cell>
                                  <Checkbox
                                    checked={this.isItemSelected(id)}
                                    onChange={() => this.handleSelect(id)}
                                    // disabled={disableDelete}
                                  />
                                </Table.Cell> */}
                                {/* <Table.Cell>{disable || "-"}</Table.Cell> */}
                                <Table.Cell>
                                  {id}
                                  {/* <Field
                                  name={`fieldData["${id}"].id`}
                                  type="text"
                                  component={renderField}
                                /> */}
                                </Table.Cell>
                                <Table.Cell>{active || "-"}</Table.Cell>
                                {/* <Table.Cell>{area || "-"}</Table.Cell> */}
                                <Table.Cell>{group || "-"}</Table.Cell>
                                <Table.Cell>{level || "-"}</Table.Cell>
                                <Table.Cell>{polygon || "-"}</Table.Cell>
                                <Table.Cell>{source || "-"}</Table.Cell>
                                <Table.Cell>{reason || "-"}</Table.Cell>
                                <Table.Cell>
                                  {lastStatusReport || "-"}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                          <TrailingContent
                            data={data}
                            isLoading={isLoading}
                            error={error}
                          />
                        </Table.Body>
                      </Table>
                      {pagination}
                    </Tab.Pane>
                  ),
                };
              })}
            />
          )}
        </Form>
        <DebugPagePropsMessages that={this} />
      </>
    );
  }
}

ExternalTriggerEditForm = reduxForm({
  form: "externalTriggerEditForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(ExternalTriggerEditForm);

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues("externalTriggerEditForm")(state), // #REVIEW - only used for debugging,
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("externalTriggerEditForm onChange values", values);
    },
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, {
    fetchNamedAreaExtTriggerEventInfo,
    saveUserSettingsComponentState,
  })(ExternalTriggerEditForm)
);
