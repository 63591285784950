import React from 'react'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'

import { withMineLevel } from 'ApplicationContexts'

const MineLevelPageHeader = ({ ...props }) => {
  return (
    <Header as='h2'>
      {props.mineLevel.name} - {props.children}
    </Header>
  )
}

export default withMineLevel(MineLevelPageHeader)
