import { myApi } from "api/apiAxios";
import { dummyResponse } from "api/apiAxios";

export function* getUserById(id) {
  const response = yield myApi.get("/api/get_user", { email: id });
  //console.log(`API_TEST_apiUsers get_user`, response);
  return response;
}

export function* getAllUsers() {
  const response = yield myApi.get("/api/get_all_users");
  //console.log(`API_TEST_apiUsers get_all_users`, response);
  return response.data;
}

export function* saveUser({
  id,
  username,
  email,
  role,
  password,
  language,
  settings,
}) {
  // console.log("SAVE_USER api", {
  //   id,
  //   username,
  //   email,
  //   role,
  //   password,
  //   language,
  //   settings,
  // });
  const response = yield myApi.post(`/api/add_user`, {
    username,
    email,
    role,
    password,
    language,
    settings: JSON.stringify(settings),
  });
  //console.log(`API_TEST_apiUsers add_user`, response);
  return response;
}

export function* deleteUser(id) {
  //console.log("deleteUser api", id);
  const response = yield myApi.post(`/api/delete_user`, { email: id });
  //console.log(`API_TEST_apiUsers delete_user`, response);
  return response;
}

export function* changeRole({ id, role }) {
  //console.log("CHANGE_USER_ROLE api", { id, role });
  const response = yield myApi.post("/api/change_role", {
    email: id,
    role: role,
  });
  //console.log(`API_TEST_apiUsers changeRole`, response);
  return response;
}

export function* changePassword({ id, password }) {
  const response = yield myApi.post("/api/change_password", {
    email: id,
    password: password,
  });
  //console.log(`API_TEST_apiUsers change_password`, response);
  return response;
}

export function* changeName({ id, username }) {
  //console.log("CHANGE_USER_NAME api", { id, username });
  const response = yield myApi.post("/api/change_name", {
    email: id,
    username: username,
  });
  //console.log(`API_TEST_apiUsers change_name`, response);
  return response;
}

export function* changeLanguage({ id, language }) {
  const response = yield myApi.post("/api/change_language", {
    email: id,
    language: language,
  });
  //console.log(`API_TEST_apiUsers changeLanguage change_language`, response);
  return response;
}

export function* changeSettings({ id, settings }) {
  console.log("CHANGE_USER_SETTINGS api", { id, settings });
  //const response = yield dummyResponse(true);

  const response = yield myApi.post("/api/change_settings", {
    email: id,
    settings: JSON.stringify(settings),
  });
  //console.log(`API_TEST_apiUsers change_settings`, response);
  return response;
}
