import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import ListMineLevels from "admin/mine-level/MineLevelList";
import CreateMineLevel from "admin/mine-level/MineLevelCreatePage";
import EditMineLevel from "admin/mine-level/MineLevelEditPage";
import { fetchMineLevels } from "MineLevels/actions";

class MineLevelAdminPages extends Component {
  componentDidMount() {
    // const { fetchMineLevels } = this.props;
    // // #FETCHS
    // fetchMineLevels(); // #FETCHS
  }

  render() {
    const { match, strings } = this.props;
    return (
      <Switch>
        <Route
          path={`${match.url}/new`}
          //component={CreateMineLevel}
          render={(props) => <CreateMineLevel {...props} strings={strings} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          //component={EditMineLevel}
          render={(props) => <EditMineLevel {...props} strings={strings} />}
        />
        <Route
          exact
          path={match.url}
          //component={ListMineLevels}
          render={(props) => <ListMineLevels {...props} strings={strings} />}
        />
      </Switch>
    );
  }
}

export default withRouter(
  connect(null, { fetchMineLevels })(MineLevelAdminPages)
);
