import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Header, Grid, List, Segment } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

class ToolsMqtt extends Component {
  render() {
    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">MQTT Tools</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment>
            <List>
              <List.Item>Send Delete MQTT to ...</List.Item>
              <List.Item>Send Null MQTT to ...</List.Item>
              <List.Item>Clear MQTT broker database</List.Item>
            </List>
          </Segment>
        </Container>
      </div>
    );
  }
}

//export default connect(null)(ToolsMqtt);
export default ToolsMqtt;
