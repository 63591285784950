import Immutable from "seamless-immutable";

import { allowedLanguages as _allowedLanguages } from "components/ConfigJs";

const initialState = Immutable({
  localisation: {},
});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "LOCALISATION_FETCH_SUCCEEDED":
      const {
        localisation: { list },
      } = payload;

      console.log("LOCALISATION_FETCH_SUCCEEDED", list);
      newState.localisation = list;
      return newState;

    case "RESET":
      console.log("RESET! - Localisation");
      return state;
    default:
      return state;
  }
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}
export const getLocalisation = (state) => {
  const byId = state.localisation.localisation;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getLocalisationByApplicationId = (state, id) => {
  const allTerms = getLocalisation(state).filter(
    (local) => local.application === "default"
  );
  return allTerms;
};

export const getLocalisationByComponentId = (state, id) => {
  const allTerms = getLocalisationByApplicationId(state, id).filter(
    (local) => local.component === id
  );

  //console.log("getLocalisationByComponentId  allTerms", allTerms);

  const uniqueLanguages = allTerms
    .map((item) => item.language)
    .filter((value, index, self) => self.indexOf(value) === index);

  // put english en-US first in array
  const sortUniqueLanguages = [
    ...uniqueLanguages.filter((item) => item === "en-US"),
    ...uniqueLanguages.filter((item) => item !== "en-US"),
  ];

  //console.log("getLocalisationByComponentId  sortUniqueLanguages", allTerms);

  let result = {};
  sortUniqueLanguages.forEach((language) => {
    const byLanguage = allTerms.filter((term) => term.language === language);
    let terms = {};
    byLanguage.forEach((term) => {
      terms[term.reference] = term.term;
    });
    result[language] = terms;
  });

  //console.log("getLocalisationByComponentId  result", result);

  return result;
};
