// see - src/admin/named-area-group/NamedAreaGroupSettingsModal.js

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Segment,
  Grid,
  Modal,
  Header,
  Icon,
  Button,
  Ref,
} from "semantic-ui-react";
import isEqual from "lodash/isEqual";
import { formatRelative, parseISO } from "date-fns";

import { getEquivalentSemanticColorName } from "colorpalette";
import { StatusEnumToReadable } from "utils/StatusEnum";
import { getMapMarkerDisplayOptions } from "components/ConfigJs";

import ForceSingleLight from "components/Map/ForceSingleLight";

import {
  getFireflyById,
  getControllerById,
  getFireflyCoordinatesById,
} from "components/WebWorker/reducer";

import { getNamedAreaEventsByAreaId } from "components/WebWorker/reducer";

import { createFireflyNamedAreaId } from "NamedAreas/createNamedAreaAroundFirefly";

import UPSLink from "admin/ups/UPSLink";
import FireflyLink from "admin/firefly/FireflyLink";

import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import { allowedLightColors as _allowedLightColors } from "components/ConfigJs";

class FireflyMarkerPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      getMapMarkerDisplayOptions: {},
      allowedLightColors: ["red", "amber", "white", "green", "blue"],
      isDirty: false,
      isChanging: false,
    };
  }

  componentDidMount() {
    const { open, isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;

    if (open) {
      this.handleOpen();
    }

    if (isPokeTheWorker) {
      TurnOnOffPokeTheWorker(false);
    }

    this.setState({ getMapMarkerDisplayOptions: getMapMarkerDisplayOptions() });

    const allowedLightColors = _allowedLightColors();

    if (allowedLightColors) {
      const newAllowedLightColors = allowedLightColors.map(
        (color) => color.key
      );

      this.setState({
        allowedLightColors: newAllowedLightColors,
      });
    }
  }

  componentWillUnmount() {
    const { isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;
    if (!isPokeTheWorker) {
      TurnOnOffPokeTheWorker(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }

    // check if still processing/updating from change request
    const { light } = this.props;
    const { color, lightState, event_id } = light;
    const { status: colorStatus, plan: colorPlan } = color;
    const { status: lightStatus, plan: lightPlan } = lightState;

    // if the form is dirty
    if (this.state.isDirty) {
      // we need a way to wait for the changes to start getting underway.
      //
      // The changes can take a few seconds to get underway (around the system) so
      // to record the start of changes i.e. when something has started changing.
      // This avoids a timer.
      //
      // test for a change...

      // if isChanging flag is not set
      if (
        !this.state.isChanging &&
        (colorPlan !== colorStatus ||
          lightPlan?.led_state !== lightStatus?.led_state ||
          prevProps?.light?.event_id !== event_id)
      ) {
        // set it
        this.setState({ isChanging: true });
      }

      // if isChanging has been set, but now everything is OK
      if (
        this.state.isChanging &&
        colorPlan === colorStatus &&
        lightPlan?.led_state === lightStatus?.led_state &&
        prevProps?.light?.event_id === event_id
      ) {
        // clear everything
        this.setState({ isDirty: false });
        this.setState({ isChanging: false });
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.resetModal();

    // reset the form
    //this.setState({ value: "" }, () => this.props.reset());
  };

  handleForceSingleLightChange = (value) => {
    console.log("handleForceSingleLightChange", value); //
    const { isDirty } = value;
    this.setState({ isDirty: isDirty });
  };

  render() {
    const {
      id,
      ups_id,
      area,
      easting,
      northing,
      X,
      Y,
      z,
      utm_zone_number,
      utm_zone_letter,
      fireflyNote = "",
      //color,  // No longer used, see 'light' below
      //led_state, // No longer used, see 'light' below
      lastStatusReport,
      light,
      namedAreaEvents,
      role,
      ffMac,
      deviceStatus,
      forcedClickList,
      namedAreaEventForFirefly,
    } = this.props;

    const { allowAdmin } = role;

    //const { getMapMarkerDisplayOptions } = this.state;

    // default display options
    const {
      firefly: {
        showMac = false,
        showStatus = true,
        showUtm = false,
        showEventInfo = false,
        showForced = true,
      },
    } = getMapMarkerDisplayOptions();

    // #NOTE - this should be "namedAreaEventForForcedFirefly"

    const {
      id: currentForcedId = "",
      active: isCurrentForcedActive = false,
      active_color: currentForcedColor = "",
      active_state: currentForcedState = "on",
    } = namedAreaEventForFirefly;

    //    console.log("fff namedAreaEventForForcedFirefly", namedAreaEventForFirefly);

    const isStrobe = ["STROBE", "FORWARD", "BACKWARD"].includes?.(
      currentForcedState?.toUpperCase?.()
    );
    const isOff = ["OFF"].includes?.(currentForcedState?.toUpperCase?.()); // ||     led_state?.toUpperCase?.() === "OFF";

    //console.log(`LIGHT DETAILS FOR FIREFLY `, JSON.stringify(light));

    // In #DEMO_MODE some of these properties may not exist on 1st load so define all ...
    const color = light?.color || { status: "", plan: "" };
    const lightState = light?.lightState || { status: "", plan: "" };
    const event_id = light?.event_id || "unknown";

    const { status: colorStatus, plan: colorPlan } = color;
    const { status: lightStatus, plan: lightPlan } = lightState;

    const controllerLink = <UPSLink id={ups_id}>{ups_id}</UPSLink>;
    const controllerNoLink = <strong>{ups_id || "unknown"} </strong>;

    const fireflyLink = <FireflyLink id={id}>{id}</FireflyLink>;
    const fireflyNoLink = <strong>{id.replaceAll("_", " ")}</strong>;

    const deviceStatusString =
      deviceStatus?.length > 0
        ? deviceStatus.map((status) => StatusEnumToReadable[status])?.join(", ")
        : "OK";

    const showForcedStyleDisplayHidden = !showForced
      ? { display: "none" }
      : null;

    const isProcessingRequest = this.state.isDirty;

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header icon="cog" content={`FireFly - ${id}`} />
        <Modal.Content>
          <Grid>
            <Grid.Row
              key={`key-FF-${id}`}
              style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>FireFly</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                {allowAdmin ? fireflyLink : fireflyNoLink}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              key={`key-FF-${ups_id}`}
              style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>Controller</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                {allowAdmin ? controllerLink : controllerNoLink}
              </Grid.Column>
            </Grid.Row>
            {showMac && (
              <Grid.Row
                key={`key-FF-${id}-mac`}
                style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
              >
                <Grid.Column width={4}>
                  <strong>MAC</strong>
                </Grid.Column>
                <Grid.Column width={11}>{ffMac}</Grid.Column>
              </Grid.Row>
            )}
            {showUtm && (
              <Grid.Row
                key={`key-FF-${id}-utm`}
                style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
              >
                <Grid.Column width={4}>
                  <strong>UTM</strong>
                </Grid.Column>
                <Grid.Column width={11}>
                  <strong>Easting:</strong> {easting} <br />
                  <strong>Northing:</strong> {northing} <br />
                  <strong>Zone:</strong> {utm_zone_number} / {utm_zone_letter}
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row
              key={`key-FF-${id}-color`}
              style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>Color</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                <strong>Requested: </strong>
                <span
                  style={{
                    fontWeight: "bold",
                    color: getEquivalentSemanticColorName(colorPlan),
                  }}
                >
                  {isOff
                    ? "OFF"
                    : colorPlan === "orange"
                    ? "AMBER"
                    : colorPlan.toUpperCase()}
                </span>
                <br />
                <strong>Reported: </strong>
                <span
                  style={{
                    fontWeight: "bold",
                    color: `${getEquivalentSemanticColorName(colorStatus)}`,
                  }}
                >
                  {isOff
                    ? "OFF"
                    : colorStatus === "orange"
                    ? "AMBER"
                    : colorStatus.toUpperCase()}
                  {colorPlan !== colorStatus ? (
                    <span
                      style={{ paddingLeft: "2px", color: "tomato" }}
                      className={"flash-text"}
                    >
                      ...updating...
                    </span>
                  ) : null}
                </span>
                <br />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              key={`key-FF-${id}-state`}
              style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>State</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                <strong>Requested: </strong>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {isOff ? "OFF" : lightPlan?.led_state?.toUpperCase()}
                </span>
                <br />
                <strong>Reported: </strong>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {isOff ? "OFF" : lightStatus?.led_state?.toUpperCase()}
                  {lightPlan?.led_state !== lightStatus?.led_state ? (
                    <span
                      style={{ paddingLeft: "2px", color: "tomato" }}
                      className={"flash-text"}
                    >
                      ...updating...
                    </span>
                  ) : null}
                </span>
              </Grid.Column>
            </Grid.Row>
            {showEventInfo && event_id !== "" && event_id !== currentForcedId && (
              <Grid.Row
                key={`key-FF-${id}-event-info`}
                style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
              >
                <Grid.Column width={4}>
                  <strong>Event Info</strong>
                </Grid.Column>
                <Grid.Column width={11}>
                  <strong>ID:</strong> {event_id} <br />
                </Grid.Column>
              </Grid.Row>
            )}
            {showForced && currentForcedId !== "" && (
              <Grid.Row
                key={`key-FF-${id}-forced-info`}
                style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
              >
                <Grid.Column width={4}>
                  <strong>Forced Info</strong>
                </Grid.Column>
                <Grid.Column width={11}>
                  <strong>ID:</strong> {currentForcedId} <br />
                  <strong>COLOR:</strong> {isOff ? "OFF" : currentForcedColor}{" "}
                  <br />
                  <strong>STATE:</strong> {currentForcedState}
                </Grid.Column>
              </Grid.Row>
            )}
            {isProcessingRequest && (
              <Grid.Row
                key={`key-FF-${id}-forced-request`}
                style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
              >
                <Grid.Column width={4}>
                  <strong>Changed</strong>
                </Grid.Column>
                <Grid.Column width={11} style={{ color: "tomato" }}>
                  <strong>Change requested. Processing ...</strong>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row
              key={`key-FF-${id}-last`}
              style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>Last Report</strong>
              </Grid.Column>
              <Grid.Column width={11}>{lastStatusReport}</Grid.Column>
            </Grid.Row>

            {showForced && allowAdmin && (
              <Grid.Row
                key={`key-FF-${id}-forced`}
                style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
              >
                <Grid.Column width={4}>
                  <strong>Force Light</strong>
                </Grid.Column>
                <Grid.Column width={11}>
                  <ForceSingleLight
                    fireflyId={id}
                    easting={easting}
                    northing={northing}
                    area={area}
                    color={color}
                    currentForcedColor={currentForcedColor}
                    currentForcedState={currentForcedState}
                    isCurrentForcedActive={isCurrentForcedActive}
                    isStrobe={isStrobe}
                    isOff={isOff}
                    onChange={this.handleForceSingleLightChange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row
              key={`key-FF-${id}-notes`}
              style={{ paddingTop: "0.5em" }}
            >
              <Grid.Column width={4}>
                <strong>Notes</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                {fireflyNote !== ""
                  ? fireflyNote
                  : "There are no notes for this firefly."}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel}>
            <Icon name="close" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    initialValues: { id, area },
  } = props;

  // user roles
  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);
  const allowGuest = userGuest;

  const allowOperator = userSuper || userAdmin || userUser;
  const allowAdmin = userSuper || userAdmin;
  const allowSuper = userSuper;

  const namedAreaEvents = getNamedAreaEventsByAreaId(state, area);

  // #NOTE - this should be "createForcedFireflyNamedAreaId"
  const fireflyNamedAreaId = createFireflyNamedAreaId(id);

  // #NOTE - this should be "namedAreaEventForForcedFirefly"
  const namedAreaEventForFirefly =
    namedAreaEvents?.find((event) => event.id.includes(fireflyNamedAreaId)) ||
    {};

  const firefly = getFireflyById(state, id);
  const fireflyCoords = getFireflyCoordinatesById(state, id);

  // console.log(`fff fireflyNamedAreaId`, fireflyNamedAreaId);
  // console.log(`fff namedAreaEvents`, namedAreaEvents);
  // console.log(`fff namedAreaEventForFirefly`, namedAreaEventForFirefly);
  // console.log(`fff firefly`, firefly);

  const {
    light,
    fireflyNote,
    utm: { northing, easting, utm_zone_letter, utm_zone_number, z },
    topology: { ups_id, deviceStatus },
    // device: { mac: ffMac },
    timestamp,
  } = firefly;

  // #TODO - when data is similated there is no device object so get error
  // "TypeError: Cannot read properties of undefined (reading 'mac')"
  // This patches the problem, but this should be resolved by making a proper
  // default 'device' property in the 'firefly' object.
  const ffMac = firefly?.device?.mac || "";

  const lastStatusReport =
    timestamp !== undefined
      ? formatRelative(parseISO(timestamp), new Date(), {
          includeSeconds: true,
        })
      : "-";

  return {
    id,
    ups_id,
    area,
    easting,
    northing,
    z,
    X: fireflyCoords?.[0],
    Y: fireflyCoords?.[1],
    utm_zone_number,
    utm_zone_letter,
    fireflyNote,
    lastStatusReport,
    namedAreaEventForFirefly,
    light,
    namedAreaEvents,
    role: {
      allowGuest: allowGuest,
      allowOperator: allowOperator,
      allowAdmin: allowAdmin,
      allowSuper: allowSuper,
    },
    ffMac,
    deviceStatus,
    // forcedClickList,
    isPokeTheWorker: isOnOffPokeTheWorker(state),
  };
}

export default connect(mapStateToProps, {
  TurnOnOffPokeTheWorker,
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
})(FireflyMarkerPopupModal);
