import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import _isEmpty from "lodash/isEmpty";

import { saveNewMineLevel, fetchMineLevels } from "MineLevels/actions";

import CreateMineLevelForm from "./MineLevelCreateForm";

import SubmissionModal from "admin/SubmissionModal";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import { getAllAreaStatuses } from "components/WebWorker/reducer";

import { fetchFolderFilesListByFolder } from "components/Settings/actions";

import { messageToken } from "utils/messageToken";
import { mqttPublish } from "components/WebWorker/actions";

import {
  SubmissionError,
  stopSubmit,
  startSubmit,
  //
} from "redux-form";

import { Dimmer, Loader } from "semantic-ui-react";

class CreateMineLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }

  componentDidMount() {
    const { allAreas, goto } = this.props;

    // If no areas data is loaded redirect to area list
    // this is typically when the the page is F5 reloaded.
    // Can't operate without area data.
    //

    // #REVIEW - disable this for Adding new Areas. In the case of 1st installation there will be no
    // areas except for the defaultArea, but the defaultArea is excluded from State
    // see " if (area !== "defaultArea") {" ... src/components/WebWorker/reducer.js
    //
    // This check is intended to prevent issues with slow load/accumulation of data (esp. over MQTT) when the
    // page is reloaded, however this may not be an issue if/when the area updates are done via POST.
    // e.g. schenario. Direct page load on 'http://localhost:3000/admin/area/new'

    //
    if (false) {
      console.log("componentDidMount checkDataOk allAreas", allAreas);
      const checkDataOk = _isEmpty(allAreas);
      if (checkDataOk) {
        goto("/admin/area");
      }
    }
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    const { mineLevel, goto } = this.props;

    // If no areas data is loaded redirect to area list
    // this is typically when the the page is F5 reloaded.
    // Can't operate without area data.
    //
    const checkDataOk = _isEmpty(mineLevel);
    if (checkDataOk) {
      goto("/admin/area");
    }
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    //console.log(`submitMyForm values`, values);

    const {
      saveNewMineLevel,
      startSubmit,
      stopSubmit,
      fetchMineLevels,
      fetchFolderFilesListByFolder,
    } = this.props;

    // set namedAreaGroup to values
    // this is done outside of the form because namedAreaGroup is maintained elsewhere - see `src/admin/named-area-group/NamedAreaGroup.js`
    const newValues = { ...values, namedAreaGroup: JSON.stringify({}) };

    // #NOTE - submit is managed manually in this process.
    // Start and Stop submit is used.

    return new Promise((resolve, reject) => {
      startSubmit();
      saveNewMineLevel({ values: newValues, resolve, reject });
    })
      .then((result) => {
        console.log(`saveNewMineLevel onSubmit SUCESSFULL! `, result);
        stopSubmit({});
        this.setState(
          {
            submitted: true,
          },
          () => {
            console.log("CLEAR ALL SYSTEM EVENTS - system/event/clear");
            // after namedAreaGroup has changed, clear the existing events.
            //
            // #WIP
            // - this needs to change to clear only defined AREAS
            // - change to API which can be resolved
            // -
            this.props.mqttPublish({
              topic: `system/event/clear`,
              qos: 0,
              message: {
                clear_events: true,
                token: messageToken(),
              },
              retained: false,
            });

            this.onResetError();
            // After saving the area return to control screen.
            // #NOTE - aside from a more logical UX, this has the additional benefit that
            // users return to area edit via the MineLevelList which updated the state,
            // especially the `folderFilesList` which takes time to update/return.
            //

            fetchFolderFilesListByFolder();
            fetchMineLevels();
            this.props.goto("/admin/area");
          }
        );
      })
      .catch((error) => {
        console.log(`onSubmit failed `, error);
        this.onSetError(`${error}`);
        stopSubmit({});
      });
  };

  render() {
    const { error, submitting, strings } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    if (error) {
      return (
        <>
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    return (
      <>
        <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          errorMessage={errorMessage}
        />
        <Dimmer active={submitting} page>
          <Loader>Saving changes</Loader>
        </Dimmer>
        <NavigationPromptModal
          formName={"createNewMineLevelForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <CreateMineLevelForm
          onSubmit={(values) => this.submitMyForm(values)}
          // #NOTE - onSubmitSuccess is not defined.
          // This is managed through submitMyForm.

          onSubmitFail={(errors) => {
            console.log(`onSubmitFail errors`, errors);
          }}
          onCancel={() => {
            this.props.goto("/admin/area");
          }}
          strings={strings}
        />
      </>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    saveNewMineLevel: (data) => dispatch(saveNewMineLevel(data)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    stopSubmit: (errors) => {
      dispatch(stopSubmit("createNewMineLevelForm", errors));
    },
    startSubmit: (errors) => {
      dispatch(startSubmit("createNewMineLevelForm"));
    },
    fetchMineLevels: () => {
      dispatch(fetchMineLevels());
    },
    fetchFolderFilesListByFolder: () => {
      dispatch(fetchFolderFilesListByFolder());
    },
    mqttPublish: (msg) => dispatch(mqttPublish(msg)),
  };
};

function mapStateToProps(state, props) {
  const allAreas = getAllAreaStatuses(state);

  return {
    allAreas, // only used to check if content loaded
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMineLevel);
