import Immutable from "seamless-immutable";
import { setCookie } from "cookies";
import { isConfigJs, pollRates } from "components/ConfigJs";

const initialState = Immutable({
  namedAreaDisplaySettings: {},
  folderFilesList: {},
  serverInfo: {},
  serverStatus: "OK", // assume everything is OK ;)
  turnOnPokeWorker: true,
  //
  pollRates: {
    disable: false, // disables data collection via web worker
    worker: 10 * 1000, // 10 seconds // #DEFAULT_POLL_RATE on startup
    workerEssentials: 1 * 1000, //  1 second // #DEFAULT_POLL_RATE on startup
    server: 30 * 1000, // 30 seconds // #DEFAULT_POLL_RATE on startup
  },
  //
  mqttBroker: {},
  //
  // monitors loading of essential settings data to manage startup/reload
  settingsDataLoading: true,
  //
  // keep copies of loaded map images
  mapImages: [],
  mapSettings: [],
  //
  checkService: [], // status of services when logged in
  //
  isSystemRestore: false, // flag indicating restore has been triggered
});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "SET_NAMED_AREA_DISPLAY_SETTINGS":
      newState.namedAreaDisplaySettings = payload;
      return newState;

    case "UPDATE_NAMED_AREA_DISPLAY_SETTINGS":
      const { hint, legend, view, columnWidth, sopInterface } = payload;

      // update cookie setting
      setCookie("hint", hint, 500);
      setCookie("legend", legend, 500);
      setCookie("view", view, 500);
      setCookie("columnWidth", columnWidth, 500);
      setCookie("sopInterface", sopInterface, 500);

      newState.namedAreaDisplaySettings = payload;
      return newState;

    case "FOLDER_FILES_LIST_FETCH_SUCCEEDED":
      //console.log(`FOLDER_FILES_LIST_FETCH_SUCCEEDED payload`, payload);

      const {
        data: { list, folder },
      } = payload;

      // list may return null if no files
      let newList = [];
      if (list !== null) {
        newList = list;
      }

      // clone it
      let newFolderFilesList = JSON.parse(
        JSON.stringify(newState.folderFilesList)
      );

      newFolderFilesList[folder] = newList;
      newState.folderFilesList = newFolderFilesList;
      return newState;

    case "SERVER_INFO_FETCH_SUCCEEDED":
      const { info } = payload;

      //console.log(`SERVER_INFO_FETCH_SUCCEEDED`, info);
      newState.serverInfo = info;

      // set server status
      newState.serverStatus = "OK";
      return newState;

    // #NOTE - should generalise these fetch failures as common error banner
    // #WIP
    case "SERVER_INFO_FETCH_FAILED":
      //console.log(`SERVER_INFO_FETCH_FAILED status:`, payload);

      // pop a message banner for SERVER_CONNECTION_CLOSED
      // see - `src/components/Settings/sagas.js`
      // When fetch fails msg SERVER_CONNECTION_CLOSED is sent to pop server failed dlg

      // set server status
      newState.serverStatus = payload;

      return newState;

    case "SYSTEM_TURN_ON_OFF_POKE_THE_WORKER":
      //console.log(`SYSTEM_TURN_ON_OFF_POKE_THE_WORKER payload:`, payload);

      newState.turnOnPokeWorker = payload;

      return newState;

    case "SYSTEM_DISABLE_POKE_THE_WORKER":
      //console.log(`SYSTEM_DISABLE_POKE_THE_WORKER payload:`, payload);

      newState.pollRates = { ...newState.pollRates, disable: payload };

      return newState;

    case "SYSTEM_SET_POLL_RATES":
      console.log(`SYSTEM_SET_POLL_RATES payload:`, payload);

      newState.pollRates = payload;
      return newState;

    case "SYSTEM_POLL_RATE_POKE_THE_WORKER":
      //console.log(`SYSTEM_POLL_RATE_POKE_THE_WORKER payload:`, payload);

      newState.pollRates = { ...newState.pollRates, worker: payload };
      return newState;

    case "MQTT_BROKER_FETCH_SUCCEEDED":
      //console.log(`MQTT_BROKER_FETCH_SUCCEEDED`, payload);

      const {
        mqttBrokerAddress: address,
        mqttBrokerAddressTWO: addressSecondary, // if it exists
        mqttBrokerPort,
        mqttBrokerProtocol,
      } = payload;

      const port =
        window.location.protocol === "https:"
          ? mqttBrokerPort.https
          : mqttBrokerPort.http;

      const protocol =
        window.location.protocol === "https:"
          ? mqttBrokerProtocol.https
          : mqttBrokerProtocol.http;

      newState.mqttBroker = { address, addressSecondary, port, protocol };

      // set dataLoading flag, ATM this is the only essential data required before started.
      // This should be expanded later to accommodate more server based settings.
      newState.settingsDataLoading = false;
      return newState;

    // #WIP - not implemented
    case "MQTT_BROKER_FETCH_FAILED":
      console.log(`MQTT_BROKER_FETCH_FAILED payload`, payload);
      //
      // ...if fails pull in mqtt details from secondary sources like network.js
      //newState.mqttBroker = payload;
      return newState;

    case "SERVER_CHECK_SERVICE_FETCH_SUCCEEDED":
      console.log(`SERVER_CHECK_SERVICE_FETCH_SUCCEEDED`, payload);

      const { service } = payload;
      let newCheckService = [...newState.checkService];
      // remove existing service (if exists)
      newCheckService = newCheckService.filter(
        (item) => item.service !== service
      );
      // push new result
      newCheckService.push(payload);

      // console.log(
      //   "xxx postCheckService SERVER_CHECK_SERVICE_FETCH_SUCCEEDED newCheckService",
      //   newCheckService
      // );

      newState.checkService = newCheckService;

      return newState;

    // #WIP - not implemented
    case "SYSTEM_SET_MAP_IMAGE":
      console.log(`SYSTEM_SET_MAP_IMAGE payload`, payload);
      const { id, imageData } = payload;

      let newMapImages = [...newState.mapImages];
      // remove existing image with the same id
      newMapImages = newMapImages.filter((img) => img.id !== id);
      // push new image
      newMapImages.push(payload);

      console.log(`SYSTEM_SET_MAP_IMAGE newMapImages`, newMapImages);
      newState.mapImages = newMapImages;
      return newState;

    case "SYSTEM_SET_MAP_SETTING":
      // console.log(
      //   `MapViewState >>>>>>>>>>> SYSTEM_SET_MAP_SETTING payload`,
      //   payload
      // );
      const { mapSettingsId } = payload;

      let newMapSettings = [...newState.mapSettings];
      // remove existing settings
      newMapSettings = newMapSettings.filter(
        (img) => img.mapSettingsId !== mapSettingsId
      );
      // push new image
      newMapSettings.push(payload);
      newState.mapSettings = newMapSettings;
      return newState;

    case "SET_SYSTEM_RESTORE":
      const { restore } = payload;
      newState.isSystemRestore = restore;
      return newState;

    case "RESET":
      console.log("RESET! - Settings");
      return initialState;

    default:
      return state;
  }
}

export function getNamedAreaDisplaySettings(state) {
  return state.settings.namedAreaDisplaySettings;
}

export const getFolderFilesListById = (state, id) =>
  state.settings.folderFilesList[id];

export const getFolderFileNamesListById = (state, id) =>
  state.settings.folderFilesList?.[id]?.map((file) => file.filename) || [];

export const getServerInfo = (state) => state.settings.serverInfo;

export const getServerInfoVersion = (state) =>
  state.settings.serverInfo?.version;

export const getServerInfoMemStat = (state) =>
  state.settings.serverInfo?.mem_stat;

export const getServerStatus = (state) => state.settings.serverStatus;

export const isOnOffPokeTheWorker = (state) => state.settings.turnOnPokeWorker;

export const isDisabledPokeTheWorker = (state) =>
  state.settings.pollRates.disable;

export const getPollRates = (state) => state.settings.pollRates;

export const getMqttBroker = (state) => state.settings.mqttBroker;

export const getSettingsDataLoading = (state) =>
  state.settings.settingsDataLoading;

export const getMapImages = (state) => state.settings.mapImages;
export const getMapImageById = (state, id) =>
  state.settings.mapImages.find((img) => img.id === id);

export const getMapSettings = (state) => state.settings.mapSettings;
export const getMapSettingsById = (state, id) =>
  state.settings.mapSettings.find((img) => img.id === id);

export const getCheckService = (state) => state.settings.checkService;

export const isServiceBackupMonitor = (state) =>
  state.settings.checkService.some(
    (item) =>
      item.service === "iot-firefly-server-backup-monitor" &&
      item.check === "active"
  );

export const isServiceTagTracker = (state) =>
  state.settings.checkService.some(
    (item) => item.service === "iot-tag-tracker" && item.check === "active"
  );

export const isSystemRestore = (state) => state.settings.isSystemRestore;
