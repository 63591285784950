import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import { combineReducers } from "redux";

import { parseDate } from "utils/format-date";

import {
  getNamedAreaEvents,
  getNamedAreaEventsParentsByAreaId,
  getNamedAreaEventsByParentId,
} from "components/WebWorker/reducer";

import { channelBufferPublishMsg } from "apiSaga";
import * as Api from "api/api";
import { microTime } from "utils/microTime";

const activeOpsInitialState = Immutable({
  remoteButtonClick: [],
});

function reduceActiveOps(state = activeOpsInitialState, action = {}) {
  const newState = { ...state };

  const { type, payload } = action;
  switch (type) {
    case `UPDATE_REMOTE_BUTTON_CLICK`:
      newState.remoteButtonClick = payload.array;
      return newState;

    case "REMOVE_REMOTE_BUTTON_CLICK":
      let newRemoteButtonClick = [...newState.remoteButtonClick];
      newRemoteButtonClick = newRemoteButtonClick.filter(
        (item) => item !== payload.id
      );
      newState.remoteButtonClick = newRemoteButtonClick;
      return newState;
    case "CLEAR_REMOTE_BUTTON_CLICK":
      return activeOpsInitialState;
    default:
      return newState;
  }
}

export default combineReducers({
  activeOpsChanges: reduceActiveOps,
});

export const getRemoteButtonClick = (state) => {
  return state.operationalChanges?.activeOpsChanges?.remoteButtonClick;
};

//const getStateSlice = (state) => state.operationalChanges;

export const isActiveEvent = (state, props) => {
  const namedAreaId = props?.area?.spec;
  const areaId = props?.area?.id;
  const namedAreaPriority = props?.operation?.priority;

  // console.log("isActiveEvent namedAreaId", namedAreaId);
  // console.log("isActiveEvent areaId", areaId);
  // console.log("isActiveEvent namedAreaPriority", namedAreaPriority);

  if (
    namedAreaId !== undefined &&
    namedAreaPriority !== undefined
    //&& areaId !== "ALL_AREAS" // #REVIEW - how to handle ALL_AREAS ?????????
  ) {
    // #REVIEW - better as a selector? quicker?

    const namedAreaEventsParents = getNamedAreaEventsParentsByAreaId(
      state,
      areaId
    );

    //console.log("isActiveEvent namedAreaEventsParents", namedAreaEventsParents);

    if (namedAreaEventsParents) {
      const events = Object.keys(namedAreaEventsParents);

      //console.log("isActiveEvent events", events);
      if (events.includes(namedAreaId)) {
        // console.log(
        //   "isActiveEvent namedAreaEventsParents[namedAreaId]",
        //   namedAreaEventsParents[namedAreaId]
        // );
        const { priority, active } = namedAreaEventsParents[namedAreaId];
        if (priority === namedAreaPriority && active) {
          return true;
        }
      }
    }
  }
  return false;
};

// #WIP - left as-is
export const getActiveForcedOverride = (state, fireflyId) => {
  const activeChanges = []; // disabled // getActiveOperationalChanges(state);

  for (var i = 0; i < activeChanges.length; i++) {
    const change = activeChanges[i];
    const {
      createdFrom: { area, operation },
    } = change;
    if (
      area.type === "SINGLE_LIGHT" &&
      operation.type === "CUSTOM" &&
      area.spec === fireflyId
    ) {
      return change;
    }
  }
  return null;
};
