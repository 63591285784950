import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Segment, Dimmer, Loader, Icon } from "semantic-ui-react";

//import NamedAreaOperationsList from "./NamedAreaOperationsList";

import NamedAreaDisplaySettings from "pages/ControlRoomPage/NamedAreaDisplaySettings";

import MapSimple from "components/Map/MineLevelMapLeafletSimple";
import Map from "components/Map/MineLevelMapLeaflet";

import MineLevelPrecannedOperationsList from "pages/ControlRoomPage/MineLevelPrecannedOperationsList";
import MineLevelCustomSOPList from "pages/ControlRoomPage/MineLevelCustomSOPList";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import {
  isConfigJs,
  faultMessageBannerDisplay,
  faultMessageBannerText,
  isBoomgateCrane as _isBoomgateCrane,
  isLightingControl as _isLightingControl,
  isAreaMapSimple,
  isAreaImageToState,
} from "components/ConfigJs";

import { isUserGuest } from "auth/reducer";

import { getAllAreaStatuses } from "components/WebWorker/reducer";

import { getFolderFileNamesListById } from "components/Settings/reducer";
import { imageCheck } from "utils/imageCheck";

import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

// >>>>> flash message support
import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
  clearFlashMessages,
  SetMessageBanner,
} from "FlashMessages/actions";

import cuid from "cuid";
// <<<<< flash message support

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { isMapMoving } from "components/Map/reducer";
import { isDraggingMarker } from "components/Map/reducer";
import { getObjectDiff } from "utils/getObjectDiff";

import { SetMapImage } from "components/Settings/actions";
import { getMapImages } from "components/Settings/reducer";

class MineLevelOpsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 6,
      isMapLoaded: [],
      isBoomgateCrane: false,
      isLightingControl: false,
      //
      isAreaMapSimple: false,
      isAreaImageToState: false,
    };
  }

  //#DEBUG_SOLUTION - reviewing render states
  //
  // shouldComponentUpdate(nextProps, nextState) {
  //   const thisState = JSON.parse(JSON.stringify(this.state));
  //   const newState = JSON.parse(JSON.stringify(nextState));

  //   const thisProps = JSON.parse(JSON.stringify(this.props));
  //   const newProps = JSON.parse(JSON.stringify(nextProps));

  //   const diffProps = getObjectDiff(thisProps, newProps);
  //   const diffState = getObjectDiff(thisState, newState);

  //   const checkProps = !_isEqual(thisProps, newProps);
  //   const checkState = !_isEqual(thisState, newState);

  //   const check = checkProps || checkState;

  //   if (check) {
  //     console.log(`xxx check diff`, check, diffProps, diffState);
  //     console.log(`xxx check thisProps`, thisProps);
  //     console.log(`xxx check newProps`, newProps);
  //     console.log(`xxx check thisState`, thisState);
  //     console.log(`xxx check newState`, newState);
  //   }

  //   return check;
  // }

  componentDidMount() {
    const { userSettings } = this.props;
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    // configjs settings
    const areaMapSimple = isAreaMapSimple() || false;
    this.setState({ isAreaMapSimple: areaMapSimple });
    //
    const areaImageToState = isAreaImageToState() || false;
    this.setState({ isAreaImageToState: areaImageToState });
    //
    const isBoomgateCrane = _isBoomgateCrane() || false;
    this.setState({ isBoomgateCrane: isBoomgateCrane });

    const isLightingControl =
      userSettings?.application?.includes("smartLighting") ||
      _isLightingControl() ||
      _isLightingControl();
    this.setState({ isLightingControl: isLightingControl });

    const { areaIds, areaImageFileNames, folderFilesList } = this.props;

    //console.log(`xxxxx componentDidMount areaIds `, areaIds);

    // AreaImage preloading
    //
    areaIds.forEach((areaId) => {
      const img = new Image();
      const imageFilename = areaImageFileNames.find?.(
        (item) => item.id === areaId
      )?.src;

      const imageSrc = process.env.PUBLIC_URL + "/areas/" + imageFilename;
      img.src = imageSrc;
      // see - https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
      // #REVIEW
      // Consider this comment about preloading with hooks
      // - https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js/67924817#67924817
      //
      window[imageSrc] = img;
      img.onload = () => {
        if (false) {
          console.log(
            `check ok to collect data? IMAGE LOADED: Loaded `,
            areaId,
            ` <img> image ${imageSrc}`
          );
        }
        let mapsLoaded = this.state.isMapLoaded;
        mapsLoaded.push(areaId);
        this.setState({
          isMapLoaded: mapsLoaded,
        });
      };
    });
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      areaIds,
      isPokeTheWorker,
      isMoving,
      isDragging,
      mapImagesFromState,
    } = this.props;

    // #Note - mapImagesFromState is dependent on 'isAreaImageToState' = true
    const mapImageIds = [...mapImagesFromState.map((img) => img.id)];
    const isMapLoaded = [...this.state.isMapLoaded];

    const shouldTurnOnPokeTheWorker =
      (_isEqual(isMapLoaded.sort(), areaIds) ||
        _isEqual(mapImageIds.sort(), areaIds)) && // all areas are loaded
      !isPokeTheWorker && // worker is already off
      (isMapLoaded.length > 0 || mapImageIds.length > 0) && // there are actually areas loaded through the fetch (i.e. not 0 state initial load situation where areaIds is empty )
      !isMoving && // the map isn't moving or popup is not displaying.
      !isDragging; // the map markers are not being dragged/repositioned

    if (false) {
      console.log(`check ok to collect data? - level`, this.props?.level?.id);

      console.log(
        `check ok to collect data? - _isEqual(isMapLoaded.sort(), areaIds)`,
        _isEqual(isMapLoaded.sort(), areaIds)
      );
      console.log(
        `check ok to collect data? - _isEqual(mapImageIds.sort(), areaIds)`,
        _isEqual(mapImageIds.sort(), areaIds)
      );
      console.log(
        `check ok to collect data? - isMapLoaded `,
        isMapLoaded,
        `areaIds`,
        areaIds
      );

      console.log(
        `check ok to collect data? - !isPokeTheWorker`,
        !isPokeTheWorker
      );
      console.log(
        `check ok to collect data? - isMapLoaded.length`,
        isMapLoaded.length
      );
      console.log(
        `check ok to collect data? - isMapLoaded.length`,
        mapImageIds.length
      );
      console.log(`check ok to collect data? - !isMoving)`, !isMoving);
      console.log(`check ok to collect data? - !isDragging)`, !isDragging);

      console.log(`check ok to collect data? - ----------------)`);
      console.log(
        `check ok to collect data? - shouldTurnOnPokeTheWorker)`,
        shouldTurnOnPokeTheWorker
      );
      console.log(`check ok to collect data? - ----------------)`);
    }

    // if loaded all areas turn on data again
    //
    // turn on data collecting again if ...
    if (shouldTurnOnPokeTheWorker) {
      // turn on data collection
      this.props.TurnOnOffPokeTheWorker(true);
    }
  }

  // #WIP - #TODO - MAKE COMMON
  // see - src/components/Map/MineLevelMapLeaflet.js
  // message banner support
  //
  //
  faultMessageBannerId = (faultType) => {
    // check if a message of this type exists

    // get the id for the flash message
    const messageBanners = this.props.messageBanners; // from redux state

    //console.log(`messageBanners`, messageBanners);
    return messageBanners[faultType].id;
  };

  clearFaultMessageBanner = (faultType) => {
    // get the id for the flash message
    const bannerId = this.faultMessageBannerId(faultType);

    //console.log(`message bannerId`, bannerId);
    if (bannerId) {
      this.props.removeFlashMessage(bannerId);
      //#REVIEW - make this a function..........
      // delete this banner message from state
      let tmpObj = {};
      tmpObj.faultType = faultType;
      tmpObj.display = false;
      tmpObj.id = "";

      this.props.SetMessageBanner(tmpObj);
    }
  };

  areaImageNotFoundMessage = (areaImageNotFound, imageFilename) => {
    const messageBanners = this.props?.messageBanners;

    if (messageBanners === undefined) return;

    // #NOTE - old comment, but still applies. Need to centralise function.
    //#REVIEW - force to one type for now - this needs to be merged with AlarmButtons and other messaging

    const bannerDisplayed = messageBanners["areaImageNotFound"]?.display;
    let bannerInfo = messageBanners["areaImageNotFound"]?.info || [];
    const isBannerDisplayedForThisImageFilename =
      bannerInfo?.includes(imageFilename);

    // console.log(`messageBanners`, messageBanners);
    // console.log(`messageBanners imageFilename`, imageFilename);
    // console.log(`messageBanners bannerInfo`, bannerInfo);
    // console.log(
    //   `messageBanners isBannerDisplayedForThisImageFilename`,
    //   isBannerDisplayedForThisImageFilename
    // );
    // console.log(`messageBanners ---------------------`);

    //
    if (isConfigJs() && faultMessageBannerDisplay()) {
      const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

      // if this fault type allows message banner display (in config)
      // and there hasn't been a message banner displayed previously

      if (isMessageBannerForFaultType["areaImageNotFound"]) {
        if (areaImageNotFound) {
          // set
          if (bannerDisplayed) {
            // banner already exists
            if (!isBannerDisplayedForThisImageFilename) {
              // update banner
              // clear current banner
              this.clearFaultMessageBanner("areaImageNotFound");
              // set a new banner
              this.setFaultMessageBanner([...bannerInfo, imageFilename]);
            }
          } else {
            // no current banner
            // set a new banner
            this.setFaultMessageBanner([...bannerInfo, imageFilename]);
          }
        } else {
          // clear banner
          if (bannerDisplayed) {
            if (isBannerDisplayedForThisImageFilename) {
              // clear just this banner
              // clear current banner
              this.clearFaultMessageBanner("areaImageNotFound");
              // set a new banner
              this.setFaultMessageBanner(
                bannerInfo.filter((file) => file !== "B")
              );
            }
            //
          }
        }
      }
    }
  };

  setFaultMessageBanner = (bannerInfo) => {
    console.log(`message bannerInfo`, bannerInfo);
    // set a new banner
    // create a unique ID for the banner
    const id = cuid();
    // display a new banner
    this.displayErrors(id, "areaImageNotFound", bannerInfo);

    // record the update state of banner including the id (needed to delete this particular banner)
    let tmpObj = {};
    tmpObj.faultType = "areaImageNotFound"; // #REVIEW - force to one status check type for now
    tmpObj.display = true;
    tmpObj.id = id;
    // multiple files : same
    tmpObj.info = bannerInfo;

    this.props.SetMessageBanner(tmpObj);
  };

  // get the text for the fault message banner from config.js or set default
  getFaultMessageBannerText = (faultType) => {
    let header;
    let message;
    let color;

    if (isConfigJs() && faultMessageBannerText()) {
      const messageBannerText = faultMessageBannerText(); // from config.js file
      header = messageBannerText[faultType].header;
      message = messageBannerText[faultType].message;
      color = messageBannerText[faultType].color;
    } else {
      // default messages if config.js object is stuffed
      switch (faultType) {
        case "areaImageNotFound":
          header = "Area Image Can Not Be Found";
          message =
            "An area image can not be found on the server, or the server connection has failed. Check the server operation then log out/in.";
          color = "red";
          break;
        default:
          break;
      }
    }

    return { header, message, color };
  };

  // loads header and message to display on control screen page
  // depending on significance of the error
  // All cases go to the Browser Logger page
  //
  displayErrors(id, faultType, info) {
    const { header, message, color } =
      this.getFaultMessageBannerText(faultType);

    // set color from highest fault_level for the faultType
    //    const color = this.setColorMessageBanner(faultType);

    this.props.addWarningFlashMessageIdColor(
      id,
      color,
      header,
      message + " " + info.join(", ") || "Unknown problem"
    );
    //console.log("message displayErrors - " + header + "-" + message);
  }

  render() {
    const {
      level,
      displaySettings,
      isUserGuest,
      areaIds,
      areaImageFileNames,
      folderFilesList,
      strings,
    } = this.props;

    const {
      isBoomgateCrane,
      isLightingControl,
      isAreaMapSimple,
      isAreaImageToState,
    } = this.state;

    // custom SOP interface
    let sopInterface = displaySettings?.sopInterface;

    if (sopInterface === undefined) {
      sopInterface = true;
    }

    // CSS for Component
    // const styleMineLevelOpsControl = {
    //   height: "100%",
    // };

    // const styleMineLevelMap = {
    //   height: "100vH", // "700px",
    //   width: "100%",
    //   position: "relative",
    //   zIndex: 0,
    // };

    // #NOTE #REVIEW #TODO - make common function
    //
    const { view } = displaySettings;
    let fontSize;
    let paddingBottom;
    switch (view) {
      case "compressed":
        fontSize = 0.6; // 30/50
        paddingBottom = 0.8;
        break;
      case "normal":
        fontSize = 1; // 50/50
        paddingBottom = 1;
        break;
      case "expanded":
        fontSize = 1.6; // 80/50
        paddingBottom = 1.2;
        break;
      default:
        fontSize = 1;
        paddingBottom = 1;
        break;
    }

    const styleInfo = { fontSize, paddingBottom };

    const { columnWidth } = displaySettings;
    let controlsWidth = columnWidth;
    let imageWidth = 16 - controlsWidth;

    let hideLeftColumn = false;
    if (controlsWidth === 0) {
      hideLeftColumn = true;
    }

    let hideRightColumn = false;
    if (controlsWidth === 16) {
      hideRightColumn = true;
    }

    // override if user is Guest or lighting control is not enabled
    if (isUserGuest || !isLightingControl) {
      controlsWidth = 0;
      imageWidth = 16;
      hideLeftColumn = true;
    }

    // console.log(
    //   `this.state.isMapLoaded`,
    //   JSON.stringify(areaIds),
    //   JSON.stringify(this.state.isMapLoaded)
    // );

    // https://stackoverflow.com/questions/19183180/how-to-save-an-image-to-localstorage-and-display-it-on-the-next-page

    // You don't need the getBase64Image function.
    // Data urls are already base 64 so when you call reader.readAsDataURL
    // the e.target.result sent to the reader.onload handler will be all you need.

    function getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    return (
      <div className={"mineLevelOpsControl"}>
        <Grid divided>
          <Grid.Row>
            {!hideLeftColumn && (
              <Grid.Column
                width={controlsWidth}
                key={"left"}
                className={"mineLevelPrecannedOperationsList"}
              >
                <Segment inverted tertiary color="grey">
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <div
                        style={{
                          paddingLeft: "10px",
                          fontSize: `${1 * fontSize}rem`,
                          fontWeight: "bold",
                        }}
                        as="h3"
                      >
                        {level.name}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Segment>
                {!sopInterface && (
                  <MineLevelPrecannedOperationsList mineLevelId={level.id} />
                )}
                {sopInterface && (
                  // #REVIEW #TODO - a div of any size allows overflow to work - why???
                  // <div style={{ height: "0px" }}>
                  <MineLevelCustomSOPList
                    mineLevelId={level.id}
                    styleInfo={styleInfo}
                    strings={strings}
                  />
                  // </div>
                )}
              </Grid.Column>
            )}
            {hideRightColumn && <div style={{ height: "100vh" }}></div>}
            {!hideRightColumn && (
              <Grid.Column width={imageWidth} key={"right"}>
                {areaIds.map((areaId) => {
                  //#NOTE - #WIP - ONLY RENDER ONE IMAGE
                  //

                  if (areaId === level.id) {
                    // if (this.state.isMapLoaded.includes?.(areaId)) {
                    //   return (
                    //     // <Map
                    //     //   className={"mineLevelMap"}
                    //     //   render={`area-${level.id}`}
                    //     //   parentState={this.state}
                    //     //   mineLevelId={level.id}
                    //     //   showMap={areaId === level.id}
                    //     // />
                    //     <Map
                    //       className={"mineLevelMap"}
                    //       render={`area-${areaId}`}
                    //       parentState={this.state}
                    //       mineLevelId={areaId}
                    //       showMap={areaId === level.id}
                    //     />
                    //   );
                    // }

                    const imageFilename = areaImageFileNames.find?.(
                      (item) => item.id === areaId
                    )?.src;

                    const imageSrc =
                      process.env.PUBLIC_URL + "/areas/" + imageFilename;

                    let loadingMessage = ``;

                    // is image already loaded and saved to State
                    const isMapImageFromState =
                      this.props.mapImagesFromState.find(
                        (img) => img.id === areaId
                      );

                    const isMapLoaded =
                      this.state.isMapLoaded.includes?.(areaId) ||
                      isMapImageFromState;

                    // #NOTE
                    // #WIP
                    // work out a solution for presenting the message banner without needing
                    // to check file lists every render.
                    // File image checks should be moved to startup/config process.
                    //

                    if (
                      !folderFilesList?.includes(imageFilename) ||
                      _isEmpty(imageFilename)
                    ) {
                      // set message  banner
                      //this.areaImageNotFoundMessage(true, imageFilename);
                      loadingMessage = `Map image file ${imageSrc} can not be found please check Area settings.`;
                    } else {
                      // clear message  banner
                      //this.areaImageNotFoundMessage(false, imageFilename);
                      loadingMessage = `.... loading map image file ${imageSrc}`;
                    }

                    // #NOTE
                    // #REVIEW - the code below is duplicated in 'src/components/AreaImages/index.js'
                    // <AreaImages /> is called in 'src/components/App/index.js'
                    // to pre-load all images to memory.
                    // The code below pre-dates <AreaImages />, and should be removed after review of the
                    // mapload process.
                    //

                    return (
                      <>
                        <Dimmer
                          active={!isMapLoaded}
                          inverted
                          style={{ zIndex: "0" }}
                        >
                          <Loader inverted>{loadingMessage}</Loader>
                        </Dimmer>

                        {/* #WIP - This is has been disabled to preference the image preload in componentDidMount */}
                        {false && !isMapLoaded && (
                          <img
                            id={`${imageSrc}`}
                            alt={`loading hidden file ${imageSrc}`}
                            style={{ display: "none" }}
                            src={`${imageSrc}`}
                            onError={(err) => {
                              console.log(`IMAGE LOADED: error`, err);
                              console.log(
                                `IMAGE LOADED: Failed to load <img> ${imageSrc}`
                              );
                            }}
                            onLoad={() => {
                              console.log(
                                `IMAGE LOADED: Loaded <img> image ${imageSrc}`
                              );
                              let mapsLoaded = this.state.isMapLoaded;
                              mapsLoaded.push(areaId);
                              this.setState({
                                isMapLoaded: mapsLoaded,
                              });
                              const bannerImage = document.getElementById(
                                `${imageSrc}`
                              );
                              const imgData = getBase64Image(bannerImage);

                              // #WIP - testing load to state (see also < AreaImage />)
                              isAreaImageToState &&
                                this.props.SetMapImage({
                                  id: areaId,
                                  imageData: imgData,
                                });
                              //console.log(`123 imgData ${imageSrc}`, imgData);
                            }}
                          />
                        )}

                        {isMapLoaded && !isAreaMapSimple && (
                          <>
                            <Map
                              className={"mineLevelMap"}
                              render={`area-${areaId}`}
                              parentState={this.state}
                              mineLevelId={areaId}
                              showMap={areaId === level.id}
                            />
                          </>
                        )}
                        {isMapLoaded && isAreaMapSimple && (
                          <>
                            <MapSimple
                              className={"mineLevelMap"}
                              render={`area-${areaId}`}
                              parentState={this.state}
                              mineLevelId={areaId}
                              showMap={areaId === level.id}
                            />
                          </>
                        )}
                      </>
                    );
                  }
                })}
              </Grid.Column>
            )}
          </Grid.Row>
          {/* <Grid.Row>
            {areaIds.map((areaId) => {
              //              console.log(`areaImageFileNames`, areaId, areaImageFileNames);

              const imageSrc = `/areas/${
                areaImageFileNames.find?.((item) => item.id === areaId)?.src
              }`;

              // const ImageSrc={`/areas/extraction_7May21-2021-May-12-11-52-30.png`}

              return (
                <>
                  <Dimmer
                    active={!this.state.isMapLoaded.includes?.(areaId)}
                    inverted
                  >
                    <Loader inverted>Loading</Loader>
                  </Dimmer>
                  <img
                    alt={`loading hidden file ${imageSrc}`}
                    style={{ display: "none" }}
                    src={`${imageSrc}`}
                    onError={() =>
                      console.log(`failed to load <img> ${imageSrc}`)
                    }
                    onLoad={() => {
                      console.log(`loaded <img> image ${imageSrc}`);
                      let mapsLoaded = this.state.isMapLoaded;
                      mapsLoaded.push(areaId);
                      this.setState({
                        isMapLoaded: mapsLoaded,
                      });
                    }}
                  />
                </>
              );
            })}
          </Grid.Row> */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const displaySettings = getNamedAreaDisplaySettings(state);

  const areas = getAllAreaStatuses(state) || [];
  const areaIds = areas.map((area) => area?.id);
  const areaImageFileNames = areas.map((area) => {
    return { id: area.id, src: area.image_filename };
  });

  // check for image files
  const folderFilesList = getFolderFileNamesListById(state, "areas");

  // message banner support
  const { messageBanners } = state.alarmButton;

  return {
    displaySettings,
    isUserGuest: isUserGuest(state),
    areaIds,
    areaImageFileNames,
    //
    folderFilesList,
    //
    messageBanners,
    //
    isPokeTheWorker: isOnOffPokeTheWorker(state),
    //
    isMoving: isMapMoving(state),
    isDragging: isDraggingMarker(state),
    //
    mapImagesFromState: getMapImages(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  // flash message support
  clearFlashMessages: () => {
    dispatch(clearFlashMessages());
  },
  removeFlashMessage: (id) => {
    dispatch(removeFlashMessage(id));
  },
  addWarningFlashMessageIdColor: (id, color, header, message) => {
    dispatch(addWarningFlashMessageIdColor(id, color, header, message));
  },
  SetMessageBanner: (messageState) => {
    dispatch(SetMessageBanner(messageState));
  },
  TurnOnOffPokeTheWorker: (action) => {
    dispatch(TurnOnOffPokeTheWorker(action));
  },
  //
  SetMapImage: (action) => {
    dispatch(SetMapImage(action));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MineLevelOpsControl);
