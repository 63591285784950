import { channel } from "redux-saga";
import { put, take, takeEvery, fork } from "redux-saga/effects";

export const downloadFileChannel = channel();

export const channelBufferPublishMsg = (payload, type) => {
  //console.log("putting sent mqtt publish msg to channel buffer ....", payload);
  downloadFileChannel.put({
    type: type,
    payload: payload,
  });
};

//https://stackoverflow.com/questions/43031832/how-to-yield-put-in-redux-saga-within-a-callback
// redux-saga channel
export function* watchDownloadFileChannel() {
  try {
    while (true) {
      //console.log("watchDownloadFileChannel before take");
      const action = yield take(downloadFileChannel);
      //console.log("watchDownloadFileChannel after take", action);
      yield put(action);
      //console.log("MQTT_PUBLISH watchDownloadFileChannel after put!!!");
    }
  } catch (error) {
    //console.log("MQTT_PUBLISH channel buffer error", error);
    //yield put(actions.cancelWatch()) // to emit 'CANCEL_WATCH'
  }

  // while (true) {
  //   const action = yield take(downloadFileChannel);
  //   yield put(action);
  // }
}

function* apiSaga() {
  //yield takeEvery(downloadFileChannel, watchDownloadFileChannel);
  yield fork(watchDownloadFileChannel);
  //console.log("MQTT_PUBLISH after apiSage takeEvery!!!");
}

export default apiSaga;
