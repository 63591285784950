// #WEBWORKER INDEX

import React, { Component } from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import cuid from "cuid";
import { getUserSessionIp } from "utils/messageToken";

import {
  UpdateMqttReset,
  //
  UpdateMapMsg, // used for stress tester _latlng
  UpdateMqttMsg,
  UpdateLatestFaultTs,
  //
  UpdateAreaInfos,
  UpdateAreaStatuses,
  //
  UpdateControllerCoordinates,
  UpdateControllerStatuses,

  //
  UpdateFireflyCoordinates,
  UpdateFireflyStatuses,
  //
  UpdateNamedAreaInfos,
  UpdateNamedAreaStatuses,
  UpdateNamedAreaEvents,
  //
  UpdateEmergencyEventSettings,
  UpdateTriggerEventSettings,
  //
  UpdateAcks,
  //
  UpdateDeviceTimestamps,
  //
  UpdateRecalcState,
  // railway_application
  UpdateMachineStatus,
  UpdateExternalTrigger,
  UpdateButtonTrigger,
  //
  clearMqttMessages,
  removeMqttMessage,
  removeMqttMessageByToken,

  // data loading
  UpdateDataLoadingResponseRequestQueue,

  // API data fetched
  clearDataFetched,

  // set flag to reload area images
  setAreaImageChanged,
  UpdateServerTimestamp,
  UpdateSystemProcessMsg,
  ClearSystemProcessMsg,
} from "components/WebWorker/actions";

import {
  saveNewNamedAreaEvent,
  saveNewEmergencyEvent,
} from "NamedAreas/actions";

import myWorker from "components/WebWorker/mqtt.worker";
import Heartbeat from "react-heartbeat";

import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
} from "FlashMessages/actions";

import { LogIt } from "components/Logger/actions";

import {
  getAllMqttMessages,
  getMqttReset,
  getMqttDataLoading,
  getMqttDataLoadingResponseRequestMessageQueue,
  //
  getAllData,
  getDataFetched,
} from "components/WebWorker/reducer";

import {
  isConfigJs,
  mqttBroker,
  NetworkTimeout,
  BoomgateControllerIp, // railway_application
  TemplateAllAreasButtons,
  TemplateDefaultArea,
  deviceCheckTimeout,
  isDemoMode,
  isTagTracking,
  isMqttDebugToConsole,
  lightingEventPriority,
} from "components/ConfigJs";

import { getSettingsDataLoading } from "components/Settings/reducer";
import { fetchServerOk } from "components/Settings/actions";
import { fetchFireflyAreaLightingplan } from "UPSPanelControllers/actions";
import { fetchUPSs, fetchFireflys } from "UPSPanelControllers/actions";

import {
  isDisabledPokeTheWorker,
  isOnOffPokeTheWorker,
  getPollRates,
  getMqttBroker,
} from "components/Settings/reducer";

import { makeAllDataForWorkerState } from "components/WebWorker/makeAllDataForWorkerState";
import { fetchTags } from "components/Tags/actions";

import { isMqttNotApiPost } from "components/ConfigJs";

const makeGeoJson = (object) => {
  return { type: "FeatureCollection", features: Object.values(object) };
};

// ***************
//  NOTE THIS IS OUTSIDE OF THE Component
//
var promiseWorker = null; // initialize variable
var worker = null; // initialize variable
//
// ***************

class Worker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mqttConnected: false,
      mqttErrors: [],
      mqttMsg: [],
      defaultAreaStatuses: { set: false, response: {} },
      //
      workerSetup: false, // indicated worker is setup OK
      dataFetched: false, // tracks if data has been fetched
      isMqttDebugToConsole: false, // output debugs to console
      isMqttNotApiPost: false, // default to send api POST
      isTagTracking: false,
      lightingEventPriority: { emergency: 9900, levelWide: 9800, forced: 5000 },
    };
  }

  terminateWorker = () => {
    if (worker !== null) {
      // worker does not exist. This could happen if connectWS connection failed
      worker.terminate();
      this.setState({ workerSetup: false });
    }
    promiseWorker = null;
  };

  setupWorker = (
    address,
    port,
    protocol,
    networkTimeout,
    boomgateControllerIp,
    userSessionIp,
    templateAllAreasButtons,
    templateDefaultArea,
    deviceCheckTimeoutSetup,
    isDemoMode,
    isMqttDebugToConsole,
    lightingEventPriority
  ) => {
    // First check whether Web Workers are supported
    if (typeof Worker !== "undefined") {
      // Check whether Web Worker has been created. If not, create a new Web Worker
      if (promiseWorker == null) {
        var PromiseWorker = require("promise-worker");
        worker = new myWorker();
        promiseWorker = new PromiseWorker(worker);

        var msg = {
          address,
          port,
          protocol,
          networkTimeout,
          type: "SETUP_MQTT",
          boomgateControllerIp,

          userSessionIp,
          templateAllAreasButtons,
          templateDefaultArea,

          deviceCheckTimeoutSetup,
          isDemoMode,
          isMqttDebugToConsole,
          lightingEventPriority,
        };
        console.log(
          "MQTT - Sending start message to worker: " + JSON.stringify(msg)
        );
        promiseWorker
          .postMessage(msg)
          .then((response) => {
            console.log("WORKER RESPONSE: " + response);
            //
            this.setState({ workerSetup: true });
          })
          .catch((error) => {
            console.log("WORKER ERROR: " + error);
            this.displayErrors("WORKER_SETUP_ERROR", error);
          });
      }
    } else {
      // Web workers are not supported by your browser
      this.displayErrors(
        "WORKER_SETUP_ERROR",
        "Sorry, your browser does not support Web Workers ..."
      );
      console.log("Sorry, your browser does not support Web Workers ...");
    }
  };

  // pre-process the workerResponse.
  // Split the errors and pass them on for display
  //
  prepErrors = (workerResponse, errorCase) => {
    // don't debug for default case
    if (workerResponse.length !== 1 && workerResponse !== "NO_ERRORS") {
      console.log(
        "askWebWorker errorCase: ",
        errorCase,
        "workerResponse: ",
        workerResponse
      );
    }

    // could be more than one error accumulated. Thus error'S'.
    const { mqttErrors } = this.state;

    mqttErrors.push(...workerResponse);

    this.setState({ mqttErrors: mqttErrors }, function () {
      // log it. Could be multiple errors........
      mqttErrors.forEach((error) => this.displayErrors(errorCase, error));
    });

    // clear the errors after they have been displayed
    // no reason to keep errors as we poll for updated status
    this.setState({ mqttErrors: [] });
  };

  // loads header and message to display on control screen page
  // depending on significance of the error
  // All cases go to the Browser Logger page
  //
  displayErrors(errorType, extraMessage) {
    let header;
    let message;
    let displayOnPageHeader = false;
    let color;

    let id = cuid();

    // console.log("check 6", Date.now());

    switch (errorType) {
      case "CONFIG_JS_ERROR":
        header = "MQTT Setup Error";
        message = "Problem loading data from ./network.js";
        color = "red";
        displayOnPageHeader = true;
        break;
      case "WEBSOCKET_CONNECTION_ERROR":
        header = "MQTT Setup Issue";
        message =
          "Could not establish websocket connection to " +
          extraMessage +
          ". Check the network connections and refresh the browser page.";
        color = "red";
        displayOnPageHeader = true;
        break;
      case "WORKER_SETUP_ERROR":
        header = "MQTT Web Worker Setup Issue";
        message = "Could not setup worker. " + extraMessage;
        color = "red";
        displayOnPageHeader = true;
        break;
      case "MQTT_WORKER":
        header = "MQTT Web Worker Issue";
        message = extraMessage;

        displayOnPageHeader = false;
        break;
      case "NETWORK_RECONNECTED":
        header = "Network Reconnected";
        message = "The computer network has been reconnected to the server.";

        // only clear 'NETWORK_DISCONNECTED' message when connection restored
        this.props.removeFlashMessage("NETWORK_DISCONNECTED");
        displayOnPageHeader = false;
        break;
      case "NETWORK_DISCONNECTED":
        header = "Network Disconnected";
        message = "The computer has been disconnected from the server network.";
        color = "red";
        displayOnPageHeader = true;

        // include errorType in id so can find and remove later (see above)
        id = id + "-NETWORK_DISCONNECTED";

        break;
      default:
        break;
    }
    if (displayOnPageHeader) {
      this.props.addWarningFlashMessageIdColor(
        id,
        color,
        header,
        message || "Unknown problem"
      );
    }
    if (message !== "NO_ERRORS") {
      //this.props.LogIt(`${errorType} - ${message}`);
      //console.log("displayErrors - " + header + "-" + message);
    }
  }

  initialiseWorker = () => {
    // function to connect to websocket
    // used to establish a test connection before proceed with mqtt_websocket
    // allows reporting of conditions of the websocket
    const connectWS = (address, port, protocol) => {
      console.log("MQTT Debug initialiseWorker > connectWS() ");
      return new Promise(function (resolve, reject) {
        let server = new WebSocket(
          protocol + "://" + address + ":" + port + "/"
        );

        // #DEBUG MESSAGES FOR connectWS
        if (true) {
          console.log(
            "MQTT Debug initialiseWorker > connectWS > new Promise() ",
            protocol + "://" + address + ":" + port + "/"
          );
          console.log(
            "MQTT Debug initialiseWorker > connectWS > server ",
            server
          );
          console.log(
            "MQTT Debug initialiseWorker > connectWS > onopen()",
            server.onerror
          );
          console.log(
            "MQTT Debug initialiseWorker > connectWS > onerror response",
            server.onerror
          );
        }

        server.onopen = () => {
          console.log(
            "MQTT Debug initialiseWorker > connectWS > onopen response"
          );
          resolve(server);
        };

        server.onerror = (err) => {
          console.log(
            "MQTT Debug initialiseWorker > connectWS > onerror response"
          );
          reject(err);
        };
      });
    };

    // check if the config.json file and broker object exists
    if (
      isConfigJs() &&
      //mqttBroker() && // #NOTE #TODO - no longer using network.js remove from code!
      NetworkTimeout() &&
      TemplateAllAreasButtons() &&
      TemplateDefaultArea() &&
      deviceCheckTimeout()
    ) {
      const fetchedMqttBroker = this.props.fetchedMqttBroker;
      const { address, addressSecondary, port, protocol } = fetchedMqttBroker; // mqttBroker(); // fetchedMqttBroker
      const networkTimeout = NetworkTimeout();

      //console.log("MQTT - Connected to Broker (network.js): ", mqttBroker());
      console.log(
        "MQTT - CONNECTED TO BROKER: ",
        JSON.stringify(fetchedMqttBroker)
      );

      // railway_application
      const boomgateControllerIP = BoomgateControllerIp();

      // get template for ALL_AREAS and level wide AREA button groups
      const templateAllAreasButtons = TemplateAllAreasButtons();

      // set default AREA so system starts even if no mqtt responses
      const templateDefaultArea = TemplateDefaultArea();
      const defaultAreaStatuses = {};
      defaultAreaStatuses[templateDefaultArea.id] = templateDefaultArea;

      this.setState({
        defaultAreaStatuses: { set: false, response: defaultAreaStatuses },
      });
      //this.props.UpdateAreaStatuses(defaultAreaStatuses);

      const deviceCheckTimeoutSetup = deviceCheckTimeout();

      const userSessionIp = this.props.userSessionIp;

      const demoMode = isDemoMode();
      //const mqttDebugToConsole = isMqttDebugToConsole();

      // (2) ...........
      // check if can connect to websocket

      // try primary and secondary addresses
      connectWS(address, port, protocol)
        .then(
          (server) => {
            console.log(
              "MQTT - WEBSOCKET CONNECTION CHECKED OK! Server: ",
              server
            );
            server.close(); // OK!, so close the test connection

            // (3) ...........
            // and setup MQTT when component mounts and has found IP and PORT
            this.setupWorker(
              address,
              port,
              protocol,
              networkTimeout,
              boomgateControllerIP,
              userSessionIp,
              templateAllAreasButtons,
              templateDefaultArea,
              deviceCheckTimeoutSetup,
              demoMode,
              this.state.isMqttDebugToConsole,
              this.state.lightingEventPriority
            );
          },
          (error) => {
            console.log(
              "MQTT - WS CHECK CONNECTION TO '",
              address,
              "' FAILED. ERROR : ",
              error
            );

            if (addressSecondary !== undefined) {
              console.log(
                "MQTT - WS CHECK CONNECTION TO '",
                address,
                "' FAILED. ATTEMPTING CONNECTION TO SECONDARY ADDRESS '",
                addressSecondary,
                "'"
              );
              console.log("MQTT - WS CHECK CONNECTION ERROR ", error);
              // attempt connection to secondary address
              connectWS(addressSecondary, port, protocol).then(
                (server) => {
                  console.log(
                    "MQTT - WS CHECK CONNECTION TO SECONDARY ADDRESS CHECKED OK! SERVER:",
                    server
                  );
                  server.close(); // OK!, so close the test connection

                  // (3) ...........
                  // and setup MQTT when component mounts and has found IP and PORT
                  this.setupWorker(
                    addressSecondary,
                    port,
                    protocol,
                    networkTimeout,
                    boomgateControllerIP,
                    userSessionIp,
                    templateAllAreasButtons,
                    templateDefaultArea,
                    deviceCheckTimeoutSetup,
                    demoMode,
                    this.state.isMqttDebugToConsole,
                    this.state.lightingEventPriority
                  );
                },
                (error) => {
                  console.log(
                    "MQTT - WS CHECK CONNECTION TO SECONDARY ADDRESS '",
                    addressSecondary,
                    "' ALSO FAILED."
                  );
                  console.log(
                    "MQTT - WS CHECK CONNECTION TO SECONDARY ADDRESS ERROR: ",
                    error
                  );
                  this.displayErrors(
                    "MQTT WEBSOCKET_CONNECTION_ERROR",
                    "primary address " +
                      protocol +
                      "://" +
                      address +
                      ":" +
                      port +
                      "/ or secondary address " +
                      protocol +
                      "://" +
                      addressSecondary +
                      ":" +
                      port +
                      "/"
                  );
                }
              );
            } else {
              console.log(
                "MQTT - WS CHECK CONNECTION TO SECONDARY ADDRESS '",
                addressSecondary,
                "' ALSO FAILED."
              );
              console.log(
                "MQTT - WS CHECK CONNECTION TO SECONDARY ADDRESS ERROR: ",
                error
              );
              this.displayErrors(
                "WEBSOCKET_CONNECTION_ERROR",
                " address " + protocol + "://" + address + ":" + port + "/"
              );
            }
          }
        )
        .catch((error) => {
          console.log("MQTT - WS CHECK CONNECTION ERROR ", error);
          this.displayErrors(
            "WEBSOCKET_CONNECTION_ERROR",
            "primary: " +
              protocol +
              "://" +
              address +
              ":" +
              port +
              "/, secondary :" +
              protocol +
              "://" +
              addressSecondary +
              ":" +
              port +
              "/"
          );
        });
    } else {
      this.displayErrors("CONFIG_JS_ERROR");
    }
  };

  componentDidMount() {
    const { UpdateMqttReset, isSettingsDataLoading } = this.props;

    // disable mqttReset before proceeding
    UpdateMqttReset(false);

    // don't startup unless the settings data is loaded (particularly mqttBroker data)
    if (!isSettingsDataLoading) {
      this.initialiseWorker();
    }

    this.setState({ isMqttDebugToConsole: isMqttDebugToConsole() });
    this.setState({ isMqttNotApiPost: isMqttNotApiPost() });
    this.setState({ isTagTracking: isTagTracking() });
    this.setState({ lightingEventPriority: lightingEventPriority() });
  }

  componentDidUpdate(prevProps) {
    const {
      mqttReset,
      mqttMessages,
      clearMqttMessages,
      removeMqttMessageByToken,
      allData,
      dataFetchedList,
      //
      isSettingsDataLoading,
      //
      saveNewNamedAreaEvent,
      saveNewEmergencyEvent,
    } = this.props;

    if (
      !isSettingsDataLoading &&
      prevProps.isSettingsDataLoading !== isSettingsDataLoading
    ) {
      this.initialiseWorker();
    }

    // if reset true -> false (i.e. re-login) restart webworker
    if (prevProps.mqttReset === true && mqttReset === false) {
      // #DEBUG
      if (false) {
        console.log("mqttReset prevProps.mqttReset", prevProps.mqttReset);
        console.log("mqttReset mqttReset", mqttReset);
        console.log("mqttReset initialiseWorker");
      }
      // #WIP _ not implemented. Review as necessary, especially as it has never been used!
      //this.initialiseWorker();
    }

    // if reset false -> true (i.e. logout ) terminate web worker
    if (prevProps.mqttReset === false && mqttReset === true) {
      // #DEBUG
      if (false) {
        console.log("mqttReset prevProps.mqttReset", prevProps.mqttReset);
        console.log("mqttReset mqttReset", mqttReset);
        console.log("mqttReset terminateWorker");
      }
      this.terminateWorker();
    }

    // set default areaStatuses
    let defaultAreaStatuses = { ...this.state.defaultAreaStatuses };
    if (!defaultAreaStatuses.set && !_isEmpty(defaultAreaStatuses.response)) {
      // deep clone to remove methods before passing
      this.props.UpdateAreaStatuses(
        JSON.parse(JSON.stringify(defaultAreaStatuses.response))
      );
      defaultAreaStatuses.set = true;
      this.setState({ defaultAreaStatuses });
    }

    let promiseArray = [];
    //
    // PUBLISH EVENTS and other messages
    //
    // if publish message prop has changed & there are some ...
    if (mqttMessages !== prevProps.mqttMessages && !_isEmpty(mqttMessages)) {
      // loop through and publish each one
      mqttMessages.forEach((mqttMessage, index, arr) => {
        //console.log(`_PUBLISH `, index, `->`, mqttMessage);

        if (this.state.isMqttNotApiPost) {
          console.log(`_PUBLISH Message via MQTT askWebWorker `, mqttMessage);
          this.askWebWorker("sendMqttMsg", mqttMessage);
        } else {
          //console.log(`_PUBLISH`, mqttMessage);

          const { topic } = mqttMessage;
          if (topic.includes("named_area/") && topic.includes("/event")) {
            console.log(
              `_PUBLISH Message via API saveNewNamedAreaEvent `,
              mqttMessage
            );
            promiseArray.push(
              new Promise((resolve, reject) => {
                saveNewNamedAreaEvent({
                  values: mqttMessage,
                  resolve,
                  reject,
                });
              })
            );
          } else if (topic.includes("emergency/") && topic.includes("/event")) {
            //console.log(`_PUBLISH - emergency event!!`);
            promiseArray.push(
              new Promise((resolve, reject) => {
                saveNewEmergencyEvent({
                  values: mqttMessage,
                  resolve,
                  reject,
                });
              })
            );
          } else {
            console.log(
              `_PUBLISH *** ADD API SUPPORT FOR THIS _PUBLISH ??`,
              mqttMessage
            );
            this.askWebWorker("sendMqttMsg", mqttMessage);
          }
        }
        // remove the message from the queue after it's been processed
        removeMqttMessageByToken(mqttMessage.message.token);
      });

      // and clear the redux list
      //clearMqttMessages();
    }

    if (promiseArray.length > 0) {
      Promise.all(promiseArray).then((results) => {
        console.log("SUCCESS _PUBLISH - mqttMessages", results);
      });
    }

    //if data has been fetched directly from the server, update the web worker data
    //console.log(`_FETCH SET_ALL_DATA allData`, allData);
    //console.log(`_FETCH SET_ALL_DATA dataFetchedList`, dataFetchedList);
    //console.log(
    //  `_FETCH SET_ALL_DATA prevProps.dataFetchedList`,
    //  prevProps.dataFetchedList
    //);

    // keep track of whether data has been fetched in case can't render ATM
    if (dataFetchedList !== prevProps.dataFetchedList) {
      this.setState({ dataFetched: true });
    }

    if (
      !_isEmpty(allData) &&
      // dataFetchedList - array listing data that's been fetched
      !_isEmpty(dataFetchedList) &&
      this.state.workerSetup &&
      this.state.dataFetched
    ) {
      // reset fetch flag
      this.setState({ dataFetched: false });

      // #REVIEW
      //#NOTE
      // make sure data fetch is complete before sending the data to the worker.
      // Check is any key is empty
      const isAllDataSomeEmpty = Object.values(allData)?.some((data) =>
        _isEmpty(data)
      );

      //console.log(`SET_ALL_DATA isAllDataSomeEmpty`, isAllDataSomeEmpty);

      //      if (isAllDataSomeEmpty) {
      //      console.log(`SET_ALL_DATA isAllDataSomeEmpty allData`, allData);
      //  }

      if (true) {
        // prepare allData to send to webworker
        // * geoJson is stripped to send only features in a keyed array
        // * methods are stripped from objects. Esp. areaInfos.
        //

        const allDataForWorkerState = makeAllDataForWorkerState(allData);

        console.log(`SET_ALL_DATA dataFetchedList`, dataFetchedList);
        console.log(`SET_ALL_DATA allData`, allDataForWorkerState);

        dataFetchedList.forEach((item) => {
          switch (item) {
            case "MINE_LEVELS_FETCH_SUCCEEDED":
              const { areaStatuses, areaInfos } = allDataForWorkerState;

              // don't send empty data
              if (!_isEmpty(areaStatuses) && !_isEmpty(areaInfos)) {
                console.log(
                  `SET_ALL_DATA >>>> Tx fetched data to worker`,
                  item,
                  areaStatuses,
                  areaInfos
                );

                this.askWebWorker("setAllData", {
                  areaStatuses,
                  areaInfos,
                });
                this.props.clearDataFetched("MINE_LEVELS_FETCH_SUCCEEDED");
              }

              break;
            case "UPS_FETCH_SUCCEEDED":
              const { controllerStatuses, controllerCoordinates } =
                allDataForWorkerState;

              if (
                !_isEmpty(controllerStatuses) &&
                !_isEmpty(controllerCoordinates)
              ) {
                console.log(
                  `SET_ALL_DATA >>>> Tx fetched data to worker`,
                  item,
                  controllerStatuses,
                  controllerCoordinates
                );

                // set web worker data to fetched data
                this.askWebWorker("setAllData", {
                  controllerStatuses,
                  controllerCoordinates,
                });
                this.props.clearDataFetched("UPS_FETCH_SUCCEEDED");

                // #WIP - is the local state already set on fetch?
                //
                //   // set local state to fetched data
                //   const getControllerCoordinates = makeGeoJson(
                //     controllerCoordinates
                //   );
                //   const getControllerStatuses = controllerStatuses;
                //   this.props.UpdateControllerCoordinates(
                //     getControllerCoordinates
                //   );
                //   this.props.UpdateControllerStatuses(getControllerStatuses);
              }
              break;
            case "FIREFLY_FETCH_SUCCEEDED":
              const { fireflyStatuses, fireflyCoordinates } =
                allDataForWorkerState;

              if (!_isEmpty(fireflyStatuses) && !_isEmpty(fireflyCoordinates)) {
                console.log(
                  `SET_ALL_DATA >>>> Tx fetched data to worker`,
                  item,
                  fireflyStatuses,
                  fireflyCoordinates
                );

                this.askWebWorker("setAllData", {
                  fireflyStatuses,
                  fireflyCoordinates,
                });
                this.props.clearDataFetched("FIREFLY_FETCH_SUCCEEDED");
              }
              break;
            case "NAMED_AREA_FETCH_SUCCEEDED":
              const { namedAreaInfos, namedAreaStatuses, namedAreaEvents } =
                allDataForWorkerState;
              if (
                !_isEmpty(namedAreaInfos) &&
                !_isEmpty(namedAreaStatuses) &&
                !_isEmpty(namedAreaEvents)
              ) {
                console.log(
                  `SET_ALL_DATA >>>> Tx fetched data to worker`,
                  item,
                  namedAreaInfos,
                  namedAreaStatuses,
                  namedAreaEvents
                );

                this.askWebWorker("setAllData", {
                  namedAreaInfos,
                  namedAreaStatuses,
                  namedAreaEvents,
                });
                this.props.clearDataFetched("NAMED_AREA_FETCH_SUCCEEDED");
              }
              break;

            default:
              break;
          }
        });
      }
    }
  }

  processGetConnectStatus = (workerResponse) => {
    if (workerResponse[0] === "TRUE") {
      // if was previously not connected (false) show reconnected message
      // message in setState callback so happens after state change
      if (!this.state.mqttConnected) {
        this.setState({ mqttConnected: true }, function () {
          this.prepErrors(["NETWORK_RECONNECTED"], "NETWORK_RECONNECTED");
        });
      }
    } else if (workerResponse[0] === "FALSE") {
      // if was previously  connected (true) show disconnected message
      // message in setState callback so happens after state change
      if (this.state.mqttConnected) {
        this.setState({ mqttConnected: false }, function () {
          this.prepErrors(["NETWORK_DISCONNECTED"], "NETWORK_DISCONNECTED");
        });
      }
    }
  };

  // ask the web worker for information
  askWebWorker = (action, message) => {
    if (promiseWorker !== null) {
      let msg; // data requested by message
      switch (action) {
        // tx data
        case "sendMqttMsg": //publish
          // console.log(
          //   "zzz MQTT_PUBLISH sendMqttMsg--->",
          //   JSON.stringify(message)
          // );
          msg = { type: "SEND_MQTT_MSG", payload: message };
          break;

        // sets the state of the worker
        // Used to match the API fetch state with the Worker state
        case "setAllData":
          //console.log(`SET_ALL_DATA this.props.allData`, message);
          msg = { type: "SET_ALL_DATA", payload: message };
          break;

        // rx data
        case "getAllData":
          msg = { type: "GET_ALL_DATA" };
          break;

        // individual messages
        case "getMqttMsg":
          msg = { type: "GET_MQTT_MSG" };
          break;
        case "getLatestFaultTs":
          msg = { type: "GET_LATEST_FAULT_TS" };
          break;
        case "getConnectStatus":
          msg = { type: "GET_CONNECT_STATUS" };
          break;
        case "getErrors":
          msg = { type: "GET_ERRORS" };
          break;
        // not used ATM
        case "clearErrors":
          msg = { type: "CLEAR_ERRORS" };
          break;
        // railway_application
        case "getExternalTrigger":
          msg = { type: "GET_EXTERNAL_TRIGGER" };
          break;
        case "getButtonTrigger":
          msg = { type: "GET_BUTTON_TRIGGER" };
          break;
        case "getMachineStatus":
          msg = { type: "GET_MACHINE_STATUS" };
          break;

        // testing map positions stresss
        case "getMapMsg":
          msg = { type: "GET_MAP_MSG" };
          break;
        //
        case "getControllerCoordinates":
          msg = { type: "GET_CONTROLLER_COORDINATES" };
          break;
        case "getControllerStatuses":
          msg = { type: "GET_CONTROLLER_STATUSES" };
          break;
        //
        case "getFireflyCoordinates":
          msg = { type: "GET_FIREFLY_COORDINATES" };
          break;
        case "getFireflyStatuses":
          msg = { type: "GET_FIREFLY_STATUSES" };
          break;
        //
        case "getAreaInfos":
          msg = { type: "GET_AREA_INFOS" };
          break;
        case "getAreaStatuses":
          msg = { type: "GET_AREA_STATUSES" };
          break;
        //
        case "getNamedAreaInfos":
          msg = { type: "GET_NAMED_AREA_INFOS" };
          break;
        case "getNamedAreaStatuses":
          msg = { type: "GET_NAMED_AREA_STATUSES" };
          break;
        case "getNamedAreaEvents":
          msg = { type: "GET_NAMED_AREA_EVENTS" };
          break;
        //
        case "getEmergencyEventSettings":
          msg = { type: "GET_EMERGENCY_EVENT_SETTINGS" };
          break;
        case "getTriggerEventSettings":
          msg = { type: "GET_TRIGGER_EVENT_SETTINGS" };
          break;

        //
        case "getAcks":
          msg = { type: "GET_ACKS" };
          break;

        //
        case "getDeviceTimestamps":
          msg = { type: "GET_DEVICE_TIMESTAMPS" };
          break;

        //
        case "getRecalcState":
          msg = { type: "GET_RECALC_STATE" };
          break;

        //
        case "getServerTimestamp":
          msg = { type: "GET_SERVER_TIMESTAMP" };
          break;

        case "getSystemProcessMsg":
          msg = { type: "GET_SYSTEM_PROCESS_MSG" };
          break;

        case "clearSystemProcessMsg":
          msg = { type: "CLEAR_SYSTEM_PROCESS_MSG", payload: message };
          break;

        default:
          msg = { type: "DO_NOTHING" };
          break;
      }

      // post message type and process callback from web worker
      promiseWorker
        .postMessage(msg)
        .then((response) => {
          // debug
          //console.log("dataLoading promiseWorker response", response);

          const workerRequest = JSON.parse(response).request;
          //console.log("dataLoading promiseWorker request", workerRequest);

          // data loading request message queue
          const newRequest = workerRequest
            .split("_")
            .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
            .join(" ")
            .replace("Get", "Received");

          //console.log("COLLECTING DATA .... ", newRequest);

          // limit queue to 5 items
          let responseRequestMessageQueue = JSON.parse(
            JSON.stringify(this.props.dataLoadingResponseRequestMessageQueue)
          );

          // console.log(
          //   "dataLoading this.props.dataLoadingResponseRequestMessageQueue",
          //   this.props.dataLoadingResponseRequestMessageQueue
          // );

          // console.log(
          //   "dataLoading responseRequestMessageQueue",
          //   responseRequestMessageQueue
          // );

          responseRequestMessageQueue.unshift(newRequest);
          if (responseRequestMessageQueue.length > 5) {
            responseRequestMessageQueue.pop();
          }

          // console.log(
          //   "dataLoading responseRequestMessageQueue",
          //   responseRequestMessageQueue
          // );

          // #NOTE
          // #WIP - if not using this as feedback could remove?
          //
          if (false) {
            this.props.UpdateDataLoadingResponseRequestQueue(
              responseRequestMessageQueue
            );
          }

          const workerResponse = JSON.parse(response).response;

          if (workerResponse === null) {
            console.log("workerResponse is NULL");
          }

          // console.log(
          //   `WebWorker workerResponse`,
          //   action,
          //   " -> ",
          //   workerResponse
          // );

          switch (action) {
            // tx data
            case "sendMqttMsg":
              // nothing to do here ...
              break;

            case "setAllData":
              // nothing to do here ...
              break;

            // rx data

            // -----------------------------------------
            //
            // get all data in one called to web worker
            //
            // -----------------------------------------

            case "getAllData":
              if (this.state.isMqttDebugToConsole) {
                console.log(
                  `COLLECTING DATA - GET_ALL_DATA WORKER RX getAllData `,
                  workerResponse
                );
              }

              const {
                getMqttMessages,
                getLatestFaultTs,
                getConnectedStatus,
                //getErrors, // #NOTE = see individual message processing
                //getClearErrors, // #NOTE = see individual message processing
                getExternalTrigger,
                getButtonTrigger,
                getMachineStatus,
                getMapMsg,
                getControllerCoordinates,
                getControllerStatuses,
                getFireflyCoordinates,
                getFireflyStatuses,
                getAreaInfos,
                getAreaStatuses,
                getNamedAreaInfos,
                getNamedAreaStatuses,
                getNamedAreaEvents,
                getEmergencyEventSettings,
                getTriggerEventSettings,
                //getAcks, // #NOTE = see individual message processing
                getDeviceTimestamps,
                getServerTimestamp,
                getSystemProcessMsg,
                // getRecalcState, // #NOTE = see individual message processing
              } = workerResponse;

              // #NOTE
              // Only process data if there is areaInfos has a feature.
              // This is/was to prevent updating data which refers to a non-existant area.
              // i.e. on startup of a new project messages from another system may be received when no area/info
              // data has been setup on this client.
              //
              // There should always be an area, albeit that area may be 'defaultArea'.
              //
              // Note that when deleting all areas 'areaStatuses' contains defaultArea (so not empty),
              // but 'areaInfos?.features' is empty.

              if (!_isEmpty(getAreaStatuses)) {
                // check for changes to the Area Images
                // If changed, set action and trigger reload on header
                //

                let isAreaImageChanged = false;
                for (const key in this.props.allData?.areaStatuses) {
                  if (
                    this.props.allData?.areaStatuses?.[key]?.image_filename !==
                    getAreaStatuses?.[key]?.image_filename
                  )
                    isAreaImageChanged = true;
                }
                if (isAreaImageChanged) {
                  // sent CHANGED message to reload images into state
                  this.props.setAreaImageChanged(true);
                }

                // process data to state
                this.props.UpdateMqttMsg(getMqttMessages);
                this.props.UpdateLatestFaultTs(getLatestFaultTs);
                this.processGetConnectStatus(getConnectedStatus);
                this.props.UpdateExternalTrigger(getExternalTrigger);
                this.props.UpdateButtonTrigger(getButtonTrigger);
                this.props.UpdateMachineStatus(getMachineStatus);
                this.props.UpdateMapMsg(getMapMsg);
                this.props.UpdateControllerCoordinates(
                  getControllerCoordinates
                );
                this.props.UpdateControllerStatuses(getControllerStatuses);
                this.props.UpdateFireflyCoordinates(getFireflyCoordinates);
                this.props.UpdateFireflyStatuses(getFireflyStatuses);
                this.props.UpdateAreaInfos(getAreaInfos);
                this.props.UpdateAreaStatuses(getAreaStatuses);
                this.props.UpdateNamedAreaInfos(getNamedAreaInfos);
                this.props.UpdateNamedAreaStatuses(getNamedAreaStatuses);
                this.props.UpdateNamedAreaEvents(getNamedAreaEvents);
                this.props.UpdateEmergencyEventSettings(
                  getEmergencyEventSettings
                );
                this.props.UpdateTriggerEventSettings(getTriggerEventSettings);
                this.props.UpdateDeviceTimestamps(getDeviceTimestamps);
                this.props.UpdateServerTimestamp(getServerTimestamp);
                this.props.UpdateSystemProcessMsg(getSystemProcessMsg);
              }

              break;

            // -----------------------------------------
            //
            // individual messages
            //
            // -----------------------------------------

            case "getMqttMsg":
              this.props.UpdateMqttMsg(workerResponse);
              break;
            case "getLatestFaultTs":
              this.props.UpdateLatestFaultTs(workerResponse);
              break;
            case "getConnectStatus":
              this.processGetConnectStatus(workerResponse);
              // if (workerResponse[0] === "TRUE") {
              //   // if was previously not connected (false) show reconnected message
              //   // message in setState callback so happens after state change
              //   if (!this.state.mqttConnected) {
              //     this.setState({ mqttConnected: true }, function () {
              //       this.prepErrors(
              //         ["NETWORK_RECONNECTED"],
              //         "NETWORK_RECONNECTED"
              //       );
              //     });
              //   }
              // } else if (workerResponse[0] === "FALSE") {
              //   // if was previously  connected (true) show disconnected message
              //   // message in setState callback so happens after state change
              //   if (this.state.mqttConnected) {
              //     this.setState({ mqttConnected: false }, function () {
              //       this.prepErrors(
              //         ["NETWORK_DISCONNECTED"],
              //         "NETWORK_DISCONNECTED"
              //       );
              //     });
              //   }
              // }

              break;
            case "getErrors":
              this.prepErrors(workerResponse, "MQTT_WORKER");
              break;

            // railway_application
            case "getExternalTrigger":
              this.props.UpdateExternalTrigger(workerResponse);
              break;
            case "getButtonTrigger":
              this.props.UpdateButtonTrigger(workerResponse);
              break;
            case "getMachineStatus":
              this.props.UpdateMachineStatus(workerResponse);
              break;

            case "getMapMsg":
              this.props.UpdateMapMsg(workerResponse);
              break;
            //
            case "getControllerCoordinates":
              this.props.UpdateControllerCoordinates(workerResponse);
              break;
            case "getControllerStatuses":
              this.props.UpdateControllerStatuses(workerResponse);
              break;
            //
            case "getFireflyCoordinates":
              this.props.UpdateFireflyCoordinates(workerResponse);
              break;
            case "getFireflyStatuses":
              this.props.UpdateFireflyStatuses(workerResponse);
              break;
            //
            case "getAreaInfos":
              this.props.UpdateAreaInfos(workerResponse);
              break;
            case "getAreaStatuses":
              this.props.UpdateAreaStatuses(workerResponse);
              break;
            //
            case "getNamedAreaInfos":
              this.props.UpdateNamedAreaInfos(workerResponse);
              break;
            case "getNamedAreaStatuses":
              this.props.UpdateNamedAreaStatuses(workerResponse);
              break;
            case "getNamedAreaEvents":
              this.props.UpdateNamedAreaEvents(workerResponse);
              break;
            //
            case "getEmergencyEventSettings":
              this.props.UpdateEmergencyEventSettings(workerResponse);
              break;
            case "getTriggerEventSettings":
              this.props.UpdateTriggerEventSettings(workerResponse);
              break;
            //
            case "getAcks":
              // #NOTE
              // #WIP - review. Why are they passed as strings?????
              //
              // acks are passed as a string, not an object so parse it 1st
              const acks = JSON.parse(workerResponse);
              const userSessionIp = getUserSessionIp();

              //console.log("getAcks workerResponse", acks);

              acks.forEach((ack) => {
                console.log("getAcks workerResponse ack", ack);
                const tokenArray = ack.token.split(",");
                //const timeStamp = tokenArray[0];

                console.log("getAcks tokenArray", tokenArray);

                const user = tokenArray[1];
                const sessionIp = tokenArray[2];
                const isSameSession = userSessionIp === `${user},${sessionIp}`; // make this check if same session

                console.log("getAcks isSameSession", isSameSession);

                console.log(
                  "getAcks UpdateAcks ack",
                  userSessionIp,
                  " vs ",
                  user,
                  ",",
                  sessionIp,
                  userSessionIp === `${user},${sessionIp}`
                );

                if (isSameSession) {
                  console.log(
                    "getAcks UpdateAcks ack isSameSession UpdateAcks",
                    ack
                  );
                  this.props.UpdateAcks(ack);
                }
              });
              break;
            case "getDeviceTimestamps":
              this.props.UpdateDeviceTimestamps(workerResponse);
              break;
            case "getRecalcState":
              this.props.UpdateRecalcState(workerResponse);
              break;
            case "getServerTimestamp":
              this.props.UpdateServerTimestamp(workerResponse);
              break;
            case "getSystemProcessMsg":
              this.props.UpdateSystemProcessMsg(workerResponse);
              break;

            default:
              break;
          }
        })
        .catch((error) => {
          // #REVIEW - need to catch errors.........?!
          console.log(
            "askWebWorker action '",
            action,
            "' returned error: " + error
          );
        });
    }
  };

  // called by the heartbeat to periodically check on the state of the worker
  pokeTheWorkerEssentials = () => {
    // get any errors
    this.askWebWorker("getErrors", "");
    // get acks
    this.askWebWorker("getAcks", "");
    // Turn on/off the collection of new data (webworker state) as necessary.
    // This disabled the significant CPU load initiated by the redux messages sent to update various states.
    //
    // Note ACKS, ERRORS etc need to be collected ASAP and the browser load here is a lot less.
    //
    // get server timestamp
    this.askWebWorker("getServerTimestamp", "");
    this.askWebWorker("getSystemProcessMsg", "");
  };

  // called by the heartbeat to periodically check on the state of the worker
  pokeTheWorker = () => {
    const { isPokeTheWorker, isDisabled } = this.props;

    if (isPokeTheWorker && !isDisabled) {
      console.log(
        "COLLECTING DATA ....  pokeTheWorker time: ",
        new Date().getTime().toString()
      );

      if (true) {
        // get all messages
        this.askWebWorker("getAllData", "");
      } else {
        // get the latest mqtt messages
        this.askWebWorker("getMqttMsg", "");

        // get the times for the latest faults
        this.askWebWorker("getLatestFaultTs", "");

        // separately check on the connection status
        this.askWebWorker("getConnectStatus", "");

        // railway_application
        this.askWebWorker("getExternalTrigger");
        this.askWebWorker("getButtonTrigger");
        this.askWebWorker("getMachineStatus");

        // get updates map marker locations <-- stress testing
        this.askWebWorker("getMapMsg", "");

        // get controller status messages
        this.askWebWorker("getControllerStatuses", "");
        // get controller map marker locations
        this.askWebWorker("getControllerCoordinates", "");

        // get firefly status messages
        this.askWebWorker("getFireflyStatuses", "");
        // get firefly map marker locations
        this.askWebWorker("getFireflyCoordinates", "");

        // get updates areas
        this.askWebWorker("getAreaInfos", "");
        // get updates areas status
        this.askWebWorker("getAreaStatuses", "");

        // get updates named areas
        this.askWebWorker("getNamedAreaInfos", "");
        // get updates named areas status
        this.askWebWorker("getNamedAreaStatuses", "");
        // get updates named area events
        this.askWebWorker("getNamedAreaEvents", "");

        // get emergency event settings
        this.askWebWorker("getEmergencyEventSettings", "");
        // get emergency event settings
        this.askWebWorker("getTriggerEventSettings", "");

        // get device timestamps
        this.askWebWorker("getDeviceTimestamps", "");
      }

      // get recalc state boolean flag.
      // Used to trigger recalculation of transforms etc.
      // Particularly when an Area is updated, since all FFs and Controller coords
      // need to be recalculated.
      // Only need to process this if Area is updated in getAllData above.
      //
      this.askWebWorker("getRecalcState", "");
    }
  };

  // called by the heartbeat to periodically check on the state of the server
  pokeTheServer = () => {
    // get the system info - this is used to monitor server uptime
    this.props.fetchServerOk();
  };

  pokeTheTagTracker = () => {
    // #NOTE - this is no longer used as tag tracker data comes from a websocket connection.
    // This was put in place for 1st development testing on tag tracking.
    // Has been left here pending cleanup/careful removal.

    //

    // get the tag system info - this is used in the tag tracking reporting pages
    // tagTracking
    // #WIP - disable in favour of websocket updates
    this.props.fetchTags();
  };

  pokeTheDemoModeFetchData = () => {
    // #TODO #WIP
    // if this works need to loop over the `active` Areas

    //console.log("xxx fetchFireflyAreaLightingplan ");
    //this.props.fetchFireflyAreaLightingplan({ id: "GBC_Extraction" });

    this.props.fetchFireflys();
  };

  render() {
    const { isDataLoading, pollRates } = this.props;

    // #NOTE - default rates if necessary
    const {
      disable = false, // disables data collection via web worker
      worker: pollingRateWorker = 10 * 1000,
      workerEssentials: pollingRateWorkerEssentials = 1 * 1000,
      server: pollingRateServer = 30 * 1000,
      tagTracking: pollingRateTagTracking = 30 * 1000,
    } = pollRates;

    // #NOTE -
    // #WIP - not used any more as now fetch everthing, remove this and all
    // infrastructure for isDataLoading
    //
    // if (isDataLoading) {
    //   pollingRateWorker = (this.props.pollRate * 1000) / 2; // half normal rate??
    // }

    return (
      <>
        <Heartbeat
          heartbeatFunction={this.pokeTheWorkerEssentials}
          heartbeatInterval={pollingRateWorkerEssentials}
        />

        <Heartbeat
          heartbeatFunction={this.pokeTheWorker}
          heartbeatInterval={pollingRateWorker}
        />
        <Heartbeat
          heartbeatFunction={this.pokeTheServer}
          heartbeatInterval={pollingRateServer}
        />
        {this.state.isTagTracking && (
          <Heartbeat
            heartbeatFunction={this.pokeTheTagTracker}
            heartbeatInterval={pollingRateTagTracking}
          />
        )}
        {
          // #NOTE - *experimental* if the demo mode is set, poll for firefly messages.
          // this is similar to getting the lightingplan, but has the benefit of "seeming" like
          // the FireFly data is recent.
          //
          // #REVIEW - this may be overwhelming if a lot of FFs are being similated.
          // NEEDS TESTING!!!!!!!!!!!!!!!!

          this.state.isDemoMode && (
            <Heartbeat
              heartbeatFunction={this.pokeTheDemoModeFetchData}
              heartbeatInterval={pollingRateWorker}
            />
          )
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  //
  UpdateMqttReset: (bool) => {
    dispatch(UpdateMqttReset(bool));
  }, //

  clearMqttMessages: () => {
    dispatch(clearMqttMessages());
  },
  removeMqttMessageByToken: (token) => {
    dispatch(removeMqttMessageByToken(token));
  },
  UpdateMapMsg: (mqttMsg) => {
    dispatch(UpdateMapMsg(mqttMsg));
  },
  UpdateMqttMsg: (mqttMsg) => {
    dispatch(UpdateMqttMsg(mqttMsg));
  },
  //
  UpdateControllerCoordinates: (mqttMsg) => {
    dispatch(UpdateControllerCoordinates(mqttMsg));
  },
  UpdateControllerStatuses: (mqttMsg) => {
    dispatch(UpdateControllerStatuses(mqttMsg));
  },
  //
  UpdateAreaInfos: (mqttMsg) => {
    dispatch(UpdateAreaInfos(mqttMsg));
  },
  UpdateAreaStatuses: (mqttMsg) => {
    dispatch(UpdateAreaStatuses(mqttMsg));
  },
  //
  UpdateFireflyCoordinates: (mqttMsg) => {
    dispatch(UpdateFireflyCoordinates(mqttMsg));
  },
  UpdateFireflyStatuses: (mqttMsg) => {
    dispatch(UpdateFireflyStatuses(mqttMsg));
  },
  //
  UpdateNamedAreaInfos: (mqttMsg) => {
    dispatch(UpdateNamedAreaInfos(mqttMsg));
  },
  UpdateNamedAreaStatuses: (mqttMsg) => {
    dispatch(UpdateNamedAreaStatuses(mqttMsg));
  },
  UpdateNamedAreaEvents: (mqttMsg) => {
    dispatch(UpdateNamedAreaEvents(mqttMsg));
  },
  //
  UpdateEmergencyEventSettings: (mqttMsg) => {
    dispatch(UpdateEmergencyEventSettings(mqttMsg));
  },
  UpdateTriggerEventSettings: (mqttMsg) => {
    dispatch(UpdateTriggerEventSettings(mqttMsg));
  },
  //
  UpdateAcks: (mqttMsg) => {
    dispatch(UpdateAcks(mqttMsg));
  },
  //
  UpdateDeviceTimestamps: (mqttMsg) => {
    dispatch(UpdateDeviceTimestamps(mqttMsg));
  },
  //
  UpdateServerTimestamp: (mqttMsg) => {
    dispatch(UpdateServerTimestamp(mqttMsg));
  },
  UpdateSystemProcessMsg: (msg) => {
    dispatch(UpdateSystemProcessMsg(msg));
  },
  ClearSystemProcessMsg: (msg) => {
    dispatch(ClearSystemProcessMsg(msg));
  },

  //
  UpdateRecalcState: (mqttMsg) => {
    dispatch(UpdateRecalcState(mqttMsg));
  },
  //
  UpdateLatestFaultTs: (latestFaultTs) => {
    dispatch(UpdateLatestFaultTs(latestFaultTs));
  },
  removeFlashMessage: (id) => {
    dispatch(removeFlashMessage(id));
  },
  addWarningFlashMessageIdColor: (id, color, header, message) => {
    dispatch(addWarningFlashMessageIdColor(id, color, header, message));
  },
  LogIt: (message) => {
    dispatch(LogIt(message));
  },
  // railway_application
  UpdateMachineStatus: (machineStatus) => {
    dispatch(UpdateMachineStatus(machineStatus));
  },
  UpdateExternalTrigger: (externalTrigger) => {
    dispatch(UpdateExternalTrigger(externalTrigger));
  },
  UpdateButtonTrigger: (buttonTrigger) => {
    dispatch(UpdateButtonTrigger(buttonTrigger));
  },

  // data loading
  UpdateDataLoadingResponseRequestQueue: (array) => {
    dispatch(UpdateDataLoadingResponseRequestQueue(array));
  },
  // server Ok info
  fetchServerOk: () => {
    dispatch(fetchServerOk());
  },
  // tag tracking info
  fetchTags: () => {
    dispatch(fetchTags());
  },

  // clears API data fetched flag
  clearDataFetched: (key) => {
    dispatch(clearDataFetched(key));
  },

  // sends events
  saveNewNamedAreaEvent: (values) => {
    dispatch(saveNewNamedAreaEvent(values));
  },
  saveNewEmergencyEvent: (values) => {
    dispatch(saveNewEmergencyEvent(values));
  },

  setAreaImageChanged: (values) => {
    dispatch(setAreaImageChanged(values));
  },

  // server Ok info
  fetchFireflyAreaLightingplan: (area) => {
    dispatch(fetchFireflyAreaLightingplan(area));
  },
  // server Ok info
  fetchFireflys: () => {
    dispatch(fetchFireflys());
  },
});

const mapStateToProps = (state) => {
  // #REVIEW - could be renamed to e.g. getAllPublishMessages() - legacy of very early code.
  //
  const mqttMessages = getAllMqttMessages(state); // returns list of messages to publish (state.webWorker.general.publish)

  //console.log("MQTT_PUBLISH mqttMessages", mqttMessages);
  const userSessionIp = getUserSessionIp();

  const mqttReset = getMqttReset(state);

  const isDataLoading = getMqttDataLoading(state);

  const dataLoadingResponseRequestMessageQueue =
    getMqttDataLoadingResponseRequestMessageQueue(state);

  const isPokeTheWorker = isOnOffPokeTheWorker(state);
  const isDisabled = isDisabledPokeTheWorker(state);

  const pollRates = getPollRates(state);

  const allData = getAllData(state);

  const dataFetchedList = getDataFetched(state) || false;

  const fetchedMqttBroker = getMqttBroker(state);

  // #NOTE -
  // mqttBroker was set in network.js, this was later moved to a GET from the server.
  // 'mqttBroker' - means from network.js
  // 'fetchedMqttBroker' - means fetched from the server

  return {
    mqttMessages,
    userSessionIp,
    mqttReset,
    isDataLoading,
    dataLoadingResponseRequestMessageQueue,
    isPokeTheWorker,
    isDisabled,
    pollRates,
    //
    allData,
    dataFetchedList, // array of *_SUCCESSFUL indicating which fetch data has been received
    fetchedMqttBroker,
    isSettingsDataLoading: getSettingsDataLoading(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Worker);
