import mineLevels from "MineLevels/reducer";
import editorSeismicLayer from "pages/CreateSeismicOverlayPage/reducer";
import seismicOverlays from "seismicOverlays/reducer";
import upsPanelControllers from "UPSPanelControllers/reducer";
import auth from "auth/reducer";
import flashMessages from "FlashMessages/reducer";
import namedAreas from "NamedAreas/reducer";
import namedAreaGroups from "NamedAreaGroups/reducer";
import operationalChanges from "OperationalChanges/reducer";
import regionPreview from "components/RegionPreview/reducer";
import { reducer as formReducer } from "redux-form";

//import mqtt from "components/MQTT/reducer";
import alarmButton from "components/AlarmButton/reducer";
import checkBrowser from "components/CheckBrowser/reducer";
import webWorker from "components/WebWorker/reducer";
import logger from "components/Logger/reducer";

//import test from "pages/Test/reducer";
//import mqttMessages from "pages/Test/TestMQTT/reducer"; // as default
//import { reduce as testWorker } from "pages/Test/TestMQTT/testMqttWorker"; // as default

import map from "components/Map/reducer";

import settings from "components/Settings/reducer";
import conflicts from "components/StatusChecks/reducer";

import userAdmin from "components/UserAdmin/reducer";
import localisation from "components/Localisation/reducer";
import tags from "components/Tags/reducer";
import tools from "components/Tools/reducer";
import externalTrigger from "components/ExternalTrigger/reducer";
import eventLog from "components/EventLog/reducer";

export default {
  mineLevels,
  editorSeismicLayer,
  seismicOverlays,
  upsPanelControllers,
  auth,
  flashMessages,
  namedAreas,
  namedAreaGroups,
  operationalChanges,
  regionPreview,
  form: formReducer,
  //mqtt,
  alarmButton,
  checkBrowser,
  webWorker,
  logger,
  //test,
  //mqttMessages,
  map,
  settings,
  conflicts,
  userAdmin,
  localisation,
  tags,
  tools,
  externalTrigger,
  eventLog,
};
