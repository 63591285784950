import { channelBufferPublishMsg } from "apiSaga";
import { myApi, dummyResponse } from "api/apiAxios";
import { messageToken, userSessionIp } from "utils/messageToken";

export function* fetchControllers() {
  const response = yield myApi.get("/api/get_all_controllers");
  // console.log(
  //   `API_TEST_apiController fetchControllers get_all_controllers`,
  //   response
  // );
  return response.data;
}

export function* saveUPS({ id, position: { mineLevelId, lat, lng }, name }) {
  // prepare message token
  const token = messageToken(userSessionIp);

  console.log("saveUPS token", token);
  console.log("saveUPS id, position, name", id, mineLevelId, lat, lng, name);

  const saveMessage = {
    id: id,
    area: mineLevelId,
    // #NOTE #WIP #TODO
    // Zone and Letter, Z need to be accommodated (even if passed around)
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [lat, lng],
    Z: 0,
    network_name: name,
    token: token,
  };

  //console.log("saveMessage", saveMessage);

  const payloadSaveMsg = {
    topic: `controller/${id}/change`,
    qos: 0,
    message: saveMessage,
    retained: false,
  };

  //console.log("payloaprotectedAPIdSaveMsg", payloadSaveMsg);

  const saveUPS = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadSaveMsg, "MQTT_PUBLISH");

      //resolve("UPS Saved");
      //resolve(`${payloadSaveMsg.topic}`);
      resolve({ topic: `${payloadSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveUPS();
    console.log("saveUPS response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    console.log("saveUPS response", response);
    return response;
  }
}

export function* saveUpsPost({ id, position, name, controllerNoteNote }) {
  const { mineLevelId, lat, lng } = position;
  // prepare message token
  const token = messageToken(userSessionIp);

  // Id              string    `json:"id"`
  // Area            string    `json:"area"`
  // UTM             []float64 `json:"utm"`
  // UTM_Zone_Number int32     `json:"utm_zone_number"`
  // UTM_Zone_Letter string    `json:"utm_zone_letter"`
  // Z               float64   `json:"z"`
  // Token           string    `json:"token,omitempty"`
  // Name            string    `json:"network_name"`
  // Master          bool      `json:"master"`
  // Mode            string    `json:"mode"`
  // Location        []string  `json:"location"`

  // #REVIEW - hard code values ATM
  const utmZoneLetter = "M";
  const utmZoneNumber = 53;

  const payloadSaveMsg = {
    topic: `controller/${id}/change`,
    id: id,
    area: mineLevelId,
    // #NOTE #WIP #TODO
    // Zone and Letter, Z need to be accommodated (even if passed around)
    utm_zone_number: utmZoneNumber,
    utm_zone_letter: utmZoneLetter,
    utm: [lat, lng],
    z: 0,
    network_name: name,
    token: token,
  };
  // console.log(
  //   `API_TEST_apiController saveUpsPost change_controller payloadSaveMsg`,
  //   payloadSaveMsg
  // );
  const response = yield myApi.post(`/api/change_controller`, payloadSaveMsg);
  //console.log(`API_TEST_apiController saveUpsPost change_controller`, response);
  return { topic: `${payloadSaveMsg.topic}`, token: token };
}

export function* deleteUPS(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  // console.log("deleteUPS token", token);
  // console.log("deleteUPS id", id);

  const payloadDeleteMsg = {
    topic: `controller/${id}/delete`,
    qos: 0,
    message: { id: id, delete: true, token: token },
    retained: false,
  };

  // console.log("payloadDeleteMsg", payloadDeleteMsg);

  const deleteUPS = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadDeleteMsg, "MQTT_PUBLISH");
      //resolve(`${payloadDeleteMsg.topic}`);
      resolve({ topic: `${payloadDeleteMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield deleteUPS();
    console.log("deleteUPS response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    // console.log("deleteUPS response", response);
    return response;
  }
}

export function* deleteUpsPost(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  const payloadDeleteMsg = {
    topic: `controller/${id}/delete`,
    id: id,
    delete: true,
    token: token,
  };

  const response = yield myApi.post("/api/delete_controller", payloadDeleteMsg);
  console.log(
    `API_TEST_apiController deleteUpsPost delete_controller`,
    response
  );
  return { topic: `${payloadDeleteMsg.topic}`, token: token };
}
