import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Header,
  Divider,
  Tab,
  Grid,
  Container,
  Message,
} from "semantic-ui-react";

import ActiveOperationChangesList from "pages/ControlRoomPage/ActiveOperationalChangesList";
import { MineLevelContext } from "ApplicationContexts";
import { getAllMineLevels } from "MineLevels/reducer";
import { fetchMineLevels } from "MineLevels/actions";
import FlashMessagesList from "FlashMessages";

import { LayersControl } from "react-leaflet";
import MineLevelMap from "components/MineLevelMap";
import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroup";
import CurrentSeismicFeatureGroup from "containers/CurrentSeismicFeatureGroup";

import MineLevelOpsControl from "pages/ControlRoomPage/MineLevelOpsControl";

import {
  getAllAreaStatuses,
  getMqttDataLoading,
} from "components/WebWorker/reducer";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { isConfigJs, TemplateDefaultArea } from "components/ConfigJs";

import Legend from "components/Legend";
import { legend } from "components/ConfigJs";
import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import { strings } from "components/App/localisation";

import _isEmpty from "lodash/isEmpty";
import { getUserData } from "auth/reducer";

class GeneralHomePage extends Component {
  render() {
    const { levels, isDataLoading, namedAreaDisplaySettings, userSettings } =
      this.props;

    const { legend: isShowLegend, sopInterface: isSopInterface } =
      namedAreaDisplaySettings;

    const configJs = isConfigJs();
    // check if the default area is loading correctly from config.js
    // if this load fails the webworker may never return a valid areaStatuses
    // resulting in an endless 'data loading' sequence.

    const startupProblem = !configJs || _isEmpty(levels);
    const templateDefaultArea = TemplateDefaultArea();

    if (startupProblem) {
      console.log("LOADING ERROR: NO AREAS TO DISPLAY.");
      console.log("CHECK IF CONFIG.JS IS LOADED OK.....? - ", configJs);
      console.log(
        "DEFAULT AREA TEMPLATE LOADED - ",
        JSON.stringify(templateDefaultArea)
      );
    }

    return (
      <>
        {startupProblem && (
          <div className={"genericGridHeader"}>
            <Container>
              <Grid>
                <Grid.Row className={"genericTitleHeader"}>
                  <Grid.Column textAlign={"left"}>
                    <FlashMessagesList />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
              </Grid>
              <Tab
                className={"controlRoomPageTabs"}
                menu={{
                  attached: true,
                  tabular: true,
                  style: { display: "flex", justifyContent: "left" },
                }}
                panes={["loading ..."].map((level) => ({
                  menuItem: strings?.["Loading ..."],
                  render: () => (
                    <Tab.Pane>
                      <Message
                        header={strings?.["Loading data ..."]}
                        content={strings?.[`LOADING_MESSAGE`]}
                      />
                    </Tab.Pane>
                  ),
                }))}
              />
              <DebugPagePropsMessages that={this} />
            </Container>
          </div>
        )}
        {!startupProblem && (
          <div className={"genericGridHeader"}>
            <Container>
              <Grid style={{ paddingBottom: "10px" }} columns={2}>
                <Grid.Row className={"genericTitleHeader"}>
                  <Grid.Column>
                    {/* <Header as="h2">Light Status</Header> */}
                  </Grid.Column>
                  <Grid.Column>
                    <FlashMessagesList />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Tab
                className={"controlRoomPageTabs"}
                menu={{
                  attached: true,
                  tabular: true,
                  style: { display: "flex", justifyContent: "left" },
                }}
                panes={levels.map((level) => ({
                  menuItem: level.name,
                  render: () => (
                    <Tab.Pane>
                      <MineLevelOpsControl
                        level={level}
                        strings={strings}
                        userSettings={userSettings}
                      />
                    </Tab.Pane>
                  ),
                }))}
              />
              {isShowLegend && <Legend icons={legend()} />}
              <DebugPagePropsMessages that={this} />
            </Container>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const thisUser = getUserData(state);

  return {
    levels: getAllAreaStatuses(state),
    isDataLoading: getMqttDataLoading(state),
    namedAreaDisplaySettings: getNamedAreaDisplaySettings(state),
    userSettings: thisUser?.settings || {},
  };
}

export default connect(mapStateToProps, { fetchMineLevels })(GeneralHomePage);
