import React, { Component } from "react";
import { Popup, Button, Grid } from "semantic-ui-react";

import { connect } from "react-redux";
import Icon from "components/Icon";

import isEmpty from "lodash/isEmpty";

import { isActiveEvent } from "OperationalChanges/reducer";

import {
  getNamedAreaEventButtonGroupState,
  getAllAreaStatuses,
  getAllNamedAreaStatuses,
} from "components/WebWorker/reducer";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import {
  cancelNamedAreaEvent,
  activateNamedAreaEvent,
  updateButtonGroupState,
  waitEventTimeOut,
} from "OperationalChanges/actions";

import isEqual from "lodash/isEqual";

import { appResponsive } from "components/ConfigJs";
import { useMediaQuery } from "react-responsive";

class SingleOperation extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(JSON.stringify(this.props), JSON.stringify(nextProps));
  }

  _onClick = (e, { operation, area, buttonInfo }) => {
    // console.log(
    //   "SingleOperation _onClick operation",
    //   new Date().getTime(),
    //   JSON.stringify(operation)
    // );

    if (false) {
      // debug .................
      console.log(
        "SingleOperation _onClick operation",
        JSON.stringify(operation)
      );
      console.log("SingleOperation _onClick area", JSON.stringify(area));
      console.log("SingleOperation _onClick area.spec", area.spec);
      console.log(
        "SingleOperation _onClick area.groupsArray",
        area.groupsArray
      );

      console.log("SingleOperation _onClick operation.type", operation.type);
      console.log(
        "SingleOperation _onClick operation.priority",
        operation.priority
      );
      console.log("SingleOperation _onClick operation.group", operation.group);
      console.log(
        "SingleOperation _onClick area.groupsArray[operation.group]",
        area.groupsArray[operation.group]
      );
      console.log(
        "SingleOperation _onClick area.groupsArray[operation.group].length",
        area.groupsArray[operation.group].length
      );

      console.log("SingleOperation _onClick buttonInfo", buttonInfo);

      // debug .................
    }

    // Note:
    //
    // Two cases:
    // 1 - whole mine event
    // In this case we simulate the whole mine by triggering a level wide event
    // for all areas
    // 2 - separate named areas
    // Separate named areas includes level wide events.

    // 1 - for ALL_AREAS
    if (area.spec === "ALL_AREAS") {
      // console.log("ALL_AREAS click operation", operation);
      // console.log("ALL_AREAS click area", area);
      // console.log("ALL_AREAS click buttonInfo", buttonInfo);

      // Note - this process sends an operation per mine level for whole mine events
      // ... however should instead use `ups/mine_wide_emergency` message
      if (false) {
        // find all named areas for the mine,
        // there is a level wide named area named after
        // each area name. i.e. area id = named area id
        const allAreas = this.props.allAreas;
        if (allAreas !== undefined || !isEmpty(allAreas)) {
          const namedAreas = allAreas.map((area) => area.id);
          namedAreas.forEach((namedArea) => {
            // change the operation, area and buttonInfo to simulate a level wide named event
            //
            let newOperation = operation;
            newOperation.id = namedArea;
            newOperation.named_area = namedArea;

            let newArea = area;
            newArea.id = namedArea;
            newArea.name = namedArea;
            newArea.spec = namedArea;

            // ...this is unchanged (for now)
            let newButtonInfo = buttonInfo;

            this.processClick(newOperation, newArea, newButtonInfo);
          });
        }
      }
      if (true) {
        // Send mine_wide_emergency for Mine Wide buttons
        // `ups/mine_wide_emergency`
        // Sets all the firefly's to the same color and state for all devices mine wide
        // {
        // "color":  "RED,GREEN,AMBER,WHITE,BLUE,ZONE"
        // "state": "The Led flash state ON,OFF,STROBE,FORWARD,BACKWARD",
        // "level": 1,2,3,4
        // "clear": true / false
        // }

        this.processClick(operation, area, buttonInfo);
      }
    } else {
      // 2 - process a named event single operation
      this.processClick(operation, area, buttonInfo);
    }

    //
  };

  processClick = (operation, area, buttonInfo) => {
    // #NOTE - here there are two possible states for the button
    //
    // 1 - button is shown as active in the UI
    // 2 - button is show as inactive in the UI
    //
    // If only 1 button is shown as ACTIVE in a row (i.e. the buttonGroup) then this has an active event associated with it.
    // If >1 button is shown as ACTIVE in a row, then the highest priority button will have the current active event.
    // Buttons in a row are in order of priority. Left is lowest, right is highest.
    //
    // Buttons are drawn in button groups.
    //
    // Consider a named area button group with three separate groups.
    // Three buttons in the 1st, one in 2nd, 2 in 3rd.
    //
    // Group  0        - 1 -  2
    //
    //        [0][1][2]-[0]-[0][1]
    //
    // Group:Button 0:2 may be selected, but if 1:0 or say 2:1 is selected then the event is associated with the most recent
    // button press (2:1) but buttons 0:2, 1:0 and 2:1 will all be shown as active in the UI (i.e. a solid icon).
    //
    // isActiveEvent - indicates if the event is active on that button
    // isActiveButton - indicates if the button is shown as active in the UI

    const namedAreaParent = area.spec;
    const namedAreaPriority = operation.priority;
    const selectedNamedAreaButtonGroups = area.groupsArray;

    // update buttons group state from the click (on a button!)
    const buttons = this.props.buttonGroupState;
    let newButtons = JSON.parse(JSON.stringify(buttons));

    // #DEBUG messages
    if (false) {
      console.log("processClick operation", operation);
      console.log("processClick area", area);
      console.log("processClick buttonInfo", buttonInfo);
      console.log(
        "processClick newButtons - updated named area event group state",
        newButtons
      );
      console.log("processClick namedAreaParent", namedAreaParent);
      console.log(
        "processClick newButtons[namedAreaParent]",
        newButtons[namedAreaParent]
      );
    }

    if (!newButtons.hasOwnProperty(namedAreaParent)) {
      // catch error if no button group state for button
      //
      // set default button group
      newButtons[namedAreaParent] = { 0: -1 };
    }

    const isActiveButton =
      newButtons[namedAreaParent][buttonInfo.group] === buttonInfo.index;

    const isActiveEvent = this.props.isActive;

    // All_Area (i.e. Mine Wide) buttons are special - they do not toggle off.
    // i.e. b/c emergency event messages do not have active=true/false, rather they exist or do not exist.
    const isAllAreasButton = area?.type === "ALL_AREAS";
    const isToggleButton = !isAllAreasButton;

    if (false) {
      console.log("processClick isActiveEvent", isActiveEvent);
      console.log("processClick isActiveButton", isActiveButton);
    }
    // Gets the next highest buttons
    // step through all the button event states and see if any others are active (from highest to lowest priority)
    // return the highest button (if any)
    //
    // e.g. nextPriorityEventOperation = getNextPriorityEventOperation(newButtons[namedAreaParent], selectedNamedAreaButtonGroups)
    const getNextPriorityEventOperation = (buttons, selectedButtonGroups) => {
      let nextPriorityEventOperation = {};
      let group = Object.keys(buttons).length;
      while (group--) {
        // go backward from highest to lowest priority
        const groupButtonSet = buttons[group];
        if (groupButtonSet !== -1) {
          nextPriorityEventOperation =
            selectedButtonGroups[group][groupButtonSet];
          break;
        }
      }
      return nextPriorityEventOperation;
    };

    // Sets redux event to pause collection of updated events from mqtt
    // This allows the button click to change locally for the wait period,
    // and for the event send to update the broker state and
    // confirm the action
    const waitEventTimeOut = (seconds) => {
      this.props.waitEventTimeOut("start");
      setTimeout(() => {
        this.props.waitEventTimeOut("stop");
      }, seconds); // WAIT_EVENT_TIMEOUT
    };

    // ********************************
    //
    // MAIN
    //
    // ********************************
    //
    // only if button group is defined (may be undefined on startup)
    //
    if (
      newButtons[namedAreaParent] !== undefined ||
      !isEmpty(newButtons[namedAreaParent])
    ) {
      // initialise button group records data is inconsistent
      if (
        Object.keys(newButtons[namedAreaParent])?.length !==
        selectedNamedAreaButtonGroups?.length
      ) {
        newButtons[namedAreaParent] = {};
        for (let i = 0; i < selectedNamedAreaButtonGroups.length; i++) {
          newButtons[namedAreaParent][i] = -1;
        }
      }

      if (isActiveButton && isToggleButton) {
        //
        // Clicked button is active.
        // Could be active because it is an active event &/or is an active button selection
        //
        // if it's an activeEvent and activeButton, then toggle the event off, then check if there is another
        // event which should be sent by checking what the next priority event is.
        //
        // if it's *not* an activeEvent and activeButton, then update the button state, and *resend* current event.
        // Resending the current event sends the updated button group state to all clients.
        // Also (perhaps) reinforces the event _BUT_ perhaps sends unnecessary event traffic.
        //
        //
        // #REVIEW -
        // As per above point about unnecessary event traffic, could consider:
        // ? - send a separate mqtt message which contains the button group information
        // ? - send the button group information via the sever/db. This process is similar to what happens in v2
        //
        //
        //

        // isActiveButton so toggle it off. i.e. set button group as -1 denotes no button set
        newButtons[namedAreaParent][buttonInfo.group] = -1;

        // console.log("kkk newButtons", newButtons);
        this.props.updatebuttons(newButtons);

        if (isActiveEvent) {
          // isActiveButton and isActiveEvent so cancel the event....
          waitEventTimeOut(1000);
          this.props.cancel(operation);

          // ok, we cancelled an event, so check if should there be
          // another event to activate?
          let nextPriorityEventOperation = getNextPriorityEventOperation(
            newButtons[namedAreaParent],
            selectedNamedAreaButtonGroups
          );

          // if there is a next highest event (i.e. an active button) activate it
          if (!isEmpty(nextPriorityEventOperation)) {
            waitEventTimeOut(1000);
            this.props.activate(nextPriorityEventOperation);
          }
        } else {
          // !isActiveEvent
          //
          // This button has *no* active event, so find any current event and resend it.
          //
          // If there is a next highest event (i.e. an active button) *resend* (i.e. re-activate) it.
          //
          // #NOTE
          // * - didn't bother checking if the event is active since if the button is active, and it's a higher priority then
          // it should be activated anyway.
          //
          let nextPriorityEventOperation = getNextPriorityEventOperation(
            newButtons[namedAreaParent],
            selectedNamedAreaButtonGroups
          );

          if (!isEmpty(nextPriorityEventOperation)) {
            waitEventTimeOut(1000);
            this.props.activate(nextPriorityEventOperation);
          }
        }
      }
      // !isActiveButton
      // Note - toggleButtons and !toggleButtons can both select inactive buttons
      else {
        //
        // not an active button so update the button state in the button group, making it active
        //
        newButtons[namedAreaParent][buttonInfo.group] = buttonInfo.index;
        this.props.updatebuttons(newButtons);

        // ok, so we've clicked the button, but is there already an existing event ongoing at a higher priority?
        // if so then we don't need to trigger this event.
        let nextPriorityEventOperation = getNextPriorityEventOperation(
          newButtons[namedAreaParent],
          selectedNamedAreaButtonGroups
        );
        // if the next highest event is this event operation then fire away!
        if (namedAreaPriority >= nextPriorityEventOperation.priority) {
          waitEventTimeOut(1000);
          this.props.activate(operation);
        } else {
          // resend the highest priority event.
          // This is primarily to send around the button group state changes for
          // other buttons but also to reinforce the event.
          waitEventTimeOut(1000);
          this.props.activate(nextPriorityEventOperation);
        }
      }
    }
  };

  render() {
    const {
      area,
      operation,
      isActive,
      key,
      buttonInfo,
      buttonGroupState,
      displaySettings,
      size,
      buttonStyle,
    } = this.props;

    //console.log("SingleOperation re-render ", new Date().getTime());

    const namedAreaParent = area.spec;
    let buttonActive = false;
    let buttonEnabled = true; // #WIP - should default to false

    // #DEBUG messages
    if (false) {
      console.log("SingleOperation -----------------------");
      console.log(
        "SingleOperation buttonGroupState",
        JSON.stringify(buttonGroupState)
      );
      console.log("SingleOperation area", area);
      console.log("SingleOperation operation", operation);
      console.log("SingleOperation buttonInfo", buttonInfo);
    }

    if (!isEmpty(buttonGroupState)) {
      if (
        buttonGroupState[namedAreaParent] !== undefined &&
        !isEmpty(buttonGroupState[namedAreaParent])
      ) {
        // DEBUG msgs
        if (false) {
          console.log("buttonActive namedAreaParent", namedAreaParent);

          console.log("buttonActive buttonGroupState", buttonGroupState);
          console.log(
            "buttonActive buttonGroupState[namedAreaParent]",
            buttonGroupState[namedAreaParent]
          );
          console.log("buttonActive buttonInfo.group", buttonInfo.group);
          console.log(
            "buttonActive buttonGroupState[namedAreaParent][buttonInfo.group]",
            buttonGroupState[namedAreaParent][buttonInfo.group]
          );
          console.log("buttonActive buttonInfo.index", buttonInfo.index);
          console.log("buttonActive ----------");
        }
        // button is active (i.e. enabled) in named area setup
        // assume active if set active or state is undefined
        buttonEnabled = buttonInfo.enable || buttonInfo.enable === undefined;

        // consider button active to send events if
        // 1 - the index is value
        // 2  - the button is enabled in setup of named area buttons

        buttonActive =
          buttonGroupState[namedAreaParent][buttonInfo.group] ===
            buttonInfo.index && buttonInfo.index !== -1;
        //&& buttonEnabled;

        // #DEBUG msgs
        if (false && namedAreaParent === "DMLZ_Extraction:1234") {
          console.log(
            "buttonActive Single --------------------------------------------------------"
          );
          console.log(
            "buttonActive Single --------",
            JSON.stringify(buttonInfo),
            "---",
            JSON.stringify(buttonGroupState[namedAreaParent]),
            " ----------"
          );
          console.log(
            "buttonActive Single --------------------------------------------------------"
          );
          console.log("buttonActive Single buttonEnabled", buttonEnabled);
          console.log("buttonActive Single buttonActive", buttonActive);
          console.log("buttonActive Single buttonInfo", buttonInfo);
          console.log("buttonActive Single buttonInfo.index", buttonInfo.index);
          console.log("buttonActive Single namedAreaParent ", namedAreaParent);
          console.log("buttonActive Single buttonInfo.group", buttonInfo.group);
          console.log("buttonActive Single buttonGroupState", buttonGroupState);
          console.log(
            "buttonActive Single buttonGroupState[namedAreaParent]",
            buttonGroupState[namedAreaParent]
          );
          console.log(
            "buttonActive Single buttonGroupState[namedAreaParent][buttonInfo.group]",
            buttonGroupState[namedAreaParent][buttonInfo.group]
          );
        }
      }
    }

    const buttonColor = (color) => {
      switch (color) {
        case "red":
          return "red";
        case "orange":
        case "amber":
          return "orange";
        case "white":
          return "white";
        case "blue":
          return "blue";
        case "no_change":
          return "grey";
        case "off":
          return "black";
        default:
          // not "GREEN" as used by the FF controller but "green" used by the semantic ui CSS
          return color.toLowerCase(); // #REVIEW - this shouldn't be necessary as I thought I'd caught these previously but THIS MAKES SURE!
      }
    };

    const color = buttonColor(operation.color) || "red";
    //    const iconColor = isActive && buttonActive ? "white" : color;

    const iconColor = buttonActive ? "white" : color;
    const iconName = operation.icon;
    const iconOpacity = buttonEnabled ? "1" : "0.2";
    const style = { ...buttonStyle, opacity: iconOpacity };

    const { hint: isShowHint } = displaySettings;

    return (
      <Popup
        trigger={
          <Button
            //disabled={!buttonEnabled}
            key={key}
            color={color}
            operation={operation}
            area={area}
            buttonInfo={buttonInfo}
            onClick={this._onClick}
            basic={!buttonActive}
            style={style}
            size={size}
          >
            <Icon name={iconName} inverted={buttonActive} color={iconColor} />
          </Button>
        }
        content={`${area.name} - ${operation.hint}`}
        position="top right" // bottom center
        basic
        disabled={!isShowHint}
      />
    );
  }
}

SingleOperation = connect(
  () => {
    return (state, props) => {
      const isActive = isActiveEvent(state, props);
      const buttonGroupState = getNamedAreaEventButtonGroupState(state);
      const allAreas = getAllAreaStatuses(state);
      // const namedAreaDisplaySettings = getNamedAreaDisplaySettings(state);

      return {
        isActive,
        buttonGroupState,
        allAreas,
        //namedAreaDisplaySettings,
      };
    };
  },
  (dispatch, props) => {
    // const { area, operation } = props;
    // const activate = buildActivateChange(area, operation);
    return {
      activate: (operation) => dispatch(activateNamedAreaEvent(operation)),
      cancel: (operation) => dispatch(cancelNamedAreaEvent(operation)),
      updatebuttons: (buttons) => dispatch(updateButtonGroupState(buttons)),
      waitEventTimeOut: (action) => dispatch(waitEventTimeOut(action)),
    };
  }
)(SingleOperation);

// function areEqual(prevProps, nextProps) {
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */
//   console.log("nextProps", nextProps);
//   //console.log("areEqual", isEqual(nextProps, prevProps));

//   const {
//     area: prevArea,
//     displayAllOnMineLevel: prevDisplayAllOnMineLevel,
//     displaySettings: prevDisplaySettings,
//   } = prevProps;
//   const {
//     area: nextArea,
//     displayAllOnMineLevel: nextDisplayAllOnMineLevel,
//     displaySettings: nextDisplaySettings,
//   } = nextProps;

//   //return isEqual(nextProps, prevProps);

//   return true; //isEqual(nextArea, prevArea);
// }

//export const PrecannedOperations = React.memo(_PrecannedOperations, areEqual);
function PrecannedOperations({
  area,
  //options, // #TODO - return BUTTON GROUP for all named areas .........
  floated,
  displayAllOnMineLevel = false,
  //size = "medium",
  displaySettings,
  ...props
}) {
  const { mobile, tablet, laptop, desktop } = appResponsive();

  // 125 difference was determined empirically
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${desktop - 125}px)`,
  }); //  1225x
  const isTabletOrMobileDevice = useMediaQuery({
    query: `(max-width: ${mobile - 125}px)`,
  }); // 1224px

  const { view } = displaySettings;
  let size = "medium";
  switch (view) {
    case "compressed":
      size = "mini";
      break;
    case "normal":
      size = "medium";
      break;
    case "expanded":
      size = "huge";
      break;
    default:
      size = "medium";
      break;
  }
  // override size for top header icons
  if (area.id === "ALL_AREAS") {
    size = "massive";
  }

  // if LEVEL_WIDE make one size bigger than selected
  if (area.type === "LEVEL_WIDE") {
    const sizes = [
      "mini",
      "tiny",
      "small",
      "medium",
      "large",
      "big",
      "huge",
      "massive",
    ];
    size = sizes[sizes.indexOf(size) + 1];
  }

  return area.groupsArray.map((group) => {
    if (isTabletOrMobileDevice)
      return createGroupMinimal({
        area,
        floated,
        size,
        group,
        displaySettings,
      });
    if (isDesktopOrLaptop)
      return createGroup({ area, floated, size, group, displaySettings });
    // default
    return createGroup({ area, floated, size, group, displaySettings });
  });
}

const createGroup = ({ area, floated, size, group, displaySettings }) => {
  return (
    <Button.Group
      floated={floated}
      compact
      size={size}
      style={{ marginRight: "3px" }}
    >
      {group.map((option, idx) => {
        return (
          <SingleOperation
            key={`${area.name}-${option.type}-${idx}`}
            buttonInfo={{
              enable: option.active,
              group: option.group,
              index: idx,
            }}
            area={area}
            operation={option}
            displaySettings={displaySettings}
            buttonStyle={{ padding: "0.5em" }}
          />
        );
      })}
    </Button.Group>
  );
};

const createGroupMinimal = ({
  area,
  floated,
  size,
  group,
  displaySettings,
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          {group.map((option, idx) => {
            return (
              <SingleOperation
                key={`${area.name}-${option.type}-${idx}`}
                buttonInfo={{
                  enable: option.active,
                  group: option.group,
                  index: idx,
                }}
                area={area}
                operation={option}
                displaySettings={displaySettings}
                size={"massive"}
                buttonStyle={
                  idx === 0
                    ? { padding: "0.5em", marginRight: "8em" }
                    : { padding: "0.5em" }
                }
              />
            );
          })}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state, props) => {
  return {
    displaySettings: getNamedAreaDisplaySettings(state),
  };
};

export default connect(mapStateToProps)(PrecannedOperations);
