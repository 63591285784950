// railway_application

import React, { Component } from "react";
import { Popup, Button, Container, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import Icon from "components/Icon";

import cuid from "cuid";

import "pages/ControlRoomPage/GatesAndCrane/GatesAndCrane.css";
import {
  getMachineStatus,
  getAllExternalTriggerById,
  getAllButtonTriggerById,
} from "components/WebWorker/reducer";

class GatesAndCrane extends Component {
  componentDidMount() {}

  _onClick = (operation) => () => {
    //console.log("_onClick", operation);
    if (this.props.isActive) {
      //this.props.cancel(this.props.activeOpsChange.id);
    } else {
      //this.props.activate();
    }
  };

  render() {
    const { machines, externalTrigger, buttonTrigger, colors } = this.props; //isActive

    //console.log("render externalTrigger", externalTrigger.active);
    //console.log("render buttonTrigger", buttonTrigger.active);

    const isActive = true;

    const { gate: gateColor, crane: craneColor, alarm: alarmColor } = colors;

    let boomgate = [];
    for (let index = 1; index < 4; index++) {
      const machineIndex = `boomgate-${index}`;
      boomgate[index] = {
        color:
          machines[machineIndex] !== undefined
            ? machines[machineIndex].active
              ? gateColor.up
              : gateColor.down
            : "grey",
        icon:
          machines[machineIndex] !== undefined
            ? machines[machineIndex].active
              ? `icon-gate-${index}-open.svg`
              : `icon-gate-${index}-closed.svg`
            : `icon-gate-${index}-open.svg`,
        name: `Gate ${index}`,
        hint:
          machines[machineIndex] !== undefined
            ? machines[machineIndex].active
              ? "OPEN"
              : "CLOSED"
            : "...waiting for status....",
        notHint:
          machines[machineIndex] !== undefined
            ? `(${machines[machineIndex].state})`
            : "",
      };
    }

    let crane = [];
    for (let index = 1; index < 2; index++) {
      const machineIndex = `crane-${index}`;
      crane[index] = {
        color:
          machines[machineIndex] !== undefined
            ? machines[machineIndex].active
              ? craneColor.on
              : craneColor.off
            : "grey",
        icon: `icon-crane.svg`,
        class:
          machines[machineIndex] !== undefined
            ? machines[machineIndex].active
              ? "flashing"
              : ""
            : "",
        name: `Crane`,
        hint:
          machines[machineIndex] !== undefined
            ? machines[machineIndex].active
              ? "OPERATING"
              : "IDLE"
            : "...waiting for status...",
      };
    }

    return (
      <Grid.Row>
        <Grid.Column style={{ display: "inline-block" }}>
          <Popup
            trigger={
              <Button
                size={"massive"}
                color={boomgate[1].color}
                onClick={this._onClick()}
                basic={!isActive}
                style={{ padding: "0.5em", opacity: "0.9" }}
              >
                <Icon
                  name={boomgate[1].icon}
                  //inverted={isActive}
                  //color={iconColor2}
                />
              </Button>
            }
            content={`${boomgate[1].name} - ${boomgate[1].hint} ${boomgate[1].notHint}`}
            position="bottom center"
            basic
          />
        </Grid.Column>

        <Grid.Column style={{ display: "inline-block" }}>
          <Popup
            trigger={
              <Button
                size={"massive"}
                color={boomgate[2].color}
                onClick={this._onClick()}
                basic={!isActive}
                style={{ padding: "0.5em", opacity: "0.9" }}
              >
                <Icon
                  name={boomgate[2].icon}
                  //inverted={isActive}
                  //color={iconColor}
                />
              </Button>
            }
            content={`${boomgate[2].name} - ${boomgate[2].hint} ${boomgate[2].notHint}`}
            position="bottom center"
            basic
          />
        </Grid.Column>
        <Grid.Column style={{ display: "inline-block" }}>
          <Popup
            trigger={
              <Button
                size={"massive"}
                color={boomgate[3].color}
                onClick={this._onClick()}
                basic={!isActive}
                style={{ padding: "0.5em", opacity: "0.9" }}
              >
                <Icon
                  name={boomgate[3].icon}
                  //inverted={isActive}
                  //color={iconColor}
                />
              </Button>
            }
            content={`${boomgate[3].name} - ${boomgate[3].hint} ${boomgate[3].notHint}`}
            position="bottom center"
            basic
          />
        </Grid.Column>

        {/* <Grid.Column style={{ display: "inline-block", marginLeft: "15px" }}>
          <Popup
            trigger={
              <Button
                size={"massive"}
                color={crane[1].color}
                onClick={this._onClick()}
                basic={!isActive}
                style={{ padding: "0.5em", opacity: "0.9" }}
                className={crane[1].class}
              >
                <Icon
                  name={crane[1].icon}
                  //inverted={isActive}
                  //color={iconColor}
                />
              </Button>
            }
            content={`${crane[1].name} - ${crane[1].hint}`}
            position="bottom center"
            basic
          />
        </Grid.Column> */}

        <Grid.Column style={{ display: "inline-block", marginLeft: "15px" }}>
          <Popup
            trigger={
              <Button
                size={"massive"}
                color={externalTrigger.active ? craneColor.on : craneColor.off}
                onClick={this._onClick()}
                basic={!isActive}
                style={{ padding: "0.5em", opacity: "0.9" }}
                className={externalTrigger.active ? "flashing" : null}
              >
                <Icon
                  name={crane[1].icon}
                  //inverted={isActive}
                  //color={iconColor}
                />
              </Button>
            }
            content={`${crane[1].name} - ${
              externalTrigger.active ? "OPERATING" : "IDLE"
            }`}
            position="bottom center"
            basic
          />
        </Grid.Column>

        <Grid.Column style={{ display: "inline-block", marginLeft: "15px" }}>
          <Popup
            trigger={
              <Button
                size={"massive"}
                color={buttonTrigger.active ? alarmColor.on : alarmColor.off}
                onClick={this._onClick()}
                basic={!isActive}
                style={{ padding: "0.5em", opacity: "0.9" }}
                className={buttonTrigger.active ? "flashing" : null}
              >
                <Icon
                  name={`icon-alarm`}
                  //inverted={isActive}
                  //color={iconColor}
                />
              </Button>
            }
            content={`Button Trigger - ${
              buttonTrigger.active ? "EMERGENCY ALARM" : "OFF"
            }`}
            position="bottom center"
            basic
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

function mapStateToProps(state, props) {
  const { controllerIp } = props;

  const machines =
    getMachineStatus(state) !== undefined ? getMachineStatus(state) : {};
  const externalTrigger = getAllExternalTriggerById(state, controllerIp);
  const buttonTrigger = getAllButtonTriggerById(state, controllerIp);

  return {
    machines: machines,
    externalTrigger: externalTrigger !== undefined ? externalTrigger : {},
    buttonTrigger: buttonTrigger !== undefined ? buttonTrigger : {},
  };
}
export default connect(mapStateToProps, null)(GatesAndCrane);
