import React from "react";

import { Form, Grid, Image, Segment, Header } from "semantic-ui-react";
import { Field } from "redux-form";
import { renderField } from "admin/form-field";
import { Link } from "react-router-dom";

export const tagTrackingUserDetailsCardPersonnel = (
  urlImage,
  tagUserId,
  disabled
) => {
  // if the card is 'disabled' it has been 'assigned'
  const cardTitle = disabled ? (
    <Link to={`/status/taguser/personnel/edit/${tagUserId}`}>
      {`Personnel Assigned`}{" "}
    </Link>
  ) : (
    "Details"
  );

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">{cardTitle}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Image src={urlImage} size="small" />
          </Grid.Column>
          <Grid.Column width={10}>
            <Form.Group widths="equal">
              <Field
                name="tagUserData.firstname"
                label="First Name"
                placeholder="Enter personnel's first name"
                component={renderField}
                className={disabled ? "disabled-form-field" : null}
                disabled={disabled}
              />
              <Field
                name="tagUserData.lastname"
                label="Last Name"
                placeholder="Enter personnel's lastname"
                component={renderField}
                className={disabled ? "disabled-form-field" : null}
                disabled={disabled}
              />
            </Form.Group>
            {/* <Field
              name="tagUserData.nick"
              label="Nickname"
              placeholder="Enter personnel's nickname"
              component={renderField}
              className={disabled ? "disabled-form-field" : null}
              disabled={disabled}
            /> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
