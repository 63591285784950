export function UpdateLocalMap(mapState) {
  return { type: "UPDATE_LOCAL_MAP", payload: mapState };
}

export function ClearLocalMap() {
  return { type: "CLEAR_LOCAL_MAP" };
}

export function SetMapMoving(bool) {
  return { type: "UPDATE_MOVING_MAP", payload: bool };
}

export function SetMapMarkerDragging(bool) {
  return { type: "UPDATE_DRAGGING_MARKER", payload: bool };
}
