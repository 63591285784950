import { channelBufferPublishMsg } from "apiSaga";
import { myApi, dummyResponse } from "api/apiAxios";
import { messageToken, userSessionIp } from "utils/messageToken";

export function* fetchFireflys() {
  const response = yield myApi.get("/api/get_all_fireflys");
  //console.log(`API_TEST_apiFirefly fetchFireflys get_all_fireflys`, response);
  return response.data;
}

export function* saveFirefly({ id, position, fireflyNote }) {
  const { mineLevelId, lat, lng } = position;

  // prepare message token
  const token = messageToken(userSessionIp);
  //console.log("saveFirefly token", token);

  // #REVIEW - hard code values ATM
  const utmZoneLetter = "M";
  const utmZoneNumber = 53;

  const saveMessage = {
    id: id,
    area: mineLevelId,
    // #NOTE #WIP #TODO
    // Zone and Letter, Z need to be accommodated (even if not used)
    utm_zone_number: utmZoneNumber,
    utm_zone_letter: utmZoneLetter,
    utm: [lat, lng],
    Z: 0,
    note: fireflyNote,
    token: token,
  };

  const payloadSaveMsg = {
    topic: `firefly/${id}/change`,
    qos: 0,
    message: saveMessage,
    retained: false,
  };

  //console.log("payloadSaveMsg", payloadSaveMsg);

  const saveFirefly = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadSaveMsg, "MQTT_PUBLISH");
      //resolve("Firefly Saved");
      //resolve(`${payloadSaveMsg.topic}`);
      resolve({ topic: `${payloadSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveFirefly();
    //console.log("saveFirefly response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    //console.log("saveFirefly response", response);
    return response;
  }
}

export function* saveFireflyPost({
  id,
  position,
  fireflyLocation,
  fireflyPosition,
  fireflyMac,
  fireflyNote,
}) {
  const { mineLevelId, lat, lng } = position;

  // prepare message token
  const token = messageToken(userSessionIp);
  // console.log(
  //   "saveFireflyPost id, mineLevelId, lat, lng, token",
  //   id,
  //   mineLevelId,
  //   lat,
  //   lng,
  //   //topology, // <<--- not used ATM
  //   //name, // <<--- not used ATM
  //   //mdt // <<--- not used ATM
  //   fireflyNote,
  //   token
  // );

  // #REVIEW - hard code values ATM
  const utmZoneLetter = "M";
  const utmZoneNumber = 53;

  const payloadSaveMsg = {
    topic: `firefly/${id}/change`,
    id: id,
    area: mineLevelId,
    // #NOTE #WIP #TODO
    // Zone and Letter, Z need to be accommodated (even if not used)
    utm_zone_number: utmZoneNumber,
    utm_zone_letter: utmZoneLetter,
    utm: [lat, lng],
    z: 0,
    mac: fireflyMac,
    location: fireflyLocation,
    position: fireflyPosition,
    note: fireflyNote,
    token: token,
  };

  //console.log(`saveFireflyPost`, payloadSaveMsg);

  const response = yield myApi.post(`/api/change_firefly`, payloadSaveMsg);
  //console.log(`API_TEST_apiFirefly saveFireflyPost change_firefly`, response);
  return { topic: `${payloadSaveMsg.topic}`, token: token };
}

export function* deleteFirefly(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  // console.log("deleteFirefly token", token);
  // console.log("deleteFirefly id", id);

  const payloadDeleteMsg = {
    topic: `firefly/${id}/delete`,
    qos: 0,
    message: { id: id, delete: true, token: token },
    retained: false,
  };

  // console.log("payloadDeleteMsg", payloadDeleteMsg);

  const deleteFirefly = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadDeleteMsg, "MQTT_PUBLISH");
      //resolve(`${payloadDeleteMsg.topic}`);
      resolve({ topic: `${payloadDeleteMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield deleteFirefly();
    console.log("deleteFirefly response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    // console.log("deleteFirefly response", response);
    return response;
  }
}

export function* deleteFireflyPost(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  const payloadDeleteMsg = {
    topic: `firefly/${id}/delete`,
    id: id,
    token: token,
  };

  //console.log(`payloadDeleteMsg deleteFireflyPost`, payloadDeleteMsg);

  const response = yield myApi.post("/api/delete_firefly", payloadDeleteMsg);
  //console.log(`API_TEST_apiFirefly deleteFireflyPost delete_firefly`, response);
  return { topic: `${payloadDeleteMsg.topic}`, token: token };
}

// ***************************************************************************************************
//
// #NOTE - no longer used for direct control of lights
// Left in place for historic reference ATM
export function* saveFireflyLed({
  id,
  position,
  color,
  brightness,
  on_time,
  off_time,
  train,
}) {
  // prepare message token
  const token = messageToken(userSessionIp);
  //console.log("forceFireflyLed token", token);
  // console.log(
  //   "forceFireflyLed id, position, color, brightness, on_time, off_time, train",
  //   id,
  //   position,
  //   color,
  //   brightness,
  //   on_time,
  //   off_time,
  //   train
  // );

  const saveMessage = {
    id,
    position,
    color: color.toUpperCase(),
    brightness,
    on_time,
    off_time,
    train,
    token: token,
  };

  const payloadSaveMsg = {
    topic: `firefly/${id}/led`,
    qos: 0,
    message: saveMessage,
    retained: false,
  };

  //console.log("payloadSaveMsg", payloadSaveMsg);

  const saveFireflyLed = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadSaveMsg, "MQTT_PUBLISH");
      //
      //resolve("Firefly Led Saved");
      //resolve(`${payloadSaveMsg.topic}`);
      resolve({ topic: `${payloadSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveFireflyLed();
    console.log("saveFireflyLed response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    //console.log("saveFireflyLed response", response);
    return response;
  }
}

export function* getAreaLightingPlan({ id }) {
  console.log(`API_TEST_apiFirefly getAreaLightingPlan`, id);
  let response = yield myApi.post("/api/get_area_lightingplan", {
    id,
  });
  console.log(`API_TEST_apiFirefly getAreaLightingPlan`, response);
  return response;
}
