import React from "react";
import { connect } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _isEmpty from "lodash/isEmpty";
import { Table, Ref, Grid, Icon, Popup } from "semantic-ui-react";

import { getAllNamedAreaStatuses } from "components/WebWorker/reducer";
import { isKthBitSet } from "utils/bit-operations";

import { eventTriggerOptions as _eventTriggerOptions } from "components/ConfigJs";

const getItemStyle = (highlightColor, color, isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? highlightColor : color,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (color, isDraggingOver) => ({
  background: isDraggingOver ? color : "white",
});

class NamedAreaGroupTableSubSubItems extends React.Component {
  onClickHandler = (e, type, id, groupId) => {
    //console.log("onClickHandler e", e);

    e.preventDefault();
    e.stopPropagation(); // stop submit to calling form

    //console.log("onClickHandler type:", type, " id:", id, " group:", groupId);
    switch (type) {
      case "deleteItem":
        this.props.onClickHandler(e, "deleteItem", id, groupId, null);
        break;
      case "cog":
        this.props.onClickHandler(e, "cog", id, groupId, null);
        break;
      default:
        break;
    }
    //console.log("onClickHandler props", id, groupId, type);
  };

  render() {
    const { namedAreas, groupId, NAMEDAREAS, strings, userSettings } =
      this.props;

    const eventTriggerOptions = _eventTriggerOptions(userSettings);

    // APP_TERMINOLOGY
    const strNamedAreas = strings?.["Polygons"];

    return (
      <Grid>
        <Grid.Column>
          <Table>
            <Droppable droppableId={groupId} type={"droppableSubSubItem"}>
              {(provided, snapshot) => (
                <Ref innerRef={provided.innerRef}>
                  <Table.Body
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                    style={getListStyle("lightgreen", snapshot.isDraggingOver)}
                  >
                    {this.props.items.length ? (
                      this.props.items.map((item, index) => {
                        //

                        // #WIP - this is unecessary a level will define attributes of the buttons
                        // Used for debugging to check if buttons are defined in namedAreas
                        //
                        const priority = item?.namedArea?.button?.priority;
                        const namedArea = namedAreas.find(
                          (na) => na.parent === item.namedArea.id
                        );
                        let buttonProps;
                        if (priority === undefined) {
                          buttonProps = {
                            color: "????",
                            title: `Error: Priority not defined in button`,
                          };
                        } else {
                          //console.log("namedAreaButtons namedArea", namedArea);
                          const namedAreaButtons = namedArea?.button;
                          //console.log("namedAreaButtons", namedAreaButtons);
                          if (namedAreaButtons !== undefined) {
                            buttonProps = namedAreaButtons.find(
                              (button) => button.priority === priority
                            );
                            if (buttonProps === undefined) {
                              buttonProps = {
                                color: "????",
                                title: `Error: No Button defined for priority ${priority}`,
                              };
                            }
                          } else {
                            buttonProps = {
                              color: "????",
                              title: `Error: No Buttons defined for the named area`,
                            };
                          }
                        }

                        const { title, color } = buttonProps;

                        return (
                          <Draggable
                            draggableId={item.id}
                            index={index}
                            key={item.id}
                          >
                            {(provided, snapshot) => (
                              <Ref innerRef={provided.innerRef}>
                                <>
                                  <Table.Row
                                    {...provided.draggableProps}
                                    // {...provided.dragHandleProps} -> moved to <Icon name="bars"... />
                                    isDragging={snapshot.isDragging}
                                    style={getItemStyle(
                                      "rgba(144,238,144,1)",
                                      //"lightgreen",
                                      "rgba(144,238,144,0.2)",
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Table.Cell width={16}>
                                      <Popup
                                        content={
                                          strings?.["Drag Polygon to reorder"]
                                        }
                                        trigger={
                                          <Icon
                                            name="bars"
                                            {...provided.dragHandleProps}
                                          />
                                        }
                                      />
                                      {eventTriggerOptions.map((option) => {
                                        const bitCheck = isKthBitSet(
                                          item?.settings?.permission,
                                          option.key
                                        );
                                        return (
                                          bitCheck && (
                                            <Icon name={option.icon} />
                                          )
                                        );
                                      })}

                                      <strong>{`${
                                        NAMEDAREAS.find(
                                          (na) =>
                                            na.namedArea.id ===
                                            item.namedArea.id
                                        )?.label
                                      }`}</strong>
                                      <em style={{ paddingLeft: "10px" }}>
                                        {item?.settings?.title
                                          ? `Custom label: "${item.settings.title}"`
                                          : ``}
                                      </em>
                                      {/* {` id : ${item.namedArea.id} - label : ${
                                        NAMEDAREAS.find(
                                          (na) =>
                                            na.namedArea.id ===
                                            item.namedArea.id
                                        )?.label
                                      }`} */}
                                      {/* {item.label} - {title} - {color} -
                                      {`Priority ${priority}`} */}
                                      {/* {` id: ${item.id}`}
                                      {` draggingOver: ${snapshot.draggingOver}`}
                                      {` isDragging: ${snapshot.isDragging}`} */}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Popup
                                        content={
                                          strings?.["Edit Polygon settings"]
                                        }
                                        trigger={
                                          <Icon
                                            name="cog"
                                            onClick={(e) =>
                                              this.onClickHandler(
                                                e,
                                                "cog",
                                                item.id,
                                                index
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Popup
                                        content={strings?.["Delete Polygon"]}
                                        trigger={
                                          <Icon
                                            name="trash"
                                            onClick={(e) =>
                                              this.onClickHandler(
                                                e,
                                                "deleteItem", // type
                                                item.id, // id
                                                groupId // index
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                </>
                              </Ref>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell>
                          {
                            strings?.[
                              `Drag and drop Polygons here. Configure light states after adding Polygons.`
                            ]
                          }
                          {provided.placeholder}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {!_isEmpty(provided.placeholder) &&
                      snapshot.isDraggingOver && (
                        <Table.Row>
                          <Table.Cell
                            colSpan={16}
                            style={getListStyle(
                              "lightgreen",
                              snapshot.isDraggingOver
                            )}
                          >
                            {provided.placeholder}
                          </Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Ref>
              )}
            </Droppable>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const namedAreas = getAllNamedAreaStatuses(state);

  return {
    namedAreas,
  };
}

export default connect(mapStateToProps, null)(NamedAreaGroupTableSubSubItems);
