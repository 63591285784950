import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Form, Grid, Icon } from "semantic-ui-react";

import isEmpty from "lodash/isEmpty";
import { login } from "auth/actions";

import { LogIt } from "components/Logger/actions";
//import { findLocalIp } from "utils/findLocalIp";

import LocalizedStrings from "react-localization";
import { getLocalisation } from "components/Localisation/reducer";

function validateInput(data) {
  let errors = {};

  if (isEmpty(data.email)) {
    errors.email = "This field is required";
  }
  if (isEmpty(data.password)) {
    errors.password = "This field is required";
  }
  return { errors, isValid: isEmpty(errors) };
}

class LoginForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      fieldErrors: {},
      hidden: true,
      capsLockOn: false,
    };
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ fieldErrors: {} });
      this.props.login(this.state);
      this.props.LogIt("LOG_IN > Username: " + this.state.username);
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    this.setState({ fieldErrors: errors });
    return isValid;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleShow = (state) => {
    this.setState({ hidden: !this.state.hidden });
  };

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      this.setState({ capsLockOn: true });
    } else {
      this.setState({ capsLockOn: false });
    }
  };

  render() {
    const { isLoading } = this.props;
    const { username, email, password, fieldErrors, capsLockOn } = this.state;

    return (
      <Form onSubmit={this.onSubmit} error>
        <Form.Input
          name="email"
          label="Email"
          placeholder="Email"
          value={email}
          error={!!fieldErrors.email}
          onChange={this.onChange}
        />
        <Form.Input
          name="password"
          type={this.state.hidden ? "password" : "text"}
          label="Password"
          placeholder="Password"
          value={password}
          error={!!fieldErrors.password}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          icon={
            <Icon
              name={this.state.hidden ? "eye" : "eye slash"}
              link
              onClick={this.toggleShow}
            />
          }
        />
        <Grid>
          <Grid.Row columns={"equal"}>
            <Grid.Column>{capsLockOn && <strong>CAPS</strong>}</Grid.Column>
            <Grid.Column>
              <Form.Button
                style={{ float: "right" }}
                primary
                type="submit"
                disabled={isLoading}
              >
                Login
              </Form.Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

function mapStateToProps(state, props) {
  const localisations = getLocalisation(state);

  //console.log("localisation", localisations);

  return {
    isLoading: state.auth.isLoading,
  };
}

export default connect(mapStateToProps, { login, LogIt })(LoginForm);
