import { connect } from 'react-redux'
import { withMineLevel } from 'ApplicationContexts'
import { getMineLevelBounds } from 'MineLevels/reducer'
import { getSeismicLayerById } from 'seismicOverlays/reducer'
import SeismicLayerFeatureGroup from './SeismicLayerFeatureGroup'

function mapStateToProps (state, props) {
  const s = getSeismicLayerById(state, props.seismicLayerId)
  return {
    mineLevelBounds: getMineLevelBounds(state, props.mineLevel.id),
    levelDefault: s.levelDefault,
    regionSettings: s.regionSettings
  }
}
export default withMineLevel(connect(mapStateToProps)(SeismicLayerFeatureGroup))
