import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Form,
  Container,
  Label,
  Header,
  Grid,
  Message,
  Segment,
  Table,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging

import { push } from "react-router-redux";

import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import ChooseMineLevelField from "components/ChooseMineLevelField";

import { deleteNamedArea } from "NamedAreas/actions";
import { renderField } from "admin/form-field";
import { SaveButton } from "admin/SaveButton";

import { DeleteButton } from "admin/DeleteButton";

import FlashMessagesList from "FlashMessages";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { reset } from "testdouble";

import NamedAreaTable from "admin/named-area/NamedAreaTable";

import { GetLocalMap } from "components/Map/reducer";
import { ClearLocalMap } from "components/Map/actions";

import Map from "./NamedAreaMap";

import {
  getNamedAreaStatusesByAreaId,
  getAllNamedAreaStatuses,
} from "components/WebWorker/reducer";

// based on
// - https://embed.plnkr.co/Gvei5I0S9yEo6fCYXPuy/
// see -> <Map /> from "./TestMapTilesMap";

// --------------------------------------
// Marker not appearing for Simple Example
// Fix - https://github.com/PaulLeCam/react-leaflet/issues/453
//
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// --------------------------------------
// HACKfix: Use zIndex to stop leaflet map from being above the Semantic-UI dropdown menus!
const DEFAULT_MAP_STYLE = {
  height: "800px",
  width: "100%",
  position: "relative",
  zIndex: 0,
};

const validate = (values) => {
  const required = ["namedAreaIdValue", "mineLevelId"];

  const errors = {};

  required.forEach((r) => {
    if (!get(values, r)) {
      set(errors, r, "Required");
    }
  });

  //console.log("@@ validate errors", errors);

  return errors;
};

const normalise = (value, type) => {
  if (type === "noSpaces") {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

const required = (value) => (value ? undefined : "Required");
const isNamedAreaNameOK = (value, allValues, props, name) => {
  const { namedAreaIdValue: id } = allValues;

  const { uniqueNamedAreaParentIds } = props;
  const valueNoSpace = value.replace(" ", "_");

  const notIncludingThisId = uniqueNamedAreaParentIds
    .filter((item) => !item.id.includes(id))
    .map((item) => item.parentName);

  //console.log(`notIncludingThisId `, notIncludingThisId);

  if (notIncludingThisId?.includes(valueNoSpace)) {
    return "This name has already been used";
  } else {
    return undefined;
  }
};

class CreateNamedAreaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLoading: true,
      errorMessage: "",
      id: props.id,
    };
  }

  // #REVIEW -
  //  Render issue - with each heartbeat the STATE updates which causes localPts to be updated and so resets the map state

  //   This stops re-rendering the map unnecessarily
  // #WIP

  componentDidMount() {
    // clear the map from any previous edits
    this.props.ClearLocalMap();
    // #WIP - set temporarily
    this.setState({ contentLoading: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // update component when state changes, not when props change or
    // initial props will clobber form edits

    // console.log("shouldComponentUpdate this.props", this.props);
    // console.log("shouldComponentUpdate nextProps", nextProps);

    return !isEqual(this.props, nextProps); //!isEqual(this.state, nextState); //!isEqual(this.props, nextProps);
  }

  requestDelete = (id) => {
    const { handleSubmit, deleteNamedArea, push } = this.props;

    console.log("requestDelete", handleSubmit, deleteNamedArea, push);

    console.log("WIP.......");
  };

  // onSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("onSubmit e", e);

  //   //   submitToReview () {
  //   //     const formValues = this.props.study.values; // map form to props.
  //   //     const valueToSubmit = Object.assign({}, formValues, {state: 'REVIEWING'}); // update value.
  //   //     this.onSubmit(valueToSubmit, this.props.dispatch); // call onSubmit directly
  //   // }

  //   this.props.handleSubmit(this); // calls prop.onSubmit()
  // };

  submitMyForm = (values) => {
    //console.log(`form submitted: values`, values);
    this.props.onSubmit(values);
  };

  resetForm = (e) => {
    e.preventDefault();

    //this.setState(setInitialValues);
    this.props.ClearLocalMap();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // abort page changes
    this.props.onCancel();
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      //reset,
      mineLevelId,
      //namedAreaId,
      namedAreaIdValue,
      namedAreaParentName,
      strings,
    } = this.props;

    // APP_TERMINOLOGY
    const strNamedArea = "Polygon";
    const strNamedAreas = "Polygons";

    //const { id } = this.state;

    const refLocationsComponent = (areaId, namedAreaIdValue) => {
      const namedAreaId = `${areaId}:${namedAreaIdValue}`;

      if (!areaId) {
        return <div>Select a mine level to display the map</div>;
      }
      return (
        <div>
          <Map
            style={DEFAULT_MAP_STYLE}
            render="single"
            parentState={this.state}
            mineLevelId={areaId}
            namedAreaId={namedAreaId}
          />
        </div>
      );
    };

    const { contentLoading } = this.state;

    if (contentLoading) {
      return (
        <Dimmer active={contentLoading} page>
          <Loader>Loading data</Loader>
        </Dimmer>
      );
    } else {
      return (
        <div className="genericGridHeader">
          <Container>
            <Grid columns={2}>
              <Grid.Row className={"genericTitleHeader"}>
                <Grid.Column width={4} textAlign={"left"}>
                  <Header as="h1">{strNamedArea}</Header>
                </Grid.Column>
                <Grid.Column width={12} textAlign={"left"}>
                  <FlashMessagesList />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
            </Grid>
            {/* ***************Create Form ************************ */}
            {error && (
              <Message error header="Error submitting" content={error} />
            )}

            <Form
              onSubmit={handleSubmit(this.submitMyForm)}
              error={Boolean(error)}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2">{`Add ${strNamedArea}`}</Header>
                      <Field
                        name="mineLevelId"
                        label="Area"
                        component={ChooseMineLevelField}
                      />
                      <Field
                        name="namedAreaIdValue" // namedAreaId
                        label="ID"
                        placeholder={`Unique ID value of the ${strNamedArea}`}
                        component={renderField}
                        disabled={true}
                      />
                      <Field
                        name="namedAreaParentName"
                        label={`${strNamedArea} Name`}
                        placeholder={`Enter the name of the ${strNamedArea}`}
                        component={renderField}
                        validate={[required, isNamedAreaNameOK]}
                        normalize={(value) => normalise(value, "noSpaces")}
                      />
                    </Segment>
                    <Segment>
                      <Message icon color="orange">
                        <Icon name="warning" />
                        <Message.Content>
                          <Message.Header>
                            Use the map controls to add a new polygon
                          </Message.Header>
                          Click on the map's Polygon or Rectangle icon to draw a
                          new polygon shape. A shape must be drawn to be able to
                          save the form.
                        </Message.Content>
                      </Message>
                    </Segment>
                    <Segment>
                      <Header as="h2">{strNamedAreas}</Header>
                      <NamedAreaTable
                        mineLevelId={mineLevelId}
                        namedAreaIdValue={namedAreaIdValue}
                        namedAreaParentName={namedAreaParentName}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    {false && (
                      <Segment>
                        <Grid columns={2}>
                          <Grid.Column>{""}</Grid.Column>
                          <Grid.Column>
                            <Grid.Row>
                              <Button.Group floated="right">
                                <SaveButton
                                  submitting={submitting}
                                  pristine={pristine}
                                  strings={strings}
                                />
                                <Button.Or />
                                {/* <Button
                                type="button"
                                disabled={pristine || submitting}
                                onClick={(e) => this.resetForm(e)}
                              >
                                Reset
                              </Button> 
                              <Button.Or /> */}
                                <Button
                                  style={{ textAlign: "right" }}
                                  onClick={(e) => this.cancelForm(e)}
                                >
                                  Cancel
                                </Button>
                              </Button.Group>
                            </Grid.Row>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    )}
                    <Segment>
                      <Header as="h2">Map</Header>
                      {refLocationsComponent(mineLevelId, "createNewNamedArea")}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>

            <DebugPagePropsMessages that={this} />
          </Container>
        </div>
      );
    }
  }
}

const selector = formValueSelector("createNewNamedAreaForm");

CreateNamedAreaForm = reduxForm({
  form: "createNewNamedAreaForm",
  // Note - to reinitialize values in componentDidMount
  // Make sure you set the values of enableReinitialize & keepDirtyOnReinitialize to true
  // See - https://stackoverflow.com/questions/44273099/redux-form-initialization-with-props-initialize
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(CreateNamedAreaForm);

const mapStateToProps = (state, props) => {
  const namedAreaId = props.id;

  const localMapState = GetLocalMap(state);
  const mineLevelId = selector(state, "mineLevelId");

  // collect an array of existing named area parent names to validate against new named area name
  const allNamedAreaStatuses = getNamedAreaStatusesByAreaId(state, mineLevelId); // getAllNamedAreaStatuses(state);
  const uniqueNamedAreaParentIds = allNamedAreaStatuses
    .map((item) => {
      return { id: item.parent, parentName: item.parent_name };
    }) // .parent
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.id === value.id && t.parentName === value.parentName
        )
    );
  //  .filter((value, index, self) => self.indexOf(value) === index);
  //.map((item) => item.split(":")[1]);

  //console.log(`uniqueNamedAreaParentIds`, uniqueNamedAreaParentIds);

  return {
    localMapState,
    mineLevelId,
    namedAreaIdValue: namedAreaId, // selector(state, "namedAreaIdValue"),
    namedAreaParentName: selector(state, "namedAreaParentName"),
    initialValues: {
      namedAreaIdValue: namedAreaId,
      regions: [],
    },
    uniqueNamedAreaParentIds,
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("reduxForm onChange values", values);

      if (values.mineLevelId !== previousValues.mineLevelId) {
        //console.log("onChange", values);
        const {
          change,
          initialValues: { mineLevelId, regions },
        } = props;
        // if (values.mineLevelId === mineLevelId) {
        //   dispatch(change("regions", regions));
        // } else {
        //   dispatch(change("regions", []));
        // }
      }
    },
    reduxFormValues: getFormValues("createNewNamedAreaForm")(state), // #REVIEW - only used for debugging,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ClearLocalMap: () => {
    dispatch(ClearLocalMap());
  },
});

CreateNamedAreaForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNamedAreaForm);

export default CreateNamedAreaForm;
