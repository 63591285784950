import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withMineLevel } from "ApplicationContexts";
import { FeatureGroup } from "react-leaflet";

import { getFireflyIdsForMineLevelId } from "components/WebWorker/reducer";

import Firefly from "containers/FireflyMapMarker";

class CurrentMineLevelFirefliesFeatureGroup extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const current = this.props.fireflies;
    const next = nextProps.fireflies;

    if (current.length === next.length) {
      const lookup = current.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});

      const len = next.length;
      for (var i = 0; i < len; i++) {
        if (!lookup[next[i]]) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  render() {
    const { fireflies, PopupContent } = this.props;

    return (
      <FeatureGroup>
        {fireflies.map((id) => {
          return (
            <Firefly
              key={`firefly-${id}`}
              id={id}
              PopupContent={PopupContent}
            />
          );
        })}
      </FeatureGroup>
    );
  }
}

export function mapStateToProps(state, props) {
  const { mineLevel } = props;
  let fireflies = getFireflyIdsForMineLevelId(state, mineLevel.id);

  return {
    fireflies,
  };
}

export default withMineLevel(
  connect(mapStateToProps)(CurrentMineLevelFirefliesFeatureGroup)
);
