// see main reducer at src/reducers.js

import Immutable from "seamless-immutable";

const initialState = Immutable([]);

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case "UPDATE_CONFLICTS":
      //console.log("UPDATE_CONFLICTS");
      return state.concat(action.payload);

    // #REVIEW - not used ATM
    // setup structure to for possible different fault cases or status issues

    case "REMOVE_CONFLICTS":
      //console.log("REMOVE_CONFLICTS");
      return Immutable(
        state.asMutable().filter(({ id }) => !id.includes(action.payload.id))
      );

    case "CLEAR_CONFLICTS":
      console.log("CLEAR_CONFLICTS");
      return initialState;
    default:
      return state;
  }
}

export function getAllConflicts(state) {
  return state.conflicts;
}
