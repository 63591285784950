import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Icon,
  Popup,
  Label,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import UPSLink from "../../admin/ups/UPSLink";
import FireflyLink from "../../admin/firefly/FireflyLink";

import AlarmButton from "components/AlarmButton";
import { timeConverter } from "../../utils/format-time";
import { capitalize } from "../../utils/format-text";

import { formatDate } from "utils/format-date";

import lodashSortBy from "lodash/sortBy";
import { formatRelative, parseISO } from "date-fns";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";

import { ClearLog } from "components/Logger/actions";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { withComponentStateCache } from "react-component-state-cache";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

// XLSL 'react-export-excel' IMPLEMENTATION
// click button to download report
// uses https://www.npmjs.com/package/react-export-excel
// see ex - https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/with_custom_download_element.md
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  render() {
    const { data } = this.props;
    const filename = `Browser_log_report_${formatDate(
      new Date(Date.now()),
      "dd-MMM-yy-HH-mm-ss"
    )}`;

    return (
      <ExcelFile
        filename={filename}
        element={
          <Popup
            content="Download XLSL logged report"
            trigger={
              <Button
                icon
                size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              >
                <Icon name="download" />
              </Button>
            }
          />
        }
      >
        <ExcelSheet data={data} name="Name">
          <ExcelColumn label="Log Time" value="time" />
          <ExcelColumn label="Description" value="description" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

class Logger extends Component {
  constructor(props) {
    super(props);
    const downloadData = [];
    this.state = {
      downloadData,
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        time: "",
        description: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    const filterInput = this.props.componentstate.get(
      "filterInput",
      "loggerList"
    );

    //console.log("filterInput componentDidMount", filterInput);

    if (!_isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "loggerList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included

        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  render() {
    const { strings } = this.props;

    const includeTitles = this.props.includeTitles === "true";
    const title = includeTitles ? (
      <Header as="h1">{strings?.["Browser Log"]}</Header>
    ) : (
      <div></div>
    );

    const { isLoading, error } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "loglist10" },
              { value: 20, text: "20", key: "loglist20" },
              { value: 40, text: "40", key: "loglist40" },
              { value: 60, text: "60", key: "loglist60" },
              { value: data.length, text: "all", key: "loglistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{title}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment textAlign="left" style={segmentStyle}>
            <div>
              <span style={{ marginRight: "8px" }}>
                <Download data={data} />
              </span>
            </div>
            {/* label and button *will not* go to same height as depends on font sizes. 
            Fudge by vertical centering the button and label in same div. */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Popup
                content={strings.formatString(
                  strings?.["{list_count_item} count. Click to clear."],
                  { list_count_item: "Log item" }
                )}
                trigger={
                  <Button
                    size="big"
                    color="orange"
                    onClick={this.props.ClearLog}
                  >
                    {itemsCount}
                  </Button>
                }
              />
            </div>
          </Segment>

          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  {/* <Input
                    size="mini"
                    icon="search"
                    placeholder="Filter..."
                    name="time"
                    onChange={this.handleFilterChange}
                  /> */}
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    icon="search"
                    placeholder="Filter..."
                    name="description"
                    onChange={this.handleFilterChange}
                    value={filterInput?.description}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "time" ? direction : null}
                  onClick={this.handleSort("time")}
                >
                  {strings?.["Time"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "description" ? direction : null}
                  onClick={this.handleSort("description")}
                >
                  {strings?.["Logged"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(viewablesPage, ({ time, description }) => (
                <Table.Row>
                  <Table.Cell>{time}</Table.Cell>
                  <Table.Cell>{description}</Table.Cell>
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  let filteredElements = [];

  // only need a subset of data
  elements.forEach(function (element, idx) {
    const { ts, description } = element;

    // compose new data set
    filteredElements[idx] = {
      time: ts > 0 ? timeConverter(ts) : "-",
      description: description,
    };
  });

  return filteredElements;
};

function mapStateToProps(state) {
  const { logOfThingsLogged } = state.logger;

  const data = _prepData(logOfThingsLogged);

  return {
    data,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, { ClearLog, saveUserSettingsComponentState })(Logger)
);
