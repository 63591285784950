import React from "react";

import { Form, Grid, Image, Segment, Header } from "semantic-ui-react";
import { Field } from "redux-form";
import { renderField } from "admin/form-field";
import { Link } from "react-router-dom";
import { minValueEqual, maxValueEqual } from "utils/normalise";

// range limits
const minValueEqual0 = minValueEqual(0); // ? "out of range" : undefined;
const maxValueEqual25 = maxValueEqual(25); // #NOTE - arbitrary value

// do not check for 0 as falsy
const required = (value) => (value !== "" ? undefined : "Required");
const validate = (values) => {
  const errors = {};
  return errors;
};

export const tagTrackingUserDetailsCardVehicle = (
  urlImage,
  tagUserId,
  disabled
) => {
  // if the card is 'disabled' it has been 'assigned'
  const cardTitle = disabled ? (
    <Link to={`/status/taguser/vehicle/edit/${tagUserId}`}>
      {`Vehicle Assigned`}{" "}
    </Link>
  ) : (
    "Details"
  );

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">{cardTitle}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Image src={urlImage} size="small" />
          </Grid.Column>
          <Grid.Column width={10}>
            <Field
              name="tagUserData.name"
              label="Name"
              placeholder="Enter vehicle name"
              component={renderField}
              className={disabled ? "disabled-form-field" : null}
              disabled={disabled}
            />
            <Form.Group widths="equal">
              <Field
                name="tagUserData.type"
                label="Type"
                placeholder="Enter vehicle type (0 to 25)"
                component={renderField}
                type="number"
                //normalize={numberNormalize}
                step={1}
                min={0}
                max={2048}
                validate={[required, minValueEqual0, maxValueEqual25]}
                parse={(value) => (value !== "" ? parseInt(value) : "")}
                className={disabled ? "disabled-form-field" : null}
                disabled={disabled}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
