import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchFireflys } from "UPSPanelControllers/actions";

import TagTrackingUserList from "admin/tag-tracking/TagTrackingUserList";
import EditTagTrackingUser from "admin/tag-tracking/TagTrackingUserEditPage";
import CreateTagTrackingUser from "admin/tag-tracking/TagTrackingUserCreatePage";

class TagTrackingUserAdminPages extends Component {
  render() {
    const { match, tagUserType, strings } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/new`}
          render={(props) => (
            <CreateTagTrackingUser
              {...props}
              tagUserType={tagUserType}
              strings={strings}
            />
          )}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={(props) => (
            <EditTagTrackingUser
              {...props}
              tagUserType={tagUserType}
              strings={strings}
            />
          )}
        />
        <Route
          exact
          path={match.url}
          render={(props) => (
            <TagTrackingUserList
              {...props}
              includeTitles="true"
              tagUserType={tagUserType}
              strings={strings}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(
  connect(null, { fetchFireflys })(TagTrackingUserAdminPages)
);
