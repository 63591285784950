import React from "react";
import { Button, Icon, Grid, Segment } from "semantic-ui-react";

// #WIP #TODO
//import { ALLOWED_LIGHT_COLORS } from 'firefly_light_colors'
// get light colors from server config
// see `src/firefly_light_colors.js`
// export const ALLOWED_LIGHT_COLORS = getConfigItem()
//
// function getConfigItem () {
//   try {
//     const val = getCookie('available-colors')
//     const serverValues = JSON.parse(atob(val))
//     return serverValues
//   } catch (e) {
//     return ['off', 'white', 'red', 'amber', 'green']
//   }
// }

import { getEquivalentSemanticColorName } from "colorpalette";
import { StatusEnumToReadable } from "utils/StatusEnum";
import { getMapMarkerDisplayOptions } from "components/ConfigJs";

// removed "off" as a  color -> state
const ALLOWED_LIGHT_COLORS = ["red", "amber", "white", "green", "blue"];

// let SingleColorOperation = ({ lightSetting, isEnabled, onClick }) => {
//   let semanticColorName = getEquivalentSemanticColorName(lightSetting);
//   semanticColorName = lightSetting === "white" ? "grey" : semanticColorName;
//   return (
//     <Button color={semanticColorName} onClick={onClick} basic={!isEnabled}>
//       <Icon name={"lightbulb"} />
//     </Button>
//   );
// };

export const FireflyMarkerPopup = ({ id, data }) => {
  const {
    ups_id,
    easting,
    northing,
    X,
    Y,
    z,
    utm_zone_number,
    utm_zone_letter,
    fireflyNote = "",
    //color,  // No longer used, see 'light' below
    //led_state, // No longer used, see 'light' below
    lastStatusReport,
    namedAreaEventForFirefly = {},
    light,
    role,
    ffMac,
    deviceStatus,
    forcedClickList,
  } = data;

  //console.log(`xxxx deviceStatus`, deviceStatus);
  //console.log(`xxxx forcedClickList`, forcedClickList);

  const { allowAdmin } = role;

  // default display options
  const {
    firefly: {
      showMac = false,
      showStatus = true,
      showUtm = false,
      showEventInfo = false,
      showForced = true,
    },
  } = getMapMarkerDisplayOptions();

  // console.log(
  //   `FireflyMarkerPopup props`,
  //   id,
  //   ups_id,
  //   easting,
  //   northing,
  //   X,
  //   Y,
  //   z,
  //   utm_zone_number,
  //   utm_zone_letter,
  //   fireflyNote,
  //   lastStatusReport,
  //   namedAreaEventForFirefly,
  //   light
  // );

  const {
    id: currentForcedId = "",
    active: isCurrentForcedActive = false,
    active_color: currentForcedColor = "",
    active_state: currentForcedState = "ON",
  } = namedAreaEventForFirefly;

  const isStrobe = ["STROBE", "FORWARD", "BACKWARD"].includes?.(
    currentForcedState?.toUpperCase?.()
  );
  const isOff = ["OFF"].includes?.(currentForcedState?.toUpperCase?.()); // ||     led_state?.toUpperCase?.() === "OFF";

  const { color, lightState, event_id } = light;
  const { status: colorStatus, plan: colorPlan } = color;
  const { status: lightStatus, plan: lightPlan } = lightState;

  //console.log(`forcedLight isStrobe`, isStrobe);

  // #NOTE - the `dataid` passed to the Button is used to attach an eventListener to the rendered string.
  // This is how the onClick event is setup.
  // See - src/components/Map/MineLevelMapLeaflet.js
  // "...
  // .querySelector(".popupFireflyMarkerLink")
  // .addEventListener("click", (e) => {
  //..."

  //
  //
  // #NOTE - `dataid` is places on both button and icon, as it is not possible to guarantee only one click target.

  const controllerLink = (
    <button
      type={"submit"}
      className={`${ups_id ? "link" : null} popupControllerMarkerLink`}
    >
      <span dataid={ups_id}>{ups_id || "unknown"} </span>
    </button>
  );

  const controllerNoLink = <strong>{ups_id || "unknown"} </strong>;

  const fireflyLink = (
    <button type={"submit"} className={"link popupFireflyMarkerLink"}>
      <span dataid={id}>{id.replaceAll("_", " ")}</span>
    </button>
  );
  const fireflyNoLink = <strong dataid={id}>{id.replaceAll("_", " ")}</strong>;

  const deviceStatusString =
    deviceStatus?.length > 0
      ? deviceStatus.map((status) => StatusEnumToReadable[status])?.join(", ")
      : "OK";

  const showForcedStyleDisplayHidden = !showForced ? { display: "none" } : null;

  const isProcessingRequest = forcedClickList.includes(id);

  return (
    <div style={showForced ? { width: "400px" } : { width: "250px" }}>
      <Grid>
        <Grid.Row
          key={`key-FF-${ups_id}`}
          style={{ paddingTop: "1em", paddingBottom: "0px" }}
        >
          <Grid.Column width={4}>
            {/* <Icon name="hdd" color={"blue"} /> */}
            <strong>Controller</strong>
          </Grid.Column>
          <Grid.Column width={11}>
            {allowAdmin ? controllerLink : controllerNoLink}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          key={`key-FF-${id}`}
          style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
        >
          <Grid.Column width={4}>
            {/* <Icon
              name="lightbulb"
              color={getEquivalentSemanticColorName(colorStatus)}
            /> */}
            <strong>FireFly</strong>
          </Grid.Column>
          <Grid.Column width={11}>
            {allowAdmin ? fireflyLink : fireflyNoLink}
          </Grid.Column>
        </Grid.Row>
        {showMac && (
          <Grid.Row
            key={`key-FF-${id}-mac`}
            style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
          >
            <Grid.Column width={4}>
              <strong>MAC</strong>
            </Grid.Column>
            <Grid.Column width={11}>{ffMac}</Grid.Column>
          </Grid.Row>
        )}
        {showStatus && (
          <Grid.Row
            key={`key-FF-${id}-deviceStatus`}
            style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
          >
            <Grid.Column width={4}>
              <strong>Status</strong>
            </Grid.Column>
            <Grid.Column width={11}>{deviceStatusString}</Grid.Column>
          </Grid.Row>
        )}
        {showUtm && (
          <Grid.Row
            key={`key-FF-${id}-utm`}
            style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
          >
            <Grid.Column width={4}>
              <strong>UTM</strong>
            </Grid.Column>
            <Grid.Column width={11}>
              <strong>Easting:</strong> {easting} <br />
              <strong>Northing:</strong> {northing} <br />
              <strong>Zone:</strong> {utm_zone_number} / {utm_zone_letter}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row
          key={`key-FF-${id}-color`}
          style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
        >
          <Grid.Column width={4}>
            <strong>Color</strong>
          </Grid.Column>
          <Grid.Column width={11}>
            <strong>Requested: </strong>
            <span
              style={{
                fontWeight: "bold",
                color: getEquivalentSemanticColorName(colorPlan),
              }}
            >
              {isOff
                ? "OFF"
                : colorPlan === "orange"
                ? "AMBER"
                : colorPlan.toUpperCase()}
            </span>

            <br />
            <strong>Reported: </strong>
            <span
              style={{
                fontWeight: "bold",
                color: `${getEquivalentSemanticColorName(colorStatus)}`,
              }}
            >
              {isOff
                ? "OFF"
                : colorStatus === "orange"
                ? "AMBER"
                : colorStatus.toUpperCase()}
              {colorPlan !== colorStatus ? (
                <span
                  style={{ paddingLeft: "2px", color: "tomato" }}
                  className={"flash-text"}
                >
                  ...updating...
                </span>
              ) : null}
            </span>
            <br />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          key={`key-FF-${id}-state`}
          style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
        >
          <Grid.Column width={4}>
            <strong>State</strong>
          </Grid.Column>
          <Grid.Column width={11}>
            <strong>Requested: </strong>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {isOff ? "OFF" : lightPlan?.led_state?.toUpperCase()}
            </span>
            <br />
            <strong>Reported: </strong>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {isOff ? "OFF" : lightStatus?.led_state?.toUpperCase()}
              {lightPlan?.led_state !== lightStatus?.led_state ? (
                <span
                  style={{ paddingLeft: "2px", color: "tomato" }}
                  className={"flash-text"}
                >
                  ...updating...
                </span>
              ) : null}
            </span>
          </Grid.Column>
        </Grid.Row>
        {showEventInfo && event_id !== "" && event_id !== currentForcedId && (
          <Grid.Row
            key={`key-FF-${id}-event-info`}
            style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
          >
            <Grid.Column width={4}>
              <strong>Event Info</strong>
            </Grid.Column>
            <Grid.Column width={11}>
              <strong>ID:</strong> {event_id} <br />
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row
          key={`key-FF-${id}-forced`}
          style={{
            paddingTop: "0.5em",
            paddingBottom: "0px",
            ...showForcedStyleDisplayHidden,
          }}
        >
          <Grid.Column width={4}>
            <strong>Forced</strong>
          </Grid.Column>
          <Grid.Column width={11}>
            <div hidden={currentForcedId !== ""}>
              Select a color to force a light color, or state, change
            </div>
            <div hidden={currentForcedId === ""}>
              <Button
                size="mini"
                //icon
                color={"grey"}
                className={"popupFireflyMarkerToggleForcedClear"}
                dataid={`${id}+clear`}
              >
                Clear
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>

        {showForced && currentForcedId !== "" && (
          <Grid.Row
            key={`key-FF-${id}-forced-info`}
            style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
          >
            <Grid.Column width={4}>
              <strong>Forced Info</strong>
            </Grid.Column>
            <Grid.Column width={11}>
              <strong>ID:</strong> {currentForcedId} <br />
              <strong>COLOR:</strong> {currentForcedColor} <br />
              <strong>STATE:</strong> {currentForcedState}
            </Grid.Column>
          </Grid.Row>
        )}
        {isProcessingRequest && (
          <Grid.Row
            key={`key-FF-${id}-forced-request`}
            style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
          >
            <Grid.Column width={4}>
              <strong>Changed</strong>
            </Grid.Column>
            <Grid.Column width={11} style={{ color: "tomato" }}>
              <strong>Change requested. Processing ...</strong>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row
          key={`key-FF-${id}-last`}
          style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
        >
          {/* <Grid.Column width={4}>
            <strong>Last Report</strong>
          </Grid.Column>
          <Grid.Column width={11}>{lastStatusReport}</Grid.Column> */}
        </Grid.Row>
        <Grid.Row
          key={`key-FF-${id}-forcedColors`}
          style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
        >
          <Grid.Column>
            <Segment clearing>
              <Icon
                style={{ paddingRight: "10px" }}
                name="lightbulb"
                color={getEquivalentSemanticColorName(colorStatus)}
              />
              <Button.Group>
                {ALLOWED_LIGHT_COLORS.map((color, idx) => {
                  let semanticColorName = getEquivalentSemanticColorName(color);

                  let isEnabled = false;
                  let isSameColor =
                    color === currentForcedColor || color === colorStatus;

                  // if strobe is set and it's the same is the same color then this is a forced event
                  // if strobe is not set and it is the same color there is no forced event

                  if (isSameColor) {
                    isEnabled = true;
                  }

                  semanticColorName =
                    color === "white" ? "grey" : semanticColorName;

                  // console.log(
                  //   `forcedLight FireflyMarkerPopup light , isEnabled, isSameColor, isStrobe`,
                  //   isEnabled,
                  //   isSameColor,
                  //   isStrobe,
                  //   "currentForcedColor",
                  //   currentForcedColor,
                  //   "color",
                  //   color
                  // );

                  const buttonBackgroundStyle = !isEnabled
                    ? {
                        backgroundColor: `${semanticColorName}!important`,
                        opacity: 0.2,
                      }
                    : {
                        opacity: 1,
                        borderColor: "#333",
                        borderStyle: "solid",
                      };

                  return (
                    <Button
                      size="mini"
                      color={semanticColorName}
                      style={buttonBackgroundStyle}
                      className={"popupFireflyMarkerToggleForcedColor"}
                      dataid={
                        showForced ? `${id}+${color}` : `${id}+FORCED_DISABLED`
                      }
                      basic={!isEnabled}
                    >
                      &nbsp;
                    </Button>
                  );
                })}
              </Button.Group>
              <div
                style={{ display: "inline", ...showForcedStyleDisplayHidden }}
              >
                <label
                  className={"leaflet-container font"}
                  style={{ background: "none" }}
                  for={`${id}+isCheckBoxStrobe`}
                >
                  <input
                    style={{ marginLeft: "10px", marginRight: "5px" }}
                    class="popupFireflyMarkerToggleForcedStateStrobe"
                    id={`${id}+isCheckBoxStrobe`}
                    type="checkbox"
                    name="field"
                    value="option"
                    dataid={`${id}+${colorStatus}`} // send current Status color
                    checked={isStrobe}
                  />
                  Strobe
                </label>
                <label
                  className={"leaflet-container font"}
                  style={{ background: "none" }}
                  for={`${id}+isCheckBoxOff`}
                >
                  <input
                    style={{ marginLeft: "10px", marginRight: "5px" }}
                    class="popupFireflyMarkerToggleForcedStateOff"
                    id={`${id}+isCheckBoxOff`}
                    type="checkbox"
                    name="field"
                    value="option"
                    dataid={`${id}+${colorStatus}`} // send current Status color when turning off
                    checked={isOff}
                  />
                  Off
                </label>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row key={`key-FF-${id}-notes`} style={{ paddingTop: "0.5em" }}>
          <Grid.Column width={4}>
            <strong>Notes</strong>
          </Grid.Column>
          <Grid.Column width={11}>
            {fireflyNote !== ""
              ? fireflyNote
              : "There are no notes for this firefly."}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
