// HB_15Mar22 - #OLD_CODE review file pending review and removal.
//
// This old code is still being used and should be rewritten.
//
// After re-write remove -src/UPSPanelControllers/reducer.js
//

import { Component } from "react";
import { connect } from "react-redux";

import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
} from "FlashMessages/actions";

import {
  isConfigJs,
  faultMessageBannerDisplay,
  faultMessageBannerText,
} from "components/ConfigJs";

import { SetMessageBanner } from "../AlarmButton/actions";

import { LogIt } from "components/Logger/actions";

import cuid from "cuid";
import uniq from "lodash/uniq";
import reduce from "lodash/reduce";

import { getAllUPSs } from "UPSPanelControllers/reducer";
//import { getFirefliesForUPSId } from "UPSPanelControllers/reducer";

import { getAllFireflys } from "UPSPanelControllers/reducer";
import { hasTopologyChanged } from "UPSPanelControllers/reducer";

import { UpdateConflicts } from "components/StatusChecks/actions";
import { getAllConflicts } from "components/StatusChecks/reducer";

// StatusChecks
//
// Runs checks on map data and reports problems to alarm banner.
//
//
class StatusChecks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controllersNeedingDiscovering: [],
    };
  }

  checkAllControllers = () => {
    const { controllers } = this.props;
    const { fireflies } = this.props;

    let controllersNeedingDiscovering = [];

    // check all FFs connected to each controller for duplicates topology.positions
    controllers.forEach(function (controller, idx) {
      const { id: upsId, name: upsName } = controller;
      let name = upsName;
      if (controller?.lastStatusReport?.topology?.network_name !== undefined) {
        name = controller.lastStatusReport.topology.network_name;
      }

      const firefliesForController = fireflies.filter(
        (ff) => ff.topology.upsId === upsId
      );

      // do this for each controller ....
      // see - src/admin/bulk-positioning/UPSPortSummary.js
      // Controllers have 4 ports
      const portNum = [1, 2, 3, 4].map((i) => String(i));
      portNum.map((pi) => {
        let totalCount = 0;
        let uncommissionedCount = 0;
        const atEachOrdinal = {};

        firefliesForController.forEach((ff) => {
          if (ff.getIn(["topology", "portNumber"]) === pi) {
            const topoPos = ff.getIn(["topology", "position"]);

            // create a list ordered of ff mac addresses by topology position i.e. position in mine panel
            atEachOrdinal[topoPos] = [ff.id]
              .concat(atEachOrdinal[topoPos])
              .filter(Boolean); // truthy values only

            totalCount += 1;

            // if there is no previously assigned geographic position (i.e. latlng) it's uncommissioned
            if (ff.position === null) {
              uncommissionedCount += 1;
            }
          }
        });

        const likelyReplacements = reduce(
          atEachOrdinal,
          (result, value, key) => {
            if (uniq(value).length > 1) {
              result.push({ position: key, fireflyIps: value });
              //console.log("xxx likelyReplacements key value", key, value);
            }
            return result;
          },
          []
        );

        if (likelyReplacements.length > 0) {
          for (let i = 0; i < likelyReplacements.length; i++) {
            controllersNeedingDiscovering.push({
              name: name,
              port: pi,
              position: likelyReplacements[i].position,
              fireflyIps: likelyReplacements[i].fireflyIps.toString(),
            });
          }

          // console.log(
          //   "likelyReplacements controllersNeedingDiscovering",
          //   controllersNeedingDiscovering
          // );
          // console.log("likelyReplacements", likelyReplacements);
          // console.log(
          //   "xxx startup message to notify run discovery on controller ",
          //   name,
          //   "because of likely replacement FF at port",
          //   pi,
          //   "position",
          //   likelyReplacements[0].position,
          //   "firefly IPs ",
          //   likelyReplacements[0].fireflyIps
          // );
        }
      });
    });

    return controllersNeedingDiscovering;
  };

  // #REVIEW - note similar code to src/admin/firefly/FireflyList.js
  checkAllFireflies = () => {
    const { fireflies } = this.props;

    //  ... is there another FF in same position?
    let samePosition = [];

    //  ... has a FF changed topology
    // i.e. moved from a previous position and recommissioned

    let changedTopology = [];

    // compare the position of every firefly with every other firefly
    fireflies.forEach(function (firefly, idx) {
      // #REVIEW - code similar to src/admin/firefly/FireflyList.js
      const {
        id,
        position,
        topology: { area },
        lastStatusReport,
      } = firefly;

      // do this for each firefly ....

      // ... has it moved?
      if (lastStatusReport !== null) {
        if (
          firefly.topology &&
          firefly.topology !== null &&
          lastStatusReport.topology &&
          lastStatusReport.topology !== null
        ) {
          if (hasTopologyChanged(firefly, lastStatusReport)) {
            changedTopology.push(firefly.name);
          }
        }
      }

      // compare this firefly with every other firefly
      fireflies.map((ff) => {
        // some fireflies do not have a position assigned
        // and ignore comparing to self
        // ignore if in different area
        if (
          position &&
          ff.position &&
          ff.id !== id &&
          ff.topology.area !== area
        ) {
          // elsewhere the positional data is changed from ~12 dec points
          // to longer some have to fix to set # of decimal places before comparison
          const decPlaces = 12;
          const fixedPosition = {
            lat: position.lat.toFixed(decPlaces),
            lng: position.lng.toFixed(decPlaces),
          };
          const fixedFFPosition = {
            lat: ff.position.lat.toFixed(decPlaces),
            lng: ff.position.lng.toFixed(decPlaces),
          };

          if (
            JSON.stringify(fixedPosition) === JSON.stringify(fixedFFPosition)
          ) {
            samePosition.push(ff.name);
          }
        }
      });
    });

    //this.displayChangedTopologyMessage(changedTopology);
    //this.displaySamePositionMessage(samePosition);
  };

  // checkChangedTopology = () => {
  //   const { fireflies } = this.props;

  //   //  ... has a FF changed topology
  //   // i.e. moved from a previous position and recommissioned

  //   let changedTopology = [];

  //   // compare the position of every firefly with every other firefly
  //   fireflies.forEach(function (firefly, idx) {
  //     const { lastStatusReport } = firefly;

  //     // #REVIEW - code similar to src/admin/firefly/FireflyList.js
  //     // ... has it moved?

  //     console.log("xxx firefly", firefly);
  //     console.log("xxx lastStatusReport", lastStatusReport);

  //     if (lastStatusReport !== null) {
  //       if (
  //         firefly.topology &&
  //         firefly.topology !== null &&
  //         lastStatusReport.topology &&
  //         lastStatusReport.topology !== null
  //       ) {
  //         // if (hasTopologyChanged(firefly, lastStatusReport)) {
  //         //   changedTopology.push(firefly.name);
  //         // }
  //       }
  //     }
  //   });

  //   console.log("changedTopology", changedTopology);
  //   this.displayChangedTopologyMessage(changedTopology);
  // };

  displaySamePositionMessage = (samePosition) => {
    if (samePosition.length) {
      let positions;
      samePosition.forEach(function (position, idx) {
        if (idx === 0) {
          positions = position;
        } else {
          positions = positions + ", " + position;
        }
      });

      if (isConfigJs() && faultMessageBannerDisplay()) {
        const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

        const messageBanners = this.props.messageBanners; // from redux state
        //#REVIEW - force to one type for now - this needs to be merged with AlarmButtons and other messaging
        const bannerDisplayed = messageBanners["fireflySamePosition"].display; // deconstruct to a simpler obj

        // if this fault type allows message banner display (in config)
        // and there hasn't been a message banner displayed previously

        //#REVIEW - need to check fault type as per review comment above
        if (
          isMessageBannerForFaultType["fireflySamePosition"] &&
          !bannerDisplayed
        ) {
          // create a unique ID for the banner
          const id = cuid();

          // display a new banner
          this.displayErrors(id, "fireflySamePosition", positions);

          // record the update state of banner including the id (needed to delete this particular banner)
          let tmpObj = {};
          tmpObj.faultType = "fireflySamePosition"; // #REVIEW - force to one status check type for now
          tmpObj.display = true;
          tmpObj.id = id;

          this.props.SetMessageBanner(tmpObj);
        }
      }
    }
  };

  displayControllerRunDiscoverMessage = (controllersNeedingDiscovering) => {
    let controllers = [];

    // console.log(
    //   "xxx controllersNeedingDiscovering",
    //   controllersNeedingDiscovering
    // );

    if (controllersNeedingDiscovering.length) {
      controllersNeedingDiscovering.forEach(function (
        { name, port, position, fireflyIps, ip },
        idx
      ) {
        //const description = `${name}| Port: ${port} | Position: ${position} | FFs : ${fireflyIps.toString()} `;
        if (idx === 0) {
          controllers = `Check Bulk Positioning for : ${name.toUpperCase()} [Port ${port}] `;
        } else {
          controllers = `${controllers}, ${name.toUpperCase()} [Port ${port}]`;
        }
      });

      if (isConfigJs() && faultMessageBannerDisplay()) {
        const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

        const messageBanners = this.props.messageBanners; // from redux state

        //#REVIEW - force to one type for now - this needs to be merged with AlarmButtons and other messaging
        const bannerDisplayed =
          messageBanners["controllerLikelyReplacement"].display; // deconstruct to a simpler obj

        // if this fault type allows message banner display (in config)
        // and there hasn't been a message banner displayed previously

        //#REVIEW - need to check fault type as per review comment above
        if (
          isMessageBannerForFaultType["controllerLikelyReplacement"] &&
          !bannerDisplayed
        ) {
          // create a unique ID for the banner
          const id = cuid();

          if (controllers.length) {
            // likely replacements exists
            // display a message banner if one doesn't already exist
            if (!this.faultMessageBannerId("controllerLikelyReplacement")) {
              // display a new banner
              this.displayErrors(
                id,
                "controllerLikelyReplacement",
                controllers
              );

              // record the update state of banner including the id (needed to delete this particular banner)
              let tmpObj = {};
              tmpObj.faultType = "controllerLikelyReplacement"; // #REVIEW - force to one status check type for now
              tmpObj.display = true;
              tmpObj.id = id;

              this.props.SetMessageBanner(tmpObj);
            }
          }
        }
      }
    } // #REVIEW - manage removal of message banner by finding ID (if exists) BUT also log a message
    //
    else {
      // clear the banner message for 'controllerLikelyReplacement'
      // and log controllerLikelyReplacementCorrected
      this.displayErrors(
        0, // dummy id
        "controllerLikelyReplacementCorrected",
        controllers
      );
      //
    }
  };

  displayChangedTopologyMessage = (changedTopology) => {
    if (changedTopology.length) {
      let locations;
      changedTopology.forEach(function (location, idx) {
        if (idx === 0) {
          locations = location;
        } else {
          locations = locations + ", " + location;
        }
      });

      if (isConfigJs() && faultMessageBannerDisplay()) {
        const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

        const messageBanners = this.props.messageBanners; // from redux state
        //#REVIEW - force to one type for now - this needs to be merged with AlarmButtons and other messaging
        const bannerDisplayed =
          messageBanners["fireflyChangedTopology"].display; // deconstruct to a simpler obj

        // if this fault type allows message banner display (in config)
        // and there hasn't been a message banner displayed previously

        //#REVIEW - need to check fault type as per review comment above
        if (
          isMessageBannerForFaultType["fireflyChangedTopology"] &&
          !bannerDisplayed
        ) {
          // create a unique ID for the banner
          const id = cuid();

          // display a new banner
          this.displayErrors(id, "fireflyChangedTopology", locations);

          // record the update state of banner including the id (needed to delete this particular banner)
          let tmpObj = {};
          tmpObj.faultType = "fireflyChangedTopology"; // #REVIEW - force to one status check type for now
          tmpObj.display = true;
          tmpObj.id = id;

          this.props.SetMessageBanner(tmpObj);
        }
      }
    }
  };

  // get the text for the fault message banner from config.js or set default
  getFaultMessageBannerText = (faultType) => {
    let header;
    let message;
    let color;

    if (isConfigJs() && faultMessageBannerText()) {
      const messageBannerText = faultMessageBannerText(); // from config.js file

      //console.log("xxx faultType", faultType);
      // console.log("xxx messageBannerText", messageBannerText);
      // console.log(
      //   "xxx messageBannerText[faultType]",
      //   messageBannerText[faultType]
      // );

      header = messageBannerText[faultType].header;
      message = messageBannerText[faultType].message;
      color = messageBannerText[faultType].color;
    } else {
      // default messages if config.js object is stuffed
      switch (faultType) {
        case "fireflySamePosition":
          header = "FireFly Same Position";
          message = "More than one FireFly in the same position.";
          color = "red";
          break;
        case "controllerLikelyReplacement":
          header = "Run Discovery";
          message = "Run discovery on controllers to correct FF positions. ";
          color = "red";
          break;
        default:
          break;
      }
    }

    return { header, message, color };
  };

  // loads header and message to display on control screen page
  displayErrors(id, faultType, extraMessage) {
    const {
      header: headerDefault,
      message: messageDefault,
      color: colorDefault,
    } = this.getFaultMessageBannerText(faultType);

    let header = "";
    let message = "";
    let color = "red";
    let displayOnPageHeader = false;
    let errorType;

    switch (faultType) {
      case "fireflySamePosition":
        header = headerDefault;
        message = messageDefault + " " + extraMessage;
        color = colorDefault;
        displayOnPageHeader = true;
        // include errorType in id so can find and remove later (see above)
        id = id + "-SAME_POSITION";
        errorType = "SAME_POSITION";
        break;

      // #REVIEW - move this to configJS too (or db entry)
      case "fireflySamePositionCorrected":
        header = headerDefault;
        message = messageDefault + " " + extraMessage;

        // only clear 'LIKELY_REPLACEMENT_CORRECTED' message if there is no problem
        this.clearFaultMessageBanner("fireflySamePosition");
        errorType = "SAME_POSITION_CORRECTED";
        displayOnPageHeader = false;
        break;
      // controllerLikelyReplacement
      case "controllerLikelyReplacement":
        header = headerDefault;
        message = messageDefault + " " + extraMessage;
        color = colorDefault;
        displayOnPageHeader = true;
        // include errorType in id so can find and remove later (see above)
        id = id + "-LIKELY_REPLACEMENT";
        errorType = "LIKELY_REPLACEMENT";
        break;

      // #REVIEW - move this to configJS too (or db entry)
      case "controllerLikelyReplacementCorrected":
        header = headerDefault;
        message = messageDefault + " " + extraMessage;

        // only clear 'controllerLikelyReplacement' message if there is no problem
        this.clearFaultMessageBanner("controllerLikelyReplacement");
        //        this.props.removeFlashMessage("LIKELY_REPLACEMENT");
        errorType = "LIKELY_REPLACEMENT_CORRECTED";
        displayOnPageHeader = false;
        break;

      default:
        break;
    }
    if (displayOnPageHeader) {
      this.props.addWarningFlashMessageIdColor(
        id,
        color,
        header,
        message || "Unknown problem"
      );
    }
    this.props.LogIt(`${errorType} - ${message}`);
    //console.log("displayErrors - " + header + "-" + message);
  }

  // #REVIEW - from src/components/AlarmButton/index.js
  // move to common function

  clearFaultMessageBanner = (faultType) => {
    // NOTE - there are two ways these messages are cleared
    //
    // removeFlashMessage("something");
    // ... clears anything with "something" in the id of the message
    // so it is possible to clear all messages of a specific type,
    // e.g.
    // id = id + "-LIKELY_REPLACEMENT";
    // removeFlashMessage("LIKELY_REPLACEMENT")
    // or a specific message
    // removeFlashMessage(id)
    //

    // Aside -  this.props.clearFlashMessages(); // clear off previous messages

    // get the id for the flash message
    const bannerId = this.faultMessageBannerId(faultType);
    if (bannerId) {
      this.props.removeFlashMessage(bannerId);

      //#REVIEW - make this a function..........
      // delete this banner message from state
      let tmpObj = {};
      tmpObj.faultType = faultType;
      tmpObj.display = false;
      tmpObj.id = "";

      this.props.SetMessageBanner(tmpObj);
    }
  };

  faultMessageBannerId = (faultType) => {
    // check if a message of this type exists

    // get the id for the flash message
    const messageBanners = this.props.messageBanners; // from redux state
    return messageBanners[faultType].id;
  };

  componentDidMount() {
    // #REVIEW - rewrite when have actual tests of faults coming and going
    // #REVIEW - had this at startup but not necessary????????????
    //
    //
    //
    // const controllersNeedingDiscovering = this.checkAllControllers();
    // // don't react to no-fault state at startup
    //   if (controllersNeedingDiscovering.length !== 0) {
    //     this.displayControllerRunDiscoverMessage(controllersNeedingDiscovering);
    //   } else {
    //     this.setState({
    //       controllersNeedingDiscovering: controllersNeedingDiscovering,
    //     });
    //   }
  }

  componentDidUpdate(prevProps) {
    // only check if controller / firefly properties have changed
    // i.e. when FFs are added and removed

    // console.log(
    //   "xxx componentDidUpdate prevProps.controllers !== this.props.controllers",
    //   prevProps.controllers !== this.props.controllers
    // );
    // console.log("xxx this.props.messageBanners", this.props.messageBanners);

    if (prevProps.controllers !== this.props.controllers) {
      // #REVIEW - rewrite when have actual tests of faults coming and going

      const controllersNeedingDiscovering = this.checkAllControllers();

      // DEBUGS FOR MESSAGE BANNER CHECKS
      // console.log(
      //   "xxx this.state.controllersNeedingDiscovering",
      //   JSON.stringify(this.state.controllersNeedingDiscovering)
      // );
      // console.log(
      //   "xxx controllersNeedingDiscovering",
      //   JSON.stringify(controllersNeedingDiscovering)
      // );

      // react if there is a change

      if (
        // #REVIEW - force both to strings before compare - super ugly????
        JSON.stringify(this.state.controllersNeedingDiscovering) !==
        JSON.stringify(controllersNeedingDiscovering)
      ) {
        this.displayControllerRunDiscoverMessage(controllersNeedingDiscovering);
        // update changed state
        this.setState({
          controllersNeedingDiscovering: controllersNeedingDiscovering,
        });
      }
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { messageBanners } = state.alarmButton;
  const fireflies = getAllFireflys(state) || [];
  const controllers = getAllUPSs(state) || [];
  const conflicts = getAllConflicts(state) || [];
  return {
    messageBanners,
    fireflies,
    controllers,
    conflicts,
  };
}

const mapDispatchToProps = (dispatch) => ({
  // sets a State record that a banner has been set
  SetMessageBanner: (messageState) => {
    dispatch(SetMessageBanner(messageState));
  },
  // remove a particular message banner
  removeFlashMessage: (id) => {
    dispatch(removeFlashMessage(id));
  },
  // display a message banner
  addWarningFlashMessageIdColor: (id, color, header, message) => {
    dispatch(addWarningFlashMessageIdColor(id, color, header, message));
  },
  LogIt: (message) => {
    dispatch(LogIt(message));
  },
  UpdateConflicts: (message) => {
    dispatch(UpdateConflicts(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusChecks);
