export function UpdateMqttReset(bool) {
  return { type: "MQTT_RESET", payload: bool };
}

export function UpdateMqttMsg(mqttMsg) {
  return { type: "MQTT_MSG_UPDATE", payload: mqttMsg };
}

export function UpdateLatestFaultTs(latestFaultTs) {
  return { type: "MQTT_LATEST_FAULT_TS_UPDATE", payload: latestFaultTs };
}

// railway_application
export function UpdateExternalTrigger(externalTrigger) {
  return { type: "MQTT_LATEST_EXTERNAL_TRIGGER", payload: externalTrigger };
}
export function UpdateButtonTrigger(buttonTrigger) {
  return { type: "MQTT_LATEST_BUTTON_TRIGGER", payload: buttonTrigger };
}
export function UpdateMachineStatus(machineStatus) {
  return { type: "MQTT_LATEST_MACHINE_STATUS_UPDATE", payload: machineStatus };
}

export function UpdateMapMsg(msg) {
  return { type: "MQTT_MAP_MSG_UPDATE", payload: msg };
}

export function UpdateControllerCoordinates(msg) {
  return { type: "MQTT_CONTROLLER_COORDINATES_UPDATE", payload: msg };
}
export function UpdateControllerStatuses(msg) {
  return { type: "MQTT_CONTROLLER_STATUSES_UPDATE", payload: msg };
}

export function UpdateFireflyCoordinates(msg) {
  return { type: "MQTT_FIREFLY_COORDINATES_UPDATE", payload: msg };
}
export function UpdateFireflyStatuses(msg) {
  return { type: "MQTT_FIREFLY_STATUSES_UPDATE", payload: msg };
}

export function UpdateAreaInfos(msg) {
  return { type: "MQTT_AREA_INFOS_UPDATE", payload: msg };
}
export function UpdateAreaStatuses(msg) {
  return { type: "MQTT_AREA_STATUSES_UPDATE", payload: msg };
}

export function UpdateNamedAreaInfos(msg) {
  return { type: "MQTT_NAMED_AREA_INFOS_UPDATE", payload: msg };
}
export function UpdateNamedAreaStatuses(msg) {
  return { type: "MQTT_NAMED_AREA_STATUSES_UPDATE", payload: msg };
}

export function UpdateNamedAreaEvents(msg) {
  return { type: "MQTT_NAMED_AREA_EVENTS_UPDATE", payload: msg };
}

export function UpdateEmergencyEventSettings(msg) {
  return { type: "MQTT_EMERGENCY_EVENT_SETTINGS_UPDATE", payload: msg };
}

export function UpdateTriggerEventSettings(msg) {
  return { type: "MQTT_TRIGGER_EVENT_SETTINGS_UPDATE", payload: msg };
}

export function UpdateAcks(msg) {
  return { type: "MQTT_ACKS_UPDATE", payload: msg };
}

export function removeAcks(token) {
  return { type: "REMOVE_ACKS", payload: token };
}

export function isAcksReceived(token) {
  return { type: "CHECK_RECEIVE_ACKS", payload: token };
}

// Device Timestamps
export function UpdateDeviceTimestamps(msg) {
  return { type: "MQTT_DEVICE_TIMESTAMPS", payload: msg };
}

// Server Timestamp from system/timestamp
export function UpdateServerTimestamp(msg) {
  return { type: "MQTT_SERVER_TIMESTAMP", payload: msg };
}

// Server Timestamp from system/timestamp
export function UpdateSystemProcessMsg(msg) {
  return { type: "MQTT_SYSTEM_PROCESS_MSG_UPDATE", payload: msg };
}

// Server Timestamp from system/timestamp
export function ClearSystemProcessMsg(msg) {
  return { type: "MQTT_SYSTEM_PROCESS_MSG_CLEAR", payload: msg };
}

// trigger recalc
export function UpdateRecalcState(msg) {
  return { type: "MQTT_RECALC_STATE", payload: msg };
}

// publishing message actions

export function mqttPublish(mqttMsg) {
  return { type: "MQTT_PUBLISH", payload: mqttMsg };
}

// WIP - not used ATM
export function removeMqttMessage(id) {
  return { type: "REMOVE_MQTT_MESSAGE", payload: { id } };
}

export function removeMqttMessageByToken(token) {
  return { type: "REMOVE_MQTT_MESSAGE_BY_TOKEN", payload: token };
}

export function clearMqttMessages() {
  return { type: "CLEAR_MQTT_MESSAGES" };
}

// passes list of named areas to be removed from the maps
// this is done:
// * to avoid the delay of waiting for the named area to be deleted by mqtt/broker
// * accommodate removal of named area polygons created locally but never saved to the system (db)

export function namedAreaSetDeleteSelections(deleteSelections) {
  return {
    type: "MAP_SET_DELETE_SELECTIONS_NAMED_AREAS",
    payload: deleteSelections,
  };
}

export function namedAreaClearDeleteSelections(id) {
  return { type: "MAP_CLEAR_DELETE_SELECTIONS_NAMED_AREAS", payload: id };
}

export function namedAreaSetShowHideSelections(hideSelections) {
  return { type: "MAP_SET_HIDE_NAMED_AREAS", payload: hideSelections };
}

export function namedAreaClearShowHideSelections(hideSelections) {
  return { type: "MAP_CLEAR_HIDE_NAMED_AREAS", payload: hideSelections };
}

// id = parent id for namedArea group
export function namedAreasSetIsDirty(id) {
  return { type: "MAP_NAMED_AREAS_SET_IS_DIRTY", payload: id };
}

// id = parent id for namedArea group
export function namedAreasClearIsDirty(id) {
  return { type: "MAP_NAMED_AREAS_CLEAR_IS_DIRTY", payload: id };
}

// data loading
export function UpdateDataLoadingResponseRequestQueue(queueArray) {
  return {
    type: "MQTT_DATA_LOADING_RESPONSE_REQUEST_MESSAGE_QUEUE",
    payload: queueArray,
  };
}

// data loading
export function fireflyIdsUpdateListDeleteId(id) {
  // fireflyIdsUpdateList
  // console.log(
  //   `fireflyIdsUpdateList MQTT_FIREFLY_STATUSES_UPDATE_LIST_DELETE_ID`,
  //   id
  // );
  return {
    type: "MQTT_FIREFLY_STATUSES_UPDATE_LIST_DELETE_ID",
    payload: id,
  };
}

// clear API data fetch flag
export function clearDataFetched(key) {
  return { type: "CLEAR_DATA_FETCHED", payload: key };
}

// set flag to reload area images
export function setAreaImageChanged(bool) {
  return { type: "SET_AREA_IMAGES_CHANGED", payload: bool };
}
