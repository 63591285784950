// #LOCALISATION
//
// see - https://www.npmjs.com/package/react-localization
// uses - https://www.npmjs.com/package/localized-strings

import LocalizedStrings from "react-localization";

export const defaultStrings = {
  // Main nav & menus
  "FireFly Lighting System": "FireFly Lighting System",
  Login: "Login",
  Email: "Email",
  Password: "Password",
  "Authentication Error": "Authentication Error",
  Unauthorized: "Unauthorized",
  Status: "Status",
  "Controller Status": "Controller Status",
  "FireFly Status": "FireFly Status",
  Faults: "Faults",
  "FireFly Faults": "FireFly Faults",
  "Battery Faults": "Battery Faults",
  "Network Faults": "Network Faults",
  "Controller Reports": "Controller Reports",
  "Smart Lighting": "Smart Lighting",
  Admin: "Admin",
  Areas: "Areas",
  Controllers: "Controllers",
  FireFly: "FireFly",
  FireFlys: "FireFlys",
  "Bulk Positioning": "Bulk Positioning",
  "Lighting Events": "Lighting Events",
  "Scheduled Events": "Scheduled Events",
  "Scheduled Event": "Scheduled Event",
  "Scheduled Lighting Events": "Scheduled Lighting Events",
  Logs: "Logs",
  "System Logs": "System Logs",
  "Browser Logs": "Browser Logs",
  System: "System",
  Tools: "Tools",
  "System Tools": "System Tools",
  "Backup and Restore": "Backup and Restore",
  Users: "Users",
  Profile: "Profile",
  "User Profile": "User Profile",
  "Select items in the table below to enable or disable.":
    "Select items in the table below to enable or disable.",
  Sorry: "Sorry",
  Logout: "Logout",
  USER_PROFILE_SETTING_MESSAGE: "Log out/log in to apply Settings changes.",
  Next: "Next",
  "Next Activate": "Next Activate",
  "Next Deactivate": "Next Deactivate",

  // Controlroompage
  "Loading ...": "Loading ...",
  "Loading data ...": "Loading data ...",
  LOADING_MESSAGE:
    "There are currently no areas to display. Select 'Admin > Areas' to create a new area. If areas have already been defined there could be a problem loading data. If the problem persists, check the console and mqtt.",
  "There are no levels defined for this group.":
    "There are no levels defined for this group.",

  // Area pages
  Map: "Map",
  "Map Image": "Map Image",
  "Edit Area": "Edit Area",
  "Light Settings": "Light Settings",
  "Default Color of Lights": "Default Color of Lights",
  "Default State of Lights": "Default State of Lights",
  "Map Settings": "Map Settings",
  "Background Color": "Background Color",
  "Choose Map Image": "Choose Map Image",
  "Add another file": "Add another file",
  "Map Background Color": "Map Background Color",
  "Map Reference Markers": "Map Reference Markers",
  Northing: "Northing",
  Easting: "Easting",
  Letter: "Letter",
  Number: "Number",
  "Area Message Header": "Set the default state of the FireFlys in the Area",
  "Area Message Content":
    "e.g. ON and AMBER, or OFF, but GREEN when turned ON.",
  "Enter Area Name": "Enter Area Name",
  Order: "Order",

  // Faults pages
  ID: "ID",
  Device: "Device",
  Fault: "Fault",
  "Fault Level": "Fault Level",
  "Fault Time": "Fault Time",
  "Total current faults": "Total current faults",

  // Generic form page terms
  Clear: "Clear",
  Copy: "Copy",
  copy: "copy",
  "Filter...": "Filter...",
  "Nothing changed": "Nothing changed",
  Save: "Save",
  Submitting: "Submitting",
  Reset: "Reset",
  Cancel: "Cancel",
  Add: "Add",
  Edit: "Edit",
  Close: "Close",
  Enable: "Enable",
  Disable: "Disable",
  Delete: "Delete",
  "Delete Button Message Header": "Delete",
  "Delete Button Message Content": "Are you sure you want to delete?",
  Yes: "Yes",
  No: "No",
  "Collapse Left Side": "Collapse Left Side",
  "Expand Left Side": "Expand Left Side",
  ON: "ON",
  OFF: "OFF",
  "This name has already been used": "This name has already been used",
  unknown: "unknown",
  "No Area data available. Waiting...": "No Area data available. Waiting...",
  Update: "Update",

  // Generic list page terms
  "Select items in the table below to apply an action":
    "Select items in the table below to apply an action.",
  "Empty list. Waiting for data...": "Empty list. Waiting for data...",
  "{list_length} items displayed": "{list_length} items displayed",
  "{list_items} selected...": "{list_items} selected...",
  "{list_count_item} count. Click to refresh data.":
    "{list_count_item} count. Click to refresh data.",
  "{list_count_item} count. Click to clear.":
    "{list_count_item} count. Click to clear.",

  // Alarm Buttons
  "View {fault_type} faults": "View {fault_type} faults",
  "View FireFly controller {fault_type} faults":
    "View FireFly controller {fault_type} faults",
  "View Controller Reports": "View Controller Reports",
  "View FireFly Status": "View FireFly Status",
  "View External Source Events": "View External Source Events",

  // Backup and Restore
  "Click to restore the selected backup archive":
    "Click to restore the selected backup archive.",
  Restore: "Restore",
  "Click to create a backup": "Click to create a backup",
  "Click to upload a file": "Click to upload a file",
  Upload: "Upload",
  "Restore Warning Message Header":
    "Administrator Use Only - Restore will perform a system restart",
  "Restore Warning Message Content":
    "Select the Restore button to restore the system database and area images from the archive. Users will be logged out until after the system has been restarted.",
  "File Name": "File Name",
  "File Date": "File Date",
  "File Size": "File Size",
  File: "File",
  Files: "Files",
  Backup: "Backup",
  BACKUP_LIST_UPDATE_POP_MSG: "Update page data",

  // error messages
  "Data Loading Message Header": "Loading",
  "Data Loading Message Content":
    "Loading data. Please wait. If there is a very long delay data may not exist for this Area.",
  "Data Loading Receiving": "Receiving data...",
  // 404
  "Page not found": "Page not found",
  "No match for": "No match for",

  // Tag Tracking
  Tag: "Tag",
  Tags: "Tags",
  "Tag Tracking": "Tag Tracking",
  "Tag Map": "Tag Map",
  "Tags In Zones": "Tags In Zones",
  "Tag Zone": "Tag Zone",
  "Tag Zones": "Tag Zones",
  "Tags for": "Tags for",
  "Assigned Tags": "Assigned Tags",
  Personnel: "Personnel",
  Vehicle: "Vehicle",
  Vehicles: "Vehicles",
  "No Tag Information": "No Tag Information",
  "Tag information is not available. Please reload the page or check the server settings.":
    "Tag information is not available. Please reload the page or check the server settings.",

  "Tag User": "Tag User",
  Type: "Type",
  Types: "Types",
  Zones: "Zones",
  Zone: "Zone",
  RSSI: "RSSI",
  Unassign: "Unassign",
  "Tag Zone List Message Header": "No Tag Zones",
  "Tag Zone List Message Content":
    "Tag Zones are not found for this Area. Check zones or wait for data to load.",
  TAG_ZONE_LIST_UPDATE_POP_MSG: "Fetch new Tag info",
  "Click Update to fetch data.": "Click Update to fetch data.",

  // Help and Doc pages
  Documents: "Documents",

  Help: "Help",
  "Help Files": "Help Files",
  "Help Files Header": "",
  "Help Files Message":
    "This area contains an assortment of help files and tips which have been prepared to assist you with Lighting Control, Controllers and  the FireFly lighting control system in general. Click to open the  files in a separate window.",
  "No Files": "Sorry, there are no files available at the moment.",

  // Controller pages
  Header_Controller_BulkPosition: "Controller Status",
  Controller: "Controller",
  "Controller URL": "Controller URL",
  Area: "Area",
  Location: "Location",
  Master: "Master",
  Version: "Version",
  "DC Supply Voltage": "DC Supply Voltage",
  "Battery Voltage": "Battery Voltage",
  "Disk Used": "Disk Used",
  "Memory Used": "Memory Used",
  "User Relay": "User Relay",
  "Latest Report": "Latest Report",
  "Controller Config": "Controller Config",
  "Controller ID": "Controller ID",
  "Controller Address": "Controller Address",
  "Controller Mode": "Controller Mode",
  "Controller Location": "Controller Location",
  Ports: "Ports",
  "Enable Emergency Events": "Enable Emergency Events",
  "Enable Trigger Events": "Enable Trigger Events",
  "Controller Port Message Header": "No Port Information",
  "Controller Port Message Content":
    "Port information is not available. Please reload the page or check the controller settings. Check FireFlys are connected to the controller.",
  "Controller Area Message Header": "Unknown Area",
  "Controller Area Message Content":
    'Area "areaError_id" is undefined. Please check the controller settings to configure the Area.',

  // FireFly pages
  Position: "Position",
  MAC: "MAC",
  "MAC Address": "MAC Address",
  "SW Version": "SW Version",
  Note: "Note",
  FireFly_Note_Placeholder:
    "...enter notes. This text displays on the FireFly marker popup and in the FireFly list.",
  "FireFly ID": "FireFly ID",
  "FireFly Location": "FireFly Location",
  "FireFly Position": "FireFly Position",
  "FireFly MAC Address": "FireFly MAC Address",
  Controller: "Controller",
  "Controller Name": "Controller Name",
  "Controller MAC": "Controller MAC",
  "Controller Config Address": "Controller Config Address",
  // Forced
  "Forced FireFly": "Forced FireFly",
  "Forced FireFlys": "Forced FireFlys",

  // Polygon pages / namedarea pages
  Polygon: "Polygon",
  Polygons: "Polygons",
  "Add Polygon": "Add Polygon",
  "Delete Polygon": "Delete Polygon",
  "Polygon Active Event Message Header": "Active Lights",
  "Polygon Active Event Message Content":
    "This polygon has an active event. Changes to this polygon will change the state of lights.",
  "Polygon Info Message Header": "Use the map controls to add a new polygon",
  "Polygon Info Message Content":
    "Click on the map's Polygon or Rectangle icon to draw a new polygon shape. A shape must be drawn to be able to save the polygon settings.",
  "Set the color of the {polygon_type} region":
    "Set the color of the {polygon_type} region",
  Styling: "Styling",
  "{polygon_type} Name": "{polygon_type} Name",
  "Enter the name of the {polygon_type}":
    "Enter the name of the {polygon_type}",
  NAMED_AREA_SIMPLE_LIST_UPDATE_POP_MSG: "Update page data",

  // NamedAreaGroup pages

  "No Lighting Control Layout": "No Lighting Control Layout",
  NO_LIGHTING_CONTROL_LAYOUT_MESSAGE:
    "There is no Lighting Control layout defined for this Area. Check Smart Lighting > Lighting Control Layouts",

  "Lighting Control": "Lighting Control",
  "Lighting Control Layouts": "Lighting Control Layouts",
  "Control Layout for Area": "Control Layout for Area",

  Groups: "Groups",
  Group: "Group",
  "Add Group": "Add Group",
  "No Groups. Add a group.": "No Groups. Add a group.",
  "No Levels. Add a level.": "No Levels. Add a level.",
  "No Polygons. Add a polygon.": "No Polygons. Add a polygon.",
  Levels: "Levels",
  Level: "Level",
  "Add Level": "Add Level",
  "Control Layout": "Control Layout",
  "Edit Settings": "Edit Settings",
  Label: "Label",
  "Custom Button Label": "Custom Button Label",
  "Custom label for buttons": "Custom label for buttons",
  "Polygon ID": "Polygon ID",
  "Polygon Unique ID": "Polygon Unique ID",
  "Edit Group settings": "Edit Group settings",
  "Edit Level settings": "Edit Level settings",
  "Edit Polygon settings": "Edit Polygon settings",
  "Delete Group": "Delete Group",
  "Delete Level": "Delete Level",
  "Drag and drop Polygons here. Configure light states after adding Polygons.":
    "Drag and drop Polygons here. Configure light states after adding Polygons.",

  "Drag Group to reorder": "Drag Group to reorder",
  "Drag Level to reorder": "Drag Level to reorder",
  "Drag Polygon to reorder": "Drag Polygon to reorder",
  "Drag Polygon to Level": "Drag Polygon to Level",
  "Click to expand/collapse the Group": "Click to expand/collapse the Group",
  "Click to expand/collapse the Level": "Click to expand/collapse the Level",
  "Click to expand/collapse all Groups": "Click to expand/collapse all Groups",

  Hint: "Hint",
  "Active Color of Lights": "Active Color of Lights",
  "Active Brightness Level": "Active Brightness Level",
  "Display Marker Icon": "Display Marker Icon",
  "Active State of Lights": "Active State of Lights",
  "Off Time": "Off Time",
  "On Time": "On Time",
  "Train Time": "Train Time",
  "About Active State Forward and Backward":
    "About Active State Forward and Backward",
  Warning_FORWARD_BACKWARD:
    "Forward and Backward creates a light 'chaser' effect over the FireFly lights per location (i.e. Controller port). The effect does not work across FireFlys on different ports.",
  "Relay Settings": "Relay Settings",
  Siren: "Siren",
  "Button Permissions": "Button Permissions",
  "Bit register of button permissions e.g. 1":
    "Bit register of button permissions e.g. 1",
  "Button Permissions Register": "Button Permissions Register",
  "Access Settings": "Access Settings",
  User: "User",
  Permission: "Permission",
  Show: "Show",
  Control: "Control",
  ACCESS_MESSAGE:
    "Restrict access to Operators. Default settings show all groups to all Operators.",
  "Click to delete row": "Click to delete row",
  "Click to toggle Show/Hide": "Click to toggle Show/Hide",
  "Click to toggle Control enable/disable":
    "Click to toggle Control enable/disable",

  // External trigger pages
  "External Trigger": "External Trigger",
  "External Triggers": "External Triggers",
  "External Trigger Status": "External Trigger Status",
  "External Trigger Event": "External Trigger Event",
  "External Trigger Events": "External Trigger Events",
  "Event Log": "Event Log",
  "External Trigger ID": "External Trigger ID",

  Active: "Active",
  ACTIVE: "ACTIVE",
  Inactive: "Inactive",
  INACTIVE: "INACTIVE",
  Source: "Source",
  "Event Source": "Event Source",
  "Scheduled Job": "Scheduled Job",
  Job: "Job",
  Reason: "Reason",
  Disabled: "Disabled",
  "Not Applicable": "Not Applicable",
  "Not applicable": "Not Applicable",
  "Polygon Coords": "Polygon Coords",
  "No event sources enabled": "No event sources enabled",
  "Allowed Event Source": "Allowed Event Source",
  EXT_TRIGGER_EVENT_LIST_UPDATE_POP_MSG: "Update page data",

  // event Log pages

  Operator: "Operator",
  Origin: "Origin",
  Time: "Time",
  Event: "Event",
  "Event/Shape ID": "Event/Shape ID",
  Priority: "Priority",
  "Active Color": "Active Color",
  "Active State": "Active State",
  "State Info ( On | Off | Train)": "State Info ( On | Off | Train)",
  "Range (Start -> End)": "Range (Start -> End)",
  "Relay Event Active": "Relay Event Active",
  Shape: "Shape",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  EVENT_LOG_LIST_UPDATE_POP_MSG: "Update page data",

  // Misc selector options
  green: "Green",
  red: "Red",
  blue: "Blue",
  amber: "Amber",
  white: "White",
  off: "Off",
  on: "On",

  strobe: "Strobe",
  forward: "Forward",
  backward: "Backward",
  slow: "slow",
  fast: "fast",

  plus: "Plus",
  cross: "Cross",
  circle: "Circle",
  square: "Square",
  diamond: "Diamond",
  travelway: "Travelway",
  star: "Star",
  hourglass: "Hour Glass",
  bowtie: "Bow Tie",
  triangle: "Triangle",

  // Scheduled event pages

  "Scheduled Jobs": "Scheduled Jobs",
  "Scheduled Job": "Scheduled Job",
  "Add Scheduled Job": "Add Scheduled Job",
  "Edit Scheduled Job": "Edit Scheduled Job",
  "Scheduled Events": "Scheduled Events",
  "Scheduled Event": "Scheduled Event",
  "Scheduled Lighting Event": "Scheduled Lighting Event",
  "Schedule Event Job Name": "Schedule Event Job Name",
  "Enter the name of the Scheduled Event Job":
    "Enter the name of the Scheduled Event Job",
  "Disable Job": "Disable Job",
  "Add a Polygon": "Add a Polygon",
  "Choose Polygons": "Choose Polygons",
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  QUEUED: "QUEUED",
  RUNNING: "RUNNING",
  Start: "Start",
  Date: "Date",
  Now: "Now",
  Never: "Never",
  Duration: "Duration",
  all: "all",
  every: "every",
  Month: "Month",
  Months: "Months",
  month: "month",
  months: "months",
  "any day": "any day",
  Day: "Day",
  Days: "Days",
  day: "day",
  days: "days",
  DAY_WEEK: "Day of the Week",
  hour: "hour",
  hours: "hours",
  Hour: "Hour",
  Hours: "Hours",
  minute: "minute",
  minutes: "minutes",
  Minute: "Minute",
  Minutes: "Minutes",
  second: "second",
  seconds: "seconds",
  Stop: "Stop",

  timezone: "Timezone",
  "Select Date": "Select Date",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  week: "Week",
  year: "Year",
  date: "Date",
  "any weekday": "any weekday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  "Show All": "Show All",
  Today: "Today",
  Yesterday: "Yesterday",
  "24 Hours": "24 Hours",
  "7 Days": "7 Days",
  "30 Days": "30 Days",
  All: "All",
  CRON: "CRON",
  "CRON Schedule Expression": "CRON Schedule Expression",
  "Start CRON Expression": "Start CRON Expression",
  "Stop CRON Expression": "Stop CRON Expression",
  "CRON Expression": "CRON Expression",
  "Scheduled Event Settings": "Scheduled Event Settings",
  "CRON Wizard": "CRON Wizard",
  Wizard: "Wizard",
  Duration: "Duration",
  "Duration Units": "Duration Units",
  "Bit register of CRON Speci e.g. 1": "Bit register of CRON Speci e.g. 1",
  Sched_Event_Note_Placeholder: "...enter notes. Recorded in the Event Log.",
  "Lighting Event": "Lighting Event",
  Activate: "Activate",
  Deactivate: "Deactivate",
  Schedule: "Schedule",
  "System Message": "System Message",
  FUTURE_FEATURE: "Future feature",
  JOB_DAY_SELECTOR_POP_MSG: "Day of the month - one day or periodic",
  JOB_MONTH_SELECTOR_POP_MSG: "Month - one month or periodic",
  JOB_DAYOFWEEK_SELECTOR_POP_MSG: "Day of the week",
  Created: "Created",
  "Click to add selected Polygon": "Click to add selected Polygon",
  "Select Group": "Select Group",
  "Select Level": "Select Level",
  "Select Polygon": "Select Polygon",
  "Choose an Area": "Choose an Area",
  "Choose a Group": "Choose a Group",
  "Choose a Level": "Choose a Level",
  "No Groups defined": "No Groups defined",
  "No Levels defined": "No Levels defined",
  "No Polygons defined": "No Polygons defined",
  SCHEDULED_JOBS_LIST_UPDATE_POP_MSG: "Update page data",
  SCHEDULED_EVENTS_LIST_UPDATE_POP_MSG: "Update page data",

  // User pages
  Name: "Name",
  Role: "Role",
  "User Group": "User Group",
  Language: "Language",
  Application: "Application",
  "Users Details": "Users Details",
  "User Name": "User Name",
  "Email Address": "Email Address",
  "User's Role": "User's Role",
  "Change Password": "Change Password",
  "New Password": "New Password",
  "Enter a new user password": "Enter a new user password",
  "Confirm Password": "Confirm Password",
  "Reenter your new password to confirm":
    "Reenter your new password to confirm",

  "Create New User": "Create New User",

  "Super User - Administrator + administer users":
    "Super User - Administrator + administer users",
  "Administrator - Operator + administer areas and devices":
    "Administrator - Operator + administer areas and devices",
  "Operator - All general operations. Trigger events etc.":
    "Operator - All general operations. Trigger events etc.",
  "Guest - View area maps and light status":
    "Guest - View area maps and light status",

  // Settings
  Settings: "Settings",
  Display: "Display",
  Features: "Features",
  Options: "Options",
  "Show Lighting Control Layout": "Show Lighting Control Layout",
  "Show hints on Polygon buttons": "Show hints on Polygon buttons",
  Compressed: "Compressed",
  Normal: "Normal",
  Expanded: "Expanded",
  "Control Size": "Control Size",
  "Column Width": "Column Width",
  smartLighting: "Smart Lighting",
  tagTracking: "Tag Tracking",
  "No applications enabled": "No applications enabled",
  Applications: "Applications",
  "No features enabled": "No features enabled",
  showDemoMode: "Demo Mode",
  showDevelopmentVersion: "Development Mode",
  showExternalTriggerEvent:
    "Show External Trigger Event options (requires Smart Lighting)",
  showScheduledEvent: "Show Scheduled Job options (requires Smart Lighting)",
  showUserRelay:
    "Show User Relay options (e.g. Siren) (requires Smart Lighting)",
  showBackupRestore: "Show Backup and Restore options",

  // Log page
  "System Log": "System Log",
  "Browser Log": "Browser Log",
  Logged: "Logged",

  // Fault log
  "Fault Log": "Fault Log",

  // Message banner messages
  "Requested by": "Initiated by",
  "ERROR: Service Fault": "ERROR: Service Fault",
  "Service reports": "Service reports",
  NOW: "NOW!",
  "Click to dismiss message": "(Click to dismiss this message)",
  PROCESS_RESTORE_REQUESTED_MSG: "System Restore Requested",
  PROCESS_RESTORE_REQUESTED_MSG_CONTENT:
    "A system restore has been requested. Within 1 minute all users will be logged off and the system reset and restored.",
  PROCESS_RESTORE_SUCCEEDED_MSG: "System Restore Succeeded",
  PROCESS_RESTORE_SUCCEEDED_MSG_CONTENT: "The system restore was successful",
  PROCESS_RESTORE_FAILED_MSG: "System Restore Failed",
  PROCESS_RESTORE_FAILED_MSG_CONTENT: "System restore failed",
  //
  PROCESS_RESTORE_LOGOUT_MSG: "System Restore Logout",
  PROCESS_RESTORE_LOGOUT_MSG_CONTENT: "Logged out all users.",
  PROCESS_RESTORE_LOGOUT_WARNING_MSG: "System Restore Underway",
  PROCESS_RESTORE_LOGOUT_WARNING_MSG_CONTENT:
    "All users will be logged out and the system will restart in ",

  PROCESS_RESTORE_FAILED_MSG_FILE_CHECKS:
    "System restore process failed file checks.",

  PROCESS_RESTORE_FAILED_MSG_SQL_SCHEMA_TMP: "tmp_db SQL schema dump failed",
  PROCESS_RESTORE_FAILED_MSG_SQL_SCHEMA_IOT:
    "iot_server SQL schema dump failed",
  PROCESS_RESTORE_FAILED_MSG_SQL_SCHEMA_COMPARE: "Schema comparison failed.",
  PROCESS_RESTORE_FAILED_MSG_SQL_SCHEMA_COMPARE_FILES:
    "Schema comparison files missing.",

  //
  PROCESS_BACKUP_REQUESTED_MSG: "System Backup Requested",
  PROCESS_BACKUP_REQUESTED_MSG_CONTENT: "A system backup has been requested",
  PROCESS_BACKUP_SUCCEEDED_MSG: "System Backup Succeeded",
  PROCESS_BACKUP_SUCCEEDED_MSG_CONTENT: "The system backup is complete",

  PROCESS_BACKUP_FAILED_MSG: "System Backup Failed",
  PROCESS_BACKUP_FAILED_MSG_CONTENT:
    "System Backup process failed. Check logs for errors.",
  PROCESS_BACKUP_FAILED_MSG_NO_FILE: "PROCESS_BACKUP_FAILED_MSG_NO_FILE",
  PROCESS_BACKUP_FAILED_MSG_SQL_DUMP_ERROR:
    "PROCESS_BACKUP_FAILED_MSG_SQL_DUMP_ERROR",
  PROCESS_BACKUP_FAILED_MSG_ZIP_COMPRESS_FAIL:
    "PROCESS_BACKUP_FAILED_MSG_ZIP_COMPRESS_FAIL",

  //
  //
};

// Default english terms.
// Key is the reference key, value is the displayed text.
export let strings = new LocalizedStrings({
  "en-US": { ...defaultStrings },
});
