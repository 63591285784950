import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import isEqual from "lodash/isEqual";

import {
  getAllAreaStatuses,
  getAllNamedAreaEvents,
  getButtonPrioritysInNamedAreaGroups,
  getNamedAreaEventButtonGroupState,
} from "components/WebWorker/reducer";

import {
  getPrevNamedAreaGroups,
  getPrevNamedAreaGroupButtonStates,
} from "NamedAreaGroups/reducer";

import {
  activateNamedAreaEvent,
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
  updateButtonGroupState,
} from "OperationalChanges/actions";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import _isEmpty from "lodash/isEmpty";
import { area } from "@turf/turf";

/*

If the Lighting Control layout is changed, e.g. through add, delete, move of namedAreas, Levels or Groups, 
there may be some events which have not been impacted. 

restorePrevActiveEvents() attempts to restore active events where possible.

When the namedAreaGroup changes are saved middleware (see - src/NamedAreaGroups/middleware.js) intercepts 
the 'UPDATE_NAMED_AREA_GROUP' message and saves a pre-save state of the namedAreaGroup and active events.

After successful updates to the namedAreaGroup the active events are cleared.
See - src/admin/named-area-group/NamedAreaGroupEditPage.js > EditNamedAreaGroup > submitMyForm > then() callback > MQTT > "system/clear/event"

The user is then prompted with the option to 
#1 - continue on with the system cleared of events -> continue
#2 - attempt to restore previously active events -> run restorePrevActiveEvents()

see - src/admin/named-area-group/NamedAreaGroupPostSaveOptionsModal.js

Why offer a choice?

If the users have substantially changed the Lighting Control layout it may not be necessary to restore events.
For example if all the current groups and levels are deleted and the layout is rebuilt, then any current events 
are unlikely to be relevant.

*/

const _restorePrevActiveEvents = (areaId, { ...props }) => {
  const {
    namedAreaEvents,
    namedAreaGroupButtons,
    updateButtonGroupState,
    buttonGroupStates,
    activateNamedAreaEvent,
    prevNamedAreaGroups,
    prevNamedAreaGroupButtonStates,
  } = props;

  // console.log(
  //   `report UPDATE_NAMED_AREA_GROUP  ---------xxxxxxxxx-------------`,
  //   areas,
  //   namedAreaEvents,
  //   namedAreaGroupButtons
  // );

  //console.clear();
  // console.log(`report UPDATE_NAMED_AREA_GROUP ---------${areaId}-------------`);
  // console.log(
  //   `report UPDATE_NAMED_AREA_GROUP  ---------namedAreaEventsByAreaId-------------`,
  //   namedAreaEvents
  // );

  // fitler for test_area, active
  const activeAllNamedAreaEvents = namedAreaEvents
    .filter((event) => event.active && event.area === areaId)
    .map((event) => {
      const { id, priority } = event;
      return { id, priority };
    });

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP sortFilteredAllNamedAreaEvents",
  //   sortActiveAllNamedAreaEvents
  // );

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtons",
  //   namedAreaGroupButtons
  // );

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP activeAllNamedAreaEvents",
  //   activeAllNamedAreaEvents
  // );

  let namedAreaGroupButtonStates = [];
  namedAreaGroupButtons[areaId].forEach((button) => {
    const {
      id: buttonId,
      priority: buttonPriority,
      event: buttonEvent,
      level,
      group,
    } = button;

    const isActiveEvent = activeAllNamedAreaEvents.some(
      (event) =>
        event.id.includes(buttonId) && event.priority === buttonPriority
    );

    namedAreaGroupButtonStates.push({
      id: buttonId,
      priority: buttonPriority,
      active: isActiveEvent,
      buttonEvent: buttonEvent,
      level,
      group,
    });
  });

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP >>>>>>> namedAreaGroupButtonStates",
  //   namedAreaGroupButtonStates
  // );

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP >>>>>>> prevNamedAreaGroupButtonStates",
  //   prevNamedAreaGroupButtonStates
  // );

  // ------------------------------
  // restore to previous state
  // ------------------------------

  //
  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP buttonGroupState",
  //   buttonGroupStates
  // );

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates -------------------------"
  // );

  let newButtonGroupState = { ...buttonGroupStates };
  let newActiveEventList = [];

  namedAreaGroupButtonStates.forEach((button, index) => {
    // console.log(
    //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates namedAreaGroupButtonState",
    //   button
    // );

    const { id, buttonEvent, level, group, priority } = button;

    // search for previous events with the same id on the same level and same group
    const prevNamedAreaGroupButtonState = prevNamedAreaGroupButtonStates?.[
      areaId
    ]?.filter(
      (button) =>
        button.id === id && button.level === level && button.group === group
    );
    // if find more than one event determine which, if any, to restore to previous state
    //

    // console.log(
    //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates prevNamedAreaGroupButtonState",
    //   prevNamedAreaGroupButtonState
    // );

    // initially lets deal with first/top .............
    //
    // #NOTE - since the events are stepped through from highest priority first,
    // the next highest priority event will be set active.
    //
    // #REVIEW
    // - set the lowest active?
    // - only set if in same group?
    // - only set if on same level?
    //
    //
    // default to restore the highest priority button - typically only one, and we choose the first
    let restoreToButton = prevNamedAreaGroupButtonState?.[0]; // #NOTE - returns 1st array result which *should* have highest priority
    // however  if there are multiple buttons decide on which to restore.
    if (prevNamedAreaGroupButtonState?.length > 1) {
      // If one is/was active, restore it
      const activeButton = prevNamedAreaGroupButtonState.filter(
        (button) => button.active
      );
      // console.log(
      //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates restoreToButton activeButton ",
      //   activeButton
      // );

      if (_isEmpty(activeButton)) {
        restoreToButton = activeButton;
      }
    }

    // if a previous event was found and event was active
    if (restoreToButton && restoreToButton?.active) {
      //
      // and if the event has not already been set here (only one event per polygon)
      if (!newActiveEventList.includes(id)) {
        // console.log(
        //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates restoreToButton ####### ---> ",
        //   restoreToButton,
        //   "buttonEvent ",
        //   buttonEvent
        // );
        // console.log(
        //   "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates restoreToButton ####### newButtonGroupState ---> ",
        //   newButtonGroupState
        // );

        newButtonGroupState[id] = { 0: priority };
        updateButtonGroupState(newButtonGroupState);
        activateNamedAreaEvent(buttonEvent);
        newActiveEventList.push(id);
      }
    }
  });

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP prevNamedAreaGroups",
  //   prevNamedAreaGroups
  // );

  // console.log(
  //   "report UPDATE_NAMED_AREA_GROUP prevNamedAreaGroups ---------------------"
  // );

  return null;
};

class NamedAreaGroupPostSaveOptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      restoreButton: { disabled: true, label: "Please wait..." },
    };
  }

  componentDidMount() {
    const { open } = this.props;

    if (open) {
      this.handleOpen();
    }

    // console.log(
    //   "UPDATE_NAMED_AREA_GROUP TurnOnOffPokeTheWorker ON !!!!!!!!!!!!!"
    // );
  }

  componentWillUnmount() {
    // turn off data collection
    const { isPokeTheWorker } = this.props;
    if (isPokeTheWorker) {
      this.props.TurnOnOffPokeTheWorker(false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    // after the modal opens
    // enable data collection
    // Note - unmount disables again
    const { isPokeTheWorker } = this.props;
    if (!isPokeTheWorker) {
      this.props.TurnOnOffPokeTheWorker(true);
    }

    // set a timer so that the restore lights button can not be
    // activated until all the previous lighting resets have propogated
    // around the system
    //
    setTimeout(() => {
      this.setState({
        restoreButton: { disabled: false, label: "" },
      });
    }, 4000); // wait for a timeout before making button active

    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    this.props.onResetError();
  };

  handleRestore = () => {
    // restore the lights --> FOR ALL AREAS!!!!!!!!!!!

    const { areas = [] } = this.props;

    areas.forEach((area) => {
      _restorePrevActiveEvents(area.id, this.props);
    });
    this.setState({ modalOpen: false });
    this.props.onResetError();
  };

  render() {
    // console.log(
    //   "UPDATE_NAMED_AREA_GROUP --------------- this.props",
    //   this.props
    // );

    return (
      <>
        <Modal open={this.state.modalOpen} onClose={this.handleClose}>
          <Header icon="question" content="Restore Lights?" />
          <Modal.Content>
            <p>
              Changes to the Lighting Control layout resets all lights,{" "}
              <strong>in all Areas</strong>, to the default state. Do you want
              to restore the lights to their previous states?{" "}
              <strong>{this.state.restoreButton.label}</strong>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={this.state.restoreButton.disabled}
              loading={this.state.restoreButton.disabled}
              color={"orange"}
              onClick={this.handleRestore}
            >
              Yes, restore the lights
            </Button>
            <Button color={"blue"} onClick={this.handleClose}>
              No, reset all Areas to default
            </Button>
            {/* <Button color={"green"} onClick={this.handleClose}>
            Leave, but also Save the changes
          </Button> */}
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    areas: getAllAreaStatuses(state),
    // namedAreaEventsByAreaId: getNamedAreaEventsByAreaId(state, areaId),
    namedAreaEvents: getAllNamedAreaEvents(state),
    // namedAreaGroupButtons: getButtonPrioritysInNamedAreaGroupByAreaId(
    //   state,
    //   areaId
    // ),
    namedAreaGroupButtons: getButtonPrioritysInNamedAreaGroups(state),
    namedAreaEventButtonGroupState: getNamedAreaEventButtonGroupState(state),
    buttonGroupStates: getNamedAreaEventButtonGroupState(state),
    prevNamedAreaGroups: getPrevNamedAreaGroups(state),
    prevNamedAreaGroupButtonStates: getPrevNamedAreaGroupButtonStates(state),
    isPokeTheWorker: isOnOffPokeTheWorker(state),
  };
};

export default connect(mapStateToProps, {
  //
  activateNamedAreaEvent,
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
  updateButtonGroupState,
  TurnOnOffPokeTheWorker,
})(NamedAreaGroupPostSaveOptionsModal);
