// change colors to web colors
export function toFireflyColor(color) {
  switch (color) {
    case "green":
      return "green";
    case "blue":
      return "blue";
    case "amber":
    case "orange":
      return "amber";
    // e.g. forced light modal displays the 'white' color as grey b/c can't do white on white bg.
    // Also white on white for the Lighting Control buttons.
    // So convert back to white for change command
    case "grey":
    case "white":
      return "white";
    case "red":
      return "red";
    default:
      return "green";
  }
}
