import Immutable from "seamless-immutable";
import isEmpty from "lodash/isEmpty";

const initialState = Immutable({
  eventLog: {},
});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "EVENT_LOG_FETCH_SUCCEEDED":
    case "EVENT_LOG_PERIOD_FETCH_SUCCEEDED":
      const { list } = payload;

      // message is saved as a string.
      const newList = list.map((item) => {
        return {
          ...JSON.parse(item?.message),
          ts_string: item?.timestring,
          ts_sec: item?.timestamp_sec,
          ts_msec: item?.timestamp_msec,
        };
      });

      newState.eventLog = newList;
      return newState;

    case "RESET":
      console.log("RESET! - Event Log");
      return state;
    default:
      return state;
  }
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}

const allEventLog = (state) => {
  const byId = state.eventLog.eventLog;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getEventLog = (state) => allEventLog(state);

// export const old_getEventLog = (state) => {
//   const eventLog = allEventLog(state);

//   let newEventLog = [];

//   eventLog.forEach((e) => {
//     newEventLog.push({
//       active: e.active,
//       area: e.area,
//       groupLabel: e.group_label,
//       levelLabel: e.level_label,
//       buttonLabel: e.button_label,
//       buttonCustomLabel: e.button_custom_label,
//       parent: e.parent,
//       priority: e.priority,
//       permission: e.permission,
//       reason: e.reason,
//       origin: e.origin,
//       operator: e.operator,
//       secureKey: e.secure_key,
//       timestamp: e.ts,
//     });
//   });

//   return newEventLog;
// };
