import React from "react";

import { Form, Grid, Image, Segment, Header } from "semantic-ui-react";
import { Field } from "redux-form";
import { renderField } from "admin/form-field";
import { Link } from "react-router-dom";

// do not check for 0 as falsy
const required = (value) => (value !== "" ? undefined : "Required");
const validate = (values) => {
  const errors = {};
  return errors;
};

export const tagTrackingUserDetailsCardUnknown = (
  urlImage,
  tagId,
  mac,
  disabled
) => {
  // if the card is 'disabled' it has been 'assigned'
  const cardTitle = disabled ? (
    <Link to={`/status/tag/${tagId}`}>{`Unassigned Tag`} </Link>
  ) : (
    "Details"
  );

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">{cardTitle}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Image src={urlImage} size="small" />
          </Grid.Column>
          <Grid.Column width={10}>
            <Field
              name="mac"
              label="Tag MAC"
              placeholder=""
              component={renderField}
              className={disabled ? "disabled-form-field" : null}
              disabled={disabled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
