// #NOTE
// This component uses leaflet styling
// to be consistent with the layer button on the map
//

import React from "react";
import { connect } from "react-redux";
import "pages/ControlRoomPage/NamedAreaDisplaySettings/NamedAreaDisplaySettings.css";
import { UpdateNamedAreaDisplaySettings } from "components/Settings/actions";
import { getNamedAreaDisplaySettings } from "components/Settings/reducer";
import SettingsModal from "pages/ControlRoomPage/NamedAreaDisplaySettings/NamedAreaDisplaySettingsModal";
import { Button, Icon, Popup } from "semantic-ui-react";

class NamedAreaDisplaySettings extends React.Component {
  state = {
    isOpenSettingsModal: false,
    hint: true,
    legend: true,
    view: "normal",
    columnWidth: 6,
    sopInterface: true,
  };

  componentDidMount() {
    const { hint, legend, view, columnWidth, sopInterface } =
      this.props.namedAreaDisplaySettings;

    this.setState({ hint: hint });
    this.setState({ legend: legend });
    this.setState({ view: view });
    this.setState({ columnWidth: columnWidth });
    this.setState({ sopInterface: sopInterface });
  }

  componentDidUpdate(prevProps, prevState) {
    const { hint, legend, view, columnWidth, sopInterface } = this.state;
    const displaySettings = { hint, legend, view, columnWidth, sopInterface };

    const {
      hint: prevHint,
      legend: prevLegend,
      view: prevView,
      columnWidth: prevColumnWidth,
      sopInterface: prevSopInterface,
    } = prevState;

    const prevDisplaySettings = {
      hint: prevHint,
      legend: prevLegend,
      view: prevView,
      columnWidth: prevColumnWidth,
      sopInterface: prevSopInterface,
    };

    if (
      JSON.stringify(displaySettings) !== JSON.stringify(prevDisplaySettings)
    ) {
      this.props.UpdateNamedAreaDisplaySettings(displaySettings);
    }
  }

  showOptions = (value) => {
    this.setState({ isOpenSettingsModal: value });
  };

  handleSettingsSave = (value) => {
    const { hint, legend, view, columnWidth, sopInterface } = value;
    this.setState({ hint: hint });
    this.setState({ legend: legend });
    this.setState({ view: view });
    this.setState({ columnWidth: columnWidth });
    this.setState({ sopInterface: sopInterface });

    // close the modal and display the cog again...
    this.setState({ isOpenSettingsModal: false });
  };

  render() {
    // #NOTE - Main nav config option test format version.
    // This is development code and has never been used.
    if (false) {
      return (
        <div className="leaflet-top leaflet-right">
          <div
            className={`leaflet-control-layers leaflet-control`}
            aria-haspopup="true"
          >
            {!this.state.isOpenSettingsModal && (
              <div
                className="options-icon  leaflet-control-layers-toggle"
                title="Settings"
                onClick={() => this.showOptions(true)}
              ></div>
            )}
            {this.state.isOpenSettingsModal && (
              <SettingsModal
                open={this.state.isOpenSettingsModal}
                initialValues={{
                  legend: this.state.legend,
                  hint: this.state.hint,
                  view: this.state.view,
                  columnWidth: this.state.columnWidth,
                  sopInterface: this.state.sopInterface,
                }}
                handleSettingsSave={(values) => this.handleSettingsSave(values)}
                resetModal={() => this.showOptions(false)}
              />
            )}
          </div>
        </div>
      );
    }

    const buttonSize = this.props?.size || "large";

    // #NOTE - Main nav config option has been superceded in favour of per User settings in profile
    // Left here JIC things change again.
    if (true) {
      return (
        <>
          <Popup
            content={"Change interface display settings"}
            trigger={
              <Button
                icon
                size={buttonSize} // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
                onClick={() => this.showOptions(true)}
              >
                <Icon name="cog"></Icon>
              </Button>
            }
          />
          {this.state.isOpenSettingsModal && (
            <SettingsModal
              open={this.state.isOpenSettingsModal}
              initialValues={{
                legend: this.state.legend,
                hint: this.state.hint,
                view: this.state.view,
                columnWidth: this.state.columnWidth,
                sopInterface: this.state.sopInterface,
              }}
              handleSettingsSave={(values) => this.handleSettingsSave(values)}
              resetModal={() => this.showOptions(false)}
            />
          )}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const namedAreaDisplaySettings = getNamedAreaDisplaySettings(state);

  return {
    namedAreaDisplaySettings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  UpdateNamedAreaDisplaySettings: (options) => {
    dispatch(UpdateNamedAreaDisplaySettings(options));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NamedAreaDisplaySettings);
