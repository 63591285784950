import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Form,
  Container,
  Grid,
  Segment,
  Button,
  Header,
  Message,
  Icon,
  TextArea,
  Popup,
  Image,
  Table,
} from "semantic-ui-react";

import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging
import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { tagTrackingUserDetailsCardPersonnel } from "admin/tag-tracking/TagTrackingUserDetailsCardPersonnel";
import { tagTrackingUserDetailsCardVehicle } from "admin/tag-tracking/TagTrackingUserDetailsCardVehicle";

const validate = (values) => {
  const { mineLevelId, areaName, regions } = values;

  const errors = {};

  if (!mineLevelId) {
    errors.mineLevelId = "Required";
  }
  if (!areaName) {
    errors.areaName = "Required";
  }

  if (_isEmpty(regions)) {
    errors.regions = "Required";
  }

  return errors;
};

const normalise = (value, type) => {
  if (type === "noSpaces") {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

const required = (value) => (value ? undefined : "Required");
const isTagUserNameOK = (value, allValues, props, name) => {
  const { id } = allValues;

  const { uniqueNamedAreaParentIds } = props;

  const valueNoSpace = value.replace(" ", "_");

  const notIncludingThisId = uniqueNamedAreaParentIds
    .filter((item) => !item.id.includes(id))
    .map((item) => item.parentName);

  if (notIncludingThisId?.includes(valueNoSpace)) {
    return "This name has already been used";
  } else {
    return undefined;
  }
};

class TagTrackingUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importSvg: "",
      collapse: false,
      errorMessage: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    //
  }

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  resetForm = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // https://github.com/Asymmetrik/ngx-leaflet/issues/88
    // unmount error - "TypeError: Cannot read property 'off' of undefined"

    // abort page changes
    //this.props.reset();
    this.props.onCancel();
  };

  submitMyForm = (values) => {
    //console.log(`form submitted: values`, values);

    this.props.onSubmit(values);
  };

  requestDelete = (id) => {
    const { change, tagUserType } = this.props;

    //console.log(`requestDelete`, id, tagUserType);

    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete");
    this.props.onDelete({ id, tagUserType });
  };

  render() {
    const {
      header,
      handleSubmit,
      pristine,
      submitting,
      error,
      //mineLevelIdValue,
      initialValues,
      //regions,
      //isActivity,
      tagUserType,
      id,
      formType,
      //formValues,
      strings,
    } = this.props;

    // APP_TERMINOLOGY
    let strTagUser = "User";
    let strTagUsers = "Users";

    switch (tagUserType) {
      case "personnel":
        strTagUser = "Personnel";
        strTagUsers = "Personnel";
        break;
      case "vehicle":
        strTagUser = "Vehicle";
        strTagUsers = "Vehicles";
        break;
      case null:
      default:
        break;
    }

    const areaNameChecks =
      formType === "new" ? [required, isTagUserNameOK] : [required];

    const {
      collapse: isCollapse,
      submitted,
      modalMessage,
      errorMessage,
    } = this.state;

    const openModal = errorMessage !== "";

    //console.log(`xxxxxxxxxxx formValues formValues`, formValues);
    //console.log(`xxxxxxxxxxx formValues pristine`, pristine);
    //console.log(`xxxxxxxxxxx formValues this.props`, this.props);

    const imageFilenameUser = "avatar-construction.png";
    const imageFilenameVehicle = "avatar-dump-truck.png";

    let urlImage = process.env.PUBLIC_URL + "/images/" + imageFilenameUser;
    switch (tagUserType) {
      case "personnel":
        urlImage = process.env.PUBLIC_URL + "/images/" + imageFilenameUser;
        break;
      case "vehicle":
        urlImage = process.env.PUBLIC_URL + "/images/" + imageFilenameVehicle;
        break;
      default:
        break;
    }

    // only show unique tag data
    // ...this deals with assignedTags possibly being in multiple Areas
    // The tag information is the same for each Area, so
    // return only the first record found for each tag.
    //
    const assignedTags = initialValues?.tagUserData?.assigned || [];
    let uniqueTagIds = assignedTags?.map((tag) => tag.mac);
    uniqueTagIds = [...new Set(uniqueTagIds)]; // unique tag mac
    // returns the 1st record found or [{}]
    const uniqueAssignedTags = [
      assignedTags.find((tag) => uniqueTagIds.includes(tag.mac)) || {},
    ];

    return (
      <Container>
        {/* {error && <div className="ui error message">{error}</div>} */}
        <Form onSubmit={handleSubmit(this.submitMyForm)}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column stretched width={isCollapse ? 1 : 6}>
                {isCollapse && (
                  <Segment>
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Popup
                            content={strings?.["Expand Left Side"]}
                            trigger={
                              <Button
                                floated={"left"}
                                icon
                                onClick={this.expandCollapse}
                                size={"mini"}
                                color={"teal"}
                              >
                                <Icon name={"angle right"} size={"large"} />
                              </Button>
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
                {!isCollapse && (
                  <>
                    <Segment>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as="h2">{header}</Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Popup
                              content={strings?.["Collapse Left Side"]}
                              trigger={
                                <Button
                                  floated={"right"}
                                  icon
                                  onClick={this.expandCollapse}
                                  size={"mini"}
                                  color={"teal"}
                                >
                                  <Icon name={"angle left"} size={"large"} />
                                </Button>
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            {tagUserType === "personnel" &&
                              tagTrackingUserDetailsCardPersonnel(
                                urlImage,
                                false
                              )}

                            {tagUserType === "vehicle" &&
                              tagTrackingUserDetailsCardVehicle(
                                urlImage,
                                false
                              )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    {error && <Message error header="Error" content={error} />}
                    {errorMessage && (
                      <Segment>
                        <Message icon color="red">
                          <Icon name="warning sign" />
                          <Message.Content>
                            <Message.Header>Error</Message.Header>
                            {errorMessage}
                          </Message.Content>
                        </Message>
                      </Segment>
                    )}
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={isCollapse ? 15 : 10}>
                <Segment>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        {formType === "edit" && (
                          <Grid.Row>
                            <DeleteButton
                              onClick={this.requestDelete}
                              id={id}
                              strings={strings}
                            />
                            {/* <Button
                            type="button"
                            onClick={this.requestDelete}
                            id={id}
                            color="red"
                          >
                            Delete
                          </Button> */}
                          </Grid.Row>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Button.Group floated="right">
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={(e) => this.resetForm(e)}
                            >
                              Reset
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              Cancel
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                {formType === "edit" && (
                  <Segment>
                    <Header as="h3">Assigned Tags</Header>
                    <Table sortable celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Tag MAC</Table.HeaderCell>
                          <Table.HeaderCell>RSSI</Table.HeaderCell>
                          <Table.HeaderCell>Locations</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {uniqueAssignedTags?.map((tag) => {
                          const { id, mac, locations, calculated_rssi } = tag;
                          return (
                            <Table.Row>
                              <Table.Cell>
                                <Link to={`/status/tag/${id}`}>{mac} </Link>
                              </Table.Cell>
                              <Table.Cell>{calculated_rssi}</Table.Cell>
                              <Table.Cell>{locations?.join(", ")}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </Segment>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        <DebugPagePropsMessages that={this} />
      </Container>
    );
  }
}

TagTrackingUserForm = reduxForm({
  form: "tagTrackingUserForm",
  //enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(TagTrackingUserForm);

const mapStateToProps = (state, props) => {
  const { id, tagUserType, formType } = props;

  return {
    tagUserType,
    formValues: getFormValues("tagTrackingUserForm")(state), // #REVIEW - only used for debugging,
    // onChange: (values, dispatch, props, previousValues) => {
    //   console.log("onChange", values);
    //   if (values.mineLevelId !== previousValues.mineLevelId) {
    //     const {
    //       change,
    //       initialValues: { mineLevelId, regions },
    //     } = props;
    //   }
    // },
  };
};

export default connect(mapStateToProps, null)(TagTrackingUserForm);
