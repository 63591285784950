import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";

import {
  Header,
  Grid,
  Table,
  Container,
  Input,
  Pagination,
  Dropdown,
  Icon,
  Segment,
  Button,
  Checkbox,
  Item,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";

import {
  fetchUPSs,
  //fetchFireflys,
  deleteUPS,
} from "UPSPanelControllers/actions";

import {
  getAllControllerStatuses,
  getAllFireflyStatuses,
} from "components/WebWorker/reducer";

import UPSLink from "./UPSLink";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

import { formatRelative, parseISO } from "date-fns";
// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { withComponentStateCache } from "react-component-state-cache";

import {
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import { StatusEnum } from "utils/StatusEnum";

import { Download } from "components/Download/index.js";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

const displayOptions = [
  {
    key: "all",
    text: "Show all",
    value: "all",
  },
  {
    key: "ok",
    text: "OK",
    value: "ok",
  },
  {
    key: "commissioned",
    text: "New",
    value: "commissioned",
  },
  {
    key: "old",
    text: "No status report",
    value: "old",
  },
];

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class UPSList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        controller: { string: "", include: true },
        area: { string: "", include: true },
        status: { string: "", include: true },
        location: { string: "", include: true },
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox delete selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},

      // default for radio button group
      statusGroup: "all",
    };
  }

  componentDidMount() {
    const {
      //data, fetchUPSs, fetchFireflys,
      componentstate,
    } = this.props;

    //FetchAllData();

    // const checkRefresh = this.props.componentstate.get("STARTUP", 1);

    // if (checkRefresh === null) {
    //   //#FETCHS
    //   //fetchMineLevels(); //#FETCHS
    //   fetchUPSs(); //#FETCHS
    //   fetchFireflys(); //#FETCHS
    //   //fetchNamedAreas(); //#FETCHS
    //   //fetchNamedAreaEvents(); // #FETCHS
    //   //fetchNamedAreaTriggers(); // #FETCHS

    //   this.props.componentstate.set("STARTUP", 1, "loaded");
    // }

    // if (_isEmpty(data)) {
    //   // #FETCHS
    //   fetchUPSs(); // #FETCHS
    //   fetchFireflys(); // #FETCHS
    // }

    const filterInput = componentstate.get("filterInput", "controllerList");

    //console.log("filterInput componentDidMount", filterInput);

    if (!_isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);

      this.setState({ filterInput: filterInput });

      // reset the radio button states
      //
      const filterInputStatusString = filterInput?.status?.string;
      switch (filterInputStatusString) {
        case "":
          this.setState({ statusGroup: "all" });
          break;
        case "ok":
          this.setState({ statusGroup: "ok" });
          break;
        case "Notcommissioned":
          this.setState({ statusGroup: "commissioned" });
          break;
        case "Nostatusreport":
          this.setState({ statusGroup: "old" });
          break;
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "controllerList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { data: dataRx } = this.props;
    const { data: prevDataRx } = prevProps;

    // compare new and prev data *ignoring* these:
    // controllerBulkPosLink,
    // controllerIpLink,
    // controllerLink,
    // timestamp,

    const data = dataRx.map(
      ({
        controllerBulkPosLink,
        controllerIpLink,
        controllerLink,
        timestamp,
        ...theRest
      }) => {
        return { ...theRest };
      }
    );

    const prevData = prevDataRx.map(
      ({
        controllerBulkPosLink,
        controllerIpLink,
        controllerLink,
        timestamp,
        ...theRest
      }) => {
        return { ...theRest };
      }
    );

    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(data) !== JSON.stringify(prevData) // compare subset of data
    ) {
      let newData = dataRx; // original data - full data set is in dataRx

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          const checkItemString = item[key]?.toLowerCase()?.replace(/\s/g, ""); // strip white spaces from value to e.g. "UPS02" = "UPS 02"
          const checkMatchString = match[key]?.string?.toLowerCase();
          const checkIncludes =
            checkMatchString !== "" // remove item which don't match, but exclude "" empty case
              ? match[key].include && checkItemString.includes(checkMatchString) //'.include' = true for include, = false for exclude
              : true;

          checkMatch = checkMatch && checkIncludes;
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  //  handleFilterStatusChange = ({ checked, value }) => { // <<--- for radio buttons
  handleFilterStatusChange = (e, data) => {
    this.setState({ statusGroup: data.value });

    let search = "";

    switch (data.value) {
      case "ok":
        search = "ok";
        break;
      case "commissioned":
        search = "Not commissioned";
        break;
      case "old":
        search = "No status report";
        break;
      case "all":
      default:
        search = "";
        break;
    }
    this.setFilterState({ name: "status", value: search }, true);
  };

  handleFilterChange = (e) => {
    const target = e.target;
    this.setFilterState(target, true);
  };

  setFilterState = ({ name, value }, include) => {
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // strip white spaces from value to e.g. "UPS02" = "UPS 02"
    // update match value with most recent filter entry
    match[name].string = value.replace(/\s/g, "");
    match[name].include = include;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    console.log("xxx upslist handleSelect id", id);
    this.setState(
      (prevState) => {
        if (_has(prevState.selections, id)) {
          // { 1: true } -> {}
          return _derivedState(
            update(prevState, {
              selections: { $unset: [id] },
            })
          );
        }
        // {} -> { 1: true }
        return _derivedState(
          update(prevState, {
            selections: { [id]: { $set: true } },
          })
        );
      }
      //() => console.log("xxx this.state.selections", this.state.selections)
    );
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  requestAction = (action) => {
    const { selections } = this.state;
    const { deleteUPS } = this.props;

    let promiseArray = [];

    for (var selection in selections) {
      console.log("deleting Controller ... ", selection);
      promiseArray.push(
        // eslint-disable-next-line no-loop-func
        new Promise((resolve, reject) => {
          deleteUPS({ values: { id: selection }, resolve, reject });
        })
      );
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("DELETING CONTROLLERS: ", results);
      })
      .then(
        () => {
          this.props.goto("/admin/controller/");
        },
        (msg) => {
          console.log("delete failed", msg); // TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "upslist10" },
              { value: 20, text: "20", key: "upslist20" },
              { value: 40, text: "40", key: "upslist40" },
              { value: 60, text: "60", key: "upslist60" },
              { value: data.length, text: "all", key: "upslistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>{" "}
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const controllerFromDataById = (id) =>
      allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let deleteButton;
    let controllerDeleteList;
    if (selectedCount > 0) {
      let controllerNames = [];
      for (var key in selections) {
        if (controllerFromDataById(key) !== undefined) {
          let controllerName = controllerFromDataById(key).controller;
          controllerNames.push(controllerName);
        }
      }
      controllerNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      controllerNames = controllerNames.join(", "); // separate by commas

      deleteButton = (
        <DeleteButton
          onClick={() => this.requestAction("delete")}
          strings={strings}
        />
      );

      controllerDeleteList = (
        <Segment textAlign="left">
          <strong>Controllers selected ... </strong>
          {controllerNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={5} textAlign={"left"}>
                <Header as="h1">
                  {strings?.["Header_Controller_BulkPosition"]}
                </Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <span>
                  {viewablesPage.length} items displayed. Select items in the
                  table below to apply an action.
                </span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or size="large" />
                  {deleteButton}
                </Button.Group>
              </div>
              <span
                style={{
                  marginLeft: "20px",
                  visibility: areAnySelected ? "visible" : "hidden",
                }}
              >
                {viewablesPage.length} items displayed. {selectedCount}{" "}
                selected.
              </span>
              <div>
                <Download data={data} dataType={"controller"} />
              </div>
            </Segment>
            {controllerDeleteList}
          </Segment.Group>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="trash" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.controller?.string
                        ? "filterInputHighlight"
                        : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="controller"
                    onChange={this.handleFilterChange}
                    value={filterInput?.controller?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.area?.string ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="area"
                    onChange={this.handleFilterChange}
                    value={filterInput?.area?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.location?.string
                        ? "filterInputHighlight"
                        : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="location"
                    onChange={this.handleFilterChange}
                    value={filterInput?.location?.string}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                {/* <Table.Cell style={headerCellStyle}></Table.Cell> */}
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}></Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Dropdown
                    fluid
                    selection
                    className={
                      this.state.statusGroup !== "all"
                        ? "dropdownSelectioHighlight"
                        : null
                    }
                    style={{ margin: "5px", width: "150px" }}
                    options={displayOptions}
                    value={this.state.statusGroup}
                    onChange={this.handleFilterStatusChange}
                    //onChange={(e, data) => this.handleFilterStatusChange(data)}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "controller" ? direction : null}
                  onClick={this.handleSort("controller")}
                >
                  {strings?.["Controller"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "controllerIp" ? direction : null}
                  onClick={this.handleSort("controllerIp")}
                >
                  {strings?.["Controller URL"]}
                </Table.HeaderCell>
                {/* <Table.HeaderCell width={1}>Bulk Position</Table.HeaderCell> */}
                <Table.HeaderCell
                  width={1}
                  sorted={column === "area" ? direction : null}
                  onClick={this.handleSort("area")}
                >
                  {strings?.["Area"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "location" ? direction : null}
                  onClick={this.handleSort("location")}
                >
                  {strings?.["Location"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "master" ? direction : null}
                  onClick={this.handleSort("master")}
                >
                  {strings?.["Master"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "version" ? direction : null}
                  onClick={this.handleSort("version")}
                >
                  {strings?.["Version"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "dcVoltage" ? direction : null}
                  onClick={this.handleSort("dcVoltage")}
                >
                  {strings?.["DC Supply Voltage"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "batteryVoltage" ? direction : null}
                  onClick={this.handleSort("batteryVoltage")}
                >
                  {strings?.["Battery Voltage"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "diskUsed" ? direction : null}
                  onClick={this.handleSort("diskUsed")}
                >
                  {strings?.["Disk Used"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "memUsed" ? direction : null}
                  onClick={this.handleSort("memUsed")}
                >
                  {strings?.["Memory Used"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "userRelay" ? direction : null}
                  onClick={this.handleSort("userRelay")}
                >
                  {strings?.["User Relay"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "lastStatusReport" ? direction : null}
                  onClick={this.handleSort("lastStatusReport")}
                >
                  {strings?.["Latest Report"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "status" ? direction : null}
                  onClick={this.handleSort("status")}
                >
                  {strings?.["Status"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({
                  id,
                  controllerLink,
                  controllerIp,
                  controllerIpLink,
                  controllerBulkPosLink,
                  area,
                  location,
                  master,
                  masterIcon,
                  version,
                  batteryVoltage,
                  dcVoltage,
                  diskUsed,
                  memUsed,
                  userRelay,
                  lastStatusReport,
                  status,
                  disableDelete,
                  style,
                }) => (
                  <Table.Row key={`row-id-${id}`} style={style}>
                    <Table.Cell>
                      <Checkbox
                        checked={this.isItemSelected(id)}
                        onChange={() => this.handleSelect(id)}
                        disabled={disableDelete}
                      />
                    </Table.Cell>
                    <Table.Cell>{controllerLink}</Table.Cell>
                    <Table.Cell>{controllerIpLink}</Table.Cell>
                    {/* <Table.Cell>{controllerBulkPosLink}</Table.Cell> */}
                    <Table.Cell>{area}</Table.Cell>
                    <Table.Cell>{location}</Table.Cell>
                    <Table.Cell>{masterIcon}</Table.Cell>
                    <Table.Cell>{version}</Table.Cell>
                    <Table.Cell>{dcVoltage}</Table.Cell>
                    <Table.Cell>{batteryVoltage}</Table.Cell>
                    <Table.Cell>{diskUsed}</Table.Cell>
                    <Table.Cell>{memUsed}</Table.Cell>
                    <Table.Cell>{userRelay}</Table.Cell>
                    <Table.Cell>{lastStatusReport}</Table.Cell>
                    <Table.Cell>{status || "-"}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const getStatus = (controller, controllers, allFireflyStatuses) => {
  const { id, timestamp, topology } = controller;

  //console.log(`getStatus controller`, controller);

  const controllerFireflies = allFireflyStatuses?.filter(
    (ff) => ff?.topology?.ups_id === id
  );

  // ... has it been commissioned?
  let isControllerCommissioned = true;
  let isControllerAllFireflysCommissioned = true;

  if (
    topology &&
    topology?.deviceStatus?.includes(StatusEnum.NOT_COMMISSIONED)
  ) {
    isControllerCommissioned = false;
    return {
      value: StatusEnum.NOT_COMMISSIONED,
      message: "New Controller - Not commissioned",
    };
  }

  // check for uncommissioned FFs connected to this controller.
  // Only do this check if the controller itself is commissioned.
  if (isControllerCommissioned && controllerFireflies) {
    isControllerAllFireflysCommissioned = !controllerFireflies.some(
      (ff) =>
        ff?.topology?.ups_id === id &&
        ff?.topology?.deviceStatus?.includes(StatusEnum.NOT_COMMISSIONED)
    );
  }

  if (!isControllerAllFireflysCommissioned) {
    return {
      value: StatusEnum.NOT_COMMISSIONED,
      message: "New Firefly - Not commissioned",
    };
  }

  // ... OK!
  return { value: StatusEnum.OK, message: "Ok" };
};

const _prepData = (elements, role, allFireflyStatuses) => {
  const { allowAdmin } = role;
  let filteredElements = [];

  // only need a subset of data
  elements.forEach(function (element, idx) {
    const { id, topology } = element;
    const { network_name: name, area, location: locations } = topology;

    let master = "-";
    let masterIcon = "-";
    if (typeof element?.topology?.master === "boolean") {
      // checks exists and type since undefined !== boolean
      master = element.topology.master;
      if (master) masterIcon = <Icon name="checkmark" size="small" />;
    }

    let controllerIpLink = "-";
    const controllerIp = element?.topology?.ip; // "10.208.26.140";
    if (controllerIp !== undefined) {
      controllerIpLink = controllerIpLink ? (
        <a
          href={`http://${controllerIp}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {controllerIp}
        </a>
      ) : (
        ""
      );
    }

    const controllerSoftVersion = element?.system?.sw_ver || "-";
    const batteryVoltage = element?.electrical?.battery?.voltage || "-";
    const dcVoltage = element?.electrical?.dc_supply?.voltage || "-";
    const diskUsed = element?.system?.disk?.replace("%", "") || "-";
    const memUsed = element?.system?.mem?.replace("%", "") || "-";
    const userRelay = element?.system?.user_relay || "-";

    // break down lastStatusReport as it may not be defined in object
    let lastStatusReport = "-";
    let timestamp = element?.timestamp;
    if (timestamp != undefined) {
      // format - previous used in tableformatters.js
      lastStatusReport = formatRelative(parseISO(timestamp), new Date(), {
        includeSeconds: true,
      });
    }

    // split up location array
    let newLocations = "-";
    if (Array.isArray(locations)) {
      locations.forEach(function (position, idx) {
        if (idx === 0) {
          newLocations = position;
        } else {
          newLocations = newLocations + ", " + position;
        }
      });
    }

    const controllerUpsId = (name !== "" ? name : "-") + " (" + id + ")";

    // styling for rows for highlight or error states
    //
    const elementStatus = getStatus(element, elements, allFireflyStatuses);
    const { value, message } = elementStatus;
    let style = null;
    let icon = "check";
    let color = "green";
    if (value !== StatusEnum.OK) {
      icon = "warning sign";
      const props = StatusEnum.properties[value];
      const { warning = false, error = false } = props;
      color = (error && "red") || (warning && "yellow") || "blue";
      style = { backgroundColor: "rgb(219, 40, 40, 15%)" };
    }
    if (value === StatusEnum.NOT_COMMISSIONED) {
      style = { backgroundColor: "rgb(255, 255, 0, 15%)" };
    }

    const status = message;

    // disable delete - provision for, but not used ATM
    const disableDelete = false;

    // DC_SUPPLY < 20 || DISK_USE > 90
    if (dcVoltage < 20 || diskUsed > 90) {
      style = { backgroundColor: "rgb(219, 40, 40, 15%)" };
    }

    const controllerLink = allowAdmin ? (
      <UPSLink id={id}>{`${name || ""} (${id})`}</UPSLink>
    ) : (
      `${name || ""} (${id})`
    );

    // compose new data set
    filteredElements[idx] = {
      id: id,
      controller: controllerUpsId,
      controllerLink: controllerLink,
      area: area,
      controllerIp: `${controllerIp}`,
      controllerIpLink: controllerIpLink,
      controllerBulkPosLink: (
        <Link to={`/admin/bulk-positioning/ups/${id}`}>
          {name} ({id})
        </Link>
      ),
      location: newLocations,
      master: master ? `${master}` : "-",
      masterIcon: masterIcon,
      version: controllerSoftVersion,
      diskUsed: diskUsed !== "-" ? `${diskUsed}%` : diskUsed,
      memUsed: memUsed !== "-" ? `${memUsed}%` : memUsed,
      batteryVoltage:
        batteryVoltage !== "-" ? `${batteryVoltage}v` : batteryVoltage,
      dcVoltage: dcVoltage !== "-" ? `${dcVoltage}v` : dcVoltage,
      userRelay: `${userRelay}`,
      note: "note", // #TODO - add support for notes in controllers
      lastStatusReport: lastStatusReport,
      status: status,
      statusValue: value,
      disableDelete: disableDelete,
      style,
      // Raw data for download report
      ups_name: name,
      ups_id: id,
      timestamp,
    };
  });

  return filteredElements;
};

function mapStateToProps(state) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  //  const { isLoading, error } = state.ups;
  const isLoading = false;
  const error = false;

  // #REVIEW
  // this is a hack to get the mineLevel name - should be in the UPS message?!
  const { mineLevels } = state;
  const { byId: mineLevelsbyId } = mineLevels;

  // user roles
  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);
  const allowGuest = userGuest;

  const allowOperator = userSuper || userAdmin || userUser;
  const allowAdmin = userSuper || userAdmin;
  const allowSuper = userSuper;

  // all fireflies

  const allFireflyStatuses = getAllFireflyStatuses(state);

  const data = _prepData(
    getAllControllerStatuses(state),
    {
      allowGuest: allowGuest,
      allowOperator: allowOperator,
      allowAdmin: allowAdmin,
      allowSuper: allowSuper,
    },
    allFireflyStatuses
  );

  return {
    isLoading,
    error,
    data,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    deleteUPS: (data) => dispatch(deleteUPS(data)),
    fetchUPSs: () => dispatch(fetchUPSs()),
    saveUserSettingsComponentState: (data) =>
      dispatch(saveUserSettingsComponentState(data)),
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, mapDispatchToProps)(UPSList)
);
