import Immutable from "seamless-immutable";

import {
  transformGeoJsonUtmToPixels,
  transformGeoJsonUtmToPixelsByChangeList,
} from "components/Map/util-geoJsonToPixels";
import { de } from "date-fns/locale";

const initialState = Immutable({
  tagsObject: {},
  areaStatuses: {},
  tagCoordinates: {},
  //
  // tag users
  tagPersonnel: [],
  tagVehicles: [],
});

const defaultTagObject = {
  calculated_rssi: 0,
  coord: { x: 0, y: 0 },
  id: "aa:bb:cc:dd:ee:ff",
  last_updated: "",
  locations: [],
  tag_id: 1,
  type: "unknown",
  user_info: null,
  vehicle_info: null,
};

const makeGeoJson = (object) => {
  return { type: "FeatureCollection", features: Object.values(object) };
};

function updateTagObjectStatus(tagsObject, newData, status) {
  //console.log(`DEBUG - updateTagObjectStatus`, newData);

  let newTagObjects = { ...tagsObject };
  let newTagObject = newTagObjects[newData.id];
  if (newTagObject) {
    newTagObject = {
      ...newTagObject,
      status: status,
      last_updated: newData.reported_ts,
    };
    newTagObjects[newData.id] = newTagObject;
  }
  return newTagObjects || {};
}

function updateTagCoordStatus(tagsCoords, newData, status) {
  //console.log(`DEBUG - updateTagCoordStatus`, newData);

  let newTagCoords = { ...tagsCoords };
  let newTagCoord = newTagCoords[newData.id];
  if (newTagCoord) {
    newTagCoord = {
      ...newTagCoord,
    };
    newTagCoord.properties.status = status;
    newTagCoord.properties.last_updated = newData.reported_ts;
    newTagCoords[newData.id] = newTagCoord;
  }
  return newTagCoords || {};
}

function updateTagsObject(tagsObject, newData, status) {
  // console.log("updateTagsObject tagsObject", tagsObject);
  // console.log("updateTagsObject newData", newData);

  let newTagsObject = { ...tagsObject }; // clone it
  let newTagObject = newTagsObject[newData.id]; // create a keyed object

  // tag already exists...update it
  if (newTagObject) {
    newTagObject = {
      ...newTagObject,

      coord: newData.coord_to,
      locations: newData.locations_to,
      status: status,
      last_updated: newData.reported_ts,
    };
  } // new tag ... make new
  else {
    newTagObject = {
      id: newData.id,
      mac: newData.mac,
      calculated_rssi: newData.calculated_rssi,
      coord: newData.coord_to,
      locations: newData.locations_to,
      status: status,
      last_updated: newData.reported_ts,
      //
      tag_id: 1,
      type: "unknown",
      user_info: null,
      vehicle_info: null,
    };
  }
  newTagsObject[newData.id] = newTagObject;
  return newTagsObject;
}

function makeTagCoordFeature(newTag) {
  //console.log(">>>>>>>>>>>>> makeTagCoordFeature newTag", newTag);

  const {
    id,
    mac,
    coord,
    calculated_rssi,
    last_updated,
    locations,
    tagId,
    tagUserId,
    tagUserType,
    userInfo,
    vehicleInfo,
    zones,
    status,
    area,
  } = newTag;

  // #WIP - until coords are sent correctly make something up
  // const tempCoords = Object.values({
  //   x: 736749.684764485,
  //   y: 9549616.990156105,
  // });

  // default startup coordinate
  let tempCoords = Object.values({
    x: 0,
    y: 0,
  });

  if (coord) {
    tempCoords = Object.values(coord);
  }

  //console.log(`############### tagFeature tempCoords`, tempCoords);

  const newTagFeature = {
    id: id,
    type: "Feature",
    geometry: { type: "Point", coordinates: tempCoords },
    properties: {
      id: id,
      area,
      zone: zones,

      calculated_rssi,
      last_updated,
      locations,
      tagId,
      mac,
      tagUserId,
      tagUserType,
      userInfo,
      vehicleInfo,

      easting: coord?.x,
      northing: coord?.y,
      utm_zone_number: 0,
      utm_zone_letter: "",
      z: 0,

      status: status,
    },
  };

  return newTagFeature;
}

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    // sends a copy of the areaStatuses to tags to allow it to do transforms on coordinates
    // for update messages
    //
    case "TAGS_FETCH_AREA_STATUSES":
      // console.log(
      //   `fetchTagTrackingAreaStatuses TAGS_UPDATE_AREA_STATUSES`,
      //   payload
      // );

      // convert to keyed object
      let newAreaStatuses = {};
      payload.forEach((area) => (newAreaStatuses[area.id] = area));

      newState.areaStatuses = newAreaStatuses;

      // console.log(
      //   `############### TAGS_FETCH_AREA_STATUSES newAreaStatuses`,
      //   newAreaStatuses
      // );

      return newState;

    case "TAGS_FETCH_SUCCEEDED":
      // fetches original state of the tags via api/areas/current
      // Subsequent updates are via websocket connection

      console.log(`tagTracking TAGS_FETCH_SUCCEEDED payload`, payload);

      // if there are no tags it returns 'null'
      const tags = payload.map((tag) => {
        let tagUserType = "unknown";
        let tagUserId = 0;
        if (tag?.vehicle) {
          tagUserId = tag.vehicle.id;
          tagUserType = "vehicle";
        }
        // #NOTE - can't be both so default to personnel if exists
        if (tag?.user) {
          tagUserId = tag.user.id;
          tagUserType = "personnel";
        }

        const {
          id,
          coord,
          calculated_rssi,
          last_updated,
          locations,
          tag_id: tagId,
          user: userInfo,
          vehicle: vehicleInfo,
          zones,
          status,
        } = tag;

        // #WIP - leave this first code in place for the moment...?

        // #Note - Area is derived from the locations, rather than Zone so tags
        // plot even if they are not in a zone
        // There must be a FF to know the location.
        // Support tag locations in multiple Areas.
        // this is possible if a controller is setup with a different Area
        // with firefly in multiple area detecting a common tag
        let derivedAreas = locations.map(
          (location) => location.split(":")?.[0]
        );

        return {
          id,
          mac: id, // set mac to rx ID
          coord,
          calculated_rssi,
          last_updated,
          locations,
          tagId,
          tagUserId,
          userInfo,
          vehicleInfo,
          tagUserType,
          zones: [...new Set(zones)], // unique zone names
          status: status,
          area: [...new Set(derivedAreas)], // unique areas
        };
      });

      // convert tag into indexed object
      // this is easier to update TAG_UPDATE as just target object key
      //
      // #NOTE - the tag id is a combination of area and tag mac b/c
      // tags could appear in multiple areas
      //
      let tagsObject = {};
      tags.forEach((tag) => {
        const { area: areas, zones } = tag;
        areas.forEach((area) => {
          // define new id based on area and mac
          const newTagId = `${area}~${tag.id}`;
          // filter out zones not in the derived area
          const newZones = zones.filter((zone) => zone.area === area);

          tagsObject[newTagId] = {
            ...tag,
            id: newTagId,
            area: area,
            zones: newZones,
          };
        });
      });

      // console.log(
      //   "tagTracking - TAGS_FETCH_SUCCEEDED tags",
      //   tags,
      //   tagsObject,
      //   new Date().getTime()
      // );

      newState.tagsObject = tagsObject || {};

      // ====================================================

      let newTagFeatures = {};
      // setup coordinate geoJson
      for (const key in tagsObject) {
        const tag = tagsObject[key];

        // #REVIEW - protects against undefined tag for key.
        // This occassionaly happens - WHY?
        //
        if (tag) {
          newTagFeatures[tag.id] = makeTagCoordFeature(tag);
        }
      }

      //console.log(`############### newTagFeatures`, newTagFeatures);

      const newTagCoordinatesToTransform = {
        type: "FeatureCollection",
        features: Object.values(newTagFeatures),
      };

      const tempTransformedCoords = transformGeoJsonUtmToPixels(
        newTagCoordinatesToTransform,
        newState.areaStatuses,
        1,
        false
      );

      // save transformed geoJson as a keyed object
      let newTagCoordinates = {};
      const tempTransformedCoordsFeatures = tempTransformedCoords.features;
      tempTransformedCoordsFeatures.forEach(
        (tag) => (newTagCoordinates[tag.id] = tag)
      );

      newState.tagCoordinates = newTagCoordinates;

      return newState;
    // "TAG_UPDATE"
    case "TAG_UPDATE":
      // tag updates via websocket connection
      // TAG_UPDATE with one of the following INITIAL, LOST, CHANGE, UNKNOWN
      // ZONE_UPDATE with one of the following CHANGE

      console.log("tagTracking - TAG_UPDATE tags", payload);

      const { type } = payload;

      switch (type) {
        case "INITIAL":
          console.log("tagTracking - TAG_UPDATE INITIAL tags", payload);
          // cater for new tag inserted into the system
          // update 'defaultTagObject'

          // split tag CHANGE message for different AREAs into separate 'payloads'
          const INITIAL_locations_to = payload.locations_to || [];
          let INITIAL_derivedAreas = INITIAL_locations_to.map(
            (location) => location.split(":")?.[0]
          );
          INITIAL_derivedAreas = [...new Set(INITIAL_derivedAreas)];

          let newInitialPayload = [];
          INITIAL_derivedAreas.forEach((area) => {
            // define new id based on area and mac
            const newTagId = `${area}~${payload.mac}`;
            // filter out zones not in the derived area
            const newZones = payload.zones?.filter?.(
              (zone) => zone.area === area
            );

            newInitialPayload.push({
              ...payload,
              id: newTagId,
              area: area,
              zones: newZones,
            });
          });

          console.log(
            "tagTracking - TAG_UPDATE INITIAL newInitialPayload",
            newInitialPayload
          );

          newInitialPayload.forEach((payload) => {
            const newInitialTagsObject = updateTagsObject(
              newState.tagsObject,
              payload,
              "initial"
            );

            //#WIP - split to multiple areas

            newState.tagsObject = newInitialTagsObject;

            let newInitialTagCoordinates = { ...newState.tagCoordinates };
            newInitialTagCoordinates[payload.id] = makeTagCoordFeature(
              newInitialTagsObject[payload.id]
            );

            // convert previous and current to feature collections
            const newInitialTagCoordinatesToTransform =
              transformGeoJsonUtmToPixelsByChangeList(
                newState.tagCoordinates, // feature object
                makeGeoJson(newInitialTagCoordinates), // geoJson object
                [payload.id],
                newState.areaStatuses
              );

            // save transformed geoJson as a keyed object
            let initialChangedTagCoordinates = {};
            const newInitialTagCoordinatesToTransformFeatures =
              newInitialTagCoordinatesToTransform.features;
            newInitialTagCoordinatesToTransformFeatures.forEach(
              (tag) => (initialChangedTagCoordinates[tag.id] = tag)
            );

            newState.tagCoordinates = initialChangedTagCoordinates || {};
          });

          console.log(
            "tagTracking - TAG_UPDATE INITIAL newState.tagCoordinates",
            newState.tagCoordinates
          );

          break;
        case "LOST":
          console.log("tagTracking - TAG_UPDATE LOST tags", payload);

          // split tag CHANGE message for different AREAs into separate 'payloads'
          const LOST_locations_to = payload.locations_to || [];
          let LOST_derivedAreas = LOST_locations_to.map(
            (location) => location.split(":")?.[0]
          );
          LOST_derivedAreas = [...new Set(LOST_derivedAreas)];

          let newLostPayload = [];
          LOST_derivedAreas.forEach((area) => {
            // define new id based on area and mac
            const newTagId = `${area}~${payload.mac}`;
            // filter out zones not in the derived area
            const newZones = payload.zones?.filter?.(
              (zone) => zone.area === area
            );

            newLostPayload.push({
              ...payload,
              id: newTagId,
              area: area,
              zones: newZones,
            });
          });

          console.log(
            "tagTracking - TAG_UPDATE LOST newLostPayload",
            newLostPayload
          );

          newLostPayload.forEach((payload) => {
            newState.tagsObject = updateTagObjectStatus(
              newState.tagsObject,
              payload,
              "lost"
            );

            newState.tagCoordinates = updateTagCoordStatus(
              newState.tagCoordinates,
              payload,
              "lost"
            );
          });

          break;
        case "CHANGE":
          console.log("tagTracking - TAG_UPDATE CHANGE tags", payload);

          // split tag CHANGE message for different AREAs into separate 'payloads'
          const CHANGE_locations_to = payload.locations_to || [];
          let CHANGE_derivedAreas = CHANGE_locations_to.map(
            (location) => location.split(":")?.[0]
          );
          CHANGE_derivedAreas = [...new Set(CHANGE_derivedAreas)];

          let newChangePayload = [];
          CHANGE_derivedAreas.forEach((area) => {
            // define new id based on area and mac
            const newTagId = `${area}~${payload.mac}`;
            // filter out zones not in the derived area
            const newZones = payload.zones?.filter?.(
              (zone) => zone.area === area
            );

            newChangePayload.push({
              ...payload,
              id: newTagId,
              area: area,
              zones: newZones,
            });
          });

          // console.log(
          //   "tagTracking - TAG_UPDATE CHANGE newChangePayload",
          //   newChangePayload
          // );

          newChangePayload.forEach((payload) => {
            const newChangeTagsObject = updateTagsObject(
              newState.tagsObject,
              payload,
              "change"
            );

            newState.tagsObject = newChangeTagsObject;

            let newTagCoordinates = { ...newState.tagCoordinates };

            let newTagCoordinate = newTagCoordinates[payload.id];
            if (newTagCoordinate) {
              newTagCoordinate = {
                ...newTagCoordinate,
              };
              console.log(
                "tagTracking - TAG_UPDATE CHANGE newTagCoordinate",
                newTagCoordinate
              );

              newTagCoordinate.geometry.coordinates = Object.values(
                payload.coord_to
              );

              // newTagCoordinate.geometry.coordinates = Object.values({
              //   x: 736749.684764485,
              //   y: 9549616.990156105,
              // }); //Object.values(coord_to);
              newTagCoordinate.properties.northing = payload.coord_to.x;
              newTagCoordinate.properties.easting = payload.coord_to.y;

              newTagCoordinate.properties.locations = payload.locations_to;
              newTagCoordinate.properties.last_updated = payload.reported_ts;
              newTagCoordinates[payload.id] = newTagCoordinate;
            }

            // convert previous and current to feature collections
            const tagCoordinatesToTransform =
              transformGeoJsonUtmToPixelsByChangeList(
                newState.tagCoordinates, // feature object
                makeGeoJson(newTagCoordinates), // geoJson object
                [payload.id],
                newState.areaStatuses
              );

            // save transformed geoJson as a keyed object
            let changedTagCoordinates = {};
            const tagCoordinatesToTransformFeatures =
              tagCoordinatesToTransform.features;
            tagCoordinatesToTransformFeatures.forEach(
              (tag) => (changedTagCoordinates[tag.id] = tag)
            );

            newState.tagCoordinates = changedTagCoordinates || {};
          });

          console.log(
            "tagTracking - TAG_UPDATE CHANGE newState.tagCoordinates",
            newState.tagCoordinates
          );

          break;
        case "UNKNOWN":
          break;
        default:
          break;
      }

      return newState;

    case "ZONE_UPDATE":
      console.log(`tagTracking - TAG_UPDATE ZONE_UPDATE  payload`, payload);

      //{"mac":"ac:23:3f:63:37:43",
      //"zones":[{"id":"Area_Main:e12dbf83-d6ae-49d6-972c-5d3dc4d7876b:1639025597226",
      //"area":"Area_Main","name":"Left"}],
      // "reported_ts":"2021-12-10T14:00:42.466719583+11:00"}

      // split tag CHANGE message for different AREAs into separate 'payloads'
      const ZONE_UPDATE_locations_to = payload.locations_to || [];
      let ZONE_UPDATE_derivedAreas = ZONE_UPDATE_locations_to.map(
        (location) => location.split(":")?.[0]
      );
      ZONE_UPDATE_derivedAreas = [...new Set(ZONE_UPDATE_derivedAreas)];

      let newZoneUpdatePayload = [];
      ZONE_UPDATE_derivedAreas.forEach((area) => {
        // define new id based on area and mac
        const newTagId = `${area}~${payload.mac}`;
        // filter out zones not in the derived area
        const newZones = payload.zones?.filter?.((zone) => zone.area === area);

        newZoneUpdatePayload.push({
          ...payload,
          id: newTagId,
          area: area,
          zones: newZones,
        });
      });

      // console.log(
      //   "tagTracking - TAG_UPDATE CHANGE newChangePayload",
      //   newChangePayload
      // );

      newZoneUpdatePayload.forEach((payload) => {
        let newTagsObjectZones = { ...newState.tagsObject };
        let newTagsObjectZone = newTagsObjectZones[payload.id];
        if (newTagsObjectZone) {
          newTagsObjectZone = {
            ...newTagsObjectZone,
            status: "zone change",
            zones: payload.zones,
            last_updated: payload.reported_ts,
          };
          newTagsObjectZones[payload.id] = newTagsObjectZone;
        }

        newState.tagsObject = newTagsObjectZones || {};

        let newTagCoordZones = { ...newState.tagCoordinates };
        let newTagCoordZone = newTagCoordZones[payload.id];
        if (newTagCoordZone) {
          newTagsObjectZone = {
            ...newTagCoordZone,
          };
          newTagsObjectZone.properties.zones = payload.zones;
          newTagsObjectZone.properties.status = "zone change";
          newTagsObjectZone.properties.last_updated = payload.reported_ts;
          newTagCoordZones[payload.id] = newTagCoordZone;
        }

        newState.tagCoordinates = newTagCoordZones || {};
      });

      console.log(
        "tagTracking - TAG_UPDATE ZONE_UPDATE newState.tagCoordinates",
        newState.tagCoordinates
      );

      return newState;

    case "TAGS_FETCH_ALL_USERS_SUCCEEDED":
      console.log(`TAGS_FETCH_ALL_USERS_SUCCEEDED`, payload);

      // get tags assigned to this tag type
      const tagPersonnelAssignedTags = getTagsAssignedByTagType(
        newState.tagsObject,
        "personnel"
      );

      // loop the payload the updated the users with the assigned tags
      let tagPersonnel = [];
      tagPersonnel = payload?.map((user) => {
        const assignedTags = tagPersonnelAssignedTags?.filter(
          (tag) => tag.userInfo.id === user.id
        );
        return { ...user, assigned: assignedTags };
      });

      //console.log(`TAGS_FETCH_ALL_USERS_SUCCEEDED `, payload, tagPersonnel);

      newState.tagPersonnel = tagPersonnel;
      return newState;

    case "TAGS_FETCH_ALL_VEHICLES_SUCCEEDED":
      console.log(`TAGS_FETCH_ALL_VEHICLES_SUCCEEDED `, payload);

      // get tags assigned to this tag type
      const tagVehicleAssignedTags = getTagsAssignedByTagType(
        newState.tagsObject,
        "vehicle"
      );

      // loop the payload the updated the users with the assigned tags
      let tagVehicles = [];
      tagVehicles = payload?.map((user) => {
        const assignedTags = tagVehicleAssignedTags?.filter(
          (tag) => tag.vehicleInfo.id === user.id
        );
        return { ...user, assigned: assignedTags };
      });

      //console.log(`TAGS_FETCH_ALL_VEHICLES_SUCCEEDED `, payload, tagVehicles);

      newState.tagVehicles = tagVehicles;
      return newState;

    case "RESET":
      console.log("RESET! - Tags!");
      return state;
    default:
      return state;
  }
}

function getTagsAssignedByTagType(tagObjects, tagType) {
  return Object.values(tagObjects)?.filter(
    (tag) => tag.tagUserType === tagType
  );
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}

export const getAllTags = (state) => {
  const data = state.tags.tagsObject;
  const keys = Object.keys(data).sort();
  return stableOrder(keys, data);
};

export const getAllTagCoordinates = (state) => {
  const data = state.tags.tagCoordinates;
  const keys = Object.keys(data).sort();
  return stableOrder(keys, data);
};

export const getTagById = (state, id) => {
  const tags = getAllTags(state, id);
  const result = tags.find((tag) => tag.id === id);
  return result;
};

export const getTagPersonnel = (state) => {
  return state.tags.tagPersonnel;
};

export const getTagPersonnelById = (state, id) => {
  const data = getTagPersonnel(state, id);
  const result = data.find((tag) => tag.id === id);
  return result;
};

export const getTagVehicles = (state) => {
  return state.tags.tagVehicles;
};

export const getTagVehicleById = (state, id) => {
  const data = getTagVehicles(state, id);
  const result = data.find((tag) => tag.id === id);
  return result;
};

// [{ id, type,  }]
// id - for linking to tag records
// type - to display icons
//
export const getFireflyByTagByPriority = (state, id, priority) => {
  const tagById = getTagById(state, id);
  const result = tagById?.fireflies?.find((ff) => ff.priority === 1);
  return result;
};

export const getAllFireflyTagsByPriority = (state, priority) => {
  const allTags = getAllTags(state);
  let fireflyTags = {};
  allTags.forEach((tag) => {
    const fireflyByPriority = getFireflyByTagByPriority(
      state,
      tag.id,
      priority
    );
    const { firefly: fireflyId } = fireflyByPriority;

    if (fireflyTags[fireflyId] === undefined) fireflyTags[fireflyId] = [];

    const { id, type, userInfo, vehicleInfo } = tag;

    let tagEntry = "?";
    switch (type) {
      case "personnel":
        const { firstname, lastname } = userInfo;
        tagEntry = `${firstname} ${lastname}`;
        break;
      case "vehicle":
        const { name, type } = vehicleInfo;
        tagEntry = `${name}`;
        break;

      default:
        break;
    }

    fireflyTags[fireflyId].push({ id: id, type: type, name: tagEntry });
  });

  return fireflyTags;
};

export const getTagTrackingFFCoordinatesByTagType = (state, tagType) => {
  //const geoJson = tagTrackingCoordinates;
  const ffCoords = state.webWorker?.general?.fireflyCoordinates || [];
  // deepclone
  const geoJson = JSON.parse(JSON.stringify(ffCoords));

  const fireflyTagsByPriority = getAllFireflyTagsByPriority(state, 1);

  let newTags = [];

  for (const tagsKey in fireflyTagsByPriority) {
    if (tagType === "all") {
      newTags[tagsKey] = fireflyTagsByPriority[tagsKey];
    } else {
      newTags[tagsKey] = fireflyTagsByPriority[tagsKey].filter(
        (tag) => tag.type === tagType
      );
    }
  }

  //console.log(`xxx fireflyTagsByPriority`, newTags);

  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  filteredgeojson.features = [];

  if (geoJson?.features !== undefined) {
    // create a new feature and properties specifically for tagTracking position markers
    geoJson.features.forEach((feature) => {
      if (newTags?.[feature.id]?.length > 0) {
        const {
          id,
          type,
          geometry,
          properties: {
            area,
            location,
            position,
            easting,
            northing,
            utm_zone_letter,
            utm_zone_number,
            z,
            timestamp, // <---THIS SHOULD BE TAG TIMESTAMP?
          },
        } = feature;
        const newProperties = {
          id,
          area,
          location,
          position,
          easting,
          northing,
          utm_zone_letter,
          utm_zone_number,
          z,
          //
          timestamp,
          //
          tagTracking: newTags[id], // <<--- {}
          tagType: tagType,
        };
        const newFeature = {
          type: type,
          geometry: geometry,
          properties: newProperties,
        };

        filteredgeojson.features.push(newFeature);
      }
    });
  } else {
    filteredgeojson.features = [];
  }
  //console.log(`xxxxx filteredgeojson`, filteredgeojson);

  return filteredgeojson;
};

export const getTagTrackingFFCoordinatesByAreaId = (state, id) => {
  //const geoJson = tagTrackingCoordinates;
  const geoJson = getTagTrackingFFCoordinatesByTagType(state, "all");

  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area.id === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getTagTrackingFFCoordinatesByAreaIdByTagType = (
  state,
  id,
  type
) => {
  //const geoJson = tagTrackingCoordinates;
  const geoJson = getTagTrackingFFCoordinatesByTagType(state, type);

  //console.log(`tagTrack geoJson`, geoJson);

  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

// ----------------------------------------------------------------
// tags not based on FF coordinates
export const getAllTagTrackingGeoJson = (state) => {
  return makeGeoJson(state.tags.tagCoordinates);
};

export const getTagTrackingCoordinatesByAreaId = (state, id) => {
  const geoJson = getAllTagTrackingGeoJson(state);

  //console.log(`getTagTrackingCoordinatesByAreaId`, geoJson);
  //console.log(`getTagTrackingCoordinatesByAreaId id`, id);

  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getTagIdsByAreaId = (state, id) => {
  //console.log(`getTagIdsByAreaId getAllTags`, getAllTags(state));

  return getAllTags(state)
    .filter((tag) => tag.area === id)
    .map((tag) => tag.id);
};
