import React, { Component } from "react";
import { connect } from "react-redux";
import { push as goto } from "react-router-redux";

import { Container, Header, Grid } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

//import { v4 as uuid } from "uuid";

//import SubmissionModal from "admin/SubmissionModal";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
//import DataLoadingMessage from "components/DataLoading/dataLoading";

//import { SubmissionError, isSubmitting, hasSubmitSucceeded } from "redux-form";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import { formatRelative, parseISO } from "date-fns";
import { formatDateRelativeNow, minutesAgo } from "utils/format-date";

import {
  getAllAreaStatuses,
  getAllNamedAreaEvents,
  getNamedAreasInfoInNamedAreaGroupByAreaId,
  getNamedAreaExtTriggerEventInfo,
} from "components/WebWorker/reducer";

import ExternalTriggerEditForm from "admin/event-external-trigger/ExternalTriggerEditForm";
import { isKthBitSet } from "utils/bit-operations";

import { fetchNamedAreaExtTriggerEventInfo } from "NamedAreas/actions";
import hash from "object-hash";

class ExternalTriggerEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      initialValues: {},
      submitted: false,
      saveSuccess: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    this.props.fetchNamedAreaExtTriggerEventInfo();
  }

  componentDidUpdate() {
    //this.props.fetchNamedAreaExtTriggerEventInfo();
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );

    const { submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      goto("/");
    }
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    const { changeLocalisation, reset, localisation } = this.props;
    const { id: language, fieldData } = values;

    let localisations = [];

    // ...............................

    const newValues = { id: language, localisations };

    return new Promise((resolve, reject) => {
      //changeLocalisation({ values: newValues, resolve, reject });
    }).then(() => {
      //this.props.fetchLocalisation();
      this.props.goto("/tools/localisation");
      // #TODO
      // Should wait for change to propogate then reset() to return to new intialValues
      //reset();
    });
  };

  render() {
    const { id, strings, goto, data, levels } = this.props;

    // APP_TERMINOLOGY
    let strExternalTrigger = strings?.["External Trigger"];
    let strExternalTriggers = strings?.["External Triggers"];

    // fieldData is a keyed object
    let fieldData = {};
    data.forEach((element) => {
      fieldData[element.id] = { ...element };
    });

    const initialValues = {
      _action: "edit", // used to control dlg display
      id: id,
      fieldData: fieldData, // fieldData,
    };

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"externalTriggerEditForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["External Trigger Events"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <ExternalTriggerEditForm
            key={hash(initialValues)}
            id={id}
            data={data}
            levels={levels}
            initialValues={initialValues}
            header={`Edit ${id}`}
            onSubmit={(values) => this.submitMyForm(values)}
            onCancel={() => {
              goto(`/external-trigger-event`);
            }}
            strings={strings}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  let filteredElements = [];

  //console.log("xxx elements", elements);

  elements.forEach(function (element, idx) {
    const {
      id,
      active,
      area,
      buttonCustomLabel,
      buttonLabel,
      groupLabel: group,
      levelLabel: level,
      parent,
      permission,
      priority,
      origin,
      operator,
      reason,
      disable,
      timestamp,
    } = element;

    // console.log("xxx element", element);

    let lastStatusReport = "-";
    if (timestamp !== undefined) {
      // format - previous used in tableformatters.js
      lastStatusReport = formatRelative(timestamp, new Date(), {
        includeSeconds: true,
      });
    }

    let style = null;
    // change row if active

    if (active) style = { backgroundColor: "rgb(219, 40, 40, 15%)" };

    // set style of disabled events
    if (disable === true) {
      style = { backgroundColor: "rgb(242, 113, 28, 60%)" };
    }

    // compose new data set
    filteredElements[idx] = {
      id: id, // `${parent}:${priority}`,
      active: active ? "ACTIVE" : "INACTIVE",
      area,
      group,
      level,
      polygon: buttonCustomLabel !== "" ? buttonCustomLabel : buttonLabel,
      source: `${origin} - ${operator}`,
      reason,
      lastStatusReport: lastStatusReport,
      style,
      disable: disable ? "DISABLED" : "ENABLED",
    };
  });

  return filteredElements;
};

function mapStateToProps(state, props) {
  const allAreas = getAllAreaStatuses(state);

  let levels = [];
  let rawData = [];

  // #NOTE - this is the old process to generate levels and data
  // This works but has been replaced by API call to get /ext_trigger_event_info
  //
  if (false) {
    // accumulate array of area ids called "levels" - old school!
    let namedAreasInNamedAreaGroupsByArea = [];

    allAreas.forEach((area) => {
      const { id } = area;
      const namedAreaInfoByArea = getNamedAreasInfoInNamedAreaGroupByAreaId(
        state,
        id
      );

      namedAreasInNamedAreaGroupsByArea.push({
        area: id,
        namedArea: namedAreaInfoByArea,
      });
      levels.push({ id: id, name: id });
    });

    const allNamedAreaEvents = getAllNamedAreaEvents(state);

    let rawData = [];
    namedAreasInNamedAreaGroupsByArea.forEach((a) => {
      const { area, namedArea } = a;
      namedArea.forEach((na) => {
        // only list if permission bit 1 is set i.e. enabled for External_Trigger_Event
        const isExternalTriggerEvent = isKthBitSet(na.permission, 1);

        if (isExternalTriggerEvent) {
          // could be multiple events (per parent polygon)
          const events = allNamedAreaEvents.filter(
            (event) =>
              event.parent === na.parent && event.priority === na.priority
          );

          // if there is an event, all child events should have the same info
          // so just use the state of the 1st found. WE call this the templateEvent
          let active = false;
          let origin = "";
          let operator = "";
          let reason = "WIP";

          if (events.length > 0) {
            const templateEvent = events[0];
            active = templateEvent.active;
            origin = templateEvent.origin;
            operator = templateEvent.operator;
          }

          rawData.push({
            area,
            events,
            active,
            origin,
            operator,
            reason,
            ...na,
          });
        }
      });
    });
  }

  // create level information
  allAreas.forEach((area) => {
    const { id } = area;
    levels.push({ id: id, name: id });
  });

  // external trigger event info via API /get_all_ext_trigger_event_info

  const namedAreaExtTriggerEventInfo = getNamedAreaExtTriggerEventInfo(state);

  rawData = namedAreaExtTriggerEventInfo;

  const data = _prepData(rawData);

  return {
    data,
    levels,
  };
}

export default connect(mapStateToProps, {
  TurnOnOffPokeTheWorker,
  goto,
  fetchNamedAreaExtTriggerEventInfo,
})(ExternalTriggerEditPage);
