import React, { Component } from "react";
import { connect } from "react-redux";

import Worker from "./testMqttWorker";
import WebWorker from "components/WebWorker";

import { mqttTest } from "./actions";

import TestMqttSimpleTable from "./TestMqttSimpleTable";
import TestMqttNamedAreasTable from "./TestMqttNamedAreasTable";

class TestMqttControl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // console.log("qqq mounted src/pages/Test/TestMQTT/index.js");
  }

  render() {
    return (
      <>
        <div>
          {/* <Worker /> */}
          {/* <WebWorker /> */}
          <TestMqttSimpleTable />
          <TestMqttNamedAreasTable />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    mqttTest: (mqttMsg) => {
      dispatch(mqttTest(mqttMsg));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestMqttControl);
