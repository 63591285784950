import React, { Component } from "react";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import isEqual from "lodash/isEqual";

export default class SubmissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  componentDidMount() {
    const { open } = this.props;

    if (open) {
      this.handleOpen();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    this.props.onResetError();
  };

  render() {
    const { modalMessage, errorMessage } = this.props;

    let message = {
      color: "red",
      header: { icon: "info", title: "Problem Saving" },
      content:
        "There has been a problem saving the changes. Please address the problem and re-save, or contact your system administrator for support if the problem persists.",
    };

    if (modalMessage) {
      message = modalMessage;
    }

    // styles
    // #NOTE - copied colors from inspect to match semantic default 'red'
    const red = { color: "#db2828", background: "#ffe8e6" };

    const {
      header: { icon, title },
      content,
    } = message;

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleClose}>
        <Header style={red} icon={icon} content={title} />
        <Modal.Content>
          <p>{content} </p>
          <p>{errorMessage}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>
            <Icon name="close" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
      // <Modal open={this.state.modalOpen} onClose={this.handleClose}>
      //   <Header icon="info" content="Problem Saving" />
      //   <Modal.Content>
      //     <p>
      //       There has been a problem saving the changes. Please address the
      //       problem and re-save, or contact your system administrator for
      //       support if the problem persists.
      //     </p>
      //     <p>{errorMessage}</p>
      //   </Modal.Content>
      //   <Modal.Actions>
      //     <Button onClick={this.handleClose}>
      //       <Icon name="close" /> OK
      //     </Button>
      //   </Modal.Actions>
      // </Modal>
    );
  }
}
