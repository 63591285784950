import Immutable from "seamless-immutable";
import { messageToken } from "utils/messageToken";
import { microTime } from "utils/microTime";

export const testController = Immutable({
  id: "11:22:33:44:55:66",
  area: "DMLZ Extraction",
  utm_zone_number: 53,
  utm_zone_letter: "M",
  utm: [737750, 9549000],
  Z: 0,
  network_name: "testController",
  token: messageToken(),
});

export const testFF = Immutable({
  id: "24:0a:c4:50:c8:54",
  utm_zone_number: 53,
  utm_zone_letter: "M",
  utm: [737750, 9549000],
  Z: 0,
  token: messageToken(),
});

export const testLedFF = Immutable({
  id: "24:0a:c4:50:c8:54",
  state: "ON",
  color: "RED",
  brightness: 100,
  on_time: 0,
  off_time: 0,
  train: 0,
  token: messageToken(),
});

export const testArea = Immutable({
  id: "DMLZ_Apex",
  image_filename: "extraction.png",
  slug: "dmlz-extraction",
  bounds: [
    [737074.9271303766, 9549161.164079294],
    [737074.8510577901, 9548828.072795311],
    [737654.4260753208, 9548827.910536127],
    [737074.9271303766, 9549161.164079294],
    [737074.9271303766, 9549161.164079294],
  ],
  ref_coord: [
    {
      utm_zone_letter: "M",
      utm: [737376.722, 9549056.885],
      z: 2969.139,
      utm_zone_number: 53,
      id: "DMLZ_Extraction:1",
      image_xy: [150.23, 200.45],
    },
    {
      id: "DMLZ_Extraction:2",
      utm: [747376.722, 9649056.885],
      z: 2969.139,
      utm_zone_number: 53,
      utm_zone_letter: "M",
      image_xy: [160.23, 300.45],
    },
  ],
  floor: 2345.88,
  ceiling: 2335.66,
  default_color: "GREEN",
  default_state: "ON",
  token: messageToken(),
});

// export const testArea = Immutable({
//   id: "DMLZ_Extraction99",
//   name: "DMLZ_Extraction99",
//   image_filename: "extraction.png",
//   slug: "dmlz-extraction",
//   ref_coord: [
//     {
//       Image_X: 4764,
//       Image_Y: 4202,
//       Easting: 736959.54,
//       Northing: 9549076.153,
//       Zone_Number: 53,
//       Zone_Letter: "M",
//     },
//     {
//       Image_X: 3236,
//       Image_Y: 7815,
//       Easting: 737526.058,
//       Northing: 9549315.041,
//       Zone_Number: 53,
//       Zone_Letter: "M",
//     },
//     {
//       Image_X: 5978,
//       Image_Y: 10090,
//       Easting: 737883.021,
//       Northing: 9548885.911,
//       Zone_Number: 53,
//       Zone_Letter: "M",
//     },
//     {
//       Image_X: 7611,
//       Image_Y: 8061,
//       Easting: 737564.472,
//       Northing: 9548630.213,
//       Zone_Number: 53,
//       Zone_Letter: "M",
//     },
//   ],
//   bounds: [
//     [737376.722, 9549037.197],
//     [737376.722, 9549037.197],
//     [737376.722, 9549037.197],
//     [737376.722, 9549037.197],
//   ],
//   floor: 2345,
//   ceiling: 2335,
//   status: "OK",
//   default_color: "GREEN",
//   default_state: "ON",
// });

export const testNamedAreaEvent = Immutable({
  id: "DMLZ_Extraction:AREA_46",
  priority: 1,
  active: true,
  active_color: "RED",
  active_state: "ON",
  timestamp: microTime(),
  precanned: 0,
  token: messageToken(),
});

export const testNamedArea = Immutable({
  id: "DMLZ_Extraction:AREA_46",
  name: "AREA_46",
  parent: "parentNamedArea",
  area: "DMLZ_Extraction",
  origin: "user",
  shape: [
    [737809.166, 9549066.886],
    [737703.561, 9549063.944],
    [737584.687, 9548925.63],
    [737646.288, 9548887.191],
    [737658.232, 9548897.419],
    [737726.042, 9548853.966],
    [737785.321, 9548838.814],
    [737837.514, 9548897.337],
    [737848.083, 9548938.28],
    [737750.6, 9549001.738],
    [737809.166, 9549066.886],
  ],

  priority: 1,
  type: "Polygon",
  default_color: "green",
  default_state: "on",
  button: [
    {
      clickable: true,
      icon: "seismic_0_button.png",
      priority: 1,
      title: "Seismic 0",
      alt: "Area A - Level 0",
      state: "on",
      color: "green",
      on_time: 0,
      off_time: 0,
    },
  ],
  token: messageToken(),
});

// multiple named area setup

export const testNamedArea46_1 = Immutable({
  id: "DMLZ_Extraction:AREA_46_1",
  name: "AREA_46_1",
  parent: "parentNamedArea",
  area: "DMLZ_Extraction",
  origin: "user",
  // shape for very 1st FF

  // shape: [
  //   [737082.1976870192, 9549043.955279833],
  //   [737082.1942551732, 9549028.928605368],
  //   [737100.3934947685, 9549028.923510266],
  //   [737100.3969266146, 9549043.95018473],
  //   [737082.1976870192, 9549043.955279833],
  // ],
  shape: [
    [737076.1835723922, 9549038.410007201],
    [737092.1832776744, 9549024.94413115],
    [737129.8460999232, 9549065.317777187],
    [737110.3945287307, 9549077.532396676],
    [737076.1835723922, 9549038.410007201],
  ],
  priority: 1,
  type: "Polygon",
  default_color: "green",
  default_state: "on",
  button: [
    {
      clickable: true,
      icon: "seismic_0_button.png",
      priority: 1,
      title: "Seismic 0",
      alt: "Area A - Level 0",
      state: "on",
      color: "green",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "seismic_123_button.png",
      priority: 2,
      title: "Seismic 123",
      alt: "Area A - Level 123",
      state: "on",
      color: "red",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 5,
      title: "Travelway 1",
      alt: "Area A - Travelway 1",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 6,
      title: "Travelway 2",
      alt: "Area A - Travelway 2",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
  ],
  token: messageToken(),
});

export const testNamedArea46_2 = Immutable({
  id: "DMLZ_Extraction:AREA_46_2",
  name: "AREA_46_2",
  parent: "parentNamedArea",
  area: "DMLZ_Extraction",
  origin: "user",
  shape: [
    [737104.4299204884, 9549065.324892761],
    [737118.5470888059, 9549052.485655224],
    [737157.4651025238, 9549093.172005592],
    [737134.2485288989, 9549106.952957831],
    [737104.4299204884, 9549065.324892761],
  ],
  priority: 1,
  type: "Polygon",
  default_color: "green",
  default_state: "on",
  button: [
    {
      clickable: true,
      icon: "seismic_0_button.png",
      priority: 1,
      title: "Seismic 0",
      alt: "Area A - Level 0",
      state: "on",
      color: "green",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "seismic_123_button.png",
      priority: 2,
      title: "Seismic 123",
      alt: "Area A - Level 123",
      state: "on",
      color: "red",
      on_time: 0,
      off_time: 0,
    },

    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 5,
      title: "Travelway 1",
      alt: "Area A - Travelway 1",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 6,
      title: "Travelway 2",
      alt: "Area A - Travelway 2",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
  ],
  token: messageToken(),
});

export const testNamedArea46_3 = Immutable({
  id: "DMLZ_Extraction:AREA_46_3",
  name: "AREA_46_3",
  parent: "parentNamedArea",
  area: "DMLZ_Extraction",
  origin: "user",
  shape: [
    [737068.3386435859, 9549036.533869058],
    [737090.6125187926, 9549016.805121716],
    [737182.8875021795, 9549120.400737487],
    [737152.4547751799, 9549137.627323097],
    [737068.3386435859, 9549036.533869058],
  ],

  priority: 1,
  type: "Polygon",
  default_color: "green",
  default_state: "on",
  button: [
    {
      clickable: true,
      icon: "seismic_0_button.png",
      priority: 1,
      title: "Seismic 0",
      alt: "Area A - Level 0",
      state: "on",
      color: "green",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "seismic_123_button.png",
      priority: 2,
      title: "Seismic 123",
      alt: "Area A - Level 123",
      state: "on",
      color: "red",
      on_time: 0,
      off_time: 0,
    },

    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 5,
      title: "Travelway 1",
      alt: "Area A - Travelway 1",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 6,
      title: "Travelway 2",
      alt: "Area A - Travelway 2",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
  ],
  token: messageToken(),
});

export const testNamedArea46_4 = Immutable({
  id: "DMLZ_Extraction:AREA_46_4",
  name: "AREA_46_4",
  parent: "parentNamedArea",
  area: "DMLZ_Extraction",
  origin: "user",
  shape: [
    [737322.8971520573, 9549354.439185187],
    [737322.8928622497, 9549335.655842107],
    [737342.3450344562, 9549335.65039623],
    [737342.3493242637, 9549354.43373931],
  ],

  priority: 1,
  type: "Polygon",
  default_color: "green",
  default_state: "on",
  button: [
    {
      clickable: true,
      icon: "seismic_0_button.png",
      priority: 1,
      title: "Seismic 0",
      alt: "Area A - Level 0",
      state: "on",
      color: "green",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "seismic_123_button.png",
      priority: 2,
      title: "Seismic 123",
      alt: "Area A - Level 123",
      state: "on",
      color: "red",
      on_time: 0,
      off_time: 0,
    },

    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 5,
      title: "Travelway 1",
      alt: "Area A - Travelway 1",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
    {
      clickable: true,
      icon: "travelway_button.png",
      priority: 6,
      title: "Travelway 2",
      alt: "Area A - Travelway 2",
      state: "on",
      color: "amber",
      on_time: 0,
      off_time: 0,
    },
  ],
  token: messageToken(),
});

export const testNamedAreas = [
  testNamedArea46_1,
  testNamedArea46_2,
  testNamedArea46_3,
  testNamedArea46_4,
];

export const namedAreasGeoJson = [
  {
    type: "Feature",
    properties: {
      id: "DMLZ_Extraction:AREA_46_1",
      name: "AREA_46_1",
      parent: "parentNamedArea",
      area: "DMLZ_Extraction",
      priority: 1,
      Firefly_List:
        "DMLZ_Extraction:P17:3,DMLZ_Extraction:P17:1,DMLZ_Extraction:P17:2",
      active_color: "AMBER",
      active_state: "ON",
      button: [
        {
          clickable: true,
          icon: "seismic_0_button.png",
          priority: 1,
          title: "Seismic 0",
          alt: "Area A - Level 0",
          state: "on",
          color: "green",
        },
        {
          clickable: true,
          icon: "seismic_123_button.png",
          priority: 2,
          title: "Seismic 123",
          alt: "Area A - Level 123",
          state: "on",
          color: "red",
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 5,
          title: "Travelway 1",
          alt: "Area A - Travelway 1",
          state: "on",
          color: "amber",
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 6,
          title: "Travelway 2",
          alt: "Area A - Travelway 2",
          state: "on",
          color: "amber",
        },
      ],
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [737076.1835723922, 9549038.410007201],
          [737092.1832776744, 9549024.94413115],
          [737129.8460999232, 9549065.317777187],
          [737110.3945287307, 9549077.532396676],
          [737076.1835723922, 9549038.410007201],
        ],
      ],
    },
    token: messageToken(),
  },
  {
    type: "Feature",
    properties: {
      id: "DMLZ_Extraction:AREA_46_2",
      name: "AREA_46_2",
      parent: "parentNamedArea",
      area: "DMLZ_Extraction",
      priority: 1,
      Firefly_List:
        "DMLZ_Extraction:P17:4,DMLZ_Extraction:P17:5,DMLZ_Extraction:P17:3",
      button: [
        {
          clickable: true,
          icon: "seismic_0_button.png",
          priority: 1,
          title: "Seismic 0",
          alt: "Area A - Level 0",
          state: "on",
          color: "green",
        },
        {
          clickable: true,
          icon: "seismic_123_button.png",
          priority: 2,
          title: "Seismic 123",
          alt: "Area A - Level 123",
          state: "on",
          color: "red",
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 5,
          title: "Travelway 1",
          alt: "Area A - Travelway 1",
          state: "on",
          color: "amber",
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 6,
          title: "Travelway 2",
          alt: "Area A - Travelway 2",
          state: "on",
          color: "amber",
        },
      ],
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [737104.4299204884, 9549065.324892761],
          [737118.5470888059, 9549052.485655224],
          [737157.4651025238, 9549093.172005592],
          [737134.2485288989, 9549106.952957831],
          [737104.4299204884, 9549065.324892761],
        ],
      ],
    },
    token: messageToken(),
  },
  {
    type: "Feature",
    properties: {
      id: "DMLZ_Extraction:AREA_46_3",
      name: "AREA_46_3",
      parent: "parentNamedArea",
      area: "DMLZ_Extraction",
      priority: 1,
      Firefly_List:
        "DMLZ_Extraction:P17:6,DMLZ_Extraction:P17:4,DMLZ_Extraction:P17:5,DMLZ_Extraction:P17:1,DMLZ_Extraction:P17:7,DMLZ_Extraction:P17:3,DMLZ_Extraction:P17:2",
      button: [
        {
          clickable: true,
          icon: "seismic_0_button.png",
          priority: 1,
          title: "Seismic 0",
          alt: "Area A - Level 0",
          state: "on",
          color: "green",
        },
        {
          clickable: true,
          icon: "seismic_123_button.png",
          priority: 2,
          title: "Seismic 123",
          alt: "Area A - Level 123",
          state: "on",
          color: "red",
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 5,
          title: "Travelway 1",
          alt: "Area A - Travelway 1",
          state: "on",
          color: "amber",
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 6,
          title: "Travelway 2",
          alt: "Area A - Travelway 2",
          state: "on",
          color: "amber",
        },
      ],
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [737068.3386435859, 9549036.533869058],
          [737090.6125187926, 9549016.805121716],
          [737182.8875021795, 9549120.400737487],
          [737152.4547751799, 9549137.627323097],
          [737068.3386435859, 9549036.533869058],
        ],
      ],
    },
    token: messageToken(),
  },
];

export const officeControllers = [
  {
    id: "40:bd:32:e5:29:b3",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
  },
  {
    id: "74:e1:82:8d:c8:3c",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "04:a3:16:fd:75:63",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:8c:fc",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "74:e1:82:8d:4b:86",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "c8:df:84:d3:83:b2",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:8c:c6",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:4b:e3",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:ef:d8",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:3e:1b",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "f0:45:da:83:01:44",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "40:bd:32:e6:3e:31",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Undercut",
    token: messageToken(),
  },
  {
    id: "74:e1:82:91:03:b9",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Extraction",
    token: messageToken(),
  },
  {
    id: "04:a3:16:fd:75:60",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Extraction",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:2f:75",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Extraction",
    token: messageToken(),
  },
  {
    id: "74:e1:82:91:60:c5",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Extraction",
    token: messageToken(),
  },
  {
    id: "40:bd:32:e5:93:43",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Extraction",
    token: messageToken(),
  },
  {
    id: "0c:ae:7d:24:d6:49",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    utm: [9548941.662, 737591.217],
    z: 0,
    area: "DMLZ_Extraction",
    token: messageToken(),
  },
];

export const officeFireflies = [
  {
    id: "DMLZ_Extraction:P17:1",
    mac: "b4:e6:2d:ad:09:4d",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737090.94426965, 9549037.6893732],
    network_name: "controller 1",
    token: messageToken(),
  },

  {
    id: "DMLZ_Extraction:P17:2",
    mac: "b4:e6:2d:aa:54:b1",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737102.467831745, 9549052.2923983],
    network_name: "controller 2",
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:3",
    mac: "b4:e6:2d:ad:08:8d",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737113.991397232, 9549066.89542385],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:4",
    mac: "b4:e6:2d:ad:09:bd",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737125.514965885, 9549081.49844984],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:5",
    mac: "b4:e6:2d:a3:85:d9",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737137.038537933, 9549096.10147627],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:6",
    mac: "b4:e6:2d:ab:ca:81",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737148.562113146, 9549110.70450314],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:7",
    mac: "b4:e6:2d:ad:08:5d",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737160.085691747, 9549125.30753046],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:8",
    mac: "b4:e6:2d:ad:08:51",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737171.609273624, 9549139.91055823],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:9",
    mac: "b4:e6:2d:ab:ca:71",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737183.132858669, 9549154.51358644],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:10",
    mac: "b4:e6:2d:ad:0a:4d",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737194.656447105, 9549169.11661509],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:11",
    mac: "b4:e6:2d:aa:54:31",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737206.180038707, 9549183.71964419],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:12",
    mac: "b4:e6:2d:ab:ca:59",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737217.703633701, 9549198.32267373],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:13",
    mac: "b4:e6:2d:ab:ca:1d",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737229.227231967, 9549212.92570372],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:14",
    mac: "b4:e6:2d:ad:0b:fd",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737240.750833399, 9549227.52873416],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:15",
    mac: "b4:e6:2d:ad:09:c5",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737252.274438222, 9549242.13176504],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:16",
    mac: "b4:e6:2d:ad:08:b5",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737263.798046211, 9549256.73479636],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:17",
    mac: "b4:e6:2d:98:f7:95",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737275.321657588, 9549271.33782813],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:18",
    mac: "b4:e6:2d:a4:9b:a1",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737286.845272132, 9549285.94086035],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:19",
    mac: "b4:e6:2d:ab:fd:05",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737298.368890062, 9549300.54389302],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:20",
    mac: "b4:e6:2d:ad:0b:89",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737309.892511267, 9549315.14692612],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:21",
    mac: "b4:e6:2d:a4:46:09",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737321.416135637, 9549329.74995968],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:22",
    mac: "b4:e6:2d:ad:09:9d",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 0,
    utm: [737332.939763397, 9549344.35299368],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:23",
    mac: "b4:e6:2d:ab:fc:e9",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 2596.139,
    utm: [736820.393, 9549226.979],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:24",
    mac: "b4:e6:2d:ad:0a:29",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 2593.24,
    utm: [736959.54, 9549076.153],
    token: messageToken(),
  },
  {
    id: "DMLZ_Extraction:P17:25",
    mac: "b4:e6:2d:98:f1:61",
    utm_zone_number: 53,
    utm_zone_letter: "M",
    Z: 2592.711,
    utm: [737526.058, 9549315.041],
    token: messageToken(),
  },
];
