// see - https://github.com/skyeer/react-leaflet-custom-control
// react-leaflet v2 wrapper for the leaflet-search-control
//

import { Children } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";
//import PropTypes from "prop-types";

//import SearchControl from "./SearchControl";
import SearchControl from "../leaflet-search-control";

class Control extends MapControl {
  constructor(props, context) {
    super(props);
  }

  createLeafletElement(options) {
    const searchControl = new SearchControl(options);
    return searchControl;
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    map.addControl(this.leafletElement);

    //To suppress control events from propagation
    const containerDiv = this.leafletElement.getContainer();
    L.DomEvent.disableClickPropagation(containerDiv);
  }

  //   createLeafletElement({ position, className, placeholder, data }) {
  //     this.leafletElement = new SearchControl({
  //       position,
  //       className,
  //       placeholder,
  //       data,
  //     });
  //     return this.leafletElement;
  //   }

  //   updateLeafletElement(fromProps, toProps) {
  //     super.updateLeafletElement(fromProps, toProps);
  //     this.renderContent();
  //   }

  //   componentDidMount() {
  //     super.componentDidMount();
  //     this.renderContent();
  //   }

  //   componentWillUnmount() {
  //     unmountComponentAtNode(this.leafletElement.getContainer());
  //     super.componentWillUnmount();
  //   }

  //   renderContent() {
  //     const container = this.leafletElement.getContainer();
  //     render(Children.only(this.props.children), container);
  //   }
}

export default withLeaflet(Control);
