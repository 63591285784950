import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MineLevelSeismicFeatureGroup from 'components/MineLevelSeismicFeatureGroup'
import { getCurrentSeismicLayer } from 'seismicOverlays/reducer'
import { fetchCurrentSeismicLayerForMineLevel } from 'seismicOverlays/actions'
import { withMineLevel } from 'ApplicationContexts'
import { FeatureGroup } from 'react-leaflet'

class CurrentSeismicFeatureGroup extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.dispatch(
      fetchCurrentSeismicLayerForMineLevel(this.props.mineLevel.id)
    )
  }
  componentDidUpdate (prevProps) {
    if (prevProps.mineLevel.id !== this.props.mineLevel.id) {
      this.props.dispatch(
        fetchCurrentSeismicLayerForMineLevel(this.props.mineLevel.id)
      )
    }
  }

  render () {
    if (!this.props.currentSeismicLayer) {
      return <FeatureGroup />
    }
    return (
      <FeatureGroup>
        <MineLevelSeismicFeatureGroup
          seismicLayerId={this.props.currentSeismicLayer.id}
        />
      </FeatureGroup>
    )
  }
}

export function mapStateToProps (state, { mineLevel }) {
  let currentSeismicLayer = getCurrentSeismicLayer(state, mineLevel.id)

  return {
    currentSeismicLayer
  }
}

export default withMineLevel(
  connect(mapStateToProps)(CurrentSeismicFeatureGroup)
)
