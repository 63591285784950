const SEMANTIC_DEFAULTS = {
  red: "#DB2828",
  orange: "#F2711C",
  yellow: "#FBBD08",
  amber: "#FBBD08", // IOT Addition
  olive: "#B5CC18",
  green: "#21BA45",
  teal: "#00B5AD",
  blue: "#2185D0",
  violet: "#6435C9",
  purple: "#A333C8",
  pink: "#E03997",
  brown: "#A5673F",
  grey: "#767676",
  black: "#1B1C1D",
  off: "#1B1C1D", // IOT Addition
  white: "white", // IOT Addition
};

function getSemanticColor(color, fallback = "black") {
  const semantic = SEMANTIC_DEFAULTS[color.toLowerCase()];
  if (semantic) {
    return semantic;
  }
  return SEMANTIC_DEFAULTS[fallback.toLowerCase()];
}

export default getSemanticColor;

export function getEquivalentSemanticColorName(color) {
  return {
    off: "black",
    white: "grey",
    green: "green",
    red: "red",
    amber: "orange",
    orange: "orange",
    blue: "blue",
  }[color.toLowerCase()];
}
