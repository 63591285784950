import React, { Component } from "react";
import { connect } from "react-redux";

import { Field, getFormValues, formValueSelector, reduxForm } from "redux-form";

import { Slider } from "react-semantic-ui-range";
import "semantic-ui-css/semantic.min.css";

import {
  Modal,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  GridColumn,
  GridRow,
  Message,
  List,
  Icon,
  Checkbox,
  Table,
  Popup,
  Pagination,
  Dropdown,
} from "semantic-ui-react";

import _isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import { renderSelect, renderField, renderRadio } from "admin/form-field";

import {
  isConfigJs,
  namedAreaIconOptions,
  isUserRelay as _isUserRelay,
  allowedMapMarkers,
} from "components/ConfigJs";

import { getAllNamedAreaStatuses } from "components/WebWorker/reducer";
import { minValueEqual, maxValueEqual } from "utils/normalise";
import { eventTriggerOptions as _eventTriggerOptions } from "components/ConfigJs";
import { isKthBitSet, bit_toggle } from "utils/bit-operations";

import {
  getAllUsersByRole,
  getAllUsersRoleUserSettingsNamedAreaGroup,
} from "components/UserAdmin/reducer";

import {
  allowedLightColors as _allowedLightColors,
  allowedLightStates as _allowedLightStates,
  allowedMapMarkers as _allowedMapMarkers,
} from "components/ConfigJs";
import { filterByLocation } from "admin/filters";
import hash from "object-hash";
import { changeSettings, fetchAllUsers } from "components/UserAdmin/actions";
import { getUserSettings } from "auth/reducer";
import { CronWizard } from "admin/named-area-group/NamedAreaGroupCronWizard";

// range limits
const minValueEqual0 = minValueEqual(0);

const maxValueEqual15 = maxValueEqual(15);

const checkCronExpression = true; // #TODO!!!!!!!!!!!!!!

// NOT USED - left here for fun for someone to find in the future
const brightnessLevelOptions = [
  //    OFF = value is 0 - is available but not added as there is an available state = OFF
  {
    key: "low",
    text: "Low",
    value: 1,
  },
  {
    key: "medium",
    text: "Medium",
    value: 2,
  },
  {
    key: "high",
    text: "High",
    value: 3,
  },
  {
    key: "bright",
    text: "Bright",
    value: 4,
  },
  {
    key: "work",
    text: "Work",
    value: 5,
  },
];

// do not check for 0 as falsy
const required = (value) => (value !== "" ? undefined : "Required");

const validate = (values) => {
  const required = ["color", "icon", "hint", "on_time", "off_time"];

  const errors = {};

  required.forEach((r) => {
    const value = get(values, r);

    if (!value && value !== 0) {
      set(errors, r, "Required");
    }
  });

  //console.log("@@ validate errors", errors);

  return errors;
};

const normalise = (value, type) => {
  switch (type) {
    case "on_time":
      return (value >= 0 && parseInt(value, 10)) || 0;
    case "off_time":
      return (value >= 0 && parseInt(value, 10)) || 0;
    case "train":
      return (value >= 0 && parseInt(value, 10)) || 0;
    default:
      return value;
  }
};

class NamedAreaGroupSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: "user", // default to sort on user column
      data: props.data,
      direction: null,
      modalOpen: false,
      isConfigJs: isConfigJs(),
      namedAreaIconOptions: namedAreaIconOptions(),
      isUserRelay: false,
      //
      permissions: 0,
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 5,
    };
  }

  componentDidMount() {
    const { open, data } = this.props;
    if (open) {
      this.handleOpen();
    }
    this.setState({ data: data });
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }

    if (
      JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) // if original props data changes
    ) {
      let newData = [...this.state.data]; // original data

      // restore sort
      const { column, direction } = this.state;

      //console.log("xxx componentDidUpdate newData", newData);
      //console.log("xxx componentDidUpdate column", column);
      //console.log("xxx componentDidUpdate direction", direction);

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      if (direction === "descending") {
        newData = newData.reverse();
      }

      this.setState({
        data: newData,
      });
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.resetModal();

    // reset the form
    this.setState({ value: "" }, () => this.props.reset());
    //    this.props.onCancel();
  };

  handleReset = () => {
    const { data, reset } = this.props;

    // reset the accessSettings (i.e. data)
    this.setState({ data });

    reset();
  };

  handleDelete = (e, idx) => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.handleSettingsDelete(idx);
  };

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    // console.log(
    //   "xxx column, data, direction, clickedColumn",
    //   column,
    //   data,
    //   direction,
    //   clickedColumn
    // );

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });

      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  saveAccessSettings = (data) => {
    const { allUserUsers, changeSettings } = this.props;

    // console.log("xxx 666 saveAccessSettings", data);
    // console.log("xxx 666 allUsersUser", allUserUsers);

    let promiseArray = [];

    data.forEach((as) => {
      // flag
      let isSaveUserChanges = false;

      const userUser = allUserUsers.find((u) => u.email === as.user);

      // deep copy to update info
      let newUserUser = JSON.parse(JSON.stringify(userUser));

      // console.log("xxx 666 newUserUser", newUserUser);

      let newSettingNamedAreaGroup = newUserUser.settings.namedAreaGroup || [];

      // console.log("xxx 666 newSettingNamedAreaGroup", newSettingNamedAreaGroup);
      // console.log("xxx 666 as", as);

      // find existing nag setting for this user id
      const userNagSetting = newSettingNamedAreaGroup.find(
        (nag) => nag.id === as.setting.id
      );

      // console.log("xxx 666 userNagSetting ", userNagSetting);

      // if exists
      if (userNagSetting !== undefined) {
        // compare userNagSetting.access with as.user.setting
        // to see if changed
        // console.log(
        //   "xxx 666 changed? - userNagSetting.access",
        //   JSON.stringify(userNagSetting.access),
        //   " !== as.setting.access ",
        //   JSON.stringify(as.setting.access)
        // );

        // if the new settings === see all (i.e. default)
        if (JSON.stringify(as.setting.access) === `["r","w","x"]`) {
          // filter the existing setting for this group id
          newSettingNamedAreaGroup = newSettingNamedAreaGroup.filter(
            (nag) => nag.id !== as.setting.id
          );
          // queue to save
          isSaveUserChanges = true;
        } else if (
          // settings changed
          JSON.stringify(userNagSetting.access) !==
          JSON.stringify(as.setting.access)
        ) {
          // console.log("xxx 666 SETTINGS CHANGED");
          //
          // filter existing setting for this group id
          newSettingNamedAreaGroup = newSettingNamedAreaGroup.filter(
            (nag) => nag.id !== as.setting.id
          );
          // push new one
          newSettingNamedAreaGroup.push(as.setting);
          // save
          isSaveUserChanges = true;
        }
      } else {
        if (JSON.stringify(as.setting.access) !== `["r","w","x"]`) {
          // push new one
          newSettingNamedAreaGroup.push(as.setting);
          // save
          isSaveUserChanges = true;
        }
      }

      if (isSaveUserChanges) {
        // console.log(
        //   "xxx 666 newSettingNamedAreaGroup",
        //   newSettingNamedAreaGroup
        // );

        newUserUser.settings.namedAreaGroup = newSettingNamedAreaGroup;

        const newValues = {
          id: newUserUser.email,
          settings: newUserUser.settings,
        };

        // console.log("xxx 666 newValues ", newValues);
        // console.log(
        //   "xxx 666 newValues newSettingNamedAreaGroup",
        //   newSettingNamedAreaGroup
        // );

        promiseArray.push(
          new Promise((resolve, reject) => {
            changeSettings({
              values: newValues,
              resolve,
              reject,
            });
          })
        );
      }
    });

    //console.log("xxx promiseArray", promiseArray);

    return Promise.all(promiseArray).then((results) => {
      //console.log(`onSubmitSuccess ${results}`);
      this.props.fetchAllUsers();
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (e, { activePage }) => {
    this.setState({ page: activePage });
  };

  myHandleSubmit = (values) => {
    // close the modal
    this.setState({ modalOpen: false });

    // check/change CRON from 'cron' and 'cron_duration' to two separate cron expressions.
    let newValues = { ...values };

    const cronOne = newValues?.settings?.cron || "";
    const cronDuration = newValues?.settings?.cron_duration || 0;
    const cronUserRelay = newValues?.settings?.cron_user_relay || 0;

    const cronTwo = "";

    // #HERENOW #WIP
    // Get wizard to work out how to make offset structure

    const newCron = JSON.stringify([
      { cron: "0-59/10 * * * * *", active: true, user_relay: 5 },
      { cron: "0-59/11 * * * * *", active: false, user_relay: 5 },
    ]);

    // make sure cron setting is available in all cases
    let newSettings = { ...newValues.settings };
    newSettings.cron = newCron;
    newValues.settings = newSettings;

    // if accessSettings have changed, save these separately
    if (values.changedAccessSettings) {
      this.saveAccessSettings(this.state.data);
    }

    // save the changes
    this.props.handleSettingsSave(newValues);
  };

  handleActiveColorChange = (e, data) => {
    if (data === "off") {
      this.props.change("settings.active_state", "off");
    }
  };

  handleActiveStateChange = (e, data) => {
    if (data === "off") {
      this.props.change("settings.active_color", "off");
    }
  };

  onChangePermission = (e, data) => {
    const { stateValuePermission, change } = this.props;

    const { optionKey } = data;

    let newPermissions = stateValuePermission;
    // optionKey is the bit position
    // toggle the bit position
    newPermissions = bit_toggle(newPermissions, optionKey - 1);

    change("settings.permission", newPermissions);
  };

  permissionRadiosContent = (options) => {
    let content = [];

    if (options.length === 0) {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={"warning"} style={{ marginRight: "10px" }} />
          {this.props.strings?.["No event sources enabled"]}
        </Form.Field>
      );
    }

    options.map((option) => {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={option.icon} />
          <Checkbox
            optionKey={option.key}
            label={option.text}
            checked={option.value}
            onChange={this.onChangePermission}
            Checkbox
          />
        </Form.Field>
      );
    });

    return (
      <Form>
        <Grid.Row>
          <Grid.Column>
            <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>
              {this.props.strings?.["Allowed Event Source"]}
            </div>
          </Grid.Column>
        </Grid.Row>
        {content}
      </Form>
    );
  };

  onClickHandlerAccessIcon = (e, access, user) => {
    const { change, data: propData } = this.props;

    const { data } = this.state;
    let newData = [...data];

    // remove this user
    newData = newData.filter((setting) => setting.user !== user.user);

    // update access from toggled icon - deep clone
    let newUser = JSON.parse(JSON.stringify(user));

    switch (access) {
      case "show":
        if (user.setting.access?.[0] === "r") {
          newUser.setting.access[0] = "-";
          // if no show then no control too
          newUser.setting.access[2] = "-";
        } else {
          newUser.setting.access[0] = "r";
        }
        break;
      case "control": // i.e. eXecute
        if (user.setting.access?.[2] === "x") {
          newUser.setting.access[2] = "-";
        } else {
          newUser.setting.access[2] = "x";
        }

        break;

      default:
        break;
    }

    newData.push(newUser);

    // accessSettings are not a part of the form data, but
    // see if access settings have changed from original props,
    // if changed back then reset form flag
    if (isEqual(propData, newData)) {
      this.setState({ data: newData }, () => {
        change("changedAccessSettings", false);
      });
    } else {
      this.setState({ data: newData }, () => {
        change("changedAccessSettings", true);
      });
    }
  };

  accessIcon = (access, user, strings) => {
    let popupMsg = "unknown";
    let iconName = "question";
    let iconColor = "grey";
    switch (access) {
      case "show":
        popupMsg = "Click to toggle Show/Hide";
        user?.setting?.access?.includes("r")
          ? (iconName = "checkmark")
          : (iconName = "close");
        break;
      case "control":
        popupMsg = "Click to toggle Control enable/disable";
        user?.setting?.access?.includes("x")
          ? (iconName = "checkmark")
          : (iconName = "close");
        break;
      default:
        break;
    }

    if (iconName === "checkmark") iconColor = "green";

    return (
      <Popup
        content={strings?.[popupMsg]}
        trigger={
          <Icon
            name={iconName}
            color={iconColor}
            onClick={(e) =>
              this.onClickHandlerAccessIcon(
                e,
                access, // access type
                user //
              )
            }
          />
        }
      />
    );
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      reset,
      initialValues,
      change,
      namedAreas,
      eventTriggerOptions,
      stateValuePermission,
      strings,
      userSettings,
    } = this.props;

    const { isConfigJs, namedAreaIconOptions, column, data, direction } =
      this.state;

    // display section if STROBE, FORWARD, BACKWARDS
    const { isPriorityLevel, isStateStrobe, isStateForwardBackward } =
      this.props;

    //    console.log("modal initialValues", initialValues);
    const { namedArea, subItems } = initialValues;

    const isUserRelay =
      userSettings?.feature?.includes("showUserRelay") || _isUserRelay();

    // // check if this is cog config for priority `level` i.e. priorityLevel
    // // check for namedArea object in subItems
    // let priorityLevel = false;
    // if (!_isEmpty(subItems)) {
    //   priorityLevel = subItems.some((items) => !_isEmpty(items?.namedArea));
    // }

    let buttonOptions = [];

    if (namedArea) {
      let iconOptions = [];
      if (isConfigJs && namedAreaIconOptions) {
        iconOptions = namedAreaIconOptions; // from config.js file
      }

      //      console.log("iconOptions", JSON.stringify(iconOptions));

      const { id } = namedArea;

      //      console.log("namedAreas", namedAreas);

      // only need first result of named areas since all button groups are the same
      const namedAreaParentButton = namedAreas.find(
        (na) => na.parent === id
      )?.button;
      //      console.log("namedAreaParentButton", namedAreaParentButton);
      if (namedAreaParentButton) {
        namedAreaParentButton.forEach((button, idx) => {
          const { priority, title, icon, color, clickable } = button;
          const image = iconOptions.find(
            (option) => option.value === icon
          )?.image;
          buttonOptions.push({
            key: priority,
            value: priority,
            color: color,
            text: title,
            image: image, // { src: "/images/SIESMIC_0.png" },
          });
        });
      }
    }

    const sectionTitle = namedArea
      ? strings?.["Polygon"]
      : isPriorityLevel
      ? strings?.["Label"]
      : strings?.["Label"]; // #NOTE #WIP - this should label "Group Label" and "Level label" but this detection of what is a Group and what is a Level is broken.

    const allowedLightColors = _allowedLightColors();
    const allowedLightStates = _allowedLightStates();
    const allowedMapMarkers = _allowedMapMarkers();

    const defaultAccessRow = (
      <>
        <Table.Cell>*</Table.Cell>
        <Table.Cell textAlign="center">
          <Icon color="green" name="checkmark" />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Icon color="green" name="checkmark" />
        </Table.Cell>
        {/* <Table.Cell textAlign="center">-</Table.Cell> */}
      </>
    );

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 5, text: "5", key: "accessUserlist5" },
              { value: 10, text: "10", key: "accessUserlist10" },
              { value: 20, text: "20", key: "accessUserlist20" },
              { value: data.length, text: "all", key: "accessUserlistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Total of {itemsCount} items.</span>{" "}
        </div>
      );
    }

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header icon="cog" content={strings?.["Edit Settings"]} />
        <Modal.Content>
          <Form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(this.myHandleSubmit)(e);
            }}
          >
            <Grid columns={1}>
              <GridColumn>
                <GridRow>
                  <Field
                    name="label"
                    label={sectionTitle}
                    disabled={namedArea} // disable label edit for namedArea
                    component={renderField}
                  />
                  {namedArea && (
                    <>
                      {/* <Field
                      name="namedArea.button.priority"
                      label="Event"
                      component={renderSelect}
                      options={buttonOptions}
                      //onChange={handleIconChange}
                    /> */}
                      <Field
                        name="settings.title"
                        label={strings?.["Custom Button Label"]}
                        component={renderField}
                        placeholder={strings?.["Custom label for buttons"]}
                      />
                      <Field
                        name="id"
                        label={strings?.["Polygon ID"]}
                        component={renderField}
                        placeholder={strings?.["Polygon Unique ID"]}
                        disabled={true}
                      />
                      {false && (
                        <>
                          {/*
                          // #NOTE - this works to set permissions directly. 
                          // Disable, but useful for testing.
                          // Not localised!
                           */}
                          <Message
                            header={strings?.["Button Permissions Register"]}
                            content={
                              <>
                                Enter an integer to set the bit register.
                                <List>
                                  <List.Item>
                                    Bit 1 - Set to enable external trigger
                                    control
                                  </List.Item>
                                  <List.Item>
                                    Bit 2 ... - For future use
                                  </List.Item>
                                </List>
                                e.g. Enter "1" to enable external trigger
                                control, enter "0" to disable control
                              </>
                            }
                          />
                          <Field
                            name="settings.permission"
                            placeholder={
                              strings?.[
                                "Bit register of button permissions e.g. 1"
                              ]
                            }
                            label={strings?.["Button Permissions"]}
                            type="number"
                            step={1}
                            min={0}
                            max={15}
                            component={renderField}
                            validate={[
                              required,
                              minValueEqual0,
                              maxValueEqual15,
                            ]}
                            parse={(value) =>
                              value !== "" ? Number(value) : ""
                            }
                          />
                        </>
                      )}
                      <Segment>
                        <Grid style={{ padding: "10px" }}>
                          {this.permissionRadiosContent(eventTriggerOptions)}
                        </Grid>
                      </Segment>
                      {
                        // Set CRON if Scheduled Event checked
                        // #REVIEW - remove after scheduled event jobs are completed via separate page.
                        //
                        false && isKthBitSet(stateValuePermission, 2) && (
                          <Segment>
                            <Grid>
                              <Grid.Row style={{ paddingBottom: "5px" }}>
                                <Grid.Column>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {strings?.["Scheduled Event Settings"]}
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row
                                columns="3"
                                style={{ paddingTop: "5px" }}
                              >
                                <Grid.Column width={5}>
                                  <Field
                                    name="settings.cron"
                                    label={
                                      strings?.["CRON Schedule Expression"]
                                    }
                                    component={renderField}
                                    placeholder={
                                      strings?.["CRON Schedule Expression"]
                                    }
                                    //validate={[checkCronExpression]} // #TODO!!!!
                                  />
                                </Grid.Column>
                                <Grid.Column width={2}>
                                  <Field
                                    name="settings.cron_duration"
                                    label={strings?.["Duration"]}
                                    placeholder={strings?.["Duration"]}
                                    component={renderField}
                                    type="number"
                                    //normalize={numberNormalize}
                                    step={1}
                                    min={0}
                                    max={60}
                                    validate={[required, minValueEqual0]}
                                    parse={(value) =>
                                      value !== "" ? parseInt(value) : ""
                                    }
                                    //className={disabled ? "disabled-form-field" : null}
                                    //disabled={disabled}
                                  />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                  <Field
                                    name="settings.cron_user_relay"
                                    placeholder={
                                      strings?.[
                                        "Bit register of User_Relay e.g. 1"
                                      ]
                                    }
                                    label={strings?.["User Relay"]}
                                    type="number"
                                    step={1}
                                    min={0}
                                    max={15}
                                    component={renderField}
                                    validate={[
                                      required,
                                      minValueEqual0,
                                      maxValueEqual15,
                                    ]}
                                    parse={(value) =>
                                      value !== "" ? Number(value) : ""
                                    }
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        )
                      }
                      <div style={{ visibility: "hidden" }}>
                        {/* #WIP #TODO */}
                        <Field
                          name="settings.hint"
                          label={strings?.["Hint"]}
                          component={renderField}
                          placeholder={"The hint for the button"}
                        />
                      </div>
                    </>
                  )}
                </GridRow>
              </GridColumn>
            </Grid>
            {isPriorityLevel && (
              <>
                <Segment>
                  <Header as="h3">{strings?.["Light Settings"]}</Header>
                  <Grid columns={2}>
                    <Grid.Column>
                      <Field
                        name="settings.active_color"
                        label={strings?.["Active Color of Lights"]}
                        component={renderSelect}
                        options={allowedLightColors}
                        onChange={this.handleActiveColorChange}
                      />
                      {false && (
                        <Field
                          style={{ paddingTop: "10px" }}
                          name="settings.active_brightness_level"
                          label={strings?.["Active Brightness Level"]}
                          component={renderSelect}
                          options={brightnessLevelOptions}
                          onChange={this.handleActiveBrightnessLevelChange}
                        />
                      )}
                      <Field
                        style={{ paddingTop: "10px" }}
                        name="settings.marker"
                        label={strings?.["Display Marker Icon"]}
                        component={renderSelect}
                        options={allowedMapMarkers}
                      />
                    </Grid.Column>
                    <GridColumn>
                      <GridRow>
                        <Field
                          name="settings.active_state"
                          label={strings?.["Active State of Lights"]}
                          component={renderSelect}
                          options={allowedLightStates}
                          onChange={this.handleActiveStateChange}
                        />
                        {isStateStrobe && (
                          <div>
                            <Field
                              style={{ paddingTop: "10px" }}
                              name="settings.off_time"
                              label={strings?.["Off Time"]}
                              normalize={(value) =>
                                normalise(value, "off_time")
                              }
                              component={renderField}
                            />
                            <Field
                              name="settings.on_time"
                              label={strings?.["On Time"]}
                              normalize={(value) => normalise(value, "on_time")}
                              component={renderField}
                            />
                          </div>
                        )}
                        {isStateForwardBackward && (
                          <div style={{ paddingTop: "10px" }}>
                            <Field
                              name="settings.train"
                              label={strings?.["Train Time"]}
                              normalize={(value) => normalise(value, "train")}
                              component={renderField}
                            />
                          </div>
                        )}
                      </GridRow>
                    </GridColumn>
                  </Grid>
                </Segment>
                {isStateForwardBackward && (
                  <Segment>
                    <Message
                      color="orange"
                      icon={"warning"}
                      header={
                        strings?.["About Active State Forward and Backward"]
                      }
                      content={strings?.["Warning_FORWARD_BACKWARD"]}
                    />
                  </Segment>
                )}
                {isUserRelay && (
                  <Segment>
                    <Header as="h3">{strings?.["Relay Settings"]}</Header>
                    <Grid columns={2}>
                      <Grid.Column>
                        <Form.Group inline style={{ paddingTop: "5px" }}>
                          <label style={{ paddingBottom: "6px" }}>
                            {strings?.["Siren"]}
                          </label>
                          <Field
                            component={renderRadio}
                            label="ON"
                            name="settings.siren_state"
                            radioValue={"on"}
                          />
                          <Field
                            component={renderRadio}
                            label="OFF"
                            name="settings.siren_state"
                            radioValue={"off"}
                          />
                        </Form.Group>
                      </Grid.Column>
                      <Grid.Column></Grid.Column>
                    </Grid>
                  </Segment>
                )}
              </>
            )}
            {!isPriorityLevel && !namedArea && (
              <Segment>
                <Header as="h3">{strings?.["Access Settings"]}</Header>
                <Message icon color="orange">
                  <Icon name="warning" />
                  <Message.Content>
                    {strings?.["ACCESS_MESSAGE"]}
                  </Message.Content>
                </Message>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Table celled structured striped>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              rowSpan="2"
                              sorted={column === "user" ? direction : null}
                              onClick={this.handleSort("user")}
                            >
                              {strings?.["User"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">
                              {strings?.["Permission"]}
                            </Table.HeaderCell>
                            {/* <Table.HeaderCell rowSpan="2" textAlign="center">
                              <Popup
                                content={strings?.["Click to delete row"]}
                                trigger={<Icon name="trash" />}
                              />
                            </Table.HeaderCell> */}
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell>
                              {strings?.["Show"]}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              {strings?.["Control"]}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body key={hash(viewablesPage)}>
                          {viewablesPage.length < 1 ? (
                            <Table.Row>{defaultAccessRow}</Table.Row>
                          ) : (
                            viewablesPage.map((user) => (
                              <Table.Row>
                                <Table.Cell>{user.user}</Table.Cell>
                                <Table.Cell textAlign="center">
                                  {this.accessIcon("show", user, strings)}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {this.accessIcon("control", user, strings)}
                                </Table.Cell>
                                {/* <Table.Cell textAlign="center">
                                  <Icon name="trash" />
                                </Table.Cell> */}
                              </Table.Row>
                            ))
                          )}
                        </Table.Body>
                      </Table>
                      {pagination}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}

            <Segment>
              <Grid columns={2}>
                <GridColumn>
                  {/* <GridRow>
                    <DeleteButton
                      onClick={(e) => this.handleDelete(e, buttonIdx)}
                    />
                  </GridRow> */}
                </GridColumn>
                <GridColumn>
                  <GridRow>
                    <Button.Group floated="right">
                      <Button
                        color="green"
                        type="submit"
                        disabled={pristine || submitting}
                      >
                        {strings?.["Save"]}
                      </Button>
                      <Button.Or />
                      <Button
                        type="button"
                        disabled={pristine || submitting}
                        onClick={this.handleReset}
                      >
                        {strings?.["Reset"]}
                      </Button>
                      <Button.Or />
                      <Button
                        style={{ textAlign: "right" }}
                        onClick={this.handleCancel}
                      >
                        {strings?.["Cancel"]}
                      </Button>
                    </Button.Group>
                  </GridRow>
                </GridColumn>
              </Grid>
            </Segment>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

NamedAreaGroupSettingsModal = reduxForm({
  form: "namedAreaGroupSettingsModal",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
})(NamedAreaGroupSettingsModal);

const selector = formValueSelector("namedAreaGroupSettingsModal");

function mapStateToProps(state, props) {
  const { initialValues, areaId } = props;
  const namedAreas = getAllNamedAreaStatuses(state);

  const { subItems } = initialValues;
  // check if this is cog config for priority `level` i.e. priorityLevel
  // check for namedArea object in subItems
  let isPriorityLevel = false;

  // #NOTE #WIP - this does not work to determine if it is a level.
  // If there are no namedAreas added the title will default to GROUP
  //
  if (!_isEmpty(subItems)) {
    isPriorityLevel = subItems.some((items) => !_isEmpty(items?.namedArea));
  }

  const stateValueActiveState = selector(state, "settings.active_state");
  const isStateStrobe = ["strobe", "forward", "backward"].includes(
    stateValueActiveState
  );
  const isStateForwardBackward = ["forward", "backward"].includes(
    stateValueActiveState
  );

  const userSettings = getUserSettings(state);

  // console.log("xxx modal userSettings", userSettings);

  // setup permission radio button
  const eventTriggerOptionsTemplate = _eventTriggerOptions(userSettings); // load setup for permission register
  const stateValuePermission = selector(state, "settings.permission");

  let eventTriggerOptions = [];

  // console.log(
  //   "xxx modal eventTriggerOptionsTemplate ",
  //   eventTriggerOptionsTemplate
  // );

  eventTriggerOptionsTemplate.forEach((item) => {
    const isBitSet = isKthBitSet(stateValuePermission, item.key) || false;

    eventTriggerOptions.push({ ...item, value: isBitSet });
  });

  // console.log("xxx modal eventTriggerOptions ", eventTriggerOptions);

  const allUserUsers = getAllUsersByRole(state, "user");

  //console.log("xxx 123 allUserUsers", allUserUsers);

  const allAccessSettings = getAllUsersRoleUserSettingsNamedAreaGroup(
    state,
    areaId
  );

  // console.log("xxx 123 allAccessSettings", allAccessSettings);

  // filter for this group
  const accessSettings = allAccessSettings.map((item) => {
    let defaultSettings = {
      id: initialValues.id,
      area: areaId,
      access: ["r", "w", "x"],
    };

    let newSettings = item.settings?.find?.(
      (nag) => nag.id === initialValues.id
    );

    // console.log("xxx 123 newSettings item", item);
    // console.log("xxx 123 newSettings initialValues", initialValues);
    // console.log("xxx 123 newSettings", newSettings);

    if (newSettings === undefined) {
      newSettings = defaultSettings;
    }

    return {
      user: item.user,
      setting: newSettings,
    };
  });

  const sortAccessSettings = accessSettings.sort((a, b) =>
    a.user > b.user ? 1 : -1
  );

  //console.log("xxx initialValues", initialValues);
  //console.log("xxx areaId", areaId);
  //console.log("xxx 123 accessSettings", accessSettings);

  // get user access permissions
  // add access settings flag to initial settings
  const newInitialValues = { ...initialValues, changedAccessSettings: false };

  return {
    initialValues: newInitialValues, // props passed to redux-form loads these as (predictably), initial values
    namedAreas,
    isStateStrobe,
    isStateForwardBackward,
    isPriorityLevel,
    eventTriggerOptions,
    stateValuePermission,
    data: sortAccessSettings, // page 'data'
    allUserUsers,
    formValues: getFormValues("namedAreaGroupSettingsModal")(state), // #REVIEW - only used for debugging,
    onChange: (values, dispatch, props, previousValues) => {
      const { change, isPriorityLevel, namedArea } = props;

      //console.log("onChange values", values);
      //console.log("onChange props", values);

      if (!namedArea && !isPriorityLevel) {
        //console.log("xxx onChange values", values);
      }

      if (isPriorityLevel) {
        const {
          settings: { active_color },
        } = values;

        dispatch(change("settings.color", active_color));
      }
    },
  };
}

export default connect(mapStateToProps, {
  changeSettings,
  fetchAllUsers,
})(NamedAreaGroupSettingsModal);
