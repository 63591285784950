import React from "react";
import { Dropdown } from "semantic-ui-react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout, reset } from "auth/actions";
import { getEmail, getUsername } from "auth/reducer";
import { UpdateMqttReset } from "components/WebWorker/actions";
import { withComponentStateCache } from "react-component-state-cache";

import { clearCookie } from "cookies";

// #NOTE
// #WIP - same functionality as
// src/components/User/UserMenuLogout.js
// >>>>COMBINE FUNCTIONS/COMPONENTS

const UserMenu = ({
  logout,
  reset,
  username,
  email,
  UpdateMqttReset,
  componentstate,
  //
  strings, // localisation
}) => {
  const UserLogout = () => {
    UpdateMqttReset(true); // terminate webworker
    reset();

    // remove cache state for data fetch
    componentstate.remove("FETCH_ALL_DATA");

    logout();

    // clear authentication cookie
    clearCookie("jwt-token");
  };

  return (
    <Dropdown item simple text={username} className="link item">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/user/profile/${email}`}>
          {strings?.["Profile"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/help/files`}>
          {strings?.["Help"]}
        </Dropdown.Item>
        <Dropdown.Item onClick={UserLogout}>
          {strings?.["Logout"]}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

function mapStateToProps(state, props) {
  const username = getUsername(state);
  const email = getEmail(state);

  return {
    username,
    email,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    logout,
    reset,
    UpdateMqttReset,
  })(UserMenu)
);
