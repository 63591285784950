import React, { Component } from "react";
import { connect } from "react-redux";
import { push as goto } from "react-router-redux";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Checkbox,
  Popup,
} from "semantic-ui-react";
import _isEqual from "lodash/isEqual";

import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";
import GenericButton from "admin/GenericButton";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { format } from "date-fns";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";

import { fetchEventLogPeriod } from "components/EventLog/actions";
import { getEventLog } from "components/EventLog/reducer";
import hash from "object-hash";
import { toWebColor } from "utils/toWebColor";
import { formatDate } from "utils/format-date";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

import { CSVLink } from "react-csv";

// XLSL 'react-export-excel' IMPLEMENTATION
// click button to download report
// uses https://www.npmjs.com/package/react-export-excel
// see ex - https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/with_custom_download_element.md
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  render() {
    const { data, buttonStyle } = this.props;

    const filename = `iot_event_log_${formatDate(
      new Date(Date.now()),
      "dd-MMM-yy-HH-mm-ss"
    )}`;

    return (
      <ExcelFile
        filename={filename}
        element={
          <Popup
            content="Download XLSL logged report"
            trigger={
              <Button
                style={buttonStyle}
                icon
                size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              >
                <Icon name="download" />
              </Button>
            }
          />
        }
      >
        <ExcelSheet data={data} name="Name">
          <ExcelColumn label="Polygon Parent" value="parentName" />
          <ExcelColumn label="Polygon ID" value="id" />
          <ExcelColumn label="Priority" value="priority" />
          <ExcelColumn label="Active" value="active" />
          <ExcelColumn label="Active Color" value="activeColor" />
          <ExcelColumn label="Active State" value="activeState" />
          <ExcelColumn label="Active State Info" value="activeStateInfo" />
          <ExcelColumn label="Active Range" value="range" />
          <ExcelColumn label="Relay Event Active" value="relayEventActive" />
          <ExcelColumn label="FireFlys" value="fireflyList" />
          <ExcelColumn label="Polygon Coords" value="strShape" />
          <ExcelColumn label="Note" value="note" />
          <ExcelColumn label="Operator" value="operator" />
          <ExcelColumn label="Origin" value="origin" />
          <ExcelColumn label="Time" value="timestamp" />
          <ExcelColumn label="Timestamp" value="ts" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

const downloadHeaders = [
  { label: "Polygon ID", key: "id" },
  { label: "Polygon Parent", key: "parentName" },
  { label: "Priority", key: "priority" },
  { label: "Active", key: "active" },
  { label: "Active Color", key: "activeColor" },
  { label: "Active State", key: "activeState" },
  { label: "Active State Info", key: "activeStateInfo" },
  { label: "Active Range", key: "range" },
  { label: "Relay Event Active", key: "relayEventActive" },
  { label: "FireFlys", key: "fireflyList" },
  { label: "Polygon Coords", key: "strShape" },
  { label: "Operator", key: "operator" },
  { label: "Origin", key: "origin" },
  { label: "Time", key: "timestamp" },
  { label: "Timestamp", key: "ts" },
  { label: "Note", key: "note" },
];

const periodOptions = (strings) => {
  return [
    {
      key: 0,
      text: strings?.["24 Hours"],
      value: "24hrs",
    },
    {
      key: 1,
      text: strings?.["7 Days"],
      value: "7days",
    },
    {
      key: 2,
      text: strings?.["30 Days"],
      value: "30days",
    },
    {
      key: 3,
      text: strings?.["All"],
      value: "all",
    },
  ];
};

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class EventLogListAllEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search        id: "",
        active: "",
        priority: "",
        activeColor: "",
        activeState: "",
        button: "",
        relayEventActive: "",
        origin: "",
        operator: "",
        disable: "",
        timestamp: "",
        note: "",
        parentName: "",
        //periodSelection: "24hrs",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},

      //
      periodSelection: "24hrs",
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    this.props.fetchEventLogPeriod({ period: "24hrs" });

    const filterInput = this.props.componentstate.get(
      "filterInput",
      "eventLogList"
    );

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });

      // const filterPeriodSelection = filterInput?.periodSelection;

      // switch (filterPeriodSelection) {
      //   case "":
      //     this.setState({ periodSelection: "24hrs" });
      //     break;
      //   case "24hrs":
      //     this.setState({ periodSelection: "24hrs" });
      //     break;
      //   case "7days":
      //     this.setState({ periodSelection: "7days" });
      //     break;
      //   case "30days":
      //     this.setState({ periodSelection: "30days" });
      //     break;
      //   case "all":
      //     this.setState({ periodSelection: "all" });
      //     break;
      //   default:
      //     break;
      // }
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "eventLogList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });

    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          //console.log(`xxx item[key]`, item[key]);
          //console.log(`xxx match[key]`, match[key]);

          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  // #NOTE - create a function
  // src/admin/named-area-group/NamedAreaGroupTableItems.js
  deleteItemByNamedAreaParentId = (items, id) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and return !== id

    let newItems = [];

    let i = 0;
    // groups
    items.forEach((item) => {
      // levels - subItems
      let newSubItem = [];
      let j = 0;
      item.subItems.forEach((item) => {
        // named areas - subSubItems
        let newSubSubItem = [];
        let k = 0;
        item.subItems.forEach((item) => {
          if (item.namedArea.id !== id) {
            newSubSubItem[k] = item;
            k = k + 1;
          }
        });
        newSubItem[j] = item;
        newSubItem[j].subItems = newSubSubItem;
        j = j + 1;
      });
      newItems[i] = item;
      newItems[i].subItems = newSubItem;
      i = i + 1;
    });

    return newItems;
  };

  handleFilterPeriodSelectorChange = (e, data) => {
    this.setState({ periodSelection: data.value });

    this.props.fetchEventLogPeriod({ period: data.value });

    // update filterInput
    //let newFilterInput = { ...this.state.filterInput };
    //newFilterInput.periodSelection = data.value;
    //this.setState({ filterInput: newFilterInput });
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // APP_TERMINOLOGY
    let strEventLog = strings?.["Event Log"];
    let strEventLogs = strings?.["Event Log"];

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "nalist10" },
              { value: 20, text: "20", key: "nalist20" },
              { value: 40, text: "40", key: "nalist40" },
              { value: 60, text: "60", key: "nalist60" },
              { value: data.length, text: "all", key: "nalistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const namedAreaFromDataById = (id) =>
      allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let updateButton;
    let periodSelector;
    let enableButton;
    let disableButton;
    let deleteButton;
    let namedAreaSelectedList;

    updateButton = (
      <Popup
        content={strings?.["EVENT_LOG_LIST_UPDATE_POP_MSG"]}
        trigger={
          <Button
            color="orange"
            onClick={() =>
              this.props.fetchEventLogPeriod({
                period: this.state.periodSelection,
              })
            }
            size="large"
          >
            {strings?.["Update"]}
          </Button>
        }
      />
    );

    periodSelector = (
      <Dropdown
        fluid
        selection
        style={{ margin: "5px", width: "150px" }}
        options={periodOptions(strings)}
        value={this.state.periodSelection}
        onChange={this.handleFilterPeriodSelectorChange}
        //onChange={(e, data) => this.handleFilterStatusChange(data)}
      />
    );

    if (selectedCount > 0) {
      let namedAreaNames = [];
      //console.log("data", data);

      // #REVIEW - WIP
      for (var key in selections) {
        if (namedAreaFromDataById(key) !== undefined) {
          let namedAreaName = namedAreaFromDataById(key).namedArea;

          // namedAreaName = namedAreaName.substring(
          //   0,
          //   namedAreaName.indexOf(" (")
          // ); // strip off the trailing ip
          namedAreaNames.push(namedAreaName);
        }
      }
      namedAreaNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      namedAreaNames = namedAreaNames.join(", "); // separate by commas

      enableButton = (
        <GenericButton
          label="Enable"
          color="blue"
          onClick={() => this.requestAction("enable")}
        />
      );
      disableButton = (
        <GenericButton
          label="Disable"
          color="orange"
          onClick={() => this.requestAction("disable")}
        />
      );
      deleteButton = (
        <DeleteButton onClick={() => this.requestAction("delete")} />
      );

      namedAreaSelectedList = (
        <div textAlign="left">
          <strong>{`${strEventLog} selected ...`} </strong>
          {namedAreaNames}
        </div>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={5} textAlign={"left"}>
                <Header as="h1">{strings?.["Event Log"]}</Header>{" "}
              </Grid.Column>
              <Grid.Column width={11}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              <div style={{ display: "inline-flex" }}>
                {updateButton}
                {periodSelector}
              </div>
              {false && !areAnySelected && (
                <span>Select items in the table below to apply an action.</span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {selectedCount} selected
                </span>
                <Button basic onClick={this.handleClearAll}>
                  Clear
                </Button>
                <Button.Group>
                  {deleteButton}
                  {/* <Button.Or />
                  {enableButton}
                  <Button.Or />
                  {disableButton} */}
                </Button.Group>
              </div>
              <div>
                <span>{viewablesPage.length} items displayed.</span>
                {/* <Download data={data} buttonStyle={{ marginLeft: "20px" }} /> */}
                <CSVLink data={data} headers={downloadHeaders}>
                  <Popup
                    content="Download CSV logged report"
                    trigger={
                      <Button
                        style={{ marginLeft: "20px" }}
                        icon
                        size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
                      >
                        <Icon name="download" />
                      </Button>
                    }
                  />
                </CSVLink>
              </div>
            </Segment>
            {namedAreaSelectedList}
          </Segment.Group>
          <Table sortable celled striped key={hash(viewablesPage)}>
            <Table.Header>
              <Table.Row>
                {/* <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="info" />
                </Table.Cell> */}
                {/* <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.disable ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="disable"
                    onChange={this.handleFilterChange}
                    value={filterInput?.disable}
                  />
                </Table.Cell> */}
                <Table.Cell style={headerCellStyle}>
                  {/* <Input
                    className={
                      filterInput?.timestamp ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="timestatmp"
                    onChange={this.handleFilterChange}
                    value={filterInput?.timestamp}
                  /> */}
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={filterInput?.id ? "filterInputHighlight" : null}
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="id"
                    onChange={this.handleFilterChange}
                    value={filterInput?.id}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.parentName ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="parentName"
                    onChange={this.handleFilterChange}
                    value={filterInput?.parentName}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.active ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="active"
                    onChange={this.handleFilterChange}
                    value={filterInput?.active}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.priority ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="priority"
                    onChange={this.handleFilterChange}
                    value={filterInput?.priority}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.activeColor ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="activeColor"
                    onChange={this.handleFilterChange}
                    value={filterInput?.activeColor}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.activeState ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="activeState"
                    onChange={this.handleFilterChange}
                    value={filterInput?.activeState}
                  />
                </Table.Cell>
                {/* <Table.Cell style={headerCellStyle} /> */}
                {/* <Table.Cell style={headerCellStyle} /> */}
                {/* <Table.Cell style={headerCellStyle} /> */}
                <Table.Cell style={headerCellStyle}>
                  {/* <Input
                    className={
                      filterInput?.relayEventActive
                        ? "filterInputHighlight"
                        : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="relayEventActive"
                    onChange={this.handleFilterChange}
                    value={filterInput?.relayEventActive}
                  /> */}
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.origin ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="origin"
                    onChange={this.handleFilterChange}
                    value={filterInput?.origin}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.operator ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="operator"
                    onChange={this.handleFilterChange}
                    value={filterInput?.operator}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.operator ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="note"
                    onChange={this.handleFilterChange}
                    value={filterInput?.note}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                {/* <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell> */}
                {/* <Table.HeaderCell
                  width={1}
                  sorted={column === "disable" ? direction : null}
                  onClick={this.handleSort("disable")}
                >
                  Disabled
                </Table.HeaderCell> */}
                <Table.HeaderCell
                  width={2}
                  sorted={column === "timestamp" ? direction : null}
                  onClick={this.handleSort("timestamp")}
                >
                  {strings?.["Time"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "id" ? direction : null}
                  onClick={this.handleSort("id")}
                >
                  {strings?.["Event/Shape ID"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "parentName" ? direction : null}
                  onClick={this.handleSort("parentName")}
                >
                  {strings?.["Polygon"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "active" ? direction : null}
                  onClick={this.handleSort("active")}
                >
                  {strings?.["Active"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "priority" ? direction : null}
                  onClick={this.handleSort("priority")}
                >
                  {strings?.["Priority"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "activeColor" ? direction : null}
                  onClick={this.handleSort("activeColor")}
                >
                  {strings?.["Active Color"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "activeState" ? direction : null}
                  onClick={this.handleSort("activeState")}
                >
                  {strings?.["Active State"]}
                </Table.HeaderCell>
                {/* <Table.HeaderCell>
                  {strings?.["State Info ( On | Off | Train)"]}
                </Table.HeaderCell> */}
                {/* <Table.HeaderCell>
                  {strings?.["Range (Start -> End)"]}
                </Table.HeaderCell> */}
                {/* <Table.HeaderCell
                  width={1}
                  sorted={column === "button" ? direction : null}
                  onClick={this.handleSort("button")}
                >
                  {strings?.["Button Info"]}
                </Table.HeaderCell> */}
                <Table.HeaderCell
                  sorted={column === "relayEventActive" ? direction : null}
                  onClick={this.handleSort("relayEventActive")}
                >
                  {strings?.["Relay Event Active"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "origin" ? direction : null}
                  onClick={this.handleSort("origin")}
                >
                  {strings?.["Origin"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "operator" ? direction : null}
                  onClick={this.handleSort("operator")}
                >
                  {strings?.["Operator"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "note" ? direction : null}
                  onClick={this.handleSort("note")}
                >
                  {strings?.["Note"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({
                  id,
                  active,
                  priority,
                  activeColor,
                  activeState,
                  button,
                  relayEventActive,
                  activeStateInfo,
                  range,
                  origin,
                  operator,
                  disable,
                  style,
                  timestamp,
                  note,
                  parentName,
                  strShape,
                  fireflyList,
                }) => (
                  <Table.Row key={`row-id-${id}`} style={style}>
                    {/* <Table.Cell>
                      <Checkbox
                        checked={this.isItemSelected(id)}
                        onChange={() => this.handleSelect(id)}
                      />
                    </Table.Cell> */}
                    {/* <Table.Cell>{disable}</Table.Cell> */}
                    <Table.Cell>{timestamp}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <Popup
                          trigger={<Icon name="lightbulb" />}
                          content={`${strings?.["FireFlys"]} -> ${fireflyList}`}
                          on="hover"
                        />
                        <Popup
                          trigger={<Icon name="star" />}
                          content={`${strings?.["Polygon Coords"]} -> ${strShape}`}
                          on="hover"
                        />
                        {id}
                      </div>
                    </Table.Cell>
                    <Table.Cell>{parentName}</Table.Cell>
                    <Table.Cell>{active}</Table.Cell>
                    <Table.Cell>{priority}</Table.Cell>
                    <Table.Cell>{activeColor}</Table.Cell>
                    <Table.Cell>{activeState}</Table.Cell>
                    {/* <Table.Cell>{activeStateInfo}</Table.Cell> */}
                    {/* <Table.Cell>{range}</Table.Cell> */}
                    {/* <Table.Cell>{button}</Table.Cell> */}
                    <Table.Cell>{relayEventActive}</Table.Cell>
                    <Table.Cell>{origin}</Table.Cell>
                    <Table.Cell>{operator}</Table.Cell>
                    <Table.Cell>{note}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = ({ elements: rxElements, strings }) => {
  let filtered = [];

  const elements = [...rxElements].sort((a, b) =>
    b.ts_string > a.ts_string ? 1 : -1
  );

  elements.forEach(function (element, idx) {
    const {
      id,
      active,
      active_color,
      active_state,
      priority,
      button_groups,
      relay_event_active,
      //relay_event_inactive,
      on_time,
      off_time,
      train,
      start,
      end,
      origin,
      operator,
      disable,
      note,
      password,
      parent_name,
      shape,
      firefly_list,
      //
      // log times
      ts_string,
      ts_sec,
      ts_msec, //
    } = element;

    let strTimestamp = "-";
    if (ts_string !== undefined) {
      strTimestamp = format(new Date(ts_sec), "yyyy-MMM-dd hh:mm:ss a");
      strTimestamp = `${strTimestamp} : ${Math.floor(ts_msec / 1000)}mS`;
    }

    let style = null;
    // set style of disabled events
    if (active === true) {
      style = { color: toWebColor(active_color) };
    }

    // make shape readable
    let strShape = [];

    if (shape !== null) {
      shape.forEach((s) => {
        strShape.push(`[${s.toString()}]`);
      });
      strShape = strShape.join(",");
    } else {
      strShape.push(strings?.["Not Applicable"]);
    }

    // make firefly_list readable
    let fireflyList = strings?.["Not Applicable"];
    if (firefly_list !== "") fireflyList = firefly_list;

    filtered[idx] = {
      id,
      active: active ? strings?.["ACTIVE"] : strings?.["INACTIVE"],
      activeColor: active_color,
      activeState: active_state,
      activeStateInfo: `${on_time || "-"} | ${off_time || "-"} | ${
        train || "-"
      }`,
      range: `${start | "-"} -> ${end | "-"}`,
      priority: `${priority}`,
      button: JSON.stringify(button_groups),
      relayEventActive: `${relay_event_active || "-"}`,
      //relay_event_inactive, // #NOTE - not supported ATM
      origin: origin,
      operator: operator,
      disable: `${disable}`,
      note,
      password,
      style,
      timestamp: strTimestamp,
      ts: ts_string,
      parentName: parent_name,
      strShape,
      fireflyList,
    };
  });

  return filtered;
};

function mapStateToProps(state, props) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  //const { isLoading, error } = state.namedAreas;
  const isLoading = false;
  const error = false;

  const allNamedAreaEvents = getEventLog(state);

  //console.log("xxx getEventLog", allNamedAreaEvents);

  const data = _prepData({
    elements: allNamedAreaEvents,
    strings: props.strings,
  });

  return {
    isLoading,
    error,
    data,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    TurnOnOffPokeTheWorker,
    goto,
    fetchEventLogPeriod,
    saveUserSettingsComponentState,
  })(EventLogListAllEvents)
);
