import React, { Component } from "react";
import { connect } from "react-redux";

import { Field, formValueSelector, reduxForm } from "redux-form";

import {
  Modal,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  GridColumn,
  GridRow,
} from "semantic-ui-react";

import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import { renderSelect, renderField } from "admin/form-field";

import { DeleteButton } from "admin/DeleteButton";

import {
  isConfigJs,
  namedAreaIconOptions,
  allowedLightStates as _allowedLightStates,
  allowedMapMarkers as _allowedMapMarkers,
} from "components/ConfigJs";

const validate = (values) => {
  const required = ["color", "icon", "hint", "on_time", "off_time"];

  const errors = {};

  required.forEach((r) => {
    const value = get(values, r);

    if (!value && value !== 0) {
      set(errors, r, "Required");
    }
  });

  //console.log("@@ validate errors", errors);

  return errors;
};

const normalise = (value, type) => {
  switch (type) {
    case "on_time":
      return (value >= 0 && parseInt(value, 10)) || 0;
    case "off_time":
      return (value >= 0 && parseInt(value, 10)) || 0;
    case "train":
      return (value >= 0 && parseInt(value, 10)) || 0;
    default:
      return value;
  }
};

//let initialValues = {};
class NamedAreaButtonSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  componentDidMount() {
    const { open } = this.props;
    if (open) {
      this.handleOpen();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.resetModal();

    // reset the form
    this.setState({ value: "" }, () => this.props.reset());
    //    this.props.onCancel();
  };

  handleDelete = (e, idx) => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.handleSettingsDelete(idx);
  };

  myHandleSubmit = (values) => {
    //console.log("myHandleSubmit e", e);
    //console.log("myHandleSubmit values", values);

    // prevents form submit, the most recent being the create or edit forms!
    //e.preventDefault();

    // close the modal
    this.setState({ modalOpen: false });

    // save the changes
    this.props.handleSettingsSave(values);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      reset,
      initialValues,
      change,
    } = this.props;

    // display section if STROBE, FORWARD, BACKWARDS
    const { isStateStrobe, isStateForwardBackward } = this.props;

    const buttonIdx = initialValues.idx;

    let iconOptions = [];
    if (isConfigJs() && namedAreaIconOptions()) {
      iconOptions = namedAreaIconOptions(); // from config.js file
    }

    const handleIconChange = (event, value) => {
      const newOptions = iconOptions.find((option) => option.value === value);
      const { text, hint } = newOptions;
      change("title", text);
      change("hint", hint);
    };

    const allowedLightStates = _allowedLightStates();
    const allowedMapMarkers = _allowedMapMarkers();

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header icon="cog" content={`Edit Button ${buttonIdx + 1} Settings`} />
        <Modal.Content>
          <Form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(this.myHandleSubmit)(e);
            }}
          >
            <Grid columns={2}>
              <GridColumn>
                <GridRow>
                  <Field
                    name="icon"
                    label="Button Icon"
                    component={renderSelect}
                    options={iconOptions}
                    onChange={handleIconChange}
                  />
                  <Field
                    name="title"
                    label="Description"
                    component={renderField}
                  />
                  <Field name="hint" label="Hint" component={renderField} />
                  <Field
                    name="marker"
                    label="Display Marker Icon"
                    component={renderSelect}
                    options={allowedMapMarkers}
                  />
                </GridRow>
              </GridColumn>
              <GridColumn>
                <GridRow>
                  <Field
                    name="state"
                    label="Light Active State"
                    component={renderSelect}
                    options={allowedLightStates}
                  />
                  {isStateStrobe && (
                    <div>
                      <Field
                        name="off_time"
                        label="Off Time (mS)"
                        normalize={(value) => normalise(value, "off_time")}
                        component={renderField}
                      />
                      <Field
                        name="on_time"
                        label="On Time (mS)"
                        normalize={(value) => normalise(value, "on_time")}
                        component={renderField}
                      />
                    </div>
                  )}
                  {isStateForwardBackward && (
                    <div style={{ marginTop: "10px" }}>
                      <Field
                        name="train"
                        label="Train Time (mS)"
                        normalize={(value) => normalise(value, "train")}
                        component={renderField}
                      />
                    </div>
                  )}
                </GridRow>
              </GridColumn>
            </Grid>
            <Segment>
              <Grid columns={2}>
                <GridColumn>
                  <GridRow>
                    <DeleteButton
                      onClick={(e) => this.handleDelete(e, buttonIdx)}
                    />
                  </GridRow>
                </GridColumn>
                <GridColumn>
                  <GridRow>
                    <Button.Group floated="right">
                      <Button
                        color="green"
                        type="submit"
                        disabled={pristine || submitting}
                        //onClick={(e) => this.myHandleSubmit(e)}
                      >
                        Save
                      </Button>
                      <Button.Or />
                      <Button
                        type="button"
                        disabled={pristine || submitting}
                        onClick={reset}
                      >
                        Reset
                      </Button>
                      <Button.Or />
                      <Button
                        style={{ textAlign: "right" }}
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Button.Group>
                  </GridRow>
                </GridColumn>
              </Grid>
            </Segment>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

NamedAreaButtonSettingsModal = reduxForm({
  form: "NamedAreaButtonSettingsModal",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(NamedAreaButtonSettingsModal);

const selector = formValueSelector("NamedAreaButtonSettingsModal");

function mapStateToProps(state, props) {
  const { initialValues } = props;

  const stateValue = selector(state, "state");
  const isStateStrobe = ["strobe", "forward", "backward"].includes(stateValue);
  const isStateForwardBackward = ["forward", "backward"].includes(stateValue);

  return {
    initialValues: initialValues, // props passed to redux-form loads these as (predictably), initial values
    isStateStrobe,
    isStateForwardBackward,
  };
}

export default connect(mapStateToProps, null)(NamedAreaButtonSettingsModal);
