import { isDemoMode, markerAcceptableEventLabels } from "components/ConfigJs";
import { getEquivalentSemanticColorName } from "colorpalette";
import _isEmpty from "lodash/isEmpty";

import { StatusEnum } from "utils/StatusEnum";

// firefly marker definition
export const markerDefinition = (feature) => {
  const { properties } = feature;

  const {
    id,
    area,
    location,
    position,
    easting,
    northing,
    utm_zone_number,
    utm_zone_letter,
    z,
    //color,
    light,
    controllerMode,
    deviceStatus,
    timestamp,
    ups_id,
    forced,
  } = properties;

  const {
    color,
    lightState,
    marker, // defaults to 'RoundMarker'
    led_state,
    off_time,
    on_time,
    train,
    brightness,
    event_id,
  } = light;

  // default
  let svgIcon = marker || "DiamondMarker";

  const { plan: ColorPlan, status: ColorStatus } = color;
  const { plan: StatePlan, status: StateStatus } = lightState;

  // FF is in transition is states are changing. Test for color change and for state change.
  // Ignore the other statuses i.e. off_time, on_time, train, brightness.
  //
  const isInTransition =
    ColorPlan !== ColorStatus ||
    StatePlan?.led_state !== StateStatus?.led_state;

  // #REVIEW
  const isStrobing = ["strobe", "forward", "backward"].includes(
    led_state.toLowerCase()
  );

  const isInCommission = controllerMode.toLowerCase() === "commission";

  // label the icons with a text character designating the source

  let textLabel = "";
  if (forced) textLabel = "F";
  // event_id labels
  const eventIdLabel = event_id?.charAt(0);

  if (markerAcceptableEventLabels()?.includes(eventIdLabel))
    textLabel = eventIdLabel;

  // #Note - if the light is off force it to be "grey" to account for
  // when a colored marker is defined. Color is meaningless when it is off.
  // A special marker like the 'OffMarker' has no color fill so it not impacted.
  //

  let svgStyle = {
    stroke:
      StatePlan?.led_state === "off"
        ? "black" // `rgba(0,0,0,.5)`  // #NOTE - changed from opaque black to hard black when white was introduced so there was more significant contrast between WHITE and BLACK
        : getEquivalentSemanticColorName(ColorPlan),
    fill:
      StateStatus?.led_state === "off"
        ? "black" //`rgba(0,0,0,.6)`
        : getEquivalentSemanticColorName(ColorStatus),
    text: textLabel,
  };

  // status checks
  let status = "OK"; // lets assume everything is OK. Ever the optimist eh?!

  if (deviceStatus.includes(StatusEnum.API_FETCH)) {
    status = "API_FETCH";
  }

  if (
    deviceStatus.includes(StatusEnum.NO_STATUS_REPORT) ||
    deviceStatus.includes(StatusEnum.TIMEOUT) ||
    deviceStatus.includes(StatusEnum.INACTIVE)
  ) {
    status = "INACTIVE_FIREFLY";
  }

  if (_isEmpty(ups_id)) {
    status = "DEAD_FIREFLY";
  }

  // OVERRIDE FAULT CHECKS IF DEMO MODE
  if (isDemoMode()) {
    // _#DEMO_MODE
    status = "OK";
  }

  //console.log(`xxx FIREFLY status`, id, deviceStatus, status);

  switch (status) {
    case "OK":
      if (marker.toLowerCase() === "travelwaymarker") {
        svgIcon = "TravelwayMarker";
        // travelways have a special color
        svgStyle = {
          stroke: "purple",
          fill: getEquivalentSemanticColorName(ColorStatus),
        };
      } else if (marker.toLowerCase() === "travelwaymarker") {
        svgIcon = "TravelwayMarker";
        // travelways have a special color
        svgStyle = {
          stroke: "purple",
          fill: getEquivalentSemanticColorName(ColorStatus),
        };
      } else {
        isInCommission
          ? (svgIcon = "StarMarker") // special commission marker
          : isInTransition
          ? (svgIcon = "TriangleMarker") // special transition marker
          : (svgIcon = marker); // ...otherwise use the marker type passed in the message
      }
      break;
    case "API_FETCH":
      svgIcon = "TriangleMarker";
      break;

    case "INACTIVE_FIREFLY":
      svgStyle = { stroke: "black", fill: "fuchsia" };
      svgIcon = "HourGlassMarker";
      break;
    // #WIP - used for Controller??????
    case "UPS_TIMEOUT":
      svgStyle = { stroke: "fuchsia", fill: "black" };
      svgIcon = "HourGlassMarker";
      break;
    case "DEAD_FIREFLY":
    default:
      svgStyle = { stroke: "fuchsia", fill: "sienna" };
      svgIcon = "BowTieMarker";
      break;
  }

  return { svgIcon, svgStyle };
};
