import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";
import GenericButton from "admin/GenericButton";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";

import {
  getTagPersonnel,
  getTagVehicles,
  getTagsAssignedByTagUserType,
} from "components/Tags/reducer";

import {
  deleteTagUserById,
  deleteTagVehicleById,
  fetchAllTagUsers,
  fetchAllTagVehicles,
} from "components/Tags/actions";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class TagTrackingUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        tagUser: "",
        tagId: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
    };
  }

  componentDidMount() {
    // const { fetchNamedAreas } = this.props;

    // // #FETCHS
    // fetchNamedAreas();// #FETCHS

    const filterInput = this.props.componentstate.get(
      "filterInput",
      "tagUsersList"
    );

    //console.log("filterInput componentDidMount", filterInput);

    if (!_isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "tagUsersList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column: rxColumn, direction } = this.state;

      // if undefined, default sort to 'tagUser'
      let column = rxColumn; // ? rxColumn : "tagUser";

      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  deleteTagUserById = (values) => {
    return new Promise((resolve, reject) => {
      this.props.deleteTagUserById({
        values,
        resolve,
        reject,
      });
    });
  };

  deleteTagVehicleById = (values) => {
    return new Promise((resolve, reject) => {
      this.props.deleteTagVehicleById({
        values,
        resolve,
        reject,
      });
    });
  };

  requestAction = (action) => {
    const { selections } = this.state;

    const { push, tagUserType } = this.props;

    let promiseArray = [];

    if (action === "delete") {
      for (var key in selections) {
        const selection = key;

        switch (tagUserType) {
          case "personnel":
            promiseArray.push(
              this.deleteTagUserById({ id: Number(selection) })
            );
            break;
          case "vehicle":
            promiseArray.push(
              this.deleteTagVehicleById({ id: Number(selection) })
            );
            break;

          default:
            break;
        }
      }
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log(" .... processed users", results);
      })
      .then(
        () => {
          this.props.fetchAllTagUsers();
          this.props.fetchAllTagVehicles();
          push(`/status/taguser/${tagUserType}`);
        },
        (errorMsg) => {
          console.log("action failed", errorMsg); // #TODO probs should show this?
        }
      );
  };

  render() {
    const {
      isLoading,
      error,
      tagUserType,
      //includeTitles
      strings,
    } = this.props;

    // APP_TERMINOLOGY
    let strTagUser = strings?.["User"];
    let strTagUsers = strings?.["Users"];

    switch (tagUserType) {
      case "personnel":
        strTagUser = strings?.["Personnel"];
        strTagUsers = strings?.["Personnel"];
        break;
      case "vehicle":
        strTagUser = strings?.["Vehicle"];
        strTagUsers = strings?.["Vehicles"];
        break;
      case null:
      default:
        break;
    }

    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "taguserlist10" },
              { value: 20, text: "20", key: "taguserlist20" },
              { value: 40, text: "40", key: "taguserlist40" },
              { value: 60, text: "60", key: "taguserlist60" },
              { value: data.length, text: "all", key: "taguserlistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const tagUserFromDataById = (id) => allData.find((item) => item.id == id); // falsey

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let enableButton;
    let disableButton;
    let deleteButton;
    let copyButton;
    let tagUserSelectedList;
    if (selectedCount > 0) {
      let tagUserNames = [];

      // #REVIEW - WIP
      for (var key in selections) {
        if (tagUserFromDataById(key) !== undefined) {
          let tagUserName = tagUserFromDataById(key).tagUser;
          tagUserNames.push(tagUserName);
        }
      }
      tagUserNames.sort(sortAlphaNum);
      tagUserNames = tagUserNames.join(", "); // separate by commas

      enableButton = (
        <GenericButton
          label={strings?.["Enable"]}
          color="blue"
          onClick={() => this.requestAction("enable")}
        />
      );
      disableButton = (
        <GenericButton
          label={strings?.["Disable"]}
          color="orange"
          onClick={() => this.requestAction("disable")}
        />
      );
      deleteButton = (
        <DeleteButton
          onClick={() => this.requestAction("delete")}
          strings={strings}
        />
      );

      copyButton = (
        <GenericButton
          label={strings?.["Copy"]}
          color="brown"
          onClick={() => this.requestAction("copy")}
        />
      );

      tagUserSelectedList = (
        <Segment textAlign="left">
          <strong>
            {strings.formatString(strings?.[`{list_items} selected...`], {
              list_items: strTagUsers,
            })}
          </strong>

          {tagUserNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">
                  {strings?.["Tag"]} {strTagUsers}
                </Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              <Link to={`./${tagUserType}/new`}>
                <Button color="blue" content={strings?.["Add"]} size="large" />
              </Link>
              {!areAnySelected && (
                <span>
                  {
                    strings?.[
                      "Select items in the table below to apply an action"
                    ]
                  }
                </span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {selectedCount} selected
                </span>
                <Button.Group>
                  <Button onClick={this.handleClearAll} size="large">
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or size="large" />
                  {deleteButton}
                </Button.Group>
              </div>
              <span>
                {strings.formatString(
                  strings?.[`{list_length} items displayed`],
                  {
                    list_length: viewablesPage.length,
                  }
                )}
              </span>
            </Segment>
            {tagUserSelectedList}
          </Segment.Group>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="edit" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.tagUser ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="tagUser"
                    onChange={this.handleFilterChange}
                    value={filterInput?.tagUser}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.tagId ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="tagId"
                    onChange={this.handleFilterChange}
                    value={filterInput?.tagId}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "tagUser" ? direction : null}
                  onClick={this.handleSort("tagUser")}
                >
                  {strTagUser}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "tagId" ? direction : null}
                  onClick={this.handleSort("tagId")}
                >
                  {strings?.["Assigned Tags"]}
                </Table.HeaderCell>
                <Table.HeaderCell width={12}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(viewablesPage, ({ id, tagUserLink, tagId }) => (
                <Table.Row key={`row-id-${id}`}>
                  <Table.Cell>
                    <Checkbox
                      checked={this.isItemSelected(id)}
                      onChange={() => this.handleSelect(id)}
                    />
                  </Table.Cell>
                  <Table.Cell>{tagUserLink}</Table.Cell>
                  <Table.Cell>{tagId || "-"}</Table.Cell>
                  <Table.Cell />
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements, tagUserType) => {
  let filteredElements = [];
  elements.forEach(function (element, idx) {
    const { id, assigned } = element;

    // tags assigned
    const tagsAssigned = assigned.map((tag) => tag.id)?.join(", ");

    let tagUser = "?";
    switch (tagUserType) {
      case "personnel":
        const { firstname, lastname } = element;
        tagUser = `${firstname} ${lastname}`;
        break;
      case "vehicle":
        const { name, type } = element;
        tagUser = `${name} - ${type}`;
        break;

      default:
        break;
    }

    filteredElements[idx] = {
      id: id,
      tagUser,
      active: true, // active,
      tagUserLink: <Link to={`./${tagUserType}/edit/${id}`}>{tagUser}</Link>,
      tagId: tagsAssigned,
    };
  });

  return filteredElements;
};

const mapStateToProps = (state, props) => {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  const isLoading = false;
  const error = false;

  const { tagUserType } = props;

  let userData;
  switch (tagUserType) {
    case "personnel":
      userData = getTagPersonnel(state);
      break;
    case "vehicle":
      userData = getTagVehicles(state);
      break;

    default:
      break;
  }

  const data = _prepData(userData, tagUserType);

  return {
    isLoading,
    error,
    data,
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, {
    push,
    deleteTagUserById,
    deleteTagVehicleById,
    fetchAllTagUsers,
    fetchAllTagVehicles,
    saveUserSettingsComponentState,
  })(TagTrackingUserList)
);
