// import React from "react";

// const UserAdmin = () => <div>Nothing here yet...coming soon...super admin</div>;

// export default UserAdmin;

import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";

import UserAdminList from "components/UserAdmin/UserAdminList";

import UserEditPage from "components/User/UserEditPage";
import UserCreatePage from "components/UserAdmin/UserCreatePage";

class UserAdminPages extends Component {
  render() {
    const { match, strings } = this.props;

    //console.log(`UserPages`, this.props);

    return (
      <Switch>
        <Route
          path={`${match.url}/new`}
          // component={UserCreatePage}
          render={(props) => <UserCreatePage {...props} strings={strings} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          //component={UserEditPage}
          render={(props) => <UserEditPage {...props} strings={strings} />}
        />
        <Route
          exact
          path={match.url}
          //component={UserAdminList}
          render={(props) => <UserAdminList {...props} strings={strings} />}
        />
      </Switch>
    );
  }
}

export default withRouter(UserAdminPages);
