import React, { Component } from "react";
import { connect } from "react-redux";

import { Form } from "semantic-ui-react";

import { sanitizeRegionShape } from "utils/RegionShape";
import { MineLevelMapById } from "components/Map/MineLevelMap";
import { Polygon, LayersControl, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import { EditControlFeatureGroup } from "@andrewdodd/react-leaflet-draw";

import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroupGeoJson";

import { SetMapMoving } from "components/Map/actions";

const buildNewRegion = (points) => {
  const namedAreaTimestamp = new Date().getTime();

  return {
    id: namedAreaTimestamp, //cuid(),
    points: sanitizeRegionShape(points),
  };
};

const regionListWithoutId = (list, id) =>
  list.filter((region) => region.id !== id);

class CreateRegionsField extends Component {
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }

  addRegion = (evt) => {
    //console.log("addRegion", evt);

    const points = evt.layer.getLatLngs()[0];
    // console.log("addRegion points", points);
    // console.log("addRegion points", JSON.stringify(points));
    // console.log("addRegion this.props", this.props);

    const newRegionList = [...this.props.input.value, buildNewRegion(points)];
    this.props.input.onChange(newRegionList);
  };

  addRegion_New = (e) => {
    //console.log("addRegionNew 1 e", e);

    var layers = e.layers;
    layers.eachLayer((layer) => {
      //console.log("addRegionNew 2 layer", layer);
      // console.log(
      //   "addRegionNew 3 layer.getLatLngs()[0]",
      //   layer.getLatLngs()[0]
      // );
      // console.log("addRegionNew 4 list = this.props.input", this.props.input);

      const points = layer.getLatLngs()[0];

      const newRegionList = [...this.props.input.value, buildNewRegion(points)];
      this.props.input.onChange(newRegionList);
    });
  };

  deleteRegion = (e) => {
    //console.log("deleteRegion", e);

    const { id } = e.props;
    this.props.input.onChange(regionListWithoutId(this.props.input.value, id));
  };

  deleteRegion_New = (e) => {
    console.log("deleteRegionNew", e);

    var layers = e.layers;
    layers.eachLayer((layer) => {
      // console.log("deleteRegionNew 2 layer", layer);
      // console.log(
      //   "deleteRegionNew 3 layer.getLatLngs()[0]",
      //   layer.getLatLngs()[0]
      // );
      // console.log(
      //   "deleteRegionNew 4 list = this.props.input",
      //   this.props.input
      // );

      const { id } = e.props;
      this.props.input.onChange(
        regionListWithoutId(this.props.input.value, id)
      );
    });
  };

  changeRegion = (e, layer) => {
    // console.log("changeRegion 1 e", e);
    // console.log("changeRegion 2 layer", layer);
    // console.log("changeRegion 3 layer.getLatLngs()[0]", layer.getLatLngs()[0]);
    // console.log("changeRegion 4 list = this.props.input", this.props.input);

    const points = layer.getLatLngs()[0];
    const { id } = e.props;
    let list = this.props.input.value;
    list = regionListWithoutId(list, id);
    list = [...list, buildNewRegion(points)];
    this.props.input.onChange(list);
  };

  changeRegion_New = (e) => {
    //console.log("changeRegion 1 e", e);

    var layers = e.layers;
    layers.eachLayer((layer) => {
      // console.log("changeRegion 2 layer", layer);
      // console.log(
      //   "changeRegion 3 layer.getLatLngs()[0]",
      //   layer.getLatLngs()[0]
      // );
      // console.log("changeRegion 4 list = this.props.input", this.props.input);

      const points = layer.getLatLngs()[0];
      const { id } = e.props;
      let list = this.props.input.value;
      list = regionListWithoutId(list, id);
      list = [...list, buildNewRegion(points)];
      this.props.input.onChange(list);
    });
  };

  handleActivityStarted = (e) => {
    //console.log("isActivity started", e, this.props);
    const { SetMapMoving } = this.props;
    this.setState({ editing: true });
    SetMapMoving(true);
  };

  handleActivityStopped = (e) => {
    //console.log("isActivity stopped", e, this.props);
    const { SetMapMoving } = this.props;
    this.setState({ editing: false });
    SetMapMoving(false);
  };

  render() {
    const {
      mineLevelIdValue,
      width,
      height,
      backgroundColor = { r: 0, g: 0, b: 0, a: 0.5 },
      input: { value },
    } = this.props;

    if (!mineLevelIdValue) {
      return <div>Must select area</div>;
    }

    // polygon styling
    const fillColor = `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`;
    const opacity = `${backgroundColor.a}`;

    return (
      <Form.Field>
        <MineLevelMapById
          mineLevelId={mineLevelIdValue}
          style={{
            height: "60vh", //Math.floor(height), //"60vh",
            width: "100%", //Math.floor(width), // "100%",
            position: "relative",
            zIndex: 0,
            backgroundColor: `rgba(255,255,255,1)`,
          }}
          //invaliddateSize={}
        >
          <LayersControl position={"topright"}>
            <LayersControl.Overlay
              name={"FireFlys"}
              key={"firefly-overlay"}
              checked
            >
              <CurrentMineLevelFirefliesFeatureGroup
              // PopupContent={ForceableComponent}
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay
              name={"Polygons"}
              key={"named-area-overlay"}
              checked
            >
              {/* see - https://github.com/andrewdodd/react-leaflet-draw/blob/master/example-full/edit-control.js */}
              <EditControlFeatureGroup
                onCreated={this.addRegion}
                onDeleted={this.deleteRegion}
                onEdited={this.changeRegion}
                onActivityStarted={this.handleActivityStarted}
                onActivityStopped={this.handleActivityStopped}
                controlProps={{
                  draw: {
                    rectangle: {
                      //shapeOptions: { color: "green" },
                      showLength: false,
                      metric: false,
                      feet: false,
                      showArea: false,
                    },
                    marker: false,
                    circle: false,
                    circlemarker: false,
                    polygon: {
                      // shapeOptions: { color: "blue" },
                      allowIntersection: false,
                      showLength: false,
                      metric: false,
                      feet: false,
                      showArea: false,
                      drawError: {
                        color: "#E1E100",
                        message:
                          "<strong>Wups</strong> shapes cannot intersect",
                      },
                    },
                    polyline: false,
                  },
                  edit: {
                    edit: {
                      selectedPathOptions: {
                        maintainColor: true,
                        fillOpacity: 0.5,
                        // fillColor: null
                      },
                    },
                    poly: {
                      allowIntersection: false,
                      drawError: {
                        color: "fuchsia",
                        message:
                          "<strong>Wups</strong> shapes cannot intersect",
                      },
                    },
                  },
                  position: "topright",
                }}
              >
                {value.map((region) => {
                  const { id, points } = region;
                  return (
                    <Polygon
                      positions={points}
                      fillOpacity={opacity} // 0.5 //
                      color={fillColor} //"black"  //fillColor
                      id={id}
                      key={`editing-region-${id}`}
                    />
                  );
                })}
              </EditControlFeatureGroup>
              {/* ******************************************* */}

              {/* #NOTE @13Jul21 - The following code section is the start of moving away
                from Andrew's fork of react-leaflet-draw. The *_New functions work
                exact for changeRegion() > e.props.
                React-leaflet-draw does not accommodate passing the child props back to the parent.
                <EditControlFeatureGroup> accommodates this.
                The original reason for moving to generic react-leaflet-draw was to get access to 
                onEditStart & onEditStop. However I found Andrew has included onActivityStarted & 
                onActivityStopped as equivalents.
                This whole area should be restructured. 
                See - https://stackoverflow.com/questions/63460655/edit-a-single-polygon-in-react-leaflet
                See - https://stackblitz.com/edit/react-sn5kcm?file=src%2FMap.js
                See - https://github.com/alex3165/react-leaflet-draw/blob/master/example/edit-control.js
                As an examples for consideration. 
                //
                 
                  <FeatureGroup>
                    <EditControl
                      onCreated={this.addRegion_New}
                      onDeleted={this.deleteRegion_New}
                      onEdited={this.changeRegion_New}
                      onEditStart={() => console.log(`editStart! :)`)}
                      onEditStop={() => console.log(`editStop! :(`)}
                      draw={{
                        rectangle: {
                          //shapeOptions: { color: "green" },
                          showLength: false,
                          metric: false,
                          feet: false,
                          showArea: false,
                        },
                        marker: false,
                        circle: false,
                        circlemarker: false,
                        polygon: {
                          // shapeOptions: { color: "blue" },
                          allowIntersection: false,
                          showLength: false,
                          metric: false,
                          feet: false,
                          showArea: false,
                          drawError: {
                            color: "#E1E100",
                            message:
                              "<strong>Wups</strong> shapes cannot intersect",
                          },
                        },
                        polyline: false,
                      }}
                      edit={{
                        edit: {
                          selectedPathOptions: {
                            maintainColor: true,
                            fillOpacity: 0.5,
                            // fillColor: null
                          },
                        },
                        poly: {
                          allowIntersection: false,
                          drawError: {
                            color: "fuchsia",
                            message:
                              "<strong>Wups</strong> shapes cannot intersect",
                          },
                        },
                      }}
                      position={"topright"}
                    />
                    {value.map((region) => {
                      const { id, points } = region;
                      //console.log("regions", region);
                      return (
                        <Polygon
                          positions={points}
                          fillOpacity={0.5}
                          color={"black"}
                          id={id}
                          key={`editing-region-${id}`}
                        />
                      );
                    })}
                  </FeatureGroup>
                )} 
                */}
              {/* ******************************************* */}
            </LayersControl.Overlay>
          </LayersControl>
        </MineLevelMapById>
      </Form.Field>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  SetMapMoving: (bool) => dispatch(SetMapMoving(bool)),
});

export default connect(null, mapDispatchToProps)(CreateRegionsField);
