import React from "react";
import { Message } from "semantic-ui-react";
import { isDebugPagePropsMessages } from "components/ConfigJs";

// isDebugPagePropsMessages() && (

export const DebugPagePropsMessages = ({ that }) => {
  const show = isDebugPagePropsMessages();
  return (
    show && (
      <Message>
        <Message.Header>Page data:</Message.Header>
        <pre>Props {JSON.stringify(that.props, null, 2)}</pre>
        <pre>State {JSON.stringify(that.state, null, 2)}</pre>
      </Message>
    )
  );
};
