import Immutable from "seamless-immutable";

const initialState = Immutable({
  externalTrigger: {},
});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "EXTERNAL_TRIGGER_FETCH_SUCCEEDED":
      const {
        externalTrigger: { list },
      } = payload;

      newState.externalTrigger = list;
      return newState;

    case "RESET":
      console.log("RESET! - External Trigger");
      return state;
    default:
      return state;
  }
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}

export const getExternalTrigger = (state) => {
  const byId = state.externalTrigger.externalTrigger;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};
