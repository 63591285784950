// TestTable-working-preComponentize-10Jun1219.js
// https://medium.com/@subalerts/create-dynamic-table-from-json-in-react-js-1a4a7b1146ef
// draggable row - https://codesandbox.io/s/4jw25r7l19?file=/src/index.js:1333-1353
// hooks parent to children- https://stackoverflow.com/questions/55726886/react-hook-send-data-from-child-to-parent-component

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Icon, Button, Input } from "semantic-ui-react";

import DraggableTableRow from "./DraggableTableRow";

import { testNamedAreas } from "./mqttMessages";

import { mqttPublish } from "components/WebWorker/actions";

class TableMade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      rows: [],
    };
  }

  swap = (a, b) => {
    let { data } = this.state;
    data[a] = data.splice(b, 1, data[a])[0];
    this.setState({
      ...this.state,
      data,
    });
  };

  getKeys = () => {
    return Object.keys(this.state.data[0]);
  };

  getHeader = () => {
    // var keys = this.getKeys();
    // return keys.map((key, index) => {
    //   return <Table.HeaderCell key={key}>{key.toUpperCase()}</Table.HeaderCell>;
    // });

    return (
      <Table.Row>
        <Table.HeaderCell>Console filter "qqq"</Table.HeaderCell>
      </Table.Row>
    );
  };

  onClickColor = (props) => {
    //console.log("qqq onClickColor ", props);

    const { row, column } = props;

    const { data } = this.state;
    // //      #REVIEW - why need to deepClone here?
    // //  replace lodash deepClone option elsewhere
    let newData = JSON.parse(JSON.stringify(data)); /// <---deepClone

    const colors = ["green", "amber", "blue", "red", "off"];
    const currentColor = newData[row].button[column].color;
    const index = colors.findIndex((x) => x === currentColor);
    let newColor;
    index + 1 > colors.length - 1
      ? (newColor = colors[0])
      : (newColor = colors[index + 1]);

    //console.log("qqq newData", newData);
    newData[row].button[column].color = newColor;

    this.setState({ data: newData });
  };

  onEventClick = (col, active) => {
    //console.log("qqq click event", col);

    const { data } = this.state;

    let newData = JSON.parse(JSON.stringify(data)); /// <---deepClone so that reverse (see below) does not alter data object

    // NOTE: named areas are published in reverse so the 'top' in the list
    // comes out last so sets highest lighting priority
    newData.reverse().forEach((namedArea, index) => {
      const eventMsg = {
        id: namedArea.id,
        // this is the priority of the button. 0 is base level of lights priority, so start from 1...
        // <----#REVIEW - should get this from the button setup
        priority: col + 1,
        active: active,
        active_color: namedArea.button[col].color,
        active_state: "ON",
        timestamp: new Date().getTime(),
        token: "token-" + new Date().getTime().toString(),
        precanned: 0,
      };
      //console.log("qqq eventNamedArea row ", col, "msg ", eventMsg);

      this.props.onSendMqtt({
        topic: `named_area/${eventMsg.id}/event`,
        qos: 0,
        message: eventMsg,
        retained: true,
      });
    });
  };

  getRowsData = () => {
    const items = this.state.data;

    // items sorted by "priority"
    // priority may be integer or string.
    // #REVIEW - force to integer for now
    // const items = this.state.data.sort((a, b) => {
    //   return a.priority.toString().localeCompare(b.priority);
    // });

    const keys = this.getKeys();
    const itemsLength = items.length;

    return items.map((row, index) => {
      return (
        <DraggableTableRow
          rowStyles={{}}
          key={index}
          i={index}
          action={this.swap}
          onClickColor={this.onClickColor}
        >
          <RenderRow
            key={index}
            index={index}
            data={row}
            keys={keys}
            dataLength={itemsLength}
            onClickColor={this.onClickColor}
          />
        </DraggableTableRow>
      );
    });
  };

  sendNamedAreaSetupMqtt = () => {
    console.log("qqq sendMqtt");
    //    console.log("qqq this.state.data", this.state.data);

    const namedAreas = this.state.data;

    namedAreas.forEach((namedArea) => {
      console.log("qqq changeArea", namedArea);
      this.props.onSendMqtt({
        topic: `named_area/${namedArea.id}/change`,
        qos: 0,
        message: namedArea,
        retained: false,
      });
    });
  };

  newNamedArea = (col) => {
    //console.log("qqq click new ", col);

    const testNamedAreas = this.state.data;

    const namedArea = testNamedAreas[col - 1];

    console.log("qqq changeArea", namedArea);
    this.props.onSendMqtt({
      topic: `named_area/${namedArea.id}/change`,
      qos: 0,
      message: namedArea,
      retained: false,
    });
  };

  deleteNamedArea = (col) => {
    //console.log("qqq click delete ", col);

    let testNamedAreaId;
    switch (col) {
      case 1:
        testNamedAreaId = "DMLZ_Extraction:AREA_46_1";
        break;
      case 2:
        testNamedAreaId = "DMLZ_Extraction:AREA_46_2";
        break;
      case 3:
        testNamedAreaId = "DMLZ_Extraction:AREA_46_3";
        break;
      case 4:
        testNamedAreaId = "DMLZ_Extraction:AREA_46_4";
        break;
      default:
        break;
    }
    // test
    const deleteMsg = {
      id: testNamedAreaId,
      delete: true,
    };

    console.log("qqq deleteNamedArea", deleteMsg);
    this.props.onSendMqtt({
      topic: `named_area/${testNamedAreaId}/delete`,
      qos: 0,
      message: deleteMsg,
      retained: false,
    });
  };

  render() {
    return (
      <div>
        <style>{`
          .draggable {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
          }
        `}</style>
        <Table>
          <Table.Header>{this.getHeader()}</Table.Header>
          <Table.Body>{this.getRowsData()}</Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                <Button size="small" onClick={this.sendNamedAreaSetupMqtt}>
                  Send Setup
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>Event button # column {`>`}</Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.onEventClick(0, true)}>
                  on 1
                </Button>
                <Button
                  size="small"
                  onClick={() => this.onEventClick(0, false)}
                >
                  off 1
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.onEventClick(1, true)}>
                  on 2
                </Button>
                <Button
                  size="small"
                  onClick={() => this.onEventClick(1, false)}
                >
                  off 2
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.onEventClick(2, true)}>
                  on 3
                </Button>
                <Button
                  size="small"
                  onClick={() => this.onEventClick(2, false)}
                >
                  off 3
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.onEventClick(3, true)}>
                  on 4
                </Button>
                <Button
                  size="small"
                  onClick={() => this.onEventClick(3, false)}
                >
                  off 4
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.newNamedArea(1)}>
                  new 1
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.newNamedArea(2)}>
                  new 2
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.newNamedArea(3)}>
                  new 3
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.newNamedArea(4)}>
                  new 4
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.deleteNamedArea(1)}>
                  delete 1
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.deleteNamedArea(2)}>
                  delete 2
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.deleteNamedArea(3)}>
                  delete 3
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button size="small" onClick={() => this.deleteNamedArea(4)}>
                  delete 4
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

const RenderRow = (props) => {
  //console.log("qqq props", props);
  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const color = "black";

  const isItemSelected = (e) => {
    //console.log("qqq e", e);
  };

  const handleSelect = (e) => {
    //console.log("qqq e", e);
  };

  const handleClick = (e, key) => {
    //console.log("qqq key", key);

    e.preventDefault();
    setOpen(!open);
  };

  const eyeIcon = open ? "eye" : "eye slash";
  const gripIcon = (index, length) => {
    //console.log("qqq length, index", length, index);
    switch (index) {
      case 0:
        return "sort down";
      case length - 1:
        return "sort up";
      default:
        return "sort";
    }
  };

  const iconButtons = {
    //padding: "0.5em",
  };

  return (
    <div>
      <Table.Cell>{props.data.priority}</Table.Cell>
      <Table.Cell>
        <Button
          style={iconButtons}
          icon
          onClick={(e) => handleClick(e, props.index)}
        >
          <Icon name={gripIcon(props.data.priority, props.dataLength)} />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={isItemSelected(props.index)}
          onChange={(e) => handleSelect(e, props.index)}
        />
      </Table.Cell>
      <Table.Cell>
        <Button icon onClick={(e) => handleClick(e, props.index)}>
          <Icon color={color} name={eyeIcon} />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Input
          value={props.data.name} // read only
          name="read only"
        />
      </Table.Cell>
      <RenderRowColorBox
        key={props.index}
        index={props.index}
        data={props.data}
        onClickColor={props.onClickColor}
      />
    </div>
  );
};

// priority: integer - order of items in list <-- or just sort the object?
// active: boolean  - checkbox status
// show: boolean - show/hide on map (eye icon)
// name: string - named area polygon shape name <-- could be generated
// button: array of button color settings

const RenderRowColorBox = (props) => {
  //console.log("qqq  RenderRowColorBox props", props);

  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const handleClick = (e, key) => {
    e.preventDefault();

    //let data = props.data;
    const row = props.index;
    const column = key;

    // console.log("qqq RenderRowColorBox index", buttonIndex);
    // console.log("qqq RenderRowColorBox key", key);
    // //console.log("qqq RenderRowColorBox data", data);

    // console.log("qqq currentColor", data.button[key].color);
    // const colors = ["green", "amber", "blue", "red"];
    // const currentColor = data.button[key].color;
    // const index = colors.findIndex((x) => x === currentColor);
    // let newColor;
    // index + 1 > colors.length - 1
    //   ? (newColor = colors[0])
    //   : (newColor = colors[index + 1]);

    // console.log("qqq newColor", newColor);

    // update data!
    props.onClickColor({ row, column });

    setOpen(!open);
  };

  const greenBox = {
    // backgroundColor: "green",
    borderRadius: 0,
    border: "1px outset black",
    height: "100%",
    margin: ".25em",
  };

  const buttons = props.data.button;

  return buttons.map((button, index) => {
    //console.log("qqq buttons index", button, index);

    let buttonColor = "green";
    switch (button.color) {
      case "amber":
        buttonColor = "yellow";
        break;
      case "no_change":
        buttonColor = "grey";
        break;
      case "off":
        buttonColor = "black";
        break;
      default:
        buttonColor = button.color;
        break;
    }
    return (
      <Table.Cell>
        <Button
          icon
          color={buttonColor}
          style={greenBox}
          onClick={(e) => handleClick(e, index)}
        >
          <Icon />
        </Button>
      </Table.Cell>
    );
  });
};

class TestNamedAreaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: testNamedAreas,
    };
  }

  // sendMqtt = (mqttMsg) => {
  //   console.log("qqq onSendMqtt", mqttMsg);
  // };

  render() {
    console.log("qqq testNamedAreas", testNamedAreas);
    return (
      <div>
        <TableMade
          data={this.state.tableData}
          onSendMqtt={(mqttMsg) => this.props.mqttPublish(mqttMsg)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    mqttPublish: (mqttMsg) => {
      dispatch(mqttPublish(mqttMsg));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestNamedAreaTable);
