export function UpdateConflicts(message) {
  //console.log("UPDATE_CONFLICTS - message - ", message);
  return { type: "UPDATE_CONFLICTS", payload: message };
}

export function RemoveConflicts(id) {
  console.log("REMOVE_CONFLICTS action- Remove");
  return { type: "REMOVE_CONFLICTS", payload: id };
}

export function ClearConflicts() {
  console.log("CLEAR_CONFLICTS action- Clear");
  return { type: "CLEAR_CONFLICTS" };
}
