export function fetchMineLevels() {
  return { type: "MINE_LEVELS_FETCH_REQUESTED" };
}

export function saveNewMineLevel(payload) {
  return { type: "SAVE_NEW_MINE_LEVEL", payload };
}

export function updateMineLevel(payload) {
  return { type: "UPDATE_MINE_LEVEL", payload };
}

// same ...
// #WIP #TODO - change all `mine level` to `area`

export function deleteMineLevel(payload) {
  return { type: "DELETE_MINE_LEVEL", payload };
}

export function deleteArea(payload) {
  return { type: "DELETE_MINE_LEVEL", payload };
}
