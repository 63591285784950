import React from "react";
import { Form } from "semantic-ui-react";

import ChooseMineLevel from "containers/ChooseMineLevel";

const ChooseMineLevelField = ({
  input: { value, onChange },
  label,
  disabled = false,
  type,
  meta: { touched, error },
}) => {
  return (
    <Form.Field error={Boolean(touched && error)}>
      <label>{label}</label>
      <ChooseMineLevel
        currentValue={value}
        disabled={disabled}
        onChange={(event, { value }) => {
          onChange(value);
        }}
      />
    </Form.Field>
  );
};

export default ChooseMineLevelField;
