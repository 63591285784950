import Immutable from "seamless-immutable";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_SETTINGS } from "utils/DEFAULTS";

const parseUserDataSettings = (user) => {
  let newUser = { ...user };
  let settings = newUser?.data?.settings;

  let newSettings = DEFAULT_SETTINGS;
  // check settings exist or set DEFAULT
  if (settings !== "") {
    if (settings?.includes("display")) {
      newSettings = JSON.parse(settings);
    }
  }

  newUser.data.settings = newSettings;

  return newUser;
};

const initialState = Immutable({
  errors: {},
  isLoading: false,
  user: {},
  isCheckingAuth: false,
  hasCheckedOnce: false,
  isLoggingOut: false,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return state.merge([
        { isLoading: true },
        { errors: {} },
        { hasCheckedOnce: true },
      ]);
    case "LOGIN_ERROR":
      return state.merge([{ isLoading: false }, { errors: action.errors }]);
    case "LOGIN_SUCCESS":
      console.log("LOGIN_SUCCESS action.payload", action.payload);

      return state.merge([
        { isLoading: false },
        { errors: [] },
        { user: parseUserDataSettings(action.payload.user) },
      ]);
    case "CHECK_AUTH":
      return state.merge([{ isCheckingAuth: true, hasCheckedOnce: true }]);
    case "AUTH_FAILURE":
      return state.merge([{ isCheckingAuth: false, user: {} }]);
    case "AUTH_UNKNOWN":
      return state.merge([{ isCheckingAuth: false }]);
    case "FOUND_VALID_AUTH":
      return state.merge([
        { isCheckingAuth: false },
        { errors: [] },
        { user: parseUserDataSettings(action.payload.user) },
      ]);

    case "LOGOUT_REQUEST":
      return state.merge([{ isLoggingOut: true }]);
    case "LOGOUT_SUCCESS":
      return initialState.merge([{ hasCheckedOnce: true }]);
    case "LOGOUT_ERROR":
      return state.merge([{ isLoggingOut: false }, { errors: action.errors }]);
    case "RESET":
      console.log("RESET! - Auth");
      return state;
    default:
      return state;
  }
}

export const isAuthenticated = (state) => {
  return !isEmpty(state.auth.user);
};

export const isLoading = (state) => {
  return state.auth.isLoading;
};

export const getRoles = (state) => {
  //console.log("getRoles - state.auth", state.auth);
  return state.auth.getIn(["user", "data", "roles"], []);
};

export const isUserSuper = (state) => {
  // console.log(
  //   'getRoles(state).indexOf("super")',
  //   getRoles(state).indexOf("super")
  // );
  return getRoles(state).indexOf("super") >= 0;
};

export const isUserAdmin = (state) => {
  return getRoles(state).indexOf("admin") >= 0;
};

export const isUserUser = (state) => {
  return getRoles(state).indexOf("user") >= 0;
};
// i.e. operator

// isLightPlanner
// isControlRoomOperator
// isNetworkAdmin

export const isUserGuest = (state) => {
  return getRoles(state).indexOf("guest") >= 0;
};

export const getUsername = (state) => {
  return state.auth.user.data?.username;
};

export const getEmail = (state) => {
  return state.auth.user.data?.email;
};

export const getUserRole = (state) => {
  return state.auth.user.data?.roles;
};

export const getUserData = (state) => {
  return state.auth.user.data;
};

export const getUserSettings = (state) => {
  return state.auth.user.data?.settings;
};
