import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Container } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import ScheduledEventSimpleForm from "admin/event-scheduled/ScheduledEventForm";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import NavigationPromptModal from "admin/NavigationPromptModal";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import {
  fetchNamedAreaScheduledEventInfo,
  saveNewScheduledEvent,
} from "NamedAreas/actions";
import { getUserData } from "auth/reducer";
import {
  DEFAULT_CRON_EXPRESSION,
  DEFAULT_CRON_EXPRESSION_STOP,
} from "utils/DEFAULTS";
import { v4 as uuid } from "uuid";

class ScheduledEventCreatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    this.props.fetchNamedAreaScheduledEventInfo();
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  _preProcessValues = (values) => {
    //console.log("xxx values", values);
    const {
      //
    } = values;

    return values;
  };

  submitMyForm = (values) => {
    const { saveNewScheduledEvent } = this.props;

    //console.log("xxx saveNewScheduledEvent submitMyForm values", values);

    const id = uuid(); // see - src/admin/named-area-simple/NamedAreaSimpleCreatePage.js, also see for copy()
    const password = uuid(); // not supported ATM

    const {
      name,
      disable,
      cron,
      mineLevelId: area_id,
      note,
      duration,
      events,
    } = values;

    // #NOTE - events are in the form ............
    // e.g.
    // const events = [
    //   {
    //     polygon_id: uuid(),
    //     user_relay: 1,
    //   },
    //   // {
    //   //   polygon_id: uuid(),
    //   //   user_relay: 3,
    //   // },
    //   // {
    //   //   polygon_id: uuid(),
    //   //   user_relay: 5,
    //   // },
    // ];

    const newEvents = events.map((event) => {
      return { polygon_id: event.polygonId, user_relay: event.userRelay };
    });

    const cron_active_duration = `${duration.value}:${duration.unit}`;
    const info = JSON.stringify(values);

    const newValues = {
      id,
      name,
      cron,
      area_id,
      disable,
      events: newEvents,
      note,
      password,
      cron_active_duration,
      info,
    };

    //console.log("xxx save newValues", newValues);

    return new Promise((resolve, reject) => {
      saveNewScheduledEvent({
        values: newValues,
        resolve,
        reject,
      });
    }).then(() => {
      // wait for state change before redirecting page
      this.setState(
        {
          submitted: true,
        },
        () => {
          const { fetchNamedAreaScheduledEventInfo } = this.props;
          fetchNamedAreaScheduledEventInfo(); // #FETCHS

          this.props.goto(`/admin/scheduled-job`);
        }
      );
    });
  };

  render() {
    const {
      //isLoading, error,
      strings,
      userLanguage,
    } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    // APP_TERMINOLOGY
    let strScheduledEvent = strings?.["Scheduled Job"];
    let strScheduledEvents = strings?.["Scheduled Jobs"];

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"scheduledEventForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) =>
            this.submitMyForm(this._preProcessValues(formValues))
          }
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["Add Scheduled Job"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <ScheduledEventSimpleForm
            initialValues={{
              disable: true,
              start: { value: 0, date: "" },
              stop: { value: 0, date: "" },
              duration: { value: 1, unit: "minute" },
              //
              year: { every: false, value: "*" },
              dayOfWeek: { every: false, value: "*" },
              month: { every: false, value: "*" },
              dayOfMonth: { every: false, value: "*" },
              hour: { every: false, value: 12 },
              minute: { every: false, value: 0 },
              second: { every: false, value: 0 },
              //
              cron: {
                start: DEFAULT_CRON_EXPRESSION,
                stop: DEFAULT_CRON_EXPRESSION_STOP,
              },
              //
              namedAreaGroupOption: {
                group: "none",
                level: "none",
                polygonId: "none",
              },
              //
              events: [],
            }}
            header={strings?.["Add Scheduled Job"]}
            onSubmit={(values) =>
              this.submitMyForm(this._preProcessValues(values))
            }
            onCancel={() => {
              this.props.goto(`/admin/scheduled-job`);
            }}
            strings={strings}
            userLanguage={userLanguage}
          />

          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    userLanguage: getUserData(state)?.language || "en-US",
  };
};

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    fetchNamedAreaScheduledEventInfo: () =>
      dispatch(fetchNamedAreaScheduledEventInfo()),
    saveNewScheduledEvent: (data) => dispatch(saveNewScheduledEvent(data)),
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledEventCreatePage);
