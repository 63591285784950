import React, { Component } from "react";
import { connect } from "react-redux";
import { push as goto } from "react-router-redux";

import { Container, Header, Grid } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

//import { v4 as uuid } from "uuid";

//import SubmissionModal from "admin/SubmissionModal";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
//import DataLoadingMessage from "components/DataLoading/dataLoading";

//import { SubmissionError, isSubmitting, hasSubmitSucceeded } from "redux-form";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import LocalisationEditForm from "admin/localisation/LocalisationEditForm";

import { changeLocalisation } from "components/Localisation/actions";

import { fetchLocalisation } from "components/Localisation/actions";
import { getLocalisation } from "components/Localisation/reducer";

class LocalisationEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      initialValues: {},
      submitted: false,
      saveSuccess: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );

    const { submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      goto("/");
    }
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    const { changeLocalisation, reset, localisation } = this.props;
    const { id: language, fieldData } = values;

    let localisations = [];

    for (const reference in fieldData) {
      if (Object.hasOwnProperty.call(fieldData, reference)) {
        // 'term' is the form entry for the reference. This may not be updated but we assume it is.
        const term = fieldData[reference];
        // find existing localisation term record from db (if it exists),
        // this returns the Id of the entry
        const existingTerm = localisation.find(
          (term) => term.language === language && term.reference === reference
        ) || { id: "", application: "default", component: "App" }; // default

        localisations.push({
          ...existingTerm,
          reference,
          language,
          term,
        });
      }
    }

    // console.log(
    //   `LocalisationEditPage submitMyForm localisations `,
    //   localisations
    // );

    const newValues = { id: language, localisations };

    return new Promise((resolve, reject) => {
      changeLocalisation({ values: newValues, resolve, reject });
    }).then(() => {
      this.props.fetchLocalisation();
      this.props.goto("/tools/localisation");
      // #TODO
      // Should wait for change to propogate then reset() to return to new intialValues
      //reset();
    });
  };

  render() {
    const { id, strings, goto } = this.props;

    const languageKeys = Object.keys(strings?._props?.["en-US"])?.sort();

    //console.log("xxx LocalisationEditPage strings ", strings);
    //console.log("xxx LocalisationEditPage strings ", strings?.getLanguage(id));
    //console.log("xxx LocalisationEditPage id", id);
    //console.log("xxx LocalisationEditPage languageKeys", languageKeys);

    let data = [];

    languageKeys.forEach((key) => {
      // defaultLanguageFirstLevelKeys
      data.push({
        reference: key,
        english: strings?._props?.["en-US"]?.[key],
        localisation: strings?._props?.[id]?.[key],
      });
    });

    const fieldData = { data, ...strings?._props?.[id] };

    //console.log("xxx LocalisationEditPage fieldData", fieldData);

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"localisationEditForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{`Edit Localisation ${id}`}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <LocalisationEditForm
            id={id}
            initialValues={{
              _action: "edit", // used to control dlg display
              id: id,
              data: data,
              fieldData: fieldData,
            }}
            header={`Edit ${id}`}
            onSubmit={(values) => this.submitMyForm(values)}
            onCancel={() => {
              goto(`/tools/localisation`);
            }}
            strings={strings}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const match = props.match;
  const id = match.params.id;
  const localisation = getLocalisation(state);

  return {
    id,
    localisation,
  };
};

export default connect(mapStateToProps, {
  TurnOnOffPokeTheWorker,
  goto,
  changeLocalisation,
  fetchLocalisation,
})(LocalisationEditPage);
