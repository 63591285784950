import { tagApi, dummyResponse } from "api/apiAxios";

// Some of the API routings are:

// http://10.8.0.13:8010/api/v1/tag/1
// http://10.8.0.13:8010/api/v1/tag/mac/ac:23:3f:63:37:3d
// http://10.8.0.13:8010/api/v1/tag/areas/current
// http://10.8.0.13:8010/api/v1/tag/areas/summary
// http://10.8.0.13:8010/api/v1/tag/area/IOT_Office
// http://10.8.0.13:8010/api/v1/tag/area/IOT_Office/location/Office_2
// http://10.8.0.13:8010/api/v1/tag/area/IOT_Office/location/Office_2/position/3

// There are some models for user, vehicle, and positions:

// http://10.8.0.13:8010/api/v1/user/1
// http://10.8.0.13:8010/api/v1/vehicle/1
// http://10.8.0.13:8010/api/v1/position/1

// see - https://bitbucket.org/iotautomation/iot-tag-tracker-webapp/src/master/router/router.go

// **********************************************

// Tag
// ar.Get(s, "/tag/{id}", middleware.Get_Tag_By_Id)
// ar.Get(s, "/tag/mac/{mac}", middleware.Get_Tag_By_Mac)
// ar.Get(s, "/tag/areas/current", middleware.Get_Current_Tag_Status_All)

export function* getTagById(id) {
  const response = yield tagApi.get(`/tag/${id}`);
  return response?.data;
}

export function* getTagByMac(mac) {
  const response = yield tagApi.get(`/tag/mac/${mac}`);
  return response?.data;
}

// e.g. http://10.8.0.13:8010/api/v1/tag/area/IOT_Office
export function* getTags() {
  // console.log(
  //   `tagTracking getTags api tag/areas/current ts `,
  //   new Date().getTime()
  // );
  const response = yield tagApi.get(`/tag/areas/current`); // yield dummyResponse(false);
  //console.log(`tagTracking getTags api tag/areas/current response `, response);
  return response?.data;
}

// Assign
// ar.Get(s, "/tag/{id}/assign/user/{user_id}", middleware.Assign_User_To_Tag)
// ar.Get(s, "/tag/{id}/assign/vehicle/{vehicle_id}", middleware.Assign_Vehicle_To_Tag)
// ar.Get(s, "/tag/{id}/unassign/user", middleware.Unassign_User_From_Tag)
// ar.Get(s, "/tag/{id}/unassign/vehicle", middleware.Unassign_Vehicle_From_Tag)

export function* assignUser({ id, user_id }) {
  const response = yield tagApi.get(`/tag/${id}/assign/user/${user_id}`);
  return response?.data;
}

export function* assignVehicle({ id, vehicle_id }) {
  const response = yield tagApi.get(`/tag/${id}/assign/vehicle/${vehicle_id}`);
  return response?.data;
}

export function* unassignUser(id) {
  //console.log(`apiTagTracking unassignUser`, id);
  const response = yield tagApi.get(`/tag/${id}/unassign/user`);
  return response?.data;
}

export function* unassignVehicle(id) {
  const response = yield tagApi.get(`/tag/${id}/unassign/vehicle`);
  return response?.data;
}

// User
// ar.Post(s, "/user", middleware.Add_Update_User)
// ar.Get(s, "/user/{id}", middleware.Get_User_By_Id)
// ar.Get(s, "/users", middleware.Get_All_Users)
// ar.Delete(s, "/user/{id}", middleware.Delete_User_By_Id)

export function* addUpdateUser({ id, firstname, lastname }) {
  const response = yield tagApi.post(`/user`, {
    id,
    firstname,
    lastname,
  });
  return response?.data;
}

export function* getTagUserById(id) {
  const response = yield tagApi.get(`/user/${id}`);
  return response?.data;
}

export function* getAllTagUsers() {
  const response = yield tagApi.get(`/users`);
  return response?.data;
}

export function* deleteTagUserById(id) {
  const response = yield tagApi.delete(`/user/${id}`, { id });
  return response?.data;
}

// Vehicle
// ar.Post(s, "/vehicle", middleware.Add_Update_Vehicle)
// ar.Get(s, "/vehicle/{id}", middleware.Get_Vehicle_By_Id)
// ar.Get(s, "/vehicles", middleware.Get_All_Vehicles)
// ar.Delete(s, "/vehicle/{id}", middleware.Delete_Vehicle_By_Id)

export function* addUpdateVehicle({ id, name, type }) {
  const response = yield tagApi.post(`/vehicle`, {
    id,
    name,
    type,
  });
  return response?.data;
}

export function* getTagVehicleById(id) {
  const response = yield tagApi.get(`/vehicle/${id}`);
  return response?.data;
}

export function* getAllTagVehicles() {
  const response = yield tagApi.get(`/vehicles`);
  return response?.data;
}

export function* deleteTagVehicleById(id) {
  const response = yield tagApi.delete(`/vehicle/${id}`, { id });
  return response?.data;
}

// Location
//ar.Get(s, "/location/{id}", middleware.Get_Location_By_Id)
//ar.Delete(s, "/location/{id}", middleware.Delete_Location_By_Id)

export function* getTagLocationById(id) {
  const response = yield tagApi.delete(`/location/${id}`, { id });
  return response?.data;
}

export function* deleteTagLocationById(id) {
  const response = yield tagApi.delete(`/vehicle/${id}`, { id });
  return response?.data;
}

// Zones
// /api/v1/zone/update
export function* updateTagZone(id) {
  //console.log(`updateTagZone id`, id);
  const response = yield tagApi.post(`/zone/update`, { id });
  //console.log(`updateTagZone response`, response);
  return response?.data;
}
