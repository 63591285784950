export const makeAllDataForWorkerState = (allData) => {
  const {
    areaStatuses, // not used. recreated from areaInfos
    areaInfos,
    controllerCoordinates,
    controllerStatuses,
    fireflyCoordinates,
    fireflyStatuses,
    namedAreaInfos,
    namedAreaStatuses,
    namedAreaEvents,
  } = allData;

  const toObject = (arr, key) =>
    arr?.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

  // remove functions, like transforms, before sending data
  let rawAreaInfos = {};
  let rawAreaStatuses = {};

  if (areaInfos.features) {
    Object.values(areaInfos.features).forEach((feature) => {
      const {
        id,
        geometry,
        properties: {
          //id: propId,
          ceiling,
          coordinatesUtm,
          default_color,
          default_state,
          floor,
          image_filename,
          image_info,
          name,
          namedAreaGroup,
          ref_coord,
          slug,
          status = "api",
        },
      } = feature;

      const properties = {
        id,
        ceiling,
        coordinatesUtm,
        default_color,
        default_state,
        floor,
        image_filename,
        image_info,
        name,
        namedAreaGroup,
        ref_coord,
        slug,
        status,
      };

      rawAreaInfos[id] = {
        geometry,
        properties: properties,
      };
      rawAreaStatuses[id] = properties;
    });
  }

  let rawFireflyCoordinates = {};

  if (fireflyCoordinates?.features) {
    const cloneFireflyCoordinatesFeatures = JSON.parse(
      JSON.stringify(fireflyCoordinates.features)
    );

    cloneFireflyCoordinatesFeatures.map((feature) => {
      const { id, properties, geometry } = feature;
      const { coordinates, type } = geometry;
      const { easting, northing } = properties;

      let newFeature = feature;
      newFeature.geometry = { coordinates: [easting, northing], type: type };
      rawFireflyCoordinates[id] = newFeature;
    });
  }

  let rawControllerCoordinates = {};

  if (controllerCoordinates?.features) {
    const cloneControllerCoordinates = JSON.parse(
      JSON.stringify(controllerCoordinates.features)
    );

    cloneControllerCoordinates.map((feature) => {
      const { id, properties, geometry } = feature;
      const { coordinates, type } = geometry;
      const { easting, northing } = properties;

      let newFeature = feature;
      newFeature.geometry = { coordinates: [easting, northing], type: type };
      rawControllerCoordinates[id] = newFeature;
    });
  }

  return {
    areaInfos: rawAreaInfos,
    areaStatuses: rawAreaStatuses,
    controllerCoordinates: rawControllerCoordinates, // toObject(controllerCoordinates?.features, "id") || {},
    controllerStatuses: controllerStatuses || {},
    fireflyCoordinates: rawFireflyCoordinates, // toObject(fireflyCoordinates?.features, "id") || {},
    fireflyStatuses: fireflyStatuses || {},
    namedAreaInfos: toObject(namedAreaInfos?.features, "id") || {},
    namedAreaStatuses: namedAreaStatuses || {},
    namedAreaEvents: namedAreaEvents || {},
  };
};
