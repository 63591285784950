import React, { PureComponent } from "react";
import { push } from "react-router-redux";

import PropTypes from "prop-types";

import { Container, Header, Grid, Message } from "semantic-ui-react";

import FlashMessagesList from "FlashMessages";

import { connect } from "react-redux";

//import { getFireflyById } from "UPSPanelControllers/reducer";
import { getFireflyById } from "components/WebWorker/reducer";

import FireflyDetailsForm from "./FireflyDetailsForm";
// import FireflyStatusSummary from "./FireflyStatusSummary";
// import FireflyLightStatus from "./FireflyLightStatus";
// import FireflyTopologyStatus from "./FireflyTopologyStatus";
// import FireflyElectricalStatus from "./FireflyElectricalStatus";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class FireflyDetail extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    error: PropTypes.string,
  };

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  render() {
    const { id, error, isDeleteFireflyNotCommissioned, strings } = this.props;

    // #WIP - ATM don't allow reload, or direct link to a firefly page.....
    // Instead redirect to the controller list page and wait for firefly to list (with next message).

    if (error) {
      this.props.goto("/admin/firefly/");
      return null;
      //   return (
      //   <>
      //     <DataLoadingMessage id={id} />
      //     <DebugPagePropsMessages that={this} />
      //   </>
      // );
    }

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">FireFly Details</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <FireflyDetailsForm
            id={id}
            isDeleteFireflyNotCommissioned={isDeleteFireflyNotCommissioned}
            strings={strings}
          />

          {/* <h2>Firefly - {id}</h2>
            <Grid.Row>
              <Grid.Column>
                <FireflyDetailsForm id={id} />
              </Grid.Column>
              <Grid.Column>
                <Header as="h2">Status information</Header>
                <FireflyRawStatusReport id={id} />
              </Grid.Column>
            </Grid.Row> 
          </Grid> */}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    goto: (path) => dispatch(push(path)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

const mapStateToProps = (state, props) => {
  const match = props.match;
  const id = match.params.id;
  const firefly = getFireflyById(state, id);
  return {
    id,
    error: firefly ? undefined : "No FireFly for id",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FireflyDetail);
