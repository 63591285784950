import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

function ChooseEditorMode({ mode, color, onChange }) {
  return (
    <Button.Group floated={"right"}>
      <Button
        onClick={(e) => {
          e.preventDefault();
          onChange("asString");
        }}
        color={mode === "asString" ? color : null}
      >
        String
      </Button>
      <Button.Or />
      <Button
        onClick={(e) => {
          e.preventDefault();
          onChange("asIndividuals");
        }}
        color={mode === "asIndividuals" ? color : null}
      >
        Individual
      </Button>
    </Button.Group>
  );
}

ChooseEditorMode.defaultProps = {};

ChooseEditorMode.propTypes = {
  mode: PropTypes.oneOf(["asString", "asIndividuals"]).isRequired,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ChooseEditorModeField = ({
  color,
  input: { value, onChange },
  label,
  disabled = false,
  type,
  meta: { touched, error },
}) => {
  return <ChooseEditorMode color={color} mode={value} onChange={onChange} />;
};

export default ChooseEditorModeField;
