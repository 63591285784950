import React, { Component } from "react";
import { connect } from "react-redux";

import {
  createNamedAreaAroundFirefly,
  createFireflyNamedAreaId,
} from "NamedAreas/createNamedAreaAroundFirefly";

import { lightingEventPriority } from "components/ConfigJs";

// #NOTE - DEFAULT PRIORITY SETTINGS
//
// MAX_PRIORITY = 10000 // <<--- see 'manager/manager.go'
//const DEFAULT_START_FORCED_LIGHT_PRIORITY = 5000;

// FireFly lights colors are 'forced' by creating a polygon around the light coordinate and applying a lighting
// event for the color and state required.
//
// This function creates a new namedArea (Set) or deleted an existing namedArea (Clear).
//

// Parameters:
//
// action - "clear", "lightState", "lightColor"
// fireflyId - e.g. "DMLZ_Extraction:Panel1:1"
// easting - easting coordinate the FireFly e.g. 57.22
// northing - northing coordinate the FireFly e.g. 94.54
// area - area of the FireFly e.g. "DMLZ_Exraction"
// color - color object for FireFly e.g. {status: {... }, plan: {...}}
// setFireflyColor -  target color to be forced e.g. "red"
// newSetFireflyState -  target state to be forced e.g. "strobe"

export const ForceSingleLightManageNamedArea = ({
  //properties
  action,
  fireflyId,
  area,
  easting,
  northing,
  color,
  setFireflyColor,
  setFireflyState,
  // functions
  namedAreaEvents,
  deleteNamedArea,
  saveNewNamedArea,
  activate,
}) => {
  // create a namedAreaId for the firefly
  const fireflyNamedAreaId = createFireflyNamedAreaId(fireflyId);

  const namedAreaEventForFirefly = namedAreaEvents?.find((event) =>
    event.id.includes(fireflyNamedAreaId)
  );

  console.log(
    `FORCED LIGHT: setForceLight action,
  fireflyId,
  easting,
  northing,
  area,
  color,
  setFireflyColor,
  setFireflyState,
  fireflyNamedAreaId,
  namedAreaEventForFirefly`,
    action,
    fireflyId,
    easting,
    northing,
    area,
    color,
    setFireflyColor,
    setFireflyState,
    fireflyNamedAreaId,
    namedAreaEventForFirefly
  );

  let promiseArray = [];
  let operationButton = {};
  let isActivate = false;

  if (action === "clear") {
    // if there are existing events, delete them ...........
    if (namedAreaEventForFirefly) {
      //
      promiseArray.push(
        new Promise((resolve, reject) =>
          deleteNamedArea({
            values: { id: namedAreaEventForFirefly.id },
            resolve,
            reject,
          })
        )
      );
    }
  }
  // process the changes ...................
  else {
    // get strobe checkBox from State if it is not passed through the parameters
    let newSetFireflyState = "on";
    if (setFireflyState !== null) {
      newSetFireflyState = setFireflyState;
    } else {
      newSetFireflyState = "on";

      // #WIP

      //if find record in state array === strobe
      // this.state.forcedLightState.some((state) => state === fireflyId)
      //   ? "strobe"
      //   : "on";
    }

    // console.log(
    //   `forcedLight: newSetFireflyState this.state.forcedLightState namedAreaEventForFirefly`,
    //   newSetFireflyState,
    //   this.state.forcedLightState,
    //   fireflyId,
    //   namedAreaEventForFirefly
    // );

    const currentNamedAreaEventForFireflyColor =
      namedAreaEventForFirefly?.active_color?.toLowerCase?.();

    const currentNamedAreaEventForFireflyState =
      namedAreaEventForFirefly?.active_state?.toLowerCase?.();

    let isStateChanged = newSetFireflyState !== "on";
    if (currentNamedAreaEventForFireflyState) {
      isStateChanged =
        currentNamedAreaEventForFireflyState !== newSetFireflyState;
    }

    let isColorChanged = color.status !== setFireflyColor;
    if (currentNamedAreaEventForFireflyColor) {
      isColorChanged = currentNamedAreaEventForFireflyColor !== setFireflyColor;
    }

    console.log(
      `forcedLight namedAreaEventForFirefly `,
      namedAreaEventForFirefly,
      newSetFireflyState
    );
    console.log(`forcedLight isColorChanged `, isColorChanged);
    console.log(`forcedLight isStateChanged `, isStateChanged);

    // Explanation
    //
    // In all cases delete the current namedArea
    // If color has not changed state, do not add a new namedArea (i.e. toggle OFF)
    // If color changed state, add a new namedArea
    // If state has changed, add a new namedAra

    // In all cases just delete the current namedArea
    //
    if (namedAreaEventForFirefly) {
      if (false && !isColorChanged && !isStateChanged) {
        // if toggle color OFF remove any existing strobe setting for deleted namdArea
        // i.e. color has changed the state has not changed

        console.log(
          `forcedLight: <<---DELETE--->> newForcedLightState fireflyId`,
          fireflyId
        );

        let newForcedLightState = this.state.forcedLightState;
        newForcedLightState = newForcedLightState.filter(
          (id) => id !== fireflyId
        );
        this.setState({ forcedLightState: newForcedLightState });
      }

      // console.log(
      //   `forcedLight: <<---DELETE--->> deleteNamedArea id`,
      //   namedAreaEventForFirefly.id
      // );

      promiseArray.push(
        new Promise((resolve, reject) =>
          deleteNamedArea({
            values: { id: namedAreaEventForFirefly.id },
            resolve,
            reject,
          })
        )
      );
    }

    // Get the next ForcedLight priority
    const allForcedNamedAreas = namedAreaEvents?.filter((event) =>
      event.id.includes("FORCED-")
    );

    const highestForcedNamedAreaPriority = Math.max(
      ...allForcedNamedAreas.map((o) => o.priority),
      lightingEventPriority()?.forced || 5000
    );

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // #NOTE - there must be a button at the priority level
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const newNamedArea = createNamedAreaAroundFirefly(
      fireflyId,
      { easting, northing },
      area,
      setFireflyColor,
      newSetFireflyState,
      1 // highestForcedNamedAreaPriority + 1
    );

    operationButton = newNamedArea.button?.[1]; // 1st, and only button, not highestForcedNamedAreaPriority + 1
    // get next highest
    operationButton.priority = highestForcedNamedAreaPriority + 1;

    //console.log(`forcedLight xxx operationButton`, operationButton);
    //console.log(`forcedLight xxx newNamedArea`, newNamedArea);

    // if toggle to change color, or change state, or there is a new color
    // #NOTE - below stops denies creating new event if same color,
    // however this is now superseded for forcing any color, even currently displayed color.
    //if (isColorChanged || isStateChanged) {

    // make a new event under all situations
    if (true) {
      // make a new event
      //
      isActivate = true;
      console.log(
        `forcedLight: <<---ADD--->> saveNewNamedArea newNamedArea`,
        newNamedArea
      );

      promiseArray.push(
        new Promise((resolve, reject) =>
          saveNewNamedArea({
            values: newNamedArea,
            resolve,
            reject,
          })
        )
      );
    }
  }

  if (promiseArray.length) {
    return Promise.all(promiseArray)
      .then((result) => {
        console.log(`FORCELIGHT: saveNewNamedArea `, result);
        if (isActivate) {
          activate(operationButton); // -> sendNamedAreaEventSingle
        }
      })
      .catch((error) => {
        console.log(
          `forcedLight: saveFireflyLedData saveNewNamedAre failed `,
          error
        );
        // #WIP - do something if fails!???
      });
  }
  /////////////////////
};
