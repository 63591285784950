// see - https://github.com/lodash/lodash/issues/723#issuecomment-332321054
// see - https://gist.github.com/Billy-/d94b65998501736bfe6521eadc1ab538
export function omitDeep(value, key) {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key));
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k == key) return newObject;
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject);
    }, {});
  }
  return value;
}
