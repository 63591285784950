import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _isEmpty from "lodash/isEmpty";
import { Table, Ref, Grid, Icon, Popup } from "semantic-ui-react";

import NamedAreaGroupTableSubSubItems from "./NamedAreaGroupTableSubSubItems";

import { toWebColor } from "utils/toWebColor";

import "admin/named-area-group/NamedAreaGroup.css";

import { makeIcon, RenderSvgIcon } from "components/Map/MakeIcon";

const getItemStyle = (highlightColor, color, isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? highlightColor : color,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (color, isDraggingOver) => ({
  background: isDraggingOver ? color : "white",
});

export default class NamedAreaGroupTableSubItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: [],
      collapseAll: false,
    };
  }

  componentDidMount = () => {
    // // collapse all sections on startup
    // const groupIds = this.props.items.map((item) => item.id);
    // this.setState({ collapse: groupIds });
    // this.setState({ collapseAll: this.state.collapseAll });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.collapseAll !== this.props.collapseAll) {
      if (this.props.collapseAll) {
        const groupIds = this.props.items.map((item) => item.id);
        this.setState({ collapse: groupIds });
        //this.setState({ collapseAll: this.state.collapseAll });
      } else {
        this.setState({ collapse: [] });
        //this.setState({ collapseAll: this.state.collapseAll });
      }
    }
  }

  onClickHandler = (e, type, id, groupId, data) => {
    //console.log("onClickHandler e", e);

    if (e.preventDefault) {
      e.preventDefault();
    }

    if (e.stopPropagation) {
      e.stopPropagation(); // stop submit to calling form
    }

    let newCollapse = this.state.collapse;
    let newCollapseAll = this.state.collapseAll;

    switch (type) {
      case "deleteItem":
        this.props.onClickHandler(e, "deleteItem", id, groupId, null);
        break;

      case "collapse":
        if (newCollapse.includes(id)) {
          newCollapse = newCollapse.filter((item) => item !== id);
        } else {
          newCollapse.push(id);
        }
        this.setState({ collapse: newCollapse });
        break;
      case "cog":
        this.props.onClickHandler(e, "cog", id, groupId, null);
        break;

      default:
        break;
    }
  };

  render() {
    const { items, NAMEDAREAS, groupId, strings, userSettings } = this.props;

    return (
      <Grid>
        <Grid.Column>
          <Table>
            <Droppable droppableId={groupId} type={"droppableSubItem"}>
              {(provided, snapshot) => (
                <Ref innerRef={provided.innerRef}>
                  <Table.Body
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                    style={getListStyle("lightblue", snapshot.isDraggingOver)}
                  >
                    {items?.length ? (
                      items?.map?.((item, index) => (
                        <Draggable
                          draggableId={item.id}
                          index={index}
                          key={item.id}
                        >
                          {(provided, snapshot) => (
                            <Ref innerRef={provided.innerRef}>
                              <>
                                <Table.Row
                                  {...provided.draggableProps}
                                  // {...provided.dragHandleProps} -> moved to <Icon name="bars"... />
                                  isDragging={snapshot.isDragging}
                                  style={getItemStyle(
                                    "rgba(173,216,230,1)",
                                    //"lightblue",
                                    "rgba(173,216,230,0.2)",
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Table.Cell>
                                    <Popup
                                      content={
                                        strings?.[
                                          "Click to expand/collapse the Level"
                                        ]
                                      }
                                      trigger={
                                        <Icon
                                          name={
                                            !this.state.collapse.includes(
                                              item.id
                                            )
                                              ? "folder open"
                                              : "folder"
                                          }
                                          onClick={(e) =>
                                            this.onClickHandler(
                                              e,
                                              "collapse", // type
                                              item.id, // id
                                              groupId, // index
                                              null
                                            )
                                          }
                                        />
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell width={15}>
                                    <Popup
                                      content={
                                        strings?.["Drag Level to reorder"]
                                      }
                                      trigger={
                                        <Icon
                                          name="bars"
                                          {...provided.dragHandleProps}
                                        />
                                      }
                                    />
                                    {/* <Icon
                                      name="lightbulb"
                                      color={toWebColor(item.settings.color)}
                                      className={
                                        [
                                          "strobe",
                                          "forward",
                                          "backward",
                                        ].includes(item.settings.state)
                                          ? "flashing"
                                          : null
                                      }
                                    /> */}
                                    <RenderSvgIcon
                                      name={
                                        item.settings.marker || "RoundMarker"
                                      }
                                      fillColor={toWebColor(
                                        item.settings.color
                                      )}
                                      strokeColor={toWebColor(
                                        item.settings.color
                                      )}
                                      size={10}
                                      markerClass={
                                        [
                                          "strobe",
                                          "forward",
                                          "backward",
                                        ].includes(item.settings.state)
                                          ? "flashing"
                                          : null
                                      }
                                    />
                                    <strong>{item.label}</strong>
                                    {/* {` id: ${item.id}`}
                                    {` draggingOver: ${snapshot.draggingOver}`}
                                    {` isDragging: ${snapshot.isDragging}`} */}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Popup
                                      content={strings?.["Edit Level settings"]}
                                      trigger={
                                        <Icon
                                          name="cog"
                                          onClick={(e) =>
                                            this.onClickHandler(
                                              e,
                                              "cog",
                                              item.id,
                                              groupId,
                                              null
                                            )
                                          }
                                        />
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Popup
                                      content={strings?.["Delete Level"]}
                                      trigger={
                                        <Icon
                                          name="trash"
                                          onClick={(e) =>
                                            this.onClickHandler(
                                              e,
                                              "deleteItem", // type
                                              item.id, // id
                                              groupId, // index
                                              null
                                            )
                                          }
                                        />
                                      }
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                {!this.state.collapse.includes(item.id) &&
                                  !snapshot?.isDragging && (
                                    <Table.Row>
                                      <Table.Cell colSpan={5}>
                                        <NamedAreaGroupTableSubSubItems
                                          NAMEDAREAS={NAMEDAREAS}
                                          items={item.subItems}
                                          groupId={item.id}
                                          onClickHandler={(
                                            e,
                                            type,
                                            id,
                                            index,
                                            data
                                          ) =>
                                            this.onClickHandler(
                                              e,
                                              type,
                                              id,
                                              index,
                                              data
                                            )
                                          }
                                          strings={strings}
                                          userSettings={userSettings}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  )}
                              </>
                            </Ref>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell>
                          {strings?.["No Levels. Add a level"]}{" "}
                          {provided.placeholder}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {!_isEmpty(provided.placeholder) &&
                      snapshot.isDraggingOver && (
                        <Table.Row>
                          <Table.Cell
                            colSpan={16}
                            style={getListStyle(
                              "lightred",
                              snapshot.isDraggingOver
                            )}
                          >
                            {provided.placeholder}
                          </Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Ref>
              )}
            </Droppable>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}
