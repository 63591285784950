import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import FireflyName from "containers/FireflyName";
import LatLngSummary from "components/LatLngSummary";
import CommissioningStatus from "./FireflyCommissioningStatus";

import selector from "./form-value-selector";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";

class FireflyRow extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const { id, topology, position } = this.props;
    return (
      <Table.Row>
        <Table.Cell>{topology.position}</Table.Cell>
        <Table.Cell>
          <FireflyName id={id} />
        </Table.Cell>
        {/* <Table.Cell>
          <LatLngSummary position={position} displayDefault="-" />
        </Table.Cell> */}
        <Table.Cell>
          <CommissioningStatus formname={`fireflies.${id}`} />
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapState = (state, props) => {
  const firefly = selector(state, `fireflies.${props.id}`);
  return firefly;
};
FireflyRow = connect(mapState)(FireflyRow);

class PortSummaryFireflyDetails extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }
  render() {
    const { fireflies } = this.props;
    return (
      <Table compact={"very"} size={"small"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Id</Table.HeaderCell>
            {/* <Table.HeaderCell>Lat/Lng</Table.HeaderCell> */}
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fireflies.map((id) => (
            <FireflyRow id={id} key={id} />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default PortSummaryFireflyDetails;
