import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Container from 'semantic-ui-react/dist/commonjs/elements/Container'
import Header from 'semantic-ui-react/dist/commonjs/elements/Header'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'

import { LayersControl } from 'react-leaflet'
import MineLevelMap from 'components/MineLevelMap'
import CurrentMineLevelFirefliesFeatureGroup from 'containers/CurrentMineLevelFirefliesFeatureGroup'
import MineLevelSeismicFeatureGroup from 'components/MineLevelSeismicFeatureGroup'

import MineLevelPageHeader from 'containers/MineLevelPageHeader'
import MineLevelSeismicLayerList from './MineLevelSeismicLayerList'
// import PrintControl from 'react-leaflet-easyprint'

class MineLevelSeismicLayerSummaryPage extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = { previewId: null }
  }

  componentWillReceiveProps () {
    this.setState({ previewId: null })
  }

  selectForPreview = id => {
    this.setState({ previewId: id })
  }

  render () {
    const { previewId } = this.state
    const showPreview = previewId !== null

    /*
    <PrintControl
      position='topleft'
      sizeModes={['Current', 'A4Portrait', 'A4Landscape']}
      hideControlContainer={false}
      title='Export as PNG'
      exportOnly
    />
    */

    return (
      <Container>
        <MineLevelPageHeader>Seismic Layer List</MineLevelPageHeader>
        <Grid divided>
          <Grid.Row>
            <Grid.Column width={showPreview ? 8 : 16}>
              <MineLevelSeismicLayerList
                onPreview={this.selectForPreview}
                previewingId={previewId}
                // onReinstate={id => { console.log('Please reinstate', id) }}
              />
            </Grid.Column>
            {showPreview && (
              <Grid.Column width={8}>
                <Header as='h4'>Map View</Header>
                <MineLevelMap>
                  <LayersControl position={'topright'}>
                    <LayersControl.Overlay name={'Fireflies'} checked>
                      <CurrentMineLevelFirefliesFeatureGroup />
                    </LayersControl.Overlay>
                    <MineLevelSeismicFeatureGroup seismicLayerId={previewId} />
                  </LayersControl>
                </MineLevelMap>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default MineLevelSeismicLayerSummaryPage
