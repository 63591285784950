import React, { Component } from "react";
import { connect } from "react-redux";

import { Slider } from "react-semantic-ui-range";
import "semantic-ui-css/semantic.min.css";

import {
  Container,
  Header,
  Grid,
  List,
  Segment,
  Popup,
  Button,
  Icon,
  Message,
  Checkbox,
  Divider,
} from "semantic-ui-react";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { messageToken, getUserSessionIp } from "utils/messageToken";
import { microTime } from "utils/microTime";
import { toFireflyColor } from "utils/toFireflyColor";
import { sleep } from "utils/sleep";

import { mqttPublish } from "components/WebWorker/actions";
import {
  getFaults,
  getAllNamedAreaEvents,
  getNamedAreaEventsByAreaId,
  getButtonPrioritysInNamedAreaGroupByAreaId,
  getNamedAreaEventButtonGroupState,
} from "components/WebWorker/reducer";

import { saveNewNamedAreaEvent } from "NamedAreas/actions";
import {
  activateNamedAreaEvent,
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
  waitEventTimeOut,
  updateButtonGroupState,
} from "OperationalChanges/actions";

import {
  getServerInfo,
  isOnOffPokeTheWorker,
  isDisabledPokeTheWorker,
  getPollRates,
  getMqttBroker,
} from "components/Settings/reducer";

import {
  getPrevNamedAreaGroups,
  getPrevNamedAreaGroupButtonStates,
} from "NamedAreaGroups/reducer";

import { ClearFaults } from "components/MQTT/actions";

import {
  TurnOnOffPokeTheWorker,
  DisablePokeTheWorker,
  SetPokeTheWorkerPollRate,
  fetchServerInfo,
} from "components/Settings/actions";

import { deleteEventLog } from "components/EventLog/actions";
import { deleteScheduledEventJobs } from "NamedAreas/actions";

import { updateRemoteButtonClick } from "OperationalChanges/actions";
import _ from "lodash";
//import Heartbeat from "react-heartbeat";

import { getMapImages } from "components/Settings/reducer";
import { DateTime } from "luxon";

const buttonData = [
  "IOT_Office:f6044b5d-6042-40b3-b8f3-3fd29ac83e61:2",
  "IOT_Office:84b8a00e-bd76-49aa-9b38-a914e1ddfc53:1",
];

class ToolsSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workerPollRate: this.props.pollRates.worker,
      workerDisable: this.props.pollRates.disable,
      isLightControlSim: false,
      lightControlSimRate: 5000,
    };
  }

  componentDidMount() {
    // #FETCHS
    this.props.fetchServerInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.mapImages.map((img) => {
      const bannerImg = document.getElementById(`${img.id}`);
      bannerImg.src = "data:image/png;base64," + img.imageData;
    });
  }

  sendEventClearMsg = () => {
    this.props.mqttPublish({
      topic: `system/event/clear`,
      qos: 0,
      message: {
        clear_events: true,
        token: messageToken(),
      },
      retained: false,
    });
  };

  // #NOTE
  // Sends individual clear messages for each fault.
  // This is superceded in favour of `sendFaultClearMsg`
  // Which instructs the server to clear the db of all fault data.
  //
  // Left in place here for reference.
  //
  sendFaultDeleteMsg = () => {
    const faults = this.props.faults;

    faults.forEach((fault, idx) => {
      const { type, subtype, id } = fault;

      const faultTopic = () => {
        return `fault/${id}/${type}`;
      };

      const faultMsg = (id, type, subtype) => {
        return {
          id,
          type,
          subtype,
          active: false,
          delete: true,
          token: messageToken(),
        };
      };

      switch (type) {
        case "network":
        case "battery":
        case "firefly":
          // send deactive message

          this.props.mqttPublish({
            topic: faultTopic(id, type, subtype),
            qos: 0,
            message: faultMsg(id, type),
            retained: false,
          });

          // send null message
          this.props.mqttPublish({
            topic: faultTopic(id, type),
            qos: 0,
            message: {},
            retained: true,
          });
          break;

        default:
          break;
      }
    });

    // #NOTE
    // #WIP - this clears the fault state _BUT_ this should wait for an ACK from the publish
    // before proceeding.
    // I am waiting for this to be implemented.

    // #NOTE - this clears the fault state locally, but note that the `system/fault/clear`
    // is also receive by the worker and will clear the state from the front end.
    this.props.ClearFaults();
  };

  sendFaultClearMsg = () => {
    // Other clients need to receive this message to clear their states _but_ mqtt does not process empty messages.
    // So send an additional message with the anticipated ongoing format.
    this.props.mqttPublish({
      topic: `system/fault/clear`,
      qos: 0,
      message: {
        clear_faults: true,
        token: messageToken(),
      },
      retained: false,
    });

    // #NOTE
    // #WIP - this clears the fault state _BUT_ this should wait for an ACK from the publish
    // before proceeding.
    // I am waiting for this to be implemented.

    // #NOTE - this clears the fault state locally, but note that the `system/fault/clear`
    // is also receive by the worker and will clear the state from the front end.
    this.props.ClearFaults();
  };

  handleDeleteEventLog = () => {
    this.props.deleteEventLog({ start: 0, end: 0 });
  };

  handleDeleteEventJob = () => {
    this.props.deleteScheduledEventJobs();
  };

  onChangeCheckbox = (evt, data) => {
    let checked = data.checked;

    this.props.DisablePokeTheWorker(!checked);

    this.setState({
      workerDisable: !checked,
    });
  };

  handleTimeout = (timerProps) => {
    const sampleRandomButton = _.sample(buttonData);
    //console.log(`sim sampleRandomButton`, sampleRandomButton);
    //console.log(`sim this.props`, this.props);
    this.props.updateRemoteButtonClick([sampleRandomButton]);
  };

  onChangeLightControlSimCheckbox = (evt, data) => {
    let checked = data.checked;

    this.setState({
      isLightControlSim: checked,
    });
  };

  // #DEBUG - reports lighting events
  reportDebugLightingEvents = () => {
    const {
      allNamedAreaEvents,
      namedAreaEventsByAreaId,
      namedAreaGroupButtons,
      namedAreaEventButtonGroupState,
      saveNewNamedAreaEvent,
      updateButtonGroupState,
      buttonGroupStates,
      cancelNamedAreaEvent,
      activateNamedAreaEvent,
      prevNamedAreaGroups,
      prevNamedAreaGroupButtonStates,
    } = this.props;

    //console.clear();
    console.log("report UPDATE_NAMED_AREA_GROUP --------");

    // fitler for test_area, active
    const activeAllNamedAreaEvents = namedAreaEventsByAreaId
      .filter((event) => event.active)
      .map((event) => {
        const { id, priority } = event;
        return { id, priority };
      });

    const sortActiveAllNamedAreaEvents = [...activeAllNamedAreaEvents].sort(
      (a, b) => b.priority - a.priority
    );

    console.log(
      "report UPDATE_NAMED_AREA_GROUP sortFilteredAllNamedAreaEvents",
      sortActiveAllNamedAreaEvents
    );

    console.log(
      "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtons",
      namedAreaGroupButtons
    );

    let namedAreaGroupButtonStates = [];
    namedAreaGroupButtons.forEach((button) => {
      const {
        id: buttonId,
        priority: buttonPriority,
        event: buttonEvent,
        level,
        group,
      } = button;

      const isActiveEvent = activeAllNamedAreaEvents.some(
        (event) =>
          event.id.includes(buttonId) && event.priority === buttonPriority
      );

      // namedAreaGroupButtonStates.push({
      //   id: buttonId,
      //   buttonPriority: buttonPriority,
      //   eventId: isActiveEvent?.id,
      //   eventPriority: isActiveEvent?.priority,
      // });

      namedAreaGroupButtonStates.push({
        id: buttonId,
        priority: buttonPriority,
        active: isActiveEvent,
        buttonEvent: buttonEvent,
        level,
        group,
      });
    });

    console.log(
      "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates",
      namedAreaGroupButtonStates
    );

    console.log(
      "report UPDATE_NAMED_AREA_GROUP prevNamedAreaGroupButtonStates",
      prevNamedAreaGroupButtonStates
    );

    // ------------------------------
    // restore to previous state
    // ------------------------------

    if (true) {
      //
      console.log(
        "report UPDATE_NAMED_AREA_GROUP buttonGroupState",
        buttonGroupStates
      );

      console.log(
        "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates -------------------------"
      );

      let newButtonGroupState = { ...buttonGroupStates };
      let newActiveEventList = [];

      namedAreaGroupButtonStates.forEach((button, index) => {
        console.log(
          "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates namedAreaGroupButtonState",
          button
        );

        const { id, buttonEvent, level, priority } = button;

        // search for previous events with the same id
        const prevNamedAreaGroupButtonState = prevNamedAreaGroupButtonStates?.[
          "test_area"
        ]?.filter((button) => button.id === id && button.level === level);
        // if find more than one event determine which, if any, to restore to previous state
        //

        console.log(
          "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates prevNamedAreaGroupButtonState",
          prevNamedAreaGroupButtonState
        );

        // initially lets deal with first/top .............
        //
        // #NOTE - since the events are stepped through from highest priority first,
        // the next highest priority event will be set active.
        //
        // #REVIEW
        // - set the lowest active?
        // - only set if in same group?
        // - only set if on same level?
        //
        const restoreToButton = prevNamedAreaGroupButtonState?.[0];

        // if a previous event was found and event was active
        if (restoreToButton && restoreToButton?.active) {
          // and if the event has not already been set here (only one event per polygon)
          if (!newActiveEventList.includes(id)) {
            console.log(
              "report UPDATE_NAMED_AREA_GROUP namedAreaGroupButtonStates restoreToButton ####### ---> ",
              restoreToButton,
              "buttonEvent ",
              buttonEvent
            );

            newButtonGroupState[id] = { 0: priority };
            updateButtonGroupState(newButtonGroupState);
            activateNamedAreaEvent(buttonEvent);
            newActiveEventList.push(id);
          }
        }
      });

      console.log(
        "report UPDATE_NAMED_AREA_GROUP prevNamedAreaGroups",
        prevNamedAreaGroups
      );
    }

    // ------------------------------

    if (false) {
      //
      console.log(
        "report UPDATE_NAMED_AREA_GROUP buttonGroupState",
        buttonGroupStates
      );

      let newButtonGroupState = { ...buttonGroupStates };

      namedAreaGroupButtonStates.forEach((button, index) => {
        // #TEST - only one time through

        if (false) {
          const { id, active, buttonEvent, priority } = button;

          switch (active) {
            case true:
              console.log(
                "UPDATE_NAMED_AREA_GROUP cancelNamedAreaEvent",
                buttonEvent
              );
              newButtonGroupState[id] = { 0: -1 };
              updateButtonGroupState(newButtonGroupState);
              cancelNamedAreaEvent(buttonEvent);

              break;
            case false:
              console.log(
                "UPDATE_NAMED_AREA_GROUP activateNamedAreaEvent",
                buttonEvent
              );
              newButtonGroupState[id] = { 0: priority };
              updateButtonGroupState(newButtonGroupState);
              activateNamedAreaEvent(buttonEvent);
              break;

            default:
              break;
          }
        }
      });

      console.log(
        "report UPDATE_NAMED_AREA_GROUP prevNamedAreaGroups",
        prevNamedAreaGroups
      );
    }

    // ------------------------------
    const activeNamedAreaGroupButtonStates = namedAreaGroupButtonStates.filter(
      (button) => button.active
    );

    // #TESTING -
    // ^ ... retained the system state above, so now test by 1st clearing all messages.
    // wait for 2 seconds, then resend the same setup again.

    //this.sendEventClearMsg();
    //sleep(1000); // wait for the clear event to go through
    //console.log("report UPDATE_NAMED_AREA_GROUP finished sleep!");

    // #NOTE #WIP #REVIEW - SIMILAR CODE TO src/components/WebWorker/reducer.js
    //
    let promiseArray = [];
    if (false) {
      activeNamedAreaGroupButtonStates.reverse().forEach((namedArea, index) => {
        // prepare message token
        const userSessionIp = getUserSessionIp();
        const token = messageToken(userSessionIp);
        const timestamp = microTime();

        const { id, button } = namedArea;
        const {
          priority,
          active,
          active_color,
          active_state,
          on_time,
          off_time,
          train,
          relay_active,
        } = button;

        const buttonGroupState = namedAreaEventButtonGroupState[id];

        let eventMsg = {};
        eventMsg = {
          id: `${id}:${timestamp}`,
          priority: priority,
          active: !active,
          active_color: toFireflyColor(active_color),
          active_state: active_state,
          on_time: on_time,
          off_time: off_time,
          train: train,
          button_groups: JSON.stringify(buttonGroupState),
          timestamp: timestamp,
          precanned: 0, // not used ATM
          relay_event_active: relay_active || 0,
          token: token,
        };

        console.log("report UPDATE_NAMED_AREA_GROUP eventMsg", eventMsg);

        if (false) {
          const eventPayload = {
            topic: `named_area/${eventMsg.id}/event`,
            qos: 0,
            message: eventMsg,
            retained: true,
          };
          promiseArray.push(
            new Promise((resolve, reject) => {
              saveNewNamedAreaEvent({
                values: eventPayload,
                resolve,
                reject,
              });
            })
          );
        }
      });
    }
    console.log(
      "report UPDATE_NAMED_AREA_GROUP sending promiseArray",
      promiseArray
    );

    // send out active events .....
    if (promiseArray.length > 0) {
      Promise.all(promiseArray).then((results) => {
        console.log("SUCCESS UPDATE_NAMED_AREA_GROUP - results ", results);
      });
    }

    console.log("report UPDATE_NAMED_AREA_GROUP --------");
  };

  render() {
    const {
      serverInfo,
      pollRates,
      isDisable,
      fetchedMqttBroker,
      userSessionIp,
      mapImages,
    } = this.props;

    const { worker } = pollRates;
    const { isLightControlSim, lightControlSimRate } = this.state;

    const settingsWebworker = {
      start: worker / 1000,
      min: 0.5,
      max: 16,
      step: 0.5,
      onChange: (value) => {
        this.props.SetPokeTheWorkerPollRate(value * 1000); // in milliseconds
        //
        this.setState({
          workerPollRate: value * 1000,
        });
      },
    };

    const settingsLightControlSim = {
      start: 5000 / 1000,
      min: 0.5,
      max: 16,
      step: 0.5,
      onChange: (value) => {
        this.setState({
          lightControlSimRate: value * 1000,
        });
      },
    };

    const imageContent = mapImages.map((image) => (
      <Grid.Column>
        <Segment>
          <img style={{ width: "100px" }} alt="" src="" id={image.id} />
        </Segment>
      </Grid.Column>
    ));

    // e.g.  const date = DateTime.fromISO("2010-10-22T21:38:00");
    const humanReadable = DateTime.now().toLocaleString(DateTime.DATETIME_MED);

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">System Tools</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment>
            <Message icon color="red">
              <Icon name="warning" />
              <Message.Content>
                <Message.Header>Administrator Use Only</Message.Header>
                The following tools can cause significant changes to your system
                state. Please be familiar with the operation before using any of
                these tools.
              </Message.Content>
            </Message>

            <Grid columns={2}>
              <Grid.Column>
                <Grid.Row style={{ paddingBottom: "5px" }}>
                  <Segment>
                    <Header as="h3">Server Operations</Header>
                    <List>
                      <List.Item key={`top`}></List.Item>

                      <List.Item
                        key={`event-clear`}
                        style={{
                          // display: "inline-block",
                          // verticalAlign: "middle",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Popup
                          content={
                            "Sends clear message to server to set events active:false in db table"
                          }
                          trigger={
                            <Button
                              floated={"left"}
                              icon
                              onClick={this.sendEventClearMsg}
                              size={"mini"}
                              color={"red"}
                            >
                              <Icon name={"repeat"} size={"large"} />
                            </Button>
                          }
                        />
                        <Header as="h4" style={{ verticalAlign: "middle" }}>
                          Clear all lighting events
                        </Header>
                      </List.Item>
                      <List.Item></List.Item>

                      <List.Item
                        key={`fault-clear`}
                        style={{
                          // display: "inline-block",
                          // verticalAlign: "middle",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Popup
                          content={
                            "Sends a system/fault/clear message to the server to clear the faults table. Also clears the local state. This will clear db records."
                          }
                          trigger={
                            <Button
                              floated={"left"}
                              icon
                              onClick={this.sendFaultClearMsg}
                              size={"mini"}
                              color={"orange"}
                            >
                              <Icon name={"repeat"} size={"large"} />
                            </Button>
                          }
                        />
                        <Header as="h4" style={{ verticalAlign: "middle" }}>
                          Clear all device fault database
                        </Header>
                      </List.Item>
                      <List.Item
                        style={{
                          // display: "inline-block",
                          // verticalAlign: "middle",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Popup
                          content={
                            "Sends a fault/id/device {active=false delete=true}, and a null message to the broker for every fault list entry. Also clears the local state. This will clear broker records."
                          }
                          trigger={
                            <Button
                              floated={"left"}
                              icon
                              onClick={this.sendFaultDeleteMsg}
                              size={"mini"}
                              color={"orange"}
                            >
                              <Icon name={"repeat"} size={"large"} />
                            </Button>
                          }
                        />
                        <Header as="h4" style={{ verticalAlign: "middle" }}>
                          Clear all device fault messages
                        </Header>
                      </List.Item>
                      {true && (
                        <List.Item
                          style={{
                            // display: "inline-block",
                            // verticalAlign: "middle",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Popup
                            content={
                              "Clears the iot_server database 'Log' table of all records."
                            }
                            trigger={
                              <Button
                                floated={"left"}
                                icon
                                onClick={this.handleDeleteEventLog}
                                size={"mini"}
                                color={"red"}
                              >
                                <Icon name={"repeat"} size={"large"} />
                              </Button>
                            }
                          />
                          <Header as="h4" style={{ verticalAlign: "middle" }}>
                            Clear all Event Log messages
                          </Header>
                        </List.Item>
                      )}
                      <List.Item
                        style={{
                          // display: "inline-block",
                          // verticalAlign: "middle",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Popup
                          content={
                            "Clears the iot_server database 'Event_Job' table of all records."
                          }
                          trigger={
                            <Button
                              floated={"left"}
                              icon
                              onClick={this.handleDeleteEventJob}
                              size={"mini"}
                              color={"red"}
                            >
                              <Icon name={"repeat"} size={"large"} />
                            </Button>
                          }
                        />
                        <Header as="h4" style={{ verticalAlign: "middle" }}>
                          Clear all Event Jobs
                        </Header>
                      </List.Item>
                      {/* #DEBUG #TESTING - "Report lighting events info" was setup for testing event clear and propogation. 
                      This is left in place for future use. */}
                      {false && (
                        <List.Item
                          style={{
                            // display: "inline-block",
                            // verticalAlign: "middle",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Popup
                            content={
                              "Report debug information to the console listing lighting events"
                            }
                            trigger={
                              <Button
                                floated={"left"}
                                icon
                                onClick={this.reportDebugLightingEvents}
                                size={"mini"}
                                color={"purple"}
                              >
                                <Icon name={"repeat"} size={"large"} />
                              </Button>
                            }
                          />
                          <Header as="h4" style={{ verticalAlign: "middle" }}>
                            Report lighting events info
                          </Header>
                        </List.Item>
                      )}
                    </List>
                  </Segment>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: "5px" }}>
                  <Segment>
                    <Header as="h3">MQTT/Webworker Message Collection</Header>
                    <Popup
                      content={"Enable data collection via web worker"}
                      trigger={
                        <Checkbox
                          label={"Enable data collection via web worker"}
                          toggle
                          //defaultChecked
                          checked={!isDisable}
                          onClick={(evt, data) =>
                            this.onChangeCheckbox(evt, data)
                          }
                        />
                      }
                    />
                    <Header as="h4" style={{ paddingLeft: "5px" }}>
                      Poll rate {this.state.workerPollRate / 1000} seconds
                    </Header>
                    <Slider
                      discrete
                      color="blue"
                      inverted={false}
                      settings={settingsWebworker}
                    />
                  </Segment>
                </Grid.Row>
                {false && (
                  <Grid.Row style={{ paddingBottom: "5px" }}>
                    <Grid.Column>
                      <Segment>
                        <Header as="h3">Area Map Images</Header>
                        <Grid>
                          <Grid.Row columns={"equal"}>{imageContent}</Grid.Row>
                        </Grid>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {/* <Grid.Row style={{ paddingBottom: "5px" }}>
                  <Segment>
                    <Header as="h3">Light Control Simulator</Header>
                    <Popup
                      content={
                        "Lighting Control button click simulator cycles through each of the Lighting Control buttons clicking the next button based on the cycle rate set below."
                      }
                      trigger={
                        <Checkbox
                          label={
                            "Enable Lighting Control button click simulator"
                          }
                          toggle
                          //defaultChecked
                          checked={isLightControlSim}
                          onClick={(evt, data) =>
                            this.onChangeLightControlSimCheckbox(evt, data)
                          }
                        />
                      }
                    />
                    <Header as="h4" style={{ paddingLeft: "5px" }}>
                      {`Button cycle rate ${
                        this.state.lightControlSimRate / 1000
                      } seconds`}
                    </Header>
                    <Slider
                      discrete
                      color="blue"
                      inverted={false}
                      settings={settingsLightControlSim}
                    />
                  </Segment>
                </Grid.Row> */}
              </Grid.Column>
              <Grid.Column>
                <Grid.Row style={{ paddingBottom: "5px" }}>
                  <Segment>
                    <Header as="h3">MQTT Information</Header>
                    <List>
                      <List.Item>
                        <strong>{"broker"}</strong>
                        <br />
                        {JSON.stringify(fetchedMqttBroker)}
                      </List.Item>
                    </List>
                  </Segment>
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: "5px" }}>
                  <Segment>
                    <Header as="h3">Session Information</Header>
                    <List>
                      <List.Item>
                        <strong>{"current local time"}</strong>
                        <br />
                        {humanReadable}
                      </List.Item>
                      <List.Item>
                        <strong>{"username and client IP"}</strong>
                        <br />
                        {JSON.stringify(userSessionIp)}
                      </List.Item>
                    </List>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment>
                    <Header as="h3">Server Information</Header>
                    {Object.keys(serverInfo).map((key) => {
                      return (
                        <div>
                          <strong>{key}</strong>
                          <p>{JSON.stringify(serverInfo[key])}</p>
                        </div>
                      );
                    })}
                  </Segment>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
          {/* {isLightControlSim && (
            <Heartbeat
              heartbeatFunction={this.handleTimeout}
              heartbeatInterval={lightControlSimRate}
            />
          )} */}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //

  return {
    faults: getFaults(state),
    serverInfo: getServerInfo(state),
    isPokeTheWorker: isOnOffPokeTheWorker(state),
    pollRates: getPollRates(state),
    isDisable: isDisabledPokeTheWorker(state),
    fetchedMqttBroker: getMqttBroker(state),
    userSessionIp: getUserSessionIp(),
    mapImages: getMapImages(state),
    //
    allNamedAreaEvents: getAllNamedAreaEvents(state),
    namedAreaEventsByAreaId: getNamedAreaEventsByAreaId(state, "test_area"),
    namedAreaGroupButtons: getButtonPrioritysInNamedAreaGroupByAreaId(
      state,
      "test_area"
    ),
    namedAreaEventButtonGroupState: getNamedAreaEventButtonGroupState(state),
    buttonGroupStates: getNamedAreaEventButtonGroupState(state),
    prevNamedAreaGroups: getPrevNamedAreaGroups(state),
    prevNamedAreaGroupButtonStates: getPrevNamedAreaGroupButtonStates(state),
  };
};

export default connect(mapStateToProps, {
  mqttPublish,
  ClearFaults,
  TurnOnOffPokeTheWorker,
  DisablePokeTheWorker,
  SetPokeTheWorkerPollRate,
  fetchServerInfo,
  updateRemoteButtonClick,
  //
  saveNewNamedAreaEvent,
  //
  activateNamedAreaEvent,
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
  updateButtonGroupState,
  deleteEventLog,
  deleteScheduledEventJobs,
})(ToolsSystem);
