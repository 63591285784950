import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Container } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import { saveNewNamedArea, updateNamedArea } from "NamedAreas/actions";

import {
  getNamedAreaStatusesById,
  getAllNamedAreaStatuses,
  getNamedAreaStatusesByAreaId,
  getNamedAreaInfosByAreaId,
  getAreaStatusesById,
  getAllAreaStatuses,
  getNamedAreaEventsByParentId,
} from "components/WebWorker/reducer";

import {
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
} from "OperationalChanges/actions";

import NamedAreaSimpleForm from "admin/named-area-simple/NamedAreaSimpleForm";
import { deleteNamedArea } from "NamedAreas/actions";
import { updateNamedAreaGroup } from "NamedAreaGroups/actions";

import { updateTagZone } from "components/Tags/actions";

import SubmissionModal from "admin/SubmissionModal";

import { SubmissionError, startSubmit } from "redux-form";

import { Dimmer, Loader } from "semantic-ui-react";
import cuid from "cuid";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import {
  fetchNamedAreas,
  fetchNamedAreaEvents,
  fetchNamedAreaTriggers,
} from "NamedAreas/actions";

import { confirmTokenRxWithRetryAndTimeout } from "utils/confirmTokenRxWithRetryAndTimeout";
import * as Api from "api/apiAck";

import { isMqttNotApiPost, isTagTracking } from "components/ConfigJs";
import { object } from "testdouble";

// #WIP - make common object merge function
//
// see also `mergeSettings` which is the same
const MergeSettings = (obj1, obj2) => {
  if (obj2) {
    var obj3 = {};
    for (let attrname in obj1) {
      obj3[attrname] = obj1[attrname];
    }
    for (let attrname in obj2) {
      obj3[attrname] = obj2[attrname];
    }
    return obj3;
  } else {
    return obj1;
  }
};

class EditNamedAreaSimple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
      isMqttNotApiPost: true,
      isTagTracking: false,
    };
  }

  componentDidMount() {
    const { allAreas, goto, namedAreaSubType, TurnOnOffPokeTheWorker } =
      this.props;

    // If no areas data is loaded redirect to area list
    // this is typically when the the page is F5 reloaded.
    // Can't operate without area data.
    //
    const checkDataOk = _isEmpty(allAreas);
    if (checkDataOk) {
      goto(`/admin/named-area/${namedAreaSubType}`);
    }

    // turn off data collection
    TurnOnOffPokeTheWorker(false);

    if (isMqttNotApiPost()) this.setState({ isMqttNotApiPost: true });
    if (isTagTracking()) this.setState({ isTagTracking: true });
  }

  componentWillUnmount() {
    const { TurnOnOffPokeTheWorker } = this.props;
    // turn on data collection
    TurnOnOffPokeTheWorker(true);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_isEqual(JSON.stringify(this.props), JSON.stringify(nextProps)) ||
      !_isEqual(this.state, nextState)
    );
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  // #NOTE - create a function
  // src/admin/named-area-group/NamedAreaGroupTableItems.js
  deleteItemByNamedAreaParentId = (items, id) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and return !== id

    //console.log(`items`, items);
    //console.log(`items id`, id);

    let newItems = [];

    let i = 0;
    // groups
    items.forEach((item) => {
      // levels - subItems
      let newSubItem = [];
      let j = 0;
      item.subItems.forEach((item) => {
        // named areas - subSubItems
        let newSubSubItem = [];
        let k = 0;
        item.subItems.forEach((item) => {
          if (item.namedArea.id !== id) {
            newSubSubItem[k] = item;
            k = k + 1;
          }
        });
        newSubItem[j] = item;
        newSubItem[j].subItems = newSubSubItem;
        j = j + 1;
      });
      newItems[i] = item;
      newItems[i].subItems = newSubItem;
      i = i + 1;
    });

    return newItems;
  };

  updateItemByNamedAreaParentId = (items, id, label) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and replace object by id

    //console.log(`items`, items);
    //console.log(`items id`, id);

    let newItems = [];

    let i = 0;
    // groups
    items.forEach((item) => {
      // levels - subItems
      let newSubItem = [];
      let j = 0;
      item.subItems.forEach((item) => {
        // named areas - subSubItems
        let newSubSubItem = [];
        let k = 0;
        item.subItems.forEach((item) => {
          if (item.namedArea.id !== id) {
            newSubSubItem[k] = item;
          } else {
            let tempItem = JSON.parse(JSON.stringify(item));
            tempItem.label = label;
            tempItem.namedArea.label = label;
            newSubSubItem[k] = tempItem;
            // console.log(
            //   `updateItemByNamedAreaParentId item newSubSubItem[k]`,
            //   newSubSubItem[k]
            // );
          }
          k = k + 1;
        });
        newSubItem[j] = item;
        newSubItem[j].subItems = newSubSubItem;
        j = j + 1;
      });
      newItems[i] = item;
      newItems[i].subItems = newSubItem;
      i = i + 1;
    });

    return newItems;
  };

  makeNewNamedAreaGroup = (area, parentId, action, data) => {
    // get current area info
    const {
      id,
      image_filename,
      image_info,
      slug,
      coordinatesUtm,
      ref_coord,
      floor,
      ceiling,
      default_color,
      default_state,
      namedAreaGroup,
    } = area;

    const cloneItems = !_isEmpty(namedAreaGroup)
      ? JSON.parse(JSON.stringify(namedAreaGroup))
      : [];

    let newItems = cloneItems;
    //console.log(`cloneItems`, cloneItems);

    switch (action) {
      case "delete":
        newItems = this.deleteItemByNamedAreaParentId(cloneItems, parentId);
        break;
      case "update":
        newItems = this.updateItemByNamedAreaParentId(
          cloneItems,
          parentId,
          data // for `update` data is the label string
        );
        break;
      default:
        break;
    }

    let bounds = JSON.parse(JSON.stringify(coordinatesUtm[0])); // #NOTE - area is a single polygon (not multi)
    const firstPoint = bounds[0];
    bounds.push(firstPoint);

    // create new Area with updated namedAreaGroup
    const newArea = {
      id,
      image_filename,
      image_info: JSON.stringify(image_info),
      slug,
      bounds: bounds,
      ref_coord,
      floor,
      ceiling,
      default_color: default_color.toUpperCase(),
      default_state: default_state.toUpperCase(),
      named_area_group: JSON.stringify(newItems),
    };

    const newValues = { id: id, data: newArea };

    return newValues;
  };

  // #NOTE - create a function
  // see - src/admin/named-area-group/NamedAreaGroupEditPage.js

  old_newNamedAreaGroup = (
    allAreas,
    allNamedAreas,
    namedAreaId,
    action,
    data
  ) => {
    const namedArea = allNamedAreas.find(
      (namedArea) => namedArea.id === namedAreaId
    );

    const { area: areaId, parent: parentId } = namedArea;

    if (_isEmpty(areaId)) {
      return null;
    }

    const area = allAreas.find((area) => area.id === areaId);

    if (_isEmpty(area)) {
      return null;
    }

    // get current area info
    const {
      id,
      image_filename,
      image_info,
      slug,
      coordinatesUtm,
      ref_coord,
      floor,
      ceiling,
      default_color,
      default_state,
      namedAreaGroup,
    } = area;

    // update namedAreaGroup - delete current namedArea if exists
    if (_isEmpty(namedAreaGroup)) {
      return null;
    }

    const cloneItems = !_isEmpty(namedAreaGroup)
      ? JSON.parse(JSON.stringify(namedAreaGroup))
      : [];

    let newItems = cloneItems;

    //console.log(`cloneItems`, cloneItems);

    switch (action) {
      case "delete":
        newItems = this.deleteItemByNamedAreaParentId(cloneItems, parentId);
        break;
      case "update":
        newItems = this.updateItemByNamedAreaParentId(
          cloneItems,
          parentId,
          data // for `update` data is the label string
        );
        break;
      default:
        break;
    }

    let bounds = JSON.parse(JSON.stringify(coordinatesUtm[0])); // #NOTE - area is a single polygon (not multi)
    const firstPoint = bounds[0];
    bounds.push(firstPoint);

    // create new Area with updated namedAreaGroup
    const newArea = {
      id,
      image_filename,
      image_info: JSON.stringify(image_info),
      slug,
      bounds: bounds,
      ref_coord,
      floor,
      ceiling,
      default_color: default_color.toUpperCase(),
      default_state: default_state.toUpperCase(),
      named_area_group: JSON.stringify(newItems),
    };

    const newValues = { id: id, data: newArea };

    return newValues;
  };

  _preProcessValues = (values) => {
    const {
      mineLevelId,
      areaName,
      regions,
      namedAreaParentName,
      backgroundColor,
    } = values;

    const { allAreas } = this.props;
    const thisArea = allAreas.find((area) => area.id === mineLevelId);
    const transformPixelsToUtm = thisArea?.transformPixelsToUtm;

    let newNamedAreas = [];

    regions.forEach((region) => {
      const { id, points } = region;

      let shape = [];

      points.forEach((point) => {
        const objLatLng = transformPixelsToUtm.transform({
          lat: point.lat,
          lng: point.lng,
        });

        shape.push(Object.values(objLatLng).reverse()); // reverse lat / lng coords
      });

      shape.push(shape[0]); // append 1st point to end to close the shape

      // polygon styling
      const color = `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`;
      const fillColor = color;
      const opacity = `${backgroundColor.a}`;

      newNamedAreas.push({
        id: `${mineLevelId}:${areaName}:${id}`,
        name: `${id}`,
        parent: `${mineLevelId}:${areaName}`,
        parentName: namedAreaParentName,
        area: mineLevelId,
        shape: shape,
        style: `{"stroke":true,"color":"${color}","weight":1,"opacity":1,"fill":true,"fillColor":"${fillColor}","fillOpacity":${opacity},"clickable":true}`,
      });
    });

    console.log(`_preProcessValues newNamedAreas`, newNamedAreas);

    return newNamedAreas;
  };

  submitMyForm = (values) => {
    console.log("updateNamedArea values", values);

    const parentIds = values.map((value) => value.parent);
    let parentId = "";
    // check all the same parentIds
    if (parentIds.every((val, i, arr) => val === arr[0])) {
      if (parentIds.length) {
        parentId = parentIds[0];
      }
    }

    const {
      id,
      saveNewNamedArea,
      deleteNamedArea,
      updateNamedAreaGroup,
      namedAreasByParent,
      area,
      // to send new events
      activateEvent,
      activeNamedAreaEventButton,
      //
      fetchNamedAreas,
      fetchNamedAreaEvents,
      //
      namedAreaSubType,
      updateTagZone,
    } = this.props;

    // #NOTE -
    // The edit process for `NamedAreaSimpleForm` deletes the original shape
    // and creates a new shape.
    // Consequently need to :
    // 1 - find named areas (shapes/regions) for deletion
    // 2 - find named areas (shapes/regions) for addition
    // 3 - there is another case where attributes have changed requiring resending
    // the same data.
    // i.e. parentName has changed.
    //

    // find the original Ids
    const originalNamedAreaIds = namedAreasByParent.map((na) => na.id);

    // find the Ids of the updated data
    const updatedNamedAreaIds = values.map((na) => na.id);

    // create an array of deleted Ids
    const deletedNamedAreaIds = originalNamedAreaIds.filter(
      (e) => !updatedNamedAreaIds.includes(e)
    );

    console.log("SAVE deletedNamedAreaIds", deletedNamedAreaIds);
    console.log("SAVE updatedNamedAreaIds", updatedNamedAreaIds);

    // get data for the new namedAreas
    // * button array
    // * active_color
    // * active_state
    //
    // These are common to all areas for the same parent so
    // copy values from any i.e. the first
    //              console.log("namedAreasByParent", namedAreasByParent);
    const templateObj = namedAreasByParent[0];
    //              console.log("templateObj", templateObj);
    let buttons = [];
    let active_color = "GREEN";
    let active_state = "ON";
    let priority = 1;
    if (templateObj !== undefined) {
      buttons = templateObj.button;
      active_color = templateObj.active_color;
      active_state = templateObj.active_state;
      priority = templateObj.priority;
    }

    let promiseArray = [];
    let newParentName;

    updatedNamedAreaIds.forEach((namedAreaId) => {
      const value = values.find((value) => value.id === namedAreaId);
      const { id, parent, parentName } = value;

      let newValue = JSON.parse(JSON.stringify(value));

      // update properties
      newValue.parent_name = parentName;
      newValue.default_state = active_state;
      newValue.active_color = active_color;
      // newValue.style =
      //   '{"stroke":true,"color":"hsl(137.508,50%,75%)","weight":1,"opacity":1,"fill":true,"fillColor":"hsl(137.508,50%,75%)","fillOpacity":0.5,"clickable":true}';
      newValue.type = "Polygon";
      newValue.sub_type = namedAreaSubType;
      newValue.priority = priority;

      // update button info
      let updateButtons = [];
      buttons.forEach((button, idx) => {
        // deep clone a copy of the button object before reassigning properties
        updateButtons[idx] = JSON.parse(JSON.stringify(button));
        // correct the id in the button object when updating
        updateButtons[idx].id = id;
        // correct the named_area
        updateButtons[idx].named_area = parent;
      });
      newValue.button = updateButtons;

      console.log("SAVING THIS NAMED AREA ID:", namedAreaId);
      console.log("SAVING THIS NAMED AREA VALUES:", newValue);

      promiseArray.push(
        new Promise((resolve, reject) =>
          saveNewNamedArea({ values: newValue, resolve, reject })
        )
      );

      if (this.state.isTagTracking) {
        // tagTracking ...
        /// this sends an update message to tagTracking api
        promiseArray.push(
          new Promise((resolve, reject) =>
            updateTagZone({ values: { id: id }, resolve, reject })
          )
        );
      }

      // #NOTE - this will get overwritten with the same information
      // for all newNamedAreaIds. i.e. parentName is the same for all newNamedAreaIds
      newParentName = parentName;
    });

    // delete named areas as necessary
    deletedNamedAreaIds.forEach((id) => {
      console.log("DELETING THIS NAMED AREA ID:", id);
      promiseArray.push(
        new Promise((resolve, reject) =>
          deleteNamedArea({
            values: { id: id },
            resolve,
            reject,
          })
        )
      );
    });

    // update namedAreaGroup with changes
    const newNamedAreaGroup = this.makeNewNamedAreaGroup(
      area,
      id,
      "update",
      newParentName
    );

    promiseArray.push(
      new Promise((resolve, reject) =>
        updateNamedAreaGroup({
          values: newNamedAreaGroup,
          resolve,
          reject,
        })
      )
    );

    // #TODO - change .all to .allSettled
    // return Promise.all(promiseArray) //
    //     .then((results) => {
    //     console.log("saveNewNamedArea promise results ", results);
    //   })
    return (
      Promise.allSettled(promiseArray)
        .then((results) => {
          // #TODO - check each promise to see if status/reason is OK and the action passed
          //
          //console.log("saveNewNamedArea promise allSettled results", results);
          results.forEach((result, idx) => {
            // console.log(
            //   "saveNewNamedArea promise allSettled result",
            //   idx,
            //   " - ",
            //   result
            // );
            const { status } = result;
            switch (status) {
              case "fulfilled":
                const {
                  value: { topic, token, status, message },
                } = result;

                // tagTracking - updateTagZone will respond with
                // e.g.
                // {
                //   status: "fulfilled",
                //   value: {
                //     data: {
                //       id: "Area_Main:717d59d5-604f-4924-bb16-d69d9c48ba06:1639017750907",
                //     },
                //     message: "ok",
                //     status: "success",
                //   },
                // };

                if (this.state.isTagTracking) {
                  if (status && message) {
                    if (message === "success") {
                      // everything is OK .... ATM not check if things fail...
                    }
                  }
                }

                // else ... saveNamedArea

                const topicArray = topic?.split("/");
                const topicType = topicArray?.[0];
                const topicId = topicArray?.[1];
                const topicAction = topicArray?.[2];

                // e.g.
                // named_area/DMLZ_Extraction:4c891f20-de82-45f4-b7ef-b3bb2ed1c5f9:1626218801483/change
                if (topicType === "named_area" && topicAction === "change") {
                  if (token !== undefined) {
                    if (this.state.isMqttNotApiPost) {
                      // wait for ack message with promise message timer
                      // waits time is 1000 mS between retries

                      // #TODO - put retries into config.js
                      //

                      const retries = 2;
                      const checkToken = async (token) => {
                        return await confirmTokenRxWithRetryAndTimeout(
                          token,
                          retries
                        );
                      };

                      checkToken(token)
                        .then((result) =>
                          console.log(
                            "saveNewNamedArea checkToken found the Ack at index: ",
                            result
                          )
                        ) // found matching ack
                        .then(() => Api.removeAckToken(token)) // delete it
                        //.then(() => Promise.resolve("ACK OK"))
                        .then(() => {
                          if (updatedNamedAreaIds.length) {
                            // console.log(
                            //   `xxxxxxxx checkToken activeNamedAreaEventButton`,
                            //   activeNamedAreaEventButton
                            // );

                            const operation = {
                              ...activeNamedAreaEventButton,
                              // overwrite these props ...
                              id: topicId,
                              named_area: parentId,
                            };

                            console.log(
                              `NAMED AREA CHANGE ACK FOUND - SENDING EVENTS  --> `,
                              operation
                            );

                            activateEvent(operation);

                            // setTimeout(() => {
                            //   // try fetch
                            //   // wait for 1st new named areas to propogate
                            //   //
                            //   // catch the ack
                            //   //
                            //   //fetchNamedAreas(); //#FETCHS
                            //   //fetchNamedAreaEvents(); // #FETCHS
                            //   // now wait for the fetch to resolve
                            //   // still wait 1000mS as well
                            //   setTimeout(() => {
                            //     console.log(
                            //       `SENDING send out events --> `,
                            //       operation
                            //     );
                            //     activateEvent(operation);
                            //   }, 1000);
                            // }, 1000);

                            // #BUG>!> BUTTON GROUP STATE IS NOT SET APPROPRIATELY
                            //
                            // ACK CHECKS?????
                            // IMPLEMENT ACK CHECKING MORE WIDELY
                            //

                            // #NOTE - works OK under local testing.
                            // Need to try production installation to see if this is a problem.
                          }
                        })
                        .catch((error) =>
                          console.log(
                            "saveNewNamedArea checkToken error",
                            error
                          )
                        );
                    }
                    // api POST - fulfilled = success
                    else {
                      if (updatedNamedAreaIds.length) {
                        const operation = {
                          ...activeNamedAreaEventButton,
                          // overwrite these props ...
                          id: topicId,
                          named_area: parentId,
                        };

                        console.log(
                          `NAMED AREA CHANGE ACK FOUND - SENDING EVENTS  --> `,
                          operation
                        );

                        activateEvent(operation);
                      }
                    }
                  } else {
                    console.log(
                      `ERROR: saveNewNamedArea - token undefined`,
                      result
                    );
                  }
                }
                break;
              case " rejected":
                const { reason } = result;
                console.log(
                  "saveNewNamedArea promise allSettled result rejected",
                  reason
                );
                break;

              default:
                break;
            }
          });
        })
        // .then(() => {
        //   if (updatedNamedAreaIds.length) {
        //     //
        //     //
        //     // #PROBLEM - I NEED TO GET WAIT FOR THE UPDATED POLYGONS TO COME BACK FROM THE SERVER.
        //     // GIVEN POLL RATE COULD BE SECONDS, THIS IS A PROBLEM!
        //     // FETCH?
        //     // WAIT FOR ACK?

        //     // #NOTE - only really need the named_area and priority properties anyway!!!!
        //     // ....after all that trouble getting the button stuff together!
        //     const operation = {
        //       ...activeNamedAreaEventButton,
        //       named_area: parentId,
        //     };

        //     setTimeout(() => {
        //       // try fetch
        //       // wait for 1st new named areas to propogate
        //       //
        //       // catch the ack
        //       //
        //       //fetchNamedAreas(); //#FETCHS
        //       //fetchNamedAreaEvents(); // #FETCHS
        //       // now wait for the fetch to resolve
        //       // still wait 1000mS as well
        //       setTimeout(() => {
        //         console.log(`SENDING send out events --> `, operation);
        //         //activateEvent(operation);
        //       }, 1000);
        //     }, 1000);

        //     // #BUG>!> BUTTON GROUP STATE IS NOT SET APPROPRIATELY
        //     //
        //     // ACK CHECKS?????
        //     // IMPLEMENT ACK CHECKING MORE WIDELY
        //     //

        //     // #NOTE - works OK under local testing.
        //     // Need to try production installation to see if this is a problem.
        //   }
        // })
        .then(() => {
          this.onResetError();
          this.props.goto(`/admin/named-area/${namedAreaSubType}`);
        })
        .catch((error) => {
          console.log("onSubmit error", error);
          this.onSetError("failed");
          // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
          throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
        })
    );
  };

  render() {
    const {
      // id,
      // mineLevelId,
      //updateNamedArea,
      // saveNewNamedArea,
      deleteNamedArea,
      updateNamedAreaGroup,
      error,
      initialValues,
      isNamedAreaActive,
      namedAreasByParent,
      // namedAreaEvents,
      area,
      namedAreaSubType,
      strings,
    } = this.props;

    // APP_TERMINOLOGY
    let strNamedArea = strings?.["Polygon"];
    let strNamedAreas = strings?.["Polygons"];

    //console.log(`namedAreaSubType edit`, namedAreaSubType);

    switch (namedAreaSubType) {
      case "tagzone":
        strNamedArea = strings?.["Tag Zone"];
        strNamedAreas = strings?.["Tag Zones"];
        break;
      case "forced":
        strNamedArea = strings?.["Forced FireFly"];
        strNamedAreas = strings?.["Forced FireFlys"];
        break;
      case null:
      default:
        break;
    }

    const { errorMessage } = this.state;
    const openModal = errorMessage !== "";

    // #WIP - ATM don't allow reload, or direct link to a named-area page.....
    // Instead redirect to the controller list page and wait for named-area to list (with next message).

    if (error) {
      this.props.goto(`/admin/named-area/${namedAreaSubType}`);
      return null;
      //   return (
      //   <>
      //     <DataLoadingMessage id={id} />
      //     <DebugPagePropsMessages that={this} />
      //   </>
      // );
    }

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"namedAreaSimpleForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) =>
            this.submitMyForm(this._preProcessValues(formValues))
          }
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{`${strings?.["Edit"]} - ${strNamedArea}`}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
          <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          <NamedAreaSimpleForm
            //id={id}
            //mineLevelId={mineLevelId}
            isNamedAreaActive={isNamedAreaActive}
            header={`${strings?.["Edit"]} - ${strNamedArea}`}
            initialValues={initialValues}
            onSubmit={(values) =>
              this.submitMyForm(this._preProcessValues(values))
            }
            // #NOTE - disabled as this is fired as soon as submit,
            // *not* after successful completion of the Promise!!
            // onSubmitSuccess={(result, dispatch) => {
            //   //console.log(`onSubmitSuccess ${result}`);
            //   // wait for state change before redirecting page
            //   this.setState(
            //     {
            //       submitted: true,
            //     },
            //     () => {
            //       this.onResetError();
            //       this.props.goto(`/admin/named-area/${namedAreaSubType}`);
            //     }
            //   );
            // }}
            onSubmitFail={(errors) => {
              console.log(`onSubmitFail ${errors}`);
            }}
            onCancel={() => {
              this.props.goto(`/admin/named-area/${namedAreaSubType}`);
            }}
            // deleting the whole named area
            onDelete={(id) => {
              //console.log("delete id", id);

              // #NOTE - this works, but instead we are using _action = "delete" in the form as this
              // can be consistently used across many other pages.
              //
              // #NOTE -
              // This is a fudge to stop the navigation prompt modal dlg.
              // Call startSubmit at the beginning of the delete process.
              // This flips the submit flag. If the form is seem to be submitting
              // The NavigationPromptModal does not display.
              //this.props.startSubmit();

              const originalNamedAreaIds = namedAreasByParent.map(
                (na) => na.id
              );

              let promiseArray = [];

              originalNamedAreaIds.forEach((id) => {
                promiseArray.push(
                  new Promise((resolve, reject) =>
                    deleteNamedArea({
                      values: { id: id },
                      resolve,
                      reject,
                    })
                  )
                );

                if (this.state.isTagTracking) {
                  // tagTracking ...
                  /// this sends an update message to tagTracking api
                  // #REVIEW- WIP . Currently does not support "DELETE"
                  // promiseArray.push(
                  //   new Promise((resolve, reject) =>
                  //     updateTagZone({ values: { id: id }, resolve, reject })
                  //   )
                  // );
                }
              });

              const { namedAreaGroup } = area;
              // update namedAreaGroup with changes
              // ...if it there is something to update

              if (!_isEmpty(namedAreaGroup)) {
                const newNamedAreaGroup = this.makeNewNamedAreaGroup(
                  area,
                  id,
                  "delete",
                  null
                );

                promiseArray.push(
                  new Promise((resolve, reject) =>
                    updateNamedAreaGroup({
                      values: newNamedAreaGroup,
                      resolve,
                      reject,
                    })
                  )
                );
              }

              return Promise.all(promiseArray)
                .then((results) => {
                  console.log("Deleting named areas", results);
                })
                .then(
                  () => {
                    this.props.fetchNamedAreas();
                    this.props.fetchNamedAreaEvents();
                    this.props.goto(`/admin/named-area/${namedAreaSubType}`);
                  },
                  (msg) => {
                    console.log("action failed", msg); // TODO probs should show this?
                  }
                );
            }}
            strings={strings}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, props) => {
  return {
    deleteNamedArea: (id) => {
      dispatch(deleteNamedArea(id));
    },
    updateNamedAreaGroup: (data) => dispatch(updateNamedAreaGroup(data)),
    goto: (path) => dispatch(push(path)),
    saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    activateEvent: (operation) =>
      dispatch(activateNamedAreaEventSingle(operation)),
    fetchNamedAreas: () => dispatch(fetchNamedAreas()),
    fetchNamedAreaEvents: () => dispatch(fetchNamedAreaEvents()),
    startSubmit: () => {
      dispatch(startSubmit("namedAreaSimpleForm"));
    },
    //
    updateTagZone: (data) => {
      dispatch(updateTagZone(data));
    },
  };
};

const mapStateToProps = (state, props) => {
  const { match, namedAreaSubType } = props;

  const id = match.params.id;
  const nameArray = id.split(":");
  const mineLevelId = nameArray[0];

  // #REVIEW - this code is used in a few places
  let name;
  if (nameArray[1] === undefined) {
    // for edit form use namedArea name (i.e. not `Level Wide (${nameArray[0]})`)
    name = nameArray[0];
  } else {
    name = nameArray[1];
  }

  // for edit form named area
  const namedAreaIdValue = name;

  const allNamedAreas = getNamedAreaStatusesByAreaId(state, mineLevelId);
  const namedAreaParent = allNamedAreas?.find((na) => na.parent === id);

  const namedAreaParentName = namedAreaParent?.parent_name;

  // polygon style
  const namedAreaParentStyle = namedAreaParent?.style;

  // polygon styling
  //
  // The styling information is passed by a string.
  // Convert the string to an object.

  // obect template
  let backgroundObjTemplate = ["r", "g", "b", "a"];
  let rgbString = namedAreaParentStyle?.fillColor || "rgb(0, 0, 0)";
  let alpha = namedAreaParentStyle?.fillOpacity || 0.5;
  let colorArr = rgbString
    .slice(rgbString.indexOf("(") + 1, rgbString.indexOf(")"))
    .split(", ");
  let backgroundColor = {};
  // Insert the values into obj
  colorArr.forEach((k, i) => {
    backgroundColor[backgroundObjTemplate[i]] = Number(k);
  });
  backgroundColor.a = alpha;

  const allAreas = getAllAreaStatuses(state);

  const namedAreasByParent = allNamedAreas.filter((na) => na.parent === id);
  const area = getAreaStatusesById(state, mineLevelId);

  const transformUtmToPixels = area?.transformUtmToPixels;

  // console.log("id", id);
  // console.log("name", name);
  // console.log("mineLevelId", mineLevelId);
  // console.log("allNamedAreas", allNamedAreas);
  // console.log("namedAreasByParent", namedAreasByParent);
  // console.log("name", name);

  let regions = [];

  // #WIP  #TODO #NOTE -
  // direct links to this page may not have the area loaded so
  // will not have transform and shapes will not show on initialValues
  // if undefined, need to pop error and suggest reload

  if (transformUtmToPixels !== undefined) {
    namedAreasByParent.forEach((namedArea) => {
      const { name, coordinatesUtm } = namedArea;

      // console.log(
      //   "sss intialValues regions c|| []
      let points = [];

      // #WIP  #TODO #NOTE - `0` reference as do not support multipart objects - check!?
      coordinatesUtm[0].forEach((coord) => {
        const arrayLatLng = coord.slice();
        const objLatLng = transformUtmToPixels.transform({
          lat: arrayLatLng[0], // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
          lng: arrayLatLng[1],
        });
        points.push(objLatLng);
      });
      regions.push({ id: name, points: points });
    });
  }

  const namedAreaEvent = getNamedAreaEventsByParentId(state, id) || [];
  //console.log(`xxxx namedAreaEvent`, namedAreaEvent);
  // check if there is an active event on  the namedArea
  // just get the first available as they are all the same event characteristices
  // for a given parent
  const activeEvent = namedAreaEvent.find((event) => event.active === true);
  let activeNamedAreaEventButton = {};
  if (!_isEmpty(activeEvent)) {
    const { priority } = activeEvent;
    const templateNamedAreaParent = namedAreasByParent.find(
      (na) => na.parent === id
    );
    activeNamedAreaEventButton = templateNamedAreaParent?.button?.[priority];
    //console.log(`xxxx namedAreaEvent namedAreasByParent`, namedAreasByParent);
    //console.log(`xxxx namedAreaEvent eventButton`, activeNamedAreaEventButton);
  }

  let namedArea = {
    _action: "", // used to control dlg display
    id: id,
    mineLevelId: mineLevelId,
    areaName: name,
    regions: regions,
    namedAreaParentName: namedAreaParentName || "unknown",
    activeEvent,
    namedAreaSubType: namedAreaSubType,
    backgroundColor: backgroundColor,
  };

  // #WIP #TODO - stop changes while active event on namedArea
  const isNamedAreaActive = false;

  return {
    id,
    mineLevelId,
    error: namedArea === undefined ? "Named area not found" : null,
    initialValues: namedArea,
    isNamedAreaActive,
    namedAreasByParent,
    activeNamedAreaEventButton,

    //
    allAreas,
    allNamedAreas,
    area,
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNamedAreaSimple);
