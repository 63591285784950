// middleware saveAckToken

import * as Api from "api/apiAck";
import { setUserSessionIp } from "utils/messageToken";

export const saveAckToken = (store) => (next) => (action) => {
  switch (action.type) {
    case "MQTT_ACKS_UPDATE":
      // after received ACK update, update the token in the API
      //console.log("MQTT_ACKS_UPDATE", action.payload);
      Api.setAckToken(action.payload);
      break;
    case "LOGIN_SUCCESS":
      // ip doesn't exist until user logs in, however mqtt processes startup irrespective of login,
      // although they don't actually use the ip unless a publish occurs (which has to be after login).
      // So default to 0.0.0.0 until login updates state.
      const ipPort = action.payload?.user?.data?.ip || "0.0.0.0";
      const ipArray = ipPort.split(":");
      const ip = ipArray[0];
      const user = action.payload?.user?.data?.email || "no_session_defined";

      const userSessionIp = `${user},${ip}`;
      setUserSessionIp(userSessionIp);

      // Save data to sessionStorage
      sessionStorage.setItem("userSessionIp", userSessionIp);

      //console.log("LOGIN_SUCCESS userSessionIp", userSessionIp);
      break;
    case "LOGOUT_SUCCESS":
      setUserSessionIp("0.0.0.0");
      // Remove saved data from sessionStorage
      sessionStorage.removeItem("userSessionIp");

      //console.log("LOGOUT_SUCCESS userSessionIp");

      break;

    default:
      break;
  }
  return next(action);
};
