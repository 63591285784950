import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import {
  isTagTracking,
  isTagTrackingMap as _isTagTrackingMap,
} from "components/ConfigJs";

let TagTrackingMenu = ({
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
  strings, // localisation
}) => {
  const isTagTrackingMap = _isTagTrackingMap();

  const allowGuest = isUserGuest;
  const allowOperator = isUserSuper || isUserAdmin || isUserUser;
  const allowAdmin = isUserSuper || isUserAdmin;
  const allowSuper = isUserSuper;

  return (
    <Dropdown
      item
      simple
      text={strings?.["Tag Tracking"]}
      className="link item"
    >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/status/tagzone`}>
          {strings?.["Tags In Zones"]}
        </Dropdown.Item>
        {isTagTrackingMap && (
          <Dropdown.Item as={Link} to={`/status/tag-map`}>
            {strings?.["Tag Map"]}
          </Dropdown.Item>
        )}
        <Dropdown.Item as={Link} to={`/status/tag`}>
          {strings?.["Tags"]}
        </Dropdown.Item>
        {allowAdmin && (
          <>
            <Dropdown.Item as={Link} to={`/admin/named-area/tagzone`}>
              {strings?.["Tag Zones"]}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>{strings?.["Users"]}</Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/status/taguser/personnel`}>
              {strings?.["Personnel"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/status/taguser/vehicle`}>
              {strings?.["Vehicles"]}
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

TagTrackingMenu.defaultProps = {};

TagTrackingMenu = connect((state) => ({
  isUserSuper: isUserSuper(state),
  isUserAdmin: isUserAdmin(state),
  isUserUser: isUserUser(state),
  isUserGuest: isUserGuest(state),
}))(TagTrackingMenu);

export default TagTrackingMenu;
