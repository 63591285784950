import { myApi } from "api/apiAxios";
import { userSessionIp } from "utils/messageToken";

function dummyResponse(response) {
  return new Promise(function (resolve, reject) {
    if (response) {
      resolve("worked!");
    } else {
      reject("whoops!");
    }
  });
}

// **********************************************
// Broker

export function* getMqttBroker() {
  const response = yield myApi.get("/api/get_broker");
  //console.log("user fetchMqttBroker response", response);
  return response.data;
}

// **********************************************
// System information

export function* fetchFolderFilesListByFolder(folder) {
  //console.log(`API_TEST_apiSystem fetchFolderFilesListByFolder`, folder);
  let response = yield myApi.post("/api/get_folder_files_list", {
    folder: folder,
  });
  //console.log(`API_TEST_apiSystem get_folder_files_list`, response);
  return response.data;
}

export function* deleteFolderFile(filename, folder) {
  //console.log(`API_TEST_apiSystem deleteFolderFile`, folder, filename);
  let response = yield myApi.post("/api/delete_folder_file", {
    folder: folder,
    filename: filename,
  });
  response.folder = folder;
  response.filename = filename;
  //console.log(`API_TEST_apiSystem delete_folder_file`, response);
  return response;
}

export function* saveFolderFile(filename, folder, file) {
  //console.log(`API_TEST_apiSystem saveFolderFile`, folder, filename, file);

  const form = new FormData();
  const types = ["application/zip"];
  const errs = [];

  // if (types.every((type) => file.type !== type)) {
  //   errs.push(`'${file.type}' is not a supported format`);
  // }

  // if (file.size > 15000000000) {
  //   errs.push(`'${file.name}' is too large, please pick a smaller file`);
  // }

  //console.log(`API_TEST_apiSystem saveFolderFile form`, form, file);

  // #REVIEW - passes '1' one map file.
  form.append(1, file);

  //console.log(`API_TEST_apiSystem saveFolderFile form`, form);
  let response = yield myApi.post(
    "/api/upload_folder_file",
    form
    // {
    //   folder: folder,
    //   filename: filename,
    //   file: file,
    // }
  );
  //response.folder = folder;
  //response.filename = filename;
  //console.log(`API_TEST_apiSystem saveFolderFile`, response);
  return response;
}

// **********************************************
// Server system

export function* getServerInfo() {
  const response = yield myApi.get("/api/get_server_info");
  //console.log(`API_TEST_apiSystem get_server_info`, response);
  return response.data;
}

export function* getServerOk() {
  const response = yield myApi.get("/api/get_server_ok");
  //console.log(`API_TEST_apiSystem get_server_ok`, response);
  return response.data;
}

export function* postCheckService({ service, check }) {
  //console.log(`API_TEST_apiSystem postCheckService`, service);
  let response = yield myApi.post("/api/check_service", {
    service,
    check, // #WIP - not implemented yet, planned to check if LOADED, ACTIVE etc. to break down if OK
  });
  //console.log(`API_TEST_apiSystem postCheckService`, response);
  return response;
}

// **********************************************
// localisation

export function* getLocalisation() {
  const response = yield myApi.get("/api/get_localisation");
  //console.log(`API_TEST_apiSystem get_localisation`, response);
  return response.data;
}

export function* changeLocalisation({ id, localisations }) {
  console.log("API_TEST_apiSystem CHANGE_LOCALISATION api", {
    id,
    localisations,
  });

  const response = yield myApi.post(`/api/change_localisation`, {
    language: id,
    localisations,
  });
  // const response = yield dummyResponse(false);
  //console.log(`API_TEST_apiSystem change_localisation`, response);
  return response;
}

// **********************************************
// Event Log

export function* deleteEventLog({ start, end }) {
  // delete over a period.
  // 0 - default to all

  const response = yield myApi.post("/api/delete_event_logs", {
    start: Number(start),
    end: Number(start),
  });
  //console.log(`API_TEST_apiSystem delete_event_logs`, response);
  return response;
}

export function* getEventLog() {
  const response = yield myApi.get("/api/get_all_event_logs");
  //console.log(`API_TEST_apiSystem get_all_logs`, response);
  return response.data;
}

export function* getEventLogPeriod({ period }) {
  const response = yield myApi.post("/api/get_event_log_period", { period });
  console.log(`API_TEST_apiSystem get_event_logs_period`, response);
  return response.data;
}

// **********************************************
// scripts, back and restore

export function* postRunScript({ folder, action, id }) {
  //console.log(`API_TEST_apiSystem postRunScript`, folder);
  let response = yield myApi.post("/api/run_script", {
    folder: folder,
    action: action,
    id: id,
    user: userSessionIp,
  });
  response.folder = folder;
  //console.log(`API_TEST_apiSystem postRunScript`, response);
  return response;
}

//
