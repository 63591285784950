import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout, reset } from "auth/actions";
import { getEmail, getUsername } from "auth/reducer";
import { UpdateMqttReset } from "components/WebWorker/actions";
import { withComponentStateCache } from "react-component-state-cache";

import { clearCookie } from "cookies";

// #NOTE
// #WIP - same functionality as
// src/components/User/UserMenuItem.js
// >>>>COMBINE FUNCTIONS/COMPONENTS

const UserMenuLogout = ({
  logout,
  reset,
  username,
  email,
  UpdateMqttReset,
  componentstate,
}) => {
  const UserLogout = () => {
    UpdateMqttReset(true); // terminate webworker
    reset();

    // remove cache state for data fetch
    componentstate.remove("FETCH_ALL_DATA");

    logout();

    // clear authentication cookie
    clearCookie("jwt-token");
  };

  return (
    <Menu.Item onClick={UserLogout} style={{ paddingLeft: "2em" }}>
      Logout
    </Menu.Item>
  );
};

function mapStateToProps(state, props) {
  const username = getUsername(state);
  const email = getEmail(state);

  return {
    username,
    email,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    logout,
    reset,
    UpdateMqttReset,
  })(UserMenuLogout)
);
