import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiSystem";
import { SubmissionError } from "redux-form";

function makeSaveFunction(apiCall) {
  return function* (action) {
    const { values, resolve, reject } = action.payload;
    const { id } = values;

    if (!id) {
      reject(
        new SubmissionError({
          _error: "Must provide id",
        })
      );
      return;
    }
    try {
      yield apiCall(values);
      resolve();
    } catch (error) {
      const formError = new SubmissionError({ _error: error?.response?.data });
      reject(formError);
    }
  };
}

function* fetchLocalisation(action) {
  // console.log(`LOCALISATION_FETCH_REQUESTED`);
  try {
    const localisation = yield call(Api.getLocalisation);
    yield put({
      type: "LOCALISATION_FETCH_SUCCEEDED",
      payload: { localisation },
    });
  } catch (error) {
    console.log(`ERROR`, error);

    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "LOCALISATION_FETCH_FAILED",
          payload: { error: "Localisation error", extra: data },
        });
      } else {
        yield put({
          type: "LOCALISATION_FETCH_UNKNOWN",
          payload: { error: "Localisation error", extra: data },
        });
      }
    }
  }
}

function* localisationSagas() {
  yield takeEvery("LOCALISATION_FETCH_REQUESTED", fetchLocalisation);
  yield takeEvery(
    "CHANGE_LOCALISATION",
    makeSaveFunction(Api.changeLocalisation)
  );
}

export default localisationSagas;
