import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Checkbox,
  Message,
  Segment,
  Divider,
} from "semantic-ui-react";

// import Worker from "./testMqttWorker";
//import { mqttPublish } from "./actions";

import {
  mqttPublish,
  removeAcks,
  isAcksReceived,
} from "components/WebWorker/actions";

import { getAllAreaStatuses, getAcks } from "components/WebWorker/reducer";

import { getUserSessionIp, messageToken } from "utils/messageToken";

import { confirmTokenRxWithRetryAndTimeout } from "utils/confirmTokenRxWithRetryAndTimeout";

import * as Api from "api/apiAck";
import { downloadFileChannel, channelBufferPublishMsg } from "apiSaga";

import {
  testLedFF,
  testFF,
  testArea,
  testNamedArea,
  testNamedAreaEvent,
  officeFireflies,
  testController,
  officeControllers,
} from "pages/Test/TestMQTT/mqttMessages";

import { cloneDeep } from "lodash";

class TestMqttSimpleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventMsg: "",
      toggleActive: {},
      groupActive: {},
      que1: "",
      que2: "",
      que3: false,
    };
  }

  // ----------NAMED_AREA MESSAGES -----------------
  newNamedArea = () => {
    console.log("qqq click new");

    const newMsg = cloneDeep(testNamedArea);
    console.log("qqq newNamedArea", newMsg);
    this.props.mqttPublish({
      topic: `named_area/${newMsg.id}/new`,
      qos: 0,
      message: newMsg,
      retained: false,
    });
  };

  changeNamedArea = () => {
    console.log("qqq click change");

    const changeButton = {
      clickable: true,
      icon: "travelway_button.png",
      priority: 2,
      title: "Travelway",
      alt: "Area A - Travelway",
      state: "on",
      color: "yelllow",
      on_time: 0,
      off_time: 0,
    };

    let changeMsg = cloneDeep(testNamedArea);
    changeMsg.button.push(changeButton);
    console.log("qqq changeNamedArea", changeMsg);
    this.props.mqttPublish({
      topic: `named_area/${changeMsg.id}/change`,
      qos: 0,
      message: changeMsg,
      retained: false,
    });
  };

  deleteNamedArea = () => {
    console.log("qqq click delete");

    // test
    const deleteMsg = {
      id: testNamedArea.id,
      delete: true,
    };

    console.log("qqq deleteNamedArea", deleteMsg);
    this.props.mqttPublish({
      topic: `named_area/${testNamedArea.id}/delete`,
      qos: 0,
      message: deleteMsg,
      retained: false,
    });
  };

  eventNamedArea = (active) => {
    console.log("qqq click event");

    let changeMsg = cloneDeep(testNamedAreaEvent);
    changeMsg.active = active;

    console.log("qqq eventNamedArea", changeMsg);
    this.props.mqttPublish({
      topic: `named_area/${changeMsg.id}/event`,
      qos: 0,
      message: changeMsg,
      retained: true,
    });
  };

  // -----AREA MESSAGES-------------------------------------------------------

  newArea = () => {
    console.log("qqq click new");

    // const token = messageToken();
    // const newMsg = {
    //   id: testArea.id,
    //   image_filename: testArea.image_filename,
    //   token: token,
    // };
    // console.log("qqq newArea", newMsg);
    // this.props.mqttPublish({
    //   topic: `area/${testArea.id}/new`,
    //   qos: 0,
    //   message: newMsg,
    //   retained: false,
    // });

    // prepare message token
    const userSessionIp = getUserSessionIp();
    const token = messageToken(userSessionIp);

    this.setState({ eventMsg: `newArea - token (${token})` });

    const newMsg = {
      id: testArea.id,
      image_filename: testArea.image_filename,
      token: token,
    };
    const payload = {
      topic: `area/${testArea.id}/new`,
      qos: 0,
      message: newMsg,
      retained: false,
    };

    // const channelBufferPublishMsg = (payload) => {
    //   console.log(
    //     "newArea sent mqtt publish msg to channel buffer ....",
    //     payload
    //   );
    //   downloadFileChannel.put({
    //     type: "MQTT_PUBLISH",
    //     payload: payload,
    //   });
    // };

    channelBufferPublishMsg(payload, "MQTT_PUBLISH");

    // after message sent get confirmation of action (ACK) by comparing with token returned

    console.log("newArea ACK sent message with token:", token);

    // wait for ack message with promise message timer
    // waits time is 1000 mS between retries
    const retries = 2;

    const checkToken = async (token) => {
      return await confirmTokenRxWithRetryAndTimeout(token, retries);
    };

    checkToken(token)
      .then((result) =>
        console.log("newArea found the Ack at index: " + result)
      ) // found matching ack
      .then(() => Api.removeAckToken(token)) // delete it
      .catch(console.log);

    // #REVIEW -  move this to form submit action
  };

  changeArea = () => {
    console.log("qqq click change");

    const changeMsg = cloneDeep(testArea);

    console.log("qqq changeArea", changeMsg);
    this.props.mqttPublish({
      topic: `area/${changeMsg.id}/change`,
      qos: 0,
      message: changeMsg,
      retained: false,
    });
  };

  deleteArea = () => {
    console.log("qqq click delete");

    const id = `Two`; //`DMLZ_Undercut`; //testArea.id; //

    // sdkfjshdkfh

    const deleteMsg = {
      id: id,
      delete: true,
      token: messageToken(),
    };

    console.log("qqq deleteArea", deleteMsg);
    this.props.mqttPublish({
      topic: `area/${id}/delete`,
      qos: 0,
      message: deleteMsg,
      retained: false,
    });
  };

  // -----CONTROLLER MESSAGES-------------------------------------------------------

  newController = () => {
    console.log("qqq click new Controller");

    const newMsg = cloneDeep(testController);

    console.log("qqq newController", newMsg);

    this.props.mqttPublish({
      topic: `controller/${newMsg.id}/new`,
      qos: 0,
      message: newMsg,
      retained: false,
    });
  };

  changeController = () => {
    console.log("qqq click change");

    const changeMsg = cloneDeep(testController);

    console.log("qqq changeController", changeMsg);
    this.props.mqttPublish({
      topic: `controller/${changeMsg.id}/change`,
      qos: 0,
      message: changeMsg,
      retained: false,
    });
  };

  deleteController = () => {
    console.log("qqq click delete");

    const deleteMsg = {
      id: testController.id,
      delete: true,
      token: messageToken(),
    };

    console.log("qqq deleteController", deleteMsg);
    this.props.mqttPublish({
      topic: `controller/${deleteMsg.id}/delete`,
      qos: 0,
      message: deleteMsg,
      retained: false,
    });
  };

  newOfficeControllers = () => {
    console.log("qqq click newOfficeControllers");

    const changeMsg = cloneDeep(officeControllers);
    console.log("qqq newOfficeControllers", changeMsg);

    changeMsg.forEach((controller) => {
      console.log("qqq controller change", controller);
      this.props.mqttPublish({
        topic: `controller/${controller.id}/change`,
        qos: 0,
        message: controller,
        retained: false,
      });
    });
  };

  // -----FF MESSAGES-------------------------------------------------------

  newFF = () => {
    console.log("qqq click new change");

    const changeMsg = cloneDeep(testFF);

    console.log("qqq newFF", changeMsg);
    this.props.mqttPublish({
      topic: `firefly/${changeMsg.id}/change`,
      qos: 0,
      message: changeMsg,
      retained: false,
    });
  };
  changeFF = () => {
    this.newFF();
  };

  deleteFF = () => {
    console.log("qqq click delete");

    const deleteMsg = {
      id: testFF.id,
      delete: true,
      token: messageToken(),
    };

    console.log("qqq deleteFF", deleteMsg);
    this.props.mqttPublish({
      topic: `firefly/${testFF.id}/delete`,
      qos: 0,
      message: deleteMsg,
      retained: false,
    });
  };

  ledFF = () => {
    console.log("qqq click led");

    const changeMsg = cloneDeep(testLedFF);
    const id = `${changeMsg.location}:${changeMsg.position}`;

    console.log("qqq ledFF", id, changeMsg);
    this.props.mqttPublish({
      topic: `firefly/${id}/led`,
      qos: 0,
      message: changeMsg,
      retained: true,
    });
  };

  // newOfficeFireflies;

  newOfficeFFs = () => {
    console.log("qqq click newOfficeFFs");

    const changeMsg = cloneDeep(officeFireflies);
    console.log("qqq newOfficeFFs", changeMsg);

    changeMsg.forEach((firefly) => {
      console.log("qqq firefly change", firefly);
      this.props.mqttPublish({
        topic: `firefly/${firefly.id}/change`,
        qos: 0,
        message: firefly,
        retained: false,
      });
    });
  };

  componentDidMount() {
    //console.log("qqq mounted src/pages/Test/TestMQTT/TestSimpleTable.js");
  }

  handleClick = () =>
    this.setState((prevState) => ({ toggleActive: !prevState.toggleActive }));
  handleGroupClick = () =>
    this.setState((prevState) => ({ toggleActive: !prevState.toggleActive }));

  handleClickDataKeyQue1 = (event) => {
    this.setState(
      {
        que1: event.target.attributes.getNamedItem("data-key").value,
      },
      () => {
        console.log(this.state.que1);
      }
    );
  };

  isButtonActiveQue1 = (dataKey) => {
    if (dataKey === this.state.que1) {
      return true;
    } else {
      return false;
    }
  };

  handleClickDataKeyQue2 = (event) => {
    this.setState(
      {
        que2: event.target.attributes.getNamedItem("data-key").value,
      },
      () => {
        console.log(this.state.que2);
      }
    );
  };

  isButtonActiveQue2 = (dataKey) => {
    if (dataKey === this.state.que2) {
      return true;
    } else {
      return false;
    }
  };

  handleClickDataKeyQue3 = (event) => {
    this.setState((prevState) => ({ que3: !prevState.que3 }));

    // this.setState(
    //   {
    //     que3: event.target.attributes.getNamedItem("data-key").value,
    //   },
    //   () => {
    //     console.log(this.state.que3);
    //   }
    // );
  };

  isButtonActiveQue3 = (dataKey) => {
    // if (dataKey === this.state.que3) {
    //   return true;
    // } else {
    //   return false;
    // }
    return this.state.que3;
  };

  render() {
    const { allAreas } = this.props;
    const { eventMsg } = this.state;

    const { toggleActive } = this.state;

    return (
      <>
        {/* <div>
          <Worker />
        </div> */}
        <div style={{ margin: "50px" }}>
          <Table basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Console filter "qqq"</Table.HeaderCell>
                {/* <Table.HeaderCell>new</Table.HeaderCell>
                <Table.HeaderCell>delete</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {/* -------------NAMED_AREA--------------------- */}
              <Table.Row>
                <Table.Cell>named_area</Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.newNamedArea}>
                    new
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.changeNamedArea}>
                    change
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.deleteNamedArea}>
                    delete
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="small"
                    onClick={() => this.eventNamedArea(true)}
                  >
                    event on
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="small"
                    onClick={() => this.eventNamedArea(false)}
                  >
                    event off
                  </Button>
                </Table.Cell>
              </Table.Row>
              {/* -------------AREA--------------------- */}
              <Table.Row>
                <Table.Cell>area</Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.newArea}>
                    new
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.changeArea}>
                    change
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.deleteArea}>
                    delete
                  </Button>
                </Table.Cell>
              </Table.Row>
              {/* -------------CONTOLLER--------------------- */}
              <Table.Row>
                <Table.Cell>controller</Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.newController}>
                    new
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.changeController}>
                    change
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.deleteController}>
                    delete
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.newOfficeControllers}>
                    addOfficeControllers
                  </Button>
                </Table.Cell>
              </Table.Row>
              {/* -------------FIREFLY--------------------- */}
              <Table.Row>
                <Table.Cell>firefly</Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.newFF}>
                    new
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.changeFF}>
                    change
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.deleteFF}>
                    delete
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.ledFF}>
                    led
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button size="small" onClick={this.newOfficeFFs}>
                    addOfficeFFs
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Segment>
            <div>
              {/* <Button toggle active={toggleActive} onClick={this.handleClick}>
                Toggle
              </Button>
              <Button.Group className={"testButtonGroup1"} size="large">
                <Button active={groupActive} onClick={this.handleGroupClick}>
                  One
                </Button>
                <Button active={groupActive} onClick={this.handleGroupClick}>
                  Two
                </Button>
                <Button active={groupActive} onClick={this.handleGroupClick}>
                  Three
                </Button>
              </Button.Group> */}
            </div>
            <div>
              <Button.Group
                toggle={true}
                className={"testButtonGroup2"}
                size="large"
              >
                <Button>One</Button>
              </Button.Group>
            </div>
          </Segment>
          <Segment>
            <Button.Group onClick={this.handleClickDataKeyQue1}>
              <Button
                active={this.isButtonActiveQue1("Never")}
                data-key="Never"
              >
                Never
              </Button>
              <Button
                active={this.isButtonActiveQue1("Occassionally")}
                data-key="Occassionally"
              >
                Occassionally
              </Button>
              <Button
                active={this.isButtonActiveQue1("Often")}
                data-key="Often"
              >
                Often
              </Button>
              <Button
                active={this.isButtonActiveQue1("Very Often")}
                data-key="Very Often"
              >
                Very Often
              </Button>
              <Button
                active={this.isButtonActiveQue1("Always")}
                data-key="Always"
              >
                Always
              </Button>
            </Button.Group>
            <Divider />
            <Button.Group onClick={this.handleClickDataKeyQue2}>
              <Button
                active={this.isButtonActiveQue2("Never")}
                data-key="Never"
              >
                Never
              </Button>
              <Button
                active={this.isButtonActiveQue2("Occassionally")}
                data-key="Occassionally"
              >
                Occassionally
              </Button>
            </Button.Group>
            <Divider />
            <Button.Group onClick={this.handleClickDataKeyQue3}>
              <Button
                toggle
                active={this.isButtonActiveQue3("Never")}
                data-key="Never"
              >
                Never
              </Button>
            </Button.Group>
          </Segment>

          <Segment>
            <Message>
              <Message.Header>Click event:</Message.Header>
              <pre>{eventMsg}</pre>
            </Message>
          </Segment>
          <Segment>
            <Message>
              <Message.Header>Page props:</Message.Header>
              <pre>Props {JSON.stringify(allAreas, null, 2)}</pre>
              {/* <pre>State {JSON.stringify(this.state, null, 2)}</pre> */}
            </Message>
          </Segment>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const allAreas = getAllAreaStatuses(state);

  // ack mqtt messages
  const acks = getAcks(state);

  // get unique ip of the client
  //const userSessionIp = getUserSessionIp(state);

  return {
    allAreas,
    acks,
    //userSessionIp,
  };
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    mqttPublish: (mqttMsg) => {
      dispatch(mqttPublish(mqttMsg));
    },
    removeAcks: (token) => {
      dispatch(removeAcks(token));
    },
    isAcksReceived: (token) => {
      dispatch(isAcksReceived(token));
    },
    // LogIt: (thingsLogged) => {
    //   dispatch(LogIt(thingsLogged));
    // },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestMqttSimpleTable);
