import React from 'react'
import PropTypes from 'prop-types'
import { FeatureGroup, Polygon } from 'react-leaflet'
import getSemanticColor from 'colorpalette'

export function SeismicMapRegion (props) {
  const { points, lightSettings: { color }, pane = 'overlayPane' } = props
  const semColor = getSemanticColor(color)
  return (
    <Polygon
      positions={points}
      fillColor={semColor}
      color={color === 'white' ? 'black' : semColor}
      pane={pane}
    />
  )
}

function pointsFromBounds (bounds) {
  const pt1 = bounds[0]
  const pt2 = bounds[1]
  const lng1 = pt1.lng
  const lat1 = pt1.lat
  const lng2 = pt2.lng
  const lat2 = pt2.lat
  return [[lat1, lng1], [lat1, lng2], [lat2, lng2], [lat2, lng1]]
}

class SeismicLayerFeatureGroup extends React.Component {
  static propTypes = {}
  render () {
    const {
      regionSettings,
      levelDefault,
      mineLevelBounds,
      displayDefault = true
    } = this.props
    let defaultRegion

    if (displayDefault) {
      const props = {
        lightSettings: levelDefault,
        points: pointsFromBounds(mineLevelBounds)
      }
      defaultRegion = <SeismicMapRegion {...props} pane={'tilePane'} />
    }

    return (
      <FeatureGroup>
        {defaultRegion}
        {regionSettings
          .asMutable()
          .reverse()
          .map((region, idx) => {
            const { priority = idx } = region
            return (
              <SeismicMapRegion
                {...region}
                key={`seismic-region-${region.id}-${priority}`}
              />
            )
          })}
      </FeatureGroup>
    )
  }
}

SeismicLayerFeatureGroup.defaultProps = {}

const LatLngObject = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
})

const LightSettings = PropTypes.shape({ color: PropTypes.string })
const RegionSetting = PropTypes.shape({
  settings: LightSettings,
  points: PropTypes.arrayOf(LatLngObject)
})

SeismicLayerFeatureGroup.propTypes = {
  mineLevelBounds: PropTypes.arrayOf(LatLngObject).isRequired,
  levelDefault: RegionSetting.isRequired,
  regionSettings: PropTypes.arrayOf(RegionSetting).isRequired,
  displayDefault: PropTypes.bool
}

export default SeismicLayerFeatureGroup
