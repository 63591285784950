import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Icon,
  Popup,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { formatDate } from "utils/format-date";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";

//import GenericButton from "admin/GenericButton";
import { Upload } from "admin/UploadButton";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";
import { languageOptions } from "components/ConfigJs";

// XLSL 'react-export-excel' IMPLEMENTATION
// click button to download report
// uses https://www.npmjs.com/package/react-export-excel
// see ex - https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/with_custom_download_element.md
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  render() {
    const { data } = this.props;
    const filename = `mqtt_messages_report_${formatDate(
      new Date(Date.now()),
      "dd-MMM-yy-HH-mm-ss"
    )}`;

    return (
      <ExcelFile
        filename={filename}
        element={
          <Popup
            content="Download XLSL logged report"
            trigger={
              <Button
                icon
                size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              >
                <Icon name="download" />
              </Button>
            }
          />
        }
      >
        <ExcelSheet data={data} name="Name">
          <ExcelColumn label="Log Time" value="time" />
          <ExcelColumn label="Topic" value="topic" />
          <ExcelColumn label="Message" value="message" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

class LocalisationList extends Component {
  constructor(props) {
    super(props);
    const downloadData = [];
    this.state = {
      downloadData,
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        localisation: "",
        regionCode: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    // localisation does not require data collection
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    const filterInput = this.props.componentstate.get(
      "filterInput",
      "localisationList"
    );

    //console.log("xxx filterInput", filterInput);

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "localisationList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });

    // localisation does not require data collection
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;
    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickExport = () => {};
  onClickImport = () => {};

  render() {
    const includeTitles = this.props.includeTitles === "true";
    const title = includeTitles ? (
      <Header as="h1">Localisations</Header>
    ) : (
      <div></div>
    );

    const { isLoading, error } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "locallist10" },
              { value: 20, text: "20", key: "locallist20" },
              { value: 40, text: "40", key: "locallist40" },
              { value: 60, text: "60", key: "locallist60" },
              { value: data.length, text: "all", key: "locallistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{title}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          {false && (
            <Segment textAlign="left" style={segmentStyle}>
              <div>
                <span style={{ marginRight: "8px" }}>{/*  */}</span>
              </div>
              {/* label and button *will not* go to same height as depends on font sizes. 
            Fudge by vertical centering the button and label in same div. */}
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Popup
                  content="Click to export a SQL data file"
                  trigger={
                    <Button
                      size="large"
                      color="grey"
                      onClick={this.onClickDownload}
                      disabled={true}
                    >
                      Export
                    </Button>
                  }
                />
                <Upload
                  popup={"Click to Import a SQL file"}
                  label={"Import"}
                  fileType={".sql"}
                  disabled={true}
                />
              </div>
            </Segment>
          )}

          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.localisation ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="localisation"
                    onChange={this.handleFilterChange}
                    value={filterInput?.localisation}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    className={
                      filterInput?.regionCode ? "filterInputHighlight" : null
                    }
                    size="mini"
                    fluid
                    icon="search"
                    placeholder="Filter..."
                    name="regionCode"
                    onChange={this.handleFilterChange}
                    value={filterInput?.regionCode}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "localisation" ? direction : null}
                  onClick={this.handleSort("localisation")}
                >
                  Localisation
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "regionCode" ? direction : null}
                  onClick={this.handleSort("regionCode")}
                >
                  Region Code
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(viewablesPage, ({ localisationLink, regionCode }) => (
                <Table.Row>
                  <Table.Cell>{localisationLink}</Table.Cell>
                  <Table.Cell>{regionCode}</Table.Cell>
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  let filteredElements = [];

  // only need a subset of data
  elements.forEach(function (element, idx) {
    const localisation = languageOptions?.find(
      (opt) => opt.key === element
    )?.regionCodeLanguage;

    // compose new data set
    filteredElements[idx] = {
      regionCode: element,
      localisation: localisation,
      localisationLink: (
        <Link to={`/tools/localisation/edit/${element}`}>{localisation}</Link>
      ),
    };
  });

  return filteredElements;
};

const mapStateToProps = (state, props) => {
  const { strings = {} } = props;
  const availableLanguages = Object.keys(strings?._props); // #NOTE - .getAvailableLanguages() does not seem to work reliably???!

  const data = _prepData(availableLanguages);

  //console.log("xxx lang availableLanguages", availableLanguages);
  //console.log("xxx lang data ", data);
  //console.log("xxx lang strings", strings);
  // console.log(
  //   "xxx lang strings getAvailableLanguages",
  //   strings.getAvailableLanguages()
  // ); // ['en-US', 'es-419', 'hi-IN']
  // console.log("xxx lang strings getLanguage", strings.getLanguage());
  // console.log(
  //   "xxx lang strings getInterfaceLanguage",
  //   strings.getInterfaceLanguage()
  // );

  return {
    data,
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, {
    TurnOnOffPokeTheWorker,
    saveUserSettingsComponentState,
  })(LocalisationList)
);
