import React, { Component } from "react";
import { connect } from "react-redux";

import { getMqttMsg } from "components/WebWorker/reducer.js";

import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Icon,
  Popup,
  Label,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import UPSLink from "admin/ups/UPSLink";
import FireflyLink from "admin/firefly/FireflyLink";

import AlarmButton from "components/AlarmButton";
import { timeConverter } from "utils/format-time";
import { capitalize } from "utils/format-text";
import { formatDate } from "utils/format-date";

import lodashSortBy from "lodash/sortBy";
import { formatRelative, parseISO } from "date-fns";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { withComponentStateCache } from "react-component-state-cache";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

// XLSL 'react-export-excel' IMPLEMENTATION
// click button to download report
// uses https://www.npmjs.com/package/react-export-excel
// see ex - https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/with_custom_download_element.md
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  render() {
    const { data, faultType } = this.props;

    const filename = `${capitalize(faultType)}_fault_status_report_${formatDate(
      new Date(Date.now()),
      "dd-MMM-yy-HH-mm-ss"
    )}`;

    return (
      <ExcelFile
        filename={filename}
        element={
          <Popup
            content="Download XLSX fault report"
            trigger={
              <Button
                icon
                size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              >
                <Icon name="download" />
              </Button>
            }
          />
        }
      >
        <ExcelSheet data={data} name="Name">
          <ExcelColumn label="Mac Address" value="id" />
          <ExcelColumn
            label={
              faultType === "firefly"
                ? "Controller Mac Addr"
                : "Controller Name"
            }
            value="name"
          />
          <ExcelColumn label="Description" value="description" />
          <ExcelColumn label="Fault Level" value="fault_level" />
          <ExcelColumn label="Area" value="area" />
          <ExcelColumn label="Location" value="location" />
          <ExcelColumn label="Position" value="position" />
          <ExcelColumn label="IP Address" value="ip" />
          <ExcelColumn label="Fault Time" value="fault_ts" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

class FaultList extends Component {
  constructor(props) {
    super(props);
    const downloadData = [];
    const mqttMsgDefault = [
      {
        type: "",
        id: "",
        name: "",
        position: 0,
        description: "",
        active: true,
        fault_level: 0,
        area: "",
        location: [""],
        ip: "",
        ts: 0,
        fault_ts: 0,
      },
    ];
    this.state = {
      downloadData,
      mqttMsgDefault,
      // initialise data list sort columns
      column: null,
      data: props.data.filter((item) => item.type === props.faultType),
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // type: "",
        // id: "",
        name: "",
        description: "",
        // fault_level: "",
        area: "",
        location: "",
        // position: "",
        // ip: "",
        // ts: "",
        // fault_ts: "",
        // controller: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    const filterInput = this.props.componentstate.get(
      "filterInput",
      `${this.props.faultType}FaultList`
    );

    // console.log(
    //   "filterInput componentDidMount ",
    //   `${this.props.faultType}FaultList`,
    //   filterInput
    // );

    if (!_isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: `${this.props.faultType}FaultList`,
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageFaultTs, faultType, data: allFaultData } = this.props;
    const { filterInput: match } = this.state;

    // #REVIEW - this is a fudge because faultType is passed through as a prop but data is rendered
    // via redux mapstatetoprop which does not have access to faultType.
    // Probably should setup a HOC(?) for this but fudged it instead

    const data = allFaultData.filter((item) => item.type === faultType);

    // also filter prevProps.data -> prevProps
    const prevPropsData = prevProps.data.filter(
      (item) => item.type === faultType
    );

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(data) !== JSON.stringify(prevPropsData) // if original props data changes
    ) {
      let newData = data;

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included

        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      // add icon to 'new' faults
      newData = newData.map((item) => {
        if (item.fault_ts > pageFaultTs[faultType]) {
          return { ...item, icon: "warning" };
        } else {
          return { ...item, icon: "" };
        }
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  render() {
    const { faultType, pageTitle, strings } = this.props;

    const { isLoading, error } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "faultlist10" },
              { value: 20, text: "20", key: "faulltist20" },
              { value: 40, text: "40", key: "faultlist40" },
              { value: 60, text: "60", key: "faultlist60" },
              { value: data.length, text: "all", key: "faultlistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{pageTitle}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              <div>
                <span style={{ marginRight: "8px" }}>
                  <Download data={data} faultType={faultType} />
                </span>
              </div>
              {/* label and button *will not* go to same height as depends on font sizes. 
            Fudge by vertical centering the button and label in same div. */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Popup
                  content={strings?.["Total current faults"]}
                  trigger={
                    <Button size="large" color="light blue" disabled>
                      {itemsCount}
                    </Button>
                  }
                />
                <AlarmButton
                  faultType={faultType}
                  faultAction="reset"
                  strings={strings}
                />
              </div>
            </Segment>
          </Segment.Group>
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={filterInput?.id ? "filterInputHighlight" : null}
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="id"
                    onChange={this.handleFilterChange}
                    value={filterInput?.id}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.name ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="name"
                    onChange={this.handleFilterChange}
                    value={filterInput?.name}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.description ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="description"
                    onChange={this.handleFilterChange}
                    value={filterInput?.description}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    icon="search"
                    fluid
                    className={
                      filterInput?.area ? "filterInputHighlight" : null
                    }
                    placeholder={strings?.["Filter..."]}
                    name="area"
                    onChange={this.handleFilterChange}
                    value={filterInput?.area}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.location ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="location"
                    onChange={this.handleFilterChange}
                    value={filterInput?.location}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle} />
                <Table.Cell style={headerCellStyle} />
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Icon name="warning" />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "id" ? direction : null}
                  onClick={this.handleSort("id")}
                >
                  {strings?.["ID"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "name" ? direction : null}
                  onClick={this.handleSort("name")}
                >
                  {strings?.["Device"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "description" ? direction : null}
                  onClick={this.handleSort("description")}
                >
                  {strings?.["Fault"]}
                </Table.HeaderCell>

                <Table.HeaderCell
                  width={1}
                  sorted={column === "fault_level" ? direction : null}
                  onClick={this.handleSort("fault_level")}
                >
                  {strings?.["Fault Level"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "area" ? direction : null}
                  onClick={this.handleSort("area")}
                >
                  {strings?.["Area"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "location" ? direction : null}
                  onClick={this.handleSort("location")}
                >
                  {strings?.["Location"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={column === "position" ? direction : null}
                  onClick={this.handleSort("position")}
                >
                  {strings?.["Position"]}
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  {strings?.["Controller URL"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={5}
                  sorted={column === "fault_tsReadable" ? direction : null}
                  onClick={this.handleSort("fault_tsReadable")}
                >
                  {strings?.["Fault Time"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({
                  id,
                  idLink,
                  name,
                  description,
                  descriptionLink,
                  fault_level,
                  area,
                  location,
                  position,
                  ip,
                  fault_tsReadable,
                  icon,
                }) => (
                  <Table.Row
                    className={icon === "" ? "faultPageRow" : "faultPageRowNew"}
                    key={`key-${id}-${name}`}
                  >
                    <Table.Cell>
                      <Icon name={icon} color="red" />
                    </Table.Cell>
                    <Table.Cell>{idLink}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{descriptionLink}</Table.Cell>
                    <Table.Cell>{fault_level}</Table.Cell>
                    <Table.Cell>{area}</Table.Cell>
                    <Table.Cell>{location}</Table.Cell>
                    <Table.Cell>{position}</Table.Cell>
                    <Table.Cell>{ip}</Table.Cell>
                    <Table.Cell>{fault_tsReadable}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
                columnSpan={3}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  // filter out only faultType
  //  const faultType = "firefly";
  //  const elements = mqttMsg.filter((item) => item.type === faultType);

  let filteredElements = [];

  // only need a subset of data
  elements.forEach(function (element, idx) {
    const {
      type,
      subtype,
      id,
      name,
      description,
      fault_level,
      area,
      location,
      position,
      ip,
      ts,
      fault_ts,
    } = element;

    // change fault level from int to descriptor
    const faultLevelDescriptions = {
      1: "Low",
      2: "Medium",
      3: "High",
    };
    let faultLevel = fault_level;
    if (Number.isInteger(fault_level)) {
      if (fault_level >= 1 && fault_level <= 3) {
        faultLevel = faultLevelDescriptions[fault_level];
      }
    }

    let ipLink = "-";
    if (!_isEmpty(ip)) {
      ipLink = ipLink ? (
        <a href={`http://${ip}`} rel="noopener noreferrer" target="_blank">
          {ip}
        </a>
      ) : (
        "-"
      );
    }

    // #NOTE - special parse of `altered` description
    let descriptionLink = description;
    if (type === "firefly" && subtype === "device_change") {
      console.log(`description`, description);

      // e.g. "Device  'c4:4f:33:23:b2:d5' has changed Controller '4c:3f:d3:3b:62:14'"
      const matches = description?.match(
        /\b(?:Device|Controller|has|changed)\b/g
      );
      if (matches?.length > 3) {
        let ipArray = description.match(/\S+|'[^']+'/g);

        //console.log("ipArray", ipArray);

        const fireflyMac = ipArray[1].replaceAll("'", "");
        const controllerMac = ipArray[5].replaceAll("'", "");
        descriptionLink = (
          <div>
            FF device ({fireflyMac}) has changed controller ID -{" "}
            <UPSLink id={controllerMac}>{controllerMac}</UPSLink>
          </div>
        );
      }
    }

    // compose new data set
    filteredElements[idx] = {
      // area: "DMLZ_Extraction",
      // description: "Network failed : Could not Ping FireFly Controller",
      // fault_level: 3,
      // fault_ts: 1591338973,
      // id: "74:e1:82:91:60:c5",
      // ip: "10.208.26.143",
      // location: "P5, P6, P7, P8",
      // name: "UPS 14",
      // position: 0,
      // ts: 1591342458,
      // type: "network",
      type: type,
      id: id,
      idLink:
        type === "firefly" ? (
          <FireflyLink id={id}>{id}</FireflyLink>
        ) : (
          <UPSLink id={id}>{id}</UPSLink>
        ),
      name: name,
      description: description,
      descriptionLink: descriptionLink,
      fault_level: faultLevel,
      area: area,
      location: location === undefined ? "-" : location.toString(),
      position: position === 0 ? "-" : `${position}`, // force to String
      ip: ipLink,
      ts: ts,
      fault_tsReadable: fault_ts > 0 ? timeConverter(fault_ts) : "-",
      fault_ts: fault_ts,
      icon: "",
    };
  });

  return filteredElements;
};

const mapStateToProps = (state, props) => {
  // see -  src/components/Header/index.js
  // switch between
  // <ProcessMQTT>
  //
  //  const { mqtt } = state;
  //  const { mqttMsg } = mqtt;
  // <WebWorker>
  //const { mqttMsg } = state.webWorker;
  const mqttMsg = getMqttMsg(state);
  const data = _prepData(mqttMsg);

  // fault timestamp states for page and mqtt message
  const { latestFaultTs: pageFaultTs } = state.alarmButton;

  return {
    mqttMsg,
    pageFaultTs,
    data,
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, { saveUserSettingsComponentState })(FaultList)
);
