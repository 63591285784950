import { makeRandomString } from "utils/make-random";
import { microTime } from "utils/microTime";
import { confirmTokenRxWithRetryAndTimeout } from "utils/confirmTokenRxWithRetryAndTimeout";

export var userSessionIp = getUserSessionIp() || "admin,0.0.0.0";

export function setUserSessionIp(ip) {
  if (ip !== undefined) {
    userSessionIp = ip;
  }
}

export function getUserSessionIp() {
  // Get saved data from sessionStorage
  return sessionStorage.getItem("userSessionIp");
}

export const checkToken = async (token) => {
  // after message sent get confirmation of action (ACK) by comparing with token returned
  // wait for ack message with promise message timer
  // waits time is 2000 mS between retries
  const retries = 5;
  return await confirmTokenRxWithRetryAndTimeout(token, retries);
};

export const messageToken = () => {
  const time = microTime().toString();
  const randString = makeRandomString(8);
  return `token-${randString}-${time},${getUserSessionIp()}`;
};
