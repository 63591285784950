import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import {
  Form,
  Container,
  Grid,
  Segment,
  Button,
  Header,
  Message,
  Icon,
  TextArea,
  Popup,
} from "semantic-ui-react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import ContainerDimensions from "react-container-dimensions";

import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { Field, reduxForm, formValueSelector } from "redux-form";

import { sanitizeRegionShape } from "utils/RegionShape";
import cuid from "cuid";

import ChooseMineLevelField from "components/ChooseMineLevelField";
//import { MineLevelMapById } from "components/Map/MineLevelMap";
//import { LayersControl } from "react-leaflet";
//import { Polygon } from "react-leaflet";

import { renderField } from "admin/form-field";

//import { EditControlFeatureGroup } from "@andrewdodd/react-leaflet-draw";

//import { FeatureGroup } from "react-leaflet";
//import { EditControl } from "react-leaflet-draw";

//import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroupGeoJson";

import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import {
  getAreaStatusesById,
  getAreaStatuses,
} from "components/WebWorker/reducer";

import { getNamedAreaStatusesByAreaId } from "components/WebWorker/reducer";

//import { geoFromSVGFile, geoFromSVGXML } from "svg2geojson";
import { geoFromSVGXML } from "utils/svg2geojson/svg2geojson";

import { transformGeoJsonUtmToPixels } from "components/Map/util-geoJsonToPixels";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
//import { fileExists } from "utils/fileExists";

import { isMapMoving } from "components/Map/reducer";

import CreateRegionsField from "admin/named-area-simple/CreateRegionsField";
import { stringify } from "querystring";

const buildNewRegion = (points) => {
  const namedAreaTimestamp = new Date().getTime();

  return {
    id: namedAreaTimestamp, //cuid(),
    points: sanitizeRegionShape(points),
  };
};

// const regionListWithoutId = (list, id) =>
//   list.filter((region) => region.id !== id);

// class _CreateRegionsField extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { editing: false };
//   }

//   addRegion = (evt) => {
//     //console.log("addRegion", evt);

//     const points = evt.layer.getLatLngs()[0];
//     // console.log("addRegion points", points);
//     // console.log("addRegion points", JSON.stringify(points));
//     // console.log("addRegion this.props", this.props);

//     const newRegionList = [...this.props.input.value, buildNewRegion(points)];
//     this.props.input.onChange(newRegionList);
//   };

//   addRegion_New = (e) => {
//     console.log("addRegionNew 1 e", e);

//     var layers = e.layers;
//     layers.eachLayer((layer) => {
//       console.log("addRegionNew 2 layer", layer);
//       console.log(
//         "addRegionNew 3 layer.getLatLngs()[0]",
//         layer.getLatLngs()[0]
//       );
//       console.log("addRegionNew 4 list = this.props.input", this.props.input);

//       const points = layer.getLatLngs()[0];

//       const newRegionList = [...this.props.input.value, buildNewRegion(points)];
//       this.props.input.onChange(newRegionList);
//     });
//   };

//   deleteRegion = (e) => {
//     //console.log("deleteRegion", e);

//     const { id } = e.props;
//     this.props.input.onChange(regionListWithoutId(this.props.input.value, id));
//   };

//   deleteRegion_New = (e) => {
//     console.log("deleteRegionNew", e);

//     var layers = e.layers;
//     layers.eachLayer((layer) => {
//       console.log("deleteRegionNew 2 layer", layer);
//       console.log(
//         "deleteRegionNew 3 layer.getLatLngs()[0]",
//         layer.getLatLngs()[0]
//       );
//       console.log(
//         "deleteRegionNew 4 list = this.props.input",
//         this.props.input
//       );

//       const { id } = e.props;
//       this.props.input.onChange(
//         regionListWithoutId(this.props.input.value, id)
//       );
//     });
//   };

//   changeRegion = (e, layer) => {
//     // console.log("changeRegion 1 e", e);
//     // console.log("changeRegion 2 layer", layer);
//     // console.log("changeRegion 3 layer.getLatLngs()[0]", layer.getLatLngs()[0]);
//     // console.log("changeRegion 4 list = this.props.input", this.props.input);

//     const points = layer.getLatLngs()[0];
//     const { id } = e.props;
//     let list = this.props.input.value;
//     list = regionListWithoutId(list, id);
//     list = [...list, buildNewRegion(points)];
//     this.props.input.onChange(list);
//   };

//   changeRegion_New = (e) => {
//     console.log("changeRegion 1 e", e);

//     var layers = e.layers;
//     layers.eachLayer((layer) => {
//       console.log("changeRegion 2 layer", layer);
//       console.log(
//         "changeRegion 3 layer.getLatLngs()[0]",
//         layer.getLatLngs()[0]
//       );
//       console.log("changeRegion 4 list = this.props.input", this.props.input);

//       const points = layer.getLatLngs()[0];
//       const { id } = e.props;
//       let list = this.props.input.value;
//       list = regionListWithoutId(list, id);
//       list = [...list, buildNewRegion(points)];
//       this.props.input.onChange(list);
//     });
//   };

//   handleActivityStarted = (e) => {
//     //console.log("started", e);
//     const { flagStartedEditing } = this.props;
//     if (flagStartedEditing) {
//       //flagStartedEditing()
//       //flagStartedEditing={() => {this.setState({'editing': true})}}
//       this.setState({ editing: true });
//       this.props.SetMapMoving(true);
//     }
//   };

//   handleActivityStopped = (e) => {
//     //console.log("stopped", e);
//     const { flagStoppedEditing } = this.props;
//     if (flagStoppedEditing) {
//       //flagStoppedEditing()
//       //flagStoppedEditing={() => {this.setState({'editing': false})}}
//       this.setState({ editing: false });
//       this.props.SetMapMoving(false);
//     }
//   };

//   render() {
//     const {
//       mineLevelIdValue,
//       width,
//       height,
//       input: { value },
//     } = this.props;

//     if (!mineLevelIdValue) {
//       return <div>Must select area</div>;
//     }

//     return (
//       <Form.Field>
//         <MineLevelMapById
//           mineLevelId={mineLevelIdValue}
//           style={{
//             height: "60vh", //Math.floor(height), //"60vh",
//             width: "100%", //</Form.Field>Math.floor(width), // "100%",
//             position: "relative",
//             zIndex: 0,
//             // #REVIEW #WIP #TODO - get background from mineLevel
//             //backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
//             backgroundColor: `rgba(255,255,255,1)`,
//           }}
//           //invaliddateSize={}
//         >
//           <LayersControl position={"topright"}>
//             <LayersControl.Overlay
//               name={"Fireflies"}
//               key={"firefly-overlay"}
//               checked
//             >
//               <CurrentMineLevelFirefliesFeatureGroup
//               // PopupContent={ForceableComponent}
//               />
//             </LayersControl.Overlay>
//             <LayersControl.Overlay
//               name={"Named Areas"}
//               key={"named-area-overlay"}
//               checked
//             >
//               {/* see - https://github.com/andrewdodd/react-leaflet-draw/blob/master/example-full/edit-control.js */}
//               <EditControlFeatureGroup
//                 onCreated={this.addRegion}
//                 onDeleted={this.deleteRegion}
//                 onEdited={this.changeRegion}
//                 onActivityStarted={this._handleActivityStarted}
//                 onActivityStopped={this._handleActivityStopped}
//                 controlProps={{
//                   draw: {
//                     rectangle: {
//                       //shapeOptions: { color: "green" },
//                       showLength: false,
//                       metric: false,
//                       feet: false,
//                       showArea: false,
//                     },
//                     marker: false,
//                     circle: false,
//                     circlemarker: false,
//                     polygon: {
//                       // shapeOptions: { color: "blue" },
//                       allowIntersection: false,
//                       showLength: false,
//                       metric: false,
//                       feet: false,
//                       showArea: false,
//                       drawError: {
//                         color: "#E1E100",
//                         message:
//                           "<strong>Wups</strong> shapes cannot intersect",
//                       },
//                     },
//                     polyline: false,
//                   },
//                   edit: {
//                     edit: {
//                       selectedPathOptions: {
//                         maintainColor: true,
//                         fillOpacity: 0.5,
//                         // fillColor: null
//                       },
//                     },
//                     poly: {
//                       allowIntersection: false,
//                       drawError: {
//                         color: "fuchsia",
//                         message:
//                           "<strong>Wups</strong> shapes cannot intersect",
//                       },
//                     },
//                   },
//                   position: "topright",
//                 }}
//               >
//                 {value.map((region) => {
//                   const { id, points } = region;
//                   //console.log("regions", region);
//                   return (
//                     <Polygon
//                       positions={points}
//                       fillOpacity={0.5}
//                       color={"black"}
//                       id={id}
//                       key={`editing-region-${id}`}
//                     />
//                   );
//                 })}
//               </EditControlFeatureGroup>
//               {/* ******************************************* */}

//               {/* #NOTE @13Jul21 - The following code section is the start of moving away
//               from Andrew's fork of react-leaflet-draw. The *_New functions work
//               exact for changeRegion() > e.props.
//               React-leaflet-draw does not accommodate passing the child props back to the parent.
//               <EditControlFeatureGroup> accommodates this.
//               The original reason for moving to generic react-leaflet-draw was to get access to
//               onEditStart & onEditStop. However I found Andrew has included onActivityStarted &
//               onActivityStopped as equivalents.
//               This whole area should be restructured.
//               See - https://stackoverflow.com/questions/63460655/edit-a-single-polygon-in-react-leaflet
//               See - https://stackblitz.com/edit/react-sn5kcm?file=src%2FMap.js
//               See - https://github.com/alex3165/react-leaflet-draw/blob/master/example/edit-control.js
//               As an examples for consideration.
//               //

//                 <FeatureGroup>
//                   <EditControl
//                     onCreated={this.addRegion_New}
//                     onDeleted={this.deleteRegion_New}
//                     onEdited={this.changeRegion_New}
//                     onEditStart={() => console.log(`editStart! :)`)}
//                     onEditStop={() => console.log(`editStop! :(`)}
//                     draw={{
//                       rectangle: {
//                         //shapeOptions: { color: "green" },
//                         showLength: false,
//                         metric: false,
//                         feet: false,
//                         showArea: false,
//                       },
//                       marker: false,
//                       circle: false,
//                       circlemarker: false,
//                       polygon: {
//                         // shapeOptions: { color: "blue" },
//                         allowIntersection: false,
//                         showLength: false,
//                         metric: false,
//                         feet: false,
//                         showArea: false,
//                         drawError: {
//                           color: "#E1E100",
//                           message:
//                             "<strong>Wups</strong> shapes cannot intersect",
//                         },
//                       },
//                       polyline: false,
//                     }}
//                     edit={{
//                       edit: {
//                         selectedPathOptions: {
//                           maintainColor: true,
//                           fillOpacity: 0.5,
//                           // fillColor: null
//                         },
//                       },
//                       poly: {
//                         allowIntersection: false,
//                         drawError: {
//                           color: "fuchsia",
//                           message:
//                             "<strong>Wups</strong> shapes cannot intersect",
//                         },
//                       },
//                     }}
//                     position={"topright"}
//                   />
//                   {value.map((region) => {
//                     const { id, points } = region;
//                     //console.log("regions", region);
//                     return (
//                       <Polygon
//                         positions={points}
//                         fillOpacity={0.5}
//                         color={"black"}
//                         id={id}
//                         key={`editing-region-${id}`}
//                       />
//                     );
//                   })}
//                 </FeatureGroup>
//               )}
//               */}
//               {/* ******************************************* */}
//             </LayersControl.Overlay>
//           </LayersControl>
//         </MineLevelMapById>
//       </Form.Field>
//     );
//   }
// }

// export const CreateRegionsField = connect(null, { SetMapMoving })(
//   _CreateRegionsField
// );

const validate = (values) => {
  const { mineLevelId, areaName, regions } = values;

  const errors = {};

  if (!mineLevelId) {
    errors.mineLevelId = "Required";
  }
  if (!areaName) {
    errors.areaName = "Required";
  }

  if (_isEmpty(regions)) {
    errors.regions = "Required";
  }

  return errors;
};

const normalise = (value, type) => {
  if (type === "noSpaces") {
    return value.replace(" ", "_");
  } else {
    return value;
  }
};

const required = (value) => (value ? undefined : "Required");
const isNamedAreaNameOK = (value, allValues, props, name) => {
  const { id } = allValues;

  const { uniqueNamedAreaParentIds, strings } = props;

  const valueNoSpace = value.replace(" ", "_");

  const notIncludingThisId = uniqueNamedAreaParentIds
    .filter((item) => !item.id.includes(id))
    .map((item) => item.parentName);

  //console.log(`notIncludingThisId `, notIncludingThisId);

  if (notIncludingThisId?.includes(valueNoSpace)) {
    return strings?.["This name has already been used"];
  } else {
    return undefined;
  }
};

class NamedAreaSimpleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importSvg: "",
      collapse: false,
      errorMessage: "",
      // color picker
      displayColorPicker: false,
      backgroundColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 0.5,
      },
    };
  }

  componentDidMount() {
    const {
      initialValues: { backgroundColor },
    } = this.props;
    this.setState({ backgroundColor: backgroundColor });
  }

  componentDidUpdate(prevProps, prevState) {
    const { mineLevelIdValue, area } = this.props;

    // #NOTE
    // #WIP

    // This section has been commented out for the moment.
    // I want to check if the file exist before trying to load the mine level, however
    // this check `fileExists` appears to have the effect of clearing the browser memory cache(?) of the
    // image file. So after the check the file needs to be reloaded from disk cache.
    //
    // This needs to be analysed and a better method found to use globally.
    //
    // if (!_isEqual(mineLevelIdValue, prevProps.mineLevelIdValue)) {
    //   console.log(`render check file exists!`);
    //   if (mineLevelIdValue) {
    //     // check there is a map image to load
    //     const url = process.env.PUBLIC_URL + "/areas/" + area?.image_filename;
    //     if (!fileExists(url)) {
    //       this.setState({
    //         errorMessage:
    //           "Area image file does not exist. Check Area settings.",
    //       });
    //     }
    //   }
    // }
  }

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  resetForm = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // https://github.com/Asymmetrik/ngx-leaflet/issues/88
    // unmount error - "TypeError: Cannot read property 'off' of undefined"

    // abort page changes
    //this.props.reset();
    this.props.onCancel();
  };

  submitMyForm = (values) => {
    //console.log(`form submitted: values`, values);

    // const { mineLevelId, areaName, regions, namedAreaParentName } = values;
    //const { transformPixelsToUtm } = this.props;

    // let newNamedAreas = [];

    // regions.forEach((region) => {
    //   const { id, points } = region;

    //   let shape = [];

    //   points.forEach((point) => {
    //     const objLatLng = transformPixelsToUtm.transform({
    //       lat: point.lat,
    //       lng: point.lng,
    //     });

    //     shape.push(Object.values(objLatLng).reverse()); // reverse lat / lng coords
    //   });

    //   shape.push(shape[0]); // append 1st point to end to close the shape

    //   newNamedAreas.push({
    //     id: `${mineLevelId}:${areaName}:${id}`,
    //     name: `${id}`,
    //     parent: `${mineLevelId}:${areaName}`,
    //     parentName: namedAreaParentName,
    //     area: mineLevelId,
    //     shape: shape,
    //   });
    // });

    //this.props.onSubmit(newNamedAreas);

    this.props.onSubmit(values);
  };

  requestDelete = (id) => {
    // `id` is the only prop
    // console.log("requestDelete", id);
    // console.log("requestDelete", this.props);

    const { change } = this.props;

    // #NOTE -
    // This is a fudge to stop the navigation prompt modal dlg.
    // Call change to populate the formValue `_action` at the beginning of the delete process.
    // The _action value is checked in the NavigationPromptModal, which does not display.
    change("_action", "delete");

    this.props.onDelete(id);
  };

  importSvgCheck = (value) => {
    console.log("value", value);

    this.setState({ importSvg: value });
  };

  importSvgSubmit = (e) => {
    e.preventDefault();

    let svg = this.state.importSvg;

    //console.log("sss svg", svg);

    const { diagonalRefCoords, areaStatuses, mineLevelIdValue } = this.props;

    //console.log("diagonalRefCoords", diagonalRefCoords);

    const { ref1, ref2, ref3, ref4 } = diagonalRefCoords;

    if (!svg.includes("<svg") && !svg.includes("</svg>")) {
      svg = `<?xml version="1.0" encoding="UTF-8"?>
      <!-- 2021-02-22 09:35:41 Generated by QCAD 3.24.3 SVG Exporter -->
      <svg width="717113.2915mm" height="650741.5438mm" viewBox="737126321.9167 -9549440501.8074 717113.2915 650741.5438" version="1.1" xmlns="http://www.w3.org/2000/svg" style="stroke-linecap:round;stroke-linejoin:round;fill:none">
      <MetaInfo xmlns="http://www.prognoz.ru"><Geo>
        <GeoItem X="${1000}" Y="${1000}" Latitude="${
        ref4.latLng[1]
      }" Longitude="${ref4.latLng[0]}"/>
      <GeoItem X="${0}" Y="${0}" Latitude="${ref2.latLng[1]}" Longitude="${
        ref2.latLng[0]
      }"/>
    </Geo></MetaInfo>
    <g transform="scale(1,-1)">
      <!-- Polyline -->
      <path d="M737344059.9452,9549222969.5983 L737238318.6279,9549301117.6062 L737287571.6456,9549360982.8531 L737325852.1936,9549387721.7267 L737371287.3214,9549417870.218 L737446065.1627,9549440501.8074 L737502572.6611,9549395684.1325 L737517917.7455,9549322137.5663 L737504364.6169,9549305553.8433 L737492544.7047,9549314788.7747 L737489181.8446,9549310672.0366 L737477312.7464,9549319512.6326 L737477312.7464,9549319512.6326 L737459236.8412,9549296506.3349 L737447393.3762,9549305112.4174 L737447393.3762,9549305112.4174 L737430468.7867,9549283867.217 L737418687.3499,9549292953.3728 L737399856.3148,9549268314.0293 L737392058.9863,9549259289.065 L737392058.9863,9549259289.065 L737380257.7906,9549268509.1696 L737349860.6068,9549229603.5427 L737338040.4455,9549238838.4649 L737333238.7992,9549232692.795 L737333238.7992,9549232692.795 L737344664.9503,9549223765.7074 L737344049.2888,9549222977.6967 L737331835.1173,9549232520.4534 L737331835.1172,9549232520.4534 L737337868.106,9549240242.1295 L737349688.2673,9549231007.2073 L737380085.4511,9549269912.8342 L737380085.4511,9549269912.8342 L737391924.578,9549260663.0945 L737391924.578,9549260663.0945 L737399080.0053,9549268945.0947 L737399080.0053,9549268945.0947 L737418502.7185,9549294358.6155 L737418502.7185,9549294358.6155 L737430299.9933,9549285260.245 L737447207.306,9549306483.7583 L737459045.5431,9549297881.4747 L737459045.5431,9549297881.4747 L737477128.5154,9549320896.7672 L737477128.5154,9549320896.7672 L737489012.3248,9549312045.2137 L737492389.2953,9549316179.2253 L737504209.3831,9549306944.1567 L737516841.3115,9549322400.6913 L737501667.6689,9549395125.5639 L737445853.8539,9549439393.059 L737445853.8539,9549439393.059 L737371717.7159,9549416955.6818 L737371717.7159,9549416955.6818 L737326415.0422,9549386895.0804 L737288256.7311,9549360241.5891 L737288256.7311,9549360241.5891 L737239760.1233,9549301295.7308 L737344654.2939,9549223773.8058 Z" style="fill:#000000;fill-rule:evenodd;"/>
    </g>
    </svg>`;
    }

    // ...processing SVG code as a string
    let importSvg = {};

    geoFromSVGXML(
      svg,
      (layer) => {
        importSvg = layer;
      },
      { area: mineLevelIdValue } // options // NOTE# - don't defined `{layers:true, tolerance:0.5}`
    );

    // scale
    // get common scaling from featureCollection
    const units = importSvg.units || "m"; // default to "m" metres 1:1
    let scale = 1;
    switch (units) {
      case "m":
        scale = 1;
        break;
      case "cm":
        scale = 100;
        break;
      case "mm":
        scale = 1000; // mm -> m
        break;
      case "in":
        scale = 39.3700787402; // in -> m
        break;
      default:
        break;
    }

    const transpose = false;

    const newGeo = transformGeoJsonUtmToPixels(
      importSvg,
      areaStatuses,
      scale,
      transpose
    );

    // add new regions to the form
    const currentRegions = this.props?.regions;

    let newRegions = [];

    if (!_isEmpty(newGeo)) {
      newGeo.features.forEach((feature) => {
        const {
          geometry: { coordinates },
        } = feature;

        const newRegion = coordinates[0].map((point) => {
          return { lat: point[0], lng: point[1] };
        });
        newRegions.push(buildNewRegion(newRegion));
      });

      const newRegionList = [...currentRegions, ...newRegions];
      this.props.change("regions", newRegionList);
    }
  };

  handleColorClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleColorClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorChange = (color) => {
    this.setState({ backgroundColor: color.rgb });
    this.props.change("backgroundColor", color.rgb);
    console.log("fillColor", color.rgb);
  };

  render() {
    const {
      header,
      handleSubmit,
      pristine,
      submitting,
      error,
      mineLevelIdValue,
      initialValues,
      regions,
      isActivity,
      strings,
    } = this.props;

    const { id, activeEvent, namedAreaSubType } = initialValues;

    const { backgroundColor } = this.state;

    // APP_TERMINOLOGY

    // APP_TERMINOLOGY
    let strNamedArea = strings?.["Polygon"];
    let strNamedAreas = strings?.["Polygons"];

    //console.log(`namedAreaSubType edit`, namedAreaSubType);

    switch (namedAreaSubType) {
      case "tagzone":
        strNamedArea = strings?.["Tag Zone"];
        strNamedAreas = strings?.["Tag Zones"];
        break;
      case "forced":
        strNamedArea = strings?.["Forced FireFly"];
        strNamedAreas = strings?.["Forced FireFlys"];
        break;
      case null:
      default:
        break;
    }

    const isActiveEvent = !_isEmpty(activeEvent);

    // #################################################

    // format of received event

    const test2 = {
      active: false,
      active_color: "red",
      active_state: "on",
      area: "DMLZ_Extraction",
      button_groups: { 0: -1 },
      id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1625725335065", // named area
      parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", // parent
      precanned: 0,
      priority: 2, //<--- points to button type
      timestamp: 1625728002755400,
    };

    // format for activate(operation)

    const testEvent = {
      active: true,
      active_color: "red",
      active_state: "on",
      alt: "Level 1",
      clickable: true,
      color: "red",
      default: true,
      group: 0,
      hint: "Level 0",
      icon: "icon-earthquake123",
      id: "c9a0be7c-c4b3-45f8-a78f-21d739c2e22b", // named area
      marker: "RoundMarker",
      named_area: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", // parent
      off_time: 0,
      on_time: 0,
      priority: 2, //<--- points to button type
      state: "on",
      title: "Seismic 1",
      train: 0,
      type: "SEISMIC1_EVENT",
    };
    // #################################################

    const stylesColorPicker = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
        },
        group: {
          alignItems: "center",
          display: "flex",
        },
        label: {
          fontWeight: "bold",
          paddingRight: "10px",
          fontSize: ".92857143em", //#NOTE #HACK - FFS why it is so hard to inherit font sizes??!
        },

        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    const isNewNamedArea = id === undefined;

    const areaNameChecks = isNewNamedArea
      ? [required, isNamedAreaNameOK]
      : [required];

    const isCollapse = this.state.collapse;

    const { errorMessage } = this.state;

    return (
      <Container>
        {/* {error && <div className="ui error message">{error}</div>} */}
        <Form onSubmit={handleSubmit(this.submitMyForm)}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column stretched width={isCollapse ? 1 : 6}>
                {isCollapse && (
                  <Segment>
                    <Grid>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Popup
                            content={strings?.["Expand Left Side"]}
                            trigger={
                              <Button
                                floated={"left"}
                                icon
                                onClick={this.expandCollapse}
                                size={"mini"}
                                color={"teal"}
                              >
                                <Icon name={"angle right"} size={"large"} />
                              </Button>
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
                {!isCollapse && (
                  <>
                    <Segment>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Header as="h2">{header}</Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Popup
                              content={strings?.["Collapse Left Side"]}
                              trigger={
                                <Button
                                  floated={"right"}
                                  icon
                                  onClick={this.expandCollapse}
                                  size={"mini"}
                                  color={"teal"}
                                >
                                  <Icon name={"angle left"} size={"large"} />
                                </Button>
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Field
                              name="mineLevelId"
                              component={ChooseMineLevelField}
                              label={strings?.["Area"]}
                              disabled={!isNewNamedArea}
                            />
                            <div hidden>
                              <Field
                                name="areaName"
                                label={strings?.["ID"]}
                                placeholder={`Unique ID value of the ${strNamedArea}`}
                                component={renderField}
                                disabled={true}
                              />
                            </div>
                            <Field
                              name="namedAreaParentName"
                              //label={`${strNamedArea} Name`}
                              label={strings?.formatString(
                                strings?.[`{polygon_type} Name`],
                                { polygon_type: strNamedArea }
                              )}
                              //placeholder={`Enter the name of the ${strNamedArea}`}
                              placeholder={strings?.formatString(
                                strings?.[
                                  `Enter the name of the {polygon_type}`
                                ],
                                { polygon_type: strNamedArea }
                              )}
                              component={renderField}
                              validate={[required, isNamedAreaNameOK]}
                              normalize={(value) =>
                                normalise(value, "normalise")
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment hidden={namedAreaSubType !== "tagzone"}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Header as="h3">{strings?.["Styling"]}</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Field
                              name="backgroundColor"
                              type="hidden"
                              component="input"
                            />
                            <Popup
                              // content={strings?.[`Set the color of the ${strNamedArea} region`]}
                              content={strings?.formatString(
                                strings?.[
                                  `Set the color of the {polygon_type} region`
                                ],
                                { polygon_type: strNamedArea }
                              )}
                              trigger={
                                <div>
                                  <div style={stylesColorPicker.group}>
                                    <label style={stylesColorPicker.label}>
                                      {strings?.["Background Color"]}
                                    </label>
                                    <div
                                      style={stylesColorPicker.swatch}
                                      onClick={this.handleColorClick}
                                    >
                                      <div style={stylesColorPicker.color} />
                                    </div>
                                  </div>
                                  {this.state.displayColorPicker ? (
                                    <div style={stylesColorPicker.popover}>
                                      <div
                                        style={stylesColorPicker.cover}
                                        onClick={this.handleColorClose}
                                      />
                                      <SketchPicker
                                        color={this.state.backgroundColor}
                                        onChange={this.handleColorChange}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    {error && <Message error header="Error" content={error} />}
                    {errorMessage && (
                      <Segment>
                        <Message icon color="red">
                          <Icon name="warning sign" />
                          <Message.Content>
                            <Message.Header>Error</Message.Header>
                            {errorMessage}
                          </Message.Content>
                        </Message>
                      </Segment>
                    )}
                    {isActiveEvent && (
                      <Segment>
                        <Message icon color="red">
                          <Icon name="warning sign" />
                          <Message.Content>
                            <Message.Header>
                              {strings?.["Polygon Active Event Message Header"]}
                            </Message.Header>
                            {strings?.["Polygon Active Event Message Content"]}
                          </Message.Content>
                        </Message>
                      </Segment>
                    )}
                    <Segment>
                      <Message icon color="blue">
                        <Icon name="warning" />
                        <Message.Content>
                          <Message.Header>
                            {strings?.["Polygon Info Message Header"]}
                          </Message.Header>
                          {strings?.["Polygon Info Message Content"]}
                        </Message.Content>
                      </Message>
                    </Segment>
                    {false && (
                      <Segment>
                        <Grid>
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <Header as="h3">Convert SVG Shape</Header>
                            </Grid.Column>
                            <Grid.Column>
                              <Popup
                                content={"Convert SVG to a Region"}
                                trigger={
                                  <Button
                                    type="button"
                                    floated={"right"}
                                    color="blue"
                                    onClick={(e) => this.importSvgSubmit(e)}
                                  >
                                    Convert
                                  </Button>
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Form>
                                <TextArea
                                  placeholder="Paste small <svg >...</svg> shapes here to convert them to regions."
                                  style={{ minHeight: 300 }}
                                  onChange={(e, { value }) =>
                                    this.importSvgCheck(value)
                                  }
                                />
                              </Form>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    )}
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={isCollapse ? 15 : 10}>
                <Segment>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        {!isNewNamedArea && (
                          <Grid.Row>
                            <DeleteButton
                              onClick={this.requestDelete}
                              id={id}
                              strings={strings}
                            />
                            {/* <Button
                            type="button"
                            onClick={this.requestDelete}
                            id={id}
                            color="red"
                          >
                            Delete
                          </Button> */}
                          </Grid.Row>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Button.Group floated="right">
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              disable={_isEmpty(regions) || isActivity}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={(e) => this.resetForm(e)}
                            >
                              {strings?.["Reset"]}
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              {strings?.["Cancel"]}
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <Segment>
                  <Header as="h2">{strings?.["Map"]}</Header>
                  <ContainerDimensions>
                    {({ width, height }) => (
                      <Field
                        name="regions"
                        component={CreateRegionsField}
                        props={{
                          mineLevelIdValue,
                          width,
                          height,
                          backgroundColor,
                        }}
                      />
                    )}
                  </ContainerDimensions>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        <DebugPagePropsMessages that={this} />
      </Container>
    );
  }
}

const selector = formValueSelector("namedAreaSimpleForm");

NamedAreaSimpleForm = reduxForm({
  form: "namedAreaSimpleForm",
  //enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(NamedAreaSimpleForm);

const mapStateToProps = (state, props) => {
  const mineLevelId = selector(state, "mineLevelId");

  const { namedAreaSubType } = props.initialValues;

  const allNamedAreaStatuses = getNamedAreaStatusesByAreaId(state, mineLevelId); // getAllNamedAreaStatuses(state);
  const uniqueNamedAreaParentIds = allNamedAreaStatuses
    .map((item) => {
      return {
        id: item.parent,
        parentName: item.parent_name,
        subType: item.sub_type,
      };
    }) // .parent
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.id === value.id && t.parentName === value.parentName
        )
    )
    .filter((item) => item.subType === namedAreaSubType);
  //  .filter((value, index, self) => self.indexOf(value) === index);
  //.map((item) => item.split(":")[1]);

  //console.log(`uniqueNamedAreaParentIds`, uniqueNamedAreaParentIds);

  const area = getAreaStatusesById(state, mineLevelId);
  const areaStatuses = getAreaStatuses(state);

  const diagonalRefCoords = !_isEmpty(area?.ref_coord)
    ? {
        ref1: {
          image: area.ref_coord[0].image_xy,
          latLng: area.ref_coord[0].utm,
        },
        ref2: {
          image: area.ref_coord[1].image_xy,
          latLng: area.ref_coord[1].utm,
        },
        ref3: {
          image: area.ref_coord[2].image_xy,
          latLng: area.ref_coord[2].utm,
        },
        ref4: {
          image: area.ref_coord[3].image_xy,
          latLng: area.ref_coord[3].utm,
        },
      }
    : { ref1: [], ref2: [], ref3: [], ref4: [] };

  const transformPixelsToUtm = area?.transformPixelsToUtm;
  const transformUtmToPixels = area?.transformUtmToPixels;

  const regions = selector(state, "regions");

  return {
    namedAreaParentName: selector(state, "namedAreaParentName"),
    mineLevelIdValue: mineLevelId,
    transformPixelsToUtm,
    transformUtmToPixels,
    uniqueNamedAreaParentIds,
    //
    diagonalRefCoords,
    areaStatuses,
    //
    regions,
    area,
    //
    isActivity: isMapMoving(state),

    onChange: (values, dispatch, props, previousValues) => {
      //console.log("onChange", values);
      if (values.mineLevelId !== previousValues.mineLevelId) {
        const {
          change,
          initialValues: { mineLevelId, regions },
        } = props;
        if (values.mineLevelId === mineLevelId) {
          dispatch(change("regions", regions));
        } else {
          dispatch(change("regions", []));
        }
      }
    },
  };
};

export default connect(mapStateToProps, null)(NamedAreaSimpleForm);
