import React from "react";
import { Form, Label, Popup, Dropdown } from "semantic-ui-react";
import "admin/form-field.css";

export const renderField = ({
  input,
  label,
  placeholder,
  disabled = false,
  type,
  meta: { touched, error, warning },
  className = "",
  style,
  //
  min,
  step,
  max,
}) => {
  return (
    <Form.Field style={style} error={Boolean(touched && error)}>
      <label>{label}</label>
      <Popup
        content={placeholder || label}
        trigger={
          <input
            {...input}
            placeholder={placeholder || label}
            type={type}
            disabled={disabled}
            className={className}
            // support for numeric range
            min={min}
            step={step}
            max={max}
          />
        }
      />
      {touched &&
        ((error && (
          <Label pointing color="red">
            {error}
          </Label>
        )) ||
          (warning && (
            <Label pointing warning>
              {warning}
            </Label>
          )))}
    </Form.Field>
  );
};

// see example
// https://codesandbox.io/s/9445n90wkw?file=/src/components/ProfileForm.js
// for render suggestions

// particular modification for toggle version
// see form-field.css
// for class to change label on select
export const renderCheckboxToggle = (field) => (
  <Form.Checkbox
    toggle
    checked={!!field.input.value}
    name={field.input.name}
    label={field.label}
    onChange={(e, { checked }) => field.input.onChange(checked)}
    disabled={field.disabled}
  />
);

export const renderCheckbox = (field) => (
  <Form.Checkbox
    checked={!!field.input.value}
    name={field.input.name}
    label={field.label}
    onChange={(e, { checked }) => field.input.onChange(checked)}
    disabled={field.disabled}
  />
);

export const renderRadio = (field) => (
  <Form.Radio
    checked={field.input.value === field.radioValue}
    label={field.label}
    name={field.input.name}
    onChange={(e, { checked }) => field.input.onChange(field.radioValue)}
    disabled={field.disabled}
  />
);

export const renderSelect_old = (field) => (
  <Form.Select
    inline={field.inline}
    label={field.label}
    name={field.input.name}
    onBlur={(e, { value }) => {
      //console.log("BLUR", value);
      field.input.onChange(value);
    }}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    value={field.input.value}
    disabled={field.disabled}

    // defaultValue={field.defaultValue}
    //
    // default value does not work on a redux form, instead pass via setting
    // the initialValue of the component
    // https://stackoverflow.com/questions/49267232/set-select-default-value-doesnt-work
    //
    // https://github.com/erikras/redux-form-material-ui#no-default-values
    //
    // No Default Values

    // Because of the strict "controlled component" nature of redux-form,
    // some of the Material UI functionality related to defaulting of values
    // has been disabled e.g. defaultValue, defaultDate, defaultTime,
    // defaultToggled, defaultChecked, etc.
    // If you need a field to be initialized to a certain state,
    // you should use the initialValues API of redux-form.
  />
);

export const renderSelect = ({
  input,
  inline,
  label,
  placeholder,
  disabled = false,
  type,
  meta: { touched, error, warning },
  className = "",
  style,
  options,
  multiple = false,
}) => (
  <Popup
    content={touched && error ? error : placeholder || label}
    // #NOTE - when popup is bottom-left custom.css style will make popup 'red'
    //
    position={touched && (error || warning) ? "bottom center" : "top left"}
    style={
      touched && (error || warning)
        ? { backgroundColor: "red", color: "white" }
        : null
    }
    //open={touched && (error || warning)}
    trigger={
      // <div style={style}>
      <Form.Select
        style={style}
        error={Boolean(touched && error)}
        inline={inline}
        label={label}
        multiple={multiple}
        name={input.name}
        onBlur={(e, { value }) => {
          //console.log("BLUR", value);
          input.onChange(value);
        }}
        onChange={(e, { value }) => input.onChange(value)}
        options={options}
        placeholder={placeholder || label}
        value={input.value}
        disabled={disabled}
        // defaultValue={field.defaultValue}
        //
        // default value does not work on a redux form, instead pass via setting
        // the initialValue of the component
        // https://stackoverflow.com/questions/49267232/set-select-default-value-doesnt-work
        //
        // https://github.com/erikras/redux-form-material-ui#no-default-values
        //
        // No Default Values

        // Because of the strict "controlled component" nature of redux-form,
        // some of the Material UI functionality related to defaulting of values
        // has been disabled e.g. defaultValue, defaultDate, defaultTime,
        // defaultToggled, defaultChecked, etc.
        // If you need a field to be initialized to a certain state,
        // you should use the initialValues API of redux-form.
      />
      // </div>
    }
  />
);

export const renderTextArea = (field) => (
  <Form.TextArea
    {...field.input}
    label={field.label}
    placeholder={field.placeholder}
  />
);

export const renderInput = ({
  input,
  label,
  placeholder,
  disabled = false,
  type,
  meta: { touched, error, warning },
  className = "",
  icon,
}) => (
  <Popup
    content={touched && error ? error : placeholder || label}
    // #NOTE - when popup is bottom-left custom.css style will make popup 'red'
    //
    position={touched && (error || warning) ? "bottom center" : "top left"}
    style={
      touched && (error || warning)
        ? { backgroundColor: "red", color: "white" }
        : null
    }
    trigger={
      <Form.Input
        {...input}
        error={Boolean(touched && error)}
        name={input.name}
        label={label}
        type={type}
        placeholder={placeholder || label}
        disabled={disabled}
        className={className}
        icon={icon}
      />
    }
  />
);
