import React from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import { getServerStatus } from "components/Settings/reducer";

const _SaveButton = ({
  submitting,
  pristine,
  disable,
  serverStatus,
  strings,
  size,
  ...props
}) => {
  // #NOTE -
  // disable - prop passed
  // disabled - local prop to set state of button

  const disabled =
    submitting || pristine || disable === true || serverStatus !== "OK";

  let positive = true;
  let text = strings?.["Save"];
  if (submitting) {
    text = strings?.["Submitting"];
  } else if (pristine) {
    positive = false;
    text = strings?.["Nothing changed"];
  }

  return (
    <Form.Button disabled={disabled} positive={positive} size={size}>
      {text}
    </Form.Button>
  );
};

const mapStateToProps = (state, props) => {
  return {
    serverStatus: getServerStatus(state),
  };
};

export const SaveButton = connect(mapStateToProps, null)(_SaveButton);

export function AlwaysSaveButton({ submitting, pristine, ...props }) {
  let text = "Save";
  if (submitting) {
    text = "Submitting";
  }

  return <Form.Button>{text}</Form.Button>;
}
