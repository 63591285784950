// Current options for /public/config.js

//isDevelopmentVersion: true,
//isExtendedNavMenu: false,
//checkForBrowserType: true,
//mqttBrokerAddress: "192.168.1.3",
//mqttWebSocketPort: "1884"

import { Component } from "react";
import { connect } from "react-redux";

import {
  addErrorFlashMessage,
  clearFlashMessages,
} from "FlashMessages/actions";

import * as is from "is_js";
import { strings } from "components/App/localisation";

// test if config.js is in public/ and loaded onto page
export const isConfigJs = () => {
  return is.object(window.config);
};

// test if network.js is in public/ and loaded onto page
export const isConfigNetworkJs = () => {
  return is.object(window.network);
};

export const isDevelopmentVersion = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isDevelopmentVersion")) {
      const { isDevelopmentVersion } = window.config;
      return isDevelopmentVersion;
    }
  } else {
    return true; // default to true - if can't find config file....problem!
  }
};

export const isDemoMode = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isDemoMode")) {
      const { isDemoMode } = window.config;
      return isDemoMode === true;
    }
  } else {
    return false;
  }
};

export const isDebugScreensize = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isDebugScreensize")) {
      const { isDebugScreensize } = window.config;
      return isDebugScreensize === true;
    }
  } else {
    return false;
  }
};

export const isMqttDebugToConsole = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isMqttDebugToConsole")) {
      const { isMqttDebugToConsole } = window.config;
      return isMqttDebugToConsole;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isShowLightControlSim = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isShowLightControlSim")) {
      const { isShowLightControlSim } = window.config;
      return isShowLightControlSim;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

//

// app responsive sizing
export const appResponsive = () => {
  let response = {
    mobile: 1350,
    tablet: 1350,
    laptop: 1351,
    desktop: 1351,
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("appResponsive")) {
      const { appResponsive } = window.config;
      return appResponsive;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const getMapDisplayOptions = () => {
  let response = {
    controllerLocations: false,
    controllerCables: false,
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("mapDisplayOptions")) {
      const { mapDisplayOptions } = window.config;
      return mapDisplayOptions;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const getMapMarkerDisplayOptions = () => {
  let response = {
    firefly: {
      showMac: false,
      showStatus: true,
      showUtm: false,
      showEventInfo: false,
      showForced: true,
    },
    controller: {},
    tagTracking: {},
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("mapMarkerDisplayOptions")) {
      const { mapMarkerDisplayOptions } = window.config;
      return mapMarkerDisplayOptions;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isDebugPagePropsMessages = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isDebugPagePropsMessages")) {
      const { isDebugPagePropsMessages } = window.config;
      return isDebugPagePropsMessages;
    }
  } else {
    return true; // default to true - if can't find config file....problem!
  }
};

export const isMqttNotApiPost = () => {
  let response = {
    isMqttNotApiPost: false,
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isMqttNotApiPost")) {
      const { isMqttNotApiPost } = window.config;
      return isMqttNotApiPost;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isExtendedNavMenu = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isExtendedNavMenu")) {
      const { isExtendedNavMenu } = window.config;

      return isExtendedNavMenu;
    }
  } else {
    return false; // default to false - if can't find config file....problem!
  }
};

export const checkForBrowserType = () => {
  if (isConfigJs()) {
    const { checkForBrowserType } = window.config;
    return checkForBrowserType;
  } else {
    return false; // default to false
  }
};

export const NetworkTimeout = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("networkTimeout")) {
      const { networkTimeout } = window.config;
      return networkTimeout * 1000;
    }
  } else {
    return 60 * 1000; // default to 60 seconds
  }
};

// railway_application
export const isBoomgateCrane = () => {
  if (isConfigJs()) {
    if (
      window.config.hasOwnProperty("isBoomgateCrane") &&
      window.config.hasOwnProperty("boomgateControllerIp") &&
      window.config.hasOwnProperty("boomgateColors") &&
      window.config.hasOwnProperty("templateAllAreasButtons_railway")
    ) {
      const { isBoomgateCrane } = window.config;
      return isBoomgateCrane;
    }
  } else {
    return false;
  }
};

export const BoomgateControllerIp = () => {
  let response = "+";
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("boomgateControllerIp")) {
      const { boomgateControllerIp } = window.config;
      return boomgateControllerIp;
    }
  }
  return response;
};

export const BoomgateColors = () => {
  let response = {
    gate: { up: "grey", down: "grey" },
    crane: { on: "grey", off: "grey" },
    alarm: { on: "grey", off: "grey" },
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("boomgateColors")) {
      const { boomgateColors } = window.config;
      return boomgateColors;
    }
  }
  return response;
};

export const mqttBroker = () => {
  if (isConfigNetworkJs()) {
    if (
      window.network.hasOwnProperty("mqttBrokerAddress") &&
      // #NOTE - do no insist on secondary address
      // window.network.hasOwnProperty("mqttBrokerAddressTWO") &&
      window.network.hasOwnProperty("mqttBrokerPort") &&
      window.network.hasOwnProperty("mqttBrokerProtocol")
    ) {
      const {
        mqttBrokerAddress: address,
        mqttBrokerAddressTWO: addressSecondary,
        mqttBrokerPort,
        mqttBrokerProtocol,
      } = window.network;

      const port =
        window.location.protocol === "https:"
          ? mqttBrokerPort.https
          : mqttBrokerPort.http;

      const protocol =
        window.location.protocol === "https:"
          ? mqttBrokerProtocol.https
          : mqttBrokerProtocol.http;

      return { address, addressSecondary, port, protocol };
    } else {
      return {
        address: undefined,
        addressSecondary: undefined,
        port: undefined,
        protocol: undefined,
      };
    }
  } else {
    return {
      address: undefined,
      addressSecondary: undefined,
      port: undefined,
      protocol: undefined,
    };
  }
};

//   faultFlash: { battery: true, network: true, firefly: true },
export const faultFlash = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("faultFlash")) {
      const { faultFlash } = window.config;
      const { battery, network, firefly, controller, statusChecks } =
        faultFlash;
      return {
        battery,
        network,
        firefly,
        controller,
        statusChecks,
      };
    }
  } else {
    return false; // default to false
  }
};

//   faultMessageBannerDisplay: { battery: true, network: true, firefly: true },
export const faultMessageBannerDisplay = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("faultMessageBannerDisplay")) {
      const { faultMessageBannerDisplay } = window.config;

      const {
        battery,
        network,
        firefly,
        controller,
        statusChecks,
        fireflySamePosition,
        fireflySamePositionCorrected,
        controllerLikelyReplacement,
        controllerLikelyReplacementCorrected,
        areaImageNotFound,
      } = faultMessageBannerDisplay;
      return {
        battery,
        network,
        firefly,
        controller,
        statusChecks,
        fireflySamePosition,
        fireflySamePositionCorrected,
        controllerLikelyReplacement,
        controllerLikelyReplacementCorrected,
        areaImageNotFound,
      };
    }
  } else {
    return false; // default to false
  }
};

//   faultMessageBannerDisplay: { battery: true, network: true, firefly: true },
export const faultMessageBannerText = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("faultMessageBannerText")) {
      const { faultMessageBannerText } = window.config;
      const {
        battery,
        network,
        firefly,
        controller,
        statusChecks,
        fireflySamePosition,
        fireflySamePositionCorrected,
        controllerLikelyReplacement,
        controllerLikelyReplacementCorrected,
        areaImageNotFound,
      } = faultMessageBannerText;
      return {
        battery,
        network,
        firefly,
        controller,
        statusChecks,
        fireflySamePosition,
        fireflySamePositionCorrected,
        controllerLikelyReplacement,
        controllerLikelyReplacementCorrected,
        areaImageNotFound,
      };
    }
  } else {
    return "no message assigned"; // default to false
  }
};

// railway_application
export const TemplateAllAreasButtons = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("templateAllAreasButtons")) {
      if (isBoomgateCrane()) {
        const { templateAllAreasButtons_railway } = window.config;
        return templateAllAreasButtons_railway;
      }
      const { templateAllAreasButtons } = window.config;
      return templateAllAreasButtons;
    }
  } else {
    return [];
  }
};

export const namedAreaIconOptions = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("namedAreaIconOptions")) {
      const { namedAreaIconOptions } = window.config;
      return namedAreaIconOptions;
    }
  } else {
    return [];
  }
};

//  railway_application
export const legend = () => {
  if (isConfigJs()) {
    if (isBoomgateCrane()) {
      if (
        window.config.hasOwnProperty("boomgateCraneIcons") &&
        window.config.hasOwnProperty("commonIcons")
      ) {
        const { boomgateCraneIcons, commonIcons } = window.config;
        return [...boomgateCraneIcons, ...commonIcons];
      }
    } else {
      if (
        window.config.hasOwnProperty("freeportIcons") &&
        window.config.hasOwnProperty("commonIcons")
      ) {
        const { freeportIcons, commonIcons } = window.config;
        return [...freeportIcons, ...commonIcons];
      }
    }
  } else {
    return [];
  }
};

export const legend_old = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("legend")) {
      const { legend } = window.config;
      return legend;
    }
  } else {
    return [];
  }
};

export const TemplateDefaultArea = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("defaultArea")) {
      const { defaultArea } = window.config;
      return defaultArea;
    }
  } else {
    return [];
  }
};

export const isAreaMapSimple = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isAreaMapSimple")) {
      const { isAreaMapSimple } = window.config;
      return isAreaMapSimple;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isShowBackupRestore = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isShowBackupRestore")) {
      const { isShowBackupRestore } = window.config;
      return isShowBackupRestore;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isShowExternalTriggerEvent = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isShowExternalTriggerEvent")) {
      const { isShowExternalTriggerEvent } = window.config;
      return isShowExternalTriggerEvent;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isShowScheduledEvent = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isShowScheduledEvent")) {
      const { isShowScheduledEvent } = window.config;
      return isShowScheduledEvent;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isShowEventLog = () => {
  let response = true;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isShowEventLog")) {
      const { isShowEventLog } = window.config;
      return isShowEventLog;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isAreaImageToState = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isAreaImageToState")) {
      const { isAreaImageToState } = window.config;
      return isAreaImageToState;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isDeleteFireflyNotCommissioned = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isDeleteFireflyNotCommissioned")) {
      const { isDeleteFireflyNotCommissioned } = window.config;
      return isDeleteFireflyNotCommissioned;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isDataCollectionDuringMarkerPopupOpen = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isDataCollectionDuringMarkerPopupOpen")) {
      const { isDataCollectionDuringMarkerPopupOpen } = window.config;
      return isDataCollectionDuringMarkerPopupOpen;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const mqttTopicOptions = () => {
  let response = [];
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("mqttTopicOptions")) {
      const { mqttTopicOptions } = window.config;
      return mqttTopicOptions;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

const triggerOptions = (strings) => [
  {
    key: 1, // i.e. bit 1 of the `settings.permission` flag bit register
    value: "isShowExternalTriggerEvent",
    text: strings?.["External Trigger"],
    icon: "chain",
  },
  {
    key: 2, // // i.e. bit 2 of the `settings.permission` flag bit register
    value: "isShowScheduledEvent",
    text: strings?.["Scheduled Event"],
    icon: "clock outline",
  },
  // ....next one?
  // {
  //   key: 3,
  //   value: "isShow...?????", //
  //   text: "?????? Event",
  //   icon: "clock outline",
  // },
];

export const eventTriggerOptions = (userSettings) => {
  let response = triggerOptions(strings);

  if (isConfigJs()) {
    if (window.config.hasOwnProperty("eventTriggerOptions")) {
      const { eventTriggerOptions } = window.config;

      response = eventTriggerOptions;

      // filter options based on value ...
      // - isShowExternalTriggerEvent
      // - isShowScheduledEvent

      if (
        !userSettings?.feature?.includes("showExternalTriggerEvent") &&
        !isShowExternalTriggerEvent()
      ) {
        response = response.filter(
          (option) => option.value !== "isShowExternalTriggerEvent"
        );
      }

      // console.log(
      //   "xxx isShowExternalTriggerEvent",
      //   isShowExternalTriggerEvent(),
      //   response
      // );

      if (
        !userSettings?.feature?.includes("showScheduledEvent") &&
        !isShowScheduledEvent()
      ) {
        response = response.filter(
          (option) => option.value !== "isShowScheduledEvent"
        );
      }

      return response;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

// SOP UI button layout

export const isCustomSOP = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isCustomSOP")) {
      const { isCustomSOP } = window.config;
      return isCustomSOP;
    }
  } else {
    return false;
  }
};

export const isShowEventButtons = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isShowEventButtons")) {
      const { isShowEventButtons } = window.config;
      return isShowEventButtons;
    }
  } else {
    return false;
  }
};

export const isUserRelay = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isUserRelay")) {
      const { isUserRelay } = window.config;
      return isUserRelay;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const isPolygonShowHide = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isPolygonShowHide")) {
      const { isPolygonShowHide } = window.config;
      return isPolygonShowHide;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

const colorOptions = (strings) => {
  return [
    {
      key: "green",
      text: strings?.["green"],
      value: "green",
      image: { src: "/images/picker-green.png" },
    },
    {
      key: "red",
      text: strings?.["red"],
      value: "red",
      image: { src: "/images/picker-red.png" },
    },
    {
      key: "blue",
      text: strings?.["blue"],
      value: "blue",
      image: { src: "/images/picker-blue.png" },
    },
    {
      key: "amber",
      text: strings?.["amber"],
      value: "amber",
      image: { src: "/images/picker-orange.png" },
    },
    {
      key: "white",
      text: strings?.["white"],
      value: "white",
      image: { src: "/images/picker-white.png" },
    },
    {
      key: "off",
      text: strings?.["off"],
      value: "off",
      image: { src: "/images/picker-black.png" },
    },
  ];
};

export const allowedLightColors = () => {
  let response = colorOptions(strings);
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedLightColors")) {
      const { allowedLightColors } = window.config;

      let newColorOptions = [];
      for (const key in allowedLightColors) {
        if (Object.hasOwnProperty.call(allowedLightColors, key)) {
          const isAllowedColor = allowedLightColors[key];
          if (isAllowedColor) {
            newColorOptions.push(
              colorOptions(strings).find((opt) => opt.key === key)
            );
          }
        }
      }

      return newColorOptions;
    }
  }
  return response;
};

const stateOptions = (strings) => {
  return [
    {
      key: "on",
      text: strings?.["on"],
      value: "on",
    },
    {
      key: "off",
      text: strings?.["off"],
      value: "off",
    },
    {
      key: "strobe",
      text: strings?.["strobe"],
      value: "strobe",
    },
    {
      key: "forward",
      text: strings?.["forward"],
      value: "forward",
    },
    {
      key: "backward",
      text: strings?.["backward"],
      value: "backward",
    },

    // #REVIEW
    // future option for precanned effects. i.e. setup of on and off time to simulate slow and fast effects
    // {
    //   key: "slow",
    //   text:  strings?.["slow"],
    //   value: "slow",
    // },
    // {
    //   key: "fast",
    //   text:  strings?.["fast"],
    //   value: "fast",
    // },
  ];
};

export const allowedLightStates = () => {
  let response = stateOptions(strings);
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedLightStates")) {
      const { allowedLightStates } = window.config;

      let newStateOptions = [];
      for (const key in allowedLightStates) {
        if (Object.hasOwnProperty.call(allowedLightStates, key)) {
          const isAllowedState = allowedLightStates[key];
          if (isAllowedState) {
            newStateOptions.push(
              stateOptions(strings).find((opt) => opt.key === key)
            );
          }
        }
      }

      return newStateOptions;
    }
  }
  return response;
};

const markerOptions = (strings) => {
  return [
    {
      key: "CrossMarker",
      text: strings?.["plus"],
      value: "CrossMarker",
      image: { src: "/images/cross-plus-green.png" },
    },
    {
      key: "Cross45Marker",
      text: strings?.["cross"],
      value: "Cross45Marker",
      image: { src: "/images/cross-blue.png" },
    },
    {
      key: "RoundMarker",
      text: strings?.["circle"],
      value: "RoundMarker",
      image: { src: "/images/circle-green.png" },
    },
    {
      key: "SquareMarker",
      text: strings?.["square"],
      value: "SquareMarker",
      image: { src: "/images/square-blue.png" },
    },
    {
      key: "DiamondMarker",
      text: strings?.["diamond"],
      value: "DiamondMarker",
      image: { src: "/images/diamond-red-orange.png" },
    },
    {
      key: "TravelwayMarker",
      text: strings?.["travelway"],
      value: "TravelwayMarker",
      image: { src: "/images/polygon-amber-purple.png" },
    },
    {
      key: "StarMarker",
      text: strings?.["star"],
      value: "StarMarker",
      image: { src: "/images/star-red-green.png" },
    },
    {
      key: "HourGlassMarker",
      text: strings?.["hourglass"],
      value: "HourGlassMarker",
      image: { src: "/images/hourglass-sienna-fuchsia.png" },
    },
    {
      key: "BowTieMarker",
      text: strings?.["bowtie"],
      value: "BowTieMarker",
      image: { src: "/images/bowtie-black-fuchsia.png" },
    },
    {
      key: "TriangleMarker",
      text: strings?.["triangle"],
      value: "TriangleMarker",
      image: { src: "/images/triangle-red-green.png" },
    },
    {
      key: "OffMarker",
      text: strings?.["off"],
      value: "OffMarker",
      image: { src: "/images/off.png" },
    },
    {
      key: "ForwardMarker",
      text: strings?.["forward"],
      value: "ForwardMarker",
      image: { src: "/images/forward-blue.png" },
    },
    {
      key: "BackwardMarker",
      text: strings?.["backward"],
      value: "BackwardMarker",
      image: { src: "/images/backward-blue.png" },
    },
  ];
};

export const allowedMapMarkers = () => {
  let response = markerOptions(strings);
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedMapMarkers")) {
      const { allowedMapMarkers } = window.config;

      let newMarkerOptions = [];
      for (const key in allowedMapMarkers) {
        if (Object.hasOwnProperty.call(allowedMapMarkers, key)) {
          const isAllowedMapMarkers = allowedMapMarkers[key];
          if (isAllowedMapMarkers) {
            newMarkerOptions.push(
              markerOptions(strings).find((opt) => opt.key === key)
            );
          }
        }
      }

      return newMarkerOptions;
    }
  }
  return response;
};

//# NOTE #TODO - get available languages from setup/sql query

// #NOTE - at the moment this is the list of languages which will be offered at the
// LOCALISATION list page.
//
// see - https://iotautomation.atlassian.net/wiki/spaces/FSSV/pages/1630240769/References

export const languageOptions = [
  {
    key: "en-US",
    text: "American English",
    regionCodeLanguage: "English - United States",
    value: "en-US",
  },
  {
    key: "es-419",
    text: "Español latinoamericano",
    regionCodeLanguage: "Spanish - Latin America/Caribbean ",
    value: "es-419",
  },
  {
    key: "hi-IN",
    text: "हिन्दी भाषा",
    regionCodeLanguage: "Hindi - India",
    value: "hi-IN",
  },
  {
    key: "it-IT",
    text: "Italiano",
    regionCodeLanguage: "Italian - Italy",
    value: "it-IT",
  },
  {
    key: "id-ID",
    text: "Bahasa Indonesia",
    regionCodeLanguage: "Indonesian - Indonesia",
    value: "id-ID",
  },
];

export const allowedLanguages = () => {
  let response = languageOptions;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedLanguages")) {
      const { allowedLanguages } = window.config;

      let newLanguageOptions = [];
      for (const key in allowedLanguages) {
        if (Object.hasOwnProperty.call(allowedLanguages, key)) {
          const isAllowedLanguages = allowedLanguages[key];
          if (isAllowedLanguages) {
            newLanguageOptions.push(
              languageOptions.find((opt) => opt.key === key)
            );
          }
        }
      }
      return newLanguageOptions;
    }
  }
  return response;
};

// https://iotautomation.atlassian.net/wiki/spaces/FSSV/pages/386367491/Roles+and+Permissions
// SUPER - administer other users (Admin)
// ADMIN - configure system (LIghtPlanner)
// USER - operate events  (ControlRoomOperator, Network Admin)
// GUEST - view area maps and light status

const roleOptions = (strings) => {
  return [
    {
      key: "super",
      text: strings?.["Super User - Administrator + administer users"],
      value: "super",
    },
    {
      key: "admin",
      text: strings?.[
        "Administrator - Operator + administer areas and devices"
      ],
      value: "admin",
    },
    {
      key: "user",
      text: strings?.["Operator - All general operations. Trigger events etc."],
      value: "user",
    },
    {
      key: "guest",
      text: strings?.["Guest - View area maps and light status"],
      value: "guest",
    },
  ];
};

export const allowedRoles = () => {
  let response = roleOptions(strings);
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedRoles")) {
      const { allowedRoles } = window.config;

      let newRoleOptions = [];
      for (const key in allowedRoles) {
        if (Object.hasOwnProperty.call(allowedRoles, key)) {
          const isAllowedRoles = allowedRoles[key];
          if (isAllowedRoles) {
            newRoleOptions.push(
              roleOptions(strings).find((opt) => opt.key === key)
            );
          }
        }
      }

      return newRoleOptions;
    }
  }
  return response;
};

const applicationOptions = (strings) => {
  return [
    {
      key: "smartLighting",
      text: strings?.["smartLighting"],
      value: "smartLighting",
    },
    {
      key: "tagTracking",
      text: strings?.["tagTracking"],
      value: "tagTracking",
    },
  ];
};

export const allowedApplications = () => {
  let response = applicationOptions(strings);
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedApplications")) {
      const { allowedApplications } = window.config;

      let newApplicationOptions = [];
      for (const key in allowedApplications) {
        if (Object.hasOwnProperty.call(allowedApplications, key)) {
          const isAllowedApplications = allowedApplications[key];
          if (isAllowedApplications) {
            newApplicationOptions.push(
              applicationOptions(strings).find((opt) => opt.key === key)
            );
          }
        }
      }

      return newApplicationOptions;
    }
  }
  return response;
};

// allowedFeatures: {
//   showDemoMode: true,
//   showDevelopmentVersion: true,
//   showExternalTriggerEvent: true,
//   showScheduledEvent: true,
// },

const featureOptions = (strings) => {
  return [
    {
      key: "showUserRelay",
      text: strings?.["showUserRelay"],
      value: "showUserRelay",
    },
    {
      key: "showExternalTriggerEvent",
      text: strings?.["showExternalTriggerEvent"],
      value: "showExternalTriggerEvent",
    },
    {
      key: "showScheduledEvent",
      text: strings?.["showScheduledEvent"],
      value: "showScheduledEvent",
    },
    {
      key: "showDemoMode",
      text: strings?.["showDemoMode"],
      value: "showDemoMode",
    },
    {
      key: "showDevelopmentVersion",
      text: strings?.["showDevelopmentVersion"],
      value: "showDevelopmentVersion",
    },
    {
      key: "showBackupRestore",
      text: strings?.["showBackupRestore"],
      value: "showBackupRestore",
    },
  ];
};

export const allowedFeatures = () => {
  let response = featureOptions(strings);
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("allowedFeatures")) {
      const { allowedFeatures } = window.config;

      let newAllowedFeatures = [];
      for (const key in allowedFeatures) {
        if (Object.hasOwnProperty.call(allowedFeatures, key)) {
          const isAllowedFeatures = allowedFeatures[key];
          if (isAllowedFeatures) {
            newAllowedFeatures.push(
              featureOptions(strings).find((opt) => opt.key === key)
            );
          }
        }
      }

      return newAllowedFeatures;
    }
  }
  return response;
};

export const lightingEventPriority = () => {
  let response = { emergency: 9900, levelWide: 9800, forced: 5000 };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("lightingEventPriority")) {
      const { lightingEventPriority } = window.config;
      return lightingEventPriority;
    }
  }
  return response;
};

export const markerSize = () => {
  let response = {
    marker: 2,
    tag: 2,
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("markerSize")) {
      const { markerSize } = window.config;
      return markerSize;
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const markerAcceptableEventLabels = () => {
  let response = ["M", "P", "F", "S", "T", "E", "C"];
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("markerAcceptableEventLabels")) {
      const { markerAcceptableEventLabels } = window.config;
      return markerAcceptableEventLabels;
    }
  }
  return response;
};

export const controlScreenEmergencyEventButtons = () => {
  let response = { show: false, label: "MINE WIDE" };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("controlScreenEmergencyEventButtons")) {
      const { controlScreenEmergencyEventButtons } = window.config;
      return controlScreenEmergencyEventButtons;
    }
    return response;
  }
  return response;
};

export const controlScreenSOPdata = () => {
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("controlScreenSOPdata")) {
      const { controlScreenSOPdata } = window.config;
      return controlScreenSOPdata;
    }
  } else {
    return [];
  }
};

export const deviceCheckTimeout = () => {
  let response = {
    checkPeriod: 2 * 60 * 1000, // 2 minutes
    period: 5 * 60 * 1000, //5 * 60 * 1000; // 5 minutes
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("deviceCheckTimeout")) {
      const { deviceCheckTimeout } = window.config;
      return deviceCheckTimeout;
    }
  }
  return response;
};

export const pollRates = () => {
  let response = {
    disable: false, // disables data collection via web worker
    workerEssentials: 1 * 1000, //  1 second
    worker: 10 * 1000, // 10 seconds
    server: 30 * 1000, // 30 seconds
    tagTracking: 30 * 1000, // 30 seconds
  };
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("pollRates")) {
      const { pollRates } = window.config;
      return pollRates;
    }
  }
  return response;
};

// Some application features are reliant on specific services running.
// Check if these are active services on the server
// #NOTE - the 'feature' property must match the features, application defined in featureOptions() and applicationOptions().
// See - src/components/ConfigJs/index.js > allowedFeatures() / allowedApplications()
//
const checkServiceOptions = [
  {
    application: "",
    feature: "showBackupRestore",
    service: "iot-firefly-server-backup-monitor",
  },
  { application: "tagTracking", feature: "", service: "iot-tag-tracker" },
];

export const checkService = () => {
  let response = checkServiceOptions;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("checkService")) {
      const { checkService } = window.config;
      return checkService;
    }
  }
  return response;
};

export const configHomepage = () => {
  let response = "lightingControl"; // default is to have the application display lighting control
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("configHomepage")) {
      const { configHomepage } = window.config;
      return configHomepage;
    }
  }
  return response;
};

export const configAppTitle = () => {
  let response = "FireFly Lighting System"; // default is to have the application display lighting control
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("configAppTitle")) {
      const { configAppTitle } = window.config;
      return configAppTitle;
    }
  }
  return response;
};

export const isLightingControl = () => {
  let response = true; // default is to have the application display lighting control
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isLightingControl")) {
      const { isLightingControl } = window.config;
      return isLightingControl;
    }
  }
  return response;
};

export const isTagTracking = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isTagTracking")) {
      const { isTagTracking } = window.config;
      return isTagTracking;
    }
  }
  return response;
};

export const isTagTrackingMap = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isTagTrackingMap")) {
      const { isTagTrackingMap } = window.config;
      return isTagTrackingMap;
    }
  }
  return response;
};

export const isTagTrackingOneUserPerTag = () => {
  let response = false;
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("isTagTrackingOneUserPerTag")) {
      const { isTagTrackingOneUserPerTag } = window.config;
      return isTagTrackingOneUserPerTag;
    }
  }
  return response;
};

export const tagTrackingPort = () => {
  let response = "8010";
  if (isConfigJs()) {
    if (window.config.hasOwnProperty("tagTrackingPort")) {
      const { tagTrackingPort } = window.config;
      return tagTrackingPort;
    }
  }
  return response;
};

export class CheckConfigJs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // loads header and message to display on control screen page
  displayErrors(errorType, extraMessage) {
    let header;
    let message;

    switch (errorType) {
      case "CONFIG_JS_PROBLEM":
        header = "Problem Loading Config File";
        message = "Check config.js file exists and is properly setup.";
        break;
      default:
        break;
    }
    //this.props.clearFlashMessages(); // clear off previous messages
    this.props.addErrorFlashMessage(header, message || "Unknown problem");
    console.log(header + " - " + message);
  }

  // not in use - yet?
  componentDidMount() {}
  componentDidUpdate() {}

  render() {
    // #REVIEW - // can't use this.isConfigJsOK because it has not loaded at this stage
    //
    //    if (!this.isConfigJsOK)) {
    if (!is.object(window.config)) {
      this.displayErrors("CONFIG_JS_PROBLEM", "");
    }

    return null;
  }
}

// placeholder for connect()
function mapStateToProps(state) {
  return {};
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    clearFlashMessages: () => {
      dispatch(clearFlashMessages());
    },
    addErrorFlashMessage: (error, extra) => {
      dispatch(addErrorFlashMessage(error, extra));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckConfigJs);
