import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Header,
  Grid,
  List,
  Segment,
  Message,
  Icon,
} from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

import { getFolderFileNamesListById } from "components/Settings/reducer";

class HelpFilesListAreas extends Component {
  render() {
    const { folderFilesList } = this.props;
    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">Help Files</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Message icon color="blue">
            <Icon name="info" />
            <Message.Content>
              <Message.Header>Help files</Message.Header>
              This area contains an assortment of help files and tips which have
              been prepared to assist you with Lighting Control, Controllers and
              the Firefly lighting control system in general. Click to open the
              files in a separate window.
            </Message.Content>
          </Message>
          <Segment>
            <List.List celled ordered>
              {folderFilesList?.map((file) => {
                return (
                  <List.Item>
                    <a
                      href={process.env.PUBLIC_URL + "/areas/" + file}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      {file}
                    </a>
                  </List.Item>
                );
              })}
            </List.List>
          </Segment>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    folderFilesList: getFolderFileNamesListById(state, "areas"),
  };
}

export default connect(mapStateToProps, null)(HelpFilesListAreas);
