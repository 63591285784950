import React, { useEffect, useRef, useState } from "react";
import { Map, ImageOverlay } from "react-leaflet";

import { simplify } from "utils/propsChildren";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import "leaflet.smoothwheelzoom";

// easy button for pan tool
// see - https://www.npmjs.com/package/leaflet-easybutton
// easybutton & leaflet-react: see - https://stackoverflow.com/questions/64125298/react-leaflet-dynamic-markers-cannot-read-property-addlayer
// https://codesandbox.io/s/react-leaflet-dynamic-markers-cannot-read-property-addlayer-dfjmb?file=/src/MapSearch.jsx:353-362

import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false

  i.e. return `false` to update
  */

  const shouldUpdate =
    JSON.stringify(simplify(prevProps.children)) ===
      JSON.stringify(simplify(nextProps.children)) &&
    nextProps.style.backgroundColor === prevProps.style.backgroundColor &&
    nextProps.imageInfo.src === prevProps.imageInfo.src;

  // if (!shouldUpdate) {
  //   console.log(`MapWithImage shouldUpdate.................`);
  // }

  return shouldUpdate;
}

// Note: About React.memo, Maps & Redux-form
// Redux-Form updates form props via Redux (as the name implies), this effectively updates the maps
// props too and causes a re-render. Re-rendering while dragging markers causes a 'snap' back to the last
// prop/state saved. This significantly impedes usabililty.
//
// https://stackoverflow.com/questions/59280319/react-memo-rerendering-with-the-same-props
//
// The solution below is to use React.memo wrapped around the map functional component
// to stop re-rendering of the component unless the image, or the marker pointers, have changed.
// Markers are passed as children.
//
// This requires a deep, flattened, comparison of the children passed to the component.
// If these are not flattened an object comparison will just suggest they're always
// being on each re-render making the comparison pointless.
//
// #REVIEW - move functions above to support utils?

export default React.memo(MapWithImage, areEqual);

function MapWithImage({ ...props }) {
  const _ENABLE_PAN_CONTROL = false;

  const {
    imageInfo,
    // HACKfix: Use zIndex to stop leaflet map from being above the Semantic-UI dropdown menus!
    //style = { height: "600px", width: "100%", position: "relative", zIndex: 0 },
    style,
    // style = {
    //   height: "60vh",
    //   width: "100%",
    //   position: "relative",
    //   zIndex: 0,
    //   backgroundColor: "white",
    // },
    center = [0, 0],
    padBounds = 0.4,
  } = props;

  const [map, setMap] = useState({});
  const mapRef = useRef();

  const [isMapView, setIsMapView] = useState({});

  //console.log("MapWithImage - imageInfo", imageInfo);
  const { minZoom, bounds, src } = imageInfo;

  //console.log("MapWithImage imageInfo", imageInfo);

  const maxBounds = L.latLngBounds(bounds).pad(padBounds);

  // support for map leaflet.contextmenu
  const showCoordinates = (e) => {
    alert(e.latlng);
  };

  const centerMap = (e) => {
    mapRef.current.leafletElement.panTo(e.latlng);
  };

  const fitMap = (e, bounds) => {
    mapRef.current.leafletElement.fitBounds(bounds);
  };

  const zoomIn = (e) => {
    mapRef.current.leafletElement.zoomIn();
  };

  const zoomOut = (e) => {
    mapRef.current.leafletElement.zoomOut();
  };

  useEffect(() => {
    //console.log("xxxx map mounted");

    if (mapRef && mapRef.current) {
      const map = mapRef.current.leafletElement;

      setMap(map); //hook to set map
      // sets to const -> 'mapRef.current.leafletElement'

      const minZoomCalced =
        Math.floor(Math.log2(map.getSize().x / bounds.getEast())) - 1;
      if (map.getMinZoom() !== minZoomCalced) {
        map.setMinZoom(minZoomCalced);
        map.setZoom(minZoomCalced);
      }

      // conditionally enable map movement/pan for leaflet map
      if (_ENABLE_PAN_CONTROL) {
        //----------------------------------------------------
        //
        // MAP MOVEMENT - DRAG ZOOM ETC
        //
        // see - https://gis.stackexchange.com/questions/54454/disable-leaflet-interaction-temporary
        //----------------------------------------------------

        const disableMapMovement = (map) => {
          map.dragging.disable();
          map.touchZoom.disable();
          map.doubleClickZoom.disable();
          //map.scrollWheelZoom.disable();
          map.smoothWheelZoom.disable();
          map.boxZoom.disable();
          map.keyboard.disable();
          if (map.tap) map.tap.disable();
        };

        const enableMapMovement = (map) => {
          map.dragging.enable();
          map.touchZoom.enable();
          map.doubleClickZoom.enable();
          //map.scrollWheelZoom.enable();
          map.smoothWheelZoom.enable();
          map.boxZoom.enable();
          map.keyboard.enable();
          if (map.tap) map.tap.enable();
        };

        // on startup disable map movement by user
        disableMapMovement(map);

        //----------------------------------------------------
        //
        // MAP MARKER ZOOM  - CONTROLS
        //
        //
        // Custom zoom controls
        //
        //----------------------------------------------------

        // listeners for disabling buttons
        map.on("zoomend", (e) => {
          const map = e.target,
            max = map.getMaxZoom(),
            min = map.getMinZoom(),
            current = map.getZoom();
          const isMapViewCurent = isMapView.current;
          if (current < max) {
            if (isMapViewCurent) {
              zoomInButton.enable();
            }
          }
          if (current >= max) {
            if (isMapViewCurent) {
              zoomInButton.disable();
            }
          }
          if (current > min) {
            if (isMapViewCurent) {
              zoomOutButton.enable();
            }
          }
          if (current <= min) {
            if (isMapViewCurent) {
              zoomOutButton.disable();
            }
          }
        });

        const moveMapViewZoomIn = `<i class="fas fa-plus fa-lg"></i>`;
        const moveMapViewZoomOut = `<i class="fas fa-minus fa-lg"></i>`;

        const zoomInButton = L.easyButton({
          states: [
            {
              stateName: "zoom-in-move-map-view",
              icon: moveMapViewZoomIn,
              title: "Zoom In",
              onClick: (control, map) => {
                map.setZoom(map.getZoom() + 1);
              },
            },
          ],
        });

        const zoomOutButton = L.easyButton({
          states: [
            {
              stateName: "zoom-out-move-map-view",
              icon: moveMapViewZoomOut,
              title: "Zoom Out",
              onClick: (control, map) => {
                map.setZoom(map.getZoom() - 1);
              },
            },
          ],
        });
        // const zoomBar = L.easyBar([zoomInButton, zoomOutButton]);
        // zoomBar.addTo(map, {
        //   position: "topleft",
        // });

        //----------------------------------------------------
        //
        // MAP VIEW MOVE - CONTROLS
        //
        //----------------------------------------------------

        const moveMapViewShow = `<i class="fas fa-search fa-lg"></i>`;
        const moveMapViewHide = `<i class="fas fa-search fa-lg" style="opacity:0.2"></i>`;

        const moveMapViewButton = L.easyButton({
          states: [
            {
              stateName: "show-move-map-view",
              icon: moveMapViewShow,
              title: "Pan and Zoom",
              onClick: (control, map) => {
                // show the zoom controls
                moveMapViewButton.disable();
                zoomOutButton.enable();
                zoomInButton.enable();
                cancelMapViewButton.enable();

                // enable map movement
                enableMapMovement(map);

                setIsMapView(true);
              },
            },
            {
              stateName: "hide-move-map-view",
              icon: moveMapViewHide,
              title: "Disable move view",
            },
          ],
        });

        const cancelMapViewButton = L.easyButton({
          states: [
            {
              stateName: "cancel-move-map-view",
              icon: "fa fa-times",
              title: "Disable Pan and Zoom",
              onClick: (control, map) => {
                // show the zoom controls
                moveMapViewButton.enable();
                zoomOutButton.disable();
                zoomInButton.disable();
                cancelMapViewButton.disable();

                // disable map movement
                disableMapMovement(map);

                setIsMapView(false);
              },
            },
          ],
        });

        let moveMapViewBar = L.easyBar(
          [moveMapViewButton, zoomInButton, zoomOutButton, cancelMapViewButton],
          { position: "topleft" }
        );
        moveMapViewBar.addTo(map);

        // startup hide - zoom buttons
        moveMapViewButton.enable();
        zoomOutButton.disable();
        zoomInButton.disable();
        cancelMapViewButton.disable();

        //----------------------------------------------------
        //
        // MAP MARKER REFRESH - CONTROLS
        //
        //----------------------------------------------------

        const refreshMarkerPositionsButton = L.easyButton({
          states: [
            {
              stateName: "reset-marker-positions",
              icon: "fa fa-redo",
              title: "Refresh Map",
              onClick: (e) => {
                map.fitBounds(bounds);
              },
            },
          ],
        });

        let refreshMapBar = L.easyBar([refreshMarkerPositionsButton], {
          position: "topleft",
        });
        refreshMapBar.addTo(map);
      }

      //console.log("xxxx map:", { map });
    }
  }, [map]);

  return (
    <Map
      style={style}
      ref={mapRef}
      crs={L.CRS.Simple} // not dynamic
      center={center}
      bounds={bounds} // sets initial viewport only
      maxBounds={maxBounds}
      minZoom={minZoom} // not dynamic
      attributionControl={false} // not dynamic
      key={`mapMinZoom-${minZoom}`} // include non-dynamic props to force react re-render
      //
      touchExtend={false}
      //
      contextmenu={true} // context menu plugin `npm i leaflet-contextmenu`
      contextmenuWidth={140}
      contextmenuItems={[
        // {
        //   text: "Show coordinates",
        //   //callback: showCoordinates,
        // },
        {
          text: "Center map here",
          callback: centerMap,
        },
        {
          text: "Show all",
          callback: (e) => fitMap(e, bounds),
        },
        "-",
        {
          text: "Zoom in",
          icon: process.env.PUBLIC_URL + "/images/zoom-in.png",
          callback: zoomIn,
        },
        {
          text: "Zoom out",
          icon: process.env.PUBLIC_URL + "/images/zoom-out.png",
          callback: zoomOut,
        },
      ]}
      // turn off default zoom control (replaced by easyButton)
      zoomControl={!_ENABLE_PAN_CONTROL}
      // scroll wheel zoom smoothing
      scrollWheelZoom={false} // disable original zoom function
      smoothWheelZoom={true} // enable smooth zoom
      smoothSensitivity={1.5} // zoom speed. default is 1
    >
      <ImageOverlay url={src} bounds={bounds} pane={"tilePane"} />

      {props.children}
    </Map>
  );
}

//export default MapWithImage;
