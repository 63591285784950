import Immutable from "seamless-immutable";
import toSlug from "utils/toSlug";
import { createSelector } from "reselect";

import L from "leaflet";

const initialState = Immutable({ byId: {}, order: [] });

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    // case "MINE_LEVELS_FETCH_SUCCEEDED":
    //   console.log(
    //     `MQTT_AREA_STATUSES_UPDATE MINE_LEVELS_FETCH_SUCCEEDED`,
    //     action.payload
    //   );

    //   return Immutable(action.payload);

    default:
      return state;
  }
}

export const getAllMineLevels = createSelector(
  (state) => state.mineLevels,
  (mineLevels) =>
    Immutable(mineLevels.order.map((id) => mineLevels.getIn(["byId", id])))
);

export const getMineLevelById = (state, levelId) => {
  return state.mineLevels.byId[levelId];
  // return getAllMineLevels(state).find(level => level.id === levelId)
};

export const getMineLevelById_new = (state, levelId) => {
  const result = state.webWorker.general.areaStatuses[levelId];
  return result;
};

export const getMineLevelByNameSlug = (state, slug) => {
  return getAllMineLevels(state).find((level) => toSlug(level.name) === slug);
};

export const getMineLevelBounds = createSelector(
  getMineLevelById,
  (mineLevel) => mineLevel.image.bounds
);

// #WIP #TODO
// this is used a lot - centralise!!!!!
var yx = L.latLng;
var xy = function (x, y) {
  if (L.Util.isArray(x)) {
    // When doing xy([x, y]);
    return yx(x[1], x[0]);
  }
  return yx(y, x); // When doing xy(x, y);
};

export const getMineLevelBounds_new = createSelector(
  getMineLevelById_new,
  (mineLevel) => {
    const { width, height } = mineLevel?.image_info || { width: 0, height: 0 };
    const img = [width, height];
    const bounds = L.latLngBounds([xy(0, 0), xy(img)]);
    return bounds;
  }
);

export const getMineLevelCentre = createSelector(
  getMineLevelBounds,
  (bounds) => {
    const lat = (bounds[0].lat + bounds[1].lat) / 2;
    const lng = (bounds[0].lng + bounds[1].lng) / 2;
    return { lat, lng };
  }
);

export const getMineLevelTemporaryCoordsProvider = (upsLatLng) => {
  //console.log("getMineLevelTemporaryCoordsProvider", upsLatLng);
  return createSelector(getMineLevelBounds_new, (bounds) => {
    //console.log("getMineLevelTemporaryCoordsProvider bounds", bounds);
    const b = bounds; //  L.latLngBounds(bounds);
    const latAbs = Math.abs(b.getNorth() - b.getSouth());
    const lngAbs = Math.abs(b.getEast() - b.getWest());
    const latDelta = latAbs / 200; // 50
    const lngDelta = lngAbs / 200; // 50

    const latRef = upsLatLng.lat !== undefined ? upsLatLng.lat : b.getNorth();
    const lngRef = upsLatLng.lng !== undefined ? upsLatLng.lng : b.getWest();
    const latStart = latRef - 1 * latDelta;
    const lngStart = lngRef + 1 * lngDelta;

    // console.log(
    //   "getMineLevelTemporaryCoordsProvider latStart,lngStart ",
    //   latStart,
    //   lngStart
    // );

    // const latStart = b.getNorth() - 10 * latDelta;
    // const lngStart = b.getWest() + 10 * lngDelta;

    return {
      ups: () => ({ lat: latStart, lng: lngStart }),
      firefly: (portNumber, position) => {
        portNumber = asNumber(portNumber);
        position = asNumber(position);
        return {
          lat: latStart + portNumber * latDelta,
          lng: lngStart + position * lngDelta,
        };
      },
    };
  });
};

export const makeTemporaryCoordsFactory = (state) => {
  // console.log("makeTemporaryCoordsFactory");
  // console.log(
  //   "state.webWorker.general.areaInfos",
  //   state.webWorker.general.areaInfos
  // );
  // console.log(
  //   "state.webWorker.general.areaInfos",
  //   state.webWorker.general.areaInfos.features.map(
  //     (feature) => feature.properties.id
  //   )
  // );

  const ZeroProvider = {
    ups: () => ({ lat: 0, lng: 0 }),
    firefly: () => ({ lat: 0, lng: 0 }),
  };

  // const providers = state.mineLevels.order.reduce((acc, id) => {
  //   acc[id] = getMineLevelTemporaryCoordsProvider(state, id);
  //   return acc;
  // }, {});

  const passUpsPosition = (upsLatLng) => {
    const areaArray = state.webWorker.general.areaInfos.features.map(
      (feature) => feature?.properties?.id
    );
    return areaArray.reduce((acc, id) => {
      //    return state.mineLevels.order.reduce((acc, id) => {
      acc[id] = getMineLevelTemporaryCoordsProvider(upsLatLng)(state, id);
      return acc;
    }, {});
  };

  return {
    ForMineLevelId: (id, upsLatLng) => {
      const providers = passUpsPosition(upsLatLng);
      return providers[id] || ZeroProvider;
    },
  };
};

function asNumber(n) {
  switch (typeof n) {
    case "string":
      return Number.parseFloat(n);
    default:
      return n;
  }
}
