// based on
// src/admin/firefly/FireflyLightStatus.js
// src/admin/firefly/FireflyStatusSummary.js
// #REVIEW - break up reports into smaller components
//
import React from "react";

import { connect } from "react-redux";
//import { getFireflyById } from "UPSPanelControllers/reducer";
import { getFireflyById } from "components/WebWorker/reducer";

import { Table, Button, Grid, Icon, Popup } from "semantic-ui-react";

import {
  formatDateShort,
  formatDateRelativeNow,
  parseDate,
  formatDuration,
} from "utils/format-date";

import { getEquivalentSemanticColorName } from "colorpalette";

function FireflyLightChangeStatus({
  id,
  error,
  color,
  ff,
  isNotResponding,
  lastCommand,
}) {
  if (error) {
    return <div>{error}</div>;
  }

  const { timestamp } = ff; // lastStatusReport
  if (!timestamp) {
    //lastStatusReport
    return <div>No status report available</div>;
  }

  //const { timeReceived, device } = lastStatusReport;
  const timeRxd = parseDate(timestamp);
  // let uptime = "???";
  // if (device) {
  //   let up = device.uptime || device.up_time || uptime;
  //   if (typeof up === "string") {
  //     up = Number.parseFloat(up);
  //   }
  //   if (up) {
  //     uptime = formatDuration(up);
  //   }
  // }

  // #REVIEW - disabled until can review need
  let responseIndicator = <Icon size="huge" color="green" name="check" />;
  if (isNotResponding) {
    responseIndicator = (
      <Popup
        trigger={
          <div>
            <Icon size="huge" color="red" name="warning" />
            <p>
              Has not processed
              <br />
              last command
            </p>
          </div>
        }
        flowing
        hoverable
      >
        <pre>{JSON.stringify(lastCommand, null, 2)}</pre>
      </Popup>
    );
  }

  // if there is a lastCommand compose a row to show the target color
  //
  let lastCommandColor;
  let transitionIndicatorIcon;
  let lastCommandRow;
  // #REVEW #TODO
  // work out a method to replace last command
  // for the moment force display of last info
  if (true) {
    // lastCommand
    const { plan: cmdColor } = color; // lastCommand.settings;
    lastCommandColor = (
      <Button color={getEquivalentSemanticColorName(cmdColor)}>
        {cmdColor}
      </Button>
    );
    //transitionIndicatorIcon = <Icon size="large" name="right arrow" />;
    transitionIndicatorIcon = <span>Requested Color</span>;
    lastCommandRow = (
      <Table.Row>
        <Table.Cell>{transitionIndicatorIcon}</Table.Cell>
        <Table.Cell>{lastCommandColor}</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <Table>
            <Table.Body>
              {/* <Table.Row>
                <Table.Cell>ID</Table.Cell>
                <Table.Cell>{id}</Table.Cell>
              </Table.Row> */}
              <Table.Row>
                <Table.Cell>Latest Report</Table.Cell>
                <Table.Cell singleLine>
                  {formatDateShort(timeRxd)} (
                  {formatDateRelativeNow(timeRxd, new Date())})
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Reported Color</Table.Cell>
                <Table.Cell>
                  <Button color={getEquivalentSemanticColorName(color.status)}>
                    {color.status}
                  </Button>
                </Table.Cell>
              </Table.Row>
              {lastCommandRow}
            </Table.Body>
          </Table>
        </Grid.Column>
        {/* <Grid.Column width={6} textAlign="center" verticalAlign="right">
          {responseIndicator}
        </Grid.Column> */}
      </Grid.Row>
    </Grid>
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const ff = getFireflyById(state, id);

  //console.log("FireflyLightChangeStatus", ff);

  if (!ff || !ff.timestamp) {
    // lastStatusReport -> timestamp
    return { error: "No light information (FireflyLightChangeStatus - #WIP)" };
  }

  let color = "unknown",
    brightness = 100,
    on_time = 0,
    off_time = 0,
    offset = 0,
    isNotResponding = false,
    lastCommand = null;

  if (ff && ff.timestamp) {
    // lastStatusReport
    ({
      color,
      brightness = 100,
      on_time = 0,
      off_time = 0,
      // offset = 0, // No longer used
    } = ff.light); // ff.lastStatusReport.light

    // compare last command and current status
    // originally compared all settings. Changed to just compare color

    //
    // if (
    //   ff.lastCommand &&
    //   hasDifferentColorSettings(
    //     ff.lastCommand.settings,
    //     ff.lastStatusReport.light
    //   )
    // ) {
    //   isNotResponding = true;
    //   lastCommand = ff.lastCommand;
    // }
  }

  return {
    id,
    ff,
    error: ff ? undefined : "No FireFly for id",
    color,
    brightness,
    on_time,
    off_time,
    offset,
    isNotResponding,
    lastCommand,
  };
}

// originally compared all settings. Changed to just compare color
function hasDifferentLightSettings(a, b) {
  return (
    a.color !== b.color ||
    a.brightness !== b.brightness ||
    a.on_time !== b.on_time ||
    a.off_time !== b.off_time ||
    a.offset !== b.offset
  );
}

function hasDifferentColorSettings(a, b) {
  return a.color !== b.color;
}

export default connect(mapStateToProps)(FireflyLightChangeStatus);
