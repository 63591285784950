// import { connect } from "react-redux";
// import { getAcks } from "components/WebWorker/reducer";

import * as Api from "api/apiAck"; // getAckToken

const isAckReceivedByToken = (token) => {
  return new Promise((resolve, reject) => {
    let acks = Api.getAckToken(); // get latest acks

    console.log("isAckReceived ... looking for token ", token);
    console.log(
      "isAckReceived ... in ",
      acks.length,
      " acks ",
      JSON.stringify(acks)
    );

    const indexInAcks = acks
      .map(function (a) {
        return a.token;
      })
      .indexOf(token);

    if (indexInAcks > -1) {
      // found ack
      console.log("isAckReceived ... found it!");
      resolve(indexInAcks);
    } else {
      console.log("isAckReceived ... nope, not here. Try again?");
      reject(indexInAcks); //new Error("ack not found")
    }
  });
};

function wait(n) {
  return new Promise((resolve) => setTimeout(resolve, n));
}

export const confirmTokenRxWithRetryAndTimeout = (token, retry) => {
  console.log("retry", retry);
  if (retry < 0) return Promise.reject(new Error("timeout"));

  return isAckReceivedByToken(token).catch(() =>
    wait(2000).then(() => confirmTokenRxWithRetryAndTimeout(token, retry - 1))
  );
};

// const mapStateToProps = (state, ownProps) => {
//   // ack mqtt messages
//   const acks = getAcks(state);
//   return {
//     acks,
//   };
// };

// connect(mapStateToProps, null)(confirmTokenRxWithRetryAndTimeout);
