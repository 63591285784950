import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Header, Grid, List, Segment } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

import { XTerm } from "xterm-for-react";

const Terminal = () => {
  const xtermRef = React.useRef(null);
  React.useEffect(() => {
    // You can call any method in XTerm.js by using 'xterm xtermRef.current.terminal.[What you want to call]
    xtermRef.current.terminal.writeln(
      "Hello, World! ... nothing to see here..."
    );
  }, []);

  return (
    // Create a new terminal and set it's ref.
    <XTerm ref={xtermRef} />
  );
};

class ToolsTerminal extends Component {
  render() {
    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">Terminal</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment>
            <Terminal />
          </Segment>
        </Container>
      </div>
    );
  }
}

export default ToolsTerminal;
