import Immutable from "seamless-immutable";

const initialState = Immutable({});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "SERVER_RUN_SCRIPT_SUCCEEDED":
      return newState;
    case "SERVER_RUN_SCRIPT__FAILED":
      return newState;

    case "RESET":
      console.log("RESET! - Settings");
      return initialState;

    default:
      return state;
  }
}
