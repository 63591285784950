import React, { Component } from "react";
import { connect } from "react-redux";

import myWorker from "./mqtt.worker";
import Heartbeat from "react-heartbeat";

import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
} from "FlashMessages/actions";

import { LogIt } from "components/Logger/actions";

import { removeMqttMessage, clearMqttMessages } from "./actions";
import { getAllMqttMessages } from "./reducer";

import { isConfigJs, mqttBroker, NetworkTimeout } from "components/ConfigJs";
import cuid from "cuid";

// ***************
//  NOTE THIS IS OUTSIDE OF THE Component
//
var promiseWorker = null; // initialize variable
var worker = null; // initialize variable
//
// ***************

class Worker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mqttConnected: false,
      mqttErrors: [],
      mqttMsg: [],
      mqttMessages: props.mqttMessages,
    };
  }

  setupWorker = (address, port, networkTimeout) => {
    // First check whether Web Workers are supported
    if (typeof Worker !== "undefined") {
      // Check whether Web Worker has been created. If not, create a new Web Worker
      if (promiseWorker == null) {
        var PromiseWorker = require("promise-worker");
        worker = new myWorker();
        promiseWorker = new PromiseWorker(worker);

        var msg = {
          address,
          port,
          networkTimeout,
          type: "SETUP_MQTT",
        };
        console.log(
          "MQTT - Sending start message to worker: " + JSON.stringify(msg)
        );
        promiseWorker
          .postMessage(msg)
          .then(function (response) {
            //console.log("zzz Worker response: " + response);
          })
          .catch(function (error) {
            console.log("Worker error: " + error);
            // this.displayErrors("WORKER_SETUP_ERROR", error); // <-------------- #REVIEW - displayErrors is not accessible from here!? why. Fix here and elsewhere
          });
      }
    } else {
      // Web workers are not supported by your browser
      // <-------------- #REVIEW - displayErrors is not accessible from here!? why. Fix here and elsewhere
      this.displayErrors(
        "WORKER_SETUP_ERROR",
        "Sorry, your browser does not support Web Workers ..."
      );
      console.log("zzz Sorry, your browser does not support Web Workers ...");
    }
  };

  // pre-process the workerResponse.
  // Split the errors and pass them on for display
  //
  prepErrors = (workerResponse, errorCase) => {
    //console.log("zzz workerResponse - errorCase", workerResponse, errorCase);
    // could be more than one error accumulated. Thus error'S'.
    const { mqttErrors } = this.state;
    // push the spread array
    mqttErrors.push(...workerResponse);
    // save it
    // console.log("zzz check 4", Date.now());

    this.setState({ mqttErrors: mqttErrors }, function () {
      // console.log("zzz check 5", Date.now());

      // log it. Could be multiple errors........
      mqttErrors.forEach((error) => this.displayErrors(errorCase, error));
    });

    // clear the errors after they have been displayed
    // no reason to keep errors as we poll for updated status
    this.setState({ mqttErrors: [] });
  };

  // loads header and message to display on control screen page
  // depending on significance of the error
  // All cases go to the Browser Logger page
  //
  displayErrors(errorType, extraMessage) {
    let header;
    let message;
    let displayOnPageHeader = false;
    let color;

    let id = cuid();

    // console.log("check 6", Date.now());

    switch (errorType) {
      case "CONFIG_JSON_ERROR":
        header = "MQTT Setup Error";
        message = "Problem loading ./config.json";
        color = "red";
        displayOnPageHeader = true;
        break;
      case "WEBSOCKET_CONNECTION_ERROR":
        header = "MQTT Setup Issue";
        message =
          "Could not establish websocket connection to " +
          extraMessage +
          ". Check the network connections and refresh the browser page.";
        color = "red";
        displayOnPageHeader = true;
        break;
      case "WORKER_SETUP_ERROR":
        header = "MQTT Web Worker Setup Issue";
        message = "Could not setup worker. " + extraMessage;
        color = "red";
        displayOnPageHeader = true;
        break;
      case "MQTT_WORKER":
        header = "MQTT Web Worker Issue";
        message = extraMessage;

        displayOnPageHeader = false;
        break;
      case "NETWORK_RECONNECTED":
        header = "Network Reconnected";
        message = "The computer network has been reconnected to the server.";

        // only clear 'NETWORK_DISCONNECTED' message when connection restored
        this.props.removeFlashMessage("NETWORK_DISCONNECTED");
        displayOnPageHeader = false;
        break;
      case "NETWORK_DISCONNECTED":
        header = "Network Disconnected";
        message = "The computer has been disconnected from the server network.";
        color = "red";
        displayOnPageHeader = true;

        // include errorType in id so can find and remove later (see above)
        id = id + "-NETWORK_DISCONNECTED";

        break;
      default:
        break;
    }
    if (displayOnPageHeader) {
      this.props.addWarningFlashMessageIdColor(
        id,
        color,
        header,
        message || "Unknown problem"
      );
    }
    this.props.LogIt(`${errorType} - ${message}`);
    //console.log("displayErrors - " + header + "-" + message);
  }

  componentDidMount() {
    //console.log("zzz mounted src/pages/Test/TestMQTT/testMqttWorker.js");
    // function to connect to websocket
    // used to establish a test connection before proceed with mqtt_websocket
    // allows reporting of conditions of the websocket
    const connectWS = (address, port) => {
      return new Promise(function (resolve, reject) {
        let server = new WebSocket("wss://" + address + ":" + port + "/");
        server.onopen = function () {
          resolve(server);
        };
        server.onerror = function (err) {
          reject(err);
        };
      });
    };

    // check if the config.json file and broker object exists
    if (isConfigJs() && mqttBroker() && NetworkTimeout()) {
      const { address, port } = mqttBroker();
      const networkTimeout = NetworkTimeout();
      // console.log(
      //   "zzz MQTT - Connect to mqttBroker ",
      //   JSON.stringify(mqttBroker())
      // );

      // (2) ...........
      // check if can connect to websocket
      connectWS(address, port)
        .then((server) => {
          server.close(); // OK!, so close the test connection
          console.log("MQTT - WSS connection checked OK!");

          // (3) ...........
          // and setup MQTT when component mounts and has found IP and PORT
          this.setupWorker(address, port, networkTimeout);

          // old.....#REVIEW ............
          // // (4) ...........
          // // then a heartbeat manages the worker, collecting msgs, checking errors etc.
          // .then(this.heartbeat)
          // .catch(error => {
          //   this.displayErrors("WORKER_SETUP_ERROR");
          // });
        })
        .catch((error) => {
          this.displayErrors(
            "WEBSOCKET_CONNECTION_ERROR",
            "wss://" + address + ":" + port + "/"
          );
        });
    } else {
      this.displayErrors("CONFIG_JSON_ERROR");
    }
  }

  componentDidUpdate({ mqttMessages }) {
    console.log(
      "zzz componentDidUpdate - /home/hugh/iot_server_web_v2/src/pages/Test/TestMQTT/testMqttWorker.js"
    );
    if (this.props.mqttMessages !== mqttMessages) {
      console.log("zzz this.props.mqttMessages", mqttMessages);

      mqttMessages.forEach((mqttMessage, index, arr) => {
        this.askWebWorker("sendMqttMsg", mqttMessage);
      });

      this.props.clearMqttMessages();
    }
  }

  // ask the web worker for information
  askWebWorker = (action, message) => {
    if (promiseWorker !== null) {
      let msg; // data requested by message
      switch (action) {
        case "getMqttMsg":
          msg = { type: "GET_MQTT_MSG" };
          break;
        case "sendMqttMsg":
          console.log("zzz sendMqttMsg--->", JSON.stringify(message));
          msg = { type: "SEND_MQTT_MSG", payload: message };
          break;
        case "getConnectStatus":
          msg = { type: "GET_CONNECT_STATUS" };
          break;
        case "getErrors":
          msg = { type: "GET_ERRORS" };
          break;
        // not used ATM
        case "clearErrors":
          msg = { type: "CLEAR_ERRORS" };
          break;
        default:
          break;
      }

      // post message type and process callback from web worker
      promiseWorker
        .postMessage(msg)
        .then((response) => {
          const workerResponse = JSON.parse(response.toString()); // <---{object}
          //console.log("zzz workerResponse", workerResponse);
        })
        .catch((error) => {
          // #REVIEW - need to catch errors.........?!
          console.log("zzz Got error fetchWebWorker: " + error);
        });
    }
  };

  // called by the heartbeat to periodically check on the state of the worker
  pokeTheWorker = () => {
    // console.log(
    //   "zzz pokeTheWorker this.state.mqttMessages",
    //   this.state.mqttMessages
    // );
    this.askWebWorker("getMqttMsg", "");
    this.askWebWorker("getConnectStatus", "");
  };

  render() {
    return (
      <Heartbeat
        heartbeatFunction={this.pokeTheWorker}
        heartbeatInterval={5000}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeFlashMessage: (id) => {
    dispatch(removeFlashMessage(id));
  },
  addWarningFlashMessageIdColor: (id, color, header, message) => {
    dispatch(addWarningFlashMessageIdColor(id, color, header, message));
  },
  LogIt: (message) => {
    dispatch(LogIt(message));
  },
  clearMqttMessages: () => {
    dispatch(clearMqttMessages());
  },
  removeMqttMessage: (id) => {
    dispatch(removeMqttMessage(id));
  },
});

const mapStateToProps = (state) => {
  const mqttMessages = getAllMqttMessages(state);

  return {
    mqttMessages,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Worker);
