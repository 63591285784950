import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Icon,
  Popup,
  Label,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { timeConverter } from "../../utils/format-time";
import { formatDate } from "utils/format-date";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";

import { ClearLog } from "components/Logger/actions";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { withComponentStateCache } from "react-component-state-cache";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

import { mqttTopicOptions as _mqttTopicOptions } from "components/ConfigJs";

import { getMqttBroker } from "components/Settings/reducer";

import Paho from "paho-mqtt"; // npm install paho-mqtt --save

// XLSL 'react-export-excel' IMPLEMENTATION
// click button to download report
// uses https://www.npmjs.com/package/react-export-excel
// see ex - https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/with_custom_download_element.md
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends React.Component {
  render() {
    const { data } = this.props;
    const filename = `mqtt_messages_report_${formatDate(
      new Date(Date.now()),
      "dd-MMM-yy-HH-mm-ss"
    )}`;

    return (
      <ExcelFile
        filename={filename}
        element={
          <Popup
            content="Download XLSL logged report"
            trigger={
              <Button
                icon
                size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              >
                <Icon name="download" />
              </Button>
            }
          />
        }
      >
        <ExcelSheet data={data} name="Name">
          <ExcelColumn label="Log Time" value="time" />
          <ExcelColumn label="Topic" value="topic" />
          <ExcelColumn label="Message" value="message" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

// var network = {
//   //
//   protocol: "ws",
//   address: "192.168.1.99",
//   port: "1885",
//   //
//   subscribe: "firefly/+/lightingstatus", //"ups/+/status",
//   consoleTopic: true,
//   consoleMessage: true,
// };

// const {
//   rejectUnauthorized,
//   protocol,
//   address,
//   port,
//   subscribe,
//   consoleTopic,
//   consoleMessage,
// } = network;

// const options = {
//   port: port || "1884",
//   host: address || "192.168.1.99",
//   protocol: protocol || "wss",
// };

// const topic = subscribe || "firefly/+/lightingstatus";

// Create a client instance
// var client = new Paho.Client(options.host, Number(options.port), "clientId");
// secure websocket connection
// https://pi3g.com/2019/05/17/correct-way-to-instantiate-paho-client-in-javascript-for-wss-secure-websocket/

var toolsMqttListClient;

const connectWS = (protocol, address, port) => {
  return new Promise(function (resolve, reject) {
    let server = new WebSocket(`${protocol}://${address}:${port}/`);
    server.addEventListener("error", function (event) {
      console.log("MQTT List - DEBUG: WebSocket error:", event);
    });
    server.onopen = function () {
      console.log(`MQTT List - connectWS onopen `, server);
      resolve(server);
    };
    server.onerror = function (err) {
      console.log(`MQTT List - connectWS onerror `, err);

      reject(err);
    };
  });
};

const mqttTopicOptions = _mqttTopicOptions();

const messageTxt = (msg) =>
  JSON.stringify(msg, null, 2).replace(/\\n/g, "\n").replace(/\\"/g, '"');

class ToolsMqttList extends Component {
  constructor(props) {
    super(props);
    const downloadData = [];
    this.state = {
      downloadData,
      // initialise data list sort columns
      column: null,
      data: [],
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        time: "",
        message: "",
        topic: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,

      // mqtt
      topic: "firefly/+/lightingstatus",
      isClientConnected: false,
      upsFFCount: {},
      topicSelect: 1,
    };
  }

  // called when a message arrives
  onMessageArrived = (message) => {
    //that.setState({ message: message.payloadString });
    // that.setState({ message: message.topic });

    const timestamp = new Date().getTime();

    let newMessage = [...(this.state?.data || [])]; // if undefined return empty array

    newMessage.unshift({
      topic: messageTxt(message.topic),
      message: messageTxt(message.payloadString),
      time: timestamp > 0 ? timeConverter(timestamp) : "-",
    });

    this.setState({ data: newMessage });
  };

  unSubscribe = (topic) => {
    console.log("MQTT List - unsubscribe to ", topic);

    toolsMqttListClient.unsubscribe(topic, function (err) {
      if (err) {
        console.log(
          "MQTT - Error - could not unsubscribe " +
            topic +
            ": " +
            err.toString()
        );
      }
    });
  };

  // called when subscribed to topic
  onSubscribe = () => {
    console.log("MQTT List - onSubscribe!");
    this.setState({ isClientConnected: true });
  };

  // called when the client loses its connection
  onConnectionLost = (responseObject) => {
    this.setState({ isClientConnected: false });
    if (responseObject.errorCode !== 0) {
      console.log(
        "MQTT List - onConnectionLost:" + responseObject.errorMessage
      );
    }
  };

  // called when the client connects
  onConnect = () => {
    // Once a connection has been made, make a subscription and send a message.
    console.log("MQTT List - onConnect subscribe to topic ", this.state.topic);

    toolsMqttListClient.subscribe(this.state.topic, {
      onSuccess: this.onSubscribe,
    });
    //var message = new Paho.Message("Hello");
    //message.destinationName = "World";
    //client.send(message);
  };

  // _OLD
  //
  _setupMQTT = () => {
    let that = this;

    // set callback handlers
    toolsMqttListClient.onConnectionLost = onConnectionLost;
    toolsMqttListClient.onMessageArrived = onMessageArrived;

    // connect the client
    toolsMqttListClient.connect({ onSuccess: onConnect });

    // called when the client connects
    function onConnect() {
      // Once a connection has been made, make a subscription and send a message.
      console.log("Connect to topic ", that.state.topic);
      toolsMqttListClient.subscribe(that.state.topic, {
        onSuccess: onSubscribe,
      });
      //var message = new Paho.Message("Hello");
      //message.destinationName = "World";
      //client.send(message);
    }

    // called when subscribed to topic
    function onSubscribe() {
      console.log("onSubscribe");
      that.setState({ isClientConnected: true });
    }
    // called when the client loses its connection
    function onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    }

    // called when a message arrives
    function onMessageArrived(message) {
      //that.setState({ message: message.payloadString });
      // that.setState({ message: message.topic });

      const timestamp = new Date().getTime();

      let newMessage = that.state.data;

      newMessage.unshift({
        topic: messageTxt(message.topic),
        message: messageTxt(message.payloadString),
        time: timestamp > 0 ? timeConverter(timestamp) : "-",
      });

      that.setState({ data: newMessage });
    }
  };

  componentDidMount() {
    const { fetchedMqttBroker } = this.props;

    const { address, port, protocol } = fetchedMqttBroker;

    const filterInput = this.props.componentstate.get(
      "filterInput",
      "mqttMessageList"
    );

    if (!_isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });
    }

    console.log(`MQTT List - connect to ${protocol}://${address}:${port}/mqtt`);

    // setup paho client
    const clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;

    toolsMqttListClient = new Paho.Client(
      `${protocol}://${address}:${port}/mqtt`,
      clientId
    );

    // load topic from the options list
    const newTopic = mqttTopicOptions.find(
      (option) => option.value === this.state.topicSelect
    )?.text;

    this.setState({ topic: newTopic });

    // # DEBUG TESTS - CONNECT MQTT WITHOUT TESTING connectWS
    if (false) {
      // set callback handlers
      toolsMqttListClient.onConnectionLost = this.onConnectionLost;
      toolsMqttListClient.onMessageArrived = this.onMessageArrived;

      // connect the client
      toolsMqttListClient.connect({ onSuccess: this.onConnect });
    }

    // check WS connection and connect
    true &&
      connectWS(protocol, address, port)
        .then((server) => {
          server.close(); // OK!, so close the test connection
          console.log("MQTT List - WSS connection checked OK!");

          //_this.setupMQTT();

          // set callback handlers
          toolsMqttListClient.onConnectionLost = this.onConnectionLost;
          toolsMqttListClient.onMessageArrived = this.onMessageArrived;

          // connect the client
          toolsMqttListClient.connect({ onSuccess: this.onConnect });
        })
        .catch((error) => {
          console.log("connectWS error", error);
        });
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "mqttMessageList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });

    if (this.state.isClientConnected) {
      this.unSubscribe("#/#/#"); // may not be necessary
      toolsMqttListClient.disconnect();
    }
  }

  // #TODO - Fix filtering.
  // The problem is that filtering is destructive. It filters the State data and re-writes it.
  // When data comes from Props this is not a problem as we just get Props again,
  // however on this page the MQTT data is rx directly to state.
  // Consequently when the data after the data is filtered the original data no longer exists.
  //

  // componentDidUpdate(prevProps, prevState) {
  //   const { data, filterInput: match } = this.state;

  //   if (
  //     JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
  //     JSON.stringify(data) !== JSON.stringify(prevState.data)
  //   ) {
  //     let newData = [...this.props.data]; // original data

  //     newData = newData.filter(function (item) {
  //       let checkMatch = true; // assume all included as default "" is always included
  //       for (var key in match) {
  //         checkMatch =
  //           checkMatch &&
  //           item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
  //       }
  //       return checkMatch;
  //     });

  //     this.setState({
  //       data: newData,
  //     });

  //     // restore sort
  //     const { column, direction } = this.state;

  //     // #REVIEW - decide if need a default sort column
  //     // column is initially undefined. i.e. no default sort column
  //     if (column) {
  //       newData = newData.slice().sort((a, b) =>
  //         a[column].localeCompare(b[column], "en", {
  //           numeric: true,
  //         })
  //       );
  //     }

  //     // newData = _.sortBy(newData, [column]);

  //     if (direction === "descending") {
  //       newData = newData.reverse();
  //     }
  //     this.setState({
  //       data: newData,
  //     });
  //   }
  // }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  onClickStart = () => {
    // stop current subscription
    this.unSubscribe(this.state.topic);
    // make selection the new topic
    const newTopic = mqttTopicOptions.find(
      (option) => option.value === this.state.topicSelect
    )?.text;
    // connect to the new topic
    this.setState({ topic: newTopic }, () => {
      this.onConnect();
    });
  };

  onClickClear = () => {
    this.setState({ data: [] });
  };

  onClickStop = () => {
    this.unSubscribe(this.state.topic);
  };

  handleDropdownMqttTopic = (e, data) => {
    console.log("select event", data.value);
    this.setState({ topicSelect: data.value });
  };

  render() {
    const includeTitles = this.props.includeTitles === "true";
    const title = includeTitles ? (
      <Header as="h1">MQTT Messages</Header>
    ) : (
      <div></div>
    );

    const { isLoading, error } = this.props;
    const { column, data, direction, filterInput } = this.state;

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "loglist10" },
              { value: 20, text: "20", key: "loglist20" },
              { value: 40, text: "40", key: "loglist40" },
              { value: 60, text: "60", key: "loglist60" },
              { value: data.length, text: "all", key: "loglistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{title}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment textAlign="left" style={segmentStyle}>
            <div>
              <span style={{ marginRight: "8px" }}>
                {/* <Download data={data} /> */}
              </span>
            </div>
            {/* label and button *will not* go to same height as depends on font sizes. 
            Fudge by vertical centering the button and label in same div. */}
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <Dropdown
                selection
                options={mqttTopicOptions}
                style={{ margin: "5px" }}
                defaultValue={this.state.topicSelect}
                onChange={this.handleDropdownMqttTopic}
              />
              <Popup
                content="Click to start data collection."
                trigger={
                  <Button
                    size="large"
                    color="green"
                    onClick={this.onClickStart}
                  >
                    Start
                  </Button>
                }
              />
              <Popup
                content="Total Logged. Click to clear."
                trigger={
                  <Button
                    size="large"
                    color="orange"
                    onClick={this.onClickClear}
                  >
                    {itemsCount}
                  </Button>
                }
              />
              <Popup
                content="Click to stop data collection."
                trigger={
                  <Button size="large" color="red" onClick={this.onClickStop}>
                    Stop
                  </Button>
                }
              />
            </div>
          </Segment>

          <Table sortable celled striped>
            <Table.Header>
              {/* #TODO - Fix filtering. See notes above */}
              {/* <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    icon="search"
                    placeholder="Filter..."
                    name="time"
                    onChange={this.handleFilterChange}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    icon="search"
                    placeholder="Filter..."
                    name="topic"
                    onChange={this.handleFilterChange}
                    value={filterInput?.topic}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    icon="search"
                    placeholder="Filter..."
                    name="message"
                    onChange={this.handleFilterChange}
                    value={filterInput?.message}
                  />
                </Table.Cell>
              </Table.Row> */}
              <Table.Row>
                <Table.HeaderCell
                  width={3}
                  sorted={column === "time" ? direction : null}
                  onClick={this.handleSort("time")}
                >
                  Time
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "topic" ? direction : null}
                  onClick={this.handleSort("topic")}
                >
                  Topic
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "message" ? direction : null}
                  onClick={this.handleSort("message")}
                >
                  Message
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(viewablesPage, ({ time, topic, message }) => (
                <Table.Row>
                  <Table.Cell>{time}</Table.Cell>
                  <Table.Cell>{topic}</Table.Cell>
                  <Table.Cell>{message}</Table.Cell>
                </Table.Row>
              ))}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    fetchedMqttBroker: getMqttBroker(state),
  };
};

export default withComponentStateCache(
  connect(mapStateToProps, { ClearLog, saveUserSettingsComponentState })(
    ToolsMqttList
  )
);
