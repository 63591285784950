import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Container } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import { getAllAreaStatuses } from "components/WebWorker/reducer";

import { saveNewNamedArea } from "NamedAreas/actions";

import NamedAreaSimpleForm from "admin/named-area-simple/NamedAreaSimpleForm";

import SubmissionModal from "admin/SubmissionModal";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { SubmissionError } from "redux-form";

import { Dimmer, Loader } from "semantic-ui-react";

import { v4 as uuid } from "uuid";

import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import { newNamedAreaTemplate } from "admin/named-area-simple/NewNamedAreaTemplate";

import { fetchNamedAreas, fetchNamedAreaEvents } from "NamedAreas/actions";
import { updateTagZone } from "components/Tags/actions";

import { isTagTracking } from "components/ConfigJs";

class CreateNamedAreaSimple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
      isTagTracking: false,
    };
  }

  componentDidMount() {
    const {
      allAreas,
      //goto,
      namedAreaSubType,
    } = this.props;

    // If no areas data is loaded redirect to area list
    // this is typically when the the page is F5 reloaded.
    // Can't operate without area data.
    //

    // #REVIEW - disable this for Adding new Named Area.
    // See case for  1st installation there will be no
    // areas except for the defaultArea, but the defaultArea is excluded from State
    // see " if (area !== "defaultArea") {" ... src/components/WebWorker/reducer.js
    //
    //
    if (false) {
      const checkAllAreas = _isEmpty(allAreas);
      if (checkAllAreas) {
        this.props.goto(`/admin/named-area/${namedAreaSubType}`);
      }
    }

    if (isTagTracking()) this.setState({ isTagTracking: true });

    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_isEqual(JSON.stringify(this.props), JSON.stringify(nextProps)) ||
      !_isEqual(this.state, nextState)
    );
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  // newNamedAreaTemplate = (id, name, parent, parentName, area, shape) => {
  //   return {
  //     id: id,
  //     area: area,
  //     name: name,
  //     shape: shape,
  //     type: "Polygon",
  //     priority: 1,
  //     parent: parent,
  //     parent_name: parentName,
  //     // #WIP #TODO - set a central location for dummy buttons - used in a few places
  //     button: [
  //       {
  //         priority: 0,
  //         type: "SEISMIC0_EVENT",
  //         clickable: true,
  //         icon: "icon-earthquake0",
  //         color: "green",
  //         hint: "Level 0",
  //         title: "Seismic 0",
  //         alt: "Level 0",
  //         id: id,
  //         named_area: parent,
  //         group: 0,
  //         default: true,
  //         active: true,
  //         column: 0,
  //         state: "on",
  //         active_color: "green",
  //         active_state: "on",
  //         on_time: 0,
  //         off_time: 0,
  //         train: 0,
  //         marker: "RoundMarker",
  //       },
  //     ],
  //     active_color: "GREEN",
  //     active_state: "ON",
  //     style:
  //       '{"stroke":true,"color":"hsl(137.508,50%,75%)","weight":1,"opacity":1,"fill":true,"fillColor":"hsl(137.508,50%,75%)","fillOpacity":0.5,"clickable":true}',
  //   };
  // };

  _preProcessValues = (values) => {
    const {
      mineLevelId,
      areaName,
      regions,
      namedAreaParentName,
      backgroundColor,
    } = values;

    const { allAreas } = this.props;
    const thisArea = allAreas.find((area) => area.id === mineLevelId);
    const transformPixelsToUtm = thisArea?.transformPixelsToUtm;

    let newNamedAreas = [];

    regions.forEach((region) => {
      const { id, points } = region;

      let shape = [];

      points.forEach((point) => {
        const objLatLng = transformPixelsToUtm.transform({
          lat: point.lat,
          lng: point.lng,
        });

        shape.push(Object.values(objLatLng).reverse()); // reverse lat / lng coords
      });

      shape.push(shape[0]); // append 1st point to end to close the shape

      // polygon styling
      const color = `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`;
      const fillColor = color;
      const opacity = `${backgroundColor.a}`;

      newNamedAreas.push({
        id: `${mineLevelId}:${areaName}:${id}`,
        name: `${id}`,
        parent: `${mineLevelId}:${areaName}`,
        parentName: namedAreaParentName,
        area: mineLevelId,
        shape: shape,
        style: `{"stroke":true,"color":"${color}","weight":1,"opacity":1,"fill":true,"fillColor":"${fillColor}","fillOpacity":${opacity},"clickable":true}`,
      });
    });

    return newNamedAreas;
  };

  submitMyForm = (values) => {
    //console.log(`submitMyForm values`, values);

    const { saveNewNamedArea, isNamedAreaActive, namedAreaSubType } =
      this.props;

    let promiseArray = [];
    values.forEach((value) => {
      const { id, name, parent, parentName, area, shape, style } = value;

      promiseArray.push(
        new Promise((resolve, reject) => {
          const newValues = newNamedAreaTemplate(
            id,
            name,
            parent,
            parentName,
            area,
            shape,
            "green",
            { active_state: "on" },
            namedAreaSubType,
            null,
            style
          );
          saveNewNamedArea({
            values: newValues,
            resolve,
            reject,
          });
        })
      );

      if (this.state.isTagTracking) {
        // tagTracking ...
        /// this sends an update message to tagTracking api
        // #REVIEW- WIP . Currently does not support "NEW"
        // promiseArray.push(
        //   new Promise((resolve, reject) =>
        //     updateTagZone({ values: { id: id }, resolve, reject })
        //   )
        // );
      }
    });
    return Promise.all(promiseArray)
      .then((results) => {
        //console.log("saveNewNamedArea promise allSettled results", results);
        results.forEach((result, idx) => {
          const { status } = result;
          switch (status) {
            case "fulfilled":
              const {
                value: { topic, token, status, message },
              } = result;

              // tagTracking - updateTagZone will respond with
              // e.g.
              // {
              //   status: "fulfilled",
              //   value: {
              //     data: {
              //       id: "Area_Main:717d59d5-604f-4924-bb16-d69d9c48ba06:1639017750907",
              //     },
              //     message: "ok",
              //     status: "success",
              //   },
              // };

              if (this.state.isTagTracking) {
                if (status && message) {
                  if (message === "success") {
                    // everything is OK .... ATM not check if things fail...
                  }
                }
              }

              break;

            default:
              break;
          }
        });

        console.log("SUCCESS saveNewNamedArea", results);
      })
      .then(() => {
        //this.onResetError();

        this.props.fetchNamedAreas();
        this.props.fetchNamedAreaEvents();
        this.props.goto(`/admin/named-area/${namedAreaSubType}`);
      })
      .catch((error) => {
        console.log(`ERROR saveNewNamedArea error`, error);
        this.onSetError("failed");
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  render() {
    const {
      //isLoading, error,
      namedAreaSubType,
      strings,
    } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    // APP_TERMINOLOGY
    let strNamedArea = strings?.["Polygon"];
    let strNamedAreas = strings?.["Polygons"];

    switch (namedAreaSubType) {
      case "tagzone":
        strNamedArea = strings?.["Tag Zone"];
        strNamedAreas = strings?.["Tag Zones"];
        break;
      case "forced":
        strNamedArea = strings?.["Forced FireFly"];
        strNamedAreas = strings?.["Forced FireFlys"];
        break;
      case null:
      default:
        break;
    }

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"namedAreaSimpleForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) =>
            this.submitMyForm(this._preProcessValues(formValues))
          }
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{`${strings?.["Add"]} - ${strNamedArea}`}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <NamedAreaSimpleForm
            initialValues={{
              // id: "",
              areaName: uuid(),
              regions: [],
              namedAreaParentName: "",
              namedAreaSubType: namedAreaSubType,
              backgroundColor: { r: 0, g: 0, b: 0, a: 0.5 },
            }}
            header={`${strings?.["Add"]} - ${strNamedArea}`}
            onSubmit={(values) =>
              this.submitMyForm(this._preProcessValues(values))
            }
            // #NOTE - disabled as this is fired as soon as submit,
            // *not* after successful completion of the Promise!!
            // onSubmitSuccess={(result, dispatch) => {
            //   //console.log(`onSubmitSuccess ${result}`);
            //   // wait for state change before redirecting page
            //   this.setState(
            //     {
            //       submitted: true,
            //     },
            //     () => {
            //       //this.onResetError();

            //       console.log(`!!!!onSubmitSuccess`);
            //       // this.props.fetchNamedAreas();
            //       // this.props.fetchNamedAreaEvents();
            //       // this.props.goto(`/admin/named-area/${namedAreaSubType}`);
            //     }
            //   );
            // }}
            onCancel={() => {
              this.props.goto(`/admin/named-area/${namedAreaSubType}`);
            }}
            strings={strings}
          />

          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
      // <>
      //   <SubmissionModal
      //     open={openModal}
      //     onResetError={() => this.onResetError()}
      //     errorMessage={errorMessage}
      //   />
      //   <Dimmer active={submitting} page>
      //     <Loader>Saving changes</Loader>
      //   </Dimmer>
      //   <CreateNamedAreaSimpleForm
      //     onSubmit={(values) => {
      //       return new Promise((resolve, reject) => {
      //         console.log("values", values);
      //         saveNewNamedArea({ values, resolve, reject });
      //       }).catch((error) => {
      //         console.log(`onSubmit ${error}`);
      //         this.onSetError("failed");
      //         // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
      //         throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      //       });
      //     }}
      //     onSubmitSuccess={(result, dispatch) => {
      //       console.log(`onSubmitSuccess ${result}`);
      //       this.onResetError();
      //       dispatch(push("/admin/named-area"));
      //     }}
      //     onSubmitFail={(errors) => {
      //       console.log(`onSubmitFail ${errors}`);
      //     }}
      //     onCancel={() => {
      //       this.props.goto("/admin/named-area");
      //     }}
      //   />
      // </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const allAreas = getAllAreaStatuses(state);

  return {
    isNamedAreaActive: false,
    allAreas,
  };
};

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    fetchNamedAreas: () => dispatch(fetchNamedAreas()),
    fetchNamedAreaEvents: () => dispatch(fetchNamedAreaEvents()),
    //
    updateTagZone: (data) => {
      dispatch(updateTagZone(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNamedAreaSimple);
