import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import * as Api from 'api'
import { getActiveForcedOverride } from 'OperationalChanges/reducer'

export function convertFromAPI (namedAreas) {
  return namedAreas.reduce(
    (acc, level) => {
      acc.byId[level.id] = level
      acc.order.push(level.id)
      return acc
    },
    { byId: {}, order: [] }
  )
}

function * fetchPrecannedOperations (action) {
  try {
    const operations = yield call(Api.fetchPrecannedOperations)
    yield put({
      type: 'FETCH_PRECANNED_OPERATIONS_SUCCEEDED',
      payload: operations
    })
  } catch (e) {
    yield put({ type: 'FETCH_PRECANNED_OPERATIONS_FAILED', payload: e.message })
  }
}

function * activatePrecannedOperationalChange (action) {
  const { area, operation } = action.payload
  try {
    const result = yield call(Api.activatePrecannedChange, area, operation)
    yield put({
      type: 'OPERATIONAL_CHANGE_ACTIVE',
      payload: result
    })
  } catch (e) {
    yield put({
      type: 'ACTIVATE_OPERATIONAL_CHANGE_FAILED',
      payload: e.message
    })
  }
}

function * deactivatePrecannedOperationalChange (action) {
  const { id } = action.payload
  try {
    const result = yield call(Api.deactivateOperationalChange, id)
    yield put({
      type: 'OPERATIONAL_CHANGE_DEACTIVATED',
      payload: result
    })
  } catch (e) {
    yield put({
      type: 'DEACTIVATE_OPERATIONAL_CHANGE_FAILED',
      payload: e.message
    })
  }
}

function * handleToggleForcedColor (action) {
  const { id, color } = action.payload
  const currentForce = yield select(getActiveForcedOverride, id)

  let create = false
  let cancel = false

  if (!currentForce) {
    create = true
  } else {
    if (currentForce.createdFrom.operation.spec.color === color) {
      cancel = true
    } else {
      create = true
    }
  }

  if (create) {
    try {
      const result = yield call(Api.activateForcedLight, id, color)
      yield put({
        type: 'OPERATIONAL_CHANGE_ACTIVE',
        payload: result
      })
    } catch (e) {
      yield put({
        type: 'ACTIVATE_OPERATIONAL_CHANGE_FAILED',
        payload: e.message
      })
    }
  } else if (cancel) {
    yield deactivatePrecannedOperationalChange({
      payload: {
        id: currentForce.id
      }
    })
  }
}

function * saga () {
  yield takeLatest(
    'FETCH_PRECANNED_OPERATIONS_REQUESTED',
    fetchPrecannedOperations
  )

  yield takeEvery(
    'ACTIVATE_OPERATIONAL_CHANGE',
    activatePrecannedOperationalChange
  )
  yield takeEvery(
    'DEACTIVATE_OPERATIONAL_CHANGE',
    deactivatePrecannedOperationalChange
  )
  yield takeEvery('TOGGLE_FORCED_COLOR', handleToggleForcedColor)
}

export default saga
