import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiAuth";

import * as messages from "FlashMessages/actions";
// ...

function* loginFlow() {
  while (true) {
    const {
      payload: { email, password },
    } = yield take("LOGIN_REQUEST");
    // fork return a Task object
    const task = yield fork(authorize, email, password);
    console.log("loginFlow auth completed, waiting on other events");
    const action = yield take(["LOGOUT_REQUEST", "LOGIN_ERROR"]);
    console.log("loginFlow receive", action);
    if (action.type === "LOGOUT_REQUEST") {
      console.log("cancelling existing authorize task");
      yield cancel(task);
      // yield call(Api.clearItem, 'token')
      console.log("forking unauthorize task");
      // yield fork(unauthorize)
    }
  }
}

function* authorize(email, password) {
  try {
    const user = yield call(Api.login, email, password);
    yield put({ type: "LOGIN_SUCCESS", payload: { user } });
    return user;
  } catch (error) {
    const {
      response: { data, status },
    } = error;
    const message =
      status >= 500 ? "Error with server" : "Incorrect credentials";
    yield put({
      type: "LOGIN_ERROR",
      payload: { error: message, extra: data },
    });
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
    }
  }
}

function* unauthorize() {
  try {
    const response = yield call(Api.logout);
    yield put({ type: "LOGOUT_SUCCESS", payload: response });
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put({
        type: "LOGOUT_SUCCESS",
        payload: "Well, it looks like you were logged out anyway",
      });
    } else {
      yield put({
        type: "LOGOUT_ERROR",
        payload: { error: "Trouble logging out", extra: error.response.data },
      });
    }
  }
}

function* checkAuth(action) {
  try {
    const user = yield call(Api.getUser);
    yield put({ type: "FOUND_VALID_AUTH", payload: { user } });
  } catch (error) {
    if (error?.response?.status) {
      const {
        response: { status, data },
      } = error;
      if (status === 401) {
        yield put({
          type: "AUTH_FAILURE",
          payload: { error: "Authentication error", extra: data },
        });
      } else {
        yield put({
          type: "AUTH_UNKNOWN",
          payload: { error: "Authentication error", extra: data },
        });
      }
    } else {
      yield put({ type: "SERVER_CONNECTION_CLOSED" });
    }
  }
}
function* manageErrors(action) {
  while (true) {
    const { type, payload } = yield take([
      "LOGIN_REQUEST",
      "LOGOUT_REQUEST",
      "AUTH_FAILURE",
      "AUTH_UNKNOWN",
      "LOGIN_ERROR",
      "LOGOUT_ERROR",
    ]);
    if (type.indexOf("_REQUEST") > -1) {
      yield put(messages.clearFlashMessages());
    } else {
      const { error, extra } = payload;
      yield put(
        messages.addErrorFlashMessage(error, extra || "Unknown problem")
      );
    }
  }
}

function* authSagas() {
  yield fork(loginFlow);
  yield takeEvery("CHECK_AUTH", checkAuth);
  yield takeLatest("LOGOUT_REQUEST", unauthorize);
  yield fork(manageErrors);
}

export default authSagas;
