export function fetchNamedAreas() {
  return { type: "NAMED_AREAS_FETCH_REQUESTED" };
}

export function fetchNamedAreaEvents() {
  return { type: "NAMED_AREA_EVENTS_FETCH_REQUESTED" };
}

export function fetchNamedAreaTriggers() {
  return { type: "NAMED_AREA_TRIGGERS_FETCH_REQUESTED" };
}

export function fetchNamedAreaExtTriggerEventInfo() {
  return { type: "NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_REQUESTED" };
}

export function fetchNamedAreaScheduledEventInfo() {
  return { type: "NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_REQUESTED" };
}

export function saveNewNamedArea(payload) {
  return { type: "SAVE_NEW_NAMED_AREA", payload };
}

export function saveNewNamedAreaEvent(payload) {
  return { type: "SAVE_NEW_NAMED_AREA_EVENT", payload };
}

export function saveNewEmergencyEvent(payload) {
  return { type: "SAVE_NEW_EMERGENCY_EVENT", payload };
}

// export function updateNamedArea(payload) {
//   return { type: "UPDATE_NAMED_AREA", payload };
// }

export function deleteNamedArea(payload) {
  return { type: "DELETE_NAMED_AREA", payload };
}

export function saveNewScheduledEvent(payload) {
  return { type: "SAVE_NEW_SCHEDULED_EVENT", payload };
}

export function fetchScheduledEventJobs() {
  return { type: "SCHEDULED_EVENT_JOBS_FETCH_REQUESTED" };
}

export function deleteScheduledEventJobs() {
  return { type: "DELETE_SCHEDULED_EVENT_JOBS_REQUESTED" };
}

export function deleteScheduledEventJob(payload) {
  return { type: "DELETE_SCHEDULED_EVENT_JOB_REQUESTED", payload };
}
