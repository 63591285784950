// https://medium.com/@subalerts/create-dynamic-table-from-json-in-react-js-1a4a7b1146ef
// draggable row - https://codesandbox.io/s/4jw25r7l19?file=/src/index.js:1333-1353
// hooks parent to children- https://stackoverflow.com/questions/55726886/react-hook-send-data-from-child-to-parent-component

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  Checkbox,
  Icon,
  Button,
  Input,
  Popup,
  Image,
} from "semantic-ui-react";

import IconSVG from "components/Icon";

import "admin/named-area/NamedAreaTableIcons.css";

import isEmpty from "lodash/isEmpty";

import { messageToken, getUserSessionIp } from "utils/messageToken";

import DraggableTableRow from "admin/named-area/DraggableTableRow";

import { mqttPublish } from "components/WebWorker/actions";

import {
  namedAreaSetDeleteSelections,
  namedAreaClearDeleteSelections,
  namedAreaSetShowHideSelections,
  namedAreaClearShowHideSelections,
  namedAreasSetIsDirty,
  namedAreasClearIsDirty,
} from "components/WebWorker/actions";

import { updateButtonGroupState } from "OperationalChanges/actions";

import {
  getNamedAreaInfos,
  GetMapNamedAreasReducedProperties,
  getIsDirty,
  getNamedAreaEventButtonGroupState,
} from "components/WebWorker/reducer";

import { GetLocalMap } from "components/Map/reducer.js";

import { GeoJsonToMqttNamedAreaChange } from "admin/named-area/utils-mqttMessages";

import SettingsModal from "admin/named-area/NamedAreaButtonSettingsModal";

import { microTime } from "utils/microTime";

import { selectColor } from "admin/named-area/region-colors";

class TableMade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      deleteSelected: [], // named area ids of the rows selected to be deleted
      hideSelected: [], // named area ids of the rows selected to be hidden
      isDirty: false,
      acks: [],
      namedAreaIdValue: "",
      namedAreaParentName: "",
      //
      // modal settings dlg for button options
      isOpenSettingsModal: false,
      editSettingsButton: 0,
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
    this.setState({ namedAreaIdValue: this.props.namedAreaIdValue });
    this.setState({ namedAreaParentName: this.props.namedAreaParentName });
    this.setState({ buttonGroupState: this.props.buttonGroupState });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.namedAreaIdValue !== this.props.namedAreaIdValue) {
      this.setState({ namedAreaIdValue: this.props.namedAreaIdValue });
    }

    if (prevProps.namedAreaParentName !== this.props.namedAreaParentName) {
      this.setState({ namedAreaParentName: this.props.namedAreaParentName });
    }

    if (prevProps.buttonGroupState !== this.props.buttonGroupState) {
      this.setState({ buttonGroupState: this.props.buttonGroupState });
    }

    // #REVIEW -
    // if any setting has been changed (i.e. isDirty) no longer update from mqtt state
    // _but_ update if there is a new item properties.status = "drawn"
    //
    // if there is an current event then after setting change this will be resent to update the event.
    const { isDirty } = this.state;

    // #REVIEW/ #TODO - need to support passing the parentId through here
    // set global state if dirty
    const parentId = "parentNamedArea";

    // #REVIEW / TODO
    // move this filter to a common location
    const isParentDirty =
      this.props.parentIsDirty.filter((namedArea) => namedArea === parentId)
        .length > 0;

    if (isParentDirty && !isDirty) {
      this.setState({ isDirty: true });
    }

    // this form is dirty
    if (!isParentDirty && isDirty) {
      // but the parent is not currently dirty
      this.props.namedAreasSetIsDirty(parentId); // make it dirty
    }

    //
    // Update new named area data
    //

    let prevDataNamedArea = {};
    let thisDataNamedArea = {};
    if (prevProps?.data !== undefined && this.props?.data !== undefined) {
      prevProps.data.forEach(
        (na, idx) => (prevDataNamedArea[na.properties.id] = na)
      );
      this.props.data.forEach(
        (na, idx) => (thisDataNamedArea[na.properties.id] = na)
      );
    }

    // console.log("ggg prevDataNamedArea", prevDataNamedArea);
    // console.log("ggg thisDataNamedArea", thisDataNamedArea);

    // const keys = Object.keys(prevDataNamedArea);
    // console.log("ggg keys", keys);
    // let updateData = false;
    // for (let i = 0; i < keys.length; i++) {
    //   console.log("ggg prevDataNamedArea[keys[i]]", prevDataNamedArea[keys[i]]);
    //   if (prevDataNamedArea[keys[i]] !== thisDataNamedArea[keys[i]])
    //     updateData = true;
    // }

    if (
      //  JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      JSON.stringify(prevDataNamedArea) !== JSON.stringify(thisDataNamedArea) &&
      this.props.data.length
    ) {
      // #REVIEW #TODO #WIP - WORK OUT A BETTER STRATEGY TO COMPARE OBJECTS!!!!!

      // console.log("ggg this.props.data", JSON.stringify(prevDataNamedArea));
      // console.log("ggg prevProps.data", JSON.stringify(thisDataNamedArea));

      // check if any namedAreas have been drawn i.e. isDirty
      // if properties.status === "drawn" then set the form as dirty
      this.props.data.forEach((item, idx) => {
        if (item.properties.status === "drawn") {
          // set to dirty
          this.setState({ isDirty: true });
        }
      });

      // if Dirty we do not want to update/overwrite the current color state of the check boxes
      // when there is an updated data message
      // make a copy of the button arrays from this state
      // overwrite the incoming data giving preference to the current state

      // #REVIEW - this gives copies this.state unless doesn't exist at which point it collects the next sequential values from this.props
      // BUT this assumes sequential
      // need to fix to reference namedArea IDs instead
      // this.props.data is the incoming update
      // this.state.data is the current data
      let newData = this.props.data;

      // sort the incoming data (which is, at this point, not reordered) by the properties.priority
      newData.sort(
        (a, b) =>
          parseFloat(a.properties.priority) - parseFloat(b.properties.priority)
      );

      // if the form is dirty need to retain all setting for the current data i.e. row posn, state of eye check, color box settings etc.
      // append any new data as the last row.

      if (isDirty) {
        let mergedData = [];
        newData.forEach((namedArea, idx) => {
          // if an existing namedArea (i.e. same propertie.id) exists overwrite the newData to keep dirty changes
          const index = this.state.data.findIndex(
            (x) => x.properties.id === namedArea.properties.id
          );
          if (index > -1) {
            mergedData[idx] = this.state.data[index];
          } else {
            mergedData[idx] = namedArea;
          }
        });

        // sort the merge data by existing row order

        // get a list of the current rows by Id
        let currentNamedRowIds = [];
        this.state.data.forEach((namedArea, idx) => {
          currentNamedRowIds.push(namedArea.properties.id);
        });

        // sort newData (properties.id) based on order of currentNamedRowIds
        // #REVEW - NOTE: the new namedArea will be added at the top!
        mergedData.sort(function (a, b) {
          return (
            currentNamedRowIds.indexOf(a.properties.id) -
            currentNamedRowIds.indexOf(b.properties.id)
          );
        });

        newData = mergedData;
      }

      // make all buttons the same for new named areas
      const homoNamedAreas = this.homogoniseButtonsForAllNamedAreas(
        newData, // recently sorted and merged
        this.state.data // compare with the existing state
      );

      this.setState({ data: homoNamedAreas }); //was `newData` //
    }

    //
  }

  // #NOTE -
  //
  // #1 - if the number of buttons is the same for an existing named areas don't homogonise (i.e. new area has 1 button)
  // #2 - only update the `new` incoming named areas,
  //
  // na goes to the top and copies the previous top named areas's colors

  homogoniseButtonsForAllNamedAreas = (incomingData, currentState) => {
    // deep clone the objects. Was having trouble with memory corruption
    const prevNamedAreas = JSON.parse(JSON.stringify(currentState));
    let homoNamedAreas = JSON.parse(JSON.stringify(incomingData));

    // get any existing named area as a template
    let buttons = prevNamedAreas[0]?.properties?.button;

    if (buttons !== undefined) {
      const currentNumberOfButtons = buttons?.length;

      //console.log("hhh currentNumberOfButtons", currentNumberOfButtons);

      incomingData.forEach((namedArea, idx) => {
        // console.log(
        //   "hhh namedArea.properties.button",
        //   namedArea.properties.button
        // );

        // #1 -
        if (namedArea.properties.button.length !== currentNumberOfButtons) {
          // update button info
          let updateButtonsId = [];
          buttons.forEach((button, idx) => {
            // deep clone a copy of the button object before reassigning properties

            updateButtonsId[idx] = JSON.parse(JSON.stringify(button));
            // correct the id in the button object when updating
            updateButtonsId[idx].id = namedArea.properties.id;
            // update priority of the button
            updateButtonsId[idx].priority = idx;
          });
          homoNamedAreas[idx].properties.button = updateButtonsId;
        } else {
          homoNamedAreas[idx].properties.button = namedArea.properties.button;
        }
      });
      return homoNamedAreas;
    }
    return incomingData;
  };

  swap = (a, b) => {
    let { data } = this.state;

    console.log("qqq swap before data", data);
    data[a] = data.splice(b, 1, data[a])[0];
    this.setState({
      ...this.state,
      data,
    });
    console.log("qqq swap after data", data);

    // row moved i.e. draw layer order has changed so now isDirty
    this.setState({ isDirty: true });
  };

  onColorClick = (props) => {
    // #WIP - disable dirty if color change -
    // now color change is for events in testing
    //
    if (true) {
      this.setState({ isDirty: true });
    }

    const { row, column } = props;
    const { data } = this.state;
    //  #REVIEW - why need to deepClone here?

    let newData = JSON.parse(JSON.stringify(data)); /// <---deepClone

    // #REVIEW - move to load from server
    const colors = [
      "green",
      "amber",
      "white",
      "blue",
      "red",
      "white",
      "no_change",
      "off",
    ];

    const currentColor = newData[row].properties.button[column].color;
    const index = colors.findIndex((x) => x === currentColor);
    let newColor;
    index + 1 > colors.length - 1
      ? (newColor = colors[0])
      : (newColor = colors[index + 1]);

    newData[row].properties.button[column].color = newColor;

    this.setState({ data: newData });
  };

  onDeleteClick = (props) => {
    // console.log("qqq onDeleteClick ", props);
    // console.log("qqq this.state.deleteSelected", this.state.deleteSelected);

    const { row } = props;
    const { data } = this.state;

    const deleteRowId = data[row].properties.id;

    // deleteSelected keeps record rows which have been selected for deletion
    let { deleteSelected } = this.state;

    if (deleteSelected.includes(deleteRowId)) {
      // remove it
      deleteSelected.splice(deleteSelected.indexOf(deleteRowId), 1);

      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      // console.log("namedAreaClearDeleteSelections deleteRowId", deleteRowId);
      // this.props.namedAreaClearDeleteSelections({
      //   area: "DMLZ_Extraction",
      //   id: deleteRowId,
      // });
      // also hide it too!
      this.props.namedAreaClearShowHideSelections({
        area: "DMLZ_Extraction",
        id: deleteRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }
    // add it
    else {
      deleteSelected.push(deleteRowId);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      // this.props.namedAreaSetDeleteSelections({
      //   area: "DMLZ_Extraction",
      //   id: deleteRowId,
      // });
      // also hide it too!
      this.props.namedAreaSetShowHideSelections({
        area: "DMLZ_Extraction",
        id: deleteRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }

    // updated state sent, so no longer dirty
    this.setState({ deleteSelected: deleteSelected });

    // updated state sent, so no longer dirty
    this.setState({ isDirty: true });
  };

  onShowHideClick = (props) => {
    const { row } = props;
    const { data } = this.state;

    const hideRowId = data[row].properties.id;

    // hideSelected keeps record rows which have been selected for hide
    let { hideSelected } = this.state;

    if (hideSelected.includes(hideRowId)) {
      // remove it
      hideSelected.splice(hideSelected.indexOf(hideRowId), 1);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      this.props.namedAreaClearShowHideSelections({
        area: "DMLZ_Extraction",
        id: hideRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }
    // add it
    else {
      hideSelected.push(hideRowId);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      this.props.namedAreaSetShowHideSelections({
        area: "DMLZ_Extraction",
        id: hideRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }

    // updated state of show and hide
    this.setState({ hideSelected: hideSelected });

    // show & hide do not change data so not dirty
    // this.setState({ isDirty: true });
  };

  getRowIcons = () => {
    const { data: items } = this.state;

    // #TODO - use the items list to determine which check box is selected
    // and change the background color
    if (items === undefined) return null;

    // Note - all button groups for a named area are the same
    // Use first named area item button group as template
    //
    const buttons = items[0]?.properties?.button;
    if (buttons === undefined) return null;

    const handleCogClick = (value, idx) => {
      this.setState({ isOpenSettingsModal: value, editSettingsButton: idx });
    };

    const handleLinkClick = (idx) => {
      // don't do anything for 1st button
      if (idx === 0) return null;

      const { isDirty, data } = this.state;

      let newData = JSON.parse(JSON.stringify(data));

      //console.log("handleLinkClick", idx, newData);

      // Note - all button groups for a named area are the same
      // Use first named area item button group as template
      //
      let buttons = newData[0]?.properties?.button;
      if (buttons === undefined) return null;

      // use first named area as reference for buttons since buttons are the same for the whole named area
      const leftButtonGroup = buttons[idx - 1].group;

      //console.log("leftButtonGroup", leftButtonGroup);
      let thisButtonGroup = buttons[idx].group;
      //console.log("thisButtonGroup", thisButtonGroup);

      if (leftButtonGroup !== undefined && thisButtonGroup !== undefined) {
        if (leftButtonGroup === thisButtonGroup) {
          // already linked, so toggle un-link
          // ... make separate group (+1)
          for (let index = idx; index < buttons.length; index++) {
            buttons[index].group = buttons[index].group + 1;
          }
        } else {
          // link left to right
          buttons[idx].group = leftButtonGroup;
          // decrement all buttons to the right
          for (let index = idx + 1; index < buttons.length; index++) {
            buttons[index].group = buttons[index].group - 1;
          }
          //
          //
        }
      }

      console.log("buttons newButtonGroupArray", buttons);

      // #TODO #WIP
      // NOte this is repeated #BUTTONSGROUPUPDATE

      const newButtonGroupArray = buttons.map((but) => but.group);

      // newButtonGroupArray
      // e.g. [0,1,1,2]
      // means button one in group 0, button two and three in group 1, button four in group 2
      console.log("handleLinkClick newButtonGroupArray", newButtonGroupArray);

      newData.forEach((namedArea, index) => {
        newData[index].properties.button[idx].group = newButtonGroupArray[idx];
      });

      this.setState({ data: newData });

      // if not dirty make it so!
      if (!isDirty) {
        this.setState({ isDirty: true });
      }
    };

    const leftPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell
          style={{
            textAlign: "right",
            paddingRight: "20px",
          }}
        >
          Edit Button
        </Table.HeaderCell>
      </>
    );

    const rightPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
      </>
    );

    return (
      <>
        {leftPaddingHeaderCells}
        <RenderCogLinkIcon
          data={buttons}
          handleCogClick={(value, idx) => handleCogClick(value, idx)}
          handleLinkClick={(idx) => handleLinkClick(idx)}
        />

        {/* <RenderRowIcons data={buttons} /> */}
        {rightPaddingHeaderCells}
      </>
    );
  };

  getRowDefaults = () => {
    const { data: items } = this.state;

    // #TODO - use the items list to determine which check box is selected
    // and change the background color
    if (items === undefined) return null;

    // Note - all button groups for a named area are the same
    // Use first named area item button group as template
    //
    const buttons = items[0]?.properties?.button;
    if (buttons === undefined) return null;

    const leftPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell
          style={{
            textAlign: "right",
            paddingRight: "20px",
          }}
        >
          Button Default Color
        </Table.HeaderCell>
      </>
    );

    const rightPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
      </>
    );

    return (
      <>
        {leftPaddingHeaderCells}
        <RenderRowDefaults data={buttons} />
        {rightPaddingHeaderCells}
      </>
    );
  };

  getRowsData = () => {
    const { data: items, deleteSelected } = this.state;

    // #TODO - use the items list to determine which check box is selected
    // and change the background color
    if (items === undefined) return null;

    // Set style for each row.
    // This is setup RenderRowoutside the .map below as rows area draggable, and styles
    // need to be preserved with the drag.
    //
    let rowStyles = [];
    //e.g.
    // [{ opacity: "0.3", backgroundColor: "red" },
    // { opacity: "1", backgroundColor: "blue" },...]
    items.forEach((item, idx) => {
      // filter deleteSelected and return an array in row order
      // e.g.  rowStyles = [false, true, false];
      //rowStyles.push(deleteSelected.includes(item?.properties?.id));

      if (deleteSelected.includes(item?.properties?.id)) {
        rowStyles[idx] = { opacity: "0.3", backgroundColor: "red" };
      } else {
        const rowPropertieStyle = item?.properties?.style;
        if (rowPropertieStyle !== undefined) {
          const { fillColor } = rowPropertieStyle;
          rowStyles[idx] = {
            opacity: "0.8",
            backgroundColor: fillColor !== undefined ? fillColor : "orange",
          };
        } else {
          rowStyles[idx] = { opacity: "0.8", backgroundColor: "grey" };
        }
      }

      //console.log("rowStyles", rowStyles);
    });

    // items sorted by "priority"
    // priority may be integer or string.
    // #REVIEW - force to integer for now
    // const items = this.state.data.sort((a, b) => {
    //   return a.priority.toString().localeCompare(b.priority);
    // });

    const itemsLength = items.length;

    return items.map((row, index) => {
      return (
        <DraggableTableRow
          rowStyles={rowStyles}
          key={index}
          i={index}
          action={this.swap}
          onColorClick={this.onColorClick}
          onDeleteClick={this.onDeleteClick}
          onShowHideClick={this.onShowHideClick}
        >
          <RenderRow
            key={index}
            index={index}
            data={row}
            dataLength={itemsLength}
            onColorClick={this.onColorClick}
            onDeleteClick={this.onDeleteClick}
            onShowHideClick={this.onShowHideClick}
          />
        </DraggableTableRow>
      );
    });
  };

  getRowButtonActives = () => {
    const { data: items } = this.state;

    if (items === undefined) return null;

    // Note - all button groups for a named area are the same
    // Use first named area item button group as template
    //
    const buttons = items[0]?.properties?.button;
    if (buttons === undefined) return null;

    const onButtonActiveToggle = (buttonIndex, buttonState) => {
      console.log("onButtonActiveToggle", buttonIndex, buttonState);

      const { isDirty, data } = this.state;

      let newData = JSON.parse(JSON.stringify(data));

      newData.forEach((namedArea, idx) => {
        // update button info
        newData[idx].properties.button[buttonIndex].active = buttonState;
      });

      this.setState({ data: newData });

      // if not dirty make it so!
      if (!isDirty) {
        this.setState({ isDirty: true });
      }
    };

    const onButtonAdd = () => {
      const { isDirty, data } = this.state;

      // #NOTE
      // Adding a button duplicates the previous last button (rhs)
      // Since this new button has the same button group it'll be 'linked'
      // to the previous button.

      // copy existing region row data
      let newData = JSON.parse(JSON.stringify(data));

      for (let index = 0; index < newData.length; index++) {
        const namedArea = newData[index];
        let newButtons = namedArea.properties.button;

        // copy the last button
        let lastButton = JSON.parse(
          JSON.stringify(newButtons[newButtons.length - 1])
        );
        // increment the priority of the button
        lastButton.priority = Number(lastButton.priority) + 1;
        // append new button to the data
        newData[index].properties.button = [...newButtons, lastButton];
        // console.log(
        //   "newData[index].properties.button",
        //   newData[index].properties.button
        // );
      }

      this.setState({ data: newData });

      // if not dirty make it so!
      if (!isDirty) {
        this.setState({ isDirty: true });
      }
    };

    const leftPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell
          style={{
            textAlign: "right",
            paddingRight: "20px",
          }}
        >
          Enable/Disable Button
        </Table.HeaderCell>
      </>
    );

    const rightPaddingHeaderCells = <></>; // no padding on this row

    return (
      <>
        {leftPaddingHeaderCells}
        <RenderRowButtonActives
          data={buttons}
          //onButtonActiveToggle={(idx) => onButtonActiveToggle(idx)}
          setButtonActive={(idx, active) => onButtonActiveToggle(idx, active)}
        />
        <RenderRowAddButton data={buttons} onButtonAdd={onButtonAdd} />
        {rightPaddingHeaderCells}
      </>
    );
  };

  getRowButtonDeletes = () => {
    const { data: items } = this.state;

    if (items === undefined) return null;

    // Note - all button groups for a named area are the same
    // Use first named area item button group as template
    //
    const buttons = items[0]?.properties?.button;
    if (buttons === undefined) return null;

    const leftPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
      </>
    );

    const rightPaddingHeaderCells = (
      <>
        <Table.HeaderCell />
      </>
    ); // no padding on this row

    return (
      <>
        {leftPaddingHeaderCells}
        <RenderRowButtonDeletes data={buttons} />
        {rightPaddingHeaderCells}
      </>
    );
  };

  // [SAVE CHANGES] button
  sendNamedAreaSetupMqtt = () => {
    let isOkToSend = true;

    const { data: namedAreas } = this.state;
    const { namedAreaIdValue, namedAreaParentName } = this.state; // value entered into the forms' named area field
    let { deleteSelected } = this.state; // let -> modified when appending drawn shapes for cleanup

    //console.log("namedAreas", namedAreas);

    if (namedAreas === undefined) return null;

    // process namedAreas

    // 1 - if namedAreas marker for deletion, send these 1st and delete from namedAreas
    // 2 - then send updated namedAreas in reverse order and with priority saved
    // 3 - send updated event messages applying to 'changed' named areas

    // 1 - ...........
    let changedNamedAreas = [];

    namedAreas.forEach((namedArea, idx) => {
      const {
        properties: { id },
      } = namedArea;

      // if named area is selected for deletion
      if (deleteSelected.includes(id)) {
        // send a delete message
        this.props.onSendMqtt({
          topic: `named_area/${id}/delete`,
          qos: 0,
          message: {
            id: id,
            delete: true,
            token: messageToken(),
          },
          retained: false,
        });
      } else {
        changedNamedAreas.push(namedArea);
      }
    });

    // 2 - send changed namedAreas
    //
    // send the messages in reverse so the top namedArea in the UI (row 0) is sent last.
    // namedAreas of the same priority apply in order, so last received has highest priority.

    //console.log("changedNamedAreas", changedNamedAreas);

    changedNamedAreas.reverse().forEach((namedArea, idx) => {
      const {
        properties: { id, name, parent, area, origin, button, style },
      } = namedArea;

      //console.log("updatedNamedArea style", style);

      // #polygonStyle
      // convert style to string since it's structure may change in map
      // and the go server does not break down to json

      const newStyle = JSON.stringify(style);

      // if parent is from create new named area form and contains "parent"
      // replace it with the correct named area
      let newParent = parent;
      let newId = id;
      let newButton = button;
      let newParentName =
        namedAreaParentName !== "" ? namedAreaParentName : `parent-${name}`;

      if (namedAreaIdValue !== "" && namedAreaParentName !== "") {
        if (
          id.includes("createNewNamedArea") &&
          parent.includes("createNewNamedArea")
        ) {
          newId = id.replace("createNewNamedArea", namedAreaIdValue);
          newParent = parent.replace("createNewNamedArea", namedAreaIdValue);

          // update button
          newButton.forEach((button, idx) => {
            newButton[idx].id = button.id.replace(
              "createNewNamedArea",
              namedAreaIdValue
            );
            newButton[idx].named_area = button.named_area.replace(
              "createNewNamedArea",
              namedAreaIdValue
            );
          });
        }
      }

      // priority is set by the row position
      // since the messages are sent out in reverse the priority is also reversed
      // lowest priority is 0
      const priority = namedAreas.length - idx;

      const {
        geometry: { coordinates, type },
      } = namedArea;

      // #TODO
      // #REVIEW - add these to config dlg
      const DEFAULT_default_color = "GREEN";
      const DEFAULT_default_state = "ON";
      const DEFAULT_on_time = 10;
      const DEFAULT_off_time = 10;
      const DEFAULT_train = 10;

      // prepare message token
      const userSessionIp = getUserSessionIp();
      const token = messageToken(userSessionIp);

      // sets final named area object
      const updatedNamedArea = {
        id: newId,
        area,
        name,
        parent_name: newParentName, // i.e. "namedAreaParentName"
        coordinates,
        type,
        priority,
        origin,
        parent: newParent,
        button: newButton,
        default_color: DEFAULT_default_color,
        default_state: DEFAULT_default_state,
        active_color: DEFAULT_default_color,
        active_state: DEFAULT_default_state,
        on_time: DEFAULT_on_time,
        off_time: DEFAULT_off_time,
        train: DEFAULT_train,
        style: newStyle,
        token,
      };

      // create named_area mqtt message
      const namedAreaMsg = GeoJsonToMqttNamedAreaChange(updatedNamedArea);

      console.log("CREATED NEW UPDATED NAMED AREA: ", namedAreaMsg);

      const namedAreaPayload = {
        topic: `named_area/${namedAreaMsg.id}/change`,
        qos: 0,
        message: namedAreaMsg,
        retained: false,
      };

      //console.log("namedAreaMsg", namedAreaMsg);

      if (isOkToSend) {
        this.props.onSendMqtt(namedAreaPayload);
      }

      // update button group state for (possible) changed button settings
      const buttonGroupState = this.state.buttonGroupState;
      let newButtonGroupState = JSON.parse(JSON.stringify(buttonGroupState));
      // console.log("newButtonGroupState ddd", newButtonGroupState);

      // create new button group state based on current buttons.
      // Note - we could have compared to see if buttons had changed, but why bother!
      // Just make a new one.
      //
      newButtonGroupState[newParent] = {};
      for (let i = 0; i < newButton.length; i++) {
        newButtonGroupState[newParent][i] = -1;
      }

      // NOTE
      // 'buttonGroupState' is communicated across the system via the event messages.
      // If buttons states have been changed in a named area:
      // * add/delete button
      // * enable/disable button
      // the `buttonGroupState` needs to be updated by sending an event message for each named area.
      //
      // Steps:
      // 1 - check if there is a current event on the named area
      // 2 - resent 'active' : false event message
      // NOTE: if a named area is changed any current event will be disabled.

      // Use the first button as a basis for the DEFAULT event settings
      //

      const eventMsg = {
        id: newId,
        priority: 0, // reset event to lowest priority
        active: false, // turn off any events
        active_color: DEFAULT_default_color, //active_button.color,
        active_state: DEFAULT_default_state, //active_button.state,
        on_time: DEFAULT_on_time,
        off_time: DEFAULT_off_time,
        train: DEFAULT_train,
        button_groups: JSON.stringify(newButtonGroupState[newParent]),
        timestamp: microTime(),
        precanned: 0, // not used ATM
        token: token,
      };

      //console.log("eventMsg", eventMsg.button_groups);

      const eventPayload = {
        topic: `named_area/${eventMsg.id}/event`,
        qos: 0,
        message: eventMsg,
        retained: true,
      };

      if (isOkToSend) {
        this.props.onSendMqtt(eventPayload);
      }
      // #REVEIW #WIP
      // This is old code which updated local state only.
      // See above.

      if (false) {
        // initialise button group records
        // update button group state for (possible) changed button settings
        // Note - this is also done in PrecannedOperations
        // make button groups object for the current named area
        if (
          newButtonGroupState[newParent] !== undefined ||
          !isEmpty(newButtonGroupState[newParent])
        ) {
          // if the # of buttons has changed .....
          if (
            Object.keys(newButtonGroupState[newParent])?.length !==
            newButton?.length
          ) {
            // reset the button group
            newButtonGroupState[newParent] = {};
            for (let i = 0; i < newButton.length; i++) {
              newButtonGroupState[newParent][i] = -1;
            }
            console.log("newButtonGroupState 1 xxx", newButtonGroupState);
            // update locally
            this.setState({ buttonGroupState: newButtonGroupState });
            // and globally
            this.props.updateButtonGroupState(newButtonGroupState);
          }
        } else {
          // create a new button group
          newButtonGroupState[newParent] = {};
          for (let i = 0; i < newButton.length; i++) {
            newButtonGroupState[newParent][i] = -1;
          }
          console.log("newButtonGroupState 2 xxx", newButtonGroupState);
          // set a new button group for this named area
          // update locally
          this.setState({ buttonGroupState: newButtonGroupState });
          // and globally
          this.props.updateButtonGroupState(newButtonGroupState);
        }
      }
    });

    // send all namedAreas to be deleted from the map
    // note - namdAreas includes those deleted or changed
    const parentId = "parentNamedArea";
    namedAreas.forEach((namedArea, idx) => {
      this.props.namedAreaSetDeleteSelections({
        area: parentId,
        id: namedArea.properties.id,
      });
    });

    // updated named area state sent, so no longer dirty
    // locally
    this.setState({ isDirty: false });

    // globally
    this.props.namedAreasClearIsDirty(parentId);
  };

  resetIsDirty = () => {
    // reset changes

    // #REVIEW/TODO/FIX - this is a fudge until the components are modularised so can pass reset to map component etc
    console.log(
      "FIX THIS - RESET RELOADS THE PAGE. INSTEAD RELOAD THE COMPONENT"
    );
    window.location.reload(false);

    this.setState({ isDirty: false });
  };

  isDeleteAllSelected = () => {};

  onDeleteAllClicked = () => {};

  resetModal = () => {
    this.setState({ isOpenSettingsModal: false });
  };

  handleSettingsSave = (values) => {
    //console.log("handleSettingsSave", values);

    const { isDirty, editSettingsButton, data } = this.state;

    let newData = JSON.parse(JSON.stringify(data));

    newData.forEach((namedArea, idx) => {
      const buttonSettings = namedArea.properties.button[editSettingsButton];
      let updatedButton = { ...buttonSettings, ...values };
      newData[idx].properties.button[editSettingsButton] = updatedButton;
    });

    this.setState({ data: newData });

    //console.log("handleSettingsSave newData", newData);

    // if not dirty make it so!
    if (!isDirty) {
      this.setState({ isDirty: true });
    }

    // close modal
    this.setState({ isOpenSettingsModal: false });
    //return Promise.resolve;
  };

  handleSettingsDelete = (idx) => {
    // #REVIEW - note. didn't need `idx` as already had `editSettingsButton` ...argghhh.
    // leave as-is.

    //console.log("handleSettingsDelete", idx);

    const { isDirty, editSettingsButton, data } = this.state;

    let newData = JSON.parse(JSON.stringify(data));

    // Note - all button groups for a named area are the same
    // Use first named area item button group as template
    //
    let buttons = newData[0]?.properties?.button;

    // #REVIEW - move adding default buttons to here rather than in MAP function
    // To do this added button template when no button defined
    if (buttons === undefined) return null;

    // don't delete the last button!!!  i.e. buttons.length-2 = 0
    if (buttons.length - 1 === 0) return null;
    // #REVIEW - should pop a dlg to announce this! i.e. can't delete last button

    //console.log("handleSettingsDelete buttons", editSettingsButton, buttons);

    // delete selected button from button array, keeping the rest
    //buttons = buttons.splice(0, 3); //editSettingsButton, buttons.length);

    let buttonsDeleted = [];
    for (let index = 0; index < buttons.length; index++) {
      if (index !== editSettingsButton) {
        buttonsDeleted = buttonsDeleted.concat(buttons[index]);
      }
    }

    //console.log("handleSettingsDelete buttons buttonsDeleted", buttonsDeleted);

    newData.forEach((namedArea, idx) => {
      //console.log("onButtonAdd namedArea", namedArea.properties.id);

      // update button info
      let updateButtonsId = [];
      buttonsDeleted.forEach((button, idx) => {
        // deep clone a copy of the button object before reassigning properties
        updateButtonsId[idx] = JSON.parse(JSON.stringify(button));
        // correct the id in the button object when updating
        updateButtonsId[idx].id = namedArea.properties.id;
        // update priority of the button
        updateButtonsId[idx].priority = idx;
      });

      newData[idx].properties.button = updateButtonsId;
    });

    //console.log("handleSettingsDelete newData", newData);

    // reset editSettingsButton to 0 in case we've deleted this record and upon re-render modal it fails to find it.
    // This happens when we delete the last button.
    this.setState({ editSettingsButton: 0 });

    let buttonSettings = buttons[this.state.editSettingsButton];

    this.setState({ data: newData });

    // if not dirty make it so!
    if (!isDirty) {
      this.setState({ isDirty: true });
    }

    // close modal
    this.setState({ isOpenSettingsModal: false });
  };

  render() {
    // #TODO - style the row if it is selected for Deletion - deleteSelected
    //const myReference = this.rowStyle1.current; // The DOM element
    //myReference.style.backgroundColor = "red"; // The style you want to apply
    //this.refs.rowStyle1.style.background = "red";
    //

    const { isDirty } = this.state;

    const iconButtons = {
      padding: "0.5em",
    };

    // padding cell for top row
    // 4: drag, active checkbox, show/hide eye icon, named area description
    const headerCellsRowTop = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
      </>
    );

    const { data: items } = this.state;
    if (items === undefined) return null;

    if (items.length === 0)
      return (
        <div>
          No regions are defined. Use the Map controls to create new regions.
        </div>
      );

    const buttons = items[0]?.properties?.button;
    if (buttons === undefined) return null;

    // deep clone button settings as we're going to delete some of the properties
    let buttonSettings = JSON.parse(
      JSON.stringify(buttons[this.state.editSettingsButton])
    );
    // don't keep the .id (which is unique to the region)
    delete buttonSettings.id;
    buttonSettings.idx = this.state.editSettingsButton;

    return (
      <div>
        <style>{`
          .draggable {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
          }
        `}</style>
        <SettingsModal
          open={this.state.isOpenSettingsModal}
          initialValues={buttonSettings}
          handleSettingsSave={(values) => this.handleSettingsSave(values)}
          resetModal={() => this.resetModal()}
          handleSettingsDelete={(idx) => this.handleSettingsDelete(idx)}
        />
        <Table>
          <Table.Header>
            <Table.Row>{this.getRowButtonActives()}</Table.Row>
            <Table.Row>{this.getRowIcons()}</Table.Row>
            {/* #WIP - put functionality into setup button */}
            {/* <Table.Row>{this.getRowDefaults()}</Table.Row> */}
          </Table.Header>
          <Table.Body>{this.getRowsData()}</Table.Body>
          <Table.Footer>
            {/* #WIP - put functionality into setup button */}
            {/* <Table.Row>{this.getRowButtonDeletes()}</Table.Row> */}
            <Table.Row>
              <Table.HeaderCell colSpan={10}>
                <Button
                  color={"green"}
                  disabled={!isDirty}
                  size="small"
                  onClick={this.sendNamedAreaSetupMqtt}
                >
                  Save Changes
                </Button>
                {/* #NOTE - no longer using this reset process */}
                {/* #NOTE - no longer using this reset processon
                  disabled={!isDirty}
                  size="small"
                  onClick={this.resetIsDirty}
                >
                  Reset Changes
                </Button> */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

// NOTE - previously used to display icon buttons.
// Superceded for <RenderCogLinkIcon />
//
const RenderRowIcons = (props) => {
  const { data: buttons } = props;

  if (buttons === undefined) return null;

  const handleClick = (e, idx) => {
    e.preventDefault();

    console.log("button handleClick idx", idx);
    // update data!
  };

  return buttons.map((button, idx) => {
    console.log("button", button);
    const color = button.color || "red";
    //    const iconColor = isActive && buttonActive ? "white" : color;
    const buttonActive = true;
    const iconColor = buttonActive ? "white" : color;
    const iconName = button.icon;

    return (
      <>
        <Table.HeaderCell style={{ paddingLeft: "2px" }}>
          <Popup
            trigger={
              <Button
                color={color}
                onClick={(e) => handleClick(e, idx)}
                basic={!buttonActive}
                style={{ padding: "0.5em" }}
                size={"large"}
              >
                <IconSVG
                  name={iconName}
                  inverted={buttonActive}
                  color={iconColor}
                />
              </Button>
            }
            content={`${button.title} - ${button.hint}`}
            position="top right" // bottom center
            basic
          />
        </Table.HeaderCell>
      </>
    );
  });
};

const RenderCogLinkIcon = (props) => {
  const { data: buttons } = props;

  if (buttons === undefined) return null;

  const handleLinkClick = (e, idx) => {
    props.handleLinkClick(idx);
  };

  const handleCogClick = (e, idx) => {
    props.handleCogClick(true, idx);
  };

  const colorBoxStyle = {
    borderRadius: 0,
    //border: "1px outset black",
    height: "100%",
    margin: ".25em",
  };

  const iconSVG = process.env.PUBLIC_URL + "/icons/activity.svg";
  const iconCOG = process.env.PUBLIC_URL + "/icons/icon-cog.svg";
  const iconLink = process.env.PUBLIC_URL + "/icons/icon-link.svg";
  const iconUnLink = process.env.PUBLIC_URL + "/icons/icon-unlink.svg";

  const buttonsLen = buttons.length;

  return buttons.map((button, idx) => {
    const color = button.color || "red";
    //    const iconColor = isActive && buttonActive ? "white" : color;
    const buttonActive = true;
    // const iconColor = buttonActive ? "white" : color;
    const iconName = process.env.PUBLIC_URL + "/icons/" + button.icon + ".svg";
    const iconHint = button.hint;

    let iconBg;
    let iconColor;
    let iconOpacity;

    switch (color) {
      case "red":
        iconBg = "#db2828";
        iconColor = "#fff";
        break;
      case "blue":
        iconBg = "#2185d0";
        iconColor = "#fff";
        break;
      case "orange":
      case "amber":
        iconBg = "#fbbd08";
        iconColor = "#fff";
        break;
      case "white":
        iconBg = "#fbbd08";
        iconColor = "#000";
        break;
      case "black":
      case "off":
        iconBg = "#2f3032";
        iconColor = "#fff";
        break;
      case "no_change":
      case "green":
      default:
        iconBg = "#21ba45";
        iconColor = "#fff";
        break;
    }

    const getLinkText = () => {
      if (buttonsLen === idx + 1) {
        // last one
        return `Click to Link/Unlink button ${idx + 1} to button  ${idx}. `;
      } else if (idx === 0) {
        // first one
        return `Linking has no effect on the first button.`;
      } else {
        // not first or last
        return `Click to Link/Unlink button ${idx + 1} to button  ${idx}. `;
      }
    };

    const getLinkIcon = () => {
      if (idx === 0) {
        // first one
        return iconLink;
      }
      // compare button group with previous button, if same = linked
      else if (button.group === buttons[idx - 1].group) {
        return iconLink;
      } else {
        return iconUnLink;
      }
    };

    return (
      <>
        <Table.HeaderCell style={{ paddingLeft: "2px" }}>
          {/* <Popup
            trigger={
              <div
                className={"iconWrapper"}
                style={{
                  height: "100%",
                  backgroundColor: iconBg,
                  color: iconColor,
                }}
              >
                <img src={iconName} alt="" />
                <img
                  className="overlayTopRight"
                  src={iconCOG}
                  alt={iconHint}
                  onClick={(e) => handleClick(e, idx)}
                  style={{ cursor: "pointer" }}
                />
                <img
                  className="overlayBottomLeft"
                  src={iconLink}
                  alt=""
                  onClick={(e) => handleClick(e, idx)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            }
            content={`Click the Link the default light color for button ${
              idx + 1
            }`}
            position="top right" // bottom center
            basic
          /> */}

          <div
            className={"iconWrapper"}
            style={{
              width: "40px", // #REVIEW - this is an arbitrary size, should be calculated in conjunction with color boxes
              backgroundColor: iconBg,
              color: iconColor,
            }}
          >
            <Popup
              trigger={<img src={iconName} alt="" />}
              content={`Button ${idx + 1}`}
              position="top right" // bottom center
              basic
            />
            <Popup
              trigger={
                <img
                  className="overlayTopRight"
                  src={iconCOG}
                  alt={iconHint}
                  onClick={(e) => handleCogClick(e, idx)}
                  style={{ cursor: "pointer" }}
                />
              }
              content={`Click to edit the settings for Button ${idx + 1}`}
              position="top right" // bottom center
              basic
            />

            <Popup
              trigger={
                <img
                  className="overlayBottomLeft"
                  style={
                    idx === 0
                      ? { cursor: "pointer", opacity: "0.5" }
                      : { cursor: "pointer" }
                  }
                  src={getLinkIcon()}
                  alt=""
                  onClick={(e) => handleLinkClick(e, idx)}
                />
              }
              content={getLinkText()}
              position="top right" // bottom center
              basic
            />
          </div>
        </Table.HeaderCell>
      </>
    );
  });
};

const RenderRowDefaults = (props) => {
  const { data: buttons } = props;

  console.log("RenderRowDefaults buttons", buttons);

  if (buttons === undefined) return null;

  const handleClick = (e, idx) => {
    //e.preventDefault();

    console.log("button handleClick idx", idx);
    // update data!
  };

  const colorBoxStyle = {
    borderRadius: 0,
    border: "1px outset black",
    height: "100%",
    margin: ".25em",
  };

  return buttons.map((button, idx) => {
    let buttonColor = "green";
    switch (button.color) {
      case "green":
        buttonColor = "green";
        break;
      case "red":
        buttonColor = "red";
        break;
      case "amber":
      case "orange":
        buttonColor = "orange";
        break;
      case "white":
        buttonColor = "grey";
        break;
      case "no_change":
        buttonColor = "white";
        break;
      case "off":
        buttonColor = "black";
        break;
      default: // #REVIEW - this shouldn't be necessary as I thought I'd caught these previously but THIS MAKES SURE!
        // not "GREEN" as used by the FF controller but "green" used by the semantic ui CSS
        buttonColor = button.color.toLowerCase();
        break;
    }

    return (
      <>
        <Table.HeaderCell style={{ paddingLeft: "2px" }}>
          <Popup
            trigger={
              <Button
                icon
                color={buttonColor}
                style={colorBoxStyle}
                onClick={(e) => handleClick(e, idx)}
              >
                <Icon />
              </Button>
            }
            content={`Click to change the default light color for button ${
              idx + 1
            }`}
            position="top right" // bottom center
            basic
          />
        </Table.HeaderCell>
      </>
    );
  });
};

const RenderRowButtonActives = (props) => {
  const { data: buttons } = props;

  if (buttons === undefined) return null;

  const isCheckSelected = (idx) => {
    const buttonState = buttons !== undefined ? buttons[idx]?.active : false;

    return buttonState;
  };

  const handleCheckClick = (e, idx) => {
    const { active } = buttons[idx];
    if (active !== undefined) {
      if (active) {
        props.setButtonActive(idx, false);
      } else {
        props.setButtonActive(idx, true);
      }
    }
  };

  return buttons.map((button, idx) => {
    return (
      <>
        <Table.HeaderCell
          style={{
            paddingLeft: "18px", // 20/2 -2 + width of checkbox is ~18. This centres checkbox over the colorboxes

            verticalAlign: "middle",
          }}
        >
          <Popup
            trigger={
              <Checkbox
                checked={isCheckSelected(idx)}
                onChange={(e) => handleCheckClick(e, idx)}
              />
            }
            content={`Click enable/disable Button ${idx + 1}`}
            position="top right" // bottom center
            basic
          />
        </Table.HeaderCell>
      </>
    );
  });
};

const RenderRowAddButton = (props) => {
  const { data: buttons } = props;

  if (buttons === undefined) return null;

  const handleAddButtonClick = (e) => {
    e.preventDefault();

    console.log("add button clicked!");

    props.onButtonAdd();
  };

  return (
    <>
      <Table.HeaderCell
        style={{
          paddingLeft: "2px",
        }}
      >
        <Popup
          trigger={
            <Button
              // style={iconButtons}
              icon
              onClick={(e) => handleAddButtonClick(e)}
              color="blue"
            >
              <Icon name={"plus"} />
            </Button>
          }
          content={`Click to add a new button to the named area`}
          position="top right" // bottom center
          basic
        />
      </Table.HeaderCell>
    </>
  );
};

const RenderRowButtonDeletes = (props) => {
  const { data: buttons } = props;

  if (buttons === undefined) return null;

  // const isCheckSelected = (e, idx) => {
  //   //console.log("qqq isDeleteSelected e", e);
  // };

  const handleCheckClick = (e, idx) => {
    //e.preventDefault();
    console.log(`delete button ${idx} clicked!!!`);
  };

  return buttons.map((button, idx) => {
    return (
      <>
        <Table.HeaderCell
          style={{
            paddingLeft: "2px",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Popup
            trigger={
              <Icon
                style={{ cursor: "pointer" }}
                name="trash"
                onClick={(e) => handleCheckClick(e, idx)}
              />
            }
            content={`Click to delete button column ${idx + 1}`}
            position="top right" // bottom center
            basic
          />

          {/* <Checkbox
            checked={isCheckSelected(idx)}
            onChange={(e) => handleCheckClick(e, idx)}
          /> */}
        </Table.HeaderCell>
      </>
    );
  });
};

const RenderRowTop_hack = (props) => {
  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const color = "black";

  const isItemSelected = (e) => {
    //    console.log("qqq isItemSelected e", e);
  };

  const handleSelect = (e) => {
    //   console.log("qqq handleSelect e", e);
  };

  const handleClick = (e, key) => {
    //   console.log("qqq key", key);

    e.preventDefault();

    setOpen(!open);
  };

  const isDeleteSelected = (e) => {
    //console.log("qqq isDeleteSelected e", e);
  };

  const handleDeleteClick = (e, key) => {
    e.preventDefault();

    const row = props.index;

    // update data!
    props.onDeleteClick({ row });
  };

  const handleShowHideClick = (e, key) => {
    e.preventDefault();

    const row = props.index;

    // toggle eye icon on button
    setOpen(!open);

    // update data!
    props.onShowHideClick({ row });
  };

  const eyeIcon = open ? "eye" : "eye slash";

  const iconButtons = {
    padding: "0.5em",
  };

  return (
    <>
      <Table.Cell>
        <Button
          style={iconButtons}
          icon
          onClick={(e) => handleClick(e, props.index)}
        >
          <Icon name={"bars"} />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={isItemSelected(props.index)}
          onChange={(e) => handleSelect(e, props.index)}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          style={iconButtons}
          icon
          onClick={(e) => handleShowHideClick(e, props.index)}
          //toggle
          //active={isShowHideSelected(props.index)}
        >
          <Icon color={color} name={eyeIcon} />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Input
          value={props.data.properties.name} // read only
          name="read only"
        />
      </Table.Cell>
      <RenderRowColorBox
        key={props.index}
        index={props.index}
        data={props.data}
        onColorClick={props.onColorClick}
      />
      <Table.Cell>
        <Checkbox
          checked={isDeleteSelected(props.index)}
          onChange={(e) => handleDeleteClick(e, props.index)}
        />
      </Table.Cell>
    </>
  );
};

const RenderRow = (props) => {
  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const color = "black";

  const isItemSelected = (e) => {
    //    console.log("qqq isItemSelected e", e);
  };

  const handleSelect = (e) => {
    //   console.log("qqq handleSelect e", e);
  };

  const handleClick = (e, key) => {
    //   console.log("qqq key", key);

    e.preventDefault();

    setOpen(!open);
  };

  const isDeleteSelected = (e) => {
    //console.log("qqq isDeleteSelected e", e);
  };

  const handleDeleteClick = (e, key) => {
    e.preventDefault();

    const row = props.index;

    // update data!
    props.onDeleteClick({ row });
  };

  const handleShowHideClick = (e, key) => {
    e.preventDefault();

    const row = props.index;

    // toggle eye icon on button
    setOpen(!open);

    // update data!
    props.onShowHideClick({ row });
  };

  const eyeIcon = open ? "eye" : "eye slash";

  const iconButtons = {
    padding: "0.5em",
  };

  return (
    <>
      <Table.Cell>
        {/* <Button
          style={iconButtons}
          icon
          onClick={(e) => handleClick(e, props.index)}
        >
          <Icon name={"bars"} />
        </Button> */}
        <Popup
          trigger={
            <Icon
              style={{ cursor: "pointer" }}
              name="bars"
              onClick={(e) => handleClick(e, props.index)}
            />
          }
          content={`Grab and drag row to change priority`}
          position="top right" // bottom center
          basic
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <Checkbox
              checked={isItemSelected(props.index)}
              onChange={(e) => handleSelect(e, props.index)}
            />
          }
          content={`Check to enable/disable this region`}
          position="top right" // bottom center
          basic
        />
      </Table.Cell>
      <Table.Cell>
        {/* <Button
          style={iconButtons}
          icon
          onClick={(e) => handleShowHideClick(e, props.index)}
          //toggle
          //active={isShowHideSelected(props.index)}
        >
          <Icon color={color} name={eyeIcon} />
        </Button> */}

        <Popup
          trigger={
            <Icon
              style={{ cursor: "pointer" }}
              name={eyeIcon}
              onClick={(e) => handleShowHideClick(e, props.index)}
            />
          }
          content={`Toggle to show/hide the region`}
          position="top right" // bottom center
          basic
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          trigger={
            <Input
              value={props.data.properties.name} // read only
              name="read only"
            />
          }
          content={`Unique region idenitifier`}
          position="top right" // bottom center
          basic
        />
      </Table.Cell>
      <RenderRowColorBox
        key={props.index}
        index={props.index}
        data={props.data}
        onColorClick={props.onColorClick}
      />
      <Table.Cell>
        <Popup
          trigger={
            <Checkbox
              checked={isDeleteSelected(props.index)}
              onChange={(e) => handleDeleteClick(e, props.index)}
            />
          }
          content={`Check to mark region for deletion`}
          position="top right" // bottom center
          basic
        />
      </Table.Cell>
    </>
  );
};

// priority: integer - order of items in list <-- or just sort the object?
// active: boolean  - checkbox status
// show: boolean - show/hide on map (eye icon)
// name: string - named area polygon shape name <-- could be generated
// button: array of button color settings

const RenderRowColorBox = (props) => {
  //console.log("qqq  RenderRowColorBox props", props);

  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const handleClick = (e, key) => {
    e.preventDefault();

    //let data = props.data;
    const row = props.index;
    const column = key;

    // update data!
    props.onColorClick({ row, column });

    setOpen(!open);
  };

  let colorBoxStyle = {
    borderRadius: 0,
    border: "1px outset black",
    height: "100%",
    margin: ".25em",
  };

  let colorBoxIcon = {};

  const buttons = props.data.properties.button;

  return buttons.map((button, index) => {
    // console.log(
    //   "qqq  RenderRowColorBox buttons.color",
    //   index,
    //   " ",
    //   button.color
    // );

    let buttonColor = "green";
    switch (button.color) {
      case "green":
        buttonColor = "green";
        break;
      case "red":
        buttonColor = "red";
        break;
      case "amber":
      case "orange":
        buttonColor = "orange";
        break;
      case "white":
        buttonColor = "grey";
        break;
      case "no_change":
        buttonColor = "white";
        colorBoxStyle.border = "1px inset white";
        colorBoxIcon = { name: "dont" };
        break;
      case "off":
        buttonColor = "black";
        break;
      default: // #REVIEW - this shouldn't be necessary as I thought I'd caught these previously but THIS MAKES SURE!
        // not "GREEN" as used by the FF controller but "green" used by the semantic ui CSS
        buttonColor = button.color.toLowerCase();
        break;
    }

    // console.log(
    //   "qqq index, button.title, button.color, buttonColor",
    //   index,
    //   button.title,
    //   button.color,
    //   buttonColor
    // );

    return (
      <Table.Cell style={{ paddingLeft: "2px" }}>
        <Popup
          trigger={
            <Button
              icon
              color={buttonColor}
              style={colorBoxStyle}
              onClick={(e) => handleClick(e, index)}
            >
              <Icon {...colorBoxIcon} />
            </Button>
          }
          content={`Click to change the light color for button ${
            index + 1
          } for this region`}
          position="top right" // bottom center
          basic
        />
      </Table.Cell>
    );
  });
};

class NamedAreaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      events: [],
      acks: [],
    };
  }

  componentDidMount() {
    this.setState({ tableData: this.props.namedAreas });
    // this.setState({ acks: this.props.acks });
  }

  componentDidUpdate(prevProps, prevState) {
    // check if namedAreas have been changed
    // properties.status ="drawn" - come from local map state
    // properties.status ="mqtt" - come from system state update (updates only happen if page is not dirty)
    //
    if (
      JSON.stringify(prevProps.namedAreas) !==
      JSON.stringify(this.props.namedAreas)
    ) {
      this.setState({ tableData: this.props.namedAreas });
    }

    if (
      JSON.stringify(prevProps.mineLevelId) !==
      JSON.stringify(this.props.mineLevelId)
    ) {
      console.log("gggg reset it!!");
    }
  }

  render() {
    return (
      <TableMade
        data={this.state.tableData}
        onSendMqtt={(mqttMsg) => this.props.mqttPublish(mqttMsg)}
        namedAreaSetDeleteSelections={(deleteSelection) =>
          this.props.namedAreaSetDeleteSelections(deleteSelection)
        }
        namedAreaClearDeleteSelections={(deleteSelection) =>
          this.props.namedAreaClearDeleteSelections(deleteSelection)
        }
        namedAreaSetShowHideSelections={(hideSelection) =>
          this.props.namedAreaSetShowHideSelections(hideSelection)
        }
        namedAreaClearShowHideSelections={(hideSelection) =>
          this.props.namedAreaClearShowHideSelections(hideSelection)
        }
        namedAreasSetIsDirty={(id) => this.props.namedAreasSetIsDirty(id)}
        namedAreasClearIsDirty={(id) => this.props.namedAreasClearIsDirty(id)}
        parentIsDirty={this.props.parentIsDirty}
        namedAreaIdValue={this.props.namedAreaIdValue}
        namedAreaParentName={this.props.namedAreaParentName}
        buttonGroupState={this.props.buttonGroupState}
        updateButtonGroupState={(buttons) =>
          this.props.updateButtonGroupState(buttons)
        }
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { namedAreaIdValue, mineLevelId, namedAreaParentName } = props;

  // namedAreas direct from mqtt
  //const geoJSONNamedAreasUtm = getNamedAreaInfos(state); // #REVEW - direct connection to mqtt
  const namedAreasReduced = GetMapNamedAreasReducedProperties(state);

  // namedAreas from local map which includes shapes added (but not necessarily in server db)
  const localMapState = GetLocalMap(state);

  // #REVIEW - may not be used. Was WIP for idea. Delete?
  // a subset of local map to allow comparison of common data between mqtt and drawn
  //const localNamedAreasReduced = GetLocalMapNamedAreasReducedProperties(state);

  const { namedArea: parentIsDirty } = getIsDirty(state);

  const namedAreas =
    localMapState.namedAreas !== undefined
      ? localMapState.namedAreas.features
      : [];

  const buttonGroupState = getNamedAreaEventButtonGroupState(state);

  return {
    mineLevelId,
    namedAreaIdValue,
    namedAreaParentName,
    namedAreasReduced,
    //localNamedAreasReduced,
    //geoJSONNamedAreasUtm,
    //localMapState,
    namedAreas,
    parentIsDirty,
    buttonGroupState,
  };
}
export function mapDispatchToProps(dispatch, ownProps) {
  return {
    mqttPublish: (mqttMsg) => {
      dispatch(mqttPublish(mqttMsg));
    },
    namedAreaSetDeleteSelections: (deleteSelection) => {
      dispatch(namedAreaSetDeleteSelections(deleteSelection));
    },
    namedAreaClearDeleteSelections: (deleteSelection) => {
      dispatch(namedAreaClearDeleteSelections(deleteSelection));
    },
    namedAreaSetShowHideSelections: (hideSelection) => {
      dispatch(namedAreaSetShowHideSelections(hideSelection));
    },
    namedAreaClearShowHideSelections: (hideSelection) => {
      dispatch(namedAreaClearShowHideSelections(hideSelection));
    },
    namedAreasSetIsDirty: (id) => {
      dispatch(namedAreasSetIsDirty(id));
    },
    namedAreasClearIsDirty: (id) => {
      dispatch(namedAreasClearIsDirty(id));
    },
    updateButtonGroupState: (buttons) => {
      dispatch(updateButtonGroupState(buttons));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NamedAreaTable);
