import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as Api from "api/apiNamedArea";
import { SubmissionError } from "redux-form";
import { convertFetchNamedAreasToMqtt } from "NamedAreas/convertFetchNamedAreasToMqtt";
import { convertFetchNamedAreaEventsToMqtt } from "NamedAreas/convertFetchNamedAreaEventsToMqtt";
import { isMqttNotApiPost } from "components/ConfigJs";

export function convertFromAPI(namedAreas) {
  return namedAreas.reduce(
    (acc, level) => {
      acc.byId[level.id] = level;
      acc.order.push(level.id);
      return acc;
    },
    { byId: {}, order: [] }
  );
}

// function* fetchMineLevels(action) {
//   try {
//     const namedAreas = yield call(Api.fetchNamedAreas, null);
//     yield put({
//       type: "NAMED_AREAS_FETCH_SUCCEEDED",
//       payload: convertFromAPI(namedAreas),
//     });
//   } catch (e) {
//     yield put({ type: "NAMED_AREAS_FETCH_FAILED", payload: e.message });
//   }
// }

function validate(mineLevelId, areaName, regions) {
  if (!mineLevelId) {
    return new SubmissionError({
      mineLevelId: "Must select a mine level",
      _error: "Must select a mine level",
    });
  }
  if (areaName === "") {
    return new SubmissionError({
      areaName: "Must select a name",
      _error: "Must select a name",
    });
  }
  if (regions.length < 1) {
    return new SubmissionError({
      regions: "Must specify at least one region",
      _error: "Must specify at least one region",
    });
  }
}

function* handleSaveNewNamedArea(action) {
  const { values, resolve, reject } = action.payload;

  try {
    const response = isMqttNotApiPost()
      ? yield Api.saveNewNamedArea(values)
      : yield Api.saveNewNamedAreaPost(values);
    //console.log("handleSaveNewNamedArea saveNewNamedArea response", response);
    resolve(response);
  } catch (error) {
    console.log("ERROR: handleSaveNewNamedArea error", error);
    const formError = new SubmissionError({ _error: error.response.data });
    //console.log("handleSaveNewNamedArea formError", formError);
    reject(error);
  }
}

function* handleSaveNewNamedAreaEvent(action) {
  const { values, resolve, reject } = action.payload;

  try {
    const response = yield Api.saveNewNamedAreaEventPost(values);
    // console.log(
    //   "handleSaveNewNamedAreaEvent saveNewNamedAreaEventPost response",
    //   response
    // );
    resolve(response);
  } catch (error) {
    console.log("ERROR: handleSaveNewNamedAreaEvent error", error);
    reject(error);
  }
}

function* handleSaveNewScheduledEvent(action) {
  const { values, resolve, reject } = action.payload;

  console.log("xxx handleSaveNewScheduledEvent", values);

  try {
    const response = yield Api.saveNewScheduledEventPost(values);
    resolve(response);
  } catch (error) {
    console.log("ERROR: handleSaveNewScheduledEvent error", error);
    reject(error);
  }
}

function* handleSaveNewEmergencyEvent(action) {
  const { values, resolve, reject } = action.payload;

  try {
    const response = yield Api.saveNewEmergencyEventPost(values);
    // console.log(
    //   "handleSaveNewEmergencyEvent saveNewEmergencyEventPost response",
    //   response
    // );
    resolve(response);
  } catch (error) {
    console.log("ERROR: handleSaveNewEmergencyEvent error", error);
    reject(error);
  }
}

// #WIP - not working ATM
// function* handleUpdateNamedArea(action) {
//   const {
//     values: { id, mineLevelId, areaName, regions },
//     resolve,
//     reject,
//   } = action.payload;

//   if (!id) {
//     reject(
//       new SubmissionError({
//         areaName: "Must provide id",
//         _error: "Must provide id",
//       })
//     );
//     return;
//   }
//   const error = validate(mineLevelId, areaName, regions);
//   if (error) {
//     reject(error);
//     return;
//   }

//   try {
//     yield Api.updateNamedArea(id, mineLevelId, areaName, regions);
//     resolve();
//   } catch (error) {
//     const formError = new SubmissionError({ _error: error.response.data });
//     reject(formError);
//   }
// }

// function* handleDeleteNamedArea(action) {
//   const { id } = action.payload;
//   try {
//     yield Api.deleteNamedArea(id);
//     yield put({
//       type: "DELETE_NAMED_AREA_SUCCEEDED",
//       payload: { id },
//     });
//     yield put(push("/admin/named-area"));
//   } catch (error) {
//     yield put({
//       type: "DELETE_NAMED_AREA_FAILED",
//       payload: { error, id },
//     });
//   }
// }

function* handleDeleteNamedArea(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  //console.log(`xxx handleDeleteNamedArea`, id);

  try {
    const response = isMqttNotApiPost()
      ? yield Api.deleteNamedArea(id)
      : yield Api.deleteNamedAreaPost(id);

    yield put({
      type: "DELETE_NAMED_AREA_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "DELETE_NAMED_AREA_FAILED", message: error });
    reject(error);
  }
}

function* handleDeleteScheduledEventJobs(action) {
  console.log(`DELETE_SCHEDULED_EVENT_JOBS_REQUESTED`);
  try {
    const users = yield call(Api.deleteAllScheduledEventJobs);
    console.log(`DELETE_SCHEDULED_EVENT_JOBS_REQUESTED`);
    yield put({
      type: "DELETE_SCHEDULED_EVENT_JOBS_SUCCEEDED",
      payload: { users },
    });
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    if (status === 401) {
      yield put({
        type: "DELETE_SCHEDULED_EVENT_JOBS_FAILED",
        payload: { error: "deleteAllScheduledEventJobs error", extra: data },
      });
    } else {
      yield put({
        type: "DELETE_SCHEDULED_EVENT_JOBS_UNKNOWN",
        payload: { error: "deleteAllScheduledEventJobs error", extra: data },
      });
    }
  }
}

function* handleDeleteScheduledEventJob(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  console.log(`xxx handleDeleteScheduledEventJobs`, id);

  try {
    const response = yield Api.deleteScheduledEventJob(id);

    yield put({
      type: "DELETE_SCHEDULED_EVENT_JOB_SUCCEEDED",
      payload: { id },
    });
    resolve(response);
  } catch (error) {
    yield put({ type: "DELETE_SCHEDULED_EVENT_JOB_FAILED", message: error });
    reject(error);
  }
}

function* fetchNamedAreas(action) {
  console.log(`NAMED_AREAS_FETCH_REQUESTED`);
  try {
    const data = yield call(Api.fetchNamedAreas, null);

    //console.log(`NAMED_AREA_FETCH_SUCCEEDED data `, data);

    // fetchNamedAreas/get_all_named_areas contains data for many states:
    // * namedAreaInfos -> MQTT_NAMED_AREA_INFOS_UPDATE
    // * namedAreaStatuses -> MQTT_NAMED_AREA_STATUSES_UPDATE

    const { namedAreaInfos, namedAreaStatuses } =
      convertFetchNamedAreasToMqtt(data);

    console.log(`NAMED_AREA_FETCH_SUCCEEDED namedAreaInfos`, namedAreaInfos);

    yield put({
      type: "MQTT_NAMED_AREA_INFOS_UPDATE",
      payload: namedAreaInfos || {},
    });

    console.log(
      `NAMED_AREA_FETCH_SUCCEEDED namedAreaStatuses`,
      namedAreaStatuses || {}
    );

    yield put({
      type: "MQTT_NAMED_AREA_STATUSES_UPDATE",
      payload: namedAreaStatuses,
    });

    // used to trigger update to worker with fetch complete
    yield put({
      type: "NAMED_AREA_FETCH_SUCCEEDED",
      payload: {},
    });
  } catch (e) {
    yield put({ type: "NAMED_AREA_FETCH_FAILED", message: e.message });
  }
}

function* fetchNamedAreaEvents(action) {
  console.log(`NAMED_AREA_EVENTS_FETCH_REQUESTED`);
  try {
    const data = yield call(Api.fetchNamedAreaEvents, null);

    console.log(`NAMED_AREA_EVENTS_FETCH_SUCCEEDED data `, data);

    // fetchNamedAreas/get_all_named_areas contains data for many states:
    // * namedAreaEvents -> MQTT_NAMED_AREA_EVENTS_UPDATE

    const { namedAreaEvents } = convertFetchNamedAreaEventsToMqtt(data);

    console.log(
      `NAMED_AREA_EVENTS_FETCH_SUCCEEDED getAllData namedAreaEvents`,
      namedAreaEvents
    );

    yield put({
      type: "MQTT_NAMED_AREA_EVENTS_UPDATE",
      payload: namedAreaEvents || {},
    });

    // used to trigger update to worker with fetch complete
    yield put({
      type: "NAMED_AREA_EVENTS_FETCH_SUCCEEDED",
      payload: {},
    });
  } catch (e) {
    yield put({ type: "NAMED_AREA_EVENTS_FETCH_FAILED", message: e.message });
  }
}

function* fetchNamedAreaTriggers(action) {
  console.log(`NAMED_AREA_TRIGGERS_FETCH_REQUESTED`);
  try {
    const data = yield call(Api.fetchNamedAreaTriggers, null);

    console.log(`NAMED_AREA_TRIGGERS_FETCH_SUCCEEDED data `, data);

    // fetchNamedAreas/get_all_named_areas contains data for many states:
    // * namedAreaTriggers -> NAMED_AREA_TRIGGERS_UPDATE

    const { namedAreaTriggers = {} } = convertFetchNamedAreaEventsToMqtt(data);

    console.log(
      `NAMED_AREA_TRIGGERS_FETCH_SUCCEEDED namedAreaEvents`,
      namedAreaTriggers
    );

    yield put({
      type: "NAMED_AREA_TRIGGERS_UPDATE",
      payload: namedAreaTriggers,
    });

    // used to trigger update to worker with fetch complete
    yield put({
      type: "NAMED_AREA_TRIGGERS_FETCH_SUCCEEDED",
      payload: {},
    });
  } catch (e) {
    yield put({ type: "NAMED_AREA_TRIGGERS_FETCH_FAILED", message: e.message });
  }
}

function* fetchNamedAreaExtTriggerEventInfo(action) {
  console.log(`NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_REQUESTED`);
  try {
    const data = yield call(Api.fetchNamedAreaExtTriggerEventInfo, null);

    console.log(
      `NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_SUCCEEDED data `,
      data
    );

    yield put({
      type: "NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_SUCCEEDED",
      payload: Array.isArray(data) ? data : [],
    });
  } catch (e) {
    yield put({
      type: "NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_FAILED",
      message: e.message,
    });
  }
}

function* fetchNamedAreaScheduledEventInfo(action) {
  console.log(`NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_REQUESTED`);
  try {
    const data = yield call(Api.fetchNamedAreaScheduledEventInfo, null);

    console.log(`NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_SUCCEEDED data `, data);

    yield put({
      type: "NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_SUCCEEDED",
      payload: Array.isArray(data) ? data : [],
    });
  } catch (e) {
    yield put({
      type: "NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_FAILED",
      message: e.message,
    });
  }
}

function* fetchScheduledEventJobs(action) {
  console.log(`SCHEDULED_EVENT_JOBS_FETCH_REQUESTED`);
  try {
    const scheduledEventJobs = yield call(Api.getScheduledEventJobs, null);
    yield put({
      type: "SCHEDULED_EVENT_JOBS_FETCH_SUCCEEDED",
      payload: { scheduledEventJobs },
    });
  } catch (error) {
    console.log(`ERROR`, error);

    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "SCHEDULED_EVENT_JOBS_FETCH_FETCH_FAILED",
          payload: { error: "Scheduled Event Job error", extra: data },
        });
      } else {
        yield put({
          type: "SCHEDULED_EVENT_JOBS_FETCH_UNKNOWN",
          payload: { error: "Scheduled Event error", extra: data },
        });
      }
    }
  }
}

function* saga() {
  yield takeLatest("NAMED_AREAS_FETCH_REQUESTED", fetchNamedAreas);
  yield takeLatest("NAMED_AREA_EVENTS_FETCH_REQUESTED", fetchNamedAreaEvents);
  yield takeLatest(
    "NAMED_AREA_TRIGGERS_FETCH_REQUESTED",
    fetchNamedAreaTriggers
  );
  yield takeLatest(
    "NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_REQUESTED",
    fetchNamedAreaExtTriggerEventInfo
  );
  yield takeLatest(
    "SCHEDULED_EVENT_JOBS_FETCH_REQUESTED",
    fetchScheduledEventJobs
  );
  yield takeEvery("SAVE_NEW_NAMED_AREA", handleSaveNewNamedArea);
  yield takeEvery("SAVE_NEW_NAMED_AREA_EVENT", handleSaveNewNamedAreaEvent);
  yield takeEvery("SAVE_NEW_EMERGENCY_EVENT", handleSaveNewEmergencyEvent);
  yield takeEvery("SAVE_NEW_SCHEDULED_EVENT", handleSaveNewScheduledEvent);

  //yield takeEvery("UPDATE_NAMED_AREA", handleUpdateNamedArea);
  yield takeEvery("DELETE_NAMED_AREA", handleDeleteNamedArea);
  yield takeEvery(
    "DELETE_SCHEDULED_EVENT_JOBS_REQUESTED",
    handleDeleteScheduledEventJobs
  );
  yield takeEvery(
    "DELETE_SCHEDULED_EVENT_JOB_REQUESTED",
    handleDeleteScheduledEventJob
  );
  yield takeLatest(
    "NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_REQUESTED",
    fetchNamedAreaScheduledEventInfo
  );
}

export default saga;
