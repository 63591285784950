export const StatusEnum = {
  OK: 0,
  OLD_STATUS_REPORT: 1,
  NO_STATUS_REPORT: 2,
  DIFFERENT_TOPOLOGY: 3,
  NOT_COMMISSIONED: 4,
  SAME_POSITION: 5,
  NEW: 6,
  INACTIVE: 7,
  TIMEOUT: 8,
  API_FETCH: 9, // data fetched by api call
  SHIFTED_POSITION: 10,
  properties: {
    0: { ok: true },
    1: { warning: true },
    2: { error: true },
    3: { error: true },
    4: { error: true },
    5: { error: true },
    6: { error: true },
    7: { error: true },
    8: { error: true },
    9: { error: true },
    10: { error: true },
  },
};

export const StatusEnumToReadable = {
  0: "OK",
  1: "OLD_STATUS_REPORT",
  2: "NO_STATUS_REPORT",
  3: "DIFFERENT_TOPOLOGY",
  4: "NOT_COMMISSIONED",
  5: "SAME_POSITION",
  6: "NEW",
  7: "INACTIVE",
  8: "TIMEOUT",
  9: "API_FETCH",
  10: "SHIFTED_POSITION",
};
