import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import ListNamedAreaSimple from "admin/named-area-simple/NamedAreaSimpleList";
import CreateNamedAreaSimple from "admin/named-area-simple/NamedAreaSimpleCreatePage";
import EditNamedAreaSimple from "admin/named-area-simple/NamedAreaSimpleEditPage";

import { fetchNamedAreas } from "NamedAreas/actions";

class NamedAreaSimpleAdminPages extends Component {
  render() {
    const { match, namedAreaSubType, strings } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/new`}
          //component={CreateNamedAreaSimple}
          render={(props) => (
            <CreateNamedAreaSimple
              {...props}
              namedAreaSubType={namedAreaSubType}
              strings={strings}
            />
          )}
        />
        <Route
          path={`${match.url}/edit/:id`}
          //component={EditNamedAreaSimple}
          render={(props) => (
            <EditNamedAreaSimple
              {...props}
              namedAreaSubType={namedAreaSubType}
              strings={strings}
            />
          )}
        />
        <Route
          exact
          path={match.url}
          //component={ListNamedAreaSimples}
          render={(props) => (
            <ListNamedAreaSimple
              {...props}
              namedAreaSubType={namedAreaSubType}
              strings={strings}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(
  connect(null, { fetchNamedAreas })(NamedAreaSimpleAdminPages)
);
