// #WIP #TODO - review to replace so no longer use eval()

export function strToObj(str) {
  var obj = {};
  if (str && typeof str === "string") {
    var objStr = str.match(/\{(.)+\}/g);
    eval("obj =" + objStr);
  }
  return obj;
}
