
const PORT_COLORS = {
  '1': 'brown',
  '2': 'olive',
  '3': 'pink',
  '4': 'purple'
}

export const getPortColor = (num) => {
  return PORT_COLORS[num] || 'grey'
}
