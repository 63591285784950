// #TODO - consolidate hardcoded default settings here
// search for "DEFAULT_" or similar
//

export const DEFAULT_SETTINGS = {
  display: {
    columnWidth: 6,
    legend: false,
    hint: true,
    view: "normal",
    sopInterface: true,
  },
  componentState: [],
  application: [],
  namedAreaGroup: [],
  options: [],
};

export const DEFAULT_CRON_EXPRESSION = "0 0 12 * * *";
export const DEFAULT_CRON_EXPRESSION_STOP = "0 1 12 * * *";
