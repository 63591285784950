import React, { Component } from "react";
import PropTypes from "prop-types";
import "leaflet/dist/leaflet.css";

import MapMarker from "components/ColorableMapMarker";
import { Polyline } from "react-leaflet";
import getSemanticColor from "colorpalette";
import find from "lodash/find";
import FireflyName from "containers/FireflyName";
import isEqual from "lodash/isEqual";
import { StatusEnum } from "utils/StatusEnum";

const SEMANTIC_BLACK = getSemanticColor("black");

class UPSPortStringMapLocationEditor extends Component {
  static propTypes = {
    fireflies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        position: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        }).isRequired,
      })
    ).isRequired,
  };

  constructor(props) {
    super(props);
    this.onDragEnded = this.onDragEnded.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps);
  }

  onDragEnded(id, newLocation) {
    const { fireflies } = this.props;
    if (this.props.asIndividuals) {
      const ff = find(fireflies, (ff) => ff.id === id);
      this.props.onRepositioned([{ ...ff, position: newLocation }]);
      return;
    }
    const first = fireflies[0];
    const last = fireflies.slice(-1)[0];
    const numItems = fireflies.length;
    const numIntevals = numItems - 1;
    const startPos = id === first.id ? newLocation : first.position;
    const endPos = id === last.id ? newLocation : last.position;

    const offsetLat = startPos.lat;
    const offsetLng = startPos.lng;

    const dLat = (endPos.lat - startPos.lat) / numIntevals;
    const dLng = (endPos.lng - startPos.lng) / numIntevals;
    const newPositions = [{ ...first, position: startPos }];
    for (var idx = 1; idx < numIntevals; idx++) {
      newPositions.push({
        ...fireflies[idx],
        position: { lat: idx * dLat + offsetLat, lng: idx * dLng + offsetLng },
      });
    }
    newPositions.push({ ...last, position: endPos });
    this.props.onRepositioned(newPositions);
  }

  makeOnDragEnd(id) {
    return ({ target }) => {
      this.onDragEnded(id, target.getLatLng());
    };
  }

  render() {
    const {
      fireflies,
      baseColor,
      asIndividuals = false,
      upsPosition,
    } = this.props;
    const color = getSemanticColor(baseColor);
    const lastIdx = fireflies.length - 1;
    const lineLocations = [{ id: "theups", position: upsPosition }].concat(
      ...fireflies
    );

    return (
      <div>
        {fireflies.map((ff, idx) => {
          const { id, position, topology } = ff;

          // if new marker make 'new' color i.e. yellow
          const markerColor = topology?.deviceStatus?.includes(
            StatusEnum.NOT_COMMISSIONED
          )
            ? "yellow"
            : color;

          return (
            <FireflyMarker
              key={id}
              id={id}
              lat={position.lat}
              lng={position.lng}
              color={markerColor}
              isMoveable={asIndividuals || idx === 0 || idx === lastIdx}
              text={topology.position}
              onDragEnd={this.makeOnDragEnd(id)}
              PopupContent={FireflyName}
            />
          );
        })}
        {lineLocations.slice(1).map(({ id, position }, idx) => {
          const from = position;
          const to = lineLocations[idx].position;
          return (
            <Cable
              from={from}
              to={to}
              color={color}
              key={`joining-line-${id}`}
            />
          );
        })}
      </div>
    );
  }
}

const FireflyMarker = ({
  id,
  lat,
  lng,
  color,
  isMoveable,
  text,
  onDragEnd,
  PopupContent,
}) => {
  const outline = isMoveable ? SEMANTIC_BLACK : color;
  const fillOpacity = isMoveable ? 0.8 : 0.7;
  const size = isMoveable ? 18.0 : 12.0;

  return (
    <MapMarker
      lat={lat}
      lng={lng}
      draggable={isMoveable}
      onDragend={onDragEnd}
      color={outline}
      fillColor={color}
      circleColor={color}
      size={size}
      circleText={text}
      fillOpacity={fillOpacity}
    >
      <PopupContent id={id} />
    </MapMarker>
  );
};

const Cable = ({ from, to, color }) => (
  <Polyline positions={[from, to]} color={color} dashArray={"5,5"} weight={1} />
);

export default UPSPortStringMapLocationEditor;
