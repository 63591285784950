import React, { Component } from "react";
import { connect } from "react-redux";
import MapMarker from "components/ColorableMapMarker";
import selector from "admin/bulk-positioning/UPSBulkEditPage/form-value-selector";

class UPSMoveableMarker extends Component {
  onDragEnded = ({ target }) => {
    const ll = target.getLatLng();
    const { change, name } = this.props;
    change(`${name}.lat`, ll.lat);
    change(`${name}.lng`, ll.lng);
  };

  render() {
    const { position, children } = this.props;
    return (
      <MapMarker
        lat={position.lat}
        lng={position.lng}
        draggable
        color={"blue"}
        size={20}
        circleText={"C"}
        onDragend={this.onDragEnded}
      >
        {children}
      </MapMarker>
    );
  }
}

UPSMoveableMarker = connect((state, props) => ({
  position: selector(state, props.name),
}))(UPSMoveableMarker);

export default UPSMoveableMarker;
