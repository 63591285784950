import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiSystem";
import { SubmissionError } from "redux-form";

function* callRunScript({ payload }) {
  console.log(`SERVER_RUN_SCRIPT_REQUESTED - payload`, payload);
  try {
    const response = yield Api.postRunScript(payload);

    const { data } = response;
    yield put({
      type: "SERVER_RUN_SCRIPT_SUCCEEDED",
      payload: { data },
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "SERVER_RUN_SCRIPT_FAILED",
          payload: { error: "callRunScript error", extra: data },
        });
      } else {
        yield put({
          type: "SERVER_RUN_SCRIPT_UNKNOWN",
          payload: { error: "callRunScript error", extra: data },
        });
      }
    }
  }
}

function* toolsSagas() {
  yield takeEvery("SERVER_RUN_SCRIPT_REQUESTED", callRunScript);
}

export default toolsSagas;
