export const normalise = (value, type = "integer") => {
  switch (type) {
    case "stringNoSpace":
      return value.replace(" ", "_");
    case "integer":
      return (value && Number(value)) || 0;
    case "float":
      //	see - onlyDecimal
      return value;
    case "char":
      return (
        (value.match(/^[A-Za-z]+$/) &&
          value.toString().toUpperCase().slice(0, 1)) ||
        ""
      );
    default:
      return value;
  }
};

// see - https://github.com/redux-form/redux-form/issues/1218

export const onlyDecimal = (value) => {
  let result;

  value = value.replace(/[^0-9.]/g, ""); // Remove all chars except numbers and .

  // Create an array with sections split by .
  const sections = value.split(".");

  // Remove any leading 0s apart from single 0
  if (sections[0] !== "0" && sections[0] !== "00") {
    sections[0] = sections[0].replace(/^0+/, "");
  } else {
    sections[0] = "0";
  }

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    result = sections[0] + "." + sections[1].slice(0, 2);

    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf(".") !== -1) {
    result = sections[0] + ".";

    // Otherwise, return only section
  } else {
    result = sections[0];
  }

  //console.log(`xxxx result`, result);
  return result;
};

// ----------------------------------------------------------------------
// Validation function for want a better place to put ThemeProvider
// ----------------------------------------------------------------------
// see - https://redux-form.com/7.0.4/examples/fieldlevelvalidation/
//
export const required = (value) => (value ? undefined : "Required");
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
//e.g.   const maxLength15 = maxLength(15)
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
// e.g. const minLength2 = minLength(2)
export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const minValueEqual = (min) => (value) =>
  value && value < min ? `Must be >= ${min}` : undefined;
export const maxValueEqual = (max) => (value) =>
  value && value > max ? `Must be <= ${max}` : undefined;
// e.g. const minValue18 = minValue(18)

// example functions
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
const tooOld = (value) =>
  value && value > 65 ? "You might be too old for this" : undefined;
const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;
const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;
const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;
const uniqueEmail = (value, allValues, props, name) =>
  props.allUserEmails.includes(value)
    ? "This email has already been used"
    : undefined;

export const numberNormalize = (value) => {
  if (value === "") {
    return "";
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums === "") {
    return "";
  }
  return Number(onlyNums);
};

export const numberLetterNormalize = (value) => {
  if (!value) {
    return value;
  }

  let patt1 = /[a-zA-Z_0-9\-]/g;

  let result = value.match(patt1);
  let validString = null;
  if (result != null) {
    validString = result.join("");
  }
  //console.log("result, validString",result,validString)
  return validString;
};
export const IPAddrNormalize = (value) => {
  if (!value) {
    return value;
  }

  let result = value.match(/[\d.]/g);
  let validString = null;
  if (result != null) {
    validString = result.join("");
  }
  //console.log("result, validString",result,validString)
  return validString;
};
export const macAddressNormalize = (value) => {
  if (!value) {
    return value;
  }

  let patt1 = /[a-fA-F_0-9\:]/g;

  let result = value.match(patt1);
  let validString = null;
  if (result != null) {
    validString = result.join("");
  }
  return validString;
};
export const httpAddressNormalize = (value) => {
  if (!value) {
    return value;
  }

  let patt1 = /[a-zA-Z_0-9\-\.\:\/]/g;

  let result = value.match(patt1);
  let validString = null;
  if (result != null) {
    validString = result.join("");
  }
  return validString;
};

export const filePathNormalize = (value) => {
  if (!value) {
    return value;
  }

  let patt1 = /[a-zA-Z_0-9\-\:\/\.\~]/g;

  let result = value.match(patt1);
  let validString = null;
  if (result != null) {
    validString = result.join("");
  }
  return validString;
};
