import { LeastSquaresApproximationFourPointsSixParametersTransformation } from "projection";

export const transformGeoJsonUtmToPixels = (
  geoJson,
  areaStatuses,
  scale = 1,
  transpose = false
) => {
  let geoJsonTransform = [];
  geoJson.features.map((value, idx) => {
    let newFeature = value;

    // transform the point from latlng to pixels
    const arrayLatLng = newFeature.geometry.coordinates.slice();

    // check if there is an area record
    const area = newFeature.properties.area;
    const isAreaUndefined = areaStatuses[area] === undefined;

    // check if rx an un positioned marker i.e. [0,0]
    const isAllZero = arrayLatLng.every((item) => item === 0);

    // if (isAllZero) {
    //   console.log(
    //     "isAllZero feature",
    //     newFeature.properties.id,
    //     JSON.stringify(newFeature)
    //   );
    // }
    // check if map has the holding pen setup

    // #REVIEW/TODO #WIP
    // make sure there is a default pen point
    let randomHoldingPenCoordinate = [0, 0];
    // why is this undefined on startup? img is defined ok?!
    // if (this.holdingPen !== undefined) {
    //   const hasHoldingPen = this.map.hasLayer(this.holdingPen);
    //   if (isAllZero && hasHoldingPen) {
    //     randomHoldingPenCoordinate = this.randomPointInPoly(this.holdingPen)
    //       .geometry.coordinates;
    //     console.log(
    //       "randomholdingPenCoordinate",
    //       newFeature.properties.id,
    //       randomHoldingPenCoordinate
    //     );
    //   }
    // }

    // 1D array
    let arrayPoints = [];
    const shapeType = newFeature.geometry.type;
    switch (shapeType.toLowerCase()) {
      case "point":
        // convert to LatLng, transform, then convert back to array

        if (!isAllZero && !isAreaUndefined) {
          const transformX = areaStatuses[area].transformUtmToPixels;
          arrayPoints = Object.values(
            transformX.transform({
              lat: arrayLatLng[0] / scale, // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
              lng: arrayLatLng[1] / scale,
            })
          );

          arrayPoints = transpose ? arrayPoints.reverse() : arrayPoints;
        } else {
          arrayPoints = randomHoldingPenCoordinate;
        }

        break;
      case "polygon":
        // iterate the geojons coord array

        // 2D array
        //arrayPoints = [[]];
        arrayLatLng.forEach((value, i, arr) => {
          arrayPoints.push([]);
          value.forEach((value, j) => {
            // convert to LatLng, transform, then convert back to array

            let arrayPoint;
            if (!isAllZero && !isAreaUndefined) {
              const transformX = areaStatuses[area].transformUtmToPixels;
              arrayPoint = Object.values(
                transformX.transform({
                  lat: value[0] / scale, // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                  lng: value[1] / scale,
                })
              );

              arrayPoint = transpose ? arrayPoint.reverse() : arrayPoint;
            } else {
              arrayPoint = randomHoldingPenCoordinate;
            }

            // accumulate results
            arrayPoints[i][j] = arrayPoint;
          });
        });
        break;
      case "multipolygon":
        // iterate the geojons coord array
        // Bracketing for MultiPolylines and MultiPolygons can get confusing.
        // You need brackets to hold the MultiPolyline or MultiPolygon, brackets for each polyline or polygon,
        // and brackets for each latitude and longitude.

        // https://leafletjs.com/examples/geojson/
        //
        // example working coordinates broken down to two separated polygons
        // from http://jsfiddle.net/erictheise/VaGy5/

        // 3D array
        // console.log("arrayLatLng", JSON.stringify(arrayLatLng));
        arrayLatLng.forEach((i_value, i, arr) => {
          arrayPoints.push([]);
          // console.log("----------------------------------");
          // console.log("i_value", i, i_value, JSON.stringify(arrayPoints));

          i_value.forEach((j_value, j) => {
            arrayPoints[i].push([]);
            // console.log("----------------------------------");
            // console.log(
            //   "     j_value",
            //   j,
            //   j_value,
            //   JSON.stringify(arrayPoints)
            // );
            j_value.forEach((k_value, k) => {
              // console.log("----------------------------------");
              // console.log(
              //   "        k_value",
              //   k,
              //   k_value,
              //   JSON.stringify(arrayPoints)
              // );

              // convert to LatLng, transform, then convert back to array
              let arrayPoint;
              if (!isAllZero && !isAreaUndefined && true) {
                const transformX = areaStatuses[area].transformUtmToPixels;
                arrayPoint = Object.values(
                  transformX.transform({
                    lat: k_value[0] / scale, // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                    lng: k_value[1] / scale,
                  })
                );

                arrayPoint = transpose ? arrayPoint.reverse() : arrayPoint;
              } else {
                arrayPoint = randomHoldingPenCoordinate;
              }
              // accumulate results
              // console.log(
              //   "           arrayPoints",
              //   i,
              //   j,
              //   k,
              //   " -> ",
              //   JSON.stringify(arrayPoint)
              // );
              arrayPoints[i][j].push(arrayPoint);
              // console.log(
              //   "           arrayPoints",
              //   i,
              //   j,
              //   k,
              //   " -> ########",
              //   JSON.stringify(arrayPoints),
              //   "#########"
              // );
            });
          });
        });
        break;
      default:
        break;
    }

    // update geometry

    newFeature.geometry.coordinates = arrayPoints;
    geoJsonTransform.push(newFeature);
  });

  return {
    type: "FeatureCollection",
    features: geoJsonTransform,
  };
};

export const transformGeoJsonUtmToPixelsByChangeList = (
  prevFeature,
  geoJson,
  changeList,
  areaStatuses
) => {
  let geoJsonTransform = [];
  geoJson.features.map((value, idx) => {
    const {
      properties: { id },
    } = value;

    let updateList = changeList;

    //
    if (updateList.includes(id)) {
      // delete array item after processing to speed search time next round
      //
      updateList = updateList.filter((item) => item !== id);
      //
      //
      let newFeature = value;
      // transform the point from latlng to pixels
      const arrayLatLng = newFeature.geometry.coordinates.slice();

      // check if there is an area record
      const area = newFeature.properties.area;
      const isAreaUndefined = areaStatuses[area] === undefined;

      // check if rx an un positioned marker i.e. [0,0]
      const isAllZero = arrayLatLng.every((item) => item === 0);

      // holding pen stuff.........

      let randomHoldingPenCoordinate = [0, 0];

      // 1D array
      let arrayPoints = [];
      const shapeType = newFeature.geometry.type;
      switch (shapeType.toLowerCase()) {
        case "point":
          // convert to LatLng, transform, then convert back to array

          if (!isAllZero && !isAreaUndefined) {
            const transformX = areaStatuses[area].transformUtmToPixels;
            arrayPoints = Object.values(
              transformX.transform({
                lat: arrayLatLng[0], // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                lng: arrayLatLng[1],
              })
            );
          } else {
            arrayPoints = randomHoldingPenCoordinate;
          }

          break;
        case "polygon":
          // iterate the geojons coord array

          // 2D array
          //arrayPoints = [[]];
          arrayLatLng.forEach((value, i, arr) => {
            arrayPoints.push([]);
            value.forEach((value, j) => {
              // convert to LatLng, transform, then convert back to array
              let arrayPoint;
              if (!isAllZero && !isAreaUndefined) {
                const transformX = areaStatuses[area].transformUtmToPixels;
                arrayPoint = Object.values(
                  transformX.transform({
                    lat: value[0], // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                    lng: value[1],
                  })
                );
              } else {
                arrayPoint = randomHoldingPenCoordinate;
              }

              // accumulate results
              arrayPoints[i][j] = arrayPoint;
            });
          });
          break;
        case "multipolygon":
          // iterate the geojons coord array
          // ........................

          // 3D array
          // console.log("arrayLatLng", JSON.stringify(arrayLatLng));
          arrayLatLng.forEach((i_value, i, arr) => {
            arrayPoints.push([]);
            i_value.forEach((j_value, j) => {
              arrayPoints[i].push([]);
              j_value.forEach((k_value, k) => {
                // convert to LatLng, transform, then convert back to array
                let arrayPoint;
                if (!isAllZero && !isAreaUndefined && true) {
                  const transformX = areaStatuses[area].transformUtmToPixels;
                  arrayPoint = Object.values(
                    transformX.transform({
                      lat: k_value[0], // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                      lng: k_value[1],
                    })
                  );
                } else {
                  arrayPoint = randomHoldingPenCoordinate;
                }
                arrayPoints[i][j].push(arrayPoint);
              });
            });
          });
          break;
        default:
          break;
      }

      // update geometry
      newFeature.geometry.coordinates = arrayPoints;
      geoJsonTransform.push(newFeature);
      //
    } else {
      geoJsonTransform.push(prevFeature[id]);
    }
    //
  });

  return {
    type: "FeatureCollection",
    features: geoJsonTransform,
  };
};

export const transformGeoJsonPixelsToUtm = (geoJson, areaStatuses) => {
  let geoJsonTransform = [];
  let error = false;
  geoJson.features.map((value, idx) => {
    let newFeature = value;
    // transform the point from pixels to pixels
    const arrayLatLng = newFeature.geometry.coordinates.slice();

    // check if there is an area record
    const area = newFeature.properties.area;
    const isAreaUndefined = areaStatuses[area] === undefined;

    let arrayPoints = [];
    const shapeType = newFeature.geometry.type;
    switch (shapeType.toLowerCase()) {
      case "point":
        // convert to LatLng, transform, then convert back to array

        if (!isAreaUndefined) {
          const transformX = areaStatuses[area].transformPixelsToUtm;
          arrayPoints = Object.values(
            transformX.transform({
              // lat: arrayLatLng[1], // <-- **NOTE: lat & lng are swapped! **
              // lng: arrayLatLng[0],
              lat: arrayLatLng[1], // <-- **NOTE: lat & lng are swapped! **
              lng: arrayLatLng[0],
            })
          ).reverse();
        } else {
          error = true;
        }
        break;
      case "polygon":
        // iterate the geojons coord array
        arrayLatLng.forEach((value, i, arr) => {
          arrayPoints.push([]);
          value.forEach((value, j) => {
            // convert to LatLng, transform, then convert back to array
            let arrayPoint;
            if (!isAreaUndefined) {
              const transformX = areaStatuses[area].transformPixelsToUtm;
              arrayPoint = Object.values(
                transformX.transform({
                  // lat: value[1], // <-- **NOTE: lat & lng are swapped! **
                  // lng: value[0],
                  lat: value[1], // <-- **NOTE: lat & lng are swapped! **
                  lng: value[0],
                })
              ).reverse();
            } else {
              error = true;
            }

            // accumulate results
            arrayPoints[i][j] = arrayPoint;
          });
        });
        break;
      default:
        break;
    }
    // update geometry
    newFeature.geometry.coordinates = arrayPoints;
    geoJsonTransform.push(newFeature);
  });
  if (error) {
    return geoJson; // do nothing
  } else {
    return {
      type: "FeatureCollection",
      features: geoJsonTransform,
    };
  }
};

const standardPtsUtm = () => {
  // #TODO - this is duplicated in localPts()
  const ref1utm = {
    northing: 9549076.153,
    easting: 736959.54,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const ref2utm = {
    northing: 9549315.041,
    easting: 737526.058,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const ref3utm = {
    northing: 9548885.911,
    easting: 737883.021,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const ref4utm = {
    northing: 9548630.213,
    easting: 737564.472,
    zoneNum: 53,
    zoneLetter: "M",
  };

  // rest UTM values
  const standardRef1 = { lat: ref1utm.easting, lng: ref1utm.northing };
  const standardRef2 = { lat: ref2utm.easting, lng: ref2utm.northing };
  const standardRef3 = { lat: ref3utm.easting, lng: ref3utm.northing };
  const standardRef4 = { lat: ref4utm.easting, lng: ref4utm.northing };

  const standardAll = [standardRef1, standardRef2, standardRef3, standardRef4];

  return standardAll;
};

export const localPts = () => {
  // measured from pixel positions
  const localRef1 = {
    lat: 4764,
    lng: 4202,
    easting: 736959.54,
    northing: 9549076.153,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const localRef2 = {
    lat: 3236,
    lng: 7815,
    easting: 737526.058,
    northing: 9549315.041,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const localRef3 = {
    lat: 5978,
    lng: 10090,
    easting: 737883.021,
    northing: 9548885.911,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const localRef4 = {
    lat: 7611,
    lng: 8061,
    easting: 737564.472,
    northing: 9548630.213,
    zoneNum: 53,
    zoneLetter: "M",
  };
  return [localRef1, localRef2, localRef3, localRef4];
};

export const standardPtsUtmX = (mineLevel) => {
  if (mineLevel?.ref_coord === undefined || mineLevel?.ref_coord === null) {
    return {};
  }
  const { ref_coord } = mineLevel;

  let result = {};
  ref_coord.forEach((coord) => {
    const indexArray = coord.id.split(":"); // e.g. "DMLZ_Extaction:1"
    const index = indexArray[1];

    result[index] = { lat: coord.utm[0], lng: coord.utm[1] };
  });

  // sort object by key
  Object.keys(result)
    .sort()
    .forEach(function (v, i) {
      //console.log(v, result[v]);
    });

  // return the values
  return Object.values(result);
};

export const localPtsX = (mineLevel) => {
  if (mineLevel?.ref_coord === undefined || mineLevel?.ref_coord === null) {
    return {};
  }

  const { ref_coord } = mineLevel;

  let result = {};
  ref_coord.forEach((coord) => {
    const indexArray = coord.id.split(":"); // e.g. "DMLZ_Extaction:1"
    const index = indexArray[1];

    result[index] = {
      id: coord.id,
      lat: coord.image_xy[0],
      lng: coord.image_xy[1],
      easting: coord.utm[0],
      northing: coord.utm[1],
      zoneNum: coord.utm_zone_number,
      zoneLetter: coord.utm_zone_letter,
    };
  });

  // sort object by key
  Object.keys(result)
    .sort()
    .forEach(function (v, i) {
      //console.log(v, result[v]);
    });

  // return the values
  return Object.values(result);
};

// the geometric coordinate to map pixel transformation
const TransformUtmToPixels = (localPts, standardPts) => {
  const LeastSq4Pt =
    LeastSquaresApproximationFourPointsSixParametersTransformation.fromPoints(
      localPts,
      standardPts
    );
  return LeastSq4Pt;
};

// the map pixel to geometric coordinate transformation
const TransformPixelsToUtm = (localPts, standardPts) => {
  const LeastSq4Pt =
    LeastSquaresApproximationFourPointsSixParametersTransformation.fromPoints(
      standardPts,
      localPts
    );
  return LeastSq4Pt;
};

export const transformUtmToPixels = (area) =>
  TransformUtmToPixels(localPtsX(area), standardPtsUtmX(area));

export const transformPixelsToUtm = (area) =>
  TransformPixelsToUtm(localPtsX(area), standardPtsUtmX(area));
