import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Form,
  Container,
  Button,
  Icon,
  Segment,
  Header,
  Message,
  Grid,
  Checkbox,
} from "semantic-ui-react";

import { Slider } from "react-semantic-ui-range";
import "semantic-ui-css/semantic.min.css";

import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";

import {
  renderInput,
  renderSelect,
  renderField,
  renderRadio,
  renderCheckbox,
} from "admin/form-field";

import { SaveButton } from "admin/SaveButton";
import { DeleteButton } from "admin/DeleteButton";

import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging

import { getUserData, isUserSuper, isUserAdmin } from "auth/reducer";
import { getUserById, getAllUsers } from "components/UserAdmin/reducer";
import {
  deleteUser,
  changeRole,
  changePassword,
  changeName,
  changeLanguage,
  changeSettings,
  fetchAllUsers,
} from "components/UserAdmin/actions";

import _isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import hash from "object-hash";

import NavigationPromptModal from "admin/NavigationPromptModal";

import {
  allowedLanguages as _allowedLanguages,
  allowedRoles as _allowedRoles,
  allowedApplications as _allowedApplications,
  allowedFeatures as _allowedFeatures,
} from "components/ConfigJs";

const required = (value) => (value ? undefined : "Required");
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const uniqueEmail = (value, allValues, props, name) =>
  props.allUserEmails.includes(value)
    ? "This email has already been used"
    : undefined;

const validate = (values) => {
  const required = ["username", "email", "role", "language"];

  const errors = {};

  required.forEach((r) => {
    if (!get(values, r)) {
      set(errors, r, "Required");
    }
  });

  if (get(values, "password") !== get(values, "passwordConfirm")) {
    set(errors, "passwordConfirm", "Does not match");
  }

  //console.log("@@ validate errors", errors);

  return errors;
};

class UserEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 6,
      hidden: true,
      submitted: false,
      //language: "en-US", // localisation
    };
  }

  componentDidMount() {
    const { initialize, initialValues } = this.props;
    initialize(initialValues);
  }

  componentDidUpdate(prevProps, prevState) {
    //
  }

  submitMyForm = (values) => {
    const { email: id, username, role, language, password, settings } = values;

    const {
      changeRole,
      changePassword,
      changeName,
      changeLanguage,
      changeSettings,
      initialValues,
      isSuper,
    } = this.props;

    //console.log("changes values", values);

    // update user
    let changes = [];

    if (username !== initialValues.username) {
      changes.push({
        id: id,
        type: "changeName",
        username: username,
      });
    }

    if (role !== initialValues.role) {
      changes.push({
        id: id,
        type: "changeRole",
        role: role,
      });
    }

    if (language !== initialValues.language) {
      changes.push({
        id: id,
        type: "changeLanguage",
        language: language,
      });
    }

    if (password !== initialValues.password) {
      changes.push({
        id: id,
        type: "changePassword",
        password: password,
      });
    }

    if (JSON.stringify(settings) !== JSON.stringify(initialValues.settings)) {
      changes.push({
        id: id,
        type: "changeSettings",
        settings: settings,
      });
    }

    let promiseArray = [];

    changes.forEach((change) => {
      //console.log("change ... ", change);
      const { id, type, username, role, password, language, settings } = change;

      switch (type) {
        case "changeRole":
          promiseArray.push(
            new Promise((resolve, reject) => {
              changeRole({
                values: { id, role },
                resolve,
                reject,
              });
            })
          );
          break;
        case "changePassword":
          promiseArray.push(
            new Promise((resolve, reject) => {
              changePassword({
                values: { id, password },
                resolve,
                reject,
              });
            })
          );

          break;
        case "changeName":
          promiseArray.push(
            new Promise((resolve, reject) => {
              changeName({
                values: { id, username },
                resolve,
                reject,
              });
            })
          );

          break;
        case "changeLanguage":
          promiseArray.push(
            new Promise((resolve, reject) => {
              changeLanguage({
                values: { id, language },
                resolve,
                reject,
              });
            })
          );

          break;
        case "changeSettings":
          promiseArray.push(
            new Promise((resolve, reject) => {
              changeSettings({
                values: { id, settings },
                resolve,
                reject,
              });
            })
          );
          break;
        default:
          break;
      }
    });

    return Promise.all(promiseArray).then((results) => {
      //console.log(`onSubmitSuccess ${results}`);
      // wait for state change before redirecting page
      this.setState(
        {
          submitted: true,
        },
        () => {
          this.props.fetchAllUsers();
          if (isSuper) {
            this.props.goto("/user/admin");
          } else {
            this.props.goto(`/user/profile/${id}`);
          }
        }
      );
    });
    // .catch((error) => {
    //   console.log(`onSubmit ${error}`);
    //   this.onSetError("failed");
    //   // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
    //   throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
    // });

    //console.log("changes list", changes);
    // return new Promise((resolve, reject) => {
    // update api
    // changeRole({ id, role })
    // changePassword({ id, password })
    // changeName({ id, name })
    // changeLanguage({ id, language })
    // changeSettings({ id, settings })

    //   changes.forEach((change) => {
    //     //console.log("change ... ", change);
    //     const {
    //       id,
    //       type,
    //       username,
    //       role,
    //       password,
    //       language,
    //       settings,
    //     } = change;

    //     switch (type) {
    //       case "changeRole":
    //         changeRole({
    //           values: { id, role },
    //           resolve,
    //           reject,
    //         });
    //         break;
    //       case "changePassword":
    //         changePassword({
    //           values: { id, password },
    //           resolve,
    //           reject,
    //         });
    //         break;
    //       case "changeName":
    //         changeName({
    //           values: { id, username },
    //           resolve,
    //           reject,
    //         });
    //         break;
    //       case "changeLanguage":
    //         changeLanguage({
    //           values: { id, language },
    //           resolve,
    //           reject,
    //         });
    //         break;
    //       case "changeSettings":
    //         changeSettings({
    //           values: { id, settings },
    //           resolve,
    //           reject,
    //         });
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   // update positions

    //   return resolve(
    //     (function () {
    //       console.log("saved users edit page");
    //       return null;
    //     })()
    //   );
    //   //------------
    // }).then(
    //   () => {
    //     if (isSuper) {
    //       this.props.goto("/user/admin");
    //     } else {
    //       this.props.goto(`/user/profile/${id}`);
    //     }
    //   },
    //   (msg) => {
    //     console.log("action failed", msg); // TODO probs should show this?
    //   }
    // );
  };

  cancelForm = (e) => {
    e.preventDefault();

    const { isSuper } = this.props;
    if (isSuper) {
      this.props.goto("/user/admin");
    } else {
      this.props.goto(``); // go home
    }
  };

  changePassword = (e) => {
    e.preventDefault();
    //
  };

  toggleShow = (state) => {
    this.setState({ hidden: !this.state.hidden });
  };

  requestDelete = (id) => {
    const { deleteUser, goto } = this.props;

    return new Promise((resolve, reject) => {
      deleteUser({ values: { id }, resolve, reject });
    }).then(
      () => {
        console.log("successful User delete");
        this.props.fetchAllUsers();
        goto("/user/admin");
      },
      (msg) => {
        console.log("delete user failed", msg); // TODO probs should show this?
      }
    );
  };

  onChangeApplication = (e, data) => {
    const { stateValueApplication, change } = this.props;
    const { optionKey } = data;

    let newApplication = [...stateValueApplication];

    // toggle - remove if exists
    if (newApplication.includes(optionKey)) {
      newApplication = newApplication.filter((app) => app !== optionKey);
    } else {
      newApplication.push(optionKey);
    }

    change("settings.application", newApplication);
  };

  applicationRadiosContent = (
    options,
    stateValueApplication,
    isAdmin,
    isSuper
  ) => {
    let content = [];

    if (options.length === 0) {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={"warning"} style={{ marginRight: "10px" }} />
          {this.props.strings?.["No applications enabled"]}
        </Form.Field>
      );
    }

    options.map((option) => {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={option.icon} />
          <Checkbox
            optionKey={option.key}
            label={option.text}
            checked={stateValueApplication?.includes(option.value)}
            onChange={this.onChangeApplication}
            Checkbox
            disabled={!isAdmin && !isSuper}
          />
        </Form.Field>
      );
    });

    return (
      <Grid style={{ padding: "10px" }}>
        <Form>
          <Grid.Row>
            <Grid.Column>
              <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                {this.props.strings?.["Applications"]}
              </div>
            </Grid.Column>
          </Grid.Row>
          {content}
        </Form>
      </Grid>
    );
  };

  onChangeFeature = (e, data) => {
    const { stateValueFeature, change } = this.props;
    const { optionKey } = data;

    let newFeature = [...stateValueFeature];

    // toggle - remove if exists
    if (newFeature.includes(optionKey)) {
      newFeature = newFeature.filter((app) => app !== optionKey);
    } else {
      newFeature.push(optionKey);
    }

    change("settings.feature", newFeature);
  };

  featureRadiosContent = (options, stateValueFeature, isAdmin, isSuper) => {
    let content = [];

    if (options.length === 0) {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={"warning"} style={{ marginRight: "10px" }} />
          {this.props.strings?.["No features enabled"]}
        </Form.Field>
      );
    }

    options.map((option) => {
      content.push(
        <Form.Field inline style={{ marginBottom: "5px" }}>
          <Icon name={option.icon} />
          <Checkbox
            optionKey={option.key}
            label={option.text}
            checked={stateValueFeature?.includes(option.value)}
            onChange={this.onChangeFeature}
            Checkbox
            disabled={!isAdmin && !isSuper}
          />
        </Form.Field>
      );
    });

    return (
      <Grid style={{ padding: "10px" }}>
        <Form>
          <Grid.Row>
            <Grid.Column>
              <div style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                {this.props.strings?.["Features"]}
              </div>
            </Grid.Column>
          </Grid.Row>
          {content}
        </Form>
      </Grid>
    );
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
      error,
      allUserEmails,
      isSuper,
      isAdmin,
      isThisUser,
      initialValues,
      dataError, // Not to be confused with the form `error`
      strings, // localisation
      emailId,
      columnWidth,
      stateValueApplication,
      stateValueFeature,
    } = this.props;

    // APP_TERMINOLOGY

    if (dataError) {
      this.props.goto(""); // redirect to home page
      // #NOTE #TODO - should pop a dlg if this happens
      return null;
      //   return (
      //   <>
      //     <DataLoadingMessage id={id} strings={strings} />
      //     <DebugPagePropsMessages that={this} />
      //   </>
      // );
    }

    const settings = {
      start: columnWidth,
      min: 0,
      max: 16,
      step: 2,
      onChange: (value) => {
        //console.log("value", value);
        this.props.change("settings.display.columnWidth", value);
      },
    };

    const allowedLanguages = _allowedLanguages();
    const allowedRoles = _allowedRoles();
    const allowedApplications = _allowedApplications();
    const allowedFeatures = _allowedFeatures();

    return (
      <>
        <NavigationPromptModal
          formName={"userEditForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <Container fluid>
          {error && <Message error header="Error submitting" content={error} />}
          <Form
            onSubmit={handleSubmit(this.submitMyForm)}
            error={Boolean(error)}
          >
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column stretched>
                  <Segment>
                    <Header as="h3">{strings?.["User Details"]}</Header>
                    <Field
                      name="username"
                      label={strings?.["Name"]} // "Name"
                      placeholder={strings?.["User Name"]}
                      component={renderInput}
                      validate={[required]}
                    />
                    <Field
                      name="email"
                      label={strings?.["Email Address"]} // "Email address"
                      placeholder={strings?.["name@company.com"]} // 'e.g. "name@company.com"'
                      component={renderInput}
                      allUserEmails={allUserEmails}
                      disabled
                      //className="disabled-form-field"
                      // #NOTE - can not edit email, only delete and re-add
                      //validate={[required, email, uniqueEmail]}
                    />
                    <Field
                      name="role"
                      label={strings?.["Role"]}
                      placeholder={strings?.["User's Role"]}
                      component={renderSelect}
                      options={
                        isSuper
                          ? allowedRoles
                          : allowedRoles.filter(
                              (option) => option.value !== "super"
                            )
                      }
                      validate={[required]}
                      //className={!isSuper ? "disabled-form-field" : null}
                      disabled={!isAdmin && !isSuper}
                    />
                    <Field
                      style={{ paddingTop: "6px" }}
                      name={"language"}
                      label={strings?.["Language"]} // "Language"
                      placeholder={strings?.["Language"]} // "Language"
                      component={renderSelect}
                      options={allowedLanguages}
                      validate={[required]}
                    />
                  </Segment>
                  <Segment>
                    <Header a="h3">{strings?.["Change Password"]}</Header>
                    <Field
                      name={"password"}
                      label={strings?.["New Password"]} // "New Password"
                      placeholder={strings?.["Enter a new user password"]} // "Enter a new user password"
                      component={renderInput}
                      //validate={[required]}
                      type={this.state.hidden ? "password" : "text"}
                      icon={
                        <Icon
                          name={this.state.hidden ? "eye" : "eye slash"}
                          link
                          onClick={this.toggleShow}
                        />
                      }
                    />
                    <Field
                      name={"passwordConfirm"}
                      label={strings?.["Confirm New Password"]} // "Confirm New Password"
                      placeholder={
                        strings?.["Reenter your new password to confirm"]
                      } // "Reenter your new password to confirm"
                      component={renderInput}
                      type={this.state.hidden ? "password" : "text"}
                      icon={
                        <Icon
                          name={this.state.hidden ? "eye" : "eye slash"}
                          link
                          onClick={this.toggleShow}
                        />
                      }
                      //validate={[required]}
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment>
                    <Grid columns={2}>
                      <Grid.Column width={4}>
                        <Grid.Row>
                          {!isThisUser && ( // #NOTE - user can not delete themselves
                            <DeleteButton
                              onClick={() => this.requestDelete(emailId)}
                              strings={strings}
                            />
                          )}
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Grid.Row>
                          <Button.Group floated="right">
                            {/* <Button
                            type="button"
                            onClick={(e) => this.changePassword(e)}
                            color="purple"
                          >
                            Change Password
                          </Button>
                          <Button.Or /> */}
                            <SaveButton
                              submitting={submitting}
                              pristine={pristine}
                              strings={strings}
                            />
                            <Button.Or />
                            <Button
                              type="button"
                              disabled={pristine || submitting}
                              onClick={reset}
                            >
                              {strings?.["Reset"]}
                            </Button>
                            <Button.Or />
                            <Button
                              style={{ textAlign: "right" }}
                              onClick={(e) => this.cancelForm(e)}
                            >
                              {strings?.["Cancel"]}
                            </Button>
                          </Button.Group>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                  <Segment>
                    <Header a="h3">{strings?.["Settings"]}</Header>
                    {!pristine && !submitting && (
                      <Message icon color="orange">
                        <Icon name="warning" />
                        <Message.Content>
                          {strings?.["USER_PROFILE_SETTING_MESSAGE"]}
                        </Message.Content>
                      </Message>
                    )}
                    <Segment>
                      <Header a="h3">{strings?.["Display"]}</Header>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Field
                              name="settings.display.sopInterface"
                              label={strings?.["Show Lighting Control Layout"]}
                              component={renderCheckbox}
                            />
                            <Field
                              name="settings.display.legend"
                              label="Show map legend"
                              component={renderCheckbox}
                            />
                            <Field
                              name="settings.display.hint"
                              label={strings?.["Show hints on Polygon buttons"]}
                              component={renderCheckbox}
                            />
                            <Grid>
                              <Grid.Row
                                columns={2}
                                style={{ paddingBottom: "0px" }}
                              >
                                <Grid.Column width={3}>
                                  <label>{strings?.["Control Size"]}</label>
                                </Grid.Column>
                                <Grid.Column>
                                  <Form.Group inline>
                                    <Field
                                      component={renderRadio}
                                      label={strings?.["Compressed"]}
                                      name="settings.display.view"
                                      radioValue={"compressed"}
                                    />
                                    <Field
                                      component={renderRadio}
                                      label={strings?.["Normal"]}
                                      name="settings.display.view"
                                      radioValue={"normal"}
                                    />
                                    <Field
                                      component={renderRadio}
                                      label={strings?.["Expanded"]}
                                      name="settings.display.view"
                                      radioValue={"expanded"}
                                    />
                                  </Form.Group>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row
                                columns={2}
                                style={{ paddingTop: "0px" }}
                              >
                                <Grid.Column width={3}>
                                  <label>{strings?.["Column Width"]}</label>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                  <Slider
                                    key={hash(settings)}
                                    discrete
                                    color="red"
                                    inverted={false}
                                    settings={settings}
                                  />
                                  <Field
                                    name="settings.display.columnWidth"
                                    type="hidden"
                                    component="input"
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                    <Segment>
                      <Header a="h3">{strings?.["Options"]}</Header>
                      {this.applicationRadiosContent(
                        allowedApplications,
                        stateValueApplication,
                        isAdmin,
                        isSuper
                      )}
                      {this.featureRadiosContent(
                        allowedFeatures,
                        stateValueFeature,
                        isAdmin,
                        isSuper
                      )}
                    </Segment>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Container>
      </>
    );
  }
}

const selector = formValueSelector("userEditForm");

UserEditForm = reduxForm({
  form: "userEditForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  touchOnChange: true,
  validate,
})(UserEditForm);

const mapDispatchToProps = (dispatch, props) => ({
  goto: (path) => dispatch(push(path)),
  deleteUser: (id) => dispatch(deleteUser(id)),
  changeRole: (data) => dispatch(changeRole(data)),
  changeName: (data) => dispatch(changeName(data)),
  changePassword: (data) => dispatch(changePassword(data)),
  changeLanguage: (data) => dispatch(changeLanguage(data)),
  changeSettings: (data) => dispatch(changeSettings(data)),
  fetchAllUsers: (data) => dispatch(fetchAllUsers(data)),
});

const mapStateToProps = (state, props) => {
  const { id } = props;

  //console.log("xxxx id", id);

  const allUsers = getAllUsers(state);

  // need all user emails to prevent duplicate accounts
  const allUserEmails = allUsers.map((user) => user.email);

  const userData = getUserById(state, id); // from fetch users

  const thisUser = getUserData(state); // from auth login
  const { email: thisUserId } = thisUser;

  // we could be maintaining another user's account,
  // so check if it is this user.
  const isThisUser =
    thisUserId !== undefined || "" ? thisUserId === userData?.email : false;

  // if it is this user apply the language change, but not for another user's account.
  const thisUserLang = isThisUser ? userData.language : thisUser.language;

  const dataError =
    !_isEmpty(userData) && !_isEmpty(thisUser)
      ? undefined
      : "Error loading the page";

  // console.log("xxxx isThisUser", isThisUser);
  // console.log("xxxx UserEditForm thisUserLang", thisUserLang);
  // console.log("xxxx getUser", getUser);
  // console.log("xxxx thisUser", thisUser);
  // console.log("xxxx dataError ", dataError);
  // console.log("xxxx userData ", userData);
  // console.log("xxxx localisedTerms", localisedTerms);

  return {
    emailId: userData?.email,
    columnWidth: selector(state, "settings.display.columnWidth"),
    dataError: dataError,
    isSuper: isUserSuper(state), // `super` can change user status
    isAdmin: isUserAdmin(state),
    isThisUser,
    thisUserLang,
    allUserEmails: allUserEmails,
    initialValues: userData,
    stateValueApplication: selector(state, "settings.application") || [],
    stateValueFeature: selector(state, "settings.feature") || [],
    //
    //localisedTerms: getLocalisationByComponentId(state, "UserEditForm"), // localisation
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("onChange values", values);
    },
  };
};

UserEditForm = connect(mapStateToProps, mapDispatchToProps)(UserEditForm);

export default UserEditForm;
