import React, { Component } from "react";
import { push } from "react-router-redux";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Popup,
  Icon,
  Checkbox,
  Message,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";
import { getFolderFilesListById } from "components/Settings/reducer";
import {
  deleteFolderFile,
  fetchFolderFilesListByFolder,
} from "components/Settings/actions";

import GenericButton from "admin/GenericButton";
import { Upload } from "admin/UploadButton";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";
import hash from "object-hash";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import { withComponentStateCache } from "react-component-state-cache";

import { callRunScript } from "components/Tools/actions";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";
import { getUserData } from "auth/reducer";
import { isSystemRestore } from "components/Settings/reducer";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class ToolsBackupRestore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        id: "",
        fileDateStr: "",
        fileSize: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},
      //
      backupButton: { disabled: false },
    };
  }

  componentDidMount() {
    const filterInput = this.props.componentstate.get(
      "filterInput",
      "backupfilenameList"
    );

    if (!_.isEmpty(filterInput)) {
      this.setState({ filterInput: filterInput });
    }

    this.props.fetchFolderFilesListByFolder("backups"); // #FETCHS
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "backupfilenameList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included

        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase().includes(match[key].toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        if (!item.disableUnassign) newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  backup = () => {
    this.props.callRunScript({
      folder: "backups",
      action: "backup",
      id: "",
    });

    // set a timer so the user does not pound the backup button,
    // as the backup takes a few seconds to be processed
    this.setState({
      backupButton: { disabled: true },
    });
    setTimeout(() => {
      this.props.fetchFolderFilesListByFolder("backups");
      this.setState({
        backupButton: { disabled: false },
      });
    }, 4000); // wait for a timeout before making button active
  };

  restore = (filenames) => {
    console.log("RESTORE ZIP FILENAME ", filenames.replace(/\.[^/.]+$/, ""));

    this.props.callRunScript({
      folder: "backups",
      action: "restore",
      id: filenames.replace(/\.[^/.]+$/, ""),
    });
  };

  requestAction = (action) => {
    const { selections } = this.state;

    const { deleteFolderFile } = this.props;

    let promiseArray = [];

    if (action === "delete") {
      for (var key in selections) {
        const selection = key; // mac address

        //console.log("deleteFolderFile selection ", selection);

        promiseArray.push(
          new Promise((resolve, reject) =>
            deleteFolderFile({
              values: { filename: selection, folder: "backups" },
              resolve,
              reject,
            })
          )
        );
      }
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log(" .... processed request", results);
      })
      .then(
        () => {
          this.props.fetchFolderFilesListByFolder("backups"); // #FETCHS
          this.props.push(`/tools/backup-restore`);
        },
        (errorMsg) => {
          console.log("action failed", errorMsg); // #TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, strings } = this.props;
    const { column, data, direction, filterInput } = this.state;

    const includeTitles = this.props.includeTitles === "true";
    const title = includeTitles ? (
      <Header as="h1">{strings?.["Backup and Restore"]}</Header>
    ) : (
      <div></div>
    );

    // APP_TERMINOLOGY
    let strFile = strings?.["File"];
    let strFiles = strings?.["Files"];

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display paginationSelect items in the table below to apply an action.
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "backupRestorelist10" },
              { value: 20, text: "20", key: "backupRestorelist20" },
              { value: 40, text: "40", key: "backupRestorelist40" },
              { value: 60, text: "60", key: "backupRestorelist60" },
              { value: data.length, text: "all", key: "backupRestorelistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const filenameFromDataById = (id) => allData.find((item) => item.id == id); // falsey

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let updateButton;
    let backupButton;
    let uploadButton;
    let deleteButton;
    let restoreButton;
    let isRestoreMessage = false;
    let filenameSelectedList;

    updateButton = (
      <Popup
        content={strings?.["BACKUP_LIST_UPDATE_POP_MSG"]}
        trigger={
          <Button
            color="orange"
            onClick={() => this.props.fetchFolderFilesListByFolder("backups")}
            size="large"
            disabled={this.props.isSystemRestore}
          >
            {strings?.["Update"]}
          </Button>
        }
      />
    );

    backupButton = (
      <Popup
        content={strings?.["Click to create a backup"]}
        trigger={
          <Button
            size="large"
            color="olive"
            disabled={
              this.state.backupButton.disabled || this.props.isSystemRestore
            }
            loading={this.state.backupButton.disabled}
            onClick={() => this.backup()}
          >
            {strings?.["Backup"]}
          </Button>
        }
      />
    );

    uploadButton = (
      <Upload
        popup={strings?.["Click to upload a file"]}
        label={strings?.["Upload"]}
        fileType={".zip"}
        disabled={this.props.isSystemRestore}
      />
    );

    if (selectedCount > 0) {
      let filenames = [];

      // #REVIEW - WIP
      for (var key in selections) {
        if (filenameFromDataById(key) !== undefined) {
          let filename = filenameFromDataById(key).id;
          filenames.push(filename);
        }
      }

      filenames.sort(sortAlphaNum);
      filenames = filenames.join(", "); // separate by commas

      deleteButton = (
        <GenericButton
          label={strings?.["Delete"]}
          color="red"
          onClick={() => this.requestAction("delete")}
          size="large"
          disabled={this.props.isSystemRestore}
        />
      );

      restoreButton = (
        <Popup
          content={strings?.["Click to restore the selected backup archive"]}
          trigger={
            <Button
              color="pink"
              onClick={() => this.restore(filenames)}
              disabled={selectedCount !== 1 || this.props.isSystemRestore}
              size="large"
            >
              {strings?.["Restore"]}
            </Button>
          }
        />
      );

      isRestoreMessage = selectedCount === 1;

      filenameSelectedList = (
        <Segment textAlign="left">
          <strong>{`${strFiles} selected ...`} </strong>
          {filenames}
        </Segment>
      );
    }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{title}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <>
                  <Button.Group>
                    {updateButton}
                    {true && <Button.Or size="large" />}
                    {backupButton}
                    {true && <Button.Or size="large" />}
                    {uploadButton}
                  </Button.Group>
                  <span>
                    {
                      strings?.[
                        "Select items in the table below to apply an action"
                      ]
                    }
                  </span>
                </>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {selectedCount} selected
                </span>
                <Button.Group>
                  <Button
                    onClick={this.handleClearAll}
                    size="large"
                    disabled={this.props.isSystemRestore}
                  >
                    {strings?.["Clear"]}
                  </Button>
                  <Button.Or />
                  {deleteButton}
                  <Button.Or />
                  {restoreButton}
                </Button.Group>
              </div>
              <span
                style={{
                  marginLeft: "20px",
                  visibility: areAnySelected ? "visible" : "hidden",
                }}
              >
                {viewablesPage.length} items displayed. {selectedCount} selected
              </span>
            </Segment>
            {filenameSelectedList}
          </Segment.Group>

          {/* <Segment.Group>
            <Segment textAlign="left" style={segmentStyle}>
              {!areAnySelected && (
                <span>
                  {
                    strings?.[
                      "Select items in the table below to apply an action"
                    ]
                  }
                </span>
              )}
              <div
                style={{ visibility: areAnySelected ? "visible" : "hidden" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {selectedCount} selected
                </span>
                <Button basic onClick={this.handleClearAll}>
                  Clear
                </Button>
                <Button.Group>
                  {deleteButton}
                  <Button.Or />
                  {restoreButton}
                </Button.Group>
              </div>
              <div>
                <span>{viewablesPage.length} items displayed.</span>
              </div>
            </Segment>
            {filenameSelectedList}
          </Segment.Group> */}
          {isRestoreMessage && (
            <Message icon color="red">
              <Icon name="warning" />
              <Message.Content>
                <Message.Header>
                  {strings?.["Restore Warning Message Header"]}
                </Message.Header>
                {strings?.["Restore Warning Message Content"]}
              </Message.Content>
            </Message>
          )}

          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell style={headerCellStyle}>
                  <Icon style={{ opacity: "0.7" }} name="edit" />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={filterInput?.id ? "filterInputHighlight" : null}
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="id"
                    onChange={this.handleFilterChange}
                    value={filterInput?.id}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.fileDate ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="fileDate"
                    onChange={this.handleFilterChange}
                    value={filterInput?.fileDate}
                  />
                </Table.Cell>
                <Table.Cell style={headerCellStyle}>
                  <Input
                    size="mini"
                    fluid
                    className={
                      filterInput?.fileSize ? "filterInputHighlight" : null
                    }
                    icon="search"
                    placeholder={strings?.["Filter..."]}
                    name="fileSize"
                    onChange={this.handleFilterChange}
                    value={filterInput?.fileSize}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={this.areAllSelected(viewablesPage)}
                    indeterminate={this.areAllIndeterminate(viewablesPage)}
                    onChange={() => this.handleSelectAll(viewablesPage)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={8}
                  sorted={column === "id" ? direction : null}
                  onClick={this.handleSort("id")}
                >
                  {strings?.["File Name"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "fileDateStr" ? direction : null}
                  onClick={this.handleSort("fileDateStr")}
                >
                  {strings?.["File Date"]}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "fileSize" ? direction : null}
                  onClick={this.handleSort("fileSize")}
                >
                  {strings?.["File Size"]}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(
                viewablesPage,
                ({ id, idLink, fileDate, fileSize, fileDateStr }) => (
                  <Table.Row>
                    <Table.Cell>
                      <Checkbox
                        checked={this.isItemSelected(id)}
                        onChange={() => this.handleSelect(id)}
                        //disabled={disableUnassign}
                      />
                    </Table.Cell>
                    <Table.Cell>{idLink}</Table.Cell>
                    <Table.Cell>{fileDateStr}</Table.Cell>
                    <Table.Cell>{fileSize}</Table.Cell>
                  </Table.Row>
                )
              )}
              <TrailingContent
                data={data}
                isLoading={isLoading}
                error={error}
              />
            </Table.Body>
          </Table>
          {pagination}
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements, userLanguage) => {
  let filteredElements = [];

  // only need a subset of data
  elements.forEach((element, idx) => {
    const link = (
      <a
        href={process.env.PUBLIC_URL + "/backups/" + element?.filename}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        {element?.filename}
      </a>
    );

    const dateStr = new Date(Number(element?.filedate) * 1000).toLocaleString(
      userLanguage,
      {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }
    );

    // compose new data set
    filteredElements[idx] = {
      id: element?.filename,
      idLink: link,
      fileDate: `${element?.filedate}`,
      fileDateStr: dateStr,
      fileSize: `${element?.filesize}`,
    };
  });

  // sort newest - oldest
  return [...filteredElements].sort((a, b) => b.fileDate - a.fileDate);
};

function mapStateToProps(state, props) {
  const folderFilesList = getFolderFilesListById(state, "backups") || [];

  const userLanguage = getUserData(state)?.language || "en-US";
  const data = _prepData(folderFilesList, userLanguage);

  return {
    data,
    userLanguage,
    isSystemRestore: isSystemRestore(state),
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    push,
    callRunScript,
    deleteFolderFile,
    fetchFolderFilesListByFolder,
    saveUserSettingsComponentState,
  })(ToolsBackupRestore)
);
