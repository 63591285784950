import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import {
  isLightingControl as _isLightingControl,
  isShowExternalTriggerEvent as _isShowExternalTriggerEvent,
  isShowScheduledEvent as _isShowScheduledEvent,
  isShowEventLog as _isShowEventLog,
} from "components/ConfigJs";

let LightingControlMenu = ({
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
  //
  strings, // localisation
  userSettings,
}) => {
  const allowGuest = isUserGuest;
  const allowOperator = isUserSuper || isUserAdmin || isUserUser;
  const allowAdmin = isUserSuper || isUserAdmin;
  const allowSuper = isUserSuper;
  const isShowExternalTriggerEvent =
    userSettings?.feature?.includes("showExternalTriggerEvent") ||
    _isShowExternalTriggerEvent();
  const isShowScheduledEvent =
    userSettings?.feature?.includes("showScheduledEvent") ||
    _isShowScheduledEvent();
  const isShowEventLog = _isShowEventLog();
  const isLightingControl =
    userSettings?.application?.includes("smartLighting") ||
    _isLightingControl();

  return (
    // <Dropdown
    //   item
    //   simple
    //   text={strings?.["Smart Lighting"]}
    //   className="link item"
    // >
    //   <Dropdown.Menu>
    //     <Dropdown.Item as={Link} to={`/lighting`}>
    //       {strings?.["Lighting Control"]}
    //     </Dropdown.Item>
    //     {allowAdmin && (
    //       <>
    //         <Dropdown.Item as={Link} to={`/admin/named-area-group`}>
    //           {strings?.["Lighting Control Layouts"]}
    //         </Dropdown.Item>
    //         <Dropdown.Item as={Link} to={`/admin/named-area/region`}>
    //           {strings?.["Polygons"]}
    //         </Dropdown.Item>
    //       </>
    //     )}
    //     {isShowEventLog && (
    //       <Dropdown.Item as={Link} to={`/event-log`}>
    //         {strings?.["Event Log"]}
    //       </Dropdown.Item>
    //     )}
    //     {(isShowExternalTriggerEvent || isShowScheduledEvent) && (
    //       <Dropdown.Divider />
    //     )}
    //     {isShowExternalTriggerEvent && (
    //       <Dropdown.Item as={Link} to={`/external-trigger-event`}>
    //         {strings?.["External Trigger Events"]}
    //       </Dropdown.Item>
    //     )}
    //     {isShowScheduledEvent && (
    //       <Dropdown.Item as={Link} to={`/admin/scheduled-job`}>
    //         {strings?.["Scheduled Jobs"]}
    //       </Dropdown.Item>
    //     )}
    //   </Dropdown.Menu>
    // </Dropdown>

    <Dropdown
      item
      simple
      text={strings?.["Smart Lighting"]}
      className="link item"
    >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/lighting`}>
          {strings?.["Lighting Control"]}
        </Dropdown.Item>
        {allowAdmin && (
          <>
            <Dropdown.Item as={Link} to={`/admin/named-area-group`}>
              {strings?.["Lighting Control Layouts"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/admin/named-area/region`}>
              {strings?.["Polygons"]}
            </Dropdown.Item>
          </>
        )}
        {(isShowEventLog ||
          isShowExternalTriggerEvent ||
          isShowScheduledEvent) && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>{strings["Lighting Events"]}</Dropdown.Header>
            <Dropdown.Divider />
          </>
        )}
        {isShowEventLog && (
          <Dropdown.Item as={Link} to={`/event-log`}>
            {strings?.["Event Log"]}
          </Dropdown.Item>
        )}
        {isShowExternalTriggerEvent && (
          <Dropdown.Item as={Link} to={`/external-trigger-event`}>
            {strings?.["External Trigger Events"]}
          </Dropdown.Item>
        )}
        {isShowScheduledEvent && (
          <Dropdown.Item as={Link} to={`/admin/scheduled-event`}>
            {strings?.["Scheduled Events"]}
          </Dropdown.Item>
        )}
        {isShowScheduledEvent && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/admin/scheduled-job`}>
              {strings?.["Scheduled Jobs"]}
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

LightingControlMenu.defaultProps = {};

LightingControlMenu = connect((state) => ({
  isUserSuper: isUserSuper(state),
  isUserAdmin: isUserAdmin(state),
  isUserUser: isUserUser(state),
  isUserGuest: isUserGuest(state),
}))(LightingControlMenu);

export default LightingControlMenu;
