import React, { Component } from "react";
import { connect } from "react-redux";
import { push as goto } from "react-router-redux";

import { Container, Header, Grid } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";

//import { v4 as uuid } from "uuid";

//import SubmissionModal from "admin/SubmissionModal";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
//import DataLoadingMessage from "components/DataLoading/dataLoading";

//import { SubmissionError, isSubmitting, hasSubmitSucceeded } from "redux-form";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import { formatRelative, parseISO } from "date-fns";
import { formatDateRelativeNow, minutesAgo } from "utils/format-date";

import {
  getAllAreaStatuses,
  getAllNamedAreaEvents,
  getNamedAreasInfoInNamedAreaGroupByAreaId,
  getNamedAreaScheduledEventInfo,
} from "components/WebWorker/reducer";

import ScheduledEventListEditForm from "admin/event-scheduled/ScheduledEventListEditForm";
import { isKthBitSet } from "utils/bit-operations";

import { fetchNamedAreaScheduledEventInfo } from "NamedAreas/actions";
import hash from "object-hash";

class ScheduledEventListEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      initialValues: {},
      submitted: false,
      saveSuccess: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    this.props.fetchNamedAreaScheduledEventInfo();
  }

  componentDidUpdate() {
    //this.props.fetchNamedAreaScheduledEventInfo();
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );

    const { submitting, submitSucceeded } = this.props;

    this.setState({ errorMessage: "" });

    // if we've just submit the form -> leave
    if (submitting || submitSucceeded) {
      goto("/");
    }
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    const { changeLocalisation, reset, localisation } = this.props;
    const { id: language, fieldData } = values;

    let localisations = [];

    // ...............................

    const newValues = { id: language, localisations };

    return new Promise((resolve, reject) => {
      //changeLocalisation({ values: newValues, resolve, reject });
    }).then(() => {
      //this.props.fetchLocalisation();
      this.props.goto("/tools/localisation");
      // #TODO
      // Should wait for change to propogate then reset() to return to new intialValues
      //reset();
    });
  };

  render() {
    const { id, strings, goto, data, levels } = this.props;

    // APP_TERMINOLOGY
    let strExternalTrigger = strings?.["Scheduled Events"];
    let strExternalTriggers = strings?.["Scheduled Event"];

    // fieldData is a keyed object
    let fieldData = {};
    data.forEach((element) => {
      fieldData[element.id] = { ...element };
    });

    const initialValues = {
      _action: "edit", // used to control dlg display
      id: id,
      fieldData: fieldData, // fieldData,
    };

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"scheduledEventListEditForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["Scheduled Events"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <ScheduledEventListEditForm
            key={hash(fieldData)}
            id={id}
            data={data}
            levels={levels}
            initialValues={initialValues}
            header={strings?.["Edit Scheduled Job"]}
            onSubmit={(values) => this.submitMyForm(values)}
            onCancel={() => {
              goto(`/admin/scheduled-job`);
            }}
            strings={strings}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements, strings) => {
  let filteredElements = [];

  //console.log("xxx elements", elements);

  elements.forEach(function (element, idx) {
    const {
      id,
      active,
      area,
      buttonCustomLabel,
      buttonLabel,
      groupLabel: group,
      levelLabel: level,
      parent,
      permission,
      priority,
      origin,
      operator,
      reason,
      disable,
      timestamp,
    } = element;

    // console.log("xxx element", element);

    let lastStatusReport = "-";
    if (timestamp !== undefined) {
      // format - previous used in tableformatters.js
      lastStatusReport = formatRelative(timestamp, new Date(), {
        includeSeconds: true,
      });
    }

    let style = null;
    // change row if active

    if (active) style = { backgroundColor: "rgb(219, 40, 40, 15%)" };

    // set style of disabled events
    if (disable === true) {
      style = { backgroundColor: "rgb(242, 113, 28, 60%)" };
    }

    //         areaLink: <Link to={`/admin/scheduled-job/edit/${id}`}>{name} </Link>,

    let sourceName = strings?.["unknown"];
    let sourceId = "";
    let source = "";
    //let sourceLink = ""; // <div>HI!</div>;

    switch (origin) {
      case "scheduled_event":
        const operatorArray = operator?.split(":");
        sourceName = operatorArray?.[1] || strings?.["unknown"];
        sourceId = operatorArray?.[0] || "";
        source = `${strings?.["Job"]}: ${sourceName}`;
        break;
      default:
        break;
    }

    // compose new data set
    filteredElements[idx] = {
      id, //`${parent}:${priority}`,
      active: active ? "ACTIVE" : "INACTIVE",
      area,
      group,
      level,
      polygon: buttonCustomLabel !== "" ? buttonCustomLabel : buttonLabel,
      source,
      sourceId,
      reason,
      lastStatusReport,
      style,
      disable: disable ? "DISABLED" : "ENABLED",
    };
  });

  console.log("sss filteredElements", filteredElements);

  return filteredElements;
};

function mapStateToProps(state, props) {
  const allAreas = getAllAreaStatuses(state);

  let levels = [];
  let rawData = [];

  // create level information
  allAreas.forEach((area) => {
    const { id } = area;
    levels.push({ id: id, name: id });
  });

  // scheduled event event info via API /get_all_scheduled_event_info
  const scheduledEventInfo = getNamedAreaScheduledEventInfo(state);

  console.log("xxx scheduledEventInfo", scheduledEventInfo);

  rawData = scheduledEventInfo; //.filter((event) => event.active !== false);

  const data = _prepData(rawData, props.strings);

  console.log("xxx scheduledEventInfo data", data);

  return {
    data,
    levels,
  };
}

export default connect(mapStateToProps, {
  TurnOnOffPokeTheWorker,
  goto,
  fetchNamedAreaScheduledEventInfo,
})(ScheduledEventListEditPage);
