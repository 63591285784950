import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import { DeleteButton } from "admin/DeleteButton";
import GenericButton from "admin/GenericButton";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _has from "lodash/has";
import _size from "lodash/size";

// #REVIEW - only use of this immute helper -consider replacing for consistency when time
import update from "immutability-helper";

import { getAllAreaStatuses } from "components/WebWorker/reducer";

import { fetchMineLevels, deleteArea } from "MineLevels/actions";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { isBoomgateCrane, isAreaImageToState } from "components/ConfigJs";

import { withComponentStateCache } from "react-component-state-cache";

import { fetchFolderFilesListByFolder } from "components/Settings/actions";

import AreaImages from "components/AreaImages/AreaImageImgBlob";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

function _derivedState(incomingState) {
  // Sets derived state based on new selections
  return Object.assign({}, incomingState, {
    areAnySelected: !_isEmpty(incomingState.selections),
    selectedCount: _size(incomingState.selections),
  });
}

class ListMineLevels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        area: "",
        active: "",
        order: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
      // manage row checkbox enabledDisable selections
      areAnySelected: false,
      selectedCount: 0,
      selections: {},

      // image update run one
      isAreaImage: false,

      // configJs settings
      isAreaImageToState: false,
      isBoomgateCrane: false,
    };
  }

  componentDidMount() {
    const {
      data,
      fetchMineLevels,
      fetchFolderFilesListByFolder,
      componentstate,
    } = this.props;

    // put configJs in state
    const areaImageToState = isAreaImageToState() || false;
    this.setState({ isAreaImageToState: areaImageToState });
    // railway_application
    const boomgateCrane = isBoomgateCrane() || false;
    this.setState({ isBoomgateCrane: boomgateCrane });

    // could have come here after editing area image so
    // fetch updated listing of the folder image list
    //
    fetchFolderFilesListByFolder("areas"); // #FETCHS

    // if (_isEmpty(data)) {
    //   // #FETCHS
    //   fetchMineLevels(); // #FETCHS
    //   fetchFolderFilesListByFolder("areas"); // #FETCHS
    // }

    const filterInput = componentstate.get("filterInput", "areaList");

    //console.log("filterInput componentDidMount", filterInput);

    if (!_isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);
      this.setState({ filterInput: filterInput });
    }

    // only run AreaImage check once at componentDidMount to load the images,
    // then this disables it.
    this.setState({ isAreaImage: false });
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "areaList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );
  }

  componentDidUpdate(prevProps, prevState) {
    //only run AreaImage check once when start, if not on mount then here
    if (this.state.isAreaImage) {
      this.setState({ isAreaImage: false });
    }

    const { filterInput: match } = this.state;

    if (
      JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      let newData = [...this.props?.data]; // original data

      newData = newData.filter(function (item) {
        let checkMatch = true; // assume all included as default "" is always included
        for (var key in match) {
          checkMatch =
            checkMatch &&
            item[key]?.toLowerCase()?.includes(match[key]?.toLowerCase()); // remove item which don't match
        }
        return checkMatch;
      });

      this.setState({
        data: newData,
      });

      // restore sort
      const { column, direction } = this.state;

      // #REVIEW - decide if need a default sort column
      // column is initially undefined. i.e. no default sort column
      if (column) {
        newData = newData.slice().sort((a, b) =>
          a[column].localeCompare(b[column], "en", {
            numeric: true,
          })
        );
      }

      // newData = _.sortBy(newData, [column]);

      if (direction === "descending") {
        newData = newData.reverse();
      }
      this.setState({
        data: newData,
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  handleClearAll = () => {
    this.setState(
      _derivedState({
        selections: {},
      })
    );
  };

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (_has(prevState.selections, id)) {
        // { 1: true } -> {}
        return _derivedState(
          update(prevState, {
            selections: { $unset: [id] },
          })
        );
      }
      // {} -> { 1: true }
      return _derivedState(
        update(prevState, {
          selections: { [id]: { $set: true } },
        })
      );
    });
  };

  handleSelectAll = (items) => {
    if (_isEmpty(this.state.selections)) {
      const newSelections = {};
      items.forEach((item) => {
        newSelections[item.id] = true;
      });
      this.setState(
        _derivedState({
          selections: newSelections,
        })
      );
    } else {
      this.setState(
        _derivedState({
          selections: {},
        })
      );
    }
  };

  areAllIndeterminate = (items) =>
    !_isEmpty(this.state.selections) && !this.areAllSelected(items);
  areAllSelected = (items) =>
    _every(items, (item) => _has(this.state.selections, item.id));
  isItemSelected = (id) => _has(this.state.selections, id);

  requestAction = (action) => {
    const { selections } = this.state;
    const { deleteArea, push, fetchMineLevels } = this.props;

    let promiseArray = [];

    for (var selection in selections) {
      //console.log("deleting Firefly ... ", selection);
      promiseArray.push(
        // eslint-disable-next-line no-loop-func
        new Promise((resolve, reject) => {
          deleteArea({ values: { id: selection }, resolve, reject });
        })
      );
    }

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("DELETING AREA: ", results);
      })
      .then(
        () => {
          fetchMineLevels();
          push("/admin/area");
        },
        (msg) => {
          console.log("delete failed", msg); // TODO probs should show this?
        }
      );
  };

  render() {
    const { isLoading, error, strings, allAreas } = this.props;
    const {
      column,
      data,
      direction,
      filterInput,
      isAreaImage,
      isAreaImageToState,
    } = this.state;

    // APP_TERMINOLOGY
    const strArea = strings?.["Area"];
    const strAreas = strings?.["Areas"];

    // railway_application
    const pageTitle = !isBoomgateCrane()
      ? strings?.["Areas"]
      : strings?.["Map Image"];

    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }

    // if enough items display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "alist10" },
              { value: 20, text: "20", key: "alist20" },
              { value: 40, text: "40", key: "alist40" },
              { value: 60, text: "60", key: "alist60" },
              { value: data.length, text: "all", key: "alistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    const areAnySelected = !_isEmpty(this.state.selections);
    const selectedCount = _size(this.state.selections);
    const selections = this.state.selections;

    const { data: allData } = this.props; // get unfiltered data
    const areaFromDataById = (id) => allData.find((item) => item.id === id);

    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let addButton;
    let enableButton;
    let disableButton;
    let deleteButton;
    let areaSelectedList;

    addButton = (
      <Link to="area/new">
        <Button color="blue" content={strings?.["Add"]} size="large" />
      </Link>
    );

    if (selectedCount > 0) {
      let areaNames = [];
      //console.log("data", data);

      // #REVIEW - WIP
      for (var key in selections) {
        if (areaFromDataById(key) !== undefined) {
          let areaName = areaFromDataById(key).area;

          // namedAreaName = namedAreaName.substring(
          //   0,
          //   namedAreaName.indexOf(" (")
          // ); // strip off the trailing ip
          areaNames.push(areaName);
        }
      }
      areaNames.sort(sortAlphaNum); // sort by name  i.e. by panel locations description
      areaNames = areaNames.join(", "); // separate by commas

      enableButton = (
        <GenericButton
          label={strings?.["Enable"]}
          color="blue"
          onClick={() => this.requestAction("enable")}
        />
      );
      disableButton = (
        <GenericButton
          label={strings?.["Disable"]}
          color="orange"
          onClick={() => this.requestAction("disable")}
        />
      );
      deleteButton = (
        <DeleteButton
          onClick={() => this.requestAction("delete")}
          strings={strings}
        />
      );

      areaSelectedList = (
        <Segment textAlign="left">
          <strong>{`${strAreas} selected ...`} </strong>
          {areaNames}
        </Segment>
      );
    }

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    return (
      <>
        {isAreaImageToState && isAreaImage && <AreaImages />}
        <div className={"genericGridHeader"}>
          <Container>
            <Grid columns={2}>
              <Grid.Row className={"genericTitleHeader"}>
                <Grid.Column width={4} textAlign={"left"}>
                  <Header as="h1">{pageTitle}</Header>
                </Grid.Column>
                <Grid.Column width={12}>
                  <FlashMessagesList />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
            </Grid>
            <Segment.Group>
              <Segment textAlign="left" style={segmentStyle}>
                {!areAnySelected && (
                  <>
                    <Button.Group>{addButton}</Button.Group>
                    <span>
                      {
                        strings?.[
                          "Select items in the table below to apply an action"
                        ]
                      }
                    </span>
                  </>
                )}
                <div
                  style={{ visibility: areAnySelected ? "visible" : "hidden" }}
                >
                  <Button.Group>
                    <Button onClick={this.handleClearAll} size="large">
                      {strings?.["Clear"]}
                    </Button>
                    <Button.Or size="large" />
                    {deleteButton}
                    {/* <Button.Or />
                  {enableButton}
                  <Button.Or />
                  {disableButton} */}
                  </Button.Group>
                </div>
                <div>
                  {viewablesPage.length} items displayed. {selectedCount}{" "}
                  selected
                </div>
              </Segment>
              {areaSelectedList}
            </Segment.Group>
            <Table sortable celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.Cell style={headerCellStyle}>
                    <Icon style={{ opacity: "0.7" }} name="trash" />
                  </Table.Cell>
                  {/* <Table.Cell style={headerCellStyle} /> */}
                  <Table.Cell style={headerCellStyle} />
                  <Table.Cell style={headerCellStyle}>
                    <Input
                      size="mini"
                      fluid
                      className={
                        filterInput?.area ? "filterInputHighlight" : null
                      }
                      icon="search"
                      placeholder={strings?.["Filter..."]}
                      name="area"
                      onChange={this.handleFilterChange}
                      value={filterInput?.area}
                    />
                  </Table.Cell>
                  <Table.Cell style={headerCellStyle} />
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <Checkbox
                      checked={this.areAllSelected(viewablesPage)}
                      indeterminate={this.areAllIndeterminate(viewablesPage)}
                      onChange={() => this.handleSelectAll(viewablesPage)}
                    />
                  </Table.HeaderCell>
                  {/* <Table.HeaderCell
                    width={1}
                    sorted={column === "active" ? direction : null}
                    onClick={this.handleSort("active")}
                  >
                    {strings?.["Active"]}
                  </Table.HeaderCell> */}
                  <Table.HeaderCell
                    width={1}
                    sorted={column === "order" ? direction : null}
                    onClick={this.handleSort("order")}
                  >
                    {strings?.["Order"]}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={4}
                    sorted={column === "area" ? direction : null}
                    onClick={this.handleSort("area")}
                  >
                    {strings?.["Area"]}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={12}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(
                  viewablesPage,
                  ({ id, areaLink, area, disableDelete, active, order }) => (
                    <Table.Row key={`row-id-${id}`}>
                      <Table.Cell>
                        <Checkbox
                          checked={this.isItemSelected(id)}
                          onChange={() => this.handleSelect(id)}
                          disabled={disableDelete}
                        />
                      </Table.Cell>
                      {/* <Table.Cell>{active}</Table.Cell> */}
                      <Table.Cell>{order}</Table.Cell>
                      <Table.Cell>{areaLink}</Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  )
                )}
                <TrailingContent
                  data={data}
                  isLoading={isLoading}
                  error={error}
                />
              </Table.Body>
            </Table>
            {pagination}
            <DebugPagePropsMessages that={this} />
          </Container>
        </div>
      </>
    );
  }
}

const _prepData = (elements) => {
  let filteredParents = [];

  elements.forEach(function (element, idx) {
    const {
      id,
      name,
      image_filename,
      floor,
      ceiling,
      default_color,
      default_state,
      active,
      order,
    } = element;

    if (!id?.includes("defaultArea")) {
      filteredParents[idx] = {
        id: id,
        active: active ? "true" : "false",
        order: order?.toString(),
        area: name,
        areaLink: <Link to={`/admin/area/edit/${id}`}>{name} </Link>,
        disableDelete: false,
      };
    }
  });

  return filteredParents;
};

function mapStateToProps(state) {
  // #REVEW - no longer applicable? We do not track loading state ... for the moment forced values
  //const { isLoading, error } = state.namedAreas;
  const isLoading = false;
  const error = false;

  // exclude ALL_AREAS, LEVEL_WIDE
  const allAreas = getAllAreaStatuses(state).filter(
    (na) => na.type !== "LEVEL_WIDE" && na.type !== "ALL_AREAS"
  );

  const data = _prepData(allAreas);

  return {
    isLoading,
    error,
    data,
    allAreas,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    deleteArea,
    fetchMineLevels,
    push,
    fetchFolderFilesListByFolder,
    saveUserSettingsComponentState,
  })(ListMineLevels)
);
