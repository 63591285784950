// see - src/components/RegionPreview/reducer.js
// see main reducer at src/reducers.js

import Immutable from "seamless-immutable";

let initialState = Immutable({
  // record of most recent fault_ts for each fault type as acknowledged by the user
  latestFaultTs: {
    firefly: 0,
    battery: 0,
    network: 0,
    controller: 0,
    fireflySamePosition: 0,
    controllerLikelyReplacement: 0,
  },
  messageBanners: {
    firefly: { display: false, id: "", info: "" },
    battery: { display: false, id: "", info: "" },
    network: { display: false, id: "", info: "" },
    controller: { display: false, id: "", info: "" },
    statusChecks: { display: false, id: "", info: "" },
    fireflySamePosition: { display: false, id: "", info: "" },
    controllerLikelyReplacement: { display: false, id: "", info: "" },
    //
    areaImageNotFound: { display: false, id: "", info: "" },
  },
});

export default function reduce(state = initialState, action = {}) {
  // copy existing state
  const newState = { ...state };

  const { type, payload } = action;
  let tempResult;
  switch (type) {
    case "SET_LATEST_FAULT_TS":
      let { latestFaultTs } = newState;
      tempResult = { ...latestFaultTs };
      tempResult[payload.faultType] = payload.faultMaxFaultTs;
      newState.latestFaultTs = tempResult;
      return newState;
    case "SET_MESSAGE_BANNER":
      let { messageBanners } = newState;
      tempResult = { ...messageBanners };

      // #REVIEW - I hate how this is done but I can not assign directly - why?

      // create sub-object from payload
      let tempObj = {};
      tempObj.display = payload?.display;
      tempObj.id = payload?.id;
      tempObj.info = payload?.info;

      //console.log(`SET_MESSAGE_BANNER`, tempObj);

      // assign sub-object to reconstructed messageBanner
      tempResult[payload.faultType] = tempObj;

      newState.messageBanners = tempResult;
      return newState;
    case "RESET":
      console.log("RESET! - AlarmButton");
      return initialState; //Always return the initial state

    default:
      return state;
  }
}
