import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiUsers";
import { SubmissionError } from "redux-form";

function makeSaveFunction(apiCall) {
  return function* (action) {
    const { values, resolve, reject } = action.payload;
    const { id } = values;

    if (!id) {
      reject(
        new SubmissionError({
          _error: "Must provide id",
        })
      );
      return;
    }
    try {
      //console.log("xxx makeSaveFunction values", values);
      yield apiCall(values);
      resolve();
    } catch (error) {
      const formError = new SubmissionError({ _error: error.response.data });
      reject(formError);
    }
  };
}

function* fetchAllUsers(action) {
  console.log(`ALL_USERS_FETCH_REQUESTED`);
  try {
    const users = yield call(Api.getAllUsers);
    console.log(`ALL_USERS_FETCH_REQUESTED users`, users);
    yield put({ type: "ALL_USERS_FETCH_SUCCEEDED", payload: { users } });
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    if (status === 401) {
      yield put({
        type: "ALL_USERS_FETCH_FAILED",
        payload: { error: "fetchAllUsers error", extra: data },
      });
    } else {
      yield put({
        type: "ALL_USERS_FETCH_UNKNOWN",
        payload: { error: "fetchAllUsers error", extra: data },
      });
    }
  }
}

function* deleteUser(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  //console.log("deleteUser action", action);

  try {
    //console.log("@@ saga deleteUser id", id);
    yield Api.deleteUser(id);
    yield put({
      type: "USER_DELETE_SUCCEEDED",
      payload: { id },
    });
    resolve(id);
  } catch (error) {
    yield put({ type: "USER_DELETE_FAILED", message: error.message });
    reject(error.message);
  }
}

function* changeUserSettingsComponentState(action) {
  //console.log(`CHANGE_USER_SETTINGS_COMPONENT_STATE`, action);

  const { id, settings } = action.payload;
  try {
    const response = yield Api.changeSettings({ id, settings });
    //console.log(`CHANGE_USER_SETTINGS_COMPONENT_STATE users`, response);
    yield put({
      type: "CHANGE_USER_SETTINGS_COMPONENT_STATE_SUCCEEDED",
      payload: { response },
    });
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    if (status === 401) {
      yield put({
        type: "CHANGE_USER_SETTINGS_COMPONENT_STATE_FAILED",
        payload: {
          error: "changeUserSettingsComponentState error",
          extra: data,
        },
      });
    } else {
      yield put({
        type: "CHANGE_USER_SETTINGS_COMPONENT_STATE_UNKNOWN",
        payload: {
          error: "changeUserSettingsComponentState error",
          extra: data,
        },
      });
    }
  }
}

function* userAdminSagas() {
  yield takeEvery("ALL_USERS_FETCH_REQUESTED", fetchAllUsers);
  yield takeEvery("SAVE_USER", makeSaveFunction(Api.saveUser));
  yield takeEvery("DELETE_USER", deleteUser);
  yield takeEvery("CHANGE_USER_ROLE", makeSaveFunction(Api.changeRole));
  yield takeEvery("CHANGE_USER_NAME", makeSaveFunction(Api.changeName));
  yield takeEvery("CHANGE_USER_PASSWORD", makeSaveFunction(Api.changePassword));
  yield takeEvery("CHANGE_USER_LANGUAGE", makeSaveFunction(Api.changeLanguage));
  yield takeEvery("CHANGE_USER_SETTINGS", makeSaveFunction(Api.changeSettings));
  yield takeEvery(
    "CHANGE_USER_SETTINGS_COMPONENT_STATE",
    changeUserSettingsComponentState
  );
}

export default userAdminSagas;
