export function upsStatusUpdate(id, status) {
  return { type: "UPS_STATUS_UPDATE", payload: { id, ...status } };
}

export function travelwayStatusUpdate(id, status) {
  return { type: "TRAVELWAY_STATUS_UPDATE", payload: { id, ...status } };
}

export function fireflyStatusUpdate(id, status) {
  return { type: "FIREFLY_STATUS_UPDATE", payload: { id, ...status } };
}

export function savePositions(payload) {
  return {
    type: "COMMISSION_RECORDS_REQUEST",
    payload,
  };
}

export function saveUPS(payload) {
  return { type: "SAVE_UPS", payload };
}

export function saveFirefly(payload) {
  return { type: "SAVE_FIREFLY", payload };
}

// #NOTE - no longer used for direct control of lights
// Left in place for historic reference ATM
export function saveFireflyLed(payload) {
  return { type: "SAVE_FIREFLY_LED", payload };
}

export function saveEmergencyEventSettings(payload) {
  return { type: "SAVE_EMERGENCY_EVENT_SETTINGS", payload };
}

export function saveTriggerEventSettings(payload) {
  return { type: "SAVE_TRIGGER_EVENT_SETTINGS", payload };
}

export const fetchUPSs = () => ({ type: "UPS_FETCH_REQUESTED" });

export const fetchFireflys = () => ({ type: "FIREFLY_FETCH_REQUESTED" });

export function fetchFireflyAreaLightingplan(payload) {
  return { type: "FIREFLY_AREA_LIGHTINGPLAN_FETCH_REQUESTED", payload };
}

// #REVIEW - not used?
export const fetchFireflyNotes = () => ({
  type: "FIREFLY_NOTE_FETCH_REQUESTED",
});

export function deleteUPS(payload) {
  return { type: "DELETE_UPS", payload };
}

export function deleteFirefly(payload) {
  return { type: "DELETE_FIREFLY", payload };
}

export function runDiscoveryUPS(id) {
  return { type: "UPS_RUN_DISCOVERY", payload: { id } };
}

export function UPSSendAdvancedConfig(payload) {
  return { type: "UPS_SEND_ADVANCED_CONFIG", payload };
}

export function sendTravelway(cmdPayload) {
  return { type: "TRAVELWAY_SEND_COMMAND", payload: { cmdPayload } };
}

export function sendUPSCommand(id, cmdPayload) {
  return { type: "UPS_SEND_COMMAND", payload: { id, cmdPayload } };
}
