import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router";

import { push } from "react-router-redux";
import {
  Button,
  Grid,
  Container,
  Segment,
  Header,
  Tab,
  Icon,
  Popup,
} from "semantic-ui-react";

import { LayersControl } from "react-leaflet";
import SearchControl from "components/leaflet-search-control/react-leaflet-search-control";

import { getAreaStatuses } from "components/WebWorker/reducer";
import { getAllTagTrackingGeoJson } from "components/Tags/reducer";
import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroupGeoJson";
import CurrentTagTrackingFeatureGroup from "admin/tag-tracking/CurrentTagTrackingFeatureGroupGeoJson";
import CurrentTagTrackingZonesFeatureGroup from "admin/tag-tracking/CurrentTagTrackingZonesFeatureGroupGeoJson";
import { MineLevelMapById } from "components/Map/MineLevelMap";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import FlashMessagesList from "FlashMessages";
import {
  fetchTagTrackingAreaStatuses,
  fetchTags,
} from "components/Tags/actions";
import { getAllAreaStatuses } from "components/WebWorker/reducer";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import { withComponentStateCache } from "react-component-state-cache";
import { strings } from "components/App/localisation";

// #WIP - testing add search
import { getTagTrackingCoordinatesByAreaId } from "components/Tags/reducer";

const tagTypeDisplaySettings = [
  { type: "unknown", icon: "fas fa-question fa-lg", label: "Unknown" },
  { type: "personnel", icon: "fas fa-hard-hat fa-lg", label: "Personnel" },
  {
    type: "vehicle",
    icon: "fas fa-truck-pickup fa-lg",

    label: "Vehicle",
  },
];

function MapPositioningDisplay({
  mineLevelId,
  mapViewState,
  displayOptions,
  children,
}) {
  if (!mineLevelId) {
    return <div>No mine level selected</div>;
  }

  return (
    <MineLevelMapById
      //key={`${mineLevelId}`}
      mineLevelId={mineLevelId}
      displayOptions={displayOptions}
      mapViewState={mapViewState}
      mapViewType={"tagMapViewState"}
      style={{
        height: "60vh",
        width: "100%",
        position: "relative",
        zIndex: 0,
        backgroundColor: `rgba(255,255,255,1)`,
      }}
    >
      {children}
    </MineLevelMapById>
  );
}

class TagTrackingMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTagType: [],
      currentArea: "",
      // map view state
      mapViewState: {
        id: undefined,
        center: undefined,
        zoom: undefined,
        options: undefined,
      },
      //savedMapViewState: { id: "", options: [] },
      displayOptions: ["showPanZoomButton"],
    };
  }

  expandCollapse = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  componentDidMount() {
    const {
      fetchTagTrackingAreaStatuses,
      areas,
      areaStatuses,
      fetchTags,
      displayTagTypes,
      savedMapViewStates,
    } = this.props;

    // set the default/startup area (tabs start from 1st area defined)
    // #WIP #TODO - this will need to change when the area order is changed
    //
    const newCurrentArea = areas?.[0];
    this.setState({ currentArea: newCurrentArea });
    this.setState({ activeTagType: displayTagTypes });

    fetchTagTrackingAreaStatuses(areaStatuses);
    fetchTags();

    // get system saved mapViewStates
    // load data for the currentArea displayed
    const savedMapViewStateForArea = savedMapViewStates.find(
      (mapState) => mapState.id === newCurrentArea
    );

    if (!_isEmpty(savedMapViewStateForArea)) {
      this.setState({ mapViewState: savedMapViewStateForArea });
      let newDisplayOptions = savedMapViewStateForArea?.options || [];
      // restore default display of showPanZoomButton
      // #WIP - move 'showPanZoomButton' to a config option!
      newDisplayOptions = [...newDisplayOptions, "showPanZoomButton"];
      if (savedMapViewStateForArea?.center && savedMapViewStateForArea?.zoom) {
        newDisplayOptions.push("showRestoreMapViewStateButton");
      }
      this.setState({
        displayOptions: newDisplayOptions,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const checkState = _isEqual(nextState, this.state);

    // check if savedMapViewState has changed for the currentArea
    const nextsavedMapViewStateCurrentArea = nextProps.savedMapViewStates.find(
      (mapState) => mapState.id === this.state.currentArea
    );

    const checkSavedMapViewState = _isEqual(
      this.state.mapViewState, // i.e. savedMapViewState,
      nextsavedMapViewStateCurrentArea
    );

    return !checkState || !checkSavedMapViewState;
  }

  componentDidUpdate(prevProps, prevState) {
    // check if savedMapViewState has changed for the currentArea
    let newSavedMapViewStateCurrentArea = this.props.savedMapViewStates.find(
      (mapState) => mapState.id === this.state.currentArea
    );

    // if there is no mapViewState set and currentArea is defined
    // then setup an empty map view state for the area (i.e. only the 'id')
    if (newSavedMapViewStateCurrentArea === undefined && this.state.currentArea)
      newSavedMapViewStateCurrentArea = {
        id: this.state.currentArea,
      };

    // check if the map view state for the current area has changed
    if (
      JSON.stringify(this.state.mapViewState) !== // savedMapViewState
      JSON.stringify(newSavedMapViewStateCurrentArea)
    ) {
      // #WIP _ FIX THIS >>>>>>>>>>>> SHOULD NOT BE BOTH
      //this.setState({ savedMapViewState: newSavedMapViewStateCurrentArea });
      this.setState({ mapViewState: newSavedMapViewStateCurrentArea });

      let newDisplayOptions = newSavedMapViewStateCurrentArea?.options || [];
      // restore default display of showPanZoomButton
      // #WIP - move 'showPanZoomButton' to a config option!
      newDisplayOptions = [...newDisplayOptions, "showPanZoomButton"];
      if (
        newSavedMapViewStateCurrentArea?.center &&
        newSavedMapViewStateCurrentArea?.zoom
      ) {
        if (!newDisplayOptions.includes("showRestoreMapViewStateButton")) {
          newDisplayOptions.push("showRestoreMapViewStateButton");
        }
      }
      this.setState({
        displayOptions: newDisplayOptions,
      });
    }
  }

  componentWillUnmount() {}

  handleTabChange = (data) => {
    const { areas } = this.props;
    //console.log(`xxx data`, data);
    this.setState({ currentArea: areas[data.activeIndex] });
  };

  handleOnClick = (evt, data) => {
    const { tagType } = data;

    let newActiveTagType = [...this.state.activeTagType];

    if (newActiveTagType.includes(tagType)) {
      newActiveTagType = newActiveTagType.filter((tags) => tags !== tagType);
    } else {
      newActiveTagType.push(tagType);
    }
    this.setState({ activeTagType: newActiveTagType });
  };

  // #NOTE - removed the layer control for <CurrentTagTrackingFeatureGroup /> b/c a new layer was redrawing
  // with each geoJson update.
  //

  handleTabChange = (data) => {
    const { activeIndex, panes } = data;
    const currentArea = panes?.[activeIndex]?.area;
    this.setState({ currentArea: currentArea });
  };

  render() {
    const { displayTagTypes, areas } = this.props;
    const { currentArea, activeTagType, mapViewState = {} } = this.state;

    // load search control data
    const { tagsAreas } = this.props;

    // setup items for the searchControl
    let items = [];
    const tags = tagsAreas?.find((area) => area.id === currentArea);
    if (!_isEmpty(tags)) {
      tags.features.forEach((feature) => {
        const item = { feature };
        items.push(item);
      });
    }

    let isSearchControl = false;
    if (this.state.displayOptions.includes("showSearchControl"))
      isSearchControl = true;

    // #NOTE - VERY IMPORTANT - changes to mapKey will triggger a map re-render.
    // options are removed to stop them re-rendering the map
    //
    let { options, ...mapKeyObject } = mapViewState;
    let mapKey = `${JSON.stringify(mapKeyObject)}`;
    if (isSearchControl) mapKey = `mapKey-showSearchControl`;

    const panes = areas.map((area, index) => ({
      menuItem: area?.replaceAll("_", " "),
      area: area,
      render: () => (
        <Tab.Pane
          onTabChange={(e, data) => {
            this.handleTabChange(data);
          }}
          key={`${area}`}
        >
          <MapPositioningDisplay
            mineLevelId={area}
            mapViewState={mapViewState}
            key={mapKey} // #NOTE - VERY IMPORTANT - This forces the map reload when the mapViewState has changed.
            // See this 'hack' solution - https://stackoverflow.com/questions/65763931/react-leaflet-update-map-center
            //
            // Need this until react-leaflet v3 allows .setView on map initialisation
            displayOptions={this.state.displayOptions}
          >
            {isSearchControl && (
              <SearchControl
                position={"topleft"}
                className={"searchControl"}
                placeholder={"Search..."}
                data={items}
              />
            )}
            <LayersControl position={"topright"}>
              <LayersControl.Overlay
                key={"firefliesOverlay"}
                name={"FireFlys"}
                checked
              >
                <CurrentMineLevelFirefliesFeatureGroup />
              </LayersControl.Overlay>
              {/*
               <LayersControl.Overlay key={"tagsOverlay"} name={"Tags"} checked> 
              <CurrentTagTrackingFeatureGroup activeTagType={activeTagType}/>
              </LayersControl.Overlay> 
              */}

              <LayersControl.Overlay
                key={"tagsZonesOverlay"}
                name={"Tags Zones"}
                checked
              >
                <CurrentTagTrackingZonesFeatureGroup
                  activeTagType={activeTagType}
                />
              </LayersControl.Overlay>
            </LayersControl>
            <CurrentTagTrackingFeatureGroup activeTagType={activeTagType} />
          </MapPositioningDisplay>
        </Tab.Pane>
      ),
    }));

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{strings?.["Tag Map"]}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Segment>
            <Grid columns={2}>
              <Grid.Column>
                <Grid.Row>
                  <Header as="h2">
                    {strings?.["Tag Map"]} - {currentArea?.replace("_", " ")}
                  </Header>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column>
                <Grid.Row>
                  <Button.Group floated="right">
                    {displayTagTypes.map((tag) => {
                      const tagSetting = tagTypeDisplaySettings.find(
                        (setting) => setting.type === tag
                      );
                      const active = activeTagType.includes(tagSetting?.type);
                      const iconStyle = active
                        ? { color: "#2185d0" }
                        : { opacity: "0.2" };
                      const popupMsg = active
                        ? `Click to hide the ${tagSetting?.label} tags`
                        : `Click to show the ${tagSetting?.label} tags`;
                      return (
                        <Popup
                          content={popupMsg}
                          trigger={
                            <Button
                              icon
                              size={"large"}
                              style={{ marginRight: "2px" }}
                              tagType={tagSetting?.type}
                              onClick={(evt, data) =>
                                this.handleOnClick(evt, data)
                              }
                            >
                              <i
                                className={`${tagSetting?.icon}`}
                                style={iconStyle}
                              ></i>
                            </Button>
                          }
                        />
                      );
                    })}
                  </Button.Group>
                </Grid.Row>
              </Grid.Column>
              <Grid.Row columns={"equal"}>
                <Grid.Column>
                  <Tab
                    onTabChange={(e, data) => {
                      this.handleTabChange(data);
                    }}
                    panes={panes}
                    menu={{
                      attached: true,
                      className: "tabTitleWrapped",
                      tabular: true,
                      //style: { display: "flex", justifyContent: "center" },
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const areaStatuses = getAllAreaStatuses(state);
  const areas = areaStatuses.map((area) => area.id) || [];
  const allTagTrackingGeoJson = getAllTagTrackingGeoJson(state);

  //console.log("allTagTrackingGeoJson", allTagTrackingGeoJson);

  const displayTagTypes = ["personnel", "vehicle", "unknown"]; // getDisplayTagTypes(state) - #WIP - proposed to get tag types from state/api request

  // track changes to the savedMapViewState
  let savedMapViewStates = [];
  let tagsAreas = [];
  areas.forEach((area) => {
    const mapViewState = props.componentstate.get("tagMapViewState", area);
    if (mapViewState) savedMapViewStates.push(mapViewState);
    let tagsArea = getTagTrackingCoordinatesByAreaId(state, area) || {
      features: [],
    };
    tagsArea.id = area;
    tagsAreas.push(tagsArea);
  });

  return {
    areaStatuses,
    areas,
    allTagTrackingGeoJson,
    displayTagTypes,
    //
    tagsAreas,
    //
    savedMapViewStates,
  };
};

TagTrackingMap = withComponentStateCache(
  connect(mapStateToProps, { fetchTagTrackingAreaStatuses, fetchTags })(
    TagTrackingMap
  )
);

export default TagTrackingMap;
