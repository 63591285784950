import { strings } from "components/App/localisation";

export const optionsMonths = [
  {
    value: "*",
    text: strings?.["month"],
    key: "every_month",
  },
  {
    value: 1,
    text: strings?.["january"],
    key: "january",
  },
  {
    value: 2,
    text: strings?.["february"],
    key: "february",
  },
  { value: 3, text: strings?.["march"], key: "march" },
  { value: 4, text: strings?.["april"], key: "april" },
  { value: 5, text: strings?.["may"], key: "may" },
  { value: 6, text: strings?.["june"], key: "june" },
  { value: 7, text: strings?.["july"], key: "july" },
  { value: 8, text: strings?.["august"], key: "august" },
  { value: 9, text: strings?.["september"], key: "september" },
  { value: 10, text: strings?.["october"], key: "october" },
  { value: 11, text: strings?.["november"], key: "novemeber" },
  { value: 12, text: strings?.["december"], key: "december" },
];

export function optionsDays() {
  let days = [
    {
      value: "*",
      text: strings?.["day"],
      key: "every_day",
    },
  ];

  for (let index = 1; index < 32; index++) {
    days.push({
      value: index,
      text:
        index == 1 ? `${strings?.["day"]}` : `${index} ${strings?.["days"]}`,
      key: `day_${index}`,
    });
  }

  return days;
}

export const optionsDayOfWeek = [
  { value: "*", text: strings?.["any day"], key: "every_*" },
  { value: 0, text: strings?.["any day"], key: "every_dayWeek" },
  { value: 1, text: strings?.["monday"], key: "monday" },
  { value: 2, text: strings?.["tuesday"], key: "tuesday" },
  { value: 3, text: strings?.["wednesday"], key: "wednesday" },
  { value: 4, text: strings?.["thursday"], key: "thursday" },
  { value: 5, text: strings?.["friday"], key: "friday" },
  { value: 6, text: strings?.["saturday"], key: "saturday" },
  { value: 7, text: strings?.["sunday"], key: "sunday" },
];

export function optionsHours() {
  let hours = [
    // {
    //   value: 0,
    //   text: strings?.["hour"],
    //   key: "every",
    // },
  ];

  for (let index = 0; index < 24; index++) {
    hours.push({
      value: index,
      text: index < 10 ? `0${index}:..` : `${index}:..`,
      key: `hour_${index}`,
    });
  }

  return hours;
}

export function optionsMinutes() {
  let minutes = [
    // {
    //   value: 0,
    //   text: strings?.["minute"],
    //   key: "every",
    // },
  ];

  for (let index = 0; index < 60; index++) {
    minutes.push({
      value: index,
      text: index < 10 ? `..:0${index}` : `..:${index}`,
      key: `minute_${index}`,
    });
  }

  return minutes;
}

// yes, yes this be a general function which passes the # of items - get to it ;)

export function options60mins() {
  let value = [];

  for (let index = 2; index < 60; index++) {
    value.push({
      value: index,
      text:
        index == 1
          ? `${strings?.["minute"]}`
          : `${index} ${strings?.["minutes"]}`,
      key: index,
    });
  }

  return value;
}

export function options24hours() {
  let value = [];

  for (let index = 1; index < 24; index++) {
    value.push({
      value: index,
      text:
        index == 1 ? `${strings?.["hour"]}` : `${index} ${strings?.["hours"]}`,
      key: index,
    });
  }

  return value;
}

export function options12months() {
  let value = [];

  for (let index = 1; index < 13; index++) {
    value.push({
      value: index,
      text:
        index == 1
          ? `${strings?.["month"]}`
          : `${index} ${strings?.["months"]}`,
      key: index,
    });
  }

  return value;
}

export const optionsDuration = [
  //{ value: "second", text: strings?.["seconds"], key: "second" },  // #Note - only support min of 1 min ON/OFF
  { value: "minute", text: strings?.["minutes"], key: "minute" },
  { value: "hour", text: strings?.["hours"], key: "hour" },
];
