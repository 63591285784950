import React, { Component, useDebugValue } from "react";
import { connect } from "react-redux";

import {
  Form,
  Container,
  Label,
  Header,
  Grid,
  Message,
  Segment,
  Table,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { getFormValues } from "redux-form"; // #REVIEW - only used for debugging

import { push } from "react-router-redux";

import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import { renderField } from "admin/form-field";
import { SaveButton } from "admin/SaveButton";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import NamedAreaGroupTableItems from "admin/named-area-group/NamedAreaGroupTableItems";

const validate = (values) => {
  const required = [];

  const errors = {};

  required.forEach((r) => {
    if (!get(values, r)) {
      set(errors, r, "Required");
    }
  });

  //console.log("@@ validate errors", errors);

  return errors;
};

const normalise = (value, type) => {
  switch (type) {
    default:
      return value;
  }
};

class EditNamedAreaGroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentLoading: true,
      errorMessage: "",
      submitted: false,
    };
  }

  componentDidMount() {
    this.setState({ contentLoading: false });
  }

  submitMyForm = (values) => {
    //console.log(`form submitted: values`, values);
    this.props.onSubmit(values);
  };

  resetForm = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  cancelForm = (e) => {
    e.preventDefault();

    // abort page changes
    this.props.onCancel();
  };

  onItemsChange = (data) => {
    this.props.change("data", data);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      reset,
      mineLevelId,
      listOfNamedAreas,
      data,
      initialValues,
      //
      strings, // localisations
      userSettings,
    } = this.props;

    // console.log("xxx EditNamedAreaGroupForm - strings", strings);

    // APP_TERMINOLOGY
    const strNamedAreaGroup = strings?.["Control Layout"];

    if (mineLevelId === undefined) {
      return (
        <>
          <DataLoadingMessage id={mineLevelId} strings={strings} />
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    const { contentLoading } = this.state;
    if (contentLoading) {
      return (
        <Dimmer active={contentLoading} page>
          <Loader>Loading data</Loader>
        </Dimmer>
      );
    } else {
      return (
        <div className="genericGridHeader">
          <Container>
            <Grid columns={2}>
              <Grid.Row className={"genericTitleHeader"}>
                <Grid.Column width={4} textAlign={"left"}>
                  <Header as="h1">{`${strNamedAreaGroup} - ${mineLevelId.replace(
                    "_",
                    " "
                  )}`}</Header>
                </Grid.Column>
                <Grid.Column width={12} textAlign={"left"}>
                  <FlashMessagesList />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
            </Grid>
            {/* ***************Create Form ************************ */}
            {error && (
              <Message error header="Error submitting" content={error} />
            )}

            <Form
              onSubmit={handleSubmit(this.submitMyForm)}
              error={Boolean(error)}
            >
              <Segment>
                <Grid columns={2}>
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <Grid.Row>
                      <Button.Group floated="right">
                        <SaveButton
                          key={`SaveButton-${strings?.["Nothing changed"]}`}
                          submitting={submitting}
                          pristine={pristine}
                          strings={strings}
                        />
                        <Button.Or />
                        <Button
                          type="button"
                          disabled={pristine || submitting}
                          onClick={(e) => this.resetForm(e)}
                        >
                          {strings?.["Reset"]}
                        </Button>
                        <Button.Or />
                        <Button
                          style={{ textAlign: "right" }}
                          onClick={(e) => this.cancelForm(e)}
                        >
                          {strings?.["Cancel"]}
                        </Button>
                      </Button.Group>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </Segment>
              <Field name="data" type="hidden" component="input" />
              <NamedAreaGroupTableItems
                initialValues={initialValues}
                mineLevelId={mineLevelId}
                namedAreas={listOfNamedAreas}
                data={data}
                onItemsChange={(data) => this.onItemsChange(data)}
                strings={strings}
                userSettings={userSettings}
              />
            </Form>
            <DebugPagePropsMessages that={this} />
          </Container>
        </div>
      );
    }
  }
}

const selector = formValueSelector("editNamedAreaGroupForm");

EditNamedAreaGroupForm = reduxForm({
  form: "editNamedAreaGroupForm",
  // Note - to reinitialize values in componentDidMount
  // Make sure you set the values of enableReinitialize & keepDirtyOnReinitialize to true
  // See - https://stackoverflow.com/questions/44273099/redux-form-initialization-with-props-initialize
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  validate,
})(EditNamedAreaGroupForm);

const mapStateToProps = (state, props) => {
  const { initialValues } = props;
  const { mineLevelId, listOfNamedAreas, data } = initialValues;

  //  console.log('selector(state, "data"),', selector(state, "data"));

  return {
    mineLevelId,
    listOfNamedAreas,
    data: selector(state, "data"), //data,
    initialValues: initialValues,
    onChange: (values, dispatch, props, previousValues) => {
      //console.log("reduxForm onChange values", values);

      if (values.mineLevelId !== previousValues.mineLevelId) {
        //console.log("onChange", values);
        //   dispatch(change("data", data);
      }
    },
    reduxFormValues: getFormValues("editNamedAreaGroupForm")(state),
  };
};

const mapDispatchToProps = (dispatch) => ({});

EditNamedAreaGroupForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNamedAreaGroupForm);

export default EditNamedAreaGroupForm;
