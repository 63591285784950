export function fetchTags() {
  return { type: "TAGS_FETCH_REQUESTED" };
}

export const getTags = (data) => {
  return { type: "GET_TAGS", payload: data };
};

export function fetchTagTrackingAreaStatuses(payload) {
  //console.log(`fetchTagTrackingAreaStatuses`, payload);
  return { type: "TAGS_FETCH_AREA_STATUSES", payload };
}

export function assignUser(payload) {
  //console.log(`assignUser`, payload);
  return { type: "TAGS_ASSIGN_USER", payload };
}

export function assignVehicle(payload) {
  //console.log(`assignVehicle`, payload);
  return { type: "TAGS_ASSIGN_VEHICLE", payload };
}

export function unassignUser(payload) {
  //console.log(`unassignUser`, payload);
  return { type: "TAGS_UNASSIGN_USER", payload };
}

export function unassignVehicle(payload) {
  //console.log(`unassignVehicle`, payload);
  return { type: "TAGS_UNASSIGN_VEHICLE", payload };
}

// user manage

export function addUpdateUser(payload) {
  //console.log(`addUpdateUser`, payload);
  return { type: "TAGS_ADD_UPDATE_USER", payload };
}

export function addUpdateVehicle(payload) {
  //console.log(`addUpdateVehicle`, payload);
  return { type: "TAGS_ADD_UPDATE_VEHICLE", payload };
}

export function deleteTagUserById(payload) {
  //console.log(`deleteTagUserById`, payload);
  return { type: "TAGS_DELETE_USER_BY_ID", payload };
}

export function deleteTagVehicleById(payload) {
  //console.log(`deleteTagVehicleById`, payload);
  return { type: "TAGS_DELETE_VEHICLE_BY_ID", payload };
}

export function updateTagZone(payload) {
  //console.log(`updateTagZone action`, payload);
  return { type: "TAGS_UPDATE_TAG_ZONE", payload };
}

// fetch all

export function fetchAllTagUsers() {
  //console.log(`fetchAllTagUsers`);
  return { type: "TAGS_FETCH_ALL_USERS" };
}

export function fetchAllTagVehicles() {
  //console.log(`fetchAllTagVehicles`);
  return { type: "TAGS_FETCH_ALL_VEHICLES" };
}
