// #WEBWORKER REDUCER
//
// see main reducer at src/reducers.js
//
// Reducers for messages from web worker mqtt interface
//

import Immutable from "seamless-immutable";
import _isEmpty from "lodash/isEmpty";
import { combineReducers } from "redux";

import { channelBufferPublishMsg } from "apiSaga";

import { getUserSessionIp, messageToken } from "utils/messageToken";

import { microTime } from "utils/microTime";

import { downloadFileChannel } from "apiSaga";

import toSlug from "utils/toSlug";

import { cloneDeep } from "lodash";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { toFireflyColor } from "utils/toFireflyColor";
import { distinctColors } from "utils/colors";

import {
  isConfigJs,
  TemplateAllAreasButtons,
  TemplateDefaultArea,
  deviceCheckTimeout,
  isDemoMode,
} from "components/ConfigJs";

import { processAreaInfoMessage } from "components/WebWorker/processAreaInfoMessage";
import { processControllerCoordinateMessage } from "components/WebWorker/processControllerCoordinateMessage";
import { processControllerStatusMessage } from "components/WebWorker/processControllerStatusMessage";

import {
  transformGeoJsonUtmToPixels,
  transformUtmToPixels,
  transformGeoJsonUtmToPixelsByChangeList,
  transformPixelsToUtm,
  standardPtsUtmX,
  localPtsX,
} from "components/Map/util-geoJsonToPixels";

//import { getObjectDiff } from "utils/getObjectDiff";
//import { constructor, object } from "testdouble";

import { StatusEnum } from "utils/StatusEnum";
import { sendNamedAreaButtonUpdateByParent } from "components/WebWorker/sendNamedAreaButtonUpdateByParent";
//import { feature } from "@turf/turf";

//import { omitDeep } from "utils/omitDeep";

//import { GeoJsonToMqttNamedAreaChange } from "admin/named-area/utils-mqttMessages";

let initialStateMqtt = Immutable({
  // flag indicating recent fetched data to update web worker
  dataFetched: [],

  // triggers reset/terminate of webWorker after RESET
  reset: true,

  // triggers recalculation of the objects which have transform data. i.e. FFs and Controller
  recalcState: { firefly: false, controller: false },

  // monitors loading of essential mqtt message data to manage startup/reload
  dataLoading: true,
  dataloadingResponseRequestMessageQueue: [],

  // displayed message list
  mqttMsg: [],
  // record of most recent fault_ts for each fault type determined by incoming MQTT
  latestFaultTs: {
    firefly: 0,
    battery: 0,
    network: 0,
  },

  // railway_application
  externalTrigger: {},
  buttonTrigger: {},
  machineStatus: {},

  // state of map position markers
  mapMessages: [],

  //
  fireflyCoordinates: {},
  fireflyStatuses: {},

  fireflyIdsUpdateList: [],

  //
  controllerCoordinates: {},
  controllerStatuses: {},

  //
  areaInfos: {}, // geoJson object with coordinate info
  areaStatuses: {}, // key/value reference of the same properties as in areaInfos

  // flag to reload area image changed
  isAreaImagesChanged: false,

  //
  namedAreaInfos: {},
  namedAreaStatuses: {},
  namedAreaEvents: {},
  //
  namedAreaEventsButtonGroupState: {},

  //
  emergencyEventSettings: {},
  triggerEventSettings: {},

  //
  namedAreaExtTriggerEventInfo: [],

  //
  namedAreaScheduledEventInfo: [],
  //
  scheduledEventJobInfo: [],

  //
  serverTimestamp: 0,
  deviceTimestamps: {},
  systemProcessMsg: [],

  //
  timedOutDevices: [],
  timedOutLatestCheck: 0,
  timeoutPeriod: 15 * 60 * 1000, //5 * 60 * 1000; // 15 minutes
  timeoutCheckPeriod: 5 * 60 * 1000, // 5 minutes
  //
  waitEventTimeout: true, // timeout has expired on startup
  //
  publish: [],
});

function reduceGeneral(state = initialStateMqtt, action = {}) {
  // copy existing state
  const newState = { ...state };

  const { type, payload } = action;

  let areaStatuses = {};
  let publishEvents = {};
  let publishChanges = {};

  switch (type) {
    // reset to clear state after logout
    case "MQTT_RESET":
      newState.reset = payload;
      return newState;

    // data loading
    case "MQTT_DATA_LOADING_RESPONSE_REQUEST_MESSAGE_QUEUE":
      newState.dataloadingResponseRequestMessageQueue = payload;
      return newState;

    // fault messages
    // #NOTE - historically these were the first ever messages rx via MQTT
    // so they have the `honour` of being called "mqttMsg".
    //
    case "MQTT_MSG_UPDATE":
      newState.mqttMsg = payload;
      return newState;
    case "MQTT_LATEST_FAULT_TS_UPDATE":
      newState.latestFaultTs = payload;
      return newState;
    case "MQTT_MSG_CLEAR":
      newState.mqttMsg = [];
      return newState;

    // railway_application
    case "MQTT_LATEST_EXTERNAL_TRIGGER":
      newState.externalTrigger = payload;
      return newState;
    case "MQTT_LATEST_BUTTON_TRIGGER":
      newState.buttonTrigger = payload;
      return newState;
    case "MQTT_LATEST_MACHINE_STATUS_UPDATE":
      newState.machineStatus = payload;
      return newState;

    // API fetch flag
    // reset to clear state after logout
    case "CLEAR_DATA_FETCHED":
      newState.dataFetched = [...newState.dataFetched].filter(
        (item) => item !== payload
      );
      return newState;

    // firefly messages
    case "MQTT_MAP_MSG_UPDATE":
      newState.mapMessages = payload;
      return newState;

    case "UPS_FETCH_SUCCEEDED":
      //console.log(`UPS_FETCH_SUCCEEDED`);

      if (false) {
        let cloneControllerCoordinates = {};

        if (newState?.controllerCoordinates?.features !== undefined) {
          // convert existing controller coordinates to keyed object
          newState.controllerCoordinates.features.map((value, idx) => {
            const {
              properties: { id },
            } = value;

            cloneControllerCoordinates[id] = value;
          });
        }

        // console.log(
        //   `mmmm cloneControllerCoordinates`,
        //   cloneControllerCoordinates
        // );
        let cloneControllerStatuses = JSON.parse(
          JSON.stringify(newState?.controllerStatuses)
        );

        const fetchedControllers = payload?.Controllers || [];
        let newFetchControllerCoordinates = {};
        let newFetchControllerStatuses = {};

        fetchedControllers.forEach((controller, idx) => {
          const { id } = controller;

          const step1 = processControllerCoordinateMessage(
            id,
            controller,
            cloneControllerCoordinates,
            cloneControllerStatuses
          );

          const {
            controllerCoordinates: step1Coordinates,
            controllerStatuses: step1Statuses,
          } = step1;

          // DISABLE STEP 2 FOR NOW - NOT ALL INFORMATION SUPLIED BY THE API
          if (false) {
            const step2 = processControllerStatusMessage(
              id,
              controller,
              step1Coordinates,
              step1Statuses
            );

            const {
              controllerCoordinates: step2Coordinates,
              controllerStatuses: step2Statuses,
            } = step2;
          }
          if (!_isEmpty(step1Coordinates)) {
            newFetchControllerCoordinates = step1Coordinates;
          }

          if (!_isEmpty(step1Statuses)) {
            newFetchControllerStatuses = step1Statuses;
          }
        });

        const newFetchGeoJsonControllerCoordinates = {
          type: "FeatureCollection",
          features: Object.values(newFetchControllerCoordinates),
        };

        // DISABLE API PROCESSING OF CONTROLLER DATA
        if (false) {
          newState.controllerCoordinates = transformGeoJsonUtmToPixels(
            newFetchGeoJsonControllerCoordinates,
            newState.areaStatuses,
            1,
            false
          );

          newState.controllerStatuses = newFetchControllerStatuses;
        }

        // console.log(
        //   `444 newFetchGeoJsonControllerCoordinates`,
        //   newState.controllerCoordinates
        // );
        // console.log(
        //   `444 newFetchControllerStatuses`,
        //   newState.controllerStatuses
        // );
      }

      newState.dataFetched = [...newState.dataFetched, "UPS_FETCH_SUCCEEDED"];

      return newState;

    case "MQTT_CONTROLLER_COORDINATES_UPDATE":
      // convert geoJson coordinate date to relative pixel value based on area and ref coordinates

      // don't process coordinates if areaStatuses is empty
      // This is a very crude method to determined if it is empty
      // ...WTF????
      areaStatuses = JSON.parse(JSON.stringify(newState.areaStatuses));
      if (_isEmpty(areaStatuses)) {
        return newState;
      }

      //
      const newControllerCoordinates = JSON.parse(JSON.stringify(payload));

      // console.log(
      //   `MQTT_CONTROLLER_COORDINATES_UPDATE newControllerCoordinates - #REVIEW - need change check as this does a transform!`,
      //   newControllerCoordinates
      // );

      newState.controllerCoordinates = transformGeoJsonUtmToPixels(
        newControllerCoordinates,
        newState.areaStatuses,
        1,
        false
      );

      return newState;
    case "MQTT_CONTROLLER_STATUSES_UPDATE":
      let newControllerStatus = payload;

      // DISABLE API PROCESSING OF CONTROLLER DATA - DIFFERENCE CHECKS

      if (false) {
        // check if newControllerStatus is different to current controllerStatus
        let changesControllerStatuses = [];
        const currentControllerStatuses = newState?.controllerStatuses;
        for (const controller of Object.keys(newControllerStatus)) {
          // if not in current set is a `new` controller
          if (currentControllerStatuses[controller] !== undefined) {
            for (const key of Object.keys(
              currentControllerStatuses[controller]
            )) {
              // ignore new properties added to current objects e.g transformations
              if (newControllerStatus[controller][key] !== undefined) {
                if (
                  JSON.stringify(currentControllerStatuses[controller][key]) !==
                  JSON.stringify(newControllerStatus[controller][key])
                ) {
                  changesControllerStatuses.push(`${controller}:${key}`);
                }
              }
            }
          } else {
            changesControllerStatuses.push(`${controller}:new`);
          }
        }

        console.log(`changesControllerStatuses`, changesControllerStatuses);

        // run update process is there are any changes
        if (!_isEmpty(changesControllerStatuses)) {
          newState.controllerStatuses = newControllerStatus;
        }
      }

      newState.controllerStatuses = newControllerStatus;

      return newState;
    //
    case "MQTT_EMERGENCY_EVENT_SETTINGS_UPDATE":
      newState.emergencyEventSettings = payload;
      return newState;
    //
    case "MQTT_TRIGGER_EVENT_SETTINGS_UPDATE":
      newState.triggerEventSettings = payload;
      return newState;
    //

    case "MQTT_SERVER_TIMESTAMP":
      newState.serverTimestamp = payload;
      return newState;
    //

    case "MQTT_SYSTEM_PROCESS_MSG_UPDATE":
      // there is also middleware servicing this message but it gets here first.
      // see - src/components/Settings/middleware.js

      //console.log("xxx MQTT_SYSTEM_PROCESS_MSG_UPDATE", payload);

      let newSystemProcessMsg = [...newState.systemProcessMsg];

      newSystemProcessMsg.push(...payload);

      // console.log(
      //   "xxx MQTT_SYSTEM_PROCESS_MSG_UPDATE newSystemProcessMsg",
      //   newSystemProcessMsg
      // );

      newState.systemProcessMsg = newSystemProcessMsg;
      return newState;
    //

    case "MQTT_SYSTEM_PROCESS_MSG_CLEAR":
      // console.log("xxx MQTT_SYSTEM_PROCESS_MSG_CLEAR payload", payload);

      let newClearSystemProcessMsg = [...newState.systemProcessMsg];
      newClearSystemProcessMsg = newClearSystemProcessMsg.filter(
        (msg) => msg.id !== payload.id
      );

      newState.systemProcessMsg = newClearSystemProcessMsg;
      return newState;
    //

    case "MQTT_DEVICE_TIMESTAMPS":
      newState.deviceTimestamps = payload;

      // #NOTE
      // #WIP - disable pending removal
      // Replaced with simple process in Worker
      //

      if (false) {
        console.log(`xxx deviceTimestamps`, payload);

        // #NOTE
        // heartbeat pollrate = 1000
        // Run checks on timeout every 5 seconds = 5 * pollrate

        let timedOutDevices = [];

        // check how many timestamps are > timeout period

        const timeOutNow = new Date().getTime();

        // initialise time count & settings

        if (
          newState.timedOutLatestCheck === 0 ||
          newState?.timedOutLatestCheck === undefined
        ) {
          if (isConfigJs() && deviceCheckTimeout()) {
            const { period, checkPeriod } = deviceCheckTimeout();

            newState.timeoutPeriod = period || 15 * 60 * 1000; //5 * 60 * 1000; // 15 minutes;
            newState.timeoutCheckPeriod = checkPeriod || 5 * 60 * 1000; // 5 minutes
          }

          newState.timedOutLatestCheck = timeOutNow;

          console.log(
            `CHECK DEVICE TIMEOUT - CONFIGURED FOR TIMEOUT PERIOD: `,
            newState.timeoutPeriod,
            "CHECK PERIOD: ",
            newState.timeoutCheckPeriod,
            " (mSec)"
          );

          //console.log(`>>>>>> set timedOutLatestCheck`, timeOutNow);

          return newState;
        }

        // console.log(
        //   `timeOutNow - newState.timedOutLatestCheck > timeoutCheckPeriod`,
        //   timeOutNow - newState.timedOutLatestCheck > newState.timeoutCheckPeriod
        // );

        // check for timeout after timoutPeriod
        if (
          timeOutNow - newState.timedOutLatestCheck >
          newState.timeoutCheckPeriod
        ) {
          console.log(
            `CHECK DEVICE TIMEOUT - NOW: `,
            timeOutNow,
            " LAST CHECK: ",
            newState.timedOutLatestCheck
          );

          for (const key in newState.deviceTimestamps) {
            const item = newState.deviceTimestamps[key];
            const deviceTimestamp = Date.parse(item.ts);

            if (timeOutNow - deviceTimestamp > newState.timeoutPeriod) {
              console.log(`CHECK DEVICE TIMEOUT - ITEM:`, item);
              timedOutDevices.push(item);
            }
          }
          newState.timedOutLatestCheck = timeOutNow;
        }

        //console.log(`CHECK DEVICE TIMEOUT - TIMED OUT DEVICES `, timedOutDevices);

        newState.timedOutDevices = timedOutDevices;

        // update controller coordinates for timed out devices
        const timedOutControllers = timedOutDevices.filter(
          (device) => device.device === "controller"
        );

        //console.log(`>>>>>> timedOutControllers`, timedOutControllers);

        if (!_isEmpty(timedOutControllers)) {
          let updateControllerCoordinatesForDeviceTimestamps = [];
          newState.controllerCoordinates.features.map((feature, idx) => {
            const { properties } = feature;
            const { id } = properties;

            let newFeature = feature;
            let cloneProperties = JSON.parse(JSON.stringify(properties));
            if (
              timedOutControllers.some((controller) => controller.id === id)
            ) {
              cloneProperties.deviceStatus.push(StatusEnum.TIMEOUT);
              newFeature.properties = cloneProperties;
            }
            updateControllerCoordinatesForDeviceTimestamps.push(newFeature);
          });

          // newState.controllerCoordinates = {
          //   type: "FeatureCollection",
          //   features: updateControllerCoordinatesForDeviceTimestamps,
          // };
        }

        // console.log(`newState.controllerCoordinates`, newState.controllerCoordinates)

        // update firefly coordinates for timed out devices
        const timedOutFireflies = timedOutDevices.filter(
          (device) => device.device === "firefly"
        );

        if (!_isEmpty(timedOutFireflies)) {
          //console.log(`sss timedOutFireflies`, timedOutFireflies);

          // add FFs to update list if it's changed
          let fireflyIdsUpdateList = JSON.parse(
            JSON.stringify(newState.fireflyIdsUpdateList)
          );

          let updateFireflyCoordinatesForDeviceTimestamps = [];
          newState.fireflyCoordinates.features.map((feature, idx) => {
            const { properties } = feature;
            const { id } = properties;

            let newFeature = feature;
            let cloneProperties = JSON.parse(JSON.stringify(properties));
            if (timedOutFireflies.some((firefly) => firefly.id === id)) {
              cloneProperties.deviceStatus.push(StatusEnum.TIMEOUT);
              newFeature.properties = cloneProperties;
            }
            updateFireflyCoordinatesForDeviceTimestamps.push(newFeature);

            // force update of FF draw
            fireflyIdsUpdateList.push(id);

            if (id === "DMLZ_Extraction:P43:1") {
              console.log(
                `DMLZ_Extraction:P43:1 processed newFeature`,
                newFeature
              );
            }
          });

          // newState.FireflyCoordinates = {
          //   type: "FeatureCollection",
          //   features: updateFireflyCoordinatesForDeviceTimestamps,
          // };
          console.log(`sss fireflyIdsUpdateList`, fireflyIdsUpdateList);

          //newState.fireflyIdsUpdateList = fireflyIdsUpdateList;
        }
      }
      // console.log(`newState.FireflyCoordinates`, newState.FireflyCoordinates)
      return newState;
    //

    // #NOTE
    // Recalc state is used to trigger the recalculation of the state of area, controllers, fireflies etc.
    // This particular applies to the recalculation of the transforms after area reference coordinates
    // have been shifted.
    //
    case "MQTT_RECALC_STATE":
      //console.log(`MQTT_RECALC_STATE payload`, payload);

      // only update for TRUE recalc state. Allow reducer state.recalcState to be cleared
      // in the reducer itself.

      // clone a copy of the recalcState
      // #WIP #TODO - fix this cloning process
      let newRecalcState = JSON.parse(JSON.stringify(newState.recalcState));

      if (payload.firefly === true) {
        newRecalcState.firefly = true;
      }
      if (payload.controller === true) {
        newRecalcState.controller = true;
      }
      newState.recalcState = newRecalcState;

      return newState;

    case "FIREFLY_FETCH_SUCCEEDED":
      //console.log(`FIREFLY_FETCH_SUCCEEDED payload`, payload);

      newState.dataFetched = [
        ...newState.dataFetched,
        "FIREFLY_FETCH_SUCCEEDED",
      ];
      return newState;

    case "MQTT_FIREFLY_COORDINATES_UPDATE":
      // process new firefly coordinate messages
      // convert geoJson coordinate date to relative pixel value based on area and ref coordinates

      // ... get an updated copy of area statuses
      areaStatuses = JSON.parse(JSON.stringify(newState.areaStatuses));
      if (_isEmpty(areaStatuses)) {
        return newState;
      }

      // Check if the coordinates have changed and only update those that have changed,
      // by adding them to a change list 'fireflyIdsUpdateList'.

      // make a copy
      const newFireflyCoordinates = JSON.parse(JSON.stringify(payload));

      // console.log(
      //   `fireflyIdsUpdateList rx newFireflyCoordinates`,
      //   newFireflyCoordinates
      // );

      const prevFireflyCoordinates = JSON.parse(
        JSON.stringify(newState.fireflyCoordinates)
      );

      // console.log(
      //   `fireflyIdsUpdateList rx prevFireflyCoordinates`,
      //   prevFireflyCoordinates
      // );

      // #WIP - considering changing the process
      // omitDeep
      // let isChanged = !isEqual(
      //   omitDeep(newFireflyCoordinates?.feature, ["timestamp"]),
      //   omitDeep(prevFireflyCoordinates?.features, ["timestamp"])
      // );

      // add FFs to update list if it's changed
      let fireflyIdsUpdateList = JSON.parse(
        JSON.stringify(newState.fireflyIdsUpdateList)
      );
      // console.log(
      //   `fireflyIdsUpdateList rx fireflyIdsUpdateList`,
      //   fireflyIdsUpdateList
      // );

      // Create indexed versions of current and previous fireflyCoordinates.
      // Use these to compare for differences. Only update the FFs which have changed.
      //
      // FFs could be identical except for the timestamp so remove the timestamp before comparison.
      //
      // convert new FFs from geojson array to indexed object

      let newFireflyProperty = {};
      if (newFireflyCoordinates?.features !== undefined) {
        newFireflyCoordinates.features.forEach((feature) => {
          const { properties } = feature;
          const { id } = properties;

          // make a copy before deleting timestamp
          const { timestamp, ...newProperties } = properties;
          newFireflyProperty[id] = newProperties; //JSON.parse(JSON.stringify(properties)) || {};

          //delete newFireflyProperty[id].timestamp;
        });
      }

      // convert prev FFs from geojson array to indexed object
      let prevFireflyProperty = {};
      let prevFireflyFeature = {};
      // a separate copy which does not have the timestamp deleted
      let prevFireflyFeatureWithTimestamp = {};
      if (prevFireflyCoordinates?.features !== undefined) {
        prevFireflyCoordinates.features.forEach((feature) => {
          const { properties } = feature;
          const { id } = properties;

          // make a copy before deleting timestamp
          const { timestamp, ...newProperties } = properties;
          prevFireflyProperty[id] = newProperties; //      JSON.parse(JSON.stringify(properties)) || {};
          //delete prevFireflyProperty[id].timestamp;
          //
          prevFireflyFeatureWithTimestamp[id] = properties || {};
          //
          prevFireflyFeature[id] = feature;
        });
      }

      // compare new and prev objects, add changed FFs to change list array 'fireflyIdsUpdateList'
      //
      const newFFStatusIds = Object.keys(newFireflyProperty);

      // to track deleted IDs, start with all prev IDs and progressively remove those which are in the new/current set
      let fireflyIdsDeleteList = Object.keys(prevFireflyProperty);

      // console.log(
      //   `fireflyIdsUpdateList 1 fireflyIdsDeleteList`,
      //   fireflyIdsDeleteList
      // );

      newFFStatusIds.forEach((id) => {
        let isFireflyPropertyChanged =
          // this will check for FFs which are NEW and for FFs which are UPDATED
          JSON.stringify(newFireflyProperty[id]) !==
            JSON.stringify(prevFireflyProperty[id]) ||
          // add every FF to recalculate the state (e.g. area ref coordinates have changed)
          newState.recalcState?.firefly === true;

        // To account for DELETED FFs, remove the current ID from the list of prev FFs.
        // The FFs left over at the end are those that have been deleted.
        fireflyIdsDeleteList = fireflyIdsDeleteList.filter((ff) => ff !== id);

        // console.log(
        //   `fireflyIdsUpdateList 2 fireflyIdsDeleteList`,
        //   fireflyIdsDeleteList
        // );

        // _#DEMO_MODE
        if (isDemoMode()) {
          console.log(
            "DEMO_MODE - PROCESS UPDATE <=================================== #REVIEW - CAN FF CHANGES BE CHECKED???"
          );

          // If demo mode update every firefly, every update. Don't check for changes.
          // This is to ensure firefly states are updated even though only /lightingplan is tx/rx.
          // e.g. the events from namedArea, emergency and triggers are no sent through /lightingplan
          // but are updated later.
          isFireflyPropertyChanged = true;
        }

        // if (isFireflyPropertyChanged) {
        //   console.log(
        //     "isDemoMode - PROCESS UPDATE isFireflyPropertyChanged <==================================="
        //   );
        // }

        if (isFireflyPropertyChanged) {
          //#WIP #NOTE #TODO
          // #fireflyIdsUpdateList
          // Consider passing a list of changes to the `fireflyIdsUpdateList` so can update only changes
          // e.g. { id: "DMLZ_Extraction:P23:10", changes: ["color", "light", "controllerMode", "deviceStatus", "ups_id"]}
          //
          // This means I'll have to filter `fireflyIdsUpdateList` instead.
          //
          // could change fireflyIdsUpdateList from array to object
          // i.e. fireflyIdsUpdateList[id]= getObjectDiff(newFireflyProperty[id], prevFireflyProperty[id] || [])

          const alreadyInTheList = fireflyIdsUpdateList.includes(id);

          if (!alreadyInTheList) {
            fireflyIdsUpdateList.push(id);
          }

          // console.log(
          //   `fireflyIdsUpdateList newFFStatusIds ADDED id`,
          //   id,
          //   `--`,
          //   fireflyIdsUpdateList,
          //   "new",
          //   newFireflyProperty[id],
          //   "prev",
          //   prevFireflyProperty[id]
          // );
        }
      });

      // console.log(`rrrrrrrrr fireflyIdsUpdateList payload`, payload);
      // console.log(`rrrrrrrrr fireflyIdsUpdateList`, fireflyIdsUpdateList);

      // transform FFs which are in the change list 'fireflyIdsUpdateList'
      let updatedFireflyCoordinates = transformGeoJsonUtmToPixelsByChangeList(
        prevFireflyFeature,
        // #NOTE - 'newFireflyCoordinates' does not contain the 'timestamp'
        // pass payload for update to retain the timestamp information
        JSON.parse(JSON.stringify(payload)), // was 'newFireflyCoordinates',
        fireflyIdsUpdateList,
        newState.areaStatuses
      );

      // filter out the deleted fireflies
      let updatedFireflyCoordinatesFeatures =
        updatedFireflyCoordinates?.features?.filter(
          (ff) => !fireflyIdsDeleteList.includes(ff.id)
        );

      // ... and update the coordinate information
      updatedFireflyCoordinates = {
        type: "FeatureCollection",
        features: updatedFireflyCoordinatesFeatures,
      };

      newState.fireflyCoordinates = updatedFireflyCoordinates;

      // #Note - the MinelevelMapLeaflet > geoJSONMarkersData @"Update firefly marker" code should now have the latest coordinate
      // data with the deleted FFs. However geoJSONMarkersData will only re-render if there are updates.
      // IF FFs have *only* been deleted (and not added or updated) then there is no reason to update.
      // Force an update by adding one of the delete list to the update list.
      // i.e. if there *are* deleted items.
      // The deleted Id will have no impact on the update (as the IDs are not in the fireflyCoordinates data) but will
      // have the effect of triggering the update process.

      // console.log(`update fireflyIdsUpdateList`, fireflyIdsUpdateList);
      // console.log(
      //   `update fireflyIdsUpdateList fireflyIdsUpdateList.length`,
      //   fireflyIdsUpdateList.length
      // );
      // console.log(
      //   `update fireflyIdsUpdateList fireflyIdsDeleteList`,
      //   fireflyIdsDeleteList
      // );
      // console.log(
      //   `update fireflyIdsUpdateList fireflyIdsDeleteList.length > 0`,
      //   fireflyIdsDeleteList.length > 0
      // );
      // console.log(`update fireflyIdsUpdateList [fireflyIdsDeleteList[0]]`, [
      //   fireflyIdsDeleteList[0],
      // ]);

      const updateFireflyIdsUpdateList =
        fireflyIdsUpdateList.length > 0
          ? fireflyIdsUpdateList
          : fireflyIdsDeleteList.length > 0 // force update to clear the screen (see comment above)
          ? [fireflyIdsDeleteList[0]] // add arbitrary item
          : [];

      newState.fireflyIdsUpdateList = updateFireflyIdsUpdateList;

      // Although the coordinate data has been updated, the map layers still exist.
      // Need to pass a delete list to remove from the leaflet map layers
      newState.fireflyIdsDeleteList = fireflyIdsDeleteList;

      // _#DEBUG - useful for debugging the update and delete list
      if (false) {
        console.log(
          `UPDATE -> fireflyIdsUpdateList fireflyIdsDeleteList`,
          fireflyIdsDeleteList
        );
        console.log(
          `UPDATE -> fireflyIdsUpdateList updateFireflyIdsUpdateList`,
          updateFireflyIdsUpdateList
        );
      }

      // firefly recalculation has been done
      // #WIP #TODO - this is shitty code because state is immutable
      // Fix this.
      let newRecalcStateForFireflyCoord = JSON.parse(
        JSON.stringify(newState.recalcState)
      );
      newRecalcStateForFireflyCoord.firefly = false;
      newState.recalcState = newRecalcStateForFireflyCoord;

      return newState;
    case "MQTT_FIREFLY_STATUSES_UPDATE":
      // const newFireflyStatuses = payload;
      // const fireflyStatuses = newState.fireflyStatuses;

      // // add FFs to update list if it's changed
      // let fireflyIdsUpdateList = JSON.parse(
      //   JSON.stringify(newState.fireflyIdsUpdateList)
      // );

      // const newFireflyStatusesIds = Object.keys(newFireflyStatuses);
      // newFireflyStatusesIds.forEach((id) => {
      //   let newStatus = JSON.parse(
      //     JSON.stringify(newFireflyStatuses[id] || {})
      //   );
      //   if (newStatus?.timestamp !== undefined) {
      //     delete newStatus.timestamp;
      //   }
      //   let status = JSON.parse(JSON.stringify(fireflyStatuses[id] || {}));
      //   if (status?.timestamp !== undefined) {
      //     delete status.timestamp;
      //   }

      //   console.log("JSON.stringify(newStatus) ", JSON.stringify(newStatus));
      //   console.log("JSON.stringify(status)", JSON.stringify(status));

      //   if (JSON.stringify(newStatus) !== JSON.stringify(status)) {
      //     fireflyIdsUpdateList.push(id);
      //   }
      // });

      // // console.log("fireflyIdsUpdateList", fireflyIdsUpdateList);

      // newState.fireflyIdsUpdateList = fireflyIdsUpdateList;

      // break fireflies down by STATUS Status.Enum

      //console.log(`fireflyStatuses`, payload);

      newState.fireflyStatuses = payload;
      return newState;
    //

    case "MQTT_FIREFLY_STATUSES_UPDATE_LIST_DELETE_ID":
      const fireflyIdsUpdateListIds = payload;
      //console.log("deleteId", payload);
      const oldIds = newState.fireflyIdsUpdateList;
      //console.log("QQQ fireflyIdsUpdateList fireflyIdsUpdateListIds", payload);

      //console.log("QQQ fireflyIdsUpdateList updatedFireflyIds oldIds", oldIds);
      let newIds = [...oldIds];

      fireflyIdsUpdateListIds.forEach((deletedId) => {
        newIds = newIds.filter((id) => id !== deletedId);
      });
      //console.log("QQQ fireflyIdsUpdateList updatedFireflyIds newIds", newIds);
      newState.fireflyIdsUpdateList = newIds;

      return newState;

    //
    case "SET_AREA_IMAGES_CHANGED":
      newState.isAreaImagesChanged = payload;
      return newState;

    case "MINE_LEVELS_FETCH_SUCCEEDED":
      console.log(`MINE_LEVELS_FETCH_SUCCEEDED`);

      if (false) {
        let newFetchAreaInfos = JSON.parse(JSON.stringify(newState?.areaInfos));
        let newFetchAreaStatuses = JSON.parse(
          JSON.stringify(newState?.areaStatuses)
        );

        const fetchedAreas = payload?.areas || [];
        fetchedAreas.forEach((area, idx) => {
          const { id } = area;
          if (id !== "ALL_AREAS") {
            const newAreaInfosStatuses = processAreaInfoMessage(
              id,
              area,
              newFetchAreaInfos,
              newFetchAreaStatuses,
              TemplateDefaultArea()
            );

            const { areaInfos: newAreaInfos, areaStatuses: newAreaStatuses } =
              newAreaInfosStatuses;

            //console.log(`newFetchAreaInfos`, newAreaInfos);
            //console.log(`newFetchAreaStatuses`, newAreaStatuses);

            if (!_isEmpty(newAreaInfos)) {
              newFetchAreaInfos = newAreaInfos;
            }

            if (!_isEmpty(newAreaStatuses)) {
              newFetchAreaStatuses = newAreaStatuses;
            }
          }
        });

        const newFetchFeatureCollectionAreaInfos = {
          type: "FeatureCollection",
          features: Object.values(newFetchAreaInfos),
        };

        newState.areaInfos = newFetchFeatureCollectionAreaInfos;
        newState.areaStatuses = processAreaStatuses(newFetchAreaStatuses);

        // manage data loading state
        // if > 1 areas, consider data has loaded
        if (Object.keys(newFetchAreaStatuses).length > 1) {
          newState.dataLoading = false;
        }
      }

      newState.dataFetched = [
        ...newState.dataFetched,
        "MINE_LEVELS_FETCH_SUCCEEDED",
      ];
      return newState;

    case "MQTT_AREA_INFOS_UPDATE":
      newState.areaInfos = payload;

      //console.log(`KKKKK MQTT_AREA_INFOS_UPDATE`, payload);

      return newState;

    case "MQTT_AREA_STATUSES_UPDATE":
      // update area with transform data
      let newAreaStatus = payload;

      //console.log(`KKKKK MQTT_AREA_STATUSES_UPDATE`, payload);

      if (false) {
        const areas = Object.keys(newAreaStatus);

        // add transforms to the areaStatus object
        for (const area of areas) {
          if (!_isEmpty(newAreaStatus[area].ref_coord)) {
            newAreaStatus[area].transform = transformUtmToPixels(
              newAreaStatus[area]
            );
            newAreaStatus[area].transformUtmToPixels = transformUtmToPixels(
              newAreaStatus[area]
            );
            newAreaStatus[area].transformPixelsToUtm = transformPixelsToUtm(
              newAreaStatus[area]
            );

            // add reference points
            const std = standardPtsUtmX(newAreaStatus[area]);
            const local = localPtsX(newAreaStatus[area]);
            newAreaStatus[area].standardPtsUtmX = std;
            newAreaStatus[area].localPtsX = local;

            //
          } else {
            console.log(
              `LOADING ERROR: Area '${newAreaStatus[area].id}' Ref_coord is empty. Check mqtt message 'mosquitto_sub -v -t area/${newAreaStatus[area].id}/info'`
            );
            // ref_coord empty - can't process so delete object
            delete newAreaStatus[area];
          }
        }

        newState.areaStatuses = newAreaStatus;
      }

      // check if an area has been deleted
      const currentAreaStatuses = newState?.areaStatuses;

      Object.keys(currentAreaStatuses).forEach((key) => {
        if (currentAreaStatuses[key].id !== newAreaStatus[key]?.id) {
          delete currentAreaStatuses[key];
        }
      });

      // check if newAreaStatus is different to current areaStatus
      let changesAreaStatuses = [];
      for (const area of Object.keys(newAreaStatus)) {
        // ignore defaultArea
        if (area !== "defaultArea") {
          // if not in current set is a `new` area
          if (currentAreaStatuses[area] !== undefined) {
            for (const key of Object.keys(currentAreaStatuses[area])) {
              // ignore new properties added to current named areas e.g transformations
              if (newAreaStatus[area][key] !== undefined) {
                if (
                  JSON.stringify(currentAreaStatuses[area][key]) !==
                  JSON.stringify(newAreaStatus[area][key])
                ) {
                  changesAreaStatuses.push(`${area}:${key}`);
                }
              }
            }
          } else {
            changesAreaStatuses.push(`${area}:new`);
          }
        }
      }

      // run update process is there are any changes
      if (!_isEmpty(changesAreaStatuses)) {
        newState.areaStatuses = processAreaStatuses(newAreaStatus);
        // manage data loading state
        // if > 1 areas, consider data has loaded
        if (Object.keys(newAreaStatus).length > 1) {
          newState.dataLoading = false;
        }
      }

      return newState;

    //
    // fetch named area data from server
    case "NAMED_AREA_FETCH_SUCCEEDED":
      console.log(`NAMED_AREA_FETCH_SUCCEEDED payload`, payload);

      newState.dataFetched = [
        ...newState.dataFetched,
        "NAMED_AREA_FETCH_SUCCEEDED",
      ];
      return newState;

    case "MQTT_NAMED_AREA_INFOS_UPDATE":
      newState.namedAreaInfos = payload;
      return newState;
    case "MQTT_NAMED_AREA_STATUSES_UPDATE":
      newState.namedAreaStatuses = payload;
      return newState;
    //
    // Event messages
    //
    case "MQTT_NAMED_AREA_EVENTS_UPDATE":
      // update events payload
      newState.namedAreaEvents = payload;

      // #DEBUG
      if (false) {
        console.log(
          "buttonActive Single MQTT_NAMED_AREA_EVENTS_UPDATE",
          payload
        );
      }

      // get the named area events
      const namedAreaEvents = newState.namedAreaEvents;

      // update event group states if the timeout has expired &
      // named area events have been logged
      //
      if (newState.waitEventTimeout && !_isEmpty(namedAreaEvents)) {
        // **************************************************************
        // #REVIEW - this is similar process to finding a parent for the namedArea
        // generalise this!!!!
        //

        // convert from a keyed object to an array
        const keys = Object.keys(namedAreaEvents).sort();
        const naEventsArray = stableOrder(keys, namedAreaEvents);
        // get the unique parents of this array
        const uniqueParents = naEventsArray
          .map((item) => item.parent)
          .filter((value, index, self) => self.indexOf(value) === index);
        // setup to get the new named area event button groups for each event parent
        // as an object keyed on the parent with the groups status....
        //
        let newNamedAreaEventsButtonGroupState = {};
        uniqueParents.forEach(function (uniqueParent, idx) {
          const namedAreaEventParentInfo = naEventsArray.find(
            (item) => item.parent === uniqueParent
          );
          const id = uniqueParent;
          const { button_groups } = namedAreaEventParentInfo;
          //...the keyed object...
          newNamedAreaEventsButtonGroupState[id] =
            button_groups !== undefined ? button_groups : {};
        });

        // #NOTE - this code applied before ALL_AREAS events were introducte
        // and level wide events were not consider normal named areas

        if (false) {
          // add group state for whole mine status

          // find all named areas for the mine,
          // there is a level wide named area named after
          // each area name. i.e. area id = named area id

          //
          // the button state for the level wide named areas and
          // set whole mine state is same

          newNamedAreaEventsButtonGroupState["ALL_AREAS"] = { 0: 0 };

          const allAreas = newState.areaStatuses;
          // convert from a keyed object to an array
          const allAreasKeys = Object.keys(allAreas).sort();
          const allAreasKeysArray = stableOrder(allAreasKeys, allAreas);

          if (allAreasKeysArray !== undefined && allAreasKeysArray.length > 0) {
            const allNamedAreas = allAreasKeysArray.map((area) => area.id);
            // use 1st named area as a template
            const template =
              newNamedAreaEventsButtonGroupState[allNamedAreas[0]];

            //console.log("xxx template", template);
            let isWholeMine = true;
            for (let index = 0; index < allNamedAreas.length; index++) {
              if (
                JSON.stringify(template) !==
                JSON.stringify(
                  newNamedAreaEventsButtonGroupState[allNamedAreas[index]]
                )
              ) {
                isWholeMine = false;
              }
            }
            if (isWholeMine) {
              newNamedAreaEventsButtonGroupState["ALL_AREAS"] = template;
            }
          }
        }

        // **************************************************************

        // assign the latest state of the buttons to the local store
        newState.namedAreaEventsButtonGroupState =
          newNamedAreaEventsButtonGroupState;

        // console.log(
        //   `MQTT_NAMED_AREA_EVENTS_UPDATE newNamedAreaEventsButtonGroupState`,
        //   newNamedAreaEventsButtonGroupState
        // );

        // reset timeout flag
        newState.waitEventTimeout = true;
      }

      return newState;

    // DEACTIVATE AN EVENT -------->
    case "DEACTIVATE_NAMED_AREA_EVENT":
      console.log("DEACTIVATE_NAMED_AREA_EVENT payload", payload);

      if (
        payload.operation.id === "ALL_AREAS" ||
        payload.operation.id === "LEVEL_WIDE"
      ) {
        console.log("Processing ALL_AREAS DEACTIVATE ... event!!");

        publishEvents = sendNamedAreaEventAllAreas(
          newState.namedAreaStatuses,
          payload.operation,
          newState.namedAreaEventsButtonGroupState,
          false
        );
      } else {
        publishEvents = sendNamedAreaEventParent({
          namedAreaStatuses: newState.namedAreaStatuses,
          operation: payload.operation,
          namedAreaEventsButtonGroupState:
            newState.namedAreaEventsButtonGroupState,
          namedAreaEvents: newState.namedAreaEvents,
          active: false,
        });
      }
      console.log("MQTT_PUBLISH publishEvents", publishEvents);
      newState.publish = Immutable(
        newState.publish.asMutable().concat(publishEvents)
      );
      return newState;

    // ACTIVATE AN EVENT -------->
    // #NOTE - this sends a group of events based on the parent
    //

    case "ACTIVATE_NAMED_AREA_EVENT":
      console.log("ACTIVATE_NAMED_AREA_EVENT payload", payload);

      if (
        payload.operation.id === "ALL_AREAS" ||
        payload.operation.id === "LEVEL_WIDE"
      ) {
        console.log("PROCESSING 'ALL_AREAS' ACTIVATE... EVENT!!");

        publishEvents = sendNamedAreaEventAllAreas(
          newState.namedAreaStatuses,
          payload.operation,
          newState.namedAreaEventsButtonGroupState,
          true
        );
      } else {
        publishEvents = sendNamedAreaEventParent({
          namedAreaStatuses: newState.namedAreaStatuses,
          operation: payload.operation,
          namedAreaEventsButtonGroupState:
            newState.namedAreaEventsButtonGroupState,
          namedAreaEvents: newState.namedAreaEvents,
          active: true,
        });
      }

      console.log("MQTT_PUBLISH publishEvents", publishEvents);
      newState.publish = Immutable(
        newState.publish.asMutable().concat(publishEvents)
      );
      return newState;

    // ACTIVATE A SINGLE EVENT -------->
    // #NOTE - this sends a single event
    //

    case "ACTIVATE_NAMED_AREA_EVENT_SINGLE":
      //console.log("ACTIVATE_NAMED_AREA_EVENT_SINGLE payload", payload);

      if (
        payload.operation.id === "ALL_AREAS" ||
        payload.operation.id === "LEVEL_WIDE"
      ) {
        console.log("PROCESSING ALL_AREAS ACTIVATE...SINGLE...EVENT!!");

        // Don't do anything...shouldn't be here
      } else {
        publishEvents = sendNamedAreaEventSingle(
          newState.namedAreaStatuses,
          payload.operation,
          newState.namedAreaEventsButtonGroupState,
          newState.namedAreaEvents,
          true
        );
      }

      console.log("MQTT_PUBLISH publishEvents", publishEvents);
      newState.publish = Immutable(
        newState.publish.asMutable().concat(publishEvents)
      );
      return newState;

    case "UPDATE_BUTTONS_GROUP_STATE":
      newState.namedAreaEventsButtonGroupState = payload.buttons;
      // console.log(
      //   "kkk xxx newState.namedAreaEventsButtonGroupState",
      //   newState.namedAreaEventsButtonGroupState
      // );
      return newState;

    case "UPDATE_BUTTONS_IN_NAMED_AREAS_BY_PARENT_MQTT":
      // console.log(
      //   "UPDATE_BUTTONS_IN_NAMED_AREAS_BY_PARENT_MQTT payload",
      //   payload
      // );

      const { parentId, buttons } = payload;

      publishChanges = sendNamedAreaButtonUpdateByParent(
        newState.namedAreaStatuses,
        parentId,
        buttons
      );

      console.log("MQTT_PUBLISH publishButtonUpdates", publishChanges);
      newState.publish = Immutable(
        newState.publish.asMutable().concat(publishChanges)
      );

      return newState;

    case "WAIT_EVENT_TIMEOUT":
      //console.log("WAIT_EVENT_TIMEOUT payload.action", payload.action);
      switch (payload.action) {
        case "start": // start the timeout (wait) period
          newState.waitEventTimeout = false;
          break;
        case "stop": // don't wait
          newState.waitEventTimeout = true;
          break;
        default:
          newState.waitEventTimeout = true;
          break;
      }
      return newState;

    // Publish Messages
    case "MQTT_PUBLISH":
      newState.publish = Immutable(
        newState.publish.asMutable().concat([payload])
      );
      return newState;
    case "REMOVE_MQTT_MESSAGE_BY_TOKEN":
      newState.publish = Immutable(
        newState.publish
          .asMutable()
          .filter((item) => item.message.token !== payload)
      );
      return newState;

    // #REVIEW - review need. Either re-write or delete
    //
    // // case "CLEAR_MQTT_MESSAGES":
    // //   console.log("CLEAR_MQTT_MESSAGES MQTT_PUBLISH");
    // //   return publishInitialState;
    // case "REMOVE_MQTT_MESSAGE":
    //   return Immutable(
    //     newState.publish
    //       .asMutable()
    //       .filter(({ id }) => id !== action.payload.id)
    //   );

    case "NAMED_AREA_EXT_TRIGGER_EVENT_INFO_FETCH_SUCCEEDED":
      newState.namedAreaExtTriggerEventInfo = payload;
      // console.log(
      //   "kkk xxx newState.namedAreaExtTriggerEventInfo",
      //   newState.namedAreaExtTriggerEventInfo
      // );

      return newState;

    case "NAMED_AREA_SCHEDULED_EVENT_INFO_FETCH_SUCCEEDED":
      newState.namedAreaScheduledEventInfo = payload;
      console.log(
        "kkk xxx newState.namedAreaScheduledEventInfo",
        newState.namedAreaScheduledEventInfo
      );

      return newState;

    case "SCHEDULED_EVENT_JOBS_FETCH_SUCCEEDED":
      const {
        scheduledEventJobs: { list },
      } = payload;

      newState.scheduledEventJobInfo = list;

      // console.log(
      //   "kkk xxx newState.scheduledEventJobInfo",
      //   newState.scheduledEventJobInfo
      // );

      return newState;

    case "RESET":
      console.log(`RESET! - reduceGeneral`);
      return initialStateMqtt; //Always return the initial state
    default:
      return newState;
  }
}

// processAreaStatuses
const processAreaStatuses = (payload) => {
  // update area with transform data
  let newAreaStatus = payload;

  const areas = Object.keys(newAreaStatus);

  // add transforms to the areaStatus object
  for (const area of areas) {
    if (!_isEmpty(newAreaStatus[area].ref_coord)) {
      newAreaStatus[area].transform = transformUtmToPixels(newAreaStatus[area]);
      newAreaStatus[area].transformUtmToPixels = transformUtmToPixels(
        newAreaStatus[area]
      );
      newAreaStatus[area].transformPixelsToUtm = transformPixelsToUtm(
        newAreaStatus[area]
      );

      // add reference points
      const std = standardPtsUtmX(newAreaStatus[area]);
      const local = localPtsX(newAreaStatus[area]);
      newAreaStatus[area].standardPtsUtmX = std;
      newAreaStatus[area].localPtsX = local;

      //
    } else {
      console.log(
        `LOADING ERROR: Area '${newAreaStatus[area].id}' Ref_coord is empty. Check mqtt message 'mosquitto_sub -v -t area/${newAreaStatus[area].id}/info'`
      );
      console.log(`LOADING ERROR: newAreaStatus[area]`, newAreaStatus[area]);
      // ref_coord empty - can't process so delete object
      delete newAreaStatus[area];
    }
  }

  return newAreaStatus;
};

// ********************************************************************************

//getAllAreaStatuses

// ********************************************************************************

// reducer used to pass map information and settings
const mapStateInitialState = Immutable({
  deleteSelected: [],
  hideSelected: [],
  isDirty: {
    firefly: [],
    namedArea: [],
    area: [],
  },
});

function reduceMapState(state = mapStateInitialState, action = {}) {
  const { type, payload } = action;

  // copy existing state
  let newState = { ...state };

  let tempArray = [];

  switch (type) {
    case "MAP_SET_DELETE_SELECTIONS_NAMED_AREAS":
      // #TODO / REVIEW - causes problem with immutable access to nested objects
      console.log("FIX THIS!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log("MAP_SET_DELETE_SELECTIONS_NAMED_AREAS", action.payload);
      tempArray = state.deleteSelected.concat(action.payload);
      return state.setIn(["deleteSelected"], Immutable(tempArray));
    case "MAP_CLEAR_DELETE_SELECTIONS_NAMED_AREAS":
      // #REVIEW - this is shite but I was having trouble with mutable state. Need to clean this up!
      tempArray = [];
      tempArray = newState.deleteSelected.filter(
        (namedArea) => namedArea.id !== action.payload.id // #REVIEW/TODO - should be action.payload.id as per same  below and make common function
      );
      return Immutable(newState).setIn(["deleteSelected"], tempArray);

    case "MAP_SET_HIDE_NAMED_AREAS":
      // #TODO / REVIEW - causes problem with immutable access to nested objects
      console.log("FIX THIS!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      tempArray = state.hideSelected.concat(action.payload);
      return state.setIn(["hideSelected"], Immutable(tempArray));
    case "MAP_CLEAR_HIDE_NAMED_AREAS":
      // #REVIEW - this is shite but I was having trouble with mutable state. Need to clean this up!
      tempArray = [];
      tempArray = newState.hideSelected.filter(
        (namedArea) => namedArea.id !== action.payload.id // #REVIEW/TODO - see comment above - make common function
      );
      return Immutable(newState).setIn(["hideSelected"], tempArray);

    case "MAP_NAMED_AREAS_SET_IS_DIRTY":
      console.log("MAP_NAMED_AREAS_SET_IS_DIRTY", action.payload);
      return Immutable(
        state.setIn(
          ["isDirty", "namedArea"],
          state.isDirty.namedArea.concat([action.payload])
        )
      );
    case "MAP_NAMED_AREAS_CLEAR_IS_DIRTY":
      // #REVIEW - this is shite but I was having trouble with state corruption. Need to clean this up!
      tempArray = [];
      tempArray = state.isDirty.namedArea.filter(
        (namedArea) => namedArea !== action.payload
      );
      return Immutable(newState).setIn(["isDirty", "namedArea"], tempArray);
    case "RESET":
      console.log(`RESET! - reduceMapState`);
      return mapStateInitialState; //Always return the initial state
    default:
      return state;
  }
}

// reducer managing outgoing publish messages
const publishInitialState = Immutable([]);

function reducePublish(state = publishInitialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    //    publish messages
    case "MQTT_PUBLISH":
      console.log("MQTT_PUBLISH reducer!", payload);
      return state.concat([action.payload]);
    case "REMOVE_MQTT_MESSAGE_BY_TOKEN":
      console.log("REMOVE_TOKEN MQTT_PUBLISH payload", payload);
      // return Immutable(
      //   state.asMutable().filter(({ token }) => token !== action.payload)
      // );
      return state;
    case "CLEAR_MQTT_MESSAGES":
      console.log("CLEAR_MQTT_MESSAGES MQTT_PUBLISH");
      return publishInitialState;
    case "REMOVE_MQTT_MESSAGE":
      return Immutable(
        state.asMutable().filter(({ id }) => id !== action.payload.id)
      );
    case "RESET":
      console.log(`RESET! - reducePublish`);
      return publishInitialState; //Always return the initial state
    default:
      return state;
  }
}

// reducer managing ack messages
const ackInitialState = Immutable([]);

function reduceAck(state = ackInitialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case "MQTT_ACKS_UPDATE":
      if (payload !== "") {
        //console.log("MQTT_ACKS_UPDATE", JSON.parse(payload));
        return state.concat(payload); //JSON.parse(payload)
      }
      return state;
    case "REMOVE_ACKS":
      return Immutable(
        state.asMutable().filter(({ token }) => token !== payload)
      );
    case "CHECK_RECEIVE_ACKS":
      console.log("isAcksReceived ", payload);
      // process Acks here
      return state;
    case "CLEAR_ACKS":
      return ackInitialState;
    case "RESET":
      console.log(`RESET! - reduceAck`);
      return ackInitialState; //Always return the initial state
    default:
      return state;
  }
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}

export const getLatestFaultTs = (state) =>
  state.webWorker.general.latestFaultTs;

export const getMqttMsg = (state) => state.webWorker.general.mqttMsg;

export const getFaults = (state) => state.webWorker.general.mqttMsg;

export const getFaultByUpsId = (state, id) => {
  return getMqttMsg(state).find((msg) => msg.id === id);
};

// railway_application

export const getExternalTrigger = (state) =>
  state.webWorker.general.externalTrigger;
export const getAllExternalTriggerById = (state, id) =>
  state.webWorker.general.externalTrigger[id];
export const getAllExternalTrigger = (state) => {
  const byId = state.webWorker.general.externalTrigger;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getButtonTrigger = (state) =>
  state.webWorker.general.buttonTrigger;
export const getAllButtonTriggerById = (state, id) =>
  state.webWorker.general.buttonTrigger[id];
export const getAllButtonTrigger = (state) => {
  const byId = state.webWorker.general.buttonTrigger;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getMachineStatus = (state) =>
  state.webWorker.general.machineStatus;
export const getMachineStatusById = (state, id) =>
  state.webWorker.general.machineStatus[id];
export const getAllMachineStatus = (state) => {
  const byId = state.webWorker.general.machineStatus;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getMapState = (state) => state.webWorker.general.mapMessages;

//
export const getControllerCoordinates = (state) =>
  state.webWorker.general.controllerCoordinates;

export const getControllerCoordinatesById = (state, id) => {
  const geoJson = state.webWorker.general.controllerCoordinates;
  if (geoJson === undefined || _isEmpty(geoJson)) return undefined;
  geoJson.getFeaturesByProperty = function (key, value) {
    return this.features.find(function (feature) {
      if (feature.properties[key] === value) {
        return true;
      } else {
        return false;
      }
    });
  };
  const coordinatesUtm = geoJson.getFeaturesByProperty("id", id)?.geometry
    ?.coordinates;
  // transform here from areaStatus information
  // -> coordinatesPixel
  return coordinatesUtm; // <- coordinatesPixel
};

export const getControllerCoordinatesByAreaId = (state, id) => {
  const geoJson = state.webWorker?.general?.controllerCoordinates;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

// i.e. getAllUPSs = (state)
export const getAllControllerStatuses = (state) => {
  const byId = state.webWorker.general.controllerStatuses;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

// i.e. getUPSById()
export const getControllerById = (state, id) =>
  state.webWorker.general.controllerStatuses[id];

// WIP - trash if unused
export const getControllerPortsById = (state, id) => {
  const fireflies = getAllFireflyStatuses(state).filter(
    (ff) => ff.getIn(["topology", "ups_id"]) === id
  );

  return fireflies;
  // return getAllFireflyStatuses(state)
  //   .filter((ff) => ff.getIn(["topology", "ups_id"]) === id)
  //   .map((ff) => ff.id);
};

// export const getControllerEmergencyEventSettingsById = (state, id) => {
//   const { emergencyEventSettings } = state.webWorker.general.controllerStatuses[
//     id
//   ]?.topology;

//   if (!_isEmpty(emergencyEventSettings)) {
//     let sortEmergencyEventSettings = JSON.parse(
//       JSON.stringify(emergencyEventSettings)
//     );
//     // sort port by id
//     sortEmergencyEventSettings.ports.sort((a, b) => (a.id > b.id ? 1 : -1));
//     return sortEmergencyEventSettings;
//   } else {
//     return undefined;
//   }
// };

export const getControllerEmergencyEventSettingsById = (state, id) => {
  return state.webWorker.general.emergencyEventSettings[id];
};

// export const getControllerTriggerEventSettingsById = (state, id) => {
//   const { triggerEventSettings } = state.webWorker.general.controllerStatuses[
//     id
//   ]?.topology;

//   if (!_isEmpty(triggerEventSettings)) {
//     let sortTriggerEventSettings = JSON.parse(
//       JSON.stringify(triggerEventSettings)
//     );
//     // sort port by id
//     sortTriggerEventSettings.ports.sort((a, b) => (a.id > b.id ? 1 : -1));
//     return sortTriggerEventSettings;
//   } else {
//     return undefined;
//   }
// };

export const getControllerTriggerEventSettingsById = (state, id) => {
  return state.webWorker.general.triggerEventSettings[id];
};

// i.e. getUPSIdsForMineLevelId
export const getControllerIdsForAreaId = (state, area) => {
  return getAllControllerStatuses(state)
    .filter((ups) => ups.getIn(["topology", "area"]) === area)
    .map((ups) => ups.id);
};

// geoJson of fireflies
export const getFireflyCoordinates = (state) =>
  state.webWorker.general.fireflyCoordinates;

// coord of particular FF id
export const getFireflyCoordinatesById = (state, id) => {
  const geoJson = state.webWorker.general.fireflyCoordinates;
  if (geoJson === undefined || _isEmpty(geoJson)) return undefined;
  geoJson.getFeaturesByProperty = function (key, value) {
    return this.features.find(function (feature) {
      // find -> one id
      if (feature.properties[key] === value) {
        return true;
      } else {
        return false;
      }
    });
  };
  return geoJson.getFeaturesByProperty("id", id)?.geometry?.coordinates;
};

export const getFireflyCoordinatesByAreaId = (state, id) => {
  const geoJson = state.webWorker?.general?.fireflyCoordinates;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getFireflyCoordinatesForcedByAreaId = (state, id) => {
  const geoJson = state.webWorker?.general?.fireflyCoordinates;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area === id && item.properties.forced === true
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getAllFireflyStatuses = (state) => {
  const byId = state.webWorker.general.fireflyStatuses;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getFireflyById = (state, id) =>
  state.webWorker.general.fireflyStatuses[id];

export const getFireflyByControllerId = (state, ups_id) => {
  return getAllFireflyStatuses(state).filter(
    (ff) => ff.getIn(["topology", "ups_id"]) === ups_id
  );
};

export const getFireflyIdsForMineLevelId = (state, area) => {
  return getAllFireflyStatuses(state)
    .filter((ff) => ff.getIn(["topology", "area"]) === area)
    .map((ff) => ff.id);
};

export const getFireflyIdsUpdateListByArea = (state, id) => {
  const idArray = state.webWorker?.general?.fireflyIdsUpdateList || [];
  if (idArray !== undefined) {
    return idArray?.filter((item) => item.includes(`${id}:`));
  } else {
    return [];
  }
};

export const getFireflyIdsDeleteListByArea = (state, id) => {
  const idArray = state.webWorker?.general?.fireflyIdsDeleteList || [];
  if (idArray !== undefined) {
    return idArray?.filter((item) => item.includes(`${id}:`));
  } else {
    return [];
  }
};

//
export const getAreaInfos = (state) => state.webWorker.general.areaInfos;

export const getAllAreaStatuses = (state) => {
  const byId = state.webWorker.general.areaStatuses;
  const keys = Object.keys(byId).sort();

  //return stableOrder(keys, byId);

  // sort level
  const sortAreas = [...stableOrder(keys, byId)];
  sortAreas.sort((a, b) => a.order - b.order);

  return sortAreas;
};

export const getAreaStatuses = (state) => {
  return state.webWorker.general.areaStatuses;
};

export const getAreaById = (state, id) => {
  return state.webWorker.general.areaStatuses[id];
};

export const getAreaStatusesById = (state, id) => {
  return getAllAreaStatuses(state).find((area) => area.id === id);
};

export const getIsAreaImagesChanged = (state) => {
  return state.webWorker.general.isAreaImagesChanged;
};

// #REVIEW - untested
export const getAreaByNameSlug = (state, slug) => {
  return getAllAreaStatuses(state).find((level) => toSlug(level.name) === slug);
};

// #REVIEW - untested
export const getAreaBoundsbyId = (state, id) => {
  return state.webWorker.general.areaStatuses[id].image.bounds;
};

// #REVIEW - untested
export const getAreaCentreById = (state, id) => {
  const bounds = getAreaBoundsbyId(state, id);
  const lat = (bounds[0].lat + bounds[1].lat) / 2;
  const lng = (bounds[0].lng + bounds[1].lng) / 2;
  return { lat, lng };
};

//
export const getNamedAreaInfos = (state) =>
  state.webWorker.general.namedAreaInfos;

export const getNamedAreaInfosById = (state, id) => {
  const geoJson = state.webWorker?.general?.namedAreaInfos;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.id === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getNamedAreaInfosByAreaId = (state, id) => {
  const geoJson = state.webWorker?.general?.namedAreaInfos;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.area === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getNamedAreaInfosByParentId = (state, id) => {
  const geoJson = state.webWorker?.general?.namedAreaInfos;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.parent === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

const getNamedAreasInNamedAreaGroupByAreaId = (state, id) => {
  const area = getAreaStatusesById(state, id);
  const namedAreaGroup = area?.namedAreaGroup || [];

  // get unique namedArea parents from namedAreaGroup
  //
  let namedAreaParentList = [];
  namedAreaGroup.forEach((group) => {
    group.subItems.forEach((level) => {
      level.subItems.forEach((button) => {
        namedAreaParentList.push(button.namedArea.id);
      });
    });
  });

  // uniqueAndSortedNamedAreaParentList
  return [...new Set(namedAreaParentList)].sort();
};

// used to get settings, priorities and other info on the named areas
// configured in the named area group
export const getNamedAreasInfoInNamedAreaGroupByAreaId = (state, id) => {
  const area = getAreaStatusesById(state, id);
  const namedAreaGroup = area?.namedAreaGroup || [];

  // get unique namedArea parents from namedAreaGroup
  //
  let namedAreaParentList = [];
  namedAreaGroup.forEach((group) => {
    group.subItems.forEach((level) => {
      level.subItems.forEach((button) => {
        console.log(
          "xxx getNamedAreasInfoInNamedAreaGroupByAreaId group",
          group
        );
        console.log(
          "xxx getNamedAreasInfoInNamedAreaGroupByAreaId level",
          level
        );
        console.log(
          "xxx getNamedAreasInfoInNamedAreaGroupByAreaId button",
          button
        );

        namedAreaParentList.push({
          groupLabel: group.label,
          levelLabel: level.label,
          buttonLabel: button.label,
          buttonCustomLabel: button?.settings?.title || "", // may not be defined. check for null
          parent: button.namedArea.id,
          priority: button.namedArea.button.priority, // priority comes from the button properties
          permission: button?.settings?.permission || 0, // may not be defined. check for null
          polygonId: button?.id || "",
          userRelay: 0, /// userRelay is not saved in the namedAreaGroup - this is added as a default for ....
        });
      });
    });
  });

  // uniqueAndSortedNamedAreaParentList
  return [...new Set(namedAreaParentList)].sort();
};

export const getButtonPrioritysInNamedAreaGroups = (state) => {
  const areas = getAllAreaStatuses(state);

  let buttonPrioritysInNamedAreaGroups = {};
  areas.forEach((area) => {
    const namedAreaGroup = area?.namedAreaGroup || [];

    // get unique namedArea parents from namedAreaGroup
    //
    let namedAreaButtonList = [];
    namedAreaGroup.forEach((group) => {
      group.subItems.forEach((level) => {
        level.subItems.forEach((button) => {
          namedAreaButtonList.push({
            id: button.namedArea.button.named_area,
            priority: button.namedArea.button.priority,
            active: button.namedArea.button.active,
            event: button.namedArea.button,
            group: group.id,
            level: level.id,
          });
        });
      });
    });

    buttonPrioritysInNamedAreaGroups[area.id] = namedAreaButtonList;
  });
  return buttonPrioritysInNamedAreaGroups;
};

export const getButtonPrioritysInNamedAreaGroupByAreaId = (state, id) => {
  const buttonPrioritysInNamedAreaGroups =
    getButtonPrioritysInNamedAreaGroups(state);
  return buttonPrioritysInNamedAreaGroups?.[id];
};

const assignDistinctColorToNamedAreasInGroupByAreaId = (state, id) => {
  // array of unique and sorted named areas in the areas
  const namedAreasInNamedAreaGroupByAreaId =
    getNamedAreasInNamedAreaGroupByAreaId(state, id);
  let distinctColorsNamedAreas = {};
  namedAreasInNamedAreaGroupByAreaId.forEach((namedArea, idx) => {
    // if idx exceeds # of available colors start at the beginning again
    distinctColorsNamedAreas[namedArea] =
      distinctColors.length > idx
        ? distinctColors[idx]
        : distinctColors[idx - distinctColors.length];
  });
  return distinctColorsNamedAreas;
};

export const getNamedAreaInfosByParentInArray = (
  state,
  array,
  distinctColorsNamedAreas
) => {
  const geoJson = state.webWorker?.general?.namedAreaInfos;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter((item) =>
      array.includes(item.properties.parent)
    );
    // set namedArea styling to be same per parent
    array.forEach((item, idx) => {
      // get a color based on idx

      filteredgeojson.features.forEach((feature) => {
        if (feature.properties.parent === item) {
          feature.properties.style =
            // if idx exceeds # of available colors start at the beginning again
            distinctColorsNamedAreas[item];
        }
      });
    });
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getNamedAreaInfosBySubTypeId = (state, id) => {
  const geoJson = state.webWorker?.general?.namedAreaInfos;
  let filteredgeojson = {};
  filteredgeojson.type = "FeatureCollection";
  if (geoJson?.features !== undefined) {
    filteredgeojson.features = geoJson.features.filter(
      (item) => item.properties.sub_type === id
    );
  } else {
    filteredgeojson.features = [];
  }
  return filteredgeojson;
};

export const getNamedAreaInfosByRegionPreviewByAreaId = (
  state,
  regions,
  id
) => {
  const namedAreaParents = regions.map((region) => region.spec);
  const distinctColorsNamedAreas =
    assignDistinctColorToNamedAreasInGroupByAreaId(state, id);
  return getNamedAreaInfosByParentInArray(
    state,
    namedAreaParents,
    distinctColorsNamedAreas
  );
};

// #NOTE - this combines regions into
// a single multipolygon. The resulting shape cuts out overlapping regions.
// Not what we want.
// Instead we need a union of shapes.
// getNamedAreaParentInfos_MultiPolygon_development
export const getNamedAreaParentInfos = (state) => {
  const namedAreas = getAllNamedAreaStatuses(state);
  const uniqueParents = namedAreas
    .map((item) => item.parent)
    .filter((value, index, self) => self.indexOf(value) === index);

  const geoJsonNamedAreaByParent = [];

  uniqueParents.forEach((uniqueParent, idx) => {
    const nameArray = uniqueParent.split(":");
    // if nameArray[1] is undefined then is level wide event
    let name;
    if (nameArray[1] === undefined) {
      name = nameArray[0];
    } else {
      name = nameArray[1];
    }

    const namedAreaInfosByParentId = getNamedAreaInfosByParentId(
      state,
      uniqueParent
    );
    let combinedCoords = [];
    let area = "";
    let type = "";
    let subType = "";
    let parent = "";

    namedAreaInfosByParentId.features.forEach((naInfo, idx) => {
      const {
        properties: {
          area: sameArea,
          type: sameType,
          sub_type: sameSubType,
          parent: sameParent,
        },
        geometry: { coordinates },
      } = naInfo;
      combinedCoords.push(coordinates);
      //
      area = sameArea; // area etc. is the same for each parents. Hack to get it out.
      type = sameType;
      subType = sameSubType;
      parent = sameParent;
    });

    // structure geoJSON object
    const geojsonFeature = {
      type: "Feature",
      properties: {
        id: uniqueParent,
        name: name,
        area: area,
        type: type,
        sub_type: subType,
        parent: parent,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: combinedCoords,
      },
    };

    if (type !== "LEVEL_WIDE" && type !== "ALL_AREAS") {
      geoJsonNamedAreaByParent.push(geojsonFeature);
    }
  });

  return {
    type: "FeatureCollection",
    features: geoJsonNamedAreaByParent,
  };
};

export const getAllNamedAreaStatuses = (state) => {
  const byId = state.webWorker.general.namedAreaStatuses;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getNamedAreaStatusesByAreaId = (state, area) => {
  return getAllNamedAreaStatuses(state).filter(
    (namedArea) => namedArea.area === area
  );
};

// returns a combined 'parent' namedArea
export const getNamedAreaParentStatusesByAreaId = (state, area) => {
  const namedAreasByAreaId = getNamedAreaStatusesByAreaId(state, area);
  //console.log("ppp namedAreasByAreaId", namedAreasByAreaId);
  const uniqueParents = namedAreasByAreaId
    .map((item) => item.parent)
    .filter((value, index, self) => self.indexOf(value) === index);

  // sort the locations so they list in order on the screen
  const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

  const sortedUniqueParents = [...uniqueParents].sort(sortAlphaNum);

  // console.log("eee uniqueParents", uniqueParents);
  // console.log("eee sortedUniqueParents", sortedUniqueParents);

  let parents = [];
  sortedUniqueParents.forEach(function (uniqueParent, idx) {
    const namedAreaParentInfo = namedAreasByAreaId.find(
      (item) => item.parent === uniqueParent
    );

    //console.log(`namedAreaParentInfo`, namedAreaParentInfo);

    const {
      area,
      active,
      button,
      type,
      sub_type,
      parent_name: parentName,
    } = namedAreaParentInfo;

    const id = uniqueParent;
    const nameArray = uniqueParent.split(":");

    // if nameArray[1] is undefined then is level wide event
    let name;
    if (parentName.length <= 0) {
      if (nameArray.length < 2) {
        // ALL_AREAS, LEVEL_WIDE named area
        name = `Level Wide (${nameArray[0]})`;
        // exclude ALL_AREAS, LEVEL_WIDE
        // filteredParents[idx] = { ....}
      } else {
        name = nameArray[1];
        // compose new data set
      }
    } else {
      name = parentName;
    }

    // massage button properties e.g.
    // icon:  "icon-earthquake0"
    // color "green"
    // ->
    // icon: { color:  "green", name:  "icon-earthquake0"}
    //
    // for (let but of button) {

    // }

    // create button groups
    let groups = new Map();

    for (let opt of button) {
      if (!groups.get(opt.group)) {
        groups.set(opt.group, [opt]);
      } else {
        groups.get(opt.group).push(opt);
      }
    }

    let groupsArray = [];

    for (let operationsValue of groups.values()) {
      groupsArray.push(operationsValue);
    }

    // Note:
    // buttons are the same for every named area under a parent group
    // populate the button array by getting the first to match a parent

    // compose new data set
    parents[idx] = {
      id: id,
      type: type,
      sub_type: sub_type,
      active: true, // active,
      namedArea: name,
      area: area,
      button: groupsArray,
    };
  });

  return parents;
};

export const getNamedAreaStatusesById = (state, id) => {
  return state.webWorker.general.namedAreaStatuses[id];
};

// const sendNamedAreaButtonUpdateByParent = (
//   namedAreaStatuses,
//   parentId,
//   buttons
// ) => {
//   const namedAreas = Object.values(namedAreaStatuses);
//   const namedAreasByParentId = namedAreas
//     .filter((namedArea) => namedArea.parent === parentId)
//     .sort((a, b) => (a.priority > b.priority ? 1 : -1));

//   if (false) {
//     console.log("sendNamedAreaButtonUpdateByParent namedAreas", namedAreas);
//     console.log("sendNamedAreaButtonUpdateByParent parentId", parentId);
//     console.log(
//       "sendNamedAreaButtonUpdateByParent namedAreasByParentId",
//       namedAreasByParentId
//     );
//     console.log("sendNamedAreaButtonUpdateByParent buttons", buttons);
//   }

//   // send out backwards because controllers overlay based on order received

//   let messagePayloads = [];

//   namedAreasByParentId.reverse().forEach((namedArea, index) => {
//     let namedAreaClone = JSON.parse(JSON.stringify(namedArea));
//     let newButtons = [];

//     //console.log("namedAreaClone", namedAreaClone);

//     // massage named area to prep data for sending
//     //
//     // remove from msg
//     delete namedAreaClone.status;
//     delete namedAreaClone.Firefly_List;
//     // change `coordinateUtm` to `shape`
//     let shape;
//     // NOTE - only supports a single polygon shape, not multi-, so get the first shape in coordinates array
//     shape = namedAreaClone.coordinatesUtm[0];
//     // check first point and last should be the same
//     if (shape[0] !== shape[shape.length - 1]) {
//       // or append first to end point to close the polygon ring
//       shape = [...shape, shape[0]];
//     }
//     namedAreaClone.shape = shape;
//     delete namedAreaClone.coordinatesUtm; // remove from msg
//     // change `style` to a string
//     namedAreaClone.style = JSON.stringify(namedAreaClone.style);
//     // update the new buttons with the original area id
//     buttons.forEach((button, idx) => {
//       newButtons[idx] = buttons[idx];
//       newButtons[idx].id = namedArea.id;
//     });
//     namedAreaClone.button = newButtons;

//     //console.log("namedAreaClone", namedAreaClone);

//     // prepare message token
//     const userSessionIp = getUserSessionIp();
//     const token = messageToken(userSessionIp);

//     let updatedNamedArea = {
//       ...namedAreaClone,
//       precanned: 0, // not used ATM
//       token: token,
//     };

//     //console.log("updatedNamedArea", updatedNamedArea);

//     const namedAreaPayload = {
//       topic: `named_area/${updatedNamedArea.id}/change`,
//       qos: 0,
//       message: updatedNamedArea,
//       retained: false,
//     };

//     //console.log("namedAreaPayload", namedAreaPayload);

//     messagePayloads.push(namedAreaPayload); //     channelBufferPublishMsg(eventPayload, "MQTT_PUBLISH");
//   });

//   return messagePayloads;
// };

// ****************************************************************
//
// Named area events

export const getAllNamedAreaEvents = (state) => {
  const byId = state.webWorker.general.namedAreaEvents;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getNamedAreaEventsById = (state, id) => {
  return state.webWorker.general.namedAreaEvents[id];
};

export const getNamedAreaEventsByAreaId = (state, area) => {
  return getAllNamedAreaEvents(state).filter((event) => event.area === area);
};

export const getNamedAreaEventsByParentId = (state, parentId) => {
  return getAllNamedAreaEvents(state).filter((event) =>
    event.id.includes(parentId)
  );
};

export const getNamedAreaEvents = (state) => {
  const namedAreaEvents = state.webWorker.general.namedAreaEvents;
  if (namedAreaEvents !== undefined && !_isEmpty(namedAreaEvents)) {
    return namedAreaEvents;
  } else {
    return {};
  }
};

// returns a combined 'parent' namedArea
export const getNamedAreaEventsParentsByAreaId = (state, area) => {
  const namedAreasEventsByAreaId = getNamedAreaEventsByAreaId(state, area);
  //console.log("ppp namedAreasEventsByAreaId", area, namedAreasEventsByAreaId);
  const uniqueParents = namedAreasEventsByAreaId
    .map((item) => item.parent)
    .filter((value, index, self) => self.indexOf(value) === index);

  //console.log("ppp uniqueParents", uniqueParents);
  let parents = [];
  uniqueParents.forEach(function (uniqueParent, idx) {
    const namedAreaEventParentInfo = namedAreasEventsByAreaId.find(
      (item) => item.parent === uniqueParent
    );

    const id = uniqueParent;
    const {
      area,
      priority,
      active_color,
      active_state,
      active,
      button_groups,
    } = namedAreaEventParentInfo;

    // compose new data set
    parents[id] = {
      id: id,
      area: area,
      priority: priority,
      active_color: toFireflyColor(active_color),
      active_state: active_state,
      active: active,
      button_groups: button_groups,
    };
  });

  return parents;
};

// event buttons
export const getNamedAreaEventButtonGroupState = (state) =>
  state.webWorker.general.namedAreaEventsButtonGroupState;

export const getNamedAreaEventButtonGroupStateByNamedAreaParentId = (
  state,
  parentId
) => {
  return state.webWorker.general.namedAreaEventsButtonGroupState[parentId];
};

export const getNamedAreaExtTriggerEventInfo = (state) => {
  // convert from [ {area, list}, ] to [ { eventInfo}]
  const areas = state.webWorker.general.namedAreaExtTriggerEventInfo;

  let eventInfo = [];
  areas.forEach((area) => {
    area.list &&
      area.list.forEach((item) => {
        // massage keys to naming more suitable to list page
        const {
          id,
          active,
          button_custom_label,
          button_label,
          group_label,
          level_label,
          operator,
          origin,
          parent,
          source,
          reason,
          secure_key,
          priority,
          disable,
          timestamp,
        } = item;

        eventInfo.push({
          area: area.area,
          id: id, //parent,
          groupLabel: group_label,
          levelLabel: level_label,
          buttonCustomLabel: button_custom_label,
          buttonLabel: button_label,
          parent,
          source,
          reason,
          operator,
          origin,
          secureKey: secure_key,
          active,
          priority,
          disable,
          timestamp,
        });
      });
  });

  return eventInfo;
};

export const getNamedAreaScheduledEventInfo = (state) => {
  // convert from [ {area, list}, ] to [ { eventInfo}]
  const areas = state.webWorker.general.namedAreaScheduledEventInfo;

  let eventInfo = [];
  areas.forEach((area) => {
    area.list &&
      area.list.forEach((item) => {
        // massage keys to naming more suitable to list page
        const {
          id,
          active,
          button_custom_label,
          button_label,
          group_label,
          level_label,
          operator,
          origin,
          parent,
          source,
          reason,
          secure_key,
          priority,
          disable,
          timestamp,
        } = item;

        eventInfo.push({
          area: area.area,
          id: id, //parent,
          groupLabel: group_label,
          levelLabel: level_label,
          buttonCustomLabel: button_custom_label,
          buttonLabel: button_label,
          parent,
          source,
          reason,
          operator,
          origin,
          secureKey: secure_key,
          active,
          priority,
          disable,
          timestamp,
        });
      });
  });

  return eventInfo;
};

export const getScheduledEventJobInfo = (state) => {
  const jobs = state.webWorker.general.scheduledEventJobInfo;

  console.log("xxx getScheduledEventJobInfo jobs", jobs);

  let eventJobInfo = [];
  jobs.forEach((job) => {
    const {
      active,
      area,
      cron,
      cron_active_duration: cronActiveDuration,
      disable,
      event_job_id: eventJobId,
      id: cronId,
      name,
      note,
      password,
      polygon_id: polygonId,
      timestamp,
      user_relay: userRelay,
      info,
      active_job: activeJob,
    } = job;

    eventJobInfo.push({
      active,
      activeJob,
      area,
      cron,
      cronActiveDuration,
      disable,
      eventJobId,
      cronId,
      name,
      note,
      password,
      polygonId,
      timestamp,
      userRelay,
      info,
    });
  });

  return eventJobInfo;
};

// *****************************************
// PUBLISH EVENT EVENTS
//
// 'sendNamedAreaEventParent' prepares event messages and prepares the payload for the MQTT message.
// The payload is concatenated to the 'publish' state of this Webworker reducer. i.e. state.webWorker.general.publish
//
// WebWorker (src/components/WebWorker/index.js) ComponentDidUpdate runs periodically (at poll rate),
// and checks if there are messages to publish in
// state.webWorker.general.publish (via getAllMqttMessages()).
//
// Messages can be published via MQTT (askWebWorker) or via API (saveNewNamedAreaEvent)
// based on the Config setting `isMqttNotApiPost`
//
//

const sendNamedAreaEventParent = ({
  namedAreaStatuses,
  operation,
  namedAreaEventsButtonGroupState,
  namedAreaEvents,
  active,
}) => {
  const {
    priority,
    // active_color,
    // active_state,
    named_area: parentId,
    relay_active,
  } = operation;

  const namedAreas = Object.values(namedAreaStatuses); // namedAreaStatuses

  const namedAreasByParentId = namedAreas
    .filter((namedArea) => namedArea.parent === parentId)
    .sort((a, b) => (a.priority > b.priority ? 1 : -1));

  const buttonGroupState = namedAreaEventsButtonGroupState[parentId];

  // #DEBUG
  const _DEBUG_MSGS = false;

  if (_DEBUG_MSGS) {
    console.log("sendNamedAreaEventParent namedAreas", namedAreas);
    console.log("sendNamedAreaEventParent parentId", parentId);
    console.log(
      "sendNamedAreaEventParent namedAreasByParentId",
      namedAreasByParentId
    );
    console.log("sendNamedAreaEventParent buttonGroupState", buttonGroupState);
    console.log("sendNamedAreaEventParent operation", operation);
    console.log(
      "sendNamedAreaEventParent namedAreaEventsButtonGroupState",
      namedAreaEventsButtonGroupState
    );
  }

  // send out backwards because controllers overlay based on order received

  let eventPayloads = [];

  namedAreasByParentId.reverse().forEach((namedArea, index) => {
    // prepare message token
    const userSessionIp = getUserSessionIp();
    const token = messageToken(userSessionIp);

    const active_button = namedArea.button.find(
      (button) => button.priority === priority
    );

    if (_DEBUG_MSGS) {
      console.log("sendNamedAreaEventParent active_button", active_button);
    }

    let eventMsg = {};

    // skip if
    // 1 - there is no button definition for the selected priority
    // 2 - the button is disabled in the named area setup for the button
    if (
      active_button !== undefined &&
      active_button.active // named area has been disabled
    ) {
      // 3 - the button is set to not apply a change to this area
      if (
        active_button.color === "no_change" // named area has no effect for this button
      ) {
        // in this case re-send the previous event with an updated 'buttonGroupState'

        // #DEBUG
        if (_DEBUG_MSGS) {
          console.log(
            "sendNamedAreaEventParent buttonActive Single namedAreaEvents[namedArea.id]",
            namedAreaEvents[namedArea.id]
          );
        }

        eventMsg = namedAreaEvents[namedArea.id];
        eventMsg.token = token;
        eventMsg.timestamp = microTime();
        eventMsg.button_groups = JSON.stringify(buttonGroupState);
        //
        // relay_event_active:1
        //eventMsg.relay_event_active
      } else {
        if (_DEBUG_MSGS) {
          console.log(
            "sendNamedAreaEventParent @eventMsg active_button",
            active_button
          );
        }

        //console.log("xxx userSessionIp", userSessionIp);

        eventMsg = {
          id: namedArea.id,
          priority: priority,
          active: active,
          active_color: toFireflyColor(active_button.active_color),
          active_state: active_button.active_state,
          on_time: active_button.on_time,
          off_time: active_button.off_time,
          train: active_button.train,
          button_groups: JSON.stringify(buttonGroupState),
          timestamp: microTime(),
          precanned: 0, // not used ATM
          relay_event_active: relay_active || 0,

          // Set client and operator for the event based on user session creds
          origin: "CLIENT",
          operator: userSessionIp || "session info unknown",
          token: token,
        };
      }

      if (_DEBUG_MSGS) {
        console.log(
          "sendNamedAreaEventParent @eventMsg eventMsg",
          JSON.stringify(eventMsg)
        );
      }

      const eventPayload = {
        topic: `named_area/${eventMsg.id}/event`,
        qos: 0,
        message: eventMsg,
        retained: true,
      };

      eventPayloads.push(eventPayload); //     channelBufferPublishMsg(eventPayload, "MQTT_PUBLISH");
    }
  });

  // #DEBUG
  if (_DEBUG_MSGS) {
    console.log("sendNamedAreaEventParent eventPayloads", eventPayloads);
  }

  return eventPayloads;
};

const sendNamedAreaEventSingle = (
  namedAreaStatuses,
  operation,
  namedAreaEventsButtonGroupState,
  namedAreaEvents,
  active
) => {
  // e.g.
  //     active: true
  // active_color: "red"
  // active_state: "on"
  // alt: "Level 1"
  // clickable: true
  // color: "red"
  // default: true
  // group: 0
  // hint: "Level 0"
  // icon: "icon-earthquake123"
  // id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626042755624"
  // marker: "RoundMarker"
  // named_area: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a"
  // off_time: 0
  // on_time: 0
  // priority: 3
  // state: "on"
  // title: "Seismic 1"
  // train: 0
  // type: "SEISMIC1_EVENT"

  const {
    priority,
    active: activeButton,
    color,
    active_color,
    active_state,
    state,
    on_time,
    off_time,
    train,
    named_area: parentId,
    id: namedAreaId, // i.e. event id
  } = operation;

  const namedAreas = Object.values(namedAreaStatuses); // namedAreaStatuses

  const namedAreasByParentId = namedAreas
    .filter((namedArea) => namedArea.parent === parentId)
    .sort((a, b) => (a.priority > b.priority ? 1 : -1));

  // normally when polygons are created the active button group is setup
  let buttonGroupState = namedAreaEventsButtonGroupState[parentId];
  // however if the event is active and there is no button group default to { 0: 1}.
  // This accommodates the case for forced light change where the named area and event are defined but there is no button set.
  if (active && buttonGroupState === undefined) {
    buttonGroupState = { 0: 1 }; // forced light change
  }

  if (false) {
    console.log("sendNamedAreaEventSingle operation", operation);

    //     active: true
    // active_color: "red"
    // active_state: "on"
    // alt: "Level 1"
    // clickable: true
    // color: "red"
    // default: true
    // group: 0
    // hint: "Level 0"
    // icon: "icon-earthquake123"
    // id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626042755624"
    // marker: "RoundMarker"
    // named_area: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a"
    // off_time: 0
    // on_time: 0
    // priority: 3
    // state: "on"
    // title: "Seismic 1"
    // train: 0
    // type: "SEISMIC1_EVENT"

    console.log("sendNamedAreaEventSingle namedAreas", namedAreas);

    //     0: {status: "mqtt", id: "DMLZ_Extraction:88175515-d033-4524-a7fb-a11913c1dc7c:1626156898246", type: "POLYGON", name: "1626156898246", parent: "DMLZ_Extraction:88175515-d033-4524-a7fb-a11913c1dc7c", …}
    // 1: {status: "mqtt", id: "DMLZ_Extraction_BASE", type: "LEVEL_WIDE", name: "LEVEL_WIDE", parent: "DMLZ_Extraction", …}
    // 2: {status: "mqtt", id: "ALL_AREAS", type: "ALL_AREAS", name: "ALL_AREAS", parent: "ALL_AREAS", …}
    // 3: {status: "mqtt", id: "ALL_AREAS_BASE", type: "LEVEL_WIDE", name: "LEVEL_WIDE", parent: "ALL_AREAS", …}
    // 4: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626042755624", type: "POLYGON", name: "1626042755624", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 5: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626155400253", type: "POLYGON", name: "1626155400253", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 6: {status: "mqtt", id: "DMLZ_Extraction:96ebaaaa-c85e-4b9b-bd39-0d4f0d36ad42:1626048851076", type: "POLYGON", name: "1626048851076", parent: "DMLZ_Extraction:96ebaaaa-c85e-4b9b-bd39-0d4f0d36ad42", …}
    // 7: {status: "mqtt", id: "DMLZ_Extraction:4c891f20-de82-45f4-b7ef-b3bb2ed1c5f9:1625717877453", type: "POLYGON", name: "1625717877453", parent: "DMLZ_Extraction:4c891f20-de82-45f4-b7ef-b3bb2ed1c5f9", …}
    // 8: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626218295018", type: "POLYGON", name: "1626218295018", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 9: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626218366181", type: "POLYGON", name: "1626218366181", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 10: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626218556024", type: "POLYGON", name: "1626218556024", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 11: {status: "mqtt", id: "DMLZ_Extraction:4c891f20-de82-45f4-b7ef-b3bb2ed1c5f9:1626218801483", type: "POLYGON", name: "1626218801483", parent: "DMLZ_Extraction:4c891f20-de82-45f4-b7ef-b3bb2ed1c5f9", …}
    // length: 12

    console.log("sendNamedAreaEventSingle parentId", parentId);

    //    parentId DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a

    console.log(
      "sendNamedAreaEventSingle namedAreasByParentId",
      namedAreasByParentId
    );

    //     0: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626042755624", type: "POLYGON", name: "1626042755624", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 1: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626155400253", type: "POLYGON", name: "1626155400253", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 2: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626218295018", type: "POLYGON", name: "1626218295018", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 3: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626218366181", type: "POLYGON", name: "1626218366181", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // 4: {status: "mqtt", id: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a:1626218556024", type: "POLYGON", name: "1626218556024", parent: "DMLZ_Extraction:411b517a-a499-4ef9-a71b-30d857fa5e0a", …}
    // length: 5

    console.log("sendNamedAreaEventSingle buttonGroupState", buttonGroupState);

    // {0: 3} // denotes button 2 active on 1st group (typically only one group)
  }

  // send out backwards because controllers overlay based on order received

  let eventPayloads = [];

  //namedAreasByParentId.reverse().forEach((namedArea, index) => {
  // const active_button = namedArea.button.find(
  //   (button) => button.priority === priority
  // );

  const active_button = {
    active: activeButton,
    color,
    active_color,
    active_state,
    state,
    on_time,
    off_time,
    train,
  };

  //console.log("sendNamedAreaEventSingle active_button priority", priority);

  // prepare message token
  const userSessionIp = getUserSessionIp();
  const token = messageToken(userSessionIp);

  //console.log("sendNamedAreaEventSingle token", token);

  let eventMsg = {};

  // skip if
  // 1 - the button is disabled in the named area setup for the button
  if (
    active_button !== undefined &&
    active_button.active // named area has been disabled
  ) {
    // 2 - the button is set to not apply a change to this area
    if (
      active_button.color === "no_change" // named area has no effect for this button
      // in this case, for a single event, don't do anything
    ) {
      // #DEBUG
      // console.log(
      //   "buttonActive Single namedAreaEvents[namedArea.id]",
      //   namedAreaEvents[namedArea.id]
      // );

      eventMsg = namedAreaEvents[namedAreaId]; //namedArea.id
      eventMsg.token = token;
      eventMsg.timestamp = microTime();
      eventMsg.button_groups = JSON.stringify(buttonGroupState);
    } else {
      eventMsg = {
        id: namedAreaId, //namedArea.id,
        priority: priority,
        active: active,
        active_color: toFireflyColor(active_button.active_color),
        active_state: active_button.active_state,
        on_time: active_button.on_time,
        off_time: active_button.off_time,
        train: active_button.train,
        button_groups: JSON.stringify(buttonGroupState),
        timestamp: microTime(),
        precanned: 0, // not used ATM
        token: token,
      };
    }

    const eventPayload = {
      topic: `named_area/${eventMsg.id}/event`,
      qos: 0,
      message: eventMsg,
      retained: true,
    };

    eventPayloads.push(eventPayload); //     channelBufferPublishMsg(eventPayload, "MQTT_PUBLISH");
  }
  //});

  console.log(`xxxx sendNamedAreaEventSingle eventPayloads`, eventPayloads);

  return eventPayloads;
};

const sendNamedAreaEventAllAreas = (
  namedAreaStatuses,
  operation,
  namedAreaEventsButtonGroupState,
  active
) => {
  // console.log(
  //   "sendNamedAreaEventWholeMine namedAreaStatuses",
  //   namedAreaStatuses
  // );

  //console.log("sendNamedAreaEventWholeMine operation", operation);

  // console.log(
  //   "sendNamedAreaEventWholeMine namedAreaStatuses",
  //   namedAreaEventsButtonGroupState
  // );
  // console.log("sendNamedAreaEventWholeMine active", active);

  const {
    priority,
    active_color,
    active_state,
    named_area: id,
    state,
    on_time,
    off_time,
    train,
  } = operation;

  // prepare message token
  const userSessionIp = getUserSessionIp();
  const token = messageToken(userSessionIp);
  const buttonGroupState = namedAreaEventsButtonGroupState[id];

  // Send a named area event equivalent to ALL_AREAS to track the event
  const namedAreaEventAllAreasMsg = {
    id: id,
    priority: priority,
    active: active,
    active_color: toFireflyColor(active_color),
    active_state: active_state,
    on_time: on_time,
    off_time: off_time,
    train: train,
    button_groups: JSON.stringify(buttonGroupState),
    timestamp: microTime(),
    precanned: 0, // not used ATM

    // Set client and operator for the event based on user session creds
    origin: "CLIENT",
    operator: userSessionIp || "session info unknown",

    token: token,
  };

  const namedAreaEventPayload = {
    topic: `emergency/${namedAreaEventAllAreasMsg.id}/event`,
    qos: 0,
    message: namedAreaEventAllAreasMsg,
    retained: true,
  };

  return [namedAreaEventPayload];
};

export const getWaitEventTimeout = (state) => state.webWorker.waitEventTimeout;

// SERVER MESSAGES and DEVICE TIMESTAMPS *************************************

//
export const getDeviceTimestamps = (state) =>
  state.webWorker.general.deviceTimestamps;
export const getTimedOutDevices = (state) =>
  state.webWorker.general.timedOutDevices;
export const getServerTimestamp = (state) =>
  state.webWorker.general.serverTimestamp;
export const getSystemProcessMsg = (state) =>
  state.webWorker.general.systemProcessMsg;

// ALL DATA *************************
//
// Used to update the worker with fetch data
//
export const getAllData = (state) => {
  // const toObject = (arr, key) =>
  //   arr?.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

  // const areaInfos =
  //   toObject(state.webWorker?.general?.areaInfos?.features, "id") || {};

  // const areaStatuses = state.webWorker?.general?.areaStatuses || {};

  // // remove functions, like transforms, before sending data
  // let rawAreaInfos = {};
  // let rawAreaStatuses = {};

  // Object.values(areaInfos).forEach((area) => {
  //   const {
  //     id,
  //     geometry,
  //     properties: {
  //       ceiling,
  //       coordinatesUtm,
  //       default_color,
  //       default_state,
  //       floor,
  //       image_filename,
  //       image_info,
  //       name,
  //       namedAreaGroup,
  //       ref_coord,
  //       slug,
  //       status = "api",
  //     },
  //   } = area;

  //   const properties = {
  //     ceiling,
  //     coordinatesUtm,
  //     default_color,
  //     default_state,
  //     floor,
  //     image_filename,
  //     image_info,
  //     name,
  //     namedAreaGroup,
  //     ref_coord,
  //     slug,
  //     status,
  //   };

  //   rawAreaInfos[id] = {
  //     geometry,
  //     properties: properties,
  //   };
  //   rawAreaStatuses[id] = properties;
  // });

  // const controllerCoordinates =
  //   toObject(state.webWorker?.general?.controllerCoordinates?.features, "id") ||
  //   {};
  // const controllerStatuses = state.webWorker?.general?.controllerStatuses || {};
  // const fireflyCoordinates =
  //   toObject(state.webWorker?.general?.fireflyCoordinates?.features, "id") ||
  //   {};
  // const fireflyStatuses = state.webWorker?.general?.fireflyStatuses || {};

  // const namedAreaInfos = state.webWorker?.general?.namedAreaInfos || {};
  // const namedAreaStatuses = state.webWorker?.general?.namedAreaStatuses || {};
  // const namedAreaEvents = state.webWorker?.general?.namedAreaEvents || {};

  return {
    // areaStatuses: rawAreaStatuses,
    // areaInfos: rawAreaInfos,
    // controllerCoordinates,
    // controllerStatuses,
    // fireflyCoordinates,
    // fireflyStatuses,
    // namedAreaInfos,
    // namedAreaStatuses,
    // namedAreaEvents,
    areaStatuses: state.webWorker?.general?.areaStatuses,
    areaInfos: state.webWorker?.general?.areaInfos,
    controllerCoordinates: state.webWorker?.general?.controllerCoordinates,
    controllerStatuses: state.webWorker?.general?.controllerStatuses,
    fireflyCoordinates: state.webWorker?.general?.fireflyCoordinates,
    fireflyStatuses: state.webWorker?.general?.fireflyStatuses,
    namedAreaInfos: state.webWorker?.general?.namedAreaInfos,
    namedAreaStatuses: state.webWorker?.general?.namedAreaStatuses,
    namedAreaEvents: state.webWorker?.general?.namedAreaEvents,
  };
};

export const getDataFetched = (state) => state.webWorker?.general?.dataFetched;

//  ACKS *************************

//
export const getAcks = (state) => state.webWorker.ack;

export const getAckByToken = (state, token) => {
  state.webWorker.ack.filter((ack) => ack.token === token);
};

// #NOTE
// see also - src/utils/confirmTokenRxWithRetryAndTimeout.js
// the difference is this passes in all acks available in this reducer
//

const isAckReceivedByTokenAcks = (token, acks) => {
  return new Promise((resolve, reject) => {
    //   console.log("acks isAckReceived token", token);
    //   console.log("acks isAckReceived acks", JSON.stringify(acks));

    // #TODO - have to get updated acks from state HERE!
    // can't pass the acks through as it'll never be updated

    const indexInAcks = acks
      .map(function (a) {
        return a.token;
      })
      .indexOf(token);

    console.log("acks isAckReceived indexInAcks", indexInAcks, token, acks);
    if (indexInAcks > -1) {
      // found ack
      this.props.removeAcks(token); // delete ack
      resolve(indexInAcks);
    } else {
      reject(indexInAcks);
      // reject(new Error());
      //throw new Error("Whoops!");
    }
  });
};

// ******************************************************************

//
export const getMqttReset = (state) => state.webWorker.general.reset;

//
export const getMqttDataLoading = (state) =>
  state.webWorker.general.dataLoading;

export const getMqttDataLoadingResponseRequestMessageQueue = (state) =>
  state.webWorker.general.dataloadingResponseRequestMessageQueue;

//
export const getAllMqttMessages = (state) => state.webWorker.general.publish; //,publish  // #REVIEW - change to "getMqttPublishMessages"

//
export const getNamedAreaDeleteSelections = (state) =>
  state.webWorker.mapState.deleteSelected;

export const getNamedAreaHideSelections = (state) =>
  state.webWorker.mapState.hideSelected;

export const getIsDirty = (state) => state.webWorker.mapState.isDirty;

// subset (reduced) of properties for each named area
// used to directly compare named areas objects excluding geometry etc.
export const GetMapNamedAreasReducedProperties = (state) => {
  const namedAreas = state.webWorker?.general?.namedAreaInfos?.features;

  let reducedProperties = [];

  if (namedAreas !== undefined && !_isEmpty(namedAreas)) {
    namedAreas.forEach(({ properties }, index, arr) => {
      const {
        id,
        name,
        parent,
        origin,
        area,
        priority,
        button,
        coordinatesUtm,
      } = properties;

      const reducedProperty = {
        id: id,
        name: name,
        parent: parent,
        origin: origin,
        area: area,
        priority: priority,
        button: button,
        coordinatesUtm: coordinatesUtm,
      };

      reducedProperties.push(reducedProperty);
    });
  }

  return reducedProperties;
};

export default combineReducers({
  general: reduceGeneral,
  //publish: reducePublish,
  mapState: reduceMapState,
  ack: reduceAck,
});
