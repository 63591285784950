import React, { Component } from "react";
import { connect } from "react-redux";
import MapMarker from "components/ColorableMapMarker";
import selector from "admin/firefly/form-value-selector";
import getSemanticColor from "colorpalette";

const SEMANTIC_BLACK = getSemanticColor("black");

class FireflyMoveableMarker extends Component {
  onDragEnded = ({ target }) => {
    const ll = target.getLatLng();
    const { change, name } = this.props;

    // console.log("name.lat, ll.lat", `${name}.lat`, ll.lat);
    // console.log("name.lng, ll.lng", `${name}.lng`, ll.lng);

    change(`${name}.lat`, ll.lat);
    change(`${name}.lng`, ll.lng);
  };

  render() {
    const { position, children } = this.props;

    // if position lat/long is 0,0 then not commissioned.
    // if not commissioned make 'new' color i.e. yellow
    const markerColor =
      position?.lat === 0 || position?.lng === 0 ? "yellow" : "blue";

    const outline = SEMANTIC_BLACK;
    const fillOpacity = 0.7;
    const size = 18.0;

    return (
      <MapMarker
        lat={position?.lat || 0}
        lng={position?.lng || 0}
        draggable
        color={outline}
        fillColor={markerColor}
        circleColor={markerColor}
        size={size}
        circleText={"F"}
        onDragend={this.onDragEnded}
        fillOpacity={fillOpacity}
      >
        {children}
      </MapMarker>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { position: selector(state, props.name) };
};

FireflyMoveableMarker = connect(mapStateToProps, null)(FireflyMoveableMarker);

export default FireflyMoveableMarker;
