import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import {
  SubmissionError,
  //
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
  getFormMeta,
  getFormAsyncErrors,
  getFormSyncWarnings,
  getFormSubmitErrors,
  getFormError,
  getFormNames,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
  stopSubmit,
  startSubmit,
} from "redux-form";

import { Container, Dimmer, Loader, Message } from "semantic-ui-react";
import { min } from "date-fns";

import _isEmpty from "lodash/isEmpty";

import { getAreaById, getAllAreaStatuses } from "components/WebWorker/reducer";

//import CreateMineLevelForm from "./MineLevelCreateForm";
import EditMineLevelForm from "./MineLevelEditForm";

import SubmissionModal from "admin/SubmissionModal";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import NavigationPromptModal from "admin/NavigationPromptModal";

import { saveNewMineLevel, fetchMineLevels } from "MineLevels/actions";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { fetchFolderFilesListByFolder } from "components/Settings/actions";

import { messageToken } from "utils/messageToken";
import { mqttPublish } from "components/WebWorker/actions";

class EditMineLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }

  componentDidMount() {
    const { mineLevel, goto } = this.props;

    // If no areas data is loaded redirect to area list
    // this is typically when the the page is F5 reloaded.
    // Can't operate without area data.
    //
    const checkDataOk = _isEmpty(mineLevel);
    if (checkDataOk) {
      goto("/admin/area");
    }

    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    //this.props.fetchFolderFilesListByFolder("areas");
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    //console.log(`submitMyForm values`, values);

    const {
      namedAreaGroup,
      saveNewMineLevel,
      stopSubmit,
      startSubmit,
      fetchMineLevels,
      fetchFolderFilesListByFolder,
    } = this.props;

    // restore namedAreaGroup to values
    // this is done outside of the form because namedAreaGroup is maintained elsewhere - see `src/admin/named-area-group/NamedAreaGroup.js`
    const newValues = {
      ...values,
      namedAreaGroup: JSON.stringify(namedAreaGroup),
    };

    // #NOTE - submit is managed manually in this process.
    // Start and Stop submit is used.

    return new Promise((resolve, reject) => {
      startSubmit();
      saveNewMineLevel({ values: newValues, resolve, reject });
    })
      .then((result) => {
        console.log(`saveNewMineLevel onSubmit SUCESSFULL! `, result);
        stopSubmit({});
        this.setState(
          {
            submitted: true,
          },
          () => {
            console.log("CLEAR ALL SYSTEM EVENTS - system/event/clear");
            // after namedAreaGroup has changed, clear the existing events.
            //
            // #WIP
            // - this needs to change to clear only defined AREAS
            // - change to API which can be resolved
            // -
            this.props.mqttPublish({
              topic: `system/event/clear`,
              qos: 0,
              message: {
                clear_events: true,
                token: messageToken(),
              },
              retained: false,
            });

            this.onResetError();
            // After saving the area return to control screen.
            // #NOTE - aside from a more logical UX, this has the additional benefit that
            // users return to area edit via the MineLevelList which updated the state,
            // especially the `folderFilesList` which takes time to update/return.
            //
            fetchFolderFilesListByFolder();
            fetchMineLevels();

            this.props.goto("/admin/area");
          }
        );
      })
      .catch((error) => {
        console.log(`onSubmit failed `, error);
        this.onSetError(`${error}`);
        stopSubmit({});
      });
  };

  render() {
    const {
      id,
      namedAreaGroup,
      saveNewMineLevel,
      updateMineLevel,
      error,
      initialValues,
      submitting,
      strings,
    } = this.props;

    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    if (error) {
      return (
        <>
          <DataLoadingMessage id={id} strings={strings} />
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    return (
      <>
        <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          errorMessage={errorMessage}
        />
        <Dimmer active={submitting} page>
          <Loader>Saving changes</Loader>
        </Dimmer>
        <NavigationPromptModal
          formName={"editNewMineLevelForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        />
        <EditMineLevelForm
          id={id}
          // #NOTE -
          // By default redux form accepts the prop `initialValues` as the initial values.
          // This page/form creates and sets initialValues from `the initial settings`,
          // in this case "theInitialValues"
          theInitialValues={initialValues}
          // onSubmit={(values) => {
          //   this.submitMyForm(values);
          //   console.log(`onSubmit out`);
          // }}

          onSubmit={(values) => {
            this.submitMyForm(values);
          }}
          // #NOTE - onSubmitSuccess is not defined.
          // This is managed through submitMyForm.

          onSubmitFail={(errors, dispatch, submitError, props) => {
            console.log(`onSubmitFail errors`, errors);
            //console.log(`onSubmitFail submitError`, submitError);
            //console.log(`onSubmitFail props`, props);
          }}
          onCancel={() => {
            this.props.goto("/admin/area");
          }}
          strings={strings}
        />
      </>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    saveNewMineLevel: (data) => dispatch(saveNewMineLevel(data)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    stopSubmit: (errors) => {
      dispatch(stopSubmit("editMineLevelForm", errors));
    },
    startSubmit: () => {
      dispatch(startSubmit("editMineLevelForm"));
    },
    fetchMineLevels: () => {
      dispatch(fetchMineLevels());
    },
    fetchFolderFilesListByFolder: () => {
      dispatch(fetchFolderFilesListByFolder());
    },
    mqttPublish: (msg) => dispatch(mqttPublish(msg)),
  };
};

//export default connect(null, { updateMineLevel })(EditMineLevel);

function mapStateToProps(state, props) {
  const match = props.match;
  const id = match.params.id;

  const mineLevel = getAreaById(state, id); // getMineLevelById(state, id);

  //console.log("xxx mineLevel", mineLevel);

  // set default case if mineLevel is undefined.
  // This can happen when returning from a deleting mine level action
  let imageInfo = {
    width: "",
    height: "",
    backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
  };
  let image_filename = "";
  let ref_coord = {};
  let defaultColor = "green"; // #REVIEW #TODO - move default setups to get from server/application state
  let defaultState = "on";
  let namedAreaGroup = {};
  let order = 0;
  let active = true;

  // Note - forcing default if objects undefined is repetitive.
  // remove when have `settings `

  //console.log(`mineLevel imageInfo`, mineLevel);

  if (mineLevel !== undefined) {
    imageInfo = mineLevel.image_info || imageInfo;
    image_filename = mineLevel.image_filename || image_filename;
    ref_coord = mineLevel.ref_coord || ref_coord;
    defaultColor = mineLevel.default_color || defaultColor;
    defaultState = mineLevel.default_state || defaultState;
    namedAreaGroup = mineLevel.namedAreaGroup || namedAreaGroup;
    order = mineLevel.order || order;
    active = mineLevel.active;
  }

  const initialValues = {
    id,
    imageInfo,
    image_filename,
    ref_coord,
    defaultColor,
    defaultState,
    order,
    active,
  };

  return {
    id,
    error: mineLevel === undefined ? "Area not found" : null,
    initialValues: initialValues, // props passed to redux-form loads these as (predictably), initial values
    namedAreaGroup,
    //
    mineLevel, // only used to check if content loaded

    //
    //values: getFormValues("editMineLevelForm")(state),
    //initialValues: getFormInitialValues("editMineLevelForm")(state),
    //formSyncErrors: getFormSyncErrors("editMineLevelForm")(state),
    //fields: getFormMeta("editMineLevelForm")(state),
    //formAsyncErrors: getFormAsyncErrors("editMineLevelForm")(state),
    //syncWarnings: getFormSyncWarnings("editMineLevelForm")(state),
    //submitErrors: getFormSubmitErrors("editMineLevelForm")(state),
    //formError: getFormError("editMineLevelForm")(state),
    //names: getFormNames()(state),
    //dirty: isDirty("editMineLevelForm")(state),
    //pristine: isPristine("editMineLevelForm")(state),
    //valid: isValid("editMineLevelForm")(state),
    //invalid: isInvalid("editMineLevelForm")(state),
    submitting: isSubmitting("editMineLevelForm")(state),
    //submitSucceeded: hasSubmitSucceeded("editMineLevelForm")(state),
    //submitFailed: hasSubmitFailed("editMineLevelForm")(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMineLevel);
