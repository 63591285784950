import React from "react";
import { Menu, Container } from "semantic-ui-react";
import hash from "object-hash";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout, reset } from "auth/actions";
import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
} from "auth/reducer";

import {
  isDevelopmentVersion as _isDevelopmentVersion,
  isExtendedNavMenu,
  isDemoMode as _isDemoMode,
  appResponsive,
  isDebugScreensize,
  isBoomgateCrane,
  isTagTracking as _isTagTracking,
  isLightingControl as _isLightingControl,
  configAppTitle as _configAppTitle,
} from "components/ConfigJs";

import Logo from "components/Logo";
import MineLevelMenus from "containers/MineLevelMenus";
import AdminMenu from "components/Menu/AdminMenuItem";
import TagTrackingMenu from "components/Menu/TagTrackingMenuItem";
import OperatorMenu from "components/Menu/OperatorMenuItem"; // i.e. Status Menu
import LightingControlMenu from "components/Menu/LightingControlMenuItem";
import UserMenu from "components/User/UserMenuItem";
import HelpMenu from "components/Help/HelpMenuItem";

import AlarmButton from "components/AlarmButton";
import DataCollectButton from "components/Tools/DataCollectButton";

import UserMenuLogout from "components/User/UserMenuLogout";

import TestMenu from "components/TestMenu";
//import ToolsMenu from "components/Tools/ToolsMenuItem";

import { LogIt } from "components/Logger/actions";

import {
  addErrorFlashMessage,
  clearFlashMessages,
} from "FlashMessages/actions";

import NamedAreaDisplaySettings from "pages/ControlRoomPage/NamedAreaDisplaySettings";

import { isUserGuest } from "auth/reducer";
import { getUserSessionIp } from "utils/messageToken";

//import Clock from "components/Clock";

import useWindowDimensions from "utils/useWindowDimensions";
import { useMediaQuery } from "react-responsive";

const Header = ({
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
  logout,
  reset,
  userSessionIp,
  //
  subHeader,
  showMinimal,
  //alarmButtonSize,
  //winDims,
  //language,
  //componentKey,
  strings, // localisation
  userSettings,
}) => {
  //console.log("xxx header - --------------- ");
  //console.log("xxx header - language ", language);
  //console.log("xxx header - strings getLanguage ", strings?.getLanguage?.());
  //console.log("xxx header - strings ", JSON.stringify(strings));
  // console.log(
  //   "xxx header - FireFly Lighting System ",
  //   strings?.["FireFly Lighting System"]
  // );
  //console.log("xxx header - userSettings ", JSON.stringify(userSettings));

  const isTagTracking =
    userSettings?.application?.includes("tagTracking") || _isTagTracking();
  const isLightingControl =
    userSettings?.application?.includes("smartLighting") ||
    _isLightingControl();
  const configAppTitle = _configAppTitle();

  const isDevelopmentVersion =
    userSettings?.feature?.includes("showDevelopmentVersion") ||
    _isDevelopmentVersion();
  const isDemoMode =
    userSettings?.feature?.includes("showDemoMode") || _isDemoMode();

  //console.log("xxx isDemoMode", isDemoMode);

  const allowGuest = isUserGuest;
  const allowOperator = isUserSuper || isUserAdmin || isUserUser;
  const allowAdmin = isUserSuper || isUserAdmin;
  const allowSuper = isUserSuper;

  //const { height, width } = winDims || {};
  const { height, width } = useWindowDimensions() || {};
  const { mobile, tablet, laptop, desktop } = appResponsive();

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${desktop}px)`,
  });

  const isTabletOrMobileDevice = useMediaQuery({
    query: `(max-width: ${mobile}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(min-width: ${mobile}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${tablet}px)`,
  });

  const isLaptop = useMediaQuery({
    query: `(min-width: ${laptop}px)`,
  });

  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop}px)`,
  });

  const isNoSpaceForButtons = useMediaQuery({
    query: `(min-width: 830px)`,
  });

  // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
  let alarmButtonSize = "large";
  let hideAlarmButtons = false;
  if (isMobile) alarmButtonSize = "mini";
  if (isTablet) alarmButtonSize = "mini";
  if (isLaptop) alarmButtonSize = "small";
  if (isDesktop) alarmButtonSize = "large";
  if (isNoSpaceForButtons) hideAlarmButtons = true;

  if (isDebugScreensize()) {
    console.log(
      `isDebugScreensize: isMobile,isTablet,isLaptop,isDesktop, `,
      isMobile,
      isTablet,
      isLaptop,
      isDesktop
    );
  }

  let userLinks;
  let userLinksMinimal;

  // APP_TERMINOLOGY
  let strTitle =
    strings?.[`${configAppTitle}`] || strings?.["FireFly Lighting System"];

  if (isDemoMode) {
    strTitle = `${strTitle} [DEMO]`;
  }

  const navButtons = (
    <div
      //className={"faultButtons"}
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {
        // #NOTE - Main nav config option has been superceded in favour of per User settings in profile
        // Left here JIC things change again.
        false && (
          <NamedAreaDisplaySettings size={alarmButtonSize} strings={strings} />
        )
      }
      {allowOperator && hideAlarmButtons && (
        <>
          <AlarmButton
            faultType="firefly"
            size={alarmButtonSize}
            faultAction="link"
            strings={strings}
          />
          <AlarmButton
            faultType="battery"
            size={alarmButtonSize}
            faultAction="link"
            strings={strings}
          />
          <AlarmButton
            faultType="network"
            size={alarmButtonSize}
            faultAction="link"
            strings={strings}
          />
          <AlarmButton
            faultType="controller"
            size={alarmButtonSize}
            faultAction="link"
            strings={strings}
          />
          <AlarmButton
            faultType="statusChecks"
            size={alarmButtonSize}
            faultAction="link"
            strings={strings}
          />
        </>
      )}
      {/* <AlarmButton faultType="external" size={"large"} faultAction="link" /> */}
      {allowAdmin && (
        <DataCollectButton size={alarmButtonSize} strings={strings} />
      )}
    </div>
  );

  if (isAuthenticated) {
    userLinksMinimal = (
      <div style={{ display: "inherit", width: "100%" }}>
        <UserMenuLogout />
      </div>
    );

    userLinks = (
      <div style={{ display: "inherit", width: "100%" }}>
        {/* <div style={{ display: "inherit", width: "100%" }}> */}
        {/* <Clock /> */}
        {false && !subHeader && allowSuper && (
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            SESSION: {JSON.stringify(userSessionIp)} [w {width}, h: {height}]
          </div>
        )}

        {!subHeader && navButtons}

        {false && !subHeader && (
          <Menu.Item as={Link} header to="/" style={{ paddingLeft: "2em" }}>
            {strTitle}
          </Menu.Item>
        )}
        {!subHeader && allowOperator && (
          <Menu.Item>
            <OperatorMenu strings={strings} userSettings={userSettings} />
          </Menu.Item>
        )}
        {isTagTracking && allowOperator && (
          <Menu.Item>
            <TagTrackingMenu strings={strings} userSettings={userSettings} />
          </Menu.Item>
        )}
        {!subHeader && isLightingControl && (
          <Menu.Item>
            <LightingControlMenu
              strings={strings}
              userSettings={userSettings}
            />
          </Menu.Item>
        )}

        {!subHeader && allowAdmin && (
          <Menu.Item>
            <AdminMenu strings={strings} userSettings={userSettings} />
          </Menu.Item>
        )}
        {!subHeader && allowAdmin && (
          <Menu.Item as={Link} to="/user/admin">
            {strings?.["Users"]}
          </Menu.Item>
        )}
        {/* {allowSuper && (
          <Menu.Item>
            <ToolsMenu />
          </Menu.Item>
        )} */}
        {false && allowSuper && (
          <Menu.Item>
            <TestMenu userSettings={userSettings} />
          </Menu.Item>
        )}
        {!subHeader && (
          <>
            {false && (
              <Menu.Item>
                <HelpMenu strings={strings} userSettings={userSettings} />
              </Menu.Item>
            )}
            <Menu.Item>
              <UserMenu strings={strings} userSettings={userSettings} />
            </Menu.Item>
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={
        isDevelopmentVersion ? "isDevelopmentVersion" : "isProductionVersion"
      }
    >
      <Container
        className={
          isDevelopmentVersion ? "isDevelopmentVersion" : "isProductionVersion"
        }
        style={{
          display: "flex",
          // TOP NAV BAR HEIGHT
          height: subHeader ? "50px" : "100px",
          justifyContent: "space-between",
        }}
      >
        <img
          className={"logoInvert"}
          alt=""
          style={{ width: "auto", padding: "10px", paddingLeft: "0px" }}
          src={process.env.PUBLIC_URL + "/logo.png"}
        />
        <div
          className={
            isDevelopmentVersion
              ? "isDevelopmentVersion"
              : "isProductionVersion"
          }
        >
          <Menu
            inverted
            className={
              isDevelopmentVersion
                ? "isDevelopmentVersion"
                : "isProductionVersion"
            }
            style={{
              height: "100%",
            }}
          >
            <Menu.Item as={Link} header to="/">
              {/* <Logo inverted /> */}
              {strTitle}
            </Menu.Item>
          </Menu>
        </div>
        <div
          style={{
            backgroundColor: "#1b1c1d",
            marginLeft: "auto",
          }}
        >
          <Menu
            inverted
            className={isDevelopmentVersion ? "isDevelopmentVersion" : ""}
            style={{
              height: "100%",
            }}
          >
            {!showMinimal && userLinks}
            {showMinimal && userLinksMinimal}
          </Menu>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    isAuthenticated: isAuthenticated(state),
    isUserSuper: isUserSuper(state),
    isUserAdmin: isUserAdmin(state),
    isUserUser: isUserUser(state),
    isUserGuest: isUserGuest(state),
    userSessionIp: getUserSessionIp(),
  };
}

export default connect(mapStateToProps, {
  logout,
  LogIt,
  addErrorFlashMessage,
})(Header);
