// see - src/admin/named-area-group/NamedAreaGroupSettingsModal.js

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Form,
  Segment,
  Modal,
  Header,
  Icon,
  Button,
  Grid,
} from "semantic-ui-react";

import isEqual from "lodash/isEqual";

import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class TagTrackingZonesMarkerPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  componentDidMount() {
    const { open, isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;

    if (open) {
      this.handleOpen();
    }

    if (isPokeTheWorker) {
      TurnOnOffPokeTheWorker(false);
    }
  }

  componentWillUnmount() {
    const { isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;
    if (!isPokeTheWorker) {
      TurnOnOffPokeTheWorker(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.resetModal();

    // reset the form
    //this.setState({ value: "" }, () => this.props.reset());
  };

  render() {
    const { id, label } = this.props;

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header
          icon="cog"
          content={
            <Link to={`/admin/named-area/tagzone/edit/${id}`}>
              {`Tag Zone ${label}`}
            </Link>
          }
        />
        <Modal.Content>
          <Segment>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    ...more information to come. e.g. notes on the zone.
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel}>
            <Icon name="close" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    initialValues: { id, label },
  } = props;

  return {
    isPokeTheWorker: isOnOffPokeTheWorker(state),
    id,
    label,
  };
}

export default connect(mapStateToProps, { TurnOnOffPokeTheWorker })(
  TagTrackingZonesMarkerPopupModal
);
