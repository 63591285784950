import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Segment } from "semantic-ui-react";

//import NamedAreaOperationsList from "./NamedAreaOperationsList";

import NamedAreaDisplaySettings from "pages/ControlRoomPage/NamedAreaDisplaySettings";

import Map from "components/Map/MineLevelMapLeaflet";
import MineLevelPrecannedOperationsList from "pages/ControlRoomPage/MineLevelPrecannedOperationsList";
import MineLevelCustomSOPList from "pages/ControlRoomPage/MineLevelCustomSOPList";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

// railway_application
import { isBoomgateCrane } from "components/ConfigJs";

import { isUserGuest } from "auth/reducer";

import { getAllAreaStatuses } from "components/WebWorker/reducer";

class MineLevelOpsControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 6,
    };
  }
  render() {
    const {
      level,
      displaySettings,
      isUserGuest,
      areaIds,
      strings,
      userSettings,
    } = this.props;

    // railway_application
    const boomgateCrane = isBoomgateCrane() || false;

    // custom SOP interface
    let sopInterface = displaySettings?.sopInterface;

    if (sopInterface === undefined) {
      sopInterface = true;
    }

    // CSS for Component
    // const styleMineLevelOpsControl = {
    //   height: "100%",
    // };

    // const styleMineLevelMap = {
    //   height: "100vH", // "700px",
    //   width: "100%",
    //   position: "relative",
    //   zIndex: 0,
    // };

    // #NOTE #REVIEW #TODO - make common function
    //
    const { view } = displaySettings;
    let fontSize;
    let paddingBottom;
    switch (view) {
      case "compressed":
        fontSize = 0.6; // 30/50
        paddingBottom = 0.8;
        break;
      case "normal":
        fontSize = 1; // 50/50
        paddingBottom = 1;
        break;
      case "expanded":
        fontSize = 1.6; // 80/50
        paddingBottom = 1.2;
        break;
      default:
        fontSize = 1;
        paddingBottom = 1;
        break;
    }

    const styleInfo = { fontSize, paddingBottom };

    // override if user is Guest
    if (isUserGuest) {
      // #WIP - what to do for guest?
    }

    return (
      <div className={"mineLevelOpsControl"}>
        <Grid divided>
          <Grid.Row>
            <Grid.Column
              width={16}
              key={"left"}
              className={"mineLevelPrecannedOperationsList"}
            >
              {!sopInterface && (
                <MineLevelPrecannedOperationsList
                  mineLevelId={level.id}
                  strings={strings}
                  userSettings={userSettings}
                />
              )}
              {sopInterface && (
                // #REVIEW #TODO - a div of any size allows overflow to work - why???
                // <div style={{ height: "0px" }}>
                <MineLevelCustomSOPList
                  mineLevelId={level.id}
                  styleInfo={styleInfo}
                  strings={strings}
                />
                // </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const displaySettings = getNamedAreaDisplaySettings(state);

  const areas = getAllAreaStatuses(state) || [];
  const areaIds = areas.map((area) => area.id);

  return {
    displaySettings,
    isUserGuest: isUserGuest(state),
    areaIds,
  };
};

export default connect(mapStateToProps, null)(MineLevelOpsControl);
