import React, { Component } from "react";
import { formatDate } from "utils/format-date";

import { Button, Popup, Icon } from "semantic-ui-react";

import { capitalize } from "utils/format-text";

// XLSL 'react-export-excel' IMPLEMENTATION
// click button to download report
// uses https://www.npmjs.com/package/react-export-excel
// see ex - https://github.com/rdcalle/react-export-excel/blob/HEAD/examples/with_custom_download_element.md
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const exportFieldsTemplate = [
  {
    type: "firefly",
    fields: [
      { label: "FireFly", value: "firefly" },
      { label: "FireFly MAC", value: "fireflyMac" },
      { label: "Controller", value: "ups_name" },
      { label: "Controller MAC", value: "ups_id" },
      { label: "Area", value: "area" },
      { label: "Position", value: "position" },
      { label: "Location", value: "location" },
      { label: "Software Version", value: "swVersion" },
      { label: "Status", value: "status" },
      { label: "Timestamp", value: "timestamp" },
    ],
  },
  {
    type: "controller",
    fields: [
      { label: "Controller", value: "ups_name" },
      { label: "Controller MAC", value: "ups_id" },
      { label: "Controller IP", value: "controllerIp" },
      { label: "Area", value: "area" },
      { label: "Location", value: "location" },
      { label: "Software Version", value: "version" },
      { label: "Disk Used", value: "diskUsed" },
      { label: "User Relay", value: "userRelay" },
      { label: "Status", value: "status" },
      { label: "Timestamp", value: "timestamp" },
    ],
  },
];

export class Download extends React.Component {
  render() {
    const { data, dataType } = this.props;

    //console.log("Download data", data);

    const filename = `${capitalize(dataType)}_status_report_${formatDate(
      new Date(Date.now()),
      "dd-MMM-yy-HH-mm-ss"
    )}`;

    const exportFields =
      exportFieldsTemplate?.find((field) => field?.type === dataType)?.fields ||
      [];

    const exportExcelColumns = exportFields?.map((field, idx) => {
      return <ExcelColumn label={`${field.label}`} value={`${field.value}`} />;
    });

    return (
      <ExcelFile
        filename={filename}
        element={
          <Popup
            content="Download XLSX data report"
            trigger={
              <Button
                icon
                size="large" // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              >
                <Icon name="download" />
              </Button>
            }
          />
        }
      >
        <ExcelSheet data={data} name="Name">
          {exportExcelColumns}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
