import React from "react";

import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import List from "semantic-ui-react/dist/commonjs/elements/List";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

import { connect } from "react-redux";
import { getEditingDefaultSettings, getEditingRegionsInOrder } from "./reducer";
import * as actions from "./actions";

import { ALLOWED_LIGHT_COLORS } from "firefly_light_colors";
import getSemanticColor from "colorpalette";

function ChooseLightSettings({ ...props }) {
  const semColor = getSemanticColor(props.color);
  return (
    <Menu compact>
      <Dropdown
        simple
        item
        style={{
          backgroundColor: semColor,
          color:
            props.color === "black" || props.color === "off"
              ? "white"
              : "black",
        }}
        text={props.color}
        closeOnChange
        onChange={(event, data) => props.onChange(data.value)}
        options={ALLOWED_LIGHT_COLORS.map((color) => ({
          key: color,
          text: color,
          value: color,
          active: props.color === color,
        }))}
      />
    </Menu>
  );
}

function UpDown({ ...props }) {
  const { onUp, onDown } = props;
  const buttonStyle = { padding: 0 };
  const iconStyle = { margin: 0 };
  return (
    <Button.Group vertical size="large">
      <Button style={buttonStyle} primary onClick={onUp} disabled={!onUp}>
        <Icon name="up triangle" style={iconStyle} />
      </Button>
      <Button style={buttonStyle} primary onClick={onDown} disabled={!onDown}>
        <Icon name="down triangle" style={iconStyle} />
      </Button>
    </Button.Group>
  );
}

function EditRegionSettingsList({ ...props }) {
  const { regions, regionActions } = props;
  const {
    changeLightSettings,
    raisePriority,
    lowerPriority,
    deleteRegion,
  } = regionActions;
  const lastIndex = regions.length - 1;
  if (lastIndex === -1) {
    return <div>No regions added</div>;
  }
  return (
    <List divided relaxed>
      {regions.map((region, idx) => (
        <List.Item key={`region-${region.id}`}>
          <List.Icon verticalAlign={"middle"}>
            <UpDown
              onUp={idx === 0 ? undefined : () => raisePriority(region.id)}
              onDown={
                idx === lastIndex ? undefined : () => lowerPriority(region.id)
              }
            />
          </List.Icon>
          <List.Content>
            <List.Description>
              <Form.Field inline>
                <label>Region #{idx + 1}</label>
                <ChooseLightSettings
                  color={region.lightSettings.color}
                  onChange={(color) => changeLightSettings(region.id, color)}
                />
                <Button
                  floated={"right"}
                  icon="trash"
                  onClick={() => deleteRegion(region.id)}
                />
              </Form.Field>
            </List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
}

export function EditSeismicRegionSettings({ ...props }) {
  const { levelDefault, regions, changeDefaultColor, regionActions } = props;
  return (
    <div>
      <Header floated="left">Defaults</Header>
      <Popup
        content="Load current settings"
        trigger={
          <Button
            icon="copy"
            size="mini"
            floated="right"
            onClick={props.onCopyExistingSettings}
          />
        }
      />
      <Divider clearing />
      <Form>
        <Form.Field inline>
          <label>Level default</label>
          <ChooseLightSettings
            color={levelDefault.color}
            onChange={changeDefaultColor}
          />
        </Form.Field>
      </Form>
      <Header dividing>Regions</Header>
      <Form>
        <EditRegionSettingsList
          regions={regions}
          regionActions={regionActions}
        />
      </Form>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    levelDefault: getEditingDefaultSettings(state),
    regions: getEditingRegionsInOrder(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    changeDefaultColor: (color) =>
      dispatch(actions.changeDefaultLightSettings(color)),
    regionActions: {
      changeLightSettings: (id, color) =>
        dispatch(actions.changeRegionLightSettings(id, color)),
      deleteRegion: (id) => dispatch(actions.deleteRegion(id)),
      raisePriority: (id) => dispatch(actions.raiseRegionPriority(id)),
      lowerPriority: (id) => dispatch(actions.lowerRegionPriority(id)),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSeismicRegionSettings);
