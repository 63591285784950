import { sanitizeRegionShape } from 'utils/RegionShape'
import { sanitizeLightSettings } from 'firefly_light_colors'
import cuid from 'cuid'

export function resetSeismicOverlayEditor () {
  return { type: 'RESET_SEISMIC_OVERLAY_EDITOR' }
}

export function addNewRegion (id, points, lightSettings) {
  return {
    type: 'ADD_NEW_REGION',
    payload: {
      id,
      points: sanitizeRegionShape(points),
      lightSettings: sanitizeLightSettings(lightSettings)
    }
  }
}

export function changeDefaultLightSettings (lightSettings) {
  return {
    type: 'CHANGE_DEFAULT_LIGHT_SETTING',
    lightSettings: sanitizeLightSettings(lightSettings)
  }
}

export function changeRegionLightSettings (id, lightSettings) {
  return {
    type: 'CHANGE_REGION_LIGHT_SETTING',
    payload: {
      id,
      lightSettings: sanitizeLightSettings(lightSettings)
    }
  }
}

export function changeRegionShape (id, points) {
  return {
    type: 'CHANGE_REGION_SHAPE',
    payload: {
      id,
      points: sanitizeRegionShape(points)
    }
  }
}

function buildPlainAction (type) {
  return id => ({ type, payload: { id } })
}

export const deleteRegion = buildPlainAction('DELETE_REGION')
export const lowerRegionPriority = buildPlainAction('LOWER_REGION_PRIORITY')
export const raiseRegionPriority = buildPlainAction('RAISE_REGION_PRIORITY')

export const saveAndApply = mineLevelId => ({
  type: 'SAVE_NEW_REGION_REQUEST',
  payload: {
    mineLevelId
  }
})

export const forceWholeSettings = settings => {
  const regionSettings = { byId: {}, priority: [] }

  settings.regionSettings.forEach(region => {
    const id = cuid()
    regionSettings.byId[id] = { id, ...region }
    regionSettings.priority.push(id)
  })

  return {
    type: 'FORCE_WHOLE_SETTINGS',
    payload: {
      levelDefault: settings.levelDefault,
      regionSettings
    }
  }
}

export const startEditingWithSeismicAsStart = (
  mineLevelId,
  seismicLayerId
) => ({
  type: 'SEISMIC_EDITOR_START_WITH_SEISMIC_LAYER',
  payload: { mineLevelId, seismicLayerId }
})
