export const showAreaPreview = (area) => ({
  type: "SHOW_AREA_PREVIEW",
  payload: area,
});

export const stopAreaPreview = (area) => ({
  type: "STOP_AREA_PREVIEW",
  payload: area,
});

export const addAreaPreview = (area) => ({
  type: "ADD_AREA_PREVIEW",
  payload: area,
});

export const removeAreaPreview = (area) => ({
  type: "REMOVE_AREA_PREVIEW",
  payload: area,
});

export const clearAreaPreview = () => ({
  type: "CLEAR_AREA_PREVIEW",
});

export const addAllAreaPreview = (allPolygonsArray) => ({
  type: "ADD_ALL_AREA_PREVIEW",
  payload: allPolygonsArray,
});
