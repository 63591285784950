// *    *    *    *    *    *
// ┬    ┬    ┬    ┬    ┬    ┬
// │    │    │    │    │    |
// │    │    │    │    │    └ day of week (0 - 7, 1L - 7L) (0 or 7 is Sun)
// │    │    │    │    └───── month (1 - 12)
// │    │    │    └────────── day of month (1 - 31, L)
// │    │    └─────────────── hour (0 - 23)
// │    └──────────────────── minute (0 - 59)
// └───────────────────────── second (0 - 59, optional)

// see - https://github.com/harrisiirak/cron-parser
// see - 7 segment fork - https://www.npmjs.com/package/@chuxingpay/cron-parser
import { parseExpression, fieldsToExpression } from "cron-parser";

// see - https://github.com/GuillaumeRochat/cron-validator
// npm i cron-validator
import { isValidCron } from "cron-validator";

// see - https://github.com/Airfooox/cron-validate
// npm install -S cron-validate
import cron from "cron-validate";

const dateToCron = (date) => {
  const second = date.getSeconds();
  const minute = date.getMinutes();
  const hour = date.getHours();
  const dayOfMonth = date.getDate();
  const month = date.getMonth() + 1;
  const dayOfWeek = date.getDay();

  return `${second} ${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
};

// #NOTE - order of this object is *important* as the values make up the cron expression
const DEFAULT_CRON = {
  second: 0,
  minute: 0,
  hour: 0,
  dayOfMonth: 1,
  month: "*",
  dayOfWeek: "*",
  year: "*",
};

// enable debug messages
const DEBUG = !true;

export const convertFieldsToCronExpression = (values) => {
  DEBUG && console.log("xxx convertCron values", values);

  //let cron = Object.values(DEFAULT_CRON);
  let cronObj = { ...DEFAULT_CRON };

  [
    "year",
    "dayOfWeek",
    "month",
    "dayOfMonth",
    "hour",
    "minute",
    "second",
  ].forEach((field) => {
    const everyString = values?.[field]?.every === true ? "*/" : "";

    if (values?.[field]) {
      // isNaN(Number(values?.[field]?.value))
      DEBUG && console.log("xxx convertCron field", field);
      DEBUG && console.log("xxx convertCron values?.[field]", values?.[field]);
      DEBUG &&
        console.log("xxx convertCron value value ", values?.[field]?.value);
      DEBUG &&
        console.log(
          "xxx convertCron value isNaN(value) ",
          isNaN(values?.[field]?.value)
        );

      if (values?.[field]?.value !== 0) {
        if (everyString) {
          if (values?.[field]?.value !== "*") {
            // #Note - special case exclude every "*/" from minutes
            // i.e. "*/59" === every 59min, is same as "59" === every 59th minute
            //if (field === "minute") {
            //  cronObj[field] = `${values?.[field]?.value}`;
            //} else {
            cronObj[field] = `${everyString}${values?.[field]?.value}`;
            //}
          }
        } else {
          cronObj[field] = values?.[field]?.value;
        }
      }
      DEBUG && console.log("xxx convertCron cronObj field", cronObj, field);
    }
  });

  // make sure the order is correct!!!!!
  const cronString = `${cronObj.second} ${cronObj.minute} ${cronObj.hour} ${cronObj.dayOfMonth} ${cronObj.month} ${cronObj.dayOfWeek} ${cronObj.year}`;
  DEBUG && console.log("xxx convertCron cronString", cronString);

  // 'isValidCron'  does not support years --- :(
  const cronStringNoYear = `${cronObj.second} ${cronObj.minute} ${cronObj.hour} ${cronObj.dayOfMonth} ${cronObj.month} ${cronObj.dayOfWeek}`;
  const isCronOK = isValidCron(cronStringNoYear, { seconds: true });

  console.log("CRON CHECK FAILED isValidCron CRON : ", cronString);

  if (isCronOK) {
    const cronResult = cron(cronString, {
      override: {
        useSeconds: true,
        useYears: true,
      },
    });

    if (cronResult.isValid()) {
      const validValue = cronResult.getValue();

      // The valid value is a object containing all cron fields
      DEBUG && console.log("xxx convertCron cronResult validValue", validValue);

      // maintenance - convert object keys for validValue
      // start
      const start = {
        year: validValue.years,
        dayOfWeek: validValue.daysOfWeek,
        month: validValue.months,
        dayOfMonth: validValue.daysOfMonth,
        hour: validValue.hours,
        minute: validValue.minutes,
        second: validValue.seconds,
      };

      let stop = { ...start };

      // #REVIEW - exclude years until needed in future
      const { year, ...stopNoYear } = stop;

      // stop = start + duration
      // convert cron to a time
      // time + duration
      //
      DEBUG &&
        console.log(
          "xxx convertCron dur startParse",
          Object.values(stopNoYear)?.join(" ")
        );

      const cronStartStringNoYear = `${start.second} ${start.minute} ${start.hour} ${start.dayOfMonth} ${start.month} ${start.dayOfWeek}`;

      const startParse = parseExpression(cronStartStringNoYear);
      DEBUG && console.log("xxx convertCron startParse", startParse);

      const nextStartParse = startParse.next().toString();

      DEBUG &&
        console.log("xxx convertCron dur startParse nextCron", nextStartParse);

      //const dateText = nextCron; // "2017-05-09T01:30:00.123Z";
      // convert next Cron event to a date
      let date = new Date(nextStartParse);

      // convert the date back to a cron
      const cronNextStart = dateToCron(date);

      DEBUG &&
        console.log(
          "xxx convertCron dur dateToCron cronNextStart",
          cronNextStart
        );

      const duration = values?.duration || {};

      DEBUG && console.log("xxx convertCron duration value", duration);

      let durationSecs = 0;
      if (!isNaN(Number(duration?.value))) {
        switch (duration?.unit) {
          case "hour":
            durationSecs = duration.value * 3600;
            break;
          case "minute":
            durationSecs = duration.value * 60;
            break;
          case "second":
            durationSecs = duration.value;
            break;
          default:
            break;
        }
      }

      // add duration to the date
      date.setSeconds(date.getSeconds() + durationSecs);

      // convert the date back to a cron
      const cronDuration = dateToCron(date);

      //console.log("xxx 123 dur dateToCron cronDuration", cronDuration); //30 5 9 5 2

      const durationParse = parseExpression(cronDuration);
      DEBUG && console.log("xxx convertCron startParse", startParse);

      const nextDurationParse = durationParse.next().toString();
      DEBUG &&
        console.log(
          "xxx convertCron dur durationParse  nextCron",
          nextDurationParse
        );

      DEBUG && console.log("xxx convertCron dur ---- DIFFERENCE-------");

      const arrayStart = cronNextStart.split(" ");
      const arrayDuration = cronDuration.split(" ");

      DEBUG &&
        console.log(
          "xxx convertCron dur ---- DIFFERENCE------- arrayStart",
          arrayStart
        );
      DEBUG &&
        console.log(
          "xxx convertCron dur ---- DIFFERENCE------- arrayDuration",
          arrayDuration
        );

      let diff = {
        second: 0,
        minute: 0,
        hour: 0,
        dayOfMonth: 0,
        month: 0,
        dayOfWeek: 0,
      };

      //   return `${second} ${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;

      ["second", "minute", "hour", "dayOfMonth", "month", "dayOfMonth"].map(
        (period, idx) => {
          diff[period] = Number(arrayDuration[idx]) - Number(arrayStart[idx]);
        }
      );

      DEBUG &&
        console.log("xxx convertCron dur ---------------------diff ", diff);
      DEBUG && console.log("xxx convertCron dur ---------------------");

      const startString = `${start.second} ${start.minute} ${start.hour} ${start.dayOfMonth} ${start.month} ${start.dayOfWeek}`;

      DEBUG && console.log("xxx convertCron dur stop ", stop);

      // #TODO - MAKE THIS A FUNCTION!!!!!!!!!!!!!!!!

      if (diff.dayOfMonth !== 0) {
        if (!isNaN(Number(start.dayOfMonth))) {
          stop.dayOfMonth = Number(start.dayOfMonth) + diff.dayOfMonth;
        } else if (start.dayOfMonth?.includes("*")) {
          const arrayCron = [...start.dayOfMonth];
          DEBUG && console.log("xxx convertCron dur arrayCron ", arrayCron);
        }
      }

      if (diff.hour !== 0) {
        if (!isNaN(Number(start.hour))) {
          stop.hour = Number(start.hour) + diff.hour;
        } else if (start.hour?.includes("*")) {
          //const arrayCron = [...start.hour];
          const arrayCron = start.hour.split("/");

          DEBUG && console.log("xxx convertCron dur arrayCron ", arrayCron);
          DEBUG && console.log("xxx convertCron diff", diff);
          DEBUG &&
            console.log("xxx convertCron dur diff.hour >= 0 ", diff.hour >= 0);

          if (diff.hour >= 0) {
            stop.hour = `${diff.hour}-23/${Number(arrayCron[1])}`;
          }
          // -ve
          else {
            let stopExp = `${23 + diff.minute}-23/${Number(arrayCron[1])}`;
            if (stopExp === "0-23/23") {
              stopExp = "0";
            } else {
              stop.minute = `${23 + diff.minute}-23/${Number(arrayCron[1])}`;
            }
          }
        }
      }

      if (diff.minute !== 0) {
        if (!isNaN(Number(start.minute))) {
          stop.minute = Number(start.minute) + diff.minute;
        } else if (start.minute?.includes("*")) {
          const arrayCron = start.minute.split("/");
          //stop.minute = `*/${Number(arrayCron[1]) + diff.minute}`;

          DEBUG && console.log("xxx convertCron diff", diff);
          DEBUG && console.log("xxx convertCron dur arrayCron ", arrayCron);
          DEBUG &&
            console.log(
              "xxx convertCron dur diff.minute >= 0 ",
              diff.minute >= 0
            );

          if (diff.minute >= 0) {
            stop.minute = `${diff.minute}-59/${Number(arrayCron[1])}`;
          }
          // -ve
          else {
            let stopExp = `${59 + diff.minute}-59/${Number(arrayCron[1])}`;
            if (stopExp === "0-59/59") {
              stopExp = "0";
            } else {
              stop.minute = `${59 + diff.minute}-59/${Number(arrayCron[1])}`;
            }
          }
        }
      }

      if (diff.second !== 0) stop.second = Number(stop.second) + diff.second;

      const stopString = `${stop.second} ${stop.minute} ${stop.hour} ${stop.dayOfMonth} ${stop.month} ${stop.dayOfWeek}`;

      DEBUG && console.log("xxx convertCron dur startString ", startString);
      DEBUG && console.log("xxx convertCron dur stopString ", stopString);
      DEBUG && console.log("xxx convertCron dur ------------------------ ");

      return { start: startString, stop: stopString };
    } else {
      const errorValue = cronResult.getError();

      // The error value contains an array of strings, which represent the cron validation errors.
      console.log("CRON RESULT ERROR ", errorValue); // string[] of error messages

      // 1...6 is meaningless but lets me quickly notice a failure
      return { start: "1 2 3 4 5 6", stop: "1 2 3 4 5 6" };
    }
  } else {
    // 1...6 is meaningless but lets me quickly notice a failure
    return { start: "6 5 4 3 2 1", stop: "6 5 4 3 2 1" };
  }
};

// #WIP - planned funtion
export const convertCronExpressionToFields = (cron) => {
  let cronString = "* * * * * * *";

  return cronString;
};
