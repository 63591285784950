import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import {
  isBoomgateCrane,
  isTagTracking as _isTagTracking,
  isShowBackupRestore as _isShowBackupRestore,
} from "components/ConfigJs";

import {
  isServiceBackupMonitor,
  isServiceTagTracker,
} from "components/Settings/reducer";

let AdminMenu = ({
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
  isServiceBackupMonitor,
  isServiceTagTracker,
  //
  strings, // localisation
  userSettings,
}) => {
  const allowGuest = isUserGuest;
  const allowOperator = isUserSuper || isUserAdmin || isUserUser;
  const allowAdmin = isUserSuper || isUserAdmin;
  const allowSuper = isUserSuper;

  const isShowBackupRestore =
    userSettings?.feature?.includes("showBackupRestore") ||
    _isShowBackupRestore();
  const isTagTracking =
    userSettings?.application?.includes("tagTracking") || _isTagTracking();

  // railway_application
  const boomgateCrane = isBoomgateCrane() || false;
  const areaTitle = !boomgateCrane ? strings["Areas"] : strings["Map Image"];

  // APP_TERMINOLOGY
  const strNamedAreaGroups = strings["Lighting Control Layout"];
  const strNamedAreas = strings["Polygons"];

  return (
    <Dropdown item simple text={"Admin"} className="link item">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/admin/area`}>
          {areaTitle}
        </Dropdown.Item>
        {/*
        <Dropdown.Item as={Link} to={`/admin/named-area-group`}>
          {strNamedAreaGroups}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/admin/named-area/region`}>
          {strNamedAreas}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/admin/ups`}> */}
        <Dropdown.Item as={Link} to={`/admin/controller`}>
          {strings["Controllers"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/admin/firefly`}>
          {strings["FireFlys"]}
        </Dropdown.Item>
        {/* <Dropdown.Item as={Link} to={`/admin/bulk-positioning`}>
          Bulk Positioning
        </Dropdown.Item> */}
        {/* <Dropdown.Item as={Link} to={`/admin/controller`}>
          {strings["Bulk Positioning"]}
        </Dropdown.Item> */}

        <Dropdown.Divider />
        <Dropdown.Header>{strings["Logs"]}</Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to={`/admin/logs`}>
          {strings["System Logs"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/admin/faults/logs`}>
          {strings["Browser Logs"]}
        </Dropdown.Item>
        {/* <Dropdown.Item as={Link} to={`/admin/fail`}>
          Fault Logs
        </Dropdown.Item> */}
        {false && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>{strings["Faults"]}</Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/admin/faults/firefly`}>
              {strings["FireFly Faults"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/admin/faults/battery`}>
              {strings["Battery Faults"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/admin/faults/network`}>
              {strings["Network Faults"]}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Status</Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/admin/faults/controller`}>
              {strings["Controller Status"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/admin/firefly`}>
              {strings["FireFly Status"]}
            </Dropdown.Item>
          </>
        )}
        {false && isTagTracking && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>{strings["Tags"]}</Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/status/tag-map`}>
              {strings["Tags Map"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/status/tag`}>
              {strings["Tags"]}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/status/tagzone`}>
              {strings["Tags In Zones"]}
            </Dropdown.Item>
            {allowAdmin && (
              <Dropdown.Item as={Link} to={`/admin/named-area/tagzone`}>
                {strings["Tag Zones"]}
              </Dropdown.Item>
            )}
          </>
        )}
        <Dropdown.Divider />
        <Dropdown.Header>{strings["Tools"]}</Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to={`/tools/system`}>
          {strings["System Tools"]}
          <Dropdown.Divider />
        </Dropdown.Item>
        {isShowBackupRestore && (
          <Dropdown.Item as={Link} to={`/tools/backup-restore`}>
            <div>{strings?.["Backup and Restore"]}</div>
            <Dropdown.Divider />
          </Dropdown.Item>
        )}
        {isUserSuper && (
          <Dropdown.Item as={Link} to={`/tools/localisation`}>
            <div className={"superOption"}>Localisation</div>
          </Dropdown.Item>
        )}

        {isUserSuper && (
          <>
            <Dropdown.Divider />
            <Dropdown.Header>
              <div className={"superOption"}>Advanced</div>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/admin/named-area-advanced`}>
              <div
                className={"superOption"}
              >{`Advanced Parent ${strNamedAreas}`}</div>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/admin/named-area-advanced/all`}>
              <div
                className={"superOption"}
              >{`Advanced All ${strNamedAreas}`}</div>
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`/admin/named-area-advanced/all-events`}
            >
              <div className={"superOption"}>{`Advanced All Events`}</div>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/admin/named-area/forced`}>
              <div className={"superOption"}>Forced FireFlys</div>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/tools/mqtt-messages`}>
              <div className={"superOption"}>MQTT Messages</div>
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

AdminMenu.defaultProps = {};

AdminMenu = connect((state) => ({
  isUserSuper: isUserSuper(state),
  isUserAdmin: isUserAdmin(state),
  isUserUser: isUserUser(state),
  isUserGuest: isUserGuest(state),
  //
  isServiceBackupMonitor: isServiceBackupMonitor(state),
  isServiceTagTracker: isServiceTagTracker(state),
}))(AdminMenu);

export default AdminMenu;
