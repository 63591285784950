// see - src/admin/named-area-group/NamedAreaGroupSettingsModal.js

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Form,
  Segment,
  Modal,
  Header,
  Icon,
  Button,
  Grid,
} from "semantic-ui-react";
import { reduxForm } from "redux-form";

import isEqual from "lodash/isEqual";

import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import { tagTrackingUserDetailsCardPersonnel } from "admin/tag-tracking/TagTrackingUserDetailsCardPersonnel";
import { tagTrackingUserDetailsCardVehicle } from "admin/tag-tracking/TagTrackingUserDetailsCardVehicle";
import { tagTrackingUserDetailsCardUnknown } from "admin/tag-tracking/TagTrackingUserDetailsCardUnknown";
import { getTagById } from "components/Tags/reducer";

class TagTrackingMarkerPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  componentDidMount() {
    const { open, isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;

    if (open) {
      this.handleOpen();
    }

    if (isPokeTheWorker) {
      TurnOnOffPokeTheWorker(false);
    }
  }

  componentWillUnmount() {
    const { isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;
    if (!isPokeTheWorker) {
      TurnOnOffPokeTheWorker(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.resetModal();

    // reset the form
    //this.setState({ value: "" }, () => this.props.reset());
  };

  render() {
    const { tagUserType, id, tagUserId, mac } = this.props;

    // APP_TERMINOLOGY
    let strTagUser = "User";
    let strTagUsers = "Users";

    switch (tagUserType) {
      case "personnel":
        strTagUser = "Personnel";
        strTagUsers = "Personnel";
        break;
      case "vehicle":
        strTagUser = "Vehicle";
        strTagUsers = "Vehicles";
        break;
      case null:
      default:
        break;
    }

    const imageFilenameUnknown = "tag-unknown.png";
    const imageFilenameUser = "avatar-construction.png";
    const imageFilenameVehicle = "avatar-dump-truck.png";

    let urlImage = process.env.PUBLIC_URL + "/images/" + imageFilenameUnknown;
    switch (tagUserType) {
      case "personnel":
        urlImage = process.env.PUBLIC_URL + "/images/" + imageFilenameUser;
        break;
      case "vehicle":
        urlImage = process.env.PUBLIC_URL + "/images/" + imageFilenameVehicle;
        break;
      default:
        break;
    }

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header
          icon="cog"
          content={<Link to={`/status/tag/${id}`}>{`Tag ${mac}`} </Link>}
        />
        <Modal.Content>
          <Segment>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Grid.Column>
                      {tagUserType === "unknown" &&
                        tagTrackingUserDetailsCardUnknown(
                          urlImage,
                          id,
                          mac,
                          true
                        )}
                      {tagUserType === "vehicle" &&
                        tagTrackingUserDetailsCardVehicle(
                          urlImage,
                          tagUserId,
                          true
                        )}
                      {tagUserType === "personnel" &&
                        tagTrackingUserDetailsCardPersonnel(
                          urlImage,
                          tagUserId,
                          true
                        )}
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel}>
            <Icon name="close" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

TagTrackingMarkerPopupModal = reduxForm({
  form: "tagTrackingMarkerPopupModal",
  enableReinitialize: true,
  //keepDirtyOnReinitialize: true,
  touchOnChange: true,
  //validate,
})(TagTrackingMarkerPopupModal);

function mapStateToProps(state, props) {
  const {
    initialValues: { id },
  } = props;

  // derive mac from id. Format is `id~mac`
  const mac = id?.split?.("~")?.[1] || "";

  const tag = getTagById(state, id);
  const { tagUserType, tagUserId } = tag;

  let tagUserData;
  switch (tagUserType) {
    case "personnel":
      tagUserData = { ...tag.userInfo };
      // temporary until nickname is setup (note - cloned above to be mutable)
      tagUserData.nick = `${
        tagUserData?.firstname
      }${tagUserData?.lastname?.charAt(0)}`;
      break;
    case "vehicle":
      tagUserData = { ...tag.vehicleInfo };
      break;
    default:
      break;
  }

  const newInitialVales = { id, mac, tagUserData };

  return {
    isPokeTheWorker: isOnOffPokeTheWorker(state),
    id,
    mac,
    tagUserId,
    tagUserType,
    initialValues: newInitialVales,
  };
}

export default connect(mapStateToProps, { TurnOnOffPokeTheWorker })(
  TagTrackingMarkerPopupModal
);
