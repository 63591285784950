import React, { Component, useState } from "react";
import { connect } from "react-redux";

import L from "leaflet";
import "leaflet-draw";
import "leaflet-contextmenu";
import { greenIcon } from "assets/leaflet/leaflet-color-markers";
import { LeastSquaresApproximationFourPointsSixParametersTransformation } from "projection";
import {
  getMapState,
  getFireflyCoordinates,
  getNamedAreaInfos,
  getNamedAreaDeleteSelections,
  getNamedAreaHideSelections,
  getIsDirty,
} from "components/WebWorker/reducer";

import {
  namedAreaClearDeleteSelections,
  namedAreasSetIsDirty,
  mqttPublish,
} from "components/WebWorker/actions";

import { GetLocalMap } from "components/Map/reducer";
import { UpdateLocalMap } from "components/Map/actions";

import { round } from "utils/number-utils.js";
import _isEmpty from "lodash/isEmpty";
import * as turf from "@turf/turf";

import { makeIcon } from "components/Map/MakeIcon";

import Heartbeat from "react-heartbeat";

// import plugin's css (if present)
// note, that this is only one of possible ways to load css
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import { messageToken } from "utils/messageToken";

// #REVIEW - move this.locationGroupLayers etc. to state rather than forced via "this."
// use setState to update once groups are defined

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refPts: {},
      thislocalMapState: {},
      map: { area: "DMLZ_Extraction" },
      heartBeat: true,
    };
  }

  // styling for polygons
  geojsonPolygonStyle = (feature) => {
    let setFillColor;
    if (feature.properties.active_color) {
      const color = feature.properties.active_color;
      switch (color.toLowerCase) {
        case "green":
          setFillColor = "green";
          break;
        case "blue":
          setFillColor = "blue";
          break;
        case "amber":
          setFillColor = "yellow";
          break;
        case "red":
          setFillColor = "red";
          break;

        default:
          setFillColor = "green";
          break;
      }
    }

    return {
      fillColor: setFillColor,
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.5,
    };
  };

  localPts = () => {
    // measured from pixel positions
    const localRef1 = {
      id: "localRef1",
      lat: 4764,
      lng: 4202,
      easting: 736959.54,
      northing: 9549076.153,
      zoneNum: 53,
      zoneLetter: "M",
    };
    const localRef2 = {
      id: "localRef2",
      lat: 3236,
      lng: 7815,
      easting: 737526.058,
      northing: 9549315.041,
      zoneNum: 53,
      zoneLetter: "M",
    };
    const localRef3 = {
      id: "localRef3",
      lat: 5978,
      lng: 10090,
      easting: 737883.021,
      northing: 9548885.911,
      zoneNum: 53,
      zoneLetter: "M",
    };
    const localRef4 = {
      id: "localRef4",
      lat: 7611,
      lng: 8061,
      easting: 737564.472,
      northing: 9548630.213,
      zoneNum: 53,
      zoneLetter: "M",
    };
    return [localRef1, localRef2, localRef3, localRef4];
  };

  markerPts = (img) => {
    const { width, height } = img;

    // image pixel positions = lat,lng
    // geometric refernece coord = easting, northing, zoneNum, zoneLetter,
    // {lat, lng, easting, northing, zoneNum, zoneLetter}

    const markerOrigin = {
      lat: 0,
      lng: 0,
      easting: 0,
      northing: 0,
      zoneNum: 53,
      zoneLetter: "M",
      z: 0,
    };
    const markerMiddle = {
      lat: height / 2,
      lng: width / 2,
      easting: 0,
      northing: 0,
      zoneNum: 53,
      zoneLetter: "M",
      z: 0,
    };
    const markerMax = {
      lat: height,
      lng: width,
      easting: 0,
      northing: 0,
      zoneNum: 53,
      zoneLetter: "M",
      z: 0,
    };

    return [markerOrigin, markerMiddle, markerMax];
  };

  // creates a geoJson object for the  map reference points
  // pass in map pixel ref lat,lng and the coord reference easting, northing
  // geometry coordinats are in pixels
  //
  // e.g. localPtsGeoJson([{ lat: 0, lng: 0, easting: 0, northing: 0, zoneNum: 53, zoneLetter: "M", z: 0 },...[...]);
  //
  // still using lat and lng because leaflet uses these references.
  //
  localPtsGeoJson = (points) => {
    let localPtsGeoJson = [];
    points.map(
      ({ id, lat, lng, easting, northing, zoneNum, zoneLetter }, idx) => {
        const objLatLng = { lat: lng, lng: lat }; //  #REVIEW - have swapped around lat and long **ADRESS THIS**
        const point = Object.values(objLatLng);

        // structure geoJSON object

        const geojsonFeature = {
          id,
          type: "Feature",
          properties: {
            index: idx,
            id: id,
            area: "refArea",
            location: "refPt",
            position: idx,
            easting: easting,
            northing: northing,
            utm_zone_number: zoneNum,
            utm_zone_letter: zoneLetter,
            z: 0,
            color: "toBeDone",
          },
          geometry: {
            type: "Point",
            coordinates: point,
          },
        };
        localPtsGeoJson.push(geojsonFeature);
      }
    );
    return {
      type: "FeatureCollection",
      features: localPtsGeoJson,
    };
  };

  standardPtsUtm = () => {
    // #TODO - this is duplicated in localPts()
    const ref1utm = {
      northing: 9549076.153,
      easting: 736959.54,
      zoneNum: 53,
      zoneLetter: "M",
    };
    const ref2utm = {
      northing: 9549315.041,
      easting: 737526.058,
      zoneNum: 53,
      zoneLetter: "M",
    };
    const ref3utm = {
      northing: 9548885.911,
      easting: 737883.021,
      zoneNum: 53,
      zoneLetter: "M",
    };
    const ref4utm = {
      northing: 9548630.213,
      easting: 737564.472,
      zoneNum: 53,
      zoneLetter: "M",
    };

    // rest UTM values
    const standardRef1 = { lat: ref1utm.easting, lng: ref1utm.northing };
    const standardRef2 = { lat: ref2utm.easting, lng: ref2utm.northing };
    const standardRef3 = { lat: ref3utm.easting, lng: ref3utm.northing };
    const standardRef4 = { lat: ref4utm.easting, lng: ref4utm.northing };

    const standardAll = [
      standardRef1,
      standardRef2,
      standardRef3,
      standardRef4,
    ];

    return standardAll;
  };

  // the geometric coordinate to map pixel transformation
  Transform = (localPts, standardPts) => {
    const LeastSq4Pt =
      LeastSquaresApproximationFourPointsSixParametersTransformation.fromPoints(
        localPts,
        standardPts
      );
    return LeastSq4Pt;
  };

  // create geojson markerData
  markerToGeoJSON = (markersData, transform) => {
    const geoJSONMarkersData = [];

    markersData.map(
      ({ id, lat, lng, name, color, position, location }, idx) => {
        // transform the point from latlng to pixels
        const objLatLng = { lat: lat, lng: lng };
        const point = Object.values(transform.transform(objLatLng));

        // structure geoJSON object
        const geojsonFeature = {
          type: "Feature",
          properties: {
            id: id,
            name: name,
            color: color,
            position: position,
            location: location,
          },
          geometry: {
            type: "Point",
            coordinates: point,
          },
        };
        geoJSONMarkersData.push(geojsonFeature);
      }
    );
    return {
      type: "FeatureCollection",
      features: geoJSONMarkersData,
    };
  };

  // support for map leaflet.contextmenu
  showCoordinates = (e) => {
    alert(e.latlng);
  };

  centerMap = (e) => {
    this.map.panTo(e.latlng);
  };

  fitMap = (e, bounds) => {
    this.map.fitBounds(bounds);
  };

  zoomIn = (e) => {
    this.map.zoomIn();
  };

  zoomOut = (e) => {
    this.map.zoomOut();
  };

  // draw
  drawTheMap = (mapId, url, geoJSONMarkersData, geoJSONNamedAreas) => {
    ////////////////////////////////////////////////////////////////////////////
    // Conversion from (x, y) raster image coordinates to equivalent of latLng
    // Taken from Leaflet tutorial "Non-geographical maps"
    // http://leafletjs.com/examples/crs-simple/crs-simple.html
    ////////////////////////////////////////////////////////////////////////////
    var yx = L.latLng;
    var xy = function (x, y) {
      if (L.Util.isArray(x)) {
        // When doing xy([x, y]);
        return yx(x[1], x[0]);
      }
      return yx(y, x); // When doing xy(x, y);
    };

    var minZoom = 2; // no need for zoom out completely
    var maxZoom = 6; // ...as defined by tile generation
    const width = 12571;
    const height = 9571;

    const img = [
      width, // original width of image `level.jpg` - undercut 800 conversion example => x / ~longitude (0 is left, width,  13234 is right)
      height, // original height of image => y / ~ reverse of latitude (0 is top, height, 9356 is bottom)
    ];

    // const { markersData, geoJSONMarkersDataUtm } = this.props;

    // // let geoJSONMarkersData = this.markerToGeoJSON(
    // //   markersData,
    // //   this.Transform(this.localPts(), this.standardPts())
    // // );

    // let geoJSONMarkersData = geoJSONMarkersDataUtm;
    // //console.log("geoJSONMarkersData TEST", geoJSONMarkersDataUtm);
    // console.log("geoJSONMarkersData markerToGeoJSON", geoJSONMarkersData);

    //break into subgroups for testing - only "P23"
    let subGroupData = [];

    geoJSONMarkersData.features.map(({ properties }, idx) => {
      const { location } = properties;

      if (location === "P23") {
        subGroupData.push(geoJSONMarkersData.features[idx]);
      }
    });

    subGroupData = {
      type: "FeatureCollection",
      features: subGroupData,
    };

    //all the resting - but not "P23"

    let tempGroupData = [];

    geoJSONMarkersData.features.map(({ properties }, idx) => {
      const { location } = properties;

      if (location !== "P23") {
        tempGroupData.push(geoJSONMarkersData.features[idx]);
      }
    });

    tempGroupData = {
      type: "FeatureCollection",
      features: tempGroupData,
    };

    // reassign main group to not have P23
    //    geoJSONMarkersData = tempGroupData;

    //console.log("geoJSONMarkersData", geoJSONMarkersData);

    /**
     *
     * Tile 0/0/0 is 256 x 256 px, and so are all other tiles.
     * gda2tile-leaflet sets tiles with reference to top left.
     *
     * In tile 0/0/0 width, height coordinate is 206, 146,
     * requiring a 1/64 scaling. i.e. e.g. 206/13234 = 64.
     * There is no offset. i.e. image is in top left
     * a = 1/64
     * b = 0
     * c = 1/64
     * d = 0
     *
     */

    L.CRS.MySimple = L.extend({}, L.CRS.Simple, {
      //                      coefficients: a      b    c     d
      transformation: new L.Transformation(1 / 64, 0, 1 / 64, 0),
    });

    // center calculation based on original pixel dimensions
    //
    const center = [img[0] / 2, img[1] / 2];

    var bounds = L.latLngBounds([xy(0, 0), xy(img)]);

    let base = L.tileLayer(url, {
      bounds: bounds, // http://leafletjs.com/reference-1.0.3.html#gridlayer-bounds
      noWrap: true,
    });

    this.map = L.map(mapId, {
      crs: L.CRS.MySimple, // http://leafletjs.com/reference-1.0.3.html#map-crs
      maxBounds: bounds.pad(0.5), // http://leafletjs.com/reference-1.0.3.html#map-maxbounds
      minZoom: minZoom,
      maxZoom: maxZoom,
      attributionControl: false,
      contextmenu: false, // context menu plugin `npm i leaflet-contextmenu`
      contextmenuWidth: 140,
      contextmenuItems: [
        {
          text: "Show coordinates",
          callback: this.showCoordinates,
        },
        {
          text: "Center map here",
          callback: this.centerMap,
        },
        {
          text: "Show all",
          callback: (e) => this.fitMap(e, bounds),
        },
        "-",
        {
          text: "Zoom in",
          icon: process.env.PUBLIC_URL + "/images/zoom-in.png",
          callback: this.zoomIn,
        },
        {
          text: "Zoom out",
          icon: process.env.PUBLIC_URL + "/images/zoom-out.png",
          callback: this.zoomOut,
        },
      ],
    }).setView(xy(center), 2); // move view to center pixel position

    // setup a dummy image and load the map image in the background
    // create a replacement image using known dimensions
    let canvas = document.createElement("canvas");
    // set desired size of transparent image
    canvas.width = width;
    canvas.height = height;
    // extract as new image (data-uri)
    let overlayUrl = canvas.toDataURL();

    // #WIP - setup switch to move between blank background and area
    overlayUrl = url;

    // set single image background
    const image = L.imageOverlay(overlayUrl, bounds);
    image.addTo(this.map);

    // setup layer for geoJSON data

    var geojsonMarkerOptions = {
      radius: 6,
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
      contextmenu: true, // contextmenu items for each geoJson marker
      contextmenuItems: [
        {
          text: "Additional entry for this marker...",
        },
      ],
    };

    // setup a pen for waylaid fireflies

    // #WIP - pen
    // #REVIEW
    // - https://www.npmjs.com/package/@turf/boolean-point-in-polygon
    // - http://turfjs.org/

    // holding pen is top corner starting from 75% in and ending 25% down

    this.holdingPen = L.polygon([
      [0, width * 0.75],
      [0, width],
      [height * 0.25, width],
      [height * 0.25, width * 0.75],
    ]).addTo(this.map);
    // -> see randomPointInPoly function

    // setup layer for geoJSON data
    // this.geoJSONGroupLayer = L.geoJSON(geoJSONMarkersData, {
    //   pointToLayer: function(feature, latlng) {
    //     return L.circleMarker(latlng, geojsonMarkerOptions);
    //   },
    // }).addTo(this.map);

    this.layerlistFireflies = {};

    // #REVIEW - https://stackoverflow.com/questions/46580213/pass-a-parameter-to-oneachfeature-leaflet
    // should pass layerlistFireflies and stuff through instead of using scope to access
    // ....?
    function onEachFeatureClosure(stuff) {
      return function onEachFeature(feature, layer) {
        // does this feature have a property named popupContent?
        if (feature.properties && feature.properties.id) {
          layer.bindPopup(JSON.stringify(feature)); //feature.properties.name
          this.layerlistFireflies[feature.properties.id] = layer;
        }

        // Attempt to split data to different layers
        // if (feature.properties.location == "P23") {
        //   // see - https://stackoverflow.com/questions/10953303/javascript-interpret-string-as-object-reference
        //   let tempStr = "p23GroupLayer";
        //   this[tempStr].addLayer(layer);
        //   //        this.p23GroupLayer.addLayer(layer);
        // }

        if (
          feature.properties &&
          feature.properties.location
          // &&
          // feature.properties.location === "P23"
        ) {
          //let tempStr = "P23";
          //let tempStr = `${feature.properties.location}`;
          this[`${feature.properties.location}`].addLayer(layer);
        }
      };
    }

    // #REVIEW
    // change coordinate order
    // https://gis.stackexchange.com/questions/246102/leaflet-reads-geojson-x-y-as-y-x-how-can-i-correct-this
    // L.geoJSON(geoJsonData, {
    //   coordsToLatLng: function (coords) {
    //     //                    latitude , longitude, altitude
    //     //return new L.LatLng(coords[1], coords[0], coords[2]); //Normal behavior
    //     return new L.LatLng(coords[0], coords[1], coords[2]);
    //   },
    // });

    this.testExternalStr = "Point:"; // test passing variable outside into ....

    // add popup information
    const onEachFeatureFirefly = (feature, layer) => {
      // #REVIEW - does this feature have a property named popupContent?

      const { properties } = feature;
      const {
        id,
        area,
        location,
        position,
        easting,
        northing,
        utm_zone_number,
        utm_zone_letter,
        z,
        color,
      } = properties;
      const { geometry } = feature;

      // coord geom is reversed
      const X = round(geometry.coordinates[0], 2);
      const Y = round(geometry.coordinates[1], 2);

      if (feature.properties && feature.properties.id) {
        layer.bindPopup(
          `<div>${id}</div>
          <div>${this.testExternalStr} ${area}:${location}:${position} </div>
          <div>Geom:  E:${round(easting, 2)} N:${round(
            northing,
            2
          )} Z${Math.trunc(z)} ${utm_zone_number} ${utm_zone_letter}  </div>
          <div>Image: X:${X} Y:${Y} </div>
          <div>Color: ${color} </div>`
        );

        this.layerlistFireflies[feature.properties.id] = layer;
      }

      // Attempt to split data to different layers
      // if (feature.properties.location == "P23") {
      //   // see - https://stackoverflow.com/questions/10953303/javascript-interpret-string-as-object-reference
      //   let tempStr = "p23GroupLayer";
      //   this[tempStr].addLayer(layer);
      //   //        this.p23GroupLayer.addLayer(layer);
      // }

      if (
        feature.properties &&
        feature.properties.location
        // &&
        // feature.properties.location === "P23"
      ) {
        //let tempStr = "P23";
        //let tempStr = `${feature.properties.location}`;

        // adds a separate layer specifically for location
        this[`${feature.properties.location}`].addLayer(layer);

        //        this.map.addLayer(this[`${feature.properties.location}`]);
      }

      // shift/transform the coordinate
      // https://gis.stackexchange.com/questions/85358/adding-offset-to-geojson-layer-in-leaflet
      // var coords = feature.geometry.coordinates;
      // //var lengthOfCoords = feature.geometry.coordinates.length;

      // // #REVIEW - need to change this for polyLine
      // coords[0] = coords[0] + 100;
      // coords[1] = coords[1] + 100;

      // // draw the marker again at new point
      // L.circleMarker(coords, geojsonMarkerOptions);

      // const objLatLng = {
      //   lat: feature.geometry.coordinates[0] + 100,
      //   lng: feature.geometry.coordinates[1] + 100,
      // };
      // //const point = Object.values(this.transform.transform(objLatLng));
      // const point = Object.values(objLatLng);
      // feature.geometry = {
      //   type: "Point",
      //   coordinates: point,
      // };
    };

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayer = (feature, latlng) => {
      return L.circleMarker(latlng);
    };

    // set style of firefly markers
    const fireflyStyle = (feature) => {
      let setFillColor;
      if (feature.properties.color) {
        switch (feature.properties.color) {
          case "green":
            setFillColor = "green";
            break;
          case "blue":
            setFillColor = "blue";
            break;
          case "amber":
            setFillColor = "yellow";
            break;
          case "red":
            setFillColor = "red";
            break;

          default:
            setFillColor = "green";
            break;
        }
      }

      return {
        radius: 6,
        fillColor: setFillColor,
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    };

    // -------------- add all locations as
    // Test draw layer by LOCATIONS
    // draw all locations to separate layers
    // filter to a specific location

    // map to
    // create separate arrays for each LOCATION............

    // determine unique properties array from geoJson
    //
    // https://stackoverflow.com/questions/56993304/extract-properties-and-unique-values-from-geojson-collection

    // console.log(
    //   "geoJSONMarkersData.features - uniqueProperties",
    //   geoJSONMarkersData.features
    // );
    let uniqueProperties = geoJSONMarkersData.features.reduce(
      (acc, { properties }) => {
        Object.entries(properties).forEach(([key, val]) => {
          acc[key] = acc[key] || new Set();
          acc[key].add(val);
        });

        return acc;
      },
      {}
    ); // uniqueProperties[key] returns a SET. Convert to array [...uniqueProperties[key]]

    // for (const key in uniqueProperties) {
    //   console.log("uniqueProperties", `${key} => ${[...uniqueProperties[key]]}`);
    // }

    //https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array

    // sort the locations so they list in order on the screen
    const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });

    let locations = [];
    this.locationGroupLayers = {};
    if (uniqueProperties["location"]) {
      locations = [...uniqueProperties["location"]].sort(sortAlphaNum);
      locations.forEach((value, idx) => {
        //console.log("locations[idx]", value);
        this[value] = L.featureGroup().addTo(this.map);
        this.locationGroupLayers[value] = this[value];
      });
    }

    // #REVIEW  - Issue is that with growing array location groups are not created on startup.
    // Need to make these with each update of the state geoJson

    // constrain to area
    const filterArea = (feature, layer) => {
      return feature.properties.area === this.state.map.area; // "DMLZ_Extraction";
    };

    // add firefly markers to the map
    this.geoJSONGroupLayer = L.geoJSON(geoJSONMarkersData, {
      pointToLayer: pointToLayer,
      onEachFeature: onEachFeatureFirefly,
      style: fireflyStyle,
      //coordsToLatLng: coordsToLatLng, // <<--- #REVIEW I still don't underestand what this does
      filter: filterArea,
    }).addTo(this.map);

    // ********************************************************************************
    //
    // Set and draw the marker points - map scaling points and origin/centre/max markers
    //
    // ********************************************************************************

    // setup scaling coord reference point markers
    const onEachFeatureMarker = (feature, layer) => {
      layer.bindPopup(
        "ref pt:" +
          JSON.stringify(feature.properties.index) +
          " => " +
          JSON.stringify(feature.geometry.coordinates)
      );
      layer.on("dragend", function (e) {
        const marker = e.target;
        const position = marker.getLatLng();
        console.log("xxx dragend refPt position", position);
        marker
          .setLatLng(position, { draggable: "true" })
          .bindPopup("" + position) // #REVIEW "" is hack as bindPopup will not accept just numbers
          .update();
      });
      layer.on("click", (e) => {
        // transform from pixels to points
        const transform = this.Transform(
          this.standardPtsUtm(),
          this.localPts()
        );
        const marker = e.target;
        const position = marker.getLatLng();
        console.log("xxx dragend refPt position", JSON.stringify(position));

        const objLatLng = transform.transform({
          lat: position.lat,
          lng: position.lng,
        });

        console.log(
          "xxx feature.properties",
          JSON.stringify(feature.properties)
        );

        const id = `ID:${feature.properties.id}`;

        // original geoJson coordinates
        const geoJsonPt = `E:${feature.properties.easting}, N:${feature.properties.northing}`;
        // image coordinates
        const imagePt = Object.values(position); //.reverse(); // map is y, x referenced. Reverse to display as x,y.
        // transformed image -> physical
        const coordPt = Object.values(objLatLng); //.reverse();

        marker
          .setLatLng(position, { draggable: "true" })
          .bindPopup(
            `<div>${id} </div>

            <div>GeoJ: ${geoJsonPt} </div>
            <div>Image: ${round(imagePt[0], 2)},${round(imagePt[1], 2)} </div>
            <div>Coord: ${round(coordPt[0], 2)},${round(coordPt[1], 2)}</div>`
          )
          .update();

        console.log(`xxx clicked - Ref Pt marker - UTM Coords:[${coordPt}]`);
      });
    };

    // add markers to map
    this.markerGroupLayer = L.geoJSON(
      this.localPtsGeoJson(this.markerPts({ width, height })),
      {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {
            //icon: greenIcon, // <--- #TODO FIX THIS!
            draggable: true,
          });
        },
        onEachFeature: onEachFeatureMarker,
      }
    ).addTo(this.map);

    // add  coord reference point markers to map

    this.refPtGroupLayer = L.geoJSON(this.localPtsGeoJson(this.localPts()), {
      pointToLayer: function (feature, latlng) {
        return L.marker(latlng, {
          //icon: greenIcon, // <--- #TODO FIX THIS!
          draggable: true,
        });
      },
      onEachFeature: onEachFeatureMarker,
    }).addTo(this.map);

    // AWESOME FONT IDEA
    // https://gis.stackexchange.com/questions/90017/change-default-leaflet-marker-color

    // ********************************************************************************
    //
    // Setup and draw the named area polygons
    //
    // ********************************************************************************

    // list of named area polygons
    this.layerlistNamedAreas = {};

    const onEachFeatureNamedAreas = (feature, layer) => {
      if (feature.properties && feature.properties.id) {
        this.layerlistNamedAreas[feature.properties.id] = layer;
      }
      layer.on("click", (e) => {
        //console.log("na click feature.properties.id", feature.properties.id);

        const id = feature.properties.id.split(":")[1]; // eg. from "DMLZ_Extraction:182736182736" -> "182736182736"
        const namedAreaPolygon = e.target;
        namedAreaPolygon.bindPopup(`<div>Named Area: ${id} </div>`);
      });
    };

    // check if geoJSONNamedAreas has any areas
    // if not add at least one!
    // #REVIEW/TODO - this default should come from the setting in db
    //
    const defaultNamedArea = {
      type: "Feature",
      properties: {
        id: "defaultNamedArea",
        name: "defaultNamedArea",
        parent: "parentNamedArea", // #REVIEW/TODO - pass in parent Named Area
        area: "DMLZ_Extraction",
        priority: 1,
        Firefly_List: "",
        active_color: "AMBER",
        active_state: "ON",
        button: [
          {
            clickable: true,
            icon: "seismic_0_button.png",
            priority: 1,
            title: "Seismic 0",
            alt: "Area A - Level 0",
            state: "off",
            color: "amber",

            // #REVIEW/TODO/WIP - yet to be implemented
            active: "true", // allows enable/disable of button column
            group: 0, // grouping of columns
            column: 0, // index for position of column - as different to priority
          },
          {
            clickable: true,
            icon: "seismic_123_button.png",
            priority: 2,
            title: "Seismic 123",
            alt: "Area A - Level 123",
            state: "off",
            color: "green",
            active: "true", // allows enable/disable of button column
            group: 0, // grouping of columns
            column: 0, // index for position of column - as different to priority
          },
          {
            clickable: true,
            icon: "travelway_button.png",
            priority: 3,
            title: "Travelway 1",
            alt: "Area A - Travelway 1",
            state: "off",
            color: "green",
            active: "true", // allows enable/disable of button column
            group: 0, // grouping of columns
            column: 0, // index for position of column - as different to priority
          },
          {
            clickable: true,
            icon: "travelway_button.png",
            priority: 4,
            title: "Travelway 2",
            alt: "Area A - Travelway 2",
            state: "on",
            color: "green",
            active: "true", // allows enable/disable of button column
            group: 0, // grouping of columns
            column: 0, // index for position of column - as different to priority
          },
        ],
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [737809.166, 9549066.886],
            [737703.561, 9549063.944],
            [737584.687, 9548925.63],
            [737646.288, 9548887.191],
            [737658.232, 9548897.419],
            [737726.042, 9548853.966],
            [737785.321, 9548838.814],
            [737837.514, 9548897.337],
            [737848.083, 9548938.28],
            [737750.6, 9549001.738],
            [737809.166, 9549066.886],
          ],
        ],
      },
    };

    // add feature 'default' for startup
    // disable. Used for debugging
    if (false) {
      geoJSONNamedAreas.features.push(defaultNamedArea);
      console.log(
        "this.editableLayers geoJSONNamedAreaGroupLayer  geoJSONNamedAreas",
        JSON.stringify(geoJSONNamedAreas)
      );
    }

    // add named area polygons to the map
    this.geoJSONNamedAreaGroupLayer = L.geoJSON(geoJSONNamedAreas, {
      style: this.geojsonPolygonStyle,
      onEachFeature: onEachFeatureNamedAreas,
    }).addTo(this.map);

    // update leaflet id to properties (used to delete.edit shapes in local state)
    this.geoJSONNamedAreaGroupLayer.eachLayer(function (layer) {
      layer.feature.properties._leaflet_id = layer._leaflet_id; //#TODO - change to L.Stamp()
    });

    //#TODO - change to L.Stamp()
    //     Also note that .getLayers() works for LayerGroup (and FeatureGroup and GeoJson), but not for L.Map.
    // Usage of "private" properties and methods like _layers or _leaflet_id or _latlng is discouraged.

    // add layers to the local maps state
    const geoJsonPixels = this.geoJSONNamedAreaGroupLayer.toGeoJSON();
    const geoJsonUtm = this.convertGeoJsonPixelsToUtm(geoJsonPixels);

    //console.log("qqq add layers to the local maps state geoJson", geoJsonUtm);
    this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
    this.setState({ thislocalMapState: geoJsonUtm });

    // push layer to back layer.bringToFront()
    this.geoJSONNamedAreaGroupLayer.bringToBack();

    // ********************************************************************************
    //
    // Setup the map layers into control groups and display controls
    //
    // ********************************************************************************

    // setup layer groups
    let baseLayers = { MineMap: base };
    // setup layer groups overlayers (see also https://leafletjs.com/reference-1.6.0.html#control-layers-addoverlay)
    let overLayers = {
      FireFly: this.geoJSONGroupLayer,
      Marker: this.markerGroupLayer,
      RefPt: this.refPtGroupLayer,
      Polygon: this.geoJSONNamedAreaGroupLayer,
    };

    // spread the objects to join the location layers to the overlay
    overLayers = { ...overLayers, ...this.locationGroupLayers };

    // define layer control
    var layerControl = L.control.layers(baseLayers, overLayers, {
      collapsed: false, //
    });

    // add it to the map
    layerControl.addTo(this.map);

    // // see - https://groups.google.com/forum/#!searchin/leaflet-js/control$20onadd/leaflet-js/rKMZX3PKFuI
    // // then remove the control container
    // layerControl._container.remove();

    // // append the control container to the other div
    // document
    //   .getElementById("custom-map-controls")
    //   .appendChild(layerControl.onAdd(this.map));

    // ********************************************************************************
    //
    // Set polylines joining the Fireflies
    //
    //
    // NOTE: this has to be done after the locationGroupLayers has been rendered
    // ...probably more likely need layer control defined before start adding groups
    //
    // #REVEW <--- reorder the code
    // ********************************************************************************

    // step through the list of locationGroupLayers
    this.polylineGroupLayers = {};

    for (const [key, value] of Object.entries(this.locationGroupLayers)) {
      // create an object with the posObject[position] = latlng coordinates
      let posObject = {};

      // loop each layer - #REVIEW - this could be labourious - perhaps only do if selected to display?
      // eslint-disable-next-line no-loop-func
      value.eachLayer(function (layer) {
        posObject[layer.feature.properties.position] = layer.getLatLng();
      });

      // sort the posObject by position order (i.e. object key) so line draws from position 1 -> n

      // see - https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
      const ordered = {};
      Object.keys(posObject)
        .sort()
        .forEach(function (key) {
          ordered[key] = posObject[key];
        });

      // plot a polyline using ordered array of values
      // create name object from layer name (i.e. key is the POSITION)
      const layerName = "poly_" + key;
      this[layerName] = L.polyline(Object.values(ordered)).addTo(this.map);

      // add this layer to layer group to add to control layer later
      this.polylineGroupLayers[layerName] = this[layerName];

      // add individual layer
      // see - https://gis.stackexchange.com/questions/161940/how-to-add-layers-and-update-layer-control-dynamically-leaflet
      // layerControl.addOverlay(this[layerName], layerName);
    }

    // add group to overlays by iterating over the object
    // https://stackoverflow.com/questions/55471295/adding-multiple-overlays-to-leaflets-layers-control
    for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
      layerControl.addOverlay(value, key);
    }

    // #REVIEW - setup ordering layers for all levels with toggle
    // setup layer control events
    // https://stackoverflow.com/questions/14103489/leaflet-layer-control-events
    this.map.on("overlayadd", onOverlayAdd);

    function onOverlayAdd(e) {
      // https://gis.stackexchange.com/questions/137061/how-to-change-layer-order-in-leaflet-js
      // push layer to back layer.bringToFront()

      if (e.name === "Polygon") {
        e.layer.bringToBack();
      }
      //console.log("onOverlayAdd e", e);
    }

    // ********************************************************************************
    //
    // Setup polygons drawn on the map
    //
    //
    // NOTE: uses leaflet.draw leaflet-draw
    //
    // ********************************************************************************

    // Truncate value based on number of decimals
    var _round = function (num, len) {
      return Math.round(num * Math.pow(10, len)) / Math.pow(10, len);
    };
    // Helper method to format LatLng object (x.xxxxxx, y.yyyyyy)
    var strLatLng = function (latlng) {
      return "(" + _round(latlng.lat, 6) + ", " + _round(latlng.lng, 6) + ")";
    };

    // Generate popup content based on layer type
    // - Returns HTML string, or null if unknown object
    var getPopupContent = function (layer) {
      // Marker - add lat/long
      if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
        return strLatLng(layer.getLatLng());
        // Circle - lat/long, radius
      } else if (layer instanceof L.Circle) {
        var center = layer.getLatLng(),
          radius = layer.getRadius();
        return (
          "Center: " +
          strLatLng(center) +
          "<br />" +
          "Radius: " +
          _round(radius, 2) +
          " m"
        );
        // Rectangle/Polygon - area
      } else if (layer instanceof L.Polygon) {
        var latlngs = layer._defaultShape
          ? layer._defaultShape()
          : layer.getLatLngs();

        // get the pixel coordinates of the shape
        var coords = latlngs.map(function (point) {
          return [point.lng, point.lat]; // #REVIEW - reverse display lat, long = YX
        });

        return (
          "Coords: " +
          JSON.stringify(coords) +
          "</br> _leaflet_id: " +
          L.stamp(layer)
        );
        // Polyline - distance
      } else if (layer instanceof L.Polyline) {
        var latlngs = layer._defaultShape
            ? layer._defaultShape()
            : layer.getLatLngs(),
          distance = 0;
        if (latlngs.length < 2) {
          return "Distance: N/A";
        } else {
          for (var i = 0; i < latlngs.length - 1; i++) {
            distance += latlngs[i].distanceTo(latlngs[i + 1]);
          }
          return "Distance: " + _round(distance, 2) + " m";
        }
      }
      return null;
    };

    // create the editable layers to drawn on

    // #REVIEW - the use of VAR here is an issue.
    // this.editableLayers is only accessible to componentDidMount, not componentDidUpdate
    // needs to go to STATE
    //
    this.editableLayers = L.featureGroup().addTo(this.map);
    //    this.map.addLayer(this.editableLayers);

    // console.log(
    //   "hasLayer this.map.hasLayer(this.editableLayers)",
    //   this.map.hasLayer(this.editableLayers)
    // );

    const drawPluginOptions = {
      position: "topleft",
      draw: {
        polygon: {
          title: "Draw a named area polygon",
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: "#e1e100", // Color the shape will turn when intersects
            message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
          },
          shapeOptions: {
            color: "green",
            fillColor: "green",
            fillOpacity: 0.7,
            opacity: 1,
          },
        },
        polyline: false, // disable toolbar item by setting it to false
        circle: false, // removet the circle marker
        rectangle: {
          title: "Draw a named area rectangle",
          shapeOptions: {
            color: "green",
            fillColor: "green",
            fillOpacity: 0.7,
            opacity: 1,
          },
        },
        marker: false, // removes the marker point
        circlemarker: false, // removed the circlemarker
      },
      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        edit: {
          // https://github.com/Leaflet/Leaflet.draw/issues/295
          // changes colour when in edit mode
          // this property shouldn't be needed
          selectedPathOptions: {
            // this property should be one level up
            color: "#000",
            fillColor: "#000",
          },
        },
        remove: false, // disables the trash can remove function
      },
    };

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    this.drawControl = new L.Control.Draw(drawPluginOptions);
    this.map.addControl(this.drawControl);

    // add a 2nd control - this couold be for setting colors
    // see - https://gis.stackexchange.com/questions/181092/is-it-possible-to-edit-draw-panel-in-leaflet-draw
    const drawControl2options = {
      position: "bottomleft",
      draw: {
        polygon: {
          title: "Draw a sexy polygon!",
          allowIntersection: false,
          drawError: {
            color: "#b00b00",
            timeout: 1000,
          },
          shapeOptions: {
            color: "#bada55",
          },
          showArea: true,
        },
        polyline: {
          metric: false,
        },
        circle: {
          shapeOptions: {
            color: "#662d91",
          },
        },
      },
      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        remove: true,
      },
    };

    // disable draw control #2
    if (false) {
      this.drawControl2 = new L.Control.Draw(drawControl2options);
      this.map.addControl(this.drawControl2);
    }

    // position in center
    // https://stackoverflow.com/questions/23762176/leaflet-custom-control-position-center/49036235#49036235

    // set popup event on created object
    // view-source:http://leaflet.github.io/Leaflet.draw/docs/examples/popup.html

    // get and set style
    // layer.setStyle({
    //   weight: 5,
    //   color: '#666',
    //   dashArray: '',
    //   fillOpacity: 0,
    //   opacity: 0.9,
    // });

    // layer.options

    // Object created - bind popup to layer, add to feature group
    // draw:created
    this.map.on(L.Draw.Event.CREATED, (event) => {
      console.log("xxx draw:created");

      // create layer
      let layer = event.layer;
      // Setup layer for geoJSON
      // Intialize layer.feature
      let feature = (layer.feature = layer.feature || {});
      // Intialize feature.type
      feature.type = feature.type || "Feature";
      // Intialize feature.properties
      let props = (feature.properties = feature.properties || {});

      // create default geoJson object properties for named area

      // #TODO/REVIEW - give shape an id. Must be unique so give timecode from epoch
      const namedAreaTimestamp = new Date().getTime(); // timestamp;

      props.id = this.state.map.area + ":" + namedAreaTimestamp;
      props._leaflet_id = L.stamp(layer); // leaflet id
      props.name = `${namedAreaTimestamp}`;
      props.parent = "parentNamedArea";
      props.area = this.state.map.area;
      props.priority = 1;
      props.Firefly_List = [];
      props.type = "Polygon"; // #TODO - get type from shape created? If only support Polygon restrict entry.
      props.default_state = "off";

      props.default_color = layer.options.fillColor; // set fillColor as active color in geoJSON;
      props.button = [
        // #TODO - getDefaultButtonSet() - ATM just do 4 buttons for testing
        {
          clickable: true,
          icon: "seismic_0_button.png",
          priority: 1,
          title: "Seismic 0",
          alt: "Area A - Level 0",
          state: "off",
          color: "green",
          // #REVIEW/TODO/WIP - yet to be implemented
          active: "true", // allows enable/disable of button column
          group: 0, // grouping of columns
          column: 0, // index for position of column - as different to priority
        },
        {
          clickable: true,
          icon: "seismic_123_button.png",
          priority: 2,
          title: "Seismic 123",
          alt: "Area A - Level 123",
          state: "off",
          color: "green",
          active: "true", // allows enable/disable of button column
          group: 0, // grouping of columns
          column: 0, // index for position of column - as different to priority
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 3,
          title: "Travelway 1",
          alt: "Area A - Travelway 1",
          state: "off",
          color: "green",
          active: "true", // allows enable/disable of button column
          group: 0, // grouping of columns
          column: 0, // index for position of column - as different to priority
        },
        {
          clickable: true,
          icon: "travelway_button.png",
          priority: 4,
          title: "Travelway 2",
          alt: "Area A - Travelway 2",
          state: "on",
          color: "green",
          active: "true", // allows enable/disable of button column
          group: 0, // grouping of columns
          column: 0, // index for position of column - as different to priority
        },
      ];

      // 'status' - used to track the status of the object on maps and in lists
      // options:
      // drawn - drawn on the localmap. i.e. not a named area source from the messages/db
      // mqtt - defined by the messages/db
      // [proposed] deleted - marked for deletion
      props.status = "drawn";

      // #TODO -
      // getDefaultButtonSet () => {
      //  get # of buttons in named area group based on 'parent'
      //  append default button set
      //  }

      // DISABLE - only used for debugging
      if (false) {
        let content = getPopupContent(layer);
        if (content !== null) {
          layer.bindPopup(content);
        }
      }

      console.log("qqq props created", props);
      // add to editable layes
      this.editableLayers.addLayer(layer); // Note - .style.display = 'block' for default

      // NO - don't do this unless the area is saved!
      // ..........
      // add to layer list
      // this.layerlistNamedAreas[props.id] = layer;

      // update the localMapState
      const geoJsonPixels = this.editableLayers.toGeoJSON();
      const geoJsonUtm = this.convertGeoJsonPixelsToUtm(geoJsonPixels);

      this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
      this.setState({ thislocalMapState: geoJsonUtm });

      console.log(
        "xxx draw:created this.props.localMapState",
        this.props.localMapState
      );
      console.log(
        "xxx draw:created thislocalMapState",
        this.state.thislocalMapState
      );
    });

    // Object(s) edited - update popups
    // draw:edited
    this.map.on(L.Draw.Event.EDITED, (event) => {
      var layers = event.layers,
        content = null;
      layers.eachLayer(function (layer) {
        console.log("qqq this.editableLayers edit " + L.stamp(layer));

        content = getPopupContent(layer);
        if (content !== null) {
          layer.setPopupContent(content);
        }
      });

      // #REVIEW / TODO - pass in parentId
      // mark the form as dirty
      const parentId = "parentNamedArea";
      this.props.namedAreasSetIsDirty(parentId);

      // #REVIEW / TODO - pass in parentId
      // move this to a common 'update()' function

      // update the localMapState
      const geoJsonPixels = this.editableLayers.toGeoJSON();
      const geoJsonUtm = this.convertGeoJsonPixelsToUtm(geoJsonPixels);

      this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
      this.setState({ thislocalMapState: geoJsonUtm });
    });

    // Named Areas edited - update state
    // draw:editstop
    this.map.on(L.Draw.Event.EDITSTOP, (event) => {
      console.log("xxx draw:editstop ", event);
      //const { layer } = event;
      //console.log("xxx EDITSTOP click " + L.stamp(layer));
      //console.log("xxx DRAWSEDITSTOPTOP - _leaflet_id " + layer._leaflet_id);
      // console.log("xxx this.props.localMapState", this.props.localMapState);
      // console.log("xxx thislocalMapState", this.state.thislocalMapState);
    });

    // Named Areas edited - update state
    // draw:drawstop
    this.map.on(L.Draw.Event.DRAWSTOP, (event) => {
      console.log("xxx draw:drawstop ", event);
    });

    // Named Areas edited - update state
    // draw:deleted
    this.map.on(L.Draw.Event.DELETED, (event) => {
      console.log("xxx draw:deleted ", event);

      // return list of deleted layers...
      const editedlayers = event.layers;
      console.log("xxx draw:deleted layers", editedlayers);

      // update the localMapState
      const geoJsonPixels = this.editableLayers.toGeoJSON();
      const geoJsonUtm = this.convertGeoJsonPixelsToUtm(geoJsonPixels);

      this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
      this.setState({ thislocalMapState: geoJsonUtm });

      // const {
      //   namedAreas: { features },
      // } = this.props.localMapState;

      // // make a new list without the deleted layers
      // let newNamedAreas = [];
      // console.log("xxx newNamedAreas", newNamedAreas);
      // editedlayers.eachLayer((layer) => {
      //   features.forEach((feature, index, arr) => {
      //     if (feature.properties._leaflet_id !== layer._leaflet_id) {
      //       newNamedAreas.push(feature);
      //     }
      //   });
      // });

      // // update state as geoJson
      // this.props.UpdateLocalMap({
      //   namedAreas: {
      //     type: "FeatureCollection",
      //     features: newNamedAreas,
      //   },
      // });
      // this.setState({
      //   thislocalMapState: {
      //     type: "FeatureCollection",
      //     features: newNamedAreas,
      //   },
      // });
    });

    // yet another this.editableLayers on click event to
    // output the maps state for debugging
    // this.editableLayers.on("click", (event) => {
    //   console.log("xxx this.props.localMapState", this.props.localMapState);
    //   console.log("xxx thislocalMapState", this.state.thislocalMapState);
    // });

    // https://stackoverflow.com/questions/40088421/returning-clicked-layer-leaflet
    // see - https://jsfiddle.net/3v7hd2vx/108/
    // attached to this.editableLayers group

    // DISABLE THIS ONCLICK ACTION - but leave in code for debugging
    if (false) {
      this.editableLayers.on("click", (event) => {
        const { layer } = event;

        // console.log("xxx this.editableLayers click " + L.stamp(layer));
        // console.log("xxx this.editableLayers - _leaflet_id " + layer._leaflet_id);

        // Note 'layer.edited' only true after handles moved
        //&& layer.edited
        if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
          // rectangle, polygon, circle
          const colors = ["green", "yellow", "blue", "red"];
          const currentColor = layer.options.fillColor;
          const index = colors.findIndex((x) => x === currentColor);
          let newColor;
          index + 1 > colors.length - 1
            ? (newColor = colors[0])
            : (newColor = colors[index + 1]);

          layer.setStyle({ color: newColor, fillColor: newColor });
          layer.feature.properties.color = layer.options.fillColor; // set fillColor as active color in geoJSON

          // console.log(
          //   "xxx this.editableLayers - UTM Coords:",
          //   JSON.stringify(convertPixelsToUtm(event))
          // );
        }
      });
    }

    const convertPixelsToUtm = (event) => {
      const latlngs = event.layer._latlngs[0]; // <----------only one lat longs array????? #REVIEW - could be more if multi-Polygo?
      // transform from pixels to points
      const transform = this.Transform(this.standardPtsUtm(), this.localPts());
      // get the pixel coordinates of the shape
      const utmPoints = latlngs.map(function (point) {
        const objUtm = transform.transform({
          lat: point.lat,
          lng: point.lng,
        });
        return [objUtm.lat, objUtm.lng];
      });
      return utmPoints;
    };

    // add existing layer to editable layer so can edit existing/loaded polygons
    //let that=this; // pass editable layer into function
    this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
      console.log(
        "Adding mqtt Named Area to this.editableLayers -> ",
        layer._leaflet_id
      ); // KEEP AS PERMANENT INDICATOR THAT MAP GROUPS ARE UPDATING

      layer.getElement().style.display = "block"; // this resets any hidden layer actions
      this.editableLayers.addLayer(layer);
    });

    // --- LAYER INFORMATION TO OUTSIDE MAP

    // check what layers are drawn.......
    this.editableLayers.eachLayer(function (layer) {
      //console.log("this.editableLayers layer", layer);
      // Pass this information through Redux to outside
    });

    // --- LAYER CONTROL TO SEPARATE HTML

    // see - https://groups.google.com/forum/#!searchin/leaflet-js/control$20onadd/leaflet-js/rKMZX3PKFuI
    // then remove the control container
    layerControl._container.remove();

    // append the control container to the other div
    if (false) {
      // disable this for the moment
      document
        .getElementById("custom-map-controls")
        .appendChild(layerControl.onAdd(this.map));
    }

    // map drawn!!!!!!!!
  };

  convertGeoJsonPixelsToUtm = (geoJsonPixels) => {
    // Output coordinates of shape in UTM
    // returns geoJSON

    //console.log("qqq geoJsonPixels", geoJsonPixels);
    // convert pixel coordinates in geoJson Pixels to utm

    // deep clone - to stop geometry being converted on raw *utm data
    geoJsonPixels = JSON.parse(JSON.stringify(geoJsonPixels));

    const geoJsonUtm = this.transformGeoJsonPixelsToUtm(geoJsonPixels);

    //console.log("qqq geoJsonUtm transformGeoJsonPixelsToUtm", geoJsonUtm);

    // #TODO - NOTE: 'drawnItemsToJSON' below strips out the properties!
    // - needs to be updated to preserve PROPERTIES
    // - do not use until fixed

    // preserve styling of polygon in geoJson
    //
    //geoJson = this.drawnItemsToJSON(this.editableLayers);
    return geoJsonUtm;
  };

  transformGeoJsonPixelsToUtm = (geoJson) => {
    const transform = this.Transform(this.standardPtsUtm(), this.localPts());
    const geoJsonTransform = [];

    geoJson.features.map((value, idx) => {
      let newFeature = value;
      // transform the point from pixels to pixels
      const arrayLatLng = newFeature.geometry.coordinates.slice();
      let arrayPoints = [];
      const shapeType = newFeature.geometry.type;
      switch (shapeType.toLowerCase()) {
        case "point":
          // convert to LatLng, transform, then convert back to array
          arrayPoints = Object.values(
            transform.transform({
              lat: arrayLatLng[1], // <-- **NOTE: lat & lng are swapped! **
              lng: arrayLatLng[0],
            })
          ).reverse();
          break;
        case "polygon":
          // iterate the geojons coord array
          arrayLatLng.forEach((value, i, arr) => {
            arrayPoints.push([]);
            value.forEach((value, j) => {
              // convert to LatLng, transform, then convert back to array
              let arrayPoint = Object.values(
                transform.transform({
                  lat: value[1], // <-- **NOTE: lat & lng are swapped! **
                  lng: value[0],
                })
              ).reverse();

              // accumulate results
              arrayPoints[i][j] = arrayPoint;
            });
          });
          break;
        default:
          break;
      }
      // update geometry
      newFeature.geometry.coordinates = arrayPoints;
      geoJsonTransform.push(newFeature);
    });

    return {
      type: "FeatureCollection",
      features: geoJsonTransform,
    };
  };

  // transformGeoJsonUtmToPixels transforms geoJson based on transform passed
  // Note:
  // * only supports points and polygons
  // * only supports single polygon shapes. i.e. not multi shapes or shapes with holes
  //
  transformGeoJsonUtmToPixels = (geoJson) => {
    const transform = this.Transform(this.localPts(), this.standardPtsUtm());

    const geoJsonTransform = [];
    geoJson.features.map((value, idx) => {
      let newFeature = value;
      // transform the point from latlng to pixels
      const arrayLatLng = newFeature.geometry.coordinates.slice();

      // check if rx an un positioned marker i.e. [0,0]
      const isAllZero = arrayLatLng.every((item) => item === 0);

      // if (isAllZero) {
      //   console.log(
      //     "isAllZero feature",
      //     newFeature.properties.id,
      //     JSON.stringify(newFeature)
      //   );
      // }
      // check if map has the holding pen setup

      // #REVIEW/TODO #WIP
      // make sure there is a default pen point
      let randomHoldingPenCoordinate = [0, 0];
      // why is this undefined on startup? img is defined ok?!
      // if (this.holdingPen !== undefined) {
      //   const hasHoldingPen = this.map.hasLayer(this.holdingPen);
      //   if (isAllZero && hasHoldingPen) {
      //     randomHoldingPenCoordinate = this.randomPointInPoly(this.holdingPen)
      //       .geometry.coordinates;
      //     console.log(
      //       "randomholdingPenCoordinate",
      //       newFeature.properties.id,
      //       randomHoldingPenCoordinate
      //     );
      //   }
      // }

      // if (newFeature.properties.id === "DMLZ_Extraction:P14:21") {
      //   console.log("newFeature 21", newFeature);
      // }
      // if (newFeature.properties.id === "DMLZ_Extraction:P14:22") {
      //   console.log("newFeature 22", newFeature);
      // }
      let arrayPoints = [];
      const shapeType = newFeature.geometry.type;
      switch (shapeType.toLowerCase()) {
        case "point":
          // convert to LatLng, transform, then convert back to array
          if (!isAllZero) {
            arrayPoints = Object.values(
              transform.transform({
                lat: arrayLatLng[0], // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                lng: arrayLatLng[1],
              })
            );
          } else {
            arrayPoints = randomHoldingPenCoordinate;
          }

          break;
        case "polygon":
          // iterate the geojons coord array
          arrayLatLng.forEach((value, i, arr) => {
            arrayPoints.push([]);
            value.forEach((value, j) => {
              // convert to LatLng, transform, then convert back to array
              let arrayPoint;
              if (!isAllZero) {
                arrayPoint = Object.values(
                  transform.transform({
                    lat: value[0], // <-- **NOTE: lat & lng are NOT swapped! (compare with transformGeoJsonPixelsToUtm) **
                    lng: value[1],
                  })
                );
              } else {
                arrayPoint = randomHoldingPenCoordinate;
              }

              // accumulate results
              arrayPoints[i][j] = arrayPoint;
            });
          });
          break;
        default:
          break;
      }

      // update geometry
      newFeature.geometry.coordinates = arrayPoints;
      geoJsonTransform.push(newFeature);
    });

    return {
      type: "FeatureCollection",
      features: geoJsonTransform,
    };
  };

  // find a random point in the holding pen
  randomPointInPoly = function (polygon) {
    var bounds = polygon.getBounds();
    var x_min = bounds.getEast();
    var x_max = bounds.getWest();
    var y_min = bounds.getSouth();
    var y_max = bounds.getNorth();

    var lat = y_min + Math.random() * (y_max - y_min);
    var lng = x_min + Math.random() * (x_max - x_min);

    var point = turf.point([lng, lat]);
    var poly = polygon.toGeoJSON();
    var inside = turf.inside(point, poly);

    if (inside) {
      return point;
    } else {
      return this.randomPointInPoly(polygon);
    }
  };

  componentDidMount() {
    const { render } = this.props;
    const mapId = "map-" + render;

    // tile URL for base layer
    const url = process.env.PUBLIC_URL + "/images/test_extraction.png"; // #WIP - this is not used at the moment.

    const { geoJSONMarkersDataUtm, geoJSONNamedAreasUtm } = this.props;

    let geoJSONMarkersData;

    // #REVIEW - issue here is that the component mounts before the mqtt messages have populated the objecet -> redux -> component etc.
    // need to ensure .features exists to transform...

    // update fireflies
    if (
      typeof geoJSONMarkersDataUtm.features !== "undefined" &&
      geoJSONMarkersDataUtm.features.length
    ) {
      // #REVIEW - this now happens in WebWorker reducer for fireflies
      // deep clone - to stop geometry being converted on raw *utm data
      //geoJSONMarkersData = JSON.parse(JSON.stringify(geoJSONMarkersDataUtm));
      //geoJSONMarkersData = this.transformGeoJsonUtmToPixels(geoJSONMarkersData);

      geoJSONMarkersData = geoJSONMarkersDataUtm;
    } else {
      // add an empty geoJson featurecollection
      geoJSONMarkersData = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // update named area
    let geoJSONNamedAreas;

    if (
      typeof geoJSONNamedAreasUtm.features !== "undefined" &&
      geoJSONNamedAreasUtm.features.length
    ) {
      // deep clone - to stop geometry being converted on raw *utm data
      geoJSONNamedAreas = JSON.parse(JSON.stringify(geoJSONNamedAreasUtm));

      geoJSONNamedAreas = this.transformGeoJsonUtmToPixels(geoJSONNamedAreas);
    } else {
      // add an empty geoJson featurecollection
      geoJSONNamedAreas = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // draw the map
    this.drawTheMap(mapId, url, geoJSONMarkersData, geoJSONNamedAreas);

    // after document is rendered move the controls to another parent
    // see - https://gis.stackexchange.com/questions/186131/placing-controls-outside-map-container-with-leaflet

    // var newParent = document.getElementById("custom-map-controls");
    // var oldParent = document.getElementsByClassName(
    //   "leaflet-top leaflet-right"
    // );

    // while (oldParent[0].childNodes.length > 0) {
    //   newParent.appendChild(oldParent[0].childNodes[0]);
    // }

    // _alternative tech
    //
    // 'modern way'
    // newParent.append(...oldParent.childNodes);
    // https://stackoverflow.com/questions/20910147/how-to-move-all-html-element-children-to-another-parent-using-javascript

    //newParent.append(...oldParent.childNodes);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("this.state.heartBeat", this.state.heartBeat);
    // console.log("prevState.heartBeat", prevState.heartBeat);
    // console.log(" heartBeat   this.map !== undefined", this.map !== undefined);

    // const heartBeatChanged =
    //   this.state.heartBeat !== prevState.heartBeat && this.map !== undefined
    //     ? true
    //     : false;

    //console.log(" heartBeatChanged heartBeat - #WIP", heartBeatChanged);

    // respond to checkbox settings
    // toggle display of marker group
    if (this.markerGroupLayer) {
      if (this.props.parentState.isCheckboxMarker) {
        this.map.addLayer(this.markerGroupLayer);
      } else {
        this.map.removeLayer(this.markerGroupLayer);
      }
    }

    // toggle display of reference marker group
    if (this.refPtGroupLayer) {
      if (this.props.parentState.isCheckboxRefMarker) {
        this.map.addLayer(this.refPtGroupLayer);
      } else {
        this.map.removeLayer(this.refPtGroupLayer);
      }
    }

    // TagUnknownMarker
    // TagHardHat
    // TagTruckPickupMarker
    // TagAmbulanceMarker
    // TagLightVehicleMarker
    // TagLoaderMarker
    // TagTruckMarker
    // TagJumboMarker

    // toggle display of the tag personnel marker
    if (this.refPtGroupLayer) {
      console.log("onClickCheckbox parentState ", this.props.parentState);
      const iconSelection = this.props.parentState.iconSelection;
      if (iconSelection !== "") {
        this.refPtGroupLayer.eachLayer((layer) => {
          layer.setIcon(
            makeIcon(
              iconSelection,
              { stroke: "green", fill: "orange", text: "" },
              1
            )
          );
        });
        this.map.addLayer(this.refPtGroupLayer);
      } else {
        this.map.removeLayer(this.refPtGroupLayer);
      }
    }

    //  ---------------------------------------

    const parentId = "parentNamedArea";

    // #REVIEW / TODO - move this filter to a common area
    const isParentDirty =
      this.props?.isDirty?.namedArea.filter(
        (namedArea) => namedArea === parentId
      ).length > 0;

    // ---------------------------------------------------------------
    // remove layers queued for deletion (via named areas UI)

    // This processes any named areas which have been 'deleted' when an update to the named area is performed.
    // This ensures immediate update to the local map so user get feedback of the action.
    // Otherwise it is necessary to wait for the mqtt send/receive process to update the map, and the
    // user is left wondering whether their request has been submitted.

    // get array of named_areas to delete
    // e.g. [{ area: "DMLZ_Extraction", id: "DMLZ_Extraction:1594276143541" },...]

    //#REVIEW/TODO - merge this with namedAreaHideSelection
    // INSTEAD OF DELETING JUST HIDE THE LEVEL
    // i.e. HIDDEN = DELETED????????????

    const namedAreaDeleteSelections = this.props.namedAreaDeleteSelections;

    let namedAreaDeleted = false; // flag to make the map update quicker after something deleted
    namedAreaDeleteSelections.forEach((selection, idx) => {
      // find the selection by id, by checking every layer in editableLayers

      this.editableLayers.eachLayer((layer) => {
        if (layer.feature.properties.id === selection.id) {
          console.log("----> namedAreaDeleteSelections remove", selection.id);

          // delete from editableLayers, when named areas area updated below the 'UpdateLocalMap' will remove this item
          // and consequently from the named area list (as this populates via redux)
          this.editableLayers.removeLayer(layer); // <----------- this is the most important bit

          // delete from
          this.props.namedAreaClearDeleteSelections(selection);
          namedAreaDeleted = true;
        }
      });
    });

    // ---------------------------------------------------------------

    // check if raw data has changed
    // update fireflies
    // if
    // * not empty
    // _and_
    // * props have changed

    // #REVIEW/TODO - make a function out of this ....
    // incoming objects (current and prevProps) are different even though JSON content is the same.
    // compare the strings to stop unnecessary updates
    const prevGeoJSONMarkersDataUtm = JSON.stringify(
      prevProps.geoJSONMarkersDataUtm
    );
    const newGeoJSONMarkersDataUtm = JSON.stringify(
      this.props.geoJSONMarkersDataUtm
    );

    let isChangedgeoJSONMarkerUtm = false;

    // if each object has content
    if (
      !_isEmpty(prevGeoJSONMarkersDataUtm) &&
      !_isEmpty(newGeoJSONMarkersDataUtm)
    ) {
      // are they changed?
      isChangedgeoJSONMarkerUtm =
        prevGeoJSONMarkersDataUtm !== newGeoJSONMarkersDataUtm ? true : false;
    }

    if (
      //#WIP - disabled to make the update happen periodically so can test hide/delete options
      //      isChangedgeoJSONMarkerUtm

      _isEmpty(this.props.geoJSONMarkersDataUtm) !== true &&
      this.props.geoJSONMarkersDataUtm !== prevProps.geoJSONMarkersDataUtm
    ) {
      // console.log(
      //   "map componentDidUpdate this.props.geoJSONMarkersDataUtm",
      //   this.props.geoJSONMarkersDataUtm
      // );
      // deep clone - to stop geometry being converted on raw *utm data
      let geoJSONMarkersData = JSON.parse(
        JSON.stringify(this.props.geoJSONMarkersDataUtm)
      );

      // #REVIEW/TODO #WIP
      // check for [0,0] or out of rage points and move them to the pen
      //  const transform = this.Transform(this.localPts(), this.standardPtsUtm());
      // const geoJsonTransform = [];

      // #WIP - test for out of range too!
      geoJSONMarkersData.features.map((value, idx) => {
        const arrayLatLng = value.geometry.coordinates.slice();
        // check if rx an un positioned marker i.e. [0,0]
        const isAllZero = arrayLatLng.every((item) => item === 0);
        if (isAllZero) {
          // console.log(
          //   "Found FF isAllZero value.properties.id -> ",
          //   value.properties.id
          // );

          // update FF value.properties.id with new coordinate in the pen
          let randomHoldingPenCoordinate;
          if (this.holdingPen !== undefined) {
            const hasHoldingPen = this.map.hasLayer(this.holdingPen);
            if (isAllZero && hasHoldingPen) {
              randomHoldingPenCoordinate = this.randomPointInPoly(
                this.holdingPen
              );
              // console.log(
              //   "isAllZero randomHoldingPenCoordinate",
              //   randomHoldingPenCoordinate
              // );

              // change the value properties coordinates with new holding pen point
              //console.log("isAllZero value", JSON.stringify(value));
              let newValue = JSON.parse(JSON.stringify(value));
              newValue.geometry = randomHoldingPenCoordinate.geometry;

              // convert to Utm values
              const newPointUTtm = this.convertGeoJsonPixelsToUtm({
                type: "FeatureCollection",
                features: [newValue],
              }).features[0].geometry.coordinates.slice();
              // #REVIEW/TODo #FIX!!!!!!!!!!!! - should filter to find the id, ATM just grab first one (and only) one which comes back

              //console.log("isAllZero newPointUTtm", newPointUTtm);

              // send via mqtt for change
              // #WIP....send FF update within map

              const changeTopic = `firefly/${newValue.properties.id}/change`;

              const changeMsg = {
                id: newValue.properties.id,
                mac: newValue.properties.mac,
                utm_zone_number: 53,
                utm_zone_letter: "M",
                Z: 0,
                utm: [newPointUTtm[0], newPointUTtm[1]],
                token: messageToken(),
              };

              //console.log("isAllZero changeFF", changeMsg);

              // #WIP - the issue here is this sends out lots of messages until the changes is made...flooding the system.
              // need to log the send, wait for an ack, clear it.
              // disabled pending implement ack management
              if (false) {
                this.props.mqttPublish({
                  topic: changeTopic,
                  qos: 0,
                  message: changeMsg,
                  retained: false,
                });
              }
            }
          }
        }
      });

      // transform the coordinates to pixel image references

      // #REVIEW - this now happens in WebWorker reducer for fireflies
      //geoJSONMarkersData = this.transformGeoJsonUtmToPixels(geoJSONMarkersData);

      // update markers for geoJSON
      this.updateFireflyMarkersGeoJSON(this.map, geoJSONMarkersData);
    }

    // ****************************************************************************************
    //
    // update named area
    //
    // ****************************************************************************************

    // update from source data if
    // * not empty
    // * props have changed
    // _or_
    // * named areas have been locally deleted

    // WIP HERE - previously allowed update if (OR) namedAreaDeleted = true even if no changes to data to stop redraw of namedAreas <------
    //     ||  namedAreaDeleted
    // ...working though?

    // #REVIEW/TODO - make a function out of this

    // incoming objects (current and prevProps) are different even though JSON content is the same.
    // compare the strings to stop unnecessary updates
    const prevGeoJSONNamedAreasUtm = JSON.stringify(
      prevProps.geoJSONNamedAreasUtm
    );
    const newGeoJSONNamedAreasUtm = JSON.stringify(
      this.props.geoJSONNamedAreasUtm
    );

    let isChangedgeoJSONNamedAreasUtm = false;

    // if each object has content
    if (
      !_isEmpty(prevGeoJSONNamedAreasUtm) &&
      !_isEmpty(newGeoJSONNamedAreasUtm)
    ) {
      // are they changed?
      isChangedgeoJSONNamedAreasUtm =
        prevGeoJSONNamedAreasUtm !== newGeoJSONNamedAreasUtm ? true : false;
    }

    if (
      // isChangedgeoJSONNamedAreasUtm ||
      // namedAreaDeleted
      (!_isEmpty(this.props.geoJSONNamedAreasUtm) &&
        this.props.geoJSONNamedAreasUtm !== prevProps.geoJSONNamedAreasUtm) ||
      namedAreaDeleted
      // ||      heartBeatChanged
    ) {
      // console.log("componentDidUpdate namedAreaHiddenSelections - updating!");

      // #WIP - check if localMapState and incoming data differ
      // with view to filtering localMapState data for delete and hidden namedAreas

      // deep clone - to stop geometry being converted on raw *utm data
      let geoJSONNamedAreas = JSON.parse(
        JSON.stringify(this.props.geoJSONNamedAreasUtm)
      );

      // transform the coordinates to pixel image references
      geoJSONNamedAreas = this.transformGeoJsonUtmToPixels(geoJSONNamedAreas);

      //#WIP - merge geoJSONNamedAreas and localMapState, giving priority to geoJSONNamedAreas if propertie.id is same
      //i.e. get geoJSONNamedAreas and import all

      const local = this.props.localMapState?.features;
      const mqtt = geoJSONNamedAreas?.features;

      let localIds = [];
      let mqttIds = [];

      if (local) {
        local.forEach((item, idx) => {
          localIds[item.properties.id] = idx;
        });
      }
      //      console.log("componentDidUpdate local", local);
      if (mqtt) {
        mqtt.forEach((item, idx) => {
          mqttIds[item.properties.id] = idx;
        });
      }
      //      console.log("componentDidUpdate mqtt", mqttIds);

      // console.log(
      //   "map componentDidUpdate geoJSONNamedAreas",
      //   JSON.stringify(geoJSONNamedAreas)
      // );

      // console.log(
      //   "map componentDidUpdate this.props.localMapState",
      //   this.props.localMapState
      // );

      // if the parent named area is 'dirty' i.e. being edited
      // then don't update the named areas
      // with new mqtt content
      if (!isParentDirty) {
        // update markers for geoJSON
        this.updateNamedAreasGeoJSON(this.map, geoJSONNamedAreas);
      }

      // update localMapState with all objects in the editable layer
      if (this.map.hasLayer(this.editableLayers)) {
        // add geoJSONNamedAreaGroupLayer layer to editable layer so can edit existing/loaded polygons
        this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
          // console.log(
          //   "Adding mqtt Named Area to this.editableLayers -> ",
          //   layer._leaflet_id
          // ); // KEEP AS PERMANENT INDICATOR THAT MAP GROUPS ARE UPDATING
          this.editableLayers.addLayer(layer);
        });

        const geoJsonPixels = this.editableLayers.toGeoJSON();

        //#REVEW/TODO - this should only happen if the shape has changed otherwise use the coordinates in the features.properties.coordinatesUtm
        // ----->
        const geoJsonUtm = this.convertGeoJsonPixelsToUtm(geoJsonPixels);
        // <-----

        this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
        this.setState({ thislocalMapState: geoJsonUtm });

        // console.log(
        //   "map componentDidUpdate this.props.localMapState - after update",
        //   JSON.stringify(geoJsonUtm)
        // );

        const geo = geoJsonUtm?.features;
        let geoIds = [];
        if (geo) {
          geo.forEach((item, idx) => {
            geoIds[item.properties.id] = idx;
          });
        }
        //        console.log("componentDidUpdate geoIds", geoIds);

        //#WIP - testing hide and show layers

        // #REVIEW/TODO
        // THIS NEEDS TO BE RE_WRITTEN TO RUN OVER ALL EDITABLE LAYERS
        //
        this.editableLayers.eachLayer((layer) => {
          layer.getElement().style.display = "block"; // this resets any hidden layer actions
        });

        //#REVIEW/TODO - namedAreaHiddenSelections and namedAreaDeleteSelections are 'same' make function or restructure message/object

        const namedAreaHiddenSelections = this.props.namedAreaHideSelections;
        //#REVIEW/TODO - filter by parentId - currently ignore id
        const namedAreasHidden = namedAreaHiddenSelections.map((na) => na.id);
        //        console.log("namedAreasHidden", namedAreasHidden);

        // const namedAreaDeleteSelections = this.props.namedAreaDeleteSelections;
        // //#REVIEW/TODO - filter by parentId - currently ignore id
        // const namedAreasDeleted = namedAreaDeleteSelections.map((na) => na.id);
        // //        console.log("namedAreasDeleted", namedAreasDeleted);

        this.editableLayers.eachLayer((layer) => {
          const includesNamedAreaHidden = namedAreasHidden.includes(
            layer.feature.properties.id
          );
          // const includesNamedAreaDeleted = namedAreasDeleted.includes(
          //   layer.feature.properties.id
          // );

          if (!includesNamedAreaHidden) {
            //
            return;
          }
          layer.getElement().style.display = "none";
        });

        this.editableLayers.bringToBack();
        this.geoJSONNamedAreaGroupLayer.bringToBack();
      }
    }

    // use this to control layers, named areas etc.
    if (this.locationGroupLayers && this.polylineGroupLayers) {
      if (this.props.parentState.isCheckboxFireflies) {
        for (const [key, value] of Object.entries(this.locationGroupLayers)) {
          //console.log("key", key);
          this.map.addLayer(value);
        }
      } else {
        for (const [key, value] of Object.entries(this.locationGroupLayers)) {
          this.map.removeLayer(value);
        }
      }

      if (this.props.parentState.isCheckboxPolyline) {
        for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
          //console.log("key", key);
          this.map.addLayer(value);
        }
      } else {
        for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
          this.map.removeLayer(value);
        }
      }
    }

    // Check if isAddPolygonClicked
    if (this.props.parentState.isAddPolygonClicked) {
      // console.log(
      //   "isAddPolygonClicked",
      //   this.props.parentState.isAddPolygonClicked
      // );
      // https://github.com/Leaflet/Leaflet.draw/issues/179
      // programmatically click on toolbar
      // #REVIEW - disable until work out how to have transitional action
      // this.drawControl._toolbars.draw._modes.rectangle.handler.enable();
    }

    // check what layers are drawn.......
    //    this.editableLayers.eachLayer(function(layer) {
    //      console.log("this.editableLayers layer", layer);
    //    });
  }

  // based on
  // https://stackoverflow.com/questions/34501524/in-place-update-leaflet-geojson-feature
  // here for reference
  //  these are not used yet..........
  addNewFeatureToGeoJsonLayerGroup = (myGeoJsonLayerGroup, newGeoJsonData) => {
    myGeoJsonLayerGroup.addData(newGeoJsonData);
  };

  updateFeature = (updatedGeoJsonData) => {
    this.deleteFeature(updatedGeoJsonData); // Remove the previously created layer.
    this.addNewFeatureToGeoJsonLayerGroup(updatedGeoJsonData); // Replace it by the new data.
  };

  deleteFeature = (myGeoJsonLayerGroup, myFeaturesMap, deletedGeoJsonData) => {
    var deletedFeature = myFeaturesMap[deletedGeoJsonData.properties.objectID];
    myGeoJsonLayerGroup.removeLayer(deletedFeature);
  };

  updateFireflyMarkersGeoJSON(map, geoJSONMarkersData) {
    const blueMarker = {
      radius: 6,
      fillColor: "blue",
    };

    const redMarker = {
      radius: 6,
      fillColor: "red",
    };

    const yellowMarker = {
      radius: 6,
      fillColor: "yellow",
    };

    const greenMarker = {
      radius: 6,
      fillColor: "green",
    };

    // map the geoJSONMarkersData and delete all the markers who have changed propertie
    // and accumulate these markers
    // add them all at the end

    // find the fireflies which have changed in geojsonMarkerOptions object
    geoJSONMarkersData.features.map(({ properties }, idx) => {
      const { id, color, location, position } = properties;

      // Make sure there is a layergroup for new markers.
      // layer groups are defined by LOCATION.
      // If a new LOCATION is added, check and add if necessary.
      //
      if (this.map.hasLayer(this[location])) {
      } else {
        this[location] = L.featureGroup().addTo(this.map);
        this.locationGroupLayers[location] = this[location];
      }

      // if the marker is in the layerlistFireflies
      if (this.layerlistFireflies[id]) {
        // if (id.includes("P17:4")) {
        //   console.log(
        //     "P17:4 current layer color = this.layerlistFireflies[id].feature.properties.color",
        //     this.layerlistFireflies[id].feature.properties.color
        //   );
        //   console.log("P17:4 new color =", color);
        //   console.log(
        //     "P17:4 this.layerlistFireflies[id].feature.properties.color !== color",
        //     this.layerlistFireflies[id].feature.properties.color !== color
        //   );
        // }

        // if new color is not the same as the old
        if (this.layerlistFireflies[id].feature.properties.color !== color) {
          // #REVIEW - no longer remove the layer, instead just update it
          //remove the layer
          //map.removeLayer(this.layerlistFireflies[id]);

          // get style for new marker

          let newStyle;

          switch (color) {
            case "red":
              newStyle = redMarker;
              break;
            case "blue":
              newStyle = blueMarker;
              break;
            case "amber":
              newStyle = yellowMarker;
              break;
            case "green":
              newStyle = greenMarker;
              break;

            default:
              break;
          }

          // if (id.includes("P17:4")) {
          //   console.log("P17:4 before .setStyle");
          //   console.log("P17:4 color", color);
          //   console.log("P17:4 newStyle", newStyle);
          // }

          // #REVIEW - no need to replace layer, instead just update the color
          // place a new layer with the new style
          //          this.layerlistFireflies[id].addTo(map).setStyle(newStyle);

          this.layerlistFireflies[id].setStyle(newStyle);
          // update the color property
          this.layerlistFireflies[id].feature.properties.color = color;

          // if (id.includes("P17:4")) {
          //   console.log("P17:4 after .setStyle");
          //   console.log(
          //     "P17:4 this.layerlistFireflies[id].feature.properties.color",
          //     this.layerlistFireflies[id].feature.properties.color
          //   );
          //   console.log("P17:4 color", color);
          // }
        }
      } else {
        // console.log(
        //   "hasLayer componentDidUpdate updateFireflyMarkersGeoJSON this.map.hasLayer(this.geoJSONGroupLayer)",
        //   this.map.hasLayer(this.geoJSONGroupLayer)
        // );

        // if it's not in the layer list add it..........
        // note pointToLayer and onEachFeature are defined in ComponentDidMount()
        this.geoJSONGroupLayer.addData(geoJSONMarkersData.features[idx]);
      }
    });
  }

  updateNamedAreasGeoJSON(map, geoJSONPolygonData) {
    // #TODO - !
    // map the geoJSONPolygonData and delete all the shapes who have changed propertie
    // and accumulate these shapes
    // add them all at the end

    // check if a named area has been removed
    // get list of IDs for current named area (i.e. layers)
    const layersIds = Object.keys(this.layerlistNamedAreas);

    // get the Ids for the incoming update of named area data
    let namedAreaIds = [];
    for (const namedArea in geoJSONPolygonData.features) {
      namedAreaIds.push(geoJSONPolygonData.features[namedArea].properties.id);
    }

    // check for a difference
    // filter each item and return it if it is *not* in the namedAreas
    const inLayerNotInNamedArea = layersIds.filter(
      (i) => !namedAreaIds.includes(i)
    );

    inLayerNotInNamedArea.forEach((layer, idx) => {
      // delete any layers which are in layersIds but *not* in namedAreaIds
      // remove the layer from layer groups
      this.editableLayers.removeLayer(this.layerlistNamedAreas[layer]);
      this.geoJSONNamedAreaGroupLayer.removeLayer(
        this.layerlistNamedAreas[layer]
      );
      // to be really sure remove it from the map completely
      this.map.removeLayer(this.layerlistNamedAreas[layer]);
      // delete the layer from the list
      delete this.layerlistNamedAreas[layer];
      // update localMapstate
    });

    // find the named areas which have changed in geoJSONPolygonData object
    geoJSONPolygonData.features.map(({ properties }, idx) => {
      const { id } = properties;

      // if the shape is not in the layer list
      if (!this.layerlistNamedAreas[id]) {
        // add it
        // note pointToLayer and onEachFeature are defined in ComponentDidMount() <--- #REVIEW - is it? i.e. for namedArea?
        this.geoJSONNamedAreaGroupLayer.addData(
          geoJSONPolygonData.features[idx]
        );
      } else {
        // if the shape exists but has changed, update it

        // #WIP - should also review updates to shape coordinates

        // AFAIK the only way to update is to go through every layer until finding the match!
        // thankfully there are not many layers for namedAreas
        this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
          if (layer === this.layerlistNamedAreas[id]) {
            layer.feature.properties = properties;
          }
        });
      }
    });

    // update leaflet id to properties (used to delete.edit shapes in local state) <--- #REVIEW - is it? may not be necessary now
    this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
      layer.feature.properties._leaflet_id = layer._leaflet_id;
    });
  }

  // heartBeat = () => {
  //   const heartBeat = this.state.heartBeat;
  //   //console.log("heartBeat", heartBeat);
  //   this.setState({ heartBeat: !heartBeat });
  // };

  render() {
    const { render } = this.props;
    const mapId = "map-" + render;

    return (
      <>
        <div>
          <div id={mapId} style={this.props.style} />
          {/* <div id="custom-map-controls"></div> */}
        </div>
        {/* heart beat only after the map has loaded ....  */}
        {/* <Heartbeat
          heartbeatFunction={this.heartBeat}
          heartbeatInterval={1000}
        /> */}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  // for testing force collect minelevel info for Id=1 "DMLZ Extraction"
  const markersData = getMapState(state);
  const geoJSONMarkersDataUtm = getFireflyCoordinates(state);
  const localMapState = GetLocalMap(state);
  const geoJSONNamedAreasUtm = getNamedAreaInfos(state);

  const namedAreaDeleteSelections = getNamedAreaDeleteSelections(state);

  const namedAreaHideSelections = getNamedAreaHideSelections(state);

  const isDirty = getIsDirty(state);

  return {
    markersData,
    geoJSONMarkersDataUtm,
    localMapState,
    geoJSONNamedAreasUtm,
    namedAreaDeleteSelections,
    namedAreaHideSelections,
    isDirty,
  };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateLocalMap: (mapState) => {
    dispatch(UpdateLocalMap(mapState));
  },
  namedAreaClearDeleteSelections: (parentId) => {
    dispatch(namedAreaClearDeleteSelections(parentId));
  },
  namedAreasSetIsDirty: (id) => {
    dispatch(namedAreasSetIsDirty(id));
  },
  mqttPublish: (mqttMsg) => {
    dispatch(mqttPublish(mqttMsg));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
