import React, { Component } from "react";
import { connect } from "react-redux";

import "react-virtualized/styles.css";
import { Column, Table as VTable, AutoSizer } from "react-virtualized";

import { fetchNamedAreas } from "NamedAreas/actions";
import {
  showAreaPreview,
  stopAreaPreview,
} from "components/RegionPreview/actions";
import { makeGetNamedAreasForLevel } from "NamedAreas/reducer";

import {
  getNamedAreaParentStatusesByAreaId,
  getNamedAreaStatusesById,
} from "components/WebWorker/reducer";
import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import PrecannedOperations from "pages/ControlRoomPage/PrecannedOperations";

import isEqual from "lodash/isEqual";

class MineLevelPrecannedOperationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewables: this._prepViewables(this.props),
    };
  }

  _prepViewables = (props) => {
    const { mineLevelId, namedAreasParents, levelWideNamedArea } = props;

    return [
      // #REVIEW - disable `fixed` level wide display in preference to real named area events
      // {
      //   key: "level",
      //   area: {
      //     id: levelWideNamedArea.area, // mineLevelId,
      //     spec: levelWideNamedArea.id, //mineLevelId,
      //     name: "LEVEL WIDE",
      //     //type: "MINE_LEVEL",
      //     groupsArray: levelWideNamedArea.button,
      //   },
      // },
      ...namedAreasParents.map((namedArea) => ({
        key: `named-area-${namedArea.id}`,
        area: {
          id: namedArea.area,
          spec: namedArea.id,
          name:
            namedArea.type !== "LEVEL_WIDE"
              ? namedArea.namedArea
              : "LEVEL WIDE",
          type: namedArea.type,
          groupsArray: namedArea.button,
          namedArea: namedArea.namedArea, // #NOTE - repeated here, as needed for SOP version
        },
      })),
    ];
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({ viewables: this._prepViewables(nextProps) });
    }
  }

  _getDatum(list, index) {
    return list[index];
  }
  render() {
    const { showAreaPreview, stopAreaPreview, displaySettings, userSettings } =
      this.props;
    const { viewables } = this.state;
    const rowGetter = ({ index }) => this._getDatum(viewables, index);

    // #NOTE #REVIEW #TODO - make common function
    //
    const { view } = displaySettings;
    // default row height
    let rowHeight;
    let fontSize;
    switch (view) {
      case "compressed":
        rowHeight = 30;
        fontSize = 0.6; // 30/50
        break;
      case "normal":
        rowHeight = 50;
        fontSize = 1; // 50/50
        break;
      case "expanded":
        rowHeight = 80;
        fontSize = 1.6; // 80/50
        break;
      default:
        rowHeight = 50;
        fontSize = 1;
        break;
    }

    //console.log("SOP viewables", viewables);

    return (
      <AutoSizer>
        {({ width, height }) => {
          return (
            <VTable
              ref="Table"
              disableHeader
              rowCount={viewables.length}
              rowHeight={rowHeight}
              rowGetter={rowGetter}
              width={width}
              height={
                height - rowHeight
              } /* reduce total by height of one row height */
              /* #TODO #WIP rollover functions not */
              onRowMouseOver={({ rowData: { area } }) => {
                showAreaPreview && showAreaPreview(area);
              }}
              onRowMouseOut={({ rowData: { area } }) => {
                stopAreaPreview && stopAreaPreview(area);
              }}
            >
              <Column
                width={1}
                flexGrow={1}
                label="Name"
                dataKey="area.name"
                style={{
                  fontSize: `${fontSize}rem`,
                }}
                cellRenderer={({ rowData }) => {
                  return rowData.area.name;
                }}
              />
              <Column
                width={1}
                flexGrow={2}
                label="Actions"
                dataKey="area"
                cellRenderer={({ rowData }) => {
                  return (
                    <PrecannedOperations
                      area={rowData.area}
                      displayAllOnMineLevel={true}
                      userSettings={userSettings}
                    />
                  );
                }}
              />
            </VTable>
          );
        }}
      </AutoSizer>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { mineLevelId } = props;
  const namedAreasParents = getNamedAreaParentStatusesByAreaId(
    state,
    mineLevelId
  );

  const levelWideNamedArea = getNamedAreaStatusesById(state, mineLevelId);

  // id -> id
  // mineLevelId -> area
  // areaName -> namedArea

  const displaySettings = getNamedAreaDisplaySettings(state);

  return {
    namedAreasParents,
    levelWideNamedArea,
    //namedAreas: tempData_min, // makeGetNamedAreasForLevel(state, props), // tempData // #REVIEW - delete, old implementation left in for testing
    displaySettings,
  };
};

export default connect(mapStateToProps, {
  //fetchNamedAreas,
  showAreaPreview,
  stopAreaPreview,
})(MineLevelPrecannedOperationsList);
