import { delay, channel, buffers } from "redux-saga";
import {
  call,
  cancel,
  put,
  take,
  takeEvery,
  fork,
  spawn,
  cancelled,
} from "redux-saga/effects";
import * as ws from "@samuelcastro/redux-websocket";
import * as Api from "api/apiWebsocket";

import { isTagTracking, tagTrackingPort } from "components/ConfigJs";

function* publishMessage(chan) {
  try {
    while (true) {
      const statusMessage = yield take(chan);
      yield call(delay, 500); // allow 20ms to collect events
      let messages = [statusMessage];
      chan.flush((dumped) => {
        messages = [statusMessage, ...dumped];
      });

      //console.log("WS",messages)

      yield put(messages); // required 'redux-batch' to handle array of actions
    }
  } catch (error) {
    console.log("publishMessageError", error);
  } finally {
    if (yield cancelled()) {
      console.log("publishMessageCancelled");
    }
  }
}

function handleWsMessage(chan, wsMessage) {
  const { payload } = wsMessage;

  //console.log(`WEBSOCKET STATUS wsMessage data`, wsMessage);

  try {
    const statusMessage = JSON.parse(payload.data);

    // #NOTE - tweak to accommodate message sent labelled "data" instead of std "payload"
    let newStatusMessage = {
      type: statusMessage?.type,
      payload: statusMessage?.data,
    };

    // TAG_UPDATE with one of the following INITIAL, LOST, CHANGE, UNKNOWN
    // ZONE_UPDATE with one of the following CHANGE

    //console.log(`tagTracking - WEBSOCKET STATUS MESSAGE`, newStatusMessage);

    chan.put(newStatusMessage);
  } catch (e) {}
}

function* websocketFlow() {
  while (true) {
    yield take([ws.WEBSOCKET_OPEN]);

    const processChannel = yield call(channel, buffers.expanding(4000));

    const publishMessages = yield spawn(publishMessage, processChannel);

    const processMessages = yield takeEvery(
      ws.WEBSOCKET_MESSAGE,
      handleWsMessage,
      processChannel
    );

    yield put({ type: "SERVER_CONNECTION_OPENED" });
    // yield put({ type: ws.WEBSOCKET_SEND, payload: { type: "GIMME_ALL_OPS" } });
    // yield put({
    //   type: ws.WEBSOCKET_SEND,
    //   payload: { type: "GIMME_CONTROL_STATUS" },
    // });
    // yield put({
    //   type: ws.WEBSOCKET_SEND,
    //   payload: { type: "GIMME_ALL_TRAVELWAYS" },
    // });
    // yield put({ type: ws.WEBSOCKET_SEND, payload: { type: "GIMME_ALL_UPS" } });
    // yield put({ type: ws.WEBSOCKET_SEND, payload: { type: "GIMME_ALL_FF" } });
    yield take([ws.WEBSOCKET_CLOSED]); // do I need this?
    yield put({ type: "SERVER_CONNECTION_CLOSED" });
    yield put({ type: ws.WEBSOCKET_DISCONNECT });
    yield cancel(processMessages);
    yield cancel(publishMessages);
    processChannel.close();
  }
}

function getBackoffReconnect(time) {
  time = 1.5 * time;
  time = Math.min(time, 15000);
  return time;
}

function* websocketSubscriptionFlow() {
  while (true) {
    yield take(["FOUND_VALID_AUTH", "LOGIN_SUCCESS"]);
    let reconnectInMs = 1000; //500
    let base = window.location.origin;
    base = base.replace(/^http/, "ws");

    const tagServicePort = tagTrackingPort();

    if (process.env.NODE_ENV === "production") {
      const port = window?.location?.port;
      if (port) {
        base = base.replace(`:${port}`, `:${tagServicePort}/api`);
      } else {
        base = `${base}:${tagServicePort}/api`;
      }
    }

    console.log(`tagTracking WEBSOCKET base `, base);

    let wasConnected = false;

    while (reconnectInMs > 0) {
      // Cope with dev server proxy dying if WSS reconnect happens after backend is turned off
      // This works by trying any "random" endpoint and only succeeding if we got a valid
      // server response (i.e. we should get a 404. A 500ish error is likely from the proxy)
      if (process.env.NODE_ENV !== "production") {
        if (wasConnected) {
          let r = yield call(Api.checkAPIExists);

          if (r === undefined || r.status >= 500) {
            reconnectInMs = getBackoffReconnect(reconnectInMs);
            yield call(delay, reconnectInMs);
            continue;
          }
        }
      }

      let urlSocket = `${base}/v1/ws`;
      //urlSocket = `ws://localhost:8010/api/v1/ws`;

      // #NOTE - In saga the 'urlSocket' gets translated to
      // `ws://localhost:8010/api/v1/ws`
      // The path "/v1" is converted to "${tagUrl}/api/v1" and appended with "/ws" to make -> ws://localhost:8010/api/v1/ws
      // See - src/setupProxy.js
      // See - src/LiveUpdates/saga.js
      // #NOTE - 'api' is intentionally excluded from this path otherwise
      // firefly server will check the routing for /api/v1

      yield put({
        type: ws.WEBSOCKET_CONNECT,
        payload: { url: urlSocket }, //
      });

      // console.log(
      //   `tagTracking websocket connecting to `,
      //   urlSocket
      // );

      let action = yield take([
        ws.WEBSOCKET_OPEN,
        "AUTH_FAILURE",
        "AUTH_UNKNOWN",
        "LOGOUT_SUCCESS",
        ws.WEBSOCKET_CLOSED,
        ws.WEBSOCKET_DISCONNECT,
      ]);

      //console.log(`tagTracking  websocket connect action `, action);

      if (action.type === ws.WEBSOCKET_OPEN) {
        reconnectInMs = 1000; //500
        action = yield take([
          "AUTH_FAILURE",
          "AUTH_UNKNOWN",
          "LOGOUT_SUCCESS",
          ws.WEBSOCKET_CLOSED,
          ws.WEBSOCKET_DISCONNECT,
        ]);
        wasConnected = true;
      }
      switch (action.type) {
        case ws.WEBSOCKET_CLOSED:
        case ws.WEBSOCKET_DISCONNECT:
          reconnectInMs = getBackoffReconnect(reconnectInMs);
          console.log(
            "tagTracking had a websocket issue, but should try again in ",
            reconnectInMs / 1000.0
          );
          yield call(delay, reconnectInMs);
          break;
        default:
          reconnectInMs = 0;
          console.log(
            "tagTracking had an AUTH issue, should wait for an AUTH trigger"
          );
          yield put({ type: ws.WEBSOCKET_DISCONNECT });
          break;
      }
    }
  }
}

function* liveUpdateSaga() {
  yield fork(websocketFlow);
  yield fork(websocketSubscriptionFlow);
}

export default liveUpdateSaga;
