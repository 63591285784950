import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Container } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import ScheduledEventSimpleForm from "admin/event-scheduled/ScheduledEventForm";
import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import NavigationPromptModal from "admin/NavigationPromptModal";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { getScheduledEventJobInfo } from "components/WebWorker/reducer";
import {
  fetchNamedAreaScheduledEventInfo,
  saveNewScheduledEvent,
  deleteScheduledEventJob,
} from "NamedAreas/actions";
import { getUserData } from "auth/reducer";
import { v4 as uuid } from "uuid";

class ScheduledEventEdtPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    this.props.fetchNamedAreaScheduledEventInfo();
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  _preProcessValues = (values) => {
    const {
      //
    } = values;

    return values;
  };

  submitMyForm = (values) => {
    const { saveNewScheduledEvent } = this.props;

    console.log("xxx saveNewScheduledEvent submitMyForm values", values);

    const id = this.props.id;
    const password = uuid(); // not supported ATM

    const {
      name,
      disable,
      cron,
      mineLevelId: area_id,
      note,
      duration,
      events,
    } = values;

    // #NOTE - events are in the form ............
    // e.g.
    // const events = [
    //   {
    //     polygon_id: uuid(),
    //     user_relay: 1,
    //   },
    //   // {
    //   //   polygon_id: uuid(),
    //   //   user_relay: 3,
    //   // },
    //   // {
    //   //   polygon_id: uuid(),
    //   //   user_relay: 5,
    //   // },
    // ];

    const newEvents = events.map((event) => {
      return { polygon_id: event.polygonId, user_relay: event.userRelay };
    });

    const cron_active_duration = `${duration.value}:${duration.unit}`;
    const info = JSON.stringify(values);

    const newValues = {
      id,
      name,
      cron,
      area_id,
      disable,
      events: newEvents,
      note,
      password,
      cron_active_duration,
      info,
    };

    console.log("xxx save newValues", newValues);

    return new Promise((resolve, reject) => {
      saveNewScheduledEvent({
        values: newValues,
        resolve,
        reject,
      });
    }).then(() => {
      // wait for state change before redirecting page
      this.setState(
        {
          submitted: true,
        },
        () => {
          const { fetchNamedAreaScheduledEventInfo } = this.props;
          fetchNamedAreaScheduledEventInfo(); // #FETCHS

          this.props.goto(`/admin/scheduled-job`);
        }
      );
    });
  };

  render() {
    const {
      //isLoading, error,
      id,
      formValues,
      strings,
      userLanguage,
    } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    // APP_TERMINOLOGY
    let strScheduledEvent = strings?.["Scheduled Job"];
    let strScheduledEvents = strings?.["Scheduled Jobs"];

    return (
      <div className="genericGridHeader">
        <NavigationPromptModal
          formName={"scheduledEventForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) =>
            this.submitMyForm(this._preProcessValues(formValues))
          }
        />
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["Edit Scheduled Job"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <ScheduledEventSimpleForm
            id={id}
            initialValues={formValues}
            header={strings?.["Edit Scheduled Job"]}
            onSubmit={(values) =>
              this.submitMyForm(this._preProcessValues(values))
            }
            onCancel={() => {
              this.props.goto(`/admin/scheduled-job`);
            }}
            onDelete={(id) => {
              let promiseArray = [];
              promiseArray.push(
                new Promise((resolve, reject) =>
                  this.props.deleteScheduledEventJob({
                    values: { id },
                    resolve,
                    reject,
                  })
                )
              );

              return Promise.all(promiseArray)
                .then((results) => {
                  console.log("Deleting scheduled job", results);
                })
                .then(
                  () => {
                    this.props.fetchNamedAreaScheduledEventInfo();
                    this.props.goto(`/admin/scheduled-job/`);
                  },
                  (msg) => {
                    console.log("action failed", msg); // TODO probs should show this?
                  }
                );
            }}
            strings={strings}
            userLanguage={userLanguage}
          />

          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const match = props.match;
  const id = match.params.id;

  console.log("xxx editpage id", id);

  const scheduledEventJobInfo = getScheduledEventJobInfo(state) || {};

  console.log("xxx editpage scheduledEventJobInfo", scheduledEventJobInfo);

  const eventJob =
    scheduledEventJobInfo.filter((info) => info.eventJobId === id)?.[0] || {};

  let formValues = {};
  if (eventJob.info) {
    formValues = JSON.parse(eventJob.info);
  }

  console.log("xxx editpage eventJob", eventJob);
  console.log("xxx editpage eventJob formValues", formValues);

  return {
    id,
    formValues,
    userLanguage: getUserData(state)?.language || "en-US",
  };
};

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
    fetchNamedAreaScheduledEventInfo: () =>
      dispatch(fetchNamedAreaScheduledEventInfo()),
    saveNewScheduledEvent: (data) => dispatch(saveNewScheduledEvent(data)),
    deleteScheduledEventJob: (data) => dispatch(deleteScheduledEventJob(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledEventEdtPage);
