import React, { Component } from "react";
import { connect } from "react-redux";
import { SetMapImage } from "components/Settings/actions";
import { getMapImages } from "components/Settings/reducer";
import { getAllAreaStatuses } from "components/WebWorker/reducer";
import { getFolderFileNamesListById } from "components/Settings/reducer";

// https://stackoverflow.com/questions/19183180/how-to-save-an-image-to-localstorage-and-display-it-on-the-next-page

// You don't need the getBase64Image function.
// Data urls are already base 64 so when you call reader.readAsDataURL
// the e.target.result sent to the reader.onload handler will be all you need.

function getBase64Image(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  var dataURL = canvas.toDataURL("image/png");

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

class AreaImages extends Component {
  render() {
    const { areaIds, areaImageFileNames, mapImagesFromState, SetMapImage } =
      this.props;

    const mapImageIds = [...mapImagesFromState.map((img) => img.id)];

    // #REVIEW #WIP
    //
    // #NOTE - this render is not limited _but_ because the browser/react checks that <img is not
    // different the DOM is not updated, and the <img /> is not reloaded, and onload is not run again.
    //
    // If memory loading is down without putting the <img /> in the DOM the rendering will have to be
    // controlled based on state changes.
    //
    //
    // console.log(
    //   `XXXXXXXXXXXXXXXX AreaImages render `,
    //   areaImageFileNames,
    //   mapImagesFromState
    // );

    const content = areaIds.map((areaId) => {
      //
      const imageFilename = areaImageFileNames.find?.(
        (item) => item.id === areaId
      )?.src;

      const imageSrc = process.env.PUBLIC_URL + "/areas/" + imageFilename;
      const images = (areaId, imageSrc) => {
        return (
          <img
            id={`${imageSrc}`}
            alt={`loading hidden file ${imageSrc}`}
            style={{ display: "none" }}
            src={`${imageSrc}`}
            onError={(err) => {
              console.log(`AreaImages IMAGE LOADED: error`, err);
              console.log(
                `AreaImages IMAGE LOADED: Failed to load <img> ${imageSrc}`
              );
            }}
            onLoad={() => {
              console.log(
                `AreaImages IMAGE LOADED: Loaded <img> image ${imageSrc}`
              );
              // let mapsLoaded = this.state.isMapLoaded;
              // mapsLoaded.push(areaId);
              // this.setState({
              //   isMapLoaded: mapsLoaded,
              // });
              const bannerImage = document.getElementById(`${imageSrc}`);
              const imgData = getBase64Image(bannerImage);
              SetMapImage({
                id: areaId,
                imageData: imgData,
              });
              console.log(`AreaImages imgData ${imageSrc}`, imgData);
            }}
          />
        );
      };

      return images(areaId, imageSrc);
    });

    //console.log(`AreaImages content`, content);

    return content;
  }
}

const mapStateToProps = (state, props) => {
  const areas = getAllAreaStatuses(state) || [];
  const areaIds = areas.map((area) => area?.id);
  const areaImageFileNames = areas.map((area) => {
    return { id: area.id, src: area.image_filename };
  });

  return {
    areaIds,
    areaImageFileNames,
    folderFilesList: getFolderFileNamesListById(state, "areas"),
    mapImagesFromState: getMapImages(state),
  };
};

export default connect(mapStateToProps, { SetMapImage })(AreaImages);
