import { put, takeLatest, select, take } from 'redux-saga/effects'
import * as Api from 'api'
import { push } from 'react-router-redux'
import toSlug from 'utils/toSlug'
import * as actions from './actions'

import { getMineLevelById } from 'MineLevels/reducer'
import {
  getCurrentSeismicLayer,
  getSeismicLayerById
} from 'seismicOverlays/reducer'
import {
  getEditingDefaultSettings,
  getEditingRegionsInOrder
} from 'pages/CreateSeismicOverlayPage/reducer'

function * interceptSaveAndApply (action) {
  const { payload: { mineLevelId } } = action
  const mineLevel = yield select(getMineLevelById, mineLevelId)
  const levelDefault = yield select(getEditingDefaultSettings)
  const regionSettings = yield select(getEditingRegionsInOrder)

  const plainRegions = regionSettings.asMutable().map(region => ({
    lightSettings: region.lightSettings.asMutable(),
    points: region.points.asMutable()
  }))

  try {
    const newLayer = yield Api.saveNewSeismicLayer(
      mineLevelId,
      levelDefault.asMutable(),
      plainRegions
    )

    yield put({ type: 'SAVE_NEW_REGION_SUCCESS', payload: newLayer })
    yield put(push(`/level/${toSlug(mineLevel.name)}/status`))
  } catch (e) {
    yield put({ type: 'SAVE_NEW_REGION_FAILED', payload: e.message })
  }
}

function * useCurrentSettings (action) {
  const { payload: { mineLevelId } } = action

  const mineLevelCurrentSeismic = yield select(
    getCurrentSeismicLayer,
    mineLevelId
  )
  if (mineLevelCurrentSeismic === undefined) {
    yield put(actions.resetSeismicOverlayEditor())
  } else {
    yield put(actions.forceWholeSettings(mineLevelCurrentSeismic))
  }
}

function * startEditingFrom (action) {
  const { payload: { mineLevelId, seismicLayerId } } = action

  const mineLevel = yield select(getMineLevelById, mineLevelId)
  const seismicLayer = yield select(getSeismicLayerById, seismicLayerId)

  if (mineLevel.id !== seismicLayer.mineLevelId) {
    console.log('Wups, trying to start editing based on incorrect level!!!')
  }

  yield put(push(`/level/${toSlug(mineLevel.name)}/create-seismic`))
  yield take('RESET_SEISMIC_OVERLAY_EDITOR')
  yield put(actions.forceWholeSettings(seismicLayer))
}

function * saga () {
  yield takeLatest('SAVE_NEW_REGION_REQUEST', interceptSaveAndApply)
  yield takeLatest(
    'SEISMIC_OVERLAY_EDITOR_USE_CURRENT_SETTINGS',
    useCurrentSettings
  )
  yield takeLatest('SEISMIC_EDITOR_START_WITH_SEISMIC_LAYER', startEditingFrom)
}

export default saga
