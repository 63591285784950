import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import ListNamedAreaGroup from "admin/named-area-group/NamedAreaGroupList";
import CreateNamedAreaGroup from "admin/named-area-group/NamedAreaGroupCreatePage";
import EditNamedAreaGroup from "admin/named-area-group/NamedAreaGroupEditPage";

class NamedAreaGroupAdminPages extends Component {
  componentDidMount() {
    //this.props.fetchNamedAreaGroups() // --- ??????????
  }

  render() {
    const { match, strings, userSettings } = this.props;

    // console.log("xxx NamedAreaGroupAdminPages - strings", strings);

    return (
      <Switch>
        <Route
          path={`${match.url}/new`}
          //component={CreateNamedAreaGroup}
          render={(props) => (
            <CreateNamedAreaGroup {...props} strings={strings} />
          )}
        />
        <Route
          path={`${match.url}/edit/:id`}
          //component={EditNamedAreaGroup}
          render={(props) => (
            <EditNamedAreaGroup
              {...props}
              strings={strings}
              userSettings={userSettings}
            />
          )}
        />
        <Route
          exact
          path={match.url}
          //component={ListNamedAreaGroup}
          render={(props) => (
            <ListNamedAreaGroup {...props} strings={strings} />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(connect(null, null)(NamedAreaGroupAdminPages));
