import { useEffect, useState } from "react";
import { isDebugScreensize } from "components/ConfigJs";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  if (isDebugScreensize()) {
    console.log(
      `isDebugScreensize: getWindowDimensions width `,
      width,
      `height`,
      height
    );

    return {
      width,
      height,
    };
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
