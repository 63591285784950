import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Header,
  Grid,
  List,
  Segment,
  Message,
  Icon,
} from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import { getFolderFileNamesListById } from "components/Settings/reducer";

const iconForFileType = (file) => {
  const fileArray = file.split(".");

  // assume last entry is file extension
  switch (fileArray[fileArray.length - 1].toLowerCase()) {
    case "pdf":
      return "file pdf outline";
    case "jpg":
    case "png":
      return "file image outline";
    case "mp4":
      return "file video outline";
    case "zip":
    case "gz":
      return "file archive outline";

    default:
      return "file outline";
  }
};

class HelpFilesList extends Component {
  render() {
    const { folderFilesList, strings } = this.props;

    const noFilesMessage = strings?.["No Files"];
    const filesMessage = (
      <List size={"huge"}>
        {folderFilesList?.map((file) => {
          return (
            <List.Item
              icon={iconForFileType(file)}
              content={
                <a
                  href={process.env.PUBLIC_URL + "/documents/" + file}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  {file}
                </a>
              }
            />
          );
        })}
      </List>
    );

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["Help Files"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <Message icon color="blue">
            <Icon name="info" />
            <Message.Content>
              <Message.Header>{strings?.["Help Files"]}</Message.Header>
              {strings?.["Help Files Message"]}
            </Message.Content>
          </Message>
          <Segment>
            {folderFilesList?.length ? filesMessage : noFilesMessage}
          </Segment>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    folderFilesList: getFolderFileNamesListById(state, "documents"),
  };
}

export default connect(mapStateToProps)(HelpFilesList);
