import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
//import { getUPSById } from 'UPSPanelControllers/reducer'
import { getControllerById } from "components/WebWorker/reducer";

export function UPSLink({ id, notFound, ...props }) {
  //  let to = `/admin/ups/${id}`;
  let to = `/admin/controller/${id}`;
  if (notFound) {
    to = "#";
  }
  return <Link to={to}>{props.children}</Link>;
}

UPSLink.propTypes = {
  id: PropTypes.string.isRequired,
  notFound: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const ups = getControllerById(state, id);
  return { id, notFound: !ups };
};

export default connect(mapStateToProps)(UPSLink);
