import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Container } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import { saveNewNamedArea, updateNamedArea } from "NamedAreas/actions";

import {
  getNamedAreaStatusesById,
  getAllNamedAreaStatuses,
} from "components/WebWorker/reducer";

import EditNamedAreaForm from "admin/named-area/NamedAreaEditForm";

import SubmissionModal from "admin/SubmissionModal";

import {
  SubmissionError,
  //
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
  getFormMeta,
  getFormAsyncErrors,
  getFormSyncWarnings,
  getFormSubmitErrors,
  getFormError,
  getFormNames,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from "redux-form";

import { Dimmer, Loader } from "semantic-ui-react";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class EditNamedArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }
  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    const { updateNamedArea } = this.props;

    return new Promise((resolve, reject) => {
      //console.log("values", values);
      //updateNamedArea({ values, resolve, reject });
    }).catch((error) => {
      console.log(`onSubmit error`, error);
      this.onSetError("failed");
      // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
      throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
    });
  };

  render() {
    const {
      id,
      mineLevelId,
      //updateNamedArea,
      error,
      initialValues,
      submitting,
    } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    return (
      <>
        <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          errorMessage={errorMessage}
        />
        <Dimmer active={submitting} page>
          <Loader>Saving changes</Loader>
        </Dimmer>

        {/* #NOTE - NavigationPromptModal is disabled for advanced named areas because it does
        not currently support form submit. */}

        {/* <NavigationPromptModal
          formName={"createNewNamedAreaForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        /> */}

        <EditNamedAreaForm
          id={id}
          mineLevelId={mineLevelId}
          initialValues={initialValues}
          onSubmit={(values) => this.submitMyForm(values)}
          // #NOTE - disabled as this is fired as soon as submit,
          // *not* after successful completion of the Promise!!
          onSubmitSuccess={(result, dispatch) => {
            //console.log(`onSubmitSuccess ${result}`);
            // wait for state change before redirecting page
            this.setState(
              {
                submitted: true,
              },
              () => {
                this.onResetError();
                this.props.goto("/admin/named-area-advanced");
              }
            );
          }}
          onSubmitFail={(errors) => {
            console.log(`onSubmitFail ${errors}`);
          }}
          onCancel={() => {
            this.props.goto("/admin/named-area-advanced");
          }}
        />
      </>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    //    updateNamedArea: (data) => dispatch(updateNamedArea(data)), // #WIP
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

//export default connect(null, { saveNewMineLevel })(CreateMineLevel);

function mapStateToProps(state, props) {
  const match = props.match;
  const id = match.params.id;
  const nameArray = id.split(":");
  const mineLevelId = nameArray[0];

  // #REVIEW - this code is used in a few places
  let name;
  if (nameArray[1] === undefined) {
    // for edit form use namedArea name (i.e. not `Level Wide (${nameArray[0]})`)
    name = nameArray[0];
  } else {
    name = nameArray[1];
  }

  // for edit form named area
  const namedAreaIdValue = name;

  const allNamedAreas = getAllNamedAreaStatuses(state);

  // console.log(`allNamedAreas`, allNamedAreas);

  const namedAreaParent = allNamedAreas?.find((na) => na.parent === id);

  const namedAreaParentName = namedAreaParent?.parent_name;
  const namedAreaSubType = namedAreaParent?.sub_type;

  const initialValues = {
    mineLevelId: mineLevelId,
    namedAreaIdValue: namedAreaIdValue,
    namedAreaParentName: namedAreaParentName || "unknown",
    namedAreaSubType: namedAreaSubType || "unknown",
  };

  return {
    id,
    mineLevelId,
    //namedArea,
    // error: namedArea === undefined ? "Named Area not found" : null,
    initialValues: initialValues, // props passed to redux-form loads these as (predictably), initial values

    //
    //values: getFormValues("editNamedAreaForm")(state),
    //initialValues: getFormInitialValues("editNamedAreaForm")(state),
    //formSyncErrors: getFormSyncErrors("editNamedAreaForm")(state),
    //fields: getFormMeta("editNamedAreaForm")(state),
    //formAsyncErrors: getFormAsyncErrors("editNamedAreaForm")(state),
    //syncWarnings: getFormSyncWarnings("editNamedAreaForm")(state),
    //submitErrors: getFormSubmitErrors("editNamedAreaForm")(state),
    //formError: getFormError("editNamedAreaForm")(state),
    //names: getFormNames()(state),
    //dirty: isDirty("editNamedAreaForm")(state),
    //pristine: isPristine("editNamedAreaForm")(state),
    //valid: isValid("editNamedAreaForm")(state),
    //invalid: isInvalid("editNamedAreaForm")(state),
    submitting: isSubmitting("editNamedAreaForm")(state),
    //submitSucceeded: hasSubmitSucceeded("editNamedAreaForm")(state),
    //submitFailed: hasSubmitFailed("editNamedAreaForm")(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNamedArea);
