// #WIP #TODO - review to replace so no longer use eval()

export function strToArray(str) {
  var arr = [];
  if (str && typeof str === "string") {
    var objArr = str.match(/\[(.)+\]/g);
    eval("arr =" + objArr);
  }
  return arr;
}
