import Immutable from "seamless-immutable";

const initialState = Immutable({
  prevNamedAreaGroup: {},
  prevNamedAreaGroupButtonStates: {},
});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "UPDATE_NAMED_AREA_GROUP_PREVIOUS":
      let newPrevNamedAreaGroup = { ...newState.prevNamedAreaGroup };
      newPrevNamedAreaGroup[payload.id] = payload.data;
      newState.prevNamedAreaGroup = newPrevNamedAreaGroup;
      // console.log(
      //   "UPDATE_NAMED_AREA_GROUP_PREVIOUS newPrevNamedAreaGroup",
      //   newPrevNamedAreaGroup
      // );
      return newState;
    case "UPDATE_NAMED_AREA_GROUP_BUTTON_STATES_PREVIOUS":
      let newPrevNamedAreaGroupButtonStates = {
        ...newState.prevNamedAreaGroupButtonStates,
      };
      newPrevNamedAreaGroupButtonStates[payload.id] = payload.data;
      newState.prevNamedAreaGroupButtonStates =
        newPrevNamedAreaGroupButtonStates;
      // console.log(
      //   "UPDATE_NAMED_AREA_GROUP_BUTTON_STATES_PREVIOUS newPrevNamedAreaGroupButtonStates",
      //   newPrevNamedAreaGroupButtonStates
      // );
      return newState;

    case "RESET":
      console.log("RESET! - Settings");
      return initialState;
    default:
      return state;
  }
}

export const getPrevNamedAreaGroups = (state) => {
  return Immutable(state.namedAreaGroups.prevNamedAreaGroup);
};

export const getPrevNamedAreaGroupButtonStates = (state) => {
  return Immutable(state.namedAreaGroups.prevNamedAreaGroupButtonStates);
};
