import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchFireflys } from "UPSPanelControllers/actions";

import TagTrackingEditPage from "admin/tag-tracking/TagTrackingEditPage";
import TagTrackingList from "admin/tag-tracking/TagTrackingList";

class TagTrackingAdminPages extends Component {
  render() {
    const { match, strings } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/:id`}
          //component={TagTrackingEditPage}
          render={(props) => (
            <TagTrackingEditPage {...props} strings={strings} />
          )}
        />
        <Route
          exact
          path={match.url}
          render={(props) => (
            <TagTrackingList
              {...props}
              includeTitles="true"
              strings={strings}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(
  connect(null, { fetchFireflys })(TagTrackingAdminPages)
);
