import { LeastSquaresApproximationFourPointsSixParametersTransformation } from "projection";

const standardPtsUtm = () => {
  // #TODO - this is duplicated in localPts()
  const ref1utm = {
    northing: 9549076.153,
    easting: 736959.54,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const ref2utm = {
    northing: 9549315.041,
    easting: 737526.058,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const ref3utm = {
    northing: 9548885.911,
    easting: 737883.021,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const ref4utm = {
    northing: 9548630.213,
    easting: 737564.472,
    zoneNum: 53,
    zoneLetter: "M",
  };

  // rest UTM values
  const standardRef1 = { lat: ref1utm.easting, lng: ref1utm.northing };
  const standardRef2 = { lat: ref2utm.easting, lng: ref2utm.northing };
  const standardRef3 = { lat: ref3utm.easting, lng: ref3utm.northing };
  const standardRef4 = { lat: ref4utm.easting, lng: ref4utm.northing };

  const standardAll = [standardRef1, standardRef2, standardRef3, standardRef4];

  return standardAll;
};

// #NOTE - pending delete. This shoudl not be used. Instead get from defaultArea in config file
//
// default reference points loaded with 'Add Mine Level'
export const localPts = () => {
  // measured from pixel positions
  const localRef1 = {
    lat: 4764,
    lng: 4202,
    easting: 736289.88025079, //736959.54,
    northing: 9549792.19986215, //9549076.1534,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const localRef2 = {
    lat: 3236,
    lng: 7815,
    easting: 738370.38357402, //737526.058,
    northing: 9549792.19986215, //9549315.041,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const localRef3 = {
    lat: 5978,
    lng: 10090,
    easting: 738370.37138483, //737883.021,
    northing: 9548413.47997008, //9548885.911,
    zoneNum: 53,
    zoneLetter: "M",
  };
  const localRef4 = {
    lat: 7611,
    lng: 8061,
    easting: 736289.88025079, //737564.472,
    northing: 9548413.47997008, // 9548630.213,
    zoneNum: 53,
    zoneLetter: "M",
  };
  return [localRef1, localRef2, localRef3, localRef4];
};

export const standardPtsUtmX = (mineLevel) => {
  const { ref_coord } = mineLevel;

  let result = {};
  ref_coord.forEach((coord) => {
    const indexArray = coord.id.split(":"); // e.g. "DMLZ_Extaction:1"
    const index = indexArray[1];

    result[index] = { lat: coord.utm[0], lng: coord.utm[1] };
  });

  // sort object by key
  Object.keys(result)
    .sort()
    .forEach(function (v, i) {
      //console.log(v, result[v]);
    });

  // return the values
  return Object.values(result);
};

export const localPtsX = (mineLevel) => {
  const { ref_coord } = mineLevel;

  let result = {};
  ref_coord.forEach((coord) => {
    const indexArray = coord.id.split(":"); // e.g. "DMLZ_Extaction:1"
    const index = indexArray[1];

    result[index] = {
      id: coord.id,
      lat: coord.image_xy[0],
      lng: coord.image_xy[1],
      easting: coord.utm[0],
      northing: coord.utm[1],
      zoneNum: coord.utm_zone_number,
      zoneLetter: coord.utm_zone_letter,
    };
  });

  // sort object by key
  Object.keys(result)
    .sort()
    .forEach(function (v, i) {
      //console.log(v, result[v]);
    });

  // return the values
  return Object.values(result);
};

// the geometric coordinate to map pixel transformation
const Transform = (localPts, standardPts) => {
  const LeastSq4Pt = LeastSquaresApproximationFourPointsSixParametersTransformation.fromPoints(
    localPts,
    standardPts
  );
  return LeastSq4Pt;
};

//   const transform = MineLevelMapImageProjection.fromPoints(
//     [ref1, ref2],
//     [imageRef1, imageRef2]
//   );

// converts from utm points to local points
export const transform = Transform(localPts(), standardPtsUtm());
// e.g.
// const refPoints = transform.transform({
//     lat: fireflyCoord[0],
//     lng: fireflyCoord[1],
//   });

export const transformX = (area) =>
  Transform(localPtsX(area), standardPtsUtmX(area));
