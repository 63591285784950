import { channelBufferPublishMsg } from "apiSaga";
import { protectedAPI, myApi, dummyResponse } from "api/apiAxios";
import { messageToken, checkToken, userSessionIp } from "utils/messageToken";

// utils
import _isString from "lodash/isString";

import toSlug from "utils/toSlug";

import {
  testLedFF,
  testFF,
  testArea,
  testNamedArea,
  testNamedAreaEvent,
  officeFireflies,
} from "pages/Test/TestMQTT/mqttMessages";

import {
  setAckToken,
  getAckToken,
  removeAckToken,
  clearAckToken,
} from "api/apiAck";

export function* fetchMineLevels() {
  const response = yield myApi.get("/api/get_all_areas");
  //console.log(`API_TEST_apiArea fetchMineLevels get_all_areas`, response);
  return response.data;
}

export function* saveNewMineLevel(values) {
  const {
    name,
    defaultColor,
    defaultState,
    imageInfo,
    ref1,
    ref2,
    ref3,
    ref4,
    mapFile,
    imageRef1,
    imageRef2,
    imageRef3,
    imageRef4,
    areaRegion,
    namedAreaGroup,
    active,
    order,
  } = values;

  //console.log("xxx values", values);

  //const bounds = areaBounds(values);
  //console.log("bounds", bounds);

  // ****************************

  // console.log(
  //   " saveNewMineLevel - new area",
  //   " new name",
  //   name,
  //   " new defaultColor",
  //   defaultColor,
  //   " new defaultState",
  //   defaultState,
  //   " new imageInfo",
  //   imageInfo,
  //   " new ref1",
  //   ref1,
  //   " new ref2",
  //   ref2,
  //   " new ref3",
  //   ref3,
  //   " new ref4",
  //   ref4,
  //   " new mapFile",
  //   mapFile,
  //   " new imageRef1",
  //   imageRef1,
  //   " new imageRef2",
  //   imageRef2,
  //   " new imageRef3",
  //   imageRef3,
  //   " new areimageRef4a",
  //   imageRef4,
  //   " new areaRegion",
  //   areaRegion,
  //   " new namedAreaGroup",
  //   namedAreaGroup
  // );

  // *********** new area *************

  const pf = (n) => Number.parseFloat(n);

  const areaId = name.replace(" ", "_");
  const areaSlug = toSlug(name);

  // prepare message token
  const token = messageToken(userSessionIp);

  let newFilename = mapFile.name; // temporary value, it'll be changed on response

  const newAreaMessage = (filename) => {
    const msg = {
      id: areaId,
      image_filename: filename,
      image_info: `{ "width": ${imageInfo.width}, "height": ${
        imageInfo.height
      }, "backgroundColor": ${JSON.stringify(imageInfo.backgroundColor)} }`,
      named_area_group: namedAreaGroup,
      slug: areaSlug,
      bounds: areaRegion,
      //  [
      //   [737074.9271303766, 9549161.164079294],
      //   [737074.8510577901, 9548828.072795311],
      //   [737654.4260753208, 9548827.910536127],
      //   [737074.9271303766, 9549161.164079294],
      //   [737074.9271303766, 9549161.164079294],
      // ],
      ref_coord: [
        {
          id: `${areaId}:1`,
          utm_zone_letter: ref1.zoneLetter, //"M",
          utm: [pf(ref1.easting), pf(ref1.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref1.zoneNum, //53,
          image_xy: [pf(imageRef1.lat), pf(imageRef1.lng)], //[150.23, 200.45],
        },
        {
          id: `${areaId}:2`,
          utm_zone_letter: ref2.zoneLetter, //"M",
          utm: [pf(ref2.easting), pf(ref2.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref2.zoneNum, //53,
          image_xy: [pf(imageRef2.lat), pf(imageRef2.lng)], //[150.23, 200.45],
        },
        {
          id: `${areaId}:3`,
          utm_zone_letter: ref3.zoneLetter, //"M",
          utm: [pf(ref3.easting), pf(ref3.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref3.zoneNum, //53,
          image_xy: [pf(imageRef3.lat), pf(imageRef3.lng)], //[150.23, 200.45],
        },
        {
          id: `${areaId}:4`,
          utm_zone_letter: ref4.zoneLetter, //"M",
          utm: [pf(ref4.easting), pf(ref4.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref4.zoneNum, //53,
          image_xy: [pf(imageRef4.lat), pf(imageRef4.lng)], //[150.23, 200.45],
        },
      ],
      floor: 0.0,
      ceiling: 0.0,
      default_color: defaultColor.toUpperCase() || "GREEN",
      default_state: defaultState.toUpperCase() || "ON",
      active: active == "true",
      order: Number(order),
      token: token,
    };

    return msg;
  };
  const newMsg = {
    id: testArea.id,
    image_filename: testArea.image_filename,
    token: token,
  };

  const payloadNew = {
    topic: `area/${testArea.id}/new`,
    qos: 0,
    message: newMsg,
    retained: false,
  };

  const payloadChange = (filename) => {
    const payload = newAreaMessage(filename);

    const msg = {
      topic: `area/${payload.id}/change`,
      qos: 0,
      message: payload,
      retained: false,
    };
    return msg;
  };

  //let payload = payloadChange;

  // send image -->
  // --> create form
  const imageForm = new FormData();
  const types = ["image/png", "image/jpeg", "image/gif"];
  const errs = [];

  if (types.every((type) => mapFile.type !== type)) {
    errs.push(`'${mapFile.type}' is not a supported format`);
  }

  if (mapFile.size > 15000000000) {
    errs.push(`'${mapFile.name}' is too large, please pick a smaller file`);
  }

  // #REVIEW - passes '1' one map file.
  imageForm.append(1, mapFile);

  // --> post form
  const newAreaImage = (form) => {
    return new Promise(function (resolve, reject) {
      const session_url = `/areas`;
      protectedAPI
        .post(session_url, form)
        .then((response) => {
          // console.log(
          //   `Response from ${session_url} - ${JSON.stringify(
          //     response.status
          //   )} ${JSON.stringify(response.data)}`
          // );
          const newFilenamePath = response.data.path;
          const newFilenameArray = newFilenamePath.split("/");
          newFilename = newFilenameArray[newFilenameArray.length - 1];

          resolve(response); // "worked!" // returns location of image
        })
        .catch((error) => {
          const strError = error.toString().replace("Error:", "");
          reject(strError);
        });
    });
  };

  const removeAreaImage = (filename) => {
    return new Promise(function (resolve, reject) {
      const session_url = `/delete_image`;
      protectedAPI
        .post(session_url, {
          filename: filename,
        })
        .then((response) => {
          // console.log(
          //   `Response from ${session_url} - ${JSON.stringify(
          //     response.status
          //   )} ${JSON.stringify(response.data)}`
          // );
          resolve(response); // returns location of image
        })
        .catch((error) => {
          let errorString = "removeAreaImage error";

          if (_isString(error)) {
            errorString = error.replace("Error:", "");
          }
          console.log(`removeAreaImage error: `, error);
          reject(errorString);
        });
    });
  };

  const changeArea = (areaMessage) => {
    return new Promise(function (resolve, reject) {
      const session_url = `/change_area`;
      protectedAPI
        .post(session_url, {
          ...areaMessage,
        })
        .then((response) => {
          // console.log(
          //   `API_TEST_apiArea saveNewMineLevel > changeArea Response from ${session_url} - ${JSON.stringify(
          //     response.status
          //   )} ${JSON.stringify(response.data)}`
          // );
          resolve(response);
        })
        .catch((error) => {
          let errorString = "API /change_area failed. Check server connection.";

          if (_isString(error)) {
            errorString = error.replace("Error: ", "");
          }
          console.log(`ERROR: newArea changeArea error: `, error);
          reject(errorString);
        });
    });
  };

  const newArea = (image, area) => {
    return new Promise((resolve, reject) => {
      newAreaImage(image) // post image to server
        .catch((error) => {
          if (error instanceof URIError) {
            // handle it
          } else {
            console.log(`newArea newAreaImage error ${error}`);
            //throw error; // throwing this or another error jumps to the next catch
            //return Promise.reject(new Error(error));
            reject(new Error(error));
          }
        })
        .then((response) => {
          const newFilenamePath = response.data.path;
          const newFilenameArray = newFilenamePath.split("/");
          newFilename = newFilenameArray[newFilenameArray.length - 1];

          // #DEBUG
          if (false) {
            console.log(` newArea newAreaImage response`, response);
            console.log(` newArea newAreaImage newFilename`, newFilename);
            console.log(
              ` newArea newAreaImage newAreaMessage(newFilename)`,
              newAreaMessage(newFilename)
            );
          }

          changeArea(newAreaMessage(newFilename))
            .catch((error) => {
              if (error instanceof URIError) {
                // #WIP
                // handle it
              } else {
                console.log(`newArea changeArea error ${error}`);
                //throw error; // throwing this or another error jumps to the next catch
                //return Promise.reject(new Error(error));
                reject(new Error(error));
              }
            })
            .then((response) => {
              console.log(`newArea changeArea response `, response);
              resolve(response);
            });
        })
        .catch((error) => {
          const strError = error.toString().replace("Error:", "");
          reject(strError);

          // Note: returns error to handleSaveNewMineLevel()
        });
    });
  };

  //dummy ending for testing...
  // const response = yield dummyResponse(false); //protectedAPI.post(`/minelevel`, form);
  // console.log("saveNewMineLevel response", response);
  // return response;

  const response = yield newArea(imageForm, newAreaMessage);
  // console.log(
  //   `API_TEST_apiArea saveNewMineLevel get_all_controllers`,
  //   response
  // );
  return response;
}

export function* saveNewMineLevel_OLD_API_MQTT_VERSION(values) {
  const {
    name,
    defaultColor,
    defaultState,
    imageInfo,
    ref1,
    ref2,
    ref3,
    ref4,
    mapFile,
    imageRef1,
    imageRef2,
    imageRef3,
    imageRef4,
    areaRegion,
    namedAreaGroup,
  } = values;

  //const bounds = areaBounds(values);
  //console.log("bounds", bounds);

  // ****************************

  // console.log(
  //   " saveNewMineLevel - new area",
  //   " new name",
  //   name,
  //   " new defaultColor",
  //   defaultColor,
  //   " new defaultState",
  //   defaultState,
  //   " new imageInfo",
  //   imageInfo,
  //   " new ref1",
  //   ref1,
  //   " new ref2",
  //   ref2,
  //   " new ref3",
  //   ref3,
  //   " new ref4",
  //   ref4,
  //   " new mapFile",
  //   mapFile,
  //   " new imageRef1",
  //   imageRef1,
  //   " new imageRef2",
  //   imageRef2,
  //   " new imageRef3",
  //   imageRef3,
  //   " new areimageRef4a",
  //   imageRef4,
  //   " new areaRegion",
  //   areaRegion,
  //   " new namedAreaGroup",
  //   namedAreaGroup
  // );

  // *********** new area *************

  const pf = (n) => Number.parseFloat(n);

  const areaId = name.replace(" ", "_");
  const areaSlug = toSlug(name);

  // prepare message token
  const token = messageToken(userSessionIp);

  let newFilename = mapFile.name; // temporary value, it'll be changed on response

  const newAreaMessage = (filename) => {
    const msg = {
      id: areaId,
      image_filename: filename,
      image_info: `{ "width": ${imageInfo.width}, "height": ${
        imageInfo.height
      }, "backgroundColor": ${JSON.stringify(imageInfo.backgroundColor)} }`,
      named_area_group: namedAreaGroup,
      slug: areaSlug,
      bounds: areaRegion,
      //  [
      //   [737074.9271303766, 9549161.164079294],
      //   [737074.8510577901, 9548828.072795311],
      //   [737654.4260753208, 9548827.910536127],
      //   [737074.9271303766, 9549161.164079294],
      //   [737074.9271303766, 9549161.164079294],
      // ],
      ref_coord: [
        {
          id: `${areaId}:1`,
          utm_zone_letter: ref1.zoneLetter, //"M",
          utm: [pf(ref1.easting), pf(ref1.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref1.zoneNum, //53,
          image_xy: [pf(imageRef1.lat), pf(imageRef1.lng)], //[150.23, 200.45],
        },
        {
          id: `${areaId}:2`,
          utm_zone_letter: ref2.zoneLetter, //"M",
          utm: [pf(ref2.easting), pf(ref2.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref2.zoneNum, //53,
          image_xy: [pf(imageRef2.lat), pf(imageRef2.lng)], //[150.23, 200.45],
        },
        {
          id: `${areaId}:3`,
          utm_zone_letter: ref3.zoneLetter, //"M",
          utm: [pf(ref3.easting), pf(ref3.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref3.zoneNum, //53,
          image_xy: [pf(imageRef3.lat), pf(imageRef3.lng)], //[150.23, 200.45],
        },
        {
          id: `${areaId}:4`,
          utm_zone_letter: ref4.zoneLetter, //"M",
          utm: [pf(ref4.easting), pf(ref4.northing)], //[737376.722, 9549056.885],
          z: 0.0,
          utm_zone_number: ref4.zoneNum, //53,
          image_xy: [pf(imageRef4.lat), pf(imageRef4.lng)], //[150.23, 200.45],
        },
      ],
      floor: 0.0,
      ceiling: 0.0,
      default_color: defaultColor.toUpperCase() || "GREEN",
      default_state: defaultState.toUpperCase() || "ON",
      token: token,
    };

    return msg;
  };
  const newMsg = {
    id: testArea.id,
    image_filename: testArea.image_filename,
    token: token,
  };

  const payloadNew = {
    topic: `area/${testArea.id}/new`,
    qos: 0,
    message: newMsg,
    retained: false,
  };

  const payloadChange = (filename) => {
    const payload = newAreaMessage(filename);

    const msg = {
      topic: `area/${payload.id}/change`,
      qos: 0,
      message: payload,
      retained: false,
    };
    return msg;
  };

  //let payload = payloadChange;

  // send image -->

  // --> create form
  const form = new FormData();
  const types = ["image/png", "image/jpeg", "image/gif"];
  const errs = [];

  if (types.every((type) => mapFile.type !== type)) {
    errs.push(`'${mapFile.type}' is not a supported format`);
  }

  if (mapFile.size > 15000000000) {
    errs.push(`'${mapFile.name}' is too large, please pick a smaller file`);
  }

  // #REVIEW - passes '1' one map file.
  form.append(1, mapFile);

  // --> post form
  const newAreaImage = (form) => {
    return new Promise(function (resolve, reject) {
      const session_url = `/areas`;
      protectedAPI
        .post(session_url, form)
        .then((response) => {
          // console.log(
          //   `Response from ${session_url} - ${JSON.stringify(
          //     response.status
          //   )} ${JSON.stringify(response.data)}`
          // );
          const newFilenamePath = response.data.path;
          const newFilenameArray = newFilenamePath.split("/");
          newFilename = newFilenameArray[newFilenameArray.length - 1];

          resolve(response); // "worked!" // returns location of image
        })
        .catch((error) => {
          const strError = error.toString().replace("Error:", "");
          reject(strError);
        });
    });
  };

  const removeAreaImage = (filename) => {
    return new Promise(function (resolve, reject) {
      const session_url = `/image_delete`;
      protectedAPI
        .post(session_url, {
          filename: filename,
        })
        .then((response) => {
          // console.log(
          //   `Response from ${session_url} - ${JSON.stringify(
          //     response.status
          //   )} ${JSON.stringify(response.data)}`
          // );
          resolve(response); // returns location of image
        })
        .catch((error) => {
          let errorString = "removeAreaImage error";

          if (_isString(error)) {
            errorString = error.replace("Error:", "");
          }
          console.log(`removeAreaImage error: `, error);
          reject(errorString);
        });
    });
  };

  const newArea = (form) => {
    return new Promise(function (resolve, reject) {
      newAreaImage(form) // post image to server
        .catch(function (error) {
          if (error instanceof URIError) {
            // handle it
          } else {
            console.log(`newAreaImage error ${error}`);
            //throw error; // throwing this or another error jumps to the next catch
            return Promise.reject(new Error(error));
          }
        })
        .then(() => {
          // then send add mqtt msg through redux-saga channel buffer
          //m.next();
          channelBufferPublishMsg(payloadChange(newFilename), "MQTT_PUBLISH");
          //console.log("downloadFileChannel ", downloadFileChannel);
        })
        .then((result) => {
          // console.log("result ....");
          // console.log("checkToken ....");
          checkToken(token)
            .catch((error) => {
              console.log(`checkToken ERROR: ${error}`);
              throw error; // throwing this or another error jumps to the next catch
            })
            .then((result) => console.log("FOUND THE ACK AT INDEX: " + result)) // found matching ack
            .then(() => removeAckToken(token)) // delete it
            // .then((result) => resolve(result))
            .then(() => resolve("ACK NEW OK"))
            // if there is no ACK from new msg then clean up by removing the Area image
            .catch(() => {
              removeAreaImage(newFilename) // post image to server
                .catch(function (error) {
                  if (error instanceof URIError) {
                    // handle it
                  } else {
                    console.log(`removeAreaImage error ${error}`);
                    //throw error; // throwing this or another error jumps to the next catch
                    return Promise.reject(new Error(error));
                  }
                })
                .then(() =>
                  reject(
                    new Error(
                      `Deleted file '${newFilename}' OK, but process failed.`
                    )
                  )
                );
            });
          // .... backup code _alt_ to removeAreaImage() etc
          // .catch((error) => {
          //   console.log(`checkToken final error: ${error}`);
          //   reject(error);
          // });
        })
        .catch((error) => {
          const strError = error.toString().replace("Error:", "");
          reject(strError);

          // Note: returns error to handleSaveNewMineLevel()
        });
    });
  };

  //dummy ending for testing...
  // const response = yield dummyResponse(false); //protectedAPI.post(`/minelevel`, form);
  // console.log("saveNewMineLevel response", response);
  // return response;

  const response = yield newArea(form); //protectedAPI.post(`/minelevel`, form);
  //console.log("saveNewMineLevel response", response);
  return response;
}

export function* deleteMineLevel(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  // console.log("deleteMineLevel token", token);
  // console.log("deleteMineLevel id", id);

  const payloadDeleteMsg = {
    topic: `area/${id}/delete`,
    qos: 0,
    message: { id: id, delete: true, token: token },
    retained: false,
  };

  //console.log("payloadDeleteMsg", payloadDeleteMsg);

  const deleteArea = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadDeleteMsg, "MQTT_PUBLISH");

      // checkToken(token)
      //   .catch((error) => {
      //     console.log(`checkToken error: ${error}`);
      //     throw error; // throwing this or another error jumps to the next catch
      //   })
      //   .then((result) => console.log("found the Ack at index: " + result)) // found matching ack
      //   .then(() => removeAckToken(token)) // delete it
      //   // .then((result) => resolve(result))
      //   .then(() => resolve("ACK new OK"))
      //   .catch((error) => {
      //     console.log(`checkToken final error: ${error}`);
      //     reject(error);
      //   });

      //resolve(`${payloadDeleteMsg.topic}`);
      resolve({ topic: `${payloadDeleteMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield deleteArea();
    console.log("deleteArea response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false); //protectedAPI.post(`/minelevel`, form);
    //console.log("deleteArea response", response);
    return response;
  }
}

// #REVIEW - WIP, yet to implement server api support

export function* deleteMineLevelPost(id) {
  const token = messageToken(userSessionIp);
  const payloadDeleteMsg = {
    topic: `area/${id}/delete`,
    id: id,
    token: token,
  };
  // console.log(
  //   `API_TEST_apiArea deleteMineLevelPost delete_area payloadDeleteMsg`,
  //   payloadDeleteMsg
  // );
  const response = yield myApi.post(`/api/delete_area`, payloadDeleteMsg);
  //console.log(`API_TEST_apiArea deleteMineLevelPost delete_area`, response);
  return { topic: `${payloadDeleteMsg.topic}`, token: token };
}

// **********************************
// #TODO - this is not used, but left in place as it is likely,
// that an 'update' will be used as well as 'save...'
//
export function* updateMineLevel({ id, name, mapFile, imageRef1, imageRef2 }) {
  //console.log("updateMineLevel", id, name, mapFile, imageRef1, imageRef2);

  const form = new FormData();
  form.append("name", name);
  form.append("mapFile", mapFile);
  form.append("imageRef1", JSON.stringify(imageRef1));
  form.append("imageRef2", JSON.stringify(imageRef2));
  const response = yield protectedAPI.post(`/minelevel/${id}`, form);
  return response;
}
