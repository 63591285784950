import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reset, isSubmitting, isPristine } from "redux-form";

import {
  Header,
  Segment,
  Icon,
  Accordion,
  Form,
  Grid,
  Button,
  Popup,
} from "semantic-ui-react";

import isEqual from "lodash/isEqual";

import { renderSelect, renderField, renderCheckbox } from "admin/form-field";
import ChooseEditorModeField from "admin/bulk-positioning/UPSBulkEditPage/ChooseEditorModeField";
import { getPortColor } from "admin/bulk-positioning/UPSBulkEditPage/port-colors";
import PortSummaryFireflyDetails from "admin/bulk-positioning/UPSBulkEditPage/PortSummaryFireflyDetails";
import selector from "admin/bulk-positioning/UPSBulkEditPage/form-value-selector";
import { getControllerById } from "components/WebWorker/reducer";

import {
  allowedLightColors as _allowedLightColors,
  allowedLightStates as _allowedLightStates,
} from "components/ConfigJs";

const normalise = (value, type) => {
  switch (type) {
    case "on_time":
      return (value >= 0 && parseInt(value, 10)) || 0;
    case "off_time":
      return (value >= 0 && parseInt(value, 10)) || 0;
    case "train":
      return (value >= 0 && parseInt(value, 10)) || 0;
    default:
      return value;
  }
};

class PortSummaryField extends Component {
  state = {
    expanded: false,
  };

  handleClick = (e) => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.expanded !== nextState.expanded) {
      return true;
    }

    return !isEqual(this.props, nextProps);
  }

  resetForm = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  render() {
    const {
      portNumber,
      fireflies,
      name,
      locationHint,
      showSection,
      //
      submitting,
      pristine,
    } = this.props;
    const { expanded } = this.state;

    const allowedLightColors = _allowedLightColors();
    const allowedLightStates = _allowedLightStates();

    const color = getPortColor(portNumber);

    const {
      isEmergencyEventSettings,
      isTriggerEventSettings,
      form_emergencyPortEnable,
      form_triggerPortEnable,
    } = showSection;

    return (
      <Segment color={color}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header>
                Port {portNumber} <small>{locationHint}</small>
              </Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Group inline>
                      {/* #NOTE - enable this section to allow control over Emergency events */}
                      {false && isEmergencyEventSettings && (
                        <Field
                          name={`${name}.emergencyEventSettings.enable`}
                          label="Enable Port Emergency Event"
                          component={renderCheckbox}
                          //disabled={true} // disable changes via UI. Only controller can change.
                        />
                      )}
                      {isTriggerEventSettings && (
                        <Field
                          name={`${name}.triggerEventSettings.enable`}
                          label="Enable Port Trigger Event"
                          component={renderCheckbox}
                        />
                      )}
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Button.Group floated="right">
                      <Popup
                        content={"Save changes"}
                        trigger={
                          <Button
                            color="green"
                            icon
                            onClick={() => this.props.onSubmit}
                            disabled={pristine || submitting}
                          >
                            <Icon name="save"></Icon>
                          </Button>
                        }
                      />
                      <Button.Or />
                      <Popup
                        content={"Reset changes"}
                        trigger={
                          <Button
                            icon
                            onClick={(e) => this.resetForm(e)}
                            disabled={pristine || submitting}
                          >
                            <Icon name="repeat"></Icon>
                          </Button>
                        }
                      />
                    </Button.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isEmergencyEventSettings && form_emergencyPortEnable && (
          <Segment>
            <Header as="h3">Emergency Event Settings</Header>
            <div>
              <Form.Group style={{ flexFlow: "row wrap" }}>
                <Field
                  name={`${name}.emergencyEventSettings.color`}
                  label="Active Color"
                  component={renderSelect}
                  options={allowedLightColors}
                  style={{
                    paddingTop: "0.5em",
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                  }}
                />
                <Field
                  name={`${name}.emergencyEventSettings.led_state`}
                  label="Light Active State"
                  component={renderSelect}
                  options={allowedLightStates}
                  style={{
                    paddingTop: "0.5em",
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                  }}
                />
                <Field
                  name={`${name}.emergencyEventSettings.off_time`}
                  label="Off Time (mS)"
                  normalize={(value) => normalise(value, "off_time")}
                  component={renderField}
                  style={{ paddingTop: "0.5em" }}
                />
                <Field
                  name={`${name}.emergencyEventSettings.on_time`}
                  label="On Time (mS)"
                  normalize={(value) => normalise(value, "on_time")}
                  component={renderField}
                  style={{ paddingTop: "0.5em" }}
                />
                <Field
                  name={`${name}.emergencyEventSettings.train`}
                  label="Train Time (mS)"
                  normalize={(value) => normalise(value, "train")}
                  component={renderField}
                  style={{ paddingTop: "0.5em" }}
                />
              </Form.Group>
            </div>
          </Segment>
        )}
        {isTriggerEventSettings && form_triggerPortEnable && (
          <Segment>
            <Header as="h3">Trigger Event Settings</Header>
            <Form.Group style={{ flexFlow: "row wrap" }}>
              <Field
                name={`${name}.triggerEventSettings.color`}
                label="Active Color"
                component={renderSelect}
                options={allowedLightColors}
                style={{
                  paddingTop: "0.5em",
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                }}
              />
              <Field
                name={`${name}.triggerEventSettings.led_state`}
                label="Light Active State"
                component={renderSelect}
                options={allowedLightStates}
                style={{
                  paddingTop: "0.5em",
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                }}
              />
              <Field
                name={`${name}.triggerEventSettings.off_time`}
                label="Off Time (mS)"
                normalize={(value) => normalise(value, "off_time")}
                component={renderField}
                style={{ paddingTop: "0.5em" }}
              />
              <Field
                name={`${name}.triggerEventSettings.on_time`}
                label="On Time (mS)"
                normalize={(value) => normalise(value, "on_time")}
                component={renderField}
                style={{ paddingTop: "0.5em" }}
              />
              <Field
                name={`${name}.triggerEventSettings.train`}
                label="Train Time (mS)"
                normalize={(value) => normalise(value, "train")}
                component={renderField}
                style={{ paddingTop: "0.5em" }}
              />
            </Form.Group>
          </Segment>
        )}
        <Segment>
          <Header as="h3">FireFlys</Header>
          <Field
            name={`${name}.positionMode`}
            component={ChooseEditorModeField}
            color={color}
          />

          <Accordion>
            <Accordion.Title
              active={expanded}
              index={0}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              {fireflies.length} FireFlys
            </Accordion.Title>
            <Accordion.Content active={expanded}>
              {expanded && <PortSummaryFireflyDetails fireflies={fireflies} />}
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Segment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    reset: () => dispatch(reset("upsBulkPositioningForm")),
  };
};

const mapStateToProps = (state, props) => {
  const { name, onSubmit } = props;

  const values = selector(state, name);

  const isEmergencyEventSettings = selector(state, "isEmergencyEventSettings");
  const isTriggerEventSettings = selector(state, "isTriggerEventSettings");

  const form_triggerPortEnable = selector(
    state,
    `${name}.triggerEventSettings.enable`
  );
  const form_emergencyPortEnable = selector(
    state,
    `${name}.emergencyEventSettings.enable`
  );

  const showSection = {
    isEmergencyEventSettings,
    isTriggerEventSettings,
    form_emergencyPortEnable,
    form_triggerPortEnable,
  };

  const form_ups = selector(state, "ups");

  const ups = getControllerById(state, form_ups.id);

  const portIndex = values.portNumber - 1;

  const area = ups?.topology?.area;
  const location = ups?.topology?.location;

  let locationHint = "";

  if (area && location && location[portIndex]) {
    locationHint = `(${area} - ${location[portIndex]})`;
  }

  return {
    ...values,
    locationHint,
    showSection,
    //
    onSubmit,
    submitting: isSubmitting("upsBulkPositioningForm")(state),
    pristine: isPristine("upsBulkPositioningForm")(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortSummaryField);
