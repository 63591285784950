import React, { Component } from "react";
import { connect } from "react-redux";
import { SetMapImage } from "components/Settings/actions";
import { getMapImages } from "components/Settings/reducer";
import { getAllAreaStatuses } from "components/WebWorker/reducer";
import { getFolderFileNamesListById } from "components/Settings/reducer";

function AreaImages({ ...props }) {
  const { areaIds, areaImageFileNames, mapImagesFromState, SetMapImage } =
    props;

  const mapImageIds = [...mapImagesFromState.map((img) => img.id)];

  const content = areaIds.map((areaId) => {
    //
    const imageFilename = areaImageFileNames.find?.(
      (item) => item.id === areaId
    )?.src;

    const imageSrc = process.env.PUBLIC_URL + "/areas/" + imageFilename;

    var xhr = new XMLHttpRequest();
    xhr.open("GET", imageSrc);
    xhr.responseType = "blob";
    xhr.onload = response;
    xhr.send();

    function response(e) {
      // console.log(
      //   `AreaImageImgBlob IMAGE LOADED: this.response`,
      //   this.response
      // );
      let reader = new FileReader();
      reader.readAsDataURL(this.response);
      reader.onloadend = function () {
        let base64data = reader.result?.replace(
          /^data:image\/(png|jpg);base64,/,
          ""
        );
        console.log(
          `AreaImageImgBlob IMAGE LOADED: blob ${imageSrc}`,
          base64data
        );
        SetMapImage({
          id: areaId,
          imageData: base64data,
        });
      };
    }

    return null;
  });

  return content;
}

const mapStateToProps = (state, props) => {
  const areas = getAllAreaStatuses(state) || [];
  const areaIds = areas.map((area) => area?.id);
  const areaImageFileNames = areas.map((area) => {
    return { id: area.id, src: area.image_filename };
  });

  return {
    areaIds,
    areaImageFileNames,
    folderFilesList: getFolderFileNamesListById(state, "areas"),
    mapImagesFromState: getMapImages(state),
  };
};

export default connect(mapStateToProps, { SetMapImage })(AreaImages);
