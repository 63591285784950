import React from 'react'

function round (val, places = 4) {
  switch (typeof val) {
    case 'number':
      const rnd = Math.pow(10, places)
      return Math.round(val * rnd) / rnd
    case 'string':
      return round(Math.parseFloat(val), places)
    default:
      return val
  }
}

const LatLngSummary = ({ position, displayDefault = '-' }) => {
  let content = displayDefault
  if (position) {
    const { lat, lng } = position
    content = [
      <div key='lat'>Lat: {round(lat)}</div>,
      <div key='lng'>Lng: {round(lng)}</div>
    ]
  }
  return <div>{content}</div>
}

export default LatLngSummary
