import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

let OperatorMenu = ({ isUserSuper, level, strings }) => {
  return (
    <Dropdown item simple text={strings?.["Status"]} className="link item">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/status/controller`}>
          {strings?.["Controller Status"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/status/firefly`}>
          {strings?.["FireFly Status"]}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>{strings?.["Faults"]}</Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to={`/status/faults/firefly`}>
          {strings?.["FireFly Faults"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/status/faults/battery`}>
          {strings?.["Battery Faults"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/status/faults/network`}>
          {strings?.["Network Faults"]}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/status/faults/controller`}>
          {strings?.["Controller Reports"]}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

OperatorMenu.defaultProps = {};

OperatorMenu = connect((state) => ({
  isUserSuper: isUserSuper(state),
  isUserAdmin: isUserAdmin(state),
  isUserUser: isUserUser(state),
  isUserGuest: isUserGuest(state),
}))(OperatorMenu);

export default OperatorMenu;
