// TestTable-working-preComponentize-10Jun1219.js
// https://medium.com/@subalerts/create-dynamic-table-from-json-in-react-js-1a4a7b1146ef
// draggable row - https://codesandbox.io/s/4jw25r7l19?file=/src/index.js:1333-1353
// hooks parent to children- https://stackoverflow.com/questions/55726886/react-hook-send-data-from-child-to-parent-component

import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Icon, Button, Input } from "semantic-ui-react";

import _isEmpty from "lodash/isEmpty";
//import _isEqual from "lodash/isEqual";
import { getObjectDiff } from "utils/getObjectDiff";

import { microTime } from "utils/microTime";
import { messageToken } from "utils/messageToken";

import DraggableTableRow from "./DraggableTableRow";

import { namedAreasGeoJson } from "./mqttMessages";

import { mqttPublish } from "components/WebWorker/actions";

import {
  namedAreaSetDeleteSelections,
  namedAreaClearDeleteSelections,
  namedAreaSetShowHideSelections,
  namedAreaClearShowHideSelections,
  namedAreasSetIsDirty,
  namedAreasClearIsDirty,
  removeAcks,
} from "components/WebWorker/actions";

import {
  getNamedAreaInfos,
  GetMapNamedAreasReducedProperties,
  getNamedAreaEvents,
  getAcks,
  getIsDirty,
} from "components/WebWorker/reducer";

import {
  GetLocalMap,
  GetLocalMapNamedAreasReducedProperties,
} from "components/Map/reducer.js";

import { GeoJsonToMqttNamedAreaChange } from "./utils-mqttMessages";

import { makeRandomString } from "../../../utils/make-random";

class TableMade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      // rows: [],
      eventColumns: [], // which column set - true/false = active/inactive
      events: [],
      eventsSelected: [],
      deleteSelected: [], // named area ids of the rows selected to be deleted
      hideSelected: [], // named area ids of the rows selected to be hidden
      isDirty: false,
      acks: [],
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
    this.setState({ events: this.props.events });
    this.setState({ acks: this.props.acks });

    // set # of events states in eventColumns based on # of buttons
    // #Review/ToDO/WIP - for the moment this is fixed to 4

    const eventColumnsDefaults = [false, false, false, false];
    this.setState({ eventColumns: eventColumnsDefaults });
  }

  componentDidUpdate(prevProps, prevState) {
    // update acks
    // update new named area data
    if (prevProps.data !== this.props.data) {
      this.setState({ acks: this.props.acks });
    }

    // #REVIEW - possible
    // if any setting has been changed (i.e. isDirty) no longer update from mqtt state
    // _but_ update if there is a new item properties.status = "drawn"
    //
    // if there is an current event then after setting change this will be resent to update the event.
    const { isDirty } = this.state;

    // #REVIEW/ #TODO - need to support passing the parentId through here
    // set global state if dirty
    const parentId = "parentNamedArea";

    // #REVIEW / TODO
    // move this filter to a common location
    const isParentDirty =
      this.props.parentIsDirty.filter((namedArea) => namedArea === parentId)
        .length > 0;

    if (isParentDirty && !isDirty) {
      this.setState({ isDirty: true });
    }

    // this form is dirty
    if (!isParentDirty && isDirty) {
      // but the parent is not currently dirty
      this.props.namedAreasSetIsDirty(parentId); // make it dirty
    }

    // update new named area data
    if (prevProps.data !== this.props.data) {
      // check if any namedAreas have been drawn i.e. isDirty
      // if properties.status === "drawn" then set the form as dirty
      this.props.data.forEach((item, idx) => {
        if (item.properties.status === "drawn") {
          // set to dirty
          this.setState({ isDirty: true });
        }
      });

      // if Dirty we do not want to update/overwrite the current color state of the check boxes
      // when there is an updated data message
      // make a copy of the button arrays from this state
      // overwrite the incoming data giving preference to the current state

      // #REVIEW - this gives copies this.state unless doesn't exist at which point it collects the next sequential values from this.props
      // BUT this assumes sequential
      // need to fix to reference namedArea IDs instead
      // this.props.data is the incoming update
      // this.state.data is the current data
      let newData = this.props.data;

      // sort the incoming data (which is so far not reordered) by the properties.priority
      newData.sort(
        (a, b) =>
          parseFloat(a.properties.priority) - parseFloat(b.properties.priority)
      );

      // if the form is dirty need to retain all setting for the current data i.e. row posn, state of eye check, color box settings etc.
      // append any new data as the last row.

      if (isDirty) {
        let mergedData = [];
        newData.forEach((namedArea, idx) => {
          // if an existing namedArea (i.e. same propertie.id) exists overwrite the newData to keep dirty changes
          const index = this.state.data.findIndex(
            (x) => x.properties.id === namedArea.properties.id
          );
          if (index > -1) {
            mergedData[idx] = this.state.data[index];
          } else {
            mergedData[idx] = namedArea;
          }
        });

        // sort the merge data by existing row order

        // get a list of the current rows by Id
        let currentNamedRowIds = [];
        this.state.data.forEach((namedArea, idx) => {
          currentNamedRowIds.push(namedArea.properties.id);
        });

        // sort newData (properties.id) based on order of currentNamedRowIds
        // #REVEW - NOTE: the new namedArea will be added at the top!
        mergedData.sort(function (a, b) {
          return (
            currentNamedRowIds.indexOf(a.properties.id) -
            currentNamedRowIds.indexOf(b.properties.id)
          );
        });

        newData = mergedData;
      }

      this.setState({ data: newData });
    }

    // update new named area event data
    if (prevProps.events !== this.props.events) {
      // && !isDirty // <--------#WIP disable...testing. Allow update of events while dirty

      this.setState({ events: this.props.events });

      // event received.
      // check event state based on:
      // - active
      // - id (same as key)
      // - priority - for the moment this is the button column

      // console.log("qqq this.props.events rx", this.props.events);
      // console.log("qqq this.state.data rx", this.state.data);

      // #REVEW - FIX THIS ...ugly as!
      // update indeterminate
      const namedAreaEvents = this.props.events;
      if (namedAreaEvents !== undefined && !_isEmpty(namedAreaEvents)) {
        // #REVIEW/TODO - only supporting on 'parent' group named area, precanned i.e. button checked will be same for all
        // therefore only check the first event id i.e. id[0]
        const ids = Object.keys(namedAreaEvents);

        // update the state data with event button colors
        let newData = this.state.data;

        //console.log("qqq event ids", ids);

        // update the color boxes from the received events

        // map the events.active_color = to data where events.id === namedArea.id &&
        // events.priority = button..priority

        newData.forEach((namedArea, idx) => {
          //console.log("qqq newData.namedArea", namedArea);
          if (ids.includes(namedArea.properties.id)) {
            // find the new color from the event received
            const newColor =
              namedAreaEvents[namedArea.properties.id].active_color;

            // find the index of the button object which needs to be updated
            // i.e. event priority = button.priority <---- see SPECIAL NOTE below
            //
            const indexOfButtonObject = namedArea.properties.button.findIndex(
              (x) =>
                x.priority === namedAreaEvents[namedArea.properties.id].priority
            );
            // update the button object color with the new Color
            newData[idx].properties.button[indexOfButtonObject].color =
              newColor.toLowerCase(); // not "GREEEN" but "green"
          }
        });

        // update the state with the color from the events
        this.setState({ data: newData });

        // #REVIEW/TODO/WIP
        // #SPECIAL NOTE - the filtering above assumes the button row is the priority.
        // In practise the row of a button for (say) travelway might be 3 but the priority might be 6.
        //
        // the following button key have been added to allow more flexibilty but this is not yet implemented
        //
        // active: "true", // allows enable/disable of button column
        // group: 0, // grouping of columns
        // column: 0 // index for position of column - as different to priority

        // check boxes have an indeterminate state until cleared by a confirmation of the
        // event being set.
        // clear eventsSelected for indeterminate state upon receipt of state update.

        // get current state
        let eventsSelected = this.state.eventsSelected;

        // only one event state is checked
        // #REVIEW/TODO - only supporting on 'parent' group named area, precanned i.e. button checked will be same for all
        // therefore only check the first event id i.e. id[0]
        // UPDATE - since LEVEL WIDE is an event use '2'

        //console.log("eventColumns ids", ids);
        const notMineLevels = ids.find(
          (element) =>
            element !== "DMLZ_Extraction" && element !== "DMLZ_Undercut"
        );
        //console.log("eventColumns notMineLevels", notMineLevels);

        // #REVIEW - only support for one checkbox OR in button group
        // priority is base 1 (0 = lowest level i.e. mine level), but button ref is base 0

        let col = 1;
        if (
          notMineLevels !== undefined && // no mine levels
          namedAreaEvents[notMineLevels] !== undefined && // no named areas
          namedAreaEvents[notMineLevels].hasOwnProperty("priority") // accommodate new situation with no events/named areas
        ) {
          col = namedAreaEvents[notMineLevels].priority - 1; //ids[2]
        }

        //console.log("eventColumns namedAreaEvents", namedAreaEvents);
        //console.log("eventColumns col", col);

        // remove the col set from items in eventSelected array
        eventsSelected = eventsSelected.filter((item) => item !== col);
        this.setState({ eventsSelected: eventsSelected });

        // keep track of which column is set
        // #REVIEW - only support for one checkbox OR in button group
        const { eventColumns } = this.state;

        // clear the area to all 'false'
        for (let i = 0; i < eventColumns.length; ++i) {
          eventColumns[i] = false;
        }
        // set active event to true
        // e.g. [ true, false, false, false, false]
        // is an active event in column #1

        eventColumns[col] = true;
        //console.log("eventColumns", eventColumns);
        this.setState({ eventColumns: eventColumns });
        //this.setState({ eventColumns: [col] });
      }
    }
  }

  swap = (a, b) => {
    let { data } = this.state;

    console.log("qqq swap before data", data);
    data[a] = data.splice(b, 1, data[a])[0];
    this.setState({
      ...this.state,
      data,
    });
    console.log("qqq swap after data", data);

    // row moved i.e. draw layer order has changed so now isDirty
    this.setState({ isDirty: true });
  };

  onColorClick = (props) => {
    //console.log("qqq onColorClick ", props);

    // #WIP - disable dirty if color change -
    // now color change is for events in testing
    //
    if (true) {
      this.setState({ isDirty: true });
    }

    const { row, column } = props;
    const { data } = this.state;
    //  #REVIEW - why need to deepClone here?

    let newData = JSON.parse(JSON.stringify(data)); /// <---deepClone

    const colors = ["green", "amber", "blue", "red", "off"];

    const currentColor = newData[row].properties.button[column].color;
    const index = colors.findIndex((x) => x === currentColor);
    let newColor;
    index + 1 > colors.length - 1
      ? (newColor = colors[0])
      : (newColor = colors[index + 1]);

    newData[row].properties.button[column].color = newColor;

    this.setState({ data: newData });
  };

  onEventClick = (col, active) => {
    const { data } = this.state;

    //console.log("qqq publishMessage click event, data", col, active, data);

    let newData = JSON.parse(JSON.stringify(data)); /// <---deepClone so that reverse (see below) does not alter data object
    // NOTE: named areas are published in reverse so the 'top' in the list
    // comes out last so sets highest lighting priority
    newData.reverse().forEach((namedArea, index) => {
      //console.log("qqq namedArea", namedArea);
      const eventMsg = {
        id: namedArea.properties.id,
        // this is the priority of the button. 0 is base level of lights priority, so start from 1...
        // <----#REVIEW - should get this from the button setup
        priority: col + 1, // <--- this is the button which has been checked!
        active: active,
        active_color: namedArea.properties.button[col].color,
        active_state: "ON",
        timestamp: microTime(), // new Date().getTime(),
        precanned: 0,
        token: messageToken(),
      };
      //console.log("qqq eventNamedArea row ", col, "msg ", eventMsg);

      // set eventsSelected for indeterminate state
      const eventsSelected = this.state.eventsSelected.concat(col);
      this.setState({ eventsSelected: eventsSelected });

      this.props.onSendMqtt({
        topic: `named_area/${eventMsg.id}/event`,
        qos: 0,
        message: eventMsg,
        retained: true,
      });
    });
  };

  onDeleteClick = (props) => {
    // console.log("qqq onDeleteClick ", props);
    // console.log("qqq this.state.deleteSelected", this.state.deleteSelected);

    const { row } = props;
    const { data } = this.state;

    const deleteRowId = data[row].properties.id;

    // deleteSelected keeps record rows which have been selected for deletion
    let { deleteSelected } = this.state;

    if (deleteSelected.includes(deleteRowId)) {
      // remove it
      deleteSelected.splice(deleteSelected.indexOf(deleteRowId), 1);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      // console.log("namedAreaClearDeleteSelections deleteRowId", deleteRowId);
      // this.props.namedAreaClearDeleteSelections({
      //   area: "DMLZ_Extraction",
      //   id: deleteRowId,
      // });
      // also hide it too!
      this.props.namedAreaClearShowHideSelections({
        area: "DMLZ_Extraction",
        id: deleteRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }
    // add it
    else {
      deleteSelected.push(deleteRowId);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      // this.props.namedAreaSetDeleteSelections({
      //   area: "DMLZ_Extraction",
      //   id: deleteRowId,
      // });
      // also hide it too!
      this.props.namedAreaSetShowHideSelections({
        area: "DMLZ_Extraction",
        id: deleteRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }

    console.log(
      "hhh namedAreaClearDeleteSelections deleteSelected",
      deleteSelected
    );

    // updated state sent, so no longer dirty
    this.setState({ deleteSelected: deleteSelected });

    // updated state sent, so no longer dirty
    this.setState({ isDirty: true });
  };

  onShowHideClick = (props) => {
    // console.log("qqq onShowHideClick ", props);

    const { row } = props;
    const { data } = this.state;

    const hideRowId = data[row].properties.id;

    // hideSelected keeps record rows which have been selected for hide
    let { hideSelected } = this.state;

    if (hideSelected.includes(hideRowId)) {
      // remove it
      hideSelected.splice(hideSelected.indexOf(hideRowId), 1);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      this.props.namedAreaClearShowHideSelections({
        area: "DMLZ_Extraction",
        id: hideRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }
    // add it
    else {
      hideSelected.push(hideRowId);
      // #REVIEW/TODO - hard coded area. See also namedAreaSetDeleteSelections
      //
      this.props.namedAreaSetShowHideSelections({
        area: "DMLZ_Extraction",
        id: hideRowId,
      });
      // #FIX - passing hideRowId because otherwise it make the structure
      // [{area: area, id: [id1, id2]}] rather than the required [{area: area, id: id1} , {area: area, id: id2}]
      // need to change to be consistent with namedAreaSetDeleteSelections
    }

    //console.log("hhh hideSelected", hideSelected);

    // updated state of show and hide
    this.setState({ hideSelected: hideSelected });

    // show & hide do not change data so not dirty
    // this.setState({ isDirty: true });
  };

  getRowsData = () => {
    const { data: items, deleteSelected } = this.state;

    // #TODO - use the items list to determine which check box is selected
    // and change the background color
    if (items === undefined) return null;

    let rowStyles = [];
    items.forEach((item, idx) => {
      // filter deleteSelected and return an array in row order
      // e.g.  rowStyles = [false, true, false];
      rowStyles.push(deleteSelected.includes(item?.properties?.id));
    });

    // items sorted by "priority"
    // priority may be integer or string.
    // #REVIEW - force to integer for now
    // const items = this.state.data.sort((a, b) => {
    //   return a.priority.toString().localeCompare(b.priority);
    // });

    //const keys = this.getKeys();
    const itemsLength = items.length;

    return items.map((row, index) => {
      return (
        <DraggableTableRow
          rowStyles={rowStyles}
          key={index}
          i={index}
          action={this.swap}
          onColorClick={this.onColorClick}
          onDeleteClick={this.onDeleteClick}
          onShowHideClick={this.onShowHideClick}
        >
          <RenderRow
            key={index}
            index={index}
            data={row}
            dataLength={itemsLength}
            onColorClick={this.onColorClick}
            onDeleteClick={this.onDeleteClick}
            onShowHideClick={this.onShowHideClick}
          />
        </DraggableTableRow>
      );
    });
  };

  // [SAVE CHANGES] button
  sendNamedAreaSetupMqtt = () => {
    const { data: namedAreas } = this.state;

    let { deleteSelected } = this.state; // let -> modified when appending drawn shapes for cleanup

    if (namedAreas === undefined) return null;

    // process namedAreas

    // 1 - if namedAreas marker for deletion, send these 1st and delete from namedAreas
    // 2 - then send updated namedAreas in reverse order and with priority saved
    // 3 - send updated event messages applying to 'changed' named areas

    // 1 - ...........
    let changedNamedAreas = [];

    namedAreas.forEach((namedArea, idx) => {
      const {
        properties: { id },
      } = namedArea;

      // if named area is selected for deletion
      if (deleteSelected.includes(id)) {
        // send a delete message
        this.props.onSendMqtt({
          topic: `named_area/${id}/delete`,
          qos: 0,
          message: {
            id: id,
            delete: true,
            token: messageToken(),
          },
          retained: false,
        });
      } else {
        changedNamedAreas.push(namedArea);
      }
    });

    // 2 - send changed namedAreas
    //
    // send the messages in reverse so the top namedArea in the UI (row 0) is sent last.
    // namedAreas of the same priority apply in order, so last received has highest priority.

    changedNamedAreas.reverse().forEach((namedArea, idx) => {
      const {
        properties: { id },
      } = namedArea;

      const {
        properties: { name, parent, area, origin, button },
      } = namedArea;

      // priority is set by the row position
      // since the messages are sent out in reverse the priority is also reversed
      // lowest priority is 1
      const priority = namedAreas.length - idx;

      const {
        geometry: { coordinates, type },
      } = namedArea;

      // #TODO
      // #REVIEW - add these to config dlg
      const default_color = "green";
      const default_state = "OFF";

      // send timestamp as a token
      const token = new Date().getTime().toString();

      // create named_area mqtt message
      const newMsg = GeoJsonToMqttNamedAreaChange(
        id,
        area,
        name,
        coordinates,
        type,
        priority,
        origin,
        parent,
        button,
        default_color,
        default_state,
        token
      );

      this.props.onSendMqtt({
        topic: `named_area/${id}/change`,
        qos: 0,
        message: newMsg,
        retained: false,
      });
    });

    // #REVIEW/TODO - this should be changed to only send for active named areas,
    // and after we have confirmation that the named areas have been deleted

    // 3- ........ resend any events that may be running
    // eventColumns keeps record of button columns selected and display as indeterminate

    // #WIP - disabled sending events when SAVE CHANGES is selected
    if (false) {
      const { eventColumns } = this.state;
      let { eventsSelected } = this.state;

      eventColumns.forEach((eventColumn, index) => {
        if (eventColumn) {
          this.onEventClick(index, true); // send mqtt via onEventClick
          eventsSelected.push(index);
        }
      });
    }

    // temp commented out as duplicated in onEventClick...
    // set eventsSelected for indeterminate state, since this event is pending confirmation
    // #REVIEW - supports only one ????
    //    this.setState({ eventsSelected: eventsSelected });

    // after sending the update, cleanup the list and the map .........
    // Delete all the named areas from the map editlayers list,
    // if the named area has been successful saved the list will update with receipt of next mqtt named_area/+/info

    // let drawnNamedAreas = [];
    // namedAreas.forEach((namedArea, idx) => {
    //   drawnNamedAreas.push(namedArea.properties.id);
    // });
    // deleteSelected = [...deleteSelected, ...drawnNamedAreas];

    // //    console.log("ffff deleteSelected", deleteSelected);
    // // #TODO - this is hard coded for DMLZ_Extraction <-----------##########FUX THIS!
    // let deleteSelectedtoMap = [];
    // deleteSelected.forEach((value, idx) => {
    //   deleteSelectedtoMap.push({ area: "DMLZ_Extraction", id: value });
    // });
    // //    this.props.namedAreaSetDeleteSelections(deleteSelectedtoMap);

    // ACK PROCESSING
    // wait for an ack to the mqtt messages before proceeding with the cleanup

    console.log("MQTT_ACKS_UPDATE this.state.acks", this.state.acks);
    const { acks } = this.state;
    console.log("acks", acks);
    // this works to remove acks - disable for the moment
    // if (acks.length > 0) {
    //   this.props.removeAcks(acks[0].ts);
    // }

    // send all namedAreas to be deleted from the map
    // note - namdAreas includes those deleted or changed
    const parentId = "parentNamedArea";
    namedAreas.forEach((namedArea, idx) => {
      this.props.namedAreaSetDeleteSelections({
        area: parentId,
        id: namedArea.properties.id,
      });
    });

    // clear named areas deleted for selection as these have now been removed
    this.setState({ deleteSelected: [] });

    // updated named area state sent, so no longer dirty
    // locally
    this.setState({ isDirty: false });
    // globally

    this.props.namedAreasClearIsDirty(parentId);
  };

  resetIsDirty = () => {
    // reset changes

    // #REVIEW/TODO/FIX - this is a fudge until the components are modularised so can pass reset to map component etc
    console.log(
      "FIX THIS - RESET RELOADS THE PAGE. INSTEAD RELOAD THE COMPONENT"
    );
    window.location.reload(false);

    this.setState({ isDirty: false });
  };

  isEventSelected = (col) => {
    const eventsSelected = this.state.eventsSelected;
    if (eventsSelected.includes(col)) {
      return true;
    } else {
      return false;
    }
  };

  isEventSet = (col) => {
    // get select state from json object for button
    const namedAreaEvents = this.state.events;

    if (namedAreaEvents !== undefined && !_isEmpty(namedAreaEvents)) {
      // #REVIEW/TODO - only supporting on 'parent' group named area, precanned i.e. button checked will be same for all
      // therefore only check the first event id i.e. id[0]

      // could be multiple events set for a named area so loop to check

      const ids = Object.keys(namedAreaEvents);

      // #REVIEW/TODO - only supporting on 'parent' group named area, precanned i.e. button checked will be same for all
      // therefore only check the first event id i.e. id[0]
      // UPDATE - since LEVEL WIDE is an event use '2'

      const notMineLevels = ids.find(
        (element) =>
          element !== "DMLZ_Extraction" && element !== "DMLZ_Undercut"
      );

      if (
        notMineLevels !== undefined && // no mine levels
        namedAreaEvents[notMineLevels] !== undefined && // no named areas
        namedAreaEvents[notMineLevels].hasOwnProperty("priority") // accommodate new situation with no events/named areas
      ) {
        if (
          col === namedAreaEvents[notMineLevels].priority - 1 &&
          namedAreaEvents[notMineLevels].active === true // ids[2]
        ) {
          // change checkbox display
          return true;
        } else {
          return false;
        }
      }
    }
  };

  isDeleteAllSelected = () => {};

  onDeleteAllClicked = () => {};

  render() {
    //
    //
    //

    // #TODO - style the row if it is selected for Deletion - deleteSelected
    //const myReference = this.rowStyle1.current; // The DOM element
    //myReference.style.backgroundColor = "red"; // The style you want to apply
    //this.refs.rowStyle1.style.background = "red";
    //

    const { isDirty } = this.state;

    const iconButtons = {
      padding: "0.5em",
    };

    // padding cell for top row
    // 4: drag, active checkbox, show/hide eye icon, named area description
    const headerCellsRowTop = (
      <>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
        <Table.HeaderCell />
      </>
    );

    return (
      <div>
        <style>{`
          .draggable {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
          }
        `}</style>
        <Table>
          <Table.Header>
            <Table.Row>
              {headerCellsRowTop}
              <Table.HeaderCell>
                <Button icon style={iconButtons}>
                  <Icon name="eye" />
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button icon style={iconButtons}>
                  <Icon name="eye" />
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button icon style={iconButtons}>
                  <Icon name="eye" />
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button icon style={iconButtons}>
                  <Icon name="eye" />
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon style={iconButtons} name="trash" />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <Checkbox />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Button icon style={iconButtons}>
                  <Icon name="eye" />
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <Checkbox
                  checked={this.isEventSet(0)}
                  indeterminate={this.isEventSelected(0)}
                  onClick={() => this.onEventClick(0, !this.isEventSet(0))}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Checkbox
                  checked={this.isEventSet(1)}
                  indeterminate={this.isEventSelected(1)}
                  onClick={() => this.onEventClick(1, !this.isEventSet(1))}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Checkbox
                  checked={this.isEventSet(2)}
                  indeterminate={this.isEventSelected(2)}
                  onClick={() => this.onEventClick(2, !this.isEventSet(2))}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Checkbox
                  checked={this.isEventSet(3)}
                  indeterminate={this.isEventSelected(3)}
                  onClick={() => this.onEventClick(3, !this.isEventSet(3))}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Checkbox
                  checked={this.isDeleteAllSelected}
                  onClick={() => this.onDeleteAllClicked()}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {/* <TableBody>
            <TableCell>1</TableCell>
            <TableCell>1</TableCell>
            <TableCell>1</TableCell>
          </TableBody> */}
          <Table.Body>{this.getRowsData()}</Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={9}>
                <Button
                  disabled={!isDirty}
                  size="small"
                  onClick={this.sendNamedAreaSetupMqtt}
                >
                  Save Changes
                </Button>
                <Button
                  disabled={!isDirty}
                  size="small"
                  onClick={this.resetIsDirty}
                >
                  Reset Changes
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

const RenderRow = (props) => {
  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const color = "black";

  const isItemSelected = (e) => {
    //    console.log("qqq isItemSelected e", e);
  };

  const handleSelect = (e) => {
    //   console.log("qqq handleSelect e", e);
  };

  const handleClick = (e, key) => {
    //   console.log("qqq key", key);

    e.preventDefault();

    setOpen(!open);
  };

  const isDeleteSelected = (e) => {
    //console.log("qqq isDeleteSelected e", e);
  };

  const handleDeleteClick = (e, key) => {
    e.preventDefault();

    const row = props.index;

    // update data!
    props.onDeleteClick({ row });
  };

  const handleShowHideClick = (e, key) => {
    e.preventDefault();

    const row = props.index;

    // toggle eye icon on button
    setOpen(!open);

    // update data!
    props.onShowHideClick({ row });
  };

  const eyeIcon = open ? "eye" : "eye slash";
  const gripIcon = (index, length) => {
    //console.log("qqq length, index", length, index);
    switch (index) {
      case 0:
        return "sort down";
      case length - 1:
        return "sort up";
      default:
        return "sort";
    }
  };

  const iconButtons = {
    padding: "0.5em",
  };

  return (
    <>
      <Table.Cell>
        <Button
          style={iconButtons}
          icon
          onClick={(e) => handleClick(e, props.index)}
        >
          <Icon
            name={gripIcon(props.data.properties.priority, props.dataLength)}
          />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={isItemSelected(props.index)}
          onChange={(e) => handleSelect(e, props.index)}
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          style={iconButtons}
          icon
          onClick={(e) => handleShowHideClick(e, props.index)}
          //toggle
          //active={isShowHideSelected(props.index)}
        >
          <Icon color={color} name={eyeIcon} />
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Input
          value={props.data.properties.name} // read only
          name="read only"
        />
      </Table.Cell>
      <RenderRowColorBox
        key={props.index}
        index={props.index}
        data={props.data}
        onColorClick={props.onColorClick}
      />
      <Table.Cell>
        <Checkbox
          checked={isDeleteSelected(props.index)}
          onChange={(e) => handleDeleteClick(e, props.index)}
        />
      </Table.Cell>
    </>
  );
};

// priority: integer - order of items in list <-- or just sort the object?
// active: boolean  - checkbox status
// show: boolean - show/hide on map (eye icon)
// name: string - named area polygon shape name <-- could be generated
// button: array of button color settings

const RenderRowColorBox = (props) => {
  // console.log("qqq  RenderRowColorBox props", props);

  const [open, setOpen] = useState(true); // declare new state variable "open" with setter

  const handleClick = (e, key) => {
    e.preventDefault();

    //let data = props.data;
    const row = props.index;
    const column = key;

    // console.log("qqq RenderRowColorBox index", buttonIndex);
    // console.log("qqq RenderRowColorBox key", key);
    // //console.log("qqq RenderRowColorBox data", data);

    // console.log("qqq currentColor", data.button[key].color);
    // const colors = ["green", "amber", "blue", "red"];
    // const currentColor = data.button[key].color;
    // const index = colors.findIndex((x) => x === currentColor);
    // let newColor;
    // index + 1 > colors.length - 1
    //   ? (newColor = colors[0])
    //   : (newColor = colors[index + 1]);

    // console.log("qqq newColor", newColor);

    // update data!
    props.onColorClick({ row, column });

    setOpen(!open);
  };

  const colorBoxStyle = {
    borderRadius: 0,
    border: "1px outset black",
    height: "100%",
    margin: ".25em",
  };

  const buttons = props.data.properties.button;

  return buttons.map((button, index) => {
    let buttonColor = "green";
    switch (button.color) {
      case "amber":
        buttonColor = "yellow";
        break;
      case "no_change":
        buttonColor = "grey";
        break;
      case "off":
        buttonColor = "black";
        break;
      default:
        // not "GREEN" as used by the FF controller but "green" used by the semantic ui CSS
        buttonColor = button.color.toLowerCase(); // #REVIEW - this shouldn't be necessary as I thought I'd caught these previously but THIS MAKES SURE!
        break;
    }

    // console.log(
    //   "qqq index, button.title, button.color, buttonColor",
    //   index,
    //   button.title,
    //   button.color,
    //   buttonColor
    // );

    return (
      <Table.Cell style={{ paddingLeft: "2px" }}>
        <Button
          icon
          color={buttonColor}
          style={colorBoxStyle}
          onClick={(e) => handleClick(e, index)}
        >
          <Icon />
        </Button>
      </Table.Cell>
    );
  });
};

class TestNamedAreaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      events: [],
      acks: [],
    };
  }

  componentDidMount() {
    this.setState({ tableData: this.props.namedAreas }); //
    this.setState({ events: this.props.namedAreaEvents });
    this.setState({ acks: this.props.acks });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.namedAreas !== this.props.namedAreas) {
      this.setState({ tableData: this.props.namedAreas });
    }

    if (prevProps.acks !== this.props.acks) {
      this.setState({ acks: this.props.acks });
    }

    if (prevProps.namedAreaEvents !== this.props.namedAreaEvents) {
      // #REVIEW / TODO
      // need to support parent group
      //      const parentId = "parentNamedArea";

      // #REVIEW / TODO
      // move this filter to a common location.
      // used in a few places
      //     const parentIsDirty =
      //     this.props.parentIsDirty.filter((namedArea) => namedArea === parentId)
      //         .length > 0;

      // if parent group is dirty do not update event external information
      //      if (parentIsDirty === false) {
      //     console.log("dirty ....");
      this.setState({ events: this.props.namedAreaEvents });
      //     }
    }
  }

  render() {
    return (
      <TableMade
        data={this.state.tableData}
        events={this.state.events}
        onSendMqtt={(mqttMsg) => this.props.mqttPublish(mqttMsg)}
        namedAreaSetDeleteSelections={(deleteSelection) =>
          this.props.namedAreaSetDeleteSelections(deleteSelection)
        }
        namedAreaClearDeleteSelections={(deleteSelection) =>
          this.props.namedAreaClearDeleteSelections(deleteSelection)
        }
        namedAreaSetShowHideSelections={(hideSelection) =>
          this.props.namedAreaSetShowHideSelections(hideSelection)
        }
        namedAreaClearShowHideSelections={(hideSelection) =>
          this.props.namedAreaClearShowHideSelections(hideSelection)
        }
        namedAreasSetIsDirty={(id) => this.props.namedAreasSetIsDirty(id)}
        namedAreasClearIsDirty={(id) => this.props.namedAreasClearIsDirty(id)}
        parentIsDirty={this.props.parentIsDirty}
        acks={this.props.acks}
        removeAcks={(ts) => this.props.removeAcks(ts)}
      />
    );
  }
}

function mapStateToProps(state, props) {
  // namedAreas direct from mqtt
  const geoJSONNamedAreasUtm = getNamedAreaInfos(state); // #REVEW - direct connection to mqtt
  const namedAreasReduced = GetMapNamedAreasReducedProperties(state);
  const namedAreasOld = geoJSONNamedAreasUtm.features;

  // named area events mqtt messages
  const namedAreaEvents = getNamedAreaEvents(state);

  // ack mqtt messages
  const acks = getAcks(state);

  // namedAreas from local map which includes shapes added (but not necessarily in server db)
  const localMapState = GetLocalMap(state);

  // #REVIEW - may not be used. Was WIP for idea. Delete?
  // a subset of local map to allow comparison of common data between mqtt and drawn
  const localNamedAreasReduced = GetLocalMapNamedAreasReducedProperties(state);

  const { namedArea: parentIsDirty } = getIsDirty(state);

  const namedAreas =
    localMapState.namedAreas !== undefined
      ? localMapState.namedAreas.features
      : [];
  return {
    namedAreasReduced,
    localNamedAreasReduced,
    geoJSONNamedAreasUtm,
    localMapState,
    namedAreas,
    namedAreaEvents,
    acks,
    parentIsDirty,
  };
}
export function mapDispatchToProps(dispatch, ownProps) {
  return {
    mqttPublish: (mqttMsg) => {
      dispatch(mqttPublish(mqttMsg));
    },
    namedAreaSetDeleteSelections: (deleteSelection) => {
      dispatch(namedAreaSetDeleteSelections(deleteSelection));
    },
    namedAreaClearDeleteSelections: (deleteSelection) => {
      dispatch(namedAreaClearDeleteSelections(deleteSelection));
    },
    namedAreaSetShowHideSelections: (hideSelection) => {
      dispatch(namedAreaSetShowHideSelections(hideSelection));
    },
    namedAreaClearShowHideSelections: (hideSelection) => {
      dispatch(namedAreaClearShowHideSelections(hideSelection));
    },
    namedAreasSetIsDirty: (id) => {
      dispatch(namedAreasSetIsDirty(id));
    },
    namedAreasClearIsDirty: (id) => {
      dispatch(namedAreasClearIsDirty(id));
    },
    removeAcks: (ts) => {
      dispatch(removeAcks(ts));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestNamedAreaTable);
