import _isEmpty from "lodash/isEmpty";
import {
  isConfigJs,
  TemplateAllAreasButtons,
  lightingEventPriority,
} from "components/ConfigJs";

// #NOTE - DEFAULT PRIORITY SETTINGS
//
// MAX_PRIORITY = 10000 // <<--- see 'manager/manager.go'
const DEFAULT_EMERGENCY_PRIORITY = lightingEventPriority()?.emergency || 9900;
const DEFAULT_LEVEL_WIDE_PRIORITY = lightingEventPriority()?.levelWide || 9800;
const DEFAULT_START_FORCED_LIGHT_PRIORITY =
  lightingEventPriority()?.forced || 5000;

function strToObj(str) {
  var obj = {};
  if (str && typeof str === "string") {
    var objStr = str.match(/\{(.)+\}/g);
    eval("obj =" + objStr);
  }
  return obj;
}

export const convertFetchNamedAreasToMqtt = (data) => {
  let namedAreaInfos = {};
  let namedAreaFeatures = {};

  let namedAreaStatuses = {};
  let allAreasButtons = [];

  if (isConfigJs() && TemplateAllAreasButtons()) {
    // get template for ALL_AREAS and level wide AREA button groups
    allAreasButtons = TemplateAllAreasButtons();
  }

  data.list.forEach((namedArea) => {
    const cloneNamedAreaFeatures = { ...namedAreaFeatures };
    const cloneNamedAreaStatuses = { ...namedAreaStatuses };
    const addInfosStatuses = makeNamedAreaInfosStatuses(
      namedArea,
      cloneNamedAreaFeatures,
      cloneNamedAreaStatuses,
      allAreasButtons
    );

    namedAreaFeatures = addInfosStatuses.namedAreaInfos;
    namedAreaStatuses = addInfosStatuses.namedAreaStatuses;
  });

  namedAreaInfos = {
    type: "FeatureCollection",
    features: Object.values(namedAreaFeatures),
  };

  return {
    namedAreaInfos,
    namedAreaStatuses,
  };
};

const makeNamedAreaInfosStatuses = (
  newMessage,
  oldNamedAreaInfos,
  oldNamedAreaStatuses,
  allAreasButtons
) => {
  let namedAreaInfos = { ...oldNamedAreaInfos };
  let namedAreaStatuses = { ...oldNamedAreaStatuses };

  const {
    id,
    area,
    name: rxName,
    shape: rxShape,
    origin,
    Firefly_List,
    type, //= "NAMED_AREA", //#REVIEW - at time of testing is in confluence definition but not passed in message yet
    sub_type,
    priority,
    parent: rxParent,
    parent_name: rxParentName,
    active_color,
    active_state,
    button: rxButton,
    style: rxStyle,
  } = newMessage;

  if (
    ["LEVEL_WIDE", "ALL_AREAS", "POLYGON"].includes(type.toUpperCase())
    // excludes "TRIGGER", "EMERGENCY_EVENT", "ALL_AREAS_EMERGENCY_EVENT"
  ) {
    // assign these variables so can update later
    // #REVIEW - all these should be set by the server
    let shape = rxShape;
    let button = rxButton;
    let name = rxName;
    let parent = rxParent;
    let parentName = rxParentName;

    // check parent is "AREA:NAMED_AREA"
    if (parent === "") {
      parent = id;
    }

    // #polygonStyleDefault
    // #TODO - move this default style to config.js (pass through setup)
    const style =
      rxStyle !== undefined || rxStyle !== ""
        ? strToObj(rxStyle)
        : {
            color: "grey",
            fillColor: "grey",
            fillOpacity: 0.3,
            opacity: 1,
            weight: 1,
          };

    // id - string. Contains > 2 :
    // area - string
    // shape - array

    // Firefly_String - string - - if null make ""
    // Firefly_List - string - if null make ""
    // type: "Polygon",

    // Note: named_area ids are of the form
    // e.g. "DMLZ_Undercut:Area_A:1234567890"
    // where if you parse by ':' you get:
    // 1 - area
    // 2 - named_area parent group
    // 3 - named_area region
    // In the case of level wide named areas this will be
    // e.g. "DMLZ_Undercut"

    // LEVEL_WIDE named_area messages may not have shape defined, don't include LEVEL_WIDE in drawn named areas.
    // Don't include named areas which do not have a shape defined i.e. ATM

    // #REVIEW/TODO - need to accommodate LEVEL_WIDE and other named areas which may not have a shape defined.
    //

    // Setup LEVEL_WIDE named areas when new area created
    //
    // 1- copy shape from region defined in area
    // 2 - setup default button group
    //

    // #REVIEW - force the area to single point
    // until do otherwise
    //

    if (type === "LEVEL_WIDE" || type === "ALL_AREAS") {
      name = type;
      parent = area;

      // console.log("eee type", type);
      // console.log("eee name", name);
      // console.log("eee parent", parent);
      // console.log("eee shape", shape);

      // #NOTE - requiring this differs from MQTT processing ?! areaInfos[topicId] is not available.

      // #REVIEW - update shape irrespective of the content from the server
      if (shape === null) {
        // if (areaInfos[topicId] !== undefined) {
        //   if (areaInfos[topicId].bounds !== undefined) {
        //     shape = areaInfos[topicId].bounds;
        //   } else {
        //     shape = [
        //       [0, 0],
        //       [1, 0],
        //       [0, 1],
        //       [1, 1],
        //       [0, 0],
        //     ]; // #REVIEW/TODO/FIX - default defined b/c at the time this was not part of the object sent by server.
        //   }
        // } else {
        shape = [
          [0, 0],
          [1, 0],
          [0, 1],
          [1, 1],
          [0, 0],
        ]; // #REVIEW/TODO/FIX - default defined b/c at the time this was not part of the object sent by server.
        //}
      }
      // console.log("eee shape", shape);
      // console.log("eee button", button);

      if (button === null || _isEmpty(button)) {
        let templateButton = JSON.parse(JSON.stringify(allAreasButtons[0])); // #REVIEW - why array in array???

        for (let i = 0; i < templateButton.length; i++) {
          templateButton[i].id = type;
          templateButton[i].named_area = area;

          // add offset to AREA and ALL_AREAS buttons, except for the 1st button which is always priority 0
          if (i !== 0) {
            let priorityOffset = 0;
            switch (type) {
              case "LEVEL_WIDE":
                priorityOffset = DEFAULT_LEVEL_WIDE_PRIORITY; //80; // LEVEL_WIDE priority starts from 80+
                break;
              case "ALL_AREAS":
                priorityOffset = DEFAULT_EMERGENCY_PRIORITY; //90;
                break;
              default:
                priorityOffset = 0;
                break;
            }
            templateButton[i].priority =
              templateButton[i].priority + priorityOffset;
          }
        }
        button = templateButton;
      }

      // console.log("eee type, button", type, button);
    }

    if (shape !== null && shape !== undefined) {
      // pop off the last element of the array which is the close point added for psql
      shape.pop();

      // geometry type has to be "Point" or "Polygon" i.e. this upper first char syntax
      // use 'type' to support passing polygons, rect, polyline etc.
      let geomType;

      if (["LEVEL_WIDE", "ALL_AREAS"].includes(type)) {
        geomType = "Polygon";
      } else {
        geomType = type[0].toUpperCase() + type.substring(1).toLowerCase();
      }

      // set key value by topicId, if the shape is defined
      namedAreaInfos[id] = {
        id,
        type: "Feature",
        properties: {
          status: "mqtt", // for info, file search for "used to track the status of the object on maps and in lists"
          id: id,
          type: type,
          sub_type: sub_type,
          name: name,
          parent: parent,
          parent_name: parentName,
          origin: origin,
          area: area,
          priority: priority,
          Firefly_List: Firefly_List,
          active_color: active_color,
          active_state: active_state,
          button: button !== null ? button : [],

          // // following below are not yet defined in mqtt object
          // easting: easting,
          // northing: northing,
          // utm_zone_number: utm_zone_number,
          // utm_zone_letter: utm_zone_letter,
          // z: z,
          coordinatesUtm: [shape],
          style,
        },
        geometry: {
          type: geomType,
          coordinates: [shape],
        },
      };

      namedAreaStatuses[id] = namedAreaInfos[id].properties;

      // console.log(
      //   "eee namedArea Infos[topicId]",
      //   namedAreaInfos[topicId]
      // );
      // console.log(
      //   "eee namedArea Statuses[topicId]",
      //   namedAreaStatuses[topicId]
      // );
    }
  }

  return {
    namedAreaInfos: { ...namedAreaInfos },
    namedAreaStatuses: { ...namedAreaStatuses },
  };
};
