import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import { Link } from 'react-router-dom'
import toSlug from 'utils/toSlug'

function MineLevelMenuItem ({ level }) {
  const { name } = level
  const slug = toSlug(name)
  return (
    <Dropdown item simple text={name}>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/level/${slug}/create-seismic`}>
          New seismic overlay
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/level/${slug}/seismic-list`}>
          Seismic history
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/level/${slug}/status`}>
          Operational status
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

MineLevelMenuItem.defaultProps = {}

MineLevelMenuItem.propTypes = {
  level: PropTypes.object.isRequired
}

export default MineLevelMenuItem
