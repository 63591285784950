// #MINELEVELMAPLEAFLET

import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-contextmenu";
import "leaflet.smoothwheelzoom";

import "leaflet-groupedlayercontrol";
import "leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min.css";
import "components/Map/MapIcons.css";

import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton";

import {
  transformGeoJsonUtmToPixels,
  transformGeoJsonPixelsToUtm,
} from "components/Map/util-geoJsonToPixels";

import {
  getAreaById,
  getMapState,
  getFireflyCoordinatesByAreaId,
  getFireflyCoordinatesForcedByAreaId,
  getControllerCoordinatesByAreaId,
  getNamedAreaInfos,
  getNamedAreaDeleteSelections,
  getNamedAreaHideSelections,
  getIsDirty,
  getFaults,
  getNamedAreaParentInfos,
  getFireflyIdsUpdateListByArea,
  getFireflyIdsDeleteListByArea,
} from "components/WebWorker/reducer";

import {
  namedAreaClearDeleteSelections,
  namedAreasSetIsDirty,
  mqttPublish,
  fireflyIdsUpdateListDeleteId,
} from "components/WebWorker/actions";

import { GetLocalMap } from "components/Map/reducer";
import { UpdateLocalMap } from "components/Map/actions";

import { sortAlphaNum } from "utils/sortAlphaNum";
import { round } from "utils/number-utils.js";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { omitDeep } from "utils/omitDeep";

import * as turf from "@turf/turf";

import { formatRelative, parseISO } from "date-fns";

import { saveFirefly } from "UPSPanelControllers/actions";
import { saveUPS } from "UPSPanelControllers/actions";

// import plugin's css (if present)
// note, that this is only one of possible ways to load css
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import { messageToken } from "utils/messageToken";

import { makeIcon } from "components/Map/MakeIcon";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import { renderToString } from "react-dom/server";
import FireflyName from "containers/FireflyName";
import FireflyLink from "admin/firefly/FireflyLink";

import { FireflyMarkerPopup } from "components/Map/FireflyMarkerPopup";
import { ControllerMarkerPopup } from "components/Map/ControllerMarkerPopup";

import { NamedAreaPolygonPopup } from "components/Map/NamedAreaPolygonPopup";

import { StatusEnum } from "utils/StatusEnum";

import { withComponentStateCache } from "react-component-state-cache";

import { getFolderFileNamesListById } from "components/Settings/reducer";

import { getRegionPreviewListByAreaId } from "components/RegionPreview/reducer";

import {
  getTimedOutDevices,
  getNamedAreaEventsByAreaId,
} from "components/WebWorker/reducer";

// >>>>> flash message support
import {
  isConfigJs,
  faultMessageBannerDisplay,
  faultMessageBannerText,
  isDemoMode,
  getMapDisplayOptions,
  markerAcceptableEventLabels,
} from "components/ConfigJs";

import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
  clearFlashMessages,
  SetMessageBanner,
} from "FlashMessages/actions";

import cuid from "cuid";
// <<<<< flash message support

import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";
import { SetMapMoving, SetMapMarkerDragging } from "components/Map/actions";

import {
  createNamedAreaAroundFirefly,
  createFireflyNamedAreaId,
} from "NamedAreas/createNamedAreaAroundFirefly";

import { saveNewNamedArea, deleteNamedArea } from "NamedAreas/actions";

import {
  cancelNamedAreaEvent,
  activateNamedAreaEventSingle,
  waitEventTimeOut,
} from "OperationalChanges/actions";
import { id } from "date-fns/locale";
import { propTypes } from "redux-form";

import { getEquivalentSemanticColorName } from "colorpalette";

import { isKthBitSet } from "utils/bit-operations";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

import {
  addAreaPreview,
  removeAreaPreview,
} from "components/RegionPreview/actions";

import { getObjectDiff } from "utils/getObjectDiff";

import { getMapImageById } from "components/Settings/reducer";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";

// -----------------------------------------------
// DEFAULT AND DEBUG
//
// -----------------------------------------------
//
// #NOTE - DEFAULT PRIORITY SETTINGS
//
// MAX_PRIORITY = 10000 // <<--- see 'manager/manager.go'
const DEFAULT_EMERGENCY_PRIORITY = 9900;
const DEFAULT_LEVEL_WIDE_PRIORITY = 9800;
const DEFAULT_START_FORCED_LIGHT_PRIORITY = 5000;

// #DEBUG
// DISABLE code sections under development and prototyped
const DEBUG_ENABLED_FALSE = false;
const DEBUG_ENABLED_TRUE = true;
const DEBUG_SHOW_COUNT_OF_MARKERS_EACH_LAYER = false; // #WIP - was working a long time ago but broken now?!

//

// #REVIEW - Need this? This is setup to be able to draw linestrings etc,
// but are there too many layers as-is?
// This adds a group for each location. i.e. The 'location' is the port, e.g. "Panel 1"
// This allows the turning on/off of individual locations.
// #REVIEW
//
// * move this.locationGroupLayers etc. to state rather than forced via "this."
// use setState to update once groups are defined

const mapDisplayOptions = getMapDisplayOptions();
const { controllerLocations, controllerCables } = mapDisplayOptions;

// Disabled for now to reduce load on leaflet
const DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER = controllerLocations;
const DEFAULT_SHOW_FIREFLY_POLYLINE_GROUP_LAYER =
  controllerCables && DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER;
// << needs location group above to also be enabled

//
// -----------------------------------------------

function unify(polyList) {
  var options = { tolerance: 0.0001, highQuality: true };

  for (var i = 0; i < polyList.length; ++i) {
    if (i === 0) {
      var unionTemp = polyList[i].toGeoJSON();
    } else {
      try {
        // clean the coordinates
        unionTemp = turf.cleanCoords(turf.simplify(unionTemp, options));
        // check for kinks
        var kinks = turf.kinks(unionTemp);
        if (kinks.features.length) {
          unionTemp = turf.unkinkPolygon(unionTemp);
        }
        unionTemp = turf.union(unionTemp, polyList[i].toGeoJSON());
      } catch (error) {
        console.log(`DATA ERROR: MapLeaflet turf union `, error);
      }
    }
  }
  return L.geoJson(unionTemp);
}

const CustomReactPopup = ({ id }) => {
  return (
    <div style={{ fontSize: "24px", color: "black" }}>
      <p>{`A pretty React Popup ${id}`}</p>
    </div>
  );
};

// firefly marker definition
const markerDefinition = (feature) => {
  const { properties } = feature;

  const {
    id,
    area,
    location,
    position,
    easting,
    northing,
    utm_zone_number,
    utm_zone_letter,
    z,
    //color,
    light,
    controllerMode,
    deviceStatus,
    timestamp,
    ups_id,
    forced,
  } = properties;

  const {
    color,
    lightState,
    marker, // defaults to 'RoundMarker'
    led_state,
    off_time,
    on_time,
    train,
    brightness,
    event_id,
  } = light;

  // default
  let svgIcon = marker || "DiamondMarker";

  const { plan: ColorPlan, status: ColorStatus } = color;
  const { plan: StatePlan, status: StateStatus } = lightState;

  // FF is in transition is states are changing. Test for color change and for state change.
  // Ignore the other statuses i.e. off_time, on_time, train, brightness.
  //
  const isInTransition =
    ColorPlan !== ColorStatus ||
    StatePlan?.led_state !== StateStatus?.led_state;

  // #REVIEW
  const isStrobing = ["strobe", "forward", "backward"].includes(
    led_state.toLowerCase()
  );

  const isInCommission = controllerMode.toLowerCase() === "commission";

  // label the icons with a text character designating the source

  let textLabel = "";
  if (forced) textLabel = "F";
  // event_id labels
  const eventIdLabel = event_id?.charAt(0);

  if (markerAcceptableEventLabels()?.includes(eventIdLabel))
    textLabel = eventIdLabel;

  // #Note - if the light is off force it to be "grey" to account for
  // when a colored marker is defined. Color is meaningless when it is off.
  // A special marker like the 'OffMarker' has no color fill so it not impacted.
  //

  let svgStyle = {
    stroke:
      StatePlan?.led_state === "off"
        ? `rgba(0,0,0,.2)`
        : getEquivalentSemanticColorName(ColorPlan),
    fill:
      StateStatus?.led_state === "off"
        ? `rgba(0,0,0,.1)`
        : getEquivalentSemanticColorName(ColorStatus),
    text: textLabel,
  };

  // status checks
  let status = "OK"; // lets assume everything is OK. Ever the optimist eh?!

  if (deviceStatus.includes(StatusEnum.API_FETCH)) {
    status = "API_FETCH";
  }

  if (
    deviceStatus.includes(StatusEnum.NO_STATUS_REPORT) ||
    deviceStatus.includes(StatusEnum.TIMEOUT) ||
    deviceStatus.includes(StatusEnum.INACTIVE)
  ) {
    status = "INACTIVE_FIREFLY";
  }

  if (_isEmpty(ups_id)) {
    status = "DEAD_FIREFLY";
  }

  // OVERRIDE FAULT CHECKS IF DEMO MODE
  if (isDemoMode()) {
    // _#DEMO_MODE
    status = "OK";
  }

  //console.log(`xxx FIREFLY status`, id, deviceStatus, status);

  switch (status) {
    case "OK":
      if (marker.toLowerCase() === "travelwaymarker") {
        svgIcon = "TravelwayMarker";
        // travelways have a special color
        svgStyle = {
          stroke: "purple",
          fill: getEquivalentSemanticColorName(ColorStatus),
        };
      } else if (marker.toLowerCase() === "travelwaymarker") {
        svgIcon = "TravelwayMarker";
        // travelways have a special color
        svgStyle = {
          stroke: "purple",
          fill: getEquivalentSemanticColorName(ColorStatus),
        };
      } else {
        isInCommission
          ? (svgIcon = "StarMarker") // special commission marker
          : isInTransition
          ? (svgIcon = "TriangleMarker") // special transition marker
          : (svgIcon = marker); // ...otherwise use the marker type passed in the message
      }
      break;

    // case "OK":
    //   if (marker.toLowerCase() === "TravelwayMarker") {
    //     svgIcon = "TravelwayMarker";
    //     svgStyle = { stroke: "purple", fill: ColorStatus };
    //   } else {
    //     switch (isStrobing) {
    //       case true:
    //         isInCommission
    //           ? (svgIcon = "StarMarker")
    //           : isInTransition
    //           ? (svgIcon = "TriangleMarker")
    //           : (svgIcon = setStrokeMarkers(led_state.toLowerCase()));

    //         break;
    //       case fINACTIVE_FIREFLYalse:
    //         isInCommission
    //           ? (svgIcon = "StarMarker")
    //           : isInTransition
    //           ? (svgIcon = "TriangleMarker")
    //           : (svgIcon = marker); // ....marker state passed in the message
    //         break;
    //       default:
    //         break;
    //     }
    //   }

    //   break;
    case "API_FETCH":
      svgIcon = "TriangleMarker";
      break;

    case "INACTIVE_FIREFLY":
      svgStyle = { stroke: "black", fill: "fuchsia" };
      svgIcon = "HourGlassMarker";
      break;
    // #WIP - used for Controller??????
    case "UPS_TIMEOUT":
      svgStyle = { stroke: "fuchsia", fill: "black" };
      svgIcon = "HourGlassMarker";
      break;
    case "DEAD_FIREFLY":
    default:
      svgStyle = { stroke: "fuchsia", fill: "sienna" };
      svgIcon = "BowTieMarker";
      break;
  }

  return { svgIcon, svgStyle };
};

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refPts: {},
      thislocalMapState: {},
      map: { area: "defaultArea" },
      heartBeat: true,
      isMapLoading: true,
      isMapLoaded: false,
      mapSrc: "",
      regionGroupLayersCount: 0,
      parentsUnionGroupLayersCount: 0,
      //
      layerControlState: {},
      // marker popup state
      isPopupOpen: false,
      // map view state
      mapViewState: { center: undefined, zoom: undefined },

      // #NOTE -
      // * set when componentDidMount is run.
      // Used to determine if map regionPreview should be updated

      isMapComponentDidMount: false,
      //
      forcedLightState: [],
      //
      isPokeTheWorkerLocal: true, // local record of whether to collect data, stops updates immediately instead of waiting for redux response
      //
      markerPositionChanges: {},
      forceMapRefresh: false, // used to force map refresh if markers moved
      isMoving: false,
      isMarkerDragging: false,
      //
      // tracks which forced markers have been clicked
      forcedClickList: [],
      //
      // tracks if map move in use
      isMapView: false,
      // tracks if edit tools in use
      isMoveMarkerPositions: false,
      // tracks activeLayers so can toggle on/off
      activeLayersList: [],
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  // #WIP - #TODO - MAKE COMMON
  // see - src/pages/ControlRoomPage/MineLevelOpsControl.js
  // message banner support
  //
  //
  faultMessageBannerId = (faultType) => {
    // check if a message of this type exists

    // get the id for the flash message
    const messageBanners = this.props.messageBanners; // from redux state

    console.log(`messageBanners`, messageBanners);
    return messageBanners[faultType].id;
  };

  clearFaultMessageBanner = (faultType) => {
    // get the id for the flash message
    const bannerId = this.faultMessageBannerId(faultType);

    console.log(`message bannerId`, bannerId);
    if (bannerId) {
      this.props.removeFlashMessage(bannerId);
      //#REVIEW - make this a function..........
      // delete this banner message from state
      let tmpObj = {};
      tmpObj.faultType = faultType;
      tmpObj.display = false;
      tmpObj.id = "";

      this.props.SetMessageBanner(tmpObj);
    }
  };

  areaImageNotFoundMessage = (areaImageNotFound, imageFilename) => {
    const messageBanners = this.props.messageBanners;

    if (messageBanners === undefined) return;

    // console.log(`messageBanners`, messageBanners);
    // console.log(`message imageFilename`, imageFilename);
    // console.log(`message areaImageNotFound`, areaImageNotFound);

    // #NOTE - old comment, but still applies. Need to centralise function.
    //#REVIEW - force to one type for now - this needs to be merged with AlarmButtons and other messaging

    const bannerDisplayed = messageBanners["areaImageNotFound"]?.display;
    let bannerInfo = messageBanners["areaImageNotFound"]?.info || [];
    const isBannerDisplayedForThisImageFilename =
      bannerInfo?.includes(imageFilename);

    // console.log(
    //   `isBannerDisplayedForThisImageFilename`,
    //   isBannerDisplayedForThisImageFilename
    // );

    // // set
    // if (areaImageNotFound) {
    //   if (isConfigJs() && faultMessageBannerDisplay()) {
    //     const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

    //     // if this fault type allows message banner display (in config)
    //     // and there hasn't been a message banner displayed previously

    //     //#REVIEW - need to check fault type as per review comment above
    //     if (
    //       isMessageBannerForFaultType["areaImageNotFound"] &&
    //       !bannerDisplayed
    //     ) {
    //       // create a unique ID for the banner
    //       const id = cuid();

    //       // display a new banner
    //       this.displayErrors(id, "areaImageNotFound", "????????");

    //       // record the update state of banner including the id (needed to delete this particular banner)
    //       let tmpObj = {};
    //       tmpObj.faultType = "areaImageNotFound"; // #REVIEW - force to one status check type for now
    //       tmpObj.display = true;
    //       tmpObj.id = id;
    //       // multiple files : same
    //       tmpObj.info = "info";

    //       this.props.SetMessageBanner(tmpObj);
    //     }
    //   }
    // } else {
    //   // clear it
    //   if (bannerDisplayed) {
    //     //this.clearFaultMessageBanner("areaImageNotFound");
    //   }
    // }

    //
    if (isConfigJs() && faultMessageBannerDisplay()) {
      const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

      // if this fault type allows message banner display (in config)
      // and there hasn't been a message banner displayed previously

      if (isMessageBannerForFaultType["areaImageNotFound"]) {
        if (areaImageNotFound) {
          // set
          if (bannerDisplayed) {
            // banner already exists
            if (!isBannerDisplayedForThisImageFilename) {
              // update banner
              // clear current banner
              this.clearFaultMessageBanner("areaImageNotFound");
              // set a new banner
              this.setFaultMessageBanner([...bannerInfo, imageFilename]);
            }
          } else {
            // no current banner
            // set a new banner
            this.setFaultMessageBanner([...bannerInfo, imageFilename]);
          }
        } else {
          // clear banner
          if (bannerDisplayed) {
            if (isBannerDisplayedForThisImageFilename) {
              // clear just this banner
              // clear current banner
              this.clearFaultMessageBanner("areaImageNotFound");
              // set a new banner
              this.setFaultMessageBanner(
                bannerInfo.filter((file) => file !== "B")
              );
            }
            //
          }
        }
      }
    }
  };

  setFaultMessageBanner = (bannerInfo) => {
    console.log(`message bannerInfo`, bannerInfo);
    // set a new banner
    // create a unique ID for the banner
    const id = cuid();
    // display a new banner
    this.displayErrors(id, "areaImageNotFound", bannerInfo);

    // record the update state of banner including the id (needed to delete this particular banner)
    let tmpObj = {};
    tmpObj.faultType = "areaImageNotFound"; // #REVIEW - force to one status check type for now
    tmpObj.display = true;
    tmpObj.id = id;
    // multiple files : same
    tmpObj.info = bannerInfo;

    this.props.SetMessageBanner(tmpObj);
  };

  // get the text for the fault message banner from config.js or set default
  getFaultMessageBannerText = (faultType) => {
    let header;
    let message;
    let color;

    if (isConfigJs() && faultMessageBannerText()) {
      const messageBannerText = faultMessageBannerText(); // from config.js file
      header = messageBannerText[faultType].header;
      message = messageBannerText[faultType].message;
      color = messageBannerText[faultType].color;
    } else {
      // default messages if config.js object is stuffed
      switch (faultType) {
        case "areaImageNotFound":
          header = "Area Image Can Not Be Found";
          message =
            "An area image can not be found on the server, or the server connection has failed. Check the server operation then log out/in.";
          color = "red";
          break;
        default:
          break;
      }
    }

    return { header, message, color };
  };

  // loads header and message to display on control screen page
  // depending on significance of the error
  // All cases go to the Browser Logger page
  //
  displayErrors(id, faultType, info) {
    const { header, message, color } =
      this.getFaultMessageBannerText(faultType);

    // set color from highest fault_level for the faultType
    //    const color = this.setColorMessageBanner(faultType);

    this.props.addWarningFlashMessageIdColor(
      id,
      color,
      header,
      message + " " + info.join(", ") || "Unknown problem"
    );
    //console.log("message displayErrors - " + header + "-" + message);
  }

  // styling for polygons
  geojsonPolygonStyle = (feature) => {
    let setFillColor;
    if (feature.properties.active_color) {
      const color = feature.properties.active_color;
      switch (color) {
        case "green":
          setFillColor = "green";
          break;
        case "blue":
          setFillColor = "blue";
          break;
        case "amber":
          setFillColor = "orange";
          break;
        case "white":
          setFillColor = "white";
          break;
        case "red":
          setFillColor = "red";
          break;

        default:
          setFillColor = "grey";
          break;
      }
    }

    return {
      fillColor: setFillColor,
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.1,
    };
  };

  markerPts = (img) => {
    const { width, height } = img;

    // image pixel positions = lat,lng
    // geometric refernece coord = easting, northing, zoneNum, zoneLetter,
    // {lat, lng, easting, northing, zoneNum, zoneLetter}

    const markerOrigin = {
      id: "corner",
      lat: 0,
      lng: 0,
      easting: 0,
      northing: 0,
      zoneNum: 53,
      zoneLetter: "M",
      z: 0,
    };
    const markerMiddle = {
      id: "center",
      lat: height / 2,
      lng: width / 2,
      easting: 0,
      northing: 0,
      zoneNum: 53,
      zoneLetter: "M",
      z: 0,
    };
    const markerMax = {
      id: "corner",
      lat: height,
      lng: width,
      easting: 0,
      northing: 0,
      zoneNum: 53,
      zoneLetter: "M",
      z: 0,
    };

    return [markerOrigin, markerMiddle, markerMax];
  };

  // creates a geoJson object for the  map reference points
  // pass in map pixel ref lat,lng and the coord reference easting, northing
  // geometry coordinats are in pixels
  //
  // e.g. localPtsGeoJson([{ lat: 0, lng: 0, easting: 0, northing: 0, zoneNum: 53, zoneLetter: "M", z: 0 },...[...]);
  //
  // still using lat and lng because leaflet uses these references.
  //
  localPtsGeoJson = (points) => {
    let localPtsGeoJson = [];
    points.map(
      ({ lat, lng, easting, northing, zoneNum, zoneLetter, id }, idx) => {
        const objLatLng = { lat: lng, lng: lat }; //  #REVIEW - have swapped around lat and long **ADRESS THIS**
        const point = Object.values(objLatLng);

        // structure geoJSON object

        const geojsonFeature = {
          type: "Feature",
          properties: {
            index: idx,
            id: id,
            area: "refArea",
            location: "refPt",
            position: idx,
            easting: easting,
            northing: northing,
            utm_zone_number: zoneNum,
            utm_zone_letter: zoneLetter,
            z: 0,
            color: "toBeDone",
          },
          geometry: {
            type: "Point",
            coordinates: point,
          },
        };
        localPtsGeoJson.push(geojsonFeature);
      }
    );
    return {
      type: "FeatureCollection",
      features: localPtsGeoJson,
    };
  };

  // create geojson markerData
  markerToGeoJSON = (markersData, transform) => {
    const geoJSONMarkersData = [];

    markersData.map(
      ({ id, lat, lng, name, color, position, location }, idx) => {
        // transform the point from latlng to pixels
        const objLatLng = { lat: lat, lng: lng };
        const point = Object.values(transform.transform(objLatLng));

        // structure geoJSON object
        const geojsonFeature = {
          type: "Feature",
          properties: {
            id: id,
            name: name,
            color: color,
            position: position,
            location: location,
          },
          geometry: {
            type: "Point",
            coordinates: point,
          },
        };
        geoJSONMarkersData.push(geojsonFeature);
      }
    );
    return {
      type: "FeatureCollection",
      features: geoJSONMarkersData,
    };
  };

  // support for map leaflet.contextmenu
  showCoordinates = (e) => {
    alert(e.latlng);
  };

  centerMap = (e) => {
    this.map.panTo(e.latlng);
  };

  fitMap = (e, bounds) => {
    this.map.fitBounds(bounds);
  };

  zoomIn = (e) => {
    this.map.zoomIn();
  };

  zoomOut = (e) => {
    this.map.zoomOut();
  };

  // draw
  drawTheMap = (
    mapId,
    mineLevel,
    geoJSONMarkersData,
    geoJSONMarkersDataForced,
    geoJSONNamedAreas,
    geoJSONNamedAreaParents,
    geoJSONControllers,
    //
    mapViewState
  ) => {
    const {
      id: mineLevelId,
      image_filename,
      image_info: { width = 10000, height = 8000 },
      localPtsX,
    } = mineLevel;

    //console.log("mapViewState", mapViewState);
    const { center: setCenter, zoom: setZoom } = this.state.mapViewState;

    let url = process.env.PUBLIC_URL + "/areas/" + image_filename; // "extraction.png";

    let isMapLoading = true;
    // console.log("loaded isMapLoading @ entry to drawTheMap ", isMapLoading);
    this.setState({ isMapLoading: true });
    this.setState({ mapSrc: url });

    // if (this.state.isMapLoaded) {
    //   console.log(`loaded isMapLoaded TRUE `, url);
    // }

    // // setup a dummy image and load the map image in the background
    // // create a replacement image using known dimensions
    // let canvas = document.createElement("canvas");
    // // set desired size of transparent image
    // canvas.width = width;
    // canvas.height = height;

    // // extract as new image (data-uri)
    // const url = canvas.toDataURL();

    ////////////////////////////////////////////////////////////////////////////
    // Conversion from (x, y) raster image coordinates to equivalent of latLng
    // Taken from Leaflet tutorial "Non-geographical maps"
    // http://leafletjs.com/examples/crs-simple/crs-simple.html
    ////////////////////////////////////////////////////////////////////////////
    var yx = L.latLng;
    var xy = function (x, y) {
      if (L.Util.isArray(x)) {
        // When doing xy([x, y]);
        return yx(x[1], x[0]);
      }
      return yx(y, x); // When doing xy(x, y);
    };

    const img = [
      width, // original width of image `level.jpg` - undercut 800 conversion example => x / ~longitude (0 is left, width,  13234 is right)
      height, // original height of image => y / ~ reverse of latitude (0 is top, height, 9356 is bottom)
    ];

    // if a center has been passed to component use it, else recalc
    let center = [width / 2, height / 2]; // center calculation based on original pixel dimensions
    if (setCenter) center = Object.values(setCenter).reverse();

    let zoom = -4;
    if (setZoom) zoom = setZoom;

    // console.log(
    //   "mapViewState center setCenter setZoom",
    //   mineLevelId,
    //   center,
    //   setCenter,
    //   setCenter ? Object.values(setCenter).reverse() : "????",
    //   zoombounds
    // );

    var bounds = L.latLngBounds([xy(0, 0), xy(img)]);

    let base = L.tileLayer(url, {
      bounds: bounds,
      noWrap: true,
    });

    // #REVEW / #WIP - put up 'loading' banners
    // events to show loading progress
    // https://stackoverflow.com/questions/27378572/leaflet-fire-event-when-tile-layer-loading-begins-and-ends

    //base.on("loading", this.setState({ isMapLoading: true }));
    //base.on("load", this.setState({ isMapLoading: false }));

    // #REVIEW - not used
    base.on("tileload", function () {
      console.log("base loaded");
    });

    base.on("tileerror", (error, tile) => {
      console.log(`Error ${error} loading tile ${tile}`);
    });

    this.map = null;

    // define the map
    this.map = L.map(mapId, {
      crs: L.CRS.Simple,

      //maxBounds: bounds.pad(0.5), // http://leafletjs.com/reference-1.0.3.html#map-maxbounds
      // center: center,
      // zoom: zoom,
      minZoom: -5,
      //maxZoom: maxZoom,
      zoomControl: false,

      attributionControl: false,
      contextmenu: true, // context menu plugin `npm i leaflet-contextmenu`
      contextmenuWidth: 140,
      contextmenuItems: [
        // {
        //   text: "Show coordinates",
        //   callback: this.showCoordinates,
        // },
        {
          text: "Center map here",
          callback: this.centerMap,
        },
        {
          text: "Show all",
          callback: (e) => this.fitMap(e, bounds),
        },
        // #REVIEW - this is disabled in favour of the control which enables zoom-in/zoom-out
        //
        // "-",
        // {
        //   text: "Zoom in",
        //   icon: process.env.PUBLIC_URL + "/images/zoom-in.png",
        //   callback: this.zoomIn,
        // },
        // {
        //   text: "Zoom out",
        //   icon: process.env.PUBLIC_URL + "/images/zoom-out.png",
        //   callback: this.zoomOut,
        // },
      ],
      // scroll wheel zoom smoothing
      scrollWheelZoom: false, // disable original zoom function
      smoothWheelZoom: false, // enable smooth zoom
      smoothSensitivity: 1.5, // zoom speed. default is 1
    });

    // ------------------------------------------------------
    //
    // dummy image load testing
    //
    // ------------------------------------------------------
    // console.log(
    //   "loaded isMapLoading new background image ",
    //   url,
    //   " ** isMapLoading TRUE ** >>>>>"
    // );
    // // set state to loading while get new background

    // console.log("loaded dummyImage new background image LOADING ** >>>>>");
    // // set dummy image background
    // // setup a dummy image and load the map image in the background
    // // create a replacement image using known dimensions
    // let dummyCanvas = document.createElement("canvas");
    // // set desired size of transparent image
    // dummyCanvas.width = width;
    // dummyCanvas.height = height;

    // // extract as new image (data-uri)
    // const dummyUrl = dummyCanvas.toDataURL();

    // // set single image background
    // const dummyImage = L.imageOverlay(dummyUrl, bounds);
    // dummyImage.addTo(this.map);

    // // image load events
    // dummyImage.on("load", (event) => {
    //   console.log("loaded dummyImage new background image LOADED ** <<<<<");
    //   //console.log("loaded event", event);
    //   if (event?.target?._url === url) {
    //     isMapLoading = false;
    //     console.log("loaded dummyImage isMapLoading", isMapLoading);
    //     this.setState({ isMapLoading: false });
    //   }
    // });
    // ------------------------------------------------------
    // set single image background

    let overlayUrl;

    // check file exists on server
    if (this.props.folderFilesList?.includes(image_filename)) {
      overlayUrl = url;

      // does not apply to default.png
      if (!image_filename.includes("default.png")) {
        this.areaImageNotFoundMessage(false, image_filename);
        // console.log(
        //   `removing a message banner!!!! - areaImageNotFoundMessage`,
        //   mineLevelId,
        //   image_filename
        // );
      }
    } else {
      // setup a dummy image and load the map image in the background
      // create a replacement image using known dimensions
      let canvas = document.createElement("canvas");
      // set desired size of transparent image
      canvas.width = width;
      canvas.height = height;
      // extract as new image (data-uri)
      overlayUrl = canvas.toDataURL();

      // #NOTE #WIP #TODO
      // SET A MESSAGE IF THE MAP FILE IS NOT AVAILABLE

      // doe not apply to default.png
      if (!image_filename.includes("default.png")) {
        this.areaImageNotFoundMessage(true, image_filename);
        // console.log(
        //   `displaying a message banner!!!! - areaImageNotFoundMessage`,
        //   mineLevelId,
        //   image_filename
        // );
      }
    }

    let isMapImageFromState = false;
    // if the image is available from the reducer Settings state load this instead
    if (!_isEmpty(this.props.mapImageFromState)) {
      overlayUrl =
        "data:image/png;base64," + this.props.mapImageFromState.imageData;
      isMapImageFromState = true;
    }

    const image = L.imageOverlay(overlayUrl, bounds);
    image.addTo(this.map);

    // image load events
    image.on("load", (event) => {
      console.log(
        "LOADED NEW BACKGROUND IMAGE ",
        url,
        "** isMapLoading FALSE ** <<<<<"
      );
      console.log("LOADED NEW BACKGROUND IMAGE event", event);

      // check if image is loaded from the url or via the state image
      if (event?.target?._url == url || isMapImageFromState) {
        isMapLoading = false;
        // console.log("loaded isMapLoading", isMapLoading);
        this.setState({ isMapLoading: false });
      }
    });

    image.on("error", (error, tile) => {
      console.log(`Error ${error} loading imageOverlay ${tile}`);
    });

    // console.log(
    //   `xxxxx newMapViewState - setCenter, setZoom, center, zoom, bounds`,
    //   setCenter,
    //   "-",
    //   setZoom,
    //   "-",
    //   center,
    //   "-",
    //   zoom,
    //   "-",
    //   bounds
    // );

    if (!_isEmpty(setCenter) || !_isEmpty(setZoom)) {
      // if mapViewState has been set
      this.map.setView(xy(center), zoom);
    } else {
      // new page load so fit to bounds
      this.map.fitBounds(bounds);
    }

    // record the current zoom level
    this.previousZoom = this.map.getZoom();

    // record the current map
    this.previousCenter = this.map.getCenter();

    // ****************************************************
    // Setup a holding pen for waylaid fireflies
    //
    // ****************************************************
    //
    // #WIP - pen
    // #REVIEW
    // - https://www.npmjs.com/package/@turf/boolean-point-in-polygon
    // - http://turfjs.org/
    //
    //
    // #REVIEW - would have put pen in top right but points random location in pen is defined in
    // src/components/Map/util-geoJsonToPixels.js
    // and this does not have image height and width ATM to define

    const holdingPenPolygon_topRight = [
      [height, width * 0.85],
      [height, width],
      [height * 0.85, width],
      [height * 0.85, width * 0.85],
    ];

    const holdingPenPolygon_bottomLeft = [
      [0, 0],
      [height * 0.15, 0],
      [height * 0.15, width * 0.15],
      [0, width * 0.15],
    ];

    if (DEBUG_ENABLED_FALSE) {
      this.holdingPen = L.polygon(holdingPenPolygon_bottomLeft).addTo(this.map);
    }
    // -> see randomPointInPoly function

    // ****************************************************
    //
    // Setup and draw the Fireflies
    //
    // ****************************************************

    //
    this.layerlistFireflies = {};

    //
    this.testExternalStr = "<strong>Point: </strong>"; // test passing variable outside into ....

    // force light change
    //
    const setForceLight = (
      action,
      fireflyId,
      easting,
      northing,
      area,
      color,
      setFireflyColor,
      rxSetFireflyState // returned for Strobe click, not for Color click
    ) => {
      console.log(
        `forcedLight: setForceLight action,
      fireflyId,
      easting,
      northing,
      area,
      color,
      setFireflyColor,
      rxSetFireflyState`,
        action,
        fireflyId,
        easting,
        northing,
        area,
        color,
        setFireflyColor,
        rxSetFireflyState
      );

      const fireflyNamedAreaId = createFireflyNamedAreaId(fireflyId);

      const namedAreaEventForFirefly = this.props.namedAreaEvents?.find(
        (event) => event.id.includes(fireflyNamedAreaId)
      );

      let promiseArray = [];
      let operationButton = {};
      let isActivate = false;

      if (action === "clear") {
        // if there are existing events, delete them ...........
        if (namedAreaEventForFirefly) {
          //
          promiseArray.push(
            new Promise((resolve, reject) =>
              this.props.deleteNamedArea({
                values: { id: namedAreaEventForFirefly.id },
                resolve,
                reject,
              })
            )
          );
        }
      }
      // process the changes ...................
      else {
        // get strobe checkBox from State if it is not passed through the parameters
        let setFireflyState = "on";
        if (rxSetFireflyState !== null) {
          setFireflyState = rxSetFireflyState;
        } else {
          setFireflyState =
            //if find record in state array === strobe
            this.state.forcedLightState.some((state) => state === fireflyId)
              ? "strobe"
              : "on";
        }

        // console.log(
        //   `forcedLight: setFireflyState this.state.forcedLightState namedAreaEventForFirefly`,
        //   setFireflyState,
        //   this.state.forcedLightState,
        //   fireflyId,
        //   namedAreaEventForFirefly
        // );

        const currentNamedAreaEventForFireflyColor =
          namedAreaEventForFirefly?.active_color?.toLowerCase?.();

        const currentNamedAreaEventForFireflyState =
          namedAreaEventForFirefly?.active_state?.toLowerCase?.();

        let isStateChanged = setFireflyState !== "on";
        if (currentNamedAreaEventForFireflyState) {
          isStateChanged =
            currentNamedAreaEventForFireflyState !== setFireflyState;
        }

        let isColorChanged = color.status !== setFireflyColor;
        if (currentNamedAreaEventForFireflyColor) {
          isColorChanged =
            currentNamedAreaEventForFireflyColor !== setFireflyColor;
        }

        // console.log(
        //   `forcedLight namedAreaEventForFirefly `,
        //   namedAreaEventForFirefly,
        //   setFireflyState
        // );
        // console.log(`forcedLight isColorChanged `, isColorChanged);
        // console.log(`forcedLight isStateChanged `, isStateChanged);

        // Explanation
        //
        // In all cases delete the current namedArea
        // If color has not changed state, do not add a new namedArea (i.e. toggle OFF)
        // If color changed state, add a new namedArea
        // If state has changed, add a new namedAra

        // In all cases just delete the current namedArea
        //
        if (namedAreaEventForFirefly) {
          if (!isColorChanged && !isStateChanged) {
            // if toggle color OFF remove any existing strobe setting for deleted namdArea
            // i.e. color has changed the state has not changed

            // console.log(
            //   `forcedLight: <<---DELETE--->> newForcedLightState fireflyId`,
            //   fireflyId
            // );

            let newForcedLightState = this.state.forcedLightState;
            newForcedLightState = newForcedLightState.filter(
              (id) => id !== fireflyId
            );
            this.setState({ forcedLightState: newForcedLightState });
          }

          // console.log(
          //   `forcedLight: <<---DELETE--->> deleteNamedArea id`,
          //   namedAreaEventForFirefly.id
          // );

          promiseArray.push(
            new Promise((resolve, reject) =>
              this.props.deleteNamedArea({
                values: { id: namedAreaEventForFirefly.id },
                resolve,
                reject,
              })
            )
          );
        }

        // Get the next ForcedLight priority
        const allForcedNamedAreas = this.props.namedAreaEvents?.filter(
          (event) => event.id.includes("FORCED-")
        );

        const highestForcedNamedAreaPriority = Math.max(
          ...allForcedNamedAreas.map((o) => o.priority),
          DEFAULT_START_FORCED_LIGHT_PRIORITY
        );

        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // #NOTE - there must be a button at the priority level
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        const newNamedArea = createNamedAreaAroundFirefly(
          fireflyId,
          { easting, northing },
          area,
          setFireflyColor,
          setFireflyState,
          1 // highestForcedNamedAreaPriority + 1
        );

        operationButton = newNamedArea.button?.[1]; // 1st, and only button, not highestForcedNamedAreaPriority + 1
        // get next highest
        operationButton.priority = highestForcedNamedAreaPriority + 1;

        //console.log(`forcedLight xxx operationButton`, operationButton);
        //console.log(`forcedLight xxx newNamedArea`, newNamedArea);

        // if toggle to change color, or change state, or there is a new color
        // #NOTE - below stops denies creating new event if same color,
        // however this is now superseded for forcing any color, even currently displayed color.
        //if (isColorChanged || isStateChanged) {

        // make a new event under all situations
        if (true) {
          // make a new event
          //
          isActivate = true;
          console.log(
            `forcedLight: <<---ADD--->> saveNewNamedArea newNamedArea`,
            newNamedArea
          );

          promiseArray.push(
            new Promise((resolve, reject) =>
              this.props.saveNewNamedArea({
                values: newNamedArea,
                resolve,
                reject,
              })
            )
          );
        }
      }

      if (promiseArray.length) {
        return Promise.all(promiseArray)
          .then((result) => {
            console.log(`FORCELIGHT: saveNewNamedArea `, result);
            if (isActivate) {
              this.props.activate(operationButton); // -> sendNamedAreaEventSingle
            }
          })
          .catch((error) => {
            console.log(
              `forcedLight: saveFireflyLedData saveNewNamedAre failed `,
              error
            );
            // #WIP - do something if fails!???
          });
      }
      /////////////////////
    };

    // add popup information
    const onEachFeatureFirefly = (feature, layer) => {
      // #REVIEW - does this feature have a property named popupContent?

      const { properties } = feature;
      const {
        id,
        area,
        location,
        position,
        easting,
        northing,
        z,
        utm_zone_number,
        utm_zone_letter,
        fireflyNote,
        color,
        timestamp,
        ups_id,
        //
        light, // #NOTE #REVIEW - if passing `light` to get 'led_state', why use `color`?
        event_id,
        mac,
        deviceStatus,
      } = properties;

      //console.log(`xxx properties`, properties);

      //console.log(`onEachFeatureFirefly feature`, feature);

      const lastStatusReport =
        timestamp !== undefined
          ? formatRelative(parseISO(timestamp), new Date(), {
              includeSeconds: true,
            })
          : "-";

      const { geometry } = feature;

      // coord geom is reversed
      const X = round(geometry.coordinates[0], 2);
      const Y = round(geometry.coordinates[1], 2);

      // #NOTE #REVIEW - if passing `light` to get 'led_state', why use `color`?
      const {
        // brightness,
        // color,
        // marker,
        led_state,
        // off_time,
        // on_time,
        // train,
      } = light;

      if (feature.properties && feature.properties.id) {
        if (false) {
          layer.bindPopup(
            `<div><strong>FireFly: </strong><a href="/admin/firefly/${id}">${id}</a></div>
            <div><strong>Controller: </strong><a href="/admin/controller/${ups_id}">${ups_id}</a></div>
            <hr/>
            <div>${this.testExternalStr} ${area}:${location}:${position} </div>
            <div><strong>Geom: </strong>E:${round(easting, 2)} N:${round(
              northing,
              2
            )} Z${Math.trunc(z)} ${utm_zone_number} ${utm_zone_letter}  </div>
            <div><strong>Image: </strong>X:${X} Y:${Y} </div>
            <div><strong>Color: </strong>Requested ${color.plan}, Reported ${
              color.status
            } </div>
            <div><strong>Last Report: </strong>${lastStatusReport} </div>`
          );
        } else if (false) {
          const popupInfo = `${id} <br> ${id} <br> <button data-key=${id} class="button-1">Edit</button><br> <button id=${id} class="delete">Delete</button>`;
          layer.bindPopup(popupInfo);
          // https://stackoverflow.com/questions/42599445/adding-buttons-inside-leaflet-popup
          layer.on("popupopen", (a) => {
            console.log("popup open", a.target);
            var marker = a.popup._source.feature.properties.id;
            console.log("popup marker", marker);

            console.log("popup open", a.target);
            var popUp = a.target.getPopup();
            console.log("popup popup", popUp);

            popUp
              .getElement()
              .querySelector(`.button-1`)
              .addEventListener("click", (e) => {
                console.log(
                  "clicked edit on marker: ",
                  e,
                  "target: ",
                  e.target,
                  "data-key ",
                  e.target.dataset.key
                );

                const id = e.target.dataset.key;
                if (!_isEmpty(id)) {
                  this.props.goto(`/admin/firefly/${id}`);
                }
              });
          });
        } else if (false) {
          layer.bindPopup(renderToString(<CustomReactPopup id={"12345"} />));
        } else {
          //

          // #WIP
          // collect events for this ff.
          // This is used to indicate which light is toggled in the popup and to
          // know which priority event to fire in the attached eventListener.
          const fireflyNamedAreaId = createFireflyNamedAreaId(id);

          const namedAreaEventForFirefly = this.props.namedAreaEvents?.find(
            (event) => event.id.includes(fireflyNamedAreaId)
          );

          // console.log(
          //   `fireflyMarkerPopup ONE fireflyNamedAreaId`,
          //   fireflyNamedAreaId,
          //   namedAreaEventForFirefly,
          //   this.props.namedAreaEvents,
          //   properties,
          //   layer
          // );

          const fireflyMarkerPopup = renderToString(
            <FireflyMarkerPopup
              id={id}
              data={{
                ups_id,
                easting,
                northing,
                X,
                Y,
                z,
                utm_zone_number,
                utm_zone_letter,
                fireflyNote,
                color,
                led_state,
                lastStatusReport,
                namedAreaEventForFirefly,
                light,
                role: this.props.role,
                ffMac: mac,
                deviceStatus,
                forcedClickList: [...this.state.forcedClickList],
              }}
            />
          );

          // after sending forcedClickList to the popup, clear it
          // acknowledge click while processing
          let newForcedClickList = [...this.state.forcedClickList];
          if (newForcedClickList.includes(id)) {
            newForcedClickList = newForcedClickList.filter(
              (item) => item !== id
            );
            this.setState({ forcedClickList: newForcedClickList });
          }

          layer.bindPopup(fireflyMarkerPopup, {
            maxWidth: "400", // auto // #REVIEW - FIXED SIZE OF POPUP #DEFAULT_
          });

          // #WIP - tool tip implementation integrated with the main FF marker
          //
          // This has been moved to a separate layer to enable display via 'layer control'
          // see also the 'zoomend' dynamic change of zoom
          //
          // #REVIEW - tooltip label for FF
          // * - could be used for TAGS?
          // * - could show which markers have Slough meters etc
          // see - https://stackoverflow.com/questions/49852157/add-custom-bindtooltip-class
          //
          // if (false) {

          //   const TooltipClass = {
          //     className: "class-tooltip",
          //   };

          //   // #REVIEW - style popup
          //   var PopupClass = {
          //     className: "class-popup",
          //   };

          //   //layer.bindPopup("Test Popup", PopupClass);

          //   layer.bindTooltip(
          //     `${location}:${position}`,
          //     {
          //       direction: "bottom",
          //       permanent: true,
          //       offset: [0, 20],
          //       //className: "transparent-tooltip",
          //       ...TooltipClass,
          //     },
          //     TooltipClass
          //   );
          // }

          layer.on("popupclose", (event) => {
            this.setState({ isPopupOpen: false });
          });
          layer.on("popupopen", (event) => {
            //console.log("popup event.target", event.target);

            //
            // #WIP - disable popup open re-rendering?
            if (false) {
              this.setState({ isPopupOpen: true });
            }

            var marker = event.popup._source.feature.properties.id;

            //console.log("popup marker", marker);

            var popUp = event.target.getPopup();
            // console.log("popup popup", popUp);
            // console.log("popup getElement", popUp.getElement());
            // console.log(
            //   "popup getElement.querySelector",
            //   popUp.getElement().querySelector(".popupFireflyMarkerLink")
            // );

            // console.log(
            //   'popUp.getElement().querySelector(".popupFireflyMarkerLink")',
            //   popUp.getElement().querySelector(".popupFireflyMarkerLink").innerHTML
            // );

            // added eventListener to firefly marker content
            // see - src/components/Map/FireflyMarkerPopup.js
            this.props.role.allowAdmin &&
              popUp
                .getElement()
                .querySelector(".popupFireflyMarkerLink")
                .addEventListener("click", (e) => {
                  // console.log(
                  //   "clicked edit on marker: ",
                  //   e,
                  //   "target: ",
                  //   e.target,
                  //   "dataid ",
                  //   e.target.getAttribute("dataid")
                  // );

                  const id = e.target.getAttribute("dataid");
                  if (!_isEmpty(id)) {
                    this.props.goto(`/admin/firefly/${id}`);
                  } else {
                    this.props.goto(`/admin/firefly/`);
                  }
                });
            // *************************

            // console.log(
            //   'popUp.getElement().querySelector(".popupControllerMarkerLink")',
            //   popUp.getElement().querySelector(".popupControllerMarkerLink")
            // ); //   .innerHTML

            this.props.role.allowAdmin &&
              popUp
                .getElement()
                .querySelector(".popupControllerMarkerLink")
                .addEventListener("click", (e) => {
                  // console.log(
                  //   "clicked edit on marker: ",
                  //   e,
                  //   "target: ",
                  //   e.target,
                  //   "dataid ",
                  //   e.target.getAttribute("dataid")
                  // );

                  const id = e.target.getAttribute("dataid");
                  if (!_isEmpty(id)) {
                    this.props.goto(`/admin/controller/${id}`);
                  } else {
                    this.props.goto(`/admin/controller/`);
                  }
                });

            // =======================================
            //
            // FORCE LIGHT CONTROL
            //
            // =======================================
            //
            // FORCE LIGHT CONTROL - RESET - popupFireflyMarkerToggleForcedClear
            //
            // Debug
            //

            // console.log(
            //   'popUp.getElement().querySelector(".popupFireflyMarkerToggleForcedClear")',
            //   popUp
            //     .getElement()
            //     .querySelector(".popupFireflyMarkerToggleForcedClear")
            // ); //   .innerHTML
            //

            popUp
              .getElement()
              .querySelector(".popupFireflyMarkerToggleForcedClear")
              .addEventListener("click", (e) => {
                // console.log(
                //   "clicked edit on marker: ",
                //   e,
                //   "target: ",
                //   e.target,
                //   "dataid ",
                //   e.target.getAttribute("dataid")
                // );

                if (!_isEmpty(id)) {
                  const IdArray = id.split("+");
                  const fireflyId = IdArray[0];
                  const setFireflyAction = IdArray[1];
                  // console.log(
                  //   "forcedLight: CLEAR  fireflyId, setFireflyAction",
                  //   fireflyId,
                  //   setFireflyAction
                  // );

                  // acknowledge click while processing
                  let newForcedClickList = [...this.state.forcedClickList];
                  if (!newForcedClickList.includes(fireflyId)) {
                    newForcedClickList.push(fireflyId);
                    this.setState({ forcedClickList: newForcedClickList });
                    this.setState({ forceMapRefresh: true });
                  }

                  // clear all forceLightStat
                  this.setState({ forcedLightState: [] });

                  // clear any events on the light
                  setForceLight(
                    "clear",
                    fireflyId,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  );
                }
              });

            //
            // FORCE LIGHT CONTROL - popupFireflyMarkerToggleForcedStateStrobe
            //
            // Debug
            //

            // console.log(
            //   'popUp.getElement().querySelector(".popupFireflyMarkerToggleForcedStateStrobe")',
            //   popUp
            //     .getElement()
            //     .querySelector(".popupFireflyMarkerToggleForcedStateStrobe")
            // ); //   .innerHTML
            //
            //
            // ****************************************
            popUp
              .getElement()
              .querySelector(".popupFireflyMarkerToggleForcedStateStrobe")
              .addEventListener("click", (e) => {
                // console.log(
                //   "click to checkbox state: ",
                //   e,
                //   "target: ",
                //   e.target,
                //   "dataid ",
                //   e.target.getAttribute("dataid")
                // );

                // Explanation
                //
                // After adding popup text to leafletjs, this attachs an eventListener to the class.
                //
                // Here we process events from the checkbox click for force light changes.
                // Because clicks on labels are processed first, so wait for a timeout before getting the check state.
                // If check exists it is CHECKED.
                // see - https://stackoverflow.com/questions/40496291/javascript-event-listener-on-a-checkbox
                //
                // When the checkbox changes update state with the current id / check state.

                const id = e.target.getAttribute("dataid");
                if (!_isEmpty(id)) {
                  const IdArray = id.split("+");
                  const fireflyId = IdArray[0];
                  const setFireflyColor = IdArray[1];
                  // console.log(
                  //   "forcedLight: STROBE  fireflyId, setFireflyColor",
                  //   fireflyId,
                  //   setFireflyColor
                  // );
                  //console.log("forcedLight:  fireflyId id", fireflyId);

                  setTimeout(() => {
                    const checkingCheck = popUp
                      .getElement()
                      .querySelector(
                        ".popupFireflyMarkerToggleForcedStateStrobe:checked"
                      );

                    let newForcedLightState = this.state.forcedLightState;
                    let isStrobe = false;
                    if (checkingCheck !== null) {
                      isStrobe = true;
                      newForcedLightState.push(fireflyId);
                    } else {
                      isStrobe = false;
                      newForcedLightState = newForcedLightState.filter(
                        (id) => id !== fireflyId
                      );
                    }
                    //console.log("forcedLight: STROBE  isStrobe", isStrobe);

                    // #WIP - may need to callback or sleep() until the state propogates

                    this.setState({ forcedLightState: newForcedLightState });

                    // acknowledge click while processing
                    let newForcedClickList = [...this.state.forcedClickList];
                    if (!newForcedClickList.includes(fireflyId)) {
                      newForcedClickList.push(fireflyId);
                      this.setState({ forcedClickList: newForcedClickList });
                      this.setState({ forceMapRefresh: true });
                    }

                    // OLD VERSION WHICH ONLY CHANGES STROBE IF A FORCE EVENT IS GOING
                    if (false) {
                      const fireflyNamedAreaId =
                        createFireflyNamedAreaId(fireflyId);
                      const namedAreaEventForFirefly =
                        this.props.namedAreaEvents?.find((event) =>
                          event.id.includes(fireflyNamedAreaId)
                        );

                      // console.log(
                      //   "forcedLight STROBE:  namedAreaEventForFirefly, newForcedLightState",
                      //   namedAreaEventForFirefly,
                      //   newForcedLightState
                      // );

                      if (namedAreaEventForFirefly) {
                        const { id: existingNamedAreaId, active } =
                          namedAreaEventForFirefly;

                        if (existingNamedAreaId?.includes("FORCED") && active) {
                          const { active_color } = namedAreaEventForFirefly;

                          // console.log(
                          //   "forcedLight STROBE: fireflyId,easting,northing,area,active_color,isStrobe",
                          //   fireflyId,
                          //   easting,
                          //   northing,
                          //   area,
                          //   active_color,
                          //   isStrobe
                          // );

                          setForceLight(
                            "lightState",
                            fireflyId,
                            easting,
                            northing,
                            area,
                            active_color,
                            isStrobe ? "strobe" : "on"
                          );
                        }
                      }
                    }

                    if (true) {
                      // console.log(
                      //   "forcedLight: STROBE: fireflyId,easting,northing,area,color, color.state,isStrobe",
                      //   fireflyId,
                      //   easting,
                      //   northing,
                      //   area,
                      //   color,
                      //   setFireflyColor,
                      //   isStrobe
                      // );

                      setForceLight(
                        "lightState",
                        fireflyId,
                        easting,
                        northing,
                        area,
                        color,
                        setFireflyColor, //setForceLight,
                        isStrobe ? "strobe" : "on" //setForceState
                      );
                    }
                  });
                }
              });

            //
            // FORCE LIGHT CONTROL - popupFireflyMarkerToggleForcedStateOff
            //
            // Debug
            //

            // console.log(
            //   'popUp.getElement().querySelector(".popupFireflyMarkerToggleForcedStateOff")',
            //   popUp
            //     .getElement()
            //     .querySelector(".popupFireflyMarkerToggleForcedStateOff")
            // ); //   .innerHTML
            //
            //
            // ****************************************

            // ****************************************
            popUp
              .getElement()
              .querySelector(".popupFireflyMarkerToggleForcedStateOff")
              .addEventListener("click", (e) => {
                // console.log(
                //   "click to checkbox state: ",
                //   e,
                //   "target: ",
                //   e.target,
                //   "dataid ",
                //   e.target.getAttribute("dataid")
                // );

                // Explanation
                //
                // After adding popup text to leafletjs, this attachs an eventListener to the class.
                //
                // Here we process events from the checkbox click for force light changes.
                // Because clicks on labels are processed first, so wait for a timeout before getting the check state.
                // If check exists it is CHECKED.
                // see - https://stackoverflow.com/questions/40496291/javascript-event-listener-on-a-checkbox
                //
                // When the checkbox changes update state with the current id / check state.

                const id = e.target.getAttribute("dataid");
                if (!_isEmpty(id)) {
                  const IdArray = id.split("+");
                  const fireflyId = IdArray[0];
                  const setFireflyColor = IdArray[1];
                  // console.log(
                  //   "forcedLight: STROBE  fireflyId, setFireflyColor",
                  //   fireflyId,
                  //   setFireflyColor
                  // );
                  //console.log("forcedLight:  fireflyId id", fireflyId);

                  setTimeout(() => {
                    const checkingCheck = popUp
                      .getElement()
                      .querySelector(
                        ".popupFireflyMarkerToggleForcedStateOff:checked"
                      );

                    let isOff = false; // not used ATM
                    if (checkingCheck !== null) {
                      isOff = true;
                      // console.log(
                      //   "forcedLight: OFF: fireflyId,easting,northing,area,color, color.state",
                      //   fireflyId,
                      //   easting,
                      //   northing,
                      //   area,
                      //   color,
                      //   setFireflyColor,
                      //   "off"
                      // );

                      setForceLight(
                        "lightState",
                        fireflyId,
                        easting,
                        northing,
                        area,
                        color,
                        setFireflyColor, //setForceLight,
                        "off"
                      );
                    } else {
                      isOff = false;
                      // console.log(
                      //   "forcedLight: ON: fireflyId,easting,northing,area,color, color.state",
                      //   fireflyId,
                      //   easting,
                      //   northing,
                      //   area,
                      //   color,
                      //   setFireflyColor,
                      //   "on"
                      // );

                      setForceLight(
                        "lightState",
                        fireflyId,
                        easting,
                        northing,
                        area,
                        color,
                        setFireflyColor, //setForceLight,
                        "on"
                      );
                    }

                    // acknowledge click while processing
                    let newForcedClickList = [...this.state.forcedClickList];
                    if (!newForcedClickList.includes(fireflyId)) {
                      newForcedClickList.push(fireflyId);
                      this.setState({ forcedClickList: newForcedClickList });
                      this.setState({ forceMapRefresh: true });
                    }
                  });
                }
              });
            // ****************************************

            // =======================================
            //
            // FORCE LIGHT CONTROL - popupFireflyMarkerToggleForcedColor
            //
            // DEBUG ....
            //
            // console.log(
            //   'popUp.getElement().querySelector(".popupFireflyMarkerToggleForcedColor")',
            //   popUp.getElement().querySelector(".popupFireflyMarkerToggleForcedColor")
            // ); //   .innerHTML

            const toggleFireflyLed = popUp
              .getElement()
              .querySelectorAll(".popupFireflyMarkerToggleForcedColor");

            for (let i = 0; i < toggleFireflyLed.length; i++) {
              toggleFireflyLed[i].addEventListener("click", (e) => {
                console.log(
                  "forcedLight: LIGHT clicked edit on marker: ",
                  e,
                  "target: ",
                  e.target,
                  "dataid ",
                  e.target.getAttribute("dataid"),
                  new Date().getTime()
                );

                // Explanation
                //
                // After adding popup text to leafletjs, this attachs an eventListener to the class.
                //
                // Here we process events from the button clicks for force light changes.
                // Check for any existing namedAreas (and therefore events) based on the fireflyId (createFireflyNamedAreaId)
                // We don't try to update the existing namedArea, instead we delete the current namedArea and add another one.
                // If the color has changed, delete the named area and make a new one.
                // If the color has toggled off, just delete the namedArea.
                //

                const id = e.target.getAttribute("dataid");
                if (!_isEmpty(id)) {
                  const IdArray = id.split("+");
                  const fireflyId = IdArray[0];
                  const setFireflyColor = IdArray[1];

                  const isActiveForcedButton =
                    setFireflyColor !== "FORCED_DISABLED";

                  console.log(
                    "forcedLight: LIGHT  fireflyId, setFireflyColor, geoJSONMarkersData",
                    fireflyId,
                    setFireflyColor,
                    geoJSONMarkersData
                  );

                  // acknowledge click while processing
                  let newForcedClickList = [...this.state.forcedClickList];
                  if (!newForcedClickList.includes(fireflyId)) {
                    newForcedClickList.push(fireflyId);
                    this.setState({ forcedClickList: newForcedClickList });
                    this.setState({ forceMapRefresh: true });
                  }

                  /// +++++++++++++++++++++++++++++++++++++++++++++++++++

                  const currentFireflyLight = geoJSONMarkersData?.features.find(
                    (ff) => ff.properties.id === fireflyId
                  )?.properties?.light;

                  console.log(
                    "forcedLight: LIGHT  currentFireflyLight, isActiveForcedButton",
                    currentFireflyLight,
                    isActiveForcedButton,
                    geoJSONMarkersData?.features
                  );

                  if (isActiveForcedButton && !_isEmpty(currentFireflyLight)) {
                    console.log(
                      "forcedLight: LIGHT: id,easting,northing,area,active_color, color.status",
                      fireflyId,
                      easting,
                      northing,
                      area,
                      setFireflyColor,
                      color.status
                    );

                    setForceLight(
                      "lightColor",
                      fireflyId,
                      easting,
                      northing,
                      area,
                      color,
                      setFireflyColor,
                      null // setFireflyState
                    );
                  }

                  if (false) {
                    // console.log("currentFireflyLight", currentFireflyLight);
                    if (!_isEmpty(currentFireflyLight)) {
                      const fireflyNamedAreaId =
                        createFireflyNamedAreaId(fireflyId);

                      const namedAreaEventForFirefly =
                        this.props.namedAreaEvents?.find((event) =>
                          event.id.includes(fireflyNamedAreaId)
                        );

                      // get strobe checkBox state
                      const isStrobe = this.state.forcedLightState.some(
                        (state) => state === fireflyId
                      );

                      // console.log(
                      //   `xxxx isStrobe this.state.forcedLightState`,
                      //   isStrobe,
                      //   this.state.forcedLightState,
                      //   fireflyId
                      // );

                      const newNamedArea = createNamedAreaAroundFirefly(
                        fireflyId,
                        { easting, northing },
                        area,
                        setFireflyColor,
                        isStrobe
                          ? { active_state: "strobe" }
                          : { active_state: "on" }
                      );

                      let operationButton = newNamedArea.button[0]; // 1st, and only button

                      const isToggle =
                        namedAreaEventForFirefly?.active_color?.toLowerCase() ===
                        setFireflyColor;

                      // console.log(
                      //   `forcedLight isToggle fireflyColor operationButton, namedAreaEventForFirefly`,
                      //   isToggle,
                      //   fireflyColor,
                      //   operationButton,
                      //   namedAreaEventForFirefly
                      // );

                      let promiseArray = [];
                      // in all cases just delete the current namedArea
                      if (namedAreaEventForFirefly) {
                        promiseArray.push(
                          new Promise((resolve, reject) =>
                            this.props.deleteNamedArea({
                              values: { id: namedAreaEventForFirefly.id },
                              resolve,
                              reject,
                            })
                          )
                        );
                      }

                      // if toggle to a new color, or there is a new color
                      if (!isToggle) {
                        // make a new event
                        //
                        // Get the next ForcedLight priority

                        const allForcedNamedAreas =
                          this.props.namedAreaEvents?.filter((event) =>
                            event.id.includes("FORCED-")
                          );

                        const highestForcedNamedAreaPriority = Math.max(
                          ...allForcedNamedAreas.map((o) => o.priority),
                          DEFAULT_START_FORCED_LIGHT_PRIORITY
                        );

                        // console.log(
                        //   `YYYY namedAreaEventForFirefly`,
                        //   namedAreaEventForFirefly
                        // );
                        // console.log(
                        //   `YYYY highestForcedNamedAreaPriority`,
                        //   highestForcedNamedAreaPriority
                        // );

                        // get next highest
                        operationButton.priority =
                          highestForcedNamedAreaPriority + 1;

                        promiseArray.push(
                          new Promise((resolve, reject) =>
                            this.props.saveNewNamedArea({
                              values: newNamedArea,
                              resolve,
                              reject,
                            })
                          )
                        );

                        return Promise.all(promiseArray)
                          .then((result) => {
                            console.log(
                              `saveFireflyLedData saveNewNamedArea SUCESSFULL! `,
                              result
                            );
                            this.props.activate(operationButton); // -> sendNamedAreaEventSingle
                            //
                            // #WIP!!!!!!!!!!!!!!!!1
                            // set the popup flag as FALSE - this allows updating of dlg
                            //this.setState({ isPopupOpen: false });
                            // reload the popup
                            // https://stackoverflow.com/questions/51732698/leaflet-popup-update-resizing-solution-recreating-a-popup-everytime-unable
                            // if (toggleFireflyLed && !toggleFireflyLed._updated) {
                            //   toggleFireflyLed._updated = true; // Set flag to prevent looping.
                            //   toggleFireflyLed.update();
                            // }
                          })
                          .catch((error) => {
                            console.log(
                              `saveFireflyLedData saveNewNamedAre failed `,
                              error
                            );
                            // #WIP - do something if fails!???
                          });
                      }
                    }
                  }

                  /// +++++++++++++++++++++++++++++++++++++++++++++++++++
                }
              });
            }

            // *************************
          });
        }

        this.layerlistFireflies[feature.properties.id] = layer;
      }

      if (DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER) {
        if (feature.properties && feature.properties.location) {
          // adds a separate layer specifically for location
          this[`${feature.properties.location}`].addLayer(layer);
        }
      }

      //----------------------------------------------------
      //
      // MAP MARKER MOVE FIREFLY POSITIONS - DRAG EVENTS
      //
      //----------------------------------------------------

      layer.on("dragstart", (e) => {
        //
      });

      // markerPositionChanges

      layer.on("dragend", (e) => {
        const marker = e.target;
        const position = marker.getLatLng();
        const markerProperties = marker.feature.properties;
        const { id, forced } = markerProperties;

        // console.log("xxx dragend Firefly position", id, position);
        // console.log(
        //   "xxx dragend Firefly fire off FF update!!!! markerProperties",
        //   marker,
        //   markerProperties
        // );
        // console.log(
        //   "xxx dragend Firefly fire off FF update!!!! forced",
        //   forced ? "FORCED!!!!!!!!!!!!!!!!" : "Not forced",
        //   id
        // );

        if (forced) {
          // if Markers has a FORCED- event, clear it
          // clear all forceLightStat
          this.setState({ forcedLightState: [] });
          // clear any events on the light
          setForceLight("clear", id, null, null, null, null, null, null);
        }

        let newMarkerPositionChanges = this.state.markerPositionChanges;
        newMarkerPositionChanges[id] = {
          id: id,
          type: "firefly",
          properties: markerProperties,
          position: position,
        };
        this.setState({ markerPositionChanges: newMarkerPositionChanges });
      });
    };

    // This is very important! Use a canvas otherwise the chart is too heavy for the browser when
    // the number of points is too high, as in this case where we have around 300K points to plot
    var myRenderer = L.canvas({
      padding: 0.5,
    });

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerFirefly = (feature, latlng) => {
      // ####SVG Marker###

      // #DEBUG
      // #KEEP THIS...
      const iconColor = { stroke: "grey", fill: "grey" };
      const markerColor = {
        stroke: feature.properties.color.plan,
        fill: feature.properties.color.status,
      };

      const { svgIcon, svgStyle } = markerDefinition(feature);
      const currentZoom = this.map.getZoom();

      return L.marker(latlng, {
        renderer: myRenderer,
        //icon: makeIcon("CrossMarker", iconColor, 1), // default to 'cross' on first write of coordinate message
        //icon: makeIcon("CrossMarker", markerColor, currentZoom), // default to 'cross' on first write of coordinate message
        icon: makeIcon(svgIcon, svgStyle, currentZoom),
        draggable: false,
      });
    };

    // TESTING_DRAG

    // set style of firefly markers
    const fireflyStyle = (feature) => {
      let setFillColor;
      if (feature.properties.color) {
        const color = feature.properties.color;
        switch (color.status) {
          case "green":
            setFillColor = "green";
            break;
          case "blue":
            setFillColor = "blue";
            break;
          case "amber":
            setFillColor = "orange";
            break;
          case "white":
            setFillColor = "white";
            break;

          case "red":
            setFillColor = "red";
            break;

          default:
            setFillColor = "green";
            break;
        }
      }

      return {
        radius: 6,
        fillColor: setFillColor,
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    };

    // determine unique properties array from geoJson
    //
    let uniqueProperties = geoJSONMarkersData.features.reduce(
      (acc, { properties }) => {
        Object.entries(properties).forEach(([key, val]) => {
          acc[key] = acc[key] || new Set();
          acc[key].add(val);
        });

        return acc;
      },
      {}
    );

    if (DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER) {
      let locations = [];
      this.locationGroupLayers = {};

      if (uniqueProperties["location"]) {
        locations = [...uniqueProperties["location"]].sort(sortAlphaNum);
        locations.forEach((value, idx) => {
          //console.log("locations[idx]", value);

          this[value] = L.featureGroup().addTo(this.map);
          // -> this makes firefly location group a separate layer
          // disabled for now so does not crowd the layer control
          this.locationGroupLayers[value] = this[value];
        });
      }
    }

    // #REVIEW  - Issue is that with growing array location groups are not created on startup.
    // Need to make these with each update of the state geoJson
    // constrain to area
    const filterArea = (feature, layer) => {
      return feature.properties.area === mineLevelId; //"DMLZ_Undercut"; //this.state.map.area; // "DMLZ_Extraction";
    };

    // add firefly markers to the map
    // Note - Only Controllers and Fireflies are add to map by default (.addTo(this.map))
    this.geoJSONGroupLayer = L.geoJSON(geoJSONMarkersData, {
      pointToLayer: pointToLayerFirefly,
      onEachFeature: onEachFeatureFirefly,
      style: fireflyStyle, //#DEBUG - DISABLED FOR RECTANGLE TESTING  ###DEFAULT_MARKER####
      //coordsToLatLng: coordsToLatLng, // <<--- #REVIEW I still don't underestand what this does
      filter: filterArea,
    }).addTo(this.map);

    // display the layer when the map is loaded
    // if (!isMapLoading) {
    //   this.geoJSONGroupLayer.addTo(this.map);
    // }

    // WHY? Why do this.....?
    if (false) {
      let updatedFireflyIds = [];
      // remove marker from the update list
      geoJSONMarkersData.features.map((feature, idx) => {
        const {
          properties: { id },
        } = feature;
        // remove FF Id from fireflyIdsUpdateList after it's been processed
        //
        updatedFireflyIds.push(id);
      });
      // remove FF Id from fireflyIdsUpdateList
      this.props.fireflyIdsUpdateListDeleteId(updatedFireflyIds);
    }

    // ********************************************************************************
    //
    // Setup and draw the Fireflies - tooltips
    //
    // These are drawn on a separate layer
    //
    // ********************************************************************************

    // add popup information
    const onEachFeatureFireflyTooltip = (feature, layer) => {
      const { properties } = feature;
      const { location, position } = properties;

      if (feature.properties && feature.properties.id) {
        // #REVIEW - tooltip label for FF
        // * - could be used for TAGS?
        // * - could show which markers have Slough meters etc
        // see - https://stackoverflow.com/questions/49852157/add-custom-bindtooltip-class

        const TooltipClass = {
          className: "class-tooltip",
        };

        layer.bindTooltip(
          `${location}:${position}`,
          {
            direction: "bottom",
            permanent: true,
            offset: [0, 20],
            //className: "transparent-tooltip",
            ...TooltipClass,
          },
          TooltipClass
        );
      }
    };

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerFireflyTooltip = (feature, latlng) => {
      return L.circleMarker(latlng, {
        renderer: myRenderer,
        clickable: false,
        // make marker ~invisible
        radius: 5,
        opacity: 0,
        fillOpacity: 0.2,
      });
    };

    // add firefly tooltip markers to the map
    this.geoJSONGroupLayerFireflyTooltips = L.geoJSON(geoJSONMarkersData, {
      pointToLayer: pointToLayerFireflyTooltip,
      onEachFeature: onEachFeatureFireflyTooltip,
      filter: filterArea,
    }); // .addTo(this.map);

    // ********************************************************************************
    //
    // Setup and draw the Fireflies - simple
    //
    // This is a very simple layer to show marker positions when the map is moved
    //
    // These are drawn on a separate layer
    //
    // ********************************************************************************

    // add popup information
    const onEachFeatureFireflySimple = (feature, layer) => {
      const { properties } = feature;
      const { location, position } = properties;

      if (feature.properties && feature.properties.id) {
        // #REVIEW - tooltip label for FF
        // * - could be used for TAGS?
        // * - could show which markers have Slough meters etc
        // see - https://stackoverflow.com/questions/49852157/add-custom-bindtooltip-class

        const TooltipClass = {
          className: "class-tooltip",
        };

        layer.bindTooltip(
          `${location}:${position}`,
          {
            direction: "bottom",
            permanent: true,
            offset: [0, 20],
            //className: "transparent-tooltip",
            ...TooltipClass,
          },
          TooltipClass
        );
      }
    };

    // see - 'pointToLayerFirefly'
    const pointToLayerFireflySimple = (feature, latlng) => {
      const { svgIcon, svgStyle } = markerDefinition(feature);
      const currentZoom = this.map.getZoom();

      // for simple markers make icon opaque to emphasise markers are only 'indicators' of position
      const simpleSvgStyle = { ...svgStyle, opacity: "0.6" };

      return L.marker(latlng, {
        renderer: myRenderer,
        //icon: makeIcon("CrossMarker", iconColor, 1), // default to 'cross' on first write of coordinate message
        //icon: makeIcon("CrossMarker", markerColor, currentZoom), // default to 'cross' on first write of coordinate message
        icon: makeIcon(svgIcon, simpleSvgStyle, currentZoom),
        draggable: false,
      });
    };

    // add firefly tooltip markers to the map
    this.geoJSONGroupLayerFireflySimple = L.geoJSON(geoJSONMarkersData, {
      pointToLayer: pointToLayerFireflySimple,
      //onEachFeature: onEachFeatureFireflySimple,
      filter: filterArea,
    }); // .addTo(this.map);

    // ********************************************************************************
    //
    // Setup and draw the Fireflies - Forced lights
    //
    // These Forced light tooltips used as an indicator of where forced lights are located
    //
    // ********************************************************************************

    this.layerlistFirefliesForced = {};

    // add popup information
    const onEachFeatureFireflyForcedToolTips = (feature, layer) => {
      const { properties } = feature;
      const { location, position } = properties;

      if (feature.properties && feature.properties.id) {
        // #REVIEW - tooltip label for FF
        // * - could be used for TAGS?
        // * - could show which markers have Slough meters etc
        // see - https://stackoverflow.com/questions/49852157/add-custom-bindtooltip-class

        const TooltipClass = {
          className: "class-tooltip",
        };

        layer.bindTooltip(
          `${location}:${position}`,
          {
            direction: "bottom",
            permanent: true,
            offset: [0, 20],
            //className: "transparent-tooltip",
            ...TooltipClass,
          },
          TooltipClass
        );
      }

      this.layerlistFirefliesForced[feature.properties.id] = layer;
    };

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerFireflyForcedToolTips = (feature, latlng) => {
      return L.circleMarker(latlng, {
        renderer: myRenderer,
        clickable: false,
        // make marker ~invisible
        radius: 5,
        opacity: 0,
        fillOpacity: 0.2,
      });
    };

    // add firefly tooltip markers to the map
    this.geoJSONGroupLayerFireflyForcedTooltips = L.geoJSON(
      geoJSONMarkersDataForced,
      {
        pointToLayer: pointToLayerFireflyForcedToolTips,
        onEachFeature: onEachFeatureFireflyForcedToolTips,
        filter: filterArea,
      }
    ); // .addTo(this.map);

    // ********************************************************************************
    //
    // Setup and draw the Tags
    //
    // These are drawn on a separate layer
    //
    // ********************************************************************************

    // add popup information
    //
    // #REVIEW - not required ATM. Left in place for later review???
    //
    const onEachFeatureTags = (feature, layer) => {
      const { properties } = feature;
      const { location, position, id } = properties;

      if (feature.properties && feature.properties.id) {
        const TooltipClass = {
          className: "class-tooltip",
        };

        layer.bindTooltip(
          `${location}:${position}`,
          {
            direction: "bottom",
            permanent: true,
            offset: [0, 20],
            //className: "transparent-tooltip",
            ...TooltipClass,
          },
          TooltipClass
        );
      }
    };

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerTags = (feature, latlng) => {
      // #REVIEW - could integrate tag marker into this function
      // const { svgIcon, svgStyle } = markerDefinition(feature);
      const svgIcon = "WorkerMarker";
      const svgStyle = { stroke: "fuchsia", fill: "sienna" };
      const currentZoom = this.map.getZoom();

      return L.marker(latlng, {
        renderer: myRenderer,
        clickable: false,
        icon: makeIcon(svgIcon, svgStyle, currentZoom),
      });
    };

    // add firefly tag markers to the map
    this.geoJSONGroupLayerTags = L.geoJSON(geoJSONMarkersData, {
      pointToLayer: pointToLayerTags,
      //onEachFeature: onEachFeatureTags,
      filter: filterArea,
    }); // .addTo(this.map);

    // ********************************************************************************
    //
    // Setup and draw the marker points - map scaling points and origin/centre/max markers
    //
    // ********************************************************************************

    // setup scaling coord reference point markers
    const onEachFeatureMarker = (feature, layer) => {
      layer.bindPopup(
        "ref pt:" +
          JSON.stringify(feature.properties.index) +
          " => " +
          JSON.stringify(feature.geometry.coordinates)
      );
      layer.on("dragend", function (e) {
        const marker = e.target;
        const position = marker.getLatLng();
        //console.log("xxx dragend refPt position", position);
        marker
          .setLatLng(position, { draggable: "true" })
          .bindPopup("" + position) // #REVIEW "" is hack as bindPopup will not accept just numbers
          .update();
      });
      layer.on("click", (e) => {
        //transform from pixels to points
        // const transform = this.Transform(
        //   this.standardPtsUtmX(),
        //   this.localPtsX()
        // );

        const transformPixelsToUtm = this.props.mineLevel.transformPixelsToUtm;

        const marker = e.target;
        const position = marker.getLatLng();
        //console.log("xxx dragend refPt position", JSON.stringify(position));

        const objLatLng = transformPixelsToUtm.transform({
          //transform
          lat: position.lat,
          lng: position.lng,
        });

        // console.log(
        //   "xxx feature.properties",
        //   JSON.stringify(feature.properties)
        // );

        const id = `ID:${feature.properties.id}`;

        // original geoJson coordinates
        const geoJsonPt = `E:${feature.properties.easting}, N:${feature.properties.northing}`;
        // image coordinates
        const imagePt = Object.values(position).reverse(); // map is y, x referenced. Reverse to display as x,y.
        // transformed image -> physical
        const coordPt = Object.values(objLatLng).reverse();

        marker
          .setLatLng(position, { draggable: "true" })
          .bindPopup(
            `<div>${id} </div>
            <div>GeoJ: ${geoJsonPt} </div>
            <div>Image: X: ${round(imagePt[0], 2)}, Y: ${round(
              imagePt[1],
              2
            )} </div>
            <div>Coord: E: ${round(coordPt[0], 2)}, N: ${round(
              coordPt[1],
              2
            )}</div>`
          )
          .update();

        console.log(`xxx clicked - Ref Pt marker - UTM Coords:[${coordPt}]`);
      });
    };

    // ******************************************************************
    //
    // Add general purpose markers to map
    //
    // ******************************************************************
    this.markerGroupLayer = L.geoJSON(
      this.localPtsGeoJson(this.markerPts({ width, height })),
      {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {
            //icon: greenIcon, // <--- #TODO FIX THIS!
            draggable: true,
          });
        },
        onEachFeature: onEachFeatureMarker,
      }
    ); //.addTo(this.map);

    // #Note - adding to the map before adding to layer will set [x] default

    // ******************************************************************
    //
    // Add coord reference point markers to map
    //
    // ******************************************************************
    this.refPtGroupLayer = L.geoJSON(this.localPtsGeoJson(localPtsX), {
      pointToLayer: function (feature, latlng) {
        return L.marker(latlng, {
          //icon: greenIcon, // <--- #TODO FIX THIS!
          draggable: true,
        });
      },
      onEachFeature: onEachFeatureMarker,
    }); //.addTo(this.map);
    // #Note - adding to the map before adding to layer will set [x] default .addTo(this.map);

    // ********************************************************************************
    //
    // Setup and draw the controller markers
    //
    // ********************************************************************************

    // list of controller layers
    this.layerlistControllers = {};

    // add popup information
    const onEachFeatureController = (feature, layer) => {
      const { properties } = feature;
      const {
        id,
        area,
        location,
        position,
        easting,
        northing,
        utm_zone_number,
        utm_zone_letter,
        z,
        color,
        timestamp,
        user_relay,
      } = properties;

      const lastStatusReport =
        timestamp !== undefined
          ? formatRelative(parseISO(timestamp), new Date(), {
              includeSeconds: true,
            })
          : "-";
      const { geometry } = feature;

      // coord geom is reversed
      const X = round(geometry.coordinates[0], 2);
      const Y = round(geometry.coordinates[1], 2);

      if (feature.properties && feature.properties.id) {
        if (false) {
          layer.bindPopup(
            `<div><strong>Controller: </strong><a href="/admin/controller/${id}">${id}</a></div>
          <hr/>
          <div>${this.testExternalStr} ${area} </div>
         <div><strong>Geom: </strong> E:${round(easting, 2)} N:${round(
              northing,
              2
            )} Z${Math.trunc(z)} ${utm_zone_number} ${utm_zone_letter}  </div>
         <div><strong>Image: </strong>X:${X} Y:${Y} </div>
         <div><strong>Color: </strong>${color} </div>
         <div><strong>Last Report: </strong>${lastStatusReport} </div>`
          );
        } else {
          //
          const controllerMarkerPopup = renderToString(
            <ControllerMarkerPopup
              id={id}
              data={{
                id,
                easting,
                northing,
                X,
                Y,
                z,
                utm_zone_number,
                utm_zone_letter,
                color,
                location,
                lastStatusReport,
                user_relay,
                role: this.props.role,
              }}
            />
          );
          layer.bindPopup(controllerMarkerPopup, {
            maxWidth: "250", // auto // #REVIEW - FIXED SIZE OF POPUP #DEFAULT_
          });

          layer.on("popupclose", (event) => {
            this.setState({ isPopupOpen: false });
          });
          layer.on("popupopen", (event) => {
            // #WIP - disable popup open re-rendering?
            if (false) {
              this.setState({ isPopupOpen: true });
            }
            var marker = event.popup._source.feature.properties.id;
            var popUp = event.target.getPopup();

            // added eventListener to controller marker content
            // see - src/components/Map/ControllerMarkerPopup.js

            this.props.role.allowAdmin &&
              popUp
                .getElement()
                .querySelector(".popupControllerMarkerLink")
                .addEventListener("click", (e) => {
                  console.log(
                    "clicked edit on marker: ",
                    e,
                    "target: ",
                    e.target,
                    "dataid ",
                    e.target.getAttribute("dataid")
                  );

                  const id = e.target.getAttribute("dataid");
                  if (!_isEmpty(id)) {
                    this.props.goto(`/admin/controller/${id}`);
                  } else {
                    this.props.goto(`/admin/controller/`);
                  }
                });

            // *************************
          });
        }

        this.layerlistControllers[feature.properties.id] = layer;
      }

      // #REVIEW - can't do this as-is b/c location is an array of positions.
      // Is this a duplication from FF which has never worked?
      // if (feature.properties && feature.properties.location) {
      //   // adds a separate layer specifically for location
      //   this[`${feature.properties.location}`].addLayer(layer);
      // }

      //----------------------------------------------------
      //
      // MAP MARKER MOVE CONTROLLER POSITIONS - DRAG EVENTS
      //
      //----------------------------------------------------

      layer.on("dragstart", (e) => {
        //
      });

      // markerPositionChanges

      layer.on("dragend", (e) => {
        const marker = e.target;
        const position = marker.getLatLng();
        const markerProperties = marker.feature.properties;
        const { id } = markerProperties;

        let newMarkerPositionChanges = this.state.markerPositionChanges;
        newMarkerPositionChanges[id] = {
          id: id,
          type: "controller",
          properties: markerProperties,
          position: position,
        };
        this.setState({ markerPositionChanges: newMarkerPositionChanges });
      });
    };

    // This is very important! Use a canvas otherwise the chart is too heavy for the browser when
    // the number of points is too high, as in this case where we have around 300K points to plot
    // var myRenderer = L.canvas({
    //   padding: 0.5,
    // });

    // set the points markers. See - https://leafletjs.com/examples/geojson/
    const pointToLayerController = (feature, latlng) => {
      // #KEEP THIS...
      const iconColor = { stroke: "blue", fill: "blue" };
      const currentZoom = this.map.getZoom();

      return L.marker(latlng, {
        renderer: myRenderer,
        icon: makeIcon("SquareMarker", iconColor, currentZoom), // default to 'cross' on first write of coordinate message
        draggable: false,
      });
    };

    // set style of controller markers
    const controllerStyle = (feature) => {
      let setFillColor = "green";
      if (feature.properties.color) {
        const color = feature.properties.color;
        switch (color.status) {
          case "green":
            setFillColor = "green";
            break;
          case "blue":
            setFillColor = "blue";
            break;
          case "amber":
            setFillColor = "orange";
            break;
          case "white":
            setFillColor = "white";
            break;

          case "red":
            setFillColor = "red";
            break;

          default:
            setFillColor = "green";
            break;
        }
      }

      return {
        radius: 6,
        fillColor: setFillColor,
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    };

    // add controller markers to the map
    // Note - Only Controllers and Fireflies are add to map by default (.addTo(this.map))
    this.geoJSONControllerGroupLayer = L.geoJSON(geoJSONControllers, {
      pointToLayer: pointToLayerController,
      onEachFeature: onEachFeatureController,
      style: controllerStyle,
      //coordsToLatLng: coordsToLatLng, // <<--- #REVIEW I still don't underestand what this does
      filter: filterArea,
    }).addTo(this.map);

    // display the layer when the map is loaded
    // if (!isMapLoading) {
    //   this.geoJSONControllerGroupLayer.addTo(this.map);
    // }

    // ********************************************************************************
    //
    // Setup and draw the named area polygons
    //
    // ********************************************************************************

    // list of named area polygons
    this.layerlistNamedAreas = {};
    this.parentsUnionGroupLayers = {};

    const onEachFeatureNamedAreas = (feature, layer) => {
      if (feature.properties && feature.properties.id) {
        this.layerlistNamedAreas[feature.properties.id] = layer;
      }
      layer.on("click", (e) => {
        const id = feature.properties.id.split(":")[1]; // eg. from "DMLZ_Extraction:182736182736" -> "182736182736"
        const namedAreaPolygon = e.target;
        namedAreaPolygon.bindPopup(`<div>Polygon: ${id} </div>`);
      });
    };

    // add named area polygons to the map
    this.geoJSONNamedAreaGroupLayer = L.geoJSON(geoJSONNamedAreas, {
      style: this.geojsonPolygonStyle,
      onEachFeature: onEachFeatureNamedAreas,
      coordsToLatLng: function (coords) {
        // swaps the coordinate references around so when polygons are created the pixel refer are [x,y] , not [y,x]
        //                    latitude , longitude, altitude
        return new L.LatLng(coords[1], coords[0], coords[2]); // <--------- Normal behavior
        //return new L.LatLng(coords[0], coords[1], coords[2]);
      },
    }); // #Note - adding to the map before adding to layer will set [x] default .addTo(this.map);

    // update leaflet id to properties (used to delete.edit shapes in local state)
    this.geoJSONNamedAreaGroupLayer.eachLayer(function (layer) {
      layer.feature.properties._leaflet_id = layer._leaflet_id; //#TODO - change to L.Stamp()
    });

    //#TODO - change to L.Stamp()
    //     Also note that .getLayers() works for LayerGroup (and FeatureGroup and GeoJson), but not for L.Map.
    // Usage of "private" properties and methods like _layers or _leaflet_id or _latlng is discouraged.

    // add layers to the local maps state
    const geoJsonPixels = this.geoJSONNamedAreaGroupLayer.toGeoJSON();

    //const mineLevel = this.props.mineLevel;
    const mineLevelKey = mineLevel.id;
    let mineLevelObj = {};
    mineLevelObj[mineLevelKey] = { ...mineLevel };

    const geoJsonUtm = this.convertGeoJsonPixelsToUtm(
      geoJsonPixels,
      mineLevelObj
    );

    //console.log("qqq add layers to the local maps state geoJson", geoJsonUtm);
    this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
    this.setState({ thislocalMapState: geoJsonUtm });

    // push layer to back layer.bringToFront()
    this.geoJSONNamedAreaGroupLayer.bringToBack();

    // ********************************************************************************
    //
    // Setup and draw the named area parent multipolygons
    //
    // ********************************************************************************

    // create separate layer references for named area parents
    // determine unique properties array from geoJson
    //

    const geoJSONNamedAreaParentsThisArea =
      geoJSONNamedAreaParents.features.filter(
        (feature) => feature.properties.area === mineLevel.id
      );
    let uniqueNamedAreaParentProperties =
      geoJSONNamedAreaParentsThisArea.reduce((acc, { properties }) => {
        Object.entries(properties).forEach(([key, val]) => {
          acc[key] = acc[key] || new Set();
          acc[key].add(val);
        });

        return acc;
      }, {});
    let regions = [];
    this.regionGroupLayers = {};
    if (uniqueNamedAreaParentProperties["name"]) {
      regions = [...uniqueNamedAreaParentProperties["name"]].sort(sortAlphaNum);
      regions.forEach((value, idx) => {
        // -> this makes region group a separate layer
        // disabled for now so does not crowd the layer control
        this[value] = L.featureGroup(); //.addTo(this.map);
        this.regionGroupLayers[value] = this[value];
      });
    }

    // list of named area polygons
    this.layerlistNamedAreaParents = {};

    const onEachFeatureNamedAreaParents = (feature, layer) => {
      if (feature.properties && feature.properties.id) {
        this.layerlistNamedAreaParents[feature.properties.id] = layer;
      }
      layer.on("click", (e) => {
        const id = feature.properties.id.split(":")[1]; // eg. from "DMLZ_Extraction:Area_A" -> "Area_A"
        const namedAreaParentPolygon = e.target;
        namedAreaParentPolygon.bindPopup(`<div>Named Area ID: ${id} </div>`);
      });

      if (feature.properties && feature.properties.name) {
        // adds a separate layer specifically for `name`

        // #NOTE - stops `geoJSONNamedAreaParentGroupLayer` adding layers on startup.
        // `geoJSONNamedAreaParentGroupLayer` is superceded in favour of
        // `parentsUnionGroupLayers`
        if (false) {
          this[`${feature.properties.name}`].addLayer(layer);
        }
      }
    };

    // add named area polygons to the map
    this.geoJSONNamedAreaParentGroupLayer = L.geoJSON(geoJSONNamedAreaParents, {
      style: this.geojsonPolygonStyle,
      onEachFeature: onEachFeatureNamedAreaParents,
      coordsToLatLng: function (coords) {
        // swaps the coordinate references around so when polygons are created the pixel refer are [x,y] , not [y,x]
        //                    latitude , longitude, altitude
        return new L.LatLng(coords[1], coords[0], coords[2]); // <--------- Normal behavior
        //return new L.LatLng(coords[0], coords[1], coords[2]);
      },
      filter: filterArea,
    }); // .addTo(this.map);

    // #Note - adding to the map before adding to layer will set [x] default
    // update leaflet id to properties (used to delete.edit shapes in local state)
    // this.geoJSONNamedAreaParentGroupLayer.eachLayer(function (layer) {
    //   layer.feature.properties._leaflet_id = layer._leaflet_id; //#TODO - change to L.Stamp()
    // });

    // push layer to back layer.bringToFront()
    this.geoJSONNamedAreaParentGroupLayer.bringToBack();

    // ********************************************************************************
    //
    // Setup the map layers into control groups and display controls
    //
    // ********************************************************************************

    // #NOTE - no longer used, instead see groupedOverlays below

    // setup layer groups
    let baseLayers = { MineMap: base };

    // setup layer groups overlayers (see also https://leafletjs.com/reference-1.6.0.html#control-layers-addoverlay)
    let overLayers = {
      // "<img src='my-layer-icon' /> <span class='my-layer-item'>Firefly</span>": this
      //   .geoJSONGroupLayer,
      FireFlys: this.geoJSONGroupLayer,
      Simple: this.geoJSONGroupLayerFireflySimple,
      "FireFly ID": this.geoJSONGroupLayerFireflyTooltips,
      Forced: this.geoJSONGroupLayerFireflyForcedTooltips,
      //Tags: this.geoJSONGroupLayerTags,
      Controllers: this.geoJSONControllerGroupLayer,
      //      Regions: this.geoJSONNamedAreaGroupLayer,
      Polygons: this.geoJSONNamedAreaParentGroupLayer,
      Marker: this.markerGroupLayer,
      RefPt: this.refPtGroupLayer,
      //Cable: this.polylineGroupLayers,
      //Editable: this.editableLayers,
    };

    // // spread the objects to join the location layers to the overlay
    // if (true) {
    //   overLayers = {
    //     ...overLayers,
    //     //...this.locationGroupLayers,
    //     ...this.regionGroupLayers,
    //   };
    // }
    // var layerControl = L.control.layers(null, overLayers, {
    //   // null -> baseLayers
    //   collapsed: true, //
    // });

    // // add it to the map
    // layerControl.addTo(this.map);

    // console.log(
    //   `this.parentsUnionGroupLayers`,
    //   this.parentsUnionGroupLayers
    // );

    // sort the named areas to display ordered
    const orderedParentsUnionGroupLayers = Object.keys(
      this.parentsUnionGroupLayers
    )
      .sort()
      .reduce((obj, key) => {
        obj[key] = this.parentsUnionGroupLayers[key];
        return obj;
      }, {});

    // APP_TERMINOLOGY
    const strNamedAreas = "Polygons";

    //
    // Use the custom grouped layer control, not "L.control.layers"
    // https://github.com/ismyrnow/leaflet-groupedlayercontrol
    // see also -  https://stackoverflow.com/questions/34384905/leaflet-how-to-toggle-all-layers-either-off-or-on-at-once/34392545#34392545
    let groupedOverlays = {
      Markers: {
        FireFlys: this.geoJSONGroupLayer,
        Simple: this.geoJSONGroupLayerFireflySimple,
        "FireFly ID": this.geoJSONGroupLayerFireflyTooltips,
        Forced: this.geoJSONGroupLayerFireflyForcedTooltips,
        Controllers: this.geoJSONControllerGroupLayer,
        //"All Named Areas": this.geoJSONNamedAreaParentGroupLayer,
        Tags: this.geoJSONGroupLayerTags,
        //Editable: this.editableLayers,
      },

      // #NOTE - these are commented out to disable the group listing for now.
      // #TODO - add displaying this as a setup config option
      //"Named Area": { ...this.regionGroupLayers }, // multipolygons
      Polygons: { ...orderedParentsUnionGroupLayers }, //this.parentsUnionGroupLayers
    };

    if (DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER) {
      groupedOverlays = {
        ...groupedOverlays,
        Locations: this.locationGroupLayers,

        //Regions: this.regionGroupLayers,
      };
    }

    const options = {
      collapsed: true,
      //exclusiveGroups: ["Named Areas"],
      groupsCollapsable: true, // see - https://github.com/ismyrnow/leaflet-groupedlayercontrol/pull/46
      // Show a checkbox next to non-exclusive group labels for toggling all
      groupCheckboxes: true,
    };

    // Use the custom grouped layer control, not "L.control.layers"
    // https://github.com/ismyrnow/leaflet-groupedlayercontrol
    // see also -  https://stackoverflow.com/questions/34384905/leaflet-how-to-toggle-all-layers-either-off-or-on-at-once/34392545#34392545
    this.controller = L.control
      .groupedLayers(null, groupedOverlays, options)
      .addTo(this.map);

    // if (!isMapLoading) {
    //   this.controller.addTo(this.map);
    // }

    // ********************************************************************************
    //
    // Set polylines joining the Fireflies
    //
    //
    // #NOTE: this has to be done after the locationGroupLayers has been rendered, and the group control
    // is defined as it uses addOverlay() to add the polylines to the existing control
    //
    // ********************************************************************************

    if (DEFAULT_SHOW_FIREFLY_POLYLINE_GROUP_LAYER) {
      this.polylineGroupLayers = {};

      // step through the list of locationGroupLayers
      for (const [key, value] of Object.entries(this.locationGroupLayers)) {
        // create an object with the posObject[position] = latlng coordinates
        let posObject = {};

        // loop each layer - #REVIEW - this could be labourious - perhaps only do if selected to display?
        // eslint-disable-next-line no-loop-func
        value.eachLayer(function (layer) {
          posObject[layer.feature.properties.position] = layer.getLatLng();
        });

        // sort the posObject by position order (i.e. object key) so line draws from position 1 -> n

        // see - https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
        const ordered = {};
        Object.keys(posObject)
          .sort()
          .forEach(function (key) {
            ordered[key] = posObject[key];
          });

        // plot a polyline using ordered array of values
        // create name object from layer name (i.e. key is the POSITION)
        const layerName = "poly_" + key;
        this[layerName] = L.polyline(Object.values(ordered)).addTo(this.map); // #REVIEW <---- ENABLE THIS TO PUT CABLES ON

        // add this layer to layer group to add to control layer later
        this.polylineGroupLayers[layerName] = this[layerName];
      }

      // #NOTE
      // ..this will need to  be updated for the groupedLayer control
      //
      //
      // add group to overlays by iterating over the object
      // https://stackoverflow.com/questions/55471295/adding-multiple-overlays-to-leaflets-layers-control
      for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
        this.controller.addOverlay(value, key, "Cables"); // #REVIEW <---- ENABLE THIS TO PUT CABLES ON
      }
    }

    //----------------------------------------------------
    //
    // MAP MOVEMENT - DRAG ZOOM ETC
    //
    // see - https://gis.stackexchange.com/questions/54454/disable-leaflet-interaction-temporary
    //----------------------------------------------------

    const disableMapMovement = (map) => {
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      //map.scrollWheelZoom.disable();
      map.smoothWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();
    };

    const enableMapMovement = (map) => {
      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      //map.scrollWheelZoom.enable();
      map.smoothWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
      if (map.tap) map.tap.enable();
    };

    // on startup disable map movement by user
    disableMapMovement(this.map);

    //----------------------------------------------------
    //
    // MAP MARKER ZOOM  - CONTROLS
    //
    //
    // Custom zoom controls
    //
    //----------------------------------------------------

    // listeners for disabling buttons
    this.map.on("zoomend", (e) => {
      const map = e.target,
        max = map.getMaxZoom(),
        min = map.getMinZoom(),
        current = map.getZoom(),
        isMapView = this.state.isMapView;

      if (current < max) {
        if (isMapView) {
          zoomInButton.enable();
        }
      }
      if (current >= max) {
        if (isMapView) {
          zoomInButton.disable();
        }
      }
      if (current > min) {
        if (isMapView) {
          zoomOutButton.enable();
        }
      }
      if (current <= min) {
        if (isMapView) {
          zoomOutButton.disable();
        }
      }
    });

    const moveMapViewZoomIn = `<i class="fas fa-plus fa-lg"></i>`;
    const moveMapViewZoomOut = `<i class="fas fa-minus fa-lg"></i>`;

    const zoomInButton = L.easyButton({
      states: [
        {
          stateName: "zoom-in-move-map-view",
          icon: moveMapViewZoomIn,
          title: "Zoom In",
          onClick: (control, map) => {
            map.setZoom(map.getZoom() + 1);
          },
        },
      ],
    });

    const zoomOutButton = L.easyButton({
      states: [
        {
          stateName: "zoom-out-move-map-view",
          icon: moveMapViewZoomOut,
          title: "Zoom Out",
          onClick: (control, map) => {
            map.setZoom(map.getZoom() - 1);
          },
        },
      ],
    });
    //const zoomBar = L.easyBar([zoomInButton, zoomOutButton]);
    //zoomBar.addTo(this.map);

    //----------------------------------------------------
    //
    // MAP VIEW MOVE - CONTROLS
    //
    //----------------------------------------------------

    const moveMapViewShow = `<i class="fas fa-search fa-lg"></i>`;
    const moveMapViewHide = `<i class="fas fa-search fa-lg" style="opacity:0.2"></i>`;

    const disableActiveLayer = (layer, layerName, activeLayersList) => {
      let newActiveLayersList = [activeLayersList];
      if (this.map.hasLayer(layer)) {
        if (!newActiveLayersList.includes(layerName)) {
          newActiveLayersList.push(layerName);

          this.map.removeLayer(layer);
        }
      }

      return newActiveLayersList;
    };

    const enableActiveLayer = (layer, layerName, activeLayersList) => {
      let newActiveLayersList = activeLayersList;
      if (!this.map.hasLayer(layer)) {
        if (newActiveLayersList.includes(layerName)) {
          newActiveLayersList.filter((layer) => layer !== layerName);

          layer.addTo(this.map);
        }
      }

      return newActiveLayersList;
    };

    // remove all layers

    const disableAllActiveLayers = (activeLayersList) => {
      let newActiveLayerList = [...activeLayersList];

      // remove all layers
      newActiveLayerList = disableActiveLayer(
        this.geoJSONGroupLayer,
        "geoJSONGroupLayer",
        newActiveLayerList
      );
      newActiveLayerList = disableActiveLayer(
        this.geoJSONGroupLayerFireflyTooltips,
        "geoJSONGroupLayerFireflyTooltips",
        newActiveLayerList
      );
      newActiveLayerList = disableActiveLayer(
        this.geoJSONGroupLayerFireflyForcedTooltips,
        "geoJSONGroupLayerFireflyForcedTooltips",
        newActiveLayerList
      );
      newActiveLayerList = disableActiveLayer(
        this.geoJSONControllerGroupLayer,
        "geoJSONControllerGroupLayer",
        newActiveLayerList
      );
      newActiveLayerList = disableActiveLayer(
        this.geoJSONGroupLayerTags,
        "geoJSONGroupLayerTags",
        newActiveLayerList
      );

      return newActiveLayerList;
    };

    const enableAllActiveLayers = (activeLayersList) => {
      let newActiveLayerList = [...activeLayersList];

      // add all layers
      this.geoJSONGroupLayer.addTo(this.map);

      // add layers which were displayed
      newActiveLayerList = enableActiveLayer(
        this.geoJSONGroupLayer,
        "geoJSONGroupLayer",
        newActiveLayerList
      );
      newActiveLayerList = enableActiveLayer(
        this.geoJSONGroupLayerFireflyTooltips,
        "geoJSONGroupLayerFireflyTooltips",
        newActiveLayerList
      );
      newActiveLayerList = enableActiveLayer(
        this.geoJSONGroupLayerFireflyForcedTooltips,
        "geoJSONGroupLayerFireflyForcedTooltips",
        newActiveLayerList
      );
      newActiveLayerList = enableActiveLayer(
        this.geoJSONControllerGroupLayer,
        "geoJSONControllerGroupLayer",
        newActiveLayerList
      );
      newActiveLayerList = enableActiveLayer(
        this.geoJSONGroupLayerTags,
        "geoJSONGroupLayerTags",
        newActiveLayerList
      );

      // FireFlys: this.geoJSONGroupLayer,
      // "FireFly ID": this.geoJSONGroupLayerFireflyTooltips,
      // Forced: this.geoJSONGroupLayerFireflyForcedTooltips,
      // Controllers: this.geoJSONControllerGroupLayer,
      // //"All Named Areas": this.geoJSONNamedAreaParentGroupLayer,
      // Tags: this.geoJSONGroupLayerTags,
      // //Editable: this.editableLayers,

      return newActiveLayerList;
    };

    //

    const toggleMapLayers = (control, map, moveMap) => {
      const { isPokeTheWorker, TurnOnOffPokeTheWorker, SetMapMarkerDragging } =
        this.props;

      let newActiveLayerList = [...this.state.activeLayersList];

      switch (moveMap) {
        case true:
          // turn off data collection
          SetMapMarkerDragging(true);
          this.setState({ isMarkerDragging: true });

          // remove all the active layers
          newActiveLayerList = disableAllActiveLayers(newActiveLayerList);

          // add simple marker layer
          this.geoJSONGroupLayerFireflySimple.addTo(this.map);

          // turn off data collection
          // #NOTE - this is setup as condition for DEBUGing layers
          if (false) {
            if (isPokeTheWorker) {
              TurnOnOffPokeTheWorker(false);
            }

            this.setState({ isPokeTheWorkerLocal: false });
          }

          break;
        case false:
          // remove simple layer
          this.map.removeLayer(this.geoJSONGroupLayerFireflySimple);

          // add all the active layers
          newActiveLayerList = enableAllActiveLayers(newActiveLayerList);

          // restore/refresh the layer control
          // .....#WIP?????????????

          SetMapMarkerDragging(false);
          this.setState({ isMarkerDragging: false });

          // turn off data collection
          // #NOTE - this is setup as condition for DEBUGing layers
          if (false) {
            // turn on data collection
            this.setState({ isPokeTheWorkerLocal: true });

            if (!isPokeTheWorker) {
              TurnOnOffPokeTheWorker(true);
            }
          }

          // force a map refresh to restore marker positions & disable drag
          this.setState({ forceMapRefresh: true });

          break;

        default:
          break;
      }

      this.setState({ activeLayerList: newActiveLayerList });
    };

    const moveMapViewButton = L.easyButton({
      states: [
        {
          stateName: "show-move-map-view",
          icon: moveMapViewShow,
          title: "Pan and Zoom",
          onClick: (control, map) => {
            // show the zoom controls
            moveMapViewButton.disable();
            zoomOutButton.enable();
            zoomInButton.enable();
            cancelMapViewButton.enable();
            moveMarkerPositionsButton.disable();

            // enable map movement
            enableMapMovement(this.map);

            // map has started moving so disable data collection
            mapStartMoving();

            toggleMapLayers(control, map, true);
            this.setState({ isMapView: true });
          },
        },
        {
          stateName: "hide-move-map-view",
          icon: moveMapViewHide,
          title: "Disable move view",
        },
      ],
    });

    const cancelMapViewButton = L.easyButton({
      states: [
        {
          stateName: "cancel-move-map-view",
          icon: "fa fa-times",
          title: "Disable Pan and Zoom",
          onClick: (control, map) => {
            // show the zoom controls
            moveMapViewButton.enable();
            zoomOutButton.disable();
            zoomInButton.disable();
            cancelMapViewButton.disable();
            moveMarkerPositionsButton.enable();

            // disable map movement
            disableMapMovement(this.map);

            // map has stopped moving so enable data collection
            mapStopMoving();

            toggleMapLayers(control, map, false);
            this.setState({ isMapView: false });
          },
        },
      ],
    });

    let moveMapViewBar = L.easyBar([
      moveMapViewButton,
      zoomInButton,
      zoomOutButton,
      cancelMapViewButton,
    ]);
    moveMapViewBar.addTo(this.map);

    // startup hide - zoom buttons
    moveMapViewButton.enable();
    zoomOutButton.disable();
    zoomInButton.disable();
    cancelMapViewButton.disable();

    //----------------------------------------------------
    //
    // MAP MARKER MOVE POSITIONS - CONTROLS
    //
    //----------------------------------------------------

    // Alternate methods to display icons:
    // <span class="star">&starf;</span>
    // '<i class="icon cog" />'
    //
    // see - https://github.com/CliffCloud/Leaflet.EasyButton/issues/82
    // see - http://danielmontague.com/projects/easyButton.js/v1/examples/#fancier-disable
    // see custom.css 'easyButton' section for styling

    const enableFeatureGroupDraggable = (featureGroup, action) => {
      featureGroup.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          //console.log(`123 layer`, action, layer);
          if (action) {
            // if (layer.feature.id === "GBC_Extraction:P26E:1") {
            //    console.log(`123 layer`, layer);
            // }
            layer.dragging.enable();
          } else if (!action) {
            layer.dragging.disable();
          }
        }
      });
    };

    const changeMarkerStyle = (featureGroup, markerName) => {
      const currentZoom = this.map.getZoom();
      featureGroup.eachLayer(function (layer) {
        if (layer instanceof L.Marker) {
          const position = layer?.feature?.properties?.position || 0;
          const color =
            layer?.feature?.properties?.light?.color?.status || "grey";

          const newColor = { stroke: color, fill: color, text: position };

          // console.log(
          //   `gggg layer?.feature?.properties?.`,
          //   color,
          //   newColor,
          //   layer?.feature?.properties
          // );
          switch (markerName) {
            case "typicalNumberedMapMarker":
              layer.setIcon(
                makeIcon(
                  "TypicalNumberedMapMarker",
                  newColor, // hack position into color object for numbered markers
                  currentZoom
                )
              );
              break;

            default:
              break;
          }
        }
      });
    };

    const moveMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "move-marker-positions",
          icon: "fas fa-edit",
          title: "Edit FireFly and Controller positions",
          onClick: (control, map) => {
            moveMarkerPositionsButton.disable();
            saveMarkerPositionsButton.enable();
            cancelMarkerPositionsButton.enable();

            // disable move
            moveMapViewButton.disable();

            let newActiveLayerList = [...this.state.activeLayersList];
            // remove all the active layers
            newActiveLayerList = disableAllActiveLayers(newActiveLayerList);
            this.setState({ activeLayerList: newActiveLayerList });

            // make sure FFs and Controller layers are enabled
            this.geoJSONGroupLayer.addTo(this.map);
            this.geoJSONControllerGroupLayer.addTo(this.map);

            // enable dragging of markers
            enableFeatureGroupDraggable(this.geoJSONGroupLayer, true);
            enableFeatureGroupDraggable(this.geoJSONControllerGroupLayer, true);

            // change the markers to look 'editable'
            // fireflys
            changeMarkerStyle(
              this.geoJSONGroupLayer,
              "typicalNumberedMapMarker"
            );
            // controllers
            changeMarkerStyle(
              this.geoJSONControllerGroupLayer,
              "typicalNumberedMapMarker"
            );

            // turn off data collection
            const {
              isPokeTheWorker,
              TurnOnOffPokeTheWorker,
              SetMapMarkerDragging,
            } = this.props;

            SetMapMarkerDragging(true);
            this.setState({ isMarkerDragging: true });

            if (isPokeTheWorker) {
              TurnOnOffPokeTheWorker(false);
            }
            this.setState({ isPokeTheWorkerLocal: false });
          },
        },
      ],
    });

    const saveMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "save-marker-positions",
          icon: "fas fa-save",
          title: "Save position changes",
          onClick: (e) => {
            const markerPositionChanges = this.state.markerPositionChanges;

            // console.log(
            //   ` markerPositionChanges markerPositionChanges`,
            //   markerPositionChanges
            // );

            if (!_isEmpty(markerPositionChanges)) {
              const {
                saveFirefly,
                saveUPS,
                transformPixelsToUtm,
                mineLevelId,
              } = this.props;

              let promiseArray = [];

              for (const change in markerPositionChanges) {
                const {
                  id,
                  type,
                  properties,
                  position: changedPosition,
                } = markerPositionChanges[change];

                const objLatLng = transformPixelsToUtm.transform({
                  lat: changedPosition.lat,
                  lng: changedPosition.lng,
                });

                // transform position to UTM
                const newPosition = {
                  mineLevelId: mineLevelId,
                  lat: objLatLng.lat,
                  lng: objLatLng.lng,
                };

                // console.log(
                //   ` markerPositionChanges id, type, properties, newPosition`,
                //   id,
                //   type,
                //   properties,
                //   changedPosition,
                //   newPosition
                // );

                switch (type) {
                  case "firefly":
                    const {
                      id: fireflyId,
                      position: fireflyPosition,
                      location: fireflyLocation,
                      mac: fireflyMac,
                      note: fireflyNote,
                    } = properties;

                    promiseArray.push(
                      new Promise((resolve, reject) => {
                        saveFirefly({
                          values: {
                            id: fireflyId,
                            position: newPosition,
                            fireflyLocation,
                            fireflyPosition,
                            fireflyMac,
                            fireflyNote,
                          },
                          resolve,
                          reject,
                        });
                      })
                    );
                    break;
                  case "controller":
                    const { id: upsId } = properties;

                    promiseArray.push(
                      new Promise((resolve, reject) => {
                        saveUPS({
                          values: {
                            id: upsId,
                            position: newPosition,
                            name: null,
                          }, // #NOTE #REVIEW - name is no longer used
                          resolve,
                          reject,
                        });
                      })
                    );
                    break;
                  default:
                    break;
                }
              }

              return Promise.all(promiseArray)
                .then((results) => {
                  console.log("UPDATING CONTROLLER/FIREFLY POSITIONS", results);
                })
                .then(
                  () => {
                    let newActiveLayerList = [...this.state.activeLayersList];
                    // enable all the active layers
                    newActiveLayerList =
                      enableAllActiveLayers(newActiveLayerList);
                    this.setState({ activeLayerList: newActiveLayerList });

                    //
                    closeMarkerPositionButtons();
                  },
                  (msg) => {
                    console.log("action failed", msg); // TODO probs should show this?
                  }
                );
            }
            closeMarkerPositionButtons();
          },
        },
      ],
    });

    const closeMarkerPositionButtons = () => {
      // clear all position changes
      this.setState({ markerPositionChanges: {} });
      // disable marker drag
      enableFeatureGroupDraggable(this.geoJSONGroupLayer, false);
      enableFeatureGroupDraggable(this.geoJSONControllerGroupLayer, false);

      let newActiveLayerList = [...this.state.activeLayersList];
      // enable all the active layers
      newActiveLayerList = enableAllActiveLayers(newActiveLayerList);
      this.setState({ activeLayerList: newActiveLayerList });

      // close buttons
      moveMarkerPositionsButton.enable();
      saveMarkerPositionsButton.disable();
      cancelMarkerPositionsButton.disable();

      // enable move
      moveMapViewButton.enable();

      // turn on data collection
      this.setState({ isPokeTheWorkerLocal: true });
      const { isPokeTheWorker, TurnOnOffPokeTheWorker, SetMapMarkerDragging } =
        this.props;

      SetMapMarkerDragging(false);
      this.setState({ isMarkerDragging: false });

      if (!isPokeTheWorker) {
        TurnOnOffPokeTheWorker(true);
      }
      // force a map refresh to restore marker positions & disable drag
      this.setState({ forceMapRefresh: true });
    };

    const cancelMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "cancel-marker-positions",
          icon: "fa fa-times",
          title: "Cancel position changes",
          onClick: (e) => {
            // clear all position changes
            this.setState({ markerPositionChanges: {} });
            // disable dragging of markers
            enableFeatureGroupDraggable(this.geoJSONGroupLayer, false);
            enableFeatureGroupDraggable(
              this.geoJSONControllerGroupLayer,
              false
            );

            let newActiveLayerList = [...this.state.activeLayersList];
            // enable all the active layers
            newActiveLayerList = enableAllActiveLayers(newActiveLayerList);
            this.setState({ activeLayerList: newActiveLayerList });

            // close buttons
            moveMarkerPositionsButton.enable();
            saveMarkerPositionsButton.disable();
            cancelMarkerPositionsButton.disable();

            // enable move
            moveMapViewButton.enable();

            // turn on data collection
            this.setState({ isPokeTheWorkerLocal: true });
            const { isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;
            if (!isPokeTheWorker) {
              TurnOnOffPokeTheWorker(true);
            }

            // force a map refresh to restore marker positions & disable drag
            this.setState({ forceMapRefresh: true });
          },
        },
      ],
    });

    let editBar = L.easyBar([
      moveMarkerPositionsButton,
      saveMarkerPositionsButton,
      cancelMarkerPositionsButton,
    ]);

    if (this.props.role.allowAdmin) {
      editBar.addTo(this.map);
    }

    // startup hide - save and cancel buttons
    moveMarkerPositionsButton.enable();
    saveMarkerPositionsButton.disable();
    cancelMarkerPositionsButton.disable();

    //----------------------------------------------------
    //
    // MAP MARKER REFRESH - CONTROLS
    //
    //----------------------------------------------------

    const refreshMarkerPositionsButton = L.easyButton({
      states: [
        {
          stateName: "reset-marker-positions",
          icon: "fa fa-redo",
          title: "Refresh Map",
          onClick: (e) => {
            // force a map refresh to restore marker positions & disable drag
            this.setState({ forceMapRefresh: true });
            this.map.fitBounds(bounds);
          },
        },
      ],
    });

    let refreshMapBar = L.easyBar([refreshMarkerPositionsButton]);
    refreshMapBar.addTo(this.map);

    //----------------------------------------------------
    //
    // MAP EVENTS
    //
    //----------------------------------------------------

    const mapLayerChangingOnInputToggle = (e) => {
      if (this.controller._handlingClick) {
        // Executes only on input toggle, not on
        // map.addLayer(marker) or map.removeLayer(marker)
        this.updateLayerControlState();
      }
    };

    this.map.on(
      "overlayadd overlayremove layeradd layerremove",
      mapLayerChangingOnInputToggle
    );

    const mapLayerChanging = () => {
      //  console.log(`layeradd updateLayerControlState`);

      this.updateLayerControlState();
    };

    // executes when adding layers via previewList
    this.map.on("layeradd layerremove", mapLayerChanging);

    // map manipulation and moving events

    // Detects click on Zoom in and Zoom out buttons
    // Works but not sure it is necessary we already use 'zoomstart' etc
    // #WIP - this works
    if (false) {
      var elementLControlZoomIn = document.querySelector(
        "a.leaflet-control-zoom-in"
      );

      L.DomEvent.addListener(elementLControlZoomIn, "click", (e) => {
        console.log(
          `COLLECTING DATA ... elementLControlZoomIn `,
          new Date().getTime().toString()
        );

        this.props.SetMapMoving(true);

        this.setState({ isPokeTheWorkerLocal: false });

        const { isPokeTheWorker } = this.props;
        if (isPokeTheWorker) {
          // turn off data collection
          this.props.TurnOnOffPokeTheWorker(false);
        }
      });

      var elementLControlZoomOut = document.querySelector(
        "a.leaflet-control-zoom-out"
      );

      L.DomEvent.addListener(elementLControlZoomOut, "click", (e) => {
        console.log(`COLLECTING DATA ... elementLControlZoomOut `);

        this.props.SetMapMoving(false);

        this.setState({ isPokeTheWorkerLocal: false });

        const { isPokeTheWorker } = this.props;
        if (isPokeTheWorker) {
          // turn on data collection
          this.props.TurnOnOffPokeTheWorker(false);
        }
      });
    }

    const mapStartMoving = () => {
      this.props.SetMapMoving(true);
      this.setState({ isMoving: true });

      if (!this.state.isMarkerDragging) {
        this.setState({ isPokeTheWorkerLocal: false });

        const { isPokeTheWorker } = this.props;
        if (isPokeTheWorker) {
          // turn off data collection
          this.props.TurnOnOffPokeTheWorker(false);
        }
      }
    };

    const mapStopMoving = () => {
      this.props.SetMapMoving(false);
      this.setState({ isMoving: false });

      if (!this.state.isMarkerDragging) {
        this.setState({ isPokeTheWorkerLocal: true });
        const { isPokeTheWorker } = this.props;
        if (!isPokeTheWorker) {
          // turn on data collection
          this.props.TurnOnOffPokeTheWorker(true);
        }
      }
    };

    //disable_simple
    // if implement 'pan' tool then no longer need this to start/stop
    if (false) {
      // fires when map starts moving: dragging map, zooming etc.
      this.map.on("movestart zoomstart ", mapStartMoving); // mousedown?

      // fires when map is moving
      this.map.on("move", (e) => {
        //console.log(`COLLECTING DATA ... move  `);

        this.props.SetMapMoving(true);

        const { isPokeTheWorker } = this.props;
        // reinforce turning off data collection!
        // this should never happen....but....
        if (isPokeTheWorker) {
          // turn off data collection
          this.props.TurnOnOffPokeTheWorker(false);
        }
        this.setState({ isPokeTheWorkerLocal: false });
      });
    }

    // fires when map stops moving: dragging map, zooming etc.
    this.map.on("moveend zoomend ", mapStopMoving); // mouseup?

    // ###################################################################################
    // See other definition for polylines.
    // these should be defined after the fireflies are defined
    //
    // ###################################################################################
    //
    //
    //

    // ********************************************************************************
    //
    // Set polylines joining the Fireflies
    //
    //
    // ***** NOTE: this has to be done after the locationGroupLayers has been rendered
    // ...probably more likely need layer control defined before start adding groups ****
    //
    // #REVEW <--- reorder the code
    // ********************************************************************************

    // if (DEFAULT_SHOW_FIREFLY_POLYLINE_GROUP_LAYER) {
    //   this.polylineGroupLayers = {};

    //   // step through the list of locationGroupLayers
    //   for (const [key, value] of Object.entries(this.locationGroupLayers)) {
    //     // create an object with the posObject[position] = latlng coordinates
    //     let posObject = {};

    //     // loop each layer - #REVIEW - this could be labourious - perhaps only do if selected to display?
    //     // eslint-disable-next-line no-loop-func
    //     value.eachLayer(function (layer) {
    //       posObject[layer.feature.properties.position] = layer.getLatLng();
    //     });

    //     // sort the posObject by position order (i.e. object key) so line draws from position 1 -> n

    //     // see - https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
    //     const ordered = {};
    //     Object.keys(posObject)
    //       .sort()
    //       .forEach(function (key) {
    //         ordered[key] = posObject[key];
    //       });

    //     // plot a polyline using ordered array of values
    //     // create name object from layer name (i.e. key is the POSITION)
    //     const layerName = "poly_" + key;
    //     this[layerName] = L.polyline(Object.values(ordered)).addTo(this.map); // #REVIEW <---- ENABLE THIS TO PUT CABLES ON

    //     // add this layer to layer group to add to control layer later
    //     this.polylineGroupLayers[layerName] = this[layerName];
    //   }

    //   // #NOTE
    //   // ..this will need to  be updated for the groupedLayer control
    //   //
    //   //
    //   // add group to overlays by iterating over the object
    //   // https://stackoverflow.com/questions/55471295/adding-multiple-overlays-to-leaflets-layers-control
    //   for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
    //     // #WIP - layerControl below commented out while testing groupLayer control
    //     //
    //     //layerControl.addOverlay(value, key); // #REVIEW <---- ENABLE THIS TO PUT CABLES ON
    //   }
    // }

    // *****************************************************************************************
    // #REVIEW - setup ordering layers for all levels with toggle
    // setup layer control events
    // https://stackoverflow.com/questions/14103489/leaflet-layer-control-events
    this.map.on("overlayadd", onOverlayAdd);

    function onOverlayAdd(e) {
      // https://gis.stackexchange.com/questions/137061/how-to-change-layer-order-in-leaflet-js
      // push layer to back layer.bringToFront()

      if (e.name === "Polygon") {
        e.layer.bringToBack();
      }
      //console.log("onOverlayAdd e", e);
    }

    //----------------------------------------------------
    //
    // MAP EVENTS (more :-)
    //
    //----------------------------------------------------

    // bind 'this' to get access to map
    const zoomend = () => {
      const currentZoom = this.map.getZoom();
      console.log("zoomend currentZoom", currentZoom);

      let newMapViewState = this.state.mapViewState;
      newMapViewState.zoom = currentZoom;
      this.setState({ mapViewState: newMapViewState });

      // Tracking whether zooming in or out
      // only update icon if size has changed
      let diffZoom = this.previousZoom - currentZoom;

      // diffZoom > 0 - console.log("zoomend zoomed out");
      // iffZoom < 0 - console.log("zoomend zoomed in");
      if (diffZoom !== 0) {
        const firefliesIdInLayer = Object.keys(this.layerlistFireflies);
        firefliesIdInLayer.forEach((fireflyId, idx) => {
          const iconOptions =
            this.layerlistFireflies[fireflyId].getIcon().options;
          const { iconName, iconSize, iconColor } = iconOptions;

          // only update icon if size has changed

          this.layerlistFireflies[fireflyId].setIcon(
            makeIcon(iconName, iconColor, currentZoom)
          );
        });
      }

      // #WIP - tool tip implementation integrated with the main FF marker
      // see also - tooltip in main marker definition
      //
      // Started implementing show/hide of tooltip based on
      // see - https://stackoverflow.com/questions/42364619/hide-tooltip-in-leaflet-for-a-zoom-range
      //
      // const lastZoom = this.previousZoom;
      // const tooltipThreshold = -1;
      // if (
      //   currentZoom < tooltipThreshold &&
      //   (!lastZoom || lastZoom >= tooltipThreshold)
      // ) {
      //   this.map.eachLayer(function (l) {
      //     if (l.getTooltip()) {
      //       var tooltip = l.getTooltip();
      //       l.unbindTooltip().bindTooltip(tooltip, {
      //         permanent: false,
      //       });
      //     }
      //   });
      // } else if (
      //   zoom >= tooltipThreshold &&
      //   (!lastZoom || lastZoom < tooltipThreshold)
      // ) {
      //   this.map.eachLayer(function (l) {
      //     if (l.getTooltip()) {
      //       var tooltip = l.getTooltip();
      //       l.unbindTooltip().bindTooltip(tooltip, {
      //         permanent: true,
      //       });
      //     }
      //   });
      // }

      this.previousZoom = currentZoom;
    };

    this.map.on("zoomend", zoomend);

    const moveend = () => {
      const currentCenter = this.map.getCenter();

      let newMapViewState = this.state.mapViewState;
      newMapViewState.center = currentCenter;
      this.setState({ mapViewState: newMapViewState });
    };

    this.map.on("moveend", moveend);

    // ********************************************************************************
    //
    // Setup polygons drawn on the map
    //
    //
    // NOTE: uses leaflet.draw leaflet-draw
    //
    // ********************************************************************************

    // Truncate value based on number of decimals
    var _round = function (num, len) {
      return Math.round(num * Math.pow(10, len)) / Math.pow(10, len);
    };
    // Helper method to format LatLng object (x.xxxxxx, y.yyyyyy)
    var strLatLng = function (latlng) {
      return "(" + _round(latlng.lat, 6) + ", " + _round(latlng.lng, 6) + ")";
    };

    // Generate popup content based on layer type
    // - Returns HTML string, or null if unknown object
    var getPopupContent = function (layer) {
      // Marker - add lat/long
      if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
        return strLatLng(layer.getLatLng());
        // Circle - lat/long, radius
      } else if (layer instanceof L.Circle) {
        var center = layer.getLatLng(),
          radius = layer.getRadius();
        return (
          "Center: " +
          strLatLng(center) +
          "<br />" +
          "Radius: " +
          _round(radius, 2) +
          " m"
        );
        // Rectangle/Polygon - area
      } else if (layer instanceof L.Polygon) {
        var latlngs = layer._defaultShape
          ? layer._defaultShape()
          : layer.getLatLngs();

        // get the pixel coordinates of the shape
        var coords = latlngs.map(function (point) {
          return [point.lng, point.lat]; // #REVIEW - reverse display lat, long = YX
        });

        return (
          "Coords: " +
          JSON.stringify(coords) +
          "</br> _leaflet_id: " +
          L.stamp(layer)
        );
        // Polyline - distance
      } else if (layer instanceof L.Polyline) {
        var latlngs = layer._defaultShape
            ? layer._defaultShape()
            : layer.getLatLngs(),
          distance = 0;
        if (latlngs.length < 2) {
          return "Distance: N/A";
        } else {
          for (var i = 0; i < latlngs.length - 1; i++) {
            distance += latlngs[i].distanceTo(latlngs[i + 1]);
          }
          return "Distance: " + _round(distance, 2) + " m";
        }
      }
      return null;
    };

    // create the editable layers to draw on
    this.editableLayers = L.featureGroup(); // .addTo(this.map);
    //#REVIEW - this stops edirtable layers displaying
    //
    // #Note - adding to the map before adding to layer will set [x] default

    // https://stackoverflow.com/questions/40088421/returning-clicked-layer-leaflet
    // see - https://jsfiddle.net/3v7hd2vx/108/
    // attached to this.editableLayers group

    // DISABLE THIS ONCLICK ACTION - but leave in code for debugging
    if (DEBUG_ENABLED_FALSE) {
      this.editableLayers.on("click", (event) => {
        const { layer } = event;

        // console.log("xxx this.editableLayers click " + L.stamp(layer));
        // console.log("xxx this.editableLayers - _leaflet_id " + layer._leaflet_id);

        // Note 'layer.edited' only true after handles moved
        //&& layer.edited
        if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
          // rectangle, polygon, circle
          const colors = ["green", "orange", "blue", "red"];
          const currentColor = layer.options.fillColor;
          const index = colors.findIndex((x) => x === currentColor);
          let newColor;
          index + 1 > colors.length - 1
            ? (newColor = colors[0])
            : (newColor = colors[index + 1]);

          layer.setStyle({ color: newColor, fillColor: newColor });
          layer.feature.properties.color.status = layer.options.fillColor; // set fillColor as active color in geoJSON
        }
      });
    }

    // #WIP - more editable layers use from control screen
    // check what layers are drawn.......
    //console.log("this.editableLayers layer before 1", this.editableLayers);
    //this.editableLayers.eachLayer(function (layer) {
    //console.log("this.editableLayers layer before", layer);
    // Pass this information through Redux to outside
    //});

    // add existing layer to editable layer so can edit existing/loaded polygons
    //let that=this; // pass editable layer into function
    this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
      console.log(
        "Adding mqtt Named Area to this.editableLayers -> ",
        layer._leaflet_id
      ); // KEEP AS PERMANENT INDICATOR THAT MAP GROUPS ARE UPDATING

      // #WIP - this is failing when going from another page back to control screen
      // "TypeError: Cannot read property 'style' of undefined"
      if (layer.getElement() !== undefined) {
        layer.getElement().style.display = "block"; // this resets any hidden layer actions
      }

      this.editableLayers.addLayer(layer);
    });

    // --- LAYER INFORMATION TO OUTSIDE MAP

    // #WIP - more editable layers use from control screen
    // check what layers are drawn.......
    //this.editableLayers.eachLayer(function (layer) {
    //console.log("this.editableLayers layer after", layer);
    // Pass this information through Redux to outside
    //});

    // #TESTING ICON STYLING AND ANIMATION

    // https://piratefsh.github.io/how-to/2015/10/16/animating-leaflet-markers.html

    // https://leafletjs.com/reference-1.7.1.html
    // https://stackoverflow.com/questions/41884070/how-to-make-markers-in-leaflet-blinking
    // https://stackoverflow.com/questions/31961549/pulsating-leaflet-marker-using-css3-animations
    // With the class added, the marker will blink:
    // L.DomUtil.addClass(marker._icon, "blinking");
    // Without the class, it won't:
    // L.DomUtil.removeClass(marker._icon, "blinking");

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++
    //
    // Finally! map drawn!!!!!!!!
    //
    // ++++++++++++++++++++++++++++++++++++++++++++++
  };

  convertGeoJsonPixelsToUtm = (geoJsonPixels, mineLevel) => {
    // Output coordinates of shape in UTM
    // returns geoJSON

    // convert pixel coordinates in geoJson Pixels to utm
    // deep clone - to stop geometry being converted on raw *utm data
    geoJsonPixels = JSON.parse(JSON.stringify(geoJsonPixels));
    const geoJsonUtm = transformGeoJsonPixelsToUtm(geoJsonPixels, mineLevel);

    // #TODO - NOTE: 'drawnItemsToJSON' below strips out the properties!
    // - needs to be updated to preserve PROPERTIES
    // - do not use until fixed

    // preserve styling of polygon in geoJson
    //
    //geoJson = this.drawnItemsToJSON(this.editableLayers);
    return geoJsonUtm;
  };

  // find a random point in the holding pen
  randomPointInPoly = function (polygon) {
    var bounds = polygon.getBounds();
    var x_min = bounds.getEast();
    var x_max = bounds.getWest();
    var y_min = bounds.getSouth();
    var y_max = bounds.getNorth();

    var lat = y_min + Math.random() * (y_max - y_min);
    var lng = x_min + Math.random() * (x_max - x_min);

    var point = turf.point([lng, lat]);
    var poly = polygon.toGeoJSON();
    var inside = turf.inside(point, poly);

    if (inside) {
      return point;
    } else {
      return this.randomPointInPoly(polygon);
    }
  };

  updateLayerControlState = () => {
    // update state of controls
    let layerControlState = {};
    for (const [key, value] of Object.entries(this.parentsUnionGroupLayers)) {
      layerControlState[key] = this.map.hasLayer(value);
    }
    this.setState({ layerControlState: layerControlState });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { mineLevel, isPokeTheWorker } = nextProps;
    const { isPokeTheWorkerLocal } = nextState;

    // console.log(`nextState.isMapLoaded`, nextState.isMapLoaded);
    // console.log(`nextState.isMaping`, nextState.isMaping);

    // console.log(`this.state.isMapLoaded`, this.state.isMapLoaded);
    // console.log(`this.state.isMaping`, this.state.isMaping);

    // // if map is not loaded
    // if (!nextState.isMapLoaded){
    //   if (!_isEqual(this.state.isMapLoading, nextState.isMapLoading))

    // }

    //console.log(`nextState.isPokeTheWorkerLocal`, isPokeTheWorkerLocal);

    if (mineLevel === undefined || !isPokeTheWorkerLocal) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    const { mineLevel, isPokeTheWorker } = this.props;

    if (!_isEmpty(mineLevel)) {
      this.setState({ map: { area: mineLevel?.id } });

      // set local version of isPokeTheWorker
      //this.setState({ isPokeTheWorkerLocal: isPokeTheWorker });

      this.setState({ isMapComponentDidMount: true });

      //console.log("mapViewState componentDidMount", mineLevel?.id);

      const newMapViewState = this.props.componentstate.get(
        "mapViewState",
        mineLevel?.id
      );
      // console.log(
      //   "newMapViewState componentDidMount",
      //   mineLevel?.id,
      //   newMapViewState
      // );
      if (!_isEmpty(newMapViewState)) {
        // console.log(
        //   "newMapViewState componentDidMount setState newMapViewState",
        //   mineLevel?.id,
        //   newMapViewState
        // );
        // wait for state change before mounting the map
        this.setState({ mapViewState: newMapViewState }, () => {
          //console.log("newMapViewState updated");
          this.mapMount();
        });
      } else {
        this.mapMount();
      }
    } else {
      // #NOTE - this should never happen, but on rapid reload of the page
      // I experienced mineLevel undefined.

      console.log(`LOAD ERROR: AREA IS EMPTY `, mineLevel);
    }
  }

  componentWillUnmount() {
    const { mineLevel } = this.props;

    //console.log("newMapViewState", mineLevel?.id, this.state.mapViewState);

    const {
      mapViewState: { center, zoom },
    } = this.state;

    const newMapViewState = {
      center: center !== undefined ? center : this.previousCenter,
      zoom: zoom !== undefined ? zoom : this.previousZoom,
    };

    //console.log(`newMapViewState componentWillUnmount`, newMapViewState);

    const settings = {
      section: "mapViewState",
      key: mineLevel?.id,
      data: { ...newMapViewState },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });

    // this.props.componentstate.set(
    //   "mapViewState",
    //   mineLevel?.id,
    //   newMapViewState
    // );

    // clear out map
    if (this.map && this.map.remove) {
      this.map.off();
      this.map.remove();
    }
  }

  //   standardPtsUtmX = () => {
  //     const { mineLevel } = this.props;
  //     const { ref_coord } = mineLevel;

  //     let result = [];

  //     ref_coord.forEach((coord) => {
  //       result.push({ lat: coord.utm[0], lng: coord.utm[1] });
  //     });
  //     return result;
  //   };

  standardPtsUtmX = () => {
    const { mineLevel } = this.props;
    const { ref_coord } = mineLevel;

    let result = {};
    ref_coord.forEach((coord) => {
      const indexArray = coord.id.split(":"); // e.g. "DMLZ_Extaction:1"
      const index = indexArray[1];

      result[index] = { lat: coord.utm[0], lng: coord.utm[1] };
    });

    // sort object by key
    Object.keys(result)
      .sort()
      .forEach(function (v, i) {
        //console.log(v, result[v]);
      });

    // return the values
    return Object.values(result);
  };

  //   localPtsX = () => {
  //     const { mineLevel } = this.props;
  //     const { ref_coord } = mineLevel;

  //     let result = [];
  //     ref_coord.forEach((coord) => {
  //       result.push({
  //         id: coord.id,
  //         lat: coord.image_xy[0],
  //         lng: coord.image_xy[1],

  //         easting: coord.utm[0],
  //         northing: coord.utm[1],
  //         zoneNum: coord.utm_zone_number,
  //         zoneLetter: coord.utm_zone_letter,
  //       });
  //     });
  //     return result;
  //   };

  localPtsX = () => {
    const { mineLevel } = this.props;
    const { ref_coord } = mineLevel;

    let result = {};
    ref_coord.forEach((coord) => {
      const indexArray = coord.id.split(":"); // e.g. "DMLZ_Extaction:1"
      const index = indexArray[1];

      result[index] = {
        // used for transformation
        lat: coord.image_xy[0],
        lng: coord.image_xy[1],
        // used for display on marker popups
        id: coord.id,
        easting: coord.utm[0],
        northing: coord.utm[1],
        zoneNum: coord.utm_zone_number,
        zoneLetter: coord.utm_zone_letter,
      };
    });

    // sort object by key
    Object.keys(result)
      .sort()
      .forEach(function (v, i) {
        //console.log(v, result[v]);
      });

    // return the values
    return Object.values(result);
  };

  mapMount = () => {
    const { render } = this.props;

    const mapId = "map-" + render;

    const { mineLevel } = this.props;

    //console.log("hhh mapMount mineLevel", mineLevel);

    const {
      geoJSONMarkersDataUtm,
      geoJSONMarkersDataUtmForced,
      geoJSONNamedAreasUtm,
      geoJSONNamedAreaParentsUtm,
      geoJSONControllersUtm,
    } = this.props;

    let geoJSONMarkersData;
    let geoJSONMarkersDataForced;
    let geoJSONControllers;
    //
    // #REVIEW - issue here is that the component mounts before the mqtt messages have populated the objecet -> redux -> component etc.
    // need to ensure .features exists to transform...

    // ***************************************************************
    //
    // Update fireflies geoJson
    //
    // ***************************************************************

    if (
      typeof geoJSONMarkersDataUtm.features !== "undefined" &&
      geoJSONMarkersDataUtm.features.length
    ) {
      // #REVIEW - this now happens in WebWorker reducer for fireflies
      // deep clone - to stop geometry being converted on raw *utm data
      //geoJSONMarkersData = JSON.parse(JSON.stringify(geoJSONMarkersDataUtm));
      //geoJSONMarkersData = this.transformGeoJsonUtmToPixels(geoJSONMarkersData);

      geoJSONMarkersData = geoJSONMarkersDataUtm;
    } else {
      // add an empty geoJson featurecollection
      geoJSONMarkersData = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // Update fireflies Forced geoJson
    //
    // ***************************************************************

    if (
      typeof geoJSONMarkersDataUtmForced.features !== "undefined" &&
      geoJSONMarkersDataUtmForced.features.length
    ) {
      geoJSONMarkersDataForced = geoJSONMarkersDataUtmForced;
    } else {
      // add an empty geoJson featurecollection
      geoJSONMarkersDataForced = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // Update Controllers geoJson
    //
    // ***************************************************************

    if (
      typeof geoJSONControllersUtm.features !== "undefined" &&
      geoJSONControllersUtm.features.length
    ) {
      geoJSONControllers = geoJSONControllersUtm;
    } else {
      // add an empty geoJson featurecollection
      geoJSONControllers = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // Update named area geoJson
    //
    // ***************************************************************

    let geoJSONNamedAreas;

    if (
      typeof geoJSONNamedAreasUtm.features !== "undefined" &&
      geoJSONNamedAreasUtm.features.length
    ) {
      // deep clone - to stop geometry being converted on raw *utm data
      geoJSONNamedAreas = JSON.parse(JSON.stringify(geoJSONNamedAreasUtm));

      const mineLevel = this.props.mineLevel;
      const mineLevelKey = mineLevel.id;
      let mineLevelObj = {};
      mineLevelObj[mineLevelKey] = { ...mineLevel };

      geoJSONNamedAreas = transformGeoJsonUtmToPixels(
        geoJSONNamedAreas,
        mineLevelObj,
        1,
        false
      );
    } else {
      // add an empty geoJson featurecollection
      geoJSONNamedAreas = {
        type: "FeatureCollection",
        features: [],
      };
    }

    // ***************************************************************
    //
    // Update named area parent geoJson
    //
    // ***************************************************************

    let geoJSONNamedAreaParents;

    if (
      typeof geoJSONNamedAreaParentsUtm.features !== "undefined" &&
      geoJSONNamedAreaParentsUtm.features.length
    ) {
      // deep clone - to stop geometry being converted on raw *utm data
      geoJSONNamedAreaParents = JSON.parse(
        JSON.stringify(geoJSONNamedAreaParentsUtm)
      );

      const mineLevel = this.props.mineLevel;
      const mineLevelKey = mineLevel.id;
      let mineLevelObj = {};
      mineLevelObj[mineLevelKey] = { ...mineLevel };

      geoJSONNamedAreaParents = transformGeoJsonUtmToPixels(
        geoJSONNamedAreaParents,
        mineLevelObj,
        1,
        false
      );
    } else {
      // add an empty geoJson featurecollection
      geoJSONNamedAreaParents = {
        type: "FeatureCollection",
        features: [],
      };
    }

    const { mapViewState } = this.state;

    //console.log("mapViewState < draw", this.state);

    // ***************************************************************
    //
    // draw the map
    //
    // ***************************************************************

    this.drawTheMap(
      mapId,
      mineLevel,
      geoJSONMarkersData,
      geoJSONMarkersDataForced,
      geoJSONNamedAreas,
      geoJSONNamedAreaParents,
      geoJSONControllers,
      //
      mapViewState
    );
  };

  componentDidUpdate(prevProps, prevState) {
    // check if map is now visible
    if (prevProps.showMap !== this.props.showMap && this.props.showMap) {
      // reinitialise map
      // see - https://gis.stackexchange.com/questions/289975/toggling-leaflet-map-on-click
      this.map.invalidateSize();
    }

    // #DEBUG - messages to show the count # of markers in each layer when component updates
    //
    if (DEBUG_SHOW_COUNT_OF_MARKERS_EACH_LAYER) {
      // #WIP #DEBUG - how many markers
      // Construct an empty list to fill with onscreen markers.
      var inBounds = [],
        // Get the map bounds - the top-left and bottom-right locations.
        bounds = this.map.getBounds();

      // For each marker, consider whether it is currently visible by comparing
      // with the current map bounds.
      this.geoJSONGroupLayer.eachLayer(function (marker) {
        if (bounds.contains(marker.getLatLng())) {
          inBounds.push(marker.options.id);
        }
      });

      console.log(
        "geoJSONGroupLayer - time ",
        new Date().getTime() / 1000,
        "marker # ",
        inBounds.length
      );

      inBounds = [];

      this.markerGroupLayer.eachLayer(function (marker) {
        if (bounds.contains(marker.getLatLng())) {
          inBounds.push(marker.options.id);
        }
      });

      console.log(
        "markerGroupLayer - time ",
        new Date().getTime() / 1000,
        "marker # ",
        inBounds.length
      );

      inBounds = [];

      this.refPtGroupLayer.eachLayer(function (marker) {
        if (bounds.contains(marker.getLatLng())) {
          inBounds.push(marker.options.id);
        }
      });

      console.log(
        "refPtGroupLayer - time ",
        new Date().getTime() / 1000,
        "marker # ",
        inBounds.length
      );

      inBounds = [];

      this.geoJSONNamedAreaGroupLayer.eachLayer(function (layer) {
        // console.log("layer", layer);
        if (bounds.contains(layer.getLatLngs())) {
          inBounds.push(layer.options.id);
        }
      });

      console.log(
        "geoJSONNamedAreaGroupLayer - time ",
        new Date().getTime() / 1000,
        "marker # ",
        inBounds.length
      );

      console.log(
        "locationGroupLayers - time ",
        new Date().getTime() / 1000,
        "marker # ",
        this.locationGroupLayers !== undefined
          ? this.locationGroupLayers.length
          : undefined
      );

      console.log(
        "polylineGroupLayers - time ",
        new Date().getTime() / 1000,
        "marker # ",
        this.polylineGroupLayers !== undefined
          ? this.polylineGroupLayers.length
          : undefined
      );

      // ***********
    }

    const { mineLevel } = this.props;

    // if the area (mine level) has changed, remove the map and remount it all again
    if (
      prevProps.mineLevelId !== this.props.mineLevelId &&
      !_isEmpty(mineLevel)
    ) {
      if (this.map && this.map.remove) {
        this.map.off();
        this.map.remove();
      }

      // console.log("hhh mineLevel", mineLevel);

      this.setState({ map: { area: mineLevel.id } });
      // reset map load state pending reload of area background image

      // console.log("loaded isMapLoading", true);
      this.setState({ isMapLoading: true });

      const newMapViewState = this.props.componentstate.get(
        "mapViewState",
        mineLevel?.id
      );
      if (!_isEmpty(newMapViewState)) {
        //console.log("newMapViewState", newMapViewState);
        this.setState({ mapViewState: newMapViewState });
      }
      this.mapMount();
    }

    // delay update until background map image has loaded
    if (this.state.isMapLoading) {
      console.log(
        `WARNING.....Map is loading...updates are not rendering!!!!!!!!!`
      );
      return;
    }

    // ***************************************
    //
    // Load map control
    //
    // The map control lists the named area polygons.
    // These may update via props.
    // If the count changes the control needs to be completely redrawn.
    //
    // ***************************************
    const regionGroupLayersCount = Object.keys(this.regionGroupLayers).length;
    const parentsUnionGroupLayersCount = Object.keys(
      this.parentsUnionGroupLayers
    ).length;

    if (
      regionGroupLayersCount !== undefined &&
      parentsUnionGroupLayersCount !== undefined
    ) {
      if (
        regionGroupLayersCount !== this.state.regionGroupLayersCount ||
        parentsUnionGroupLayersCount !== this.state.parentsUnionGroupLayersCount
      ) {
        // remove previous control and reload it
        this.controller.remove();

        if (!this.map.hasLayer(this.geoJSONGroupLayer)) {
          this.geoJSONGroupLayer.addTo(this.map);
        }
        if (!this.map.hasLayer(this.geoJSONControllerGroupLayer)) {
          this.geoJSONControllerGroupLayer.addTo(this.map);
        }

        // #WIP #TODO
        // Make a common function!!!!
        // #######################################################################

        // console.log(
        //   `this.parentsUnionGroupLayers`,
        //   this.parentsUnionGroupLayers
        // );

        // sort the named areas to display ordered

        const orderedParentsUnionGroupLayers = Object.keys(
          this.parentsUnionGroupLayers
        )
          .sort()
          .reduce((obj, key) => {
            obj[key] = this.parentsUnionGroupLayers[key];
            return obj;
          }, {});

        //
        // Use the custom grouped layer control, not "L.control.layers"
        // https://github.com/ismyrnow/leaflet-groupedlayercontrol
        // see also -  https://stackoverflow.com/questions/34384905/leaflet-how-to-toggle-all-layers-either-off-or-on-at-once/34392545#34392545

        let groupedOverlays = {
          Markers: {
            FireFlys: this.geoJSONGroupLayer,
            Simple: this.geoJSONGroupLayerFireflySimple,
            "FireFly ID": this.geoJSONGroupLayerFireflyTooltips,
            Forced: this.geoJSONGroupLayerFireflyForcedTooltips,
            Controllers: this.geoJSONControllerGroupLayer,
            //"All Named Areas": this.geoJSONNamedAreaParentGroupLayer,
            Tags: this.geoJSONGroupLayerTags,
            //Editable: this.editableLayers,
          },

          // #NOTE - these are commented out to disable the group listing for now.
          // #TODO - add displaying this as a setup config option

          //"Named Area Regions:": { ...this.regionGroupLayers }, // multipolygons
          Polygons: { ...orderedParentsUnionGroupLayers }, //this.parentsUnionGroupLayers
        };

        // spread the objects to join the location layers to the overlay
        if (DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER) {
          groupedOverlays = {
            ...groupedOverlays,
            Locations: this.locationGroupLayers,
            //Regions: {...this.regionGroupLayers},
          };
        }

        // adds control group which is later updated by updateFireflyMarkersGeoJSON()
        if (DEFAULT_SHOW_FIREFLY_POLYLINE_GROUP_LAYER) {
          groupedOverlays = {
            ...groupedOverlays,
            Cables: this.polylineGroupLayers,
            //Regions: {...this.regionGroupLayers},
          };
        }

        const options = {
          collapsed: true,
          //exclusiveGroups: ["Named Areas"],
          groupsCollapsable: true, // see - https://github.com/ismyrnow/leaflet-groupedlayercontrol/pull/46
          // Show a checkbox next to non-exclusive group labels for toggling all
          groupCheckboxes: true,
        };

        // console.log(
        //   "loaded isMapLoading @ componentDidUpdate groupedLayer this.controller.addTo(this.map) ++++++++"
        // );

        this.controller = L.control
          .groupedLayers(null, groupedOverlays, options)
          .addTo(this.map);

        this.setState({ regionGroupLayersCount: regionGroupLayersCount });
        this.setState({
          parentsUnionGroupLayersCount: parentsUnionGroupLayersCount,
        });
      }
    }

    // // respond to checkbox settings
    // // toggle display of marker group
    // if (this.markerGroupLayer) {
    //   if (true) {
    //     //this.props.parentState.isCheckboxMarker
    //     this.map.addLayer(this.markerGroupLayer);
    //   } else {
    //     //this.map.addLayer(this.markerGroupLayer);
    //     this.map.removeLayer(this.markerGroupLayer);
    //   }
    // }

    // // toggle display of Fireflies group
    // if (this.refPtGroupLayer) {
    //   if (true) {
    //     //this.props.parentState.isCheckboxRefMarker
    //     this.map.addLayer(this.refPtGroupLayer);
    //   } else {
    //     //this.map.addLayer(this.refPtGroupLayer);
    //     this.map.removeLayer(this.refPtGroupLayer);
    //   }
    // }

    // ****************************************************************************************
    //
    // Enable / disabled control layers, named areas etc.
    //
    // ****************************************************************************************

    // // use this to control layers, named areas etc.
    // if (
    //   this.locationGroupLayers &&
    //   this.polylineGroupLayers &&
    //   this.geoJSONNamedAreaGroupLayer &&
    //   this.regionGroupLayers
    // ) {
    //   if (false) {
    //     for (const [key, value] of Object.entries(this.locationGroupLayers)) {
    //       //console.log("key", key);
    //       this.map.addLayer(value);
    //     }
    //   } else {
    //     for (const [key, value] of Object.entries(this.locationGroupLayers)) {
    //       this.map.removeLayer(value);
    //     }
    //   }

    //   if (false) {
    //     for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
    //       //console.log("key", key);
    //       this.map.addLayer(value);
    //     }
    //   } else {
    //     for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
    //       this.map.removeLayer(value);
    //     }
    //   }

    //   if (false) {
    //     for (const [key, value] of Object.entries(
    //       this.geoJSONNamedAreaGroupLayer
    //     )) {
    //       //console.log("key", key);
    //       this.map.addLayer(value);
    //     }
    //   } else {
    //     for (const [key, value] of Object.entries(
    //       this.geoJSONNamedAreaGroupLayer
    //     )) {
    //       this.map.removeLayer(value);
    //     }
    //   }

    //   if (false) {
    //     for (const [key, value] of Object.entries(this.regionGroupLayers)) {
    //       console.log("regionGroupLayers key", key);
    //       this.map.addLayer(value);
    //     }
    //   } else {
    //     for (const [key, value] of Object.entries(this.regionGroupLayers)) {
    //       this.map.removeLayer(value);
    //     }
    //   }
    // }

    // // keep track of state of named area polygon display (i.e. as triggered by layer control)
    // for (const [key, value] of Object.entries(
    //   this.parentsUnionGroupLayers
    // )){
    // }

    // console.log(
    //   `this.state.isMapComponentDidMount`,
    //   this.state.isMapComponentDidMount
    // );

    // !_isEmpty(this.props.regionPreview)

    // update display of named areas by mouse over on buttons

    // if regionPreview is defined
    if (this.props.regionPreview !== undefined) {
      // if the parentGroupLayers exist, and there are some!
      if (!_isEmpty(this.parentsUnionGroupLayers)) {
        // if the regionPreview has changed _OR_ this is the first time (or back)
        if (
          JSON.stringify(prevProps.regionPreview) !==
            JSON.stringify(this.props.regionPreview) ||
          this.state.isMapComponentDidMount
        ) {
          // refresh polygon display if component is a new mount
          if (this.state.isMapComponentDidMount) {
            this.setState({ isMapComponentDidMount: false });
          }

          // console.log(`prevProps.regionPreview`, prevProps.regionPreview);
          // console.log("this.props.regionPreview", this.props.regionPreview);
          // console.log(
          //   `this.parentsUnionGroupLayers`,
          //   this.parentsUnionGroupLayers
          // );

          const regionPreviewList = this.props?.regionPreview;

          if (regionPreviewList) {
            // check every layer
            for (const [key, value] of Object.entries(
              this.parentsUnionGroupLayers
            )) {
              // flag for layer in previewList
              const isShow = regionPreviewList.some(
                (item) => item.namedArea === key
              );

              // if layer is displayed
              if (this.state.layerControlState[key] === true) {
                // ..but *not* in the preview list
                if (!isShow) {
                  // remove it
                  this.map.removeLayer(value);
                }
                // if layer is not displayed
              } else {
                //... but is in the preview list
                if (isShow) {
                  // ... display it
                  this.map.addLayer(value);
                }
              }
            }

            // regionPreviewList.forEach((region, idx) => {
            //   const {
            //     id: areaId,
            //     spec,
            //     type,
            //     name,
            //     namedArea: parentName,
            //   } = region;

            //   //console.log(`regionPreview region`, region);

            //   if (spec !== undefined) {
            //     if (type === "Polygon") {
            //       // Polygon = *not* level or area wide, also not `undefined`
            //       if (areaId === this.props.mineLevelId) {
            //         // if layer in previewList and not displayed ...
            //         if (!this.state.layerControlState[parentName]) {
            //           const layer = this.parentsUnionGroupLayers[parentName];
            //           if (layer !== undefined) {
            //             //...show it
            //             this.map.addLayer(layer);
            //           }
            //         }
            //       }
            //     }
            //   }
            // });
          } else {
            for (const [key, value] of Object.entries(
              this.parentsUnionGroupLayers
            )) {
              this.state.layerControlState[key] === true
                ? this.map.addLayer(value)
                : this.map.removeLayer(value);
            }
          }

          // #NOTE - support for regionPreview as one object, not list of objects
          if (DEBUG_ENABLED_FALSE) {
            const {
              spec,
              type,
              name,
              namedArea: parentName,
            } = this.props.regionPreview;
            if (name !== undefined) {
              if (type === "Polygon") {
                // Polygon = *not* level or area wide, also not `undefined`
                const nameArray = spec.split(":");
                const area = nameArray[0];
                // const parentName = nameArray[1];
                if (area === this.props.mineLevelId) {
                  if (!this.state.layerControlState[parentName]) {
                    //console.log("add layer", parentName);
                    const layer = this.parentsUnionGroupLayers[parentName];
                    if (layer !== undefined) {
                      this.map.addLayer(layer);
                    }
                  }
                }
              }
            } else {
              // restore layer control state
              // console.log(
              //   "this.state.layerControlState",
              //   this.state.layerControlState
              // );

              for (const [key, value] of Object.entries(
                this.parentsUnionGroupLayers
              )) {
                this.state.layerControlState[key] === true
                  ? this.map.addLayer(value)
                  : this.map.removeLayer(value);
              }
            }
          }
        }
      }

      // ****************************************************************************************
      //
      // ###REVIEW ##PENDING DELETE
      // old code used in named area editing map
      // ****************************************************************************************

      const parentId = "parentNamedArea";

      // #REVIEW / TODO - move this filter to a common area
      const isParentDirty =
        this.props?.isDirty?.namedArea.filter(
          (namedArea) => namedArea === parentId
        ).length > 0;

      // ---------------------------------------------------------------
      // remove layers queued for deletion (via named areas UI)

      const namedAreaDeleteSelections = this.props.namedAreaDeleteSelections;

      let namedAreaDeleted = false; // flag to make the map update quicker after something deleted
      namedAreaDeleteSelections.forEach((selection, idx) => {
        // find the selection by id, by checking every layer in editableLayers

        this.editableLayers.eachLayer((layer) => {
          if (layer.feature.properties.id === selection.id) {
            console.log("----> namedAreaDeleteSelections remove", selection.id);

            // delete from editableLayers, when named areas area updated below the 'UpdateLocalMap' will remove this item
            // and consequently from the named area list (as this populates via redux)
            this.editableLayers.removeLayer(layer); // <----------- this is the most important bit

            // delete from
            this.props.namedAreaClearDeleteSelections(selection);
            namedAreaDeleted = true;
          }
        });
      });

      // ****************************************************************************************
      //
      // Update Firefly markers
      //
      // ****************************************************************************************
      //

      if (
        !_isEmpty(this.props.geoJSONMarkersDataUtm) && //#WIP - disabled to test rendering update for lightingplan changes
        this.props.geoJSONMarkersDataUtm.features && // features propertie exists but does not have to have any FFs
        // #NOTE - ATM this state is not used (i.e. not set). Leave in place for #REVIEW
        !this.state.isPopupOpen && // don't update if a marker popup is open, because redraw clobber eventListener is open dlg
        (this.props.fireflyIdsUpdateList.length > 0 ||
          this.state.forceMapRefresh)
      ) {
        if (this.state.forceMapRefresh)
          console.log(`------------> FORCED REFRESH`);

        // keep a copy of the previous state
        // #REVIEW #TODO - is this necesary since we have already compared at the reducer?
        let prevGeoJSONMarkersData = {};

        // deep clone - to stop geometry being converted on raw *utm data
        let geoJSONMarkersData = JSON.parse(
          JSON.stringify(this.props.geoJSONMarkersDataUtm)
        );

        if (DEBUG_ENABLED_FALSE) {
          // #REVIEW/TODO #WIP
          // check for [0,0] or out of rage points and move them to the pen
          //  const transform = this.Transform(this.localPts(), this.standardPtsUtm());
          // const geoJsonTransform = [];

          // #WIP - test for out of range too!
          geoJSONMarkersData.features.map((value, idx) => {
            const arrayLatLng = value.geometry.coordinates.slice();
            // check if rx an un positioned marker i.e. [0,0]
            const isAllZero = arrayLatLng.every((item) => item === 0);
            if (isAllZero) {
              // console.log(
              //   "Found FF isAllZero value.properties.id -> ",
              //   value.properties.id
              // );

              // update FF value.properties.id with new coordinate in the pen
              let randomHoldingPenCoordinate;
              if (this.holdingPen !== undefined) {
                const hasHoldingPen = this.map.hasLayer(this.holdingPen);
                if (isAllZero && hasHoldingPen) {
                  randomHoldingPenCoordinate = this.randomPointInPoly(
                    this.holdingPen
                  );
                  // console.log(
                  //   "isAllZero randomHoldingPenCoordinate",
                  //   randomHoldingPenCoordinate
                  // );

                  // change the value properties coordinates with new holding pen point
                  //console.log("isAllZero value", JSON.stringify(value));
                  let newValue = JSON.parse(JSON.stringify(value));
                  newValue.geometry = randomHoldingPenCoordinate.geometry;

                  const mineLevel = this.props.mineLevel;
                  const mineLevelKey = mineLevel.id;
                  let mineLevelObj = {};
                  mineLevelObj[mineLevelKey] = { ...mineLevel };

                  // convert to Utm values
                  const newPointUtm = this.convertGeoJsonPixelsToUtm(
                    {
                      type: "FeatureCollection",
                      features: [newValue],
                    },
                    mineLevelObj
                  ).features[0].geometry.coordinates.slice();
                  // #REVIEW/TODo #FIX!!!!!!!!!!!! - should filter to find the id, ATM just grab first one (and only) one which comes back

                  //console.log("isAllZero newPointUTtm", newPointUTtm);

                  // send via mqtt for change
                  // #WIP....send FF update within map

                  const changeTopic = `firefly/${newValue.properties.id}/change`;

                  const changeMsg = {
                    id: newValue.properties.id,
                    mac: newValue.properties.mac,
                    utm_zone_number: 53,
                    utm_zone_letter: "M",
                    Z: 0,
                    utm: [newPointUtm[0], newPointUtm[1]],
                    token: messageToken(),
                  };

                  //console.log("isAllZero changeFF", changeMsg);

                  // #WIP - the issue here is this sends out lots of messages until the changes is made...flooding the system.
                  // need to log the send, wait for an ack, clear it.
                  // disabled pending implement ack management
                  if (false) {
                    this.props.mqttPublish({
                      topic: changeTopic,
                      qos: 0,
                      message: changeMsg,
                      retained: false,
                    });
                  }
                }
              }
            }
          });
        }

        //
        // update the fireflies.

        // make a local copy
        let newFireflyIdsUpdateList = this.props.fireflyIdsUpdateList;

        // check for deleted FFs
        if (
          !_isEmpty(this.props.fireflyIdsUpdateList) &&
          !_isEmpty(this.props.fireflyIdsDeleteList)
        ) {
          // check if there are any FFs for deletion and if so delete it from
          // the firefly layers - this.layerlistFireflies[id]
          if (this.props.fireflyIdsDeleteList.length > 0) {
            let newLayerListFireflies = {};
            let updatedFireflyIds = [];
            for (const [key, value] of Object.entries(
              this.layerlistFireflies
            )) {
              if (this.props.fireflyIdsDeleteList.includes(key)) {
                this.map.removeLayer(value);
                // if we delete the ID don't update it
                newFireflyIdsUpdateList = newFireflyIdsUpdateList.filter(
                  (ff) => ff === key
                );
                // remove FF Id from fireflyIdsUpdateList after it's been processed
                //
                updatedFireflyIds.push(key);
              } else {
                newLayerListFireflies[key] = value;
              }
            }
            // remove FF Id from fireflyIdsUpdateList
            this.props.fireflyIdsUpdateListDeleteId(updatedFireflyIds);
            this.layerlistFireflies = newLayerListFireflies;
          }
        }

        // now manage the FF updates - this.geoJSONMarkersData / this.geoJSONGroupLayerFireflySimple
        if (
          !_isEmpty(this.props.fireflyIdsUpdateList) ||
          this.state.forceMapRefresh
        ) {
          // update markers for geoJSON
          this.updateFireflyMarkersGeoJSON(
            this.map,
            geoJSONMarkersData,
            prevGeoJSONMarkersData,
            this.props.mineLevelId,
            newFireflyIdsUpdateList,
            this.state.forceMapRefresh
          );
        }

        // if doing forced map refresh reset flag
        if (this.state.forceMapRefresh) {
          this.setState({ forceMapRefresh: false });
        }
      }

      // ****************************************************************************************
      //
      // Update Firefly Forced markers
      //
      // ****************************************************************************************
      //

      if (
        !_isEmpty(this.props.geoJSONMarkersDataUtmForced) &&
        !_isEmpty(this.props.geoJSONMarkersDataUtmForced?.features)
      ) {
        // keep a copy of the previous state
        let prevGeoJSONMarkersDataForced = {};
        if (prevProps.geoJSONMarkersDataForced !== undefined) {
          prevGeoJSONMarkersDataForced = JSON.parse(
            JSON.stringify(prevProps.geoJSONMarkersDataForced)
          );
        }

        // deep clone - to stop geometry being converted on raw *utm data
        let geoJSONMarkersDataForced = JSON.parse(
          JSON.stringify(this.props.geoJSONMarkersDataUtmForced)
        );

        if (!_isEmpty(this.props.fireflyIdsUpdateList)) {
          // update markers for geoJSON
          this.updateFireflyForcedMarkersGeoJSON(
            this.map,
            geoJSONMarkersDataForced,
            prevGeoJSONMarkersDataForced,
            this.props.mineLevelId,
            this.props.fireflyIdsUpdateList
          );
        }
      }

      // ****************************************************************************************
      //
      // Update controller markers
      //
      // ****************************************************************************************

      // const prevGeoJSONControllersUtm = JSON.stringify(
      //   prevProps.geoJSONControllersUtm
      // );
      // const newGeoJSONControllersUtm = JSON.stringify(
      //   this.props.geoJSONControllersUtm
      // );

      // // if each object has content
      // if (
      //   !_isEmpty(prevGeoJSONControllersUtm) &&
      //   !_isEmpty(newGeoJSONControllersUtm)
      // ) {
      // }

      if (
        (!_isEmpty(this.props.geoJSONControllersUtm) &&
          !_isEmpty(this.props.geoJSONControllersUtm?.features) &&
          this.props.geoJSONControllersUtm !==
            prevProps.geoJSONControllersUtm &&
          // #WIP?????????????????????????
          // is this really why???????//
          !this.state.isPopupOpen) || // don't update if a marker popup is open, because redraw clobber eventListener is open dlg
        this.state.forceMapRefresh
      ) {
        // keep a copy of the previous state
        let prevGeoJSONControllers = {};
        if (prevProps.geoJSONControllersUtm !== undefined) {
          prevGeoJSONControllers = JSON.parse(
            JSON.stringify(prevProps.geoJSONControllersUtm)
          );
        }

        // deep clone - to stop geometry being converted on raw *utm data
        let geoJSONControllers = JSON.parse(
          JSON.stringify(this.props.geoJSONControllersUtm)
        );

        // update markers for geoJSON
        this.updateControllerMarkersGeoJSON(
          this.map,
          geoJSONControllers,
          prevGeoJSONControllers,
          this.props.mineLevelId,
          this.state.forceMapRefresh
        );
      }

      // ****************************************************************************************
      //
      // update named area polygons
      //
      // ****************************************************************************************

      // update from source data if
      // * not empty
      // * props have changed
      // _or_
      // * named areas have been locally deleted

      // WIP HERE - previously allowed update if (OR) namedAreaDeleted = true even if no changes to data to stop redraw of namedAreas <------
      //     ||  namedAreaDeleted
      // ...working though?

      // #REVIEW/TODO - make a function out of this

      // incoming objects (current and prevProps) are different even though JSON content is the same.
      // compare the strings to stop unnecessary updates
      const prevGeoJSONNamedAreasUtm = JSON.stringify(
        prevProps.geoJSONNamedAreasUtm
      );
      const newGeoJSONNamedAreasUtm = JSON.stringify(
        this.props.geoJSONNamedAreasUtm
      );

      let isChangedgeoJSONNamedAreasUtm = false;

      // if each object has content
      if (
        !_isEmpty(prevGeoJSONNamedAreasUtm) &&
        !_isEmpty(newGeoJSONNamedAreasUtm)
      ) {
        // are they changed?
        isChangedgeoJSONNamedAreasUtm =
          prevGeoJSONNamedAreasUtm !== newGeoJSONNamedAreasUtm ? true : false;
      }

      if (
        // isChangedgeoJSONNamedAreasUtm ||
        // namedAreaDeleted
        (!_isEmpty(this.props.geoJSONNamedAreasUtm) &&
          !_isEmpty(this.props.geoJSONNamedAreasUtm?.features) &&
          this.props.geoJSONNamedAreasUtm !== prevProps.geoJSONNamedAreasUtm) ||
        namedAreaDeleted
        // ||      heartBeatChanged
      ) {
        // console.log("componentDidUpdate namedAreaHiddenSelections - updating!");

        // #WIP - check if localMapState and incoming data differ
        // with view to filtering localMapState data for delete and hidden namedAreas

        // deep clone - to stop geometry being converted on raw *utm data
        let geoJSONNamedAreas = JSON.parse(
          JSON.stringify(this.props.geoJSONNamedAreasUtm)
        );

        const mineLevel = this.props.mineLevel;
        const mineLevelKey = mineLevel.id;
        let mineLevelObj = {};
        mineLevelObj[mineLevelKey] = { ...mineLevel };

        // transform the coordinates to pixel image references
        geoJSONNamedAreas = transformGeoJsonUtmToPixels(
          geoJSONNamedAreas,
          mineLevelObj,
          1,
          false
        );

        //#WIP - merge geoJSONNamedAreas and localMapState, giving priority to geoJSONNamedAreas if propertie.id is same
        //i.e. get geoJSONNamedAreas and import all

        const local = this.props.localMapState?.features;
        const mqtt = geoJSONNamedAreas?.features;

        let localIds = [];
        let mqttIds = [];

        if (local) {
          local.forEach((item, idx) => {
            localIds[item.properties.id] = idx;
          });
        }
        //      console.log("componentDidUpdate local", local);
        if (mqtt) {
          mqtt.forEach((item, idx) => {
            mqttIds[item.properties.id] = idx;
          });
        }

        if (!isParentDirty) {
          // update markers for geoJSON
          this.updateNamedAreasGeoJSON(
            this.map,
            geoJSONNamedAreas,
            this.props.mineLevelId
          );
        }

        // update localMapState with all objects in the editable layer
        if (this.map.hasLayer(this.editableLayers)) {
          // add geoJSONNamedAreaGroupLayer layer to editable layer so can edit existing/loaded polygons
          this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
            // console.log(
            //   "Adding mqtt Named Area to this.editableLayers -> ",
            //   layer._leaflet_id
            // ); // KEEP AS PERMANENT INDICATOR THAT MAP GROUPS ARE UPDATING
            this.editableLayers.addLayer(layer);
          });

          const geoJsonPixels = this.editableLayers.toGeoJSON();

          //#REVEW/TODO - this should only happen if the shape has changed otherwise use the coordinates in the features.properties.coordinatesUtm
          // ----->
          //const transform = this.props.mineLevel.transform;

          const mineLevel = this.props.mineLevel;
          const mineLevelKey = mineLevel.id;
          let mineLevelObj = {};
          mineLevelObj[mineLevelKey] = { ...mineLevel };

          const geoJsonUtm = this.convertGeoJsonPixelsToUtm(
            geoJsonPixels,
            mineLevelObj
          );
          // <-----

          this.props.UpdateLocalMap({ namedAreas: geoJsonUtm });
          this.setState({ thislocalMapState: geoJsonUtm });

          // console.log(
          //   "map componentDidUpdate this.props.localMapState - after update",
          //   JSON.stringify(geoJsonUtm)
          // );

          const geo = geoJsonUtm?.features;
          let geoIds = [];
          if (geo) {
            geo.forEach((item, idx) => {
              geoIds[item.properties.id] = idx;
            });
          }
          //        console.log("componentDidUpdate geoIds", geoIds);

          //#WIP - testing hide and show layers

          // #REVIEW/TODO
          // THIS NEEDS TO BE RE_WRITTEN TO RUN OVER ALL EDITABLE LAYERS
          //
          this.editableLayers.eachLayer((layer) => {
            // #WIP - this is failing when going from another page back to control screen
            // "TypeError: Cannot read property 'style' of undefined"
            if (layer.getElement() !== undefined) {
              layer.getElement().style.display = "block"; // this resets any hidden layer actions
            }
          });

          //#REVIEW/TODO - namedAreaHiddenSelections and namedAreaDeleteSelections are 'same' make function or restructure message/object

          const namedAreaHiddenSelections = this.props.namedAreaHideSelections;
          //#REVIEW/TODO - filter by parentId - currently ignore id
          const namedAreasHidden = namedAreaHiddenSelections.map((na) => na.id);
          //        console.log("namedAreasHidden", namedAreasHidden);

          // const namedAreaDeleteSelections = this.props.namedAreaDeleteSelections;
          // //#REVIEW/TODO - filter by parentId - currently ignore id
          // const namedAreasDeleted = namedAreaDeleteSelections.map((na) => na.id);
          // //        console.log("namedAreasDeleted", namedAreasDeleted);

          this.editableLayers.eachLayer((layer) => {
            const includesNamedAreaHidden = namedAreasHidden.includes(
              layer.feature.properties.id
            );
            // const includesNamedAreaDeleted = namedAreasDeleted.includes(
            //   layer.feature.properties.id
            // );

            if (!includesNamedAreaHidden) {
              //
              return;
            }
            layer.getElement().style.display = "none";
          });

          this.editableLayers.bringToBack();
          this.geoJSONNamedAreaGroupLayer.bringToBack();
        }
      }

      //#############################################

      // ****************************************************************************************
      //
      // update named area parent multipolygons
      //
      // ****************************************************************************************

      // incoming objects (current and prevProps) are different even though JSON content is the same.
      // compare the strings to stop unnecessary updates
      // if each object has content

      if (
        !_isEmpty(this.props.geoJSONNamedAreaParentsUtm) &&
        !_isEmpty(this.props.geoJSONNamedAreaParentsUtm?.features) &&
        this.props.geoJSONNamedAreaParentsUtm !==
          prevProps.geoJSONNamedAreaParentsUtm
      ) {
        // deep clone - to stop geometry being converted on raw *utm data
        let geoJSONNamedAreaParents = JSON.parse(
          JSON.stringify(this.props.geoJSONNamedAreaParentsUtm)
        );

        const mineLevel = this.props.mineLevel;
        const mineLevelKey = mineLevel.id;
        let mineLevelObj = {};
        mineLevelObj[mineLevelKey] = { ...mineLevel };

        // transform the coordinates to pixel image references
        geoJSONNamedAreaParents = transformGeoJsonUtmToPixels(
          geoJSONNamedAreaParents,
          mineLevelObj,
          1,
          false
        );

        // update markers for geoJSON
        this.updateNamedAreaParentsGeoJSON(
          this.map,
          geoJSONNamedAreaParents,
          this.props.mineLevelId
        );
      }

      //#############################################
    }
  }

  updateFireflyMarkersGeoJSON(
    map,
    geoJSONMarkersData,
    prevGeoJSONMarkersData,
    mineLevelId,
    fireflyIdsUpdateList,
    forceUpdate
  ) {
    const blueMarker = {
      radius: 6,
      fillColor: "blue",
    };

    const redMarker = {
      radius: 6,
      fillColor: "red",
    };

    const orangeMarker = {
      radius: 6,
      fillColor: "orange",
    };

    const greenMarker = {
      radius: 6,
      fillColor: "green",
    };

    if (false) {
      // convert previous marker data to keyed object
      let prevMarkerData = {};
      if (prevGeoJSONMarkersData?.features !== undefined) {
        prevGeoJSONMarkersData.features.map((feature, idx) => {
          const {
            properties: { id },
          } = feature;
          prevMarkerData[id] = feature;
        });
      }

      //console.log("FF_marker before calc", prevMarkerData);

      // update....
      geoJSONMarkersData.features.map((feature, idx) => {
        const { properties } = feature;

        const {
          id,
          area,
          location,
          position,
          easting,
          northing,
          z,
          utm_zone_number,
          utm_zone_letter,
          fireflyNote,
          //color,
          light,
          controllerMode,
          deviceStatus,
          timestamp,
          ups_id,
          mac,
        } = properties;

        //      const isChanged = !_isEqual(prevMarkerData[id], feature);

        const isChanged =
          JSON.stringify(prevMarkerData[id]) !== JSON.stringify(feature);

        // console.log(
        //   "FF_Marker JSON.stringify(prevMarkerData[id])",
        //   JSON.stringify(prevMarkerData[id])
        // );

        // console.log("FF_Marker JSON.stringify(feature)", JSON.stringify(feature));

        // console.log("FF_Marker isChanged", isChanged);

        // #DEBUG
        // if (id === "DMLZ_Extraction:P17:1") {
        //   console.log("xxx P17:1 isChanged", isChanged);
        // }
        // if (id === "DMLZ_Extraction:P16:2") {
        //   console.log("xxx P16:2 isChanged", isChanged);
        // }
        // if (id === "DMLZ_Extraction:P18:2") {
        //   console.log("xxx P18:2 isChanged", isChanged);
        // }

        //      console.log("isChanged", prevMarkerData[id]);
        //      console.log("isChanged", feature);
        // console.log(
        //   `isChanged ${id} : ${isChanged} : old: ${prevMarkerData[id]?.properties?.timestamp}`
        // );
        // console.log(
        //   `isChanged ${id} : ${isChanged} : new: ${feature?.properties?.timestamp}`
        // );

        const lastStatusReport =
          timestamp !== undefined
            ? formatRelative(parseISO(timestamp), new Date(), {
                includeSeconds: true,
              })
            : "-";

        const { geometry } = feature;

        // coord geom is reversed
        const X = round(geometry.coordinates[0], 2);
        const Y = round(geometry.coordinates[1], 2);

        const {
          brightness,
          color,
          marker,
          led_state,
          off_time,
          on_time,
          train,
        } = light;

        // Make sure there is a layergroup for new markers.
        // layer groups are defined by LOCATION.
        // If a new LOCATION is added, check and add if necessary.
        //
        // Note: @28Oct20 - layergroups are not used ATM. This is development work
        // which has not been released.
        //
        if (this.map.hasLayer(this[location])) {
        } else {
          this[location] = L.featureGroup().addTo(this.map);
          this.locationGroupLayers[location] = this[location];
        }

        if (this.layerlistFireflies[id]) {
          // DEBUG ??????????????????????
          // if (id === "DMLZ_Extraction:P17:1") {
          //   console.log(
          //     "xxx P17:1 this.layerlistFireflies[id]",
          //     this.layerlistFireflies[id]?.feature.geometry?.coordinates,
          //     new Date().getTime()
          //   );
          // }
          if (isChanged) {
            // if (id === "DMLZ_Extraction:P17:1") {
            //   console.log(
            //     "xxx P17:1 this.layerlistFireflies[id] isChanged",
            //     new Date().getTime()
            //   );
            // }

            //
            //
            const { svgIcon, svgStyle } = markerDefinition(feature);
            const currentZoom = this.map.getZoom();

            this.layerlistFireflies[id].setIcon(
              makeIcon(svgIcon, svgStyle, currentZoom)
            );

            // update popup content
            if (false) {
              this.layerlistFireflies[id].getPopup().setContent(
                `<div><strong>Firefly: </strong><a href="/admin/firefly/${id}">${id}</a></div>
          <div><strong>Controller: </strong><a href="/admin/controller/${ups_id}">${ups_id}</a></div>

          <hr/>
          <div>${this.testExternalStr} ${area}:${location}:${position} </div>
          <div><strong>Geom: </strong>E:${round(easting, 2)} N:${round(
                  northing,
                  2
                )} Z${Math.trunc(
                  z
                )} ${utm_zone_number} ${utm_zone_letter}  </div>
          <div><strong>Image: </strong>X:${X} Y:${Y} </div>
          <div><strong>Color: </strong>Requested ${color.plan}, Reported ${
                  color.status
                } </div>
          <div><strong>Last Report: </strong>${lastStatusReport}</div>`
              );
            } else if (false) {
              const popupInfo = `${id} <br> ${id} <br> <button class="edit">Edit</button><br> <button class="delete">Delete</button>`;
              this.layerlistFireflies[id].getPopup().setContent(popupInfo);
            } else {
              //console.log("FF_marker popup update");

              // #WIP
              // collect events for this ff.
              // This is used to indicate which light is toggled in the popup and to
              // know which priority event to fire in the attached eventListener.
              const fireflyNamedAreaId = createFireflyNamedAreaId(id);

              const namedAreaEventForFirefly = this.props.namedAreaEvents?.find(
                (event) => event.id.includes(fireflyNamedAreaId)
              );

              const fireflyMarkerPopup = renderToString(
                <FireflyMarkerPopup
                  id={id}
                  data={{
                    ups_id,
                    easting,
                    northing,
                    X,
                    Y,
                    z,
                    utm_zone_number,
                    utm_zone_letter,
                    fireflyNote,
                    color,
                    led_state,
                    lastStatusReport,
                    namedAreaEventForFirefly,
                    light,
                    role: this.props.role,
                    ffMac: mac,
                    deviceStatus,
                    forcedClickList: [...this.state.forcedClickList],
                  }}
                />
              );

              // after sending forcedClickList to the popup, clear it
              // acknowledge click while processing
              let newForcedClickList = [...this.state.forcedClickList];
              if (newForcedClickList.includes(id)) {
                newForcedClickList = newForcedClickList.filter(
                  (item) => item !== id
                );
                this.setState({ forcedClickList: newForcedClickList });
              }

              // update popup content
              this.layerlistFireflies[id]
                .getPopup()
                .setContent(fireflyMarkerPopup);

              // if (id === "DMLZ_Extraction:P17:1") {
              //   console.log(`P17:1 update content ${id}`);
              // }
              // re-attached the event listener
              // this.layerlistFireflies[id].on("popupopen", (event) => {
              //   var marker = event.popup._source.feature.properties.id;
              //   console.log("popup update marker", marker);

              //   console.log("popup update event.target", event.target);
              //   var popUp = event.target.getPopup();
              //   console.log("popup update popup", popUp);
              //   console.log("popup update getElement", popUp.getElement());
              //   console.log(
              //     "popup update getElement.querySelector",
              //     popUp.getElement().querySelector(".popupFireflyMarkerLink")
              //   );

              //   popUp
              //     .getElement()
              //     .querySelector(".popupFireflyMarkerLink")
              //     .addEventListener("click", (e) => {
              //       console.log(
              //         "update clicked edit on marker: ",
              //         e,
              //         "target: ",
              //         e.target,
              //         "dataId ",
              //         e.target.getAttribute("dataId")
              //       );

              //       const id = e.target.getAttribute("dataId");
              //       if (!_isEmpty(id)) {
              //         this.props.goto(`/admin/firefly/${id}`);
              //       }
              //     });
              // });
            }
          }
          //??????????????????????
        } else if (feature.properties.area === mineLevelId) {
          // if it's not in the layer list, and is in the area i.e. `mineLevelId` add it..........
          //
          // Note - pointToLayer and onEachFeature are defined in ComponentDidMount() and
          // .addData() runs `onEachFeatureFirefly` which adds the feature to layerlistFireflies[]

          this.geoJSONGroupLayer.addData(geoJSONMarkersData.features[idx]);
        }
      });
    } else {
      // #WIP - updated version

      // tracks fireflies which have been updated. These should be deleted from the list.
      let updatedFireflyIds = [];

      // update....
      geoJSONMarkersData.features.map((feature, idx) => {
        const { properties } = feature;

        const { id, location } = properties;

        // Make sure there is a layergroup for new markers.
        // layer groups are defined by LOCATION.
        // If a new LOCATION is added, check and add if necessary.

        if (DEFAULT_SHOW_FIREFLY_LOCATION_GROUP_LAYER) {
          if (this.map.hasLayer(this[location])) {
          } else {
            this[location] = L.featureGroup().addTo(this.map);
            this.locationGroupLayers[location] = this[location];
          }
        }

        if (this.layerlistFireflies[id]) {
          if (fireflyIdsUpdateList.includes(id) || forceUpdate) {
            // remove FF Id from fireflyIdsUpdateList - it is being processed
            //
            updatedFireflyIds.push(id);

            const {
              area,
              position,
              easting,
              northing,
              z,
              utm_zone_number,
              utm_zone_letter,
              fireflyNote,
              //color,
              light,
              controllerMode,
              timestamp,
              ups_id,
              mac,
              deviceStatus,
            } = properties;

            const lastStatusReport =
              timestamp !== undefined
                ? formatRelative(parseISO(timestamp), new Date(), {
                    includeSeconds: true,
                  })
                : "-";

            const { geometry } = feature;

            // coord geom is reversed
            const X = round(geometry.coordinates[0], 2);
            const Y = round(geometry.coordinates[1], 2);

            const {
              brightness,
              color,
              marker,
              led_state,
              off_time,
              on_time,
              train,
            } = light;

            // #WIP - check if moved?
            // #NOTE - for 1st
            // see #fireflyIdsUpdateList - src/components/WebWorker/reducer.js
            //

            // console.log(
            //   `FIREFLY UPDATED!!! id`,
            //   id,
            //   fireflyIdsUpdateList,
            //   forceUpdate
            // );

            if (true) {
              var newLatLng = new L.LatLng(Y, X);
              this.layerlistFireflies[id].setLatLng(newLatLng);
            }

            //
            const { svgIcon, svgStyle } = markerDefinition(feature);
            const currentZoom = this.map.getZoom();

            this.layerlistFireflies[id].setIcon(
              makeIcon(svgIcon, svgStyle, currentZoom)
            );

            //console.log(`FIREFLY MARKER_REDRAW ID `, id);

            // #WIP
            // collect events for this ff.
            // This is used to indicate which light is toggled in the popup and to
            // know which priority event to fire in the attached eventListener.
            // const forcedLightEventInfo = {
            //   active: true,
            //   light: {
            //     color: "yellow",
            //     state: "ON",
            //     brightness: 70,
            //     on_time: 10,
            //     off_time: 10,
            //     train: 10,
            //   },
            // };

            const fireflyNamedAreaId = createFireflyNamedAreaId(id);

            const namedAreaEventForFirefly = this.props.namedAreaEvents?.find(
              (event) => event.id.includes(fireflyNamedAreaId)
            );

            // console.log(
            //   `fireflyMarkerPopup TWO fireflyNamedAreaId`,
            //   fireflyNamedAreaId,
            //   namedAreaEventForFirefly,
            //   this.props.namedAreaEvents,
            //   properties,
            //   this.layerlistFireflies[id]
            // );

            // update popup content
            const fireflyMarkerPopup = renderToString(
              <FireflyMarkerPopup
                id={id}
                data={{
                  ups_id,
                  easting,
                  northing,
                  X,
                  Y,
                  z,
                  utm_zone_number,
                  utm_zone_letter,
                  fireflyNote,
                  color,
                  led_state,
                  lastStatusReport,
                  namedAreaEventForFirefly,
                  light,
                  role: this.props.role,
                  ffMac: mac,
                  deviceStatus,
                  forcedClickList: [...this.state.forcedClickList],
                }}
              />
            );

            // after sending forcedClickList to the popup, clear it
            // acknowledge click while processing
            let newForcedClickList = [...this.state.forcedClickList];
            if (newForcedClickList.includes(id)) {
              newForcedClickList = newForcedClickList.filter(
                (item) => item !== id
              );
              this.setState({ forcedClickList: newForcedClickList });
            }

            // Update popup content
            // If it is open close it and reopen with updated content
            //
            const isPopupOpen = this.layerlistFireflies[id].isPopupOpen();
            if (isPopupOpen) {
              this.layerlistFireflies[id].closePopup();
              this.layerlistFireflies[id]
                .getPopup()
                // https://leafletjs.com/reference-1.7.1.html#popup-setcontent
                .setContent(fireflyMarkerPopup);
              this.layerlistFireflies[id].openPopup();
            } else {
              this.layerlistFireflies[id]
                .getPopup()
                // https://leafletjs.com/reference-1.7.1.html#popup-setcontent
                .setContent(fireflyMarkerPopup);
            }
          }
          //??????????????????????
        } else if (feature.properties.area === mineLevelId) {
          //console.log("FF_UPDATE -> ADD TO LAYER LIST ...", id);
          // if it's not in the layer list, and is in the area i.e. `mineLevelId` add it..........
          //
          // Note - pointToLayer and onEachFeature are defined in ComponentDidMount() and
          // .addData() runs `onEachFeatureFirefly` which adds the feature to layerlistFireflies[]

          this.geoJSONGroupLayer.addData(geoJSONMarkersData.features[idx]);
        }
      });

      // after processing the list send a delete processed items from the update list
      this.props.fireflyIdsUpdateListDeleteId(updatedFireflyIds);

      if (DEFAULT_SHOW_FIREFLY_POLYLINE_GROUP_LAYER) {
        // After update firefly, recalculate all polylines.
        // Delete the control layer, delete the layers from the map.
        // Re-build both map and control layers.
        //
        // #REVIEW
        // - could make this more efficient by tracking which FFs have changed

        for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
          this.controller.removeLayer(value);
          this.map.removeLayer(value);
        }

        this.polylineGroupLayers = {};

        // step through the list of locationGroupLayers
        for (const [key, value] of Object.entries(this.locationGroupLayers)) {
          // create an object with the posObject[position] = latlng coordinates
          let posObject = {};

          // loop each layer - #REVIEW - this could be labourious - perhaps only do if selected to display?
          // eslint-disable-next-line no-loop-func
          value.eachLayer(function (layer) {
            posObject[layer.feature.properties.position] = layer.getLatLng();
          });

          // sort the posObject by position order (i.e. object key) so line draws from position 1 -> n

          // see - https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
          const ordered = {};
          Object.keys(posObject)
            .sort()
            .forEach(function (key) {
              ordered[key] = posObject[key];
            });

          // plot a polyline using ordered array of values
          // create name object from layer name (i.e. key is the POSITION)
          const layerName = "poly_" + key;
          this[layerName] = L.polyline(Object.values(ordered)).addTo(this.map); // #REVIEW <---- ENABLE THIS TO PUT CABLES ON

          // add this layer to layer group to add to control layer later
          this.polylineGroupLayers[layerName] = this[layerName];
        }

        // #NOTE
        // ..this will need to  be updated for the groupedLayer control
        //
        //
        // add group to overlays by iterating over the object
        // https://stackoverflow.com/questions/55471295/adding-multiple-overlays-to-leaflets-layers-control
        for (const [key, value] of Object.entries(this.polylineGroupLayers)) {
          this.controller.addOverlay(value, key, "Cables"); // #REVIEW <---- ENABLE THIS TO PUT CABLES ON
        }
      }

      // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    }
  }

  updateFireflyForcedMarkersGeoJSON(
    map,
    geoJSONMarkersDataForced,
    prevGeoJSONMarkersDataForced,
    mineLevelId,
    fireflyIdsUpdateList
  ) {
    // FORCED light data is different to Firefly data. We display _ALL_ of the geoJSONMarkersDataForced
    // with a tooltip. There is not need to e.g. `this.layerlistFirefliesForced[id].unbindTooltip();`
    //  unbind the tooltip - we just remove the layer completely.
    //

    if (geoJSONMarkersDataForced.features.length < 1) {
      // if no data, reinitialise layer list
      this.layerlistFirefliesForced = {};
      // remove all layers
      if (this.geoJSONGroupLayerFireflyForcedTooltips) {
        this.geoJSONGroupLayerFireflyForcedTooltips.eachLayer((layer) => {
          this.geoJSONGroupLayerFireflyForcedTooltips.removeLayer(layer);
        });
      }
    }
    // if there is data in `geoJSONMarkersDataForced`
    else {
      // create a new the force lights layer list
      let newLayerlist = {};

      // compare the keys in the layer list with the new data
      const prevLayerlistFirefliesForcedIds = Object.keys(
        this.layerlistFirefliesForced
      );
      const geoJSONMarkersDataForcedIds = geoJSONMarkersDataForced.features.map(
        (feature) => feature.id
      );
      // check previous layers
      prevLayerlistFirefliesForcedIds.forEach((id) => {
        // to see if this is still valid data
        if (geoJSONMarkersDataForcedIds.includes(id)) {
          // if so, keep it
          newLayerlist[id] = this.layerlistFirefliesForced[id];
        }
        // if not, delete it from current layer group
        else {
          this.geoJSONGroupLayerFireflyForcedTooltips.removeLayer(
            this.layerlistFirefliesForced[id]
          );
        }
      });
      // now, update the layer list. We have removed old data.
      this.layerlistFirefliesForced = newLayerlist;

      // At this point we have `this.layerlistFirefliesForced` containing
      // all current Force markers.
      // This leaves the task to add those which are new....
      //
      // step through `geoJSONMarkersDataForced` to update ....
      geoJSONMarkersDataForced.features.map((feature, idx) => {
        const { properties } = feature;
        const { id, location, position, forced } = properties;

        // if `this.layerlistFirefliesForced[id]` does not exist for this Force,
        // and area is correct -> addData()
        if (
          !this.layerlistFirefliesForced[id] &&
          feature.properties.area === mineLevelId
        ) {
          // if it's not in the layer list, and is in the area i.e. `mineLevelId` add it..........
          //
          // Note - pointToLayer and onEachFeature are defined in ComponentDidMount() and
          // .addData() runs `onEachFeatureFireflyForcedToolTips` which adds the feature to layerlistFirefliesForced[]

          this.geoJSONGroupLayerFireflyForcedTooltips.addData(
            geoJSONMarkersDataForced.features[idx]
          );
        }
      });
    }
  }

  // WIP
  updateControllerMarkersGeoJSON(
    map,
    geoJSONControllers,
    prevJSONControllers,
    mineLevelId,
    forceUpdate
  ) {
    // map the geoJSONControllers and delete all the markers who have changed propertie
    // and accumulate these markers and
    // add them all at the end

    // convert previous marker data to keyed object
    let prevMarkerData = {};
    if (prevJSONControllers?.features !== undefined) {
      prevJSONControllers.features.map((feature, idx) => {
        const {
          properties: { id },
        } = feature;
        prevMarkerData[id] = feature;
      });
    }

    // find the fireflies which have changed in geojsonMarkerOptions object
    geoJSONControllers.features.map((feature, idx) => {
      const { properties } = feature;
      const {
        id,
        area,
        location,
        position,
        easting,
        northing,
        utm_zone_number,
        utm_zone_letter,
        z,
        color,
        timestamp,
        mode,
        master,
        //
        deviceStatus,
        user_relay,
      } = properties;

      let isChanged = !_isEqual(
        omitDeep(prevMarkerData[id], ["timestamp"]),
        omitDeep(feature, ["timestamp"])
      );

      // console.log(`MARKER_REDRAW isChanged`, isChanged);
      // if (isChanged) {
      //   console.log(`MARKER_REDRAW prevMarkerData[id]`, prevMarkerData[id]);
      //   console.log(`MARKER_REDRAW feature`, feature);
      // }

      const lastStatusReport =
        timestamp !== undefined
          ? formatRelative(parseISO(timestamp), new Date(), {
              includeSeconds: true,
            })
          : "-";
      const { geometry } = feature;

      // coord geom is reversed
      const X = round(geometry.coordinates[0], 2);
      const Y = round(geometry.coordinates[1], 2);

      // status checks
      let status = "OK"; // lets assume everything is OK. Ever the optimist eh?!

      if (deviceStatus.includes(StatusEnum.API_FETCH)) {
        status = "API_FETCH";
      }

      if (
        deviceStatus.includes(StatusEnum.NO_STATUS_REPORT) ||
        deviceStatus.includes(StatusEnum.TIMEOUT)
      ) {
        status = "INACTIVE_CONTROLLER";
      }

      const isSirenActive = isKthBitSet(user_relay, 1);
      if (isSirenActive) {
        status = "RELAY_SIREN";
      }

      // _#DEMO_MODE - force regular updates irrespective of real changes
      if (!isSirenActive && isDemoMode()) {
        status = "OK";
      }

      // console.log(`MARKER_REDRAW isChanged`, isChanged);
      // console.log(`MARKER_REDRAW status !== "OK"`, status !== "OK");
      // console.log(`MARKER_REDRAW forceUpdate`, forceUpdate);

      if (this.layerlistControllers[id]) {
        if (isChanged || status !== "OK" || forceUpdate) {
          // controller color is set but whether there are faults and mode settings
          let upsFaultmsg = this.props.faults;

          //console.log("upsFaultmsg", upsFaultmsg);
          const isUpsNetworkFault = upsFaultmsg.some(
            (fault) =>
              fault.type === "network" &&
              fault.active &&
              fault.id === id &&
              fault.area === area
          );

          // #Note - this is probably overkill to detect battery ON for controller
          // but making sure!
          const isUpsBatteryOnFault = upsFaultmsg.some(
            (fault) =>
              fault.type === "battery" &&
              fault.active &&
              fault.subtype === "charge" &&
              fault.description.includes("ON") &&
              fault.id === id &&
              fault.area === area
          );

          // ####SVG Marker###
          // default
          let svgIcon = "SquareMarker";
          let svgStyle = { stroke: "grey", fill: "grey" };

          // CONTROLLER UPS MODE SETTINGS
          // * isInCommission
          // * isMaster
          const isInCommission = mode.toLowerCase() === "commission";
          const isMaster = master ? true : false;

          // isInCommission - change icon
          isInCommission
            ? (svgIcon = "StarMarker")
            : (svgIcon = "SquareMarker");

          // isMaster - change color
          switch (isMaster) {
            case true:
            default:
              svgStyle = { stroke: "blue", fill: "blue" };
              break;
            case false:
              svgStyle = { stroke: "brown", fill: "brown" };
              break;
          }

          // CONTROLLER UPS FAULT SETTINGS
          // * isUpsNetworkFault
          // * isUpsBatteryOnFault
          // override color if network fault &/or battery fault
          // ups icon normal = blue/blue
          // network fault = outline red, fill blue, SquareMarker
          // battery fault = outline red, fill orange, DiamondMarker

          if (isUpsNetworkFault) {
            if (isUpsBatteryOnFault) {
              svgIcon = "DiamondMarker";
              svgStyle = { stroke: "red", fill: "orange" };
            } else {
              svgIcon = "SquareMarker";
              svgStyle = { stroke: "blue", fill: "red" };
            }
          } else {
            if (isUpsBatteryOnFault) {
              svgIcon = "DiamondMarker";
              svgStyle = { stroke: "blue", fill: "orange" };
            }
          }

          // #NOTE
          // #WIP
          // Status switch is used for firefly. The checks above should be moved
          // into the case below.

          switch (status) {
            case "RELAY_SIREN":
              svgIcon = "RelaySirenMarker";
              break;
            case "API_FETCH":
              svgIcon = "TriangleMarker";
              break;

            case "INACTIVE_CONTROLLER": // aka UPS_TIMEOUT
              svgStyle = { stroke: "fuchsia", fill: "black" };
              svgIcon = "HourGlassMarker";
              break;
            default:
              break;
          }

          const currentZoom = this.map.getZoom();

          this.layerlistControllers[id].setIcon(
            makeIcon(svgIcon, svgStyle, currentZoom)
          );

          //console.log(`CONTROLLER MARKER_REDRAW ID `, id);

          // update popup content
          if (false) {
            this.layerlistControllers[id].getPopup().setContent(
              `<div><strong>Controller: </strong><a href="/admin/controller/${id}">${id}</a></div>
          <hr/>
          <div>${this.testExternalStr} ${area} </div>
         <div><strong>Geom: </strong> E:${round(easting, 2)} N:${round(
                northing,
                2
              )} Z${Math.trunc(z)} ${utm_zone_number} ${utm_zone_letter}  </div>
         <div><strong>Image: </strong>X:${X} Y:${Y} </div>
         <div><strong>Color: </strong>${color} </div>
         <div><strong>Last Report: </strong>${lastStatusReport} </div>`
            );
          } else {
            const controllerMarkerPopup = renderToString(
              <ControllerMarkerPopup
                id={id}
                data={{
                  id,
                  easting,
                  northing,
                  X,
                  Y,
                  z,
                  utm_zone_number,
                  utm_zone_letter,
                  color,
                  location,
                  lastStatusReport,
                  user_relay,
                  role: this.props.role,
                }}
              />
            );

            // Update popup content
            // If it is open close it and reopen with updated content
            //
            const isPopupOpen = this.layerlistControllers[id].isPopupOpen();
            if (isPopupOpen) {
              this.layerlistControllers[id].closePopup();
              this.layerlistControllers[id]
                .getPopup()
                // https://leafletjs.com/reference-1.7.1.html#popup-setcontent
                .setContent(controllerMarkerPopup);
              this.layerlistControllers[id].openPopup();
            } else {
              this.layerlistControllers[id]
                .getPopup()
                .setContent(controllerMarkerPopup);
            }
          }
        }
      } else if (properties.area === mineLevelId) {
        // if it's not in the layer list, and is in the area i.e. `mineLevelId` add it..........
        //
        // Note - pointToLayer and onEachFeature are defined in ComponentDidMount() and
        // .addData() runs `onEachFeatureController` which adds the feature to layerlistControllers[]
        this.geoJSONControllerGroupLayer.addData(
          geoJSONControllers.features[idx]
        );
      }
    });
  }

  updateNamedAreasGeoJSON(map, geoJSONPolygonData, mineLevelId) {
    // #TODO - !
    // map the geoJSONPolygonData and delete all the shapes who have changed propertie
    // and accumulate these shapes
    // add them all at the end

    // check if a named area has been removed
    // get list of IDs for current named area (i.e. layers)
    const layersIds = Object.keys(this.layerlistNamedAreas);

    // get the Ids for the incoming update of named area data
    let namedAreaIds = [];
    for (const namedArea in geoJSONPolygonData.features) {
      namedAreaIds.push(geoJSONPolygonData.features[namedArea].properties.id);
    }

    // check for a difference
    // filter each item and return it if it is *not* in the namedAreas
    const inLayerNotInNamedArea = layersIds.filter(
      (i) => !namedAreaIds.includes(i)
    );

    inLayerNotInNamedArea.forEach((layer, idx) => {
      // delete any layers which are in layersIds but *not* in namedAreaIds
      // remove the layer from layer groups
      this.editableLayers.removeLayer(this.layerlistNamedAreas[layer]);
      this.geoJSONNamedAreaGroupLayer.removeLayer(
        this.layerlistNamedAreas[layer]
      );
      // to be really sure remove it from the map completely
      this.map.removeLayer(this.layerlistNamedAreas[layer]);
      // delete the layer from the list
      delete this.layerlistNamedAreas[layer];
      // update localMapstate
    });

    // find the named areas which have changed in geoJSONPolygonData object
    geoJSONPolygonData.features.map(({ properties }, idx) => {
      const {
        id,
        area,
        parent,
        type,
        name,
        parent_name,
        sub_type = "region",
      } = properties;

      //console.log("geoJSONPolygonData properties", properties);

      // if the shape is not in the layer list
      if (!this.layerlistNamedAreas[id]) {
        // add it
        // note pointToLayer and onEachFeature are defined in ComponentDidMount() <--- #REVIEW - is it? i.e. for namedArea?

        this.geoJSONNamedAreaGroupLayer.addData(
          geoJSONPolygonData.features[idx]
        );
      } else {
        // if the shape exists but has changed, update it

        // #WIP - should also review updates to shape coordinates

        // AFAIK the only way to update is to go through every layer until finding the match!
        // thankfully there are not many layers for namedAreas
        this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
          if (layer === this.layerlistNamedAreas[id]) {
            layer.feature.properties = properties;
          }
        });
      }

      // create/update a union of named area polygon shapes
      if (
        type !== "LEVEL_WIDE" &&
        type !== "ALL_AREAS" &&
        area === mineLevelId
      ) {
        // console.log("union ----------------------");
        // console.log("union parent, type", parent, type, area);

        const parentsUnionGroupLayersCount = Object.keys(
          this.parentsUnionGroupLayers
        ).length;

        const parentName =
          parent_name.length > 0 ? parent_name : parent.replace(`${area}:`, "");

        // #NOTE - VERY USEFUL DEBUG. DO NOT DELETE
        if (DEBUG_ENABLED_FALSE) {
          console.log(
            "union parentName, parentsUnionGroupLayersCount, layerlistNamedAreas",
            parentName,
            parentsUnionGroupLayersCount,
            this.layerlistNamedAreas
          );
        }

        // if the shape is not in the parentsUnionGroupLayers layer list or the layerlist count has changed
        if (
          !this.parentsUnionGroupLayers[parentName] ||
          parentsUnionGroupLayersCount !==
            this.state.parentsUnionGroupLayersCount
        ) {
          // add it
          for (const [key, value] of Object.entries(this.layerlistNamedAreas)) {
            //            console.log("union key ", key);

            const {
              feature: {
                properties: { type, area, name, sub_type },
              },
            } = value;

            if (DEBUG_ENABLED_FALSE) {
              console.log(
                "union this.layerlistNamedAreas -> area, name, type, sub_type",
                area,
                name,
                type,
                sub_type
              );
            }

            if (
              type !== "LEVEL_WIDE" &&
              type !== "ALL_AREAS" &&
              area === mineLevelId &&
              key.includes(parent)
            ) {
              // make a layer  group per parent
              if (this[parent] === undefined) {
                this[parent] = new L.LayerGroup();
              }

              value.addTo(this[parent]);
            }
          }

          // #NOTE - VERY USEFUL DEBUG. DO NOT DELETE
          if (DEBUG_ENABLED_FALSE) {
            console.log(
              "union unify paret -> this[parent].getLayers",
              parent,
              this[parent].getLayers()
            );
          }

          let layer = unify(this[parent].getLayers());
          const namedAreaPolygonPopup = renderToString(
            <NamedAreaPolygonPopup
              id={id}
              data={{
                parent,
                parentName,
                sub_type,
              }}
            />
          );
          layer.bindPopup(namedAreaPolygonPopup);
          layer.on("popupclose", (event) => {
            this.setState({ isPopupOpen: false });
          });
          layer.on("popupopen", (event) => {
            // #WIP - disable popup open re-rendering?
            if (false) {
              this.setState({ isPopupOpen: true });
            }

            //console.log(`namedAreaPolygonPopup event`, event);

            var namedAreaParent = event.popup._source.feature.properties.parent;
            var popUp = event.target.getPopup();

            // added eventListener to controller marker content
            // see - src/components/Map/NamedAreaPolygonPopup.js
            popUp
              .getElement()
              .querySelector(".popupNamedAreaPolygon")
              .addEventListener("click", (e) => {
                console.log(
                  "clicked edit on polygon: ",
                  e,
                  "target: ",
                  e.target,
                  "dataid ",
                  e.target.getAttribute("dataid")
                );

                const id = e.target.getAttribute("dataid");
                if (!_isEmpty(id)) {
                  this.props.goto(`/admin/named-area/${id}`);
                } else {
                  this.props.goto(`/admin/named-area/`);
                }
              });

            // *************************
          });
          // #NOTE - VERY USEFUL DEBUG. DO NOT DELETE
          if (DEBUG_ENABLED_FALSE) {
            console.log("union unify layer", layer);
          }
          this.parentsUnionGroupLayers[parentName] = layer;
        } else {
          // update?
        }
      }
    });

    // update leaflet id to properties (used to delete.edit shapes in local state) <--- #REVIEW - is it? may not be necessary now
    this.geoJSONNamedAreaGroupLayer.eachLayer((layer) => {
      layer.feature.properties._leaflet_id = layer._leaflet_id;
      layer.bindPopup(`<div>geoJSONNamedAreaGroupLayer</div>`);
      //console.log(`geoJSONNamedAreaGroupLayer`, layer);
    });
  }

  updateNamedAreaParentsGeoJSON(map, geoJSONPolygonData, mineLevelId) {
    // find the named areas which have changed in geoJSONPolygonData object
    geoJSONPolygonData.features.map((feature, idx) => {
      const { properties } = feature;
      const { id, area, name } = properties;

      if (this.map.hasLayer(this[name])) {
      } else if (properties.area === mineLevelId) {
        this[name] = L.featureGroup(); //.addTo(this.map);
        this.regionGroupLayers[name] = this[name];
      }

      // if the shape is not in the layer list
      if (this.layerlistNamedAreaParents[id]) {
        // #WIP update it .............
        //
        // Leave this space for potential updates - what?
        //
        //
        //
      } else if (properties.area === mineLevelId) {
        // AFAIK the only way to update is to go through every layer until finding the match!
        // thankfully there are not many layers for namedAreas
        // this.geoJSONNamedAreaParentGroupLayer.eachLayer((layer) => {
        //   if (layer === this.layerlistNamedAreaParents[id]) {
        //     layer.feature.properties = properties;
        //   }
        // });
        this.geoJSONNamedAreaParentGroupLayer.addData(
          geoJSONPolygonData.features[idx]
        );
      }
    });

    // // update leaflet id to properties (used to delete.edit shapes in local state) <--- #REVIEW - is it? may not be necessary now
    // this.geoJSONNamedAreaParentGroupLayer.eachLayer((layer) => {
    //   layer.feature.properties._leaflet_id = layer._leaflet_id;
    // });
  }

  // heartBeat = () => {
  //   const heartBeat = this.state.heartBeat;
  //   //console.log("heartBeat", heartBeat);
  //   this.setState({ heartBeat: !heartBeat });
  // };

  render() {
    const { render, showMap, mineLevel = {} } = this.props;
    const { isMapLoading } = this.state;

    const mapId = "map-" + render;

    const { legend: isShowLegend } = this.props.namedAreaDisplaySettings;

    const visibility =
      showMap !== true ? { display: "none" } : { display: "block" };

    const showLegend = isShowLegend
      ? undefined
      : { minHeight: "calc(95vh - 320px)" };

    const {
      image_info: { backgroundColor },
    } = mineLevel;

    const bgColor = backgroundColor
      ? `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`
      : "green";

    return (
      <>
        <div
          className={"leafletMapContainer"}
          style={{
            ...showLegend,
            ...visibility,
          }}
        >
          <div className={"leafletMapContainerDiv"}>
            <div
              className={"leafletMap"}
              style={{ backgroundColor: bgColor }}
              id={mapId}
            />
            {/* <div id="custom-map-controls"></div> */}
          </div>
        </div>

        {/* testing loading image by having image in a separte part of the dom */}
        {/* <img
          style={{ display: "none" }}
          src={this.state.mapSrc}
          onLoad={() => {
            console.log(`loaded <img> image `, this.state.mapSrc);
            this.setState({ isMapLoaded: true });
          }}
        /> */}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const mineLevel = getAreaById(state, props.mineLevelId);

  const transformPixelsToUtm = mineLevel?.transformPixelsToUtm;

  const markersData = getMapState(state);

  // Firefly coordinates
  let geoJSONMarkersDataUtm = getFireflyCoordinatesByAreaId(
    state,
    props.mineLevelId
  );

  // #DEBUG - setup empty layer
  //geoJSONMarkersDataUtm = [];

  // Forced Firefly coordinates
  let geoJSONMarkersDataUtmForced = getFireflyCoordinatesForcedByAreaId(
    state,
    props.mineLevelId
  );

  // #DEBUG - setup empty layer
  geoJSONMarkersDataUtmForced = [];

  // Firefly update changes
  const fireflyIdsUpdateList = getFireflyIdsUpdateListByArea(
    state,
    props.mineLevelId
  );

  // Firefly deletetions
  const fireflyIdsDeleteList = getFireflyIdsDeleteListByArea(
    state,
    props.mineLevelId
  );

  // Controller coordinates
  const geoJSONControllersUtm = getControllerCoordinatesByAreaId(
    state,
    props.mineLevelId
  );

  // Named Area polygons
  let geoJSONNamedAreasUtm = getNamedAreaInfos(state);

  // #DEBUG - setup empty layer
  //geoJSONNamedAreasUtm = [];

  let geoJSONNamedAreaParentsUtm = getNamedAreaParentInfos(state);

  // #DEBUG - setup empty layer
  //geoJSONNamedAreaParentsUtm = [];

  const namedAreaDeleteSelections = getNamedAreaDeleteSelections(state);
  const namedAreaHideSelections = getNamedAreaHideSelections(state);
  const namedAreaDisplaySettings = getNamedAreaDisplaySettings(state);

  const localMapState = GetLocalMap(state);
  const isDirty = getIsDirty(state);

  const faults = getFaults(state) || [];

  const regionPreview = getRegionPreviewListByAreaId(state, props.mineLevelId); // { spec, type, name, namedArea };

  // check for image files
  const folderFilesList = getFolderFileNamesListById(state, "areas");

  // message banner support
  const { messageBanners } = state.alarmButton;

  // deviceTimestamps for checking timeout

  const timedOutDevices = getTimedOutDevices(state);

  const isPokeTheWorker = isOnOffPokeTheWorker(state);

  const namedAreaEvents = getNamedAreaEventsByAreaId(state, props.mineLevelId);

  // user roles

  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);

  // map image from state
  // this has previously been loaded and saved to the Settings reducer state
  const mapImageFromState = getMapImageById(state, props.mineLevelId);

  return {
    mineLevel,
    markersData,
    geoJSONMarkersDataUtm,
    geoJSONMarkersDataUtmForced,
    localMapState,
    geoJSONNamedAreasUtm,
    namedAreaDeleteSelections,
    namedAreaHideSelections,
    isDirty,
    namedAreaDisplaySettings,
    geoJSONControllersUtm,
    //
    faults,
    geoJSONNamedAreaParentsUtm,
    regionPreview,
    //
    fireflyIdsUpdateList,
    fireflyIdsDeleteList,
    folderFilesList,
    //
    messageBanners,
    //
    timedOutDevices,
    //
    isPokeTheWorker,
    //
    namedAreaEvents,
    //
    transformPixelsToUtm,
    // user roles
    role: {
      allowGuest: userGuest,
      allowOperator: userSuper || userAdmin || userUser,
      allowAdmin: userSuper || userAdmin,
      allowSuper: userSuper,
    },
    //
    // map image from state
    mapImageFromState,
  };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateLocalMap: (mapState) => {
    dispatch(UpdateLocalMap(mapState));
  },
  namedAreaClearDeleteSelections: (parentId) => {
    dispatch(namedAreaClearDeleteSelections(parentId));
  },
  namedAreasSetIsDirty: (id) => {
    dispatch(namedAreasSetIsDirty(id));
  },
  mqttPublish: (mqttMsg) => {
    dispatch(mqttPublish(mqttMsg));
  },
  goto: (path) => dispatch(push(path)),
  fireflyIdsUpdateListDeleteId: (id) =>
    dispatch(fireflyIdsUpdateListDeleteId(id)),
  // flash message support
  clearFlashMessages: () => {
    dispatch(clearFlashMessages());
  },
  removeFlashMessage: (id) => {
    dispatch(removeFlashMessage(id));
  },
  addWarningFlashMessageIdColor: (id, color, header, message) => {
    dispatch(addWarningFlashMessageIdColor(id, color, header, message));
  },
  SetMessageBanner: (messageState) => {
    dispatch(SetMessageBanner(messageState));
  },
  TurnOnOffPokeTheWorker: (action) => {
    dispatch(TurnOnOffPokeTheWorker(action));
  },
  SetMapMoving: (bool) => dispatch(SetMapMoving(bool)),
  SetMapMarkerDragging: (bool) => dispatch(SetMapMarkerDragging(bool)),

  //
  saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)),
  deleteNamedArea: (id) => {
    dispatch(deleteNamedArea(id));
  },
  //
  // see - src/pages/ControlRoomPage/PrecannedOperations.js
  activate: (operation) => dispatch(activateNamedAreaEventSingle(operation)),
  cancel: (operation) => dispatch(cancelNamedAreaEvent(operation)),
  waitEventTimeOut: (action) => dispatch(waitEventTimeOut(action)),
  saveFirefly: (data) => dispatch(saveFirefly(data)),
  saveUPS: (data) => dispatch(saveUPS(data)),
  //
  // region preview
  addAreaPreview: (area) => dispatch(addAreaPreview(area)),
  removeAreaPreview: (area) => dispatch(removeAreaPreview(area)),

  saveUserSettingsComponentState: (data) =>
    dispatch(saveUserSettingsComponentState(data)),
});

export default withComponentStateCache(
  connect(mapStateToProps, mapDispatchToProps)(Map)
);
