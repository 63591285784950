import React from 'react'
import { Route } from 'react-router-dom'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Switch } from 'react-router-dom'
import { getMineLevelByNameSlug } from 'MineLevels/reducer'

import MineLevelSeismicLayerSummaryPage from 'pages/MineLevelSeismicLayerSummaryPage'
import CreateSeismicOverlayPage from 'pages/CreateSeismicOverlayPage'
import MineLevelStatusPage from 'pages/MineLevelStatusPage'
import PageNotImplemented from 'components/PageNotImplemented'

import { MineLevelContext } from 'ApplicationContexts'

function LevelSpecificPage ({ ...props }) {
  const { error, match, level } = props
  if (error) {
    return <div>{error}</div>
  }

  // NB: This is a bit ugly to have the MineLevel context stuff tied up with the route stuff
  // but hopefully there is a better way?
  return (
    <MineLevelContext.Provider value={level}>
      <Switch>
        <Route
          path={`${match.url}/create-seismic`}
          component={CreateSeismicOverlayPage}
        />
        <Route
          path={`${match.url}/seismic-list`}
          component={MineLevelSeismicLayerSummaryPage}
        />
        <Route path={`${match.url}/status`} component={MineLevelStatusPage} />
        <Route component={PageNotImplemented} />
      </Switch>
    </MineLevelContext.Provider>
  )
}

export function mapStateToProps (state, ownProps) {
  const match = ownProps.match
  const nameSlug = match.params.nameSlug
  const level = getMineLevelByNameSlug(state, nameSlug)
  return {
    level,
    match,
    error: level ? undefined : 'No level for slug'
  }
}

export default withRouter(connect(mapStateToProps)(LevelSpecificPage))
