import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import { getServerStatus } from "components/Settings/reducer";

export class DeleteButton extends Component {
  state = { open: false };

  clicked = (e) => {
    const { onClick, id } = this.props;
    onClick(id);
    this.close();
  };
  close = () => this.setState({ open: false });
  open = () => this.setState({ open: true });

  render() {
    const { open } = this.state;
    const { serverStatus, strings, size = "large" } = this.props;
    return (
      <Modal
        open={open}
        onOpen={this.open}
        onClose={this.close}
        trigger={
          <Button
            color="red"
            onClick={(e) => {
              e.preventDefault(); // Stop any form validation
              this.open();
            }}
            disabled={serverStatus !== "OK"}
            size={size}
          >
            {strings?.["Delete"] || "Delete"}
          </Button>
        }
        basic
        size="small"
      >
        <Header
          icon="trash"
          content={strings?.["Delete Button Message Header"]}
        />
        <Modal.Content>
          <p>{strings?.["Delete Button Message Content"]}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button inverted onClick={this.close}>
            <Icon name="close" />
            {strings?.["No"]}
          </Button>
          <Button color="red" inverted onClick={this.clicked}>
            <Icon name="checkmark" />
            {strings?.["Yes"]}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    serverStatus: getServerStatus(state),
  };
};

DeleteButton = connect(mapStateToProps, null)(DeleteButton);
