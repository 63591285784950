export function UpdateMqttMsg(mqttMsg) {
  return { type: "MQTT_MSG_UPDATE", payload: mqttMsg };
}

export function ClearMqttMsg() {
  return { type: "MQTT_MSG_CLEAR" };
}

// #NOTE - same as above.
// Transitioning from "mqttMsg" to "faults"
export function ClearFaults() {
  return { type: "MQTT_MSG_CLEAR" };
}
