import React, { Component } from "react";
import L from "leaflet";
//import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";
//import "leaflet.awesome-markers/dist/leaflet.awesome-markers";

import { toWebColor } from "utils/toWebColor";
import { markerSize as IconMarkerSize } from "components/ConfigJs";

// TESTING - pulsating effect
// #NOTE - 7Dec21 - having this present (and the icon var defined)
// cause major re-rendering with "Recalculate Style" issues on the freeport bomgar Chrome jump point demo.
//
//import "components/Map/leafletIconPulse.css";
//import "components/Map/leafletIconPulse.js";

// TESTING -  MARIO
//import "components/Map/mario_1.scss";
//import "components/Map/mario_1.js";

import "components/Map/mario_2.css";

// TESTING - SVG icons from illustrator

import {
  hackedLoaderPng,
  hackedJumboPng,
  hackedAmbulancePng,
  hackedLightVehiclePng,
  hackedTruckPng,
} from "components/Map/MakeIconSvgIcons.js";

// #NOTE - Instructions on how to make SVG icons.
//
// Open boxy svg (see - https://boxy-svg.com/)
//
// Create (from new or open existing & mod) an SVG.
// Save to /icon/filename.svg e.g. triangle.svg
// Open svg file with text editor.

// e.g.

// <?xml version="1.0" encoding="utf-8"?>
// <svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
//   <polygon style="stroke: rgb(0, 0, 0); stroke-width: 20px; fill: rgb(255, 255, 255);" points="300.4939880371094 22.224000930786133 583.0770263671875 591.156005859375 14.666999816894531 588.3660278320312" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -1.223744, 1.228757)"/>
//   <polygon style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" points="471.959 592.867" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -2.419402, 1.934086)"/>
//   <polygon style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" points="290.088 21.091" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -0.083786, 1.185912)"/>
// </svg>

// Save to object file in js.
// Make sure width and height are correct since all paths are relative to these values.

// Replace fill and stroke variables.

// e.g.

// if (name === "triangle") {
//   icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
// <polygon style="stroke: ${strokeColor}; stroke-width: 20px; fill: ${fillColor};" points="300.4939880371094 22.224000930786133 583.0770263671875 591.156005859375 14.666999816894531 588.3660278320312" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -1.223744, 1.228757)"/>
// <polygon style="fill: ${fillColor}; stroke: ${strokeColor};" points="471.959 592.867" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -2.419402, 1.934086)"/>
// <polygon style="fill: ${fillColor}; stroke: ${strokeColor};" points="290.088 21.091" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -0.083786, 1.185912)"/>
// </svg>`;
// }

const { marker: markerSize, tag: tagSize } = IconMarkerSize();

// icons
// inspired by
// https://stackoverflow.com/questions/51850249/leaflet-js-too-slow-with-custom-svg-markers-and-a-lot-of-points
export const makeIcon = (name, style, zoom) => {
  const { stroke, fill, text = "", opacity = "1" } = style;

  const strokeColor = toWebColor(stroke);
  const fillColor = toWebColor(fill);

  // console.log(
  //   `makeIcon name strokeColor fillColor `,
  //   name,
  //   stroke,
  //   fill,
  //   " -- ",
  //   strokeColor,
  //   fillColor
  // );

  let icon;
  let iconClass = null;

  // #TODO - change to switch
  //
  // switch (name) {
  //   case "diamond":
  //     icon =
  //       "<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20' height='20'> " +
  //       "<path stroke=" +
  //       "'" +
  //       strokeColor +
  //       "'" +
  //       " stroke-width='3' fill='none' " +
  //       " d='M10,1 5,10 10,19, 15,10Z'/></svg>";
  //     break;

  //   default:
  //     break;
  // }

  let isForced = true;
  const forcedIcon = `<path d="M 557.539 11.07 L 589.373 74.426 L 528.389 74.426 L 557.539 11.07 Z" style="stroke-width: 20px; paint-order: fill; fill: ${fillColor}; stroke: ${strokeColor};" />`;

  //const stdText_1 = `<text style="fill: rgb(0, 0, 0); font-family: &quot;Ubuntu Mono&quot;; font-size: 400px; text-anchor: middle; text-transform: uppercase; white-space: pre;" x="299.997" y="431.83">${text}</text>`;
  //const stdText_11 = `<text style="white-space: pre; fill: rgb(0, 0, 0); font-family: Arial, sans-serif; font-size: 330.4px;" x="128.852" y="398.944">${text}</text>`;

  const stdText_1 = (text) => {
    return `<text style="fill: rgb(0, 0, 0); font-family: &quot;Ubuntu Mono&quot;; font-size: 400px; text-anchor: middle; text-transform: uppercase; white-space: pre;" x="299.997" y="431.83">${text}</text>`;
  };
  const stdText_11 = (text) => {
    return `<text style="white-space: pre; fill: rgb(0, 0, 0); font-family: Arial, sans-serif; font-size: 330.4px;" x="128.852" y="398.944">${text}</text>`;
  };

  // if (["M","T","F","P"].includes(text)){

  // }

  // Based on http://www.smiffysplace.com/stars.html
  if (name === "6-pointed-star") {
    icon =
      "<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='28' height='28'> " +
      "<path stroke=" +
      "'" +
      strokeColor +
      "'" +
      " stroke-width='3' fill='none' " +
      " d='m13 13m0 5l5 3.66l-0.67 -6.16l5.67 -2.5l-5.67 -2.5l0.67 -6.16l-5 3.66l-5 -3.66l0.67 6.16l-5.67 2.5l5.67 2.5l-0.67 6.16z'/></svg>";
  }

  if (name === "CrossMarker") {
    // cross
    icon = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='400' height='400'> 
      <path fill='${fillColor}' stroke='${strokeColor}' stroke-width='40' d='M 160.014 0.066 H 239.987 V 160.013 H 399.934 V 239.987 H 239.987 V 399.934 H 160.014 V 239.987 H 0.067 V 160.013 H 160.014 Z' />
      </svg>`;
  }

  if (name === "Cross45Marker") {
    // cross45
    icon = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='512' height='512'> 
      <path fill='${fillColor}' stroke='${strokeColor}' stroke-width='45' d='m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z'/>
      </svg>`;
  }

  if (name === "OffMarker") {
    //round
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <circle style="fill: rgb(255, 255, 255); stroke-width: 50px; stroke: rgb(0, 0, 0, 0.4);" cx="299.069" cy="299.451" r="289.373" transform="matrix(0.823327, 0, 0, 0.817654, 60.158787, 47.563206)"/>
    <line style="stroke: rgb(0, 0, 0, 0.4); stroke-width: 50px;" x1="0" y1="600" x2="600" y2="0"/>
  </svg>`;
  }

  if (name === "RoundMarker") {
    //round
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <circle style="fill: ${fillColor}; stroke-width: 20px; stroke: ${strokeColor}; opacity: ${opacity}" cx="299.069" cy="299.451" r="289.373" transform="matrix(1, 0, 0, 0.999234, 0.930973, 0.99951)"/>
    ${text !== "" ? stdText_1(text) : null}
    </svg>`;
  }

  // #TESTING
  // #WIP - old version with overlaid 'forcedIcon'
  // - removed in favour of a normal marker with "F" overlaid
  // if (name === "RoundMarkerForced") {
  //   //round
  //   icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
  //   <circle style="fill: ${fillColor}; stroke-width: 50px; stroke: ${strokeColor};" cx="299.069" cy="299.451" r="289.373" transform="matrix(1, 0, 0, 0.999234, 0.930973, 0.99951)"/>
  //   ${isForced ? forcedIcon : null}
  // </svg>`;
  // }

  if (name === "RoundMarkerForced") {
    //round
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <circle style="fill: ${fillColor}; stroke-width: 20px; stroke: ${strokeColor};" cx="299.069" cy="299.451" r="289.373" transform="matrix(1, 0, 0, 0.999234, 0.930973, 0.99951)"/>
    ${stdText_1("F")}
    </svg>`;
  }

  const posnText =
    text < 10
      ? `<text style="white-space: pre; fill: rgb(51, 51, 51); font-family: Arial, sans-serif; font-size: 162.1px;" x="200.008" y="233.491">${text}</text>`
      : `<text style="white-space: pre; fill: rgb(51, 51, 51); font-family: Arial, sans-serif; font-size: 162.1px;" x="158.664" y="235.214">${text}</text>`;
  // TypicalMapMarker
  if (name === "TypicalNumberedMapMarker") {
    icon = `<svg width="485.213px" height="485.212px" viewBox="0 0 485.213 485.212" style="enable-background:new 0 0 485.213 485.212;" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
      <g>
  	<path d="M242.606,0C142.124,0,60.651,81.473,60.651,181.955s151.631,303.257,181.956,303.257
  		c30.326,0,181.955-202.775,181.955-303.257S343.089,0,242.606,0z M242.606,303.257c-66.9,0-121.302-54.433-121.302-121.302
  		S175.706,60.651,242.606,60.651c66.902,0,121.302,54.435,121.302,121.304S309.509,303.257,242.606,303.257z" style="fill: ${fillColor};"/>
  </g>
  ${posnText}
  </svg>`;
  }

  if (name === "SquareMarker") {
    // square
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <polygon style="stroke: ${strokeColor}; fill: ${fillColor}; paint-order: fill; stroke-width: 50px;" points="589.8709716796875 10.041999816894531 589.3300170898438 590.3909912109375 9.567000389099121 589.2429809570312 10.128999710083008 10.086999893188477"/>
    ${text !== "" ? stdText_1(text) : null}
  </svg>`;

    // #NOTE - to make SquareMarker blink enable the iconClass = "iconBlinking"
    if (false) {
      iconClass = "iconBlinking";
    }
  }

  if (name === "SquareMarkerHandle") {
    // square
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <polygon style="stroke: ${strokeColor}; fill: ${fillColor}; paint-order: fill; stroke-width: 50px;" points="589.8709716796875 10.041999816894531 589.3300170898438 590.3909912109375 9.567000389099121 589.2429809570312 10.128999710083008 10.086999893188477"/>
  </svg>`;

    iconClass = "iconEditable";
  }

  // rotated version of SquareMarker

  if (name === "DiamondMarker") {
    // square
    // Note `rotate(<45degrees> <half x> <half y>)`
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <polygon style="stroke: ${strokeColor}; fill: ${fillColor}; paint-order: fill; stroke-width: 50px;" points="300.28399658203125 585.822998046875 14.052000045776367 299.59100341796875 300.32000732421875 13.322999954223633 586.291015625 299.29400634765625"/>
    </svg>`;
  }

  // a true 'diamond' shape
  if (name === "DiamondMarker2") {
    //diamond
    icon =
      "<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20' height='20'> " +
      "<path stroke=" +
      "'" +
      strokeColor +
      "'" +
      " stroke-width='3' fill='none' " +
      " d='M10,1 5,10 10,19, 15,10Z'/></svg>";
  }

  if (name === "TravelwayMarker") {
    // travelway
    icon = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='600' height='600'>
        <path d='M 599.4149780273438 -0.0650000125169754 L 600.5850219726562 298.7650146484375 L 298.8299865722656 599.9349975585938 L -0.5849997997283936 298.7650146484375 L 0 -1.819000005722046 Z' fill='${fillColor}' stroke-width='50' stroke='${strokeColor}' />
        <path d='M 601.086 298.972 L 298.829 600.643 L -1.085 298.971 L -0.499 -2.32 L 599.913 -0.564 Z M 0.499 -1.318 L -0.085 298.559 L 298.831 599.227 L 600.084 298.558 L 598.917 0.434 Z' style=''/>
      </svg>`;
  }

  if (name === "StarMarker") {
    // star
    icon = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='600' height='600'>
       <polygon style='stroke: ${strokeColor}; stroke-width: 50px; fill: ${fillColor};' points='600 -1.753999948501587 447.9530029296875 298.8299865722656 600 599.4149780273438 299.4150085449219 456.1400146484375 -0.5849997997283936 599.4149780273438 146.19900512695312 298.8299865722656 146.19900512695312 298.8299865722656 0 0 299.4150085449219 149.1230010986328'/>
      </svg>`;
  }

  if (name === "HourGlassMarker") {
    // hourGlass
    icon = `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='600' height='600'>
      <polygon style='stroke: ${strokeColor}; fill: ${fillColor}; stroke-width: 50px;' points='600 -0.6500002145767212 469.82501220703125 129.52499389648438 300 301.6889953613281 0 0.5199997425079346'/>
      <polygon style='stroke: ${strokeColor}; fill: ${fillColor}; stroke-width: 50px;' points='599 603 468.825 472.825 299 300.661 -1 601.83'/>
      </svg>`;
  }

  if (name === "BowTieMarker") {
    // bowTie
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <polygon style="stroke: ${strokeColor}; fill: ${fillColor}; paint-order: fill; stroke-width: 50px;" points="727.64501953125 161.76100158691406 619.97998046875 280.67999267578125 450.1549987792969 452.843994140625 170.41400146484375 161.2429962158203" transform="matrix(0, 1, -1, 0, 751.830017, -148.479996)"/>
    <polygon style="stroke: ${strokeColor}; fill: ${fillColor}; paint-order: fill; stroke-width: 50px;" points="426.3340148925781 439.4639892578125 318.66900634765625 321.6700134277344 148.843994140625 149.50599670410156 -129.77200317382812 440.5459899902344" transform="matrix(0, 1, -1, 0, 449.518982, 151.831009)"/>
    </svg>`;
  }

  if (name === "TriangleMarker") {
    // triangle
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
  <polygon style="stroke: ${strokeColor}; stroke-width: 50px; fill: ${fillColor};" points="300.4939880371094 22.224000930786133 583.0770263671875 591.156005859375 14.666999816894531 588.3660278320312" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -1.223744, 1.228757)"/>
  <polygon style="fill: ${fillColor}; stroke: ${strokeColor};" points="471.959 592.867" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -2.419402, 1.934086)"/>
  <polygon style="fill: ${fillColor}; stroke: ${strokeColor};" points="290.088 21.091" transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -0.083786, 1.185912)"/>
  </svg>`;
  }

  if (name === "ForwardMarker") {
    // triangle
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
        <path d='M 589.104 9.755 L 590.274 294.346 L 300 586.678 L 9.235 294.346 L 9.878 8.059 L 589.104 9.755 Z'  fill='${fillColor}' stroke-width='50' stroke='${strokeColor}' transform='matrix(0, -1, 1, 0, 2.386022, 597.122979)'/>
        <path d='M 602.633 296.933 L 300.376 598.604 L 0.462 296.932 L 1.048 -4.359 L 601.46 -2.603 Z M 2.046 -3.357 L 1.462 296.52 L 300.378 597.188 L 601.631 296.519 L 600.464 -1.605 Z' transform='matrix(0, -1, 1, 0, 4.425004, 598.670016)'/>
    </svg>`;
  }

  if (name === "BackwardMarker") {
    // triangle
    icon = `<svg viewBox="0 0 600 600" width="600" height="600" xmlns="http://www.w3.org/2000/svg">
    <path d='M 589.104 9.755 L 590.274 294.346 L 300 586.678 L 9.235 294.346 L 9.878 8.059 L 589.104 9.755 Z' fill='${fillColor}' stroke-width='50' stroke='${strokeColor}' transform='matrix(0, 1, -1, 0, 597.122979, -2.386022)'/>
    <path d='M 599.047 297.425 L 296.79 599.096 L -3.124 297.424 L -2.538 -3.867 L 597.874 -2.111 Z M -1.54 -2.865 L -2.124 297.012 L 296.792 597.68 L 598.045 297.011 L 596.878 -1.113 Z' transform='matrix(0, 1, -1, 0, 595.576016, -0.347004)'/>
</svg>`;
  }

  if (name === "WorkerMarker") {
    //   icon = `<svg id="Icons" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M27,28.2c-0.5-2.8-2.5-5.1-5.1-5.8c-1.3-0.3-2.2-1.5-2.3-2.9c1.2-1,2.1-2.6,2.3-4.4c0-0.3,0-0.6-0.2-0.8S21.2,14,20.9,14  c-1.8,0-3.4-1-4.3-2.5c-0.2-0.3-0.5-0.5-0.9-0.5H11c-0.6,0-1,0.4-1,1v2c0,2.2,0.9,4.2,2.4,5.5c-0.2,1.4-1.1,2.6-2.5,3  c-2.5,0.7-4.4,2.9-5,5.7c-0.1,0.5,0,0.9,0.3,1.3C5.6,29.8,6,30,6.5,30h19.1c0.4,0,0.8-0.2,1.1-0.5C26.9,29.1,27.1,28.7,27,28.2z" style="fill: rgb(255, 165, 0);"/>
    //   <path d="M24,11h-0.2v-0.2c0-1.8-0.6-3.4-1.6-4.8l-0.7,2.5c-0.1,0.4-0.5,0.7-1,0.7c-0.1,0-0.2,0-0.3,0c-0.5-0.2-0.8-0.7-0.7-1.2  l1.2-4.1c0-0.3-0.2-0.6-0.5-0.8c-2.6-1.3-5.7-1.3-8.3,0c-0.3,0.2-0.5,0.5-0.5,0.8l1.2,4.1c0.2,0.5-0.2,1.1-0.7,1.2  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8-0.3-1-0.7L9.9,6c-1,1.4-1.6,3-1.6,4.8V11H8c-0.6,0-1,0.4-1,1s0.4,1,1,1h16c0.6,0,1-0.4,1-1  S24.6,11,24,11z" style="fill: rgb(255, 165, 0);"/>
    // </svg>`;

    icon = `<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xmlns="http://www.w3.org/2000/svg">
<g id="XMLID_1_">
  <path id="XMLID_5_" d="M256,85.6c23.3,0,42.8-18.6,42.8-42.8C298.8,19.5,279.3,0,256,0s-42.8,19.5-42.8,42.8S232.7,85.6,256,85.6z M298.8,94.9h-85.6c-32.6,0-53,27.9-53,54.9v129.3c0,25.1,35.4,25.1,35.4,0V160h6.5v326.5c0,34.4,48.4,33.5,49.3,0V297.7h9.3v187.9 c1.9,35.4,49.3,32.6,49.3,0V160h5.6v120c0,25.1,36.3,25.1,36.3,0V149.8C351.8,122.8,330.4,94.9,298.8,94.9z" style="fill: rgb(255, 165, 0);"/>
</g>
</svg>`;
  }

  // here's the trick, base64 encode the URL
  var svgURL = "data:image/svg+xml;base64," + btoa(icon);

  function between(x, min, max) {
    return x >= min && x <= max;
  }

  let scaleIcon = 6;

  // #NOTE - this seems ridiculous, but I wanted the option to cap out the size.

  // switch (true) {
  //   case zoom < -5:
  //     scaleIcon = 2;
  //     break;
  //   case zoom < -4:
  //     scaleIcon = 4;
  //     break;
  //   case zoom < -3:
  //     scaleIcon = 6;
  //     break;
  //   case zoom < -2:
  //     scaleIcon = 8;
  //     break;
  //   case zoom < -1:
  //     scaleIcon = 10;
  //     break;
  //   case zoom < 0:
  //     scaleIcon = 12;
  //     break;
  //   case zoom < 1:
  //     scaleIcon = 14;
  //     break;
  //   case zoom < 2:
  //     scaleIcon = 16;
  //     break;
  //   case zoom < 3:
  //     scaleIcon = 18;
  //     break;
  //   case zoom < 4:
  //     scaleIcon = 20;
  //     break;
  //   case zoom < 5:
  //     scaleIcon = 22;
  //     break;

  //   default:
  //     scaleIcon = 6;
  //     break;
  // }

  scaleIcon = Math.trunc(2 * zoom + 12); // trunc to integer

  if (name === "WorkerMarker") {
    scaleIcon = scaleIcon * 3; // #NOTE - worker should be bigger
  }

  // create default icon type
  var svgIcon = L.icon({
    iconName: name,
    iconUrl: svgURL,
    iconColor: { stroke: strokeColor, fill: fillColor, text },
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize],
    //shadowSize: [12, 10],
    //iconAnchor: [5, 5],
    //popupAnchor: [5, -5],
    //
    className: iconClass,
  });

  // ****************************************************
  //
  // TESTING - animated icons
  //
  //

  // currently supports circular icons only
  // https://github.com/mapshakers/leaflet-icon-pulse
  // var _test_pulse = L.icon.pulse({
  //   //#WIP - need to add name support for leaflet!

  //   iconName: name,
  //   // ^^^^^^^^^^^^
  //   iconSize: [scaleIcon * markerSize, scaleIcon * markerSize],
  //   color: strokeColor,
  //   fillColor: fillColor,
  //   heartbeat: 1,
  // });

  // https://stackoverflow.com/questions/41884070/how-to-make-markers-in-leaflet-blinking
  var _tests_2_pulse = L.divIcon({
    iconName: name,
    // Specify a class name we can refer to in CSS.
    className: "css-icon",
    html: '<div class="gps_ring"></div>',
    // Set marker width and height
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize],
    // ,iconAnchor: [11,11]
  });

  // transition between colors?
  // https://stackoverflow.com/questions/48619314/animated-transition-of-leaflet-marker-icon

  // mario_1 animation
  // see - https://codepen.io/thatianepisco/pen/JrYMBK
  // see - https://stackoverflow.com/questions/64625050/error-node-sass-version-5-0-0-is-incompatible-with-4-0-0
  //
  // npm uninstall node-sass
  // npm install node-sass@4.14.1
  //
  var _tests_3_mario_1 = L.divIcon({
    // Specify a class name we can refer to in CSS.
    className: "css-icon",
    html: `<div class="mario-holder"><div class="mario framethree"></div></div>`,
    // Set marker width and height
    iconName: name,
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize],
    // ,iconAnchor: [11,11]
  });

  // mario_2 animation
  // see - https://codepen.io/LantareCode/pen/dOXmBJ
  // This proves any spritesheet will work OK! ;)
  //
  //
  var _tests_3_mario_2 = L.divIcon({
    iconName: name,
    // Specify a class name we can refer to in CSS.
    className: "css-icon",
    html: '<div class="mario_2"></div>',
    // these have no impact on image but are necessary for code
    // sizing is done through scaling, see css
    // see also - https://stackoverflow.com/questions/2430206/how-can-i-scale-an-image-in-a-css-sprite
    //
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize],
    // sets the anchor point relative to the scaled size - see CSS. Image is 70 (as per sprite sheet), scale is 50%
    iconAnchor: [15, -15], // [+ve = left, -ve = down]

    // these have no impact on image but are necessary for code
    iconColor: { stroke: strokeColor, fill: fillColor },
  });

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // create icon
  var typicalNumberedMapMarker = L.icon({
    iconName: name,
    iconUrl: svgURL,
    iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
    iconSize: [50, 70],
    iconAnchor: [25, 58],
    className: iconClass,
  });

  //https://www.npmjs.com/package/leaflet.awesome-markers
  // var redMarker = L.AwesomeMarkers.icon({
  //   iconName: name,
  //   prefix: "fa",
  //   icon: "coffee",
  //   markerColor: "pink",
  //   iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
  //   //className: "awesome-marker awesome-marker-square",
  // });

  // for styling and stacking see
  // https://fontawesome.com/v5.15/how-to-use/on-the-web/styling/stacking-icons
  // offset idea - https://stackoverflow.com/questions/52773353/putting-a-number-inside-a-font-awesome-icon-marker-for-google-maps-marker-label
  // for layers see -   // https://fontawesome.com/v5.15/how-to-use/on-the-web/styling/layering

  // color options - see https://www.html-color-names.com/color-chart.php

  // hardhat tag markers
  // tagHardHatMarker
  var tagHardHatMarker0deg = L.divIcon({
    iconName: name,
    // Note - below offsets the flag to ~center on top section of the marker
    // - fa-map-marker-alt <-- has circle on map marker
    // user-hard-hat
    // hard-hat
    html: `<span class="fa-stack fa-2x" style="font-size: ${
      tagSize * scaleIcon * markerSize
    }px"> <i class="fa fa-map-marker fa-stack-2x " style="color:${fillColor};position:relative"></i> <i class="fas fa-hard-hat fa-stack-1x fa-inverse" style="position:absolute;margin:0px;padding:0px;top:-4px;left:0px;font-size:12px"></i><i class="fa fa-stack-1x" style="position:absolute;margin:0px;padding:0px;top:-18px;left:10px;font-size:12px">${text}</i></span>`,
    iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize], // [50, 20],
    // _OLD - off centre positioning
    // iconAnchor: [
    //   scaleIcon * markerSize * (1 - 0.15), // slightly off centre to the right
    //   scaleIcon * markerSize * (1 + 0.5), //
    // ],
    iconAnchor: [
      scaleIcon * markerSize * (1 - 0.15), // slightly off centre to the right
      scaleIcon * markerSize * (1 + 0.5), //
    ],
    className: "myDivIcon", // <<--- see .css for color property
  });

  // heavy vehicle marker
  // fa-truck-plow tag marker
  // Note - this uses an rotated map-marker with different offsets
  // tagTruckPlowMarker
  var tagTruckPlowMarker180deg = L.divIcon({
    iconName: name,
    html: `<span class="fa-stack fa-2x" style="font-size: ${
      tagSize * scaleIcon * markerSize
    }px"> <i class="fa fa-map-marker fa-stack-2x fa-rotate-180" style="color:${fillColor};position:relative"></i> <i class="fas fa-truck-plow fa-stack-1x fa-inverse" style="position:absolute;margin:0px;padding:0px;top:3px;left:0px;font-size:12px"></i><i class="fa fa-stack-1x" style="position:absolute;margin:0px;padding:0px;top:15px;left:-8px;font-size:12px">${text}</i></span>`,
    iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize], // [50, 20],
    iconAnchor: [
      scaleIcon * markerSize * (1 - 0.15), //
      scaleIcon * markerSize * (1 - 1.3), //
    ],
    className: "myDivIcon", // <<--- see .css for color property
  });

  // light vehicle marker
  // <i class="fas fa-truck-pickup"></i>
  // Note - this uses an rotated map-marker with different offsets
  // tagTruckPickupMarker
  var tagTruckPickupMarker180deg = L.divIcon({
    iconName: name,
    //html: '<i class="fa fa-map-marker fa-4x" style="color:Tomato"></i>',
    // Note - below offsets the flag to ~center on top section of the marker
    // - fa-map-marker-alt <-- has circle on map marker
    html: `<span class="fa-stack fa-2x" style="font-size: ${
      tagSize * scaleIcon * markerSize
    }px"> <i class="fa fa-map-marker fa-stack-2x fa-rotate-180" style="color:${fillColor};position:relative"></i> <i class="fas fa-truck-pickup fa-stack-1x fa-inverse" style="position:absolute;margin:0px;padding:0px;top:3px;left:0px;font-size:12px"></i><i class="fa fa-stack-1x" style="position:absolute;margin:0px;padding:0px;top:15px;left:-8px;font-size:12px">${text}</i></span>`,
    iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize], // [50, 20],
    iconAnchor: [
      scaleIcon * markerSize * (1 - 0.15), // X axis
      scaleIcon * markerSize * (1 - 1.3), // Y axis
    ],
    className: "myDivIcon", // <<--- see .css for color property
  });

  // unknown tag type marker
  // Note - this uses an rotated map-marker with different offsets
  // tagTruckPickupMarker
  var tagUnknownMarker270deg = L.divIcon({
    iconName: name,
    //html: '<i class="fa fa-map-marker fa-4x" style="color:Tomato"></i>',
    // Note - below offsets the flag to ~center on top section of the marker
    // - fa-map-marker-alt <-- has circle on map marker
    html: `<span class="fa-stack fa-2x" style="font-size: ${
      tagSize * scaleIcon * markerSize
    }px"> <i class="fa fa-map-marker fa-stack-2x fa-rotate-270" style="color:${fillColor};position:relative"></i> <i class="fas fa-question fa-stack-1x fa-inverse" style="position:absolute;margin:0px;padding:0px;top:0px;left:-3px;font-size:12px"></i><i class="fa fa-stack-1x" style="position:absolute;margin:0px;padding:0px;top:12px;left:-10px;font-size:12px">${text}</i></span>`,
    iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize], // [50, 20],
    iconAnchor: [
      scaleIcon * markerSize * (1 + 0.75), // slightly off centre to the left
      scaleIcon * markerSize * (1 - 0.35), //
    ],

    className: "myDivIcon", // <<--- see .css for color property
  });

  // marker custom tag icons
  // #Note - when marker clustering was introduced it was no longer necessary (or desireable) to
  // rotate the markers for positioning, so a generalised icon was introduced.

  function tagMarkerIcon(
    markerType,
    name,
    tagSize,
    markerSize,
    scaleIcon,
    fillColor,
    strokeColor,
    text
  ) {
    return L.divIcon({
      iconName: name,
      html: `<span class="fa-stack fa-2x" style="font-size: ${
        tagSize * scaleIcon * markerSize
      }px"> <i class="fa fa-map-marker fa-stack-2x " style="color:${fillColor};position:relative"></i> <i class="fas ${markerType} fa-stack-1x fa-inverse" style="position:absolute;margin:0px;padding:0px;top:-4px;left:0px;font-size:12px"></i><i class="fa fa-stack-1x" style="position:absolute;margin:0px;padding:0px;top:-18px;left:10px;font-size:12px">${text}</i></span>`,
      iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
      iconSize: [scaleIcon * markerSize, scaleIcon * markerSize], // [50, 20],
      iconAnchor: [
        scaleIcon * markerSize * (1 - 0.15), // slightly off centre to the right
        scaleIcon * markerSize * (1 + 0.25), //
      ],
      className: "myDivIcon", // <<--- see .css for color property
    });
  }

  // siren marker
  // <i class="fas fa-bullhorn"></i>
  // <i class="fas fa-square-full"></i>
  var relaySirenMarker = L.divIcon({
    iconName: name,
    html: `<span class="fa-stack fa-2x"  style="font-size: ${
      tagSize * scaleIcon * markerSize
    }px" ><i class="fas fa-square fa-stack-2x" style="color:red"></i><i class="fas fa-bullhorn fa-stack-1x fa-inverse"></i></span>`,
    iconColor: { stroke: strokeColor, fill: fillColor, text }, // hacked the position into color property
    iconSize: [scaleIcon * markerSize, scaleIcon * markerSize], // [50, 20],
    iconAnchor: [scaleIcon * markerSize, scaleIcon * markerSize], //[30, 55]
    className: "myDivIcon", // <<--- see .css for color property
  });

  // special case makeIcon return
  if (false && name === "RoundMarkerForced") {
    // #TESTING - temporary disable separate marker for Forced lights
    return _tests_3_mario_2; // _test_pulse;
  } else if (name === "TypicalNumberedMapMarker") {
    return typicalNumberedMapMarker;
  } else if (name === "TagHardHat") {
    return tagMarkerIcon(
      "fa-hard-hat",
      name,
      tagSize,
      markerSize,
      scaleIcon,
      fillColor,
      strokeColor,
      text
    ); // tagHardHatMarker;
  } else if (name === "TagTruckPickupMarker") {
    return tagMarkerIcon(
      "fa-truck-pickup",
      name,
      tagSize,
      markerSize,
      scaleIcon,
      fillColor,
      strokeColor,
      text
    ); // tagTruckPickupMarker;
  } else if (name === "TagUnknownMarker") {
    return tagMarkerIcon(
      "fa-question",
      name,
      tagSize,
      markerSize,
      scaleIcon,
      fillColor,
      strokeColor,
      text
    ); // tagUnknownMarker;
  }

  // TagAmbulanceMarker
  // TagLightVehicleMarker
  // TagLoaderMarker
  // TagTruckMarker
  // TagJumboMarker

  // TESTING - illustrator as source for SVG icons
  // .. comment out working tag markers
  // Stuart test icons
  else if (name === "RelaySirenMarker") {
    return relaySirenMarker;
  } else if (name === "TagAmbulanceMarker") {
    return hackedAmbulancePng;
  } else if (name === "TagLightVehicleMarker") {
    return hackedLightVehiclePng;
  } else if (name === "TagLoaderMarker") {
    return hackedLoaderPng;
  } else if (name === "TagTruckMarker") {
    return hackedTruckPng;
  } else if (name === "TagJumboMarker") {
    return hackedJumboPng;
  } else if (name === "TagJumboMarker") {
    return hackedJumboPng;
  }

  // default makeIcon return
  return svgIcon;
};

// ***********************************************************************
//
// React versions of above SVGs
//
// see - https://stackoverflow.com/questions/19484707/how-can-i-make-an-svg-scale-with-its-parent-container
//
// ***********************************************************************

const CrossMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 400 400"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={fillColor}
          stroke={strokeColor}
          stroke-width="40"
          d="M 160.014 0.066 H 239.987 V 160.013 H 399.934 V 239.987 H 239.987 V 399.934 H 160.014 V 239.987 H 0.067 V 160.013 H 160.014 Z"
        />
      </svg>
    </div>
  );
};

const Cross45Marker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 512 512"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={fillColor}
          stroke={strokeColor}
          stroke-width="45"
          d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z"
        />
      </svg>
    </div>
  );
};

const RoundMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          style={{ fill: fillColor, strokeWidth: "50px", stroke: strokeColor }}
          cx="299.069"
          cy="299.451"
          r="289.373"
          transform="matrix(1, 0, 0, 0.999234, 0.930973, 0.99951)"
        />
      </svg>
    </div>
  );
};

const SquareMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          style={{
            stroke: strokeColor,
            fill: fillColor,
            paintOrder: "fill",
            strokeWidth: "50px",
          }}
          points="589.8709716796875 10.041999816894531 589.3300170898438 590.3909912109375 9.567000389099121 589.2429809570312 10.128999710083008 10.086999893188477"
        />
      </svg>
    </div>
  );
};

const DiamondMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          style={{
            stroke: strokeColor,
            fill: fillColor,
            paintOrder: "fill",
            strokeWidth: "50px",
          }}
          points="300.28399658203125 585.822998046875 14.052000045776367 299.59100341796875 300.32000732421875 13.322999954223633 586.291015625 299.29400634765625"
        />
      </svg>
    </div>
  );
};

const TravelwayMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 599.4149780273438 -0.0650000125169754 L 600.5850219726562 298.7650146484375 L 298.8299865722656 599.9349975585938 L -0.5849997997283936 298.7650146484375 L 0 -1.819000005722046 Z"
          fill={fillColor}
          stroke-width="50"
          stroke={strokeColor}
        />
        <path d="M 601.086 298.972 L 298.829 600.643 L -1.085 298.971 L -0.499 -2.32 L 599.913 -0.564 Z M 0.499 -1.318 L -0.085 298.559 L 298.831 599.227 L 600.084 298.558 L 598.917 0.434 Z" />
      </svg>
    </div>
  );
};

const StarMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          style={{ stroke: strokeColor, strokeWidth: "50px", fill: fillColor }}
          points="600 -1.753999948501587 447.9530029296875 298.8299865722656 600 599.4149780273438 299.4150085449219 456.1400146484375 -0.5849997997283936 599.4149780273438 146.19900512695312 298.8299865722656 146.19900512695312 298.8299865722656 0 0 299.4150085449219 149.1230010986328"
        />
      </svg>
    </div>
  );
};

const HourGlassMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          style={{ stroke: strokeColor, fill: fillColor, strokeWidth: "50px" }}
          points="600 -0.6500002145767212 469.82501220703125 129.52499389648438 300 301.6889953613281 0 0.5199997425079346"
        />
        <polygon
          style={{ stroke: strokeColor, fill: fillColor, strokeWidth: "50px" }}
          points="599 603 468.825 472.825 299 300.661 -1 601.83"
        />
      </svg>
    </div>
  );
};

const BowTieMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          style={{ stroke: strokeColor, fill: fillColor, strokeWidth: "50px" }}
          points="727.64501953125 161.76100158691406 619.97998046875 280.67999267578125 450.1549987792969 452.843994140625 170.41400146484375 161.2429962158203"
          transform="matrix(0, 1, -1, 0, 751.830017, -148.479996)"
        />
        <polygon
          style={{ stroke: strokeColor, fill: fillColor, strokeWidth: "50px" }}
          points="426.3340148925781 439.4639892578125 318.66900634765625 321.6700134277344 148.843994140625 149.50599670410156 -129.77200317382812 440.5459899902344"
          transform="matrix(0, 1, -1, 0, 449.518982, 151.831009)"
        />
      </svg>
    </div>
  );
};

const TriangleMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon
          style={{ stroke: fillColor, strokeWidth: "50px", fill: fillColor }}
          points="300.4939880371094 22.224000930786133 583.0770263671875 591.156005859375 14.666999816894531 588.3660278320312"
          transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -1.223744, 1.228757)"
        />
        <polygon
          style={{ fill: fillColor, stroke: strokeColor }}
          points="471.959 592.867"
          transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -2.419402, 1.934086)"
        />
        <polygon
          style={{ fill: fillColor, stroke: strokeColor }}
          points="290.088 21.091"
          transform="matrix(0.999992, -0.004088, 0.004088, 0.999992, -0.083786, 1.185912)"
        />
      </svg>
    </div>
  );
};

const ForwardMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 589.104 9.755 L 590.274 294.346 L 300 586.678 L 9.235 294.346 L 9.878 8.059 L 589.104 9.755 Z"
          fill={fillColor}
          stroke-width="50"
          stroke={strokeColor}
          transform="matrix(0, -1, 1, 0, 2.386022, 597.122979)"
        />
        <path
          d="M 602.633 296.933 L 300.376 598.604 L 0.462 296.932 L 1.048 -4.359 L 601.46 -2.603 Z M 2.046 -3.357 L 1.462 296.52 L 300.378 597.188 L 601.631 296.519 L 600.464 -1.605 Z"
          transform="matrix(0, -1, 1, 0, 4.425004, 598.670016)"
        />
      </svg>
    </div>
  );
};

const BackwardMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 600 600"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 589.104 9.755 L 590.274 294.346 L 300 586.678 L 9.235 294.346 L 9.878 8.059 L 589.104 9.755 Z"
          fill={fillColor}
          stroke-width="50"
          stroke={strokeColor}
          transform="matrix(0, 1, -1, 0, 597.122979, -2.386022)"
        />
        <path
          d="M 599.047 297.425 L 296.79 599.096 L -3.124 297.424 L -2.538 -3.867 L 597.874 -2.111 Z M -1.54 -2.865 L -2.124 297.012 L 296.792 597.68 L 598.045 297.011 L 596.878 -1.113 Z"
          transform="matrix(0, 1, -1, 0, 595.576016, -0.347004)"
        />
      </svg>
    </div>
  );
};

const WorkerMarker = (fillColor, strokeColor, size, markerClass) => {
  return (
    <div
      className={markerClass}
      style={{ display: "inline-block", paddingRight: "5px" }}
    >
      <svg
        viewBox="0 0 32 32"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <path
          fill={"orange"}
          stroke={"orange"}
          d="M27,28.2c-0.5-2.8-2.5-5.1-5.1-5.8c-1.3-0.3-2.2-1.5-2.3-2.9c1.2-1,2.1-2.6,2.3-4.4c0-0.3,0-0.6-0.2-0.8S21.2,14,20.9,14  c-1.8,0-3.4-1-4.3-2.5c-0.2-0.3-0.5-0.5-0.9-0.5H11c-0.6,0-1,0.4-1,1v2c0,2.2,0.9,4.2,2.4,5.5c-0.2,1.4-1.1,2.6-2.5,3  c-2.5,0.7-4.4,2.9-5,5.7c-0.1,0.5,0,0.9,0.3,1.3C5.6,29.8,6,30,6.5,30h19.1c0.4,0,0.8-0.2,1.1-0.5C26.9,29.1,27.1,28.7,27,28.2z"
        />
        <path
          fill={"orange"}
          stroke={"orange"}
          d="M24,11h-0.2v-0.2c0-1.8-0.6-3.4-1.6-4.8l-0.7,2.5c-0.1,0.4-0.5,0.7-1,0.7c-0.1,0-0.2,0-0.3,0c-0.5-0.2-0.8-0.7-0.7-1.2  l1.2-4.1c0-0.3-0.2-0.6-0.5-0.8c-2.6-1.3-5.7-1.3-8.3,0c-0.3,0.2-0.5,0.5-0.5,0.8l1.2,4.1c0.2,0.5-0.2,1.1-0.7,1.2  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8-0.3-1-0.7L9.9,6c-1,1.4-1.6,3-1.6,4.8V11H8c-0.6,0-1,0.4-1,1s0.4,1,1,1h16c0.6,0,1-0.4,1-1  S24.6,11,24,11z"
        /> */}
        <path
          fill={"orange"}
          stroke={"orange"}
          d="M256,85.6c23.3,0,42.8-18.6,42.8-42.8C298.8,19.5,279.3,0,256,0s-42.8,19.5-42.8,42.8S232.7,85.6,256,85.6z M298.8,94.9h-85.6c-32.6,0-53,27.9-53,54.9v129.3c0,25.1,35.4,25.1,35.4,0V160h6.5v326.5c0,34.4,48.4,33.5,49.3,0V297.7h9.3v187.9 c1.9,35.4,49.3,32.6,49.3,0V160h5.6v120c0,25.1,36.3,25.1,36.3,0V149.8C351.8,122.8,330.4,94.9,298.8,94.9z"
        />{" "}
      </svg>
    </div>
  );
};

export class RenderSvgIcon extends Component {
  render() {
    const { name, strokeColor, fillColor, size, markerClass } = this.props;
    let svg;

    const forced = true;

    switch (name) {
      case "CrossMarker":
        svg = CrossMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "Cross45Marker":
        svg = Cross45Marker(fillColor, strokeColor, size, markerClass);
        break;
      case "RoundMarker":
        svg = RoundMarker(fillColor, strokeColor, size, markerClass, forced);
        break;

      case "SquareMarker":
        svg = SquareMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "DiamondMarker":
        svg = DiamondMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "TravelwayMarker":
        svg = TravelwayMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "StarMarker":
        svg = StarMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "TriangleMarker":
        svg = TriangleMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "HourGlassMarker":
        svg = HourGlassMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "BowTieMarker":
        svg = BowTieMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "ForwardMarker":
        svg = ForwardMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "BackwardMarker":
        svg = BackwardMarker(fillColor, strokeColor, size, markerClass);
        break;
      case "WorkerMarker":
        svg = WorkerMarker(fillColor, strokeColor, size, markerClass);
        break;
      default:
        svg = RoundMarker(fillColor, strokeColor, size, markerClass, false);
        break;
    }
    return svg;
  }
}
