import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table,
  Container,
  Button,
  Header,
  Grid,
  Input,
  Pagination,
  Dropdown,
  Segment,
  Icon,
  Popup,
  Label,
  Message,
  Tab,
} from "semantic-ui-react";
import { TrailingContent } from "components/TableTrailingContent";
import FlashMessagesList from "FlashMessages";

import AlarmButton from "components/AlarmButton";
import { timeConverter } from "../../utils/format-time";
import { capitalize } from "../../utils/format-text";

import { formatDate } from "utils/format-date";

import lodashSortBy from "lodash/sortBy";
import { formatRelative, parseISO } from "date-fns";

import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";

import { ClearLog } from "components/Logger/actions";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import { withComponentStateCache } from "react-component-state-cache";

// tag specific operations
//
import {
  getAllNamedAreaStatuses,
  getAllAreaStatuses,
} from "components/WebWorker/reducer";
import { getAllTags } from "components/Tags/reducer";
import { fetchTags } from "components/Tags/actions";
import { fetchAllUsers } from "components/UserAdmin/actions";
import { groupBy } from "utils/groupBy";

import { saveUserSettingsComponentState } from "components/UserAdmin/actions";
import hash from "object-hash";
import { strings } from "components/App/localisation";

const colors = [
  "purple",
  "olive",
  "blue",
  "violet",
  "teal",
  "pink",
  "brown",
  "grey",
  "black",
  "red",
  "orange",
  "yellow",

  "green",
];

class TagTrackingByZoneList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialise data list sort columns
      column: null,
      data: props.data,
      direction: null,
      // intialise filter input strings
      filterInput: {
        // called "filterInput" to avoid reserved word .filter
        // #NOTE - some list page versions of filtering have e.g. { strings: ..., include: true}
        // this is used when there is a dropdown list to remove the whole group from the data search
        id: "",
        rssi: "",
        name: "",
        vehicle: "",
        tagZone: "",
        area: "",
      },
      // intialise pagination of data list items
      page: 1,
      itemsPerPage: 10,
    };
  }

  componentDidMount() {
    const filterInput = this.props.componentstate.get(
      "filterInput",
      "tagsByZoneList"
    );

    // console.log(
    //   "TagTrackingByZoneList filterInput componentDidMount",
    //   filterInput
    // );

    if (!_isEmpty(filterInput)) {
      //console.log("filterInput componentDidMount setState", filterInput);
      this.setState({ filterInput: filterInput });
    }
  }

  componentWillUnmount() {
    const settings = {
      section: "filterInput",
      key: "tagsByZoneList",
      data: { ...this.state.filterInput },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    this.props.saveUserSettingsComponentState({ settings });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    ) {
      this.setState({
        data: this.props.data,
      });
    }

    // const { filterInput: match } = this.state;
    //   if (
    //     JSON.stringify(match) !== JSON.stringify(prevState.filterInput) ||
    //     JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) // if original props data changes
    //   ) {
    //     let newData = [...this.props?.data]; // original data
    //     newData = newData.filter(function (item) {
    //       let checkMatch = true; // assume all included as default "" is always included
    //       for (var key in match) {
    //         checkMatch =
    //           checkMatch &&
    //           item[key]?.toLowerCase().includes(match[key].toLowerCase()); // remove item which don't match
    //       }
    //       return checkMatch;
    //     });
    //     this.setState({
    //       data: newData,
    //     });
    //     // restore sort
    //     const { column, direction } = this.state;
    //     // #REVIEW - decide if need a default sort column
    //     // column is initially undefined. i.e. no default sort column
    //     if (column) {
    //       newData = newData.slice().sort((a, b) =>
    //         a[column].localeCompare(b[column], "en", {
    //           numeric: true,
    //         })
    //       );
    //     }
    //     // newData = _.sortBy(newData, [column]);
    //     if (direction === "descending") {
    //       newData = newData.reverse();
    //     }
    //     this.setState({
    //       data: newData,
    //     });
    //   }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        //data: _.sortBy(data, [clickedColumn]),
        data: data.slice().sort((a, b) =>
          a[clickedColumn].localeCompare(b[clickedColumn], "en", {
            numeric: true,
          })
        ),
        direction: "ascending",
      });
      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handleFilterChange = (e) => {
    const target = e.target;
    const { name, value } = target;

    let match = JSON.parse(JSON.stringify(this.state?.filterInput));

    // update match value with most recent filter entry
    match[name] = value;

    this.setState({
      filterInput: { ...match },
    });
  };

  handleDropdownItemsPerPage = (e, data) => {
    this.setState({ itemsPerPage: data.value, page: 1 });
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  fetchStuff = () => {
    this.props.fetchAllUsers();
    this.props.fetchTags();
  };

  zoneContent = (data = [], zone, color) => {
    // total # of items to display
    let itemsCount = 0;
    // setup variable to display viewable items per page
    let viewablesPage = [];
    // check data exists. May not happen on initial startup when redux state not setup
    if (data !== undefined) {
      itemsCount = data.length;
      viewablesPage = [...data];
    }
    // if enough faults display pagination
    let pagination;
    const { page, itemsPerPage } = this.state;

    if (itemsCount > itemsPerPage) {
      const totalPages = itemsCount / itemsPerPage;
      viewablesPage = data.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
      pagination = (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(totalPages)}
            siblingRange={1}
            onPageChange={this.setPageNum}
          />
          <Dropdown
            selection
            compact
            options={[
              { value: 10, text: "10", key: "loglist10" },
              { value: 20, text: "20", key: "loglist20" },
              { value: 40, text: "40", key: "loglist40" },
              { value: 60, text: "60", key: "loglist60" },
              { value: data.length, text: "all", key: "loglistall" },
            ]}
            style={{ margin: "5px" }}
            defaultValue={this.state.itemsPerPage}
            onChange={this.handleDropdownItemsPerPage}
          />
          <span>items per page. Found {itemsCount} items.</span>
        </div>
      );
    }

    return (
      <div>
        <Table
          sortable
          striped
          celled
          color={color}
          key={color}
          style={
            {
              // #WIP - testing making font size smaller - I *hate* mixed font sizes
              //fontSize: "0.8em",
            }
          }
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={6}>Zone: {zone}</Table.HeaderCell>
            </Table.Row>
            <Table.Row columns="equal">
              <Table.HeaderCell>Tag ID</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              {/* <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Vehicle</Table.HeaderCell>
              <Table.HeaderCell>RSSI</Table.HeaderCell>  */}
              <Table.HeaderCell>Latest Report</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(
              viewablesPage,
              ({
                id,
                rssi,
                name,
                vehicle,
                tagZone,
                area,
                tagLink,
                tagType,
                lastStatusReport,
              }) => (
                <Table.Row>
                  <Table.Cell>{tagLink}</Table.Cell>
                  <Table.Cell>{tagType}</Table.Cell>
                  {/* <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{vehicle}</Table.Cell> 
                  <Table.Cell>{rssi}</Table.Cell> */}
                  <Table.Cell>{lastStatusReport}</Table.Cell>
                </Table.Row>
              )
            )}
            {/* <TrailingContent data={data} isLoading={isLoading} error={error} /> */}
          </Table.Body>
        </Table>
        {pagination}
      </div>
    );
  };

  render() {
    const {
      //isLoading, error,
      levels,
    } = this.props;

    const {
      //column,
      data,
      //direction, filterInput
    } = this.state;

    const includeTitles = this.props.includeTitles === "true";
    const title = includeTitles ? (
      <Header as="h1">{strings?.["Tags In Zones"]}</Header>
    ) : (
      <div></div>
    );

    // // total # of items to display
    // let itemsCount = 0;
    // // setup variable to display viewable items per page
    // let viewablesPage = [];
    // // check data exists. May not happen on initial startup when redux state not setup
    // if (data !== undefined) {
    //   itemsCount = data.length;
    //   viewablesPage = [...data];
    // }
    // // if enough faults display pagination
    // let pagination;
    // const { page, itemsPerPage } = this.state;

    // if (itemsCount > itemsPerPage) {
    //   const totalPages = itemsCount / itemsPerPage;
    //   viewablesPage = data.slice(
    //     (page - 1) * itemsPerPage,
    //     (page - 1) * itemsPerPage + itemsPerPage
    //   );
    //   pagination = (
    //     <div style={{ display: "inline-flex", alignItems: "center" }}>
    //       <Pagination
    //         activePage={page}
    //         totalPages={Math.ceil(totalPages)}
    //         siblingRange={1}
    //         onPageChange={this.setPageNum}
    //       />
    //       <Dropdown
    //         selection
    //         compact
    //         options={[
    //           { value: 10, text: "10", key: "loglist10" },
    //           { value: 20, text: "20", key: "loglist20" },
    //           { value: 40, text: "40", key: "loglist40" },
    //           { value: 60, text: "60", key: "loglist60" },
    //           { value: data.length, text: "all", key: "loglistall" },
    //         ]}
    //         style={{ margin: "5px" }}
    //         defaultValue={this.state.itemsPerPage}
    //         onChange={this.handleDropdownItemsPerPage}
    //       />
    //       <span>items per page. Found {itemsCount} items.</span>
    //     </div>
    //   );
    // }

    const headerCellStyle = {
      borderBottom: "1px solid rgba(34,36,38,.1)",
    };

    const segmentStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    return (
      <div className={"genericGridHeader"}>
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4}>
                <Header as="h1">{title}</Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          {true && (
            <Segment textAlign="left" style={segmentStyle}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Popup
                  content={strings?.["TAG_ZONE_LIST_UPDATE_POP_MSG"]}
                  trigger={
                    <Button
                      //icon
                      size="large"
                      color="orange"
                      onClick={() => this.fetchStuff()}
                      // onClick={this.props.ClearLog}
                    >
                      {strings?.["Update"]}
                      {/* <Icon name="download" /> */}
                      {/* {itemsCount} */}
                    </Button>
                  }
                />
              </div>
            </Segment>
          )}
          <Tab
            //style={{ paddingTop: "1rem" }}
            //activeIndex={activeIndex}
            // className={"????"}
            //onTabChange={this.handleTabChange}
            menu={{
              attached: true,
              className: "tabTitleWrapped",
              tabular: true,
              //style: { display: "flex", justifyContent: "center" },
            }}
            panes={levels?.map((level) => {
              // data filter to level
              const levelData = data[level.id] || {};
              return {
                menuItem: level.name,
                render: () => (
                  <Tab.Pane>
                    <Grid>
                      <Grid.Row key={hash(levelData)} columns={"equal"}>
                        {_isEmpty(levelData) && (
                          <Grid.Column>
                            <Message icon color="orange">
                              <Icon name="warning" />
                              <Message.Content>
                                <Message.Header>
                                  {strings?.["Tag Zone List Message Header"]}
                                </Message.Header>
                                {strings?.["Tag Zone List Message Content"]}
                              </Message.Content>
                            </Message>
                          </Grid.Column>
                        )}
                        {Object.keys(levelData).map((zone, idx) => {
                          return (
                            <Grid.Column>
                              {this.zoneContent(
                                levelData[zone],
                                `${zone}`,
                                colors[idx]
                              )}
                            </Grid.Column>
                          );
                        })}
                      </Grid.Row>
                    </Grid>
                  </Tab.Pane>
                ),
              };
            })}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const _prepData = (elements) => {
  let filteredElements = {};

  //console.log(`xxx elements`, elements);
  //console.log(`xxx strings`, strings);

  // #WIP - force to only one area for testing
  let newElements;
  for (const area in elements) {
    newElements = elements[area];
    filteredElements[area] = {};
    for (const zone in newElements) {
      filteredElements[area][zone] = [];

      if (newElements[zone].length < 1) {
        filteredElements[area][zone][0] = {
          id: "",
          tagLink: strings?.["Click Update to fetch data."],
          tagType: "",
          rssi: "",
          name: ``,
          vehicle: ``,
          lastStatusReport: "",
        };
      }

      // only need a subset of data
      newElements[zone].forEach((tag, idx) => {
        const id = tag?.id || "-";
        const mac = tag?.mac || "-";
        const rssi = tag?.rssi || "-";
        const user_info = tag?.userInfo || {};
        const vehicle_info = tag?.vehicleInfo || {};
        const tagType = tag?.tagUserType;
        const lastUpdated = tag?.last_updated;

        const { firstname, lastname } = user_info;
        const { name, type } = vehicle_info;

        // is it a user _OR_ a vehicle

        let tagEntry = "?";
        switch (tagType) {
          case "personnel":
            tagEntry = `${firstname} ${lastname}`;
            break;
          case "vehicle":
            tagEntry = `${name}`;
            break;
          case "unknown":
            tagEntry = `${mac}`;
            break;
          default:
            break;
        }

        // break down lastStatusReport as it may not be defined in object
        let lastStatusReport = "-";
        if (lastUpdated) {
          // see - https://moment.github.io/luxon/#/formatting?id=toformat
          lastStatusReport = DateTime.fromISO(lastUpdated).toFormat("FF");
        }

        // compose new data set
        filteredElements[area][zone][idx] = {
          id: id,
          tagLink: <Link to={`/status/tag/${id}`}>{tagEntry} </Link>,
          tagType: tagType === "unknown" ? "?" : tagType,
          rssi: rssi.toString(),
          name: `${firstname} ${lastname}`,
          vehicle: `${name}`,
          lastStatusReport,
        };
      });
    }
  }

  return filteredElements;
};

function mapStateToProps(state, props) {
  const tagData = getAllTags(state);

  const allAreas = getAllAreaStatuses(state);

  //console.log("xxx allAreas", allAreas);

  // exclude ALL_AREAS, LEVEL_WIDE
  const allNamedAreas = getAllNamedAreaStatuses(state).filter(
    (na) => na.type !== "LEVEL_WIDE" && na.type !== "ALL_AREAS"
  );

  const tagZoneNamedAreas = allNamedAreas.filter(
    (na) => na.sub_type === "tagzone"
  );

  // accumulate array of area ids called "levels" - old school!
  let levels = [];

  // e.g. data: {area1: {zone1: [{id, rssi...}...], zone2: []}}
  let tagsByAreaByZone = {};

  allAreas.forEach((area) => {
    // console.log(
    //   `tagsByAreaByZone ----PROCESS AREA ${area.id}-----------------`
    // );

    const { id } = area;
    levels.push({ id: id, name: id });

    const tagZoneNamedAreasByArea = tagZoneNamedAreas.filter(
      (na) => na.area === id
    );

    // console.log(
    //   "tagsByAreaByZone tagZoneNamedAreasByArea",
    //   tagZoneNamedAreasByArea
    // );

    const groupedTagZonesByAreaByParent = groupBy(
      tagZoneNamedAreasByArea,
      "parent_name"
    );

    // console.log(
    //   "tagsByAreaByZone groupedTagZonesByAreaByParent",
    //   groupedTagZonesByAreaByParent
    // );

    tagsByAreaByZone[id] = {};

    // console.log("tagsByAreaByZone tagData", tagData);

    // #WIP - Tags may be in multiple areas so searching by 'area' property may not work.
    // consider changing the area to an array?
    const tagsByArea = tagData.filter((tag) =>
      tag.zones?.filter((z) => z.area === id)
    );

    // console.log("tagsByAreaByZone tagsByArea", tagsByArea);

    for (const zone in groupedTagZonesByAreaByParent) {
      tagsByAreaByZone[id][zone] = [];
      tagsByArea.forEach((tag) => {
        if (tag.zones.some((z) => z.name === zone)) {
          tagsByAreaByZone[id][zone].push(tag);
        }
      });
    }

    // console.log("tagsByAreaByZone ===> tagsByAreaByZone", tagsByAreaByZone);
  });

  // e.g. data: {area1: {zone1: [{id, rssi...}...], zone2: []}}
  const data = _prepData(tagsByAreaByZone);

  // console.log("tagsByAreaByZone data", data);
  // console.log("tagsByAreaByZone ---------------------");

  return {
    levels,
    data,
  };
}

export default withComponentStateCache(
  connect(mapStateToProps, {
    ClearLog,
    fetchTags,
    fetchAllUsers,
    saveUserSettingsComponentState,
  })(TagTrackingByZoneList)
);
