import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiSystem";
import { SubmissionError } from "redux-form";

function* fetchFolderFilesListByFolder({ payload }) {
  //console.log(`FOLDER_FILES_LIST_FETCH_REQUESTED`);

  try {
    const data = yield Api.fetchFolderFilesListByFolder(payload);

    console.log(`FOLDER_FILES_LIST_FETCH_REQUESTED`, data);

    yield put({
      type: "FOLDER_FILES_LIST_FETCH_SUCCEEDED",
      payload: { data },
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "FOLDER_FILES_LIST_FETCH_FAILED",
          payload: { error: "fetchFolderFilesListByFolder error", extra: data },
        });
      } else {
        yield put({
          type: "FOLDER_FILES_LIST_FETCH_UNKNOWN",
          payload: { error: "fetchFolderFilesListByFolder error", extra: data },
        });
      }
    }
  }
}

function* deleteFolderFile(action) {
  console.log(`FOLDER_FILE_DELETE_REQUESTED`);
  const { values, resolve, reject } = action.payload;
  const { filename, folder } = values;
  try {
    const response = yield Api.deleteFolderFile(filename, folder);
    const { data } = response;
    yield put({
      type: "FOLDER_FILE_DELETE_SUCCEEDED",
      payload: { data },
    });
    resolve(response);
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "FOLDER_FILE_DELETE_FAILED",
          payload: { error: "deleteFolderFile error", extra: data },
        });
      } else {
        yield put({
          type: "FOLDER_FILE_DELETE_UNKNOWN",
          payload: { error: "deleteFolderFile error", extra: data },
        });
      }
    }
    reject(error);
  }
}

function* saveFolderFile(action) {
  console.log(`FOLDER_FILE_SAVE_REQUESTED`, action);
  const { values, resolve, reject } = action.payload;
  const { filename, folder, file } = values;
  try {
    const response = yield Api.saveFolderFile(filename, folder, file);
    const { data } = response;
    yield put({
      type: "FOLDER_FILE_SAVE_SUCCEEDED",
      payload: { data },
    });
    resolve(response);
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "FOLDER_FILE_SAVE_FAILED",
          payload: { error: "saveFolderFile error", extra: data },
        });
      } else {
        yield put({
          type: "FOLDER_FILE_SAVE_UNKNOWN",
          payload: { error: "saveFolderFile error", extra: data },
        });
      }
    }
    reject(error);
  }
}

function* fetchServerInfo(action) {
  //console.log(`SERVER_INFO_FETCH_REQUESTED`);
  try {
    const info = yield call(Api.getServerInfo);
    yield put({
      type: "SERVER_INFO_FETCH_SUCCEEDED",
      payload: { info },
    });
    yield put({ type: "SERVER_CONNECTION_OPENED" });
  } catch (error) {
    console.log(`ERROR fetchServerInfo`, error);

    if (error?.response) {
      // if there is response, it means its not a 50x, but 4xx
      const {
        response: { status, data },
      } = error;
      if (status === 401) {
        yield put({
          type: "SERVER_INFO_FETCH_FAILED",
          payload: { error: "Server Info error", extra: data },
        });
      } else if (status === 504) {
        yield put({
          type: "SERVER_INFO_FETCH_FAILED",
          payload: status,
        });
        yield put({ type: "SERVER_CONNECTION_CLOSED" });
      } else {
        // yield put({
        //   type: "SERVER_INFO_FETCH_UNKNOWN",
        //   payload: { error: "Server Info error", extra: data },
        // });
        yield put({
          type: "SERVER_INFO_FETCH_FAILED",
          payload: status,
        });
        yield put({ type: "SERVER_CONNECTION_CLOSED" });
      }
    } else {
      // gets activated on 50x errors, since no response from server

      yield put({
        type: "SERVER_INFO_FETCH_NO_RESPONSE",
        payload: "no_response",
      });
    }
  }
}

function* fetchServerOk(action) {
  console.log(`SERVER_OK_FETCH_REQUESTED`);

  // getServerOk only returns the version information from the server.
  // The getServerOk and getServerInfo version information can be compared to
  // determine if the server is OK.
  //

  // #NOTE - fetchServerOk sends response to SERVER_INFO_FETCH_* reducer processing

  try {
    const info = yield call(Api.getServerOk);
    //console.log("SERVER_OK_FETCH_REQUESTED info ", info);
    yield put({
      type: "SERVER_INFO_FETCH_SUCCEEDED",
      payload: { info },
    });
    yield put({ type: "SERVER_CONNECTION_OPENED" });
  } catch (error) {
    console.log(`ERROR fetchServerInfo`, error);

    if (error?.response) {
      // if there is response, it means its not a 50x, but 4xx
      const {
        response: { status, data },
      } = error;
      if (status === 401) {
        yield put({
          type: "SERVER_INFO_FETCH_FAILED",
          payload: { error: "Server Info error", extra: data },
        });
      } else if (status === 504) {
        yield put({
          type: "SERVER_INFO_FETCH_FAILED",
          payload: status,
        });
        yield put({ type: "SERVER_CONNECTION_CLOSED" });
      } else {
        // yield put({
        //   type: "SERVER_INFO_FETCH_UNKNOWN",
        //   payload: { error: "Server Info error", extra: data },
        // });
        yield put({
          type: "SERVER_INFO_FETCH_FAILED",
          payload: status,
        });
        yield put({ type: "SERVER_CONNECTION_CLOSED" });
      }
    } else {
      // gets activated on 50x errors, since no response from server

      yield put({ type: "SERVER_CONNECTION_CLOSED" });
      // #WIP
      yield put({
        type: "SERVER_INFO_FETCH_NO_RESPONSE",
        payload: "no_response",
      });
    }
  }
}

function* fetchMqttBroker(action) {
  //console.log(`MQTT_BROKER_FETCH_REQUESTED`);
  try {
    const networkInfo = yield call(Api.getMqttBroker);
    yield put({ type: "MQTT_BROKER_FETCH_SUCCEEDED", payload: networkInfo });
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    if (status === 401) {
      yield put({
        type: "MQTT_BROKER_FETCH_FAILED",
        payload: { error: "fetchMqttBroker error", extra: data },
      });
    } else {
      yield put({
        type: "MQTT_BROKER_FETCH_UNKNOWN",
        payload: { error: "fetchMqttBroker error", extra: data },
      });
    }
  }
}

function* fetchCheckServer({ payload }) {
  console.log(`SERVER_CHECK_SERVICE_FETCH_REQUESTED - payload`, payload);
  try {
    const response = yield Api.postCheckService(payload);

    const { data } = response;
    yield put({
      type: "SERVER_CHECK_SERVICE_FETCH_SUCCEEDED",
      payload: data,
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "SERVER_CHECK_SERVICE_FETCH_FAILED",
          payload: { error: "fetchCheckServer error", extra: data },
        });
      } else {
        yield put({
          type: "SERVER_CHECK_SERVICE_FETCH_UNKNOWN",
          payload: { error: "fetchCheckServer error", extra: data },
        });
      }
    }
  }
}

function* settingsSagas() {
  yield takeEvery(
    "FOLDER_FILES_LIST_FETCH_REQUESTED",
    fetchFolderFilesListByFolder
  );
  yield takeEvery("FOLDER_FILE_DELETE_REQUESTED", deleteFolderFile);
  yield takeEvery("FOLDER_FILE_SAVE_REQUESTED", saveFolderFile);

  yield takeEvery("SERVER_INFO_FETCH_REQUESTED", fetchServerInfo);
  yield takeEvery("SERVER_OK_FETCH_REQUESTED", fetchServerOk);
  yield takeEvery("MQTT_BROKER_FETCH_REQUESTED", fetchMqttBroker);
  yield takeEvery("SERVER_CHECK_SERVICE_FETCH_REQUESTED", fetchCheckServer);
}

export default settingsSagas;
