// see - https://reactjs.org/docs/error-boundaries.html

import React from "react";
import { Container } from "semantic-ui-react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
    console.log(`ERROR: error, errorInfo`, error, errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Container>
          <div style={{ paddingTop: "20px" }}>
            <h2>Something went wrong.</h2>
            <h3>
              Report this error, and click to go{" "}
              <a href="/">click here to go Home</a>.
            </h3>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        </Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
