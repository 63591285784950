import React from "react";
import { connect } from "react-redux";

import { getFireflyById } from "components/WebWorker/reducer";

import {
  formatDate,
  formatDateRelativeNow,
  parseDate,
} from "utils/format-date";

import { StatusEnumToReadable } from "utils/StatusEnum";

function Component({ statusReport }) {
  let updatedStatusReport = { ...statusReport };

  if (statusReport) {
    const {
      timestamp: timestampRx,
      topology: { deviceStatus: deviceStatusRx },
    } = statusReport;

    // format timestamp
    let timestamp;

    if (timestampRx !== undefined) {
      timestamp = parseDate(timestampRx);
      timestamp =
        formatDate(timestamp) +
        " (" +
        formatDateRelativeNow(timestamp, new Date()) +
        ")";
    } else {
      timestamp = "No time information";
    }

    updatedStatusReport = { ...statusReport, timestamp: timestamp };

    // make deviceStatus readable
    let deviceStatus;
    if (deviceStatusRx) {
      deviceStatus = deviceStatusRx.map(
        (status) => StatusEnumToReadable[status]
      );
      updatedStatusReport.topology.deviceStatus = deviceStatus;
    }
  } else {
    updatedStatusReport = null;
  }

  return <pre>{JSON.stringify(updatedStatusReport, null, 2)}</pre>;
}

export default connect((state, { id }) => {
  const ff = getFireflyById(state, id);

  return { statusReport: ff };
})(Component);
