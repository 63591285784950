import { channelBufferPublishMsg } from "apiSaga";
import { myApi, dummyResponse } from "api/apiAxios";
import { messageToken, userSessionIp } from "utils/messageToken";

export function* getNamedAreaGroupPost(id) {
  // prepare message token
  const token = messageToken(userSessionIp);

  const payload = {
    id: id,
    token: token,
  };

  const response = yield myApi.post("/api/get_named_area_group", payload);
  // console.log(
  //   `API_TEST_apiNamedAreaGroup getNamedAreaGroupPost get_named_area_group`,
  //   response
  // );
  return { topic: `${payload.topic}`, token: token };
}

export function* updateNamedAreaGroup(id, data) {
  // prepare message token
  const token = messageToken(userSessionIp);
  const saveMessage = {
    ...data,
    token: token,
  };

  console.log(
    `>>>>>>>>>>>>>>>UPDATE_NAMED_AREA_GROUP updateNamedAreaGroup`,
    saveMessage
  );

  const payloadSaveMsg = {
    topic: `area/${id}/change`,
    qos: 0,
    message: saveMessage,
    retained: false,
  };

  const saveNamedAreaGroup = () => {
    return new Promise(function (resolve, reject) {
      channelBufferPublishMsg(payloadSaveMsg, "MQTT_PUBLISH");

      // console.log("saveNamedAreaGroup response", {
      //   topic: `${payloadSaveMsg.topic}`,
      //   token: token,
      // });

      //resolve(`${payloadSaveMsg.topic}`);
      resolve({ topic: `${payloadSaveMsg.topic}`, token: token });
    });
  };

  if (true) {
    const response = yield saveNamedAreaGroup();
    console.log("saveNamedAreaGroup response", response);
    return response;
  } else {
    //dummy ending for testing...
    const response = yield dummyResponse(false);
    //console.log("saveFirefly response", response);
    return response;
  }
}

// #Note - this is different to mqqt message which updates via area/+/change.
// The post directly changes the area column data.
//
export function* updateNamedAreaGroupPost(id, data) {
  const { named_area_group } = data;

  // prepare message token
  const token = messageToken(userSessionIp);

  const payloadNewSaveMsg = {
    topic: `area/${id}/change`,
    id: id,
    named_area_group: named_area_group,
    token: token,
  };

  // console.log(
  //   `API_TEST_apiNamedAreaGroup updateNamedAreaGroupPost payloadNewSaveMsg`,
  //   payloadNewSaveMsg
  // );
  const response = yield myApi.post(
    `/api/change_named_area_group`,
    payloadNewSaveMsg
  );
  // console.log(
  //   `API_TEST_apiNamedAreaGroup updateNamedAreaGroupPost change_named_area_group`,
  //   response
  // );
  return { topic: `${payloadNewSaveMsg.topic}`, token: token };
}
