import React, { Component } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Table,
  Ref,
  Grid,
  Button,
  Icon,
  Message,
  Segment,
  Popup,
} from "semantic-ui-react";
import { v4 as uuid } from "uuid";

import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import NamedAreaGroupTableSubItems from "./NamedAreaGroupTableSubItems";
import SettingsModal from "admin/named-area-group/NamedAreaGroupSettingsModal";

import ContainerDimensions from "react-container-dimensions";

const getItemStyle = (highlightColor, color, isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? highlightColor : color,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (color, isDraggingOver) => ({
  background: isDraggingOver ? color : "white",
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destClone;
};

// find each object parent
const findParent = (id, items) => {
  let result;
  const itemsKeys = Object.keys(items);
  for (const item of itemsKeys) {
    result = items[item].find((item) => item.id === id);
    if (result !== undefined) {
      return result;
    }
  }
  return result;
};

const MergeSettings = (obj1, obj2) => {
  if (obj2) {
    var obj3 = {};
    for (let attrname in obj1) {
      obj3[attrname] = obj1[attrname];
    }
    for (let attrname in obj2) {
      obj3[attrname] = obj2[attrname];
    }
    return obj3;
  } else {
    return obj1;
  }
};

export default class NamedAreaGroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      collapse: [],
      collapseAll: false,
      collapseLevel: false,
      NAMEDAREAS: [],
      initialValues: {},
      //
      // modal settings dlg for button options
      isOpenSettingsModal: false,
      modalSettings: {},
    };
  }

  componentDidMount() {
    const { data, initialValues } = this.props;
    const { listOfNamedAreas } = initialValues;

    if (data !== undefined) {
      this.setState({ items: data });
    }
    if (initialValues !== undefined) {
      this.setState({ initialValues: initialValues });
    }
    if (listOfNamedAreas !== undefined) {
      this.setState({ NAMEDAREAS: listOfNamedAreas });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.items !== this.state.items && prevProps.data !== undefined) {
      // check undefined to stop circular update on startup
      // maintain the state of the calling form
      this.props.onItemsChange(this.state.items);
    }
    // update the list state from the calling form if reset
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ items: this.props.data });
    }
  }

  onDragStart = (result) => {
    // type:
    // "droppableSubSubItem" - named areas
    // "droppableSubItem" - levels
    // "droppableItem" - groups

    // while dragging sections are collapsed
    // set collapseAll, collapseLevel
    const { type } = result;
    switch (type) {
      case "droppableItem":
        this.setState({ collapseAll: true });
        break;
      case "droppableSubItem":
        // collapse levels
        this.setState({ collapseLevel: true });

        break;

      default:
        break;
    }
  };

  onDragEnd = (result) => {
    // console.log("onDragEnd result", result);
    // console.log("onDragEnd result.source", result.source);
    // console.log("onDragEnd result.destination", result.destination);

    const { source, destination } = result;

    const { items } = this.state;
    const cloneItems = JSON.parse(JSON.stringify(items));

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // delete from groups
    if (destination.droppableId.includes("DELETE")) {
      // can't delete named area items
      if (source.droppableId === "NAMEDAREAS") {
        return;
      }
      return;
    }

    // while dragging sections are collapsed
    // reset collapseAll, collapseLevel

    // type:
    // "droppableSubSubItem" - named areas
    // "droppableSubItem" - levels
    // "droppableItem" - groups
    const { type } = result;
    switch (type) {
      case "droppableItem":
        this.setState({ collapseAll: false });
        break;

      case "droppableSubItem":
        this.setState({ collapseLevel: false });
        break;

      default:
        break;
    }

    // add named area
    if (source.droppableId === "NAMEDAREAS") {
      // case "NAMEDAREAS":
      //   groups = this.state.groups;
      //   groups[destination.droppableId] = copy(
      //     NAMEDAREAS,
      //     this.state.groups[destination.droppableId],
      //     source,
      //     destination
      //   );
      //   this.setState({ groups: groups });

      //   break;

      const sourceClone = Array.from(this.state.NAMEDAREAS);
      let item = sourceClone[source.index];

      // console.log("sourceClone", sourceClone);
      // console.log("source item", item);

      const itemSubItemMap = cloneItems.reduce((acc, item) => {
        acc[item.id] = item.subItems;
        return acc;
      }, {});

      const destParentId = destination.droppableId;
      const destParentObject = findParent(destParentId, itemSubItemMap);

      // console.log("source destParentObject", destParentObject);

      const { settings } = destParentObject;
      // update item button settings from priority level settings and default
      // add button information for the namedArea based on settings for the level

      const buttonTemplate = (itemId, namedAreaId) => {
        const obj = {
          id: itemId,
          named_area: namedAreaId,
          // clickable: true,
          // icon: "icon-earthquake123",
          // priority: 0,
          // title: "Seismic 1",
          // alt: "Level 1",
          // color: "red",
          // group: 0,
          // type: "SEISMIC1_EVENT",
          // hint: "Level 0",
          // default: true,
          // active_state: "on",
          // active_color: "red",
          // state: "on",
          // on_time: 0,
          // off_time: 0,
          // train: 0,
          // active: true,
          // marker: "",
        };
        return obj;
      };

      const button = MergeSettings(
        settings,
        buttonTemplate(item.id, item.namedArea.id)
      );
      //console.log("button merge", button);

      item.namedArea.button = button;

      const destClone = destParentObject.subItems;
      destClone.splice(destination.index, 0, {
        ...item,
        id: uuid(),
        parent: destParentObject.id,
      });

      //console.log("source destClone", destClone);

      // update items state based on destParentObject.id (parent)
      let newItems = [];
      let i = 0;
      // groups
      cloneItems.forEach((item) => {
        // levels - subItems
        let newSubItem = [];
        let j = 0;
        item.subItems.forEach((item) => {
          newSubItem[j] = item;
          if (item.id === destParentObject.id) {
            // named areas - subSubItems
            newSubItem[j].subItems = destClone;
          }
          j = j + 1;
        });
        newItems[i] = item;
        newItems[i].subItems = newSubItem;
        i = i + 1;
      });

      //console.log("source newItems", JSON.stringify(newItems));

      this.setStateNewItems(newItems);
      //this.setState({ items: newItems });

      //#########
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (result.type === "droppableItem") {
      const items = reorder(cloneItems, sourceIndex, destIndex);
      // console.log("onDragEnd reorder items", items);
      this.setState({
        items,
      });
    } else if (result.type === "droppableSubItem") {
      const itemSubItemMap = cloneItems.reduce((acc, item) => {
        acc[item.id] = item.subItems;
        return acc;
      }, {});

      // console.log("onDragEnd itemSubItemMap", itemSubItemMap);

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      // console.log("onDragEnd sourceParentId", sourceParentId);
      // console.log("onDragEnd destParentId", destParentId);

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      // console.log("onDragEnd sourceSubItems", sourceSubItems);
      // console.log("onDragEnd destSubItems", destSubItems);

      let newItems = [...cloneItems];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        // console.log(
        //   "onDragEnd reorder sourceSubItems,sourceIndex,destIndex",
        //   sourceSubItems,
        //   sourceIndex,
        //   destIndex
        // );
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.subItems = reorderedSubItems;
          }
          return item;
        });
        this.setStateNewItems(newItems);
        //this.setState({items: newItems,});
      } else {
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.subItems = newSourceSubItems;
          } else if (item.id === destParentId) {
            item.subItems = newDestSubItems;
          }
          return item;
        });
        this.setStateNewItems(newItems);
        //this.setState({items: newItems,});
      }
    } else if (result.type === "droppableSubSubItem") {
      const itemSubItemMap = cloneItems.reduce((acc, item) => {
        acc[item.id] = item.subItems;
        return acc;
      }, {});

      // console.log("onDragEnd reorder itemSubItemMap", itemSubItemMap);

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceParentObject = findParent(sourceParentId, itemSubItemMap);
      const sourceSubItems = sourceParentObject?.subItems;

      const destParentObject = findParent(destParentId, itemSubItemMap);
      const destSubItems = destParentObject?.subItems;

      // #DEBUG - useful debugs messages
      if (false) {
        console.log("onDragEnd sourceParentId", sourceParentId);
        console.log("onDragEnd destParentId", destParentId);
        console.log("onDragEnd sourceParentObject", sourceParentObject);
        console.log("onDragEnd sourceSubItems", sourceSubItems);
        console.log("onDragEnd destParentObject", destParentObject);
        console.log("onDragEnd destSubItems", destSubItems);
      }

      // don't allow moving sub sub items across group boundaries
      if (sourceSubItems === undefined || destSubItems === undefined) {
        return;
      }

      let newItems = [...cloneItems];

      // CASE - subItems are reOrdered inside same Parent
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );

        // console.log("onDragEnd newItems", newItems);

        for (const [i, subItems] of newItems.entries()) {
          // console.log("onDragEnd subItems", i, subItems);
          for (const [j, subItem] of subItems.subItems.entries()) {
            // console.log("onDragEnd subItem j", j, subItem);
            if (subItem.id === sourceParentId) {
              if (subItem.parent === sourceParentObject.parent) {
                // console.log("onDragEnd subItem - found it!", subItem);
                // console.log(
                //   "onDragEnd newItems[i][j] - found it!",
                //   newItems[i].subItems[j].subItems
                // );
                newItems[i].subItems[j].subItems = reorderedSubItems;
              }
            }
          }
        }

        // console.log("onDragEnd newItems", newItems);

        this.setStateNewItems(newItems);
        //this.setState({items: newItems});
      }
      // CASE - subItems are moved between different parents
      else {
        let newSourceSubItems = [...sourceSubItems];

        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);

        // newItems = newItems.map((item) => {
        //   if (item.id === sourceParentId.toString()) {
        //     item.subItems = newSourceSubItems;
        //   } else if (item.id === destParentId.toString()) {
        //     item.subItems = newDestSubItems;
        //   }
        //   return item;
        // });

        // console.log(
        //   "onDragEnd subItems are moved between different parents --------"
        // );
        for (const [i, subItems] of newItems.entries()) {
          //console.log("onDragEnd subItems", i, subItems);
          for (const [j, subItem] of subItems.subItems.entries()) {
            //console.log("onDragEnd subItem j", j, subItem);

            // #REVIEW - WHEN DOES id === sourceParentId????????????????
            //

            if (subItem.id === sourceParentId) {
              //console.log("onDragEnd subItem - subItem.id === sourceParentId");
              if (subItem.parent === sourceParentObject.parent) {
                // console.log(
                //   "onDragEnd subItem - subItem.parent === sourceParentObject.parent"
                // );

                // console.log("onDragEnd subItem - found it!", subItem);
                // console.log(
                //   "onDragEnd newItems[i][j] - found it!",
                //   newItems[i].subItems[j].subItems,
                //   "newSourceSubItems - ",
                //   newSourceSubItems
                // );
                newItems[i].subItems[j].subItems = newSourceSubItems;
              }
            }
            if (subItem.id === destParentId) {
              //console.log("onDragEnd subItem - subItem.id === destParentId");
              if (subItem.parent === destParentObject.parent) {
                // console.log(
                //   "onDragEnd subItem - subItem.parent === destParentObject.parent)"
                // );
                // console.log("onDragEnd subItem - found it!", subItem);
                // console.log(
                //   "onDragEnd newItems[i][j] - found it!",
                //   newItems[i].subItems[j].subItems,
                //   "newDestSubItems - ",
                //   newDestSubItems
                // );

                // #WIP - update settings of newDestSubItems from settings of subItem
                // The item has been dragged to a new level.
                // In this situation update the item's settings to match the new level settings.
                //
                let updatedNewDestSubItems = [];
                // go over every item is the level
                newDestSubItems.forEach((item) => {
                  // copy the item
                  let newItem = { ...item };
                  // get the button (settings)
                  let newButton = item?.namedArea?.button;
                  //console.log("onDragEnd newButton ", newButton);
                  // console.log(
                  //   "onDragEnd subItem?.settings ",
                  //   subItem?.settings
                  // );
                  // merge the level settings with the button settings, using the subItem (level) settings to overwrite/update existing values
                  //
                  if (newButton) {
                    newButton = { ...newButton, ...subItem?.settings };
                    //console.log("onDragEnd newButton ", newButton);
                    newItem.namedArea.button = newButton;
                  }
                  // save the updated items
                  updatedNewDestSubItems.push(newItem);
                });

                newItems[i].subItems[j].subItems = updatedNewDestSubItems; // newDestSubItems;
              }
            }
          }
        }

        this.setStateNewItems(newItems);
        //this.setState({items: newItems});

        //console.log("onDragEnd newItems", newItems);
      }
      // console.log("onDragEnd newItems", JSON.stringify(newItems));
    }
  };

  findItem = (items, id) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and return object === id

    let findItem = {};

    let i = 0;
    // groups
    items.forEach((item) => {
      if (item.id === id) {
        findItem = item;
      } else {
        // levels - subItems
        let j = 0;
        item.subItems.forEach((item) => {
          if (item.id === id) {
            findItem = item;
          } else {
            // named areas - subSubItems
            let k = 0;
            item.subItems.forEach((item) => {
              if (item.id === id) {
                findItem = item;
              } else {
                k = k + 1;
              }
            });
            j = j + 1;
          }
        });
        i = i + 1;
      }
    });
    return findItem;
  };

  updateItem = (items, newItem) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and replace object by id

    const { id } = newItem;

    let newItems = [];

    let i = 0;
    // groups
    items.forEach((item) => {
      if (item.id !== id) {
        // levels - subItems
        let newSubItem = [];
        let j = 0;
        item.subItems.forEach((item) => {
          if (item.id !== id) {
            // named areas - subSubItems
            let newSubSubItem = [];
            let k = 0;
            item.subItems.forEach((item) => {
              if (item.id !== id) {
                newSubSubItem[k] = item;
              } else {
                newSubSubItem[k] = newItem;
              }
              k = k + 1;
            });
            newSubItem[j] = item;
            newSubItem[j].subItems = newSubSubItem;
          } else {
            const prevSettings = item?.settings;
            newSubItem[j] = newItem;
            const settings = newItem.settings;
            // if subItem (i.e. priority) settings changed update subItems.namedArea.button
            if (!_isEqual(prevSettings, settings)) {
              //console.log("settings changed");
              newSubItem[j].settings = MergeSettings(prevSettings, settings);
              // update subItems
              newSubItem[j].subItems.map(
                (item) =>
                  (item.namedArea.button = MergeSettings(
                    item.namedArea.button,
                    settings
                  ))
              );
            }
          }
          j = j + 1;
        });
        newItems[i] = item;
        newItems[i].subItems = newSubItem;
      } else {
        newItems[i] = newItem;
      }
      i = i + 1;
    });
    return newItems;
  };

  setStateNewItems = (newItems) => {
    this.setState({
      items: newItems,
    });
  };

  deleteItem = (items, id) => {
    // object structure is three levels deep
    // all id are unique
    // loop over all levels and return !== id

    let newItems = [];

    let i = 0;
    // groups
    items.forEach((item) => {
      if (item.id !== id) {
        // levels - subItems
        let newSubItem = [];
        let j = 0;
        item.subItems.forEach((item) => {
          if (item.id !== id) {
            // named areas - subSubItems
            let newSubSubItem = [];
            let k = 0;
            item.subItems.forEach((item) => {
              if (item.id !== id) {
                newSubSubItem[k] = item;
                k = k + 1;
              }
            });
            newSubItem[j] = item;
            newSubItem[j].subItems = newSubSubItem;
            j = j + 1;
          }
        });
        newItems[i] = item;
        newItems[i].subItems = newSubItem;
        i = i + 1;
      }
    });

    return newItems;
  };

  onClickHandler = (e, type, id, groupId, data) => {
    // #NOTE -
    // * 'data' was added to the structure to pass misc. data around between
    // subsections, but has not been used so 'null' is passed in all cases.

    //console.log("onClickHandler e", e);
    if (e.preventDefault) {
      e.preventDefault();
    }

    if (e.stopPropagation) {
      e.stopPropagation(); // stop submit to calling form
    }

    // console.log(
    //   "drag onClickHandler -  type:",
    //   type,
    //   " id:",
    //   id,
    //   " groupId:",
    //   groupId,
    //   " data:",
    //   data
    // );

    const { items } = this.state;
    const cloneItems = !_isEmpty(items)
      ? JSON.parse(JSON.stringify(items))
      : [];

    //console.log("cloneItems", cloneItems);

    let newCollapse = this.state.collapse;
    let newCollapseAll = this.state.collapseAll;
    let newItems = [];

    const openSettingsModal = (items, id) => {
      // console.log("openSettingsModal items", items);
      // console.log("openSettingsModal id", id);
      const findItem = this.findItem(items, id);
      //console.log("openSettingsModal findItem", findItem);

      this.setState({
        isOpenSettingsModal: true,
        modalSettings: findItem,
      });
    };

    const buttonTemplate = (itemId, namedAreaId) => {
      const obj = {
        //id: itemId,
        //named_area: namedAreaId,
        clickable: true,
        icon: "icon-earthquake123",
        priority: 0,
        title: "Seismic 1",
        alt: "Level 1",
        color: "red",
        group: 0,
        type: "SEISMIC1_EVENT",
        hint: "Level 0",
        default: true,
        active_state: "on",
        active_color: "red",
        active_brightness_level: 1,
        state: "on",
        on_time: 0,
        off_time: 0,
        train: 0,
        active: true,
        marker: "RoundMarker",
        siren_state: "off",
        origin: "clientOrigin2",
        operator: "clientOperator2",
        permission: 0,
      };
      return obj;
    };

    switch (type) {
      case "add":
        const newId = uuid();
        const newId2 = uuid();

        if (groupId === "group") {
          const newGroup = {
            id: newId,
            label: "New group",
            // automatically insert a default level for each new group
            subItems: [
              {
                id: newId2,
                parent: newId,
                label: "New level",
                subItems: [],
                settings: buttonTemplate(newId2, "placeholder"),
              },
            ],
          };
          newItems = [...cloneItems, newGroup];

          // if this is the 1st group added, don't collapse
          if (_isEmpty(cloneItems)) {
            newCollapseAll = false;
          }
        } else if (groupId === "level") {
          const newLevel = {
            id: newId,
            parent: id,
            label: "New level",
            subItems: [],
            settings: buttonTemplate(newId, "placeholder"),
          };

          // update items state based on id (parent)
          cloneItems.forEach((item, idx) => {
            newItems[idx] = item;
            if (item.id === id) {
              newItems[idx].subItems = [...item.subItems, newLevel];
            }
          });
        }

        this.setStateNewItems(newItems);
        //this.setState({items: newItems});

        //console.log("add newItems", newItems);

        // pop modal dlg to edit item label
        openSettingsModal(newItems, newId);

        break;

      case "deleteItem":
        // object structure is three levels deep
        // all id are unique
        // search over all levels and delete id

        newItems = this.deleteItem(cloneItems, id);

        this.setStateNewItems(newItems);
        //this.setState({ items: newItems });

        break;
      case "collapse":
        // collapse does not change item state
        newItems = cloneItems;

        const groupIds = cloneItems.map((item) => item.id);

        if (id === "all") {
          if (!this.state.collapseAll) {
            newCollapse = groupIds;
            newCollapseAll = true;
          } else {
            newCollapse = [];
            newCollapseAll = false;
          }
        } else {
          if (newCollapse.includes(id)) {
            newCollapse = newCollapse.filter((item) => item !== id);
            newCollapseAll = false;
          } else {
            newCollapse.push(id);

            // check if all collapsed?
            if (
              newCollapse.sort().join(",") === groupIds.sort().join(",") &&
              !_isEmpty(newCollapse)
            ) {
              newCollapseAll = true;
            } else {
              newCollapseAll = false;
            }
          }
        }
        this.setState({ collapse: newCollapse });
        this.setState({ collapseAll: newCollapseAll });
        break;
      case "cog":
        openSettingsModal(cloneItems, id);

        // cog does not change item state - see `handleSettingsSave`
        newItems = cloneItems;

        this.setStateNewItems(newItems);
        //this.setState({ items: newItems });
        break;

      default:
        break;
    }

    //console.log("newItems", JSON.stringify(newItems));
    //console.log("newCollapse", newCollapse);
    //console.log("newCollapseAll", newCollapseAll);
  };

  resetModal = () => {
    this.setState({ isOpenSettingsModal: false });
  };

  handleSettingsSave = (values) => {
    const { items } = this.state;
    const cloneItems = JSON.parse(JSON.stringify(items));
    const newItems = this.updateItem(cloneItems, values);

    console.log("handleSettingsSave newItems", newItems);

    this.setStateNewItems(newItems);
    //this.setState({ items: newItems });

    // close modal
    this.setState({ isOpenSettingsModal: false });
  };

  render() {
    const {
      NAMEDAREAS,
      items,
      collapse,
      collapseAll,
      collapseLevel,
      modalSettings,
      isOpenSettingsModal,
    } = this.state;

    const { strings, mineLevelId, userSettings } = this.props; // localisation

    // APP_TERMINOLOGY
    let strNamedAreas = strings?.["Polygons"];

    // sort the NAMEDAREAS list by "label"
    const sortAlphaNum = (a, b) =>
      a?.label?.localeCompare(b?.label, "en", { numeric: true });
    NAMEDAREAS.sort(sortAlphaNum);

    return (
      <ContainerDimensions>
        {({ width, height }) => (
          <>
            {this.state.isOpenSettingsModal && (
              <SettingsModal
                open={isOpenSettingsModal}
                initialValues={modalSettings}
                areaId={mineLevelId}
                handleSettingsSave={(values) => this.handleSettingsSave(values)}
                resetModal={() => this.resetModal(false)}
                strings={strings}
                userSettings={userSettings}
              />
            )}

            <DragDropContext
              onDragStart={this.onDragStart}
              onDragEnd={this.onDragEnd}
            >
              <Grid
                columns={2}
                style={
                  width > 1400
                    ? { width: "1200px", margin: "auto" }
                    : { width: "80%", margin: "auto" }
                }
              >
                <Grid.Column style={{ flex: "0 0 400px" }}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{strNamedAreas}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Droppable
                      droppableId="NAMEDAREAS"
                      isDropDisabled={true}
                      type={"droppableSubSubItem"}
                    >
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                            style={getListStyle(
                              "lightgreen",
                              snapshot.isDraggingOver
                            )}
                          >
                            {NAMEDAREAS.map((item, index) => (
                              <Draggable
                                draggableId={item.id}
                                index={index}
                                key={item.id}
                              >
                                {(provided, snapshot) => (
                                  <>
                                    <Ref innerRef={provided.innerRef}>
                                      <Table.Row
                                        {...provided.draggableProps}
                                        // {...provided.dragHandleProps} -> moved to <Icon name="bars" ... />
                                        isDragging={snapshot.isDragging}
                                        style={getItemStyle(
                                          "rgba(144,238,144,1)",
                                          //"lightgreen",
                                          "rgba(144,238,144,0.2)",
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <Table.Cell width={16}>
                                          <Popup
                                            content={
                                              strings?.["Drag Polygon to Level"]
                                            }
                                            trigger={
                                              <Icon
                                                name="bars"
                                                {...provided.dragHandleProps}
                                              />
                                            }
                                          />
                                          <strong>{item.label}</strong>
                                        </Table.Cell>
                                      </Table.Row>
                                    </Ref>
                                  </>
                                )}
                              </Draggable>
                            ))}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </Table>
                </Grid.Column>
                <Grid.Column style={{ flex: "1" }}>
                  {/* #NOTE - no longer used but kept here JIC want to restore warning in future */}
                  {/* <Message icon color="red">
                    <Icon name="warning sign" />
                    <Message.Content>
                      Saving changes to the Control Layout will clear all
                      current light states for all Areas! Re-enable all lights
                      on polygons after saving changes to the Control layout.
                    </Message.Content>
                  </Message> */}
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        {items.length !== 0 && (
                          <Table.HeaderCell>
                            <Popup
                              content={
                                strings?.["Click to expand/collapse all Groups"]
                              }
                              trigger={
                                <Icon
                                  name={!collapseAll ? "folder open" : "folder"}
                                  onClick={(e) =>
                                    this.onClickHandler(
                                      e,
                                      "collapse", // type
                                      "all", // id
                                      0, // index
                                      null //data
                                    )
                                  }
                                />
                              }
                            />
                          </Table.HeaderCell>
                        )}
                        <Table.HeaderCell
                          width={items.length !== 0 ? 13 : 15}
                          colSpan={3}
                        >
                          {strings?.["Groups"]}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Popup
                            content={strings?.["Add Group"]}
                            trigger={
                              <Button
                                icon
                                size="mini"
                                color="pink"
                                onClick={(e) => {
                                  //console.log("onClick!!! Add");

                                  this.onClickHandler(
                                    e,
                                    "add", // type
                                    "group", // id
                                    "group", // index
                                    null
                                  );
                                }}
                              >
                                <Icon name="plus" />
                              </Button>
                            }
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Droppable
                      key={"group"}
                      droppableId="droppable"
                      type="droppableItem"
                    >
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Body
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                            style={getListStyle(
                              "lightpink",
                              snapshot.isDraggingOver
                            )}
                          >
                            {items.length ? (
                              items.map((item, index) => (
                                <Draggable
                                  draggableId={item.id}
                                  index={index}
                                  key={item.id}
                                >
                                  {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                      <>
                                        <Table.Row
                                          {...provided.draggableProps}
                                          // {...provided.dragHandleProps} -> moved to <Icon name="bars"... />
                                          isDragging={snapshot.isDragging}
                                          style={getItemStyle(
                                            "rgba(255,182,193,1)",
                                            //"lightpink",
                                            "rgba(255,182,193,0.2)",
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <Table.Cell>
                                            <Popup
                                              content={
                                                strings?.[
                                                  "Click to expand/collapse the Group"
                                                ]
                                              }
                                              trigger={
                                                <Icon
                                                  name={
                                                    !collapseAll &&
                                                    !collapse.includes(item.id)
                                                      ? "folder open"
                                                      : "folder"
                                                  }
                                                  onClick={(e) =>
                                                    this.onClickHandler(
                                                      e,
                                                      "collapse", // type
                                                      item.id, // id
                                                      "level", // group
                                                      null
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          </Table.Cell>
                                          <Table.Cell width={15}>
                                            <Popup
                                              content={
                                                strings?.[
                                                  "Drag Group to reorder"
                                                ]
                                              }
                                              trigger={
                                                <Icon
                                                  name="bars"
                                                  {...provided.dragHandleProps}
                                                />
                                              }
                                            />
                                            <strong>{item.label}</strong>
                                            {/* {` id: ${item.id}`}
                                        {` draggingOver: ${snapshot.draggingOver}`}
                                        {` isDragging: ${snapshot.isDragging}`} */}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {!collapseAll &&
                                              !collapse.includes(item.id) && (
                                                <Popup
                                                  content={
                                                    strings?.["Add Level"]
                                                  }
                                                  trigger={
                                                    <Button
                                                      icon
                                                      color="blue"
                                                      size="mini"
                                                      onClick={(e) =>
                                                        this.onClickHandler(
                                                          e,
                                                          "add",
                                                          item.id,
                                                          "level",
                                                          null
                                                        )
                                                      }
                                                    >
                                                      <Icon name="plus" />
                                                    </Button>
                                                  }
                                                />
                                              )}
                                          </Table.Cell>
                                          <Table.Cell>
                                            <Popup
                                              content={
                                                strings?.["Edit Group settings"]
                                              }
                                              trigger={
                                                <Icon
                                                  name="cog"
                                                  onClick={(e) =>
                                                    this.onClickHandler(
                                                      e,
                                                      "cog",
                                                      item.id,
                                                      "level",
                                                      null
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          </Table.Cell>
                                          <Table.Cell>
                                            <Popup
                                              content={
                                                strings?.["Delete Group"]
                                              }
                                              trigger={
                                                <Icon
                                                  name="trash"
                                                  onClick={(e) =>
                                                    this.onClickHandler(
                                                      e,
                                                      "deleteItem", // type
                                                      item.id, // id
                                                      "level",
                                                      null
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          </Table.Cell>
                                        </Table.Row>
                                        {!collapseAll &&
                                          !snapshot?.isDragging &&
                                          !collapse.includes(item.id) && (
                                            <Table.Row>
                                              <Table.Cell colSpan={5}>
                                                <NamedAreaGroupTableSubItems
                                                  NAMEDAREAS={
                                                    this.state.NAMEDAREAS
                                                  }
                                                  items={item.subItems}
                                                  groupId={item.id}
                                                  onClickHandler={(
                                                    e,
                                                    type,
                                                    id,
                                                    index,
                                                    data
                                                  ) =>
                                                    this.onClickHandler(
                                                      e,
                                                      type,
                                                      id,
                                                      index,
                                                      data
                                                    )
                                                  }
                                                  collapseAll={collapseLevel}
                                                  strings={strings}
                                                  userSettings={userSettings}
                                                />
                                              </Table.Cell>
                                            </Table.Row>
                                          )}
                                      </>
                                    </Ref>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <Table.Row>
                                <Table.Cell>
                                  {strings?.["No Groups. Add a group."]}
                                </Table.Cell>
                              </Table.Row>
                            )}
                            {!_isEmpty(provided.placeholder) &&
                              snapshot.isDraggingOver && (
                                <Table.Row>
                                  <Table.Cell
                                    colSpan={16}
                                    style={getListStyle(
                                      "lightpink",
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {provided.placeholder}
                                  </Table.Cell>
                                </Table.Row>
                              )}
                          </Table.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </Table>
                </Grid.Column>
              </Grid>
            </DragDropContext>
          </>
        )}
      </ContainerDimensions>
    );
  }
}
