import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as Api from "api/apiArea";
import { SubmissionError, startSubmit, stopSubmit } from "redux-form";
import { push } from "react-router-redux";
import { convertFetchAreasToMqtt } from "MineLevels/convertFetchAreasToMqtt";
import { isMqttNotApiPost } from "components/ConfigJs";

export function convertMineLevelsAPI(mineLevels) {
  return mineLevels.reduce(
    (acc, level) => {
      acc.byId[level.id] = level;
      acc.order.push(level.id);
      return acc;
    },
    { byId: {}, order: [] }
  );
}

// worker Saga: will be fired on MINE_LEVELS_FETCH_REQUESTED actions
function* fetchMineLevels(action) {
  console.log(`MINE_LEVELS_FETCH_REQUESTED`);
  try {
    const data = yield call(Api.fetchMineLevels, null);

    console.log(`MINE_LEVELS_FETCH_SUCCEEDED data `, data);

    // fetchMineLevels/get_all_areas contains data for many states:
    // * areaInfos -> MQTT_AREA_INFOS_UPDATE
    // * areaStatuses -> MQTT_AREA_STATUSES_UPDATE

    const { areaInfos, areaStatuses } = convertFetchAreasToMqtt(data);

    console.log(`MINE_LEVELS_FETCH_SUCCEEDED areaInfos`, areaInfos);

    yield put({
      type: "MQTT_AREA_INFOS_UPDATE",
      payload: areaInfos,
    });

    console.log(`MINE_LEVELS_FETCH_SUCCEEDED areaStatuses`, areaStatuses);

    yield put({
      type: "MQTT_AREA_STATUSES_UPDATE",
      payload: areaStatuses,
    });

    // used to trigger update to worker with fetch complete
    yield put({
      type: "MINE_LEVELS_FETCH_SUCCEEDED",
      payload: {},
    });
  } catch (e) {
    yield put({ type: "MINE_LEVELS_FETCH_FAILED", message: e.message });
  }
}

function* handleSaveNewMineLevel(action) {
  const { values, resolve, reject } = action.payload;

  try {
    const response = yield Api.saveNewMineLevel(values);
    //console.log("handleSaveNewMineLevel response", response);
    resolve(response);
  } catch (error) {
    //console.log("handleSaveNewMineLevel error:", error);

    // const formError = new SubmissionError({
    //   _error: error.response.data || "There was an error submitting",
    // });
    // const formError = new SubmissionError({
    //   _error: "There was an error submitting",
    // });
    // reject(formError);

    // console.log("handleSaveNewMineLevel formError", formError);

    //const formId = "createNewMineLevelForm";
    // #REVIEW - ? stopSubmit()?
    // This does not seem to work, and is not necessary
    // if catch() in Promise @ saveNewMineLevel
    // see - src/admin/mine-level/MineLevelCreatePage.js
    // https://www.codota.com/code/javascript/functions/redux-form/stopSubmit
    //yield put(stopSubmit(formId, {}));

    reject(error);
    // reject({
    //   error: error?.response?.data || error || "There was an error submitting",
    // });
  }
}

function* handleUpdateMineLevel(action) {
  const { values, resolve, reject } = action.payload;

  try {
    const response = yield Api.updateMineLevel(values);
    resolve(response);
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.response.data || "There was an error submitting",
    });
    reject(formError);
  }
}

// Issues passing via redux-form
// function* deleteMineLevel(action) {
//   const { values, resolve, reject } = action.payload;
//   const { id } = values;
//   try {
//     console.log("deleteMineLevel");
//     yield call(Api.deleteMineLevel, id);
//     yield put({
//       type: "MINE_LEVEL_DELETE_SUCCESS",
//       payload: { id },
//     });
//     resolve();
//   } catch (error) {
//     yield put({ type: "MINE_LEVEL_DELETE_FAILED", message: error.message });
//     const formError = new SubmissionError({
//       _error: error.response.data || "There was an error deleting",
//     });
//     reject(formError);
//   }
// }

// function* deleteMineLevel(action) {
//   const { values, resolve, reject } = action.payload;
//   const { id } = values;

//   try {
//     console.log("@@ saga deleteMineLevel id", id);
//     yield Api.deleteMineLevel(id);
//     yield put({
//       type: "MINE_LEVEL_DELETE_SUCCESS",
//       payload: { id },
//     });
//     resolve();
//   } catch (error) {
//     yield put({ type: "MINE_LEVEL_DELETE_FAILED", message: error.message });
//     reject(error.message);
//   }
// }

// // #REVIEW - DO NOT USE BELOW
// function* deleteMineLevel_reduxForm(action) {
//   const { values, resolve, reject } = action.payload;
//   const { id } = values;

//   console.log("@@ deleteMineLevel_reduxForm", id);

//   try {
//     yield call(Api.deleteMineLevel, id);
//     yield put({
//       type: "MINE_LEVEL_DELETE_SUCCESS",
//       payload: { id },
//     });
//     resolve();
//   } catch (error) {
//     yield put({ type: "MINE_LEVEL_DELETE_FAILED", message: error.message });
//     const formError = new SubmissionError({
//       _error: error.response.data || "There was an error deleting",
//     });
//     reject(formError);
//   }
// }

function* handleDeleteMineLevel(action) {
  const { values, resolve, reject } = action.payload;
  const { id } = values;

  try {
    const response = isMqttNotApiPost()
      ? yield Api.deleteMineLevel(id)
      : yield Api.deleteMineLevelPost(id);
    yield put({
      type: "MINE_LEVEL_DELETE_SUCCESS",
      payload: { id },
    });
    resolve(id);
  } catch (error) {
    yield put({ type: "MINE_LEVEL_DELETE_FAILED", message: error });
    reject(error);
  }
}

function* mineLevelSaga() {
  yield takeLatest("MINE_LEVELS_FETCH_REQUESTED", fetchMineLevels);
  yield takeEvery("SAVE_NEW_MINE_LEVEL", handleSaveNewMineLevel);
  yield takeEvery("UPDATE_MINE_LEVEL", handleUpdateMineLevel);
  yield takeEvery("DELETE_MINE_LEVEL", handleDeleteMineLevel);
}

export default mineLevelSaga;
