import React, { Component } from 'react'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import { connect } from 'react-redux'
import MineLevelMenuItem from 'components/MineLevelMenuItem'

import { getAllMineLevels } from 'MineLevels/reducer'
import { fetchMineLevels } from 'MineLevels/actions'

export const MineLevelMenus = ({ ...props }) => {
  const { levels, dispatch, ...rest } = props
  if (levels.length === 0) {
    return <Menu.Item>No levels configured</Menu.Item>
  }
  return (
    <Menu {...rest}>
      {levels.map(level => (
        <MineLevelMenuItem
          key={`mine-level-menu-item-${level.id}`}
          level={level}
        />
      ))}
    </Menu>
  )
}

export function mapStateToProps (state) {
  const levels = getAllMineLevels(state)

  return { levels }
}

const ConnectedMineLevelMenus = connect(mapStateToProps)(MineLevelMenus)

function actionOnMount (Wrapped, action) {
  class Wrapper extends Component {
    componentDidMount () {
      const actionObject = typeof action === 'function' ? action() : action
      this.props.dispatch(actionObject)
    }
    render () {
      return <Wrapped {...this.props} />
    }
  }
  return connect()(Wrapper)
}

export default actionOnMount(ConnectedMineLevelMenus, fetchMineLevels)
