import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Container } from "semantic-ui-react";
import FlashMessagesList from "FlashMessages";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import { saveNewNamedArea } from "NamedAreas/actions";

//import NamedAreaForm from "./NamedAreaForm";

import CreateNamedAreaForm from "admin/named-area/NamedAreaCreateForm";

import SubmissionModal from "admin/SubmissionModal";

import {
  SubmissionError,
  //
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
  getFormMeta,
  getFormAsyncErrors,
  getFormSyncWarnings,
  getFormSubmitErrors,
  getFormError,
  getFormNames,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from "redux-form";

import { Dimmer, Loader } from "semantic-ui-react";

import { v4 as uuid } from "uuid";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import NavigationPromptModal from "admin/NavigationPromptModal";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

class CreateNamedArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      submitted: false,
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  onResetError = () => {
    // console.log(
    //   "onResetError this.state.errorMessage",
    //   this.state.errorMessage
    // );
    this.setState({ errorMessage: "" });
  };

  onSetError = (err) => {
    //    console.log("onSetError", err);
    this.setState({ errorMessage: err });
  };

  submitMyForm = (values) => {
    const { saveNewNamedArea } = this.props;

    return new Promise((resolve, reject) => {
      console.log("values", values);
      //saveNewNamedArea({ values, resolve, reject });
    })
      .then(() => {
        //this.onResetError();

        this.props.fetchNamedAreas();
        this.props.fetchNamedAreaEvents();
        this.props.goto(`/admin/named-area-advanced`);
      })
      .catch((error) => {
        console.log(`onSubmit error`, error);
        this.onSetError("failed");
        // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
        throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
      });
  };

  render() {
    const { saveNewNamedArea, submitting, error } = this.props;
    const { errorMessage } = this.state;

    const openModal = errorMessage !== "";

    if (error) {
      return (
        <>
          <DebugPagePropsMessages that={this} />
        </>
      );
    }

    return (
      <>
        <SubmissionModal
          open={openModal}
          onResetError={() => this.onResetError()}
          errorMessage={errorMessage}
        />
        <Dimmer active={submitting} page>
          <Loader>Saving changes</Loader>
        </Dimmer>

        {/* #NOTE - NavigationPromptModal is disabled for advanced named areas because it does
        not currently support form submit. */}

        {/* <NavigationPromptModal
          formName={"createNewNamedAreaForm"}
          submitted={this.state.submitted}
          onSubmit={(formValues) => this.submitMyForm(formValues)}
        /> */}
        <CreateNamedAreaForm
          id={uuid()}
          onSubmit={(values) => this.submitMyForm(values)}
          // #NOTE - disabled as this is fired as soon as submit,
          // *not* after successful completion of the Promise!!
          onSubmitSuccess={(result, dispatch) => {
            //console.log(`onSubmitSuccess ${result}`);
            // wait for state change before redirecting page
            this.setState(
              {
                submitted: true,
              },
              () => {
                this.onResetError();
                this.props.goto("/admin/named-area-advanced");
              }
            );
          }}
          onSubmitFail={(errors) => {
            console.log(`onSubmitFail ${errors}`);
          }}
          onCancel={() => {
            this.props.goto("/admin/named-area-advanced");
          }}
        />
      </>
    );
  }
}

// when props.redirect is called, dispatch the push method
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    goto: (path) => dispatch(push(path)),
    //    saveNewNamedArea: (data) => dispatch(saveNewNamedArea(data)), // #WIP
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

export default connect(
  (state) => ({
    // values: getFormValues("createNewMineLevelForm")(state),
    // initialValues: getFormInitialValues("createNewMineLevelForm")(state),
    // formSyncErrors: getFormSyncErrors("createNewMineLevelForm")(state),
    // fields: getFormMeta("createNewMineLevelForm")(state),
    // formAsyncErrors: getFormAsyncErrors("createNewMineLevelForm")(state),
    // syncWarnings: getFormSyncWarnings("createNewMineLevelForm")(state),
    // submitErrors: getFormSubmitErrors("createNewMineLevelForm")(state),
    // formError: getFormError("createNewMineLevelForm")(state),
    // names: getFormNames()(state),
    // dirty: isDirty("createNewMineLevelForm")(state),
    // pristine: isPristine("createNewMineLevelForm")(state),
    // valid: isValid("createNewMineLevelForm")(state),
    // invalid: isInvalid("createNewMineLevelForm")(state),
    submitting: isSubmitting("createNewNamedAreaForm")(state),
    // submitSucceeded: hasSubmitSucceeded("createNewMineLevelForm")(state),
    // submitFailed: hasSubmitFailed("createNewMineLevelForm")(state),
  }),
  mapDispatchToProps
)(CreateNamedArea);
