import React, { Component } from "react";
import { push } from "react-router-redux";

import { Container, Header, Grid, Message } from "semantic-ui-react";

import { connect } from "react-redux";

import UserEditForm from "components/User/UserEditForm";

import FlashMessagesList from "FlashMessages";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";
import DataLoadingMessage from "components/DataLoading/dataLoading";

import { SubmissionError } from "redux-form";

import { saveUser } from "components/UserAdmin/actions";

import { getLocalisationByComponentId } from "components/Localisation/reducer";

import _isEmpty from "lodash/isEmpty";

import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

// localisation
import { getUserData } from "auth/reducer";
import { getUserById } from "components/UserAdmin/reducer";
// import LocalizedStrings from "react-localization";

// let strings = new LocalizedStrings({
//   "en-US": {
//     "User Profile": "User Profile",
//   },
// });

class UserEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //language: "en-US", // localisation
    };
  }

  componentDidMount() {
    // turn off data collection
    this.props.TurnOnOffPokeTheWorker(false);

    // const { localisedTerms  } = this.props;
    // if (!_isEmpty(localisedTerms)) {
    //   strings.setContent(this.props.localisedTerms);
    //   this.setState({ language: this.props.thisUserLang });
    //   strings.setLanguage(this.props.thisUserLang);
    //   console.log(
    //     "xxx UserEditPage -  language set to ",
    //     this.props.thisUserLang
    //   );
    // }
  }

  componentWillUnmount() {
    // turn on data collection
    this.props.TurnOnOffPokeTheWorker(true);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   // this.props.localisedTerms !== prevProps.localisedTerms ||
    //   this.props.thisUserLang !== prevProps.thisUserLang
    // ) {
    //   //strings.setContent(this.props.localisedTerms);
    //   this.setState({ language: this.props.thisUserLang });
    //   //strings.setLanguage(this.state.language);
    //   strings.setLanguage(this.props.thisUserLang);
    //   console.log(
    //     "xxx UserEditPage -  language changed to ",
    //     this.props.thisUserLang
    //   );
    // }
  }

  // https://stackoverflow.com/questions/40741743/how-to-implement-localization-in-reactjs
  // handleLanguageChange = (e) => {
  //   e.preventDefault();
  //   let lang = e.target.value;
  //   this.setState((prevState) => ({
  //     language: lang,
  //   }));
  // };

  render() {
    const { id, dataError, strings } = this.props;

    if (dataError) {
      this.props.goto(""); // redirect to home page
      // #NOTE #TODO - should pop a dlg if this happens
      return null;
      //{strings["   return (
      //   <>
      //     <DataLoadingMessage id={id} strings={strings}/>
      //     <DebugPagePropsMessages that={this} />
      //   </>
      // );
    }

    // console.log("xxx UserEditPage - strings", strings);

    return (
      <div className="genericGridHeader">
        <Container>
          <Grid columns={2}>
            <Grid.Row className={"genericTitleHeader"}>
              <Grid.Column width={4} textAlign={"left"}>
                <Header as="h1">{strings?.["User Profile"]}</Header>
              </Grid.Column>
              <Grid.Column width={12} textAlign={"left"}>
                <FlashMessagesList />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
          </Grid>
          <UserEditForm
            id={id}
            strings={strings}
            // onSubmit={(values) => {
            //   return new Promise((resolve, reject) => {
            //     console.log("UserEditPage values", values);

            //     // set email as `id`
            //     const { email: id } = values;
            //     const newValues = { id, ...values };

            //     this.props.saveUser({ values: newValues, resolve, reject });
            //   }).catch((error) => {
            //     console.log(`onSubmit ${error}`);
            //     //this.onSetError("failed");
            //     // see https://redux-form.com/6.1.1/docs/api/submissionerror.md/
            //     throw new SubmissionError(error.validationErrors); // required to catch as form submission error and prevent execute of onSubmitSuccess
            //   });
            // }}
            // onSubmitSuccess={(result, dispatch) => {
            //   console.log(`onSubmitSuccess ${result}`);
            //   this.props.goto("/user/profile/admin");
            // }}
            // onSubmitFail={(errors) => {
            //   console.log(`onSubmitFail ${errors}`);
            // }}
            // onCancel={() => {
            //   this.props.goto("/user/profile/admin");
            // }}
          />
          <DebugPagePropsMessages that={this} />
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    saveUser: (data) => dispatch(saveUser(data)),
    goto: (path) => dispatch(push(path)),
    TurnOnOffPokeTheWorker: (action) =>
      dispatch(TurnOnOffPokeTheWorker(action)),
  };
};

function mapStateToProps(state, props) {
  const match = props.match;
  const id = match.params.id;

  // get language from most recent fetch to avoid need to log out/in
  const thisUser = getUserData(state);
  const { email: thisUserId } = thisUser;
  const thisUserLang = getUserById(state, thisUserId)?.language || "en-US";

  //console.log("xxxx UserEditPage thisUserLang", thisUserLang);

  //const localisedTerms = getLocalisationByComponentId(state, "UserEditPage");

  // console.log(`xxxx UserPages props`, props);
  //console.log("xxxx thisUser", thisUser);

  const dataError = id ? undefined : "No User with this email (id)";

  return {
    thisUserLang,
    //localisedTerms,
    id,
    dataError,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);
