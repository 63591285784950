import Immutable from "seamless-immutable";
import { LOCATION_CHANGE } from "react-router-redux";

const initialState = Immutable([]);

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case "CONTROL_CHANNEL_ESTABLISHED":
      return Immutable(
        state.asMutable().filter(({ id }) => id !== "control-channel")
      );
    case "CONTROL_CHANNEL_LOST":
      return state.concat([
        {
          id: "control-channel",
          header: "Control lost",
          content: "Server has lost connection to MQTT broker",
          warning: true,
          // #NOTE - removed size to be consistent with other Flash messages. Search for `addWarningFlashMessageIdColor`
          //size: "small",
        },
      ]);

    case "SERVER_CONNECTION_OPENED":
      console.log("SERVER_CONNECTION_OPENED <--------------------------- 😎");
      return Immutable(
        state.asMutable().filter(({ id }) => id !== "server-connection")
      );
    case "SERVER_CONNECTION_CLOSED":
      console.log("SERVER_CONNECTION_CLOSED <---------------------------- 😫");
      const closedConnectionDisplayed = state.some(
        ({ id }) => id === "server-connection"
      );

      if (!closedConnectionDisplayed) {
        return state.concat([
          {
            id: "server-connection",
            header: "Connection to server lost",
            content: "The computer has lost connection to the server",
            warning: true,
            // #NOTE - removed size to be consistent with other Flash messages. Search for `addWarningFlashMessageIdColor`
            //size: "small",
          },
        ]);
      } else {
        return state;
      }

    case "ADD_FLASH_MESSAGE":
      //console.log("ADD_FLASH_MESSAGE");
      return state.concat([action.payload]);

    case "REMOVE_FLASH_MESSAGE":
      //console.log("REMOVE_FLASH_MESSAGE");
      return Immutable(
        //        state.asMutable().filter(({ id }) => id !== action.payload.id)
        state.asMutable().filter(({ id }) => !id.includes(action.payload.id))
      );
    case "CLEAR_FLASH_MESSAGES":
      return initialState;
    case LOCATION_CHANGE: // location change clears error message...hmmmm
      const tempStr =
        "#REVIEW - LOCATION_CHANGE - would normally reset errors to initialState. Disabled to retain across pages";
      console.log("FlashMessages/reducer: ", tempStr);
      return state;

    case "RESET":
      console.log("RESET! - FlashMessages");
      return initialState; //Always return the initial state
    default:
      return state;
  }
}

export function getAllMessages(state) {
  return state.flashMessages;
}
