import { getUserSessionIp, messageToken } from "utils/messageToken";

// This has a history...this is/was used to prepare mqtt messages to sent out 'name_area/id/change' message,
// but with performance issues and delays the /change messages were moved to
// POST api requests.
// However this same function is used for the POST by using the 'message' property in the POST request.
//

export const sendNamedAreaButtonUpdateByParent = (
  namedAreaStatuses,
  parentId,
  buttons
) => {
  const namedAreas = Object.values(namedAreaStatuses);
  const namedAreasByParentId = namedAreas
    .filter((namedArea) => namedArea.parent === parentId)
    .sort((a, b) => (a.priority > b.priority ? 1 : -1));

  if (false) {
    console.log("sendNamedAreaButtonUpdateByParent namedAreas", namedAreas);
    console.log("sendNamedAreaButtonUpdateByParent parentId", parentId);
    console.log(
      "sendNamedAreaButtonUpdateByParent namedAreasByParentId",
      namedAreasByParentId
    );
    console.log("sendNamedAreaButtonUpdateByParent buttons", buttons);
  }

  // send out backwards because controllers overlay based on order received

  let messagePayloads = [];

  namedAreasByParentId.reverse().forEach((namedArea, index) => {
    let namedAreaClone = JSON.parse(JSON.stringify(namedArea));
    let newButtons = [];

    //console.log("namedAreaClone", namedAreaClone);

    // massage named area to prep data for sending
    //
    // remove from msg
    delete namedAreaClone.status;
    delete namedAreaClone.Firefly_List;
    // change `coordinateUtm` to `shape`
    let shape;
    // NOTE - only supports a single polygon shape, not multi-, so get the first shape in coordinates array
    shape = namedAreaClone.coordinatesUtm[0];
    // check first point and last should be the same
    if (shape[0] !== shape[shape.length - 1]) {
      // or append first to end point to close the polygon ring
      shape = [...shape, shape[0]];
    }
    namedAreaClone.shape = shape;
    delete namedAreaClone.coordinatesUtm; // remove from msg
    // change `style` to a string
    namedAreaClone.style = JSON.stringify(namedAreaClone.style);
    // update the new buttons with the original area id
    buttons.forEach((button, idx) => {
      newButtons[idx] = buttons[idx];
      newButtons[idx].id = namedArea.id;
    });
    namedAreaClone.button = newButtons;

    //console.log("namedAreaClone", namedAreaClone);

    // prepare message token
    const userSessionIp = getUserSessionIp();
    const token = messageToken(userSessionIp);

    let updatedNamedArea = {
      ...namedAreaClone,
      precanned: 0, // not used ATM
      token: token,
    };

    //console.log("updatedNamedArea", updatedNamedArea);

    const namedAreaPayload = {
      topic: `named_area/${updatedNamedArea.id}/change`,
      qos: 0,
      message: updatedNamedArea,
      retained: false,
    };

    //console.log("namedAreaPayload", namedAreaPayload);

    messagePayloads.push(namedAreaPayload); //     channelBufferPublishMsg(eventPayload, "MQTT_PUBLISH");
  });

  return messagePayloads;
};
