import React, { Component } from "react";
import { connect } from "react-redux";

import {
  addErrorFlashMessage,
  clearFlashMessages,
} from "FlashMessages/actions";

export class CheckBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // loads header and message to display on control screen page
  displayErrors(errorType, extraMessage) {
    let header;
    let message;

    switch (errorType) {
      case "BROWSER_HAS_LIMITED_FUNCTIONALITY":
        header = "Browser Incompatible";
        message =
          "This browser does not support some functions. Please use Chrome.";
        break;
      default:
        break;
    }
    //this.props.clearFlashMessages(); // clear off previous messages
    this.props.addErrorFlashMessage(header, message || "Unknown problem");
    console.log(header + " - " + message);
  }

  componentDidMount() {
    this.props.checkBrowser();
  }
  componentDidUpdate() {
    // not in use - yet?
  }
  render() {
    const { isBrowerOfLimitedFunctionality } = this.props;
    if (isBrowerOfLimitedFunctionality) {
      this.displayErrors("BROWSER_HAS_LIMITED_FUNCTIONALITY", "");
    }

    return null;
  }
}

function mapStateToProps(state) {
  const { isBrowerOfLimitedFunctionality } = state.checkBrowser;
  return {
    isBrowerOfLimitedFunctionality,
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    checkBrowser: (checkBrowser) => {
      dispatch({ type: `CHECK_BROWSER` });
    },
    clearFlashMessages: () => {
      dispatch(clearFlashMessages());
    },
    addErrorFlashMessage: (error, extra) => {
      dispatch(addErrorFlashMessage(error, extra));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckBrowser);
