import {
  take,
  takeEvery,
  takeLatest,
  put,
  call,
  fork,
  cancel,
  cancelled,
} from "redux-saga/effects";
import * as Api from "api/apiSystem";
import { SubmissionError } from "redux-form";

function makeSaveFunction(apiCall) {
  return function* (action) {
    const { values, resolve, reject } = action.payload;
    const { id } = values;

    if (!id) {
      reject(
        new SubmissionError({
          _error: "Must provide id",
        })
      );
      return;
    }
    try {
      yield apiCall(values);
      resolve();
    } catch (error) {
      const formError = new SubmissionError({ _error: error.response.data });
      reject(formError);
    }
  };
}

function* fetchEventLog(action) {
  console.log(`EVENT_LOG_FETCH_REQUESTED`);
  try {
    const eventLog = yield call(Api.getEventLog);
    yield put({
      type: "EVENT_LOG_FETCH_SUCCEEDED",
      payload: { eventLog },
    });
  } catch (error) {
    console.log(`ERROR`, error);

    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "EVENT_LOG_FETCH_FAILED",
          payload: { error: "Event Log error", extra: data },
        });
      } else {
        yield put({
          type: "EVENT_LOG_FETCH_UNKNOWN",
          payload: { error: "Event Log error", extra: data },
        });
      }
    }
  }
}

function* fetchEventLogPeriod({ payload }) {
  //console.log(`EVENT_LOG_PERIOD_FETCH_REQUESTED`, payload);
  try {
    const eventLog = yield Api.getEventLogPeriod(payload);

    yield put({
      type: "EVENT_LOG_PERIOD_FETCH_SUCCEEDED",
      payload: eventLog,
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "EVENT_LOG_PERIOD_FETCH_FAILED",
          payload: { error: "Event Log error", extra: data },
        });
      } else {
        yield put({
          type: "EVENT_LOG_PERIOD_FETCH_UNKNOWN",
          payload: { error: "Event Log error", extra: data },
        });
      }
    }
  }
}

function* handleDeleteLog({ payload }) {
  console.log(`EVENT_LOG_DELETE_REQUESTED`, payload);
  try {
    const response = yield Api.deleteEventLog(payload);
    const { data } = response;

    yield put({
      type: "EVENT_LOG_DELETE_SUCCEEDED",
      payload: data,
    });
  } catch (error) {
    const status = error?.status;
    const data = error?.data;

    if (status && data) {
      if (status === 401) {
        yield put({
          type: "EVENT_LOG_DELETE_FAILED",
          payload: { error: "Event Log error", extra: data },
        });
      } else {
        yield put({
          type: "EVENT_LOG_DELETE_UNKNOWN",
          payload: { error: "Event Log error", extra: data },
        });
      }
    }
  }
}

function* eventLogSagas() {
  yield takeEvery("EVENT_LOG_FETCH_REQUESTED", fetchEventLog);
  yield takeEvery("EVENT_LOG_PERIOD_FETCH_REQUESTED", fetchEventLogPeriod);
  yield takeEvery("EVENT_LOG_DELETE_REQUESTED", handleDeleteLog);

  //
  // yield takeEvery(
  //   "EVENT_LOG_DELETE",
  //   makeSaveFunction(Api.changeLocalisation)
  // );
}

export default eventLogSagas;
