import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";
import { capitalize } from "utils/format-text";
import { LogIt } from "components/Logger/actions";
import {
  isConfigJs,
  faultFlash,
  faultMessageBannerDisplay,
  faultMessageBannerText,
} from "components/ConfigJs";

import {
  addWarningFlashMessageIdColor,
  removeFlashMessage,
  clearFlashMessages,
  SetMessageBanner,
} from "FlashMessages/actions";

//import { SetMessageBanner } from "components/AlarmButton/actions";

import { getLatestFaultTs, getMqttMsg } from "../WebWorker/reducer";

import cuid from "cuid";

// https://blog.lftechnology.com/using-svg-icons-components-in-react-44fbe8e5f91
// import { ReactComponent as Icon} from './icon.svg';
// <Icon />
// https://github.com/Semantic-Org/Semantic-UI-React/issues/931#issuecomment-263643210

export class AlarmButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // not in use - yet?
  }

  componentDidUpdate(prevProps, prevState) {
    // set a message banner if necessary ...
    this.setFaultMessageBanner(prevProps);
  }

  clickAction = (e) => {
    const { faultType, faultAction, mqttFaultTs } = this.props;

    this.props.LogIt("FAULTS_BUTTON_PRESSED > " + faultType);

    // for page reset button action
    if (faultAction === "reset") {
      // clear the alarm by setting the pageTS = mqttTS
      // i.e. PageFaultTs[faultType] = mqttFaultTs[faultType]
      // when button pushed, update state.alarmButton.faultLatestTs
      // via DISPATCH
      let tmpObj = {}; // make temporary object
      tmpObj.faultType = faultType;
      tmpObj.faultMaxFaultTs = mqttFaultTs[faultType];
      this.props.SetLatestFaultTs(tmpObj);

      // clear any MessageList banner which may be displayed
      this.clearFaultMessageBanner(faultType);
    }
  };

  clearFaultMessageBanner = (faultType) => {
    //    this.props.clearFlashMessages(); // clear off previous messages

    // get the id for the flash message
    const bannerId = this.faultMessageBannerId(faultType);
    if (bannerId) {
      this.props.removeFlashMessage(bannerId);
      //#REVIEW - make this a function..........
      // delete this banner message from state
      let tmpObj = {};
      tmpObj.faultType = faultType;
      tmpObj.display = false;
      tmpObj.id = "";

      this.props.SetMessageBanner(tmpObj);
    }
  };

  faultMessageBannerId = (faultType) => {
    // check if a message of this type exists

    // get the id for the flash message
    const messageBanners = this.props.messageBanners; // from redux state
    return messageBanners[faultType].id;
  };

  // determined highest fault level for the current faultType
  getHighestFaultLevel = (faultType) => {
    // get all faults reported
    let faults = this.props.mqttMsg;

    // filter out only faultType
    faults = faults.filter((item) => item.type === faultType);

    // reduces to set with highest fault level
    // probably > 1 fault
    faults.reduce = (prev, current) => {
      return prev.fault_level > current.fault_level ? prev : current;
    };

    // just need one - first one will do
    return faults[0].fault_level;
  };

  // set the color for the message banner
  // setColorMessageBanner = (faultType) => {
  //   const highestFaultLevel = this.getHighestFaultLevel(faultType);

  //   switch (highestFaultLevel) {
  //     case 3:
  //       return "red";
  //     case 2:
  //       return "pink";
  //     case 1:
  //       return "brown";
  //     default:
  //       return "brown";
  //   }
  // };

  setFaultMessageBanner = (prevProps) => {
    const { faultType, faultAction, pageFaultTs, mqttFaultTs } = this.props;
    // show 'alarm' state on button if *not* reset button
    if (faultAction !== "reset") {
      // if there are no mqttFaults, but there were (i.e. banner is being displayed), clear the banner.
      // This automatically clears the message banner if faults have been rectified
      if (
        prevProps.mqttFaultTs[faultType] >= mqttFaultTs[faultType] &&
        mqttFaultTs[faultType] === 0
      ) {
        this.clearFaultMessageBanner(faultType);
      }
      // if the current page faults timestamp (TS) is less than mqtt TS
      else if (pageFaultTs[faultType] < mqttFaultTs[faultType]) {
        // check if there is config for button styling
        if (isConfigJs() && faultMessageBannerDisplay()) {
          const isMessageBannerForFaultType = faultMessageBannerDisplay(); // from config.js file

          const messageBanners = this.props.messageBanners; // from redux state
          const bannerDisplayed = messageBanners[faultType].display; // deconstruct to a simpler obj

          // if this fault type allows message banner display (in config)
          // and there hasn't been a message banner displayed previously
          if (isMessageBannerForFaultType[faultType] && !bannerDisplayed) {
            // create a unique ID for the banner
            const id = cuid();

            // display a new banner
            this.displayErrors(id, faultType);

            // record the update state of banner including the id (needed to delete this particular banner)
            let tmpObj = {};
            tmpObj.faultType = faultType;
            tmpObj.display = true;
            tmpObj.id = id;

            this.props.SetMessageBanner(tmpObj);
          }
        }
      }
    }
  };

  // get the text for the fault message banner from config.js or set default
  getFaultMessageBannerText = (faultType) => {
    let header;
    let message;
    let color;

    if (isConfigJs() && faultMessageBannerText()) {
      const messageBannerText = faultMessageBannerText(); // from config.js file
      header = messageBannerText[faultType].header;
      message = messageBannerText[faultType].message;
      color = messageBannerText[faultType].color;
    } else {
      // default messages if config.js object is stuffed
      switch (faultType) {
        case "firefly":
          header = "FireFly Fault";
          message =
            "FireFly fault reported. Please address this fault immediately.";
          color = "red";
          break;
        case "battery":
          header = "Battery Fault";
          message =
            "Controller battery fault reported. Please address this fault immediately.";
          color = "red";
          break;
        case "network":
          header = "Network Fault";
          message =
            "Network fault reported. Please address this fault immediately.";
          color = "red";
          break;
        case "controller":
          header = "Controller Status Issue";
          message =
            "Controller Status Change reported. Please review this immediately.";
          color = "red";
          break;
        case "statusChecks":
          header = "Status Check Report";
          message =
            "Diagnostics have recommended actions to correct issues. Please review this immediately.";
          color = "red";
          break;
        default:
          break;
      }
    }

    return { header, message, color };
  };

  // loads header and message to display on control screen page
  // depending on significance of the error
  // All cases go to the Browser Logger page
  //
  displayErrors(id, faultType) {
    const { header, message, color } =
      this.getFaultMessageBannerText(faultType);

    // set color from highest fault_level for the faultType
    //    const color = this.setColorMessageBanner(faultType);

    this.props.addWarningFlashMessageIdColor(
      id,
      color,
      header,
      message || "Unknown problem"
    );
    //console.log("displayErrors - " + header + "-" + message);
  }

  getButtonStyle = () => {
    // possible button colours
    // ["red","orange","orange","olive","green","teal","blue","violet","purple","pink","brown","grey","black","facebook","google plus","instagram","linkedin","twitter","vk","youtube"].

    const { faultType, faultAction, pageFaultTs, mqttFaultTs } = this.props;
    // show 'alarm' state on button if *not* reset button
    if (faultAction !== "reset") {
      // and the current page faults timestamp (TS) is less than mqtt TS
      if (pageFaultTs[faultType] < mqttFaultTs[faultType]) {
        // check if there is config for button styling
        if (isConfigJs() && faultFlash()) {
          const buttonFlash = faultFlash();
          if (buttonFlash[faultType]) {
            // refer to custom.css for style class
            return "red flash-button";
          }
        }
        return "red";
      } else {
        return "";
      }
    }
  };

  getButtonClass = () => {
    return "flash-button";
  };

  // #WIP
  // custom icons
  //  const iconTest={{ className: '/images/firefly.png' }};
  // https://github.com/Semantic-Org/Semantic-UI-React/issues/931#issuecomment-263643210

  getButtonIcon = () => {
    const { faultType, faultAction } = this.props;

    // button for reset has 'repeat' icon
    if (faultAction === "reset") return "alarm mute";

    // otherwise set by type
    switch (faultType) {
      case "firefly":
        return "lightbulb";
      case "battery":
        return "battery half";
      case "network":
        return "sitemap";
      case "controller":
        return "hdd outline";
      case "statusChecks":
        return "lightbulb outline";
      case "external":
        return "level down alternate";
      default:
        return "lightbulb";
    }
  };

  getButtonIconRotate = () => {
    const { faultType } = this.props;

    // otherwise set by type
    switch (faultType) {
      case "external":
        return ""; //"counterclockwise"; // #NOTE - subsequently changed from `reply` to `level down alternate`
      default:
        return "";
    }
  };

  getButtonLink = () => {
    const { faultType, faultAction } = this.props;

    // button for reset does not link to another page!
    if (faultAction === "reset") return `#`;

    switch (faultType) {
      case "firefly":
        return `/status/faults/${faultType}`;
      case "battery":
        return `/status/faults/${faultType}`;
      case "network":
        return `/status/faults/${faultType}`;
      case "controller":
        return `/status/faults/${faultType}`;
      case "statusChecks":
        return `/status/firefly`;
      case "external":
        return `/status/firefly`;
      default:
        return `#`;
    }
  };

  getButtonTooltip = () => {
    const { faultType, faultAction, strings } = this.props;

    // button for reset is different
    if (faultAction === "reset")
      return `Clear ${capitalize(faultType)} alarm and row highlight`;

    switch (faultType) {
      case "firefly":
        //return `View ${capitalize(faultType)} faults`;
        return strings?.formatString(strings?.["View {fault_type} faults"], {
          fault_type: capitalize(faultType),
        });
      case "battery":
        //return `View FF controller ${capitalize(faultType)} faults`;
        return strings?.formatString(
          strings?.["View FireFly controller {fault_type} faults"],
          { fault_type: capitalize(faultType) }
        );
      case "network":
        // return `View ${capitalize(faultType)} faults`;
        return strings?.formatString(strings?.["View {fault_type} faults"], {
          fault_type: capitalize(faultType),
        });
      case "controller":
        return strings?.["View Controller Reports"];
      case "statusChecks":
        return strings?.["View FireFly Status"];
      case "external":
        return strings?.["View External Source Events"];
      default:
        return ``;
    }
  };

  render() {
    const buttonSize = this.props?.size || "large";
    return (
      // note - button spacing in header defined at
      // src/components/Header/index.js
      <Link to={this.getButtonLink()}>
        <Popup
          content={this.getButtonTooltip()}
          trigger={
            <Button
              // style={this.getButtonStyle()}
              icon
              size={buttonSize} // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              // color="orange"
              // color={this.getButtonStyle()}
              onClick={this.clickAction}
              className={this.getButtonStyle()}
            >
              {/* <img src="../images/battery.svg" /> */}
              <Icon
                name={this.getButtonIcon()}
                rotated={this.getButtonIconRotate()}
              />
              {/* <Icon name={{ className: "images/battery.svg" }} /> */}
            </Button>
          }
        />
      </Link>
    );
  }
}

function mapStateToProps(state) {
  const { latestFaultTs: pageFaultTs } = state.alarmButton;

  // see -  src/components/Header/index.js
  // switch between
  // <ProcessMQTT>
  //
  //  const { latestFaultTs: mqttFaultTs } = state.mqtt;
  // <WebWorker>
  //const { latestFaultTs: mqttFaultTs } = state.webWorker;
  const mqttFaultTs = getLatestFaultTs(state); // state.webWorker;
  const { messageBanners } = state.alarmButton;

  // need fault message to determine highest fault level for a given faultType
  //  const { mqttMsg } = state.webWorker;
  const mqttMsg = getMqttMsg(state);

  return {
    messageBanners,
    pageFaultTs,
    mqttFaultTs,
    mqttMsg,
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    SetLatestFaultTs: (latestFaultTs) => {
      dispatch({ type: `SET_LATEST_FAULT_TS`, payload: latestFaultTs });
    },
    clearFlashMessages: () => {
      dispatch(clearFlashMessages());
    },
    removeFlashMessage: (id) => {
      dispatch(removeFlashMessage(id));
    },
    addWarningFlashMessageIdColor: (id, color, header, message) => {
      dispatch(addWarningFlashMessageIdColor(id, color, header, message));
    },
    SetMessageBanner: (messageState) => {
      dispatch(SetMessageBanner(messageState));
    },
    LogIt: (thingsLogged) => {
      dispatch(LogIt(thingsLogged));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmButton);
