// see - src/admin/named-area-group/NamedAreaGroupSettingsModal.js

import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Modal, Header, Icon, Button } from "semantic-ui-react";
import isEqual from "lodash/isEqual";
import { formatRelative, parseISO } from "date-fns";

import { getControllerById } from "components/WebWorker/reducer";
import UPSLink from "admin/ups/UPSLink";

import { isOnOffPokeTheWorker } from "components/Settings/reducer";
import { TurnOnOffPokeTheWorker } from "components/Settings/actions";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

class ControllerMarkerPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false };
  }

  componentDidMount() {
    const { open, isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;

    if (open) {
      this.handleOpen();
    }

    if (isPokeTheWorker) {
      TurnOnOffPokeTheWorker(false);
    }
  }

  componentWillUnmount() {
    const { isPokeTheWorker, TurnOnOffPokeTheWorker } = this.props;
    if (!isPokeTheWorker) {
      TurnOnOffPokeTheWorker(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display          <Grid>

    this.props.resetModal();

    // reset the form
    //this.setState({ value: "" }, () => this.props.reset());
  };

  render() {
    const {
      ups_id,
      easting,
      northing,
      X,
      Y,
      z,
      utm_zone_number,
      utm_zone_letter,
      color,
      location,
      lastStatusReport,
      user_relay,
      role,
      network_name,
    } = this.props;

    //console.log(`ControllerMarkerPopupModal`, this.props);

    const { allowAdmin } = role;

    const controllerLabel = `${network_name} (${ups_id})`;
    const controllerLink = <UPSLink id={ups_id}>{controllerLabel}</UPSLink>;
    const controllerNoLink = <strong>{controllerLabel || "unknown"} </strong>;

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header icon="cog" content={`Controller - ${controllerLabel}`} />
        <Modal.Content>
          <Grid>
            <Grid.Row
              key={`key-C-${ups_id}`}
              style={{ paddingTop: "1em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>Controller</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                {allowAdmin ? controllerLink : controllerNoLink}
              </Grid.Column>
            </Grid.Row>
            {location?.map((loc, idx) => {
              const locString = JSON.stringify(loc)?.replaceAll('"', "");
              return (
                <Grid.Row
                  key={`key-C-${ups_id}-${locString}`}
                  style={{ paddingTop: "0em", paddingBottom: "0px" }}
                >
                  <Grid.Column width={4}>
                    {idx > 0 ? null : <strong>Location</strong>}
                  </Grid.Column>
                  <Grid.Column width={11}>{locString}</Grid.Column>
                </Grid.Row>
              );
            })}
            <Grid.Row
              key={`key-C-${ups_id}-user-relay`}
              style={{ paddingTop: "0em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>User Relay</strong>
              </Grid.Column>
              <Grid.Column width={11}>{user_relay || 0}</Grid.Column>
            </Grid.Row>

            <Grid.Row
              key={`key-C-${ups_id}-last`}
              style={{ paddingTop: "0.5em", paddingBottom: "0px" }}
            >
              <Grid.Column width={4}>
                <strong>Last Report</strong>
              </Grid.Column>
              <Grid.Column width={11}>{lastStatusReport}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel}>
            <Icon name="close" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    initialValues: { id },
  } = props;

  // user roles
  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);
  const allowGuest = userGuest;

  const allowOperator = userSuper || userAdmin || userUser;
  const allowAdmin = userSuper || userAdmin;
  const allowSuper = userSuper;

  const controller = getControllerById(state, id);

  const {
    topology: { location, network_name },
    system,
    timestamp,
  } = controller;

  //console.log(`ControllerMarkerPopupModal xxx controller`, controller);

  // user_relay is undefined when in DEMO_MODE
  const user_relay = system?.user_relay || 0;

  const lastStatusReport =
    timestamp !== undefined
      ? formatRelative(parseISO(timestamp), new Date(), {
          includeSeconds: true,
        })
      : "-";

  return {
    ups_id: id,
    location,
    user_relay,
    network_name,
    role: {
      allowGuest: allowGuest,
      allowOperator: allowOperator,
      allowAdmin: allowAdmin,
      allowSuper: allowSuper,
    },
    lastStatusReport,
    isPokeTheWorker: isOnOffPokeTheWorker(state),
  };
}

export default connect(mapStateToProps, { TurnOnOffPokeTheWorker })(
  ControllerMarkerPopupModal
);
