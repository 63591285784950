export function SetNamedAreaDisplaySettings(options) {
  return { type: "SET_NAMED_AREA_DISPLAY_SETTINGS", payload: options };
}

export function UpdateNamedAreaDisplaySettings(options) {
  return { type: "UPDATE_NAMED_AREA_DISPLAY_SETTINGS", payload: options };
}

export const fetchFolderFilesListByFolder = (folder) => {
  return { type: "FOLDER_FILES_LIST_FETCH_REQUESTED", payload: folder };
};

export const deleteFolderFile = (info) => {
  return { type: "FOLDER_FILE_DELETE_REQUESTED", payload: info };
};

export const saveFolderFile = (info) => {
  return { type: "FOLDER_FILE_SAVE_REQUESTED", payload: info };
};

export const fetchServerInfo = (info) => {
  return { type: "SERVER_INFO_FETCH_REQUESTED", payload: info };
};

export const fetchServerOk = (info) => {
  return { type: "SERVER_OK_FETCH_REQUESTED", payload: info };
};

export const TurnOnOffPokeTheWorker = (action) => {
  //console.log(`TurnOnOffPokeTheWorker -->`, action);
  return { type: "SYSTEM_TURN_ON_OFF_POKE_THE_WORKER", payload: action };
};

export const DisablePokeTheWorker = (action) => {
  return { type: "SYSTEM_DISABLE_POKE_THE_WORKER", payload: action };
};

export const SetPokeTheWorkerPollRate = (milliseconds) => {
  return { type: "SYSTEM_POLL_RATE_POKE_THE_WORKER", payload: milliseconds };
};

export const SetPollRates = (rates) => {
  return { type: "SYSTEM_SET_POLL_RATES", payload: rates };
};

export const fetchMqttBroker = () => {
  return { type: "MQTT_BROKER_FETCH_REQUESTED" };
};

export const fetchCheckService = (action) => {
  return { type: "SERVER_CHECK_SERVICE_FETCH_REQUESTED", payload: action };
};

export const SetMapImage = (data) => {
  return { type: "SYSTEM_SET_MAP_IMAGE", payload: data };
};

// used to communicate the zoom and center of the currently displayed map
export const SetMapSettings = (data) => {
  return { type: "SYSTEM_SET_MAP_SETTING", payload: data };
};
