import React, { Component } from "react";
import { connect } from "react-redux";

import { Field, formValueSelector, reduxForm } from "redux-form";

import { Slider } from "react-semantic-ui-range";
import "semantic-ui-css/semantic.min.css";

import {
  Modal,
  Header,
  Button,
  Form,
  Segment,
  Grid,
  GridColumn,
  GridRow,
  Label,
} from "semantic-ui-react";

import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import { renderRadio, renderCheckbox } from "admin/form-field";

import {
  isAuthenticated,
  isUserSuper,
  isUserAdmin,
  isUserUser,
  isUserGuest,
} from "auth/reducer";

class NamedAreaDisplaySettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      columnWidth: props.initialValues.columnWidth,
    };
  }

  componentDidMount() {
    const { open } = this.props;
    if (open) {
      this.handleOpen();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    if (!isEqual(this.props, prevProps)) {
      if (open) {
        this.handleOpen();
      }
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleCancel = () => {
    // close the modal
    this.setState({ modalOpen: false });

    // reset the modal display
    this.props.resetModal();

    // reset the form
    this.setState({ value: "" }, () => this.props.reset());
    //    this.props.onCancel();
  };

  myHandleSubmit = (values) => {
    // close the modal
    this.setState({ modalOpen: false });

    // save the changes
    this.props.handleSettingsSave(values);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      error,
      reset,
      initialValues,
      change,
      role,
    } = this.props;

    const { allowOperator } = role;

    // APP_TERMINOLOGY
    const strNamedArea = "Polygon";
    const strNamedAreas = "Polygons";
    const strSopInterface = "Lighting Control Layout"; // "SOP user interface"
    const strTitle = "Lighting Control"; // operational interface

    const { columnWidth } = initialValues;

    //console.log("modal initialValues", initialValues);

    const settings = {
      start: columnWidth,
      min: 0,
      max: 16,
      step: 2,
      onChange: (value) => {
        //console.log("value", value);
        this.props.change("columnWidth", value);

        this.setState({
          columnWidth: value,
        });
      },
    };

    return (
      <Modal open={this.state.modalOpen} onClose={this.handleCancel} closeIcon>
        <Header icon="cog" content={`${strTitle} Display Settings`} />
        <Modal.Content>
          <Form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(this.myHandleSubmit)(e);
            }}
          >
            <Grid>
              <GridRow>
                <GridColumn>
                  <Field
                    name="legend"
                    label="Show map legend"
                    component={renderCheckbox}
                  />
                  {allowOperator && (
                    <>
                      <Field
                        name="sopInterface"
                        label={`Show ${strSopInterface}`}
                        component={renderCheckbox}
                      />

                      <Field
                        name="hint"
                        label={`Show hints on ${strNamedArea} buttons`}
                        component={renderCheckbox}
                      />
                      <Grid>
                        <GridRow columns={2} style={{ paddingBottom: "0px" }}>
                          <GridColumn width={3}>
                            <label>Control Size</label>
                          </GridColumn>
                          <GridColumn>
                            <Form.Group inline>
                              <Field
                                component={renderRadio}
                                label="Compressed"
                                name="view"
                                radioValue={"compressed"}
                              />
                              <Field
                                component={renderRadio}
                                label="Normal"
                                name="view"
                                radioValue={"normal"}
                              />
                              <Field
                                component={renderRadio}
                                label="Expanded"
                                name="view"
                                radioValue={"expanded"}
                              />
                            </Form.Group>
                          </GridColumn>
                        </GridRow>
                        <GridRow columns={2} style={{ paddingTop: "0px" }}>
                          <GridColumn width={3}>
                            <label>Column Width</label>
                          </GridColumn>
                          <GridColumn width={10}>
                            <Slider
                              discrete
                              color="red"
                              inverted={false}
                              settings={settings}
                            />
                            <Field
                              name="columnWidth"
                              type="hidden"
                              component="input"
                            />
                          </GridColumn>
                        </GridRow>
                      </Grid>
                    </>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>
            <Segment>
              <Grid>
                <GridRow>
                  <GridColumn>
                    <Button.Group floated="right">
                      <Button
                        color="green"
                        type="submit"
                        disabled={pristine || submitting}
                        //onClick={(e) => this.myHandleSubmit(e)}
                      >
                        Save
                      </Button>
                      <Button.Or />
                      <Button
                        type="button"
                        disabled={pristine || submitting}
                        onClick={reset}
                      >
                        Reset
                      </Button>
                      <Button.Or />
                      <Button
                        style={{ textAlign: "right" }}
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Button.Group>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Segment>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

NamedAreaDisplaySettingsModal = reduxForm({
  form: "NamedAreaDisplaySettingsModal",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
})(NamedAreaDisplaySettingsModal);

//const selector = formValueSelector("NamedAreaDisplaySettingsModal");

function mapStateToProps(state, props) {
  const { initialValues } = props;

  // user roles
  const userSuper = isUserSuper(state);
  const userAdmin = isUserAdmin(state);
  const userUser = isUserUser(state);
  const userGuest = isUserGuest(state);
  const allowGuest = userGuest;

  const allowOperator = userSuper || userAdmin || userUser;
  const allowAdmin = userSuper || userAdmin;
  const allowSuper = userSuper;

  return {
    initialValues: initialValues, // props passed to redux-form loads these as (predictably), initial values
    role: { allowGuest, allowOperator, allowAdmin, allowSuper },
  };
}

export default connect(mapStateToProps, null)(NamedAreaDisplaySettingsModal);
