export const newNamedAreaTemplate = (
  id,
  name,
  parent,
  parentName,
  area,
  shape,
  color,
  lightState,
  namedAreaSubType,
  priority = 0,
  style
) => {
  // #NOTE #TODO #WIP - MOVE DEFAULT SETUP TO CONFIG
  const { active_state, on_time = 20, off_time = 20, train = 20 } = lightState;

  // console.log(
  //   `forcedLight xxxxxxxxxxxxx`,
  //   id,
  //   name,
  //   parent,
  //   parentName,
  //   area,
  //   shape,
  //   color,
  //   lightState,
  //  style
  // );

  let marker = "RoundMarker";

  if (id.includes("FORCED")) {
    marker = "RoundMarkerForced";
  }

  switch (active_state) {
    case "strobe":
      marker = "SquareMarker";
      break;
    case "forward":
      marker = "ForwardMarker";
      break;
    case "backward":
      marker = "BackwardMarker";
      break;
    case "off":
      marker = "OffMarker";
      break;
    case "on":
      break;

    default:
      // "on"
      break;
  }

  // #NOTE - there must be a button defined for the priority level selected for the namedArea
  //
  // if 'priority' is passed, create a button with this priority
  const makeButton = (priority) => {
    let result = [];
    for (let idx = 0; idx < priority; idx++) {
      result.push({});
    }
    result.push({
      priority: 0,
      type: "SEISMIC0_EVENT",
      clickable: true,
      icon: "icon-earthquake0",
      color: color,
      hint: "Level 0",
      title: "Seismic 0",
      alt: "Level 0",
      id: id,
      named_area: parent,
      group: 0,
      default: true,
      active: true,
      column: 0,
      state: active_state,
      active_color: color,
      active_state: active_state,
      on_time,
      off_time,
      train,
      marker,
      siren_state: "off",
      operator: "client2",
    });
    return result;
  };

  const result = {
    id: id,
    area: area,
    name: name,
    shape: shape,
    type: "Polygon",
    sub_type: namedAreaSubType,
    priority: 1,
    parent: parent,
    parent_name: parentName,
    // #WIP #TODO - set a central location for dummy buttons - used in a few places
    button: makeButton(priority),
    active_color: color,
    active_state,
    style: style,
  };

  // console.log(`forcedLight: NewNamedAreaTemplate `, result);

  return result;
};
