import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container, Header } from "semantic-ui-react";

import MineLevelMap from "components/MineLevelMap";
import { LayersControl } from "react-leaflet";
import CurrentMineLevelFirefliesFeatureGroup from "containers/CurrentMineLevelFirefliesFeatureGroup";
import CurrentSeismicFeatureGroup from "containers/CurrentSeismicFeatureGroup";

import MineLevelPageHeader from "containers/MineLevelPageHeader";
// import PrintControl from 'react-leaflet-easyprint'

class MineLevelSeismicLayerSummaryPage extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  /* <PrintControl
        position='topleft'
        sizeModes={['Current', 'A4Portrait', 'A4Landscape']}
        hideControlContainer={false}
        title='Export as PNG'
        exportOnly
      /> */
  render() {
    return (
      <Container>
        <MineLevelPageHeader>Status</MineLevelPageHeader>
        <Header as="h3">MineLevel Status Page</Header>

        <MineLevelMap style={{ height: "700px" }}>
          <LayersControl position={"topright"}>
            <LayersControl.Overlay
              name={"Current seismic"}
              key={"current-overlay"}
              checked
            >
              <CurrentSeismicFeatureGroup />
            </LayersControl.Overlay>
            <LayersControl.Overlay
              name={"FireFlys"}
              key={"firefly-overlay"}
              checked
            >
              <CurrentMineLevelFirefliesFeatureGroup />
            </LayersControl.Overlay>
          </LayersControl>
        </MineLevelMap>
      </Container>
    );
  }
}

// export default withMineLevel(connect(mapStateToProps)(SeismicLevelOverviewPage))
export default MineLevelSeismicLayerSummaryPage;
