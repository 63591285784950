import React from "react";
import {
  Container,
  Segment,
  Grid,
  GridRow,
  GridColumn,
} from "semantic-ui-react";

// #REVIEW
// ? style box with rounded corner box with separate padding
// ? could change tooltips to semantic-ui <Popup /> for consistency, but would be a lot bigger
const LegendIcon = ({ icon, index }) => {
  return (
    <div className={"legend"} key={index}>
      <img alt="" src={process.env.PUBLIC_URL + "/images/" + icon.imageUrl} />
      <div className={"tooltip"} key={index}>
        {icon.text}
        <span className="tooltiptext" key={index}>
          {icon.tooltip}
        </span>
      </div>
    </div>
  );
};

const Legend = ({ icons }) => (
  <Segment vertical className={"legend"}>
    <Container textAlign="center">
      <Grid
        // columns={10}
        columns="equal"
        // style={styleControlRoomPageGrid}
      >
        <GridRow>
          {icons.map((value, index) => {
            return (
              <GridColumn key={index}>
                <LegendIcon icon={value} index={index} />
              </GridColumn>
            );
          })}
        </GridRow>
      </Grid>
    </Container>
  </Segment>
);

export default Legend;
