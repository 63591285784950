import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import selector from './form-value-selector'

class CommissioningStatus extends Component {
  static propTypes = {
    status: PropTypes.oneOf([
      'ERROR',
      'NO_CHANGES',
      'TOPO_AND_POS',
      'TOPO_ONLY',
      'POS_ONLY',
      'NOT_COMMISSIONED'
    ])
  }

  render () {
    const { status } = this.props
    const options = {
      ERROR: 'No item found',
      NO_CHANGES: 'No change',
      NOT_COMMISSIONED: 'Not yet commissioned',
      TOPO_AND_POS: 'Full recommissioning',
      TOPO_ONLY: 'Will update topology',
      POS_ONLY: 'Will update position'
    }
    return <span>{options[status]}</span>
  }
}

export function hasTopologyChanged (fireflyFormRecord) {
  const { originalCommissioning, topology } = fireflyFormRecord
  if (!originalCommissioning) {
    return false
  }

  const fields = ['upsId', 'position', 'portNumber']
  for (let i = 0; i < fields.length; i++) {
    const k = fields[i]
    if (originalCommissioning.topology[k] !== topology[k]) {
      return true
    }
  }
  return false
}

export function hasLatLngChanged (fireflyFormRecord) {
  const { originalCommissioning, position } = fireflyFormRecord
  if (!originalCommissioning) {
    return false
  }

  const fields = ['lat', 'lng']
  for (let i = 0; i < fields.length; i++) {
    const k = fields[i]
    if (originalCommissioning.position[k] !== position[k]) {
      return true
    }
  }
  return false
}

CommissioningStatus = connect((state, props) => {
  const ff = selector(state, props.formname)
  if (!ff) {
    return { status: 'ERROR' }
  }

  const { originalCommissioning } = ff
  if (!originalCommissioning) {
    return { status: 'NOT_COMMISSIONED' }
  }

  let diffTopo = hasTopologyChanged(ff)
  let diffPos = hasLatLngChanged(ff)

  let status = 'NO_CHANGES'
  if (diffTopo && diffPos) {
    status = 'TOPO_AND_POS'
  } else if (diffTopo) {
    status = 'TOPO_ONLY'
  } else if (diffPos) {
    status = 'POS_ONLY'
  }

  return { status }
})(CommissioningStatus)

export default CommissioningStatus
