// see main reducer at src/reducers.js
//

// Definition
//
// logOfThingsLogged: [
//   {
//     description: "",
//     ts: 0
//   }
// ]

import Immutable from "seamless-immutable";

let initialState = Immutable({
  logOfThingsLogged: [],
});

const logList = [
  "CHECK_AUTH",
  "CHECK_BROWSER",
  "AUTH_FAILURE",
  "OPERATIONAL_CHANGE_UPDATE",
  "OPERATIONAL_CHANGE_DEACTIVATED",
  "SERVER_CONNECTION_OPENED",
  "SERVER_CONNECTION_CLOSED",
  "DELETE_FIREFLY",
];

// this is just to remove the traffic of more common messages
//
const ignore = [
  "FIREFLY_STATUS_UPDATE",
  "WEBSOCKET:MESSAGE",
  "WEBSOCKET:SEND",
  "UPS_STATUS_UPDATE",
  "TRAVELWAY_STATUS_UPDATE",
  "MQTT_MSG_UPDATE",
  "MQTT_LATEST_FAULT_TS_UPDATE",
  "STOP_AREA_PREVIEW",
  "SHOW_AREA_PREVIEW",
  "STATUS_CHECK_TICK",
];

export default function reduce(state = initialState, action = {}) {
  // copy existing state
  const newState = { ...state };
  let { type, payload } = action;

  // only for viewing messages to decide on what to log
  const toIgnore = ignore.indexOf(type);
  if (toIgnore < 0) {
    //console.log("Log - type", type, " payload ", payload); // #NOTE - main debug Log it
  }

  // compare incoming redux messages to a list of approved messages
  // to collect. When found reassign their type to REDUX_MESSAGE
  // and process them normally
  const isInLogList = logList.indexOf(type);
  if (isInLogList > -1) {
    // order important, change payload to type and set generic type
    if (payload) {
      if (payload.description) {
        payload = type + " > " + JSON.stringify(payload.description);
      } else {
        payload = type + " > " + JSON.stringify(payload);
      }
    } else {
      payload = type;
    }
    type = "REDUX_MESSAGE";
  }

  switch (type) {
    case "REDUX_MESSAGE":
    case "LOG_IT":
      let { logOfThingsLogged } = newState;

      // purge log items after 3 days
      const threeDaysInSeconds = 3 * 24 * 60 * 60;
      const now = Date.now() / 1000;
      const threeDaysAgo = now - threeDaysInSeconds;
      logOfThingsLogged = logOfThingsLogged.filter(
        (item) => item.ts >= threeDaysAgo
      );

      newState.logOfThingsLogged = [
        { description: payload, ts: now },
        ...logOfThingsLogged,
      ];
      return newState;
    case "CLEAR_LOG":
      newState.logOfThingsLogged = [];

      return newState;

    case "RESET":
      console.log("RESET! - Logger");
      return initialState; //Always return the initial state
    default:
      return state;
  }
}
