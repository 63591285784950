import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";

import { isOnOffPokeTheWorker } from "components/Settings/reducer";

import {
  TurnOnOffPokeTheWorker,
  SetPokeTheWorkerPollRate,
} from "components/Settings/actions";

export class DataCollectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // not in use - yet?
  }

  componentDidUpdate(prevProps, prevState) {
    // not in use - yet?
  }

  clickAction = (e) => {
    //nothing for the moment
    // later add action to disable data collection
  };

  getButtonStyle = () => {
    const { isPokeTheWorker, isFetching } = this.props;

    // possible button colours
    // ["red","orange","orange","olive","green","teal","blue","violet","purple","pink","brown","grey","black","facebook","google plus","instagram","linkedin","twitter","vk","youtube"].

    // and the current page faults timestamp (TS) is less than mqtt TS
    if (isPokeTheWorker || isFetching) {
      return "light green";
    } else {
      return "";
    }
  };

  getButtonLink = () => {
    return `/tools/system`;
  };

  render() {
    const buttonSize = this.props?.size || "large";

    return (
      <Link to={this.getButtonLink()}>
        <Popup
          content={"Indicates data collection from the web worker & server"}
          trigger={
            <Button
              icon
              size={buttonSize} // sizes - 'mini', 'tiny', 'small', 'large', 'big', 'huge', and 'massive'
              // color="orange"
              // color={this.getButtonStyle()}
              onClick={this.clickAction}
              className={this.getButtonStyle()}
            >
              {/* <img src="../images/battery.svg" /> */}
              <Icon name={"database"} />
            </Button>
          }
        />
      </Link>
    );
  }
}

function mapStateToProps(state) {
  const isPokeTheWorker = isOnOffPokeTheWorker(state);
  const isFetching = false; // get fetch status from API

  return {
    isPokeTheWorker,
    isFetching,
  };
}

export default connect(mapStateToProps, {
  TurnOnOffPokeTheWorker,
})(DataCollectButton);
