import Immutable from "seamless-immutable";
import { DEFAULT_SETTINGS } from "utils/DEFAULTS";

const initialState = Immutable({
  users: {},
});

export default function reduce(state = initialState, action = {}) {
  const newState = { ...state };
  const { type, payload } = action;

  switch (type) {
    case "ALL_USERS_FETCH_SUCCEEDED":
      const {
        users: { list },
      } = payload;

      let newList = [];
      list.forEach((item) => {
        let settings = DEFAULT_SETTINGS;
        // check settings exist or set DEFAULT
        if (item?.settings !== "") {
          if (item?.settings?.includes("display")) {
            settings = JSON.parse(item?.settings);
          }
        }
        newList.push({ ...item, settings });
      });

      //console.log("ALL_USERS_FETCH_SUCCEEDED USERS", newList);

      newState.users = newList;
      return newState;

    case "RESET":
      console.log("RESET! - UserAdmin");
      return state;
    default:
      return state;
  }
}

function stableOrder(order, items) {
  return Immutable(order.map((k) => items[k]));
}
export const getAllUsers = (state) => {
  const byId = state.userAdmin.users;
  const keys = Object.keys(byId).sort();
  return stableOrder(keys, byId);
};

export const getUserById = (state, id) => {
  const allUsers = getAllUsers(state).find((user) => user.email === id);
  return allUsers;
};

export const getAllUsersByRole = (state, role) => {
  return getAllUsers(state)?.filter((user) => user.role === role) || [];
};

export const getAllUsersRoleUserSettingsNamedAreaGroup = (state, area) => {
  const allUsersRoleUser =
    getAllUsers(state)?.filter((user) => user.role === "user") || [];
  let users = [];
  allUsersRoleUser.forEach((user) => {
    //console.log("xxx user allUsersRoleUser", user);
    const nagSettings =
      user?.settings?.namedAreaGroup?.filter((group) => group.area === area) ||
      [];
    //console.log("xxx user allUsersRoleUser nagSettings", nagSettings);

    users.push({ user: user.email, settings: nagSettings });
  });

  return users;
};
