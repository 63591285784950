import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Header,
  Tab,
  Grid,
  Dimmer,
  Loader,
  Container,
  Message,
} from "semantic-ui-react";

import MineLevelOpsControl from "pages/ControlRoomPage/MineLevelOpsControl";
// mobile version
import MineLevelOpsControlMinimal from "pages/ControlRoomPage/MineLevelOpsControlMinimal";

import PrecannedOperations from "pages/ControlRoomPage/PrecannedOperations";

import {
  getAllAreaStatuses,
  getNamedAreaStatusesById,
  getMqttDataLoading,
} from "components/WebWorker/reducer";

import { getNamedAreaDisplaySettings } from "components/Settings/reducer";

import FlashMessagesList from "FlashMessages";

import Legend from "components/Legend";
import { legend, appResponsive } from "components/ConfigJs";

import TestMqttGeoJson from "pages/Test/TestMqttGeoJson";

import _isEmpty from "lodash/isEmpty";

import { DebugPagePropsMessages } from "components/Debug/propsMessages";

import {
  isConfigJs,
  TemplateDefaultArea,
  controlScreenEmergencyEventButtons as _controlScreenEmergencyEventButtons,
} from "components/ConfigJs";

// railway_application
import GatesAndCrane from "pages/ControlRoomPage/GatesAndCrane/GatesAndCrane";
import {
  isBoomgateCrane as _isBoomgateCrane,
  BoomgateControllerIp,
  BoomgateColors,
} from "components/ConfigJs";

import { withComponentStateCache } from "react-component-state-cache";

import MediaQuery from "react-responsive";

import { fetchMineLevels } from "MineLevels/actions";
import { fetchUPSs } from "UPSPanelControllers/actions";
import { fetchFireflys } from "UPSPanelControllers/actions";
import {
  fetchNamedAreas,
  fetchNamedAreaEvents,
  fetchNamedAreaTriggers,
} from "NamedAreas/actions";

import { strings } from "components/App/localisation";
import { saveUserSettingsComponentState } from "components/UserAdmin/actions";
import { getUserData } from "auth/reducer";

class ControlRoomPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      mapTab: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    // const {
    //   levels,
    //   fetchMineLevels,
    //   fetchUPSs,
    //   fetchFireflys,
    //   fetchNamedAreas,
    //   fetchNamedAreaEvents,
    //   fetchNamedAreaTriggers,
    // } = this.props;

    // // fetch server data if new startup or reload
    // if (_isEmpty(levels)) {
    //   //#FETCHS
    //   fetchMineLevels(); //#FETCHS
    //   fetchUPSs(); //#FETCHS
    //   fetchFireflys(); //#FETCHS
    //   fetchNamedAreas(); //#FETCHS
    //   fetchNamedAreaEvents(); // #FETCHS
    //   fetchNamedAreaTriggers(); // #FETCHS

    //   this.props.componentstate.set("STARTUP", 1, "loaded");
    // }

    // track window dimension
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    // #WIP - debugging reset to latest tab
    const currentMapTab = this.props.componentstate.get("currentMapTab", 1);
    //console.log("currentMapTab componentDidMount", currentMapTab);
    if (Number.isInteger(currentMapTab)) {
      //console.log("currentMapTab componentDidMount setState", currentMapTab);
      this.setState({ mapTab: currentMapTab });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);

    const settings = {
      section: "currentMapTab",
      key: 1,
      data: { ...this.state.activeIndex },
    };

    this.props.componentstate.set(
      settings.section,
      settings.key,
      settings.data
    );

    //#REVIEW #TODO - is this necessary?
    //this.props.saveUserSettingsComponentState({ settings });

    // #WIP - debugging reset to latest tab
    //this.props.componentstate.set("currentMapTab", 1, this.state.activeIndex);
    //console.log("currentMapTab componentWillUnmount", this.state.activeIndex);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleTabChange = (e, { activeIndex }) => {
    // #WIP - debugging reset to latest tab

    //console.log("currentMapTab handleTabChange activeIndex", activeIndex);
    this.setState({ activeIndex });
  };

  render() {
    const {
      levels,
      namedAreaDisplaySettings,
      allAreasNamedArea,
      isDataLoading,
      userSettings,
    } = this.props;

    const { activeIndex, height, width } = this.state;
    //console.log("mobile width: {width} ~ height: {height}", width, height);

    //console.log("controlRoomPage isDataLoading", isDataLoading);
    const { legend: isShowLegend, sopInterface: isSopInterface } =
      namedAreaDisplaySettings;

    // #NOTE #WIP
    // This should not be necessary as defaultLevel loads through webworker

    const configJs = isConfigJs();
    // check if the default area is loading correctly from config.js
    // if this load fails the webworker may never return a valid areaStatuses
    // resulting in an endless 'data loading' sequence.

    const templateDefaultArea = TemplateDefaultArea();

    // DON'T DO THIS!
    //key={`div-${cuid()}`}

    const allAreasButtonGroup = allAreasNamedArea?.button;

    const isBoomgateCrane = _isBoomgateCrane() || false;

    const controlScreenEmergencyEventButtons =
      _controlScreenEmergencyEventButtons() || {
        show: false,
        label: "MINE WIDE",
      };

    const startupProblem = !configJs || _isEmpty(levels);

    if (startupProblem) {
      console.log("LOADING ERROR: NO AREAS TO DISPLAY.");
      console.log("CHECK IF CONFIG.JS IS LOADED OK.....? - ", configJs);
      console.log(
        "DEFAULT AREA TEMPLATE LOADED - ",
        JSON.stringify(templateDefaultArea)
      );
    }

    const { mobile, tablet, laptop, desktop } = appResponsive();

    const isDesktopOrLaptop = desktop; // (min-width: 1351px)
    const isTabletOrMobileDevice = mobile; // 1350 (max-width: 1350px)

    return (
      <>
        {/* See - src/components/Header/index.js for other size setups */}
        <MediaQuery maxWidth={tablet}>
          <div className={"genericGridHeader"}>
            <Container>
              <Grid style={{ paddingBottom: "10px" }}>
                <Grid.Row className={"genericTitleHeader"}>
                  <Grid.Column textAlign="center">
                    {!isBoomgateCrane &&
                      controlScreenEmergencyEventButtons.show &&
                      allAreasButtonGroup !== undefined && (
                        <PrecannedOperations
                          area={{
                            id: "ALL_AREAS",
                            spec: "ALL_AREAS",
                            name:
                              controlScreenEmergencyEventButtons.label ||
                              "Whole Area",
                            type: "ALL_AREAS",
                            groupsArray: [allAreasButtonGroup],
                          }}
                          //size={"massive"} // superceded for `displaySettings`
                          displayAllOnMineLevel={true}
                          userSettings={userSettings}
                        />
                      )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Tab
                activeIndex={activeIndex}
                onTabChange={this.handleTabChange}
                className={"controlRoomPageTabs"}
                menu={{
                  attached: true,
                  tabular: true,
                  style: {
                    display: "flex",
                    justifyContent: "center",
                  },
                }}
                panes={levels.map((level) => ({
                  menuItem: level.name,
                  render: () => (
                    <Tab.Pane>
                      <MineLevelOpsControlMinimal
                        level={level}
                        userSettings={userSettings}
                      />
                    </Tab.Pane>
                  ),
                }))}
              />
              {!isDataLoading && <Legend icons={legend()} />}
              <DebugPagePropsMessages that={this} />
            </Container>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={tablet}>
          <>
            {startupProblem && (
              <div className={"genericGridHeader"}>
                <Container>
                  <Grid>
                    <Grid.Row className={"genericTitleHeader"}>
                      <Grid.Column textAlign={"left"}>
                        <FlashMessagesList />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      {/* dummy row for spacing consistency */}
                    </Grid.Row>
                  </Grid>
                  <Tab
                    className={"controlRoomPageTabs"}
                    menu={{
                      attached: true,
                      tabular: true,
                      style: { display: "flex", justifyContent: "left" },
                    }}
                    panes={["loading ..."].map((level) => ({
                      menuItem: strings?.["Loading ..."],
                      render: () => (
                        <Tab.Pane>
                          <Message
                            header={strings?.["Loading data ..."]}
                            content={strings?.[`LOADING_MESSAGE`]}
                          />
                        </Tab.Pane>
                      ),
                    }))}
                  />
                  <DebugPagePropsMessages that={this} />
                </Container>
              </div>
            )}
            {!startupProblem && (
              <div className={"genericGridHeader"}>
                <Container>
                  <Grid columns={!isBoomgateCrane ? 2 : 3}>
                    <Grid.Row className={"genericTitleHeader"}>
                      <Grid.Column
                        width={!isBoomgateCrane ? null : 4}
                        textAlign={"left"}
                      >
                        <div key={`div-all-areas`}>
                          {allAreasButtonGroup !== undefined && (
                            <>
                              {!isBoomgateCrane &&
                                controlScreenEmergencyEventButtons.show && (
                                  <Header
                                    style={{
                                      display: "inline-block",
                                      paddingRight: "20px",
                                      paddingLeft: "10px",
                                    }}
                                    as="h2"
                                  >
                                    {controlScreenEmergencyEventButtons.label}
                                  </Header>
                                )}
                              {(controlScreenEmergencyEventButtons.show ||
                                !isSopInterface) && (
                                <PrecannedOperations
                                  area={{
                                    id: "ALL_AREAS",
                                    spec: "ALL_AREAS",
                                    name:
                                      controlScreenEmergencyEventButtons.label ||
                                      "Whole Mine",
                                    type: "ALL_AREAS",
                                    groupsArray: [allAreasButtonGroup],
                                  }}
                                  //size={"massive"} // superceded for `displaySettings`
                                  displayAllOnMineLevel={true}
                                  userSettings={userSettings}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={!isBoomgateCrane ? null : 6}
                        textAlign={"left"}
                      >
                        <FlashMessagesList />
                      </Grid.Column>

                      {isBoomgateCrane && (
                        <Grid.Column width={6} textAlign={"right"}>
                          <GatesAndCrane
                            controllerIp={BoomgateControllerIp()}
                            colors={BoomgateColors()}
                          />
                        </Grid.Column>
                      )}
                    </Grid.Row>
                    <Grid.Row>
                      {/* dummy row for spacing consistency */}
                    </Grid.Row>
                  </Grid>
                  {/* #WIP - remove dimmer to allow more feedback */}
                  {/* <Dimmer active={isDataLoading} page>
              <Loader>Loading data</Loader>
            </Dimmer> */}
                  <Tab
                    activeIndex={activeIndex}
                    onTabChange={this.handleTabChange}
                    className={"controlRoomPageTabs"}
                    menu={{
                      attached: true,
                      tabular: true,
                      style: { display: "flex", justifyContent: "center" },
                    }}
                    panes={levels.map((level) => ({
                      menuItem: level.name,
                      render: () => (
                        <Tab.Pane>
                          <MineLevelOpsControl
                            level={level}
                            strings={strings}
                            userSettings={userSettings}
                          />
                        </Tab.Pane>
                      ),
                    }))}
                  />
                  {isShowLegend && <Legend icons={legend()} />}
                  {/* #TODO #FIX use of `isDataLoading` {!isDataLoading && isShowLegend && <Legend icons={legend()} />} */}
                  <DebugPagePropsMessages that={this} />
                </Container>
              </div>
            )}
          </>
        </MediaQuery>
      </>
    );

    // if (!configJs || _isEmpty(levels)) {
    //   return (
    //     <div className={"genericGridHeader"}>
    //       <Container>
    //         <Grid>
    //           <Grid.Row className={"genericTitleHeader"}>
    //             <Grid.Column textAlign={"left"}>
    //               <FlashMessagesList />
    //             </Grid.Column>
    //           </Grid.Row>
    //           <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
    //         </Grid>
    //         <Tab
    //           className={"controlRoomPageTabs"}
    //           menu={{
    //             attached: true,
    //             tabular: true,
    //             style: { display: "flex", justifyContent: "left" },
    //           }}
    //           panes={["loading ..."].map((level) => ({
    //             menuItem: "loading ...",
    //             render: () => (
    //               <Tab.Pane>
    //                 <Message
    //                   header={"Startup Problem"}
    //                   content={`There are no areas to display. This could be a data load failure. Check the console and mqtt.`}
    //                 />
    //               </Tab.Pane>
    //             ),
    //           }))}
    //         />
    //         <DebugPagePropsMessages that={this} />
    //       </Container>
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div className={"genericGridHeader"}>
    //       <Container>
    //         <Grid columns={!boomgateCrane ? 2 : 3}>
    //           <Grid.Row className={"genericTitleHeader"}>
    //             <Grid.Column
    //               width={!boomgateCrane ? null : 4}
    //               textAlign={"left"}
    //             >
    //               <div key={`div-all-areas`}>
    //                 {allAreasButtonGroup !== undefined && (
    //                   <>
    //                     {!boomgateCrane && (
    //                       <Header
    //                         style={{
    //                           display: "inline-block",
    //                           paddingRight: "20px",
    //                           paddingLeft: "10px",
    //                         }}
    //                         as="h2"
    //                       >
    //                         MINE WIDE
    //                       </Header>
    //                     )}
    //                     <PrecannedOperations
    //                       area={{
    //                         id: "ALL_AREAS",
    //                         spec: "ALL_AREAS",
    //                         name: !boomgateCrane ? "Whole Mine" : "Whole Area",
    //                         type: "ALL_AREAS",
    //                         groupsArray: [allAreasButtonGroup],
    //                       }}
    //                       //size={"massive"} // superceded for `displaySettings`
    //                       displayAllOnMineLevel={true}
    //                     />
    //                   </>
    //                 )}
    //               </div>
    //             </Grid.Column>
    //             <Grid.Column
    //               width={!boomgateCrane ? null : 6}
    //               textAlign={"left"}
    //             >
    //               <FlashMessagesList />
    //             </Grid.Column>

    //             {boomgateCrane && (
    //               <Grid.Column width={6} textAlign={"right"}>
    //                 <GatesAndCrane
    //                   controllerIp={BoomgateControllerIp()}
    //                   colors={BoomgateColors()}
    //                 />
    //               </Grid.Column>
    //             )}
    //           </Grid.Row>
    //           <Grid.Row>{/* dummy row for spacing consistency */}</Grid.Row>
    //         </Grid>
    //         {/* #WIP - remove dimmer to allow more feedback */}
    //         {/* <Dimmer active={isDataLoading} page>
    //           <Loader>Loading data</Loader>
    //         </Dimmer> */}
    //         <Tab
    //           activeIndex={activeIndex}
    //           onTabChange={this.handleTabChange}
    //           className={"controlRoomPageTabs"}
    //           menu={{
    //             attached: true,
    //             tabular: true,
    //             style: { display: "flex", justifyContent: "center" },
    //           }}
    //           panes={levels.map((level) => ({
    //             menuItem: level.name,
    //             render: () => (
    //               <Tab.Pane>
    //                 <MineLevelOpsControl level={level} />
    //               </Tab.Pane>
    //             ),
    //           }))}
    //         />
    //         {isShowLegend && <Legend icons={legend()} />}
    //         {/* #TODO #FIX use of `isDataLoading` {!isDataLoading && isShowLegend && <Legend icons={legend()} />} */}
    //         <DebugPagePropsMessages that={this} />
    //       </Container>
    //     </div>
    //   );
    // }
  }
}

function mapStateToProps(state) {
  // set the display and order of levels

  const allAreas = getAllAreaStatuses(state);
  const thisUser = getUserData(state);

  return {
    allAreasNamedArea: getNamedAreaStatusesById(state, "ALL_AREAS"),
    namedAreaDisplaySettings: getNamedAreaDisplaySettings(state),
    levels: allAreas,
    isDataLoading: getMqttDataLoading(state),
    userSettings: thisUser?.settings || {},
  };
}
export default withComponentStateCache(
  connect(mapStateToProps, {
    fetchMineLevels,
    fetchUPSs,
    fetchFireflys,
    fetchNamedAreas,
    fetchNamedAreaEvents,
    fetchNamedAreaTriggers,
    //fetchPrecannedOperations,
    saveUserSettingsComponentState,
  })(ControlRoomPage)
);
