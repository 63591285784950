export const imageCheck = (folderFilesList, image_filename, imageInfo) => {
  // check file exists on server
  let url;
  if (folderFilesList?.includes(image_filename)) {
    url = process.env.PUBLIC_URL + "/areas/" + image_filename;
  } else {
    console.log(`IMAGE> IMAGECHECK`, image_filename, "not in folderFilesList");

    // setup a dummy image and load the map image in the background
    // create a replacement image using known dimensions
    let canvas = document.createElement("canvas");
    // set desired size of transparent image
    canvas.width = imageInfo?.width || 10000; //#TODO - get default image load
    canvas.height = imageInfo?.height || 8000; //#TODO - get default image load
    // extract as new image (data-uri)
    url = canvas.toDataURL();

    // #NOTE #WIP #TODO
    // SET A MESSAGE IF THE MAP FILE IS NOT AVAILABLE
  }

  return url;
};
