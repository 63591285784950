import React from "react";

import { Children } from "react";

// Flattens all child elements into a single list
const flatten = (children, flat = []) => {
  flat = [...flat, ...React.Children.toArray(children)];

  if (children.props && children.props.children) {
    return flatten(children.props.children, flat);
  }

  return flat;
};

// Strips all circular references and internal fields
export const simplify = (children) => {
  const flat = flatten(children);

  return flat.map(({ key, ref, type, props: { children, ...props } }) => ({
    key,
    ref,
    type,
    props,
  }));
};

// returns a flat array of react children
// https://gist.github.com/gazdagergo/7833156e096683b49a21133858d521ca
// from
// https://github.com/facebook/react/issues/6889

const getChildrenType = (node) => {
  if (node === null) return "null";
  if (Array.isArray(node)) return "array";
  if (typeof node === "object") return "object";
  return "string";
};

const getElemType = (elem) => {
  if (typeof elem === "string") return "string";
  if (elem.props.children === undefined) return "void";
  return "normal";
};

export const reactChildrenToFlatArray = (children) => {
  const result = Children.toArray(children).map((child) => {
    switch (
      getElemType(child) // Check node type
    ) {
      case "void": // e.g. <br /> <img />
      case "string": // normal text
        return child;
      case "normal": // any other html tag or component
        switch (
          getChildrenType(child.props.children) // Check node content
        ) {
          case "null": // e.g. <Elem>null</Elem>
          case "string": // e.g <Elem>foo</Elem>
          case "object": // e.g. <Elem><Foo /></Elem>
            return child;
          case "array": // multiple children
            reactChildrenToFlatArray(child.props.children);
            break;
          default:
            return child;
        }
        break;
      default:
        return child;
    }
    return child;
  });
  return result;
};

//export default reactChildrenToFlatArray;
